import { push } from 'connected-react-router';

import { getRouteDraftView } from 'modules/draft/routes/navigation';
import { getRouteMyOrders } from 'modules/dashboard/routes/navigation';

import { isDevelopmentEnvironment } from 'modules/core/settings';

export const goToDraftView = (draftId) => {
  const route = getRouteDraftView(draftId);
  return push(route);
};

export const goToMyOrders = () => {
  const route = getRouteMyOrders();
  return push(route);
};

export const goToRoot = () => {
  if (isDevelopmentEnvironment()) {
    // TODO: Fix error handlers
    // eslint-disable-next-line no-console
    console.warn('If you are handling an error, consider using the toast instead');
  }

  const route = '/';
  return push(route);
};
