import Digital from 'assets/images/plus/digital.png';
import Premium from 'assets/images/plus/premium.png';
import { planHandle } from '../../../dashboard/pages/Plans/constants';

export const plusPlansIntroData = [
  {
    name: 'Digital+ Plus',
    planType: 'digital',
    image: Digital,
    perks: [
      'Google Business Setup Service ($299 value)',
      'Facebook Ads Setup ($299 value)',
      'Social Media Images ($99 value)',
      'Retargeting Ads Service ($59/monthly)',
      '2 Lead Capture Websites',
      '20 Printed Books ($100 value) on your first month',
      'Access to 2 additional niche books',
      'You can buy more books anytime',
      '12 Month Price Lock',
    ],
  },
  {
    name: 'Premium+ Plus',
    planType: 'premium',
    image: Premium,
    perks: [
      'Google Business Setup Service ($299)',
      'Professional Social Media Graphics ($199)',
      'Facebook Ads Campaign Setup ($299)',
      'Retargeting Ads ($79/monthly - $948/yearly)',
      '50 Additional Printed Books ($250)',
      'Custom Listing Presentation ($199)',
      'Additional 4 niche books',
      'You can buy more books anytime',
      '12 Month Price Lock',
    ],
  },
];

export const plusPlansProductInfo = [
  {
    planType: 'digital',
    perks: plusPlansIntroData[0].perks,
    data: [
      {
        id: 1,
        name: 'Digital+ Plan Annual',
        monthly: '$167 / month',
        type: 'annually',
        billed: 'Billed Yearly',
        saved: 'Saved $319',
        price: '$1,999',
        planHandle: planHandle.DIGITAL_PLUS_ANNUAL,
        isMonthly: false,
      },
      {
        id: 2,
        name: 'Digital+ Plan Monthly',
        monthly: '$199 / month',
        type: 'monthly',
        billed: 'Billed Monthly',
        saved: '',
        price: '$199',
        planHandle: planHandle.DIGITAL_PLUS_MONTHLY,
        isMonthly: true,
      },
    ],
  },
  {
    planType: 'premium',
    perks: plusPlansIntroData[1].perks,
    data: [
      {
        id: 1,
        name: 'Premium+ Plan Annual',
        monthly: '$250 / month',
        type: 'annually',
        billed: 'Billed Yearly',
        saved: 'Saved $479',
        price: '$2,999',
        planHandle: planHandle.PREMIUM_PLUS_ANNUAL,
        isMonthly: false,
      },
      {
        id: 2,
        name: 'Premium+ Plan Monthly',
        monthly: '$299 / month',
        type: 'monthly',
        billed: 'Billed Monthly',
        saved: '',
        price: '$299',
        planHandle: planHandle.PREMIUM_PLUS_MONTHLY,
        isMonthly: true,
      },
    ],
  },
];
