import { useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';

import { formatDate } from 'modules/v2/common/utils';
import { Title } from 'modules/v2/common/AtomicDesign/atoms';
import { getStats, getCampaignMetrics } from 'modules/api';
import { LeadIcon, LandingPageIcon, EmailIcon } from 'modules/v2/common/components/SvgIcon';


const AnalyticsContainer = () => {
  const [landingPageVisits, setLandingPageVisits] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [emailsSent, setTotalEmailsSent] = useState(0);
  const date = new Date();
  const fortNightDate = formatDate(date.setDate(date.getDate() - 90), 'YYYY-MM-DD');
  const currentDate = formatDate(new Date(), 'YYYY-MM-DD');

  const dateRange = [moment(new Date()).add(-7, 'days'), moment(new Date())];

  useQuery(
    'campaignMetrics',
    () =>
      getCampaignMetrics({
        start: dateRange[0],
        end: dateRange[1],
        sortBy: '',
        textSearch: '',
        perPage: 25,
      }),
    {
      onSuccess: (data) => {
        const totalEmailsSent = data?.total?.current?.Send;
        setTotalEmailsSent(totalEmailsSent);
      },
    },
  );

  useQuery(
    ['gettingStats', { fortNightDate, currentDate }],
    () => getStats(fortNightDate, currentDate),
    {
      onSuccess: (data) => {
        const visits = data?.data[0].value;
        const leads = data?.data[1].value;

        setLandingPageVisits(visits);
        setTotalLeads(leads);
      },
    },
  );

  return (
    <div className="bg-white rounded-lg shadow-md mt-6">
      <h4 className="p-6 text-base mb-0">Analytics</h4>

      <div className="flex flex-wrap flex-col lg:flex-row border-t border-t-neutral-200">
        <div className="p-8 lg:border-r lg:border-b-0 border-b flex-1 border-r-neutral-200 flex flex-col">
          <div className="inline-flex gap-x-2"><LandingPageIcon /> <Title size="small">Landing page visits</Title></div>
          <strong className="text-3xl font-bold">{landingPageVisits}</strong>
        </div>
        <div className="p-8 lg:border-r lg:border-b-0 border-b flex-1 border-r-neutral-200 flex flex-col">
          <div className="inline-flex gap-x-2"><LeadIcon active /> <Title size="small">Total subscribed leads</Title></div>
          <strong className="text-3xl font-bold">{totalLeads}</strong>
        </div>
        <div className="p-8 flex-1 flex flex-col">
          <div className="inline-flex gap-x-2"><EmailIcon /> <Title size="small">Total emails sent</Title></div>
          <strong className="text-3xl font-bold">{emailsSent}</strong>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsContainer;
