import LoaderImg from 'assets/images/loader/loader.gif';
import S from './styles';

const GlobalLoader = ({ height = '100vh' }) => (
  <S.Wrapper height={height}>
    <S.LoaderImg src={LoaderImg} alt="" />
  </S.Wrapper>
);

export default GlobalLoader;
