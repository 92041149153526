const SearchAddressPinIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 1.66699C6.31802 1.66699 3.33325 5.00248 3.33325 8.75033C3.33325 12.4688 5.46102 16.5107 8.78081 18.0624C9.5547 18.4241 10.4451 18.4241 11.219 18.0624C14.5388 16.5107 16.6666 12.4688 16.6666 8.75033C16.6666 5.00248 13.6818 1.66699 9.99992 1.66699ZM9.99992 10.0003C10.9204 10.0003 11.6666 9.25413 11.6666 8.33366C11.6666 7.41318 10.9204 6.66699 9.99992 6.66699C9.07944 6.66699 8.33325 7.41318 8.33325 8.33366C8.33325 9.25413 9.07944 10.0003 9.99992 10.0003Z"
        fill="#AEAEAE"
      />
    </svg>
  );
};

export default SearchAddressPinIcon;
