import * as color from 'modules/common/theme/color';

const bookImg = 'book-cover/large-business-card-book.png';
const bookImg1 = 'book-cover/large-home-buyer-book.png';
const bookImg2 = 'book-cover/large-fsbo-book.png';
const bookImg3 = 'book-cover/large-expired-book.png';
const bookImg4 = 'book-cover/large-divorcing-homeowners.png';

const bookImgBack = 'book-cover/back-business-card-book.png';
const bookImg1Back = 'book-cover/back-home-buyer-book.png';
const bookImg2Back = 'book-cover/back-fsbo-book.png';
const bookImg3Back = 'book-cover/back-expired-book.png';
const bookImg4Back = 'book-cover/back-divorcing-homeowners.png';

export const bookStyles = [
  {
    id: 0,
    style: {
      position: 'absolute',
      borderRadius: '50%',
      zIndex: 9999,
      left: '20px',
      top: '20px',
      height: '32px',
      width: '32px',
      transform: 'matrix(1, -0.29, 0.15, 1, 52, 42)',
      objectFit: 'cover',
    },
  },
  {
    id: 1,
    style: {
      position: 'absolute',
      borderRadius: '50%',
      zIndex: 9999,
      left: '20px',
      top: '20px',
      height: '38px',
      width: '39px',
      transform: 'matrix(1, -0.3, 0.15, 1, 50, 75)',
      objectFit: 'cover',
    },
  },
  {
    id: 2,
    style: {
      borderRadius: '50%',
      zIndex: 9999,
      left: '20px',
      position: 'absolute',
      top: '20px',
      height: '38px',
      width: '39px',
      transform: 'matrix(1, -0.3, 0.15, 1, 50, 76)',
      objectFit: 'cover',
    },
  },
  {
    id: 3,
    style: {
      borderRadius: '50%',
      height: '42px',
      left: '20px',
      position: 'absolute',
      top: '20px',
      transform: 'matrix(1, -0.3, 0.15, 1, 49, 76)',
      width: '42px',
      zIndex: 9999,
      objectFit: 'cover',
    },
  },
  {
    id: 4,
    style: {
      borderRadius: '50%',
      height: '45px',
      left: '20px',
      position: 'absolute',
      top: '20px',
      transform: 'matrix(1.1, -0.36, 0.2, 1, 46, 31)',
      width: '45px',
      zIndex: 9999,
      objectFit: 'cover',
    },
  },
];

export const bookCoverArray = [
  {
    id: 0,
    front: bookImg,
    back: bookImgBack,
    text: 'Business Card Book',
  },
  // eslint-disable-next-line quotes
  {
    id: 1,
    front: bookImg1,
    back: bookImg1Back,
    text: 'Home Buyers Guide',
  },
  {
    id: 2,
    front: bookImg2,
    back: bookImg2Back,
    text: 'FSBO Books',
  },
  {
    id: 3,
    front: bookImg3,
    back: bookImg3Back,
    text: 'Expired Book',
  },
  {
    id: 4,
    front: bookImg4,
    back: bookImg4Back,
    text: 'Divorcing Homeowners',
  },
];

export const bookLabelStyles = [
  {
    id: 0,
    style: {
      transform: 'matrix(1, -0.25, 0.2, 1, -24, -5)',
      color: '#427bb3',
      fontWeight: '700',
    },
  },
  {
    id: 1,
    style: {
      transform: 'matrix(1, -0.2, 0.2, 1, -27, -64)',
      color: color.White,
      fontWeight: '700',
    },
  },
  {
    id: 2,
    style: {
      transform: 'matrix(1, -0.2, 0.2, 1, -22, -10)',
      color: color.White,
      fontWeight: '700',
    },
  },
  {
    id: 3,
    style: {
      transform: 'matrix(1, -0.25, 0.1, 1, -23, -7)',
      color: color.White,
      fontWeight: '700',
    },
  },
  {
    id: 4,
    style: {
      transform: 'matrix(1, -0.2, 0.2, 1, -25, -4)',
    },
  },
];
