import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { has } from 'lodash';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';

import { BasicOfferTemplate } from 'modules/landingPages/templates';
import { Advertisement, OrderSummary } from 'modules/landingPages/components';
import { Modal, Loader } from 'modules/common/components';
import FormBuilder from 'modules/formBuilder';
import NotFound from 'modules/dashboard/pages/NoRoutesFound';
import { setItem } from 'modules/dashboard/utils/legacy';
import { useFinishPayment } from 'modules/landingPages/hooks';
import { notification } from 'modules/common/utils';
import INITIAL_VALUES from 'modules/formBuilder/initialValues';
import { getRouteLandingPageClickFunnelUpsell } from 'modules/landingPages/routes/navigation';

import { parse } from 'query-string';
import { createUpsellWithNoSession } from 'modules/api';
import { useWaitPayment } from './hooks';
import validationSchema from './validationSchema';
import * as funnels from '../../definitions';

const ClickFunnels = ({ history }) => {
  const { funnelCode } = useParams();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(
    'Please wait while we get your payment profile.',
  );
  const queryString = window.location.search;

  useEffect(() => {
    localStorage.clear();
  }, []);

  const pathname = getRouteLandingPageClickFunnelUpsell(funnelCode, queryString);

  // TODO: refactor this function to have proper validation and error handling
  const handleWaitPaymentSuccess = ({ isApproved, customer }) => {
    if (isApproved) {
      const {
        channel,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_content: utmContent,
        utm_term: utmTerm,
      } = parse(queryString);

      const { upsell } = funnels[funnelCode];

      // Adding isArray because parse doesn't provide a way to remove duplicated values
      createUpsellWithNoSession({
        channel: Array.isArray(channel) ? channel[0] : channel,
        utmSource: Array.isArray(utmSource) ? utmSource[0] : utmSource,
        utmMedium: Array.isArray(utmMedium) ? utmMedium[0] : utmMedium,
        utmContent: Array.isArray(utmContent) ? utmContent[0] : utmContent,
        utmTerm: Array.isArray(utmTerm) ? utmTerm[0] : utmTerm,
        paymentProvider: 'CHARGIFY',
        paymentStatus: 'SUCCESS',
        offerId: upsell?.offerId ?? funnelCode,
        customerEmail: customer.email,
        firstName: customer.first_name,
        lastName: customer.last_name,
      })
        .then(() => {
          setPaymentLoading(false);
          history.push(pathname);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleWaitPaymentError = () => {
    notification.error({
      title: 'Error',
      description: 'Something went wrong while processing your payment.',
    });
  };

  const { waitPayment } = useWaitPayment({
    eventPrefix: 'payment',
    onSuccess: handleWaitPaymentSuccess,
    onError: handleWaitPaymentError,
    startWaitingForPaymentResponse: paymentLoading,
  });

  const {
    offerDescription,
    offerImage,
    formDefinitions,
    productHandler,
    componentId,
    offerId,
    summaryTitle,
    summaryPrice,
    USOnly,
  } = funnels[funnelCode];

  const handlePaymentSuccess = (response) => {
    const { data } = response || {};
    const { customer, id } = data || {};

    if (customer && id) {
      setItem('customerAndSubscriptionId', { customer, id });
    }

    setModalMessage('Please wait one more step while we process your order.');
    waitPayment();
  };

  const handleError = () => {
    notification.error({
      title: 'Error',
      description: 'There was an issue with your payment!',
    });

    setPaymentLoading(false);
  };

  const { handleFormFinish } = useFinishPayment({
    onSuccess: handlePaymentSuccess,
    onError: handleError,
    productHandler,
    componentId,
    offerId,
  });

  const storeDataAndFinishForm = (params, actions) => {
    actions.setSubmitting(false);

    setItem('customer', params);
    setPaymentLoading(true);
    handleFormFinish(params);
  };

  const getOrderSummary = <OrderSummary price={summaryPrice} title={summaryTitle} />;

  if (!has(funnels, funnelCode)) return <NotFound />;

  return [
    <>
      {funnelCode.startsWith('trustOffer') && (
        <Helmet>
          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '716384701741346');
            fbq('track', 'PageView');
            `}
          </script>
          <noscript>
            {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=716384701741346&ev=PageView&noscript=1"
          />`}
          </noscript>
        </Helmet>
      )}
      <BasicOfferTemplate
        key="offerTemplate"
        advertisementComponent={
          <Advertisement image={offerImage} offerDescription={offerDescription} />
        }
        formComponent={
          <Formik
            onSubmit={storeDataAndFinishForm}
            validationSchema={validationSchema}
            initialValues={INITIAL_VALUES}
          >
            <FormBuilder
              formDefinitions={formDefinitions}
              submitTitle="Place Order"
              mainTitle={USOnly && 'FOR U.S. RESIDENTS ONLY'}
              orderSummary={getOrderSummary}
            />
          </Formik>
        }
      />
      ,
      <Modal key="paymentModal" isOpen={paymentLoading} footer={null}>
        <Loader title="Loading" description={modalMessage} height="500px" />
      </Modal>
      ,
    </>,
  ];
};

export default ClickFunnels;
