//

import { filter, map } from 'lodash-es';
import $ from 'jquery';

import S from './styles';

const checkEmpty = (value) => !$(`<p>${value}</p>`).text().trim();

const ContentPreview = ({ layout, elements, showGuides }) => {
  const active = filter(elements, ({ status }) => status === 'active');
  const mappedElements = map(active, (element) => {
    const isEmpty = checkEmpty(element.defaultValue);
    const value = isEmpty ? element.placeholder : element.defaultValue;

    return (
      <S.Element
        key={element.id}
        showGuides={showGuides}
        element={element}
        isEmpty={isEmpty}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  });

  return (
    <S.Wrapper>
      <S.Panel layout={layout}>{mappedElements}</S.Panel>
    </S.Wrapper>
  );
};

ContentPreview.defaultProps = {
  layout: {},
  elements: [],
  showGuides: false,
};

export default ContentPreview;
