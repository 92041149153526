import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import { ApolloProvider } from 'react-apollo';

import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Root = ({ store, graphql }) => (
  <ApolloProvider client={graphql}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </ApolloProvider>
);

export default Root;
