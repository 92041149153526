import styled from 'styled-components';
import * as font from 'modules/common/theme/font';

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  width: ${(props) => (props.width ? props.width : '310px')};
  height: ${(props) => (props.height ? props.height : '100%')};

  padding: 20px;

  font-family: ${font.RobotoFont};

  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
`;

const Icon = styled.div`
  font-size: 26px;
  height: 26px;
  text-align: center;
  margin-bottom: 6px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font.LoraFont};
  text-align: center;
  line-height: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const Description = styled.div`
  height: ${(props) => (props.descriptionHeight ? props.descriptionHeight : '61px')};
  margin-bottom: 10px;
  font-size: 12px;
  text-align: justify;
  line-height: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export default { CardContainer, Icon, Title, Description, ButtonContainer };
