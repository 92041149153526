import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 20px;
  flex: 1;
`;

export default {
  Wrapper,
};
