import { FieldGroup } from 'modules/onboard/components';

import Table from 'modules/common/components/Table';

import S from './styles';

interface IOrderSummary {
  title: string;
  price: string | number;
}

const OrderSummary = ({ title, price }: IOrderSummary) => (
  <>
    <S.FormTitle>Order Summary</S.FormTitle>
    <S.BlockForm>
      <FieldGroup gap>
        <Table
          rowKey="key"
          columns={[
            {
              title: 'Title',
              dataIndex: 'title',
            },
            {
              title: 'Price',
              dataIndex: 'price',
              align: 'right',
            },
          ]}
          dataSource={[{ key: title, title, price }]}
        />
      </FieldGroup>
    </S.BlockForm>
  </>
);

export default OrderSummary;
