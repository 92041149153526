import { useEffect, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { get, noop } from 'lodash-es';

import { imagePlugin } from 'modules/editor/components/EditorElement/components/Editor/plugins';
import { options } from 'modules/editor/components/ContentEditor/utils';
import { FilePickerModal } from 'modules/editor/components/CoverEditorModal/components';
import { isLoading, isSucceeded } from 'store/status';

const Image = ({
  editor,
  images,
  onChange = noop,
  onConfirmFilePickerModal = noop,
  onGalleryTabOpen = noop,
  onDelete = noop,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { image } = options;
  const imagesData = get(images, 'data', null);
  const uploadData = get(images, ['upload', 'data'], null);
  const uploadStatus = get(images, ['upload', 'status'], null);

  useEffect(() => {
    if (isSucceeded(uploadStatus)) {
      const newEditorState = imagePlugin.addImage(editor, uploadData.url);
      onChange(newEditorState);
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus, uploadData]);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <span className="normal" key={image.type} onClick={handleClick}>
        <LegacyIcon type={image.icon} />
      </span>
      {isOpen && (
        <FilePickerModal
          isOpen={isOpen}
          isConfirmLoading={isLoading(uploadStatus)}
          isImagesLoading={isLoading(images.status)}
          title="Add Image"
          type="content"
          images={imagesData}
          onCancel={handleCancel}
          onConfirm={onConfirmFilePickerModal}
          onDelete={onDelete}
          onGalleryTabOpen={onGalleryTabOpen}
        />
      )}
    </>
  );
};

export default Image;
