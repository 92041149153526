import styled from 'styled-components';

import * as color from 'modules/common/theme/color';
import { TickedIcon as BaseTickedIcon } from 'modules/onboard/components/UIComponents';

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
`;

const Title = styled.h5`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 0px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.Black};
  text-transform: capitalize;
`;

const TopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 0px 0px;
`;

const AvailableAnymore = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: ${color.Black};
  margin-bottom: 10px !important;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px !important;
`;

const ListText = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${color.Black};
  margin-left: 8px !important;
`;

const TickedIcon = styled(BaseTickedIcon)`
  color: ${(props) => (props.color ? props.color : color.DefaultBlue)};
  font-size: 16px;
`;

const List = styled.div`
  margin-bottom: 30px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  margin: 40px 0px;
  button {
    margin-right: 10px;
  }
`;

export default {
  MainContain,
  Title,
  Description,
  TopWrap,
  AvailableAnymore,
  ListItem,
  ListText,
  TickedIcon,
  List,
  ButtonsWrap,
};
