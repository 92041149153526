import { Redirect, useParams } from 'react-router';
import { Helmet } from 'react-helmet';

import { currentYear } from 'modules/common/utils';
import { GlobalLoader } from 'modules/common/components';
import { isDentistOffer } from 'modules/dashboard/utils';
import { getRouteError } from 'modules/onboard/routes/navigation';
import Header from 'modules/onboard/components/Header';
import Steps from '../components/Steps';

import { OnboardTemplateProps } from './types';
import S from './styles';

const OnboardTemplate = ({
  children,
  currentStep,
  stickyContent = null,
  isLoading,
  isError,
  isStepsVisible = true,
}: OnboardTemplateProps) => {
  const { offerCode } = useParams<{ offerCode: string }>();

  const isDentist = isDentistOffer();
  const isOffer1014 = offerCode === 'offer-1014';
  const PRODUCT = isDentist ? 'guide' : 'book';

  if (isLoading) {
    return <GlobalLoader />;
  }

  if (isError) {
    return <Redirect to={getRouteError()} />;
  }

  const stepsData = [
    {
      label: 'Schedule Appointment',
      stepNumber: '1',
    },
    {
      label: 'Training Webinar',
      stepNumber: '2',
      status: isDentist ? 'inactive' : '',
    },
    {
      label: `Your ${PRODUCT} details`,
      stepNumber: '3',
    },
    {
      label: isDentist ? 'Send for print' : 'Summary',
      stepNumber: '4',
    },
  ];

  return (
    <>
      <Helmet>
        {isOffer1014 ? <script>provesrc.trackGoal("662162ac79b5637292dbb54d")</script> : null}
      </Helmet>
      <S.Layout>
        <Header />

        <S.Wrapper>
          {isStepsVisible && <Steps currentStep={currentStep} stepsData={stepsData} />}
          <S.WrapperInner>
            <S.Container>{children}</S.Container>
          </S.WrapperInner>
        </S.Wrapper>
        {stickyContent}
        <S.FooterContainer>
          <S.FooterWrapper>
            Authorify © {currentYear} - All Rights Reserved <br />
            3500 Beachwood Court #203 Jacksonville, FL 32216 <br />
            <a href="https://authorify.com/privacy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            |{' '}
            <a href="https://authorify.com/terms" target="_blank" rel="noreferrer">
              Terms Of Use
            </a>
          </S.FooterWrapper>
        </S.FooterContainer>
      </S.Layout>
    </>
  );
};

export default OnboardTemplate;
