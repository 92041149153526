import { get } from 'lodash-es';
import { STATUS_SUCCESS, STATUS_ERROR, STATUS_LOADING } from 'store/status';

import { GET_COVER_PREVIEW } from 'modules/v2/editor/store/constants';

import { preview as initialState } from 'modules/v2/editor/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_COVER_PREVIEW}_REQUESTED`: {
      return {
        ...state,
        data: null,
        status: STATUS_LOADING,
      };
    }

    case `${GET_COVER_PREVIEW}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${GET_COVER_PREVIEW}_SUCCEEDED`: {
      const urls = get(action, ['payload', 'urls'], null);
      const timestamp = get(action, ['payload', 'timestamp'], null);

      return {
        ...state,
        data: {
          urls,
          timestamp,
        },
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
};

export default reducer;
