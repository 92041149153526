import withStore from 'store/StoreContainer';
import {
  userImagesSelector,
  userLogosSelector,
  deleteUserImage,
  getUserImages,
  getPresetLogos,
} from 'modules/v2/editor/store';

const mapStateToProps = (state) => ({
  images: userImagesSelector(state),
  logos: userLogosSelector(state),
});

const mapDispatchToProps = {
  deleteUserImage,
  getUserImages,
  getPresetLogos,
};

export default withStore(mapStateToProps, mapDispatchToProps);
