/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import S from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PdfPageViewerProps {
  pdfUrl: string;
  width: number;
  height: number;
}

const PdfViewer: React.FC<PdfPageViewerProps> = ({ pdfUrl, width, height }) => {
  return (
    <S.PdfContainer>
      <Document file={pdfUrl}>
        <Page pageNumber={1} width={width} height={height} />
      </Document>
    </S.PdfContainer>
  );
};

export default PdfViewer;
