import { createSelector } from 'reselect';
import { filter, get } from 'lodash-es';

import { dashboardSelector } from 'modules/dashboard/store/selectors/dashboard';

export const booksStateSelector = createSelector(dashboardSelector, ({ books, categories }) => {
  const data = get(books, 'data', null);
  const selected = get(categories, 'selected');

  if (!selected) {
    return books;
  }

  return {
    ...books,
    data: filter(data, ({ category }) => category === selected),
  };
});
