import { Form, useFormikContext } from 'formik';
import moment from 'moment';

import { ALL_SEGMENTS_ID } from 'v2/pages/CreateOnDemandEmail';
import { ErrorMessage, SelectMultiField } from 'modules/v2/common/components';

import { defaultCheckboxTheme } from 'v2/common/AtomicDesign/atoms/Checkbox/theme';
import { Checkbox } from 'flowbite-react';
import RelativeLoading from 'v2/common/AtomicDesign/RelativeLoading';
import { Tooltip } from 'v2/common/AtomicDesign/molecules';
import { QuestionCircleIcon } from 'v2/common/components/SvgIcon';
import S from './styles';

const Sidebar = ({ campaignName, segmentsList, isLoading, disableSegmentsDropdown }) => {
  const diableAlloweekend = true;
  const { values, setFieldValue, setValues, errors, touched, setFieldTouched } = useFormikContext();
  const { startDate, allowWeekend, name, segments } = values;

  const startDateParsed = startDate ? moment(startDate) : null;

  const handleChange = ({ field, type = '' }) => {
    return (fieldData) => {
      let value = fieldData;

      if (type === 'checkbox') {
        value = fieldData.target.checked;
      }

      if (type === 'input') {
        value = fieldData.target.value;
      }

      setFieldValue(field, value);
    };
  };

  const handleSelectChange = (segmentIdArray) => {
    if (segmentIdArray.includes(ALL_SEGMENTS_ID)) {
      setFieldValue('segments', []);
      setFieldValue('allSegments', true);
      return;
    }
    if (!segmentIdArray.length) {
      setFieldValue('segments', []);
      setFieldValue('allSegments', false);
      return;
    }
    setFieldValue('segments', segmentIdArray);
  };

  function handleAllowWeekend() {
    return () => {
      setFieldValue('allowWeekend', !allowWeekend);
    };
  }

  return (
    <div className="p-6 font-figtree min-w-[458px]">
      <S.Wrapper>
        <Form>
          <div className="text-sm mb-1 font-semibold">Campaign name</div>
          <S.Field
            name="name"
            placeholder={campaignName}
            onChange={handleChange({
              field: 'name',
              type: 'input',
            })}
            value={name}
            onBlur={() => setFieldTouched('name')}
            $hasError={errors.name && touched.name}
            helperText={
              (errors.name && touched.name && (
                <span className="text-error-500">{errors.name}</span>
              )) ||
              null
            }
          />

          <div className="flex flex-col mt-4">
            <span className="text-sm font-semibold">Select recipients</span>
            <span className="text-sm text-neutral-400 mb-1">
              Who are you sending this email to?
            </span>
          </div>
          <RelativeLoading isLoading={isLoading} />
          <S.SelectField
            disabled={disableSegmentsDropdown}
            className="w-full"
            hideAllSegmentsOption
            component={SelectMultiField}
            name="segments"
            placeholder="Select segment here"
            mode="multiple"
            onChange={handleSelectChange}
            onBlur={() => {
              setFieldTouched('segments');
            }}
            options={segmentsList}
            value={values.allSegments ? [ALL_SEGMENTS_ID] : values.segments}
            selectedSegment={segments}
            $hasErrors={touched.segments && errors.segments}
          />
          {values.allSegments === false && errors.segments && touched.segments ? (
            <ErrorMessage>{errors.segments}</ErrorMessage>
          ) : (
            ''
          )}

          <div className="flex flex-col mt-4">
            <span className="text-sm font-semibold flex">
              Send time{' '}
              <Tooltip
                content="The selected date will be the start date of the first email"
                trigger="hover"
                style="dark"
                arrow
                placement="top"
                theme={{
                  base: 'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                }}
              >
                <QuestionCircleIcon />
              </Tooltip>
            </span>
            <span className="text-sm text-neutral-400 mb-1">When should we send this email?</span>
          </div>

          <S.DatePicker
            showTime
            name="startDate"
            validRange={[moment(), moment().add(1, 'month')]}
            onChange={handleChange({ field: 'startDate' })}
            value={startDateParsed}
            format="MMM DD, YYYY h:mm A"
          />

          <div className="items-center">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className="-mt-3 flex items-center cursor-pointer"
              onClick={handleAllowWeekend()}
              onKeyUp={handleAllowWeekend()}
              htmlFor="allowWeekend"
              key="allowWeekend"
            >
              <Checkbox
                name="allowWeekend"
                theme={defaultCheckboxTheme}
                checked={allowWeekend}
                onChange={handleAllowWeekend()}
                disabled={!diableAlloweekend}
              />
              <span className="ml-2 font-semibold font-figtree">
                Include the weekend to send the email.
              </span>
            </label>
          </div>
        </Form>
      </S.Wrapper>
    </div>
  );
};

export default Sidebar;
