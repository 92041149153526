import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './trustOffer.png';

const isProduction = env.ENVIRONMENT === 'production';

const trustOffer = {
  offerDescription: [
    'The entire Instant Trust Package',
    'The Agent Comparison Chart',
    'The editable 18-Step Marketing Plan',
    'The Reference Letter Worksheet',
    'The Introduction Cover Letter',
    'The Photo Brochure that shows you’re an expert',
    'Complete A-to-Z Instructions on how to set up your Instant Trust Package and send it to sellers',
    'The Complete Guide to Finding Unlimited Leads',
    'The Guide on how to Outsource Seller Lead Research to a VA',
  ],
  productHandler: 'trust-offer',
  componentId: 'handle:clickfunnel-1',
  summaryPrice: '$1',
  summaryTitle: 'Instant Trust Package (Bonus: The Complete Guide to Finding Unlimited Leads)',
  offerImage,
  upsell: {
    productHandler: 'monthly-with-trial-10',
    productPricePointHandle: 'monthly-with-trial-10',
    componentId: isProduction ? '1824463' : '2197555',
    component: () => <Upsell productName="Trust Guide" />,
    offerId: isProduction ? '650498b6b8cad4bdeb9f2055' : '64e4c9a28820dd81bbcebc6b',
  },
  USOnly: false,
};

export default trustOffer;
