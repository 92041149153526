import { useState, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import { sortBy, get, isEmpty } from 'lodash-es';
import { Radio } from 'antd';

import env from 'environments';
import { FormLabel, SelectField } from 'modules/common/components';
import { Checkbox, InputPassword } from 'modules/common/components/UIComponents';
import {
  FieldGroup,
  FieldControl,
  Input,
  ErrorMessage,
  InputMask,
} from 'modules/onboard/components/UIComponents';
import { isDentistOffer, useIsRmOffer } from 'modules/dashboard/utils';
import { setItem } from 'modules/dashboard/utils/legacy';
import { createCustomer } from 'modules/api/digitalServices';
import { notification } from 'modules/common/utils';
import { COUNTRY_NAME } from 'modules/common/constants';
import Table from 'modules/common/components/Table';

import validationSchema from './validationSchema';
import buildConfig from './ChargifyCustomerFields';

import S from './styles';
import { setSession } from '../../utils';

const PlaceOrderForm = ({
  product,
  states,
  countries,
  nextPayment,
  fields,
  paymentOptions,
  isSessionLoading,
  onPasswordBlur,
  onPaymentDone,
  offerCode,
  isContracted,
  freeBooks,
  offerTitle,
}) => {
  const [isSubmitting, setIsSubmitting] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const chargifyForm = useRef();
  const chargify = useRef(new window.Chargify());
  const config = buildConfig('card');
  const isDentist = isDentistOffer();
  const { isRm } = useIsRmOffer();
  // TO DO: We should deal with this in the backend/database
  // We will implent this in the new DMP

  const productPrice = product?.price;

  const formatPrice = (price) => {
    if (!price) return '';
    let formatedPrice = get(price?.match(/\d+/g), '0', '');
    formatedPrice = parseInt(formatedPrice.replace(/[^0-9]/g, ''), 10);
    formatedPrice = `$${formatedPrice.toLocaleString('en-US')}`;
    return formatedPrice;
  };

  const price = formatPrice(productPrice);

  let subtitle = get(productPrice?.match(/\$\d+\s(.+)/), '1', '');
  if (subtitle.startsWith('For ')) subtitle = subtitle.slice(4);

  const { mutateAsync: customerMutate } = useMutation(createCustomer, {
    onSuccess: ({ data }) => {
      const paymentId = get(data, 'currentOffer.paymentId', '');

      if (paymentId) {
        setItem('payment_confirmed', 1);
      } else {
        setIsSubmitting(false);
        notification.error({
          title: 'Error',
          description: 'Something went wrong, please verify your data and try again.',
        });
      }
    },
    onError: () => {
      setIsSubmitting(false);
      notification.error({
        description: 'Something went wrong while we creating your account, please try again.',
      });
    },
  });

  const handleCreateCustomer = (formData) => customerMutate({ ...formData, offerCode });

  useEffect(() => {
    const chargifyRef = chargify.current;
    chargifyRef.load(config, { onThreeDsConfigError: console.error }); // eslint-disable-line no-console

    return () => {
      chargifyRef.unload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargify]);

  const handlePayment = (values) => {
    setIsSubmitting(true);
    try {
      chargify.current.token(
        chargifyForm.current,
        async (token) => {
          const payload = {
            ...values,
            chargifyToken: token,
          };

          const { data } = await handleCreateCustomer(payload);
          setSession(data);
          onPaymentDone();
        },
        (error) => {
          notification.error({
            title: 'Error',
            description: error?.errors,
          });
          // eslint-disable-next-line no-console
          console.log('{host} token ERROR - err: ', error);
          setIsSubmitting(false);
        },
      );
      return false;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setIsSubmitting(false);
    }

    return false;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={fields}
      validationSchema={validationSchema}
      onSubmit={handlePayment}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        isValid,
        dirty,
      }) => {
        const selectedCountry = COUNTRY_NAME[values.billing.country];
        const selectedStateList = get(states, selectedCountry);

        const stateList = sortBy(selectedStateList, 'title');
        const handleCountryChange = () => setFieldValue('billing.state', undefined);

        const isDisabled = !isEmpty(errors) || !dirty || !isValid;
        const hasPaymentOptions = paymentOptions.length > 0;

        const paymentOptionsItems = paymentOptions.map(({ code, name, title, recurrance }) => ({
          key: code,
          plan: (
            <Radio value={recurrance}>
              <S.OptionText>
                Authorify {name} Membership - {recurrance}
              </S.OptionText>
            </Radio>
          ),
          price: title,
        }));

        return (
          <Form onSubmit={handleSubmit} id="chargify" ref={chargifyForm}>
            <S.PageTitle>
              Place Order
              {isSessionLoading ? 'Is loading' : null}
            </S.PageTitle>
            <S.FormTitle>Personal Information</S.FormTitle>
            <S.BlockForm>
              <FieldGroup gap>
                <FieldControl>
                  <FormLabel title="Full Name" htmlFor="firstName" required>
                    <Input
                      id="firstName"
                      name="firstName"
                      value={values.firstName}
                      placeholder="First Name"
                      data-chargify="firstName"
                      $hasError={errors.firstName && touched.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.firstName && touched.firstName ? (
                      <ErrorMessage>{errors.firstName}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
                <FieldControl>
                  <FormLabel htmlFor="lastName" space>
                    <Input
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      value={values.lastName}
                      data-chargify="lastName"
                      $hasError={errors.lastName && touched.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.lastName && touched.lastName ? (
                      <ErrorMessage>{errors.lastName}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
              </FieldGroup>

              <FieldGroup gap>
                <FieldControl>
                  <FormLabel title="Phone Number" htmlFor="phone" required>
                    <InputMask
                      id="phone"
                      name="phone"
                      type="text"
                      mask="999-999-9999"
                      value={values.phone}
                      $hasError={errors.phone && touched.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      alwaysShowMask
                      disabled={isSubmitting}
                    />
                    {errors.phone && touched.phone ? (
                      <ErrorMessage>{errors.phone}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
              </FieldGroup>

              <FieldGroup>
                <FieldControl>
                  <Checkbox
                    name="optinReminder"
                    onChange={handleChange}
                    checked={values.optinReminder}
                  >
                    I want to receive member updates via text message.
                  </Checkbox>
                  <S.SubLabel>(Optional but highly recommended)</S.SubLabel>
                </FieldControl>
              </FieldGroup>

              <FieldGroup gap>
                <FieldControl>
                  <FormLabel title="Email" htmlFor="email" required>
                    <Input
                      id="email"
                      name="email"
                      value={values.email}
                      $hasError={errors.email && touched.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.email && touched.email ? (
                      <ErrorMessage>{errors.email}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
              </FieldGroup>

              <FieldGroup>
                <FieldControl>
                  <FormLabel title="Password" $hasError={errors.password}>
                    <InputPassword
                      id="password"
                      name="password"
                      value={values.password}
                      autoComplete="off"
                      visibilityToggle={{
                        visible: passwordVisible,
                        onVisibleChange: setPasswordVisible,
                      }}
                      type="password"
                      $hasError={errors.password || !touched.password}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      onBlur={() =>
                        onPasswordBlur({
                          email: values.email,
                          password: values.password,
                        })
                      }
                    />
                    {errors.password || touched.password ? (
                      <ErrorMessage>{errors.password}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
              </FieldGroup>
            </S.BlockForm>
            <S.FormTitle>Shipping Information</S.FormTitle>
            <S.BlockForm>
              <FieldGroup>
                <FieldControl>
                  <FormLabel title="Street Address" htmlFor="address">
                    <Input
                      id="address"
                      name="billing.address1"
                      value={values.billing.address1}
                      placeholder="Street Address"
                      $hasError={errors.billing?.address1 && touched.billing?.address1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.billing?.address1 && touched.billing?.address1 ? (
                      <ErrorMessage>{errors.billing.address1}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
              </FieldGroup>

              <FieldGroup gap>
                <FieldControl>
                  <FormLabel title="City" id="city">
                    <Input
                      id="city"
                      name="billing.city"
                      value={values.billing.city}
                      placeholder="City"
                      $hasError={errors.billing?.city && touched.billing?.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.billing?.city && touched.billing?.city ? (
                      <ErrorMessage>{errors.billing.city}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
                <FieldControl>
                  <FormLabel title="Country" id="country">
                    <S.FormikField
                      id="country"
                      component={SelectField}
                      name="billing.country"
                      placeholder="Choose your country"
                      type="text"
                      value={values.billing.country}
                      options={countries}
                      $hasError={errors.billing?.country && touched.billing?.country}
                      onChange={handleCountryChange}
                      onBlur={handleCountryChange}
                      disabled={isSubmitting}
                    />
                    {errors.billing?.country && touched.billing?.country ? (
                      <ErrorMessage>{errors.billing.country}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
              </FieldGroup>

              <FieldGroup gap>
                <FieldControl>
                  <FormLabel title="State" htmlFor="state">
                    <S.FormikField
                      id="state"
                      component={SelectField}
                      name="billing.state"
                      placeholder="Choose your state"
                      value={values.billing.state}
                      options={stateList}
                      $hasError={errors.billing?.state && touched.billing?.state}
                      disabled={isSubmitting}
                    />
                    {errors.billing?.state && touched.billing?.state ? (
                      <ErrorMessage>{errors.billing.state}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
                <FieldControl>
                  <FormLabel title="Postal Code" htmlFor="zip">
                    <Input
                      id="zip"
                      name="billing.zip"
                      value={values.billing.zip}
                      $hasError={errors.billing?.zip && touched.billing?.zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.billing?.zip && touched.billing?.zip ? (
                      <ErrorMessage>{errors.billing.zip}</ErrorMessage>
                    ) : null}
                  </FormLabel>
                </FieldControl>
              </FieldGroup>
            </S.BlockForm>

            <S.FormTitle>Payment Information</S.FormTitle>

            <S.BlockFormCard>
              <S.CardFieldGroup gap>
                <FieldControl>
                  <FormLabel htmlFor="state">
                    <S.CreditCardInput
                      disabled={isSubmitting}
                      data-cy="cardElement"
                      id="chargify1"
                    />
                  </FormLabel>
                </FieldControl>
              </S.CardFieldGroup>

              <S.CardFieldGroup gap>
                <FieldControl>
                  <S.CreditCardInput disabled={isSubmitting} id="chargify2" />
                </FieldControl>

                <FieldControl>
                  <S.CreditCardInput disabled={isSubmitting} id="chargify3" />
                </FieldControl>

                <FieldControl>
                  <S.CreditCardInput disabled={isSubmitting} id="chargify4" />
                </FieldControl>
              </S.CardFieldGroup>
              <input hidden type="text" readOnly value={values.billing.state} />
              <input hidden type="text" readOnly value={values.billing.country} />
            </S.BlockFormCard>

            <S.BlockForm>
              <S.Note>
                * Rest assured we will not charge your credit card until the order process is
                complete
              </S.Note>
            </S.BlockForm>

            {hasPaymentOptions ? (
              <S.BlockForm>
                <Radio.Group
                  onChange={(event) => setFieldValue('periodPay', event.target.value)}
                  name="periodPay"
                  value={values?.periodPay}
                >
                  <Table
                    rowKey="key"
                    columns={[
                      {
                        title: 'Choose your plan',
                        dataIndex: 'plan',
                        width: '70%',
                      },
                      {
                        title: 'Price',
                        dataIndex: 'price',
                        align: 'right',
                      },
                    ]}
                    dataSource={paymentOptionsItems}
                  />
                </Radio.Group>
              </S.BlockForm>
            ) : (
              <S.BlockForm>
                <S.SummaryHeader>Don't miss out on our limited offer.</S.SummaryHeader>
                <S.Summary>
                  <S.Summary.Title>{product?.title}</S.Summary.Title>
                  <S.Summary.Price>{price}</S.Summary.Price>
                  <S.Summary.SubTitle>{subtitle}</S.Summary.SubTitle>
                  <S.Summary.Info>
                    {isContracted ? `${offerTitle}` : 'For Shipping & Handling Only!'}
                  </S.Summary.Info>
                  {isDentist ? (
                    <S.Summary.Extra>+ 50 Free Dentist Education Packets</S.Summary.Extra>
                  ) : (
                    !!freeBooks && (
                      <S.Summary.Extra>{`+ ${freeBooks} FREE Printed Books`}</S.Summary.Extra>
                    )
                  )}
                </S.Summary>
              </S.BlockForm>
            )}

            <S.ConditionsText>
              By continuing, I agree to Authorify's <a href={env.TERMS_URL}>terms and conditions</a>
            </S.ConditionsText>

            <FieldGroup justifyContent="center">
              <S.PlaceOrderPrimaryButton
                data-cy="submit-place-order"
                disabled={isSubmitting || isDisabled}
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit(values.password);
                }}
              >
                Place Order
              </S.PlaceOrderPrimaryButton>
            </FieldGroup>

            {!isContracted && <S.InfoText>{nextPayment}. Cancel anytime.</S.InfoText>}
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlaceOrderForm;
