function getDefaultProps() {
  return {
    width: '100',
    height: '100',
    fill: '#FFF',
  };
}

function ResetIcon() {
  return (
    <svg
      id="reset"
      width={getDefaultProps().width}
      height={getDefaultProps().height}
      fill={getDefaultProps().fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        d="M86.6666663 56.2500003c0-22.0333333-16.1530158-40.2041666-37.1428571-43.2375V3e-7L25.1044443 16.666667l24.4193649 16.6666667v-11.8625c16.3923809 2.9625 28.8888889 17.3916666 28.8888889 34.7791666 0 19.5291667-15.7361905 35.4166667-35.0793651 35.4166667-19.3431747 0-35.0793653-15.8875-35.0793653-35.4166667 0-12.5625 6.533016-23.5916666 16.3304766-29.8791666l-7.3253973-5C6.7930157 29.354167-3e-7 41.9875003-3e-7 56.2500003-3e-7 80.404167 19.405079 100 43.333333 100s43.3333333-19.595833 43.3333333-43.7499997z"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default ResetIcon;
