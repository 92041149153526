import styled from 'styled-components';
import * as font from 'modules/common/theme/font';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  font-family: ${font.MontserratFont};
  padding: 30px 20px;
  text-align: center;
`;

const Title = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: ${color.GreyText};
`;

const Address = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

export default {
  Address,
  Title,
  Text,
  Wrapper,
};
