const ShippingIcon = ({ strokeColor = '#FF8A33' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33301 8.45258C3.33301 4.70473 6.31778 1.6665 9.99967 1.6665C13.6816 1.6665 16.6663 4.70473 16.6663 8.45258C16.6663 12.1711 14.5386 16.5102 11.2188 18.0619C10.4449 18.4236 9.55446 18.4236 8.78056 18.0619C5.46078 16.5102 3.33301 12.1711 3.33301 8.45258Z"
      stroke={strokeColor}
      stroke-width="1.5"
    />
    <ellipse cx="10" cy="8.3335" rx="2.5" ry="2.5" stroke={strokeColor} stroke-width="1.5" />
  </svg>
);

export default ShippingIcon;
