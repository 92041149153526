import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';

import { PageTitle } from 'modules/onboard/components';

const Main = styled.div`
  display: flex;
  width: 888px;
  margin: 0 auto;

  @media ${device.laptopL} {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  @media ${device.tabletS} {
    width: 100%;
    padding: 0 20px;
    flex-direction: column;
  }
`;

const Title = styled(PageTitle)`
  margin-bottom: 5px;
`;

const SubTitle = styled.p`
  text-align: center;
  margin-bottom 50px;
`;

const SidePreview = styled.div`
  display: flex;
  width: 336px;
  margin-right: 25px;
  position: relative;

  @media ${device.tabletS} {
    width: 80%;
    margin: 0 auto 40px;
  }
`;

const SidePreviewWrapper = styled.div`
  position: sticky;
  height: 600px;
  top: 0;

  @media ${device.tabletS} {
    position: static;
    height: auto;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const SwitchContainer = styled.div`
  button {
    margin-right: 10px;
  }
`;

export default {
  Title,
  SubTitle,
  Main,
  SidePreview,
  Content,
  SidePreviewWrapper,
  SwitchContainer,
};
