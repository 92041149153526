import { put, select, takeEvery } from 'redux-saga/effects';

import { UPDATE_COVER_IMAGE, UPDATE_COVER_ELEMENT } from 'modules/editor/store/constants';
import { coverElementByIdSelector, updatePreferenceByElementId } from 'modules/editor/store';

export function* onUpdateCoverElement({ payload: meta }) {
  const { elementId, defaultValue } = meta;

  const selector = coverElementByIdSelector(elementId);
  const { id } = yield select(selector);

  if (id) {
    const action = updatePreferenceByElementId(id, defaultValue);
    yield put(action);
  }
}

export function* watchUpdateCoverElement() {
  yield takeEvery([UPDATE_COVER_IMAGE, UPDATE_COVER_ELEMENT], onUpdateCoverElement);
}
