import noData from 'assets/images/no-data-box.svg';

import S from './styles';

const NoContent = ({ message }) => (
  <S.Container>
    <S.NoDataContainer>
      <S.IconWrapper src={noData} />
      <S.Title>No data to show</S.Title>
      <S.Description>{message}</S.Description>
    </S.NoDataContainer>
  </S.Container>
);

export default NoContent;
