import { get, isEmpty, noop } from 'lodash-es';
import { Loader } from 'modules/v2/common/components';
import { getPreflightCheckItems } from 'modules/common/utils';
import { CriteriaList } from 'modules/v2/editor/components';
import { MODAL_HEIGHT } from 'modules/publish/constants';

const PreflightCheckView = ({ loading, preflight, onEditCover = noop, onEditSection = noop }) => {
  if (loading) {
    return <Loader height={MODAL_HEIGHT} title="Checking book" />;
  }

  const criteria = get(preflight, 'finalResponse');
  const items = getPreflightCheckItems(criteria);

  const handleEdit = (sectionId) => {
    if (sectionId) {
      onEditSection(sectionId);
    } else {
      onEditCover();
    }
  };

  const criteriaList = !isEmpty(items) && <CriteriaList items={items} onEdit={handleEdit} />;

  return (
    <div className="h-fit">
      <p className="py-6 border-b border-neutral-200">
        We are checking your book and verifying all the essentials. Please use the edit feature to
        clear any pre-flight check errors.
      </p>
      {criteriaList}
    </div>
  );
};

export default PreflightCheckView;
