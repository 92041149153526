import { Form } from 'antd';
import styled from 'styled-components';
import BaseInputMask from 'react-input-mask';
import * as color from 'modules/common/theme/color';

const ContentInput = styled.div`
  margin-right: 10px;
`;

const InputMask = styled(BaseInputMask)`
  height: 40px;
  border-radius: 3px;
  width: 100%;
  display: block;
  font-size: 12px;
  line-height: 22px;
  color: #2e3f50;
  border: 1px solid ${color.SideBarSelectedBackground};
  padding-left: 10px;
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-explain-error {
    font-size: 10px;
  }
`;

export default {
  ContentInput,
  InputMask,
  FormItem,
};
