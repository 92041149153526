import getInstance from './instance';

const instance = getInstance();

export const fetchCustomerPortalLink = ({ redirectLink }) => {
  const url = '/support/payment-portal';
  const body = { redirectLink };

  return instance.post(url, body);
};
