import S from './styles';

export const ConfirmOrder = ({ subTransId, creditValue }) => {
  const { credits } = creditValue;
  const orderNumber = subTransId ?? '';
  return (
    <S.Container>
      <S.Icon />
      <div>
        <S.Title>Congratulations!</S.Title>
        <S.Description>
          Your order of <b>{credits} credits</b> has been confirmed. <br />
          Please wait <b>5-10 minutes</b> for it to reflect on your account.
        </S.Description>
        <S.Description>Please note your order number below</S.Description>
      </div>
      <S.OrderNumberContainer>
        <S.Description>Your order number is</S.Description>
        <S.Description>
          <b>{orderNumber}</b>
        </S.Description>
      </S.OrderNumberContainer>
      <div>
        <S.Description>Got Questions?</S.Description>
        <S.Description>Contact our support team at:</S.Description>
      </div>
      <div>
        <S.Description>+1 904-695-9933</S.Description>
        <S.Description>support@authorify.com</S.Description>
      </div>
    </S.Container>
  );
};
