import { Progress, Typography, Spin } from 'antd';
import { useQuery } from 'react-query';

import { getPlanDetails } from 'modules/api';

import S from '../../styles';

const { Text } = Typography;

const CurrentPlanDetails = () => {
  const { data: { data: listData } = {}, isLoading: isDraftLoading } = useQuery(
    'getPlanDetails',
    getPlanDetails,
  );

  const { totalBookCount, digitalBookCount, leadSiteCaptureCount, licensedBookCount } =
    listData ?? {
      totalBookCount: 0,
      digitalBookCount: 0,
      leadSiteCaptureCount: 0,
      licensedBookCount: 0,
    };

  const planProperty = (property, count) => {
    const percentage = Math.round((count / totalBookCount) * 100);
    const remaining = totalBookCount - count;
    return (
      <Spin spinning={isDraftLoading}>
        <S.Space direction="vertical" size={0}>
          <S.Space direction="horizontal">
            <Text strong>{property}</Text>
            <Text type="secondary">
              {count} / {totalBookCount}
            </Text>
          </S.Space>
          <Progress percent={percentage} showInfo={false} />
          {remaining > 0 && <Text type="secondary">{remaining} remaining</Text>}
        </S.Space>
      </Spin>
    );
  };
  if (!totalBookCount && !isDraftLoading) return null;

  return (
    <S.Section>
      <S.Space direction="vertical" size={10}>
        {planProperty('Licensed Books', licensedBookCount)}
        {planProperty('Digital Books', digitalBookCount)}
        {planProperty('Lead Capture Websites', leadSiteCaptureCount)}
      </S.Space>
    </S.Section>
  );
};

export default CurrentPlanDetails;
