import { generatePath } from 'react-router-dom';

import {
  ROUTE_DRAFT_VIEW,
  ROUTE_GENERATE_DRAFT,
  ROUTE_GENERATE_DRAFT_WELCOME_JOURNEY,
  ROUTE_DRAFT_CHECKOUT,
} from './constants';

export const getRouteDraftGenerate = (entity, id) =>
  generatePath(ROUTE_GENERATE_DRAFT, { entity, id });

export const getRouteDraftGenerateFromWelcomeJourney = (entity, id, flowPath) =>
  generatePath(ROUTE_GENERATE_DRAFT_WELCOME_JOURNEY, { entity, id, flowPath });

export const getRouteDraftGenerateByBookId = (bookId) => getRouteDraftGenerate('book', bookId);

export const getRouteDraftGenerateByBookIdFromWelcomeJourney = (bookId, flowPath) =>
  getRouteDraftGenerateFromWelcomeJourney('book', bookId, flowPath);

export const getRouteDraftGenerateByDraftId = (draftId) => getRouteDraftGenerate('draft', draftId);

export const getRouteDraftGenerateByOrderId = (orderId) => getRouteDraftGenerate('order', orderId);

export const getRouteOrderCheckout = (draftId) => generatePath(ROUTE_DRAFT_CHECKOUT, { draftId });

export const getRouteDraftView = (draftId) => generatePath(ROUTE_DRAFT_VIEW, { draftId });
