//

import Loader from 'modules/v2/common/components/Loader';

import S from './styles';

const RedirectWait = ({ title }) => (
  <S.Content>
    {title}
    <Loader hasIcon={false} height="20px" />
    Please wait! while we redirect you to your Billing Info. If this takes more than a minute,
    refresh this page and try again. If the app times out please let our team know.
  </S.Content>
);

export default RedirectWait;
