import { useContext, useState } from 'react';

import { Typography, Space } from 'antd';
import { PlansContext } from 'modules/v2/pages/Plans/contexts/UpgradePlan';

import environments from 'environments';
import { hideLesserPlans } from 'modules/v2/pages/Plans/utils';

import {
  contractedColumnsMonthly,
  contractedColumnsYearly,
  contractedPlanData,
} from './contracted';
import {
  nonContractedColumnsMonthly,
  nonContractedColumnsYearly,
  nonContractedPlanData,
} from './non-contracted';
import S from '../../styles';

const { Text } = Typography;

const UpgradePlanModal = () => {
  const [isMonthlyToggled, setIsMonthlyToggled] = useState(true);
  const { toggleUpgradePlanModal, subscription, isContracted, isUpgradePlanModalOpened } =
    useContext(PlansContext);
  const userPlan = subscription?.component?.component_handle;
  const isYearly = userPlan?.includes('annual');
  const toggleSubscriptionTerm = (e) => {
    setIsMonthlyToggled(e.target.value === 'monthly');
  };

  const getWidth = () => {
    return window.innerWidth * 0.9;
  };

  const getTermPlanColumns = () => {
    const yearlyData = isContracted ? contractedColumnsYearly : nonContractedColumnsYearly;
    const monthlyData = isContracted ? contractedColumnsMonthly : nonContractedColumnsMonthly;
    return hideLesserPlans(yearlyData, monthlyData, userPlan, isYearly, isMonthlyToggled);
  };

  return (
    <>
      <S.UpgradePlanModal
        isOpen={isUpgradePlanModalOpened}
        title="UPGRADE YOUR PLAN"
        onCancel={toggleUpgradePlanModal}
        footer={null}
        width={getWidth()}
      >
        <S.MainSection>
          <S.Section>
            <Text type="secondary">
              Select plan that will help you grow or contact us, we will guide you better. <br />
              Call sales at {environments.SUPPORT_PHONE}
            </Text>
            <S.CenterContent>
              {!isYearly && (
                <Space direction="vertical">
                  <S.RadioGroup
                    defaultValue="monthly"
                    onChange={toggleSubscriptionTerm}
                    buttonStyle="solid"
                  >
                    <S.RadioButton value="monthly">Monthly</S.RadioButton>
                    <S.RadioButton value="annual">Annual</S.RadioButton>
                  </S.RadioGroup>
                  <Text type="secondary">
                    Save up to{' '}
                    <Text type="success" strong>
                      16% off
                    </Text>{' '}
                    by going annual
                  </Text>
                </Space>
              )}
            </S.CenterContent>
          </S.Section>
          <S.PlanTableSection>
            <S.UpgradePlanTable
              size="middle"
              columns={getTermPlanColumns()}
              dataSource={isContracted ? contractedPlanData : nonContractedPlanData}
              pagination={false}
              bordered={false}
              scroll={{ y: 300 }}
              height={600}
            />
          </S.PlanTableSection>
        </S.MainSection>
      </S.UpgradePlanModal>
    </>
  );
};

export default UpgradePlanModal;
