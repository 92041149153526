import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  align-items: flex-start;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  will-change: transform;
  align-items: center;
  width: 100%;
  margin-top: 64px;
  flex: 1;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const Footer = styled.div`
  width: 100%;
  background: ${color.ViewFooterBackground};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 2;
  flex-wrap: wrap;
`;

export default {
  Container,
  ContentWrapper,
  Footer,
  Wrapper,
};
