import { Modal, CustomFlowbiteTheme } from 'flowbite-react';
import { useEffect, useState } from 'react';
import Spinner from '../atoms/Spinner';

interface ProcessingModalProps {
  title: string;
  description?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  theme?: CustomFlowbiteTheme['modal'];
}

const defaultProps: Partial<ProcessingModalProps> = {
  description: '',
};

const customTheme: CustomFlowbiteTheme['modal'] = {
  content: {
    base: 'relative h-full w-fit w-[440px] md:h-auto',
  },
  footer: {
    base: 'flex space-x-2 rounded-b border-neutral-100 min-h-[91px] px-6 py-[13px]',
  },
};

const ProcessingModal = ({
  title,
  description,
  showModal,
  setShowModal,
  theme = customTheme,
}: ProcessingModalProps) => {
  const [descriptionText, setDescriptionText] = useState('Designing your email.');
  useEffect(() => {
    if (typeof description === 'string') {
      setDescriptionText(description);
      return;
    }

    let descriptionIndex = 0;
    const intervalId = setInterval(() => {
      setDescriptionText(description[descriptionIndex]);
      descriptionIndex = (descriptionIndex + 1) % description.length;
    }, 2000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [description]);

  return (
    <Modal theme={theme || customTheme} show={showModal} onClose={() => setShowModal(false)}>
      <Modal.Body>
        <div className="flex flex-col justify-center items-center h-[175px]">
          <Spinner className="mb-5" />
          <p className="font-bold text-md mb-4 font-[figtree]">{title}</p>
          {descriptionText && (
            <p className="text-sm text-neutral-500 font-[figtree]">{descriptionText}</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

ProcessingModal.defaultProps = defaultProps;

export default ProcessingModal;
