import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';

const FooterContainer = styled.div`
  height: 120px;
  background: ${color.Black};
  color: ${color.White};
  width: 100%;
`;

const FooterWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 25px;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto;
  font-weight: 200;

  a {
    color: ${color.White};
  }

  @media ${device.tabletS} {
    width: 100%;
    font-size: 12px;
  }
`;

export default {
  FooterContainer,
  FooterWrapper,
};
