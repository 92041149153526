import {
  GET_COUNTRIES,
  GET_STATES_BY_COUNTRIES,
  GET_USER_CREDITS,
  GET_USER_ADDRESSES,
  ADD_USER_ADDRESS,
  EDIT_USER_ADDRESS,
  DELETE_USER_ADDRESS,
} from 'modules/publish/store/constants';

export const getCountries = () => ({
  type: GET_COUNTRIES,
  payload: {},
});

export const getStatesByCountries = (countries) => ({
  type: GET_STATES_BY_COUNTRIES,
  payload: {
    countries,
  },
});

export const getUserCredits = () => ({
  type: GET_USER_CREDITS,
});

export const getUserAddresses = () => ({
  type: GET_USER_ADDRESSES,
});

export const addUserAddresses = ({
  firstName,
  lastName,
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  pincode,
}) => ({
  type: ADD_USER_ADDRESS,
  payload: {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    pincode,
  },
});

export const editUserAddresses = ({
  _id,
  firstName,
  lastName,
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  pincode,
}) => ({
  type: EDIT_USER_ADDRESS,
  payload: {
    _id,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    pincode,
  },
});

export const deleteUserAddresses = (addressId) => ({
  type: DELETE_USER_ADDRESS,
  payload: {
    addressId,
  },
});
