
import { Spinner } from 'modules/v2/common/AtomicDesign/atoms';
import S from './styles';

const Loader = ({
  title = 'Loading Data',
  description = 'Please wait...',
  height,
}) => (
  <S.Wrapper height={height}>
    <div className="flex flex-col justify-center items-center h-[175px]">
      <Spinner className="mb-5" />
      <p className="font-bold text-md mb-4 font-[figtree]">{title}</p>
      {description && (
        <p className="text-sm text-neutral-500 font-[figtree]">{description}</p>
      )}
    </div>
  </S.Wrapper>
);

Loader.defaultProps = {
  hasIcon: true,
  height: '100vh',
};

export default Loader;
