const MagazineIcon = ({ active }) => {
  const notActive = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M3.33325 5.50033V16.3337C3.33325 17.7144 4.45254 18.8337 5.83325 18.8337H14.1666C15.5473 18.8337 16.6666 17.7144 16.6666 16.3337V8.00033C16.6666 6.61961 15.5473 5.50033 14.1666 5.50033H3.33325ZM3.33325 5.50033V4.66699" stroke="#757575" strokeWidth="1.5"/>
    <path d="M14.9999 5.49953V6.24953H15.7499V5.49953H14.9999ZM13.0976 2.43796L12.9915 1.6955L13.0976 2.43796ZM4.09958 3.72339L3.99351 2.98092H3.99351L4.09958 3.72339ZM4.22581 6.24953H14.9999V4.74953H4.22581V6.24953ZM15.7499 5.49953V4.08788H14.2499V5.49953H15.7499ZM12.9915 1.6955L3.99351 2.98092L4.20565 4.46585L13.2036 3.18042L12.9915 1.6955ZM3.99351 2.98092C3.18431 3.09653 2.58325 3.78955 2.58325 4.60697H4.08325C4.08325 4.53603 4.13542 4.47588 4.20565 4.46585L3.99351 2.98092ZM15.7499 4.08788C15.7499 2.61722 14.4474 1.48752 12.9915 1.6955L13.2036 3.18042C13.7558 3.10153 14.2499 3.53004 14.2499 4.08788H15.7499ZM4.22581 4.74953C4.14708 4.74953 4.08325 4.6857 4.08325 4.60697H2.58325C2.58325 5.51413 3.31865 6.24953 4.22581 6.24953V4.74953Z" fill="#757575"/>
    <path d="M6.66675 10.5H13.3334" stroke="#757575" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M6.66675 13.417H11.2501" stroke="#757575" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>;

  const isActive = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33325 4.66716V16.3338C3.33325 17.7145 4.45254 18.8338 5.83325 18.8338H14.1666C15.5473 18.8338 16.6666 17.7145 16.6666 16.3338V8.00049C16.6666 6.61978 15.5473 5.50049 14.1666 5.50049H4.16659C3.70635 5.50049 3.33325 5.12739 3.33325 4.66716ZM6.04159 10.5005C6.04159 10.1553 6.32141 9.87549 6.66659 9.87549H13.3333C13.6784 9.87549 13.9583 10.1553 13.9583 10.5005C13.9583 10.8457 13.6784 11.1255 13.3333 11.1255H6.66659C6.32141 11.1255 6.04159 10.8457 6.04159 10.5005ZM6.04159 13.4172C6.04159 13.072 6.32141 12.7922 6.66659 12.7922H11.2499C11.5951 12.7922 11.8749 13.072 11.8749 13.4172C11.8749 13.7623 11.5951 14.0422 11.2499 14.0422H6.66659C6.32141 14.0422 6.04159 13.7623 6.04159 13.4172Z" fill="#3C3C3C"/>
    <path d="M3.67391 3.9064C3.96431 4.03664 4.16659 4.32828 4.16659 4.66716H14.1666C14.4543 4.66716 14.7336 4.70362 14.9999 4.77217V4.08885C14.9999 3.07461 14.1016 2.2955 13.0975 2.43894L4.09958 3.72436C3.93919 3.74728 3.79384 3.81194 3.67391 3.9064Z" fill="#3C3C3C"/>
  </svg>;

  return active ? isActive : notActive;
};

export default MagazineIcon;
