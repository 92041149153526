export const PLACE_ORDER = 'place_order';
export const PLACE_ORDER_WAIT = 'place_order_wait';
export const ADDON = 'addon';
export const ADDON_WAIT = 'addon_wait';
export const SCHEDULE_COACHING = 'schedule_coaching';
export const SCHEDULE_COACHING_WAIT = 'schedule_coaching_wait';
export const TRAINING_WEBINAR = 'training_webinar';
export const TRAINING_WEBINAR_WAIT = 'training_webinar_wait';
export const BOOK_DETAILS = 'book_details';
export const BOOK_DETAILS_WAIT = 'book_details_wait';
export const YOUR_BOOK = 'your_book';
export const DENTIST_ORDER_DATA = 'dentistOrderData';

export const availableSteps = {
  place_order: 'Place Order',
  schedule_coaching: 'Schedule Coaching',
  training_webinar: 'Training Webinar',
  book_details: 'Book Details',
  your_book: 'Your Book',
};
