import { useEffect, useState } from 'react';
import { get, noop } from 'lodash-es';

import { isSucceeded } from 'store/status';
import { Modal } from 'modules/common/components';

import { ViewRenderer } from './components';
import { modalWidth, views } from './constants';

import S from './styles';

const GenerateDigitalBookModal = ({
  isOpen,
  preflight,
  title,
  onCancel = noop,
  onClose = noop,
  onEditCover = noop,
  onEditSection = noop,
  onOpen = noop,
}) => {
  const [activeView, setActiveView] = useState(views.CHECKING_PREFLIGHT);
  const displayConfirmButton = activeView === views.GENERATING_LINKS;

  useEffect(() => {
    if (isOpen) {
      setActiveView(views.CHECKING_PREFLIGHT);
      onOpen();
    } else {
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const isPreflightSucceeded = isSucceeded(preflight.status);
    const checkFinalPass = get(preflight.data, 'finalCheckPass', false);

    if (isPreflightSucceeded && checkFinalPass) {
      setActiveView(views.GENERATING_LINKS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preflight.status, preflight.data, activeView]);

  const handleCancel = () => {
    // Should cancel the whole generation if the preflight check is dismissed
    if (activeView === views.CHECKING_PREFLIGHT) {
      onCancel();
    }

    // Should just close if the confirmation modal is opened
    if (activeView === views.GENERATING_LINKS) {
      onClose();
    }
  };

  const footer = displayConfirmButton && (
    <Modal.Button key="submit" type="primary" onClick={onClose}>
      Confirm
    </Modal.Button>
  );

  return (
    <Modal
      footer={footer}
      title={title}
      width={modalWidth[activeView]}
      isOpen={isOpen}
      onCancel={handleCancel}
    >
      <S.Wrapper>
        <ViewRenderer
          activeView={activeView}
          preflight={preflight}
          onEditCover={onEditCover}
          onEditSection={onEditSection}
        />
      </S.Wrapper>
    </Modal>
  );
};

export default GenerateDigitalBookModal;
