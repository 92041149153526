import getInstance from './instance';
import { buildOrder } from './utils';

const instance = getInstance();

export const generateOrder = ({ draftId, selectedAddressId, numberOfPrints, isDigital }) => {
  const url = '/order';

  const body = buildOrder({
    draftId,
    isDigital,
    numberOfPrints,
    selectedAddressId,
  });

  return instance.post(url, body);
};

export const regenerateOrder = ({
  draftId,
  isDigital,
  numberOfPrints,
  parentOrderId,
  selectedAddressId,
}) => {
  const url = '/order';
  const body = buildOrder({
    draftId,
    isDigital,
    numberOfPrints,
    parentOrderId,
    selectedAddressId,
  });

  return instance.post(url, body);
};

export const fetchOrderById = ({ orderId }) => {
  const url = '/order';
  const params = {
    order_id: orderId,
  };

  return instance.get(url, params);
};

export const fetchOrderList = () => {
  const url = '/order';
  return instance
    .get(url)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const fetchDraftListWithDigital = () => {
  const url = '/draft/completed-updated-list';
  return instance
    .get(url)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const updateOrderedDraft = ({ orderId }) => {
  const url = `/order/updateOrderedBook/${orderId}`;

  return instance.post(url);
};

export const updateOrderedPdf = ({ orderId }) => {
  const url = `/order/updateOrderedBook/${orderId}`;

  return instance.post(url);
};

export const generateDraftFromOrder = ({ orderId }) => {
  const url = `/order/${orderId}/generate-draft`;

  return instance.post(url);
};
