//

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { RichUtils } from 'draft-js';
import classnames from 'classnames';
import { map, noop } from 'lodash-es';

import { options } from 'modules/editor/components/ContentEditor/utils';

const List = ({ editor, onChange = noop }) => {
  const { listStyles } = options;

  const key = editor.getSelection().getStartKey();

  const blockType = editor.getCurrentContent().getBlockForKey(key).getType();

  const handleList = (list) => (e) => {
    e.preventDefault();
    const lists = ['ordered-list-item', 'unordered-list-item'];

    if (lists.indexOf(list) >= 0) {
      const state = RichUtils.toggleBlockType(editor, list);
      onChange(state);
    }
  };

  return map(listStyles, ({ icon, type, style }) => (
    <span
      key={type}
      className={classnames('normal', { active: type === blockType })}
      onClick={handleList(style)}
    >
      <LegacyIcon type={icon} />
    </span>
  ));
};

export default List;
