import { Spin } from 'antd';
import { getTimeSelectedZone } from 'modules/common/utils/timezone';

import S from './styles';

const TimeSlot = ({ children }) => <div>{children}</div>;

const TimeSlots = ({
  type,
  slots,
  selectedSlot,
  onSelectSlot,
  serverTimeZone,
  userTimeZone,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <S.LoaderContainer>
        <Spin />
      </S.LoaderContainer>
    );
  }

  if (!slots) {
    return null;
  }

  if (type === 'date') {
    return (
      <S.ScheduleCardsDateList>
        {slots.map((slot) => (
          <TimeSlot.Date
            key={slot}
            active={selectedSlot === slot}
            onClick={() => onSelectSlot(slot)}
            slot={getTimeSelectedZone(slot, userTimeZone, serverTimeZone)}
          />
        ))}
      </S.ScheduleCardsDateList>
    );
  }

  return (
    <S.ScheduleCardsTimeList data-cy="timeSlotsList">
      {slots.map((slot) => (
        <TimeSlot.Time
          key={slot}
          active={selectedSlot === slot}
          onClick={() => onSelectSlot(slot)}
          slot={slot}
        />
      ))}
    </S.ScheduleCardsTimeList>
  );
};

const Time = ({ active, onClick, slot }) => (
  <S.ScheduleCardItem active={active} onClick={onClick}>
    {slot}
  </S.ScheduleCardItem>
);

const Date = ({ active, onClick, slot }) => (
  <S.TimeDateCard active={active} onClick={onClick}>
    <S.TimeText active={active}>{slot.format('MMMM, DD (dddd)')}</S.TimeText>
    <S.DateText active={active}>{slot.format('h:mma')}</S.DateText>
  </S.TimeDateCard>
);

TimeSlot.Time = Time;
TimeSlot.Date = Date;

export default TimeSlots;
