/* eslint-disable react/no-danger */
import Dropzone from 'react-dropzone';
import { PlusIcon } from 'modules/v2/common/components/SvgIcon';
import { notification } from 'modules/v2/common/utils';

import { validateImage } from './validation';
import S from './styles';

const ImageUploader = ({
  onSelect,
  mimeTypes,
  imageType,
  noMargin = false,
  uploadText = 'Browse files',
}) => {
  const handleDrop = async (files) => {
    const [file] = files;

    validateImage(file, mimeTypes, imageType)
      .then(() => onSelect(file))
      .catch((error) => {
        notification.error({
          description: error.message,
        });
      });
  };

  let fileUploadDescriptions;
  switch (imageType) {
    case 'rm_profile':
      fileUploadDescriptions =
        'Works with any .PNG, .JPG, or .JPEG file from the web </br> Recommended size: 520 x 520';
      break;
    case 'brokerageLogo':
      fileUploadDescriptions =
        'Works with any .PNG, .JPG, or .JPEG file from the web </br> Recommended size: 540 x 192';
      break;
    default:
      fileUploadDescriptions = 'Drag/drop or upload your image here';
  }

  return (
    <S.DragDropWrapper
      noMinHeight={imageType === 'email-template-header'}
      noMargin={noMargin}
      showWhite={imageType === 'preview-book-image'}
    >
      <S.ResetModalHeightStyle />
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="flex flex-col w-full items-center justify-center">
                {imageType === 'email-template-header' ? null : (
                  <div className="w-10 h-10 mb-2 flex items-center justify-center">
                    <PlusIcon width={50} height={50} />
                  </div>
                )}
                <div className="action flex items-center gap-2">
                  <div className="flex items-center rounded-md text-primary-500 text-sm font-semibold">
                    {uploadText}
                  </div>
                  {imageType === 'email-template-header' ? null : (
                    <div className="text-neutral-600 text-sm font-semibold">or drag and drop</div>
                  )}
                </div>
                {imageType === 'email-template-header' ? null : (
                  <p
                    className="text-neutral-400 text-center text-xs font-medium"
                    dangerouslySetInnerHTML={{ __html: fileUploadDescriptions }}
                  />
                )}
              </div>
            </div>
          );
        }}
      </Dropzone>
    </S.DragDropWrapper>
  );
};

export default ImageUploader;
