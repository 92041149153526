//

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { CaretDownOutlined } from '@ant-design/icons';
import { map, noop } from 'lodash-es';

import { options } from 'modules/editor/components/ContentEditor/utils';
import { styles } from 'modules/common/utils';

import S from './styles';

const FontFamily = ({ defaultFontFamily, editor, onChange = noop }) => {
  const { icon, values } = options.fontFamily;

  const handleFontFamily = (fontFamily) => {
    let state = editor;
    state = styles.fontFamily.remove(state);

    if (defaultFontFamily !== fontFamily) {
      state = styles.fontFamily.add(state, fontFamily);
    }

    onChange(state);
  };

  const current = styles.fontFamily.current(editor);
  const selected = current || defaultFontFamily;

  return (
    <S.Select
      placeholder={<LegacyIcon type={icon} />}
      suffixIcon={<CaretDownOutlined />}
      onSelect={handleFontFamily}
      value={selected}
    >
      {map(values, (value) => (
        <S.Option key={value} value={value}>
          {value}
        </S.Option>
      ))}
    </S.Select>
  );
};

export default FontFamily;
