export const InfoIconNew = ({ width = 20, height = 20, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Iconer">
      <path id="Icon" d="M10.8889 13.5556H10V10H9.11111M10 6.44444H10.0089M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
);
