import { useQuery } from 'react-query';

import env from 'environments';

import { getGuidesOrders } from 'modules/api';
import { ConfirmButton } from 'modules/onboard/components/UIComponents';
import { OnboardTemplate } from 'modules/onboard/templates';
import { isDentistCustomer } from 'modules/dashboard/utils';

import S from './styles';

const CongratulationsOffer = ({ callback, label, disabled, loading, order }) => {
  const isDentist = isDentistCustomer();
  const currentOrderPage = 1;
  const perPage = 10;

  const { data: guidesOrders } = useQuery(
    ['getGuidesOrders', currentOrderPage, perPage],
    () => getGuidesOrders(currentOrderPage, perPage),
    {
      enabled: isDentist,
    },
  );

  const packetOrder = isDentist ? guidesOrders?.data[0] : order;

  return (
    <OnboardTemplate hideNavigation isStepsVisible={false}>
      <S.Container>
        <S.Icon />
        <div>
          <S.Title>Thank you for using Authorify. We’re on it!</S.Title>
          <S.Description>
            We’ve received your order and we’ll notify you when we send it.
          </S.Description>
          <S.Description>
            Please note your order number below and allow 7-10 days for printing and shipping.
          </S.Description>
        </div>
        <S.OrderNumberContainer>
          <S.Description>Your order number is</S.Description>
          <S.Description>
            <b>{packetOrder}</b>
          </S.Description>
        </S.OrderNumberContainer>
        <div>
          <S.Description>Contact our support team at:</S.Description>
        </div>
        <div>
          <S.Description>{env.SUPPORT_PHONE}</S.Description>
          <S.Description>{env.SUPPORT_EMAIL}</S.Description>
        </div>
        <S.ButtonContainer>
          <ConfirmButton
            onClick={callback}
            size="large"
            block
            disabled={disabled}
            loading={loading}
          >
            <S.ButtonText>{label ?? 'View my ordered books'}</S.ButtonText>
          </ConfirmButton>
        </S.ButtonContainer>
      </S.Container>
    </OnboardTemplate>
  );
};

export default CongratulationsOffer;
