import { useParams } from 'react-router-dom';
import { DashboardTemplate } from 'modules/v2/templates';
import { DentistSideNav } from 'modules/v2/components';
import {
  PrimaryButton,
  PrimaryBlackButton,
  SecondaryBlackButton,
  Modal,
  Loader,
} from 'modules/v2/common/components';
import { notification } from 'modules/v2/common/utils';
import {
  isGuideOrPacket,
  bookCreditsContentTitle,
  bookCreditsContentDescription,
  firstLetterCapital,
} from 'modules/v2/pages/Dentist/OrderGuideModal/constants';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  fetchDraftLinks,
  fetchPreFlightCheck,
  generateDentistDraftLinks,
  generateDraftFromBook,
  getGuideDetails,
  getGuidesOrders,
  fetchUserCredits,
  getGuideOnboardData,
} from 'modules/api';
import { goToMyOrders } from 'modules/v2/draft/store';
import { PaymentSteps } from 'modules/v2/pages/PaymentSteps';
import S from './styles';
import { Guide, GuideType } from '../GuideCatalog/types';
import OrderGuideModal from '../OrderGuideModal';

const GuideViewer = () => {
  const { id: guideId } = useParams();
  const [draftId, setDraftId] = useState(null);
  const [getLinksLoopingActive, setGetLinksLoopingActive] = useState(false);
  const [guideDetails, setGuideDetails] = useState<Guide>({
    guideName: '',
    pdfUrl: '',
    thumbnail: '',
    type: '',
    guideId: '',
  });

  const [payloadData, setPayloadData] = useState({
    practiceName: '',
    practicePhone: '',
    practiceEmail: '',
    practiceLogo: '',
    practiceWebsite: '',
    quantity: 0,
    guideName: '',
    guideId: '',
    thumbnail: '',
    landingPage: '',
    frontCover: [],
    practiceAddress: {
      addressLine1: '',
      city: '',
      state: '',
      pincode: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    shippingAddress: {
      addressLine1: '',
      city: '',
      state: '',
      pincode: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  const [websiteBookLink, setWebsiteBookLink] = useState('');
  const [isGuideDataGenerated, setIsGuideDataGenerated] = useState(false);
  const [isSiteGenerated, setIsSiteGenerated] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [showPaymentSteps, setShowPaymentSteps] = useState(false);
  const [guidePacketName, setGuidePacketName] = useState('guide');

  // For getting latest ordered Data for pre filling Guide Forms
  const currentOrderPage = 1;
  const perPage = 1;
  const { data: guidesOrders } = useQuery(['getGuidesOrders', currentOrderPage, perPage], () =>
    getGuidesOrders(currentOrderPage, perPage),
  );
  const { data: guidesOnboardData } = useQuery('guidesOnboardData', getGuideOnboardData);

  const guidePrefillFormData = guidesOnboardData || guidesOrders?.data[0] || {};

  // getting Book Credits
  const { data: userBookCredit } = useQuery(['getUserCredits'], fetchUserCredits);
  const bookCredit = userBookCredit?.data?.data?.credits || 0;

  const { mutateAsync: preFlightCheckMutate, isLoading: isPreflightChecking } = useMutation(
    fetchPreFlightCheck,
  );

  const { mutateAsync: generateDraftLinksMutate, isLoading: isGeneratingDrafts } = useMutation(
    generateDentistDraftLinks,
  );

  const { mutateAsync: checkLinkOnce } = useMutation(fetchDraftLinks);
  const successGenerateLinks = async ({ data }) => {
    const response = data?.data || {};
    const { status, url } = response;
    if (status === 'failed') {
      setGetLinksLoopingActive(false);
      notification.error({
        description: 'There was an error generating your guide. Please try again later.',
      });
    }

    if (status === 'ready') {
      setGetLinksLoopingActive(false);
      const { site } = url || {};
      setWebsiteBookLink(site);
      setIsSiteGenerated(true);
    }
  };

  const { isLoading: isGettingGuideDetails } = useQuery(
    'getGuideDetails',
    () => getGuideDetails({ guideId }),
    {
      onSuccess: (data) => {
        setGuideDetails(data);
        setPayloadData({
          ...payloadData,
          guideName: data.guideName,
          thumbnail: data.thumbnail,
          guideId: data.guideId,
        });
        setIsGuideDataGenerated(true);
        setGuidePacketName(isGuideOrPacket(data.guideId));
      },
    },
  );

  useQuery(['getLinks', { draftId }], () => fetchDraftLinks({ draftId }), {
    enabled: getLinksLoopingActive,
    refetchInterval: 3000,
    onSuccess: successGenerateLinks,
  });

  const { isLoading: isFetchingDraft } = useQuery(
    'generateDraftFromBook',
    () => generateDraftFromBook({ bookId: guideId }),
    {
      onSuccess: async (draft) => {
        const generatedDraftId = draft?.data?.draftId;
        setDraftId(generatedDraftId);
        const { data } = await checkLinkOnce({ draftId: generatedDraftId });
        successGenerateLinks({ data });
      },
    },
  );

  const generateLandingPage = async () => {
    await preFlightCheckMutate({ draftId });
    await generateDraftLinksMutate({ draftId });

    setGetLinksLoopingActive(true);
  };

  const handlePublishDraft = () => {
    const isCreditLow = guidePacketName === 'guide' ? bookCredit < 10 : bookCredit < 30;
    setIsWarningModalOpen(isCreditLow);
    if (!isCreditLow) {
      setPublishModalOpen(true);
    }
  };

  const handleCancelPublish = () => {
    setPublishModalOpen(false);
  };

  const handleClosePublish = () => {
    setPublishModalOpen(false);
  };

  const handleShowstepWarningModal = () => {
    setShowPaymentSteps(true);
    setIsWarningModalOpen(false);
  };

  const handleCopy = (text: string) => {
    const temporaryInput = document.createElement('input');
    temporaryInput.value = text;
    document.body.appendChild(temporaryInput);
    temporaryInput.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      notification.error({
        description: error.message,
      });
      throw new Error(error.message);
    } finally {
      document.body.removeChild(temporaryInput);
    }
  };

  const visitWebsite = () => {
    window.open(websiteBookLink, '_blank');
  };

  const isGeneratingLandingPage =
    getLinksLoopingActive || isPreflightChecking || isGeneratingDrafts;

  const isPageLoading = isFetchingDraft || isGettingGuideDetails;

  if (isPageLoading) {
    return (
      <DashboardTemplate noPaddingLeft noPaddingRight>
        <S.Container>
          <Loader title="Loading Guide..." height="100%" />
        </S.Container>
      </DashboardTemplate>
    );
  }

  // @ts-ignore
  return (
    <DashboardTemplate sidebar={<DentistSideNav />} noPaddingLeft noPaddingRight>
      <S.Container>
        <S.ViewGuidesContainer>
          <S.IframeWrapper>
            {guideDetails.type === GuideType.packet ? (
              <S.ImageContainer>
                <S.Image key={guideDetails.guideId} src={guideDetails.thumbnail} />
              </S.ImageContainer>
            ) : (
              <afy-flip-book key="test" pdfurl={guideDetails.pdfUrl} />
            )}
          </S.IframeWrapper>
        </S.ViewGuidesContainer>
        <S.GuideTitle>
          Title: <span>{guideDetails.guideName}</span>
        </S.GuideTitle>
        <S.FooterContainer>
          <S.ButtonsContainer>
            <PrimaryButton onClick={handlePublishDraft} disabled={!isGuideDataGenerated}>
              Order {firstLetterCapital(guidePacketName)}
            </PrimaryButton>
            <PrimaryButton
              onClick={generateLandingPage}
              loading={isGeneratingLandingPage}
              disabled={isSiteGenerated}
            >
              Generate Website
            </PrimaryButton>
          </S.ButtonsContainer>
          {websiteBookLink && (
            <>
              <S.VerticalLine />
              <S.LandingPageContainer>
                <div>Landing Page</div>
                <S.FakeInput>
                  <S.LandingPageLink target="_blank" href={websiteBookLink}>
                    {websiteBookLink}
                  </S.LandingPageLink>
                  <PrimaryButton onClick={() => handleCopy(websiteBookLink)}>Copy</PrimaryButton>
                </S.FakeInput>
                <S.ViewLink onClick={visitWebsite}>
                  <p>View</p>
                </S.ViewLink>
              </S.LandingPageContainer>
            </>
          )}
        </S.FooterContainer>
      </S.Container>

      {publishModalOpen && (
        <OrderGuideModal
          isOpen
          draftId={draftId}
          title={`Title: ${guideDetails.guideName.toUpperCase()}`}
          onCancel={handleCancelPublish}
          onClose={handleClosePublish}
          setPayloadData={setPayloadData}
          payloadData={payloadData}
          onComplete={goToMyOrders}
          guidePrefillFormData={guidePrefillFormData}
          fromScreen="GuideViewer"
          guidePacketName={guidePacketName}
        />
      )}

      {/* Credits Check Modal */}
      <Modal
        isOpen={isWarningModalOpen}
        maskClosable
        title="Warning"
        width="auto"
        footer={[
          <S.ModalFooterContainer key={1}>
            <PrimaryBlackButton style={{ width: '80%' }} onClick={handleShowstepWarningModal}>
              Buy Credits
            </PrimaryBlackButton>
            <SecondaryBlackButton
              style={{ width: '80%' }}
              onClick={() => setIsWarningModalOpen(false)}
            >
              Cancel, I'll buy later
            </SecondaryBlackButton>
          </S.ModalFooterContainer>,
        ]}
      >
        <S.ContentContainer>
          <S.ContentTitle>
            You have <span>{bookCredit} credits</span> left
          </S.ContentTitle>
          <S.ContentDescription>{bookCreditsContentTitle(guidePacketName)}</S.ContentDescription>
          <S.ContentDescription>
            {bookCreditsContentDescription(guidePacketName)}
          </S.ContentDescription>
        </S.ContentContainer>
      </Modal>
      {showPaymentSteps && (
        <PaymentSteps
          setShowPaymentSteps={setShowPaymentSteps}
          showPaymentSteps={showPaymentSteps}
          type="guide"
        />
      )}
    </DashboardTemplate>
  );
};

export default GuideViewer;
