import { PrimaryBlackButton } from 'modules/v2/common/components';
import { useFormikContext } from 'formik';

const OkButton = () => {
  const { submitForm } = useFormikContext();
  return (
    <PrimaryBlackButton style={{ width: '80%' }} onClick={submitForm}>
      Add New Card
    </PrimaryBlackButton>
  );
};

export default OkButton;
