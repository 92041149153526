import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 20px 0 10px 0 !important;
`;

const Title = styled.h3`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default { HeaderContainer, Title, FilterContainer, ActionWrapper, LoaderContainer };
