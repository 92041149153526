import S from './styles';

const Item = ({ icon, title, onClick, to, href, children, ...extra }) => {
  const content = (
    <span className="w-full items-center flex">
      <span className="flex mr-[10px] justify-center items-center">{icon}</span>
      <span className="block w-full leading-none ">{title}</span>
    </span>
  );

  if (to) {
    return (
      <S.Link to={to} className="">
        {content}
      </S.Link>
    );
  }

  if (href) {
    return (
      <S.RawLink {...extra} href={href}>
        {content}
      </S.RawLink>
    );
  }

  return (
    <div className="w-full" onClick={onClick}>
      <span className="block">{content}</span>
      {children}
    </div>
  );
};

export default Item;
