import styled from 'styled-components';
import { EyeIcon, DownloadIconBadge } from 'modules/v2/common/components/SvgIcon';
import * as color from 'modules/common/theme/color';

const LeadCard = styled.div`
  border: 1px solid ${({ selected }) => (selected ? '#4CC9E1' : '#E9E9E9')};
  ${({ selected }) => (selected ? 'box-shadow: 0px 0px 0px 1px #4CC9E1;' : '')}
  padding: 14px 22px 14px 14px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  background: ${({ selected }) => (selected ? 'rgba(237, 250, 252, 0.40)' : '#fff')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #fafafa;
  }
`;

const CheckboxWrapper = styled.div`
  margin-right: 16px;
`;

const LeadInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LeadName = styled.span`
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
`;

const LeadEmail = styled.span`
  color: #555;
`;

const LeadPhone = styled.span`
  color: #555;

  input {
    border: none;
    background-color: transparent;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    pointer-events: none; /* Previne interação do usuário */
    width: 100%; /* Garante que o input ocupe o espaço necessário */
  }
`;

const LeadAddress = styled.span`
  color: #555;
`;

const LeadAddedSince = styled.span`
  color: #999;
`;

const ViewButton = styled.button`
  background: #fff;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  border-radius: 6px;
  padding: 8px;
  margin-top: 14px;
`;

const ViewIcon = styled(EyeIcon)`
  font-size: 20px;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const EmailPhoneText = styled.div`
  color: ${color.neutral600};
`;

const PinIconWrapper = styled.div`
  margin-left: 5px;
  color: ${color.orange400};
  fill: ${color.orange400} !important;
  background-color: none;
  background: none;
`;

const Badge = styled.div`
  display: inline-flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  background: #fff;
  color: #000;
  font-size: 12px;
`;

const BadgeContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;

const DownloadIcon = styled(DownloadIconBadge)`
  width: 12px;
  height: 12px;
  fill: #3ab886;
  margin-right: 4px;
`;

export default {
  LeadCard,
  LeadInfo,
  LeadName,
  LeadEmail,
  LeadPhone,
  LeadAddress,
  LeadAddedSince,
  ViewButton,
  ViewIcon,
  NameContainer,
  EmailPhoneText,
  PinIconWrapper,
  CheckboxWrapper,
  Badge,
  BadgeContainer,
  DownloadIcon,
};
