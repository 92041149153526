import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Icon } from '@ant-design/compatible';

const Item = styled.span`
  font-size: 14px;
  display: block;
`;

Item.Span = styled.span`
  padding: 21px 20px;

  :hover {
    border-bottom: 3px solid ${color.PrimaryColor};
    transition: 0.2s ease all;
  }
`;

Item.Icon = styled(Icon)`
  margin-right: 10px;
  font-size: 24px;
  position: relative;
  top: 4px;
`;

export default {
  Item,
};
