import styled from 'styled-components';
import { Button } from 'antd';

import * as color from 'modules/common/theme/color';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 196px;
  justify-content: space-between;
  border: 1px solid #efefef;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 24px;
  text-align: left;
  position: relative;

  ${({ isSelected }) => isSelected && 'border: 2px solid #1890ff;'}
`;

const AddressWrapper = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: ${color.GreySelectDd};
`;

const ButtonWrapper = styled.div`
  margin: 20px 0 0;
`;

const StyledButton = styled(Button)`
  font-size: 16px !important;
  margin-right: 15px;
`;

const ButtonLink = styled(Button)`
  color: ${color.PrimaryColor} !important;
  font-size: 16px;
  padding: 0 6px !important;
  margin-right: 15px;
  &:hover span {
    text-decoration: underline;
  }
`;

export default {
  ButtonWrapper,
  CardWrapper,
  AddressWrapper,
  Button: StyledButton,
  ButtonLink,
};
