import React from 'react';
import { Alert } from 'flowbite-react';
import classnames from 'classnames';

const alertCustomTheme = {
  base: 'flex flex-col gap-2 p-4 text-sm',
  borderAccent: 'border-t-4',
  closeButton: {
    base: '-m-1.5 ml-auto inline-flex h-8 w-8 rounded-lg p-1.5 focus:ring-2',
    icon: 'h-5 w-5',
    color: {
      info:
        'bg-cyan-100 text-cyan-500 hover:bg-cyan-200 focus:ring-cyan-400 dark:bg-cyan-200 dark:text-cyan-600 dark:hover:bg-cyan-300',
      failure:
        'bg-red-100 text-red-500 hover:bg-red-200 focus:ring-red-400 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300',
      success:
        'bg-green-100 text-green-500 hover:bg-green-200 focus:ring-green-400 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300',
      warning:
        'bg-yellow-100 text-yellow-500 hover:bg-yellow-200 focus:ring-yellow-400 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300',
    },
  },
  color: {
    info: 'border-cyan-500 bg-cyan-100 text-cyan-700 dark:bg-cyan-200 dark:text-cyan-800',
    error: 'bg-orange-50 text-orange-600 font-semibold dark:bg-orange-200 dark:text-orange-800',
    failure: 'border-red-500 bg-red-100 text-red-700 dark:bg-red-200 dark:text-red-800',
    success: 'border-green-500 bg-green-100 text-success-500 dark:bg-green-200 dark:text-green-800',
    warning:
      'border-yellow-500 bg-yellow-100 text-yellow-700 dark:bg-yellow-200 dark:text-yellow-800',
  },
  icon: 'mr-3 inline h-5 w-5 flex-shrink-0',
  rounded: 'rounded-lg',
  wrapper: 'flex items-center',
};

type WarnMessageProps = {
  type?: 'sucess' | 'error' | 'warning' | 'info';
  children: React.ReactNode;
  className?: string;
  color: string;
};

const defaultProps: Partial<WarnMessageProps> = {
  type: 'sucess',
  className: '',
};

const WarnMessage = ({ children, className, type = 'info', ...props }: WarnMessageProps) => {
  return (
    <Alert
      color={type}
      theme={alertCustomTheme}
      className={classnames('mb-2 font-semibold', className)}
      {...props}
    >
      {children}
    </Alert>
  );
};

WarnMessage.defaultProps = defaultProps;

export default WarnMessage;
