import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import env from 'environments';

import { getItem } from 'modules/dashboard/utils/legacy';

export const useWaitPayment = ({ onSuccess, onError }) => {
  const [isWaiting, setIsWaiting] = useState(false);
  const waitPayment = () => setIsWaiting(true);

  useEffect(() => {
    if (!isWaiting) return;

    const socket = io(env.SOCKET_IO, { transports: ['websocket'], withCredentials: true });
    const subscription = JSON.parse(getItem('customerAndSubscriptionId'));
    const { customer, id } = subscription || {};
    const paymentEvent = `payment_${customer?.email}_${id}`;

    socket.on(paymentEvent, ({ isApproved }) => {
      if (isApproved) {
        setIsWaiting(false);
        return onSuccess({ isApproved, customer });
      }

      return onError({ isApproved });
    });

    return () => {
      socket.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaiting]);

  return { waitPayment, isWaiting };
};
