import styled from 'styled-components';
import { Input as BaseInput } from 'antd';
import { Form as BaseForm } from 'formik';
import * as color from 'modules/common/theme/color';
import { TickedIcon } from 'modules/onboard/components/UIComponents';
import { device } from 'modules/common/theme/mediaqueries';
import { PrimaryButton } from 'modules/common/components';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Container = styled.div`
  background-color: ${color.White};
  th {
    font-weight: bold;
  }
  tr {
    border: 1px solid ${color.LightDullGray};
  }
`;

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.h3`
  font-size: 24px !important;
  line-height: 46px !important;
  color: ${color.Black};
  margin-top: 10px !important;
  font-weight: bold !important;
  padding-bottom: 15px !important;
`;

const Form = styled(BaseForm)`
  display: block;
`;

const inputStyle = `
  height: 45px;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  height: 45px;
  width: 400px;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: ${color.GreySelectDd};

  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px ${color.FormBoxShadowColor};
  }
  &:disabled{
    border: 1px solid ##eeecec;
  }

`;

const inputEmailStyle = `

width: 470px;
height: 40px;
background: ${color.White} 0% 0% no-repeat padding-box;
border: 1px solid ${color.Black};
border-radius: 2px;
opacity: 1;
`;

const Input = styled(BaseInput)`
  ${inputStyle}
`;

const InputEmail = styled(BaseInput)`
  ${inputEmailStyle}
`;

const SecondaryButton = styled(PrimaryButton)`
  background-color: ${color.SkyBlue} !important;
  margin: 10px 0 !important;

  &:hover,
  &:active,
  &:focus {
    background-color: ${color.SideBarActiveText} !important;
  }

  :disabled {
    background: ${color.ViewBlueDisabledButton} 0% 0% no-repeat padding-box !important;

    &:active,
    &:focus {
      background-color: ${color.ViewBlueDisabledButtonFocus} !important;
    }
  }
`;

const PasswordRequirements = styled.span`
  width: 166px;
  height: 79px;

  text-align: left;
  font: normal normal normal 14px/20px Nunito;
  letter-spacing: 0px;
  color: ${color.GreyFormBorder};
  opacity: 1;
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
`;

const FieldGroupRight = styled.div`
  display: flex;
  flex: 1;
  float: right;
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  font-weight: 600 !important;
`;

const FieldControlLeft = styled.div`
  flex: 1;
  flex-direction: column;
  padding-left: 20px !important;
  font-weight: 600 !important;
`;

const InfoText = styled.span`
  font-weight: 400;
  color: ${color.Body};
  padding-top: 15px;
`;

const PopupWrapper = styled.div`
  top: 241px;
  left: 383px;
  width: 600px;
  height: 240px;
  background: ${color.White} 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 6px ${color.Black}59;
  border-radius: 2px;
  opacity: 1;
`;

const PopupHeading = styled.div`
  background: var(--unnamed-color-980f20) 0% 0% no-repeat padding-box;
  background: ${color.PrimaryColor} 0% 0% no-repeat padding-box;
  border-radius: 2px 2px 0px 0px;
  opacity: 1;
`;

const AvailableAnymore = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: ${color.Black};
  margin-bottom: 3px !important;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
`;

const ListText = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${color.listTextGreyed};
`;

const ListTextError = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${color.ErrorWarning};
`;

const CheckIcon = styled(TickedIcon)`
  font-size: 22px;
  position: relative;
  right: 6px;

  @media ${device.tabletS} {
    right: 1px;
  }
`;

const Label2 = styled.span`
  text-align: left;
  letter-spacing: 0px;
  font-weight: bold;
  color: ${color.Black};
  opacity: 1;
`;
const Label = styled.span`
  text-align: left;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
`;
const List = styled.div`
  padding-top: 20px;
  margin-bottom: 30px;
`;

const Subheading = styled.p`
  margin-top: 34px !important;
`;

const Content = styled.div`
  padding: 30px;
  font-size: 18px;
  text-align: center;
`;

const Error = styled.p`
  color: #f27575;
`;

const CheckOutlinedIcon = styled(CheckOutlined)`
  color: ${color.SkyBlue};
  opacity: 1;
`;

const CloseOutlinedIcon = styled(CloseOutlined)`
  color: ${color.ErrorWarning};
  opacity: 1;
`;

const EyeIcon = styled.span`
  position: absolute;
  margin: -29px 0px 0px 362px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default {
  EyeIcon,
  CheckOutlinedIcon,
  CloseOutlinedIcon,
  Container,
  MainContain,
  Title,
  FieldGroup,
  FieldControl,
  Form,
  InfoText,
  Input,
  InputEmail,
  PasswordRequirements,
  PopupWrapper,
  PopupHeading,
  List,
  ListItem,
  ListText,
  AvailableAnymore,
  CheckIcon,
  Label,
  Label2,
  Content,
  FieldGroupRight,
  Subheading,
  SecondaryButton,
  FieldControlLeft,
  Error,
  ListTextError,
  ButtonWrapper,
};
