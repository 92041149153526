import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export default {
  Container,
  Label,
};
