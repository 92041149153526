function SuccessToastIcon({
  width = 14,
  height = 14,
  stroke = '#FAFAFA',
  strokeWidth = '2',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M2.80006 7.7L5.2445 10.5L11.3556 3.5"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SuccessToastIcon;
