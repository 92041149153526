export const WindowFrameIcon = ({ width = 20, height = 20, fill = '#FF8A33' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconer">
      <g clipPath="url(#clip0_3337_53052)">
        <path
          id="Vector"
          d="M1.6665 10.0003C1.6665 6.07195 1.6665 4.10777 2.88689 2.88738C4.10728 1.66699 6.07147 1.66699 9.99984 1.66699C13.9282 1.66699 15.8924 1.66699 17.1128 2.88738C18.3332 4.10777 18.3332 6.07195 18.3332 10.0003C18.3332 13.9287 18.3332 15.8929 17.1128 17.1133C15.8924 18.3337 13.9282 18.3337 9.99984 18.3337C6.07147 18.3337 4.10728 18.3337 2.88689 17.1133C1.6665 15.8929 1.6665 13.9287 1.6665 10.0003Z"
          stroke={fill}
          strokeWidth="1.5"
        />
        <g id="Vector_2">
          <path
            d="M5.83317 5.00033C5.83317 5.46056 5.46007 5.83366 4.99984 5.83366C4.5396 5.83366 4.1665 5.46056 4.1665 5.00033C4.1665 4.54009 4.5396 4.16699 4.99984 4.16699C5.46007 4.16699 5.83317 4.54009 5.83317 5.00033Z"
            fill={fill}
          />
          <path
            d="M8.33317 5.00033C8.33317 5.46056 7.96007 5.83366 7.49984 5.83366C7.0396 5.83366 6.6665 5.46056 6.6665 5.00033C6.6665 4.54009 7.0396 4.16699 7.49984 4.16699C7.96007 4.16699 8.33317 4.54009 8.33317 5.00033Z"
            fill={fill}
          />
          <path
            d="M10.8332 5.00033C10.8332 5.46056 10.4601 5.83366 9.99984 5.83366C9.5396 5.83366 9.1665 5.46056 9.1665 5.00033C9.1665 4.54009 9.5396 4.16699 9.99984 4.16699C10.4601 4.16699 10.8332 4.54009 10.8332 5.00033Z"
            fill={fill}
          />
        </g>
        <path
          id="Vector_3"
          d="M1.6665 7.91699H18.3332"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Vector_4"
          d="M7.5 17.5L7.5 8.33333"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3337_53052">
        <rect width="20" height="20" rx="5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
