//

import S from './styles';

const Item = ({ title, onClick, to }) => {
  if (to) {
    return <S.Link to={to}>{title}</S.Link>;
  }

  return <S.NonLink onClick={onClick}>{title}</S.NonLink>;
};

export default Item;
