export const PlansSkeleton = ({ quantity = 3, ...props }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-1">
      {
        [...Array(quantity)].map((_) => {
          return <div role="status" className="w-full animate-pulse p-6" style={{boxShadow: '0px 2px 4px -2px #191B1F0D, 0px 4px 8px -2px #191B1F1A' }}>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            <span className="sr-only">Loading...</span>
          </div>
        })
      }
    </div>
  )
}
