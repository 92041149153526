import styled, { createGlobalStyle } from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const DragDropWrapper = styled.div`
  border: 1px dotted ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: #a29a9a;
  margin: ${({ noMargin }) => (noMargin ? '0' : '20px')};
  font-weight: normal;
  font-size: 16px;
  min-height: 210px;
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-height: 70vh !important;
  max-width: 90vw;
  overflow: auto;
  position: relative;

  canvas {
    width: 400px !important;
    height: 400px !important;
  }

  > div {
    width: 100%;
    outline: 0;
    cursor: pointer;
  }

  svg {
    fill: #9c9a9a;
    width: 75px;
    height: 45px;
    margin: 0 auto;
    display: block;
  }
`;

const ImageGrid = styled.div`
   {
    position: relative;
    height: ${({ height }) => height || '100%'};
    display: contents;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      left: 28px;
      top: 25px;
      height: ${({ height }) => height || '100%'};
      width: 90%;
      z-index: 50;
      background-size: 105px 112px;
      background-image: linear-gradient(to right, #efefef 1px, transparent 1px),
        linear-gradient(to bottom, #efefef 1px, transparent 1px);
    }
  }
`;

const UploadText = styled.div`
  color: ${color.GreySelectDd};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  padding: 5px 0;
`;
const ModalWrapper = styled.div`
  padding: 2rem;
  background: ${color.White};
  max-height: 500px !important;
  overflow: scroll;
  text-align: center;
`;

const BtnWrapper = styled.div`
  float: right;
`;

const Btn = styled.button`
  margin-right: 20px;
  margin-bottom: 10px;
`;

const InputRange = styled.input`
  width: 100%;
  margin: 12px 0;

  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(
      to right,
      ${color.primary500},
      ${color.primary500} var(--value),
      ${color.neutral300} var(--value),
      ${color.neutral300}
    );
  }

  &::-webkit-slider-thumb {
    border: 2px solid ${color.neutral300} !important;
    background: ${color.White} !important;
    margin-top: -8px;
  }

  ${({ value }) => `
  --value: ${((value - 1) / 3) * 100}%;
`}
`;

const ResetModalHeightStyle = createGlobalStyle`
  .ant-modal-body {
    max-height: 100% !important;
  }
`;

export default {
  DragDropWrapper,
  UploadText,
  ModalWrapper,
  BtnWrapper,
  Btn,
  InputRange,
  ResetModalHeightStyle,
  ImageGrid,
};
