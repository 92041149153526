import { getInstance } from './instance';

const instance = getInstance();

export const createUploads = async ({ payload, callbackTargetKey, callbackPayload }) => {
  try {
    const { data: createUpload = {} } = await instance.post('/uploads', payload);
    const callbackLink = createUpload[callbackTargetKey] || '';
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': payload.contentType,
      },
      body: callbackPayload,
    };
    const response = await fetch(callbackLink, requestOptions);
    if (response.status === 200) {
      return createUpload.downloadUrl;
    }
    return false;
  } catch (error) {
    return error;
  }
};

export const getUploadedImages = () => {
  return instance
    .get('/uploads')
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
