import gql from 'graphql-tag';

export default gql(`
query {

        categories: categories{
            _id,
            name,
       
        }
    }
    `);
