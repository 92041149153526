/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { isEmpty } from 'lodash';
import { cn } from '../../utils/cn';
import { PlayIcon } from '../SvgIcon';

const CardWithVideo = ({
  imageSource = '',
  title = 'Card title',
  content = 'Card content',
  className = '',
  iconClick = () => {},
}) => {
  return (
    <div
      className={cn(
        'shadow-box rounded-lg flex flex-col md:flex-row w-full gap-4 p-6 overflow-hidden bg-white',
        className,
      )}
    >
      <button onClick={iconClick} className="flex justify-center">
        <div
          className={cn(
            'rounded-lg sm:w-[276px] sm:h-[156px] flex items-center justify-center relative',
            {
              hidden: isEmpty(imageSource),
            },
          )}
        >
          <img src={imageSource} alt={title} width="100%" height="100%" className="rounded-xl" />
          <div className="absolute top-1/2 -translate-y-1/2 cursor-pointer">
            <PlayIcon width={52} height={52} />
          </div>
        </div>
      </button>
      <div>
        <button onClick={iconClick}>
          <h2 className="text-neutral-800 text-base font-medium pb-0 mb-0">{title}</h2>
        </button>
        <p className="font-normal text-cardTextColor leading-[23px]">{content}</p>
      </div>
    </div>
  );
};

export default CardWithVideo;
