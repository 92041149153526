import { Card, Col, Row, Typography } from 'antd';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import S from './styles';
import { PlusPlansContext } from '../context';
import { getRoutePlusPlansContractBinding } from '../../routes/navigation';

const { Title, Text } = Typography;

const PaymentConfirmation = () => {
  const history = useHistory();
  const { newPlanName } = useContext(PlusPlansContext);
  const {
    planType,
  }: {
    planType: string | undefined;
  } = useParams();
  const continueToContractSigning = () => {
    history.push(getRoutePlusPlansContractBinding(planType));
  };

  return (
    <Card>
      <Row>
        <Col span={24}>
          <S.MainSection>
            <S.Section>
              <S.CheckIcon />
            </S.Section>
            <S.Section>
              <Title level={4}>Congratulations! You’re now a {newPlanName} Member</Title>
            </S.Section>
            <S.Section>
              <Text>
                Thank you for using Authorify. You now have access to {newPlanName} Member features
                and services. <br /> We're excited to get your Google Business Listing, <br /> Easy
                Close Presentation, printed books and everything else setup for you!{' '}
              </Text>
            </S.Section>
            <S.Section>
              <Text>Continue to contract signing and schedule a call for us to help you setup</Text>
            </S.Section>

            <S.Section>
              <S.OkButton onClick={continueToContractSigning}>
                Continue to Contract Signing
              </S.OkButton>
            </S.Section>
          </S.MainSection>
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentConfirmation;
