import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { getSession, setSession, STEPS } from 'modules/onboard/utils';
import { getStep } from 'modules/api';

import { useStep } from './useSession';

export const useWait = () => {
  const { offerCode } = useParams();
  const [pooling, setPooling] = useState(true);
  const { goToNextStep } = useStep();
  const session = getSession(offerCode);
  const { offer, id, step } = session;
  const { order } = STEPS[step];

  useQuery('pooling', () => getStep(offer.code, id), {
    enabled: !!pooling,
    onSuccess: (result) => {
      if (step !== result.data) {
        setPooling(false);
        const newSession = Object.assign(session, { step: result.data });
        setSession(newSession);
        goToNextStep(result.data);
      }
    },
    refetchInterval: 3000,
  });

  return { order };
};
