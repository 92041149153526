//

import { map } from 'lodash-es';

import { NavBar } from 'modules/v2/nav/components';
import { TYPE_MANUSCRIPT } from 'modules/v2/editor/utils';

import EditorGrid from '../EditorGrid';

const { Dropdown } = NavBar;
const { Item } = NavBar.Menu;

const EditorDropdown = ({ title, items }) => {
  const content = map(items, ({ _id, displayName, to, type, onClick, children }) => (
    <Item key={_id} title={displayName} to={to} onClick={onClick}>
      {type === TYPE_MANUSCRIPT && children && <EditorGrid items={children} />}
    </Item>
  ));

  return <Dropdown title={title}>{content}</Dropdown>;
};

export default EditorDropdown;
