import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';
import { Select as BaseSelect } from 'antd';

const Container = styled.div`
  max-width: 1124px;
  margin-left: auto;
  margin-right: auto;
`;

const Credits = styled.span`
  font-family: ${font.NunitoFont};
  font-size: 18px;
  font-weight: 700;
  margin-right: 16px;
  line-height: 30px;
  color: ${({ isCreditEnough }) => (isCreditEnough ? color.Body : color.DangerRed)};
`;

const Select = styled(BaseSelect)`
  width: 200px;
`;

const Loading = styled.span`
  font-family: ${font.NunitoFont};
  font-size: 16px;
  margin: 0;
  line-height: 30px;
  color: ${color.GreyText};
`;

const SubHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  span {
    font-size: 16px;
  }
`;

const SubHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CreditsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 16px;
`;

const MainWrapper = styled.div`
  padding: 24px 0 24px 0;
`;

const BookWrapper = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 212px);
  gap: 15px 16px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  overflow: hidden;
`;
const ButtonWrapper = styled.div`
  margin: 20px;
  margin-left: 0 !important;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 640px;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContentTitle = styled.p`
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-right: 2px;

  span {
    color: ${color.DangerRed};
  }
`;

const ContentDescription = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

export default {
  Container,
  Credits,
  Loading,
  Select,
  SubHeader,
  MainWrapper,
  BookWrapper,
  ButtonWrapper,
  SubHeaderWrapper,
  CreditsWrapper,
  ModalFooterContainer,
  ContentContainer,
  ContentTitle,
  ContentDescription,
};
