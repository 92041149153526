import { useState } from 'react';
import { Radio } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import S from './styles';
import CardItem from '../components/CardItem';

export const SelectPayment = ({
  getStep,
  paymentValue,
  paymentData,
  getpaymentValue,
  showAddCredit = true,
}) => {
  const [value, setValue] = useState(paymentValue?.id);

  const onChange = (e) => {
    const selectedItem = paymentData?.find((item) => item.id === e.target.value);
    if (selectedItem) {
      getpaymentValue(selectedItem);
    }
    setValue(e.target.value);
  };

  const card = paymentData;

  return (
    <S.Container>
      <S.ModalDescription>Select or Add New Credit or Debit Card</S.ModalDescription>
      <Radio.Group onChange={onChange} value={value}>
        {card.map((item) => {
          return <CardItem key={item.id} creditData={item} selectedId={paymentValue.id} />;
        })}
      </Radio.Group>
      {showAddCredit && (
        <S.CreditContainer onClick={() => getStep(4)}>
          <S.ContainerTitle>Add New Credit/Debit Card</S.ContainerTitle>
          <S.ContainerTitle>
            <RightOutlined />
          </S.ContainerTitle>
        </S.CreditContainer>
      )}
    </S.Container>
  );
};
