import styled from 'styled-components';
import { Layout, Card as BaseCard } from 'antd';
import * as color from 'modules/common/theme/color';

const MainLayout = styled(Layout)`
  min-height: 100vh;
`;

const Content = styled(Layout.Content)`
  padding: 5% 15%;
  background: ${color.White};

  @media (max-width: 576px) {
    padding: 0;
  }
`;

const Card = styled(BaseCard)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  overflow-y: visible;
`;

const FooterContainer = styled.div`
  position: relative !important;
`;

export default {
  Content,
  FooterContainer,
  MainLayout,
  Card,
};
