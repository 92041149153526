import { CoverImage } from 'modules/v2/editor/components';
import { cn } from 'modules/v2/common/utils/cn';

import S from './styles';

const Content = ({ image, isAllowed, title, name }) => (
  <div>
    <S.ImageWrapper>
      <CoverImage url={image} isAllowed={isAllowed} noBorder />
    </S.ImageWrapper>
    <span
      className={cn(
        'block text-sm font-medium mt-2',
        { 'text-neutral-800 truncate': isAllowed },
        { 'text-neutral-800 lowercase first-letter:uppercase': !isAllowed },
      )}
      title={isAllowed ? title : undefined}
    >
      {title}
    </span>
    <span
      className={cn(
        'block text-sm font-medium',
        { 'text-neutral-500': isAllowed },
        { 'text-neutral-500 mt-2': !isAllowed },
      )}
    >
      {name}
    </span>
  </div>
);

const BookCard = ({ isAllowed, pdfUrl, image, title, name, onClickHandle }) => {
  if (isAllowed) {
    return (
      <S.WrapperLink onClick={() => onClickHandle(pdfUrl)}>
        <Content image={image} isAllowed={isAllowed} title={title} name={name} />
      </S.WrapperLink>
    );
  }

  return <Content image={image} isAllowed={isAllowed} title={title} name={name} />;
};

BookCard.defaultProps = {
  name: '',
  image: '',
  to: '',
};

export default BookCard;
