import emojiRegex from 'emoji-regex';
import { DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import Immutable from 'immutable';

const EMOJI_REGEX = emojiRegex();

const removeEmojis = (regex, content) => {
  const blockMap = content.getBlockMap();

  const blocks = blockMap.map((block) => {
    const text = block.getText();
    const newText = text.replace(regex, '');

    return text !== newText ? block.set('text', newText) : block;
  });

  return content.merge({
    blockMap: blocks,
  });
};

export const filterState = (nextEditorState) => {
  const content = nextEditorState.getCurrentContent();
  const newContent = removeEmojis(EMOJI_REGEX, content);
  let newState = EditorState.createWithContent(newContent);
  newState = EditorState.moveSelectionToEnd(newState);

  return newState;
};

export const shouldFilterOnPaste = (editorState, nextEditorState) => {
  /*
     It is necessary to compare the currentContent with the new one
     before trying to filter the content searching for emojis
     to avoid this step when user first click in the editor
    */
  const nextContent = nextEditorState.getCurrentContent();
  const currentContent = editorState.getCurrentContent && editorState.getCurrentContent();
  const isInsertType = nextEditorState.getLastChangeType() === 'insert-fragment';

  return editorState && nextContent !== currentContent && isInsertType;
};

export const getExtendedBlockRenderMap = (block) => {
  const blockRenderMap = Immutable.Map(block);

  return DefaultDraftBlockRenderMap.merge(blockRenderMap);
};
