import { createContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getItem } from 'modules/v2/utils/legacy';
import { getShowCreditsButton } from 'modules/api';
import { Address } from '../index';

export const BooksContext = createContext(null);

export function BooksProvider({ children }) {
  const [bookQuantity, setBookQuantity] = useState(8);
  const [isBookQuantityValid, setIsBookQuantityValid] = useState(true);
  const [selectedOrderContext, setSelectedOrderContext] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({} as Address);
  const [activePage, setActivePage] = useState('ordered-books');
  const [userAddressesContext, setUserAddressesContext] = useState([] as Address[]);
  const [userCredits, setUserCredits] = useState(0);
  const [isBuyCreditEnabled, setIsBuyCreditEnabled] = useState(false);
  const userDataString = getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  useQuery('getShowCreditsButton', getShowCreditsButton, {
    onSuccess: (buyCreditsEnabled: boolean) => {
      setIsBuyCreditEnabled(buyCreditsEnabled);
    },
  });

  const clearContext = () => {
    setBookQuantity(8);
    setIsBookQuantityValid(true);
    setSelectedOrderContext({});
    setSelectedAddress({} as Address);
    setActivePage('ordered-books');
    setUserAddressesContext([]);
    setUserCredits(0);
  };

  const contextData = {
    clearContext,
    userData,
    bookQuantity,
    setBookQuantity,
    isBookQuantityValid,
    setIsBookQuantityValid,
    selectedOrderContext,
    setSelectedOrderContext,
    activePage,
    setActivePage,
    selectedAddress,
    setSelectedAddress,
    userAddressesContext,
    setUserAddressesContext,
    userCredits,
    setUserCredits,
    isBuyCreditEnabled,
  };

  const memoizedContextData = useMemo(() => contextData, [
    contextData,
    bookQuantity,
    isBookQuantityValid,
    selectedOrderContext,
    selectedAddress,
    activePage,
    userAddressesContext,
    userCredits,
  ]);

  return <BooksContext.Provider value={memoizedContextData}>{children}</BooksContext.Provider>;
}
