export default function GoogleIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10823_3509" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path
          d="M14.2121 6.78804H7.99998V9.3638H11.5757C11.2424 11.0002 9.84846 11.9396 7.99998 11.9396C5.81816 11.9396 4.06059 10.182 4.06059 8.00016C4.06059 5.81834 5.81816 4.06077 7.99998 4.06077C8.93937 4.06077 9.78786 4.3941 10.4545 4.93956L12.3939 3.00016C11.2121 1.96986 9.69695 1.3335 7.99998 1.3335C4.30301 1.3335 1.33331 4.30319 1.33331 8.00016C1.33331 11.6971 4.30301 14.6668 7.99998 14.6668C11.3333 14.6668 14.3636 12.2426 14.3636 8.00016C14.3636 7.60622 14.303 7.18198 14.2121 6.78804Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10823_3509)">
        <path d="M0.727234 11.9393V4.06055L5.87875 7.99994L0.727234 11.9393Z" fill="#FBBC05" />
      </g>
      <mask id="mask1_10823_3509" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path
          d="M14.2121 6.78804H7.99998V9.3638H11.5757C11.2424 11.0002 9.84846 11.9396 7.99998 11.9396C5.81816 11.9396 4.06059 10.182 4.06059 8.00016C4.06059 5.81834 5.81816 4.06077 7.99998 4.06077C8.93937 4.06077 9.78786 4.3941 10.4545 4.93956L12.3939 3.00016C11.2121 1.96986 9.69695 1.3335 7.99998 1.3335C4.30301 1.3335 1.33331 4.30319 1.33331 8.00016C1.33331 11.6971 4.30301 14.6668 7.99998 14.6668C11.3333 14.6668 14.3636 12.2426 14.3636 8.00016C14.3636 7.60622 14.303 7.18198 14.2121 6.78804Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_10823_3509)">
        <path
          d="M0.727234 4.06087L5.87875 8.00027L7.99996 6.15178L15.2727 4.96996V0.727539H0.727234V4.06087Z"
          fill="#EA4335"
        />
      </g>
      <mask id="mask2_10823_3509" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path
          d="M14.2121 6.78804H7.99998V9.3638H11.5757C11.2424 11.0002 9.84846 11.9396 7.99998 11.9396C5.81816 11.9396 4.06059 10.182 4.06059 8.00016C4.06059 5.81834 5.81816 4.06077 7.99998 4.06077C8.93937 4.06077 9.78786 4.3941 10.4545 4.93956L12.3939 3.00016C11.2121 1.96986 9.69695 1.3335 7.99998 1.3335C4.30301 1.3335 1.33331 4.30319 1.33331 8.00016C1.33331 11.6971 4.30301 14.6668 7.99998 14.6668C11.3333 14.6668 14.3636 12.2426 14.3636 8.00016C14.3636 7.60622 14.303 7.18198 14.2121 6.78804Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_10823_3509)">
        <path
          d="M0.727234 11.9397L9.81814 4.96996L12.2121 5.27299L15.2727 0.727539V15.273H0.727234V11.9397Z"
          fill="#34A853"
        />
      </g>
      <mask id="mask3_10823_3509" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path
          d="M14.2121 6.78804H7.99998V9.3638H11.5757C11.2424 11.0002 9.84846 11.9396 7.99998 11.9396C5.81816 11.9396 4.06059 10.182 4.06059 8.00016C4.06059 5.81834 5.81816 4.06077 7.99998 4.06077C8.93937 4.06077 9.78786 4.3941 10.4545 4.93956L12.3939 3.00016C11.2121 1.96986 9.69695 1.3335 7.99998 1.3335C4.30301 1.3335 1.33331 4.30319 1.33331 8.00016C1.33331 11.6971 4.30301 14.6668 7.99998 14.6668C11.3333 14.6668 14.3636 12.2426 14.3636 8.00016C14.3636 7.60622 14.303 7.18198 14.2121 6.78804Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_10823_3509)">
        <path
          d="M15.2727 15.2727L5.87875 7.99994L4.66663 7.09085L15.2727 4.06055V15.2727Z"
          fill="#4285F4"
        />
      </g>
    </svg>
  );
}
