import { ReactNode } from 'react';
import { HolidaySaleProvider } from './components/context';
import HolidaySaleRoute from './pages';

type HolidaySaleProps = {
  children: ReactNode;
};

const HolidaySaleMain = ({ children }: HolidaySaleProps) => {
  return (
    <HolidaySaleProvider>
      <HolidaySaleRoute> {children} </HolidaySaleRoute>
    </HolidaySaleProvider>
  );
};

export default HolidaySaleMain;
