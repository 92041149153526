import styled from 'styled-components';

import * as font from 'modules/common/theme/font';
import * as color from 'modules/common/theme/color';

const Sidebar = styled.div`
  width: 200px;
  height: calc(100vh - 50px);
  border-right: 1px solid ${color.TitleBorder};
  float: left;
  background: ${color.White};
  flex: 0 0 200px;
  position: relative;
  z-index: 10000;
`;

const Title = styled.div`
  font-family: ${font.NunitoFont};
  letter-spacing: 0.5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 63px;
  height: 63px;
  text-align: center;
  color: ${color.GreySelectDd};
  background: ${color.TitleBg};
  border-bottom: 1px solid ${color.TitleBorder};
`;

export default {
  Sidebar,
  Title,
};
