import styled from 'styled-components';
import { Input } from 'antd';
import * as color from 'modules/common/theme/color';

const Title = styled.div`
  font-weight: bold;
  margin: 16px 0;
`;

const TextArea = styled(Input.TextArea)`
  margin-bottom: ${(props) => (props.mb ? '16px' : 0)} !important;
  resize: none;
`;

const Wrapper = styled.div`
  margin-top: 18px;
  span {
    &:first-child {
      color: ${color.Black};
    }
  }
  textarea {
    min-height: 100px;
  }
  label {
    margin-top: 20px;
    display: block;
    &:first-child {
      margin-top: 0px;
    }
  }
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
`;

export default {
  FlexEnd,
  TextArea,
  Title,
  Wrapper,
};
