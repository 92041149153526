import { Public } from 'modules/router/types';

import {
  ROUTE_LANDING_PAGES_CLICKFUNNELS,
  ROUTE_LANDING_PAGES_CLICKFUNNELS_UPSELL,
  ROUTE_LANDING_PAGES_CONGRATS,
} from './constants';

import { ClickFunnels, Upsell, Congrats } from '../pages';
import { IsFeatureVisible } from '../../dashboard/utils/isAdminCustomer';

export const routes = IsFeatureVisible('FEATURE_LANDING_PAGES')
  ? [
    {
      type: Public,
      path: ROUTE_LANDING_PAGES_CLICKFUNNELS,
      component: ClickFunnels,
      exact: true,
    },
    {
      type: Public,
      path: ROUTE_LANDING_PAGES_CLICKFUNNELS_UPSELL,
      component: Upsell,
      exact: true,
    },
    {
      type: Public,
      path: ROUTE_LANDING_PAGES_CONGRATS,
      component: Congrats,
      exact: true,
    },
  ]
  : [];
