import { useState } from 'react';

import { FilePicker, Modal } from 'modules/common/components';

// TODO: needs to be moved on into common module components
const FilePickerModal = ({
  // DATA
  type,
  isOpen,
  title,

  // CALLBACKS
  onConfirm,
  onCancel,
}) => {
  const [image, setImage] = useState(null);
  const [imageFormat, setImageFormat] = useState();
  const group = 'upload';

  const handleConfirm = () => {
    if (onConfirm) {
      if (image instanceof HTMLCanvasElement) {
        onConfirm(image.toDataURL(imageFormat, 0.7), group);
      } else if (typeof image === 'string') {
        onConfirm(image, group);
      }
    }
  };

  const handleSelect = (img, format) => {
    setImage(img);
    setImageFormat(format);
  };

  return (
    <Modal
      isOpen={isOpen}
      maskClosable={false}
      title={title}
      width="700px"
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      <FilePicker
        type={type}
        onSelect={handleSelect}
        isEditImage={false}
        toggleCancelEdit={null}
        imageUrlForEdit={null}
        onSuccess={null}
      />
    </Modal>
  );
};

export default FilePickerModal;
