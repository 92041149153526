import {
  LOGIN_USER,
  AUTO_LOGIN_CHECK,
  FORGET_PASSWORD,
  GET_NEW_TOKEN,
  RESET_PASSWORD,
  CHECK_EXPIRED_LINK,
  SET_USER_EMAIL_LOGIN,
} from '../constants';

const initialState = {
  loading: false,
  loaded: true,
  data: {},
  message: '',
  expiredLink: 99,
  login: {
    statusCode: 99,
    data: {},
  },
  forgotPassword: {
    statusCode: 99,
  },
  resetPassword: {
    statusCode: 1,
  },
  checked: false,
  userEmail: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN_USER}_REQUEST`:
    case `${AUTO_LOGIN_CHECK}_REQUEST`:
      return { ...state, loading: true, loaded: false };

    case `${LOGIN_USER}`:
    case `${AUTO_LOGIN_CHECK}`:
      return {
        ...state,
        loading: false,
        loaded: true,
        ...state.login,
        login: {
          statusCode: action.payload.statusCode,
          data: action.payload.data,
          message: action.payload.message,
        },
      };

    case `${LOGIN_USER}_FAILURE`:
    case `${AUTO_LOGIN_CHECK}_FAILURE`:
      return { ...state, loaded: true, loading: true };

    case `${FORGET_PASSWORD}_REQUEST`:
      return { ...state, loading: true, loaded: false };

    case `${FORGET_PASSWORD}`:
      return {
        ...state,
        loading: false,
        loaded: true,
        message: action.payload.message,
        ...state.forgotPassword,
        forgotPassword: {
          statusCode: action.payload.statusCode,
        },
      };

    case `${FORGET_PASSWORD}_FAILURE`:
      return { ...state, loaded: true };

    case GET_NEW_TOKEN:
      return {
        loading: false,
        loaded: true,
        ...state,
      };
    case RESET_PASSWORD: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data,
        ...state.resetPassword,
        resetPassword: {
          statusCode: action.payload.statusCode,
        },
      };
    }

    case `${CHECK_EXPIRED_LINK}_REQUEST`: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case CHECK_EXPIRED_LINK: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data,
        expiredLink: action.payload.statusCode,
      };
    }

    case SET_USER_EMAIL_LOGIN: {
      return {
        ...state,
        userEmail: action.payload,
      }
    }

    default:
      return { ...state };
  }
};
