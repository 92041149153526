import { STATUS_SUCCESS, STATUS_ERROR, STATUS_LOADING } from 'store/status';

import { RESET_SECTION } from 'modules/v2/editor/store/constants';
import { resetSection as initialState } from 'modules/v2/editor/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${RESET_SECTION}_REQUESTED`: {
      return {
        ...state,
        status: STATUS_LOADING,
      };
    }

    case `${RESET_SECTION}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${RESET_SECTION}_SUCCEEDED`: {
      return {
        ...state,
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
};

export default reducer;
