import { useState } from 'react';

import Modal from 'modules/v2/common/components/Modal';
import RedirectConfirm from './RedirectConfirm';
import RedirectWait from './RedirectWait';

import S from './styles';

const RedirectModal = ({ title, onConfirm, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const styledTitle = <S.Title>{title}</S.Title>;

  const handleConfirm = () => {
    onConfirm();
    setLoading(true);
  };

  let content = (
    <RedirectConfirm title={styledTitle} onConfirm={handleConfirm} onCancel={onCancel} />
  );

  if (loading) {
    content = <RedirectWait title={styledTitle} />;
  }

  return (
    <Modal width="600px" footer={null} isOpen onCancel={onCancel} title="Redirecting">
      {content}
    </Modal>
  );
};

export default RedirectModal;
