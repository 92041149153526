import { CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Row } from 'antd';
import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';

const ImgContainer = styled(Row)`
  margin-bottom: 40px;
  text-align: center;
`;

const CoverImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

const TickedIcon = styled(CheckOutlined)`
  color: ${color.DefaultBlue};
  font-size: 16px;
  position: relative;
  top: 2px;

  @media ${device.tabletS} {
    font-size: 14px;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  margin: 20px auto;
  color: ${color.Black};

  @media ${device.tabletS} {
    font-size: 16px;
  }
`;

const Text = styled.p`
  text-align: center;
  font-size: 16px;
  color: ${color.Black};

  @media ${device.tabletS} {
    font-size: 14px;
  }
`;

const Highlight = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${color.Black};
  font-weight: 600;

  @media ${device.tabletS} {
    font-size: 16px;
  }
`;

const MailWrapper = styled.div`
  text-align: center;
  margin: 20px auto;
  font-size: 16px;
  color: ${color.Black};

  @media ${device.tabletS} {
    font-size: 14px;
  }
`;

const TextPointsContainer = styled.ul`
  display: block;
  margin-left: 15% !important;
  margin-top: 10% !important;

  @media ${device.tabletS} {
  }
`;

const TextPoints = styled.li`
  display: flex;
  margin-bottom: 20px;
`;

const AdPoint = styled.p`
  margin-left: 13px !important;
  text-align: left;
  font-size: 22px;
  line-height: 28px;
  font-weight: 601;
  letter-spacing: 0px;
  color: ${color.Black};
  flex: 1;
  list-style: none;

  @media ${device.tabletS} {
    font-size: 14px;
  }
`;

const BreakLine = styled.div`
  width: 100%;
  border: 1px solid ${color.LightDullGray};
  margin: 30px auto;
`;

export default {
  AdPoint,
  BreakLine,
  CoverImage,
  Heading,
  Highlight,
  ImgContainer,
  MailWrapper,
  Text,
  TextPoints,
  TextPointsContainer,
  TickedIcon,
};
