import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const ExternalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: calc(100vh - 32px);
  height: 100%;
  background-color: ${color.White};
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${color.White};
  margin-top: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 960px;
  height: 100%;
  padding: 15px 0;

  color: ${color.Black};
`;

const Title = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  font-family: ${font.LoraFont};
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 7.5px;
  width: 100%;
  margin-bottom: 30px;
`;

const ContainerWrapper = styled.div`
  display: flex;
  gap: 67px;
  width: 100%;
`;

const RotateIcon = styled.div`
  transform: rotate(90deg);
`;

export default {
  ExternalContainer,
  PageContainer,
  Container,
  ContainerWrapper,
  Title,
  RotateIcon,
};
