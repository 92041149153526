import { throttle } from 'lodash-es';
import {
  NO_PRINTS,
  STEP,
  MIN_NUMBER_OF_PRINTS,
  PRINT_NUMBER_PROMO,
  FORBIDDEN_NUMBER_OF_PRINTS,
} from './constants';

// We cannot print less than 8 books and
// the user should be able to choose 10 prints
const isSpecialRange = (value) =>
  (value >= NO_PRINTS && value <= MIN_NUMBER_OF_PRINTS) || value === PRINT_NUMBER_PROMO;

const isTheValueDecreasing = (newValue, currValue) => newValue < currValue;

// Normally the number of prints should be a multiple of 4 but we have a
// set of rules to be considered when number of prints are between 0 and 10
const handleSpecialRange = throttle((newValue, currValue) => {
  // There is a promotions which allow the user to print 10 books
  if (newValue === PRINT_NUMBER_PROMO) return newValue;

  // If the user tries to insert a number that is less than 4 the field value will be setup to 0.
  if (newValue < FORBIDDEN_NUMBER_OF_PRINTS) return NO_PRINTS;

  // If the user tries to insert a number that is greater than 4 the field value will be setup to the minimal number of prints
  if (newValue > FORBIDDEN_NUMBER_OF_PRINTS) return MIN_NUMBER_OF_PRINTS;

  // If the user is dragging the slider within the special range we should return no prints or the minimal amount depends on the direction followed
  return isTheValueDecreasing(newValue, currValue) ? NO_PRINTS : MIN_NUMBER_OF_PRINTS;
}, 1000);

const findNextMultipleOfFour = (newValue, currValue, credits) => {
  const module = newValue % STEP;

  if (module === 0) return newValue;

  const previousMutiple = newValue - module;
  const nextMutiple = newValue - module + STEP;

  if (nextMutiple > currValue) {
    // if the next multiple is found we need to check if it is greater than the
    // credits that was provided and return the next multiple or the previous one.
    if (nextMutiple < credits) {
      return nextMutiple;
    }
    return previousMutiple;
  }

  return nextMutiple;
};

export const fixValue = (newValue, currValue, credits) =>
  isSpecialRange(newValue)
    ? handleSpecialRange(newValue, currValue)
    : findNextMultipleOfFour(newValue, currValue, credits);

export const isNumberOfPrintsAllowed = (numberOfPrints) => {
  if (numberOfPrints < MIN_NUMBER_OF_PRINTS) {
    return false;
  }

  if (numberOfPrints === PRINT_NUMBER_PROMO) {
    return true;
  }

  if (numberOfPrints % 4 === 0) {
    return true;
  }

  return false;
};
