import { BookCard } from './components';

import S from './styles';

const BookList = ({ children }) => {
  if (!children) {
    return null;
  }

  return <S.Wrapper>{children}</S.Wrapper>;
};

BookList.BookCard = BookCard;

export default BookList;
