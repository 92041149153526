import { Radio as RadioBase, Tag as BaseTag, Space as SpaceBase } from 'antd';
import styled from 'styled-components';

import * as color from 'modules/v2/common/theme/color';

const Container = styled.div`
  background: ${color.White};
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: ${(props) =>
    props.isActive
      ? `1.5px solid ${color.ActivePlusPlanSelection}`
      : `1px solid ${color.ResetButtonHoverBg}`};
  border-radius: 2px;

  .ant-radio-wrapper {
    align-items: center;
    width: 100% !important;
  }

  span.ant-radio + * {
    width: 100%;
  }

  .ant-radio {
    margin-right: 8px;
  }
`;

const Radio = styled(RadioBase)`
  .ant-radio {
    top: 0 !important;
  }
`;

const CardImg = styled.img`
  width: 60px;
  height: 40px;
`;

const CreditWrapper = styled(SpaceBase)`
  justify-content: space-between;
  width: 100%;
`;

const Credit = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: center;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const CardName = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

const CardTag = styled(BaseTag)`
  background-color: ${color.PhoneLink};
  color: ${color.White};
  border-radius: 15px;
  padding: 5px 15px;
  float: right;

  @media (max-width: 576px) {
    display: none;
  }
`;

const ExpiredTag = styled(BaseTag)`
  background-color: ${color.ResetButtonHoverBg};
  color: ${color.White};
  border-radius: 15px;
  padding: 5px 15px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${color.grayDot};
  border-radius: 50%;
  margin-right: 5px;
`;

const DotContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default {
  Container,
  Radio,
  CreditWrapper,
  Credit,
  CardWrapper,
  Description,
  CardImg,
  CardName,
  CardTag,
  ExpiredTag,
  Dot,
  DotContainer,
};
