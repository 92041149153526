import styled from 'styled-components';
import { Tooltip as BaseTooltip } from 'antd';
import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const Tooltip = styled(BaseTooltip)`
  .ant-tooltip-inner {
    font-family: ${font.NunitoFont};
    font-weight: 600;
    font-size: 13px;
    padding: 10px 15px;
    background-color: ${color.White};
    color: ${color.TooltipTextColor};
    white-space: normal;
    text-transform: none;
  }
  .ant-tooltip-arrow:before {
    background: ${color.White};
  }
`;

export default { Tooltip };
