import React, { useState } from 'react';
import { compose } from 'redux';
import { find, get } from 'lodash-es';

import { Toolbar } from 'modules/nav/components';

import { hasRealtorLogoAccess } from 'modules/dashboard/utils';

import {
  withBook,
  withCover,
  withDraft,
  withImageLibrary,
  withPreferences,
} from 'modules/editor/containers';
import { EditorTemplate } from 'modules/editor/templates';
import {
  CoverEditor,
  CoverEditorModal,
  EditorFooter,
  EditorNavbar,
  Sidebar,
  TemplateList,
} from 'modules/editor/components';
import { COVER_IMAGE_GROUP_UPLOAD } from 'modules/editor/utils/constants';

import { getRouteEditDraftManageSections } from 'modules/editor/routes/navigation';
import { SettingsIcon } from 'modules/common/components/SvgIcon';

const getSection = (draft) => {
  const draftSections = get(draft, ['data', 'sections'], []);
  const draftSection = find(draftSections, ({ type }) => type === 'Cover');

  return draftSection;
};

const getCover = (book, draft) => {
  const draftSection = getSection(draft);
  const draftTemplates = get(draftSection, 'templates', []);
  const draftTemplate = find(draftTemplates, ({ isActive }) => isActive);

  const bookSections = get(book.data, 'sections', []);
  const bookSection = find(bookSections, ({ _id }) => _id === draftSection._id);
  const bookTemplates = get(bookSection, 'templates', []);
  const bookTemplate = find(bookTemplates, ({ _id }) => _id === draftTemplate._id);

  // TODO: Move to selectors
  const templateHTML = get(bookTemplate, 'templateHTML', '');

  return { templateHTML };
};

export const Cover = ({
  book,
  draft,
  draftId,
  dropdown,
  elements,
  images,
  loadingElements,
  logos,
  templateId,
  templateList,
  toolbar,
  isPendingSave,
  isSaving,
  preferences,
  deleteUserImage,
  getUserImages,
  getPresetLogos,
  goToDraftView,
  replaceCoverImage,
  resetDraft,
  resetCurrentSection,
  saveDraft,
  toggleShowGuides,
  uploadCoverImage,
  updateCoverElement,
  updateCoverActiveTemplate,
  updatePreferences,
  updatePreferenceStatusByKey,
  updatePreferenceByKey,
} = {}) => {
  const [elementId, setElementId] = useState(null);
  const [scale, setScale] = useState(1);

  const brokerLogo = get(preferences, ['brokerLogo', 'status'], true);
  const realtorLogo = get(preferences, ['realtorLogo', 'status'], false);
  const brokerInfo = get(preferences, ['brokerInfo', 'status'], true);
  const housingLogo = get(preferences, ['housingLogo', 'status'], true);
  const { startChaptersInRecto } = preferences;
  const element = find(elements, (e) => e?._id === elementId);

  // TODO: Move templateHTML, showGuides, bookTitle, sections and dropDownItems to the selectors
  const { templateHTML } = getCover(book, draft);
  const { _id: sectionId } = getSection(draft);

  const { showGuides } = toolbar;
  const bookTitle = get(book, ['data', 'name']);

  const handleFilePickerConfirm = (value, group, imageFormat) => {
    if (group === COVER_IMAGE_GROUP_UPLOAD) {
      uploadCoverImage(templateId, elementId, value, group, imageFormat);
      return setElementId(null);
    }

    replaceCoverImage(templateId, elementId, value, group, imageFormat);
    return setElementId(null);
  };

  const handleRichTextConfirm = ({ rawValue, defaultValue }) => {
    updateCoverElement(templateId, elementId, rawValue, defaultValue);
    setElementId(null);
  };

  const handleModalCancel = () => setElementId(null);

  const handleContactDetailsChange = (fields) => {
    updatePreferences(fields);
    setElementId(null);
  };

  const handleDeleteImage = (imageId) => deleteUserImage(imageId);

  const updatePreference = (key) => (e) => {
    const { checked } = e.target;

    if (key === 'startChaptersInRecto') {
      updatePreferenceByKey(key, checked);
      return;
    }

    updatePreferenceStatusByKey(key, checked);
  };

  const toggleGuides = (e) => {
    const { checked } = e.target;

    toggleShowGuides(checked);
  };

  /*
   * this function add status property to dropdown items
   * */
  const addMissingInformation = (dropdownItems) => {
    return dropdownItems.map((item) => {
      const section = draft.data.sections.find(({ _id }) => _id === item._id);
      if (section && section.status === 'deprecated') {
        return {
          ...item,
          status: 'deprecated',
        };
      }
      return item;
    });
  };

  const renderNavbar = (
    <EditorNavbar
      bookTitle={bookTitle}
      dropdownTitle="Book Cover"
      dropDownItems={addMissingInformation(dropdown)}
      isSaving={isSaving}
      isPendingSave={isPendingSave}
      onSave={saveDraft}
      onExit={() => goToDraftView(draftId)}
    />
  );

  const renderSidebar = (
    <Sidebar title="Available Templates">
      <TemplateList
        templates={templateList}
        selectedId={templateId}
        onSelect={updateCoverActiveTemplate}
      />
    </Sidebar>
  );

  const toolbarSettings = (
    <Toolbar.Options title="Preferences" icon={<SettingsIcon />}>
      <Toolbar.Options.Item
        onChange={updatePreference('brokerInfo')}
        checked={brokerInfo}
        text="Include your broker information"
      />
      <Toolbar.Options.Item
        onChange={updatePreference('brokerLogo')}
        checked={brokerLogo}
        text="Include your broker logo"
      />
      {hasRealtorLogoAccess() && (
        <Toolbar.Options.Item
          onChange={updatePreference('realtorLogo')}
          checked={realtorLogo}
          text="Include your realtor logo"
        />
      )}
      <Toolbar.Options.Item
        onChange={updatePreference('housingLogo')}
        checked={housingLogo}
        text="Include the Fair Housing logo"
      />
      <Toolbar.Options.Item
        onChange={updatePreference('startChaptersInRecto')}
        checked={startChaptersInRecto}
        text="Always start chapters on the right-hand page"
      />
    </Toolbar.Options>
  );

  const handleResetDraft = () => resetDraft(draftId);
  const handleResetSection = () => resetCurrentSection(sectionId);
  const renderToolbar = (
    <EditorFooter
      bookTitle={bookTitle}
      onReset={handleResetDraft}
      onResetSection={handleResetSection}
    >
      <Toolbar.Link to="" disabled>
        Back
      </Toolbar.Link>
      <Toolbar.Controls onChange={toggleGuides} checked={showGuides} text="Show Guides" />
      {toolbarSettings}
      <Toolbar.Link to={getRouteEditDraftManageSections(draftId)}>Next</Toolbar.Link>
    </EditorFooter>
  );

  return (
    <EditorTemplate navbar={renderNavbar} sidebar={renderSidebar} toolbar={renderToolbar}>
      <CoverEditor
        template={templateHTML}
        elements={elements}
        scale={scale}
        showGuides={showGuides}
        onClick={setElementId}
        onScaleChange={setScale}
        loadingElements={loadingElements}
      />
      <CoverEditorModal
        element={element}
        images={images}
        logos={logos}
        scale={scale}
        preferences={preferences}
        onContactDetailsChange={handleContactDetailsChange}
        onDeleteImage={handleDeleteImage}
        onFilePickerConfirm={handleFilePickerConfirm}
        onModalCancel={handleModalCancel}
        onRichTextConfirm={handleRichTextConfirm}
        onGalleryTabOpen={getUserImages}
        onLogosTabOpen={getPresetLogos}
      />
    </EditorTemplate>
  );
};

const MemoCover = React.memo(Cover);
const Composed = compose(
  withCover,
  withPreferences,
  withBook,
  withDraft,
  withImageLibrary,
)(MemoCover);

export default Composed;
