import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { getSession } from 'modules/onboard/utils';
import { fetchSession } from 'modules/api';

export const usePoolingSession = ({ onSuccess }) => {
  const { offerCode } = useParams();
  const [pooling, setPooling] = useState(false);
  const session = getSession(offerCode);
  const {
    offer: { code },
    id,
    step,
  } = session;

  useQuery('getStepPoolingSession', (params) => fetchSession(code, id), {
    enabled: !!pooling,
    onSuccess,
    refetchInterval: 5000,
  });

  const startPooling = () => setPooling(true);

  const stopPooling = () => setPooling(false);

  return { startPooling, stopPooling };
};
