import { getCurrentMonth, getCurrentYear } from 'modules/common/utils';
import { approvedState, reviewState } from '../../../common/theme/color';

export const rmMagzineEditorSteps = [
  'Magazine',
  'Front Cover',
  'Front Inside Cover',
  'Back Inside Cover',
  'Back Cover',
  'Generate Magazine',
];

export const params = {
  year: getCurrentYear(),
  month: getCurrentMonth(),
};

export const stepHavingForms = [1, 2, 3, 4];

export const generatedMagzineRefetchInterval = 3000;

export const mockGeneratedMagzineUrl =
  'https://authorify-generated-pdf.s3.amazonaws.com/77037451/previews/77037451-619e19f307d08d3e83a4abe6.pdf';

export const magazineStatus = {
  EDITING: 'Editing',
  MAGAZINE_GENERATED: 'Generated Magazine',
  SENT_FOR_PRINTING: 'Revisions Sent',
  PENDING: 'Pending',
  DONE: 'Done',
};

export const rmMagazineStatus = {
  EDITING: 'EDITING',
  MAGAZINE_GENERATED: 'MAGAZINE_GENERATED',
  SENT_FOR_PRINTING: 'SENT_FOR_PRINTING',
  PENDING: 'PENDING',
  DONE: 'DONE',
};

export const SENT_FOR_PRINTING = 'SENT_FOR_PRINTING';

export const magazineStatusColor = {
  EDITING: reviewState,
  MAGAZINE_GENERATED: reviewState,
  SENT_FOR_PRINTING: approvedState,
};

export const htmlReplacers = [
  'agentName',
  'brokerName',
  'phoneNumber',
  'email',
  'address',
  'website',
  'profileImage',
];

export const magazineStatusWithNoPolling = ['DONE', 'ERROR'];

export const monthsList = {
  JAN: 'january',
  FEB: 'february',
  MAR: 'march',
  APR: 'april',
  MAY: 'may',
  JUN: 'june',
  JUL: 'july',
  AUG: 'august',
  SEP: 'september',
  OCT: 'october',
  NOV: 'november',
  DEC: 'december',
};

export const optionFieldsWithWordLimit = [
  'description',
  'features',
  'testimonial1',
  'testimonial2',
  'testimonial3',
  'testimonial4',
  'testimonial5',
];
