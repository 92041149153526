import { IInitialValues } from './types';

const INITIAL_VALUES: IInitialValues = {
  customer: {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
  },
  billing_address: {
    address: '',
    city: '',
    state: '',
    states: [],
    zip: '',
    country: 'USA',
  },
  credit_card: {
    full_number: '',
    expiration_month: '',
    expiration_year: '',
    cvc: '',
  },
};

export default INITIAL_VALUES;
