import env from 'environments';

export function getCurrentEnvironment() {
  return env.REACT_APP_ENV || 'localhost';
}

export function isDevelopmentEnvironment() {
  const current = getCurrentEnvironment();

  return ['practice', 'development', 'localhost'].indexOf(current) >= 0;
}

export function enableReduxLogger() {
  return isDevelopmentEnvironment() && Boolean(env.LOGGER);
}
