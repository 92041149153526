export const RoundArrowUp = ({ width = 17, height = 16, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconer">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66675 8.00065C1.66675 11.6825 4.65152 14.6673 8.33341 14.6673C12.0153 14.6673 15.0001 11.6825 15.0001 8.00065C15.0001 4.31875 12.0153 1.33398 8.33341 1.33398C4.65152 1.33398 1.66675 4.31875 1.66675 8.00065ZM10.687 8.31376C10.8822 8.50903 10.8822 8.82561 10.687 9.02087L8.68697 11.0209C8.49171 11.2161 8.17512 11.2161 7.97986 11.0209L5.97986 9.02087C5.7846 8.82561 5.7846 8.50903 5.97986 8.31376C6.17512 8.1185 6.49171 8.1185 6.68697 8.31376L7.83341 9.46021V5.33398C7.83341 5.05784 8.05727 4.83398 8.33341 4.83398C8.60956 4.83398 8.83341 5.05784 8.83341 5.33398V9.46021L9.97986 8.31376C10.1751 8.1185 10.4917 8.1185 10.687 8.31376Z"
        fill={fill}
      />
    </g>
  </svg>
);
