import styled from 'styled-components';
import { Checkbox as BaseCheckbox } from 'antd';

import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  background-color: ${color.White};
  border: 1px solid ${color.GreyDivider};
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;

  // Prevent text selection on double click
  user-select: none;
`;

Wrapper.Inner = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-right: 1px solid ${color.GreyDivider};
  height: 30px;

  &:last-child {
    border-right: none;
  }
`;

const ZoomIcon = styled.a`
  display: flex;
  align-items: center;
  margin: 0 8px;

  & > svg {
    fill: ${color.GreySelectDd};
    line-height: normal;
  }
`;

const ZoomPercentage = styled.div`
  padding: 0 10px;
`;

const Checkbox = styled(BaseCheckbox)`
  font-size: 12px;
  font-weight: 700;
  color: rgb(46, 63, 80);
  letter-spacing: 0;
`;

export default {
  Wrapper,
  ZoomIcon,
  ZoomPercentage,
  Checkbox,
};
