export const CloseCircleIcon = ({ width = 12, height = 12, fill = '#252525' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      d="M12 4.00003L4 12M3.99997 4L11.9999 12"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
