export const MenuDotsVertical = ({ width = 16, height = 16, fill = '#252525' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.80039 5.1999C7.02719 5.1999 6.40039 4.5731 6.40039 3.7999C6.40039 3.0267 7.02719 2.3999 7.80039 2.3999C8.57359 2.3999 9.20039 3.0267 9.20039 3.7999C9.20039 4.5731 8.57359 5.1999 7.80039 5.1999Z"
      fill={fill}
    />
    <path
      d="M7.80039 9.3999C7.02719 9.3999 6.40039 8.7731 6.40039 7.9999C6.40039 7.2267 7.02719 6.5999 7.80039 6.5999C8.57359 6.5999 9.20039 7.2267 9.20039 7.9999C9.20039 8.7731 8.57359 9.3999 7.80039 9.3999Z"
      fill={fill}
    />
    <path
      d="M7.80039 13.5999C7.02719 13.5999 6.40039 12.9731 6.40039 12.1999C6.40039 11.4267 7.02719 10.7999 7.80039 10.7999C8.57359 10.7999 9.20039 11.4267 9.20039 12.1999C9.20039 12.9731 8.57359 13.5999 7.80039 13.5999Z"
      fill={fill}
    />
  </svg>
);
