import { TOGGLE_MODAL, MODAL_TEXT_CHANGE } from '../constants';

const initialState = {
  open: false,
  value: '',
  name: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        open: !state.open,
      };
    case MODAL_TEXT_CHANGE:
      return {
        ...state,
        value: action.text,
      };

    default:
      return { ...state };
  }
};
