import React, { useState } from 'react';
import classnames from 'classnames';
import { ImagePlaceholder, Modal } from 'modules/common/components';
import { cn } from 'modules/v2/common/utils';

import env from 'environments';
import S from './styles';

const CoverImage = ({ url, title, loading, isAllowed, selected, noBorder = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState({});

  const imageUrl = `${url?.includes('http') ? '' : env.GRAPHQL_URL}${url}?random=33`;

  if (loading) return <ImagePlaceholder />;
  if (!url) return null;

  const isAlreadyFilteredList = isAllowed === undefined;
  const borderStyle = cn('text-center inline-block h-full w-full', {
    'relative border-[2px] rounded-[8px] overflow-hidden hover:border-blueBorder': !noBorder,
    'border-blueBorder': selected,
    'border-[transparent]': !selected,
  });

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const handleImageLoaded = (image) => () => {
    setIsLoaded({ ...isLoaded, [image]: true });
  };

  if (isAllowed || isAlreadyFilteredList) {
    return (
      <div className={borderStyle}>
        {imageUrl && (
          <img
            className={classnames(!isLoaded[imageUrl] ? 'h-0 overflow-hidden' : 'h-full')}
            id="imageURL"
            src={imageUrl}
            alt={imageUrl}
            onLoad={handleImageLoaded(imageUrl)}
          />
        )}

        {!isLoaded[imageUrl] ? (
          <ImagePlaceholder
            className="bg-neutral-100 absolute top-0 left-0"
            height="100%"
            width="100%"
          />
        ) : null}
      </div>
    );
  }
  return (
    <div className="w-full">
      <S.UpgradeImage src={url} alt={title} />
      <Modal footer={null} isOpen={isModalOpen} onCancel={onCancel} title="Upgrade Plan">
        <S.ModalContent>
          <h1>Coming Soon!</h1>
        </S.ModalContent>
      </Modal>
    </div>
  );
};

CoverImage.defaultProps = {
  title: 'Book cover',
};

export default React.memo(CoverImage);
