export const SET_SELECTED_LEADS = 'SET_SELECTED_LEADS';
export const SET_UNSELECTED_LEADS = 'SET_UNSELECTED_LEADS';

export const setSelectedLeads = (leads) => ({
  type: SET_SELECTED_LEADS,
  payload: leads,
});

export const setUnselectedLeads = (leads) => ({
  type: SET_UNSELECTED_LEADS,
  payload: leads,
});
