import styled, { createGlobalStyle } from 'styled-components';
import * as color from 'modules/common/theme/color';

const DragDropWrapper = styled.div`
  border: 2px dotted ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${({ showWhite }) => (showWhite ? color.White : color.GreyMenuItemHover)};
  margin: ${({ noMargin }) => (noMargin ? '0' : '20px')};
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  height: calc(100% - 20px);

  > div {
    width: 100%;
    outline: 0;
    cursor: pointer;
  }

  svg {
    fill: #9c9a9a;
    width: 75px;
    height: 45px;
    margin: 0 auto;
    display: block;
  }
`;

const UploadText = styled.div`
  color: ${color.GreySelectDd};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  padding: 5px 0;
`;

const ResetModalHeightStyle = createGlobalStyle`
  .ant-modal-body {
    max-height: 100% !important;
  }
`;

export default { DragDropWrapper, UploadText, ResetModalHeightStyle };
