export const guidesInformation = [
  {
    id: '653133791b453e0033e904c3',
    name: 'The Cosmetic Dentistry Guide',
    thumbnailUrl: 'https://cms.staging.authorify.com/uploads/3765c196467b4ad3b22f6d1177ee8525.jpg',
    site: '',
    pdfUrl:
      'https://afy-dentist-files.s3.amazonaws.com/static/pdf/the_cosmetic_dentistry_guide_flippingbook_653133791b453e0033e904c3.pdf',
  },
  {
    id: '653134121b453e0033e904c8',
    name: 'Myths & Misconceptions About Cosmetic Dentistry',
    thumbnailUrl: 'https://cms.staging.authorify.com/uploads/ec39c236f1454260a5189569a3c6753e.jpg',
    site: '',
    pdfUrl:
      'https://afy-dentist-files.s3.amazonaws.com/static/pdf/myths_and_misconceptions_about_cosmetic_dentistry_flippingbook_653134121b453e0033e904c8.pdf',
  },
  {
    id: '653135f01b453e0033e904cd',
    name: 'The Ultimate Guide To Cosmetic Dentistry Procedures',
    thumbnailUrl: 'https://cms.staging.authorify.com/uploads/749ac58d94fc4b42956916b69b75ebc8.jpg',
    site: '',
    pdfUrl:
      'https://afy-dentist-files.s3.amazonaws.com/static/pdf/the_ultimate_guide_to_cosmetic_dentistry_procedures_flippingbook_653135f01b453e0033e904cd.pdf',
  },
];

export const guidesPacketInformation = {
  id: '657c4e963cef5c001c2a6be0',
  name: 'Dentist Educational Packet',
  site: '',
  thumbnailUrl: 'https://cms.authorify.com/uploads/20294ee5e2b64c97a5c987aa57e67965.png',
  pdfUrl:
    'https://afy-dentist-files.s3.amazonaws.com/static/pdf/the_cosmetic_dentistry_guide_flippingbook_653133791b453e0033e904c3.pdf', // FIXME: change to real pdf
};
