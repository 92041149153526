import { get } from 'lodash-es';

import { isLoading } from 'store/status';
import ImagePlaceholder from '../ImagePlaceholder';

import S from './styles';

const UserAvatar = ({ avatar, onClick, size, scale, defaultAvatar }) => {
  let avatarImage = defaultAvatar;
  const avatarUrl = get(avatar.data, 'url');

  if (avatarUrl) {
    avatarImage = (
      <S.Avatar.Image data-testid="loaded-image" src={avatarUrl} alt="avatar" size={size} />
    );
  }

  if (isLoading(avatar.status)) {
    avatarImage = <ImagePlaceholder width={size} height={size} scale={scale} />;
  }

  return (
    <div className="rounded-full overflow-hidden cursor-pointer">
      <S.Avatar onClick={onClick}>{avatarImage}</S.Avatar>
    </div>
  );
};

export default UserAvatar;
