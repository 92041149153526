import React from 'react';
import { Row, Col } from 'antd';
import CommonModalWrapper from 'modules/common/theme/modalTheme';
import { EditorStyle } from './style';

const EditorTemplate = ({ navbar, sidebar, children, toolbar }) => (
  <CommonModalWrapper>
    <div className="commonModalInner">
      <div>
        <div>
          {navbar && (
            <Row>
              {/* Header Component */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {navbar}
              </Col>
            </Row>
          )}

          <EditorStyle>
            <Row>
              <div className="dispflx">
                {sidebar}
                <div className="rgt">
                  <div className="editorWrapper">
                    <div className="mainEditorWrap">
                      <div id="fullSc" className="mainEditor">
                        {children}
                      </div>
                    </div>

                    {toolbar}
                  </div>
                </div>
              </div>
            </Row>
          </EditorStyle>
        </div>
      </div>
    </div>
  </CommonModalWrapper>
);

export default React.memo(EditorTemplate);
