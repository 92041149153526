import S from './styles';

const Description = ({ text }) => <S.Description>{text}</S.Description>;

const LabelTitle = ({ text, description }) => {
  return (
    <p>
      {text} {description ? <Description text={description} /> : null}
    </p>
  );
};

export default LabelTitle;
