import styled from 'styled-components';

const AvatarWrapper = styled.div`
  background-color: rgb(230, 230, 230);
  border-radius: 60px;
  border: 2px solid rgb(204, 204, 204);
  cursor: pointer;
  height: 104px;
  width: 104px;
  margin: 0 auto;

  svg {
    width: 75px;
    height: 75px;
    path {
      fill: rgb(204, 204, 204);
    }
  }
`;

const AvatarIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default {
  AvatarWrapper,
  AvatarIcon,
};
