import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './ncc-funnel.png';

const isProduction = env.ENVIRONMENT === 'production';

const nccFunnelCA = {
  offerDescription: [
    'The entire 2-Step No Cold Calling Listing System.',
    'The Instant Trust Package.',
    'The Agent Comparison Chart.',
    'The editable 18-Step Marketing Plan.',
    'The Reference Letter Worksheet.',
    'The Introduction Cover Letter.',
    'The Photo Brochure that shows you’re an expert.',
    'The 60-Day Seller lead Follow Up Campaign.',
    'Complete A-to-Z Instructions on how to implement everything and start getting listings without cold calling.',
    'The Complete Guide to Finding Unlimited Leads.',
    'The Guide on how to Outsource Seller Lead Research to a VA.',
  ],
  productHandler: 'nccfunnel',
  componentId: 'handle:clickfunnel-1-99',
  summaryPrice: '$1.99',
  summaryTitle: '2-Step No Cold Calling Listing System Canada',
  offerImage,
  upsell: {
    productHandler: 'monthly-with-trial-10',
    productPricePointHandle: 'monthly-with-trial-10',
    componentId: isProduction ? '1824463' : '2197557',
    component: () => <Upsell productName="No Code Calling Guide" />,
    offerId: isProduction ? '654b6e320329a5197ff40c21' : '654b15520329a5197ff40c0b',
  },
};

export default nccFunnelCA;
