import WebFont from 'webfontloader';

const allowed = ['production', 'staging'];

const fonts = (families) => {
  if (WebFont) {
    WebFont.load({
      google: {
        families,
      },
    });
  }
};

const intercom = () => {
  if (!window.Intercom) {
    return;
  }
  window.Intercom('boot', {
    app_id: window.APP_ID,
  });
};

const plugins = () => {
  if (allowed.indexOf(process.env.NODE_ENV) >= 0) {
    intercom();
  }

  const families = [
    'Caladea',
    'Minion Pro',
    'Oswald',
    'Roboto',
    'Roboto Condensed',
    'Frank Ruhl Libre',
    'Heebo',
    'Lato',
    'Rubik',
  ];

  fonts(families);
};

export default plugins;
