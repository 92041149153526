const Navbar = ({ rightItems, leftItems }) => {
  return (
    <nav className=" bg-neutral-800 h-[64px] py-2">
      <div className="text-neutral-50 justify-between text-base font-semibold leading-[46px] flex items-center h-full">
        {rightItems}

        <div className="mr-4 flex items-center">{leftItems}</div>
      </div>
    </nav>
  );
};

export default Navbar;
