function CircleCheck() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11007_167029)">
        <circle cx="9.99935" cy="9.99935" r="8.33333" stroke="#069668" strokeWidth="1.5" />
        <path
          d="M7.08398 10.416L8.75065 12.0827L12.9173 7.91602"
          stroke="#069668"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11007_167029">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CircleCheck;
