import styled from 'styled-components';

const CenterSection = styled.div`
  text-align: center;
  padding: 20px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 576px) {
    padding: 0;
    height: 100vh;
  }
`;

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export default {
  CenterSection,
  Frame,
};
