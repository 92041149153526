import { BookList } from 'modules/dashboard/components';

import { Space } from 'antd';
import { Guide } from '../../types';
import S from './styles';

const BookPreview = ({ guide, linkUrl, handleStartOrder }: { guide: Guide; linkUrl: string }) => {
  const { thumbnail } = guide;

  return (
    <S.Preview>
      <BookList>
        <S.GuideContainer>
          <S.ImageContainer>
            <S.Image src={thumbnail} className="image-container" />
            <S.ButtonContainer>
              <S.ButtonGroup>
                <Space direction="vertical">
                  <S.WhiteButton type="primary" href={linkUrl} size="middle">
                    Preview
                  </S.WhiteButton>
                  <S.Button type="primary" size="middle" onClick={() => handleStartOrder(guide)}>
                    Order Guide
                  </S.Button>
                </Space>
              </S.ButtonGroup>
            </S.ButtonContainer>
          </S.ImageContainer>
        </S.GuideContainer>
      </BookList>
    </S.Preview>
  );
};

export default BookPreview;
