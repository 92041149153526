import styled from 'styled-components';
import { Button } from 'antd';

import * as color from 'modules/common/theme/color';

const InfoButton = styled(Button)`
  font-weight: normal !important;
  color: ${color.SkyBlue};
`;

export default {
  InfoButton,
};
