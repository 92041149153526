import { useState, useEffect } from 'react';
import { filter, find, get, isEmpty } from 'lodash-es';
import { Switch } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Modal } from 'modules/v2/common/components';
import { DashboardTemplate } from 'modules/v2/templates';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';

import {
  deleteCustomerTemplates,
  getAttributes,
  getCustomerTemplates,
  getEmailTemplates,
} from 'modules/api';
import { notification } from 'modules/v2/common/utils';

import { PencilIcon, TrashIcon } from 'modules/v2/common/components/SvgIcon';
import {
  getRouteCreateCustomerTemplate,
  getRouteCreateCustomerTemplateFromScratch,
  getRouteEditCustomerTemplate,
} from 'modules/dashboard/routes/navigation';

import Table from 'modules/v2/common/components/Table';

import { parseCustomerTemplates, parseHtmlTemplate } from './utils';
import S from './styles';

const EmailTemplates = ({ history }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [showTemplate, setShowTemplate] = useState('');
  const [currentTemplatesPage, setCurrentTemplatesPage] = useState(1);
  const [currentCustomerTemplatesPage, setCurrentCustomerTemplatesPage] = useState(1);
  const [showCustomerTemplates, setShowCustomerTemplates] = useState(false);
  const [customerTemplates, setCustomerTemplates] = useState([]);
  const [templatesPagination, setTemplatesPagination] = useState({
    pageSize: 25,
    perPage: 25,
    current: 1,
  });
  const [customerTemplatesPagination, setCustomerTemplatesPagination] = useState({
    pageSize: 25,
    perPage: 25,
    current: 1,
  });
  const [allTemplates, setAllTemplates] = useState([]);
  const [idToBeDeleted, setIdToBeDeleted] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userAttributes, setUserAttributes] = useState({
    memberEmail: '',
    memberFirstName: '',
    memberLastName: '',
    memberPhone: '',
    imageUrl: '',
    memberBookUrl: '',
    memberAddress: '',
    memberBrokerName: '',
  });
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowSize < 1024;

  const queryClient = useQueryClient();

  const { perPage } = templatesPagination;

  useQuery('getUserAttributes', getAttributes, {
    onSuccess: (data) => {
      const attributes = get(data, 'data', {});
      setUserAttributes({ ...attributes });
    },
  });

  const { isLoading: isCustomerTemplatesLoading } = useQuery(
    ['getCustomerTemplates', { perPage, currentTemplatesPage }],
    () => getCustomerTemplates(perPage, currentTemplatesPage),
    {
      // eslint-disable-next-line consistent-return
      onSuccess: (data) => {
        const newCustomerTemplates = data?.data;

        if (!newCustomerTemplates) {
          return notification.error({
            title: 'Error',
            description: 'There was an error. Please try again.',
          });
        }

        const meta = data?.meta || {};
        const { perPage: newPerPage, currentPage: current, total } = meta;

        setCustomerTemplates(newCustomerTemplates);
        setCustomerTemplatesPagination({
          pageSize: newPerPage,
          size: perPage,
          perPage,
          total,
          current,
        });
      },
    },
  );

  const { isLoading: isTemplatesLoading } = useQuery(
    ['getTemplates', { perPage, currentTemplatesPage }],
    () => getEmailTemplates(perPage, currentTemplatesPage),
    {
      onSuccess: (data) => {
        const templatesReceived = get(data, 'data', []);
        const templatesWithCustomVersions = parseCustomerTemplates(templatesReceived);

        const meta = get(data, 'meta', {});
        const { perPage: newPerPage, currentPage: current, total } = meta;

        setTemplatesPagination({
          pageSize: newPerPage,
          size: perPage,
          perPage,
          total,
          current,
        });

        setAllTemplates(templatesWithCustomVersions);
      },
    },
  );

  const {
    mutate: mutateDeleteCustomerTemplates,
    isLoading: isDeleteCustomerTemplatesLoading,
  } = useMutation(deleteCustomerTemplates, {
    onSuccess: ({ data }) => {
      const filteredList = filter(
        allTemplates,
        ({ templateId }) => templateId !== data?.templateId,
      );

      setAllTemplates(filteredList);

      queryClient.invalidateQueries(['getCustomerTemplates', { perPage, currentTemplatesPage }]);
      notification.success({
        description: 'Your template was deleted successfully!',
      });
    },
    onError: () => {
      notification.error({
        title: 'Error',
        description: 'Something went wrong, please verify your data and try again.',
      });
    },
  });

  const handleCloseModal = () => {
    setShowTemplate('');
    setIsInfoModalOpen(false);
  };

  const handleShowTemplate = (id) => () => {
    setIsInfoModalOpen(true);
    const currentTemplate = find(allTemplates, { id });
    setShowTemplate(currentTemplate.content);
  };

  const handleShowCustomerTemplate = (templateId) => () => {
    setIsInfoModalOpen(true);
    const currentTemplate = find(customerTemplates, { templateId });
    setShowTemplate(currentTemplate.content);
  };

  const handleCreateCustomerTemplate = (templateBaseId) =>
    history.replace(getRouteCreateCustomerTemplate(templateBaseId));

  const handleCreateCustomerTemplateFromScratch = () => {
    history.replace(getRouteCreateCustomerTemplateFromScratch());
  };

  const handleEditCustomerTemplate = (id) => history.replace(getRouteEditCustomerTemplate(id));

  const handleDeleteCustomerTemplate = (id) => {
    setIdToBeDeleted(id);
    setShowDeleteModal(true);
  };

  const handleModalSubmit = (id) => {
    if (id) mutateDeleteCustomerTemplates(id);
    setShowDeleteModal(false);
  };

  const handleModalCancel = () => {
    setShowDeleteModal(false);
  };

  const getEditLink = (customerTemplateId, templateId, id) => {
    if (customerTemplateId) {
      return handleEditCustomerTemplate(customerTemplateId);
    }

    if (templateId) {
      return handleEditCustomerTemplate(id);
    }

    return handleCreateCustomerTemplate(id);
  };

  const handleActionButtons = (record) => {
    const { customerTemplateId, id, templateId } = record;

    return (
      <div className="flex flex-nowrap gap-2">
        <Button
          size="sm"
          type="muted"
          onClick={() => getEditLink(customerTemplateId, templateId, id)}
        >
          <PencilIcon />
        </Button>
        {customerTemplateId || showCustomerTemplates ? (
          <Button
            size="sm"
            type="muted"
            onClick={() => handleDeleteCustomerTemplate(customerTemplateId || id)}
          >
            <TrashIcon fill="#252525" />
          </Button>
        ) : null}
      </div>
    );
  };

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '358px',
      render: (name, record) => {
        const { templateId, id } = record;

        if (templateId) {
          return (
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={handleShowCustomerTemplate(templateId)}
            >
              {name}
            </div>
          );
        }

        return (
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={handleShowTemplate(id)}
          >
            {name}
          </div>
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Action',
      key: 'action',
      width: '180px',
      fixed: 'right',
      render: handleActionButtons,
    },
  ];

  if (isMobile) {
    const actionsColumn = tableColumns.find((col) => col.key === 'action');
    if (actionsColumn && actionsColumn.fixed === 'right') {
      delete actionsColumn.fixed;
    }
  }

  const templateTitle = showCustomerTemplates ? 'Custom templates' : 'Available templates';

  const isTableLoading =
    isDeleteCustomerTemplatesLoading || isTemplatesLoading || isCustomerTemplatesLoading;

  return (
    <DashboardTemplate contentTitle={templateTitle} hasSidebar isLoading={isTableLoading}>
      <div className="text-2xl font-bold flex-1 mb-6">Email templates</div>

      <div className="w-full flex items-center justify-between bg-neutral-0 py-[22px] px-6 rounded-t-lg shadow-box max-[550px]:flex-col max-[550px]:gap-y-2">
        <S.SwitchContainer>
          <Switch
            checked={showCustomerTemplates}
            onChange={() => setShowCustomerTemplates((value) => !value)}
          />
          <p className="ml-[12px] font-semibold">Show custom templates only</p>
        </S.SwitchContainer>

        <Button onClick={handleCreateCustomerTemplateFromScratch}>Create new template</Button>
      </div>

      {showCustomerTemplates ? (
        <Table
          loading={isTableLoading}
          hasData={!isEmpty(customerTemplates)}
          rowKey="id"
          columns={tableColumns}
          dataSource={customerTemplates}
          pagination={{
            ...customerTemplatesPagination,
            current: currentCustomerTemplatesPage,
            onChange: setCurrentCustomerTemplatesPage,
          }}
          scroll={{ x: 'max-content' }}
        />
      ) : (
        <Table
          loading={isTableLoading}
          hasData={!isEmpty(allTemplates)}
          rowKey="id"
          columns={tableColumns}
          dataSource={allTemplates}
          pagination={{
            ...templatesPagination,
            current: currentTemplatesPage,
            onChange: setCurrentTemplatesPage,
          }}
          scroll={{ x: 'max-content' }}
        />
      )}

      <Modal
        isOpen={isInfoModalOpen}
        width="720px"
        title="Template preview"
        onConfirm={handleCloseModal}
        onCancel={handleCloseModal}
        okText="Ok"
        footer={[
          <div className="flex justify-end">
            <Button type="outlined" onClick={() => setIsInfoModalOpen(false)}>
              Ok
            </Button>
          </div>,
        ]}
      >
        <S.TemplateWrapper
          dangerouslySetInnerHTML={{
            __html: parseHtmlTemplate(showTemplate, userAttributes),
          }}
        />
      </Modal>
      <Modal
        title="Delete custom template"
        isOpen={showDeleteModal}
        onCancel={handleModalCancel}
        hasCloseButton
        footer={
          <div className="flex justify-end h-[48px] gap-x-2">
            <Button type="outlined" onClick={handleModalCancel}>
              Cancel
            </Button>
            <Button type="danger" onClick={() => handleModalSubmit(idToBeDeleted)}>
              Yes, Delete custom template
            </Button>
          </div>
        }
      >
        <S.Wrapper>
          <div>
            If you delete this, you can no longer retrieve it.
            <br />
            Would you like to delete this template?
          </div>
        </S.Wrapper>
      </Modal>
    </DashboardTemplate>
  );
};

export default EmailTemplates;
