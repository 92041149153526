import LogoImg from 'assets/images/logo/logo_alt.png';

import env from 'environments';
import S from './styles';

const Header = () => (
  <S.Wrap>
    <S.InnerWrap>
      <S.Logo src={LogoImg} alt="logo" />
      <S.HeaderText>Contact Us: {env.SUPPORT_PHONE}</S.HeaderText>
    </S.InnerWrap>
  </S.Wrap>
);

export default Header;
