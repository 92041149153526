import S from './styles';

const DeleteLeads = () => (
  <S.Wrapper>
    <S.Title>Deleting This Lead(s)</S.Title>
    <S.Text>If you delete this lead(s), you can no longer retrieve this.</S.Text>
    <S.Text>Would you like to delete this lead(s)?</S.Text>
  </S.Wrapper>
);

export default DeleteLeads;
