import { findIndex, get, map } from 'lodash-es';

import {
  UPDATE_COVER_IMAGE,
  UPDATE_COVER_ELEMENT,
  UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
  UPDATE_COVER_ACTIVE_TEMPLATE,
  UPDATE_CONTENT_ELEMENT,
  UPDATE_CONTENT_ACTIVE_TEMPLATE,
} from 'modules/v2/editor/store/constants';

import bookpageReducer from './bookpage';

const reducer = (templates = [], action) => {
  switch (action.type) {
    case UPDATE_COVER_IMAGE:
    case UPDATE_COVER_ELEMENT:
    case UPDATE_COVER_ELEMENTS_BY_PREFERENCES:
    case UPDATE_CONTENT_ELEMENT: {
      const { templateId } = action.payload;
      const index = findIndex(templates, ({ _id }) => _id === templateId);

      if (index < 0) {
        return templates;
      }

      const bookpage = get(templates, [index, 'bookpage'], []);

      const template = {
        ...templates[index],
        bookpage: bookpageReducer(bookpage, action),
      };

      return [...templates.slice(0, index), template, ...templates.slice(index + 1)];
    }

    case UPDATE_COVER_ACTIVE_TEMPLATE:
    case UPDATE_CONTENT_ACTIVE_TEMPLATE: {
      const { templateId } = action.payload;
      const index = findIndex(templates, ({ _id }) => _id === templateId);

      if (index < 0) {
        return templates;
      }

      return map(templates, (template) => {
        let { isActive } = template;

        // It should be defined only in case the isActive is truthy
        // because there are null values and we need to avoid re-renders
        if (template.isActive) {
          isActive = false;
        }

        // It should be defined after modifying the isActive
        if (template._id === templateId) {
          isActive = true;
        }

        return {
          ...template,
          isActive,
        };
      });
    }

    default:
      return templates;
  }
};

export default reducer;
