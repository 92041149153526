import styled from 'styled-components';

import * as color from 'modules/common/theme/color';

const ListItem = styled.li`
  padding-top: 20px;

  &:last-child {
    padding-bottom: 20px;
  }
`;

ListItem.Paragraph = styled.p`
  color: ${color.GreySelectDd};
  padding-top: 7px;
  text-transform: capitalize;
`;

ListItem.Anchor = styled.a`
  display: block;
  margin: 0 auto;

  img {
    width: 127px;
    height: 190px;
    display: block;
    object-fit: cover;
    border: 2px solid ${color.GreyFormBorder};
    margin: 0 auto;
  }

  &:hover,
  &.selected {
    img {
      border-color: ${color.ImgBorder};
    }
  }
`;

export default {
  ListItem,
};
