import { FC } from 'react';
import { ModalCustomProps, StyledComponentModalExtended } from './types';

import { Modal as StyledModal, ImagePickerModal, GeneralModal, Tabs, Button } from './styles';

const Modal: FC<ModalCustomProps> & StyledComponentModalExtended = ({
  children,
  title,
  isOpen,
  width,
  footer,
  okText,
  cancelText,
  maskClosable,
  hasCloseButton,
  onConfirm,
  onCancel,
  cancelButtonProps,
  ...props
}) => {
  let ComponentToRender;

  switch (title) {
    case 'Add Image':
    case 'Upload photo':
      ComponentToRender = ImagePickerModal;
      break;
    case 'Edit your broker information':
    case 'Delete Image':
      ComponentToRender = GeneralModal;
      break;  
    default:
      ComponentToRender = StyledModal;
  }

  return (
    <ComponentToRender
      title={title}
      open={isOpen}
      width={width}
      footer={footer}
      okText={okText}
      cancelText={cancelText}
      maskClosable={maskClosable}
      hasCloseButton={hasCloseButton}
      onOk={onConfirm}
      onCancel={onCancel}
      cancelButtonProps={{
        className: 'ant-btn-cancel',
        ...cancelButtonProps,
      }}
      {...props}
    >
      {children}
    </ComponentToRender>
  );
};

Modal.Tabs = Tabs;
Modal.Button = Button;

export default Modal;
