import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device as breakPoints } from 'modules/common/theme/mediaqueries';

const ScheduleCardItem = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => (props.active ? color.White : color.LightHeadGray)};
  font-weight: normal;
  padding: 11px 4px;
  background: ${(props) => (props.active ? color.SkyBlue : color.White)} 0% 0% no-repeat padding-box;
  border: 1px solid ${(props) => (props.active ? color.SkyBlue : color.LightDullGray)};
  border-radius: 2px;
  opacity: 1;
  cursor: pointer;
  width: 21.7%;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 28%;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    width: 42%;
  }
  @media (max-width: 360px) {
    width: 41%;
  }
  &:hover {
    background: ${color.PhoneLink};
    color: ${color.White};
    border: 1px solid ${color.PhoneLink};
  }
`;

const ScheduleCardsTimeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @media ${breakPoints.tabletS} {
    padding: 0 15px;
    overflow: auto;
  }
`;

const ScheduleCardsDateList = styled(ScheduleCardsTimeList)`
  flex: 1;
  display: flex;
  justify-content: space-between;

  margin: 0 auto;
  width: 630px;

  span {
    font-size: 12px;
  }

  @media screen and ${breakPoints.tabletS} {
    width: 100%;
    justify-content: center;
  }
`;

const TimeDateCard = styled.div`
  color: ${color.grayBox};
  border: ${({ active }) => (active ? '1.5px #0096C7 solid' : `1.5px solid ${color.GrayBorder}`)};

  transition: all 0.4s;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  height: 60px;
  margin-bottom: 10px;
  font-size: 12px !important;
  padding: 0 5px;
  text-transform: uppercase;
  width: 300px;

  span {
    color: black;
  }

  &:hover {
    border: 1.5px solid #0096c7;
  }

  @media screen and ${breakPoints.tabletS} {
  }
`;

const TimeText = styled.span`
  line-height: 24px;
  font-size: 14px;
  color: ${(props) => (props.active ? color.White : color.LightHeadGray)};

  padding: 7px;
  width: 200px;

  font-weight: 400;
  @media (max-width: 600px) {
    padding: 7px 10px;
  }
  @media (max-width: 520px) {
    padding: 7px 5px;
  }
  @media (max-width: 520px) {
    padding: 7px 20px;
  }
`;

const DateText = styled.span`
  line-height: 24px;
  font-size: 14px;
  color: ${(props) => (props.active ? color.White : color.LightHeadGray)};
  border-left: 1px solid ${color.LightDullGray};
  flex: 2;
  text-align: center;
  @media (max-width: 600px) {
    padding: 7px 10px;
  }
`;

const LoaderContainer = styled.div`
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export default {
  LoaderContainer,
  ScheduleCardsTimeList,
  ScheduleCardsDateList,
  ScheduleCardItem,
  TimeDateCard,
  TimeText,
  DateText,
};
