import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const CardWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px 20px 0px;
`;

const CardNumber = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.Black};
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${color.grayDot};
  border-radius: 50%;
  margin-right: 5px;
`;

const DefaultWrap = styled.div`
  width: 73px;
  height: 30px;
  background-color: ${({ $transparent }) =>
    $transparent ? color.transparent : color.defaultLightGray};
  border-radius: 2px;
  margin: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.TooltipTextColor};
`;

const AddPaymentMethod = styled.a`
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  color: ${color.InputFocusBorder};
  text-decoration: underline !important;
  margin-bottom: 40px;
  display: block;
`;

const CardImage = styled.div`
  margin-right: 15px;
  margin-right: 15px;
  background-color: ${({ $icon }) =>
    // eslint-disable-next-line no-nested-ternary
    $icon === 'visa' ? color.SideBarActiveText : $icon === 'mastercard' ? color.grayBox : ''};
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`;

export default {
  CardWrap,
  CardNumber,
  Dot,
  DefaultWrap,
  AddPaymentMethod,
  CardImage,
};
