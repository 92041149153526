import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Type offer name'),
  code: Yup.string()
    .trim()
    .required('Type offer code'),
  description1: Yup.string()
    .trim()
    .required('Type offer description here')
    .min(100, 'Description must be at least 100 characters long'),
});

export default validationSchema;
