import { useContext } from 'react';

import { QuestionCircleFilled } from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { PlansContext } from 'modules/v2/pages/Plans/contexts/UpgradePlan';

import { planHandle } from 'modules/v2/pages/Plans/constants';

import S from '../../styles';

const { Title, Text } = Typography;

export const contractedPlanData = [
  {
    key: '1',
    name: (
      <Space direction="horizontal">
        <S.TableText>Licensed Books</S.TableText>
        <Tooltip
          placement="right"
          title="Dive into our meticulously curated collection of licensed books authored by industry experts. When you offer your clients valuable resources through licensed books, you demonstrate your commitment to their success and build trust, making them more likely to choose you as their agent."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Licensed Books',
    digitalPlusPlan: '2',
    digitalPlusPlusPlan: '2',
    premiumPlusPlan: '4',
    platinumPlusPlan: '25',
  },
  {
    key: '2',
    name: (
      <Space direction="horizontal">
        <S.TableText>Digital Books</S.TableText>
        <Tooltip
          placement="right"
          title="You can share and conduct presentations with your digital book with anyone in the world. Allowing you to expand your reach and connect with clients regardless of their location."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Digital Books',
    digitalPlusPlan: '2',
    digitalPlusPlusPlan: '2',
    premiumPlusPlan: '4',
    platinumPlusPlan: '25',
  },

  {
    key: '3',
    name: (
      <Space direction="horizontal">
        <S.TableText>Print Credits</S.TableText>
        <Tooltip
          placement="right"
          title="Our printed books can be customized with your branding elements, including your logo, contact information, and personalized messages.

This creates a powerful branding opportunity, reinforcing your professional image and ensuring that clients remember you long after they've finished reading."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Print Credits',
    digitalPlusPlan: '20 credits one-time',
    digitalPlusPlusPlan: '100 credits one-time',
    premiumPlusPlan: 20,
    platinumPlusPlan: 50,
  },

  {
    key: '4',
    name: (
      <Space direction="horizontal">
        <S.TableText>Lead Capture Websites</S.TableText>
        <Tooltip
          placement="right"
          title="Grow your real estate business with our powerful lead capture sites. Get instant notifications for new leads, enabling you to follow up promptly and secure the deal."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Lead Capture Websites',
    digitalPlusPlan: '2',
    digitalPlusPlusPlan: '2',
    premiumPlusPlan: '4',
    platinumPlusPlan: '25',
  },

  {
    key: '5',
    name: (
      <Space direction="horizontal">
        <S.TableText>Marketing Templates</S.TableText>
        <Tooltip
          placement="right"
          title="Save time and effort while maximizing your reach with our ready-to-use and customizable marketing templates and scripts to boost lead generation.

Our templates are designed to grab attention and convey your expertise, allowing you to effectively communicate your unique value proposition and differentiate yourself as a trusted authority in the real estate market."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Marketing Templates',
    digitalPlusPlan: <S.CheckOutlined />,
    digitalPlusPlusPlan: <S.CheckOutlined />,
    premiumPlusPlan: <S.CheckOutlined />,
    platinumPlusPlan: <S.CheckOutlined />,
  },
  {
    key: '6',
    name: (
      <Space direction="horizontal">
        <S.TableText>Training Site Access</S.TableText>
        <Tooltip
          placement="right"
          title="Designed to supercharge your sales and maximize the potential of your Authorify membership, providing you with the following essential resources: Success Training, Marketing Templates, Live Coaching, and Member Interviews."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Training Site Access',
    digitalPlusPlan: <S.CheckOutlined />,
    digitalPlusPlusPlan: <S.CheckOutlined />,
    premiumPlusPlan: <S.CheckOutlined />,
    platinumPlusPlan: <S.CheckOutlined />,
  },

  {
    key: '7',
    name: (
      <Space direction="horizontal">
        <S.TableText>Weekly Live Coaching</S.TableText>
        <Tooltip
          placement="right"
          title="Benefit from weekly live coaching sessions with industry experts who will guide you through personalized strategies and provide valuable insights. "
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Weekly Live Coaching',
    digitalPlusPlan: <S.CheckOutlined />,
    digitalPlusPlusPlan: <S.CheckOutlined />,
    premiumPlusPlan: <S.CheckOutlined />,
    platinumPlusPlan: <S.CheckOutlined />,
  },
  {
    key: '8',
    name: (
      <Space direction="horizontal">
        <S.TableText>Retargeting</S.TableText>
        <Tooltip
          placement="right"
          title="Reach your ideal audience with precision and purpose. Our retargeting campaigns strategically place you in front of individuals who have previously shown interest in your real estate offerings. By targeting these warm leads, you can significantly increase the chances of converting them into loyal clients."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Retargeting',
    digitalPlusPlan: <S.CheckOutlined />,
    digitalPlusPlusPlan: <S.CheckOutlined />,
    premiumPlusPlan: <S.CheckOutlined />,
    platinumPlusPlan: <S.CheckOutlined />,
  },

  {
    key: '9',
    name: (
      <Space direction="horizontal">
        <S.TableText>Google My Business</S.TableText>
        <Tooltip
          placement="right"
          title="Unlock the full potential of your Google Business Profile with Google My Business by Authorify. Take control of your online visibility, generate more leads, and cultivate a positive reputation that sets you apart in your industry."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Google My Business',
    digitalPlusPlan: <S.CheckOutlined />,
    digitalPlusPlusPlan: <S.CheckOutlined />,
    premiumPlusPlan: <S.CheckOutlined />,
    platinumPlusPlan: <S.CheckOutlined />,
  },
  {
    key: '10',
    name: (
      <Space direction="horizontal">
        <S.TableText>White-Glove Services</S.TableText>
        <Tooltip
          placement="right"
          title="Experience a stress-free publishing journey with our premium concierge services, catering to your unique author needs."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'White-Glove Services',
    digitalPlusPlan: <S.CloseOutlined />,
    digitalPlusPlusPlan: <S.CloseOutlined />,
    premiumPlusPlan: <S.CheckOutlined />,
    platinumPlusPlan: <S.CheckOutlined />,
  },

  {
    key: '11',
    name: (
      <Space direction="horizontal">
        <S.TableText>Social Media Training Site</S.TableText>
        <Tooltip
          placement="right"
          title="Harness the power of social media with our training site access, mastering the art of engagement and building a loyal reader community."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Social Media Training Site',
    digitalPlusPlan: <S.CloseOutlined />,
    digitalPlusPlusPlan: <S.CloseOutlined />,
    premiumPlusPlan: <S.CloseOutlined />,
    platinumPlusPlan: <S.CheckOutlined />,
  },
];

export const TitleTemplate = (isActive, planTitle, description, price, plan, productHandle) => {
  const { setSelectedPlan, toggleReviewAndPayModal, toggleUpgradePlanModal } =
    useContext(PlansContext);
  const handleClick = () => {
    const isMonthly = price.includes('mo');
    const priceOnly = price?.split('/')[0]?.trim();
    const planDetails = {
      plan,
      price: priceOnly,
      productHandle,
      planTitle,
      isMonthly,
    };
    const packageDetails = {};
    contractedPlanData.forEach((item) => {
      const { attribute } = item;
      if (attribute === 'Print Credits' && typeof item[plan] === 'number') {
        if (isMonthly) {
          packageDetails[attribute] = `${item[plan]} monthly`;
          return;
        }
        packageDetails[attribute] = `${item[plan] * 12} yearly`;
        return;
      }
      packageDetails[attribute] = item[plan];
    });
    planDetails.packageDetails = packageDetails;
    setSelectedPlan(planDetails);
    toggleReviewAndPayModal();
    toggleUpgradePlanModal();
  };
  return (
    <S.PerPlanBox isActive={isActive}>
      <Title level={4}>{planTitle}</Title>
      <Text type="secondary">{description}</Text>
      <S.PlanPrice strong>{price}</S.PlanPrice>
      <S.UpgradePlanButton block disabled={isActive} onClick={handleClick}>
        {isActive ? 'Current Plan' : 'Upgrade Plan'}
      </S.UpgradePlanButton>
    </S.PerPlanBox>
  );
};

export const renderText = (text, isMonthly = true) => {
  let finalText = text;
  if (typeof text === 'number') {
    if (isMonthly) {
      finalText = `${text} credits per month`;
    } else {
      finalText = `${text * 12} credits per year`;
    }
  }
  return <S.TableText>{finalText}</S.TableText>;
};
export const contractedColumnsMonthly = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: '25%',
    render: (text) => <S.TableText>{text}</S.TableText>,
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.DIGITAL_PLUS_MONTHLY,
        'Digital+ Plan',
        'Cost-effective and efficient. Perfect for real estate agents seeking to establish their online presence.',
        '$149 / mo',
        'digitalPlusPlan',
        planHandle.DIGITAL_PLUS_MONTHLY,
      ),

    dataIndex: 'digitalPlusPlan',
    key: planHandle.DIGITAL_PLUS_MONTHLY,
    width: '25%',
    render: (text) => renderText(text),
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.PREMIUM_PLUS_MONTHLY,
        'Premium+ Plan',
        'Elevate your success beyond the Digital+ Plan offering all the benefits of the Digital+ Plan plus additional perks.',
        '$259 / mo',
        'premiumPlusPlan',
        planHandle.PREMIUM_PLUS_MONTHLY,
      ),

    dataIndex: 'premiumPlusPlan',
    key: planHandle.PREMIUM_PLUS_MONTHLY,
    width: '25%',
    render: (text) => renderText(text),
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.PLATINUM_PLUS_MONTHLY,
        'Platinum+ Plan',
        'Experience the ultimate level of services. Includes everything from the Premium+ Plan with these exclusive advantages.',
        '$399 / mo',
        'platinumPlusPlan',
        planHandle.PLATINUM_PLUS_MONTHLY,
      ),

    dataIndex: 'platinumPlusPlan',
    key: planHandle.PLATINUM_PLUS_MONTHLY,
    width: '25%',
    render: (text) => renderText(text),
  },
];

export const contractedColumnsYearly = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: '18%',
    render: (text) => <S.TableText>{text}</S.TableText>,
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.DIGITAL_PLUS_ANNUAL,
        'Digital+ Plan',
        'Cost-effective and efficient. Perfect for real estate agents seeking to establish their online presence.',
        '$1490 / year',
        'digitalPlusPlan',
        planHandle.DIGITAL_PLUS_ANNUAL,
      ),
    dataIndex: 'digitalPlusPlan',
    key: planHandle.DIGITAL_PLUS_ANNUAL,
    width: '20%',
    render: (text) => renderText(text, false),
  },

  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.DIGITAL_PLUS_PLUS_ANNUAL,
        'Digital++ Plan',
        'Go bigger with Digital++ Plan! Everything offered in the Digital+ Plan, but with 80 more printed books.',
        '$1990 / year',
        'digitalPlusPlusPlan',
        planHandle.DIGITAL_PLUS_PLUS_ANNUAL,
      ),
    dataIndex: 'digitalPlusPlusPlan',
    key: planHandle.DIGITAL_PLUS_PLUS_ANNUAL,
    width: '20%',
    render: (text) => renderText(text, false),
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.PREMIUM_PLUS_ANNUAL,
        'Premium+ Plan',
        'Elevate your success beyond the Digital+ . Offering everything from Digital+ Plan and more perks.',
        '$2590 / year',
        'premiumPlusPlan',
        planHandle.PREMIUM_PLUS_ANNUAL,
      ),

    dataIndex: 'premiumPlusPlan',
    key: planHandle.PREMIUM_PLUS_ANNUAL,
    width: '20%',
    render: (text) => renderText(text, false),
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.PLATINUM_PLUS_ANNUAL,
        'Platinum+ Plan',
        'Experience the ultimate level of services. Includes everything from the Premium+ Plan with these exclusive advantages.',
        '$3990 / year',
        'platinumPlusPlan',
        planHandle.PLATINUM_PLUS_ANNUAL,
      ),

    dataIndex: 'platinumPlusPlan',
    key: planHandle.PLATINUM_PLUS_ANNUAL,
    width: '20%',
    render: (text) => renderText(text, false),
  },
];
