import { useMutation } from 'react-query';
import { saveLeads } from 'modules/api/digitalServices';

export const useSaveNewLead = (data, successCallback, errorCallback) => {
  const {
    mutate: saveNewLead,
    isLoading: isSaveNewLeadLoading,
    isError,
  } = useMutation(saveLeads, {
    onSuccess: successCallback,
    onError: errorCallback,
  });

  return { saveNewLead, isSaveNewLeadLoading, isError };
};
