import { DateTime } from 'luxon';
import get from 'lodash/get';
import { getInstance } from './instance';

const instance = getInstance();

export const getAppConfig = () => {
  const query = '?filters[key]=afy-ui';
  return instance
    .get(`/cms/app-configs${query}`)
    .then((response) => get(response, 'data.data[0].attributes.value'))
    .catch((error) => Promise.reject(error));
};

export const getLastMagazine = () => {
  let now = DateTime.now();

  // This logic is necessary because we rotate the magazines by the 21 of every month
  if (now.day >= 21) {
    now = now.plus({ months: 1 });
  }

  const nextMonth = now.plus({ months: 1 }).toFormat('MMMM');

  const contentType = 'rm-magazines';
  const query = `?filters[month]=${nextMonth}&populate[frontCoverDesign][populate]=displayImage`;

  return instance
    .get(`/cms/${contentType}${query}`)
    .then(({ data }) => data?.data[0].attributes)
    .catch((error) => Promise.reject(error));
};

export const getAppConfigPreviewURLStatus = () => {
  const query = '?filters[key]=RM_PREVIEW_URL';
  return instance
    .get(`/cms/app-configs${query}`)
    .then((response) => get(response, 'data.data[0].attributes.value'))
    .catch((error) => Promise.reject(error));
};
