import { put, takeEvery } from 'redux-saga/effects';

import { clearDraftLinks, clearPreflightCheck } from 'modules/draft/store';

import { CANCEL_GENERATE_DIGITAL_BOOK, CLEAR_DIGITAL_BOOK } from 'modules/draft/store/constants';

export function* onClearDigitalBook() {
  yield put(clearPreflightCheck());
  yield put(clearDraftLinks());
}

export function* watchClearDigitalBook() {
  const pattern = [CANCEL_GENERATE_DIGITAL_BOOK, CLEAR_DIGITAL_BOOK];
  yield takeEvery(pattern, onClearDigitalBook);
}
