import { useEffect, useState } from 'react';
import StatCard from './StatCard';
import { CampaignMetrics } from './types';
import { RateCardsFormatter } from '../../utils';

const RateCards = ({ campaignMetricsData, isLoading }) => {
  const [metrics, setMetrics] = useState<CampaignMetrics[]>([]);

  const formatData = () => {
    const formattedData = RateCardsFormatter(campaignMetricsData);
    setMetrics(formattedData);
  };

  useEffect(() => {
    formatData();
  }, [campaignMetricsData]);

  const cardSize = (index) => (index > 2 ? 'w-1/2' : 'w-1/3');

  return (
    <div className="flex flex-wrap">
      {metrics.map((item: CampaignMetrics, index) => (
        <div
          className={`${cardSize(index)} border-t border-neutral-200 max-sm:w-full`}
          key={item.statTitle}
        >
          <StatCard item={item} hasBorderRight={index !== 2 && index !== 4} />
        </div>
      ))}
    </div>
  );
};

export default RateCards;
