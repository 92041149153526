import { get } from 'lodash-es';

import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';
import { GET_DRAFTS } from 'modules/v2/store/constants';
import { drafts as initialState } from 'modules/v2/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_DRAFTS}_REQUESTED`: {
      return { ...state, data: null, status: STATUS_LOADING };
    }

    case `${GET_DRAFTS}_SUCCEEDED`: {
      const data = get(action, ['payload', 'drafts'], null);

      return {
        ...state,
        status: STATUS_SUCCESS,
        data,
      };
    }

    case `${GET_DRAFTS}_FAILED`: {
      return { ...state, status: STATUS_ERROR };
    }

    default:
      return state;
  }
};

export default reducer;
