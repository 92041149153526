import classNames from 'classnames';

type TitleProps = {
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  className?: string;
};

const Title = ({ children, size = 'medium', className = '' }: TitleProps) => {
  const titleSize = {
    small: 'text-sm font-medium',
    medium: 'text-base font-semibold',
    large: 'text-2xl font-800',
  }[size];

  const mergedClasses = classNames(titleSize, className);

  return <h3 className={classNames(mergedClasses, 'tracking-normal pb-0')}>{children}</h3>;
};

export default Title;
