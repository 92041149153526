import {
  GET_USER_ORDER,
  LIST_USER_ORDERS,
  EDIT_ORDERED_DRAFT,
  REORDER_BOOK,
  GET_ORDER_BY_ID,
  UPDATE_ORDER,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  data: [],
  statusCode: '',
  orders: {
    loading: false,
    loaded: false,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USER_ORDER}_REQUEST`:
      return { ...state, loading: true };

    case GET_USER_ORDER:
      return {
        ...state,
        loaded: true,
        loading: false,
        statusCode: action.payload.statusCode,
        data: action.payload.data ? action.payload.data : [],
      };

    case `${GET_USER_ORDER}_FAILURE`:
      return { ...state, loaded: true };

    case `${LIST_USER_ORDERS}_REQUEST`:
      return { ...state, orders: { ...state.orders, loading: true } };

    case LIST_USER_ORDERS: {
      return {
        ...state,
        orders: {
          loading: false,
          loaded: true,
          data: action.payload.data,
        },
      };
    }

    case `${REORDER_BOOK}_REQUEST`: {
      return {
        ...state,
      };
    }

    case REORDER_BOOK: {
      return {
        ...state,
      };
    }

    case `${REORDER_BOOK}_FAILURE`: {
      return {
        ...state,
      };
    }

    case `${LIST_USER_ORDERS}_FAILURE`: {
      return { ...state, orders: { loading: true, loaded: true } };
    }

    case `${EDIT_ORDERED_DRAFT}_REQUEST`: {
      return {
        ...state,
        orders: {
          loading: true,
        },
      };
    }

    case EDIT_ORDERED_DRAFT: {
      return {
        ...state,
        orders: {
          loading: false,
          loaded: true,
        },
      };
    }

    case `${EDIT_ORDERED_DRAFT}_FAILURE`: {
      return {
        ...state,
        orders: {
          loading: true,
          loaded: true,
        },
      };
    }

    case `${GET_ORDER_BY_ID}_REQUEST`: {
      return {
        ...state,
      };
    }

    case GET_ORDER_BY_ID: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data ? action.payload.data : [],
        statusCode: action.payload.statusCode,
      };
    }

    case `${GET_ORDER_BY_ID}_FAILURE`: {
      return {
        ...state,
      };
    }

    case `${UPDATE_ORDER}_REQUEST`: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case UPDATE_ORDER: {
      return { ...state, loading: false, loaded: true };
    }

    case `${UPDATE_ORDER}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    default:
      return { ...state };
  }
};
