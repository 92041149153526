import { call, put, takeEvery } from 'redux-saga/effects';

import { deleteUserAddress, resource, getError } from 'modules/api';

import { DELETE_USER_ADDRESS } from 'modules/publish/store/constants';
import { getUserAddresses } from 'modules/publish/store/actions';

export function* onDeleteUserAddress({ payload: meta }) {
  try {
    const request = resource(DELETE_USER_ADDRESS, deleteUserAddress);
    const payload = yield call(request, meta);

    yield put(getUserAddresses());

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchDeleteAddress() {
  yield takeEvery(DELETE_USER_ADDRESS, onDeleteUserAddress);
}
