import styled, { css } from 'styled-components';
import BaseInputMask from 'react-input-mask';
import { device } from 'modules/common/theme/mediaqueries';

import { FormLabel } from 'modules/common/components';
import * as color from 'modules/common/theme/color';

const TrainingWebinars = styled.div`
  @media (max-width: 768px) {
    margin-top: 0;
    padding: 0 10px;
  }
`;

const TrainingWebinarHeading = styled.h3`
  line-height: 24px !important;
  font-size: 25px !important;
  color: ${color.Black};
  text-align: center;
  font-weight: 400;
  margin-bottom: 18px !important;

  @media (max-width: 768px) {
    margin-bottom: 16px !important;
    font-size: 20px !important;
  }
`;

const TrainingSubHeading = styled.p`
  line-height: 23px;
  font-size: 14px;
  font-weight: 500;
  color: ${color.LightDescriptionGray};
  max-width: 800px;
  margin: 0 auto 57px;
  text-align: center;
`;

const TimeDateBoxWrap = styled.div`
  margin-right: 18px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

const TimeDateHead = styled.h4`
  line-height: 24px;
  font-size: 18px;
  font-weight: 400;
  color: ${color.Black};
  text-align: center;
  flex: 1;

  @media (max-width: 768px) {
    margin-bottom: 10px !important;
  }
`;

const Timezone = styled.p`
  color: ${color.TimezoneGray};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 4px;
  margin-bottom: 16px;
`;

const RegisterFreeWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 13px;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 24px;
  }
`;

const CoverBox = styled.img`
  background-color: #ccc;
  width: 70%;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const PickDateTimeBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  max-width: 70%;
  margin: 20px auto 90px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    max-width: 95%;
    margin: 0 auto;
  }
`;

const TimeDateTopBox = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TimeDateCardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    items-align: center;
  }
`;

const RegisterSubBox = styled.div`
  line-height: 22px;
  font-size: 14px;
  color: ${color.LightHeadGray};
  padding-top: 10px;

  span {
    font-weight: bold;
  }
`;

const ConfirmWrap = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 120px 0;
`;

const ResultWrap = styled.div`
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
`;

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-content: end;

  @media ${device.tabletS} {
    justify-content: center;
    margin: 20px 0;
  }
`;

const ScheduleBox = styled.div`
  width: 58%;
  text-align: left;
  margin: 12px auto auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Label = styled(FormLabel)`
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  font-weight: 600;
  ${(props) =>
    props.error &&
    css`
      font-size: 12px;
    `}
`;

const FormGroup = styled.div`
  margin-top: 11px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ScheduleSmsAlert = styled.div`
  label {
    text-indent: -2.2em;
    padding-left: 30px;
  }
  .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background: ${color.SkyBlue};
    border: 1px solid ${color.SkyBlue};
  }
  .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
    border: 2px solid ${color.White};
    border-top: 0;
    border-left: 0;
  }
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }
  .ant-checkbox-inner::after {
    width: 6.714286px;
    height: 15.142857px;
    top: 10px;
  }
  .sms-text {
    font-size: 16px;
    line-height: 22px;
    color: ${color.Black};
    font-weight: bold;
  }
  .optional-text {
    color: ${color.LightHeadGray};
    font-size: 14px;
    line-height: 22px;
    padding-left: 32px;
  }
`;

const InputMask = styled(BaseInputMask)`
  border: 1px solid ${color.GreyFormBorder};
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : '#2e3f50')};
  background: ${({ disabled }) => (disabled ? color.DisabledInput : color.White)} 0% 0% no-repeat
    padding-box;
  outline-style: none;
  border-radius: 2px;
  height: 40px;
  padding: 4px 10px;

  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${color.ErrorWarning};
    `}
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;

  justify-content: ${({ justifyContent }) => justifyContent || null};
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

export default {
  ConfirmWrap,
  CoverBox,
  PickDateTimeBox,
  RegisterFreeWrap,
  RegisterSubBox,
  ResultWrap,
  SubmitWrapper,
  TimeDateBoxWrap,
  TimeDateCardWrap,
  TimeDateHead,
  TimeDateTopBox,
  TrainingSubHeading,
  TrainingWebinarHeading,
  TrainingWebinars,
  ScheduleBox,
  FormGroup,
  ScheduleSmsAlert,
  InputMask,
  FieldGroup,
  Label,
  Timezone,
};
