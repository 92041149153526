import { LandingPageIcon, LeadIcon, NewBookIcon } from 'modules/v2/common/components/SvgIcon';

const BookCards = ({ visits, leads, digitalVisits, loading }) => {
  return (
    <div className="flex w-full items-center bg-neutral-0 border-b shadow-md max-[700px]:flex-col">
      <div className="w-1/3 border-r p-[36px] max-[700px]:w-full max-[700px]:flex max-[700px]:items-center max-[700px]:justify-center max-[700px]:border-b max-[700px]:border-r-0">
        <div className="flex items-center gap-2 text-sm font-medium flex-wrap max-[700px]:mr-2">
          <LandingPageIcon /> Landing page visits
        </div>
        {loading ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-[30px] bg-gray-200 dark:bg-gray-700 w-8 mt-1" />
          </div>
        ) : (
          <p className="font-bold text-[30px]">{visits?.value ?? 0}</p>
        )}
      </div>

      <div className="w-1/3 border-r p-[36px] max-[700px]:w-full max-[700px]:flex max-[700px]:items-center max-[700px]:justify-center max-[700px]:border-b max-[700px]:border-r-0">
        <div className="flex items-center gap-2 text-sm font-medium flex-wrap max-[700px]:mr-2">
          <LeadIcon active /> Total leads collected
        </div>
        {loading ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-[30px] bg-gray-200 dark:bg-gray-700 w-8 mt-1" />
          </div>
        ) : (
          <p className="font-bold text-[30px]">{leads?.value ?? 0}</p>
        )}
      </div>

      <div className="w-1/3 p-[36px] max-[700px]:w-full max-[700px]:flex max-[700px]:items-center max-[700px]:justify-center">
        <div className="flex items-center gap-2 text-sm font-medium flex-wrap max-[700px]:mr-2">
          <NewBookIcon active /> Digital book visits
        </div>
        {loading ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-[30px] bg-gray-200 dark:bg-gray-700 w-8 mt-1" />
          </div>
        ) : (
          <p className="font-bold text-[30px]">{digitalVisits?.value ?? 0}</p>
        )}
      </div>
    </div>
  );
};

export default BookCards;
