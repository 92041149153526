import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Input as InputBase, DatePicker as DatePickerBase } from 'antd';
import { BackArrowIcon as Icon } from 'modules/common/components';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const { Search } = InputBase;

const SubTitle = styled.div`
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 22px;
`;

const Description = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 22px;
`;

const PreviewLink = styled(Link)`
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: ${color.PrimaryColor};

  margin-left: 10px;

  :hover {
    color: ${color.SecondaryColor};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  .header-title {
    margin-bottom: 12px;

    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 20px;
  }
`;

const LeadsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const MailingListContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const BackArrowIcon = styled(Icon)`
  display: block;
  svg {
    fill: ${color.PrimaryColor};
  }
`;

const BackPage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;

  color: ${color.PrimaryColor};
  font-size: 16px;
  cursor: pointer;

  span {
    font-family: ${font.NunitoFont};
  }
`;

const GetLeads = styled.button`
  display: flex;
  align-items: center;

  width: fit-content;
  padding: 10px 15px;

  border: none;
  border-radius: 2px;
  background-color: ${color.PrimaryColor};

  color: ${color.White};
  text-align: center;
  font-size: 16px;

  :hover {
    background-color: ${color.SecondaryColor};
  }

  transition: transform 0.5s ease;

  cursor: pointer;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;

  width: fit-content;
  margin-top: 20px;
`;

const PrintMailingList = styled.button`
  display: flex;
  align-items: center;

  width: fit-content;
  padding: 10px 15px;

  border: none;
  border-radius: 2px;
  background-color: ${color.PrimaryColor};

  color: ${color.White};
  text-align: center;
  font-size: 16px;
  font-family: ${font.RobotoFont};

  :hover {
    background-color: ${color.SecondaryColor};
  }

  transition: transform 0.5s ease;

  cursor: pointer;
`;

const SearchEmail = styled(Search)`
  width: 470px;
  height: 40px;
`;

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;

const CenterItemTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FlexItemTable = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const DateNowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DatePicker = styled(DatePickerBase)`
  .ant-calendar-picker-input.ant-input {
    width: 222px;
    height: 40px;
  }
`;

export default {
  Header,
  LeadsContainer,
  ButtonContainer,
  SearchContainer,
  GetLeads,
  PrintMailingList,
  SearchEmail,
  CheckBox,
  CenterItemTable,
  FlexItemTable,
  BackArrowIcon,
  BackPage,
  SubTitle,
  Description,
  PreviewLink,
  DescriptionContainer,
  MailingListContainer,
  DateContainer,
  DateNowContainer,
  DatePicker,
};
