import styled from 'styled-components';
import { device as BreakPoints } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';

const BlackOverlay = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.31), rgba(0, 0, 0, 0.31)),
    url('https://authorify.com/wp-content/uploads/2020/08/offer-bg-1B.jpg') !important;
  position: absolute;
  height: 50%;
  width: 100%;
`;

const PreviewWrapper = styled.div`
  position: relative;
  max-width: 1170px;
  width: 100%;
  margin: 40px auto;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  overflow: auto;
  padding: 20px;
  background-color: ${color.White};
  flex-wrap: wrap;
  @media screen and ${BreakPoints.tabletS} {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 96%;
  }
  @media screen and (min-width: 768px) and ${BreakPoints.laptopS} {
    width: 100%;
  }
`;

const NavBarSideContent = styled.p`
  width: 40%;
  font-size: 20px;
  color: ${color.White};
  font-family: 'Roboto';
  @media screen and ${BreakPoints.tabletS} {
    font-size: 14px;
    width: max-content !important;
  }
`;

const Heading = styled.h5`
  font-size: 24px;
  font-weight: bold;
  color: ${color.Black};
  text-align: center;
  width: 100%;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 10px;
  @media screen and ${BreakPoints.tabletS} {
    line-height: 29px;
    font-size: 20px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${color.Black};
  text-align: center;
  width: 100%;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 40px !important;
  @media screen and ${BreakPoints.tabletS} {
    font-size: 14px;
    margin-bottom: 20px !important;
  }
`;

export default {
  NavBarSideContent,
  PreviewWrapper,
  BlackOverlay,
  Heading,
  Description,
};
