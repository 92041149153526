import styled from 'styled-components';
import { Input as BaseInput } from 'antd';

import * as color from 'modules/common/theme/color';

const FormFlex = styled.section`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  background: ${color.White};
  padding: 32px 0;
`;

const FormFooter = styled.footer`
  display: flex;
  justify-content: end;
`;

const inputStyle = `
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  height: 45px;
  
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #2e3f50;
  
  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;
const Input = styled(BaseInput)`
  ${inputStyle}
  height: ${(props) => (props.primary ? '64px' : '45px')};
`;
const { TextArea } = Input;

const InputTextArea = styled(TextArea)`
  ${inputStyle}
  height: 64px;
`;

export default {
  FormFlex,
  Wrapper,
  FormFooter,
  InputTextArea,
};
