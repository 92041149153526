import { call, takeLatest } from 'redux-saga/effects';

import { fetchLatestReleaseNote, resource, getError } from 'modules/api';
import { GET_LATEST_RELEASE_NOTE } from 'modules/dashboard/store/constants';

function* onGetLatestReleaseNote() {
  try {
    const request = resource(GET_LATEST_RELEASE_NOTE, fetchLatestReleaseNote);
    const response = yield call(request);

    return response;
  } catch (error) {
    const response = getError(error);
    // eslint-disable-next-line no-console
    console.error('Error ===>', response);

    return response;
  }
}

export function* watchGetLatestReleaseNote() {
  yield takeLatest(GET_LATEST_RELEASE_NOTE, onGetLatestReleaseNote);
}
