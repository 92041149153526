type Props = {
  className?: string;
  stroke?: string;
  fill?: string;
  width?: number;
  height?: number;
};

const CreditCardIcon: React.FC<Props> = ({ className, stroke, fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6665 10.0002C1.6665 6.85747 1.6665 5.28612 2.64281 4.30981C3.61913 3.3335 5.19047 3.3335 8.33317 3.3335H11.6665C14.8092 3.3335 16.3805 3.3335 17.3569 4.30981C18.3332 5.28612 18.3332 6.85747 18.3332 10.0002C18.3332 13.1429 18.3332 14.7142 17.3569 15.6905C16.3805 16.6668 14.8092 16.6668 11.6665 16.6668H8.33317C5.19047 16.6668 3.61913 16.6668 2.64281 15.6905C1.6665 14.7142 1.6665 13.1429 1.6665 10.0002Z"
        stroke={stroke}
        stroke-width="1.5"
      />
      <path d="M8.33333 13.3335H5" stroke={stroke} stroke-width="1.5" stroke-linecap="round" />
      <path
        d="M11.6665 13.3335H10.4165"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M1.6665 8.3335L18.3332 8.3335"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

CreditCardIcon.defaultProps = {
  className: '',
  stroke: '#FF8A33',
  fill: 'none',
  width: 20,
  height: 20,
};

export default CreditCardIcon;
