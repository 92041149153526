import { PinIcon } from 'modules/v2/common/components/SvgIcon';
import { Checkbox, Tooltip } from 'flowbite-react';
import { defaultCheckboxTheme } from 'modules/v2/common/AtomicDesign/atoms/Checkbox/theme';
import InputMask from 'react-input-mask';
import S from './styles';

const LeadCard = ({ lead, onSelect, onClickDetail }) => {
  return (
    <S.LeadCard selected={lead.selected}>
      <S.CheckboxWrapper>
        <Checkbox
          checked={lead.selected}
          onChange={() => onSelect(lead.id)}
          theme={defaultCheckboxTheme}
        />
      </S.CheckboxWrapper>
      <S.LeadInfo>
        <S.NameContainer>
          {lead.name}
          <S.PinIconWrapper>
            <PinIcon color="#FF8A33" />
          </S.PinIconWrapper>
        </S.NameContainer>
        <S.LeadEmail>{lead.email}</S.LeadEmail>
        <S.LeadPhone>
          <InputMask mask="+1 (999) 999-9999" value={lead.phone} disabled>
            {(inputProps) => <input {...inputProps} type="text" />}
          </InputMask>
        </S.LeadPhone>
        <S.LeadAddress>{lead.address}</S.LeadAddress>
        <S.BadgeContainer>
          <S.Badge>
            <S.DownloadIcon fill="#3AB886" />
            Added since {lead.addedSince}
          </S.Badge>
        </S.BadgeContainer>
      </S.LeadInfo>
      <S.ViewButton onClick={() => onClickDetail(lead.id)}>
        <S.ViewIcon />
      </S.ViewButton>
    </S.LeadCard>
  );
};

export default LeadCard;
