import { ConnectedRouter } from 'connected-react-router';

import { getData } from 'modules/auth/guards';
import { ScrollToTop } from 'modules/common/components';
import history from 'store/history';
import { loginRoutes } from 'modules/v2/login/routes';
import { routes as onboardRoutes } from 'modules/onboard/routes';
import { routes as landingPageRoutes } from 'modules/landingPages/routes';
import { routes as regularRoutes } from 'modules/v2/routes';
import { routes as PlusPlansRoutes } from 'modules/plusPlans/routes/index.ts';
import { routes as DraftRoutes } from 'modules/v2/draft/routes';

import Renderer from './Renderer';
import routes from './routes';

const Router = () => {
  const { token, userData, refreshToken, admin } = getData();

  const isLogged = !!token && !!userData && !!refreshToken && !admin;

  if (!isLogged) {
    return (
      <ConnectedRouter history={history}>
        <Renderer
          config={[
            ...loginRoutes,
            ...onboardRoutes,
            ...landingPageRoutes,
            ...regularRoutes,
            ...PlusPlansRoutes,
            ...DraftRoutes,
          ]}
        />
      </ConnectedRouter>
    );
  }

  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <Renderer config={routes} />
      </ScrollToTop>
    </ConnectedRouter>
  );
};

export default Router;
