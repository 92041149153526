import { createContext, useMemo, useState } from 'react';

import { formatDate } from 'modules/common/utils';
import { getItem } from 'modules/dashboard/utils/legacy';
import dayjs from 'dayjs';
import { log } from 'modules/api/afyLogger/afyLogger';
import {
  upgradePlanEventAlreadyTriggered,
  upgradePlanSetEventTriggered,
} from 'modules/api/afyLogger/helpers';

export const PlansContext = createContext(null);

export function PlansProvider({ children }) {
  const [paymentProfiles, setPaymentProfiles] = useState([]);
  const [stripePaymentProfiles, setStripePaymentProfiles] = useState([]);
  const [isStripeCustomer, setIsStripeCustomer] = useState(false);
  const [defaultProfile, setDefaultProfile] = useState({});
  const [subscription, setSubscription] = useState({});
  const [selectedCard, setSelectedCard] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isReviewAndPayModalOpened, setIsReviewAndPayModalOpened] = useState(false);
  const [isUpgradePlanModalOpened, setIsUpgradePlanModalOpened] = useState(false);
  const [isOrderConfirmationModalOpened, setIsOrderConfirmationModalOpened] = useState(false);
  const [isContracted, setIsContracted] = useState(false);
  const [isTrialing, setIsTrialing] = useState(false);
  const [newPlanName, setNewPlanName] = useState('');

  const userDataString = getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const getNextMonthRenewal = () => {
    const today = dayjs();
    const nextBillingDate = today.add(1, 'month');
    return formatDate(nextBillingDate, 'MMMM D, YYYY');
  };

  const getNextYearRenewal = () => {
    const today = dayjs();
    const nextBillingDate = today.add(1, 'year');
    return formatDate(nextBillingDate, 'MMMM D, YYYY');
  };

  const toggleReviewAndPayModal = () => {
    setIsReviewAndPayModalOpened(!isReviewAndPayModalOpened);
  };

  const toggleUpgradePlanModal = () => {
    let event = 'click-upgrade-plan';
    if (isUpgradePlanModalOpened) {
      event = 'abandon-upgrade-plan';
    }

    if (!upgradePlanEventAlreadyTriggered(event)) {
      upgradePlanSetEventTriggered(event);
      log(event, 'upgrade-plan');
    }

    setIsUpgradePlanModalOpened(!isUpgradePlanModalOpened);
  };

  const toggleOrderConfirmationModal = () => {
    setIsOrderConfirmationModalOpened(!isOrderConfirmationModalOpened);
  };

  const contextData = {
    userData,
    paymentProfiles,
    selectedCard,
    subscription,
    selectedPlan,
    isReviewAndPayModalOpened,
    isUpgradePlanModalOpened,
    isOrderConfirmationModalOpened,
    isContracted,
    defaultProfile,
    setPaymentProfiles,
    setDefaultProfile,
    setSelectedCard,
    setSubscription,
    setSelectedPlan,
    toggleReviewAndPayModal,
    toggleUpgradePlanModal,
    toggleOrderConfirmationModal,
    setIsContracted,
    getNextMonthRenewal,
    getNextYearRenewal,
    isTrialing,
    newPlanName,
    setNewPlanName,
    setIsTrialing,
    stripePaymentProfiles,
    setStripePaymentProfiles,
    isStripeCustomer,
    setIsStripeCustomer,
  };

  const memoizedContextData = useMemo(() => contextData, [contextData]);

  return <PlansContext.Provider value={memoizedContextData}>{children}</PlansContext.Provider>;
}
