import BaseInputMask from 'react-input-mask';
import * as color from 'modules/common/theme/color';
import { CKEditor as BaseCkEditor } from '@ckeditor/ckeditor5-react';
import { QrcodeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Alert as BaseAlert, Space as SpaceBase } from 'antd';
import styled from 'styled-components';

const HeadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  h5 {
    font-size: 16px;
  }

  .ant-tooltip-placement-top .ant-tooltip-arrow {
    left: ${(props) => props.adjustToolTip && '35%'};
  }
`;

const Title = styled.h5`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: bold;
  margin: ${(props) => props.marginLeft ?? '0px'};
`;

const TitleCenter = styled(Title)`
  text-align: center;
`;

const TitleNoPaddingRight = styled(Title)`
  padding-right: 0px;
`;

const TitlePaddingBottom = styled(Title)`
  padding-bottom: 20px;
`;

const TopWrap = styled.div`
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 20px 0px;

  ${BaseAlert} {
    margin-top: 30px;
  }
`;

const TableWrap = styled.div`
  margin-bottom: 30px;
`;

const AllFeaturesWrap = styled.div`
  width: 100%;
`;

const SelectWrap = styled.select`
  font-size: 14;
  margin: 0px 10px;
`;

const SelectOption = styled.option`
  font-size: 14;
`;

const TabsWrap = styled.div`
  .ant-tabs-bar {
    border-bottom: 1px solid transparent;
  }

  .ant-tabs-ink-bar {
    border-bottom: 3px solid ${color.SideBarBackground};
    background-color: ${color.SideBarBackground};
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${color.Black};
    font-weight: 600;
  }
`;

const SubContainer = styled.div`
  width: 100%;
`;

const InputWrap = styled.div`
  padding: 10px;
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  font-size: 14px;
  line-height: 24px;
  color: ${color.Black};
  padding-bottom: ${(props) => (props.noPaddingBottom ? '0px' : '')};
  position: relative;

  &:first-child {
    padding-left: 0px;
  }

  input,
  textarea,
  .ant-select {
    font-size: 14px;
    line-height: 22px;
    padding: 7px 20px;
    resize: none;
    color: ${color.Black};
    margin-top: 10px;
    border: 1px solid ${color.Black};
    border-radius: 2px;
  }

  textarea {
    height: 100px;
  }

  .ant-select {
    width: 100%;
    padding: 0px;
  }

  .ant-select-selection {
    padding: 5px 10px;
  }

  .ant-select-selection--single {
    height: unset;
  }
`;

const InputWrapNoPaddingRight = styled(InputWrap)`
  padding-right: 0px;
`;

const InputWrapWithIcon = styled(InputWrap)`
  width: 100%;

  input {
    text-indent: 45px;
  }
`;

const InputWithError = styled(InputWrapWithIcon)``;

const Icon = styled.div`
  width: 49px;
  height: 30px;
  position: absolute;
  left: 1px;
  bottom: 13%;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  z-index: 1;
  text-align: center;
  align-items: center;
  border-right: 1px solid ${color.Black};
  justify-content: center;
  background: ${color.GreyDivider};
`;

const FieldSeprator = styled.div`
  display: flex;
  align-items: baseline;
  margin: ${(props) => props.margin && `${props.margin}`};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TextAreaWrap = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
  align-items: center;
  font-weight: 600;
  color: ${color.Black};
  position: relative;
  opacity: ${(props) => (props.isPendingStep ? 0.3 : 1)};

  &:after {
    content: '';
    position: absolute;
    top: 33px;
    left: 15px;
    border-left: 1px dashed ${color.GreyBorder};
    height: 20px;
  }

  &:last-child:after {
    display: none;
  }
`;

const TextAreaWithJustifyContent = styled(TextAreaWrap)`
  justify-content: center;
`;

const TextAreaWithJustifyContentEnd = styled(TextAreaWrap)`
  justify-content: flex-end;
`;

const TextAreaNoMarginBottom = styled(TextAreaWrap)`
  justify-content: flex-end;
  margin-bottom: 0px;
`;

const AddOfferImage = styled.div`
  padding: 10px 10px 0px 10px;
  width: 50%;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  color: ${color.Black};
  font-weight: 500;

  &:first-child {
    padding-left: 0px;
  }
`;

const OptionalField = styled.div`
  margin-top: 20px;
  border-radius: 2px;
`;

const SubHeading = styled.h6`
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  margin-top: 20px;
`;

const BottomWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0px 10px;
  padding-right: ${(props) => props.noPaddingRight && '0'};
`;

const ImageSizeText = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    margin-left: 0px;
  }
`;

const SelectFile = styled.div`
  font-size: 16px;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px dashed ${color.GreyBorder};
  width: ${(props) => `${props.width}` || 'unset'};
  justify-content: ${(props) => (props.justifyContent ? `${props.justifyContent}` : 'unset')};
  position: relative;

  span {
    color: ${color.LightBlue};
  }

  svg {
    width: 40px;
    height: 40px;
  }
`;

const FileWrap = styled.div`
  display: flex;
  padding-top: 10px;
  padding-right: 10px;
  align-items: center;
  height: 100px;
  position: relative;
`;

const ImageConatainer = styled.div`
  border-radius: 2px;

  p {
    margin-left: 20px;
  }

  padding-bottom: 15px;
`;

const FileInput = styled.input`
  position: absolute;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  background-size: 60px 60px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  input {
    position: relative;
    text-indent: 30px;
    margin-top: 0px;
  }

  span {
    position: absolute;
    left: 30px;
  }

  svg {
    color: ${(props) => (props.hasValue ? color.Black : color.GreyFormBorder)};
  }
`;

const ImageWrap = styled.div`
  border: 1px dashed ${color.GreyBorder};
  position: relative;

  svg {
    position: absolute;
    top: -8px;
    right: 72px;
    color: ${color.PrimaryColor};
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
  }
`;

const OfferImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  padding: 10px;
`;

const InclusionItemCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  span.closeIcon {
    position: absolute;
    right: 16px;
    top: 8px;
    background: ${color.GreyHover};
    cursor: pointer;
  }
`;

const InclusionItem = styled.div`
  font-size: 14px;
  width: 100%;
  background-color: ${color.GreyHover};
  border: 1px solid ${color.GreyBorder};
  border-radius: 2px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 6px 4px 4px;
  -webkit-line-clamp: 1;
  position: relative;

  &:last-child {
    margin-right: 0px;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 92%;
  }
`;

const Text = styled.p`
  color: ${(props) => (props.color ? props.color : color.Black)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
`;

const TextCursorPointer = styled(Text)`
  cursor: pointer;
`;

const TextWrap = styled.div`
  display: block;
`;

const PageContainer = styled.div`
  display: flex;
  position: relative;
  padding-left: 20px;
`;

const SubSideBar = styled.div`
  width: 25%;
  margin-right: 20px;
  background-color: ${color.lightBackground};
  padding: 20px 13px 20px 29px;
  box-shadow: 0px 3px 6px ${color.sidebarBoxShadow};
  height: calc(100vh - 96px);
`;

const StepCount = styled.div`
  background-color: ${(props) =>
    props.isPendingStep || props.isCurrentStep ? color.LeadsButtonHover : color.SideBarText};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  color: ${color.White};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`;

const InfoIcon = styled.span`
  margin-left: 8px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '30px')};
  vertical-align: middle;

  svg {
    cursor: pointer;
  }
`;

const SubPageContainer = styled.div`
  width: ${(props) => (props.width ? props.width : '75%')};
  overflow: hidden;
  padding-bottom: 20px;
`;

const CurrentStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 100px);
`;

const MagazineWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Note = styled.p`
  font-style: italic;
`;

const TextAreaCenterBold = styled(TextAreaWrap)`
  justify-content: center;
  font-weight: 700;
`;

const InputWrapWithMarginTop = styled(InputWrap)`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '20px')};
  padding: 0px;
`;

const BottomWrapNoMargin = styled(BottomWrap)`
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 10px;
  padding-right: ${(props) => props.noPaddingRight && '0'};
`;

const TextAreaWithJustifyContentBetween = styled(TextAreaWrap)`
  justify-content: space-between;
  margin-bottom: 28px;
`;

const TextColor = styled.span`
  color: ${(props) => props.textColor && props.textColor};
`;

const IframeWrapper = styled.div`
  height: 421px;
  margin-bottom: 20px;
`;

const TextLineHeight = styled(Text)`
  line-height: 20px;
`;

const TextLineHeightDimensions = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-left: 20px;
`;

const ContainerWithFlexOne = styled.div`
  flex: 1;
`;

const ErrorMessage = styled.span`
  color: ${color.ErrorWarning};
  font-size: 12px;
`;

const LinksWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-left: ${(props) => props.marginLeft && props.marginLeft};

  input {
    width: 100%;
  }

  padding-left: 10px;

  > div {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  label {
    display: none;
  }
`;

const ModalContainer = styled.div`
  padding: 29px 20px 20px 20px;
  height: 227px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin-top: 15px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
`;

const NoActiveMagazine = styled(SpaceBase)`
  margin-top: 200px;
  width: 100%;
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const MaskedInputWrap = styled.div`
  input {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  input:hover {
    border: 1px solid #40a9ff;
    outline: none;
  }

  input:active {
    border: 1px solid #40a9ff;
    outline: none;
  }

  input:focus {
    border: 1px solid #40a9ff;
    outline: none;
  }
`;

const InputMask = styled(BaseInputMask)`
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;

  ::placeholder {
    color: ${color.Placeholder};
  }
`;

const QRCode = styled(QrcodeOutlined)`
  font-size: 64px;
`;

const Alert = styled(BaseAlert)`
  margin: 0 auto;
  text-align: center;
  width: fit-content;
`;

const SmallAlert = styled(BaseAlert)`
  margin: 0 auto;
  text-align: center;
  width: 40rem;
`;

const AdjustWidth = styled.div`
  width: ${(props) => props.width};
`;

const SpBlack = styled.span`
  color: ${color.Black};
`;

const SpRed = styled.span`
  color: ${color.DangerRed};
`;

const ULStyleNone = styled.ul`
  list-style: none;
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: ${color.LilyWhite};
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;

  span {
    color: ${color.ErrorWarning};
    font-size: 18px;
  }

  svg {
    width: unset;
    height: unset;
  }
`;

const DeleteIcon = styled(DeleteOutlined)`
  text-align: center;
`;

const CKEditor = styled(BaseCkEditor)`
  height: 500px;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: ${color.LilyWhite};
  border-radius: 50%;
  position: absolute;
  top: 50px;
  right: 5px;
  width: 25px;
  height: 25px;

  span {
    color: ${color.ErrorWarning};
    font-size: 18px;
  }

  svg {
    width: unset;
    height: unset;
  }
`;

const EditIcon = styled(EditOutlined)`
  text-align: center;
`;

const CapitalizeText = styled.span`
  text-transform: capitalize;
  margin: 0 8px;
`;

export default {
  Actions,
  AddOfferImage,
  AdjustWidth,
  AllFeaturesWrap,
  BottomWrap,
  BottomWrapNoMargin,
  ContainerWithFlexOne,
  CurrentStep,
  CKEditor,
  DeleteButton,
  DeleteIcon,
  ErrorMessage,
  EditButton,
  EditIcon,
  FileInput,
  FileWrap,
  HeadingWrap,
  Icon,
  IconContainer,
  IframeWrapper,
  ImageConatainer,
  ImageSizeText,
  ImageWrap,
  InclusionItem,
  InclusionItemCover,
  InfoIcon,
  InputMask,
  InputWrap,
  InputWrapNoPaddingRight,
  InputWrapWithIcon,
  InputWrapWithMarginTop,
  FieldSeprator,
  LinksWrapper,
  MagazineWrap,
  MaskedInputWrap,
  ModalContainer,
  NoActiveMagazine,
  Note,
  OfferImage,
  OptionalField,
  PageContainer,
  SelectFile,
  SelectOption,
  SelectWrap,
  StepCount,
  SubContainer,
  SubHeading,
  SubPageContainer,
  SubSideBar,
  SpBlack,
  SpRed,
  TableWrap,
  TabsWrap,
  Text,
  TextAreaCenterBold,
  TextColor,
  TextCursorPointer,
  TextLineHeight,
  TextWrap,
  TextAreaWrap,
  TextAreaNoMarginBottom,
  TextAreaWithJustifyContent,
  TextAreaWithJustifyContentBetween,
  TextAreaWithJustifyContentEnd,
  Title,
  TitleCenter,
  TitleNoPaddingRight,
  TitlePaddingBottom,
  TopWrap,
  UploadedImage,
  ULStyleNone,
  QRCode,
  Alert,
  SmallAlert,
  InputWithError,
  TextLineHeightDimensions,
  CapitalizeText,
};
