//

import { Formik, Form } from 'formik';
import { find } from 'lodash';
import classnames from 'classnames';
import { FormLabel } from 'modules/v2/common/components';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { PasswordInput } from 'modules/v2/common/AtomicDesign/molecules';
import { CloseCircleIcon, CheckIconNew } from 'modules/v2/common/components/SvgIcon';

import validationSchema from './validationSchema';

import S from './styles';

const ChangePasswordForm = ({ onSave }) => {
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validation = [
    {
      rule: /^.{8,}$/,
      message: 'Must be 8 characters or more',
    },
    {
      rule: /[a-z]+/,
      message: 'One lowercase character',
    },
    {
      rule: /[A-Z]+/,
      message: 'One uppercase character',
    },
    {
      rule: /\d/,
      message: 'Contains a number',
    },
    {
      rule: /[^\w\s]/,
      message: 'Contains a symbol',
    },
  ];

  const validatePassword = (password) => {
    const validationMessages = [];

    validation.forEach(({ rule, message }) => {
      if (rule.test(password)) {
        validationMessages.push({ valid: true, message });
      } else {
        validationMessages.push({ valid: false, message });
      }
    });

    const touched = find(validationMessages, { valid: true });

    return validationMessages.map(({ valid, message }) => {
      if (!touched) {
        return (
          <div className="w-[210px] text-sm flex items-center gap-2">
            <span className="rounded-full w-3 h-3 flex justify-center items-center bg-neutral-500">
              <CloseCircleIcon fill="#ffffff" width={10} height={10} />
            </span>
            <span className="text-neutral-500">{message}</span>
          </div>
        );
      }
      return (
        <div className="w-[210px] text-sm flex items-center gap-2">
          <span
            className={classnames(
              'rounded-full w-3 h-3 flex justify-center items-center',
              valid ? 'bg-success-500' : 'bg-error-500',
            )}
          >
            {valid ? (
              <CheckIconNew width={10} height={10} />
            ) : (
              <CloseCircleIcon fill="#ffffff" width={10} height={10} />
            )}
          </span>
          <span className={classnames(valid ? 'text-success-500' : 'text-error-500')}>
            {message}
          </span>{' '}
        </div>
      );
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
      {({ values, dirty, errors, handleSubmit, handleChange, isValid }) => {
        const isDisabled = !isValid || !dirty;
        return (
          <Form onSubmit={handleSubmit}>
            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="Current password" htmlFor="oldPassword" required>
                  <PasswordInput
                    id="oldPassword"
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={handleChange}
                  />
                  {errors.oldPassword ? (
                    <span className="text-error-500 mt-2">{errors.oldPassword}</span>
                  ) : null}
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>
            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="New password" htmlFor="newPassword" required>
                  <PasswordInput
                    id="newPassword"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                  />
                  <div className="flex justify-between flex-wrap gap-2 mt-2">
                    {validatePassword(values.newPassword)}
                  </div>
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="Repeat new password" htmlFor="confirmPassword" required>
                  <PasswordInput
                    id="confirmPassword"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword ? (
                    <span className="text-error-500 mt-2">{errors.confirmPassword}</span>
                  ) : null}
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <Button type="dark" buttonType="submit" onClick={handleSubmit} disabled={isDisabled}>
                Save changes
              </Button>
            </S.FieldGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
