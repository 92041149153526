import withStore from 'store/StoreContainer';

import {
  // Actions
  goToEditDraftContent,
  goToEditDraftCover,
  goToEditDraftManageSections,
  replaceCoverImage,
  saveDraft,
  toggleShowGuides,
  updateCoverActiveTemplate,
  updateCoverElement,
  uploadCoverImage,

  // Selectors
  bookSelector,
  coverElementsSelector,
  coverTemplateIdSelector,
  coverTemplateListSelector,
  draftIdSelector,
  draftStateSelector,
  dropdownSectionsSelector,
  loadingElementsSelector,
  toolbarSelector,
  userImagesSelector,
  userLogosSelector,
} from 'modules/v2/editor/store';

const mapStateToProps = (state) => ({
  book: bookSelector(state),
  draft: draftStateSelector(state),
  draftId: draftIdSelector(state),
  dropdown: dropdownSectionsSelector(state),
  elements: coverElementsSelector(state),
  images: userImagesSelector(state),
  loadingElements: loadingElementsSelector(state),
  logos: userLogosSelector(state),
  templateId: coverTemplateIdSelector(state),
  templateList: coverTemplateListSelector(state),
  toolbar: toolbarSelector(state),
});

const mapDispatchToProps = {
  goToEditDraftContent,
  goToEditDraftCover,
  goToEditDraftManageSections,
  replaceCoverImage,
  saveDraft,
  toggleShowGuides,
  updateCoverActiveTemplate,
  updateCoverElement,
  uploadCoverImage,
};

export default withStore(mapStateToProps, mapDispatchToProps);
