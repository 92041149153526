//

import { Formik, Form } from 'formik';

import { FormErrors, FormLabel } from 'modules/common/components';

import validationSchema from './validationSchema';

import S from './styles';

const ChangePasswordForm = ({ onSave }) => {
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
      {({ values, errors, handleSubmit, handleChange }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <FormErrors errors={errors} />

            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="Current Password" htmlFor="oldPassword" required>
                  <S.Input
                    id="oldPassword"
                    name="oldPassword"
                    type="password"
                    value={values.oldPassword}
                    onChange={handleChange}
                  />
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>
            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="New Password" htmlFor="newPassword" required>
                  <S.Input
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    value={values.newPassword}
                    onChange={handleChange}
                  />
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="Confirm Password" htmlFor="confirmPassword" required>
                  <S.Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.Button onClick={handleSubmit}>Save</S.Button>
            </S.FieldGroup>
            <S.FieldGroup>
              <S.InfoText>* Indicates required field</S.InfoText>
            </S.FieldGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
