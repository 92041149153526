export default function OutlookIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33331 4.3335H14.1776C14.4476 4.3335 14.6666 4.5525 14.6666 4.8225V11.1778C14.6666 11.4478 14.4476 11.6668 14.1776 11.6668H9.33331V4.3335Z"
        fill="#1976D2"
      />
      <path d="M14 5.98616H9.33331V11.1668H14V5.98616Z" fill="white" />
      <path d="M8.99998 14.6668L1.33331 13.1668V2.8335L8.99998 1.3335V14.6668Z" fill="#1976D2" />
      <path
        d="M5.08331 5.50016C4.02465 5.50016 3.16665 6.6195 3.16665 8.00016C3.16665 9.38083 4.02465 10.5002 5.08331 10.5002C6.14198 10.5002 6.99998 9.38083 6.99998 8.00016C6.99998 6.6195 6.14198 5.50016 5.08331 5.50016ZM4.99998 9.50016C4.44765 9.50016 3.99998 8.8285 3.99998 8.00016C3.99998 7.17183 4.44765 6.50016 4.99998 6.50016C5.55231 6.50016 5.99998 7.17183 5.99998 8.00016C5.99998 8.8285 5.55231 9.50016 4.99998 9.50016Z"
        fill="white"
      />
      <path d="M10.249 9.9125H9.34898V10.8792H10.249V9.9125Z" fill="#1976D2" />
      <path d="M11.3826 9.9125H10.4826V10.8792H11.3826V9.9125Z" fill="#1976D2" />
      <path d="M12.5163 9.9125H11.6163V10.8792H12.5163V9.9125Z" fill="#1976D2" />
      <path d="M10.249 8.71983H9.34898V9.6865H10.249V8.71983Z" fill="#1976D2" />
      <path d="M11.3826 8.71983H10.4826V9.6865H11.3826V8.71983Z" fill="#1976D2" />
      <path d="M12.5163 8.71983H11.6163V9.6865H12.5163V8.71983Z" fill="#1976D2" />
      <path d="M13.65 8.71983H12.75V9.6865H13.65V8.71983Z" fill="#1976D2" />
      <path d="M10.249 7.56883H9.34898V8.5355H10.249V7.56883Z" fill="#1976D2" />
      <path d="M11.3826 7.56883H10.4826V8.5355H11.3826V7.56883Z" fill="#1976D2" />
      <path d="M12.5163 7.56883H11.6163V8.5355H12.5163V7.56883Z" fill="#1976D2" />
      <path d="M13.65 7.56883H12.75V8.5355H13.65V7.56883Z" fill="#1976D2" />
      <path d="M11.3826 6.37083H10.4826V7.3375H11.3826V6.37083Z" fill="#1976D2" />
      <path d="M12.5163 6.37083H11.6163V7.3375H12.5163V6.37083Z" fill="#1976D2" />
      <path d="M13.65 6.37083H12.75V7.3375H13.65V6.37083Z" fill="#1976D2" />
    </svg>
  );
}
