import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 375px;
  margin: 100px 0;
`;

const IconWrapper = styled.img`
  text-align: center;
`;

const Title = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: ${color.gray100};
  font-weight: 400;
  margin-top: 1rem;
`;

const Description = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: ${color.grayDescription};
  font-weight: 400;
  margin-top: 0.2rem;
`;

export default {
  Container,
  NoDataContainer,
  IconWrapper,
  Title,
  Description,
};
