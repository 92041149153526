import { withRouter } from 'react-router';
import { Menu } from 'antd';
import { NavLink, NavItem, NavSubMenu } from 'modules/common/components/UIComponents';

import {
  getRouteLeads,
  getRouteEmailSenderEmailConfiguration,
  getRouteEmailTemplates,
  getRouteLaunchCampaign,
  getRouteAllCampaignMetrics,
} from 'modules/dashboard/routes/navigation';

const DashboardSideNav = ({ location }) => {
  return (
    <Menu
      mode="inline"
      selectedKeys={location.pathname.split('/')}
      defaultOpenKeys={location.pathname.split('/')}
    >
      <NavItem key="leads">
        <NavLink to={getRouteLeads()}>
          <span>Leads</span>
        </NavLink>
      </NavItem>
      <NavSubMenu key="email-campaign" title={<span>Email Campaign</span>}>
        <NavItem key="sender-email-configuration">
          <NavLink to={getRouteEmailSenderEmailConfiguration()}>Sender Email Configuration</NavLink>
        </NavItem>

        <NavItem key="templates">
          <NavLink to={getRouteEmailTemplates()}>Template Emails</NavLink>
        </NavItem>

        <NavItem key="launch-campaign">
          <NavLink to={getRouteLaunchCampaign()}>Launch Email Campaign</NavLink>
        </NavItem>

        <NavItem key="campaign-metrics">
          <NavLink to={getRouteAllCampaignMetrics()}>Campaign Metrics</NavLink>
        </NavItem>
      </NavSubMenu>
    </Menu>
  );
};

export default withRouter(DashboardSideNav);
