import AuthenticationTemplate from 'modules/dashboard/components/AuthenticationTemplate';
import S from './styles';

function SuccessMessage({
  email,
  setShowPasswordReset,
  noBackground = false,
  noFooter = false,
  noLogo = true,
}) {
  return (
    <AuthenticationTemplate noBackground={noBackground} noFooter={noFooter} noLogo={noLogo}>
      <S.Warning>
        <S.Warning.Title> Please Check Your Email </S.Warning.Title>
        <S.Warning.Description>
          <p>
            We are searching through our records to find the Authorify account for &nbsp;
            <strong>{email}</strong>.
          </p>
          <p>
            This may take a few minutes. When we find a match you'll get an email with further
            instructions.
          </p>
          <p> If you do not hear from us within 10 minutes, please check your email spam folder.</p>
        </S.Warning.Description>
      </S.Warning>
      <S.Warning.BackToLogin>
        <S.Warning.LoginDescription> Go back to </S.Warning.LoginDescription>
        {!noBackground ? (
          <S.Warning.LoginLink to="/auth/login">login</S.Warning.LoginLink>
        ) : (
          <S.Warning.LoginLink
            onClick={() => {
              setShowPasswordReset(false);
            }}
          >
            login
          </S.Warning.LoginLink>
        )}
      </S.Warning.BackToLogin>
    </AuthenticationTemplate>
  );
}

export default SuccessMessage;
