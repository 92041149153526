import styled from 'styled-components';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Wrapper = styled.div`
  padding-left: 13px;
  margin-top: 21px;
  padding-top: 21px;
  border-top: 1px solid ${color.InputBorder};
  font-family: ${font.NunitoFont};
`;

const Header = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${color.WelcomeTitle};
  padding: 5px 0 10px 0;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin: 0;
`;

const LinkWrapper = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${color.WelcomeTitle};
  padding: 0;
  letter-spacing: 0.5px;
  text-decoration: underline;
  margin: 0;
`;

const Link = styled.a`
  color: inherit;
`;

const Divider = styled.span`
  padding: 0 5px;
`;

export default {
  Divider,
  Header,
  LinkWrapper,
  Link,
  Wrapper,
};
