import * as Yup from 'yup';

import * as address from './address';
import * as customer from './customer';
import * as password from './password';

import * as template from './template';
import * as campaign from './campaign';

const imageUrl = Yup.string();

const validationSchema = {
  ...address.default,
  ...customer.default,
  ...password.default,
  ...template.default,
  ...campaign.default,
  imageUrl,
};

export default validationSchema;
