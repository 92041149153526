import {
  GET_DRAFTS,
  DELETE_DRAFTS,
  SAVE_DRAFT,
  TOGGLE_PANEL,
  GET_DRAFTBYID,
  TOGGLE_INITIAL_LOADER,
  UPLOAD_USER_IMAGE,
  UPLOADED_USER_IMAGE,
  CHANGE_USER_COVER_IMAGE,
  DELETE_USER_UPLOADED_IMAGE,
  DRAFT_COMPLETION_PERCENTAGE,
  GET_DRAFT_PREVIEW,
  GET_PREDEFINED_LOGOS,
  GENERATE_NEW_IMAGE_URL,
  UPDATE_DRAFT_PREFERENCES,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  saving: false,
  uploading: false,
  data: [],
  initialLoader: {
    loading: false,
    loaded: false,
  },
  userImages: [],
  profileImage: [],
  logoImages: [],
  filteredDrafts: [],
  initialPanel: true,
  saved: false,

  finalCheck: [],
  pdf: {
    url: '',
    loading: false,
    loaded: false,
  },
};

export default (state = initialState, action) => {
  const filterCompleteDrafts = (draft) => draft.percentComplete === 100;
  const filterIncompleteDrafts = (draft) => draft.percentComplete < 100;
  const { initialPanel } = action;
  const filterFn = (draft) =>
    initialPanel ? filterIncompleteDrafts(draft) : filterCompleteDrafts(draft);

  switch (action.type) {
    case `${GET_DRAFTS}_REQUEST`:
      return { ...state, loading: true };

    case `${GET_DRAFTS}`:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data.results,
        filteredDrafts: action.payload.data.results,
      };

    case `${GET_DRAFTS}_FAILURE`:
      return { ...state, loaded: true };

    /* Initial screen loader */
    case `${TOGGLE_INITIAL_LOADER}_SET`:
      return {
        ...state,
        initialLoader: {
          ...state.initialLoader,
          loaded: true,
          loading: false,
        },
      };

    case `${TOGGLE_INITIAL_LOADER}_RESET`:
      return {
        ...state,
        initialLoader: {
          ...state.initialLoader,
          loading: false,
          loaded: false,
        },
      };

    case `${GET_DRAFTBYID}_REQUEST`:
      return { ...state, loading: true, loaded: false };

    case GET_DRAFTBYID:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: { ...action.payload.data.results },
      };

    case `${GET_DRAFTBYID}_FAILURE`:
      return { ...state, loaded: true };

    case TOGGLE_PANEL: {
      const filteredDrafts = Array.isArray(state.data)
        ? state.data.filter((draft) => filterFn(draft))
        : [];

      return {
        ...state,
        initialPanel,
        filteredDrafts,
      };
    }

    case `${DELETE_DRAFTS}_REQUEST`: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case DELETE_DRAFTS: {
      const draftData = state.data.filter((draft) => draft.draftId !== action.id);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: draftData,
        filteredDrafts: state.initialLoader
          ? draftData.filter((draft) => filterIncompleteDrafts(draft))
          : draftData.filter((draft) => filterCompleteDrafts(draft)),
      };
    }

    case `${DELETE_DRAFTS}_FAILURE`: {
      return {
        ...state,
        loaded: true,
      };
    }

    case `${SAVE_DRAFT}_REQUEST`:
      return { ...state, saving: true };

    case SAVE_DRAFT:
      return {
        ...state,
        data: { ...state.data, ...action.data },
        saving: false,
      };

    case `${SAVE_DRAFT}_FAILURE`:
      return { ...state, saving: true };

    case `${UPLOAD_USER_IMAGE}_REQUEST`:
      return { ...state, uploading: true };

    case UPLOAD_USER_IMAGE: {
      const imageUrl = {};
      if (action.elementId === 'authorImageFront') {
        imageUrl.userImageUrl = action.payload.data.url;
      }
      if (action.elementId === 'brokerLogo') {
        imageUrl.logoImageUrl = action.payload.data.url;
      }
      if (action.elementId === 'realtorLogo') {
        imageUrl.logoImageUrl = action.payload.data.url;
      }
      return {
        ...state,
        uploading: false,
        userImages: {
          ...state.userImages,
          ...imageUrl,
        },
        saved: true,
      };
    }

    case `${UPLOAD_USER_IMAGE}_FAILURE`:
      return { ...state, uploading: true };

    case GENERATE_NEW_IMAGE_URL: {
      return {
        ...state,
      };
    }

    case `${UPLOADED_USER_IMAGE}_REQUEST`:
      return { ...state };

    case UPLOADED_USER_IMAGE: {
      const logos = action.payload.data.files.filter((file) => file.type === 'logo');
      const profile = action.payload.data.files.filter((file) => file.type === 'profile');

      return {
        ...state,
        profileImage: profile,
        logoImages: logos,
        saved: true,
      };
    }

    case GET_PREDEFINED_LOGOS:
      return {
        ...state,

        logodata: action.payload.data,
        saved: true,
      };

    case `${DRAFT_COMPLETION_PERCENTAGE}_FAILURE`:
      return { ...state, loaded: true };

    case `${DRAFT_COMPLETION_PERCENTAGE}_REQUEST`:
      return { ...state, loading: true, loaded: false };

    case DRAFT_COMPLETION_PERCENTAGE:
      return {
        ...state,
        finalCheck: { ...action.payload.data },
        loading: false,
        loaded: true,
        saved: true,
      };

    case `${UPLOADED_USER_IMAGE}_FAILURE`:
      return { ...state, loaded: true };

    case CHANGE_USER_COVER_IMAGE:
      return {
        ...state,
        loading: false,
        loaded: true,
        userImages: {
          ...state.userImages,
          userImageUrl: action.userImageUrl,
        },
        saved: true,
      };

    case DELETE_USER_UPLOADED_IMAGE: {
      const updatedImages = state.profileImage.filter((image) => image._id !== action.id.userId);
      const updatelogoImages = state.logoImages.filter((image) => image._id !== action.id.userId);

      return {
        ...state,
        loading: false,
        loaded: true,
        profileImage: updatedImages,
        logoImages: updatelogoImages,
        saved: true,
      };
    }

    case `${GET_DRAFT_PREVIEW}_REQUEST`:
      return {
        ...state,
        pdf: {
          loading: true,
        },
      };

    case `${GET_DRAFT_PREVIEW}`:
      return {
        ...state,
        pdf: {
          url: action.payload.data.url,
          loading: false,
          loaded: true,
        },
      };

    case `${GET_DRAFT_PREVIEW}_FAILURE`:
      return {
        ...state,
        pdf: {
          loaded: true,
        },
      };

    case UPDATE_DRAFT_PREFERENCES: {
      return {
        ...state,
        data: { ...state.data, preferences: action.payload.preferences },
      };
    }

    default:
      return { ...state };
  }
};
