import { useEffect, useRef } from 'react';
import { map } from 'lodash-es';

import { Scrollbars } from 'modules/common/components';

import { TemplateItem } from './components';

import S from './styles';

const scrollTo = (element, selected) => {
  if (element && selected) {
    requestAnimationFrame(() => {
      const top = selected.offsetTop;
      element.scrollTop(top);
    });
  }
};

const TemplateList = ({ templates, selectedId, onSelect }) => {
  const handleSelect = (id) => () => onSelect(id);
  const scrollbarRef = useRef();
  const selectedRef = useRef();

  useEffect(() => {
    scrollTo(scrollbarRef.current, selectedRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <Scrollbars ref={scrollbarRef} renderInLayers style={{ height: 'calc(100vh - 150px)' }}>
      <div className="grid grid-cols-2">
        {map(templates, ({ _id, name, cover }) => {
          const selected = selectedId === _id;
          const ref = selected ? selectedRef : null;

          return (
            <TemplateItem
              ref={ref}
              key={_id}
              name={name}
              cover={cover}
              selected={selectedId === _id}
              onSelect={handleSelect(_id)}
            />
          );
        })}
      </div>
    </Scrollbars>
  );
};

export default TemplateList;
