import { useQuery, useMutation } from 'react-query';
import {
  getBookPreviews,
  deleteBookPreviews,
  updateBookPreviews,
  createBookPreviews,
  fetchAllBooks,
} from 'modules/api';
import { PaginationProps } from 'antd';
import { BookPreviewResponse } from './types';

interface GetBookPreviewsParams {
  onSuccess?: (response: BookPreviewResponse) => void;
  onError?: (error: Error) => void;
  pagination?: PaginationProps;
}

interface CreateBookPreviewsParams {
  onSuccess?: (response: BookPreviewResponse) => void;
  onError?: (error: Error) => void;
}

interface DeleteBookPreviewParams {
  onSuccess?: (response: BookPreviewResponse) => void;
  onError?: (error: Error) => void;
}

export const useGetBookPreviews = ({ pagination, ...options }: GetBookPreviewsParams) => {
  return useQuery(
    ['getBookPreviews', pagination?.current],

    () => getBookPreviews(pagination),
    options,
  );
};

export const useGetBooks = (options) => useQuery('getAllBooks', fetchAllBooks, options);

export const useUpdateBookPreviews = (options) => useMutation(updateBookPreviews, options);

export const useCreateBookPreviews = (options: CreateBookPreviewsParams) =>
  useMutation(createBookPreviews, options);
export const useDeleteBookPreviews = (options: DeleteBookPreviewParams) =>
  useMutation(deleteBookPreviews, options);
