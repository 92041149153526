import { compose } from 'redux';
import { withAuth } from 'modules/auth/containers';
import { withProfile, withDashboardStore } from 'modules/v2/containers';
import { MyBooksSideNav } from 'modules/v2/components';
import { ErrorState } from 'modules/v2/common/components';
import env from 'environments';
import { DashboardTemplate } from '../../templates';

const MyBooks = ({ children }) => {
  return (
    <DashboardTemplate
      sidebar={<MyBooksSideNav />}
      hasSidebar
      error={
        <ErrorState
          topMessage="Our system encountered an error while loading your books information."
          bottomMessage={
            <>
              Please call our customer service at <b>{env.SUPPORT_PHONE}</b>.
            </>
          }
        />
      }
    >
      {children}
    </DashboardTemplate>
  );
};

export default compose(withAuth, withProfile, withDashboardStore)(MyBooks);
