import { put, select } from 'redux-saga/effects';
import { matchPath } from 'react-router';

import { watchExitRoute } from 'modules/router/sagas';

import { isSucceeded } from 'store/status';

import { clearDraftEditor, goToRoot } from 'modules/editor/store';
import { orderSelector, clearPublish } from 'modules/publish/store';

const matcher = (pathname, route) => matchPath(pathname, route) !== null;

// TODO: Define the publish over here
const isPublishRoute = (location) => matcher(location.pathname, '/');

export function* onExitPublish() {
  // If the order is completed, clear the draft data and do not allow to return to the editor
  // Otherwise, clear the publish data, including the preflight check, credits and preferences
  const order = yield select(orderSelector);
  const isOrderCompleted = isSucceeded(order.status);

  if (isOrderCompleted) {
    yield put(goToRoot());
    yield put(clearDraftEditor());
  } else {
    yield put(clearPublish());
  }
}

export function* watchExitPublish() {
  const watchFn = (location) => isPublishRoute(location);
  const validFn = (location) => !isPublishRoute(location);

  yield watchExitRoute(watchFn, validFn, onExitPublish);
}
