import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  .buttonsWrapper {
    margin-left: 15px;
    padding: 10px 0;
  }

  .cancelButton {
    color: #980f20;
    letter-spacing: 1px;
    top: 1px;
    left: -1px;
  }

  .impo {
    padding-top: 29px;
    font-weight: 600;

    span {
      padding-right: 5px;
      font-size: 20px;
    }
  }

  .contentWrap {
    padding: 0 0 0 50px;
    flex-grow: 1;

    .contentWrapInner {
      max-width: 620px;
      margin: 0 auto;

      h6 {
        text-align: center;
      }
    }
  }

  .address-form {
    text-align: left;

    label {
      font-size: 14px;
      font-weight: bold;
      line-height: 25px;
      color: ${color.GreySelectDd};
      display: block;
      margin: 0 10px 20px 15px;
      position: relative;

      span {
        padding: 5px;
      }
    }

    input {
      height: 45px;
      width: 100%;
      display: block;
      border: 1px solid #cccccc;
      border-radius: 3px;
      background-color: #ffffff;
      padding: 0 15px;
      font-size: 16px;
      line-height: 22px;
      color: ${color.GreySelectDd};
    }
  }

  .btn {
    margin: 50px 0 0;

    a {
      line-height: 44px;
      height: 44px;
      font-weight: bold;
      padding: 0 25px;
    }
  }

  .ant-checkbox-wrapper {
    margin: 0 10px 10px;

    input {
      height: 20px;
    }
  }

  .button-bar {
    max-width: 620px;
    margin: 0 auto;
    padding: 10px 15px;

    a {
      letter-spacing: 1px;
      position: relative;
      top: 1px;
      display: inline-block;
      font-weight: 700;
      color: #980f20;
      cursor: pointer;
      margin: 0 0 0 20px;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  .ant-btn-primary {
    height: 38px;
  }

  .form-input-error {
    border: 1px solid #f27575 !important;
  }
`;

export default {
  Wrapper,
};
