import styled from 'styled-components';
import { CheckCircleFilled } from '@ant-design/icons';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-height: 60vh;
  padding: 24px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Icon = styled(CheckCircleFilled)`
  font-size: 46px !important;
  color: ${color.SuccessSaving};
  border: 16px solid rgba(92, 163, 64, 0.3);
  border-radius: 50%;
`;

const OrderNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 12px;
  border: 1px solid ${color.LightGray};
  border-radius: 4px;
`;

const Title = styled.p`
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-right: 2px;
`;

const Description = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

export default { Container, Icon, Title, Description, OrderNumberContainer };
