import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Field } from 'formik';

import { PrimaryBlackButton as PrimaryBaseButton } from 'modules/common/components/UIComponents';
import { FieldGroup as BaseFieldGroup } from 'modules/onboard/components';
import { InfoCircleFilled as BaseIcon } from '@ant-design/icons';

const GroupTitle = styled.div`
  border: 1px solid ${color.GreyLightText};
  background: ${color.lightBackground};
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 15px;
  font-weight: 700;
`;

const UploadWrapper = styled.div`
  height: 144px;
  width: 100%;
  margin-bottom: 20px;

  div {
    background: ${color.White}!important;
  }

  ${({ $hasError }) =>
    $hasError
      ? `
      div {
        background: ${color.ErrorBg}!important;
      }

    `
      : null};
`;

const FieldGroup = styled(BaseFieldGroup)`
  margin: 0;
  font-size: 14px;

  input,
  .ant-input-group-wrapper {
    border-color: ${color.GreyLightText}!important;
  }

  .ant-input-group-wrapper input {
    border: none !important;
  }

  .avatar-uploader {
  }

  input {
    height: 43px;
  }

  .ant-input-group-addon {
    border: none;
  }
`;

const DentistCollapse = styled.div`
  margin-top: 20px;
  background: ${color.lightBackground};

  .ant-collapse-item {
    border: 1px solid ${color.GreyLightText};
    border-radius: 6px !important;
    margin-bottom: 20px;
  }

  .ant-collapse-header {
    font-weight: bold;
  }

  .ant-collapse-content-box {
  }
`;

const PrimaryBlackButton = styled(PrimaryBaseButton)`
  border-radius: 6px !important;
`;

const NavigationContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  border-top: 1px solid ${color.GreyLightText};

  button {
    width: 150px;
  }
`;

const InfoCircleFilled = styled(BaseIcon)`
  font-size: 20px;
  height: 20px;
  margin-right: 12px;
`;

const WarnMessage = styled.div`
  display: block;
  margin: 20px 0;
  display: flex;
  padding: 10px;
  border-radius: 6px;
  background-color: ${color.WarnMessageBackground};
  color: ${color.WarnMessageText};
  font-weight: 600px;

  strong {
    text-decoration: underline;
  }
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['$hasError'].includes(prop),
})`
  display: flex;
  border: 1px solid ${color.GreyLightText}!important;

  .ant-select-selector {
    height: 45px !important;
  }

  .ant-select-selection-search-input,
  .ant-select-selection-placeholder {
    line-height: 45px !important;
  }
`;

const SwitchContainer = styled.div`
  display: flex;

  p {
    margin-left: 20px;
    font-weight: 600;
    line-height: 23px;
  }
`;

export default {
  FieldGroup,
  GroupTitle,
  UploadWrapper,
  DentistCollapse,
  PrimaryBlackButton,
  NavigationContainer,
  InfoCircleFilled,
  WarnMessage,
  FormikField,
  SwitchContainer,
};
