import { Col, Row } from 'antd';
import { useState } from 'react';
import Login from 'modules/dashboard/components/Login';
import { checkEmail } from 'modules/common/utils';
import { toast } from 'react-toastify';
import { authUser, forgetPassword } from 'modules/api';
import { useMutation } from 'react-query';
import S from './styles';
import { getRouteHolidaySaleSelect } from '../../routes/navigation';
import { ForgotPassword, SuccessMessage } from '../../../dashboard/components';

const BAD_REQUEST_STATUS_CODE = 1;

const HolidaySaleLogin = ({ history }) => {
  const [authData, setAuthData] = useState({ username: '', password: '' });
  const [message, setMessage] = useState('');
  const [malformedEmail, setMalformedEmail] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [loader, setLoader] = useState({
    loading: false,
    loaded: false,
  });

  const handleLoginChange = (event) => {
    setAuthData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const showReset = (value: boolean) => {
    setMessage('');
    setStatusCode(0);
    setShowPasswordReset(value);
  };

  const { mutate: loginMutate } = useMutation('authUser', authUser, {
    onSuccess: (data) => {
      if (data.statusCode === BAD_REQUEST_STATUS_CODE) {
        setStatusCode(data.statusCode);
        setMessage(data.message);
      } else {
        history.push(getRouteHolidaySaleSelect());
      }
    },
    onError: () => {
      toast.dismiss();
      toast.error('Oops Something went Wrong!!!', {
        className: 'Errortoaster',
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const { mutate: forgotPasswordMutate } = useMutation('forgetPassword', forgetPassword, {
    onSuccess: (data) => {
      setStatusCode(data.statusCode);
      setLoader({
        loading: false,
        loaded: true,
      });
      if (data.statusCode === BAD_REQUEST_STATUS_CODE) {
        setMessage('User does not exist');
      } else {
        setShowResetSuccess(true);
      }
    },
    onError: () => {
      toast.dismiss();
      toast.error('Oops Something went Wrong!!!', {
        className: 'Errortoaster',
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const handleLogin = () => {
    setMalformedEmail(false);
    setMessage('');
    setStatusCode(0);

    if (authData.password && checkEmail(authData.username)) {
      return loginMutate({ ...authData });
    }

    setMalformedEmail(true);
    return setMessage('Email/Password is required');
  };

  const handlePasswordReset = () => {
    setShowResetSuccess(false);
    if (!authData.username || !checkEmail(authData.username)) {
      return setMessage('Invalid email address');
    }

    setLoader({
      loading: true,
      loaded: false,
    });
    return forgotPasswordMutate({
      username: authData.username,
      template: 'template_forgot_password',
    });
  };

  const getPreview = () => {
    if (!showPasswordReset) {
      return (
        <Login
          username={authData.username}
          handleChange={handleLoginChange}
          message={message}
          handleLogin={handleLogin}
          invalidEmail={malformedEmail}
          statusCode={statusCode}
          setShowPasswordReset={showReset}
          noBackground
          noFooter
          noLogo
        />
      );
    }
    if (showResetSuccess) {
      return (
        <SuccessMessage
          email={authData.username}
          noBackground
          noFooter
          noLogo
          setShowPasswordReset={showReset}
        />
      );
    }
    return (
      <ForgotPassword
        statusCode={statusCode}
        message={message}
        handleChange={handleLoginChange}
        handleSubmit={handlePasswordReset}
        setShowPasswordReset={showReset}
        auth={loader}
        noBackground
        noFooter
        noLogo
      />
    );
  };

  return (
    <S.Card bordered={false}>
      <Row>
        <Col span={24}>
          <S.CenterHorizontalVerticalDiv>
            <S.Layout1>
              {!showPasswordReset && (
                <S.CenterSection>
                  <S.Title level={3}>To continue, login with your Authorify Account</S.Title>
                </S.CenterSection>
              )}
              {getPreview()}
            </S.Layout1>
          </S.CenterHorizontalVerticalDiv>
        </Col>
      </Row>
    </S.Card>
  );
};

export default HolidaySaleLogin;
