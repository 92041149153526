import withStore from 'store/StoreContainer';

import { getPublish, clearPublish, submitOrder } from 'modules/publish/store';

const mapDispatchToProps = {
  getPublish,
  clearPublish,
  submitOrder,
};

export default withStore(null, mapDispatchToProps);
