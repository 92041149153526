import { DashboardTemplate } from 'modules/v2/templates';
import { DentistSideNav } from 'modules/v2/components';

const GuideAnalytics = () => {
  return (
    <DashboardTemplate sidebar={<DentistSideNav />} hasSidebar>
      Guide Analytics
    </DashboardTemplate>
  );
};

export default GuideAnalytics;
