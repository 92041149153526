import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import styled, { keyframes } from 'styled-components';

import * as color from 'modules/v2/common/theme/color';

const MainSection = styled.div`
  text-align: center;
  padding: 20px;
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const pulsateAnimation = keyframes`
  0% {
    border-color: rgba(92, 163, 64, 0.3);
  }
  50% {
    border-color: rgba(92, 163, 64, 0.6);
  }
  100% {
    border-color: rgba(92, 163, 64, 0.3);
  }
`;

const CheckIcon = styled(CheckCircleFilled)`
  font-size: 46px !important;
  color: ${color.SuccessSaving};
  border: 16px solid rgba(92, 163, 64, 0.3);
  border-radius: 50%;
  animation: ${pulsateAnimation} 1s infinite;
`;

const OkButton = styled(Button)`
  width: 250px !important;
`;

export default {
  MainSection,
  Section,
  CheckIcon,
  OkButton,
};
