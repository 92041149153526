//
// TODO: We should not have any references to segments
// to make this component to be reusable
import { map } from 'lodash-es';
import S from './styles';

const SelectMultiField = ({
  options = [],
  field,
  form,
  onBlur,
  placeholder = '',
  onChange,
  selectedSegment,
  maxCount = 1,
  disabled,
  className,
  value,
}) => {
  const getOnChangeHandle = (option) => {
    if (onChange) {
      onChange(option);
    }

    if (form && field && field.name) {
      form.setFieldValue(field.name, option);
    }
  };

  return (
    <S.Select
      className={className}
      defaultValue={selectedSegment}
      mode="multiple"
      onBlur={onBlur}
      placeholder={placeholder}
      onChange={getOnChangeHandle}
      maxTagCount={maxCount}
      disabled={disabled}
      showArrow
      {...(value ? { value } : {})}
    >
      {map(options, (option) => (
        <S.Select.Option key={option.id} value={option.id}>
          {option.name}
        </S.Select.Option>
      ))}
    </S.Select>
  );
};

export default SelectMultiField;
