import env from 'environments';

import S from './styles';

const Advertisement = ({ offer }) => {
  const { whatsIncluded } = offer;

  return (
    <>
      <S.ImgContainer>
        <S.CoverImage src={offer.image} alt="offer-icon" />
      </S.ImgContainer>
      <S.Heading>Here’s What You Get:</S.Heading>
      <S.TextPointsContainer>
        {whatsIncluded.map((text) => (
          <S.TextPoints key={text}>
            <S.TickedIcon type="check" />
            <S.AdPoint>{text}</S.AdPoint>
          </S.TextPoints>
        ))}
      </S.TextPointsContainer>
      <S.BreakLine />
      <S.Text>{offer.description2}</S.Text>

      <S.Heading>Need Help?</S.Heading>
      <S.Text>
        Complete our secure order form to reserve your spot or call our sales team at{' '}
        {env.SUPPORT_PHONE}
      </S.Text>
      <S.MailWrapper>{env.SUPPORT_EMAIL}</S.MailWrapper>
      <S.Text>Monday through Friday</S.Text>
      <S.Text>{env.SUPPORT_OPPENING_HOURS}</S.Text>
    </>
  );
};

export default Advertisement;
