import { ProfileTemplate } from 'modules/dashboard/templates';
import { compose } from 'redux';
import { useState, useEffect } from 'react';
import { get } from 'lodash-es';
import { withProfile } from 'modules/dashboard/containers';
import { withAuth } from 'modules/auth/containers';
import { PageProfileForm } from 'modules/dashboard/components';
import { useQuery, useMutation } from 'react-query';
import { notification } from 'modules/common/utils/notify';
import {
  getLandingPageProfile,
  saveLandingPageProfile,
  uploadIntakeFormHeadshot,
} from 'modules/api';
import { Loader } from 'modules/common/components';
import PageProfileModal from './components/PageProfileModal';
import S from './styles';

const PageProfile = ({ avatar, profile, signOut }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [landingPageImage, setLandingPageImage] = useState(null);
  const [landingPageImageUploading, setLandingPageImageUploading] = useState(null);

  const [loadingTitle, setLoadingTitle] = useState('');
  const [loadingDescription, setLoadingDescription] = useState('');
  const [image, setImage] = useState('');
  const [intialvalue, setInitialValue] = useState({});

  const { data } = profile;
  const email = get(data, 'email');
  const username = get(data, 'firstName');

  const showModal = () => {
    setModalOpen(true);
  };

  const selectedProfileLogo = (e) => {
    setLandingPageImage(e);
  };

  const base64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onImageConfirm = async () => {
    setLoadingTitle('Uploading Landing Page Profile Image');
    setLoadingDescription('Please wait while we upload your landing profile page image.');
    setLandingPageImageUploading(true);
    setModalOpen(false);
    const base64String = await base64(landingPageImage);
    uploadIntakeFormHeadshot({ data: base64String, filename: landingPageImage.name })
      .then((d) => {
        setLandingPageImageUploading(false);
        setImage(d?.data?.url);
      })
      .catch(() => {
        setLandingPageImageUploading(false);
      });
  };
  const { data: landingPageProfile, refetch } = useQuery(
    ['getLandingPageProfile'],
    getLandingPageProfile,
  );
  const landingPageProfileData = get(landingPageProfile, 'data');

  useEffect(() => {
    if (landingPageProfileData) {
      setInitialValue(landingPageProfileData);
      setImage(landingPageProfileData.brokerLogo);
    }
  }, [landingPageProfileData]);

  const { mutateAsync: saveLandingProfile, isLoading } = useMutation(saveLandingPageProfile);

  const onSave = (e) => {
    setLoadingTitle('Saving Landing Page Profile');
    setLoadingDescription('Please wait while we save your landing profile page.');

    const request = { ...e, ...{ brokerLogo: image } };

    saveLandingProfile(request)
      .then(() => {
        refetch();
        notification.success({
          description: 'Landing Page Profile has been saved successfully',
          autoClose: 3000,
        });
      })
      .catch(() => {
        notification.error({
          description:
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const content = (
    <>
      {isLoading || landingPageImageUploading ? (
        <Loader height="100%" title={loadingTitle} description={loadingDescription} />
      ) : (
        <PageProfileForm
          modalOpen={showModal}
          landingPageProfileData={intialvalue}
          onSave={onSave}
          image={image}
        />
      )}
    </>
  );

  return (
    <ProfileTemplate
      avatar={avatar}
      email={email}
      title="Landing Page Footer Profile"
      username={username}
      onLogout={signOut}
      sidebarWidth={0}
      noPaddingLeft
      noPaddingRight
    >
      {content}
      <S.ModalWrapper
        title="Broker Logo"
        isOpen={isModalOpen}
        onConfirm={onImageConfirm}
        onCancel={() => setModalOpen(false)}
        okText="OK"
      >
        <PageProfileModal
          type="profile"
          selectedProfileLogo={(e) => selectedProfileLogo(e)}
          imageUrlForEdit={null}
          isEditImage={false}
          toggleCancelEdit={null}
        />
      </S.ModalWrapper>
    </ProfileTemplate>
  );
};

const Composed = compose(withProfile, withAuth)(PageProfile);

export default Composed;
