import { call, put, select, takeEvery } from 'redux-saga/effects';
import { notification } from 'modules/common/utils';
import { resetSection, getError, getDraftLinksById } from 'modules/api';
import { GET_DRAFT_BY_ID, RESET_SECTION } from 'modules/editor/store/constants';
import { draftIdSelector } from 'modules/editor/store/';
import { filterDraftPayload } from './utils';

const RESET_ERROR_DESCRIPTION = 'There was a problem while resetting this section';
const RESET_SUCCESS_DESCRIPTION = 'Resetting this section was successful';

export function* onResetSection({ payload: meta }) {
  const draftId = yield select(draftIdSelector);
  const { sectionId } = meta;

  yield put({
    type: `${RESET_SECTION}_REQUESTED`,
    meta,
  });

  try {
    const response = yield call(resetSection, { sectionId, draftId });
    const payload = filterDraftPayload(response);
    const { draft } = payload;
    const payloadLinks = yield call(getDraftLinksById, { draftId });

    yield put({
      type: `${GET_DRAFT_BY_ID}_SUCCEEDED`,
      payload,
    });

    yield put({
      type: `${RESET_SECTION}_SUCCEEDED`,
      error: false,
      payload,
      meta,
    });

    yield put({
      type: 'draft/GET_DRAFT_LINKS_SUCCEEDED',
      payload: payloadLinks.data,
    });

    if (draft === null) {
      notification.error({
        description: RESET_ERROR_DESCRIPTION,
      });
    }

    notification.success({
      description: RESET_SUCCESS_DESCRIPTION,
    });

    return payload;
  } catch (error) {
    const payload = getError(error);

    yield put({
      type: `${RESET_SECTION}_FAILED`,
      error: true,
      payload,
      meta,
    });

    notification.error({
      description: RESET_ERROR_DESCRIPTION,
    });

    return payload;
  }
}

export function* watchResetSection() {
  yield takeEvery(RESET_SECTION, onResetSection);
}
