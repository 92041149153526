//

import { Form, Formik } from 'formik';
import { CloudUploadOutlined } from '@ant-design/icons';
import { FilePicker, FormErrors, HideOnMobile } from 'modules/common/components';
import env from 'environments';
import { CustomButton, Heading } from '../UIComponents';
import S from './styles';
import validationSchema from './validationSchema';

const GenerateBookForm = ({
  fields,
  onSave,
  uploadedImage,
  setUploadedImage,
  handleNameChange,
  removeUploadedImage,
  loading,
}) => {
  const handleImageChange = (file) => {
    if (file) {
      setUploadedImage(URL.createObjectURL(file), file);
    }
  };

  const removeImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    removeUploadedImage();
  };

  const imageUploadedText = (
    <S.ImageUploadedText>
      Image Uploaded (<S.RemoveImage onClick={removeImage}>Remove</S.RemoveImage>)
    </S.ImageUploadedText>
  );
  return (
    <S.BookForm>
      <Formik initialValues={fields} validationSchema={validationSchema} onSubmit={onSave}>
        {({ errors, handleSubmit, handleChange, touched, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <FormErrors errors={errors} />
            <HideOnMobile Component={<Heading>Customize Your Book Preview</Heading>} />
            <S.InputWrap
              placeholder="Enter your full name here"
              id="name"
              name="name"
              onChange={handleChange}
              onBlur={handleNameChange}
              error={errors.name && touched.name}
            />
            <S.InputWrap
              placeholder="Enter your email address here"
              id="email"
              name="email"
              onChange={handleChange}
              error={errors.email && touched.email}
            />
            <S.InputMask
              placeholder="Enter your phone number here"
              id="phone"
              name="phone"
              onChange={handleChange}
              type="text"
              mask="999-999-9999"
              error={errors.phone && touched.phone}
            />
            <S.UploadImageWrapper>
              <FilePicker
                type="preview-book-image"
                handleImageChange={handleImageChange}
                icon={
                  uploadedImage ? <S.UploadedImage src={uploadedImage} /> : <CloudUploadOutlined />
                }
                uploadText={uploadedImage ? imageUploadedText : 'Select image to upload'}
                onSelect={null}
                isEditImage={false}
                toggleCancelEdit={null}
                imageUrlForEdit={null}
                onSuccess={null}
              />
            </S.UploadImageWrapper>
            <CustomButton htmlType="submit" disabled={loading || !isValid} loading={loading}>
              Save Preview
            </CustomButton>
            <S.Description>
              By clicking this button you consent to receive a text with a link to your digital book
              as well as receive marketing text messages from us at the cell number used when
              signing up. Consent is not a condition of any purchase. Reply STOP to cancel. Msg
              frequency varies. Msg and data rates may apply. View{' '}
              <a href={env.TERMS_URL} rel="noopener noreferrer" target="_blank">
                TERMS
              </a>{' '}
              and{' '}
              <a href={env.PRIVACY_URL} rel="noopener noreferrer" target="_blank">
                PRIVACY POLICY
              </a>
              .
            </S.Description>
          </Form>
        )}
      </Formik>
    </S.BookForm>
  );
};

export default GenerateBookForm;
