import { concat } from 'lodash-es';

import { getPayload } from 'modules/api';

export const mergeAddresses = (addresses) => {
  if (!addresses) {
    return [];
  }

  const { billingAddress, externalShippingAddress, shippingAddress = [] } = addresses;
  const fixedAddresses = [billingAddress, externalShippingAddress].filter(Boolean);

  return concat(fixedAddresses, shippingAddress);
};

export const getAddressesPayload = (response) => {
  const payload = getPayload(response);
  return mergeAddresses(payload);
};
