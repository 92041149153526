import { EditorState, convertFromRaw, CompositeDecorator } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { get, noop } from 'lodash-es';

import highlightDecorator from '../HighlightDecorator';
import getEntityObject from './getEntityObject';

// TODO: Write tests
export const getStateFromElements = (elements) =>
  elements.reduce((acc, element) => {
    const rawValue = get(element, 'rawValue');
    let defaultValue = get(element, 'defaultValue');

    // for some reason, this is showing the text content as "null" without this
    if (defaultValue === null) {
      defaultValue = '';
    }

    const content = rawValue
      ? convertFromRaw(getEntityObject(rawValue))
      : stateFromHTML(defaultValue);

    // This is a work around:
    // Note: Using DraftJSPlugin as Editor component, the decorators added works only for the last instance of the component
    // So it is needed to add the decorator only for the title for now
    const decorator = new CompositeDecorator([highlightDecorator]);
    const addDecorator = element.id === 'title' ? decorator : null;

    const state = EditorState.createWithContent(content, addDecorator);

    return {
      ...acc,
      [element.id]: state,
    };
  }, {});

// TODO: Write tests
export const createContextObject = (context, element) => {
  const { selected, setSelected, editorState, setEditorState, onChange = noop } = context;
  const key = (element && element.id) || (selected && selected.id);
  const fallback = EditorState.createEmpty();

  const setEditorStateByKey = (editor) => {
    if (key) {
      onChange(editor);
      setEditorState({
        ...editorState,
        [key]: editor,
      });
    }
  };

  return {
    selected,
    setSelected,
    editorState: get(editorState, key, fallback),
    setEditorState: setEditorStateByKey,
  };
};
