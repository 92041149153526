import styled from 'styled-components';
import { Tooltip as TooltipBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  margin: 20px 20px 50px 20px;
  width: calc(100% - 75px);
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  padding: 20px;

  textarea {
    border-radius: 2px;
    height: 120px;
    border: 1px solid #000000;
    :disabled {
      height: 60px;
      border: 1px solid #dbdbdb;
      color: #000;
    }
  }
  .totalCount {
    font-size: 14px;
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }

  .header-2 {
    .sub-title {
      font-weight: 700;
      font-size: 16px;
      margin: 0 0 20px 0;
      display: block;
    }
  }

  .title-header {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    .title,
    .sub-title {
      display: block;
    }
    .title {
      font-size: 14px;
      margin: 0 0 10px 0;
    }
    .sub-title {
      font-size: 16px;
      margin: 0;
      font-weight: 400;
    }
  }

  .data-grid {
    margin-bottom: 20px;
    .Full-Width {
      margin-top: 20px;
    }
  }
`;

const Tooltip = styled(TooltipBase)`
  color: ${color.SkyBlue};
  margin-left: 10px;
`;

const ImageSrc = styled.div`
  width: 50px;
  height: 50px;
  background: ${color.GreyFormBorder};
  float: right;
`;

export default {
  Wrapper,
  Tooltip,
  ImageSrc,
};
