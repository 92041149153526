import env from 'environments';
import getAdminInstance from './adminInstance';

const adminInstance = getAdminInstance();

export const fetchReleaseNotes = () => {
  const url = '/release-notes';
  const params = {
    app: env.REACT_APP_NAME,
    all: true,
  };

  return adminInstance.get(url, { params });
};

export const fetchLatestReleaseNote = () => {
  const url = '/release-notes/latest';
  const params = {
    app: env.REACT_APP_NAME,
    all: true,
  };

  return adminInstance.get(url, { params });
};
