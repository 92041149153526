import { ValidIcon, InvalidIcon } from 'modules/v2/common/components/SvgIcons';
import { ClosePublishIcon, SaveIcon } from 'modules/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';

const CriteriaItem = ({ checked, text, onEdit }) => {
  const icon = checked ? <ValidIcon /> : <InvalidIcon />;

  return (
    <li
      className="font-semibold border-b border-neutral-100 h-16 flex justify-between items-center relative"
      checked={checked}
    >
      <div className="flex items-center">
        <div className="mr-4 relative bottom-[1px]">{icon}</div>
        <p>{text}</p>
      </div>

      {!checked && (
        <Button color="outlined" size="base" type="link" className="w-[116px]" onClick={onEdit}>
          Edit
        </Button>
      )}
    </li>
  );
};

export default CriteriaItem;
