import styled from 'styled-components';
import BaseInputMask from 'react-input-mask';
import { device as BreakPoints } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';

const BookForm = styled.div`
  text-align: left;
  flex: 1;
  @media screen and ${BreakPoints.tabletS} {
    max-width: unset;
    flex: 1;
    order: 1;
  }
`;

const LabelWrap = styled.label`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
  font-weight: 600;
  color: ${color.Black};
  width: 100%;
  text-align: left;
`;

const InputWrap = styled.input`
  font-family: 'Roboto' !important;
  font-size: 16px;
  font-weight: normal !important;
  width: 100%;
  padding: 10px 10px;
  text-align: left;
  margin: 10px 0;
  color: ${({ error }) => (error ? color.RedFormText : color.Black)};
  border: ${({ error }) => (error ? `1px solid ${color.RedFormText}` : `1px solid ${color.Black}`)};
  border-radius: 5px;
  @media screen and ${BreakPoints.tabletS} {
    width: 100%;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${({ error }) => (error ? color.RedFormText : color.Black)};
  }
`;

const InputMask = styled(BaseInputMask)`
  font-family: 'Roboto' !important;
  font-size: 16px;
  font-weight: normal !important;
  width: 100%;
  padding: 10px 10px;
  text-align: left;
  margin: 10px 0;
  color: ${({ error }) => (error ? color.RedFormText : color.Black)};
  border: ${({ error }) => (error ? `1px solid ${color.RedFormText}` : `1px solid ${color.Black}`)};
  border-radius: 5px;
  @media screen and ${BreakPoints.tabletS} {
    width: 100%;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${({ error }) => (error ? color.RedFormText : color.Black)};
  }
`;

const ImageUploadedText = styled.p`
  font-family: 'Roboto' !important;
  font-size: 16px;
  font-weight: normal !important;
  cursor: pointer;
`;

const RemoveImage = styled.span`
  color: ${color.PrimaryColor};
  cursor: pointer;
  text-decoration: underline;
`;

const UploadedImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
`;

const UploadImageWrapper = styled.div`
  margin: 0 -20px;
`;

const FlexContainer = styled.div`
  display: flex;
  .city {
    margin-right: 10px;
  }
`;

const FlexItem = styled.div`
  width: 100%;
  .ant-select {
    width: 100%;
  }
  .ant-select-selection--single {
    padding: 7px 10px;
    text-align: left;
    margin: 5px 0 12px 0;
    height: 41px;
    border: 1px solid ${color.GreyFormBorder};
    border-radius: 2px;
  }
  &.country-select {
    margin-right: 10px;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    color: ${color.GreyFormBorder};
  }
  .ant-select-selection__rendered {
    margin-left: 0px;
    margin-top: -2px;
  }
`;

const Description = styled.p`
  font-family: 'Roboto' !important;
  font-size: 12px;
  line-height: 20px;
`;

export default {
  BookForm,
  ImageUploadedText,
  InputWrap,
  LabelWrap,
  RemoveImage,
  UploadedImage,
  UploadImageWrapper,
  FlexContainer,
  FlexItem,
  InputMask,
  Description,
};
