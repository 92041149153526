import { call, put, takeEvery } from 'redux-saga/effects';

import { notification } from 'modules/common/utils';

import { resetDraft, resource, getError, getDraftLinksById } from 'modules/api';

import { GET_DRAFT_BY_ID, RESET_DRAFT } from 'modules/editor/store/constants';

import { filterDraftPayload } from './utils';

const RESET_ERROR_DESCRIPTION = 'There was a problem while resetting your book';
const RESET_SUCCESS_DESCRIPTION = 'Resetting your book was successful';

export function* onResetDraft({ payload: meta }) {
  try {
    const resetDraftRequest = resource(RESET_DRAFT, resetDraft, filterDraftPayload);
    const payload = yield call(resetDraftRequest, meta);
    const payloadLinks = yield call(getDraftLinksById, meta);
    const { draft } = payload;

    if (draft === null) {
      notification.error({
        description: RESET_ERROR_DESCRIPTION,
      });
    }

    notification.success({
      description: RESET_SUCCESS_DESCRIPTION,
    });

    yield put({
      type: `${GET_DRAFT_BY_ID}_SUCCEEDED`,
      payload,
    });

    yield put({
      type: 'draft/GET_DRAFT_LINKS_SUCCEEDED',
      payload: payloadLinks.data,
    });

    return payload;
  } catch (error) {
    const payload = getError(error);

    notification.error({
      description: RESET_ERROR_DESCRIPTION,
    });

    // eslint-disable-next-line no-console
    console.error(payload);

    return payload;
  }
}

export function* watchResetDraft() {
  yield takeEvery(RESET_DRAFT, onResetDraft);
}
