import gql from 'graphql-tag';

export default gql(`
query($where:JSON){
    books(where:$where){
        _id,
        name,
        category,
        status,
        updatedAt,
        createdAt
    }
}`);
