import styled from 'styled-components';
import * as font from 'modules/v2/common/theme/font';
import * as color from 'modules/v2/common/theme/color';

import { Select as BaseSelect } from 'antd';

const Select = styled(BaseSelect)`
  .ant-select {
    height: 40px;
    border: 1px solid ${color.neutral200} !important;
  }

  .ant-select-selector {
    border-radius: 6px !important;
    max-height: 44px;
    font-size: 14px;
    font-family: ${font.FigtreeFont};

    overflow: auto;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      background: ${color.neutral100};
    }
    ::-webkit-scrollbar-thumb {
      background: ${color.neutral200};
      border-radius: 6px;
    }
  }

  .ant-select-selection-overflow {
    max-height: 44px;
  }

  .ant-select-selection-item {
    border-radius: 6px;
  }

  .ant-select-item .ant-select-item-option {
    border-radius: 6px;
  }
`;

export default { Select };
