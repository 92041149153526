import { Item } from './components';

import S from './styles';

const BookLinks = ({ children }) => (
  <S.Wrapper>
    <S.Title>My Book Links</S.Title>
    <S.Info>
      Get quick access to all of your digital books, landing pages, and book marketing materials.
    </S.Info>
    {children}
  </S.Wrapper>
);

BookLinks.Item = Item;

export default BookLinks;
