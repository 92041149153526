import { useEffect, useState } from 'react';

function useUserData() {
  const key = 'userData';
  const EVENT_ID = 'userData_storage_change';

  const [userData, setUserData] = useState(() => {
    const savedData = localStorage.getItem(key);
    return savedData ? JSON.parse(savedData) : null;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const savedData = localStorage.getItem(key);
      setUserData(savedData ? JSON.parse(savedData) : null);
    };

    window.addEventListener(EVENT_ID, handleStorageChange);

    return () => {
      window.removeEventListener(EVENT_ID, handleStorageChange);
    };
  }, [key]);

  return userData;
}

export default useUserData;
