import { formatDateText } from 'modules/v2/pages/LaunchEmailCampaign/utils';
import * as PropTypes from 'prop-types';

const EmailCard = ({ data }) => {
  const {
    emailName,
    templateName,
    image,
    usesRelativeDate,
    absoluteMonth,
    absoluteDay,
    relativeDays,
  } = data;

  const formattedDate = formatDateText({
    usesRelativeDate,
    absoluteMonth,
    absoluteDay,
    relativeDays,
  });

  return (
    <div className="p-6 flex border-b-[1px] border-neutral-200 font-figtree">
      <div>{emailName}</div>

      <div>
        <img className="rounded-lg" width="164" height="80" src={image} alt={templateName} />
      </div>

      <div className="flex flex-col px-4">
        <div className="mb-1">
          <span className="text-sm text-neutral-500">Template name:</span>
          <span className="text-sm text-neutral-900 font-semibold">{templateName}</span>
        </div>

        <div>
          <span className="text-sm text-neutral-500 ">Scheduled date:</span>
          <span className="text-sm text-neutral-900 font-semibold"> {formattedDate}</span>
        </div>
      </div>
    </div>
  );
};

EmailCard.propTypes = {
  data: PropTypes.shape({
    emailName: PropTypes.string.isRequired,
    templateName: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    usesRelativeDate: PropTypes.bool.isRequired,
    absoluteMonth: PropTypes.number,
    absoluteDay: PropTypes.number,
    relativeDays: PropTypes.number,
  }).isRequired,
};

export default EmailCard;
