import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const ErrorWrap = styled.div`
  text-align: left;
  border-radius: 3px;
  background: ${color.ErrorBg};
  padding: 10px 15px 10px;
  margin: 20px auto;
  border: 1px solid ${color.ErrorBdr};
`;

export default { ErrorWrap };
