import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Empty = styled.div`
  display: flex;
  gap: 0;
  flex: 1 0 0;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 2px dashed #e9e9e9;
  color: ${color.TitleBorder};
  font-weight: bold;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 55px 0;
  position: relative;
  cursor: pointer;

  &:hover {
    border: 2px dashed ${color.neutral300};
  }

  svg {
    fill: ${color.TitleBorder};
  }

  span {
    display: block;
    padding: 5px 0 0 0;
    font-size: 16px;
  }
`;

export default { Empty };
