import styled from 'styled-components';
import { DatePicker as DatePickerBase, Layout as LayoutBase, Select as SelectBase } from 'antd';
import { Tooltip as TooltipBase } from 'modules/v2/common/components';
import * as color from 'modules/v2/common/theme/color';
import { Field as FormikField } from 'formik';
import { BounceRateMetric, LightGray } from 'modules/v2/common/theme/color';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
`;

const Wrapper = styled.div`
  input,
  .ant-select {
    width: 100%;
  }
`;

const WrapperContent = styled(LayoutBase.Content)`
  padding: 20px;
  background-color: ${color.White};
  height: 100%;
  overflow: auto;
`;

const TemplateView = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: 647px;
  border-radius: 2px;

  img {
    max-width: 100%;
  }
`;

const Title = styled.h4`
  font-size: 16px !important;
`;

const Label = styled.label`
  margin-bottom: 10px;
  margin-top: ${({ $noMarginTop }) => ($noMarginTop ? 0 : '20px')};
  font-size: 12px;
  display: block;
`;

const DatePicker = styled(DatePickerBase)`
  width: 100%;
  margin-bottom: 20px;
`;

const LabelWithIcon = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextField = styled(FormikField)`
  border: 1px solid ${LightGray};
  border-radius: 6px;
  height: 38px;
  padding: 9px 14px;

  &:active {
    outline-color: #4cc9e1;
  }

  &:focus {
    outline-color: #4cc9e1;
  }
`;

const Select = styled(SelectBase)`
  border-radius: 8px;
  border: 1px solid ${LightGray};

  .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    justify-items: center;
    height: 38px !important;
    padding: 5px 14px !important;
  }

  .ant-select-selection-search-input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-select-selector {
    border: ${LightGray} solid 1px;
    outline: ${LightGray} solid 1px !important;

    &:focus {
      border: ${BounceRateMetric} solid 1px !important;
      outline: ${BounceRateMetric} solid 1px !important;
    }

    &:active {
      border: ${BounceRateMetric} solid 1px !important;
      outline: ${BounceRateMetric} solid 1px !important;
    }
  }
`;

const Tooltip = styled(TooltipBase)``;

const Sidebar = styled(LayoutBase.Sider)`
  flex-basis: 280px !important;
  width: 400px !important;
  max-width: 400px !important;
  padding: 30px 20px 30px 40px;
`;

const Field = styled(FormikField)`
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px !important;

  .ant-select-selection-search {
    box-shadow: none;
  }

  .ant-select-selection-search-input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    justify-items: center;
    height: 38px !important;
    padding: 5px 14px !important;
  }

  .ant-select-selector {
    border: ${LightGray} solid 1px;
    outline: ${LightGray} solid 1px !important;

    &:focus {
      border: ${BounceRateMetric} solid 1px !important;
      outline: ${BounceRateMetric} solid 1px !important;
    }

    &:active {
      border: ${BounceRateMetric} solid 1px !important;
      outline: ${BounceRateMetric} solid 1px !important;
    }
  }
`;

export default {
  Layout,
  DatePicker,
  Label,
  LabelWithIcon,
  Title,
  Tooltip,
  Wrapper,
  Sidebar,
  WrapperContent,
  TemplateView,
  Select,
  TextField,
  Field,
};
