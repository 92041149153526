import React, { useState } from 'react';
import { ImagePlaceholder, Modal } from 'modules/common/components';

import S from './styles';

const CoverImage = ({ url, title, loading, isAllowed }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const imageUrl = `${url}?random=33`;

  if (loading) return <ImagePlaceholder />;
  if (!url) return null;

  const isAlreadyFilteredList = isAllowed === undefined;
  const onCancel = () => {
    setIsModalOpen(false);
  };
  if (isAllowed || isAlreadyFilteredList) {
    return (
      <S.CoverImage>
        {imageUrl && <S.Image id="imageURL" src={imageUrl} alt={imageUrl} />}
      </S.CoverImage>
    );
  }
  return (
    <div>
      <S.UpgradeImage src={url} alt={title} />
      <Modal footer={null} isOpen={isModalOpen} onCancel={onCancel} title="Upgrade Plan">
        <S.ModalContent>
          <h1>Coming Soon!</h1>
        </S.ModalContent>
      </Modal>
    </div>
  );
};

CoverImage.defaultProps = {
  title: 'Book cover',
};

export default React.memo(CoverImage);
