import { Public } from 'modules/router/types';

import { ROUTE_GENERATE_PREVIEW_BOOK } from './constants';
import GenerateYourPreviewBook from '..';

export const routes = [
  {
    type: Public,
    exact: true,
    path: ROUTE_GENERATE_PREVIEW_BOOK,
    component: GenerateYourPreviewBook,
  },
];
