import { SET_SELECTED_LEADS, SET_UNSELECTED_LEADS } from '../actions/leads';

const initialState = {
  selectedLeads: [],
  unselectedLeads: [],
};

export default function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_LEADS:
      return {
        ...state,
        selectedLeads: action.payload,
      };
    case SET_UNSELECTED_LEADS:
      return {
        ...state,
        unselectedLeads: action.payload,
      };
    default:
      return state;
  }
}
