import styled from 'styled-components';

import { OnboardTemplate } from 'modules/onboard/templates';
import { AlertWithButton } from 'modules/common/components';
import env from 'environments';

const ErrorWrapper = styled.div`
  padding: 40px 0;
`;

const handleRedirect = () => {
  window.location.href = env.HELP_CENTER_URL;
};

const ErrorPage = () => (
  <OnboardTemplate currentStep={1} paddingContent="0" isErrored hideNavigation>
    <ErrorWrapper>
      <AlertWithButton
        type="info"
        buttonTitle="Help Page Center"
        handleClick={handleRedirect}
        description="Something went wrong. Please refresh this page and try again. If the issue persists, please contact our support team. Click on the button bellow."
      />
    </ErrorWrapper>
  </OnboardTemplate>
);

export default ErrorPage;
