import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { get, isEmpty } from 'lodash-es';
import { compose } from 'redux';

import { Box } from 'modules/v2/common/AtomicDesign/atoms';
import { SetPasswordForm } from 'modules/v2/components';
import { PasswordSetupIcon } from 'modules/v2/common/components/SvgIcon';
import { updateAfyPassword } from 'modules/api';
import { notification } from 'modules/v2/common/utils/notify';
import { withProfile } from 'modules/v2/containers';
import useTodos from 'modules/v2/context/JourneyContextReducer';
import { useTodoJourney } from 'modules/v2/context/JourneyContextProvider';
import { postJourneyTodos } from 'modules/api/common';
import { getRouteHomePage } from 'modules/v2/routes/navigation';
import { ROUTE_WELCOME_JOURNEY } from 'modules/v2/routes/constants';
import { setItem } from 'modules/dashboard/utils/legacy';
import { getData } from 'modules/auth';
import { updatePasswordFlag } from 'modules/api/auth';

import { generateClickUrl } from '../../utils/NavigationHandler';
import { PASSWORD_SETUP_TASKS_CHECK, TASK_STATUS } from '../../utils/constants';

function PasswordSetup({ profile, history }) {
  const [isSavingPassword, setIsSavingPassword] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);

  const { mutateAsync: updateAuthorifyPassword } = useMutation(updateAfyPassword);
  const { todos: fetchedTodos } = useTodoJourney();

  const { completed, initializeTodos, tasks, todos } = useTodos();

  useEffect(() => {
    initializeTodos(fetchedTodos);
  }, [fetchedTodos]);

  useEffect(() => {
    if (!isEmpty(tasks)) {
      const getCompletedTasks = todos.filter(
        (item) =>
          PASSWORD_SETUP_TASKS_CHECK.includes(item.name) && item.status === TASK_STATUS.COMPLETED,
      );
      if (getCompletedTasks.length === 2) {
        setIsLastStep(true);
      }
    }
  }, [tasks, todos]);

  const handleNavigateClick = useCallback(
    (stepName) => {
      const path = generateClickUrl(stepName);
      history.push(`${path}&completed=${tasks?.setupPassword?.name}`);
    },
    [history, tasks],
  );

  const handleNavigateHomeClick = useCallback(() => {
    const { userData } = getData();
    const updatedUser = {
      ...userData,
      welcomeJourneyEligible: false,
    };
    setItem('userData', updatedUser);
    const path = getRouteHomePage();
    history.push(`${path}?welcomeJourneyCompleted`);
  }, [history]);

  const handleSave = useCallback(
    (fields) => {
      setIsSavingPassword(true);
      const { newPassword: password, confirmPassword: passwordConfirmation } = fields;
      const { email } = get(profile, 'data', {});
      const payload = {
        email,
        password,
        passwordConfirmation,
      };
      const callback = updateAuthorifyPassword;
      const cbResult = callback(payload);

      if (cbResult) {
        cbResult
          .then(async () => {
            const response = await postJourneyTodos({
              taskId: tasks?.setupPassword?.taskId,
              stepId: tasks?.setupPassword?.stepId?.setupPassword,
            });

            if (response && response.status === 201) {
              completed({
                [tasks?.setupPassword?.name]: TASK_STATUS.COMPLETED,
              });
              setIsSavingPassword(false);
              if (isLastStep) {
                await updatePasswordFlag(email, false, false);
                handleNavigateHomeClick();
              } else {
                await updatePasswordFlag(email, true, false);
                handleNavigateClick('');
              }
            }
          })
          .catch(() => {
            notification.error({
              title: 'Saving failed',
              description: 'Changes could not be saved',
            });
          });
      }
    },
    [profile, updateAuthorifyPassword, handleNavigateClick, completed, tasks],
  );

  const handleCancel = () => {
    history.push(ROUTE_WELCOME_JOURNEY);
  };

  return (
    <div className="flex flex-col mt-8 gap-8 bg-neutral-50 h-full">
      <Box>
        <div className="flex flex-col justify-center items-start w-full">
          <div className="flex gap-3 text-base font-semibold px-6 py-[22px] text-boxTitle border-b border-neutral-200 w-full">
            <PasswordSetupIcon />
            Set up your password
          </div>
          <div className="p-6 w-full">
            <SetPasswordForm
              onSave={handleSave}
              handleCancel={handleCancel}
              disabledStatus={isSavingPassword}
              isSmallInput
            />
          </div>
        </div>
      </Box>
    </div>
  );
}

export default compose(withProfile)(PasswordSetup);
