import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { Menu } from 'antd';
import * as color from 'modules/v2/common/theme/color';
import { Icon } from '@ant-design/compatible';

const NavItem = styled(Menu.Item)`
  display: flex;
`;

const NavSubMenu = styled(Menu.SubMenu)`
  .ant-menu-submenu-title span {
    display: flex;
  }
`;

const NavIcon = styled(Icon)`
  display: flex;
  align-items: center;

  svg {
    font-size: 22px !important;
    margin: 0 !important;
  }
`;

const NavLink = styled(Link)`
  display: flex !important;
  width: 100%;
  align-items: center;
  list-style-type: none;
  text-align: left;
  letter-spacing: 0;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  :hover {
    color: ${color.SideBarActiveText} !important;
  }

  &.active {
    color: ${color.SideBarActiveText};
  }
`;

const LinkText = styled.span`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
`;

const IdcardOutlinedIcon = styled.span`
  display: flex;
  align-items: center;
  svg {
    font-size: 22px;
    margin: 0;
  }
`;

export { NavLink, LinkText, NavItem, NavIcon, NavSubMenu, IdcardOutlinedIcon };
