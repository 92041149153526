export const DeleteIconNew = ({ width = 16, height = 16, fill = '#E4483D' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path d="M13.6664 4H2.33301" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M12.5554 5.66675L12.2488 10.2662C12.1308 12.0361 12.0718 12.9211 11.4952 13.4606C10.9185 14.0001 10.0315 14.0001 8.25768 14.0001H7.7421C5.96824 14.0001 5.0813 14.0001 4.50463 13.4606C3.92796 12.9211 3.86896 12.0361 3.75096 10.2662L3.44434 5.66675"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6.33301 7.33325L6.66634 10.6666"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M9.66634 7.33325L9.33301 10.6666"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4.33301 4C4.37026 4 4.38889 4 4.40577 3.99957C4.95474 3.98566 5.43902 3.63661 5.62582 3.12021C5.63157 3.10433 5.63746 3.08666 5.64924 3.05132L5.71396 2.85714C5.76921 2.69139 5.79684 2.6085 5.83348 2.53813C5.97968 2.25738 6.25017 2.06242 6.56275 2.01251C6.64109 2 6.72846 2 6.90318 2H9.09617C9.27089 2 9.35826 2 9.4366 2.01251C9.74918 2.06242 10.0197 2.25738 10.1659 2.53813C10.2025 2.6085 10.2301 2.69138 10.2854 2.85714L10.3501 3.05132C10.3619 3.08661 10.3678 3.10434 10.3735 3.12021C10.5603 3.63661 11.0446 3.98566 11.5936 3.99957C11.6105 4 11.6291 4 11.6663 4"
      stroke={fill}
      strokeWidth="1.5"
    />
  </svg>
);
