import { message } from 'antd';

/**
 * This is handler to convert image to base64 for preview
 * @param {String} img -  Input image
 * @param {function } callback - callback function
 * @returns {Object} - blob image
 *
 */

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

/**
 *
 *
 * @param {Object} file - Input file
 * @returns {Promise} - Image dimensions
 */

export const getImageDimensions = (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      return resolve({ height: img.height, width: img.width });
    };
  });
};

export const getS3ImageURLDimensions = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      return resolve({ height: img.height, width: img.width });
    };
    img.onerror = () => {
      reject();
    };
  });
};

/**
 * This is a handler for image upload(dragger )
 * @property {Object} fileInfo - File object getting on upload imageu
 *
 */

export const beforeImageUpload = (fileInfo, validDimensions) => {
  const isJpgOrPng = fileInfo.type === 'image/jpeg' || fileInfo.type === 'image/png';
  // type validation
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG !');
    return isJpgOrPng;
  }
  // size 204  X 312 validations
  return getImageDimensions(fileInfo).then((imageDimensions) => {
    const isValidDimension =
      imageDimensions.height >= validDimensions.height &&
      imageDimensions.width >= validDimensions.width;
    if (!isValidDimension) {
      message.error(
        `You can only upload image  of minimum dimensions ${validDimensions.width} x ${validDimensions.height} px.`,
      );
      return Promise.reject(isValidDimension);
    }
    return isValidDimension;
  });
};

export const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};
