import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { Empty } from 'modules/common/components';

const withContainer = (Component = Empty) => React.memo((props) => <Component {...props} />);

const withStore = (mapStateToProps = () => ({}), mapDispatchToProps = {}) => {
  const actionCreators = (dispatch) => bindActionCreators(mapDispatchToProps, dispatch);

  return compose(connect(mapStateToProps, actionCreators), withContainer);
};

export default withStore;
