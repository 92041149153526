import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const ConfirmationWrapper = styled.div`
  max-width: 570px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid ${color.TitleBorder};
  border-radius: 3px;
  padding: 25px;

  h4 {
    color: ${color.GreySelectDd};
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    font-family: ${font.NunitoFont};
    margin: 0;
    margin: 5px;
  }
`;

const AddressWrapper = styled.div`
  max-width: 325px;
  margin: 0 auto;
  text-align: left;
  margin-top: 20px;

  h6 {
    font-size: 20px;
    font-weight: bold;
    line-height: 19px;
    color: ${color.GreySelectDd};
    font-family: ${font.NunitoFont};
    text-align: center;
    padding-bottom: 0;
  }
`;

const OrderNumber = styled.p`
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin: 0;
  color: ${color.GreySelectDd};
`;

const Address = styled.div`
  border: 1px solid ${color.TitleBorder};
  border-radius: 3px;
  background-color: ${color.DisabledBookSectionsBg};
  padding: 15px;
  margin-bottom: 15px;

  p {
    color: ${color.GreySelectDd};
    font-family: ${font.NunitoFont};
    font-size: 16px;
    line-height: 22px;
    padding: 0;
    margin: 0;
    font-weight: 600;

    strong {
      font-weight: 700;
      display: block;
    }
  }
`;

const ContactInfoWrapper = styled.div`
  p {
    color: ${color.GreySelectDd};
    font-family: ${font.NunitoFont};
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0.5px;
    margin: 0;
  }
`;

const Phone = styled.h4`
  margin-top: 15px !important;
`;

export default {
  ConfirmationWrapper,
  AddressWrapper,
  ContactInfoWrapper,
  Address,
  Phone,
  OrderNumber,
};
