import { Button } from '../../../../common/AtomicDesign/atoms';
import RelativeLoading from '../../../../common/AtomicDesign/RelativeLoading';

type Props = {
  id: string;
  price: number;
  credits: number;
  type: string;
  onBuy: () => void;
  perAmount?: number;
  totalAmount?: number;
  isActive?: boolean;
  theme: string;
  isLoading: boolean;
};

const CreditOfferCard: React.FC<Props> = ({
  id,
  price,
  credits,
  type,
  onBuy,
  theme,
  perAmount,
  totalAmount,
  isActive,
  isLoading,
}) => {
  const renderType = () => {
    switch (type) {
      case 'most-popular':
        return (
          <div className="flex flex-col bg-black font-sm text-white font-bold pt-1 pb-1  items-center justify-center rounded-t-lg ">
            ✨ Most popular ✨
          </div>
        );
      case 'best-deal':
        return (
          <div className="flex flex-col bg-success-500 font-sm text-white font-semibold pt-1 pb-1 items-center justify-center rounded-t-lg ">
            Best Deal
          </div>
        );
      default:
        return (
          <div className="flex flex-col font-sm text-white font-semibold pt-1 pb-1 h-[30px]" />
        );
    }
  };

  function renderBorderStyle() {
    return type === 'most-popular' || type === 'best-deal' ? 'rounded-t-none' : '';
  }

  function renderActiveStyle() {
    return isActive ? 'bg-primary-500' : '';
  }

  return (
    <>
      {renderType()}
      <div
        id={id}
        className={`md:p-2 sm:p-1 lg:p-3 transition ease-in-out duration-300 hover:bg-gray-100 hover:border-neutral-300 font-figtree flex flex-col items-center justify-center p-6 bg-white border-[1px] border-neutral-200 rounded-lg ${renderBorderStyle()} ${renderActiveStyle()}`}
      >
        <RelativeLoading isLoading={isLoading} />
        <div className="mt-4 text-lg font-semibold">{credits.toLocaleString()} Credits</div>
        <div className="mt-2 text-sm text-neutral-500">${perAmount} each</div>
        <Button onClick={() => onBuy()} type="primary" wrapperClassName="w-full" className="mt-4">
          Buy for ${totalAmount}
        </Button>
      </div>
    </>
  );
};

export default CreditOfferCard;
