//

import { Link } from 'react-router-dom';

import { getRouteHelpCenter } from 'modules/dashboard/routes/navigation';
import { AuthorifyIcon } from 'modules/common/components/SvgIcon';

import S from './styles';

const GenerateLinksView = () => (
  <S.Content>
    <S.IconWrapper>
      <AuthorifyIcon />
    </S.IconWrapper>
    <S.Title> Generating your digital book </S.Title>
    <S.ContentParagraph>
      Please wait while we generate your digital book. It may take up to 60 seconds. We encourage
      you to stay on this page to complete the process.
    </S.ContentParagraph>
    <br />
    <S.ContentParagraph>
      If this takes more than a minute, refresh this page and try again. If the app times out or
      does not generate your book, please let our team know by{' '}
      <Link to={getRouteHelpCenter()}>clicking here</Link>.
    </S.ContentParagraph>
  </S.Content>
);

export default GenerateLinksView;
