import { CheckOutlined } from '@ant-design/icons';
import { Tag, DatePicker } from 'antd';
import Table from 'modules/common/components/Table';

import { Select } from 'modules/common/components';
import { formatDateTime2 } from 'modules/common/utils';
import CardItemStyle from 'modules/dashboard/pages/PaymentSteps/components/CardItem/styles';
import cardDetails from 'modules/dashboard/pages/PaymentSteps/payments';

import { showTableLines } from './utils';
import S from '../../styles';

const { RangePicker } = DatePicker;

const BillingTable = ({
  billingHistory,
  meta,
  onTableChange,
  handlePerPageChange,
  onDateRangeChange,
  perPage,
  isLoading,
}) => {
  const cleanProduct = (product) => {
    const productName = product?.replace(/\(Non Contracted\)/, '');
    return productName?.replace(/\(Contracted\)/, '');
  };

  const columns = [
    {
      title: 'Invoice',
      dataIndex: 'product_name',
      key: 'product_name',
      render: (text) => {
        return <span>{cleanProduct(text)}</span>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (text) => {
        return <span>${text}</span>;
      },
    },
    {
      title: 'Billing Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => {
        return <div>{`${formatDateTime2(text)}`}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: () => {
        return (
          <Tag icon={<CheckOutlined />} color="#38EB71">
            Paid
          </Tag>
        );
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'payments',
      key: 'payments',
      render: (_, record) => {
        const payments = record?.payments;
        if (!payments?.length) {
          return null;
        }

        const maskedCardNumber = payments[0].payment_method?.masked_card_number;
        const cardBrand = payments[0].payment_method?.card_brand;
        return (
          <CardItemStyle.CreditWrapper>
            <S.CardImg src={cardDetails[cardBrand]} alt={maskedCardNumber} />
            <CardItemStyle.CardWrapper>
              <CardItemStyle.Description>
                <S.CardNumber>
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />{' '}
                  <S.CardText>
                    {maskedCardNumber.substring(
                      maskedCardNumber.length - 4,
                      maskedCardNumber.length,
                    )}
                  </S.CardText>
                </S.CardNumber>
              </CardItemStyle.Description>
            </CardItemStyle.CardWrapper>
          </CardItemStyle.CreditWrapper>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'invoice_download',
      key: 'invoice_download',
      render: (link) => {
        return (
          <a href={link} download target="_blank" rel="noreferrer">
            Download
          </a>
        );
      },
    },
  ];
  return (
    <>
      <S.FilterContainer data-testid="billing-table">
        <S.FilterItem>
          <S.FilterLabel>Show</S.FilterLabel>
          <Select
            value={perPage}
            showArrow
            onChange={handlePerPageChange}
            size="large"
            style={{ width: '100%', minWidth: '130px' }}
            options={showTableLines}
          />
        </S.FilterItem>
        <S.FilterItem>
          <RangePicker
            renderExtraFooter={() => 'Select Date Range'}
            onChange={onDateRangeChange}
            size="large"
          />
        </S.FilterItem>
      </S.FilterContainer>

      <Table
        dataSource={billingHistory}
        columns={columns}
        align="right"
        onChange={onTableChange}
        bordered={false}
        pagination={{
          total: meta?.total || 0,
          current: meta?.currentPage || 1,
          pageSize: perPage,
        }}
        loading={isLoading}
      />
    </>
  );
};

export default BillingTable;
