import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${color.Black};
`;

const ContactWrapper = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  padding: 0 !important;
`;

const Text = styled.p`
  font-size: 14px;
  margin-bottom: 30px;
`;

export default {
  Title,
  Wrapper,
  Text,
  ContactWrapper,
};
