import styled from 'styled-components';
import { Checkbox as BaseCheckbox } from 'antd';

import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  background-color: ${color.White};
  background-color: #ffffff;
  display: flex;

  // Prevent text selection on double click
  user-select: none;
`;

Wrapper.Inner = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-right: 1px solid #e6e6e6;
  height: 30px;

  &:last-child {
    border-right: none;
  }
`;

const Checkbox = styled(BaseCheckbox)`
  font-size: 14px;
  color: rgb(46, 63, 80);
  letter-spacing: 0;
`;

export default {
  Wrapper,
  Checkbox,
};
