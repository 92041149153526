import withStore from 'store/StoreContainer';

import {
  clearPublish,
  getPublish,
  orderSelector,
  submitOrder,
  submitReorder,
} from 'modules/v2/publish/store';

const mapDispatchToProps = {
  getPublish,
  clearPublish,
  submitOrder,
  submitReorder,
};

const mapStateToProps = (state) => ({
  order: orderSelector(state),
});

export default withStore(mapStateToProps, mapDispatchToProps);
