import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

const regexUSA = /(^[0-9]{5}(?:-[0-9]{4})?$)/;
const regexCanada = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]●?[0-9][A-Z][0-9]$/;

const zipUSA = {
  is: 'USA',
  then: Yup.string().matches(regexUSA, 'Postal Code for USA needs to be valid'),
};

const zipCanada = {
  is: 'Canada',
  then: Yup.string().matches(regexCanada, 'Postal Code to Canada needs to be valid'),
};

export const pincode = Yup.string()
  .required('Postal Code is required')
  .when('country', zipUSA)
  .when('country', zipCanada);

const validationSchema = Yup.object().shape({
  firstName: validation.firstName,
  lastName: validation.lastName,
  addressLine1: validation.address,
  country: validation.country,
  pincode: validation.pincode,
  city: validation.city,
  state: validation.state,
});

export default validationSchema;
