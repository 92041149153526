import { Radio } from 'antd';
import { map } from 'lodash-es';

import { renderIcons } from 'modules/v2/utils/cardIcons';
import S from './styles';

export const PaymentMethods = ({ checkbox, cardClick, paymentMethods, selectedMethod }) => {
  const renderPaymentMethods = () =>
    map(paymentMethods, ({ last4, expMonth, expYear, default: defaultBtn, brand, id }) => (
      <S.CardWrap key={id}>
        {checkbox && <Radio value={id} />}
        <S.CardImage $icon={brand}>{renderIcons(brand)}</S.CardImage>
        <S.CardNumber>
          <S.Dot />
          <S.Dot />
          <S.Dot />
          <S.Dot />
          {last4}
        </S.CardNumber>
        <S.DefaultWrap $transparent={!defaultBtn}>{defaultBtn && 'Default'}</S.DefaultWrap>
        <S.CardNumber>
          Expires: {expMonth}/{expYear}
        </S.CardNumber>
      </S.CardWrap>
    ));
  return checkbox ? (
    <Radio.Group onChange={cardClick} value={selectedMethod}>
      {renderPaymentMethods()}
    </Radio.Group>
  ) : (
    renderPaymentMethods()
  );
};

export default PaymentMethods;
