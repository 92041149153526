//
import { useState } from 'react';
import { get, includes, pick } from 'lodash-es';

import { isPending } from 'store/status';
import { Formik } from 'formik';

import { getImageTypeByElementId } from 'modules/editor/utils';
import { Modal } from 'modules/common/components';
import { ContactDetailsModal, FilePickerModal, RichTextModal, PlainTextModal } from './components';
import validationSchema from './validationSchema';
import { getModalType, setLogoUrls } from './utils';

import {
  CONTACT_DETAILS_MODAL,
  ELEMENT_SIMPLE_TOOLBAR,
  FILE_PICKER_MODAL,
  PLAIN_TEXT_MODAL,
  RICH_TEXT_MODAL,
} from './constants';

import S from './styles';

const CoverEditorModal = ({
  // Data
  element,
  images,
  logos,
  preferences,
  scale,

  // Callbacks
  onContactDetailsChange,
  onDeleteImage,
  onFilePickerConfirm,
  onGalleryTabOpen,
  onLogosTabOpen,
  onModalCancel,
  onRichTextConfirm,
}) => {
  const [noticeImageDimension, setNoticeImageDimension] = useState(true);

  if (!element) {
    return null;
  }

  const modalType = getModalType(element.id, element.type);
  const fields = pick(preferences, ['email', 'phone']);

  switch (modalType) {
    case CONTACT_DETAILS_MODAL: {
      return (
        <Formik
          initialValues={fields}
          validationSchema={validationSchema}
          validateOnChange={false}
          isInitialInvalid
          onSubmit={onContactDetailsChange}
        >
          <ContactDetailsModal
            isOpen
            fields={fields}
            title={element.name}
            onSubmit={onContactDetailsChange}
            onCancel={onModalCancel}
          />
        </Formik>
      );
    }

    case FILE_PICKER_MODAL: {
      const imagesStatus = get(images, 'status');
      const logosStatus = get(logos, 'status');

      const isImagesLoading = isPending(imagesStatus);
      const isLogosLoading = isPending(logosStatus);

      const imageType = getImageTypeByElementId(element.id);
      const imagesData = get(images, 'data', null);
      const logosData = get(logos, 'data', null);
      const logosWithUrls = setLogoUrls(logosData, imageType);

      const handleGalleryTabOpen = () => onGalleryTabOpen(imageType);

      return (
        <>
          <FilePickerModal
            isOpen
            isImagesLoading={isImagesLoading}
            isLogosLoading={isLogosLoading}
            title={element.name}
            type={imageType}
            images={imagesData}
            logos={logosWithUrls}
            onConfirm={onFilePickerConfirm}
            onCancel={onModalCancel}
            onDelete={onDeleteImage}
            onGalleryTabOpen={handleGalleryTabOpen}
            onLogosTabOpen={onLogosTabOpen}
          />
          {element.name === 'Author Image' && (
            <Modal
              isOpen={noticeImageDimension}
              onConfirm={() => setNoticeImageDimension(false)}
              onCancel={() => setNoticeImageDimension(false)}
              title={element.name}
              width="500px"
            >
              <S.NoticeContainer>
                <span>
                  Please use an image with at least <b>500x500</b> pixels or above
                </span>
              </S.NoticeContainer>
            </Modal>
          )}
        </>
      );
    }

    case PLAIN_TEXT_MODAL: {
      return (
        <PlainTextModal
          isOpen
          title={element.name}
          element={element}
          scale={scale}
          onConfirm={onRichTextConfirm}
          onCancel={onModalCancel}
        />
      );
    }

    case RICH_TEXT_MODAL: {
      const isSimple = includes(ELEMENT_SIMPLE_TOOLBAR, element.id);

      return (
        <RichTextModal
          isOpen
          isSimple={isSimple}
          title={element.name}
          element={element}
          scale={scale}
          onConfirm={onRichTextConfirm}
          onCancel={onModalCancel}
        />
      );
    }

    default: {
      return null;
    }
  }
};

export default CoverEditorModal;
