import { call, put, takeEvery } from 'redux-saga/effects';

import { resource, deleteUserImage, getError } from 'modules/api';

import { getUserImages } from 'modules/editor/store';
import { DELETE_USER_IMAGE } from 'modules/editor/store/constants';

export function* onDeleteUserImage({ payload: meta }) {
  try {
    const request = resource(DELETE_USER_IMAGE, deleteUserImage, (data) => data);
    const payload = yield call(request, meta);
    
    yield put(getUserImages(meta.imageType));

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchDeleteUserImage() {
  yield takeEvery(DELETE_USER_IMAGE, onDeleteUserImage);
}
