import React, { useState } from 'react';
import { DashboardTemplate } from 'modules/v2/templates';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getShowCreditsButton } from 'modules/api';
import { BuyCreditsProvider } from './context';
import BuyCreditsOfferPage from './components/BuyCreditsOfferPage';
import BuyCreditsCheckoutPage from './components/BuyCreditsCheckoutPage';

export type PageMapping = {
  id: string;
  title: string;
};

const BuyCreditsJourney = () => {
  const [activePage, setActivePage] = useState<PageMapping>({
    id: 'buy-credits',
    title: 'Buy credits',
  });

  const history = useHistory();

  useQuery('getShowCreditsButton', getShowCreditsButton, {
    onSuccess: (data: boolean) => {
      if (!data) history.push('/');
    },
  });


  const showActivePage = (id: string) => {
    switch (id) {
      case 'buy-credits':
        return <BuyCreditsOfferPage setActivePage={setActivePage} />;
      case 'checkout':
        return <BuyCreditsCheckoutPage setActivePage={setActivePage} />;
      default:
        return <BuyCreditsOfferPage setActivePage={setActivePage} />;
    }
  };

  return (
    <DashboardTemplate hasSidebar title={activePage.title}>
      <BuyCreditsProvider>{showActivePage(activePage.id)}</BuyCreditsProvider>
    </DashboardTemplate>
  );
};

export default BuyCreditsJourney;
