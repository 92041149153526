import { Children } from 'react';
import { Menu } from 'antd';
import { get } from 'lodash-es';

import { ChevronIcon } from 'modules/common/components/SvgIcon';

import { Grid } from './components';

import S from './styles';

const Dropdown = ({ children, title }) => {
  const Overlay = (
    <Menu className="userDropdown editordropdown">
      {Children.map(children, (child) => {
        const key = get(child, ['props', 'title']);
        return <Menu.Item key={key}>{child}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <S.Dropdown
      menu={Overlay}
      trigger={['click']}
      overlayClassName="draftDropdown"
      dropdownRender={() => Overlay}
    >
      <S.Button>
        <S.Button.Label>{title}</S.Button.Label>
        <S.Button.Icon>
          <ChevronIcon />
        </S.Button.Icon>
      </S.Button>
    </S.Dropdown>
  );
};

Dropdown.Grid = Grid;

export default Dropdown;
