interface Content {
  id: number;
  content: any;
}

class ObjectSet {
  private map: Map<number, Content>;

  constructor() {
    this.map = new Map<number, Content>();
  }

  add(obj: Content): boolean {
    if (this.map.has(obj.id)) {
      return false;
    }
    this.map.set(obj.id, obj);
    return true;
  }

  has(obj: Content): boolean {
    return this.map.has(obj.id);
  }

  delete(obj: Content): boolean {
    return this.map.delete(obj.id);
  }

  get size(): number {
    return this.map.size;
  }

  toArray(): IterableIterator<Content> {
    return this.map.values();
  }
}

export default ObjectSet;
