import PaymentMethodCard from '../components/PaymentMethodCard';
import CreditItemPay from '../components/CreditItemPay';
import S from './styles';
import cardDetails from '../payments';

export const ReviewPay = ({ getStep, creditValue, paymentValue, paymentData, getpaymentValue }) => {
  if ((paymentData.length === 1 && paymentData[0].expired) || !paymentData.length) {
    getStep(4);
    return null;
  }

  const { id, credits, totalAmount, perAmount } = creditValue;

  let payData = paymentData[0];

  if (paymentValue?.id !== undefined) {
    payData = paymentValue;
  } else {
    getpaymentValue(paymentData[0]);
  }

  const { id: idPayment, card_type: card, masked_card_number: cardNumber } = payData;

  return (
    <S.Container>
      <S.ModalDescription>
        Please make sure information below is correct before placing an order.
      </S.ModalDescription>
      <CreditItemPay
        key={id}
        credits={credits}
        totalAmount={totalAmount}
        perAmount={perAmount}
        getStep={getStep}
      />
      <S.Line />
      <S.ContainerTitle>Payment Method</S.ContainerTitle>
      {card && (
        <PaymentMethodCard
          key={idPayment}
          card={card}
          cardNumber={cardNumber}
          getStep={getStep}
          image={cardDetails[card]}
        />
      )}
    </S.Container>
  );
};
