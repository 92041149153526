import withStore from 'store/StoreContainer';

import {
  // selectors
  linksSelector,
  preflightStateSelector,
  previewStateSelector,

  // actions
  clearDigitalBook,
  cancelGenerateDigitalBook,
  generateDigitalBook,
  generateDraftPreview,
  getDraftLinks,
  getDraftPreview,
  goToMyOrders,
} from 'modules/draft/store';
import { goToEditDraftCover, goToEditDraftContent } from 'modules/editor/store';

const mapStateToProps = (state) => ({
  links: linksSelector(state),
  preview: previewStateSelector(state),
  preflight: preflightStateSelector(state),
});

const mapDispatchToProps = {
  clearDigitalBook,
  cancelGenerateDigitalBook,
  generateDraftPreview,
  generateDigitalBook,
  getDraftPreview,
  getDraftLinks,
  goToEditDraftCover,
  goToEditDraftContent,
  goToMyOrders,
};

export default withStore(mapStateToProps, mapDispatchToProps);
