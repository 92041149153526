import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Button } from 'antd';

const Status = styled.span`
  text-transform: capitalize;
`;
const DashboardSubTitle = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 13px;
`;
const FirstCol = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SecondCol = styled.div`
  margin-right: 22px;
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LeadName = styled.div`
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
`;
const LeadValue = styled.div`
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  margin-left: 5px;
  color: ${color.Black};
  opacity: 1;
`;
const FilterBy = styled.div`
  font-size: 16px;
  padding-right: 12px;
  text-align: right;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
`;

const Title = styled.h3`
  font-size: 24px;
  padding-top: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 46px;
  color: ${color.White};
  font-family: 'Roboto', sans-serif;
`;

const ContentTitle = styled(Title)`
  color: ${color.Black};
  margin-top: 5px;
`;

const TemplateButton = styled(Button)`
  font-weight: normal !important;
  color: ${color.SkyBlue};
  text-align: right;
  svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
    font-size: 14px;
  }
`;
const TemplateWrapper = styled.div`
  max-width: 710px;
  margin: 20px auto;
  border-radius: 2px;
  border: 1px solid ${color.GreyBorder};
  padding: 20px;

  img {
    margin: 10px auto;
    max-width: 100%;
  }
`;
export default {
  Status,
  DashboardSubTitle,
  Title,
  ContentTitle,
  LeadName,
  LeadValue,
  MainContain,
  FilterBy,
  FirstCol,
  SecondCol,
  TemplateButton,
  TemplateWrapper,
};
