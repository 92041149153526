import { Redirect } from 'react-router';
import NotFound from 'modules/dashboard/pages/NoRoutesFound';
import { Empty } from 'modules/common/components';

const withGuard =
  (Component = Empty, guard = () => null) =>
    (props) => {
      const redirect = guard(props);

      if (redirect === 'notFound') {
        return <NotFound {...props} />;
      }

      if (redirect === null) {
        return <Component {...props} />;
      }

      return <Redirect to={redirect} />;
    };

export default withGuard;
