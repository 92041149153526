import classnames from 'classnames';
import { LightningBoltIcon } from 'modules/v2/common/components/SvgIcon';

type BookLabelProps = {
  className?: string;
  label: string;
};

const BookLabel = ({ label, className, ...props }: BookLabelProps) => {
  const isUpgrade = label === 'Upgrade';
  return (
    <span
      className={classnames(
        'flex font-medium absolute text-xs bg-white rounded-xl top-4 right-4 px-2 py-1 border border-neutral-200',
        className,
      )}
      {...props}
    >
      {isUpgrade && (
        <div className="w-[16px] h-[16px] mr-2">
          <LightningBoltIcon height="100%" width="100%" />
        </div>
      )}
      {label}
    </span>
  );
};

BookLabel.defaultProps = {
  className: '',
};

export default BookLabel;
