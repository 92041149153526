import { get } from 'lodash-es';

import { getPayload } from 'modules/api';

export const filterDraftPayload = (response) => {
  const data = getPayload(response);
  const draft = get(data, ['results'], null);

  return {
    draft,
  };
};
