import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { get, noop } from 'lodash-es';
import { useHistory } from 'react-router';
import { Modal } from 'modules/v2/common/components';
import { withDraft } from 'modules/editor/containers';
import { withPublish } from 'modules/publish/containers';
import { getRouteDentistGuidesOrdered } from 'modules/v2/routes/dentist/navigation';
import { isPending, isLoading } from 'store/status';

import { generateGuideOrder } from 'modules/api';
import { useMutation } from 'react-query';
import { views, MODAL_WIDTH } from './constants';
import { ViewRenderer } from './components';

const OrderGuideModal = ({
  // Input
  draftId,
  isOpen,
  title,
  guidePrefillFormData = {},
  refetchGuides,
  fromScreen,
  guidePacketName,
  // State
  addresses,
  countries,
  states,
  setPayloadData,
  payloadData,

  // Actions
  addUserAddresses,
  deleteUserAddresses,
  editUserAddresses,
  goToEditDraftContent,
  goToEditDraftCover,

  // Callbacks
  // onCancel,
  onClose,
  onComplete = noop,
}) => {
  const history = useHistory();
  const [activeView, setActiveView] = useState(views.GUIDEDETAILS);
  const [order, setOrder] = useState('');
  const isSubmitting = isLoading(order.status);

  useEffect(() => {
    const loading = isPending(addresses.status);

    if (!loading && activeView === views.ADDRESS_LIST) {
      const length = get(addresses, ['data', 'length'], 0);

      if (length === 0) {
        setActiveView(views.ADDRESS_FORM);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses.data, activeView]);

  const handleCloseModal = () => {
    if (activeView === views.CONFIRMATION) {
      if (fromScreen === 'OrderedGuides') {
        refetchGuides();
      } else {
        history.push(getRouteDentistGuidesOrdered());
      }
    }
    onClose();
  };

  const { mutate: orderMutate, isLoading: isOrderLoading } = useMutation(generateGuideOrder, {
    onSuccess: (data) => {
      setOrder(data);
      setActiveView(views.CONFIRMATION);
    },
  });

  const handleEditCover = () => goToEditDraftCover(draftId);
  const handleEditSection = (sectionId) => goToEditDraftContent(draftId, sectionId);
  const handleSubmitOrder = (payload) => {
    orderMutate(payload);
  };

  return (
    <Modal
      footer={null}
      isOpen={isOpen}
      title={title}
      width={MODAL_WIDTH}
      hasCloseButton={!isSubmitting}
      maskClosable={false}
      onCancel={onClose}
    >
      <ViewRenderer
        activeView={activeView}
        addresses={addresses}
        countries={countries}
        order={order}
        states={states}
        guidePrefillFormData={guidePrefillFormData}
        onDone={handleCloseModal}
        onCreateAddress={addUserAddresses}
        onDeleteAddress={deleteUserAddresses}
        onEditAddress={editUserAddresses}
        onEditCover={handleEditCover}
        onEditSection={handleEditSection}
        onSubmitOrder={handleSubmitOrder}
        setActiveView={setActiveView}
        setPayloadData={setPayloadData}
        payloadData={payloadData}
        isOrderLoading={isOrderLoading}
        refetchGuides={refetchGuides}
        guidePacketName={guidePacketName}
      />
    </Modal>
  );
};

const MemoPublish = React.memo(OrderGuideModal);
const Composed = compose(withDraft, withPublish)(MemoPublish);

export default Composed;
