import { createSelector } from 'reselect';

import { dashboardSelector } from '../dashboard';

export const intakeFormSelector = createSelector(
  dashboardSelector,
  (dashboard) => dashboard.intakeForm,
);

export const headshotSelector = createSelector(
  intakeFormSelector,
  (intakeForm) => intakeForm.upload,
);
