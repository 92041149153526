import { AlertLargeIcon } from 'modules/common/components/SvgIcon';
import { NoRoutes } from './style';

export default function NoRoutesFound() {
  return (
    <NoRoutes>
      <div className="contentWrap">
        <div className="card">
          <div className="icon">
            <AlertLargeIcon />
          </div>
          <h1>Page not found</h1>
        </div>
      </div>
    </NoRoutes>
  );
}
