import { useMutation } from 'react-query';
import { sendPayment } from 'modules/api';

import { getParsedPayload } from 'modules/landingPages/utils';
import { IInitialValues } from 'modules/formBuilder/types';
import { AxiosResponse } from 'axios';
import { getItem } from 'modules/dashboard/utils/legacy';

interface IOptions {
  onSuccess: (data: AxiosResponse<IInitialValues>) => void;
  onError: (error: string, actions: any) => void;
  productHandler: string;
  componentId?: string;
  productPricePointHandle?: string;
  offerId: string;
}

const useFinishPayment = (options: IOptions) => {
  const { productHandler, componentId, offerId, productPricePointHandle, onError, onSuccess } =
    options;

  const { mutate: mutateSendPayment, isLoading } = useMutation(sendPayment, { onError, onSuccess });

  const handleFormFinish = (values: IInitialValues) => {
    const newValues = { ...values, productHandler, componentId };
    const payload = getParsedPayload(newValues);

    mutateSendPayment(payload);
  };

  const handleProfilePaymentFinish = () => {
    const storedValue = getItem('paymentProfile');
    const paymentValue = JSON.parse(storedValue);
    const lastFour = paymentValue?.masked_card_number?.slice(-4);

    const paymentData = {
      customer: {
        id: paymentValue.customer_id,
        first_name: paymentValue.first_name,
        last_name: paymentValue.last_name,
        email: paymentValue.email,
      },
      credit_card: {
        first_name: paymentValue.first_name,
        last_name: paymentValue.last_name,
        card_type: paymentValue.card_type,
        expiration_month: paymentValue.expiration_month?.toString(),
        expiration_year: paymentValue.expiration_year?.toString(),
        last_four: lastFour,
        vault_token: paymentValue.vault_token,
        current_vault: paymentValue.current_vault,
        ...(paymentValue.customer_vault_token && {
          customer_vault_token: paymentValue.customer_vault_token,
        }),
      },
      product: {
        component_id: componentId,
        product_handle: 'authorify-monthly-non-contracted',
        ...(productPricePointHandle && { product_price_point_handle: productPricePointHandle }),
      },
      meta_fields: { offerId },
    };

    mutateSendPayment(paymentData);
  };

  return { handleFormFinish, handleProfilePaymentFinish, isLoading };
};

export default useFinishPayment;
