import React, { forwardRef } from 'react';
import classnames from 'classnames';

import CoverImage from 'modules/v2/editor/components/CoverImage';

import S from './styles';

const TemplateItem = forwardRef(({ name, cover, selected, onSelect }, ref) => (
  <S.ListItem ref={ref}>
    <S.ListItem.Anchor className={classnames({ selected })} onClick={onSelect}>
      <CoverImage url={cover} selected={selected} />
    </S.ListItem.Anchor>
    {/* <S.ListItem.Paragraph>{name}</S.ListItem.Paragraph> */}
  </S.ListItem>
));

export default React.memo(TemplateItem);
