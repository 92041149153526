import { call, put, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { editOrderedDraft, resource } from 'modules/api';
import { getRouteEditDraft } from 'modules/editor/routes/navigation';
import { goToRoot } from 'modules/editor/store';

import { EDIT_ORDERED_DRAFT } from 'modules/editor/store/constants';

export function* onEditOrderedDraft({ payload }) {
  const { orderId } = payload;

  try {
    const request = resource(EDIT_ORDERED_DRAFT, editOrderedDraft);
    const { draftId } = yield call(request, { orderId });

    if (draftId) {
      const location = getRouteEditDraft(draftId);
      // Use replace to avoid returning to the same URL with back button
      yield put(replace(location));
    }
  } catch (err) {
    yield put(goToRoot());
  }
}

export function* watchEditOrderedDraft() {
  yield takeEvery(EDIT_ORDERED_DRAFT, onEditOrderedDraft);
}
