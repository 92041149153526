import { Formik, Form } from 'formik';
import { z } from 'zod';
import { cn } from 'modules/v2/common/utils/cn';
import { Button, LabelInput } from 'modules/v2/common/AtomicDesign/atoms';
import { PasswordInput } from 'modules/v2/common/AtomicDesign/molecules';
import { CloseCircleIcon, CheckIconNew } from 'modules/v2/common/components/SvgIcon';
import { useState } from 'react';
import validationSchema from './validationSchema';

const SetPasswordForm = ({ onSave, handleCancel, disabledStatus, isSmallInput = false }) => {
  const [inputTouched, setInputTouched] = useState(false);
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const passwordSchema = z
    .string()
    .min(8, { message: '8 characters minimum' })
    .regex(/[a-z]+/, { message: 'One lowercase letter' })
    .regex(/[A-Z]+/, { message: 'One uppercase letter' })
    .regex(/[\d\W]/, { message: 'Contains a number or symbol' });

  const ValidationMessage = ({ valid, message, touched, key }) => {
    const getIcon = () => {
      if (!touched) {
        return <CloseCircleIcon fill="#ffffff" width={10} height={10} />;
      }
      return valid ? (
        <CheckIconNew width={10} height={10} />
      ) : (
        <CloseCircleIcon fill="#ffffff" width={10} height={10} />
      );
    };

    return (
      <div key={key} className="w-[210px] text-sm font-semibold flex items-center gap-2">
        <span
          className={cn(
            'rounded-full w-3 h-3 flex justify-center items-center',
            { 'bg-neutral-500': !touched },
            { 'bg-success-500': valid },
            { 'bg-error-500': !valid && touched },
          )}
        >
          {getIcon()}
        </span>
        <span
          className={cn(
            { 'text-neutral-500': !touched },
            { 'text-success-500': valid },
            { 'text-error-500': !valid && touched },
          )}
        >
          {message}
        </span>
      </div>
    );
  };

  const validatePassword = (password) => {
    const result = passwordSchema.safeParse(password);
    const validationMessages = [];

    // eslint-disable-next-line no-underscore-dangle
    passwordSchema._def.checks.forEach((check) => {
      validationMessages.push({
        valid:
          result.success || !result.error.errors.some((error) => error.message === check.message),
        message: check.message,
      });
    });

    const touched = validationMessages.some(({ valid }) => valid);
    setInputTouched(touched);
    return validationMessages.map(({ valid, message }) => (
      <ValidationMessage key={message} valid={valid} message={message} touched={inputTouched} />
    ));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSave}>
      {({ values, dirty, errors, handleSubmit, handleChange, isValid }) => {
        const isDisabled = !isValid || !dirty;
        return (
          <Form onSubmit={handleSubmit}>
            <div className="flex flex-1 w-[514px] max-[700px]:w-full">
              <div className="flex flex-col flex-1 mb-6">
                <LabelInput>Password</LabelInput>
                <PasswordInput
                  id="newPassword"
                  name="newPassword"
                  isSmallInput={isSmallInput}
                  value={values.newPassword}
                  onChange={handleChange}
                />
                <div className="flex justify-between flex-wrap gap-2 mt-2">
                  {validatePassword(values.newPassword)}
                </div>
              </div>
            </div>

            <div className="flex flex-1 w-[514px] max-[700px]:w-full">
              <div className="flex flex-col flex-1 mb-6">
                <LabelInput>Re-enter password</LabelInput>
                <PasswordInput
                  id="confirmPassword"
                  name="confirmPassword"
                  isSmallInput={isSmallInput}
                  value={values.confirmPassword}
                  onChange={handleChange}
                />
                {values.confirmPassword && errors.confirmPassword && (
                  <span className="text-error-500 mt-2 inline-flex items-center gap-2 font-semibold">
                    <span className="rounded-full w-3 h-3 flex justify-center items-center bg-error-500">
                      <CloseCircleIcon fill="#ffffff" width={10} height={10} />
                    </span>
                    {errors.confirmPassword}
                  </span>
                )}
                {inputTouched && values.confirmPassword && !errors.confirmPassword && (
                  <span className="text-success-500 mt-2 inline-flex items-center gap-2 font-semibold">
                    <span className="rounded-full w-3 h-3 flex justify-center items-center bg-success-500">
                      <CheckIconNew width={10} height={10} />
                    </span>
                    Passwords match
                  </span>
                )}
              </div>
            </div>

            <div className="flex flex-1 gap-2">
              <Button type="outlined" buttonType="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                buttonType="submit"
                onClick={handleSubmit}
                disabled={isDisabled || disabledStatus}
              >
                Save password
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SetPasswordForm;
