import { useState } from 'react';
import { Radio } from 'antd';
import { notification } from 'modules/v2/common/utils';

import CreditItem from '../components/CreditItem';

import S from './styles';

export const OrderCredits = ({
  getSetCreditValue,
  creditValue,
  creditsData,
  paymentData,
  type = 'book',
}) => {
  if (!paymentData.length) {
    notification.error({
      description: 'There is no payment profile in this account',
    });
  }

  let val = creditsData[0]?.id;
  if (creditValue?.id !== undefined) {
    val = creditValue?.id;
  } else {
    getSetCreditValue(creditsData[0]);
  }

  const [value, setValue] = useState(val);

  const handleChange = (e) => {
    const newValue = e.target.value;
    const selectedCredit = creditsData.find((item) => item.id === newValue);
    if (selectedCredit) {
      getSetCreditValue(selectedCredit);
    }
    setValue(newValue);
  };

  return (
    <S.Container>
      <S.ModalDescription>
        <b>1 credit is 1 printed {type}.</b>
        The more credits you order, the more you save.
      </S.ModalDescription>
      <form>
        <Radio.Group onChange={handleChange} value={value}>
          {creditsData?.map((item) => (
            <CreditItem key={item.id} creditData={item} />
          ))}
        </Radio.Group>
      </form>
    </S.Container>
  );
};
