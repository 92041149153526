import { get } from 'lodash-es';

import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';
import { GET_BOOKS } from 'modules/v2/store/constants';
import { books as initialState } from 'modules/v2/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_BOOKS}_REQUESTED`: {
      return {
        ...state,
        data: null,
        status: STATUS_LOADING,
      };
    }

    case `${GET_BOOKS}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${GET_BOOKS}_SUCCEEDED`: {
      const data = get(action, ['payload', 'books'], null);
      const order = get(action, ['payload', 'bookSortOrderArray'], null);

      return {
        ...state,
        data,
        order,
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
};

export default reducer;
