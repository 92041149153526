import { useState } from 'react';

import { Modal } from 'modules/common/components';
import EditBookModal from 'modules/v2/common/AtomicDesign/organisms/EditBookModal';

import ModalContent from './ModalContent';

const RichTextModal = ({
  title,
  element,
  isOpen,
  scale,
  isSimple,
  isExtended,
  onConfirm,
  onCancel,
}) => {
  const [state, setState] = useState(element);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(state);
    }
  };

  return (
    <EditBookModal isOpen={isOpen} onConfirm={handleConfirm} onCancel={onCancel}>
      <ModalContent
        element={element}
        scale={scale}
        isSimple={isSimple}
        isExtended={isExtended}
        onChange={setState}
      />
    </EditBookModal>
  );
};

export default RichTextModal;
