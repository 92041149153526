import { useEffect, useState } from 'react';
import { find, get, isEmpty, map } from 'lodash-es';

import { isPending, isReady, isSucceeded } from 'store/status';

import { ContentPlaceholder, Loader } from 'modules/common/components';
import { addressToString, orderQuantity } from 'modules/common/utils';
import { PublishModal } from 'modules/publish';
import { DashboardTemplate } from 'modules/dashboard/templates';
import { MyBooksSideNav, OrderList } from 'modules/dashboard/components';
import { withOrders } from 'modules/dashboard/containers';
import { getThumbnail } from 'modules/dashboard/utils';

export const MyOrders = ({
  orders,
  reorder,
  clearOrders,
  clearPublish,
  getOrders,
  getPublish,
  submitReorder,
  history,
}) => {
  const [reorderDraftId, setReorderDraftId] = useState(null);

  const currentOrder = find(orders.data, (item) => item.draftId === reorderDraftId);
  const reorderDraftTitle = get(currentOrder, 'bookTitle');
  const parentOrderId = get(currentOrder, 'orderId');

  useEffect(
    () => () => {
      clearOrders();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmitReorder = ({ draftId, isDigital, numberOfPrints, selectedAddressId }) => {
    submitReorder({
      draftId,
      isDigital,
      numberOfPrints,
      selectedAddressId,
      parentOrderId,
    });
  };

  const handleCancelReorder = () => {
    setReorderDraftId(null);
    clearPublish();
  };

  const handleCloseReorder = () => {
    if (isSucceeded(reorder.status)) {
      getOrders();
    }
    setReorderDraftId(null);
    clearPublish();
  };

  const noOrders = <ContentPlaceholder>No Orders Available</ContentPlaceholder>;
  const loader = <Loader height="calc(100vh - 250px)" title="Loading Orders" />;

  let content = isPending(orders.status) ? loader : noOrders;

  if (isReady(orders.status) && !isEmpty(orders.data)) {
    content = (
      <OrderList>
        {map(
          orders.data,
          ({
            _id: id,
            bookName,
            bookTitle,
            createdAt,
            draftId,
            isDigital,
            orderId,
            quantity,
            shippingAddress,
            updatedAt,
            allowPersonalization,
            allowReorder,
            ...props
          }) => (
            <OrderList.Item
              key={id}
              draftId={draftId}
              address={addressToString(shippingAddress)}
              bookName={bookName}
              bookTitle={bookTitle}
              coverImage={getThumbnail(props)}
              createdAt={createdAt}
              history={history}
              orderId={orderId}
              quantity={orderQuantity(quantity, isDigital)}
              updatedAt={updatedAt}
              allowPersonalization={allowPersonalization}
              allowReorder={allowReorder}
              onReorder={setReorderDraftId}
            />
          ),
        )}
      </OrderList>
    );
  }

  return (
    <DashboardTemplate sidebar={<MyBooksSideNav />} hasSidebar contentTitle="Ordered books">
      {reorderDraftId && (
        <PublishModal
          isOpen
          draftId={reorderDraftId}
          title={reorderDraftTitle}
          onCancel={handleCancelReorder}
          onClose={handleCloseReorder}
          onOpen={getPublish}
          onSubmit={handleSubmitReorder}
        />
      )}

      {content}
    </DashboardTemplate>
  );
};

export default withOrders(MyOrders);
