import styled from 'styled-components';

const Highlighted = styled.span`
  background-color: rgb(255, 255, 0);
  padding: 2px 0;
`;

export default {
  Highlighted,
};
