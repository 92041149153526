import { useEffect, useState } from 'react';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import { setItem } from 'modules/dashboard/utils/legacy';
import { getRouteAutoLogin } from 'modules/dashboard/routes/navigation';
import { CongratulationsOffer } from 'modules/onboard/components';
import { setSession } from 'modules/onboard/utils';
import { useFetchSession, usePoolingSession } from '../../hooks';

const OrderConfirmation = ({ history }) => {
  const [orderNumber, setOrderNumber] = useState(null);
  const { session } = useFetchSession();

  const handlePoolingSuccess = ({ data }) => {
    const { order } = data;

    if (order) {
      setOrderNumber(order);
      stopPooling();
      setSession(data);
    }
  };

  const { startPooling, stopPooling } = usePoolingSession({ onSuccess: handlePoolingSuccess });

  useEffect(() => {
    setItem('orderConfirmation', true);
    startPooling();
  }, []);

  const isDigitalCustomer = useCustomerPlanInfo();

  const handleClick = () => {
    const {
      autoLoginToken,
      customer: { email },
    } = session;

    const extraParams = isDigitalCustomer.isDigital ? 'redirect=/' : 'redirect=/my-books/my-orders';

    const redirectUrl = autoLoginToken
      ? getRouteAutoLogin(email, autoLoginToken, extraParams)
      : '/';

    return history.push(redirectUrl);
  };

  return (
    <CongratulationsOffer
      callback={handleClick}
      label="View my ordered books"
      disabled={!orderNumber}
      loading={!orderNumber}
      order={orderNumber}
    />
  );
};

export default OrderConfirmation;
