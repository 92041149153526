import styled from 'styled-components';
import { Input as BaseInput, Button as BaseButton } from 'antd';
import { Form as BaseForm } from 'formik';
import BaseInputMask from 'react-input-mask';

import * as color from 'modules/common/theme/color';

const Form = styled(BaseForm)`
  display: block;
`;

const inputStyle = `
  height: 45px;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  height: 45px;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #2e3f50;

  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const Input = styled(BaseInput)`
  ${inputStyle}
`;

const InputMask = styled(BaseInputMask)`
  ${inputStyle}
  padding: 4px 10px;
`;

const Button = styled(BaseButton)`
  margin: 10px 15px;
  height: 40px;
  width: 100%;
  font-size: 16px;

  &,
  &:hover,
  &:focus {
    color: ${color.White};
    background-color: ${color.Black};
    border-color: ${color.Black};
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 0 15px;
  .ant-select-selection__rendered {
    line-height: 41px;
  }
  .ant-select-selection--single {
    height: 45px;
  }
`;

const InfoText = styled.span`
  font-weight: 400;
  color: ${color.Body};
  padding: 15px;
`;

const Wrapper = styled.div`
  max-height: 60vh;
  padding: 24px;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 95vw;
    max-height: 100vh;
    padding: 16px;
  }
`;

const FormContainer = styled.div`
  flex: 1;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CreditCardInput = styled.div`
  width: 100%;
  flex: 1;
  iframe {
    width: 100% !important;
  }

  &:nth-child(2n) {
    margin-left: 10px;
  }
`;

export default {
  CreditCardInput,
  Button,
  FieldGroup,
  FieldControl,
  FlexContainer,
  Form,
  FormContainer,
  InfoText,
  Input,
  InputMask,
  Wrapper,
};
