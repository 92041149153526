import { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import isEmpty from 'lodash/isEmpty';
import {
  ListingDetailsComponent,
  QRCodeComponent,
  RenderOptionsComponent,
  SingleInputComponent,
} from 'modules/v2/pages/ReferralMarketingMagazines/components';
import { backCoverStripOptionsFormFields, backCoverStripOptionsHeading } from '../constants';

const BackCover = ({ backCoverList, updateMagazineData, handleImageLightBox }) => {
  const [showListingImageModal, setShowListingImageModal] = useState(false);
  const {
    magazine = {},
    handleUpdateMagazine,
    magazineDataFormik,
    previousMagazineData,
    setFormikContext,
  } = updateMagazineData || {};

  const selectedCoverStripOption = get(magazine, 'backCover');

  const lastSelectedCoverStripOption = useRef(selectedCoverStripOption?.formKeyword);

  useEffect(() => {
    const currentSelectedOption = get(selectedCoverStripOption, 'formKeyword');
    const lastSelectedOption = get(lastSelectedCoverStripOption, 'current');

    if (lastSelectedOption !== currentSelectedOption) {
      lastSelectedCoverStripOption.current = currentSelectedOption;
      const initialValueOfSelctedOptionForm =
        backCoverStripOptionsFormFields[currentSelectedOption];
      magazineDataFormik.setFieldValue('backCover._formFields', initialValueOfSelctedOptionForm);
    }
  }, [selectedCoverStripOption, magazineDataFormik]);

  const toggleShowListingImageModal = () => {
    setShowListingImageModal(!showListingImageModal);
  };

  const onCancelListingImageModal = () => {
    setShowListingImageModal(false);
  };

  const handleCoverStripOptionChange = (option) => {
    handleUpdateMagazine(option, 'backCover');
  };

  const onHandleResetFormFields = () => {
    if (!isEmpty(previousMagazineData)) {
      const insideCoverContent = previousMagazineData.selections.find(
        (selection) => selection.page === 4,
      );
      const { formKeyword } = insideCoverContent;
      const regex = /option-(\d)/;
      const match = formKeyword.match(regex);
      if (match.length > 0) {
        const optionSelected = match[0].replace('-', ' ');
        const option = backCoverList.find(
          (opt) => opt.name.toLowerCase() === optionSelected.toLowerCase(),
        );
        handleUpdateMagazine(option, 'backCover');
      }
    } else {
      const initialValueOfSelctedOptionForm = backCoverList.find(
        (item) => item.name === 'Option 1',
      );
      handleUpdateMagazine(initialValueOfSelctedOptionForm, 'backCover');
    }
    setFormikContext('current', -1);
  };

  const allowedResolutionsTextBackCover3 = { width: 600, height: 960 };

  const allowedResolutionsTextBackCover4 = { width: 960, height: 750 };

  const renderAdditionalData = (type) => {
    switch (type) {
      case 'backCover-option-3':
        return (
          <SingleInputComponent
            imageDimensions={allowedResolutionsTextBackCover3}
            step="backCover"
            title="Generated Book Link"
            formikHandler={magazineDataFormik}
            uploadImageTitle="Your book image"
            propertyKey="bookImage"
            uploadImageType="backCoverImageOption3"
            presentImageLocation={get(magazineDataFormik, 'values.backCover._formFields.bookImage')}
          />
        );
      case 'backCover-option-2':
        return <QRCodeComponent formikHandler={magazineDataFormik} step="backCover" />;
      case 'backCover-option-4':
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextBackCover4}
            step="backCover"
            hasInputFields
            formikHandler={magazineDataFormik}
            isOpen={showListingImageModal}
            onCancel={onCancelListingImageModal}
            handleOpenModal={toggleShowListingImageModal}
            uploadImageType="backCoverImageOption4"
            propertyKey="listingImageJustSold"
            presentImageLocation={get(
              magazineDataFormik,
              'values.backCover._formFields.listingImageJustSold',
            )}
          />
        );
      case 'backCover-option-5':
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextBackCover4}
            step="backCover"
            hasInputFields
            hasTextArea
            hasListingName
            formikHandler={magazineDataFormik}
            isOpen={showListingImageModal}
            propertyKey="listingImageJustListed"
            onCancel={onCancelListingImageModal}
            presentImageLocation={get(
              magazineDataFormik,
              'values.backCover._formFields.listingImageJustListed',
            )}
            handleOpenModal={toggleShowListingImageModal}
            uploadImageType="backCoverImageOption5"
            hasName
          />
        );
      default:
        return null;
    }
  };

  const selectedCoverStripOptionName = get(selectedCoverStripOption, 'name');
  const selectedOptionTitle =
    backCoverStripOptionsHeading[get(selectedCoverStripOption, 'formKeyword')];

  return (
    <>
      <div className="mt-8 flex flex-col gap-6">
        <div className="w-full flex flex-col">
          <h5 className="text-neutral-800 text-sm font-semibold">Select back cover</h5>
          <RenderOptionsComponent
            onImageClick={handleImageLightBox}
            optionsList={backCoverList}
            handleChange={handleCoverStripOptionChange}
            selectedOption={selectedCoverStripOption}
            setMaxWidth="182px"
            setMaxHeight="276px"
          />
        </div>
        <div className="text-neutral-800 text-base font-semibold">
          {selectedOptionTitle && `${selectedCoverStripOptionName}: ${selectedOptionTitle}`}
          {selectedCoverStripOptionName?.toLowerCase() === 'option 2' && (
            <div className="overflow-hidden text-neutral-400 text-ellipsis  text-sm font-medium">
              A special QR code will generate once the order has been placed.
            </div>
          )}
        </div>
        <div className="w-full">{renderAdditionalData(selectedCoverStripOption?.formKeyword)}</div>
        <div className="flex justify-start w-full gap-2">
          <Button color="light" onClick={onHandleResetFormFields}>
            Cancel
          </Button>
          <Button color="dark" buttonType="submit" onClick={magazineDataFormik.handleSubmit}>
            Save changes
          </Button>
        </div>
      </div>
    </>
  );
};

export default BackCover;
