//

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { CaretDownOutlined } from '@ant-design/icons';
import { map, noop } from 'lodash-es';
import parseUnit from 'parse-unit';

import { options } from 'modules/editor/components/ContentEditor/utils';
import { styles } from 'modules/common/utils';

import S from './styles';

const FontSize = ({ defaultFontSize, editor, onChange = noop }) => {
  const { icon, values } = options.fontSize;

  const handleFontSize = (fontSize) => {
    const lineHeight = Math.floor(Number(fontSize) * 1.25);

    let state = editor;
    state = styles.fontSize.remove(state);
    state = styles.lineHeight.remove(state);

    if (defaultFontSize !== `${fontSize}pt`) {
      state = styles.fontSize.add(state, `${fontSize}pt`);
    }

    state = styles.lineHeight.add(state, `${lineHeight}pt`);

    onChange(state);
  };

  const current = styles.fontSize.current(editor);
  let selected = current || defaultFontSize;

  if (typeof selected === 'string') {
    [selected] = parseUnit(selected);
  }

  return (
    <S.Select
      placeholder={<LegacyIcon type={icon} />}
      suffixIcon={<CaretDownOutlined />}
      onSelect={handleFontSize}
      value={selected}
    >
      {map(values, (value) => (
        <S.Option key={value} value={value}>
          {value}
        </S.Option>
      ))}
    </S.Select>
  );
};

export default FontSize;
