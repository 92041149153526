import {
  AmericanExpressIcon,
  DiscoverIcon,
  MasterCardIcon,
  VisaIcon,
} from 'modules/v2/common/components';

export const renderIcons = (name) => {
  switch (name) {
    case 'visa': {
      return <VisaIcon />;
    }
    case 'mastercard': {
      return <MasterCardIcon />;
    }
    case 'amex': {
      return <AmericanExpressIcon />;
    }
    case 'discover': {
      return <DiscoverIcon />;
    }
    default:
      return <MasterCardIcon />;
  }
};
