import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const MetricValue = styled.div`
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;
  border: 17px solid ${(props) => (props.color ? props.color : color.PrimaryColor)};
  border-radius: 50%;

  span {
    font-size: 21px;
    font-weight: bold;
    font-family: ${font.RobotoFont};
    line-height: 24px;
  }
`;

const ButtonContainer = styled.div`
  float: left;
  padding: 10px;
  font-weight: bold;
`;

const Container = styled.div`
  margin: 20px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  display: flex;
  font-weight: bold;
`;

const MetricContainer = styled.div`
  font-weight: bold;
  float: left;
  padding: 20px;
`;

const Title = styled.h5`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 0px;
`;

const TopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 20px 0px;
`;

export default {
  ButtonContainer,
  Title,
  TopWrap,
  MetricValue,
  MetricContainer,
  Container,
  Wrapper,
};
