const HelpVideo = ({ videoCode }) => (
  <div
    className="wistia_responsive_padding"
    style={{
      padding: '56.25% 0 0 0',
      position: 'relative',
      marginBottom: '20px',
    }}
  >
    <div
      className="wistia_responsive_wrapper"
      style={{
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    >
      <span
        className={`wistia_embed ${videoCode} popover=true popoverAnimateThumbnail=true videoFoam=true`}
        style={{
          display: 'inline-block',
          height: '100%',
          position: 'relative',
          width: '100%',
        }}
      >
        &nbsp;
      </span>
    </div>
  </div>
);

export default HelpVideo;
