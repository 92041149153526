import AuthenticationTemplate from 'modules/v2/components/AuthenticationTemplate';
import { Button, TextInput, LabelInput } from 'modules/v2/common/AtomicDesign/atoms';
import { WarnMessage } from 'modules/v2/common/AtomicDesign/molecules';
import { Link } from 'react-router-dom';
import LoginErrors from 'modules/v2/components/Login/LoginErrors';
import { CheckIconNew } from 'modules/v2/common/components/SvgIcon';
import { Alert } from 'flowbite-react';
import AlertWarning from 'modules/v2/common/components/SvgIcon/AlertWarning';

function ForgotPassword({ statusCode, message, handleChange, handleSubmit, auth, email }) {
  const isValidEmail = message !== 'invalid';
  return (
    <AuthenticationTemplate pageTitle="Forgot password">
      <div className="w-full h-full flex flex-col items-center justify-center border-t border-neutral-200">
        <div className="w-[400px] h-full flex flex-col items-center max-[450px]:w-full max-[450px]:px-5">
          <h2 className="mt-[70px] font-extrabold text-2xl">Forgot password</h2>
          <p className="text-center text-neutral-500 text-sm mt-2">
            Enter the email address associated with your account, and we'll email you a link to
            reset your password.
          </p>

          {statusCode === 0 && (
            <WarnMessage className="mt-[30px]" type="success">
              <div className="inline-flex gap-x-2">
                <CheckIconNew width="20" height="20" fill="#069668" /> A link to reset your password
                has been sent to {email}.
              </div>
            </WarnMessage>
          )}
          {message && statusCode === 1 && (
            <LoginErrors statusCode={statusCode} statusMessage={message} />
          )}

          {auth.expiredLink === 1 && (
            <Alert className="w-full bg-error-50 text-error-500 font-semibold text-sm justify-center mt-[30px]">
              <div className="flex gap-2">
                <AlertWarning fill="#E4483D" stroke="#E4483D" />
                Your request to reset password already expired. Please try again.
              </div>
            </Alert>
          )}

          <div className="w-full">
            <div className="w-full my-[30px]">
              <LabelInput>Email</LabelInput>
              <TextInput
                type="email"
                name="username"
                className="w-full"
                errorMessage="Please enter a valid email!"
                onChange={handleChange}
                isValid={isValidEmail}
                onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
              />
            </div>
            <Button
              type="primary"
              buttonType="submit"
              full
              disabled={auth.loading && !auth.loaded}
              onClick={handleSubmit}
              size="lg"
              className="w-full"
            >
              Send reset link
            </Button>
            <div className="text-sm text-neutral-500 text-center mt-6">
              <span> Remember your password? </span>
              <Link
                className="text-neutral-800 font-medium underline cursor-pointer"
                to="/auth/login"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </AuthenticationTemplate>
  );
}

export default ForgotPassword;
