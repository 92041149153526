import { useState, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import env from 'environments';
import { createUploads } from 'modules/api';
import Loader from 'modules/common/components/Loader';
import S from './styles';

const ImageCropper = ({ image, type, onSuccess, handleCancel, onChange }) => {
  let editor = '';
  const [picture, setPicture] = useState({
    cropperOpen: true,
    img: image,
    zoom: 1,
    croppedImg: '',
    previewImg: '',
  });

  const [value, setValue] = useState({
    value: 0,
  });

  const [allowedDimension, setallowedDimension] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    switch (type) {
      case 'rm_profile':
        return setallowedDimension({ width: 520, height: 520 });
      case 'book_profile':
        return setallowedDimension({ width: 520, height: 520 });
      case 'brokerageLogo':
        return setallowedDimension({ width: 520, height: 192 });
      case 'backInsideCoverImageOption3and4':
        return setallowedDimension({ width: 1440, height: 960 });
      case 'backInsideCoverImageOption6':
        return setallowedDimension({ width: 960, height: 672 });
      case 'backCoverImageOption4and5':
        return setallowedDimension({ width: 960, height: 750 });
      case 'backCoverImageOption3':
        return setallowedDimension({ width: 600, height: 960 });
      default:
        return setallowedDimension({ width: 500, height: 500 });
    }
  }, [type]);

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = () => {
    setIsLoading(true);
    setDescription('Please wait, uploading image');
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const ImageURL = canvasScaled.toDataURL();
      onChange(ImageURL);

      fetch(ImageURL)
        .then((res) => {
          return res.blob();
        })
        .then(async (images) => {
          if (type !== 'book_profile') {
            try {
              const path = '';
              const req = {
                payload: {
                  bucket: env.DS_BUCKET,
                  isPrivate: false,
                  ext: 'png',
                  contentType: 'image/png',
                  path,
                },
                callbackTargetKey: 'uploadUrl',
                callbackPayload: images,
              };
              const response = await createUploads(req);
              onSuccess?.(response);
            } catch (err) {
              throw new Error(err);
            }
          }
        });

      setIsLoading(false);
    }
  };

  const handleDrop = async (files) => {
    const url = URL.createObjectURL(files[0]);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    });
  };

  const handleScale = (e) => {
    setValue({ value: e.target.value });
    const scale = parseFloat(e.target.value);
    setPicture({
      ...picture,
      zoom: scale > 1 ? scale : 1,
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader height="75vh" hasIcon={false} description={description} />
      ) : (
        <div>
          <S.ResetModalHeightStyle />
          <S.DragDropWrapper noMargin className="w-fit border-none">
            {allowedDimension && (
              <Dropzone
                noClick
                onDrop={handleDrop}
                noKeyboard
                style={{ width: '250px', height: '250px', backgroundColor: '#24374f' }}
              >           
                {({ getRootProps }) => (
                  <div {...getRootProps()}>
                    <AvatarEditor
                      ref={setEditorRef}
                      image={picture.img}
                      width={allowedDimension.width}
                      height={allowedDimension.height}
                      border={30}
                      color={[255, 255, 255, 0.8]}
                      rotate={0}
                      scale={picture.zoom}
                    />
                  </div>
                )}
              </Dropzone>
            )}
          </S.DragDropWrapper>
          <div>
            <S.InputRange
              name="scale"
              type="range"
              onChange={handleScale}
              min="1"
              max="4"
              step="0.01"
              defaultValue="1"
              style={{ '--value': `${((value.value - 1) / 3) * 100}%` }}
            />
            <S.BtnWrapper>
              <S.Btn type="button" onClick={handleCancel}>
                Cancel
              </S.Btn>
              <S.PrimaryBtn type="button" onClick={handleSave} disabled={isLoading}>
                Save changes
              </S.PrimaryBtn>
            </S.BtnWrapper>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCropper;
