import styled from 'styled-components';

import { Input as BaseInput, Select as BaseSelect, Switch as BaseSwitch } from 'antd';
import BaseInputMask from 'react-input-mask';

import * as color from 'modules/v2/common/theme/color';

const inputStyle = `
  border: 1px solid ${color.neutral200} !important;
  border-radius: 6px !important;
  background-color: ${color.White};
  width: 100%;
  min-height: 40px;
  display: flex;
  font-size: 16px;
  line-height: 22px;
  color: ${color.gray100};

  .ant-input::placeholder {
    color: ${color.grayDescription} !important;
  }

  svg {
    color: ${color.grayDescription};
  }

  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px ${color.FormBoxShadowColor} ;
  }
`;

const InputMask = styled(BaseInputMask)`
  ${inputStyle}
  padding: 4px 10px;

  ${(props) =>
    props.$hasError &&
    `
      border: 1px solid ${color.ErrorWarning};
    `}

  ${({ disabled }) =>
    disabled
      ? `
      color: ${color.InputMaskDisabled};
      background-color: ${color.InputMaskBackGroundDisabled};
    `
      : ''}
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  margin-bottom: 10px;
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;

  justify-content: ${({ justifyContent }) => justifyContent || null};

  ${({ gap }) => (gap ? 'grid-column-gap: 20px;' : '')}
`;

const ErrorMessage = styled.span`
  color: ${color.ErrorWarning};
  display: inline-block;
  padding-top: 5px;
`;

const Input = styled(BaseInput)`
  ${inputStyle}

  ${(props) =>
    props.$hasError
      ? `
    border: 1px solid ${color.ErrorWarning};
    background-color: ${color.ErrorBg} !important;
    :focus, :hover {
      border-color: ${color.ErrorWarning};
      box-shadow: none;
    }
    :focus-visible {
      outline: unset;
      border: 1px solid ${color.ErrorWarning};
      box-shadow: none;
    }
    `
      : `
    :focus {
      border-color: ${color.GreyFormBorder};
      box-shadow: none;
    }
    :focus-visible {
      outline: unset;
      border: 1px solid ${color.GreyFormBorder};
      box-shadow: none;}
    `}
`;

const Select = styled(BaseSelect)`
  .anticon svg {
    color: ${color.gray100};
    margin-left: 10px;
  }

  .ant-picker-input {
    color: ${color.gray100} !important;
  }

  .ant-select-selector {
    ${inputStyle}
    display: flex;
  }

  .ant-picker-input,
  .ant-picker-input-active {
    ${inputStyle}
  }

  .ant-select-selection-item {
    color: ${color.gray100} !important;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`;

const SelectFull = styled(BaseSelect)`
  font-size: 14px;
  display: block;
  border: 1px solid ${color.neutral200};

  .ant-select-selector {
    height: 45px !important;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
`;

const Switch = styled(BaseSwitch)`
  margin: 0 8px;
  min-width: ${(props) => (props.issmall ? '32px' : '44px')};
  height: ${(props) => (props.issmall ? '16px' : '25px')};
  line-height: ${(props) => (props.issmall ? '16px' : '25px')};

  &:after {
    top: ${(props) => (props.issmall ? '0' : '2px')};
    left: 2px;
    width: ${(props) => (props.issmall ? '14px' : '18px')};
    height: ${(props) => (props.issmall ? '14px' : '18px')};
  }

  &.ant-switch-checked:after {
    left: 100%;
  }

  .ant-switch-inner {
    font-size: ${(props) => (props.issmall ? '10px' : '16px')};
  }
`;

const InputPassword = styled(BaseInput.Password)`
  height: 45px !important;

  ${(props) =>
    props.$hasError
      ? `
    border: 1px solid ${color.ErrorWarning};
    background-color: ${color.ErrorBg} !important;
    :focus, :hover {
      border-color: ${color.ErrorWarning};
      box-shadow: none;
    }
    :focus-visible {
      outline: unset;
      border: 1px solid ${color.ErrorWarning};
      box-shadow: none;
    }
    `
      : `
    :focus {
      // border-color: ${color.GreyFormBorder};
      box-shadow: none;
    }
    :focus-visible {
      outline: unset;
      border: 1px solid ${color.GreyFormBorder};
      box-shadow: none;}
    `}
`;

export {
  Input,
  ErrorMessage,
  FieldGroup,
  FieldControl,
  InputMask,
  Select,
  Switch,
  SelectFull,
  InputPassword,
};
