import styled, { keyframes } from 'styled-components';
import { CheckCircleFilled } from '@ant-design/icons';
import * as color from '../../../common/theme/color';
import { PrimaryButton } from '../../../common/components';

const MainSection = styled.div`
  text-align: center;
  padding: 20px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const pulsateAnimation = keyframes`
  0% {
    border-color: rgba(92, 163, 64, 0.3);
  }
  50% {
    border-color: rgba(92, 163, 64, 0.6);
  }
  100% {
    border-color: rgba(92, 163, 64, 0.3);
  }
`;

const CheckIcon = styled(CheckCircleFilled)`
  font-size: 46px !important;
  color: ${color.SuccessSaving};
  border: 16px solid rgba(92, 163, 64, 0.3);
  border-radius: 50%;
  animation: ${pulsateAnimation} 1s infinite;
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const OkButton = styled(PrimaryButton)`
  width: 250px !important;
  height: 60px;
`;

const OrderNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 12px;
  border: 1px solid ${color.LightGray};
  border-radius: 4px;
`;

const Description = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

export default {
  MainSection,
  CheckIcon,
  Section,
  OkButton,
  OrderNumberContainer,
  Description,
};
