import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import env from 'environments';
import { getUser, logDD } from 'modules/api/afyLogger/afyLogger';

export class DatadogManager {
  constructor() {
    this.logConfiguration = {
      clientToken: env.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sampleRate: 100,
    };

    this.rumConfiguration = {
      applicationId: env.DATADOG_APPLICATION_ID,
      clientToken: env.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'afy-ui',
      version: env.APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['clickmap'],
      allowedTracingUrls: [
        (url) => url.startsWith('https://scheduler.authorify.com'),
        (url) => url.startsWith('https://bba.prd.authorify.com'),
        (url) => url.startsWith('https://ds.authorify.com'),
        (url) => url.startsWith('https://logger.authorify.com/'),
      ],
      beforeSend: this.beforeSendRoutine.bind(this),
    };
  }

  init() {
    datadogLogs.init(this.logConfiguration);
    datadogRum.init({ ...this.rumConfiguration, env: env.DATADOG_ENV });

    this.initializeUser();
    datadogRum.startSessionReplayRecording();
  }

  beforeSendRoutine(event, context) {
    try {
      if (event.type === 'view' && event?.view?.action?.count > 0) {
        const { url } = event.view;
        const urlParts = url
          .replace('http://', '')
          .replace('https://', '')
          .split('/');
        return logDD('view', url, urlParts).then(() => true);
      }
    } catch (err) {
      console.error('Error in beforeSendRoutine');
    }
    return true;
  }

  initializeUser() {
    try {
      const user = getUser();
      if (user?.name && user?.email && user?.email !== 'anon@authorify.com') {
        datadogRum.setUser({
          id: user.id,
          name: user.name,
          email: user.email,
          isAdmin: user.isAdmin,
          hasAccessToDMPOneDotFive: user.hasAccessToDMPOneDotFive,
        });
      }
    } catch (err) {
      console.error(`Error in initializeUser: ${err}`);
    }
  }
}

const datadogManager = new DatadogManager();
datadogManager.init();
