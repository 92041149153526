export const DangerTriangleSolidIcon = ({ width = 34, height = 34, fill = '#E75A50' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconer">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5246 15.2455C11.6589 7.91515 13.7261 4.25 16.9997 4.25C20.2732 4.25 22.3404 7.91515 26.4747 15.2454L26.9899 16.1589C30.4255 22.2503 32.1434 25.296 30.5908 27.523C29.0383 29.75 25.1971 29.75 17.5149 29.75H16.4845C8.80222 29.75 4.96108 29.75 3.40854 27.523C1.85599 25.296 3.5738 22.2503 7.00941 16.1589L7.5246 15.2455ZM16.9997 10.2708C17.5865 10.2708 18.0622 10.7465 18.0622 11.3333V18.4167C18.0622 19.0035 17.5865 19.4792 16.9997 19.4792C16.4129 19.4792 15.9372 19.0035 15.9372 18.4167V11.3333C15.9372 10.7465 16.4129 10.2708 16.9997 10.2708ZM16.9997 24.0833C17.7821 24.0833 18.4163 23.4491 18.4163 22.6667C18.4163 21.8843 17.7821 21.25 16.9997 21.25C16.2173 21.25 15.583 21.8843 15.583 22.6667C15.583 23.4491 16.2173 24.0833 16.9997 24.0833Z"
        fill={fill}
      />
    </g>
  </svg>
);
