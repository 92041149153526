import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Input as BaseInput } from 'antd';
import { Field } from 'formik';

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  width: 100%;
  flex: 1;
  border-radius: 3px;
  font-size: 18px;
  line-height: 22px;
  color: ${color.UserMenuText};
  .ant-select-selection {
    height: 43px !important;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  background: ${color.White};
  padding: 2rem;
  font-size: 16px;
`;

const HeaderText = styled.p`
  font-size: 16px;
  margin: 0px 0 10px 0 !important;
  color: ${color.DeleteLeadModalText};
  font-weight: bold;
`;

const ContentWrapper = styled.div`
  margin-top: 10px;

  p {
    margin: 10px 0 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  padding: 0 20px;
  button {
    font-size: 12px !important;
  }
`;

interface InputProps {
  $isValid: boolean;
}

const Input = styled(BaseInput)<InputProps>`
  outline: ${({ $isValid }) => ($isValid ? '1px solid red' : 'none')};
`;

const CheckBox = styled.span`
  .ant-checkbox-wrapper {
    margin-top: 8px;
  }
  .ant-checkbox + span {
    color: black;
    font-size: 16px;
  }
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 16px;
  line-height: 25px;
  color: ${color.Black};
`;

const FormWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;
`;

const LeadWrapper = styled.div`
  display: flex;
  align-items: top;
  height: 60px;
  margin-bottom: 10px;

  button {
    height: 40px !important;
    margin: 0 !important;
    margin-right: 10px !important;
    font-size: 11px !important;
  }
`;

export default {
  ButtonsWrapper,
  CheckBox,
  ContentWrapper,
  FormWrapper,
  FormikField,
  HeaderText,
  Input,
  Label,
  LeadWrapper,
  Wrapper,
};
