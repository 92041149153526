import { get } from 'lodash-es';

import { TOGGLE_SHOW_GUIDES } from 'modules/v2/editor/store/constants';
import { toolbar as initialState } from 'modules/v2/editor/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SHOW_GUIDES: {
      const showGuides = get(action, ['payload', 'showGuides'], false);

      return {
        ...state,
        showGuides,
      };
    }

    default:
      return state;
  }
};

export default reducer;
