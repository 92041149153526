import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Button } from 'antd';

const CustomButton = styled(Button)`
  width: 100%;
  height: 48px !important;
  font-size: 18px !important;
  line-height: 24px !important;
  font-family: 'Roboto', sans-serif;
  color: ${color.White};
  background-color: ${color.PrimaryColor};
  border: none;
  border-radius: 5px !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  margin: 20px auto;
  cursor: pointer;
  outline: none;
  &:hover,
  &:active,
  &:focus {
    background-color: ${color.PrimaryColorHover};
    color: ${color.White};
  }
  :disabled {
    background-color: ${color.PrimaryDisabledColor};
    color: ${color.White};
    &:hover,
    &:active,
    &:focus {
      background-color: ${color.PrimaryDisabledColor};
      color: ${color.White};
    }
  }
`;

export { CustomButton };
