import { createSelector } from 'reselect';
import { get } from 'lodash-es';

import { dashboardSelector } from 'modules/v2/store/selectors/dashboard';

export const profileStateSelector = createSelector(dashboardSelector, (state) =>
  get(state, 'profile'),
);

export const profileDataSelector = createSelector(profileStateSelector, (profile) => profile.data);

export const avatarStateSelector = createSelector(
  profileStateSelector,
  (profile) => profile.avatar,
);
