import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 50px;
  font-size: 16px;

  ul {
    padding-left: 20px;
  }

  li {
    margin-left: 20px;
  }
`;

const Date = styled.strong`
  margin-bottom: 12px;
  display: block;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 20px !important;
`;

const ListDescription = styled.p`
  margin-bottom: 20px !important;
`;

const List = styled.ul`
  padding-left: 30px !important;
`;

export default {
  Wrapper,
  Title,
  Date,
  ListDescription,
  List,
};
