type SvgIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

function LightningBoltIcon({
  width = '16',
  height = '16',
  fill = '#FFB703',
  ...props
}: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width,
        height,
      }}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M4.2102 6.69648L6.12404 4.10708C7.36114 2.4333 7.97969 1.59641 8.55679 1.77326C9.13389 1.95011 9.13389 2.97656 9.13389 5.02945V5.22301C9.13389 5.96345 9.13389 6.33366 9.37048 6.56588L9.383 6.57791C9.62469 6.80523 10.01 6.80523 10.7806 6.80523C12.1674 6.80523 12.8608 6.80523 13.0952 7.22582C13.0991 7.23278 13.1028 7.2398 13.1065 7.24687C13.3277 7.67397 12.9262 8.21717 12.1233 9.30355L10.2094 11.8929C8.97231 13.5667 8.35375 14.4036 7.77665 14.2267C7.19955 14.0499 7.19956 13.0234 7.19959 10.9705L7.19959 10.777C7.19961 10.0366 7.19961 9.66636 6.96302 9.43414L6.95051 9.42211C6.70881 9.19479 6.32349 9.19479 5.55284 9.19479C4.16605 9.19479 3.47265 9.19479 3.23831 8.77421C3.23443 8.76724 3.23066 8.76022 3.22699 8.75315C3.00577 8.32605 3.40725 7.78286 4.2102 6.69648Z"
        fill={fill}
      />
    </svg>
  );
}

export default LightningBoltIcon;
