//

import { STATUS_INIT } from 'store/status';

export const autoSignin = {
  data: null,
  status: STATUS_INIT,
};

export default {
  autoSignin,
};
