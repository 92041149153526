export const taskCount = 3;

export const welcomeJourneyIntroText = `
Follow the steps below to customize your book. You can publish your book and order your printed copies in a few steps. Afterward, you can gain access to more tools and training.
`;

export const welcomeJourneyTitle = (customerName) => `Welcome to Authorify, ${customerName}`;

export const componentRoutes = {
  passwordSetup: 'password-setup',
  yourShipingAddress: 'your-shipping-address',
  bookSetup: 'book-setup',
  scheduleCoachingCall: 'schedule-coaching-call',
};

export const videoSource = 'https://fast.wistia.com/embed/medias/wk1ntrvmq1.m3u8';
export const editBookVideoSource = 'https://fast.wistia.com/embed/medias/d008cvhx7p.m3u8';
export const thumbnailSource =
  'https://embed-ssl.wistia.com/deliveries/ef4981bf9060f8d397aae774e3e4df22.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=0&amp;image_play_button_color=54bbffe0';

export const PASSWORD_SETUP_TASKS_CHECK = ['setupShippingAddress', 'setupBookJourney'];
export const SHIPPING_SETUP_TASKS_CHECK = ['setupPassword', 'setupBookJourney'];
export const SETUPBOOK_TASKS_CHECK = ['setupPassword', 'setupShippingAddress'];
export const SCHEDULE_CALL_TASKS_CHECK = [
  'setupShippingAddress',
  'setupPassword',
  'setupShippingAddress',
];

export const BOOK_SETUP_TASKS_CHECK = 'setupPassword';

export const TASK_STATUS = {
  COMPLETED: 'completed',
  TODOSTATUS: 'todo',
};

export const COACHING_CALL_STATUS = {
  MISSED: 'Missed call',
  TODAY: 'Today',
};

export const TASK_NAME = {
  SETUPPASSWORD: 'setupPassword',
  SETUPBOOK: 'setupBookJourney',
  SETUPADDRESS: 'setupShippingAddress',
  SCHEDULECALL: 'scheduleCoachingCall',
};

export const BOOK_SETUP_STEPS = {
  ORDERBOOKSTEP: 'orderTheBook',
  PUBLISHBOOKSTEP: 'publishTheBook',
  EDITBOOKSTEP: 'editTheBook',
};

export const BOOK_NAVIGATION = {
  VIEW: 'view',
  CUSTOMIZE: 'customize',
};
