import { useState } from 'react';
import { PiEye } from 'react-icons/pi';

import { getFlippingBookCanonicalLinkByBookId } from 'modules/api';
import { Link } from 'modules/v2/draft/components';
import { Modal } from 'modules/v2/common/components';
import FlippingBookEmbed from 'modules/v2/common/components/FlippingBookEmbed';
import { Box, Button, Spinner } from 'modules/v2/common/AtomicDesign/atoms';
import { Tooltip } from 'modules/v2/common/AtomicDesign/molecules';
import { QuestionCircleIcon, WindowFrameIcon } from 'modules/v2/common/components/SvgIcon';
import { normalizeUrl } from '../../../utils/stringManipulation';

const DigitalBookCard = ({ bookData }) => {
  const [showBookPreviewModal, setShowBookPreviewModal] = useState(false);
  const [canonicalLink, setCanonicalLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadFlippingBookCanonicalLink = () => {
    setIsLoading(true);
    getFlippingBookCanonicalLinkByBookId({ bookId: bookData?.bookId })
      .then((response) => {
        setIsLoading(false);
        setCanonicalLink(response.data.canonicalLink);
        setShowBookPreviewModal(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(
          'Error fetching flipping book canonical link for book:',
          bookData.bookId,
          error,
        );
      });
  };

  function handlePreview() {
    if (!canonicalLink) loadFlippingBookCanonicalLink();
    else setShowBookPreviewModal(true);
  }

  function renderLoadingOnPreview() {
    return (
      <>
        {isLoading && (
          <div className="absolute inset-0 z-50 flex justify-center items-center bg-white bg-opacity-80">
            <Spinner />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Box className="mb-6 relative">
        <div className="text-base gap-2 flex-row items-center px-6 py-[22px] text-boxTitle">
          <WindowFrameIcon />
          <span className="text-sm font-semibold capitalize">{bookData?.name}</span>
        </div>

        <div className="p-6 flex gap-6 border-t-[1px] border-t-neutral-200 overflow-visible">
          <div className="">
            <div
              className="group relative overflow-hidden h-48 cursor-pointer rounded-lg"
              onClick={handlePreview}
              onKeyDown={handlePreview}
            >
              <div className="h-32 md:h-48 overflow-hidden rounded-lg relative w-auto transition-transform duration-200 group-hover:scale-105">
                <img
                  src={bookData?.thumbnail?.url}
                  alt={bookData?.name}
                  className="h-full max-w-none object-cover rounded-lg transition-transform duration-200"
                />
              </div>
              <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-40 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                <div className="flex items-center gap-2 text-white text-sm opacity-100">
                  <PiEye /> <span>Preview</span>
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-visible">
            <div className="w-[470px] max-sm:w-full">
              <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                Lead capture website
                <Tooltip
                  content="Generate more leads and grow your business. Receive instant notifications for every new lead, so you can follow up quickly and win the business. "
                  trigger="hover"
                  style="dark"
                  arrow
                  placement="top"
                  theme={{
                    base:
                      'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                  }}
                >
                  <QuestionCircleIcon />
                </Tooltip>
              </h5>
              <Link
                key="landingPage"
                id="landingPage"
                showPreview={false}
                href={normalizeUrl(bookData?.links?.url?.site)}
                title={null}
              />
            </div>

            <div className="w-full mt-6">
              <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                Digital book
                <Tooltip
                  content="Share your book digitally to showcase expertise and build trust with remote clients."
                  trigger="hover"
                  style="dark"
                  arrow
                  placement="top"
                  theme={{
                    base:
                      'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                  }}
                >
                  <QuestionCircleIcon />
                </Tooltip>
              </h5>
              <Link
                key="digitalBook"
                id="digitalBook"
                showPreview={false}
                href={normalizeUrl(bookData?.links?.url?.book)}
                title={null}
              />
            </div>
          </div>
        </div>

        <Modal
          isOpen={showBookPreviewModal}
          width="100%"
          onCancel={() => setShowBookPreviewModal(false)}
          footer={
            <div className="flex w-full justify-end">
              <Button type="dark" onClick={() => setShowBookPreviewModal(false)}>
                Close
              </Button>
            </div>
          }
        >
          <FlippingBookEmbed src={canonicalLink} />
        </Modal>
        {renderLoadingOnPreview()}
      </Box>
    </>
  );
};

export default DigitalBookCard;
