import { call, race, take } from 'redux-saga/effects';

import { fetchBookLinks, resource, getError } from 'modules/api';

import { CLEAR_LINKS, GET_LINKS } from 'modules/bookLinks/store/constants';

import { getLinksPayload } from './utils';

export function* onGetLinks() {
  try {
    const request = resource(GET_LINKS, fetchBookLinks, getLinksPayload);
    const payload = yield call(request);

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchCancellation(request, action) {
  yield race([call(request, action), take(CLEAR_LINKS)]);
}

export function* watchGetLinks() {
  while (true) {
    const action = yield take(GET_LINKS);

    if (action) {
      yield call(watchCancellation, onGetLinks, action);
    }
  }
}
