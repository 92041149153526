import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

import * as color from 'modules/common/theme/color';

const SectionSorter = styled.div`
  border: 3px solid ${color.GreyBookBorder};
  background-color: ${color.DisabledBookSectionsBg};
`;

const ActiveSortingGroup = styled.div`
  background-color: ${color.ActiveBookSectionsBg};
`;

const Sections = styled.div`
  padding: 15px 20px 30px;
`;

const SectionTitle = styled.h5`
  font-weight: 600;
  text-align: center;
  color: ${color.GreySelectDd};
  font-size: 16px;
  padding: 0;
  line-height: 20px;
`;

const Info = styled.p`
  display: flex;
  padding-bottom: 20px;
`;

Info.Icon = styled(InfoCircleOutlined)`
  color: #048fcd;
  margin-right: 10px;
  font-size: 24px;
  position: relative;
  top: 3px;
`;

export default {
  SectionSorter,
  ActiveSortingGroup,
  Sections,
  SectionTitle,
  Info,
};
