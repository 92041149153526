import styled from 'styled-components';

const Title = styled.h3`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 20px 0 10px 0 !important;

  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default { Title, ActionWrapper, LoaderContainer };
