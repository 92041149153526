import styled from 'styled-components';
import LoaderImg from 'assets/images/alert-icon.png';

const ErrorMessage = styled.div`
  background: rgba(152, 15, 32, 0.1) url(${LoaderImg}) no-repeat center 40px;
  border-radius: 10px;
  padding: 20px;
  padding-top: 100px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
  line-height: 30px;
`;

export default {
  ErrorMessage,
};
