import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';
import { Button } from 'antd';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${color.TitleBorder};
  padding: 8px 0;

  &:last-child {
    border-bottom: 0;
  }
`;

const Link = styled(Button)`
  height: auto;
  padding: 0 !important;
  border: 0;

  span {
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: ${color.PrimaryColor};
  }
`;

const ContentDescription = styled.div`
  display: flex;
  color: #333333;
  font-family: ${font.NunitoFont};
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  span {
    margin-right: 1rem;
    font-size: 16px;
    font-weight: 700;
  }
`;

const Icon = styled.div`
  width: 2.6rem;
  position: relative;
  top: 3px;

  svg {
    fill: ${color.PrimaryColor};
    width: 16px;
    height: 16px;

    path {
      fill: ${color.PrimaryColor};
    }
  }
`;

export default {
  Link,
  Icon,
  ContentDescription,
  ContentWrapper,
};
