import { spawn } from 'redux-saga/effects';

import { watchExitDraft } from './exitDraft';
import { watchGetDraftById } from './getDraft';
import { watchGenerateDraft } from './generateDraft';

export default function* saga() {
  yield spawn(watchExitDraft);
  yield spawn(watchGetDraftById);
  yield spawn(watchGenerateDraft);
}
