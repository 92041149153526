import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './listingPresentationImage.png';

const isProduction = env.ENVIRONMENT === 'production';

const listingPresentationDefinitions = {
  offerDescription: [
    'The "Done-For-You" Listing Presentation" guaranteed to double your close rate on listing presentations.',
    'Instructions on how to modify the Listing Presentation to fit your personality and business.',
    'Step-by-Step Training on exactly how to do the Listing Presentation so you "Wow" the sellers and win the listing.',
    'This presentation has been "battle tested" on over 100 different Listing Presentations with all sorts of different sellers.',
    "This Presentation comes with Case Studies and Examples already put into presentation, so you can start using it right away to show sellers why you're different, and win the listing.",
    '6 color schemes to choose from.',
    'How to get sellers to price their home at a realistic price.',
    'Tips on how to avoid the classic "We have to think about it" objection so you win more listings.',
    "Here's what Eugenia in Virginia had to say about this Listing Presentation: “I used the listing presentation twice this week. The presentation demonstrates how I’m different from my competition and gets seller’s excited about my marketing. I got 1 listing at the price I wanted. I'm pretty sure I'll get the other next week.\"",
  ],
  productHandler: 'instant-listing-presentation',
  componentId: 'handle:clickfunnel-1',
  summaryTitle: 'Listing Presentation',
  summaryPrice: '$1',
  offerImage,
  upsell: {
    productHandler: 'monthly-with-trial-10',
    productPricePointHandle: 'monthly-with-trial-10',
    componentId: isProduction ? '1824463' : '2197555',
    component: () => <Upsell productName="Listing Presentation" />,
    offerId: isProduction ? '65b3a691db10a2b0181954eb' : '65b3a2f0059ee3cfa8d9bbdc',
  },
  USOnly: false,
};

export default listingPresentationDefinitions;
