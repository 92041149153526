import { useState } from 'react';
import { useQuery } from 'react-query';

import { Form } from '@ant-design/compatible';
import { Formik, Field } from 'formik';
import classnames from 'classnames';
import { noop } from 'lodash-es';

import env from 'environments';

import { PaymentSteps } from 'modules/dashboard/pages/PaymentSteps';
import { fetchUserCredits, getShowCreditsButton } from 'modules/api';
import { PrimaryBlackButton, PrimaryButton } from 'modules/common/components';
import { MIN_NUMBER_OF_PRINTS } from './constants';

import { CreditsSlider, CreditsNumericInput } from './components';
import { isNumberOfPrintsAllowed } from './utils';

import S from './styles';

const CheckoutForm = ({ fields, credits = 0, onSubmit = noop }) => {
  const [isCheckedConditions, setIsCheckConditions] = useState(true);
  const [showPaymentSteps, setShowPaymentSteps] = useState(false);
  const [showBuyCreditsButton, setShowBuyCreditsButton] = useState(false);
  const hasCredits = credits >= MIN_NUMBER_OF_PRINTS;
  const initialNumberOfPrints = MIN_NUMBER_OF_PRINTS;

  useQuery('getShowCreditsButton', getShowCreditsButton, {
    onSuccess: (data) => {
      setShowBuyCreditsButton(data);
    },
  });

  const { refetch: reactCreditQueryRefetch } = useQuery('getUserCredits', fetchUserCredits);

  const defaultFields = {
    ...fields,
    numberOfPrints: initialNumberOfPrints,
    isDigital: credits === 0,
  };

  return (
    <S.CreditsStyle>
      <Formik
        initialValues={defaultFields}
        validateOnChange={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, handleSubmit, isSubmitting }) => {
          const isContinueDisabled =
            !isCheckedConditions || !isNumberOfPrintsAllowed(values.numberOfPrints);

          return (
            <Form>
              <S.Block>
                {hasCredits && (
                  <div data-testid="quantity-form">
                    <S.SelectWrap>
                      <S.SelectWrapper>
                        <S.SliderTitle>I want to order:</S.SliderTitle>

                        <Field name="numberOfPrints">
                          {({ field, form: { setFieldValue } }) => (
                            <CreditsSlider
                              field={field}
                              name={field.name}
                              credits={credits}
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </Field>

                        <Field name="numberOfPrints">
                          {({ field, form: { setFieldValue } }) => (
                            <CreditsNumericInput
                              field={field}
                              name={field.name}
                              data-testid="numeric-input"
                              credits={credits}
                              setFieldValue={setFieldValue}
                              disabled={credits === 0}
                            />
                          )}
                        </Field>

                        <span>books</span>
                        <br />
                      </S.SelectWrapper>
                    </S.SelectWrap>

                    <Form.Item className="btnWrap">
                      <PrimaryButton
                        type="primary"
                        disabled={isContinueDisabled || isSubmitting}
                        data-testid="continue-button"
                        className={classnames({
                          disabled: isContinueDisabled || isSubmitting,
                        })}
                        onClick={handleSubmit}
                      >
                        Continue
                      </PrimaryButton>
                    </Form.Item>
                    <S.Separator />
                    <S.SeparatorNote>
                      *Please note: Any images added to the book will be printed in black and white
                    </S.SeparatorNote>
                  </div>
                )}
                {showBuyCreditsButton && (
                  <S.MoreCredits>
                    <div>
                      <S.MoreCredits.Title>Need More Credits?</S.MoreCredits.Title>

                      <PrimaryBlackButton onClick={() => setShowPaymentSteps(true)}>
                        Buy More Credits
                      </PrimaryBlackButton>
                    </div>
                  </S.MoreCredits>
                )}
                <PaymentSteps
                  setShowPaymentSteps={setShowPaymentSteps}
                  showPaymentSteps={showPaymentSteps}
                  getUserCredits={reactCreditQueryRefetch}
                />
              </S.Block>

              {hasCredits && (
                <S.AgreeConditions>
                  <S.Checkbox
                    id="agreeConditions"
                    onChange={(e) => setIsCheckConditions(e.target.checked)}
                    checked={isCheckedConditions}
                  />

                  <label htmlFor="agreeConditions" className="termConditions">
                    I have read and agree to the Authorify{' '}
                    <S.TermsLink target="_blank" rel="noopener noreferrer" href={env.TERMS_URL}>
                      Terms &amp; Conditions
                    </S.TermsLink>
                    .
                  </label>
                </S.AgreeConditions>
              )}
            </Form>
          );
        }}
      </Formik>
    </S.CreditsStyle>
  );
};

export default CheckoutForm;
