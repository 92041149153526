import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const WrapperLink = styled.div`
  cursor: pointer;
  &:hover {
    color: inherit;
  }
`;

const HoverWrapper = styled.div`
  position: absolute;
  background-color: rgba(40, 55, 70, 0.85);
  top: 3px;
  left: 4px;
  display: none;
  width: calc(100% - 8px);
  height: calc(100% - 6px);
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${WrapperLink}:hover & {
    display: flex;
  }
`;
const RoundedLinkButton = styled(Link)`
  background: transparent;
  border: 1px solid ${color.White} !important;
  border-radius: 19px !important;
  color: ${color.White};
  padding: 0 35px;
  text-transform: uppercase;
  font: 700 12px;
  height: 34px;
  line-height: 34px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  outline: none;
  transition: all 0.3s;

  :hover,
  :focus,
  :active {
    background-color: ${color.White};
    color: ${color.Black};
  }
`;

const LinkButton = styled(Link)`
  background: none;
  border: none;
  color: white;
  text-transform: uppercase;
  font: 700 12px / 12px ${font.NunitoFont};
  border-bottom: 1px solid transparent;
  margin-top: 7px;

  :hover,
  :focus,
  :active {
    background: none;
    color: ${color.White};
    border-bottom: 1px solid ${color.White};
  }
`;

const ImageWrapper = styled.div`
  height: 220px;
  width: 140px;
  border-radius: 6px;
  border: 1px solid ${color.neutral300};
  transition: border 0.5s ease;

  img {
    width: 140px;
    height: 220px;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
  }
`;
const upgradeDiv = styled.div`
  width: 220px;
  height: 180px;
  background: ${color.Black} 0% 0% no-repeat padding-box;
  opacity: 0.75;
`;
const Title = styled.span`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  display: inline-block;
`;

export default {
  WrapperLink,
  ImageWrapper,
  Title,
  HoverWrapper,
  RoundedLinkButton,
  LinkButton,
  upgradeDiv,
};
