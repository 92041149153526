import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import { pick, get } from 'lodash-es';

import { bookDetails } from 'modules/api';
import { notification } from 'modules/common/utils';
import { FormLabel } from 'modules/common/components';
import { ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import { isDentistOffer } from 'modules/dashboard/utils';
import { useFetchSession, usePoolingSession } from 'modules/onboard/hooks';
import { OnboardTemplate } from 'modules/onboard/templates';
import {
  Input,
  InputMask,
  FieldGroup,
  FieldControl,
  BookDetailsTitle,
  ContinueButton,
  ImageSlider,
} from 'modules/onboard/components';
import { getRouteBookSummary } from 'modules/onboard/routes/navigation';
import ImageUploader from 'modules/common/components/ImageUploader';
import useHubspotTrackCode from '../Common/HubspotTrackCode';
import validationSchema from './validationSchema';

import S from './styles';

const BookDetailsPage = ({ history }) => {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    book: '',
    avatar: '',
  };
  const [avatarUrl, setAvatarUrl] = useState('');
  const [bookId, setBookId] = useState('');
  const [fields, setFields] = useState(initialValues);
  const [showModal, setShowModal] = useState(false);

  const { offerCode } = useParams();
  const { session } = useFetchSession();
  const isDentist = isDentistOffer();

  const queryString = window.location.search;

  const { startPooling, stopPooling } = usePoolingSession({
    onSuccess: ({ data }) => {
      const bookData = data.book?.links?.pages;

      if (bookData) {
        setShowModal(false);
        stopPooling();
        history.push(getRouteBookSummary(offerCode, queryString));
      }
    },
  });

  const PRODUCT = isDentist ? 'guide' : 'book';

  useHubspotTrackCode();

  let bookOptions = session?.currentOffer?.bookOptions || [];
  const country = session?.customer?.billing?.country;
  if (country === 'CA' || country === 'Canada') {
    bookOptions = session?.currentOffer?.bookOptionsCA || [];
  }

  useEffect(() => {
    setBookId(session.book?.bookId);
    const customer = get(session, 'customer', {});
    setBookId(get(bookOptions[0], 'id'));

    setFields({
      ...customer,
      name: `${customer.firstName} ${customer.lastName}`,
      book: get(bookOptions[0], 'id'),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: mutateGenerateBook, isLoading: isLoadingBook } = useMutation((payload) =>
    bookDetails({ payload, offerCode }),
  );

  const onSave = (values) => {
    setShowModal(true);
    const payload = pick(values, ['name', 'email', 'phone']);
    payload.book = bookId;
    payload.avatar = avatarUrl;

    mutateGenerateBook(payload, {
      onSuccess: () => {
        startPooling();
      },
      onError: (error) => {
        notification.error({
          title: 'Something bad has happened!',
          description: error?.response?.data?.message,
        });
      },
    });
  };

  return (
    <OnboardTemplate currentStep="3" hideNavigation isDentist={isDentist}>
      <Formik
        enableReinitialize
        initialValues={fields}
        onSubmit={onSave}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, handleChange, isValid, dirty, isSubmitting }) => {
          const isSubmitDisabledByValidation = dirty ? !isValid || isSubmitting : false;

          return (
            <>
              <S.MainContent>
                <S.BoxSelectCover>
                  <ImageSlider
                    title={`${PRODUCT} Preview`}
                    images={bookOptions}
                    bookId={setBookId}
                  />
                </S.BoxSelectCover>
                <S.LineSeparator />
                <S.BoxUserForm>
                  <BookDetailsTitle>Your {PRODUCT} details</BookDetailsTitle>
                  <S.Title>Upload Photo</S.Title>
                  <ImageUploader callback={setAvatarUrl} />
                  <S.FormDescription>
                    Information provided below will show on your {PRODUCT} cover
                  </S.FormDescription>
                  <Form onSubmit={handleSubmit}>
                    <FieldGroup>
                      <FieldControl>
                        <FormLabel title="Full Name" htmlFor="name" required>
                          <Input
                            disabled={isLoadingBook}
                            id="name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </FormLabel>
                      </FieldControl>
                    </FieldGroup>

                    <FieldGroup>
                      <FieldControl>
                        <FormLabel title="Email Address" htmlFor="email" required>
                          <Input
                            disabled={isLoadingBook}
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                        </FormLabel>
                      </FieldControl>
                    </FieldGroup>

                    <FieldGroup>
                      <FieldControl>
                        <FormLabel title="Phone Number" htmlFor="phone" required>
                          <InputMask
                            disabled={isLoadingBook}
                            id="phone"
                            name="phone"
                            type="text"
                            mask="999-999-9999"
                            value={values.phone}
                            onChange={handleChange}
                            alwaysShowMask
                          />
                        </FormLabel>
                      </FieldControl>
                    </FieldGroup>
                  </Form>
                </S.BoxUserForm>
              </S.MainContent>

              <S.ButtonStepsContainer>
                <ContinueButton onClick={handleSubmit} disabled={isSubmitDisabledByValidation}>
                  Continue
                </ContinueButton>
              </S.ButtonStepsContainer>
            </>
          );
        }}
      </Formik>
      {showModal && (
        <ProcessingModal
          title={`Generating your ${PRODUCT}`}
          description="Please wait..."
          showModal={showModal}
        />
      )}
    </OnboardTemplate>
  );
};

export default BookDetailsPage;
