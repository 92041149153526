export const PencilIcon = ({ width = 16, height = 16, fill = '#252525' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_3919_7677)">
      <path
        d="M9.57305 2.71902L10.191 2.1011C11.2148 1.0773 12.8747 1.0773 13.8985 2.1011C14.9223 3.12491 14.9223 4.78482 13.8985 5.80862L13.2806 6.42654M9.57305 2.71902C9.57305 2.71902 9.65029 4.0321 10.8089 5.1907C11.9675 6.3493 13.2806 6.42654 13.2806 6.42654M9.57305 2.71902L3.89222 8.39985C3.50745 8.78463 3.31506 8.97701 3.14961 9.18914C2.95443 9.43937 2.7871 9.71012 2.65057 9.9966C2.53483 10.2395 2.44879 10.4976 2.27671 11.0138L1.54755 13.2013M13.2806 6.42654L7.59974 12.1074C7.21497 12.4921 7.02258 12.6845 6.81045 12.85C6.56022 13.0452 6.28947 13.2125 6.00299 13.349C5.76014 13.4648 5.50202 13.5508 4.98579 13.7229L2.79829 14.452M2.79829 14.452L2.26357 14.6303C2.00953 14.715 1.72945 14.6488 1.5401 14.4595C1.35074 14.2701 1.28463 13.9901 1.36931 13.736L1.54755 13.2013M2.79829 14.452L1.54755 13.2013"
        stroke={fill}
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_3919_7677">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
