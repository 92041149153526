import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  background: ${color.White};
  padding: 2rem;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 10px 0 !important;
  text-align: center;
`;

const Text = styled.h3`
  margin: 20px 0 10px 0 !important;
  color: ${color.Black};
`;

export default {
  Wrapper,
  Title,
  Text,
};
