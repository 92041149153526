//

import { Row, Col } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';
import { filter } from 'lodash-es';
import classnames from 'classnames';

import { cn } from 'modules/v2/common/utils';
import ToolTip from 'modules/v2/common/AtomicDesign/molecules/Tooltip';
import QuestionCircle from 'modules/v2/common/components/SvgIcon/QuestionCircle';
import { ListSorting, ListArchived } from './components';
import S from './styles';

const SectionSorting = ({ items, selectedId, onDrag, onEdit, onSelect, archivedItems }) => {
  const handleDrag = ({ draggableId, source, destination }) => {
    onDrag(draggableId, source, destination);
  };

  const isArchived = new Set();
  archivedItems.forEach(({ _id }) => {
    isArchived.add(_id);
  });

  const active = filter(items, (item) => item.isActive);
  const inactive = filter(items, (item) => !item.isActive && !isArchived.has(item._id));

  return (
    <div className="w-full h-full font-figtree">
      <DragDropContext onDragEnd={handleDrag}>
        <Row className="h-auto outline-2 outline-indigo-50">
          <Col span={24}>
            <S.ActiveSortingGroup>
              <S.Sections
                className={classnames({
                  dropSpacing: active.length,
                })}
              >
                <div className="w-full flex gap-1">
                  <h3 className="text-neutral-800 text-sm font-normal mb-3 tracking-normal">
                    Active book sections
                  </h3>
                  <ToolTip
                    arrow
                    trigger="hover"
                    style="dark"
                    placement="top"
                    content="This section will appear in your book in the following order below."
                    theme={{
                      base: cn('top-12 z-50 rounded-md py-2 px-3 max-w-80 text-sm font-medium'),
                    }}
                  >
                    <QuestionCircle />
                  </ToolTip>
                </div>
                <ListSorting
                  droppableId="active"
                  selectedId={selectedId}
                  items={active}
                  onDrag={onDrag}
                  onEdit={onEdit}
                  onSelect={onSelect}
                />
              </S.Sections>
            </S.ActiveSortingGroup>
          </Col>

          <Col span={24}>
            <S.Sections
              className={classnames(
                {
                  dropSpacing: inactive.length,
                },
                'bg-neutral-50',
              )}
            >
              <div className="w-full flex gap-1">
                <h3 className="text-neutral-800 text-sm font-normal tracking-normal">
                  Inactive book sections
                </h3>
                <ToolTip
                  arrow
                  trigger="hover"
                  style="dark"
                  placement="bottom"
                  content="This section will not appear in your book."
                  theme={{
                    base: cn('top-12 z-50 rounded-md py-2 px-3 max-w-80 text-sm font-medium'),
                  }}
                >
                  <QuestionCircle />
                </ToolTip>
              </div>

              <p className="text-sm text-neutral-500 font-normal mb-4">
                Drag and drop to ”active book” to start editing and to include it in your book.
              </p>

              <ListSorting
                droppableId="inactive"
                selectedId={selectedId}
                items={inactive}
                onDrag={onDrag}
                onSelect={onSelect}
              />
            </S.Sections>
          </Col>

          <Col span={24}>
            <S.Sections
              className={classnames({
                dropSpacing: archivedItems.length,
              })}
            >
              <h3 className="text-neutral-800 text-sm font-normal tracking-normal">
                Archived book sections
              </h3>

              <p className="text-sm text-neutral-500 font-normal mb-4">
                These are outdated versions of your book and available for viewing only
              </p>

              <ListArchived
                droppableId="archived"
                selectedId={selectedId}
                items={archivedItems}
                onSelect={onSelect}
              />
            </S.Sections>
          </Col>
        </Row>
      </DragDropContext>
    </div>
  );
};

export default SectionSorting;
