import { NavBar } from 'modules/nav/components';
import { TYPE_MANUSCRIPT } from 'modules/editor/utils';

import EditorGrid from '../EditorGrid';

const { Dropdown } = NavBar;
const { Item } = NavBar.Menu;

const EditorDropdown = ({ title, items }) => {
  const content = items.reduce((acc, { _id, displayName, to, type, onClick, children, status }) => {
    if (status !== 'deprecated') {
      acc.push(
        <Item key={_id} title={displayName} to={to} onClick={onClick}>
          {type === TYPE_MANUSCRIPT && children && <EditorGrid items={children} />}
        </Item>,
      );
    }
    return acc;
  }, []);

  return <Dropdown title={title}>{content}</Dropdown>;
};

export default EditorDropdown;
