import { useState } from 'react';
import { BookPreview as IBookPreview } from 'modules/dashboard/pages/admin/BookPreviews/types';
import { Modal, PrimaryButton, SecondaryButton, TertiaryButton } from 'modules/common/components';
import { Iframe } from 'modules/draft/components';
import { notification } from 'modules/common/utils';
import { BookList } from 'modules/dashboard/components';

import { Book } from '../../types';
import S from './styles';

const BookPreview = ({
  book,
  linkUrl,
  bookPreviews,
}: {
  book: Book;
  linkUrl: string;
  bookPreviews: IBookPreview[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name: bookName, _id: bookId, thumbnail: bookThumbnail, isAllowed } = book;

  if (!bookName) return null;

  const currentBookPreview = bookPreviews.find((previews) => bookId === previews.bookId);

  const handlePreviewOpen = () => setIsOpen(true);
  const handlePreviewClose = () => setIsOpen(false);

  const handleUpdateClick = () => {
    notification.error({ description: 'Coming soon.' });
  };

  const getPreviewWrapper =
    currentBookPreview && !isAllowed ? (
      <S.PreviewWrapper>
        <S.UpdateMembershipContainer>
          <p>Update your membership to see this book </p>
          <PrimaryButton onClick={handleUpdateClick}>Upgrade Plan</PrimaryButton>
          <TertiaryButton type="link" onClick={handlePreviewOpen}>
            Preview Book
          </TertiaryButton>
        </S.UpdateMembershipContainer>
        <Modal
          title={currentBookPreview?.bookTitle}
          isOpen={isOpen}
          width="1000px"
          wrapClassName="transparent-bg"
          hasCloseButton
          onConfirm={handlePreviewClose}
          onCancel={handlePreviewClose}
          footer={[
            <S.ModalFooterContainer>
              <SecondaryButton onClick={handlePreviewClose}>Close</SecondaryButton>
            </S.ModalFooterContainer>,
          ]}
        >
          <S.PreviewModalBody>
            <Iframe src={currentBookPreview?.pdfUrl} />
          </S.PreviewModalBody>
        </Modal>
      </S.PreviewWrapper>
    ) : null;

  return (
    <S.Preview>
      {getPreviewWrapper}
      <BookList>
        <BookList.BookCard
          name={bookName}
          image={bookThumbnail?.url}
          to={linkUrl}
          isAllowed={isAllowed}
        />
      </BookList>
    </S.Preview>
  );
};

export default BookPreview;
