function BillingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_4265" data-name="Group 4265" transform="translate(-800 -262)">
        <rect
          id="Rectangle_1007"
          data-name="Rectangle 1007"
          width="24"
          height="24"
          transform="translate(800 262)"
          fill="none"
        />
        <path
          id="credit-card"
          d="M79.429,160H64.571a.571.571,0,0,0-.571.571V172a.571.571,0,0,0,.571.571H79.429A.571.571,0,0,0,80,172V160.571A.571.571,0,0,0,79.429,160Zm-14.143,1.286H78.714v2.143H65.286Zm13.429,10H65.286V165H78.714Zm-4.232-1.143h2.946a.143.143,0,0,0,.143-.143v-1.286a.143.143,0,0,0-.143-.143H74.482a.143.143,0,0,0-.143.143V170A.143.143,0,0,0,74.482,170.143Z"
          transform="translate(740 107.999)"
          fill="#003a8c"
        />
      </g>
    </svg>
  );
}

export default BillingIcon;
