//

import S from './styles';

const Button = ({
  children,
  onClick,
  icon: Icon,
  disabled,
  'data-testid': dataTestId,
  theme = 'primary',
}) => (
  <S.Button theme={theme} data-testid={dataTestId} onClick={onClick} disabled={disabled}>
    {Icon instanceof Function && (
      <S.Button.Icon>
        <Icon />
      </S.Button.Icon>
    )}
    <S.Button.Label>{children}</S.Button.Label>
  </S.Button>
);

export default Button;
