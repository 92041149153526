import { getItem } from 'modules/dashboard/utils/legacy';

export const getSessionId = (offerCode) => {
  try {
    const session = getItem('onboardSession');
    const sessionObj = JSON.parse(session)[offerCode];

    return sessionObj.id;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return error;
  }
};
