import get from 'lodash/get';
import map from 'lodash/map';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { useState } from 'react';
import ProgressBar from 'modules/v2/common/components/ProgressBar';

const RenderOptions = ({
  optionsList = [],
  selectedOption,
  handleChange,
  onImageClick,
  displayType = 'magazine',
}) => {
  const [imageIsLoading, setImageisLoading] = useState(true);
  let wd = displayType === 'magazine' ? 'md:w-36 xl:w-44' : 'md:w-40 lg:w-44 xl:w-56';
  let ht = displayType === 'magazine' ? 'md:h-52 xl:h-60' : 'h-32';

  if (optionsList.length > 5) {
    wd = displayType === 'magazine' ? 'md:w-28 xl:w-32 2xl:w-36' : 'md:w-40 lg:w-44 xl:w-56';
    ht = displayType === 'magazine' ? 'md:h-40 xl:h-44 2xl:h-52' : 'h-32';
  }

  return (
    <div className="flex gap-4 flex-wrap justify-center lg:justify-start">
      {map(optionsList, (option) => {
        const isSelected = selectedOption?.id === option?.id;
        const optionImage = get(option, 'displayImage');

        return (
          <div
            key={option.id}
            className={`relative flex items-center ${wd} ${ht} rounded-xl group overflow-hidden}`}
          >
            {imageIsLoading && (
              <div className="absolute z-10 flex w-full items-center left-0 h-full backdrop-blur-sm opacity-30">
                <ProgressBar />
              </div>
            )}
            <div
              className={`flex items-center rounded-xl overflow-hidden ${wd} ${ht} p-0.5 ${
                isSelected ? 'border-primary-500 border-4' : 'border-neutral-300 border'
              }`}
            >
              <img
                alt={option.name}
                src={optionImage}
                className={`rounded-lg object-fill m-0 group-hover:scale-110 transform transition-scale duration-500 ease-in-out w-full ${
                  displayType === 'magazine' ? 'h-full' : 'h-auto'
                }`}
                onLoad={() => setTimeout(() => setImageisLoading(false), 500)}
              />
            </div>
            <div className="absolute rounded-xl w-full h-full opacity-0 bg-black/0 group-hover:bg-black/20 group-hover:opacity-100 transition-opacity duration-700 ease-in-out top-0 left-0 flex flex-col gap-1 items-center justify-center p-2">
              <Button
                color="light"
                onClick={() => onImageClick(optionImage)}
                size="md"
                buttonType="button"
                className={`w-fit ${
                  optionsList.length > 5 ? 'min-w-24 xl:min-w-28' : 'min-w-32'
                } text-neutral-800 text-sm focus:ring-0 font-bold hover:border-neutral-600 hover:bg-neutral-100 py-1`}
              >
                Preview
              </Button>
              <Button
                onClick={() => handleChange(option)}
                buttonType="button"
                size="md"
                className={`w-fit ${
                  optionsList.length > 5 ? 'min-w-24 xl:min-w-28' : 'min-w-32'
                } focus:ring-0 bg-primary-500 text-neutral-800 hover:bg-primary-400 py-1`}
              >
                Choose
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RenderOptions;
