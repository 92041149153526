import gql from 'graphql-tag';

export default gql(`
    query($sectionId: ID!) {
        section(id:$sectionId){
            _id
            name
            type
            currentStatus
            order
            displayName
            bookId
            templates {
                _id
                name
                templateHTML
                bookpage {
                    _id
                    elements
                }
                thumbnail {
                    _id
                    url
                    mime
                }
                thumbnail_small {
                    _id
                    url
                    mime
                }
                elements
            }
        }
    }
`);
