import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 14px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top: 10px;

  .ant-radio-wrapper {
    align-items: center;
  }
  .ant-radio {
    margin-right: 8px;
  }
`;

const Change = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #1890ff;
  cursor: pointer;
`;

const CreditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Credit = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const Price = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export default { Container, CreditWrapper, Credit, Price, Description, Change };
