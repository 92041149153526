import { useState } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'antd';
import classnames from 'classnames';

import { FormErrors } from 'modules/common/components';
import { isLoading, isFailed } from 'store/status';
import { NextIcon } from 'modules/common/components/SvgIcon';
import { FileUploadModal } from 'modules/dashboard/components';

import validationSchema from './validationSchema';

import { UploadInput } from './components';
import S from './styles';

const IntakeForm = ({
  fields,
  isSubmitting,
  preferencesStatus,
  uploadStatus,
  uploadError,
  onSubmit,
  onUpload,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isPreferencesLoading = isLoading(preferencesStatus);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleConfirm = (newFile) => {
    setIsOpen(false);
    onUpload(newFile);
  };

  return (
    <Formik
      initialValues={fields}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      isInitialInvalid
    >
      {({ values, errors, handleSubmit, handleChange }) => {
        const hasErrors = Object.keys(errors).length > 0;
        const disabled =
          hasErrors || isSubmitting || isLoading(uploadStatus) || isFailed(uploadStatus);

        return (
          <S.Form onSubmit={handleSubmit}>
            <FormErrors errors={errors} />

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <S.FormLabel title="Full name" htmlFor="name" required>
                  <S.Input
                    id="name"
                    maxLength={26}
                    type="text"
                    onChange={handleChange}
                    className={classnames({ 'form-input-error': errors.name })}
                    value={values.name}
                    name="name"
                    placeholder="Enter full name"
                  />
                </S.FormLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <S.FormLabel title="Email" htmlFor="email" required>
                  <S.Input
                    id="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    className={classnames({ 'form-input-error': errors.email })}
                    value={values.email}
                    placeholder="Enter email"
                  />
                </S.FormLabel>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <S.FormLabel title="Phone" htmlFor="phone" required>
                  <S.InputMask
                    id="phone"
                    name="phone"
                    type="text"
                    className={classnames({ 'form-input-error': errors.phone })}
                    mask="999-999-9999"
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="Enter phone"
                    alwaysShowMask
                  />
                </S.FormLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <S.FormLabel title="Broker info" htmlFor="brokerInfo">
                  <S.TextArea
                    type="text"
                    id="brokerInfo"
                    rows={2}
                    maxLength={146}
                    name="brokerInfo"
                    value={values.brokerInfo}
                    placeholder="Enter broker info"
                    onChange={handleChange}
                  />
                </S.FormLabel>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <S.FormLabel title="Upload headshot">
                  <UploadInput
                    uploadStatus={uploadStatus}
                    uploadError={uploadError}
                    onClick={handleClick}
                  />
                </S.FormLabel>

                {/*
                  If the user tries to upload another image after the first one,
                  the FilePickerModal component needs to been removed from the DOM to be reseted
                */}
                {isOpen && (
                  <FileUploadModal
                    isOpen={isOpen}
                    title="Upload Headshot"
                    type="profile"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                  />
                )}
              </Col>
            </Row>

            <S.ButtonRow>
              <S.Button
                className={classnames('ant-btn ant-btn-primary resizebtn', {
                  disabled,
                })}
                loading={isPreferencesLoading}
                disabled={disabled}
                onClick={handleSubmit}
              >
                Continue <NextIcon />
              </S.Button>
              <S.RequiredTextWrapper>* Required field</S.RequiredTextWrapper>
            </S.ButtonRow>
          </S.Form>
        );
      }}
    </Formik>
  );
};

IntakeForm.defaultProps = {
  isSubmitting: false,
};

export default IntakeForm;
