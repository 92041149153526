//

import { get, isEmpty } from 'lodash-es';

import { Loader, Scrollbars } from 'modules/common/components';
import { OrderReview } from 'modules/editor/components';
import { MODAL_HEIGHT } from 'modules/publish/constants';
import { orderQuantity } from 'modules/common/utils';

import S from 'modules/publish/styles';

const ReviewView = ({
  loading,
  numberOfPrints,
  selectedAddress,
  isDigitalBook,
  onChangeAddress,
  onEditQuantity,
  onComplete,
}) => {
  if (loading) {
    return <Loader height={MODAL_HEIGHT} title="Processing Order" />;
  }

  const quantity = orderQuantity(numberOfPrints, isDigitalBook);

  let address = null;

  if (!isEmpty(selectedAddress)) {
    const firstName = get(selectedAddress, 'firstName', '');
    const lastName = get(selectedAddress, 'lastName', '');
    const addressLine1 = get(selectedAddress, 'addressLine1', '');
    const addressLine2 = get(selectedAddress, 'addressLine2', '');
    const city = get(selectedAddress, 'city', '');
    const state = get(selectedAddress, 'state', '');
    const pincode = get(selectedAddress, 'pincode', '');
    const country = get(selectedAddress, 'country', '');

    address = (
      <OrderReview.Address
        data-testid="change-address"
        firstName={firstName}
        lastName={lastName}
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        city={city}
        state={state}
        pincode={pincode}
        country={country}
        title="Shipping Address"
        linkTitle="Change"
        onClick={onChangeAddress}
      />
    );
  }

  return (
    <Scrollbars style={{ height: MODAL_HEIGHT }}>
      <S.ViewTitle>Review Order</S.ViewTitle>
      <S.ViewSubTitle>
        Please review your order details and then click print to order your books. Your order number
        will appear on the next screen.
      </S.ViewSubTitle>
      <OrderReview data-testid="complete-review" onComplete={onComplete}>
        <OrderReview.Item
          data-testid="edit-quantity"
          title="Quantity"
          linkTitle="Edit"
          value={quantity}
          onClick={onEditQuantity}
        />
        {address}
      </OrderReview>
    </Scrollbars>
  );
};

export default ReviewView;
