const PasswordEyeClosedIcon = ({ fill = '#d7d7d7', ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.70613 2.29289C3.31561 1.90237 2.68244 1.90237 2.29192 2.29289C1.90139 2.68342 1.90139 3.31658 2.29192 3.70711L16.2919 17.7071C16.6824 18.0976 17.3156 18.0976 17.7061 17.7071C18.0967 17.3166 18.0967 16.6834 17.7061 16.2929L16.233 14.8197C17.7705 13.5924 18.938 11.9211 19.5414 9.99996C18.2671 5.94288 14.4769 3 9.99924 3C8.37567 3 6.84247 3.38692 5.48681 4.07358L3.70613 2.29289ZM7.96715 6.55391L9.48103 8.0678C9.64632 8.02358 9.82005 8 9.99928 8C11.1038 8 11.9993 8.89543 11.9993 10C11.9993 10.1792 11.9757 10.353 11.9315 10.5182L13.4454 12.0321C13.7973 11.4366 13.9993 10.7419 13.9993 10C13.9993 7.79086 12.2084 6 9.99928 6C9.2574 6 8.5627 6.20197 7.96715 6.55391Z"
      fill={fill}
    />
    <path
      d="M12.4531 16.6967L9.74868 13.9923C7.73915 13.8681 6.13122 12.2601 6.007 10.2506L2.33394 6.57754C1.49965 7.57223 0.855392 8.73169 0.457031 10C1.73131 14.0571 5.52159 17 9.99922 17C10.8459 17 11.668 16.8948 12.4531 16.6967Z"
      fill={fill}
    />
  </svg>
);

export default PasswordEyeClosedIcon;
