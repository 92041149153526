import styled from 'styled-components';

const IframeWrapper = styled.div`
  height: 570px;
  margin-top: 12px;
`;

const BackButtonContainer = styled.div`
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const BackIcon = styled.img`
  margin-right: 10px;
`;

export default {
  BackButtonContainer,
  BackIcon,
  IframeWrapper,
};
