import {
  GET_PREFERENCES,
  SET_PREFERENCES,
  UPDATE_PREFERENCES,
  CLEAR_PREFERENCES,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  data: {
    name: '',
    email: '',
    phone: '',
    brokerInfo: {
      status: false,
      value: '',
    },
    brokerLogo: {
      status: false,
      value: '',
    },
    realtorLogo: {
      status: false,
      value: '',
    },
    housingLogo: {
      status: false,
      value: '',
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PREFERENCES}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PREFERENCES: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data,
      };
    }

    case `${GET_PREFERENCES}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }
    case `${SET_PREFERENCES}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_PREFERENCES: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case `${SET_PREFERENCES}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case UPDATE_PREFERENCES: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }

    case CLEAR_PREFERENCES: {
      return {
        loading: false,
        loaded: true,
        data: initialState.data,
      };
    }

    default: {
      return { ...state };
    }
  }
};
