import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  max-height: 60vh;
  padding: 24px;
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
  }
`;

const CreditContainer = styled.div`
  width: 560px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${color.GrayBorderColor};
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContainerTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
`;

const Line = styled.hr`
  margin-left: -1px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  background-color: ${color.GrayBorderColor};
  color: ${color.GrayBorderColor};
  border: none;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ModalDescription = styled.span`
  font-size: 15px;

  b {
    font-weight: 800;
    color: ${color.PrimaryColor};
    margin-right: 2px;
  }
`;

export default { ContainerTitle, Container, ModalDescription, Line, CreditContainer, LeftContent };
