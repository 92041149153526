import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';

const GuideBox = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${color.White};
  border-radius: 4px;
  border: 2px solid ${color.BoxBorder};
  margin-bottom: 23px;
  display: flex;
  flex-direction: column;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const GuidePacketContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-bottom: 35px;

  img {
    max-width: 100%;
    width: 300px;
    height: auto;
  }

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const YourGuideContainer = styled.div`
  display: flex;
  gap: 45px;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const GuideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GuideContainer = styled.div`
  max-height: 200px;
  display: block;
  background-color: ${color.BoxBorder};
  flex-wrap: wrap;
  flex: 1;

  img {
    max-width: 100%;
    width: 154px;
    height: auto;
  }

  @media ${device.tabletS} {
    margin: auto;
  }
`;

const GuideCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .ant-btn {
    padding: 0;
  }
  span {
    color: ${color.GreySubtitle};
  }
`;

const YourGuideTitle = styled.p`
  font-size: 20px;
  line-height: 25px;
  margin: 10px 0;
  color: ${color.BoxTitle};
`;

const PageSubTitleDescription = styled.li`
  font-size: 16px;
  line-height: 25px;
  color: ${color.BoxTopics};
  margin-left: 30px;
`;

export default {
  YourGuideContainer,
  GuideWrapper,
  GuideContainer,
  GuideCardContainer,
  GuideBox,
  YourGuideTitle,
  PageSubTitleDescription,
  GuidePacketContainer,
};
