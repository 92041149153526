import env from 'environments';
import { currentYear } from 'modules/common/utils/date';

import S from './style';

const privacyUrl = 'https://authorify.com/privacy';

const FooterTemplate = ({ tiny = true }) => (
  <S.FooterContainer tiny={tiny}>
    <S.FooterWrapper tiny={tiny}>
      Authorify © {currentYear} - All Rights Reserved
      {tiny ? null : (
        <>
          <br />
          3500 Beachwood Court #203 Jacksonville, FL 32216 <br />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href={env.PRIVACY_URL || privacyUrl}>Privacy Policy</a> |{' '}
          <a href={env.TERMS_URL}>Terms Of Use</a>
        </>
      )}
    </S.FooterWrapper>
  </S.FooterContainer>
);

export default FooterTemplate;
