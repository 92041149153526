export const BoxIcon = ({ width = 18, height = 19, fill = '#252525' }) => (
  <svg width={width} height={height} viewBox="0 0 18 19" fill="none">
    <path
      d="M11.6833 2.78648L13.1833 3.57364C14.797 4.4205 15.6039 4.84393 16.052 5.60481C16.5 6.36569 16.5 7.3125 16.5 9.20613V9.29387C16.5 11.1875 16.5 12.1343 16.052 12.8952C15.6039 13.6561 14.797 14.0795 13.1833 14.9264L11.6833 15.7135C10.3666 16.4045 9.7082 16.75 9 16.75C8.2918 16.75 7.63344 16.4045 6.31672 15.7135L4.81672 14.9264C3.20297 14.0795 2.3961 13.6561 1.94805 12.8952C1.5 12.1343 1.5 11.1875 1.5 9.29387V9.20613C1.5 7.3125 1.5 6.36569 1.94805 5.60481C2.3961 4.84393 3.20297 4.4205 4.81672 3.57364L6.31672 2.78647C7.63344 2.09549 8.2918 1.75 9 1.75C9.7082 1.75 10.3666 2.09549 11.6833 2.78648Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.75 5.875L12.75 7.375M9 9.25L2.25 5.875M9 9.25V16.375M9 9.25C9 9.25 11.057 8.22151 12.375 7.5625C12.5214 7.48928 12.75 7.375 12.75 7.375M12.75 7.375V10M12.75 7.375L5.625 3.625"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
