import styled from 'styled-components';
import { Checkbox as BaseCheckbox } from 'antd';

import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const CrStyle = styled.div`
  text-align: center;

  .ant-slider {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .ant-slider-track {
    height: 20px;
    border-radius: 10px;
  }

  .ant-slider-rail {
    height: 20px;
    background-color: #e1e1e1;
    border-radius: 10px;
  }

  .ant-slider-dot {
    display: none;
  }

  .ant-slider-handle,
  .ant-slider-dot {
    height: 30px;
    width: 30px;
  }

  .ant-slider-mark-text {
    top: 15px;
  }

  .ant-slider-dot {
    top: -6px;
  }

  .ant-form {
    textarea.ant-input {
      border: 1px solid #cccccc;
      border-radius: 3px;
      background-color: #ffffff;
      min-height: 90px;
      resize: none;
      font-size: 16px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-checkbox-wrapper {
      text-align: left;
      display: contents !important;
      color: ${color.PrimaryColor};
      font-family: ${font.MontserratFont};
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      letter-spacing: 0.5px;
      text-decoration: underline !important;
      display: inline-block;
      vertical-align: middle;
      margin: -14px 0 0 0;

      &.ant-checkbox-wrapper-disabled,
      &.ant-checkbox-wrapper-disabled + span {
        color: ${color.PrimaryColor};
      }
    }

    .ant-form-item-control-wrapper {
      text-align: left;
    }

    .ant-input-disabled {
      background-color: #f6f6f6 !important;
    }

    .ant-form-item-label {
      label {
        font-size: 16px;
      }
    }
  }

  .btnWrap {
    display: flex;
    justify-content: center;
    margin: 3rem 0 0;

    button {
      height: 50px;
      width: 300px;
      line-height: 50px;
      margin: 0 5px;
    }

    .disabled {
      cursor: default;
      position: relative;

      &::after {
        content: 'a';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const Select = styled.div`
  font-family: ${font.MontserratFont};
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
`;

const STitle = styled.span`
  padding: 0 5px;
  font-size: 24px;
`;

const SelectWrap = styled.div`
  padding: 0;

  .ant-select {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    width: auto !important;
    min-width: 70px;

    .ant-select-selection--single {
      height: 36px;

      .ant-select-selection-selected-value {
        font-family: ${font.MontserratFont};
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        line-height: 34px;
        text-align: right;
        padding: 0 20px 0 0;
        width: 100%;
      }
    }

    .ant-select-arrow {
      i {
        display: none;
      }

      &::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid ${color.GreyFormCheckbox};
        position: absolute;
        top: 3px;
        right: 2px;
      }
    }
  }
`;

const FormBlock = styled.div`
  border: 1px solid ${color.TitleBorder};
  border-radius: 3px;
  padding: 30px;
  margin: 0 25px 30px;
`;

const Separator = styled.div`
  border-top: 1px solid ${color.SeparatorBorder};
  margin: 30px 0 5px;
`;

const SeparatorNote = styled.div`
  display: flex;
  margin-bottom: 30px;
  color: ${color.Black};
  font-size: 12px;
  font-weight: bold;
  font-family: ${font.MontserratFont};
`;

const DescriptionCredits = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

const AgreeCondition = styled.div`
  width: 70%;
  margin: 0 auto;
`;

const ConditionLink = styled.a`
  color: ${color.Body};
  text-decoration: underline !important;
`;

const Checkbox = styled(BaseCheckbox)`
  .ant-checkbox {
    margin-right: 5px;
  }
`;

export default {
  AgreeCondition,
  FormBlock,
  Checkbox,
  CrStyle,
  DescriptionCredits,
  SelectWrap,
  Select,
  Separator,
  SeparatorNote,
  STitle,
  ConditionLink,
};
