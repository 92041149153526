import styled from 'styled-components';
import { Button } from 'antd';

import { PrimaryButton as BasePrimaryButton } from 'modules/common/components';
import * as color from 'modules/common/theme/color';

const ModalVerifyEmailFooter = styled.div`
  text-align: center;

  ${BasePrimaryButton} {
    width: 90%;
    margin: 0 auto;
  }
`;

const EmailVerifyText = styled.p`
  font-size: 22px;
  text-align: center;
  margin: 20px 0;
  line-height: 36px;
`;

const ListCards = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  overflow-x: auto;
`;

const Card = styled.div`
  display: flex;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 0px 3px ${color.GrayBorder};
  border-radius: 4px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  gap: 8px;
`;

const CardImage = styled.div`
  border-right: 1px solid ${color.GrayBorder};
  position: relative;
  img {
    max-height: 130px;
  }
`;

const CardTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  display: block;
  text-align: center;
  color: ${color.Black};
`;

const CardCount = styled.div`
  position: absolute;
  z-index: 1;
  top: 56px;
  height: 32px;
  width: 100%;
  text-align: center;
  padding: 7px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${color.White};
`;

const ContentInner = styled.div`
  border: 2px solid ${color.GrayBorder};
  border-radius: 2px;
  min-height: calc(100vh - 206px);
  padding: 20px;
`;

const WrapperTitle = styled.h4`
  display: block;
  margin-bottom: 20px !important;
`;

const TemplateButton = styled(Button)`
  font-weight: normal !important;
  color: ${color.SkyBlue};

  svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
    font-size: 16px;
  }
`;

const ModalWrapper = styled.div`
  overflow: auto;
  max-height: 450px;
  background: ${color.White};
  padding: 24px 32px;
`;

const ModalBody = styled.h3`
  margin: 20px 0 10px 0 !important;
  color: ${color.DeleteLeadModalText};
`;

const PrimaryButton = styled(BasePrimaryButton)`
  &.ant-btn {
    margin: unset;
  }
`;

export default {
  ListCards,
  ContentInner,
  Card,
  CardContent,
  CardImage,
  CardTitle,
  CardCount,
  WrapperTitle,
  TemplateButton,
  ModalWrapper,
  ModalBody,
  PrimaryButton,
  ModalVerifyEmailFooter,
  EmailVerifyText,
};
