import qs from 'query-string';
import env from 'environments';
import {
  AUTO_LOGIN_CHECK,
  CHECK_EXPIRED_LINK,
  FORGET_PASSWORD,
  GET_NEW_TOKEN,
  LOGIN_USER,
  RESET_PASSWORD,
  SET_USER_EMAIL_LOGIN,
} from '../constants';

export const loginUser = (payload) => ({
  type: LOGIN_USER,
  promise: (client) => client.post(`${env.BASE_URL}/auth/signin`, payload),
});

export const autoLoginCheck = ({ username, token }) => {
  const query = qs.stringify({ username, token });

  return {
    type: AUTO_LOGIN_CHECK,
    promise: (client) => client.get(`${env.BASE_URL}/auth/token?${query}`),
  };
};

export const forgetPassword = (username, template) => ({
  type: FORGET_PASSWORD,
  promise: (client) =>
    client.get(`${env.BASE_URL}/auth/forgotpassword?username=${username}&template=${template}`),
});

export const getNewToken = () => ({
  type: GET_NEW_TOKEN,
  promise: (client) => client.get(`${env.BASE_URL}/auth/refreshtoken`),
});

export const resetPassword = (payload, newpassword) => ({
  type: RESET_PASSWORD,
  promise: (client) => client.post(`${env.BASE_URL}/auth/setpassword`, payload),
});

export const setUserEmail = (email) => ({
  type: SET_USER_EMAIL_LOGIN,
  payload: email,
});

export const checkExpiredLink = (payload) => ({
  type: CHECK_EXPIRED_LINK,
  promise: (client) =>
    client.get(
      `${env.BASE_URL}/auth/checkpasscode?username=${payload.username}&passcode=${payload.passcode}`,
      payload,
    ),
});
