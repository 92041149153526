//

import { DashboardNavBar } from 'modules/dashboard/components';

import S from './styles';

const DraftTemplate = ({ avatar, children, email, footer, username, onLogout }) => (
  <S.Wrapper>
    <DashboardNavBar email={email} avatar={avatar} username={username} onLogout={onLogout} />
    <S.Container>{children}</S.Container>
    <S.Footer>{footer}</S.Footer>
  </S.Wrapper>
);

DraftTemplate.defaultProps = {
  header: null,
};

export default DraftTemplate;
