import { Formik, Form } from 'formik';
import { Loader, PrimaryButton } from 'modules/v2/common/components';
import { MODAL_HEIGHT } from 'modules/v2/pages/Dentist/OrderGuideModal/constants';
import { DentistGuideDetailsForm } from 'modules/onboard/components';
import { guideDentistDetailsValidationSchema as validationSchema } from 'modules/onboard/utils';
import S from './styles';

const PreflightView = ({ loading, onSubmit, guidePrefillFormData }) => {
  if (loading) {
    return <Loader height={MODAL_HEIGHT} title="Checking book" />;
  }

  const initialValues = {
    practiceName: guidePrefillFormData?.practiceName || '',
    practicePhone: guidePrefillFormData?.practicePhone || '',
    shippingAddress: {
      addressLine1: guidePrefillFormData?.practiceAddress?.addressLine1 || '',
      city: guidePrefillFormData?.practiceAddress?.city || '',
      state: guidePrefillFormData?.practiceAddress?.state || '',
      pincode: guidePrefillFormData?.practiceAddress?.pincode || '',
      country: guidePrefillFormData?.practiceAddress?.country || '',
    },
    practiceLogo: guidePrefillFormData?.practiceLogo || '',
    practiceWebsite: guidePrefillFormData?.practiceWebsite || '',
    practiceEmail: guidePrefillFormData?.practiceEmail || '',
    firstDentist: {
      id: 0,
      image: guidePrefillFormData?.frontCover?.[0]?.image || '',
      name: guidePrefillFormData?.frontCover?.[0]?.name || '',
      title: guidePrefillFormData?.frontCover?.[0]?.title || '',
    },
    frontCover: [],
  };

  const onSave = (values) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSave}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <S.ViewWrapper>
            <S.ViewContent>
              <DentistGuideDetailsForm hideButton />
            </S.ViewContent>

            <S.FooterContainer>
              <PrimaryButton htmlType="submit">Continue</PrimaryButton>
            </S.FooterContainer>
          </S.ViewWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default PreflightView;
