import { getInstance } from './instance';

const instance = getInstance();

const getLandingPageProfile = () => {
  const getUrl = 'landing-pages/profile';
  return instance.get(getUrl);
};

const saveLandingPageProfile = (params) => {
  const getUrl = 'landing-pages/profile';
  return instance.post(getUrl, params);
};
export { getLandingPageProfile, saveLandingPageProfile };
