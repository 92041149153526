import { useState } from 'react';

import { Typography, Space } from 'antd';
import { useQuery } from 'react-query';
import { compose } from 'redux';

import env from 'environments';
import { getBillingHistory } from 'modules/api';
import { ErrorState } from 'modules/common/components';
import { notification } from 'modules/common/utils';
import { BillingSideNav } from 'modules/dashboard/components';
import { withDashboardStore, withProfile } from 'modules/dashboard/containers';
import { DashboardTemplate } from 'modules/dashboard/templates';

import { BillingTable } from './components';
import S from './styles';

const { Text } = Typography;

export const Billing = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    data: { data: billingHistory, meta } = [],
    isLoading,
    isError: isPageError,
  } = useQuery(['getBillingHistory', page, perPage, startDate, endDate], getBillingHistory, {
    onError: (error) => {
      notification.error({
        description: error.response.data.errors?.[0] || error?.data?.message || 'Network Error',
      });
    },
  });

  const onTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const handlePerPageChange = (value) => {
    setPerPage(value);
  };

  const onDateRangeChange = (dates, dateRange) => {
    if (dates) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    } else {
      setStartDate('');
      setEndDate('');
    }
  };

  return (
    <DashboardTemplate
      sidebar={<BillingSideNav />}
      hasSidebar
      isError={isPageError}
      error={
        <ErrorState
          topMessage="Our system encountered an error while loading your billing information."
          bottomMessage={
            <>
              Please call our customer service at <b>{env.SUPPORT_PHONE}</b>.
            </>
          }
        />
      }
    >
      <S.TableWrap>
        <S.TopWrap>
          <Space direction="vertical">
            <S.Title>Invoice</S.Title>
            <Text>Access all your previous invoices.</Text>
          </Space>
        </S.TopWrap>
        <BillingTable
          billingHistory={billingHistory}
          meta={meta}
          onTableChange={onTableChange}
          handlePerPageChange={handlePerPageChange}
          perPage={perPage}
          onDateRangeChange={onDateRangeChange}
          isLoading={isLoading}
        />
      </S.TableWrap>
    </DashboardTemplate>
  );
};

export default compose(withProfile, withDashboardStore)(Billing);
