import { useState, useEffect } from 'react';
import { map, get } from 'lodash-es';
import { compose } from 'redux';
import { Select } from 'antd';
import { useQuery as useReactQuery } from 'react-query';

import { isInitial, isReady, isSucceeded } from 'store/status';
import { MyBooksSideNav, IntakeFormModal } from 'modules/dashboard/components';
import { pluralize } from 'modules/common/utils';
import { PrimaryBlackButton, Loader } from 'modules/common/components';
import { withAuth } from 'modules/auth/containers';
import {
  withBooksStore,
  withProfile,
  withUserCredits,
  withReleaseNotes,
} from 'modules/dashboard/containers';
import { DashboardTemplate } from 'modules/dashboard/templates';
import { fetchUserCredits, getShowCreditsButton } from 'modules/api';
import { DragAndRearrange } from './dragAndRearrangeBooks';
import { PaymentSteps } from '../PaymentSteps';
import { useQuery } from './utils';
import S from './styles';

const { Option } = Select;

export const BookCatalog = ({
  books,
  categories,
  credits,
  headshot,
  history,
  preferences,
  profile,
  getBooks,
  getCategories,
  getUserCredits,
  selectCategory,
  updatePreferences,
  uploadHeadshot,
}) => {
  const query = useQuery();

  const hasWelcomeFlag = query.get('action') === 'welcome';

  const { email, firstName, lastName, phone, isNewUser } = get(profile, 'data', {});
  const userName = [firstName, lastName].filter(Boolean).join(' ');
  const headshotId = get(headshot, ['data', '_id']);
  const isPreferencesUpdated = isSucceeded(preferences.status);
  const showIntakeForm =
    (isSucceeded(profile.status) && isNewUser && !isPreferencesUpdated) || hasWelcomeFlag;

  const [showPaymentSteps, setShowPaymentSteps] = useState(false);
  const [showBuyCreditsButton, setShowBuyCreditsButton] = useState(false);

  // After the preferences been updated we can hide the IntakeformModal
  if (isPreferencesUpdated && hasWelcomeFlag) {
    history.push('/');
  }

  let sortedBooks = books?.data || [];
  if (books?.data && books?.order) {
    const newBookData = [];
    books?.order?.forEach((o) => {
      const book = books?.data?.find((b) => b._id === o.bookId);
      if (book) {
        newBookData.push(book);
      }
    });
    if (newBookData.length) sortedBooks = newBookData;
  }

  useEffect(() => {
    if (isInitial(categories.status)) {
      getCategories();
    }
    getBooks();

    getUserCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (formFields) => {
    const data = {
      ...formFields,
      headshotId,
    };

    updatePreferences(data);
  };

  const handleCategoryChange = (categoryId) => {
    selectCategory(categoryId);
  };

  let creditsSubHeader = <S.Loading>Loading credits...</S.Loading>;
  let categoriesSubHeader = <S.Loading>Loading categories...</S.Loading>;
  let content = <Loader height="calc(100vh - 400px)" title="Loading Books" />;

  const {
    data: userBookCredit,
    isLoading: iscreditLoading,
    refetch: reactCreditQueryRefetch,
  } = useReactQuery(['getUserCredits'], fetchUserCredits);
  const bookCredit = userBookCredit?.data?.data?.credits;

  useReactQuery('getShowCreditsButton', getShowCreditsButton, {
    onSuccess: (results) => {
      setShowBuyCreditsButton(results);
    },
  });

  if (isSucceeded(credits.status)) {
    creditsSubHeader = (
      <S.Credits isCreditEnough={credits.data >= 8}>
        {pluralize('Credit', 'Credits', credits.data)}
      </S.Credits>
    );
  }

  if (!iscreditLoading) {
    creditsSubHeader = (
      <S.Credits isCreditEnough={bookCredit >= 8}>
        {pluralize('Credit', 'Credits', bookCredit)}
      </S.Credits>
    );
  }

  if (isSucceeded(categories.status)) {
    categoriesSubHeader = (
      <S.Select value={categories.selected} onChange={handleCategoryChange}>
        {map(categories.data, ({ _id, name }) => (
          <Option key={_id} value={_id}>
            {name}
          </Option>
        ))}
      </S.Select>
    );
  }

  if (isReady(books.status)) {
    content = (
      <DragAndRearrange
        books={sortedBooks.sort((a, b) => b.isAllowed - a.isAllowed)}
        gridLayoutOrder={books?.data?.bookSortOrder}
      />
    );
  }

  const subHeader = (
    <S.SubHeader>
      <span>Choose a book to personalize</span>
      <S.SubHeaderWrapper>
        <S.CreditsWrapper>
          {creditsSubHeader}
          {showBuyCreditsButton && (
            <PrimaryBlackButton onClick={() => setShowPaymentSteps(true)}>
              Buy Credits
            </PrimaryBlackButton>
          )}
        </S.CreditsWrapper>
        <div>{categoriesSubHeader}</div>
      </S.SubHeaderWrapper>
    </S.SubHeader>
  );

  return (
    <DashboardTemplate sidebar={<MyBooksSideNav />} hasSidebar contentTitle="Book catalog">
      {subHeader}
      {content}
      {showIntakeForm && (
        <IntakeFormModal
          fields={{ name: userName, email, phone }}
          preferencesStatus={preferences.status}
          uploadStatus={headshot.status}
          uploadError={headshot.error}
          onSubmit={handleSubmit}
          onUpload={uploadHeadshot}
          isOpen
        />
      )}
      <PaymentSteps
        setShowPaymentSteps={setShowPaymentSteps}
        showPaymentSteps={showPaymentSteps}
        getUserCredits={reactCreditQueryRefetch}
      />
    </DashboardTemplate>
  );
};

export default compose(
  withAuth,
  withProfile,
  withUserCredits,
  withBooksStore,
  withReleaseNotes,
)(BookCatalog);
