import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

import env from 'environments';
import { Loader } from 'modules/v2/common/components';
import { getItem } from 'modules/v2/utils/legacy';
import { notification } from 'modules/v2/common/utils';

import S from './styles';

export const WaitPage = ({ getStep }) => {
  const [message, setMessage] = useState('Please wait while we confirm your payment');

  useEffect(() => {
    const socket = io(env.SOCKET_IO, { transports: ['websocket'], withCredentials: true });

    try {
      const subscription = JSON.parse(getItem('customerAndSubscriptionId'));
      const { customer, id } = subscription || {};
      const event = `payment_${customer?.email}_${id}`;
      socket.on(event, (data) => {
        if (data?.isApproved) {
          getStep(5);
        }
        setMessage(data);
      });

      socket.on('error', (error) => {
        notification.error({
          description: error || 'Something went wrong, please try after sometime',
        });
      });
    } catch (error) {
      notification.error({
        description: error || 'Something went wrong, please try after sometime',
      });
    }
    setTimeout(() => {
      if (!socket.connected) {
        getStep(5);
      }
    }, 30000);
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <S.Container>
      <Loader title="Loading Payment" description={message} />
    </S.Container>
  );
};
