const defaultOtpInputTheme = {
  field: {
    base: 'w-full rounded-md disabled:bg-neutral-200 disabled:text-neutral-400',
    input: {
      base:
        'text-sm w-[46px] h-[46px] py-[13px] px-[16px] text-sm border flex items-center focus:border-primary-500 focus:ring-0 focus:border-2',
      colors: {
        gray: `border-neutral-200 bg-white text-neutral-800
               focus:border-primary-500 active:border-primary-500`,
        failure:
          'border-error-500 border-2 text-red-500 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500',
      },
    },
  },
};

export { defaultOtpInputTheme };
