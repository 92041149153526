import { useQuery } from 'react-query';

import { getAppConfig } from 'modules/api';
import { getRouteReferralMarketingMagazineEditor } from 'modules/v2/routes/navigation';
import { Loader } from 'modules/v2/common/components';
import { BookLoadIcon } from 'modules/v2/common/components/SvgIcon';

const RedirectReferralMarketing = ({ history }) => {
  useQuery('getAppConfig', getAppConfig, {
    staleTime: 1 * 60 * 60 * 1000,
    onSuccess: (data) => {
      const { MONTH } = data;
      history.replace(getRouteReferralMarketingMagazineEditor(MONTH));
    },
  });

  return (
    <Loader
      height="calc(50vh - 50px)"
      icon={<BookLoadIcon />}
      title="Loading magazine"
      description="Please wait while you are beign redirect"
    />
  );
};

export default RedirectReferralMarketing;
