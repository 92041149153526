import { get, keys, reduce } from 'lodash-es';

import { checkPendingSave } from 'modules/v2/editor/utils';

export const checkPendingState = (changed, saved) => {
  const reducer = (acc, key) => {
    if (acc) {
      return true;
    }

    const changedValue = get(changed, key, null);
    const savedValue = get(saved, key, null);

    if (typeof changedValue === 'object' && changedValue !== null) {
      return checkPendingState(changedValue, savedValue);
    }

    return checkPendingSave(changedValue, savedValue);
  };

  return reduce(keys(changed), reducer, false);
};
