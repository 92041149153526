import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import environments from 'environments';
import { getCurrentTimeZone } from 'modules/v2/common/utils/timezone';
import moment from 'moment';
import { DateTime } from 'luxon';

// TODO: Refactor this file to use only one library (dayjs or luxon)
// TODO: Improve the name of the functions to DMP 2.0
// TODO: Remove the unused functions
// TODO: Improve the code quality

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY - HH:mm';
const DEFAULT_DATE_FORMAT_IN_LUXON = 'LL/dd/yyyy - HH:mm';

export const formatDate = (date, format = DEFAULT_DATE_FORMAT) => dayjs(date).format(format);

export const formatDateWithTimeZone = (
  date,
  format = DEFAULT_DATE_FORMAT,
  newTimeZone = getCurrentTimeZone,
) =>
  dayjs(date)
    .tz(newTimeZone)
    .format(format);

export const currentYear = new Date().getFullYear();

export const getPreviousUnit = (date, unit = 'month') => moment(date).subtract(1, unit);

export const getNextUnit = (date, unit = 'month') => moment(date).add(1, unit);

export const isBefore = (date) => dayjs(date).isBefore(new Date(), 'day');

export const formatDateTime = (date, char = '-') => {
  return dayjs(date).format(`MMMM DD, YYYY [${char}] h:mm a`);
};

// June 27, 2023
export const formatDateTime2 = (date) => {
  return dayjs(date).format('MMMM DD, YYYY');
};

// June 27
export const formatDateTime3 = (date) => {
  return dayjs(date).format('MMMM DD');
};

// Dec 05, 2023 - 08:39
export const formatDateTime4 = (date) => {
  return dayjs(date).format('MMM DD, YYYY - HH:mm');
};

// Jan 22, 2024
export const formatDateTime5 = (date) => {
  return dayjs(date).format('MMM DD, YYYY');
};

export const getCurrentMonth = () => environments.RMM_CURRENT_MONTH || moment().format('MMMM');

export const getCurrentMonthShort = () => environments.RMM_CURRENT_MONTH || moment().format('MMM');

export const getCurrentYear = () => moment().format('YYYY');

export const getLastMonthShort = () =>
  moment()
    .subtract(1, 'month')
    .format('MMM');

export const formatDateWithTZFromIso = (
  date,
  format = DEFAULT_DATE_FORMAT_IN_LUXON,
  newTimeZone = getCurrentTimeZone,
) => {
  const formatedDate = DateTime.fromISO(date)
    .setZone(newTimeZone)
    .toFormat(format);
  return formatedDate;
};
