import React from 'react';
import { Slide, toast } from 'react-toastify';

import Notification from 'modules/v2/common/components/Notification';
import { notifyDatadog } from 'modules/v2/common/utils/datadogNotifyier';
import {
  DEFAULT_AUTO_CLOSE_MS,
  TYPE_ERROR,
  TYPE_SUCCESS,
  TYPE_WARNING,
} from 'modules/v2/common/utils/variables';
import { SuccessToastIcon, ErrorToastIcon } from 'modules/v2/common/components/SvgIcon';

export function setToastDescription(typeToast, descriptionToast) {
  const icon = {
    [TYPE_SUCCESS]: (
      <div className="flex items-center">
        <SuccessToastIcon className="rounded-full bg-success-400 mr-2" />
        <span className="whitespace-nowrap">{descriptionToast}</span>
      </div>
    ),
    [TYPE_ERROR]: (
      <div className="flex items-center">
        <ErrorToastIcon className="mr-2" />
        <span className="whitespace-nowrap">{descriptionToast}</span>
      </div>
    ),
  };

  return icon[typeToast] || descriptionToast;
}

const notify = ({
  title,
  description,
  autoClose = DEFAULT_AUTO_CLOSE_MS,
  position = toast.POSITION.BOTTOM_CENTER,
  transition = Slide,
  type,
  sendToServer = false,
}) => {
  const content = { description };

  content.description = setToastDescription(type, description);

  const element = React.createElement(Notification, content);

  if (sendToServer || type === TYPE_ERROR) {
    notifyDatadog({
      title,
      description,
      type,
    });
  }

  return toast(element, {
    className: type,
    position,
    transition,
    autoClose,
  });
};

export const notification = {
  success: (args) => notify({ ...args, type: TYPE_SUCCESS }),
  error: (args) => notify({ ...args, type: TYPE_ERROR }),
  warning: (args) => notify({ ...args, type: TYPE_WARNING }),
};
