import { createContext, useState } from 'react';
import { nanoid } from 'nanoid';
import { compose } from 'redux';
import { find, get, isEmpty, map } from 'lodash-es';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  MoreOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Checkbox, Popover } from 'antd';

import AntdTable from 'modules/common/components/Table';
import {
  deleteLeads,
  bulkLeadsDelete,
  downloadLeads,
  getEmailSegments,
  getLeadsList,
  getStatesByCountryName,
  importLeads,
  saveLeads,
  updateLeads,
} from 'modules/api';

import {
  ErrorState,
  Modal,
  NoContent,
  PrimaryButton,
  Search,
  SecondaryButton,
  TertiaryButton,
} from 'modules/common/components';
import LeadAction from 'modules/dashboard/pages/Leads/components/LeadsAction';
import { DashboardTemplate } from 'modules/dashboard/templates';

import EditLeads from 'modules/dashboard/pages/Leads/components/EditLeads';
import { notification } from 'modules/common/utils/notify';
import { withProfile } from 'modules/dashboard/containers';
import { formatDate } from 'modules/common/utils';
import { element } from 'modules/common/utils/element';

import S from './styles';
import ImportLeads from './components/ImportLeads';
import ImportedLeadsFix from './components/ImportedLeadsFix';
import SegmentsLeads from './components/SegmentsLeads';
import DeleteLeads from './components/DeleteLeads';
import { removeNullOrEmptyProps, serialize } from './utils';

export const LeadsFixContext = createContext(null);

const Leads = ({ profile }) => {
  const [fixLeadsImported, setFixLeadsImported] = useState(false);
  const queryClient = useQueryClient();
  const memberEmail = get(profile, 'data.email');
  const [sortBy, setSortBy] = useState('');
  const [page, setPage] = useState(1);
  const [isError, setIsError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [hideActionToolTip, setHideActionToolTip] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newLead, setNewLead] = useState({});
  const [newImportLead, setNewImportLead] = useState({});
  const [segmentList, setSegmentList] = useState([]);
  const [isAllSegments, setIsAllSegments] = useState(false);
  const [current, setCurrent] = useState();
  const [disable, setDisabled] = useState(true);
  const [loadingTitle, setLoadingTitle] = useState('Loading Leads');
  const [loaderDescription, setLoaderDescription] = useState('Please wait while Loading...');
  const [filters, setFilters] = useState({});
  const [selectedSegments, setSelectedSegments] = useState([]);

  const [noSavedLeads, setNoSavedLeads] = useState([]);
  const perPage = 10;

  const {
    isError: isLeadError,
    isFetching: isLeadsFetching,
    data: leads,
  } = useQuery(
    ['gettingLeads', { perPage, page, sortBy, filters }],
    () =>
      getLeadsList(perPage, page, sortBy, serialize(removeNullOrEmptyProps(filters), 'filters')),
    { keepPreviousData: true },
  );

  const initalLeadFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    segments: [],
  };

  let leadsData = get(leads, 'data.data');
  if (leadsData) {
    leadsData = leadsData?.map((lead) => {
      if (lead.address) {
        /* eslint-disable no-param-reassign */
        Object.assign(lead, lead.address);
        delete lead.address;
      }
      return lead;
    });
  }
  const leadsPagination = get(leads, 'data.meta', {});
  const { total, lastPage } = leadsPagination;
  const { data: segmentsList } = useQuery(['gettingSegments'], getEmailSegments);
  const segmentsData = get(segmentsList, 'data');
  const Canada = 'Canada';
  const USA = 'USA';
  const { data: states } = useQuery(['getStatesByCountryName', { Canada, USA }], () =>
    getStatesByCountryName(Canada, USA),
  );
  const [checkAll, setCheckAll] = useState(false);
  const [boxChecked, setBoxChecked] = useState(false);
  const [headChecked, setHeadChecked] = useState(false);

  const selectAll = () => {
    setIsAllSegments(false);
    setCheckAll(!checkAll);
    setBoxChecked(!boxChecked);
    setHeadChecked(!checkAll);

    leadsData = leadsData.map((lead) => {
      /* eslint-disable no-param-reassign */
      lead.isSelected = !checkAll;
      return lead;
    });
  };

  const handleCancelLeadsFix = () => {
    setFixLeadsImported(false);
    queryClient.invalidateQueries('gettingLeads');
  };

  const selectBox = (position, value) => {
    if (!value.currentTarget.checked) {
      setCheckAll(false);
    }
    setBoxChecked(!boxChecked);
    leadsData[position].isSelected = value.currentTarget.checked;

    const showHeader = leadsData.some((selected) => selected.isSelected);

    if (showHeader) {
      setHeadChecked(true);
      setIsAllSegments(false);
    } else {
      setHeadChecked(false);
    }
  };

  const { mutateAsync: getCSVLeadsMutation, isLoading: isExportLoading } =
    useMutation(downloadLeads);

  const getLeadRequest = (lead) => {
    const leadRequest = {};
    const address = {};
    address.address1 = lead.address1;
    address.address2 = lead.address2;
    address.city = lead.city;
    address.country = lead.country;
    address.zip = lead.zip;
    address.state = lead.state;
    leadRequest.address = address;
    leadRequest.email = lead.email;
    leadRequest.firstName = lead.firstName;
    leadRequest.lastName = lead.lastName;
    leadRequest.phone = lead.phone;
    leadRequest.segments = lead.segments;
    leadRequest.allSegments = lead.allSegments;
    return leadRequest;
  };

  const getCSVLeads = () => {
    setLoadingTitle('Exporting Your Leads');
    setLoaderDescription('Please wait while we exporting your leads...');
    getCSVLeadsMutation()
      .then((data) => {
        element(data, 'Leads');
      })
      .catch(() => {
        toast.dismiss();
        toast.error(
          'Sorry, an error occurs while exporting your leads. Please try again or contact our customer support.',
          {
            className: 'Errortoaster',
            position: toast.POSITION.TOP_RIGHT,
          },
        );
      });
  };

  const { mutateAsync, isLoading: isEditLoading } = useMutation(updateLeads);
  const { mutateAsync: saveLeadsMutation, isLoading: isSaveLoading } = useMutation(saveLeads);
  const { mutateAsync: deleteLeadsMutation, isLoading: isDeleteLoading } = useMutation(deleteLeads);
  const { mutateAsync: bulkLeadsDeleteMutation, isLoading: isBulkDeleteLoading } =
    useMutation(bulkLeadsDelete);
  const { mutate: importLeadsMutation, isLoading: isImportLoading } = useMutation(importLeads, {
    onSuccess: ({ data }) => {
      const { invalidList } = data || {};

      if (Array.isArray(invalidList) && invalidList.length) {
        const invalidListWithIds = invalidList.map(({ firstName, lastName, email, phone }) => ({
          firstName,
          lastName,
          email,
          phone,
          key: nanoid(),
        }));

        setNoSavedLeads(invalidListWithIds);
        setFixLeadsImported(true);
        queryClient.invalidateQueries('gettingLeads');
        return;
      }

      setIsAllSegments(false);
      queryClient.invalidateQueries('gettingLeads');
    },
    onError: () => {
      notification.error({
        description:
          'The system has encountered an error. Please try again or contact customer support!!!',
      });
    },
  });

  const onEditLead = async (lead) => {
    if (lead.segments.length && !lead.allSegments) {
      if (lead.segments && Object.keys(lead.segments[0]).length > 0 && !lead.allSegments) {
        lead.segments = lead.segments.map((l) => l.id);
      }
    }
    setLoadingTitle('Updating Your Lead');
    setLoaderDescription('Please wait while we Update your lead.');
    const leadRequest = { ...{}, ...getLeadRequest(lead) };
    leadRequest.id = lead.id;
    const allSegmentSelected = lead.allSegments;
    if (allSegmentSelected) {
      leadRequest.allSegments = true;
      leadRequest.segments = [];
    } else {
      leadRequest.allSegments = false;
    }
    await mutateAsync(leadRequest)
      .then(() => {
        queryClient.invalidateQueries('gettingLeads');
        notification.success({
          description: 'Lead updated successfully',
          autoClose: 3000,
        });
      })
      .catch(() => {
        notification.error({
          description:
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const onMultipleDeleteLeads = async (leadsId) => {
    setLoadingTitle('Deleting Your Lead');
    setLoaderDescription('Please wait while we delete your lead.');

    await bulkLeadsDeleteMutation(leadsId).catch(() => {
      notification.error({
        description:
          'The system has encountered an error. Please try again or contact customer support!!!',
        autoClose: 3000,
      });
    });
  };

  const deleteMultipleLeads = async () => {
    setDeleteModal(false);
    const selectedleads = checkAll ? leadsData : leadsData.filter((lead) => lead.isSelected);
    const leadIds = selectedleads.map((lead) => lead.id);

    await onMultipleDeleteLeads(leadIds);

    setCheckAll(false);
    setHeadChecked(false);
    setBoxChecked(false);

    leadsData = leadsData.map((lead) => {
      lead.isSelected = false;
      return lead;
    });

    notification.success({
      title: 'Delete lead',
      description: 'Lead has been deleted',
    });

    queryClient.invalidateQueries('gettingLeads');
  };

  const onDeleteAction = () => {
    setDeleteModal(true);
  };

  const onMultipleEditLeads = async (lead) => {
    setLoadingTitle('Updating Your Leads');
    setLoaderDescription('Please wait while we Update your leads...');

    await mutateAsync(lead).catch(() => {
      notification.error({
        description:
          'The system has encountered an error. Please try again or contact customer support!!!',
        autoClose: 3000,
      });
    });
  };

  const updateMultipleLeads = async () => {
    const selectedleads = checkAll ? leadsData : leadsData.filter((lead) => lead.isSelected);
    const allPromises = [];
    selectedleads.map((lead) => {
      const leadRequest = { ...{}, ...lead }; // TODO
      if (isAllSegments) {
        leadRequest.allSegments = true;
        leadRequest.segments = [];
      } else {
        leadRequest.allSegments = false;
        leadRequest.segments = segmentList;
      }
      leadRequest.bookId = 'testBook123';
      allPromises.push(onMultipleEditLeads(leadRequest));
      return lead;
    });

    notification.success({
      description: 'Lead(s) move to segments successfully',
      autoClose: 3000,
    });
    setCheckAll(false);
    setHeadChecked(false);
    setBoxChecked(false);
    setIsAllSegments(false);
    leadsData = leadsData.map((lead) => {
      /* eslint-disable no-param-reassign */
      lead.isSelected = false;
      return lead;
    });
    await Promise.all(allPromises);
    queryClient.invalidateQueries('gettingLeads');
  };

  const onSaveLead = async () => {
    setModalVisible(false);
    setLoadingTitle('Adding Your Lead');
    setLoaderDescription('Please wait while we add your lead.');
    const leadRequest = { ...{}, ...getLeadRequest(newLead) };
    const allSegmentSelected = leadRequest?.allSegments;
    if (allSegmentSelected) {
      leadRequest.segments = [];
    } else {
      leadRequest.allSegments = false;
    }
    saveLeadsMutation({ ...leadRequest, bookId: 11 })
      .then(() => {
        notification.success({
          description: 'Lead created successfully',
          autoClose: 3000,
        });
        queryClient.invalidateQueries('gettingLeads');
      })
      .catch(() => {
        notification.error({
          description:
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const handleImportLeads = () => {
    setImportVisible(false);
    const formData = new FormData();
    formData.append('bookId', 'Groucho');
    formData.append('customerEmail', memberEmail);
    // const checkLead = isAllSegments;
    const newSegmentsList = [];

    segmentList.forEach((segment, index) => {
      newSegmentsList.push(segment);
      formData.append(`segments[${index}]`, segment);
    });

    formData.append('allSegments', isAllSegments);
    setSelectedSegments(isAllSegments ? [] : newSegmentsList);

    formData.append('file', newImportLead.originFileObj);

    setLoadingTitle('Importing Leads');
    setLoaderDescription('Please wait while we import your lead.');

    importLeadsMutation(formData);
  };

  const deleteLead = (lead) => {
    setLoadingTitle('Deleting Your Lead');
    setLoaderDescription('Please wait while we Delete your lead.');

    deleteLeadsMutation(lead?.id)
      .then(() => {
        queryClient.invalidateQueries('gettingLeads');
        notification.success({
          description: 'Lead has been deleted',
          autoClose: 3000,
        });
      })
      .catch(() => {
        notification.error({
          description:
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const outLineClick = (leadId) => {
    setHideActionToolTip(!hideActionToolTip);
    setCurrent(leadId);
  };

  const addLeadClick = () => {
    setModalVisible(true);
    setHideActionToolTip(false);
  };

  const importLeadClick = () => {
    setImportVisible(true);
    setHideActionToolTip(false);
  };

  const buttonValidation = () => {
    if (
      newLead &&
      newLead.firstName &&
      newLead.lastName &&
      newLead.email &&
      newLead.phone &&
      newLead.address1 &&
      newLead.city &&
      newLead.state &&
      newLead.zip &&
      newLead.country &&
      newLead.segments &&
      newLead.segments.length &&
      !newLead.allSegments &&
      !newLead.segments.some((segment) =>
        typeof segment === 'number'
          ? segmentsData.find((s) => s.id === segment)?.name === 'RM Mailing List'
          : segment.name === 'RM Mailing List',
      )
    ) {
      setIsError(false);
      setDisabled(false);
    } else if (
      newLead &&
      newLead.firstName &&
      newLead.lastName &&
      newLead.email &&
      newLead.phone &&
      newLead.address1 &&
      newLead.city &&
      newLead.state &&
      newLead.zip &&
      newLead.country &&
      (newLead.allSegments ||
        (!newLead.allSegments &&
          newLead.segments.some((segment) =>
            typeof segment === 'number'
              ? segmentsData.find((s) => s.id === segment)?.name === 'RM Mailing List'
              : segment.name === 'RM Mailing List',
          )))
    ) {
      setIsError(false);
      setDisabled(false);
    } else {
      setDisabled(true);
      setIsError(true);
    }
  };

  const formatSegments = (segments) => {
    if (!segments.length) return 'All Segments';
    if (!segments) return '-';
    return segments.map((l) => l.name).join(', ');
  };

  const isChecked = !headChecked && !checkAll;
  const leadsParsed = map(leadsData, ({ segments, ...params }) => ({
    ...params,
    segments: formatSegments(segments),
  }));
  const countries = ['USA', 'Canada'];
  const stateList = get(states, 'data.data', {});
  const canada = [];
  const usa = [];
  Object.keys(stateList).forEach((x) => {
    if (stateList[x].country === 'Canada') {
      canada.push(stateList[x].state);
    } else {
      usa.push(stateList[x].state);
    }
  });

  const statesObject = {
    Canada: canada,
    USA: usa,
  };

  const pageChange = (newpage) => {
    setPage(newpage);
    setCheckAll(false);
    setIsAllSegments(false);
    setHeadChecked(false);
    setBoxChecked(false);
  };
  const pagination = {
    defaultCurrent: page,
    pageSize: perPage,
    total,
    lastPage,
    currentPage: page,
    onChange: pageChange,
  };
  const getOriginalLead = (leadId) => find(leadsData, ({ id }) => id === leadId);
  const tableColumns = [
    {
      title: <S.CheckBox checked={(boxChecked || headChecked) && checkAll} onChange={selectAll} />,
      key: 'checkbox',
      width: '30px',
      render: (leadData) => {
        const checked = find(leadsData, ({ id }) => id === leadData?.id)?.isSelected;
        return (
          <S.CheckBox
            checked={checked}
            onChange={(value) => selectBox(leadsParsed.indexOf(leadData), value)}
          />
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b, sortDirection) => {
        setSortBy(`[firstName]:${sortDirection.slice(0, -3)}`);
        return a.firstName.localeCompare(b.firstName);
      },

      render: (value, { lastName }) => `${value} ${lastName}`,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b, sortDirection) => {
        setSortBy(`[email]:${sortDirection.slice(0, -3)}`);
        return a.email.localeCompare(b.email);
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b, sortDirection) => {
        setSortBy(`[phone]:${sortDirection.slice(0, -3)}`);
        return a.phone.localeCompare(b.phone);
      },
    },
    {
      title: 'Segments',
      dataIndex: 'segments',
      key: 'segments',
      sorter: (a, b, sortDirection) => {
        setSortBy(`[segments]:${sortDirection.slice(0, -3)}`);
        return a.segments.localeCompare(b.segments);
      },
    },
    {
      title: 'valid?',
      dataIndex: 'isValid',
      key: 'isValid',
      render: (isValid) => (isValid ? 'Yes' : 'No'),
      sorter: (a, b, sortDirection) => {
        setSortBy(`[isValid]:${sortDirection.slice(0, -3)}`);
        return Number(a.isValid) - Number(b.isValid);
      },
    },
    {
      title: 'Unsubscribed?',
      dataIndex: 'unsubscribed',
      key: 'unsubscribed',
      render: (unsubscribed) => (unsubscribed ? 'Yes' : 'No'),
      sorter: (a, b, sortDirection) => {
        setSortBy(`[unsubscribed]:${sortDirection.slice(0, -3)}`);
        return Number(a.unsubscribed) - Number(b.unsubscribed);
      },
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <span>{formatDate(createdAt, 'MM-DD-YYYY')}</span>,
      sorter: (a, b, sortDirection) => {
        setSortBy(`[createdAt]:${sortDirection.slice(0, -3)}`);
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: ({ id }) => (
        <Popover
          placement="leftTop"
          trigger="click"
          content={
            <LeadAction
              hide={() => setHideActionToolTip(false)}
              data={getOriginalLead(id)}
              onSave={onEditLead}
              onDelete={deleteLead}
              segmentsData={segmentsData}
            />
          }
          visible={current === id && hideActionToolTip}
        >
          <MoreOutlined rotate={90} onClick={() => outLineClick(id)} />
        </Popover>
      ),
    },
  ];

  const handleClearFilters = () => {
    setFilters({});
  };

  const pushFilter = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const hasFilters = () => {
    return Object.keys(filters).length > 0;
  };
  return (
    <DashboardTemplate
      isError={isLeadError}
      isLoading={
        isEditLoading ||
        isSaveLoading ||
        isDeleteLoading ||
        isImportLoading ||
        isExportLoading ||
        isBulkDeleteLoading
      }
      hasSidebar
      contentTitle="Leads"
      loadingTitle={loadingTitle}
      noDataTitle="No Leads Available"
      loaderDescription={loaderDescription}
      error={
        <ErrorState
          topMessage={
            <>
              Sorry, our server encounters an error while processing and loading your Leads. <br />
              Please try again later.
            </>
          }
          bottomMessage={
            <>
              If the issue continues, please let our team know by{' '}
              <a href="/help-center">clicking here.</a>
            </>
          }
        />
      }
    >
      <S.MainWrapper>
        <Search
          placeholder="Name or Email"
          onSearch={(value) => pushFilter('email', value)}
          enterButton
        />
        <S.HeaderWrapper>
          <S.SwitchContainer>
            <S.Select
              placeholder="Select some Segments"
              mode="multiple"
              onChange={(selected) => pushFilter('segments', selected)}
            >
              {segmentsData &&
                segmentsData.map((segment) => {
                  return (
                    <S.Option key={segment.id} value={segment.id}>
                      {segment.name}
                    </S.Option>
                  );
                })}
              )
            </S.Select>
            <div>
              <span>Valid Emails</span>
              <S.Switch
                name="enabled"
                id="enabled"
                checked={filters?.isValid ?? false}
                onClick={(checked) => pushFilter('isValid', checked)}
              />
            </div>
            <div>
              <span>Unsubscribed Leads</span>
              <S.Switch
                name="enabled"
                id="enabled"
                checked={filters?.unsubscribed ?? false}
                onChange={(checked) => pushFilter('unsubscribed', checked)}
              />
            </div>
            <SecondaryButton onClick={handleClearFilters} size="small" disabled={!hasFilters()}>
              Clear Filter
            </SecondaryButton>
          </S.SwitchContainer>

          {isChecked ? (
            <S.BtnGroup>
              <TertiaryButton onClick={addLeadClick}>
                Add Lead <PlusOutlined />
              </TertiaryButton>
              <SecondaryButton onClick={importLeadClick}>
                <S.ButtonText>
                  Import Leads
                  <CloudDownloadOutlined />
                </S.ButtonText>
              </SecondaryButton>
              {!isEmpty(leadsData) && (
                <PrimaryButton onClick={getCSVLeads}>
                  <S.ButtonText>
                    Export Leads <CloudUploadOutlined />
                  </S.ButtonText>
                </PrimaryButton>
              )}
            </S.BtnGroup>
          ) : (
            <>
              <S.BtnGroup itemsCenter>
                <S.allSegmentsCheck>
                  <Checkbox
                    name="allSegments"
                    id="allSegments"
                    onChange={(e) => {
                      setIsAllSegments(e.target.checked);
                    }}
                  >
                    All Segments
                  </Checkbox>
                </S.allSegmentsCheck>
                <S.SegmentWrapper>
                  <S.Label>Add To</S.Label>
                  <SegmentsLeads
                    segmentsData={segmentsData}
                    getSegmentList={setSegmentList}
                    isAllSegments={isAllSegments}
                  />
                </S.SegmentWrapper>
                <S.SegmentButton
                  primary
                  onClick={updateMultipleLeads}
                  disabled={!segmentList || (!segmentList.length && !isAllSegments)}
                >
                  <SaveOutlined />
                </S.SegmentButton>
                <S.SegmentButton onClick={onDeleteAction}>
                  <DeleteOutlined />
                </S.SegmentButton>
              </S.BtnGroup>
            </>
          )}
        </S.HeaderWrapper>
        {isEmpty(leadsData) ? (
          <NoContent message="No Leads Found" />
        ) : (
          <AntdTable
            size="small"
            rowKey="id"
            loading={isLeadsFetching}
            columns={tableColumns}
            dataSource={leadsParsed}
            pagination={pagination}
          />
        )}
        <LeadsFixContext.Provider value={{ noSavedLeads, setNoSavedLeads }}>
          <ImportedLeadsFix
            isOpen={fixLeadsImported}
            setIsOpen={setFixLeadsImported}
            onCancel={handleCancelLeadsFix}
            data={noSavedLeads}
            selectedSegments={selectedSegments}
          />
        </LeadsFixContext.Provider>
        <Modal
          title="Add Lead"
          isOpen={modalVisible}
          maskClosable={false}
          onConfirm={onSaveLead}
          onCancel={() => setModalVisible(false)}
          okText="Save Lead"
          okButtonProps={{ disabled: disable }}
        >
          <EditLeads
            data={initalLeadFormData}
            isError={isError}
            getData={setNewLead}
            segmentsData={segmentsData}
            disabled={buttonValidation}
            countries={countries}
            states={statesObject}
          />
        </Modal>
        <Modal
          title="Import Lead(s)"
          isOpen={importVisible}
          onConfirm={handleImportLeads}
          onCancel={() => setImportVisible(false)}
          okText="Import Lead(s)"
          okButtonProps={{
            disabled: !Object.keys(newImportLead).length || (!segmentList.length && !isAllSegments),
          }}
        >
          <ImportLeads
            getData={setNewImportLead}
            segmentsData={segmentsData}
            getSegmentList={setSegmentList}
            getIsAllSegments={setIsAllSegments}
          />
        </Modal>
        <Modal
          title="Delete Lead(s)"
          isOpen={deleteModal}
          onConfirm={deleteMultipleLeads}
          onCancel={() => setDeleteModal(false)}
          okText="Delete Lead(s)"
          cancelText="Cancel"
        >
          <DeleteLeads />
        </Modal>
      </S.MainWrapper>
    </DashboardTemplate>
  );
};

export default compose(withProfile)(Leads);
