const RouteSignIcon = ({ width = 16, height = 19, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.625 1.1665C8.625 0.821326 8.34518 0.541504 8 0.541504C7.65482 0.541504 7.375 0.821326 7.375 1.1665V2.4165H3.58665C3.03868 2.4165 2.7647 2.4165 2.51327 2.49834C2.40235 2.53444 2.29553 2.58209 2.19455 2.6405C1.96568 2.7729 1.78263 2.97675 1.41651 3.38447C0.707596 4.17395 0.353133 4.56869 0.267796 5.03C0.230722 5.23041 0.230722 5.43593 0.267796 5.63634C0.353133 6.09765 0.707594 6.49239 1.41651 7.28187C1.78262 7.68958 1.96568 7.89344 2.19455 8.02584C2.29553 8.08425 2.40235 8.1319 2.51327 8.168C2.7647 8.24984 3.03868 8.24984 3.58665 8.24984H7.375V9.9165H3.41667C2.24628 9.9165 1.66109 9.9165 1.24072 10.1974C1.05873 10.319 0.902482 10.4752 0.780884 10.6572C0.5 11.0776 0.5 11.6628 0.5 12.8332C0.5 14.0036 0.5 14.5887 0.780884 15.0091C0.902482 15.1911 1.05873 15.3474 1.24072 15.469C1.66109 15.7498 2.24628 15.7498 3.41667 15.7498H7.375V17.2082H6.33333C5.98816 17.2082 5.70833 17.488 5.70833 17.8332C5.70833 18.1783 5.98816 18.4582 6.33333 18.4582H9.66667C10.0118 18.4582 10.2917 18.1783 10.2917 17.8332C10.2917 17.488 10.0118 17.2082 9.66667 17.2082H8.625V15.7498H12.4133C12.9613 15.7498 13.2353 15.7498 13.4867 15.668C13.5976 15.6319 13.7045 15.5843 13.8054 15.5258C14.0343 15.3934 14.2174 15.1896 14.5835 14.7819C15.2924 13.9924 15.6469 13.5977 15.7322 13.1363C15.7693 12.9359 15.7693 12.7304 15.7322 12.53C15.6469 12.0687 15.2924 11.674 14.5835 10.8845C14.2174 10.4768 14.0343 10.2729 13.8054 10.1405C13.7045 10.0821 13.5976 10.0344 13.4867 9.99834C13.2353 9.9165 12.9613 9.9165 12.4133 9.9165H8.625V8.24984H12.5833C13.7537 8.24984 14.3389 8.24984 14.7593 7.96895C14.9413 7.84736 15.0975 7.6911 15.2191 7.50912C15.5 7.08875 15.5 6.50356 15.5 5.33317C15.5 4.16279 15.5 3.57759 15.2191 3.15722C15.0975 2.97524 14.9413 2.81899 14.7593 2.69739C14.3389 2.4165 13.7537 2.4165 12.5833 2.4165H8.625V1.1665Z"
      fill={fill}
    />
  </svg>
);

export default RouteSignIcon;
