import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import LogoImg from 'assets/images/logo/logo_black.svg';
import Sidebg from 'assets/images/logo/side_bg.png';
import { Footer } from 'modules/common/components';

import S from './index.styled';

const AuthenticationTemplate = ({ children, noLogo, noBackground, noFooter }) => (
  <>
    <S.LoginWrapper noBackground={noBackground}>
      <S.Container noBackground={noBackground}>
        <S.FormContent noLogo={noLogo}>
          {!noLogo && (
            <S.LoginLogo>
              <Link to="/">
                <img src={LogoImg} alt="Authorify Logo" />
              </Link>
            </S.LoginLogo>
          )}
          {children}
        </S.FormContent>
        {!noBackground && (
          <S.Sidebar>
            <img src={Sidebg} alt="" />
          </S.Sidebar>
        )}
      </S.Container>
    </S.LoginWrapper>
    {!noFooter && <Footer />}
  </>
);

AuthenticationTemplate.defaultProps = {
  noLogo: false,
  noBackground: false,
  noFooter: false,
};

AuthenticationTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  noLogo: PropTypes.bool,
  noBackground: PropTypes.bool,
  noFooter: PropTypes.bool,
};

export default AuthenticationTemplate;
