import { isEmpty } from 'lodash-es';
import S from './styles';

const ConfirmationScreen = ({
  orderId: id,
  shippingAddress,
  contactPhone: phone,
  contactEmail: email,
}) => {
  let address = null;

  if (!isEmpty(shippingAddress)) {
    const {
      firstName = '',
      lastName = '',
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      country = '',
      pincode = '',
    } = shippingAddress;

    const fullName = `${firstName} ${lastName}`;
    const location = `${city}, ${state} ${pincode}`;

    address = (
      <S.AddressWrapper>
        <h4>Your shipping address is:</h4>
        <S.Address>
          <p>
            <strong>{fullName}</strong>
          </p>
          <p>{addressLine1}</p>
          {addressLine2 && <p>{addressLine2}</p>}
          <p>{location}</p>
          <p>{country}</p>
        </S.Address>
      </S.AddressWrapper>
    );
  }

  return (
    <S.Confirmation>
      <h4>Your order number is:</h4>
      <S.OrderNumber>{id}</S.OrderNumber>
      {address}
      <S.ContactInfo>
        <h4>Got Questions?</h4>
        <h4>Contact our support team at:</h4>
        <S.Phone>{phone}</S.Phone>
        <h4>{email}</h4>
      </S.ContactInfo>
    </S.Confirmation>
  );
};

export default ConfirmationScreen;
