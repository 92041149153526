import { withRouter } from 'react-router';
import { Menu } from 'antd';

import { isPilotCustomer, isSuperAdminCustomer, hasTripwireReportAccess } from 'modules/v2/utils';
import { NavItem, NavLink, NavSubMenu } from 'modules/v2/common/components/UIComponents';

import {
  // TO DO remove comments, pages and routes when the removal is final
  // getRouteReferralMarketingMailingList,
  getRouteReports,
  // getRouteReferralMarketingPages,
  // getSocialMediaTemplate,
  // getSocialMediaCampaigns,
  // getSocialMediaCampaignsCatalog,
  getRouteOffers,
  getAdminChangeMemberHubspotPassword,
  getRouteCreateOffers,
  // getSocialMediaConfiguration,
  // getRouteProductConfig,
  getRouteCoachMaintenance,
  getRouteProductMaintenance,
  // getRouteCustomLandingPage,
  getRouteOnboardingReport,
  getRouteTripwireSalesReport,
  getRouteCoachEmailReminder,
  getRouteEmailReminderRescheduler,
  getRouteRmMagazineReport,
  getRouteEmailCampaignReport,
  getRouteOffBoarding,
  // getRouteCustomersAdmin,
  getRouteLaunchOffers,
  // getRouteCustomerPropertiesList,
  // getRouteMembers360,
  getRouteOnboardingReportExport,
  getRouteEndMonth,
  getRouteBookDraftMaintenance,
  getRouteTeamMemberMeetings,
  getRouteTeamMemberPhoneCall,
  getRouteBookPreviews,
} from 'modules/v2/routes/admin/navigation';

const AdminSideNav = ({ location }) => (
  <Menu
    mode="inline"
    selectedKeys={location.pathname.split('/')}
    defaultOpenKeys={location.pathname.split('/')}
  >
    <NavSubMenu
      key="reports"
      title={
        <span>
          <span>Reports</span>
        </span>
      }
    >
      <NavItem key="reports">
        <NavLink to={getRouteReports()}>
          <span>Lead Metrics</span>
        </NavLink>
      </NavItem>
      {isPilotCustomer() ? (
        <NavItem key="onboarding-report">
          <NavLink to={getRouteOnboardingReport()}>
            <span>Onboarding Report</span>
          </NavLink>
        </NavItem>
      ) : null}
      {isPilotCustomer() ? (
        <NavItem key="rm-magazine-report">
          <NavLink to={getRouteRmMagazineReport()}>
            <span>RM Magazine Metrics</span>
          </NavLink>
        </NavItem>
      ) : null}
      {hasTripwireReportAccess() ? (
        <NavItem key="tripwire-report">
          <NavLink to={getRouteTripwireSalesReport()}>
            <span>Tripwire Upsell Sales Report</span>
          </NavLink>
        </NavItem>
      ) : null}
      {isPilotCustomer() ? (
        <NavItem key="email-campaign-report">
          <NavLink to={getRouteEmailCampaignReport()}>
            <span>Email Campaign Metrics</span>
          </NavLink>
        </NavItem>
      ) : null}
      ,
    </NavSubMenu>
    {/* {isPilotCustomer() ? (
      <NavItem key="members-360">
        <NavLink to={getRouteMembers360()}>

          <span>Members 360</span>
        </NavLink>
      </NavItem>
    ) : null} */}
    {/* TO DO remove comments, pages and routes when the removal is final */}
    {/* {isPilotCustomer()
      ? [
          <NavSubMenu
            key="social-media-campaign"
            title={
              <span>
                <IdcardOutlined>
                  <ShareAltOutlined />
                </IdcardOutlined>
                <span>Social Media Campaign</span>
              </span>
            }
          >
            <NavItem key="social-media-configuration">
              <NavLink to={getSocialMediaConfiguration()}>Social Media Configuration</NavLink>
            </NavItem>
            <NavItem key="templates">
              <NavLink to={getSocialMediaTemplate()}>Templates</NavLink>
            </NavItem>
            <NavSubMenu key="campaigns" title="Campaigns">
              <NavItem key="template-campaigns">
                <NavLink to={getSocialMediaCampaignsCatalog()}>Campaigns Catalog</NavLink>
              </NavItem>
              <NavItem key="active-campaigns">
                <NavLink to={getSocialMediaCampaigns()}>My Campaigns</NavLink>
              </NavItem>
            </NavSubMenu>
          </NavSubMenu>,
        ]
      : null} */}

    {/* TO DO remove comments, pages and routes when the removal is final */}

    {/* {isPilotCustomer()
      ? [
          <NavSubMenu
            key="referral-marketing"
            title={
              <span>
                <IdcardOutlined>
                  <ReadOutlined />
                </IdcardOutlined>
                <span>Referral Marketing</span>
              </span>
            }
          >
            <NavItem key="referral-marketing-pages">
              <NavLink to={getRouteReferralMarketingPages}>Referral Marketing Admin</NavLink>
            </NavItem>
            <NavItem key="referral-marketing-mailing-list">
              <NavLink to={getRouteReferralMarketingMailingList}>RM Mailing List</NavLink>
            </NavItem>
          </NavSubMenu>,
        ]
      : null} */}
    {/* {isPilotCustomer()
      ? [
          <NavItem key="customers">
            <NavLink to={getRouteCustomersAdmin()}>
              <span>Customers</span>
            </NavLink>
          </NavItem>,
        ]
      : null} */}
    {isPilotCustomer()
      ? [
        <NavItem key="coaches">
          <NavLink to={getRouteCoachMaintenance()}>
            <span>Coach Maintenance</span>
          </NavLink>
        </NavItem>,
      ]
      : null}
    {isPilotCustomer()
      ? [
        <NavItem key="coach-email">
          <NavLink to={getRouteCoachEmailReminder()}>
            <span>Coach Email Reminder</span>
          </NavLink>
        </NavItem>,
      ]
      : null}
    {isPilotCustomer()
      ? [
        <NavItem key="team-member-meeting">
          <NavLink to={getRouteTeamMemberMeetings}>
            <span>Team Member Meetings</span>
          </NavLink>
        </NavItem>,
      ]
      : null}
    {isPilotCustomer()
      ? [
        <NavItem key="team-member-phone-call">
          <NavLink to={getRouteTeamMemberPhoneCall}>
            <span>Team Member Phone Calls</span>
          </NavLink>
        </NavItem>,
      ]
      : null}
    {isPilotCustomer()
      ? [
        <NavItem key="email-reminder-rescheduler">
          <NavLink to={getRouteEmailReminderRescheduler}>
            <span>Email Reminder Rescheduler</span>
          </NavLink>
        </NavItem>,
      ]
      : null}
    {isPilotCustomer()
      ? [
        <NavItem key="offboarding" style={{ display: 'none' }}>
          <NavLink to={getRouteOffBoarding()}>
            <span>Offboarding</span>
          </NavLink>
        </NavItem>,
      ]
      : null}

    {/* TO DO remove comments, pages and routes when the removal is final */}

    {/* {isPilotCustomer()
      ? [
          <NavItem key="products-config">
            <NavLink to={getRouteProductConfig()}>
              <IdcardOutlined>
                <ShoppingCartOutlined />
              </IdcardOutlined>
              <span>Product Config</span>
            </NavLink>
          </NavItem>,
        ]
      : null} */}
    {isPilotCustomer()
      ? [
        <NavItem key="products">
          <NavLink to={getRouteProductMaintenance()}>
            <span>Product Maintenance</span>
          </NavLink>
        </NavItem>,
      ]
      : null}

    {/* TO DO remove comments, pages and routes when the removal is final */}

    {/* <NavItem key="change-properties-list">
      <NavLink to={getRouteCustomerPropertiesList()}>
        <span>Customer Property List</span>
      </NavLink>
    </NavItem> */}

    {/* TO DO remove comments, pages and routes when the removal is final */}

    {/* {isPilotCustomer()
      ? [
          <NavItem key="custom-landing-page">
            <NavLink to={getRouteCustomLandingPage}>
              <IdcardOutlined>
                <GlobalOutlined />
              </IdcardOutlined>
              <span>Custom Landing Page</span>
            </NavLink>
          </NavItem>,
        ]
      : null} */}

    <NavSubMenu key="offers" title={<span>Offers</span>}>
      {isPilotCustomer() ? (
        <NavItem key="create">
          <NavLink to={getRouteCreateOffers()}>
            <span>Create Offer</span>
          </NavLink>
        </NavItem>
      ) : null}
      <NavItem key="list">
        <NavLink to={getRouteOffers()}>
          <span>Offer Lists</span>
        </NavLink>
      </NavItem>
      <NavItem key="launch">
        <NavLink to={getRouteLaunchOffers()}>
          <span>Launch Offer</span>
        </NavLink>
      </NavItem>
    </NavSubMenu>

    {isSuperAdminCustomer() ? (
      <NavSubMenu key="RmmSuperAdmin" title={<span>Super Admin</span>}>
        <NavItem key="end-month">
          <NavLink to={getRouteEndMonth()}>
            <span>End Month</span>
          </NavLink>
        </NavItem>
        <NavItem key="onboarding-report-export">
          <NavLink to={getRouteOnboardingReportExport()}>
            <span>Sales Report Export</span>
          </NavLink>
        </NavItem>
        <NavItem key="book-draft-maintenance">
          <NavLink to={getRouteBookDraftMaintenance()}>
            <span>Book Draft Maintenance</span>
          </NavLink>
        </NavItem>
      </NavSubMenu>
    ) : null}

    <NavItem key="change-password">
      <NavLink to={getAdminChangeMemberHubspotPassword()}>
        <span>Member Password Change</span>
      </NavLink>
    </NavItem>
    {isPilotCustomer() ? (
      <NavItem key="book-previews">
        <NavLink to={getRouteBookPreviews()}>
          <span>Book Previews</span>
        </NavLink>
      </NavItem>
    ) : null}
  </Menu>
);

export default withRouter(AdminSideNav);
