/** LOADER */
export const TOGGLE_INITIAL_LOADER = '[LOADER] TOGGLE_INITIAL_LOADER';

/** BOOKS */
export const GET_BOOK = '[BOOKS] GET_BOOK';
export const GET_BOOK_PDF = '[BOOKS] GET_BOOK_PDF';
export const TO_ORDER_BOOK_SECTION = 'TO_ORDER_BOOK_SECTION';

/** SECTIONS */
export const SET_SECTIONS = '[SECTIONS] SET_SECTIONS';
export const SELECT_SECTION = '[SECTIONS] SELECT_SECTION';
export const GET_SECTION = '[SECTIONS] GET_SECTION';
export const PERSIST_PREVIOUS_DROPDOWN = '[SECTIONS] PERSIST_PREVIOUS_DROPDOWN ';

export const SET_DROPDOWN = '[SECTIONS] SET_DROPDOWN';

export const ADD_NEW_CHAPTER = '[SECTIONS] ADD_NEW_CHAPTER';
export const UPDATE_SECTION = '[SECTIONS] UPDATE_SECTION';

/** TEMPLATES */
export const SET_TEMPLATES = '[TEMPLATES] SET_TEMPLATES';
export const TOGGLE_TEMPLATE = '[TEMPLATES] TOGGLE_TEMPLATE';
export const SELECT_TEMPLATE = '[TEMPLATES] SELECT_TEMPLATE';
export const UPDATE_TEMPLATE_BEHAVIOR = '[TEMPLATES] UPDATE_TEMPLATE_BEHAVIOR';
export const UPDATE_TEMPLATE_ELEMENT = '[TEMPLATES] UPDATE_TEMPLATE_ELEMENT';
export const UPDATE_TEMPLATE_PAGES = '[TEMPLATES] UPDATE_TEMPLATE_PAGES';
export const UPDATE_TEMPLATE_ELEMENT_ALIGNMENT = '[TEMPLATES] UPDATE_TEMPLATE_ELEMENT_ALIGNMENT';
export const GET_TEMPLATE = '[TEMPLATES] GET_TEMPLATE';
export const CHECK_UNSAVED_CHANGES = '[TEMPLATES] CHECKED_UNSAVED_CHANGES';
export const POPULATE_PREFERENCES = '[TEMPLATES] POPULATE_PREFERENCES';
export const UPDATE_SPINE = '[TEMPLATES] UPDATE_SPINE';
export const UPDATE_BOOKPAGE_ELEMENT = '[TEMPLATES] UPDATE_BOOKPAGE_ELEMENT';
export const UPDATE_ACTIVE_ELEMENT = '[TEMPLATES] UPDATE_ACTIVE_ELEMENT';

/** BROWSE */
export const GET_CATEGORIES = '[BROWSE] GET_CATEGORIES';
export const GET_BOOKS_BY_CATEGORY = '[BROWSE] GET_BOOKS_BY_CATEGORY';
export const GET_SELECTED_BOOK = '[BROWSE] GET_SELECTED_BOOK';
export const SET_INDICES = '[BROWSE] SET_INDICES';
export const TOGGLE_VIEW = '[BROWSE] TOGGLE_VIEW';
export const TOGGLE_READMORE = '[BROWSE] TOGGLE_READMORE';
export const SET_CONTENT_HEIGHT = '[BROWSE] SET_CONTENT_HEIGHT';
export const GET_ALL_BOOKS = '[BROWSE] GET_ALL_BOOKS';

/** DRAFTS */
export const GET_DRAFTS = '[DRAFTS] GET_DRAFTS';
export const DELETE_DRAFTS = '[DRAFTS] DELETE_DRAFTS';
export const TOGGLE_PANEL = '[DRAFTS] TOGGLE_PANEL';
export const SAVE_DRAFT = '[DRAFTS] SAVE_DRAFT';
export const CHECK_USER_DRAFTS = '[DRAFTS] CHECK_USER_DRAFTS';
export const GET_DRAFTBYID = '[DRAFTS] GET_DRAFTBYID';
export const REORDER_DRAFT_SECTIONS = '[DRAFTS|MANAGE SECTIONS] REORDER_DRAFT_SECTIONS';

export const FILTER_SECTIONS = '[MANAGE SECTIONS] FILTER_SECTIONS';
export const ACTIVATE_SECTION = '[MANAGE SECTIONS] ACTIVATE_SECTION';
export const INACTIVATE_SECTION = '[MANAGE SECTIONS] INACTIVATE_SECTION';
export const REORDER_SECTION = '[MANAGE SECTIONS] REORDER_SECTION';
export const ADD_NEW_CHAPTER_SECTION = '[MANAGE SECTIONS] ADD_NEW_CHAPTER_SECTION';

export const UPLOAD_USER_IMAGE = '[DRAFTS] UPLOAD_USER_IMAGE';
export const UPLOADED_USER_IMAGE = '[DRAFTS] UPLOADED_USER_IMAGE';
export const GENERATE_NEW_IMAGE_URL = '[DRAFTS] GENERATE_NEW_IMAGE_URL';
export const CHANGE_USER_COVER_IMAGE = '[DRAFTS] CHANGE_USER_COVER_IMAGE';
export const DELETE_USER_UPLOADED_IMAGE = '[DRAFTS] DELETE_USER_UPLOADED_IMAGE';
export const DRAFT_COMPLETION_PERCENTAGE = '[DRAFTS] DRAFT_COMPLETION_PERCENTAGE';
export const GET_DRAFT_PREVIEW = '[DRAFTS] GET_DRAFT_PREVIEW';
export const GET_PREDEFINED_LOGOS = '[DRAFTS] GET_PREDEFINED_LOGOS';
export const UPDATE_DRAFT_PREFERENCES = '[DRAFTS] UPDATE_DRAFT_PREFERENCES';

/** MODAL */
export const TOGGLE_MODAL = '[MODAL] TOGGLE_MODAL';
export const MODAL_TEXT_CHANGE = '[MODAL] MODAL_TEXT_CHANGE';

/** ALERTS */
export const TOOGLE_ALERT = '[ALERT] TOOGLE_ALERT';
export const ALERT_MESSAGE = '[ALERT] ALERT_MESSAGE';
export const SHOW_ALERT = '[ALERT] SHOW_ALERT';

// AUTH
export const LOGIN_USER = 'LOGIN_USER';
export const AUTO_LOGIN_CHECK = 'AUTO_LOGIN_CHECK';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_NEW_TOKEN = 'GET_NEW_TOKEN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHECK_EXPIRED_LINK = 'CHECK_EXPIRED_LINK';
export const ADMIN_USER_LOGIN = 'ADMIN_USER_LOGIN';
export const SET_USER_EMAIL_LOGIN = 'SET_USER_EMAIL_LOGIN';

/** DASHBOARD*/
export const GET_DASHBOARD_PAGE = 'GET_DASHBOARD_PAGE';

/* Credit*/
export const GET_CREDITS = 'GET_CREDITS';
export const GET_PASSCODE = 'GET_PASSCODE';

/**SHIPPING_ADDRESS */
export const GET_USER_ADDRESS = 'GET_USER_ADDRESS';
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_STATES_BY_COUNTRY_NAME = 'GET_STATES_BY_COUNTRY_NAME';
export const ADD_USER_ADDRESS = 'ADD_USER_ADDRESS';
export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const SET_ADDRESS = 'SET_ADDRESS';

/**  ORDERS  */
export const GET_USER_ORDER = 'GET_USER_ORDER';
export const LIST_USER_ORDERS = 'LIST_USER_ORDERS';
export const EDIT_ORDERED_DRAFT = 'EDIT_ORDERED_DRAFT';
export const REORDER_BOOK = 'REORDER_BOOK';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const UPDATE_ORDER = '[ORDER] UPDATE_ORDER';

/** CONTACT_FORM*/
export const GET_PREFERENCES = 'GET_PREFERENCES';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
export const CLEAR_PREFERENCES = 'CLEAR_PREFERENCES';

/** USER PROFILE PAGE*/
export const LIST_CLICK_HANDLER = 'LIST_CLICK_HANDLER';
export const GET_USERDETAILS = 'GET_USERDETAILS';
export const SET_USERDETAILS = 'SET_USERDETAILS';
export const PROFILE_UPLOAD_USER_IMAGE = 'PROFILE_UPLOAD_USER_IMAGE';

export const GET_PAGES = 'GET_PAGES';
/** ADMIN */
export const GET_ALL_SECTIONS = '[ADMIN] GET_ALL_SECTIONS';
export const GET_CATEGORIE = '[ADMIN] GET_CATEGORIE';
export const SELECT_DROPDOWN = '[ADMIN] SELECT_DROPDOWN';
export const REMOVE_ITEM = '[ADMIN] REMOVE_ITEM';
export const GET_ALL_BOOKSDETAILS = '[ADMIN] GET_ALL_BOOKSDETAILS';
export const EDIT_THIS_BOOK = '[ADMIN] EDIT_THIS_BOOK';
export const CATEGORY_CHECKBOX = 'ADMIN CATEGORY_CHECKBOX';
export const ADD_NEW_SECTION = '[ADMIN] ADD_NEW_SECTION';
export const ADD_NEW_TEMPLATE = '[ADMIN] ADD_NEW_TEMPLATE';
export const ADD_NEW_BOOK = '[ADMIN] ADD_NEW_BOOK';
export const UPDATE_TEMPLATE = '[ADMIN] UPDATE_TEMPLATE';
export const FETCH_TEMPLATE_ELEMENTS = '[ADMIN] FETCH_TEMPLATE_ELEMENTS';
export const ADMIN_SELECT_SECTION = '[ADMIN] ADMIN_SELECT_SECTION';
export const REORDER_BOOK_SECTIONS = '[ADMIN] REORDER_BOOK_SECTIONS';
export const UPDATE_BOOK_SECTIONS_ORDER = '[ADMIN] UPDATE_BOOK_SECTIONS_ORDER';
export const GET_SECTION_BY_ID = '[ADMIN] GET_SECTION_BY_ID';
export const GET_TEMPLATE_BY_ID = '[ADMIN] GET_TEMPLATE_BY_ID';
export const GET_TEMPLATES_BY_TYPE = '[ADMIN] GET_TEMPLATES_BY_TYPE';
export const GET_ALL_CATEGORIES = '[ADMIN] GET_ALL_CATEGORIES';
export const UPDATE_FIELD_VALUE = '[ADMIN] UPDATE_FIELD_VALUE';
export const UPDATE_SUGGESTED_BOOKS = '[ADMIN] UPDATE_SUGGESTED_BOOKS';
export const SUBMIT_BOOK = '[ADMIN] SUBMIT_BOOK';
export const UPDATE_TEMPLATE_FIELD = '[ADMIN] UPDATE_TEMPLATE_FIELD';
export const SUBMIT_SECTION = '[ADMIN] SUBMIT_SECTION';
export const ADMIN_LOGIN = '[ADMIN] ADMIN_LOGIN';
export const CREATE_BOOKPAGE = '[ADMIN] CREATE_BOOKPAGE';
export const GET_FILTERED_SECTIONS = '[ADMIN] GET_FILTERED_SECTIONS';
export const GET_FILTERED_BOOKS = '[ADMIN] GET_FILTERED BOOKS';
export const SHOW_SUGGESTED_SECTIONS = '[ADMIN] SHOW_SUGGESTED_SECTIONS';
export const UPDATE_BOOKPAGE = '[ADMIN] UPDATE_BOOKPAGE';
export const ADD_TO_GLOBAL_SECTIONS = '[ADMIN] ADD_TO_GLOBAL_SECTIONS';
export const SAVE_BOOK = '[ADMIN] SAVE_BOOK';
export const UPDATE_BOOK_STATUS = '[ADMIN] UPDATE_BOOK_STATUS';
export const PERSIST_SECTIONS = '[ADMIN] PERSIST_SECTIONS';
export const GET_PERSIST_SECTIONS = '[ADMIN] GET_PERSIST_SECTIONS';
export const CLEAR_BOOK_UPDATING_STATUS = '[ADMIN] CLEAR_BOOK_UPDATING_STATUS';
export const UPLOAD_IMAGE = '[ADMIN] UPLOAD_IMAGE';
export const GET_BOOK_LAYOUTS = '[ADMIN] GET_BOOK_LAYOUTS';
export const GET_ALL_FONTS = '[ADMIN] GET_ALL_FONTS';
export const ADD_NEW_LAYOUT = '[ADMIN] ADD_NEW_LAYOUT';
export const RESET_LAYOUT = '[ADMIN] RESET_LAYOUT';
export const UPDATE_ACTIVE_BOOKPAGE_ELEMENT = '[ADMIN] UPDATE_ACTIVE_BOOKPAGE_ELEMENT';
export const SAVE_BOOKPAGE_ELEMENT = '[ADMIN] SAVE_BOOKPAGE_ELEMENT';
export const BOOK_VALIDATIONS = '[ADMIN] BOOK_VALIDATIONS';
export const GENERATE_BOOK_PDF = '[ADMIN] GENERATE_BOOK_PDF';
