import styled from 'styled-components';
import { DatePicker as DatePickerBase } from 'antd';

const { RangePicker } = DatePickerBase;

const DatePicker = styled(RangePicker)`
  .ant-calendar-picker-input.ant-input {
    width: 316px;
    height: 40px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 22px;
  gap: 16px;
  span {
    margin: 0;
  }
`;

export default {
  HeaderContainer,
  DatePicker,
};
