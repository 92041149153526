import styled from 'styled-components';
import { device as BreakPoints } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';

const ChooseAnotherBookCover = styled.div`
  flex: 1;
  @media screen and ${BreakPoints.tabletS} {
    order: 2;
  }
`;

const BookBox = styled.div`
  font-family: 'Roboto' !important;
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and ${BreakPoints.tabletS} {
    justify-content: space-evenly;
    width: 70%;
    margin: 0 auto;
  }
`;

const BookBoxMain = styled.div`
  max-width: 170px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  @media screen and ${BreakPoints.tabletS} {
    max-width: unset;
    width: 169px;
  }
`;

const ImageBox = styled.div`
  border: ${(props) => (props.active ? '2px solid #666' : '2px solid transparent')};
  padding: 10px 0px;
  border-radius: 5px;
  width: 130px;
  margin: 0 auto;
  height: 180px;
`;
const ChooseAnotherBookImage = styled.img`
  width: 100px;
  height: 152px;
  transform: matrix(1, -0.06, 0.03, 1, 0, 0);
`;

const BooksText = styled.p`
  font-family: 'Roboto' !important;
  font-size: 14px;
  font-weight: 600;
  color: ${color.Black};
  width: 100%;
  text-align: center;
  margin-top: 10px !important;
`;

const DownloadBookMobileButton = styled.button`
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  color: ${color.White};
  background-color: ${color.PrimaryColor};
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 20px auto;
  cursor: pointer;
  display: none;
  :hover {
    background-color: ${color.PrimaryColorHover};
  }
  @media screen and ${BreakPoints.tabletS} {
    display: block;
  }
`;

const AuthorNameBottom = styled.p`
  position: absolute;
  bottom: 52px;
  left: 50%;
  font-size: 7px;
  padding: 0 2px;
  min-width: 60px;
  text-overflow: ellipsis;
  max-width: 75px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 8px;
  text-transform: uppercase;
`;

const AuthorNameLeft = styled.p`
  position: absolute;
  left: 26px;
  color: ${color.White};
  padding: 0;
  bottom: 65px;
  line-height: 4px;
  font-size: 5px;
  min-width: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform: rotate(86deg);
  font-weight: 700;
`;

export default {
  ChooseAnotherBookCover,
  BookBox,
  BookBoxMain,
  ChooseAnotherBookImage,
  BooksText,
  DownloadBookMobileButton,
  ImageBox,
  AuthorNameBottom,
  AuthorNameLeft,
};
