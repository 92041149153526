import { PageSubTitle } from 'modules/onboard/components';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { useState } from 'react';
import Modal from 'modules/v2/common/components/Modal';
import PdfViewer from '../PdfViewer';

import S from './styles';

const BookContent = ({ bookUrl }) => {
  const [showBookPreviewModal, setShowBookPreviewModal] = useState(false);
  const handleClosePreviewModal = () => {
    setShowBookPreviewModal(false);
  };

  return (
    <>
      <PageSubTitle>Your Book</PageSubTitle>
      <S.BookBox>
        <S.YourBookContainer>
          <S.BookContainer>
            <PdfViewer pdfUrl={bookUrl} width={280} height={470} />
          </S.BookContainer>
        </S.YourBookContainer>
        <div className="justify-center flex flex-col items-center text-base font-normal gap-[20px]">
          <Button type="outlined" onClick={() => setShowBookPreviewModal(true)}>
            Preview Book
          </Button>
          Click the "Complete Customizing Your Book" below to finish customizing your book
        </div>
      </S.BookBox>
      <Modal
        isOpen={showBookPreviewModal}
        title="Preview"
        width="80%"
        onConfirm={handleClosePreviewModal}
        onCancel={handleClosePreviewModal}
        hasCloseButton
        footer={null}
      >
        <div className="h-[555px]">
          <afy-flip-book key="onboarding-pdf" pdfurl={bookUrl} />
        </div>
      </Modal>
    </>
  );
};

export default BookContent;
