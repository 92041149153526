import styled from 'styled-components';
import { device } from 'modules/common/theme/mediaqueries';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Container = styled.div`
  font-family: ${font.FigtreeFont} !important;
  background-color: ${color.neutral100};
  padding: 0 180px 180px;
  min-height: calc(100vh - 64px);

  .ant-input {
    height: 38px;
  }

  @media ${device.tabletL} {
    padding: 0 30px 180px;
  }
`;

const BoxContainer = styled.div`
  width: 100%;
  padding-bottom: 24px;
  background-color: ${color.White};
  border-radius: 8px;
  background: #fff;

  box-shadow:
    0px 2px 4px -2px rgba(25, 27, 31, 0.05),
    0px 4px 8px -2px rgba(25, 27, 31, 0.1);
  margin-bottom: 23px;
  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

const BoxTitle = styled.span``;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  padding: 0 24px;
  text-align: center;
  @media ${device.mobileL} {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
`;

const SideContent = styled.div`
  width: 212px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  @media ${device.mobileL} {
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  position: relativeTitle;
  height: auto;
  display: block;
  height: 250px;
  margin-right: 18px;

  img {
    width: 100%;
  }
`;

const WelcomeTitle = styled.h1`
  font-size: 24px;
  font-weight: 800;
  font-family: ${font.FigtreeFont};
  line-height: 28px;
  margin-top: 40px;
  display: inline-block;
`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  display: inline-block;
`;

const LinksList = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  .ant-btn {
    width: 147px;
  }
`;

const BookName = styled.span`
  font-weight: 700;
  color: ${color.GreySelectDd};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 15px;

  @media ${device.mobileL} {
    text-align: center;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  margin-bottom: 24px;

  div {
    display: flex;
    align-items: center;
  }
`;

const DetailsTitle = styled.div`
  color: ${color.neutral600};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 5px;
`;

const DetailsInformation = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const ConfirmTitle = styled.p`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: ${color.Black};
`;

const ConfirmText = styled.p`
  margin-top: 20px;
  margin-bottom: 0;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 640px;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContentTitle = styled.p`
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-right: 2px;

  span {
    color: ${color.DangerRed};
  }
`;

const ContentDescription = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  @media ${device.tabletS} {
    align-items: center;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: 28px;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const LabeledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  flex-wrap: wrap;
  @media ${device.tabletS} {
    align-items: center;
  }
`;

const InputAddonAfter = styled.button`
  height: 46px;
  width: 104px;
  border: 1px solid ${color.GreyBookBorder};
  background-color: ${color.neutral50};
  padding: 13px 4px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-top: -1px;
  cursor: pointer;
  span {
    font-weight: 600;
    padding: 0 4px !important;
  }
`;

const OrderedGuidesContainer = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${color.GreyBookBorder};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
`;

const FakeInput = styled.div`
  display: flex;
  justify-content: space-between;
  width: 390px;
  height: 46px;
  border-radius: 6px;
  border: 1px solid ${color.GreyBookBorder};
  border-right: none;
  span {
    width: 280px;
    font-size: 14px;
    padding: 13px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media ${device.tabletS} {
    width: 100%;

    span {
      width: 200px;
    }
  }
`;

const InputTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${color.neutral800};
  margin-bottom: 4px;
`;

const FreeIcon = styled.span`
  padding: 4px 8px;
  background-color: ${color.success50};
  color: ${color.success500};
  font-size: 12px;
  font-weight: 600;
  margin-left: 16px;
  border-radius: 6px;
`;

const HideGuidesContainer = styled.div`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  span {
    margin-right: 5px;
  }
`;

const Line = styled.div`
  height: 30px;
  border-bottom: 1px solid ${color.GreyBookBorder};
  margin: 0px 24px 30px;

  @media ${device.tabletS} {
    border-bottom: 1px solid ${color.White};
  }
`;

export default {
  Container,
  BoxContainer,
  BoxTitle,
  BookName,
  ConfirmText,
  ConfirmTitle,
  Details,
  DetailsTitle,
  DetailsInformation,
  ImageWrapper,
  LinksList,
  MainContent,
  SideContent,
  Title,
  Wrapper,
  ModalFooterContainer,
  ContentContainer,
  ContentTitle,
  ContentDescription,
  LandingPageContainer,
  InputContainer,
  InputAddonAfter,
  OrderedGuidesContainer,
  FakeInput,
  FreeIcon,
  HideGuidesContainer,
  Line,
  InputTitle,
  LabeledInputWrapper,
  WelcomeTitle,
};
