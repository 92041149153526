export const LogoutIconNew = ({ width = 18, height = 19, fill = '#3C3C3C' }) => (
  <svg width={width} height={height} viewBox="0 0 18 19" fill="none">
    <path
      d="M9 15.25C5.68629 15.25 3 12.5637 3 9.25C3 5.93629 5.68629 3.25 9 3.25"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7.5 9.25H15M15 9.25L12.75 7M15 9.25L12.75 11.5"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
