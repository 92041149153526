import ReactDOM from 'react-dom';

import { ToastContainer } from 'react-toastify';
import { hasAccessToDMPOneDotFive } from 'modules/auth';
import AppStyle from 'modules/common/theme/appStyle';
import GlobalStyle from 'modules/common/theme/globalTheme';
import GlobalStyleV2 from 'modules/v2/common/theme/globalTheme';
import { Router } from 'modules/router';

import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';

const toastContainer = ReactDOM.createPortal(
  <ToastContainer pauseOnHover={false} rtl={false} autoClose={3000} hideProgressBar closeOnClick />,
  document.getElementById('ToastContainer'),
);

const App = () => (
  <>
    {!hasAccessToDMPOneDotFive() ? <GlobalStyle /> : <GlobalStyleV2 />}
    <AppStyle />
    {toastContainer}
    <Router />
  </>
);

export default App;
