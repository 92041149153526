import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().trim().required('Please enter your current password'),
  newPassword: Yup.string()
    .trim()
    .required('Please enter your new password')
    .min(8, 'Password must be a minimum of 8-characters')
    .matches(/([^0-9]+\d+)|(\d+[^0-9]+)/, {
      message: 'Password must be a combination of numbers and letters',
      excludeEmptyString: false,
    }),
  confirmPassword: Yup.string()
    .trim()
    .required('Please confirm your password')
    .when('newPassword', {
      is: (newPassword) => !!(newPassword && newPassword.length > 0),
      then: Yup.string().oneOf([Yup.ref('newPassword')], 'Password does not match'),
    }),
});

export default validationSchema;
