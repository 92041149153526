import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';
import env from 'environments';

const ccFieldsStyle = {
  field: {
    borderRadius: '5px',
    display: 'block',
    fontFamily: font.RobotoFont,
  },
  input: {
    paddingTop: '4px',
    paddingBottom: '4px',
    height: '45px',
    color: color.GreySelectDd,
    fontWeight: '400',
    marginBottom: '10px',
  },
  label: {
    fontFamily: font.RobotoFont,
    paddingTop: '2px',
    paddingBottom: '1px',
    fontSize: '16px',
    lineHeight: '25px',
    color: color.Black,
    fontWeight: '400',
  },
  message: { paddingTop: '2px', paddingBottom: '1px' },
};

const buildConfig = (paymentType) => ({
  selector: '#chargify-form',
  publicKey: env.CHARGIFY_PUBLIC_KEY,
  type: paymentType || 'card',
  serverHost: env.CHARGIFY_SERVER_HOST,
  threeDSecure: true,
  hideCardImage: true,
  style: ccFieldsStyle,
  fields: {
    number: {
      selector: '#chargify1',
      next_focus: 'month',
    },
    month: {
      selector: '#chargify2',
      next_focus: 'year',
    },
    year: {
      selector: '#chargify3',
      next_focus: 'cvv',
    },
    cvv: {
      selector: '#chargify4',
      required: true,
    },
  },
});

export default buildConfig;
