import moment from 'moment';
import { IDatePicker } from './types';
import S from './styles';

const DatePicker = ({
  isDateRange,
  startDate,
  endDate,
  format = 'MM/DD/YYYY',
  onChange,
  ...rest
}: IDatePicker) => {
  if (!isDateRange) return <S.DatePicker format={format} onChange={onChange} {...rest} />;

  const parsedStartDate = moment(startDate, format);
  const parsedEndDate = moment(endDate, format);

  return (
    <S.DatePicker
      format={format}
      onChange={onChange}
      defaultValue={[parsedStartDate, parsedEndDate]}
      {...rest}
    />
  );
};

export default DatePicker;
