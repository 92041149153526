//

import { compose } from 'redux';
import { get } from 'lodash-es';

import { isLoading } from 'store/status';
import { Loader } from 'modules/common/components';
import { ChangePasswordForm } from 'modules/dashboard/components';
import { ProfileTemplate } from 'modules/dashboard/templates';
import { withPassword, withProfile } from 'modules/dashboard/containers';
import { withAuth } from 'modules/auth/containers';

const Password = ({ avatar, password, profile, updatePassword, signOut }) => {
  const { data } = profile;
  const email = get(data, 'email');
  const firstName = get(data, 'firstName');

  const handleSave = (fields) => {
    const { newPassword, oldPassword } = fields;

    updatePassword(oldPassword, newPassword);
  };

  const loading = isLoading(password.status);

  const content = loading ? (
    <Loader title="Updating password" height="100%" />
  ) : (
    <ChangePasswordForm onSave={handleSave} />
  );

  return (
    <ProfileTemplate
      avatar={avatar}
      email={email}
      title="Password Update"
      username={firstName}
      onLogout={signOut}
      sidebarWidth={0}
      noPaddingLeft
      noPaddingRight
    >
      {content}
    </ProfileTemplate>
  );
};

const Composed = compose(withPassword, withProfile, withAuth)(Password);

export default Composed;
