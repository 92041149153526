import styled, { createGlobalStyle } from 'styled-components';
import { Typography, Space as BaseSpace } from 'antd';
import { PrimaryButton } from 'modules/common/components';

const PlanTitle = styled(Typography.Text)`
  font-size: 24px;
  font-weight: bolder;
`;

const CardWrap = styled(BaseSpace)`
  width: 100%;
  justify-content: space-between;
`;

const ResetModal = createGlobalStyle`
  .ant-modal-wrap .ant-modal {
    top: 0 !important;
  }

  .ant-modal-wrap .ant-modal-close {
    display: block !important;
  }

  .ant-modal-content {
    background-color: #fff !important;
  }

  .ant-modal-header {
    border-bottom: none !important;
    padding: 16px 24px 0 24px !important;
  }
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const FooterSection = styled.div`
  text-align: center;
`;

const OkayButton = styled(PrimaryButton)`
  width: 60% !important;
  margin: auto;
`;

export default {
  PlanTitle,
  CardWrap,
  ResetModal,
  Section,
  OkayButton,
  FooterSection,
};
