import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import { isSucceeded } from 'store/status';
import { Provider } from 'modules/common/components/DraftContext';
import Scrollbars from 'modules/common/components/Scrollbars';

import { ContentEditor, EditorToolbar } from 'modules/v2/editor/components';

import { EditorTemplate } from 'modules/v2/editor/templates';
import {
  withContent,
  withContentRoute,
  withDraft,
  withBook,
  withImageLibrary,
  withPreferences,
} from 'modules/v2/editor/containers';

import { getElementsBySectionId } from 'modules/v2/editor/utils';
import { DocumentsIcon, ResetIcon } from 'modules/v2/common/components/SvgIcons';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { ControlItem } from 'modules/v2/common/AtomicDesign/molecules';
import { DeleteModal } from 'modules/v2/common/AtomicDesign/organisms';
import TemplateListChapters from './TemplateListChapters';

const SideMenuOptions = {
  TEMPLATES: 'templates',
  RESET: 'reset',
};

const Content = ({
  images,
  layout,
  sections,
  sectionId,
  templateList,
  toolbar,
  reset,
  resetSection,
  resetCurrentSection,
  deleteUserImage,
  getUserImages,
  updateContentElement,
  updateContentActiveTemplate,
  uploadContentImage,
  draftId,
  resetDraft,
}) => {
  const [activeControls, setActiveControls] = useState(SideMenuOptions.TEMPLATES);
  const [showResetSectiontModal, setShowResetSectionModal] = useState(false);
  const [showResetFactoryModal, setShowFactoryResetModal] = useState(false);

  const { templateId, template, elements } = getElementsBySectionId(sections, sectionId);
  const { showGuides } = toolbar;
  const wasReset = isSucceeded(reset.status) || isSucceeded(resetSection.status);

  const handleContentChange = ({ _id: elementId, rawValue, defaultValue }) => {
    updateContentElement(sectionId, templateId, elementId, rawValue, defaultValue);
  };

  const handleSelectTemplate = (selectedTemplateId) => {
    updateContentActiveTemplate(sectionId, selectedTemplateId);
  };

  const handleFilePickerConfirm = (value) => {
    uploadContentImage(value);
  };

  const imageHandlerProps = {
    images,
    onConfirmFilePickerModal: handleFilePickerConfirm,
    onDelete: (imageId) => deleteUserImage({ imageId, imageType: 'content' }),
    onGalleryTabOpen: () => getUserImages('content'),
  };

  const handleResetSection = () => {
    setShowResetSectionModal(false);
    resetCurrentSection(sectionId);
  };

  const handleResetDraft = () => {
    setShowFactoryResetModal(false);
    resetDraft(draftId);
  };

  return (
    <EditorTemplate dropdownTitle="Content">
      <Provider
        wasReset={wasReset}
        sectionId={sectionId}
        templateId={template._id}
        elements={elements}
        onChange={handleContentChange}
      >
        <div className="w-fit h-[calc(100vh-64px)] text-center border border-1 border-r-neutral-200">
          <ul className="py-2">
            <ControlItem
              icon={<DocumentsIcon className="mb-2" />}
              active={activeControls === SideMenuOptions.TEMPLATES}
              label="Templates"
              onClick={() => setActiveControls(SideMenuOptions.TEMPLATES)}
            />
            <ControlItem
              type="danger"
              active={activeControls === SideMenuOptions.RESET}
              icon={<ResetIcon className="mb-1" />}
              label="Reset"
              onClick={() => setActiveControls(SideMenuOptions.RESET)}
            />
          </ul>
        </div>
        <div className="flex w-full z-10 bg-white">
          {activeControls === SideMenuOptions.TEMPLATES ? (
            <>
              <div className="w-[412px] items-center flex flex-col border-b border-r border-neutral-200 ">
                <h3 className="p-0 flex items-center basis-[58px] text-zinc-900 px-4 border-b border-b-neutral-200 text-base font-semibold flex-1 w-full mb-8 tracking-normal">
                  Available Templates
                </h3>
                <TemplateListChapters
                  selectedId={templateId}
                  onSelect={handleSelectTemplate}
                  templates={templateList}
                />
              </div>

              <div className="w-full">
                <EditorToolbar imageHandlerProps={imageHandlerProps} />
                <Scrollbars style={{ height: 'calc(100vh - 180px)' }}>
                  <ContentEditor layout={layout} elements={elements} showGuides={showGuides} />
                </Scrollbars>
              </div>
            </>
          ) : null}

          {activeControls === SideMenuOptions.RESET ? (
            <>
              <div className="w-[412px] flex flex-col items-start justify-start border-b border-r border-neutral-200">
                <div className="h-[62px] w-full border-b border-neutral-200 flex items-center pl-6">
                  <h3 className="text-zinc-900 text-base font-semibold leading-7 w-full">Reset</h3>
                </div>
                <div>
                  <div className="px-4">
                    <h4 className="mt-6 text-sm font-semibold">Reset this section</h4>
                    <p className="text-neutral-400">
                      This will reset all possible changes made in this section back to its default
                      version.
                    </p>

                    <Button
                      type="dangerOutlined"
                      className="mt-3.5 mb-6"
                      onClick={() => setShowResetSectionModal(true)}
                    >
                      <div className="flex items-center">
                        <ResetIcon className="mr-2" />
                        Reset this section only
                      </div>
                    </Button>
                  </div>
                  <hr />
                  <div className="px-4">
                    <h4 className="mt-6 text-sm font-semibold">Factory reset</h4>
                    <p className="text-neutral-400">
                      This will reset all possible changes made in this book including your headshot
                      photo and name and back to its default version;
                    </p>

                    <Button
                      type="danger"
                      className="mt-3.5 mb-6"
                      onClick={() => setShowFactoryResetModal(true)}
                    >
                      <div className="flex items-center">
                        <ResetIcon className="mr-2" stroke="white" />
                        Factory reset
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <EditorToolbar imageHandlerProps={imageHandlerProps} />
                <Scrollbars style={{ height: 'calc(100vh - 180px)' }}>
                  <ContentEditor layout={layout} elements={elements} showGuides={showGuides} />
                </Scrollbars>
              </div>
            </>
          ) : null}
        </div>
        <DeleteModal
          title="Reset this section only"
          showModal={showResetSectiontModal}
          setShowModal={setShowResetSectionModal}
          handleDelete={handleResetSection}
          deleteButton="Yes, Reset this section"
        >
          This will reset all possible changes made in this section back to its default version.
          Don’t worry other sections of the book won’t be affected.
        </DeleteModal>
        <DeleteModal
          title="Factory reset?"
          showModal={showResetFactoryModal}
          setShowModal={setShowFactoryResetModal}
          handleDelete={handleResetDraft}
          deleteButton="Yes, Factory reset"
        >
          This will reset all possible changes made in this book including your headshot photo and
          name and back to its default version.
        </DeleteModal>
      </Provider>
    </EditorTemplate>
  );
};

const MemoContent = React.memo(Content);
const Composed = compose(
  withRouter,
  withContentRoute,
  withBook,
  withDraft,
  withContent,
  withPreferences,
  withImageLibrary,
)(MemoContent);

export default Composed;
