import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const PlaceOrderContainer = styled.div`
  width: 628px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const AdBlockWrap = styled.div`
  display: block;
  width: 576px;
  background-color: ${color.OfferBackground};
  padding: 40px 20px;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 20px;
  }
`;

const FullWrap = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export default {
  AdBlockWrap,
  FullWrap,
  PlaceOrderContainer,
};
