import { Children } from 'react';
import { UserAvatar } from 'modules/common/components';
import { DefaultAvatar } from 'modules/v2/common/components/SvgIcon';
import { Item } from 'modules/v2/nav/components/NavBar/components/Menu/components';
import { Version } from './components';

import { getVersion } from './utils';
import S from './styles';

const UserMenu = ({ email, avatar, children }) => {
  const version = getVersion();

  const dropdownList = (
    <S.Menu>
      <S.Menu.Item>
        <S.Details>
          <S.Details.Name>Signed in as</S.Details.Name>
          <S.Details.Email title={email}>{email}</S.Details.Email>
        </S.Details>
      </S.Menu.Item>
      {Children.map(children, (child) => {
        return child && <S.Menu.Item className="child-item">{child}</S.Menu.Item>;
      })}

      {version && (
        <S.Menu.Item disabled>
          <Version version={version} />
        </S.Menu.Item>
      )}
    </S.Menu>
  );

  return (
    <S.Dropdown trigger={['click']} dropdownRender={() => dropdownList}>
      <S.DropdownAvatar>
        <UserAvatar avatar={avatar} defaultAvatar={<DefaultAvatar />} size="40px" scale={0.5} />
      </S.DropdownAvatar>
    </S.Dropdown>
  );
};

UserMenu.Item = Item;

export default UserMenu;
