import { get, map } from 'lodash-es';

import { getPayload } from 'modules/api';
import { addPrefixToBook } from '../common/utils';

export const getBooksPayload = (response) => {
  const payload = getPayload(response);
  const books = get(payload, ['books'], []);

  return {
    ...payload,
    books: map(books, addPrefixToBook),
  };
};
