import { Formik, Form } from 'formik';
import { FileImageOutlined } from '@ant-design/icons';
import { FormErrors, FormLabel } from 'modules/v2/common/components';

import S from './styles';

const PageProfileForm = ({ modalOpen, landingPageProfileData, onSave, image }) => {
  return (
    <Formik enableReinitialize initialValues={landingPageProfileData} onSubmit={onSave}>
      {({ values, errors, handleChange, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <FormErrors errors={errors} />

            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="First Name" htmlFor="firstName" required>
                  <S.Input
                    id="firstName"
                    name="firstName"
                    value={values?.firstName}
                    onChange={handleChange}
                  />
                </FormLabel>
              </S.FieldControl>
              <S.FieldControl>
                <FormLabel title="Last Name" htmlFor="lastName" required>
                  <S.Input
                    id="lastName"
                    name="lastName"
                    value={values?.lastName}
                    onChange={handleChange}
                  />
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="Email" htmlFor="email" required>
                  <S.Input id="email" name="email" value={values?.email} onChange={handleChange} />
                </FormLabel>
              </S.FieldControl>
              <S.FieldControl>
                <FormLabel title="Phone" htmlFor="phone" required>
                  <S.InputMask
                    id="phone"
                    name="phone"
                    value={values?.phone}
                    type="text"
                    mask="999-999-9999"
                    onChange={handleChange}
                    alwaysShowMask
                  />
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.FieldControl>
                <FormLabel title="Broker Address">
                  <S.InputTextArea
                    primary
                    id="address"
                    value={values?.brokerAddress}
                    name="brokerAddress"
                    onChange={handleChange}
                  />
                </FormLabel>
                <S.FieldGroup>
                  <S.AddressText>40/130 characters</S.AddressText>
                </S.FieldGroup>
              </S.FieldControl>
              <S.FieldControl>
                <FormLabel title="Broker Logo" htmlFor="logo">
                  {image ? (
                    <S.Image src={image} onClick={modalOpen} />
                  ) : (
                    <S.UploadField onClick={modalOpen}>
                      <FileImageOutlined />
                    </S.UploadField>
                  )}
                </FormLabel>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.Button onClick={handleSubmit}>Save</S.Button>
            </S.FieldGroup>
            <S.FieldGroup>
              <S.InfoText>* Indicates required field</S.InfoText>
            </S.FieldGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PageProfileForm;
