import { Box } from 'modules/v2/common/AtomicDesign/atoms';

const OrderedBookSkeleton = () => {
  return (
    <Box className="flex-1 p-7 mb-6">
      <div className="flex animate-pulse flex-col bg-white">
        <div className="h-6 bg-gray-300 rounded w-full mb-4" />

        <div className="flex space-x-4">
          <div className="bg-gray-300 rounded w-32 flex-none h-40" />

          <div className="flex flex-col flex-1 space-y-3">
            <div className="h-4 bg-gray-300 rounded-full w-1/4" />
            <div className="flex-1 bg-gray-300 rounded w-3/4" />
            <div className="mt-auto h-10 bg-gray-300 rounded w-1/2" />
          </div>

          <div className="flex flex-col flex-1 space-y-4">
            <div>
              <div className="h-4 bg-gray-300 rounded w-1/3 mb-2" />
              <div className="h-10 bg-gray-300 rounded" />
            </div>

            <div>
              <div className="h-4 bg-gray-300 rounded w-1/3 mb-2" />
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default OrderedBookSkeleton;
