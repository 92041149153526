import { useState } from 'react';
import classnames from 'classnames';
import { PasswordEyeClosedIcon, PasswordEyeOpenIcon } from 'modules/v2/common/components/SvgIcons';
import { cn } from 'modules/v2/common/utils';

import { TextInput } from '../../atoms';

type PasswordInputProps = {
  wrapperClasses?: string;
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  isSmallInput: boolean;
};

const PasswordInput = ({
  wrapperClasses,
  className,
  isSmallInput = false,
  ...props
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState<'text' | 'password'>('password');

  const handleShowPassword = () => {
    setShowPassword((prevStatus) => !prevStatus);
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  return (
    <div className={classnames('relative')}>
      <TextInput {...props} type={inputType} sizing={isSmallInput ? 'sm' : 'md'} />
      <span
        className={cn(
          'absolute top-0 right-2 h-[38px] p-2 text-xs cursor-pointer flex items-center',
          {
            'w-9 h-8 top-1.5 right-2': !isSmallInput,
          },
        )}
        onClick={handleShowPassword}
      >
        {showPassword ? <PasswordEyeOpenIcon /> : <PasswordEyeClosedIcon />}
      </span>
    </div>
  );
};

export default PasswordInput;
