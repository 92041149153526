import styled from 'styled-components';

const TopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 20px 0;
`;

const TableWrap = styled.div`
  margin-bottom: 30px;
  max-width: 700px;

  td {
    &:nth-child(3) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0;

      p {
        min-width: 60px;
        text-align: right;
      }
    }
  }
`;

const Title = styled.h5`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 0;
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

export default {
  TopWrap,
  TableWrap,
  Title,
  Section,
};
