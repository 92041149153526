import { useEffect, useState } from 'react';
import { get } from 'lodash-es';
import { compose } from 'redux';
import { ROUTE_BUY_CREDITS } from 'v2/routes/constants';
import { useHistory } from 'react-router-dom';
import { getRouteDraftGenerateByBookId } from 'modules/v2/draft/routes/navigation';
import { Checkbox, Label, Tooltip } from 'flowbite-react';
import { useQuery as useReactQuery } from 'react-query';

import empty from 'assets/images/empty.png';
import { Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { defaultCheckboxTheme } from 'modules/v2/common/AtomicDesign/atoms/Checkbox/theme';
import { RestartIcon } from 'modules/v2/common/components/SvgIcon';

import { isInitial, isLoading, isSucceeded } from 'store/status';
import { notification, pluralize } from 'modules/v2/common/utils';

import { Loader } from 'modules/v2/common/components';
import { withAuth } from 'modules/auth/containers';
import { withBooksStore, withReleaseNotes, withUserCredits } from 'modules/v2/containers';
import { DashboardTemplate } from 'modules/v2/templates';
import { fetchUserCredits, getShowCreditsButton } from 'modules/api';
import CanadaFlag from 'assets/images/CanadaFlag.png';
import USFlag from 'assets/images/USFlag.png';
import { BookLabel, BookPreview } from './components';
import { setBookLabel, setLabelColor } from './utils';
import { PaymentSteps } from '../PaymentSteps';
import { useGetBookPreviews } from '../admin/BookPreviews/hooks';
import S from './styles';

export const BookCatalog = ({
  books,
  categories,
  credits,
  headshot,
  getBooks,
  getCategories,
  getUserCredits,
  selectCategory,
  updatePreferences,
}) => {
  const [booksList, setBooksList] = useState(books?.data);
  const [bookPreviews, setBookPreviews] = useState([]);
  const [showPaymentSteps, setShowPaymentSteps] = useState(false);
  const [showBuyCreditsButton, setShowBuyCreditsButton] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [checked, setChecked] = useState({});
  const headshotId = get(headshot, ['data', '_id']);
  const isBooksLoading = isLoading(books.status);

  const history = useHistory();

  useEffect(() => {
    if (books?.data?.length > 0) {
      const SortedBooks = (a, b) => {
        if (a.isAllowed === b.isAllowed) {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        }
        return a.isAllowed ? -1 : 1;
      };
      setBooksList(books.data.sort(SortedBooks));
    }
  }, [books]);

  useEffect(() => {
    if (isInitial(categories.status)) {
      getCategories();
    }
    getBooks();

    getUserCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetBookPreviews({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    onSuccess: (response) => setBookPreviews(response?.data?.data),
    onError: (error) => {
      notification.error({
        title: 'Error',
        description: 'there was an error, please try again later',
      });
      throw new Error(error.message);
    },
  });

  const handleSubmit = (formFields) => {
    const data = {
      ...formFields,
      headshotId,
    };

    updatePreferences(data);
  };

  const handleCategoryChange = (e, categoryId) => {
    const copyCheck = checked;
    copyCheck[categoryId] = e.target.checked;
    setChecked(copyCheck);
    selectCategory(checked);
    setSearchValue('');
  };

  let creditsSubHeader = <S.Loading>Loading credits...</S.Loading>;
  let categoriesSubHeader = (
    <span className="w-full pl-4 flex items-center justify-end">Loading categories...</span>
  );
  let content = <Loader height="calc(100vh - 400px)" title="Loading Books" />;

  const {
    data: userBookCredit,
    isLoading: isCreditLoading,
    refetch: reactCreditQueryRefetch,
  } = useReactQuery(['getUserCredits'], fetchUserCredits);
  const bookCredit = userBookCredit?.data?.data?.credits;

  useReactQuery('getShowCreditsButton', getShowCreditsButton, {
    onSuccess: (results) => {
      setShowBuyCreditsButton(results);
    },
  });

  if (isSucceeded(credits.status)) {
    creditsSubHeader = (
      // eslint-disable-next-line react/no-unknown-property
      <span className="text-base text-neutral-800 mr-2" isCreditEnough={credits.data >= 8}>
        {pluralize('Credit', 'Credits', credits.data)}
      </span>
    );
  }

  if (!isCreditLoading) {
    creditsSubHeader = (
      // eslint-disable-next-line react/no-unknown-property
      <span className="text-base text-neutral-800 mr-2" isCreditEnough={bookCredit >= 8}>
        {pluralize('Credit', 'Credits', bookCredit)}
      </span>
    );
  }

  function setCategory(label) {
    const categoryMap = {
      'U.S. Books': (
        <div className="flex items-center gap-x-[4px] font-semibold">
          <img src={USFlag} height="12px" alt="US flag" /> US
        </div>
      ),
      'Canadian Books': (
        <div className="flex items-center gap-x-[4px] font-semibold">
          <img src={CanadaFlag} height={12} alt="Canada flag" /> Canada
        </div>
      ),
      'Spanish Books': <span className="font-semibold">Español</span>,
    };

    return categoryMap[label] || <span className="font-semibold">{label}</span>;
  }

  if (isSucceeded(categories.status)) {
    categoriesSubHeader = categories?.data?.map(({ _id, name }) => (
      <div
        className="flex items-center gap-2 ml-4 "
        key={_id}
        onClick={(e) => handleCategoryChange(e, _id)}
      >
        <Checkbox checked={checked[_id]} theme={defaultCheckboxTheme} id={_id} />
        <Label className="cursor-pointer" htmlFor={_id}>
          {setCategory(name)}
        </Label>
      </div>
    ));
  }

  if (booksList?.length > 0) {
    content = (
      <div className="mb-4 flex flex-wrap gap-y-4 gap-x-5 justify-center overflow-hidden">
        {booksList.map((book, index) => {
          const bookStatus = book?.links?.status;
          const currentBookPreview = bookPreviews.find((previews) => book._id === previews.bookId);

          if (
            book?.categoryDetails?.name === 'Dentist Guides' ||
            (!book.isAllowed && !currentBookPreview)
          ) {
            return null;
          }

          return (
            <div className="relative w-[216px]">
              <BookPreview
                book={book}
                currentBookPreview={currentBookPreview}
                linkUrl={getRouteDraftGenerateByBookId(book?._id)}
                key={book.name}
              />
              {(book?.milestone || (currentBookPreview && !book?.isAllowed)) && (
                <BookLabel
                  label={setBookLabel(bookStatus, book?.isAllowed, bookPreviews)}
                  className={setLabelColor(bookStatus)}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  const handleFilter = (e) => {
    const { value } = e.target;
    setSearchValue(value);

    const filteredBooks = books.data.filter(
      ({ name, title }) =>
        name?.toLowerCase().includes(value.toLowerCase()) ||
        title?.toLowerCase().includes(value.toLowerCase()),
    );

    setBooksList(filteredBooks);
  };

  const handleClearFilters = () => {
    setChecked({});
    getBooks();
    selectCategory({});
    setSearchValue('');
  };

  if (isBooksLoading) {
    content = (
      <div
        role="status"
        className="mb-4 flex flex-wrap gap-y-4 gap-x-5 justify-center overflow-hidden animate-pulse"
      >
        {Array.from({ length: 40 }).map(() => (
          <div>
            <div className="flex items-center justify-center w-[216px] h-[328px] bg-gray-300 rounded dark:bg-gray-700" />
            <div className="h-4 mt-2 bg-gray-300 dark:bg-gray-700 w-full" />
            <div className="h-2.5 mt-1 bg-gray-300 dark:bg-gray-700 w-full" />
          </div>
        ))}
      </div>
    );
  }

  const hasBooksToShow = booksList?.length > 0;

  if (!hasBooksToShow && !isBooksLoading) {
    content = (
      <div className="flex flex-col items-center justify-center h-[400px]">
        <img src={empty} alt="No books found" className="mb-3.5" />
        <p className="font-semibold">No results found for “{searchValue}”</p>
        <p className="text-sm font-medium">
          Try a different keyword or{' '}
          <button
            type="button"
            onClick={handleClearFilters}
            className="text-primary-500 text-sm cursor-pointer"
          >
            <u>reset filter</u>
          </button>
          .
        </p>
      </div>
    );
  }

  return (
    <DashboardTemplate title="Book catalog">
      <div className="w-full">
        <div className="flex justify-end mb-6">
          <div className="flex">
            <S.CreditsWrapper>
              {creditsSubHeader}
              {showBuyCreditsButton && (
                <Button size="base" color="primary" onClick={() => history.push(ROUTE_BUY_CREDITS)}>
                  Buy credits
                </Button>
              )}
            </S.CreditsWrapper>
          </div>
        </div>

        <div className="w-full flex justify-between items-center bg-neutral-0 py-[15px] px-6 rounded-t-lg border-b border-neutral-200 shadow-box max-[650px]:flex-col">
          <div className="w-[308px]">
            <TextInput
              onChange={handleFilter}
              placeholder="Search title or category"
              sizing="md"
              errorMessage="Invalid input"
              value={searchValue}
              className=""
              color="gray"
            />
          </div>
          <div className="w-[550px] flex justify-end max-[650px]:justify-center max-[420px]:flex-wrap">
            {categoriesSubHeader}
            <Tooltip style="dark" content="Reset filters">
              <Button className="ml-4 w-[38px] h-[38px]" type="muted" onClick={handleClearFilters}>
                <RestartIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="bg-neutral-0 py-[15px] px-6 rounded-b-lg shadow-box">{content}</div>
      </div>
      <PaymentSteps
        setShowPaymentSteps={setShowPaymentSteps}
        showPaymentSteps={showPaymentSteps}
        getUserCredits={reactCreditQueryRefetch}
      />
    </DashboardTemplate>
  );
};

export default compose(withAuth, withUserCredits, withBooksStore, withReleaseNotes)(BookCatalog);
