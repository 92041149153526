import styled, { css } from 'styled-components';
import BaseScrollbars from 'react-custom-scrollbars';

const Scrollbars = styled(BaseScrollbars).withConfig({
  shouldForwardProp: (prop) => !['renderInLayers'].includes(prop),
})`
  & > div {
    scroll-behavior: smooth;

    ${({ renderInLayers }) =>
    renderInLayers &&
      css`
        will-change: transform;
      `}
  }
`;

export default {
  Scrollbars,
};
