import { get, startsWith } from 'lodash-es';
import join from 'url-join';
import { getPayload } from 'modules/api';

import env from 'environments';

export const formatImageUrl = (response) => {
  const payload = getPayload(response);
  const payloadUrl = get(payload, 'url');

  const prefixedUrl = join(env.BASE_AUTH_URL_STATIC_FILES, payloadUrl);

  const url = startsWith(payloadUrl, 'http') ? payloadUrl : prefixedUrl;

  return {
    ...payload,
    url,
  };
};
