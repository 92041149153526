import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const Notification = styled.div`
  font-size: 13px;
  font-family: ${font.FigtreeFont};
  font-weight: 600;
`;

Notification.Text = styled.div`
  color: ${color.White};
`;

export default {
  Notification,
};
