import { CaretDownOutlined } from '@ant-design/icons';

import env from 'environments';

import { PrimaryButton } from 'modules/common/components';
import S from './styles';

const SitesDropdown = ({ isPremiumUser }) => {
  const TrainingSiteUrl = `${env.SOCIAL_TRAINING_URL}`;
  const SocialMediaTrainingSiteUrl = `${env.SOCIAL_MEDIA_TRAINING_URL}`;

  const handleClick = () => {
    window.open(SocialMediaTrainingSiteUrl, '_blank');
  };
  const handleTrainingClick = () => {
    window.open(TrainingSiteUrl, '_blank');
  };

  const SiteModal = (
    <S.SiteModal>
      <S.SiteModal.Item>
        <S.SiteModal.Title>Training Site</S.SiteModal.Title>
        <S.SiteModal.Text>
          This is your one-stop shop for generating more sales with <br />
          Authorify
        </S.SiteModal.Text>
        <S.SiteModal.ButtonDiv>
          <PrimaryButton type="primary" onClick={handleTrainingClick}>
            View Now
          </PrimaryButton>
        </S.SiteModal.ButtonDiv>
      </S.SiteModal.Item>
      {isPremiumUser ? (
        <S.SiteModal.Item>
          <S.SiteModal.Title>Social Media Training</S.SiteModal.Title>
          <S.SiteModal.Text>
            Generate sales using social media with step by step guides and templates
          </S.SiteModal.Text>
          <S.SiteModal.ButtonDiv>
            <PrimaryButton type="primary" onClick={handleClick}>
              View Now
            </PrimaryButton>
          </S.SiteModal.ButtonDiv>
        </S.SiteModal.Item>
      ) : null}
    </S.SiteModal>
  );

  return (
    <S.Dropdown
      overlayClassName="toolbarDropdown"
      placement="bottom"
      trigger={['click']}
      dropdownRender={() => SiteModal}
    >
      <S.Link>
        <S.Link.Span>
          <S.Link.Label>Training</S.Link.Label>
          <S.Link.Icon>
            <CaretDownOutlined />
          </S.Link.Icon>
        </S.Link.Span>
      </S.Link>
    </S.Dropdown>
  );
};

export default SitesDropdown;
