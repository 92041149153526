import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  width: 100%;
  height: 62px;
  background-color: ${color.White};
  color: ${color.Black};
  padding: 4px 15px 0 25px;
  line-height: initial;
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-top: 5px solid ${color.primary500};

  ${({ fixed }) =>
    fixed &&
    `
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  `}
`;

Wrapper.Main = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 250px;
  justify-content: flex-start;
  flex-grow: 1;

  > *,
  ul {
    margin-right: 12px;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

Wrapper.Side = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  > *,
  ul {
    margin-right: 12px;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-self: center;
  margin-right: 10px;

  img {
    width: 40px;
    height: 40px;
  }
`;

const Separator = styled.div`
  height: 32px;
  width: 1px;
  background: ${({ showLine }) => (showLine ? color.NavBarSeparatorColor : 'none')};
  margin: 15px 0 15px 5px;
`;

export default { Wrapper, Logo, Separator };
