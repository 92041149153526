import { useMutation, useQuery } from 'react-query';
import { map, get } from 'lodash-es';
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { DashboardTemplate } from 'modules/v2/templates';
import { Box } from 'v2/common/AtomicDesign/atoms';
import {
  createEmailCampaign,
  getEmailCampaignContentPromissed,
  getEmailSegments,
} from 'modules/api';
import { getRouteLaunchCampaign } from 'modules/v2/routes/navigation';
import { EmailCampaignSidebar, EmailCard } from 'modules/v2/components';

import { useSelector } from 'react-redux';
import { ALL_SEGMENTS_ID } from 'v2/pages/CreateOnDemandEmail';
import Header from 'v2/pages/CreateEmailCampaign/components/Header';
import { ProcessingModal } from 'v2/common/AtomicDesign/organisms';
import { customLoadingModalTheme } from 'v2/pages/BuyCredits/components/BuyCreditsCheckoutPage/theme';
import { useState } from 'react';
import validationSchema from './validationSchema';

const CreateEmailCampaign = () => {
  const { isRm, isBooks } = useSelector((data) => data.bundleAccess);
  const history = useHistory();
  const { content } = useParams();

  const [name, setName] = useState('');
  const [emails, setEmails] = useState([]);
  const [id, setId] = useState('');

  const { isLoading, isRefetching, isError } = useQuery(
    'getEmailCampaignContentPromissed',
    () => getEmailCampaignContentPromissed(content),
    {
      onSuccess: ({ data }) => {
        setName(data.name);
        setEmails(data.emails);
        setId(data.id);
      },
    },
  );

  const {
    mutate,
    isLoading: isLoadingCreateEmailCampaign,
    isError: isErrorCreateEmailCampaign,
  } = useMutation((formData) => createEmailCampaign(formData), {
    onSuccess: () => {
      history.push(getRouteLaunchCampaign());
    },
  });

  const handleFormSubmit = (formData) => {
    if (formData.segments.includes(ALL_SEGMENTS_ID)) {
      formData.allSegments = true;
      formData.segments = formData.allAvailableSegments;
    }
    mutate(formData);
  };

  const { data: segmentsData, isLoading: isLoadingEmailSegments } = useQuery(
    'getEmailSegments',
    getEmailSegments,
  );

  const segmentsList = get(segmentsData, 'data')?.filter((segment) => {
    if (!isRm && isBooks) {
      return segment.name !== 'RM Mailing List';
    }

    if (isRm && !isBooks) {
      return segment.name === 'RM Mailing List';
    }

    return true;
  });

  const formFields = {
    name: '',
    startDate: moment(),
    segments:
      isRm && !isBooks
        ? [segmentsList?.find((segment) => segment.name === 'RM Mailing List').id]
        : [],
    status: 'draft',
    allSegments: false,
    contentId: id,
    allowWeekend: true,
  };

  function renderTableSkeleton() {
    return (
      <div className="m-6 p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
        {Array.from({ length: 4 }).map(() => (
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
          </div>
        ))}
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formFields}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      <DashboardTemplate
        isError={isError || isErrorCreateEmailCampaign}
        marginRight="0"
        hasSidebar
        noPaddingLeft
      >
        <h1 className="font-figtree text-2xl leading-7 font-bold">Create nurture campaign</h1>
        <Box className="flex flex-row font-figtree w-full max-h-[90vh] min-w-[460px]">
          <Header />
          <div className="flex min-w-[438px] w-full max-[900px]:flex-col">
            <EmailCampaignSidebar
              campaignName={name}
              segmentsList={segmentsList}
              isLoading={isLoadingEmailSegments}
              disableSegmentsDropdown={isRm && !isBooks}
            />

            <div
              className="flex-1 bg-neutral-50 overflow-y-scroll"
              style={{ maxHeight: 'calc(80vh - 120px)' }}
            >
              {(isLoading || isRefetching) && renderTableSkeleton()}
              {!(isLoading || isRefetching) &&
                map(emails, (emailData, index) => <EmailCard key={index} data={emailData} />)}
            </div>
          </div>
        </Box>

        <ProcessingModal
          title="Preparing to send your email..."
          description={[
            'Designing your email.',
            'Organizing email in the sending queue',
            'Managing the sending queue',
          ]}
          showModal={isLoadingCreateEmailCampaign}
          theme={customLoadingModalTheme}
        />
      </DashboardTemplate>
    </Formik>
  );
};

export default CreateEmailCampaign;
