import styled from 'styled-components';
import { device } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Box = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${color.White};
  border-radius: 4px;
  border: 2px solid ${color.BoxBorder};
  margin-bottom: 23px;
  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const ScheduleContainer = styled.div`
  padding-bottom: 4px;
`;

const ScheduleDescription = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${color.BoxDescription};
`;

const YourBookContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const BookContainer = styled.div`
  width: 168px !important;
  height: 280px !important;
  background-color: ${color.BoxBorder};
  display: flex;
  flex-wrap: wrap;

  @media ${device.tabletS} {
    margin: auto;
  }
`;

const BookWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const BookCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  width: calc(100% - 188px);

  .ant-btn {
    padding: 0;
  }
`;

const YourBookTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  margin-top: 10px;
`;

const YourGuideTitle = styled.div`
  font-size: 16px;
  line-height: 28px;
  margin-top: 10px;
`;

const BookLink = styled.a`
  max-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${color.Black};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${color.GrayDividingLine};
  margin: 18px 0 29px;
`;

const UpperCase = styled.span`
  text-transform: uppercase;
  font-weight: normal;
`;

const BookLinksContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const FakeInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 344px;
  height: 48px;
  padding: 4px 12px;

  background-color: ${color.White};
  border: 1px solid ${color.gray200};
  border-radius: 4px;

  font-size: 16px;
`;

const CountInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 150px;
  height: 48px;
  padding: 4px 12px;

  background-color: ${color.White};
  border: 1px solid ${color.BoxBorder};

  font-size: 16px;
  span {
    width: 50px;
  }
`;

const TextFocus = styled.h4`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: ${color.Black};
  margin-bottom: 8px;
`;

const IconWrapper = styled.img`
  text-align: center;
`;

const FlexShipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  min-height: 130px;
  color: ${color.BoxDescription};

  b {
    color: ${color.BoxTitle};
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const FlexPrintContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  min-height: 93px;

  color: ${color.BoxDescription};

  .black-title {
    color: ${color.BoxTitle};
  }
`;

const AdressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  p {
    margin-bottom: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 27px;

  @media ${device.tabletS} {
    justify-content: space-around;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;

  margin-bottom: 30px;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;

  .ant-btn {
    border: none;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .ant-input::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: ${color.OnboardingPlaceholder};
  }
`;

const ModalRescheduleContainer = styled.div`
  padding: 28px 57px 15px;

  p {
    max-width: 435px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;

export default {
  TitleContainer,
  Box,
  ScheduleContainer,
  ScheduleDescription,
  SpaceBetween,
  BookCardContainer,
  YourBookContainer,
  BookContainer,
  BookWrapper,
  YourBookTitle,
  YourGuideTitle,
  BookLink,
  Line,
  UpperCase,
  BookLinksContainer,
  FakeInput,
  CountInput,
  TextFocus,
  IconWrapper,
  FlexPrintContainer,
  FlexShipContainer,
  AdressContainer,
  ButtonContainer,
  SubmitContainer,
  ModalFooterContainer,
  ModalContainer,
  ModalRescheduleContainer,
};
