import { filter, get, includes } from 'lodash-es';

import {
  ADD_LOADING_COVER_ELEMENTS,
  REMOVE_LOADING_COVER_ELEMENTS,
} from 'modules/editor/store/constants';
import { loadingElements as initialState } from 'modules/editor/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LOADING_COVER_ELEMENTS: {
      const elements = get(action, 'payload', []);

      // It should use concat for both merging arrays either adding a string to end of the array
      return [].concat(state, elements);
    }

    case REMOVE_LOADING_COVER_ELEMENTS: {
      const elements = get(action, 'payload', []);
      const filteredList = filter(state, (item) => !includes(elements, item));

      return filteredList;
    }

    default:
      return state;
  }
};

export default reducer;
