const PaperPlaneIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3085 12.9361L16.6817 8.81664C17.8813 5.21793 18.4811 3.41858 17.5312 2.46876C16.5814 1.51895 14.7821 2.11873 11.1834 3.3183L7.06389 4.69146C4.15939 5.65963 2.70714 6.14371 2.29445 6.85359C1.90185 7.5289 1.90185 8.36295 2.29445 9.03827C2.70713 9.74814 4.15939 10.2322 7.06389 11.2004C7.42385 11.3204 7.82881 11.2347 8.09835 10.9677L12.5029 6.60396C12.7506 6.35847 13.1505 6.36033 13.396 6.60812C13.6415 6.85591 13.6397 7.2558 13.3919 7.5013L9.05849 11.7945C8.7614 12.0889 8.66736 12.5394 8.79961 12.9361C9.76777 15.8406 10.2519 17.2929 10.9617 17.7056C11.637 18.0982 12.4711 18.0982 13.1464 17.7056C13.8563 17.2929 14.3404 15.8406 15.3085 12.9361Z"
      fill="#252525"
    />
  </svg>
);

export default PaperPlaneIcon;
