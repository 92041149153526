import { getInstance } from './instance';

const instance = getInstance();

export const getAvailableCoachSlots = ({ queryKey }) => {
  const [, { email, date, timezone }] = queryKey;
  return instance
    .get(
      `coachings/${encodeURIComponent(email)}/${encodeURIComponent(date)}/${encodeURIComponent(
        timezone,
      )}`,
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const registerForSelectedSlot = ({ payload, queryKey }) => {
  const { offerCode, sessionId } = queryKey;
  return instance
    .post(`onboard/schedule-coaching?offerCode=${offerCode}&sessionId=${sessionId}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getCoachDetails = async ({ start, offerCode, sessionId, outputTimezone }) => {
  const params = {
    start,
    offerCode,
    sessionId,
    outputTimezone,
  };

  try {
    const response = await instance.get('onboard/schedule-coaching', { params });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNewCoach = (sessionId, offerCode) => {
  return instance
    .patch(`onboard/schedule-coaching/coach?offerCode=${offerCode}&sessionId=${sessionId}`)
    .then((response) => response.data);
};

export const getTrackingPixels = async ({ offerCode }) => {
  const params = {
    offerCode,
  };

  try {
    const response = await instance.get('tracking-pixel', { params });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
