import { useEffect, useState } from 'react';
import { CustomFlowbiteTheme, Modal } from 'flowbite-react';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { CloseCircleIcon } from 'modules/v2/common/components/SvgIcon';

const customTheme: CustomFlowbiteTheme['modal'] = {
  root: {
    show: {
      on: 'flex bg-neutral-900 bg-opacity-80',
      off: 'hidden',
    },
  },
  content: {
    base: 'relative h-full max-sm:w-full md:h-auto font-[figtree]',
  },
  body: {
    base: 'px-6',
  },
  footer: {
    base:
      'flex space-x-2 rounded-b border-neutral-200 min-h-[91px] px-6 pb-[13px] pt-[34px] border-neutral-600',
  },
};

const DeleteModal = ({ title, showModal, deleteButton, setShowModal, children, handleDelete }) => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    let timer;
    if (showNotification) {
      timer = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showNotification]);

  const deleteButtonTitle = deleteButton || 'Yes, Delete permanently';

  return (
    <Modal
      theme={customTheme}
      show={showModal}
      onClose={() => setShowModal(false)}
      size="lg"
      popup
      dismissible
    >
      <div className="mx-6 pt-6 pb-3 flex justify-between items-center font-bold border-b border-neutral-200">
        <span className="text-base font-bold">{title}</span>
        <div
          className="p-2.5 bg-neutral-100 rounded-md cursor-pointer hover:bg-neutral-200"
          onClick={() => setShowModal(false)}
        >
          <CloseCircleIcon />
        </div>
      </div>
      <Modal.Body className="pt-[18px] text-sm font-figtree">{children}</Modal.Body>
      <Modal.Footer>
        <div className="w-full flex items-center justify-end gap-2">
          <Button color="muted" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              handleDelete();
              setShowModal(false);
            }}
          >
            {deleteButtonTitle}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
