import { get } from 'lodash-es';

export const isDraftEditable = (draft) => {
  if (draft === null) {
    return false;
  }

  const milestone = get(draft, ['milestone']);
  const status = get(draft, ['status']);

  return milestone === 'draft' && status === 'active';
};
