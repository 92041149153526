import { getSession } from 'modules/onboard/utils';
import { getInstance } from './instance';

const instance = getInstance();

const billingToShippingAddressMapper = (billing, firstName, lastName, phoneNumber) => {
  return {
    firstName,
    lastName,
    phoneNumber,
    country: billing.country,
    state: billing.state,
    city: billing.city,
    pincode: billing.zip,
    addressLine1: billing.address1,
    addressLine2: billing.address2,
    addressType: 'home',
    isDefault: true,
  };
};

function setShippingAddressToLocalStorage(billing, firstName, lastName, phone) {
  localStorage.setItem(
    'onboard-shipping-address',
    JSON.stringify(billingToShippingAddressMapper(billing, firstName, lastName, phone)),
  );
}

export const createCustomer = ({
  firstName,
  lastName,
  email,
  phone,
  password,
  billing,
  offerCode,
  chargifyToken,
  productHandle,
  optinReminder,
  periodPay,
}) => {
  const { id, currentOffer } = getSession(offerCode);
  const { accountType, paymentOptions } = currentOffer || {};
  const hasPaymentOptions = paymentOptions.length > 0;
  const paymentOptionParam = hasPaymentOptions ? `-${periodPay}` : '';
  setShippingAddressToLocalStorage(billing, firstName, lastName, phone);

  const url = `/onboard/customer?offerCode=${offerCode}${paymentOptionParam}&sessionId=${id}`;

  const payload = {
    firstName,
    lastName,
    email,
    phone,
    password,
    billing,
    chargifyToken,
    accountType,
    productHandle,
    smsPreferences: {
      schedulingCoachReminders: optinReminder,
    },
  };

  return instance.post(url, payload);
};
