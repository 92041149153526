const PreviousMagazineDetails = ({ selectedMagazine }) => {
  return (
    <>
      <div className="text-neutral-800 text-2xl font-extrabold leading-7 mb-6 capitalize">
        {selectedMagazine.month} {selectedMagazine.year}
      </div>
      <div className="w-full h-full flex flex-col xl:flex-row gap-4">
        <div className="content w-full h-full bg-white rounded-lg p-6 shadow-box">
          <div className="relative w-full flex justify-between items-center mb-3.5">
            <div className="text-neutral-800 text-base font-semibold">Your magazine</div>
          </div>
          <div className="h-[550px]">
            <afy-flip-book key="generated-magazine-pdf" pdfurl={selectedMagazine.url || selectedMagazine.contentUrl} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousMagazineDetails;
