import Yup from 'configuration/yup';
import validation from 'modules/v2/common/validationSchema';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'The author name is too short')
    .required('Author name is required'),
  email: validation.email,
  phone: validation.phoneNumber,
});

export default validationSchema;
