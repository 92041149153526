import styled from 'styled-components';
import { Table as TableAntd } from 'antd';
import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const Table = styled(TableAntd)`
  font-size: 14px;
  font-family: ${font.FigtreeFont};
  font-weight: 600;
  text-align: center;
  width: 100%;
  border: none;
  margin: 0;
  max-width: 100%;
  opacity: 1;

  .ant-table {
    color: ${color.neutral800};
  }

  .ant-table-container {
    border: none;
  }

  .table-cell {
    border: none;
    padding: 0;
  }

  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    padding: 18px 24px;
  }

  // Title
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th {
    padding: 12px 24px;
  }

  .ant-table-column-title {
    color: ${color.neutral800};
    font-size: 14px;
    font-weight: 600;
  }

  .ant-table-thead > tr > th {
    background-color: ${color.White};
    border-top: 1px solid ${color.neutral200};
    border-bottom: 1px solid ${color.neutral200};
    font-weight: 600;
  }

  // Body

  .ant-table-body {
    ::-webkit-scrollbar {
      width: 0px;
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      background: ${color.neutral100};
    }
    ::-webkit-scrollbar-thumb {
      background: ${color.neutral200};
      width: 100px;
      border-radius: 6px;
    }
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
    padding: 18px 24px;
  }

  .ant-table-tbody > tr > td {
    /* border: none; */
    font-weight: 500;
  }

  .ant-table-tbody .ant-cell {
    border: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-row {
    border-bottom: 1px solid ${color.neutral200};
  }

  .ant-table-cell .ant-table-cell-fix-left .ant-table-cell-fix-left-last {
    border-right: none !important;
  }

  //table pagination
  .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${color.White};
    padding: 22px 24px;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 4px 8px -2px rgba(25, 27, 31, 0.1), 0px 2px 4px -2px rgba(25, 27, 31, 0.05);
  }
  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-pagination-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    padding: 10px 14px;
  }

  .ant-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    padding: 10px 14px;
  }
  .ant-table-pagination.ant-pagination {
    margin: 0 !important;
  }

  @media screen and (max-width: 1024px) {
    .ant-pagination-item:nth-child(n + 5):not(:nth-last-child(-n + 2)) {
      display: none;
    }

    .ant-pagination {
      padding: 22px 0;
    }

    .ant-pagination-prev .ant-pagination-next {
      padding: 10px 0;
    }

    .ant-pagination-item:nth-child(5):after,
    .ant-pagination-item:nth-last-child(2):before {
      content: '...';
    }

    .ant-pagination-item:nth-child(2):before,
    .ant-pagination-item:nth-child(3):before,
    .ant-pagination-item:nth-child(4):before {
      content: none;
    }
  }

  .ant-pagination-disabled {
    opacity: 0.5;
  }

  .ant-pagination-item {
    border: none;
  }

  .ant-pagination-next,
  .ant-pagination-jump-next {
    .ant-btn {
      :hover {
        border: none;
        border-radius: 4px;
        color: ${color.White} !important;
        background-color: ${color.ActivePageHover} !important;
      }
    }
  }

  .ant-table-footer {
    display: none;
  }
`;

const PageDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  color: ${color.gray100};
  font-size: 16px;
`;

export default {
  Table,
  PageDescription,
};
