import withStore from 'store/StoreContainer';
import {
  addressesSelector,
  addUserAddresses,
  countriesSelector,
  deleteUserAddresses,
  editUserAddresses,
  getCountries,
  getUserAddresses,
  statesSelector,
} from 'modules/v2/store';

const mapDispatchToProps = {
  addUserAddresses,
  editUserAddresses,
  deleteUserAddresses,
  getCountries,
  getUserAddresses,
};

const mapStateToProps = (state) => ({
  addresses: addressesSelector(state),
  countries: countriesSelector(state),
  states: statesSelector(state),
});

export default withStore(mapStateToProps, mapDispatchToProps);
