import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { get } from 'lodash-es';
import { Layout } from 'antd';
import { DoubleLeftOutlined } from '@ant-design/icons';

import { withProfile } from 'modules/dashboard/containers';
import { withAuth } from 'modules/auth/containers';

import { DashboardNavBar, DashboardSideNav } from 'modules/dashboard/components';

import { BookLoadIcon } from 'modules/common/components/SvgIcon';

import { Footer, Loader, NoContent } from 'modules/common/components';

import S from './styles';

const DashboardTemplate = ({
  avatar,
  children,
  subHeader,
  subTitle,
  title,
  contentTitle,
  contentSubTitle,
  profile,
  signOut,
  isLoading,
  isError,
  hasNoData,
  hasSidebar,
  hasFooter = true,
  marginTop = '64px',
  loadingTitle = 'Loading...',
  noDataTitle = 'No Data Available',
  error,
  loaderDescription,
  navBar,
  sidebar = <DashboardSideNav />,
  sidebarWidth = 290,
  noPaddingLeft = false,
  noPaddingRight = false,
}) => {
  const [collapsed, setIsCollapsed] = useState(false);
  const [marginLeft, setMarginLeft] = useState(sidebarWidth);
  const { email, firstName } = get(profile, 'data', {});
  const showContent = !isLoading && !hasNoData && !isError;

  useEffect(() => {
    setMarginLeft(collapsed || !hasSidebar ? 0 : sidebarWidth);
  }, [collapsed, sidebarWidth]);

  return (
    <>
      {navBar || (
        <DashboardNavBar email={email} avatar={avatar} username={firstName} onLogout={signOut} />
      )}

      <Layout style={{ marginTop: `${marginTop}` }} hasSider={hasSidebar}>
        {hasSidebar && (
          <S.Sidebar
            collapsible
            collapsed={collapsed}
            collapsedWidth="0"
            trigger={null}
            width={sidebarWidth}
          >
            {sidebar}
          </S.Sidebar>
        )}
        <Layout style={{ marginLeft }}>
          <S.Content noPaddingLeft={noPaddingLeft} noPaddingRight={noPaddingRight}>
            {hasSidebar && (
              <S.CollapseButton
                style={{ left: marginLeft }}
                type="button"
                collapsed={collapsed}
                onClick={() => setIsCollapsed(!collapsed)}
              >
                <DoubleLeftOutlined />
              </S.CollapseButton>
            )}
            {(title || subTitle) && (
              <S.WrapperTitle>
                {title && <S.Title>{title}</S.Title>}
                {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
              </S.WrapperTitle>
            )}

            {!!subHeader && <S.SubHeader>{subHeader}</S.SubHeader>}
            {!!contentTitle && <S.ContentTitle>{contentTitle}</S.ContentTitle>}
            {!!contentSubTitle && <S.ContentSubTitle>{contentSubTitle}</S.ContentSubTitle>}
            {!!isError && error}
            {isLoading && (
              <Loader
                height="calc(50vh - 50px)"
                icon={<BookLoadIcon />}
                title={loadingTitle}
                description={loaderDescription}
              />
            )}
            {hasNoData && !isLoading && <NoContent message={noDataTitle} />}
            {showContent && children}
          </S.Content>
        </Layout>
      </Layout>
      {hasFooter && (
        <S.FooterWrapper>
          <Footer />
        </S.FooterWrapper>
      )}
    </>
  );
};

export default compose(withProfile, withAuth)(DashboardTemplate);
