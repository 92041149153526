function CopyIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9.88235C6 8.05219 6 7.13711 6.64437 6.56856C7.28873 6 8.32582 6 10.4 6H12.6C14.6742 6 15.7113 6 16.3556 6.56856C17 7.13711 17 8.05219 17 9.88235V13.1176C17 14.9478 17 15.8629 16.3556 16.4314C15.7113 17 14.6742 17 12.6 17H10.4C8.32582 17 7.28873 17 6.64437 16.4314C6 15.8629 6 14.9478 6 13.1176V9.88235Z"
        stroke="#252525"
        strokeWidth="1.5"
      />
      <path
        d="M5.2 14C3.98497 14 3 13.1309 3 12.0588V8.17647C3 5.73626 3 4.51615 3.85915 3.75808C4.71831 3 6.10109 3 8.86667 3H11.8C13.015 3 14 3.86909 14 4.94118"
        stroke="#252525"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default CopyIcon;
