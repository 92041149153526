import { Route } from 'react-router';

import { holidaySaleGuard } from 'modules/auth';
import { withGuard } from '../containers';

const HolidaySaleRoute = ({ component, path, exact, children }) => {
  const render = (props) => withGuard(component, holidaySaleGuard)({ ...props, children });

  return <Route exact={exact} path={path} render={render} />;
};

export default HolidaySaleRoute;
