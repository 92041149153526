import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  segments: Yup.array().when('allSegments', {
    is: false,
    then: Yup.array()
      .of(Yup.number().min(1))
      .required('Please select at least one segment')
      .nullable(),
  }),
  sendImmediately: Yup.boolean(),
});

export default validationSchema;
