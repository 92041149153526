import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device as BreakPoints } from 'modules/common/theme/mediaqueries';

const ScheduleWebinar = styled.div`
  width: ${(props) => props.imgWidth};
  text-align: center;
  margin: 0 auto;
  svg {
    margin-bottom: 24px;
  }
  @media screen and ${BreakPoints.tabletS} {
    width: unset;
  }
`;

const ScheduleWebinarHeading = styled.div`
  font-size: 24px !important;
  line-height: 24px !important;
  color: ${color.Black};
  font-weight: 700 !important;
  margin-bottom: 10px;
`;

const ScheduleWebinarSubHeading = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  font-weight: 400;
  @media screen and ${BreakPoints.tabletS} {
    padding: 10px;
  }
`;

export default {
  ScheduleWebinar,
  ScheduleWebinarHeading,
  ScheduleWebinarSubHeading,
};
