export const DENTIST = '/dentist';

export const ROUTE_DENTIST = `${DENTIST}`;

export const ROUTE_DENTIST_GUIDES_WELCOME = `${ROUTE_DENTIST}/welcome`;
export const ROUTE_DENTIST_GUIDES = `${DENTIST}/guides`;
export const ROUTE_DENTIST_GUIDES_CATALOG = `${ROUTE_DENTIST_GUIDES}/catalog`;
export const ROUTE_DENTIST_GUIDES_ORDERED = `${ROUTE_DENTIST_GUIDES}/ordered`;
export const ROUTE_DENTIST_GUIDES_ANALYTICS = `${ROUTE_DENTIST_GUIDES}/analytics`;
export const ROUTE_DENTIST_GUIDES_VIEWER = `${ROUTE_DENTIST_GUIDES}/viewer/:id`;

export const ROUTE_DENTIST_CAMPAIGNS = `${DENTIST}/campaigns`;
