import React from 'react';
import styled, { css } from 'styled-components';
import { Layout as LayoutBase } from 'antd';

import * as color from 'modules/common/theme/color';

const { Content: BaseContent, Header, Sider } = LayoutBase;

const Sidebar = styled(Sider)`
  height: calc(100vh - 96px);
  position: fixed;
  left: 0;
  top: 60px;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  background-color: ${color.SideBarBackground};

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${color.scrollbarThumb};
  }

  ::-webkit-scrollbar-thumb {
    background: ${color.sidebarBoxShadow};
    border-radius: 6px;
  }

  .ant-menu {
    overflow-x: hidden;
    padding-bottom: 20px;

    .ant-menu-submenu-title {
      height: 68px !important;

      :hover,
      :focus,
      :active {
        color: ${color.SideBarActiveText};
        background-color: ${color.SideBarSelectedBackground};

        .ant-menu-submenu-arrow {
          color: ${color.SideBarActiveText};
        }
      }
    }

    .ant-menu-item {
      color: ${color.SideBarText};
      height: 68px;
      border-right: none !important;

      :hover {
        background-color: ${color.SideBarSelectedBackground};
        color: ${color.SideBarActiveText} !important;
      }
    }

    .ant-menu-submenu-selected {
      color: ${color.SideBarActiveText};
      background-color: ${color.SideBarSelectedBackground};

      .ant-menu-submenu-arrow {
        color: ${color.SideBarActiveText};
      }
    }

    .ant-menu-item-selected {
      background-color: ${color.SideBarSelectedBackground} !important;
      border-right: none !important;

      a {
        color: ${color.SideBarActiveText} !important;
        cursor: pointer;
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-open {
        .ant-menu-submenu-title {
          color: ${color.SideBarActiveText} !important;
          background-color: ${color.SideBarSelectedBackground} !important;
        }

        .ant-menu-submenu-arrow {
          color: ${color.SideBarActiveText};
        }
      }

      .ant-menu-vertical .ant-menu-item::after,
      .ant-menu-vertical-left .ant-menu-item::after,
      .ant-menu-vertical-right .ant-menu-item::after,
      .ant-menu-inline .ant-menu-item::after {
        border-right: none !important;
      }

      .ant-menu-submenu-title:hover {
        color: ${color.SideBarActiveText};
        background-color: ${color.SideBarSelectedBackground};

        .ant-menu-submenu-arrow {
          color: ${color.SideBarActiveText};
        }
      }

      .ant-menu-item-selected {
        background-color: transparent !important;
        border-right: none !important;

        a {
          color: ${color.SideBarActiveMenuText} !important;
          cursor: pointer;
        }
      }

      ul {
        background-color: ${color.SideBarSelectedMenuBackground};
      }
    }
  }
`;

const Content = styled(({ noPaddingLeft, noPaddingRight, ...props }) =>
  React.createElement(BaseContent, props),
)`
  ${(props) => css`
    padding: 0 ${props.noPaddingRight ? '0' : '20px'} 20px ${props.noPaddingLeft ? '0' : '40px'};
    background-color: ${color.White};
    -
  `}
`;

const DashboardWrapper = styled.div`
  width: 100%;
`;

const WrapperTitle = styled(Header)`
  text-align: center;
  background: ${color.BookCatalogHeaderBg};
  color: ${color.White};
  margin-bottom: 20px;
  padding: 20px;
  height: auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 46px;
  color: ${color.White};
  font-size: 34px;
`;

const ContentTitle = styled(Title)`
  color: ${color.Black};
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 20px 0 0px 0 !important;
`;

const ContentSubTitle = styled(Title)`
  color: ${color.Black};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  margin: 0px 0 20px 0 !important;
`;

const SubTitle = styled.p`
  color: inherit;
  display: block;
  font-size: 16px;
  line-height: 16px;
`;

const SubHeader = styled.div`
  padding: 0 0 5px;
  border-bottom: 1px solid ${color.GreyFormBorder};
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
`;

const FooterWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 99;
`;

const CollapseButton = styled.button`
  cursor: pointer;
  background-color: ${color.SideBarBackground};
  position: fixed;
  left: 0px;
  top: 30vh;
  border-radius: 0 4px 4px 0;
  border: none;
  box-shadow: 0px 3px 6px #00000040;
  clip-path: inset(-6px -6px -6px 0px);
  padding: 12px 4px;
  z-index: 1;

  span {
    color: ${color.LightBlue};
    fill: ${color.LightBlue};
    transform: rotate(${({ collapsed }) => (collapsed ? '180deg' : '0deg')});
    transition: transform 0.6s ease-in-out;
  }

  &:hover {
    span {
      color: ${color.Black};
      fill: ${color.Black};
    }
  }
`;

export default {
  Sidebar,
  FooterWrapper,
  Content,
  Title,
  SubTitle,
  SubHeader,
  DashboardWrapper,
  WrapperTitle,
  ContentTitle,
  ContentSubTitle,
  CollapseButton,
};
