const CampaignIcon = ({ active = false }) => {
  const notActive = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M15.3085 13.4361L16.6817 9.31664C17.8813 5.71793 18.4811 3.91858 17.5312 2.96876C16.5814 2.01895 14.7821 2.61873 11.1834 3.8183L7.06389 5.19146C4.15939 6.15963 2.70714 6.64371 2.29445 7.35359C1.90185 8.0289 1.90185 8.86295 2.29445 9.53827C2.70713 10.2481 4.15939 10.7322 7.06389 11.7004C7.53026 11.8559 7.76344 11.9336 7.95835 12.0641C8.14723 12.1905 8.3095 12.3528 8.43595 12.5417C8.56642 12.7366 8.64416 12.9698 8.79961 13.4361C9.76777 16.3406 10.2519 17.7929 10.9617 18.2056C11.637 18.5981 12.4711 18.5981 13.1464 18.2056C13.8563 17.7929 14.3404 16.3406 15.3085 13.4361Z" stroke="#757575" strokeWidth="1.5"/>
    <path d="M13.8336 7.72703C14.1265 7.43414 14.1265 6.95926 13.8336 6.66637C13.5407 6.37348 13.0659 6.37348 12.773 6.66637L13.8336 7.72703ZM8.53033 13.0303L13.8336 7.72703L12.773 6.66637L7.46967 11.9697L8.53033 13.0303Z" fill="#757575"/>
  </svg>;

  const isActive = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M15.3085 13.4361L16.6817 9.31664C17.8813 5.71793 18.4811 3.91858 17.5312 2.96876C16.5814 2.01895 14.7821 2.61873 11.1834 3.8183L7.06389 5.19146C4.15939 6.15963 2.70714 6.64371 2.29445 7.35359C1.90185 8.0289 1.90185 8.86295 2.29445 9.53827C2.70713 10.2481 4.15939 10.7322 7.06389 11.7004C7.42385 11.8204 7.82881 11.7347 8.09835 11.4677L12.5029 7.10396C12.7506 6.85847 13.1505 6.86033 13.396 7.10812C13.6415 7.35591 13.6397 7.7558 13.3919 8.0013L9.05849 12.2945C8.7614 12.5889 8.66736 13.0394 8.79961 13.4361C9.76777 16.3406 10.2519 17.7929 10.9617 18.2056C11.637 18.5982 12.4711 18.5982 13.1464 18.2056C13.8563 17.7929 14.3404 16.3406 15.3085 13.4361Z" fill="#3C3C3C"/>
  </svg>;

  return active ? isActive : notActive;
};

export default CampaignIcon;
