import { useState } from 'react';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import { getRouteEmailTemplates } from 'modules/v2/routes/navigation';
import { editCustomerTemplate, getCustomerTemplate } from 'modules/api';
import { DashboardTemplate } from 'modules/v2/templates';
import { notification } from 'modules/v2/common/utils';
import { CustomerTemplatesSideBar, CustomerTemplatesNavBar } from 'modules/v2/components';
import { buildCustomerTemplate } from 'modules/v2/components/CustomerTemplatesSideBar/utils';
import { Loader } from 'modules/v2/common/components';

import { INITIAL_FORM_VALUES } from '../CreateCustomerTemplate/constants';
import S from './styles';
import { validationSchema } from './validationSchema';

const EditCustomerTemplate = ({ history }) => {
  const [initialFormValues, setInitialFormValues] = useState(INITIAL_FORM_VALUES);
  const { id } = useParams();

  const { isLoading: isLoadingCustomerTemplate } = useQuery(
    ['getTemplate', id],
    () => getCustomerTemplate(id),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnMount: true,
      enabled: !!id,
      onSuccess: (data) => {
        const {
          content: templateContent,
          name,
          subject,
          bodyContent,
          imageUrl,
          templateTitle,
          hasHeader,
          hasHeaderTitle,
          hasSignature,
          hasFirstName,
          hasLastName,
          hasMemberBookUrl,
          hasMemberBrokerName,
          hasMemberTitle,
          hasMemberAddress,
          hasSignatureTitle,
          hasEmailAddress,
          hasPhoneNumber,
          hasAddress,
          hasBrokerName,
          hasProfileImage,
          customer,
        } = data;

        const newValues = {
          name,
          subject,
          bodyContent,
          imageUrl,
          templateTitle,
          templateContent,
          hasSavedControls: true,
          user: customer.attributes ? { ...customer.attributes } : {},
        };

        if (hasHeader !== undefined) {
          newValues.controls = {
            hasHeader,
            hasHeaderTitle,
            hasSignature,
            hasFirstName,
            hasLastName,
            hasMemberBookUrl,
            hasMemberBrokerName,
            hasMemberTitle,
            hasMemberAddress,
            hasSignatureTitle,
            hasEmailAddress,
            hasPhoneNumber,
            hasAddress,
            hasBrokerName,
            hasProfileImage,
          };
        } else {
          newValues.hasSavedControls = false;
        }

        setInitialFormValues((initialValues) => {
          const startValues = { ...initialValues, ...newValues };

          if (!customer.attributes) {
            startValues.controls.hasSignature = false;
          }

          return startValues;
        });
      },
      onError: () => {
        notification.error({ title: 'Template could not be loaded. Please try again later.' });
      },
    },
  );

  const {
    mutate: editCustomerTemplateMutation,
    isLoading: isLoadingEditCustomerTemplate,
  } = useMutation(editCustomerTemplate, {
    onSuccess: () => history.replace(getRouteEmailTemplates()),
    onError: () =>
      notification.error({ description: 'There we an error. Please try again later.' }),
  });

  const handleSubmit = (formValues) => {
    const content = buildCustomerTemplate({ formValues });
    const data = { ...formValues, content };

    editCustomerTemplateMutation({ id, data });
  };

  const handleCancel = () => {
    history.push(getRouteEmailTemplates());
  };

  if (isLoadingCustomerTemplate) {
    return <Loader />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <DashboardTemplate
        isLoading={isLoadingEditCustomerTemplate}
        loadingTitle="Loading customer template"
        marginRight="0"
        hasSidebar
        noPaddingLeft
      >
        <S.Wrapper>
          <CustomerTemplatesSideBar onCancel={handleCancel} />
        </S.Wrapper>
      </DashboardTemplate>
    </Formik>
  );
};

export default EditCustomerTemplate;
