import authModule from 'modules/auth/store/reducers/initialState';
import bookLinks from 'modules/bookLinks/store/reducers/initialState';
import dashboardModule from 'modules/dashboard/store/reducers/initialState';
import draftModule from 'modules/draft/store/reducers/initialState';
import editor from 'modules/editor/store/reducers/initialState';
import publish from 'modules/publish/store/reducers/initialState';

const initialState = {
  authModule,
  bookLinks,
  dashboardModule,
  draftModule,
  editor,
  publish,
};

export default initialState;
