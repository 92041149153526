import { compose } from 'redux';
import { useParams } from 'react-router';
import { find, get } from 'lodash-es';

import withStore from 'store/StoreContainer';
import { Loader, Empty } from 'modules/common/components';
import {
  getDraftById,
  goToEditDraftCover,
  goToEditDraftContent,
  draftIdSelector,
  activeContentSectionsSelector,
} from 'modules/v2/editor/store';

const withContentRoute = (Component = Empty) => (props) => {
  const { sectionId } = useParams();
  const { draftId, activeContentSections } = props;

  if (sectionId) {
    const section = find(activeContentSections, ({ _id }) => _id === sectionId);
    if (!section) {
      props.goToEditDraftContent(draftId);
      return <Loader title="Loading Book Editor" />;
    }
  }

  if (!sectionId) {
    const newSectionId = get(activeContentSections, [0, '_id']);

    if (newSectionId) {
      props.goToEditDraftContent(draftId, newSectionId);
    } else {
      props.goToEditDraftCover(draftId);
    }

    return <Loader title="Loading Book Editor" />;
  }

  const newProps = {
    ...props,
    sectionId,
  };

  return <Component {...newProps} />;
};

const mapStateToProps = (state) => ({
  draftId: draftIdSelector(state),
  activeContentSections: activeContentSectionsSelector(state),
});

const mapDispatchToProps = {
  getDraftById,
  goToEditDraftCover,
  goToEditDraftContent,
};

export default compose(withStore(mapStateToProps, mapDispatchToProps), withContentRoute);
