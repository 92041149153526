import { Children } from 'react';

import S from './styles';

const ButtonGroup = ({ children }) => (
  <S.List>
    {Children.map(children, (child) => child && <S.List.Item key={child}>{child}</S.List.Item>)}
  </S.List>
);

export default ButtonGroup;
