import styled from 'styled-components';
import { Button as AntButton } from 'antd';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 20px 0 10px 0 !important;
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
`;

const Button = styled(AntButton)`
  line-height: 19px;

  span {
    font-weight: 800;
  }

  &:hover,
  &:focus {
    color: inherit;
    border-color: inherit;
  }
`;

export default { HeaderContainer, Title, FilterContainer, ActionWrapper, Button };
