import { Typography, Radio } from 'antd';
import S from './styles';

const { Title, Text } = Typography;

const RadioItem = ({ item, selected }) => {
  const isActive = selected === item.planHandle;

  const renderCardTag = () => {
    if (item.type === 'annually') {
      return <S.CardTag>Recommended</S.CardTag>;
    }
    return null;
  };

  return (
    <S.Container isActive={isActive}>
      <Radio value={item.planHandle}>
        <S.RadioWrapper direction="horizontal">
          <S.InlineGroup>
            <Title level={5}>{item.name}</Title>
            <Text strong>{item.monthly}</Text>
            <div>
              <Text>{item.billed}</Text> {item.saved && <S.Savings>({item.saved})</S.Savings>}
            </div>
          </S.InlineGroup>
          {renderCardTag()}
        </S.RadioWrapper>
      </Radio>
    </S.Container>
  );
};

export default RadioItem;
