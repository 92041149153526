import { createSelector } from 'reselect';
import { get } from 'lodash-es';

import { dashboardSelector } from 'modules/v2/store/selectors/dashboard';

export const ordersStateSelector = createSelector(dashboardSelector, (state) =>
  get(state, 'orders', {}),
);
export const ordersSelector = createSelector(ordersStateSelector, (state) =>
  get(state, 'data', null),
);
