//

import { Droppable, Draggable } from 'react-beautiful-dnd';
import { List } from 'antd';

import { DragIcon, EditIcon } from 'modules/common/components/SvgIcon';
import { cn } from 'modules/v2/common/utils';
import S from './styles';

const ListSorting = ({ droppableId, selectedId, items = [], onEdit, onSelect }) => {
  const onEditCallback = (e, item) => {
    e.stopPropagation();
    onEdit(item);
  };

  const onSelectCallback = (e, item) => {
    e.stopPropagation();
    onSelect(item);
  };

  return (
    <Droppable droppableId={droppableId} isCombineEnabled={false}>
      {(droppable) => (
        <div ref={droppable.innerRef}>
          <List
            className="!border-0 outline-none"
            bordered
            dataSource={items}
            renderItem={(item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(draggable) => {
                  const selected = item._id === selectedId;

                  return (
                    <li
                      ref={draggable.innerRef}
                      {...draggable.draggableProps}
                      {...draggable.dragHandleProps}
                      className={cn(
                        'group h-12 p-3.5 bg-white rounded-lg border border-neutral-200 text-neutral-800 text-sm mb-2 hover:outline hover:border-transparent hover:outline-primary-400 hover:outline-2 hover:bg-primary-50',
                        { 'border-primary-400 outline-2 bg-primary-50': selected },
                      )}
                    >
                      <div
                        className="relative flex items-center"
                        onClick={(e) => onSelectCallback(e, item._id)}
                        onKeyDown={(e) => onSelectCallback(e, item._id)}
                      >
                        {items.length > 1 && (
                          <div className="absolute left-2 top-0">
                            <DragIcon />
                          </div>
                        )}
                        <div
                          className={cn(
                            'relative w-full flex justify-between items-center absolute mt-5',
                          )}
                        >
                          <span
                            className={cn(
                              'block overflow-hidden text-ellipsis ',
                              { 'pl-8': items.length > 1 },
                              { '': !onEdit || !selected },
                            )}
                            title={item.displayName}
                          >
                            {item.displayName}
                          </span>
                          {onEdit && (
                            <div className="group-hover:opacity-100 opacity-0 transition-all ease-in-out duration-200">
                              <S.EditLink onClick={(e) => onEditCallback(e, item._id)}>
                                <EditIcon />
                              </S.EditLink>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                }}
              </Draggable>
            )}
          >
            {items.length > 0 && droppable.placeholder}
          </List>
        </div>
      )}
    </Droppable>
  );
};

export default ListSorting;
