import { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { withAuth } from 'modules/v2/containers';
import { ForgotPassword, NewForgotPassword } from 'modules/v2/components';
import { setItem, getItem } from 'modules/v2/utils/legacy';
import env from 'environments';
/**
 * Container for Forgot Password screen

 */
class ForgetPassword extends PureComponent {
  constructor() {
    super();

    this.state = {
      username: '',
      message: '',
    };
  }

  /**
   * Verify users email address if user exists in the Hubspot
   * then send a password reset link to user's emailid
   */
  handleSubmit = () => {
    const { username } = this.state;
    const { checkEmail, forgetPassword } = this.props;

    // check for valid email
    if (!username || !checkEmail(username)) {
      this.setState({
        message: 'invalid',
      });
    } else {
      setItem('username', username);

      // Api for forget password
      forgetPassword(getItem('username'), 'template_forgot_password')
        .then(() => {
          // user does not exist in hubspot
          if (this.props.auth.forgotPassword.statusCode === 1)
            this.setState({
              message: "doesn't exist",
            });
        })
        .catch(() => {
          toast.dismiss();
          toast.error('Oops Something went Wrong!!!', {
            className: 'Errortoaster',
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  /**
   * handle change for user email field
   */
  handleChange = (event) => {
    this.setState({
      username: event.target.value,
    });
  };

  

  render() {
    const { auth } = this.props;
    const email = getItem('username');
    const ForgotPasswordComponent = env.AFY_NEW_LOGIN ? NewForgotPassword : ForgotPassword;
    
    return (
      <ForgotPasswordComponent
        statusCode={auth.forgotPassword.statusCode}
        message={this.state.message}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        email={email}
        auth={auth}
      />
    );
  }
}

export default withAuth(ForgetPassword);
