import get from 'lodash/get';
import { TextInput, Textarea, Tooltip } from 'flowbite-react';
import { defaultTextInputTheme } from 'modules/v2/common/AtomicDesign/atoms/TextInput/theme';
import { defaultTextAreaTheme } from 'modules/v2/common/AtomicDesign/atoms/TextArea/theme';
import QuestionCircleIcon from 'modules/v2/common/components/SvgIcon/QuestionCircle';

import { ErrorMessage } from 'modules/v2/common/components';
import { checkWordLimit, getWordsCount } from 'modules/v2/utils/functions';
import { optionFieldsWithWordLimit } from '../../constants';

const TestimonialsComponent = ({ step, formikHandler }) => {
  const { values = {}, setFieldValue, setFieldError, errors } = formikHandler || {};
  const targetLocation = step.concat('.', '_formFields');

  const handleChange = (e) => {
    const {
      target: { name = '', value = '' },
    } = e || {};

    const formikValues = get(values, `${targetLocation}`) || {};

    if (optionFieldsWithWordLimit.includes(name)) {
      const { limitExceeded, isRemovingWords } = checkWordLimit(formikValues[name], value, 80);

      if (limitExceeded && !isRemovingWords) {
        setFieldError(`${targetLocation}.${name}`, 'Testimonial must not be more than 80 words.');
        return;
      }
    }

    setFieldError(`${targetLocation}.${name}`, null);
    const locationToSave = step.concat('.', '_formFields', '.', name);
    setFieldValue(locationToSave, value);
  };

  const numbersArray = [1, 2, 3, 4, 5];

  return (
    <div className="flex flex-col gap-4">
      {numbersArray.map((number) => {
        const fieldName = `clientName${number}`;
        const testimonialFieldName = `testimonial${number}`;
        const fieldNameErr = get(errors, `${targetLocation}.clientName${number}`) || '';
        const testimonialFieldErr = get(errors, `${targetLocation}.testimonial${number}`) || '';

        const testimonialField = get(values, `${targetLocation}.${testimonialFieldName}`) || '';
        const testimonialWordCount = getWordsCount(testimonialField);

        return (
          <div key={`clinetName-${number}`} className="flex gap-4 items-start">
            <div className="flex flex-col w-1/3 gap-2">
              <label
                htmlFor={fieldName}
                className="flex gap-1 font-semibold text-sm text-neutral-800"
              >
                Name
                <Tooltip content="The name of the customer">
                  <QuestionCircleIcon />
                </Tooltip>
              </label>
              <TextInput
                theme={defaultTextInputTheme}
                type="text"
                id={fieldName}
                name={fieldName}
                onChange={handleChange}
                value={get(values, fieldName)}
              />
              <ErrorMessage>{fieldNameErr}</ErrorMessage>
            </div>
            <div className="w-full flex gap-4 items-center">
              <div className="w-full flex flex-col w-1/3 gap-2">
                <label
                  htmlFor={testimonialFieldName}
                  className="flex gap-2 font-semibold text-sm text-neutral-800"
                >
                  Testimonial
                </label>
                <Textarea
                  theme={defaultTextAreaTheme}
                  id={testimonialFieldName}
                  name={testimonialFieldName}
                  onChange={handleChange}
                  value={testimonialField}
                  className="rounded-lg border border-neutral-200 py-[9px] px-3.5 h-[90px]"
                />
                <div className="flex justify-between items-center">
                  <ErrorMessage>{testimonialFieldErr}</ErrorMessage>
                  <div className="flex justify-end font-medium text-sm text-neutral-400">
                    {testimonialWordCount}/80 words
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TestimonialsComponent;
