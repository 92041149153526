import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDropzone } from 'react-dropzone';
import { get, noop } from 'lodash-es';
import env from 'environments';
import camera from 'assets/images/camera.svg';
import trash from 'assets/images/trash.svg';
import { createUploads, uploadImage, updateUserAvatar } from 'modules/api';
import { useFetchSession } from 'modules/onboard/hooks';
import { ErrorMessage } from 'modules/common/components/UIComponents';
import S from './styles';

const ImageUploader = ({
  hasErrors = false,
  label = 'Upload your headshot photo',
  callback = noop,
  imagePlaceholder = '',
}) => {
  const [avatarURL, setAvatarURL] = useState(imagePlaceholder);
  const [previousImage, setPreviousImage] = useState('');
  const [imageProps, setImageProps] = useState({
    name: '',
    size: '',
  });

  const { session } = useFetchSession();

  const handleSubmit = async (file) => {
    if (!file?.[0]) return;
    try {
      const req = {
        payload: {
          bucket: env.DS_BUCKET,
          isPrivate: false,
          contentType: file?.[0].type,
          ext: file?.[0].type.replace('image/', ''),
          path: '',
        },
        callbackTargetKey: 'uploadUrl',
        callbackPayload: file?.[0],
      };
      const response = await createUploads(req);
      if (response) {
        setAvatarURL(response);
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  const convertBytes = (bytes) => {
    let convertedBytes = 0;
    if (bytes < 50000) convertedBytes = `${(bytes / 1000).toFixed(1)} KB`;
    else convertedBytes = `${(bytes / (1000 * 1000)).toFixed(1)} MB`;
    return convertedBytes;
  };

  const { mutate: mutateUpdateUserAvatar } = useMutation(updateUserAvatar);

  const { mutateAsync: mutateImageUpload } = useMutation(uploadImage, {
    onSuccess: ({ Location: url }) => {
      const email = get(session, 'customer.email');
      callback(url);

      mutateUpdateUserAvatar({ email, avatar: url });
    },
    onError: ({ response }) => {
      const error = get(response, 'data.error', 'Error');
      throw new Error(error);
    },
  });

  const handleConfirm = async (newFile) => {
    const file = new Blob([newFile], { type: newFile.type });
    const formData = new FormData();
    formData.append('file', file);
    mutateImageUpload(formData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    onDrop: (res) => {
      handleSubmit(res);
      handleConfirm(res[0]);
      setImageProps({
        name: res[0].name,
        size: convertBytes(res[0].size),
      });
    },
  });

  if (avatarURL)
    localStorage.setItem(
      'avatar',
      JSON.stringify({
        url: avatarURL,
        name: imageProps.name,
        size: imageProps.size,
      }),
    );

  return (
    <S.AvatarWrapper {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {previousImage || avatarURL ? (
        <S.AvatarImageContainer>
          <S.AvatarContainer>
            <S.AvatarImage>
              <img src={avatarURL || previousImage.url} alt="author-cover" />
            </S.AvatarImage>
            <S.AvatarDescription>
              <span>{imageProps.name || previousImage.name}</span>
              <br />
              <span className="imageSize">{imageProps.size || previousImage.size}</span>
            </S.AvatarDescription>
          </S.AvatarContainer>
          <div>
            <S.TrashImage
              src={trash}
              alt="trash"
              onClick={() => {
                setAvatarURL(false);
                setPreviousImage(false);
              }}
            />
          </div>
        </S.AvatarImageContainer>
      ) : (
        <S.EmptyAvatarContainer>
          <img src={camera} alt="camera" />
          <br />
          <S.DisabledText>{label}</S.DisabledText>
        </S.EmptyAvatarContainer>
      )}
      {hasErrors ? <ErrorMessage>Invalid file type</ErrorMessage> : null}
    </S.AvatarWrapper>
  );
};

export default ImageUploader;
