import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Input, DatePicker as DatePickerAntd } from 'antd';
import { PrimaryButton } from 'modules/onboard/components';

const Wrapper = styled.div`
  .selectedRow {
    border-color: red;
  }
`;

const ToolsWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;

  button {
    margin: 0 !important;
  }
`;

const EmailField = styled(Input)`
  height: 40px;
  margin-right: 10px;
`;

const ToolsButton = styled(PrimaryButton)`
  height: 32px;
  font-size: 16px;
`;

const RescheduleTitle = styled.h3`
  font-size: 18px !important;
  margin-bottom: 10px !important;
  font-weight: normal !important;
`;

const DatePicker = styled(DatePickerAntd)`
  margin-right: 10px;
`;
const CancelReminder = styled.span`
  color: ${color.DangerRed};
  cursor: pointer;
`;

export default {
  Wrapper,
  ToolsWrapper,
  EmailField,
  ToolsButton,
  DatePicker,
  RescheduleTitle,
  CancelReminder,
};
