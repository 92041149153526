import { Link } from 'react-router-dom';
import classNames from 'classnames';

type MenuItemProps = {
  href: string;
  current: boolean;
  name: string;
  Icon: React.FC<{ active: boolean }>;
  isChild?: boolean;
  welcomeJourneyEligible?: boolean;
};

const MenuItem = ({
  href,
  current,
  name,
  Icon,
  isChild = false,
  welcomeJourneyEligible = false,
}: MenuItemProps) => {
  const hasHttps = href.includes('http' || 'https');

  const isDisabled = welcomeJourneyEligible ? name !== 'Welcome journey' : false;

  if (hasHttps) {
    return (
      <a
        href={href}
        target="blank"
        className={classNames(
          `
        m-0
        w-full
        h-[39px]
        flex
        relative
        items-center
      `,
          isChild ? 'pr-1' : 'pr-3',
          current
            ? `
        after:w-2 w-2
        after:content-[' ']
        after:absolute
        after:right-[-4px]
        after:top-1
        after:h-8
        after:bg-blueBorder
        after:transition-all
        after:duration-200
        after:rounded-full

        h-[39px]
        hover:after:w-2
        flex
        relative
        items-center
      `
            : '',
          isChild ? '' : 'pr-3',
          current ? 'after:w-2 w-2' : '',
        )}
      >
        <div
          className={classNames(
            current
              ? 'bg-gray-100 text-neutral-700 after:w-2'
              : 'hover:bg-gray-100 text-neutral-600 after:w-0',
            'w-full flex gap-[10px] items-center px-2 py-[9.5px] rounded-lg',
            isChild ? 'mr-2' : '',
            !Icon ? 'pl-10' : '',
          )}
        >
          {Icon && <Icon active={current} />}
          <span>{name}</span>
        </div>
      </a>
    );
  }

  if (isDisabled) {
    return (
      <span
        className={classNames(
          `
        m-0
        w-full
        h-[39px]
        flex
        relative
        items-center
        cursor-not-allowed
        opacity-50
        pr-3
      `,
        )}
      >
        <div className="w-full mr-2 flex gap-[10px] items-center px-2 py-[9.5px] rounded-lg">
          {Icon && <Icon active={current} />}
          <span>{name}</span>
        </div>
      </span>
    );
  }
  return (
    <Link
      to={href}
      className={classNames(
        `
          m-0
          w-full
          h-[39px]
          flex
          relative
          items-center
        `,
        isChild ? 'pr-1' : 'pr-3',
        current
          ? `
          after:w-2 w-2
          after:content-[' ']
          after:absolute
          after:right-[-4px]
          after:top-1
          after:h-8
          after:bg-blueBorder
          after:transition-all
          after:duration-200
          after:rounded-full

          h-[39px]
          hover:after:w-2
          flex
          relative
          items-center
        `
          : '',
        isChild ? '' : 'pr-3',
        current ? 'after:w-2 w-2' : '',
      )}
    >
      <div
        className={classNames(
          current
            ? 'bg-neutral-50 text-neutral-700 after:w-2'
            : 'hover:bg-neutral-50 text-neutral-600 after:w-0',
          'w-full flex gap-[10px] items-center p-2 rounded-lg',
          isChild ? 'mr-2' : '',
          !Icon ? 'pl-10' : '',
        )}
      >
        {Icon && <Icon active={current} />}
        <span>{name}</span>
      </div>
    </Link>
  );
};

export default MenuItem;
