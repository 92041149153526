import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import * as color from 'modules/v2/common/theme/color';

const Upload = styled.div`
  display: flex;
  background: ${color.White};
  padding: 5px;
  height: 44px;
  border: 1px solid ${color.Grey3};
  border-radius: 4px;
  align-items: center;
`;

Upload.Button = styled(AntdButton)`
  margin-right: 20px;
  background-color: ${color.GreyBookBorder};
  border: 1px solid ${color.Grey3};

  :hover {
    background-color: ${color.GreyBookBorder};
    border: 1px solid ${color.Grey3};
    color: rgba(0, 0, 0, 0.65);
  }
`;

Upload.Succeeded = styled.span`
  color: ${color.SuccessWarning};
`;

Upload.Failed = styled.span`
  text-transform: none;
  color: ${color.ErrorWarning};
`;

export default {
  Upload,
};
