function ErrorToastIcon({
  width = 16,
  height = 16,
  ...props
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 20" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.92642 8.96791C7.35839 4.65597 8.57438 2.5 10.5 2.5C12.4256 2.5 13.6416 4.65597 16.0736 8.96791L16.3766 9.50522C18.3976 13.0884 19.408 14.88 18.4948 16.19C17.5815 17.5 15.322 17.5 10.803 17.5H10.1969C5.67796 17.5 3.41847 17.5 2.50521 16.19C1.59195 14.88 2.60242 13.0884 4.62337 9.50523L4.92642 8.96791ZM10.5 6.04167C10.8452 6.04167 11.125 6.32149 11.125 6.66667V10.8333C11.125 11.1785 10.8452 11.4583 10.5 11.4583C10.1548 11.4583 9.875 11.1785 9.875 10.8333V6.66667C9.875 6.32149 10.1548 6.04167 10.5 6.04167ZM10.5 14.1667C10.9602 14.1667 11.3333 13.7936 11.3333 13.3333C11.3333 12.8731 10.9602 12.5 10.5 12.5C10.0398 12.5 9.66666 12.8731 9.66666 13.3333C9.66666 13.7936 10.0398 14.1667 10.5 14.1667Z" fill="#E75A50"/>
    </svg>
  );
}

export default ErrorToastIcon;
