import styled from 'styled-components';

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
`;

List.Item = styled.li`
  padding: 0;
  margin: 0;
  display: flex;
  margin-right: -1px;

  &:last-child {
    margin-right: 0;
  }
`;

export default {
  List,
};
