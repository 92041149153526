import { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';

import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import {
  ListingDetailsComponent,
  QRCodeComponent,
  RenderOptionsComponent,
  SocialMediaFormComponent,
} from 'modules/v2/pages/ReferralMarketingMagazines/components';
import isEmpty from 'lodash/isEmpty';
import { frontCoverStripOptionFormFields, frontCoverStripOptionsHeading } from '../constants';

const FrontCover = ({
  handleImageLightBox,
  frontCoverDesign,
  frontCoverStrip,
  updateMagazineData,
}) => {
  const {
    magazine = {},
    handleUpdateMagazine,
    magazineDataFormik,
    previousMagazineData,
    setFormikContext,
  } = updateMagazineData || {};

  const selectedCoverOption = get(magazine, 'frontCoverDesign');
  const selectedCoverStripOption = get(magazine, 'frontCoverStrip');

  const lastSelectedCoverStripOption = useRef(selectedCoverStripOption?.formKeyword);

  useEffect(() => {
    magazineDataFormik.setFieldValue(
      'frontCover.selectedOption',
      selectedCoverStripOption?.formKeyword,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentSelectedOption = get(selectedCoverStripOption, 'formKeyword');
    const lastSelectedOption = get(lastSelectedCoverStripOption, 'current');

    if (lastSelectedOption !== currentSelectedOption) {
      lastSelectedCoverStripOption.current = currentSelectedOption;
      const initialValueOfSelctedOptionForm =
        frontCoverStripOptionFormFields[currentSelectedOption];
      magazineDataFormik.setFieldValue('frontCover._formFields', initialValueOfSelctedOptionForm);
      magazineDataFormik.setFieldValue('frontCover.selectedOption', currentSelectedOption);
    }
  }, [selectedCoverStripOption, magazineDataFormik]);

  const [showListingImageModal, setShowListingImageModal] = useState(false);

  const toggleShowListingImageModal = () => {
    setShowListingImageModal(!showListingImageModal);
  };

  const onCancelListingImageModal = () => {
    setShowListingImageModal(false);
  };

  const handleCoverOptionChange = (option) => {
    handleUpdateMagazine(option, 'frontCoverDesign');
  };

  const handleCoverStripOptionChange = (option) => {
    handleUpdateMagazine(option, 'frontCoverStrip');
    magazineDataFormik.setFieldValue('frontCover.selectedOption', option.formKeyword);
  };

  const onHandleResetFormFields = () => {
    if (!isEmpty(previousMagazineData)) {
      const coverSelection = previousMagazineData.selections.find(
        (selection) => selection.page === 1,
      );

      if (!isEmpty(coverSelection)) {
        const {
          formKeyword,
          extraHtml: { formKeyword: formStripKeyword },
        } = coverSelection;
        const frontCover = frontCoverDesign.find((design) => design.formKeyword === formKeyword);
        const frontStrip = frontCoverStrip.find((strip) => strip.formKeyword === formStripKeyword);

        if (!isEmpty(frontCover)) {
          handleUpdateMagazine(frontCover, 'frontCoverDesign');
        }

        if (!isEmpty(frontStrip)) {
          handleUpdateMagazine(frontStrip, 'frontCoverStrip');
          magazineDataFormik.setFieldValue('frontCover.selectedOption', formStripKeyword);
        }
      }
    } else {
      handleUpdateMagazine(frontCoverDesign[0], 'frontCoverDesign');
      handleUpdateMagazine(frontCoverStrip[0], 'frontCoverStrip');
      magazineDataFormik.setFieldValue(
        'frontCover.selectedOption',
        frontCoverStrip[0].formStripKeyword,
      );
    }
    setFormikContext('current', -1);
  };

  const allowedResolutionsTextLogo = { width: 540, height: 192 };

  const renderAdditionalData = (type) => {
    switch (type) {
      case 'frontCoverStrip-option-2':
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextLogo}
            step="frontCover"
            uploadImageType="brokerageLogo"
            uploadImageTitle="Broker company logo"
            propertyKey="brokerageLogo"
            presentImageLocation={get(
              magazineDataFormik,
              'values.frontCover._formFields.brokerageLogo',
            )}
            formikHandler={magazineDataFormik}
            isOpen={showListingImageModal}
            onCancel={onCancelListingImageModal}
            handleOpenModal={toggleShowListingImageModal}
          />
        );
      case 'frontCoverStrip-option-3':
        return <SocialMediaFormComponent formikHandler={magazineDataFormik} step="frontCover" />;
      case 'frontCoverStrip-option-4':
        return <QRCodeComponent formikHandler={magazineDataFormik} step="frontCover" />;
      default:
        return null;
    }
  };

  const selectedCoverStripOptionName = get(selectedCoverStripOption, 'name');
  const selectedOptionTitle =
    frontCoverStripOptionsHeading[get(selectedCoverStripOption, 'formKeyword')];

  return (
    <div>
      <h5 className="text-neutral-800 text-sm font-semibold mt-[37px]">Select front cover</h5>

      <RenderOptionsComponent
        onImageClick={handleImageLightBox}
        optionsList={frontCoverDesign}
        handleChange={handleCoverOptionChange}
        selectedOption={selectedCoverOption}
        displayType="magazine"
      />

      <h5 className="text-neutral-800 text-sm font-semibold mt-[37px]">Select front cover strip</h5>

      <RenderOptionsComponent
        onImageClick={handleImageLightBox}
        optionsList={frontCoverStrip}
        handleChange={handleCoverStripOptionChange}
        selectedOption={selectedCoverStripOption}
        displayType="strip"
      />

      <h5 className="text-neutral-800 text-base font-semibold mt-8 mb-6">
        {selectedOptionTitle && `${selectedCoverStripOptionName}: ${selectedOptionTitle}`}
        {selectedCoverStripOptionName.toLowerCase() === 'option 4' && (
          <span className="overflow-hidden text-neutral-400 text-ellipsis  text-sm font-medium">
            A special QR code will generate once the order has been placed.
          </span>
        )}
      </h5>

      <div className="w-full">{renderAdditionalData(selectedCoverStripOption?.formKeyword)}</div>

      <div className="flex gap-2 justify-start mt-6">
        <Button color="light" onClick={onHandleResetFormFields}>
          Cancel
        </Button>
        <Button color="dark" buttonType="submit" onClick={magazineDataFormik.handleSubmit}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

export default FrontCover;
