import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import moment from 'moment'; // Ant.Design components V3 requires momentJS
import { map, get, find, isEmpty } from 'lodash-es';
import { Form, Field, useFormikContext } from 'formik';
import { Checkbox } from 'antd';
import {
  getAttributes,
  getEmailTemplate,
  getEmailTemplatesDropdown,
  getCustomerTemplatesDropdown,
  getCustomerTemplate,
  getOnDemandEmail,
  getEmailSegments,
} from 'modules/api';

import {
  SelectMultiField,
  TimeZoneDropDown,
  Loader,
  ErrorMessage,
} from 'modules/common/components';

import S from './styles';
import { parseHtmlTemplate } from './utils';

const { Option, OptGroup } = S.Select;

const OnDemandEmailsSidebar = ({ campaignName, onDemandEmailId }) => {
  const queryClient = useQueryClient();
  const { values, setFieldValue, setValues, errors } = useFormikContext();
  const { templateId, scheduleDate, allSegments, sendImmediately, segments, timezone } = values;

  const [templateContent, setTemplateContent] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(templateId);
  const [selectedCustomerTemplateId, setSelectedCustomerTemplateId] = useState(templateId);
  const [customerTemplates, setCustomerTemplates] = useState([]);
  const [userAttributes, setUserAttributes] = useState({
    memberEmail: '',
    memberFirstName: '',
    memberLastName: '',
    memberPhone: '',
    imageUrl: '',
    memberBookUrl: '',
    memberAddress: '',
    memberBrokerName: '',
  });

  useQuery('getUserAttributes', getAttributes, {
    onSuccess: (data) => {
      const attributes = get(data, 'data', {});
      setUserAttributes({ ...attributes });
    },
  });

  const { mutate: mutateCustomerTemplatesDropdown } = useMutation(getCustomerTemplatesDropdown, {
    onSuccess: ({ data }) => setCustomerTemplates(data),
  });

  const { data: templatesData } = useQuery('getEmailTemplatesDropdown', getEmailTemplatesDropdown, {
    onSuccess: (data) => {
      if (!onDemandEmailId) {
        const id = get(data, 'data[0].id');
        setSelectedTemplateId(id);
        setFieldValue('templateId', id);
      }

      mutateCustomerTemplatesDropdown();
    },
  });

  const templates = get(templatesData, 'data');

  useQuery(['getOnDemandEmail', onDemandEmailId], () => getOnDemandEmail(onDemandEmailId), {
    enabled: !!onDemandEmailId && !!templates,
    onSuccess: ({ templateName, ...currentValues }) => {
      const template = find(templates, ({ name }) => name === templateName);

      setValues({ templateId: template.id, ...currentValues });

      setSelectedTemplateId(template.id);
    },
  });

  const { isLoading: isLoadingTemplate } = useQuery(
    ['getTemplate', selectedTemplateId],
    () => getEmailTemplate(selectedTemplateId),
    {
      enabled: !!templates && !!selectedTemplateId,
      onSuccess: ({ data }) => {
        const content = get(data, 'content');
        const parsedHtml = parseHtmlTemplate(content, userAttributes);
        setTemplateContent(parsedHtml);
      },
    },
  );

  useQuery(
    ['getCustomerTemplate', selectedCustomerTemplateId],
    () => getCustomerTemplate(selectedCustomerTemplateId),
    {
      enabled: !!customerTemplates && !!selectedCustomerTemplateId,
      onSuccess: (data) => {
        const content = get(data, 'content');
        const parsedHtml = parseHtmlTemplate(content, userAttributes);
        setTemplateContent(parsedHtml);
        queryClient.invalidateQueries('getOnDemandEmails');
      },
    },
  );

  const { mutate: mutateTemplate } = useMutation(getEmailTemplate, {
    onSuccess: ({ data }) => {
      const content = get(data, 'content');
      const parsedHtml = parseHtmlTemplate(content, userAttributes);
      setTemplateContent(parsedHtml);
      queryClient.invalidateQueries('getOnDemandEmails');
    },
  });

  const { data: segmentsData } = useQuery('getEmailSegments', getEmailSegments);

  const segmentsList = get(segmentsData, 'data');

  const handleChange = ({ field, type = '' }) => {
    return (fieldData) => {
      let value = fieldData;

      if (type === 'checkbox') {
        value = fieldData.target.checked;
      }

      if (type === 'input') {
        value = fieldData.target.value;
      }

      setFieldValue(field, value);
    };
  };

  const handleTemplateChange = (id) => {
    const customerTemplate = find(customerTemplates, { id });
    const newTemplateId = customerTemplate ? customerTemplate.templateId : id;

    setFieldValue('templateId', newTemplateId);

    if (customerTemplate) setSelectedCustomerTemplateId(customerTemplate.id);

    return mutateTemplate(newTemplateId);
  };

  if (isLoadingTemplate || !selectedTemplateId) {
    return <Loader title="Loading template" />;
  }

  return (
    <S.Layout>
      <S.Sidebar theme="light">
        <S.Wrapper>
          <Form>
            <S.Title>{campaignName}</S.Title>

            <S.Label>Select Template</S.Label>

            <S.Select
              showSearch
              defaultValue={templateId || selectedTemplateId}
              name="templateId"
              placeholder="Select template here"
              onChange={handleTemplateChange}
            >
              {isEmpty(templates) ? null : (
                <OptGroup label="Templates">
                  {map(templates, ({ name: templateName, id }) => (
                    <Option key={id} value={id}>
                      {templateName}
                    </Option>
                  ))}
                </OptGroup>
              )}

              {isEmpty(customerTemplates) ? null : (
                <OptGroup label="Customer Templates">
                  {map(customerTemplates, ({ name, id }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </OptGroup>
              )}
            </S.Select>

            <S.Label>Subject</S.Label>
            <S.TextField
              name="subject"
              $hasErrors={errors.subject}
              placeholder="Untitled Subject"
            />
            {errors.subject ? <ErrorMessage>{errors.subject}</ErrorMessage> : ''}

            <S.Label>
              <Checkbox
                name="allSegments"
                onChange={handleChange({ field: 'allSegments', type: 'checkbox' })}
                checked={allSegments}
              >
                Select all segments
              </Checkbox>
            </S.Label>

            <S.Label>Select Segment</S.Label>
            <Field
              disabled={allSegments}
              hideAllSegmentsOption
              component={SelectMultiField}
              name="segments"
              placeholder="Select segment here"
              mode="multiple"
              onChange={handleChange({ field: 'segments' })}
              options={segmentsList}
              selectedSegment={segments}
              $hasErrors={errors.segments}
            />
            {values.allSegments === false && errors.segments ? (
              <ErrorMessage>{errors.segments}</ErrorMessage>
            ) : (
              ''
            )}

            <S.Label>Select Timezone</S.Label>
            <TimeZoneDropDown
              defaultValue={timezone}
              name="timezone"
              onChange={handleChange({ field: 'timezone' })}
            />

            <S.Label>
              <Checkbox
                name="sendImmediately"
                onChange={handleChange({ field: 'sendImmediately', type: 'checkbox' })}
                checked={sendImmediately}
              >
                Send Now
              </Checkbox>
            </S.Label>

            <S.Label $noMarginTop>Set Email Schedule</S.Label>
            <S.DatePicker
              defaultValue={moment(scheduleDate)}
              name="scheduleDate"
              format="YYYY-MM-DD HH:mm"
              showTime={{ format: 'HH:mm' }}
              onChange={handleChange({ field: 'scheduleDate' })}
              placeholder="Select date and time"
              disabled={sendImmediately}
            />
          </Form>
        </S.Wrapper>
      </S.Sidebar>

      <S.WrapperContent>
        <S.TemplateView dangerouslySetInnerHTML={{ __html: templateContent }} />
      </S.WrapperContent>
    </S.Layout>
  );
};

export default OnDemandEmailsSidebar;
