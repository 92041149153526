import styled from 'styled-components';
import { device as BreakPoints } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';

const BookForm = styled.div`
  text-align: left;
  width: 65%;
  @media screen and ${BreakPoints.tabletS} {
    max-width: unset;
    width: 100%;
  }
`;

const InputWrap = styled.input`
  width: 100%;
  padding: 7px 10px;
  text-align: left;
  margin: 5px 0 12px 0;
  color: ${({ error }) => (error ? color.RedFormText : color.Black)};
  border: ${({ error }) => (error ? `1px solid ${color.RedFormText}` : `1px solid ${color.Black}`)};
  border-radius: 5px;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  @media screen and ${BreakPoints.tabletS} {
    width: 100%;
  }
  &::placeholder {
    color: ${({ error }) => (error ? color.RedFormText : color.Black)};
  }
`;

const PreviewBook = styled.div`
  margin: 0 30px;
  width: 100%;
  text-align: center;
  @media screen and ${BreakPoints.tabletS} {
    margin: 10px auto !important;
  }
  @media screen and ${BreakPoints.laptopS} {
    margin: 0 30px;
  }
`;

const BackgroundBox = styled.div`
  max-width: 960px;
  width: 100%;
  margin: auto;
  overflow: auto;
  padding: 20px;
  background-color: ${color.White};
  border-radius: 5px;
  height: calc(100vh - 50px);
  max-height: 790px;
  @media screen and ${BreakPoints.laptopS} {
    width: 100%;
    height: calc(100vh - 50px);
    overflow: auto;
    padding: 28px 35px;
  }
`;

const TopHeading = styled.div`
  position: relative;
`;

const BookBackBox = styled.div`
  display: flex;
  width: 914px;
  @media screen and ${BreakPoints.tabletS} {
    display: block;
    max-width: 400px;
    margin: 0 auto;
    width: unset;
  }
`;

const Icon = styled.div`
  position: absolute;
  right: -2px;
  top: -11px;
  font-size: 29px;
  font-weight: 600;
  cursor: pointer;
  color: black;
  @media screen and ${BreakPoints.tabletS} {
    top: -17px;
    right: -23px;
    font-size: 23px;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputCheckBoxWrap = styled.input`
  margin-right: 10px;
  cursor: pointer;
  width: 16px;
  height: 32px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: unset;
`;

const BookImage = styled.img`
  width: 309px;
  height: 476px;
  margin-left: 10px;
  @media screen and ${BreakPoints.laptopL} {
    width: 250px;
    height: 100%;
    margin: 0 auto;
  }
  @media screen and ${BreakPoints.tabletS} {
    width: 171px;
    height: 260px;
  }
`;

const CoverWrap = styled.div`
  position: relative;
  @media screen and (max-width: 380px) {
    margin-left: -30px !important;
  }
  @media screen and ${BreakPoints.laptopL} {
    width: 400px;
    height: 355px;
    margin: 0 auto;
  }
  @media screen and ${BreakPoints.tabletS} {
    width: 328px;
    height: 260px;
    margin: 0 auto;
  }
`;
const UserUploadedImage = styled.img`
  object-fit: cover;
  @media screen and ${BreakPoints.tabletS} {
    left: 14px !important;
    top: 24px !important;
  }
`;

const AuthorNameBottom = styled.p`
  position: absolute;

  bottom: 22px;
  left: 50%;
  font-size: 11px;
  padding: 0px 2px;
  min-width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  @media screen and ${BreakPoints.laptopL} {
    left: 48%;
    bottom: 12px;
  }
  @media screen and ${BreakPoints.tabletS} {
    left: 45%;
    bottom: 1px;
  }
`;

const AuthorNameLeft = styled.p`
  position: absolute;
  left: 93px;
  color: ${color.White};
  padding: 0px;
  bottom: 57px;
  line-height: 7px;
  font-size: 9px;
  min-width: 75px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform: rotate(88deg);
  @media screen and ${BreakPoints.laptopL} {
    left: 55px;
    bottom: 50px;
  }
  @media screen and ${BreakPoints.tabletS} {
    left: 53px;
    bottom: 50px;
  }
`;

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }
  .ant-checkbox-inner::after {
    width: 6.714286px;
    height: 12.142857px;
  }
`;

const CheckboxText = styled.p`
  font-family: 'Roboto';
  font-weight: normal;
  margin: 0px;
  margin-left: 10px;
  font-size: 16px;
  color: ${color.Black};
`;

const ModalHeading = styled.h4`
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 9.5px;
  color: ${color.Black};
  text-align: center;
  font-family: 'Roboto';
`;

const ModalSubHeading = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
  margin-bottom: 24.5px;
  color: ${color.Black};
  text-align: center;
  font-family: 'Roboto';
`;

const FlexContainer = styled.div`
  display: flex;
  .city {
    margin-right: 10px;
  }
`;

const FlexItem = styled.div`
  width: 100%;
  .ant-select {
    width: 100%;
  }
  .ant-select-selection--single {
    padding: 7px 10px;
    text-align: left;
    margin: 5px 0 12px 0;
    color: ${({ error }) => (error ? color.RedFormText : color.Black)};
    border: ${({ error }) =>
    error ? `1px solid ${color.RedFormText}` : `1px solid ${color.Black}`};
    border-radius: 6px;
    height: 37px;
  }
  &.country-select {
    margin-right: 10px;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    color: ${({ error }) => (error ? color.RedFormText : color.Black)};
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    margin-left: 0px;
    margin-top: -3px;
  }
`;

const DisclaimerText = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${color.Black};
  width: 100%;
  font-family: 'Roboto', sans-serif !important;
`;

export default {
  AuthorNameBottom,
  AuthorNameLeft,
  BackgroundBox,
  BookBackBox,
  BookForm,
  BookImage,
  CoverWrap,
  InputWrap,
  Icon,
  InputCheckBoxWrap,
  PreviewBook,
  CheckBoxWrapper,
  Text,
  TopHeading,
  UserUploadedImage,
  CheckboxWrap,
  CheckboxText,
  ModalHeading,
  ModalSubHeading,
  FlexContainer,
  FlexItem,
  DisclaimerText,
};
