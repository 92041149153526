function ReportIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63.871" height="41.5" viewBox="0 0 63.871 41.5">
      <g id="Group_2251" data-name="Group 2251" transform="translate(-742 -345.5)">
        <g id="inbox-outline" transform="translate(742 347)">
          <ellipse
            id="Ellipse_34"
            data-name="Ellipse 34"
            cx="31.935"
            cy="7.097"
            rx="31.935"
            ry="7.097"
            transform="translate(0 25.806)"
            fill="rgba(0,0,0,0.05)"
          />
          <g id="Group_1779" data-name="Group 1779" transform="translate(8.983 0)">
            <path
              id="Path_165"
              data-name="Path 165"
              d="M54.913,12.736,44.786,1.256A2.374,2.374,0,0,0,42.843,0H21.07a2.375,2.375,0,0,0-1.943,1.255L9,12.737v9.222H54.913Z"
              transform="translate(-9 0)"
              fill="none"
              stroke="#000000"
            />
            <path
              id="Path_166"
              data-name="Path 166"
              d="M41.552,15.925c0-1.6.992-2.924,2.223-2.925H54.913V31.1c0,2.119-1.318,3.856-2.944,3.856H11.944C10.318,34.959,9,33.221,9,31.1V13H20.139c1.231,0,2.223,1.321,2.223,2.922v.022c0,1.6,1,2.9,2.233,2.9H39.319c1.23,0,2.233-1.306,2.233-2.908Z"
              transform="translate(-9 -0.025)"
              fill="#fafafa"
              stroke="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ReportIcon;
