import { Space, Divider } from 'antd';

import S from 'modules/v2/components/Payments/CardItem/styles';
import cardDetails from 'modules/v2/pages/PaymentSteps/payments';

import Card from './styles';

const PrimaryCard = ({ creditData }) => {
  if (!creditData) {
    return null;
  }

  const {
    card_type: card,
    masked_card_number: cardNumber,
    expiration_month: expirationMonth,
    expiration_year: expirationYear,
  } = creditData;

  const formattedExpirationMonth = expirationMonth.toString().padStart(2, '0');
  const formattedCardNumber = cardNumber.substring(cardNumber.length - 4, cardNumber.length);

  return (
    <Card.CardWrap>
      <S.Container>
        <S.CreditWrapper direction="horizontal">
          <div style={{ display: 'inline-flex' }}>
            <S.CardImg src={cardDetails[card]} alt={card} />
            <S.CardWrapper>
              <S.CardName>{card}</S.CardName>
              <Space direction="horizontal">
                <S.DotContainer>
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                </S.DotContainer>
                <S.Description>
                  {formattedCardNumber}
                  <Divider type="vertical" />
                  {formattedExpirationMonth}/{expirationYear}
                </S.Description>
              </Space>
            </S.CardWrapper>
          </div>
          <S.CardTag>Primary</S.CardTag>
        </S.CreditWrapper>
      </S.Container>
    </Card.CardWrap>
  );
};

export default PrimaryCard;
