import styled from 'styled-components';
import { Space as SpaceBase, Tag as BaseTag, Typography } from 'antd';
import * as color from '../../../common/theme/color';

interface DivProps {
  isActive?: boolean;
}

const ContainerWrap = styled.div`
  width: 100%;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-top: 24px;
  }
`;

const Container = styled.div<DivProps>`
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: ${(props) =>
    props.isActive
      ? `1.5px solid ${color.ActivePlusPlanSelection}`
      : `1px solid ${color.ResetButtonHoverBg}`};
  border-radius: 2px;

  .ant-radio-wrapper {
    align-items: center;
    width: 100% !important;
  }

  span.ant-radio + * {
    width: 100%;
  }

  .ant-radio {
    margin-right: 8px;
  }
`;

const Savings = styled(Typography.Text)`
  color: ${color.StatusCardBadgeFGGreen};
`;

const CardTag = styled(BaseTag)`
  background-color: ${color.PhoneLink};
  color: ${color.White};
  border-radius: 15px;
  padding: 5px 15px;
  float: right;

  @media (max-width: 576px) {
    display: none;
  }
`;

const RadioWrapper = styled(SpaceBase)`
  justify-content: space-between;
  width: 100%;
`;

const InlineGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: center;
`;

export default {
  Container,
  ContainerWrap,
  Savings,
  CardTag,
  RadioWrapper,
  InlineGroup,
};
