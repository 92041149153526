import getInstance from 'modules/api/instanceBBA';

const instance = getInstance();

export const deleteVersions = (body) => {
  const url = '/draft/delete';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
