const themes = {
  primary: {
    base: 'text-primary-500',
    hover: 'underline text-primary-400',
    focus: 'text-primary-400',
    disabled: 'text-primary-300',
  },
  danger: {
    base: 'underline text-danger-500',
    hover: 'underline text-danger-400 rounded-lg p-2 bg-red-500',
    focus: 'text-danger-400',
    disabled: 'text-danger-300',
  },
  warning: {
    base: 'text-warning-500',
    hover: 'text-warning-400',
    focus: 'text-warning-400',
    disabled: 'text-warning-300',
  },
  info: {
    base: 'text-info-500',
    hover: 'text-info-400',
    focus: 'text-info-400',
    disabled: 'text-info-300',
  },
  muted: {
    base: 'text-muted-500',
    hover: 'text-muted-400',
    focus: 'text-muted-400',
    disabled: 'text-muted-300',
  },
};
type AnchorProps = {
  children: React.ReactNode;
  theme?: 'primary' | 'danger' | 'warning' | 'info' | 'muted';
  disabled?: boolean;
  onClick?: () => void;
};

const Anchor: React.FC<AnchorProps> = ({
  children,
  theme = 'primary',
  disabled = false,
  onClick,
}) => {
  const baseStyle = themes[theme].base;
  const hoverStyle = themes[theme].hover;
  const focusStyle = themes[theme].focus;
  const disabledStyle = themes[theme].disabled;

  const className = `${baseStyle} ${
    disabled ? disabledStyle : ''
  }  focus:${focusStyle} hover:${hoverStyle.split(' ').join(' hover:')}`;

  return (
    <a
      className={className}
      style={{
        textDecoration: 'underline',
        textUnderlineOffset: '4px',
        cursor: onClick && !disabled ? 'pointer' : 'default',
      }}
      onClick={disabled ? undefined : onClick}
      tabIndex={disabled ? -1 : undefined}
      aria-disabled={disabled ? true : undefined}
    >
      {children}
    </a>
  );
};

export default Anchor;
