import { useEffect } from 'react';
import { useParams } from 'react-router';

import { Loader } from 'modules/common/components';
import { withDraft } from 'modules/draft/containers';

const Generate = ({ generateDraftFromBook, generateDraftFromOrder, goToRoot }) => {
  const { entity, id } = useParams();

  useEffect(() => {
    switch (entity) {
      case 'book':
        generateDraftFromBook(id);
        break;

      case 'order':
        generateDraftFromOrder(id);
        break;

      default:
        goToRoot();
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, id]);

  return <Loader title="Generating Book" />;
};

export default withDraft(Generate);
