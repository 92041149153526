export const ROUTE_ONBOARD = '/onboard';
export const ROUTE_ONBOARD_ERROR = `${ROUTE_ONBOARD}/error`;
export const ROUTE_ONBOARD_PLACE_ORDER = `${ROUTE_ONBOARD}/place-order/:offerCode`;
export const ROUTE_ONBOARD_CONFIRM_TRIAL = `${ROUTE_ONBOARD}/confirm-trial/:offerCode`;
export const ROUTE_ONBOARD_ADDON = `${ROUTE_ONBOARD}/addon/:offerCode`;
export const ROUTE_ONBOARD_SCHEDULE_COACHING = `${ROUTE_ONBOARD}/schedule-coaching/:offerCode`;
export const ROUTE_ONBOARD_TRAINING_WEBINAR = `${ROUTE_ONBOARD}/training-webinar/:offerCode`;
export const ROUTE_ONBOARD_BOOK_DETAILS = `${ROUTE_ONBOARD}/book-details/:offerCode`;
export const ROUTE_ONBOARD_DENTIST_GUIDE_DETAILS = `${ROUTE_ONBOARD}/dentist-guide-details/:offerCode`;
export const ROUTE_ONBOARD_YOUR_GUIDE = `${ROUTE_ONBOARD}/your-guide/:offerCode`;
export const ROUTE_ONBOARD_YOUR_BOOK = `${ROUTE_ONBOARD}/your-book/:offerCode`;
export const ROUTE_ONBOARD_PLACE_ORDER_WAIT = `${ROUTE_ONBOARD}/place-order-wait/:offerCode`;
export const ROUTE_ONBOARD_BOOK_SUMMARY = `${ROUTE_ONBOARD}/your-book/:offerCode`;

export const ROUTE_ONBOARD_GUIDE_CONFIRMATION = `${ROUTE_ONBOARD}/guide-confirmation/:offerCode`;
export const ROUTE_ONBOARD_ORDER_CONFIRMATION = `${ROUTE_ONBOARD}/order-confirmation/:offerCode`;
export const ROUTE_ONBOARD_BOOK_DETAILS_WAIT = `${ROUTE_ONBOARD}/book-details-wait/:offerCode`;
export const ROUTE_FINISH_ONBOARDING = `${ROUTE_ONBOARD}/finish-onboarding/:offerCode/:sessionId`;
export const ROUTE_ONBOARD_ACCOUNT_WAIT = `${ROUTE_ONBOARD}/account-wait/:offerCode`;

// =============================================== DENTIST PROJECT =================== //
export const ROUTE_DENTIST = '/dentist';
export const ROUTE_DENTIST_ERROR = `${ROUTE_DENTIST}/error`;
export const ROUTE_DENTIST_PLACE_ORDER = `${ROUTE_DENTIST}/place-order/:offerCode`;
export const ROUTE_DENTIST_CONFIRM_TRIAL = `${ROUTE_DENTIST}/confirm-trial/:offerCode`;
export const ROUTE_DENTIST_SCHEDULE_COACHING = `${ROUTE_DENTIST}/schedule-coaching/:offerCode`;
export const ROUTE_DENTIST_BOOK_DETAILS = `${ROUTE_DENTIST}/book-details/:offerCode`;
export const ROUTE_DENTIST_YOUR_BOOK = `${ROUTE_DENTIST}/your-book/:offerCode`;
export const ROUTE_DENTIST_PLACE_ORDER_WAIT = `${ROUTE_DENTIST}/place-order-wait/:offerCode`;
export const ROUTE_DENTIST_SUMMARY = `${ROUTE_DENTIST}/your-book/:offerCode`;

export const ROUTE_DENTIST_ORDER_CONFIRMATION = `${ROUTE_DENTIST}/order-confirmation/:offerCode`;
