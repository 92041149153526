import { noop } from 'lodash-es';
import { Modal } from 'modules/common/components';
import { CheckIconSuccess } from 'modules/common/components/UIComponents';

import S from './styles';

const CongratsModal = ({ isOpen, footer, content }) => (
  <Modal isOpen={isOpen} onConfirm={noop} onCancel={noop} width="600" footer={footer}>
    <S.ModalHeader>
      <CheckIconSuccess />
      <S.ModalTitle>Congratulations! Welcome to Authorify!</S.ModalTitle>
      {content}
    </S.ModalHeader>
  </Modal>
);

export default CongratsModal;
