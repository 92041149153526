export const StarIcon = ({ width = 16, height = 16, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconer">
      <g clipPath="url(#clip0_3919_7672)">
        <path
          id="Vector"
          d="M6.10178 3.6055C6.94622 2.09067 7.36843 1.33325 7.99967 1.33325C8.63092 1.33325 9.05313 2.09067 9.89756 3.6055L10.116 3.99741C10.356 4.42788 10.476 4.64311 10.663 4.78512C10.8501 4.92714 11.0831 4.97985 11.5491 5.08528L11.9733 5.18127C13.6131 5.55229 14.433 5.7378 14.6281 6.36507C14.8231 6.99235 14.2642 7.64597 13.1463 8.9532L12.8571 9.2914C12.5394 9.66287 12.3806 9.84861 12.3091 10.0784C12.2376 10.3082 12.2617 10.556 12.3097 11.0516L12.3534 11.5028C12.5224 13.247 12.6069 14.1191 12.0962 14.5067C11.5856 14.8944 10.8179 14.5409 9.28256 13.834L8.88535 13.6511C8.44906 13.4503 8.23091 13.3498 7.99967 13.3498C7.76844 13.3498 7.55029 13.4503 7.114 13.6511L6.71679 13.834C5.18146 14.5409 4.41379 14.8944 3.90311 14.5067C3.39242 14.1191 3.47693 13.247 3.64594 11.5029L3.68966 11.0516C3.73769 10.556 3.76171 10.3082 3.69025 10.0784C3.61879 9.84861 3.45996 9.66287 3.14229 9.2914L2.85307 8.9532C1.73517 7.64597 1.17622 6.99235 1.37129 6.36507C1.56635 5.7378 2.38625 5.55229 4.02603 5.18127L4.45027 5.08528C4.91624 4.97985 5.14923 4.92714 5.3363 4.78512C5.52338 4.64311 5.64336 4.42788 5.88332 3.99741L6.10178 3.6055Z"
          stroke={fill}
          strokeWidth="1.5"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3919_7672">
        <rect width="16" height="16" rx="5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
