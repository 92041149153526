import { spawn } from 'redux-saga/effects';

import { watchAutoSave } from './autoSave';
import { watchSave } from './save';
import { watchSaveCover } from './saveCover';
import { watchSaveContent } from './saveContent';
import { watchSavePreferences } from './savePreferences';
import { watchSaveSections } from './saveSections';
import { watchMarkCover } from './markCover';
import { watchMarkContent } from './markContent';
import { watchMarkPreferences } from './markPreferences';
import { watchMarkSections } from './markSections';

export default function* saga() {
  yield spawn(watchAutoSave);
  yield spawn(watchSave);
  yield spawn(watchSaveCover);
  yield spawn(watchSaveContent);
  yield spawn(watchSavePreferences);
  yield spawn(watchSaveSections);
  yield spawn(watchMarkCover);
  yield spawn(watchMarkContent);
  yield spawn(watchMarkPreferences);
  yield spawn(watchMarkSections);
}
