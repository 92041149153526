import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import * as color from 'modules/common/theme/color';

const Span = styled.span`
  display: block;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 600;
  touch-action: manipulation;
  outline: none;
  cursor: pointer;
  color: ${color.UserMenuText};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 !important;

  svg {
    fill: ${color.UserMenuIcon};
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 14px;
  }
`;

const Link = styled(Span.withComponent(RouterLink))`
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
`;

const RawLink = styled(Span.withComponent('a'))`
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
`;

const Text = styled.span`
  vertical-align: middle;
  font-weight: 600;
  color: ${color.UserMenuText};
`;

const ContentWrapper = styled.span`
  display: block;
  line-height: 44px;
  align-self: flex-start;
`;

export default {
  ContentWrapper,
  Span,
  Link,
  RawLink,
  Text,
};
