import { useState } from 'react';
import { map } from 'lodash-es';

import { AddressList } from 'modules/editor/components';
import { Modal } from 'modules/common/components';
import AddressCard from './components/AddressCard';

import S from './styles';

const AddressListView = ({ addresses, onSelect, onCreate, onEdit, onDelete }) => {
  const [addressEditId, setAddressEditId] = useState('');

  const handleCancel = () => {
    setAddressEditId('');
  };
  const handleOpenConfirmModal = (id) => {
    setAddressEditId(id);
  };

  const handleDeleteConfirm = () => {
    onDelete(addressEditId);
  };

  const footer = (
    <>
      <Modal.Button key="reset" type="cancel" onClick={handleDeleteConfirm}>
        Delete
      </Modal.Button>
      <Modal.Button key="submit" type="primary" onClick={handleCancel}>
        Cancel
      </Modal.Button>
    </>
  );

  const selectedAddress = addresses.find((address) => address._id === addressEditId);
  const hasSelectedAddress = Boolean(selectedAddress);
  const handleDelete = (id) => handleOpenConfirmModal(id);
  const { addressLine1, pincode, country, city, state } = selectedAddress || {};

  return (
    <AddressList onCreate={onCreate}>
      {map(addresses, (address) => (
        <AddressCard
          key={address._id}
          address={address}
          selectedAddressId={addressEditId}
          onDelete={handleDelete}
          onSelect={onSelect}
          onEdit={onEdit}
        />
      ))}

      <Modal
        footer={footer}
        title="Confirmation"
        width="590px"
        isOpen={hasSelectedAddress}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <S.Wrapper>
          <S.Title>Delete this shipping address</S.Title>

          <S.Address>{addressLine1}</S.Address>
          <S.Address>
            {city} /{state} {pincode}
          </S.Address>
          <S.Address>{country}</S.Address>
          <S.Text>Would you like to continue?</S.Text>
        </S.Wrapper>
      </Modal>
    </AddressList>
  );
};

export default AddressListView;
