import { useState, useEffect } from 'react';
import withStore from 'store/StoreContainer';
import env from 'environments';

import { isSucceeded, isFailed } from 'store/status';

import { getCustomerPortalLink, customerPortalLinkSelector } from 'modules/v2/store';
import { RedirectModal } from './components';

import S from './styles';

const CustomerPortalLink = ({
  fallbackLink,
  customerPortalLink: link,
  getCustomerPortalLink: getPortalLink,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isSucceeded(link.status)) {
      window.location.href = link.data;
    }

    if (isFailed(link.status)) {
      window.location.href = fallbackLink;
    }
  }, [link, fallbackLink]);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleGetLinkConfirm = () => {
    getPortalLink(env.REDIRECT_URL);
  };

  const handleGetLinkCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <RedirectModal
          title="Update Payment Methods"
          onConfirm={handleGetLinkConfirm}
          onCancel={handleGetLinkCancel}
        />
      )}
      <S.AddPaymentMethod onClick={handleClick}>+ Add Payment Method</S.AddPaymentMethod>
    </>
  );
};

const mapDispatchToProps = {
  getCustomerPortalLink,
};

const mapStateToProps = (state) => ({
  customerPortalLink: customerPortalLinkSelector(state),
});

export { CustomerPortalLink };

export default withStore(mapStateToProps, mapDispatchToProps)(CustomerPortalLink);
