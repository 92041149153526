import {
  GET_USER_ADDRESS,
  GET_COUNTRY_LIST,
  GET_STATES_BY_COUNTRY_NAME,
  ADD_USER_ADDRESS,
  SET_ADDRESS,
  DELETE_USER_ADDRESS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  data: {},
  country: [],
  states: {
    loading: false,
    loaded: false,
    data: [],
  },
  selected: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USER_ADDRESS}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_USER_ADDRESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data,
      };
    }

    case `${GET_USER_ADDRESS}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case `${GET_COUNTRY_LIST}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_COUNTRY_LIST: {
      return {
        ...state,
        loading: false,
        loaded: true,
        country: action.payload.data,
      };
    }

    case `${GET_COUNTRY_LIST}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case `${GET_STATES_BY_COUNTRY_NAME}_REQUEST`: {
      return {
        ...state,
        states: {
          loading: true,
        },
      };
    }

    case GET_STATES_BY_COUNTRY_NAME: {
      return {
        ...state,
        states: {
          loading: false,
          loaded: true,
          data: action.payload.data,
        },
      };
    }

    case `${GET_STATES_BY_COUNTRY_NAME}_FAILURE`: {
      return {
        ...state,
        states: {
          loading: true,
          loaded: true,
        },
      };
    }

    case ADD_USER_ADDRESS: {
      return {
        ...state,
      };
    }

    case SET_ADDRESS: {
      return { ...state, selected: action.address };
    }

    case DELETE_USER_ADDRESS: {
      let data = state.data.shippingAddress.filter((address) => address._id !== action.id);

      return {
        ...state,
        data: { ...state.data, shippingAddress: data },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
