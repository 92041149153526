import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Modal } from 'flowbite-react';
import { capitalize, isEmpty } from 'lodash';
import { compose } from 'redux';

import { fetchJourneyTodos, postJourneyTodos } from 'modules/api/common';
import { fetchUserAddresses, reAuthenticate } from 'modules/api';
import { getscheduledCoachingCall } from 'modules/api/scheduleCoachingCall';
import { JourneyContextProvider } from 'modules/v2/context/JourneyContextProvider';
import { isPending, isReady } from 'store/status';
import useTodos from 'modules/v2/context/JourneyContextReducer';
import environments from 'environments';
import { DashboardTemplate } from 'modules/v2/templates';
import { CardWithVideo, ErrorState, TaskCard } from 'modules/v2/common/components';
import { Box, VideoModal } from 'modules/v2/common/AtomicDesign/atoms';
import { withBooksStore, withOrders } from 'modules/v2/containers';
import AddToCalendarButton from 'modules/v2/common/components/AddToCalendarButton';
import { getThumbnail } from 'modules/v2/utils';
import OrderTag from 'modules/v2/common/AtomicDesign/atoms/OrderTag';
import { notification } from 'modules/v2/common/utils';
import { cn } from 'modules/v2/common/utils/cn';
import { useStatesAndCountries } from 'modules/common/hooks';
import { getData } from 'modules/auth';
import { setItem } from 'modules/dashboard/utils/legacy';
import QuestionCircle from 'modules/v2/common/components/SvgIcon/QuestionCircle';
import { ROUTE_WELCOME_JOURNEY } from 'modules/v2/routes/constants';
import ToolTip from 'modules/v2/common/AtomicDesign/molecules/Tooltip';
import { getItem } from 'modules/v2/utils/legacy';
import Button from '../../common/AtomicDesign/atoms/Button';
import { generateClickUrl } from './utils/NavigationHandler';
import { EditBook } from './Components/EditBook';
import ShippingAddress from './Components/ShippingAddress';
import PasswordSetup from './Components/PasswordSetup';
import OrderList from './Components/EditBook/components/OrderList';
import ScheduleCoachingCall from './Components/ScheduleCoachingCall';

import {
  ArrowRightTable,
  BookMiniIcon,
  CalendarIcon,
  PasswordSetupIcon,
  PinLocationIcon,
  RouteSignIcon,
  TickRound,
  VideoIcon,
} from '../../common/components/SvgIcon';
import StepCheckIcon from '../../common/components/SvgIcon/StepCheckIcon';
import {
  COACHING_CALL_STATUS,
  componentRoutes,
  TASK_NAME,
  TASK_STATUS,
  thumbnailSource,
  videoSource,
  welcomeJourneyIntroText,
  welcomeJourneyTitle,
} from './utils/constants';
import {
  getCoachFullName,
  getDateOnly,
  getDescriptionDetails,
  getFullAddress,
  getMonthOnly,
  getRemainingDays,
  getTimeDifference,
  getZoomJoinBtn,
} from './utils/utils';

function WelcomeJourney({ history, orders, getBooks, books }) {
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [customerName, setCustomerName] = useState('...');
  const [customerEmail, setCustomerEmail] = useState('');
  const [completedTaskCount, setCompletedTaskCount] = useState(3);
  const [taskCount, setTaskCount] = useState(0);
  const [welcomeOrderId, setWelcomeOrderId] = useState('');
  const [customerAddress, setCustomerAddress] = useState('Loading address...');
  const [completion, setCompletion] = useState(false);
  const [sendIntroVideoApi, setSendIntroVideoApi] = useState(true);
  const [passwordCardShow, setPasswordCardShow] = useState(true);
  const [showScheduleCallCard, setShowScheduleCallCard] = useState(false);
  const [completedTask, setCompletedTask] = useState({
    setupPassword: false,
    setupShipiingAddress: false,
    setupBookJourney: false,
    scheduleCoachingCall: false,
  });
  const [booksList, setBooksList] = useState({});
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { states, countries } = useStatesAndCountries();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [refetchCount, setRefetchCount] = useState(0);

  const handleClick = (event) => {
    event.preventDefault();
    setIsTooltipVisible(!isTooltipVisible);
  };

  const handleClickOutside = (event) => {
    event.preventDefault();
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const location = useLocation();
  const task = new URLSearchParams(location.search).get('task');
  const completed = new URLSearchParams(location.search).get('completed');
  const orderId = new URLSearchParams(location.search).get('orderId');

  const {
    data: userAddress,
    refetch: refetchUserAddresses,
    isLoading: isAddressLoading,
  } = useQuery(['getUserAddress'], () => fetchUserAddresses());

  const { data: journeyTodos, refetch: refetchTodos, isLoading } = useQuery(
    'fetchJourneyTodos',
    () => fetchJourneyTodos(),
    {
      onError: () => {
        const { userData } = getData();
        const updatedUser = {
          ...userData,
          welcomeJourneyEligible: false,
        };
        setItem('userData', updatedUser);
        window.location.href = '/';
      },
      onSuccess: (data) => {
        if (completed) {
          setCompletion(true);
        }
      },
    },
  );

  const { data: scheduledData, isLoading: isScheduledLoading } = useQuery(
    ['getCoachingCalls', customerEmail],
    () => getscheduledCoachingCall(customerEmail),
    {
      enabled: !!customerEmail,
    },
  );

  const scheduledDataDetails = scheduledData?.data?.[0] ?? null;

  let isDisableMeetingBtn = true;

  if (!isEmpty(scheduledDataDetails)) {
    isDisableMeetingBtn = getZoomJoinBtn(scheduledDataDetails?.start, scheduledDataDetails?.end);
  }

  const { initializeTodos, completedTask: taskCompleted, tasks, todos } = useTodos();

  const sidebarWidth = 250;
  const marginRight = 0;

  useEffect(() => {
    if (orderId) {
      setWelcomeOrderId(orderId);
    }
    if (completed) {
      refetchTodos();
      refetchUserAddresses();
    }
  }, [completed, refetchTodos, refetchUserAddresses]);

  useEffect(() => {
    if (!isAddressLoading && userAddress) {
      const { data } = userAddress;
      if (!isEmpty(data.data.shippingAddress)) {
        const fullAddress = getFullAddress(data.data.shippingAddress[0], countries, states);
        setCustomerAddress(fullAddress);
      }
    }
  }, [userAddress, isAddressLoading, states, countries]);

  useEffect(() => {
    if (!isEmpty(journeyTodos) || !isLoading) {
      const { data } = journeyTodos;
      const result = data.find((res) => res.status !== TASK_STATUS.COMPLETED);
      if (isEmpty(result)) {
        const hasWelcomeJourneyCompleted = new URLSearchParams(location.search).has('fromHomePage');
        const welcomeOrder = new URLSearchParams(location.search).get('welcomeOrderId');
        const { userData } = getData();
        const updatedUser = {
          ...userData,
          welcomeJourneyEligible: false,
        };
        setItem('userData', updatedUser);
        if (hasWelcomeJourneyCompleted && welcomeOrder) {
          history.push(`/?welcomeOrderId=${welcomeOrder}&welcomeJourneyCompleted`);
        } else if (hasWelcomeJourneyCompleted) {
          history.push('/?welcomeJourneyCompleted');
        } else {
          history.push('/');
        }
      }
      setTaskCount(data.length - 1);
      const response = data.find((resultData) => resultData.name === TASK_NAME.SETUPPASSWORD);
      if (isEmpty(response)) {
        setPasswordCardShow(false);
      }

      initializeTodos(data);
    }
  }, [journeyTodos, completion, isLoading]);

  useEffect(() => {
    if (!isEmpty(tasks)) {
      const result = todos.reduce(
        (acc, todo) => {
          if (todo.name === tasks?.introductionVideoJourney?.name) {
            const isIntroductionVideoIncomplete = todo.steps.some(
              (step) => step?.name === 'viewIntroductionVideo' && !step?.isCompleted,
            );

            if (isIntroductionVideoIncomplete) {
              acc.showModal = true;
            } else {
              setSendIntroVideoApi(false);
            }
          }

          acc.completedTask[todo.name] = todo.status;

          if (todo?.status === TASK_STATUS.COMPLETED) {
            acc.completedCount += 1;
          }

          return acc;
        },
        {
          showModal: false,
          completedTask: {},
          completedCount: -1,
        },
      );

      setCompletedTask(result.completedTask);
      const completedCountUpdated = result.completedCount === -1 ? 0 : result.completedCount;

      setCompletedTaskCount(completedCountUpdated);

      if (completion) {
        setShowModal(false);
        setShowNotification(true);
      } else {
        setShowModal(result.showModal);
      }
    }
  }, [tasks, todos]);

  useEffect(async () => {
    if (!isEmpty(tasks) && tasks?.scheduleCoachingCall) {
      setShowScheduleCallCard(true);
      const params = new URLSearchParams(location.search);
      // This 'schedule-coaching-call' param is comming from the URL set on HubSPot
      const isScheduleDone = params.get('schedule-coaching-call') === 'done';

      if (isScheduleDone) {
        const response = await postJourneyTodos({
          taskId: tasks?.scheduleCoachingCall?.taskId,
          stepId: tasks?.scheduleCoachingCall?.stepId?.scheduleCoachingCall,
        });
        if (response && response.status === 201) {
          setCompletedTask((prev) => ({
            ...prev,
            [tasks?.scheduleCoachingCall?.name]: TASK_STATUS.COMPLETED,
          }));
          setCompletedTaskCount(completedTaskCount + 1);
        }
      }
    }
  }, [location.search, tasks]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      setCustomerName(userData.firstname);
      setCustomerEmail(userData?.email);
    }
  }, [customerEmail, setCustomerEmail]);

  useEffect(() => {
    getBooks();
  }, [getBooks]);

  useEffect(() => {
    if (!isEmpty(books)) {
      setBooksList(books);
    }
  }, [books]);

  const userDataString = getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  useQuery(['reAuthenticate', customerEmail], () => reAuthenticate(customerEmail, true), {
    enabled: !!customerEmail && !userData.afy_package,
    refetchInterval: () => {
      return refetchCount < 10 ? 6000 : false;
    },
    onSuccess: () => {
      setRefetchCount(refetchCount + 1);
      getBooks();
    },
    onError: (error) => {
      console.error('Authentication failed:', error);
    },
  });

  const handleNavigateClick = (stepName) => {
    const path = generateClickUrl(stepName);
    return history.push(path);
  };

  const VideoModalButtonClick = async () => {
    setShowModal(false);
    if (sendIntroVideoApi) {
      try {
        const response = await postJourneyTodos({
          taskId: tasks?.introductionVideoJourney?.taskId,
          stepId: tasks?.introductionVideoJourney?.stepId?.viewIntroductionVideo,
        });

        if (!(response.status === 201)) {
          notification.error({
            title: 'Saving failed',
            description: 'Unexpected response',
          });
        }
        await refetchTodos();
      } catch (error) {
        notification.error({
          title: 'Saving failed',
          description: 'An error occurred while posting journey todos',
        });
      }
    }
  };

  const calendarEvent = {
    title: `Scheduled coaching call with ${getCoachFullName(scheduledDataDetails?.coach)}`,
    description: getDescriptionDetails(scheduledDataDetails?.start, scheduledDataDetails?.end),
    startDate: new Date(scheduledDataDetails?.start),
    endDate: new Date(scheduledDataDetails?.end),
    durationInMinutes: getTimeDifference(scheduledDataDetails?.start, scheduledDataDetails?.end),
    address: scheduledDataDetails?.location,
  };

  let content;
  let iconAddress;
  if (isAddressLoading) {
    content = 'Loading address...';
  } else if (
    !isEmpty(tasks) &&
    completedTask[tasks?.setupShippingAddress?.name] === TASK_STATUS.COMPLETED
  ) {
    content = customerAddress;
    iconAddress = <TickRound fill="#38AB86" />;
  } else {
    content = 'Where do you want us to ship your order?';
    iconAddress = <PinLocationIcon />;
  }

  let contentSetUpBook;
  let iconSetUpBook;
  if (!isEmpty(tasks) && completedTask[tasks?.setupBookJourney?.name] === TASK_STATUS.COMPLETED) {
    let contentOrder = isPending(books.status) ? 'Loading...' : '';
    if (isReady(books.status) && !isEmpty(books.data)) {
      const resultBook = books?.data.filter((book) => book?.links?.status === 'ready');
      contentOrder = resultBook?.length > 0 && (
        <OrderList
          bookTitle={capitalize(resultBook[0]?.bookTitle)}
          coverImage={getThumbnail(resultBook[0])}
          bookId={resultBook[0]?._id}
          history={history}
        />
      );
    }
    contentSetUpBook = contentOrder;
    iconSetUpBook = <TickRound fill="#38AB86" />;
  } else {
    contentSetUpBook =
      'Publishing your book is a powerful way to set yourself apart. Customize your book with your headshot and bio to make it your own.';
    iconSetUpBook = <BookMiniIcon />;
  }

  let utilityCard;

  if (passwordCardShow) {
    let contentPassword;
    let iconPassword;
    if (!isEmpty(tasks) && completedTask[tasks.setupPassword.name] === TASK_STATUS.COMPLETED) {
      contentPassword = 'Password created!';
      iconPassword = <TickRound fill="#38AB86" />;
    } else {
      contentPassword = 'Create a strong and secure password for your account.';
      iconPassword = <PasswordSetupIcon />;
    }

    utilityCard = [
      {
        id: 'pwd-setup',
        icon: iconPassword,
        title: 'Set up password',
        name: TASK_NAME.SETUPPASSWORD,
        content: contentPassword,
        onClick: () => handleNavigateClick(componentRoutes.passwordSetup),
      },
      {
        id: 'address-setup',
        icon: iconAddress,
        title: 'Your shipping address',
        name: TASK_NAME.SETUPADDRESS,
        content,
        onClick: () => handleNavigateClick(componentRoutes.yourShipingAddress),
      },
      {
        id: 'book-setup',
        icon: iconSetUpBook,
        title: 'Customize and publish your first book',
        name: TASK_NAME.SETUPBOOK,
        content: contentSetUpBook,
        onClick: () => handleNavigateClick(componentRoutes.bookSetup),
      },
    ];
  } else {
    utilityCard = [
      {
        id: 'address-setup',
        icon: iconAddress,
        title: 'Your shipping address',
        name: TASK_NAME.SETUPADDRESS,
        content,
        onClick: () => handleNavigateClick(componentRoutes.yourShipingAddress),
      },
      {
        id: 'book-setup',
        icon: iconSetUpBook,
        title: 'Customize and publish your first book',
        name: TASK_NAME.SETUPBOOK,
        content: contentSetUpBook,
        onClick: () => handleNavigateClick(componentRoutes.bookSetup),
      },
    ];
  }
  if (showScheduleCallCard) {
    let iconScheculeCall;
    if (
      !isEmpty(tasks) &&
      completedTask[tasks.scheduleCoachingCall.name] === TASK_STATUS.COMPLETED
    ) {
      iconScheculeCall = <TickRound fill="#38AB86" />;
    } else {
      iconScheculeCall = <CalendarIcon fill="#4CC9E1" width={24} height={24} />;
    }

    utilityCard.unshift({
      id: 'schedule-call',
      icon: iconScheculeCall,
      title: '(Bonus) Schedule Your VIP Coaching Call',
      name: TASK_NAME.SCHEDULECALL,
      content:
        'You can meet with one of our Success Coaches one-on-one, and have them walk you through the entire process to set up your book and plan out your marketing.',
      onClick: () => handleNavigateClick(componentRoutes.scheduleCoachingCall),
    });
  }

  const renderCurrentComponent = (step) => {
    switch (step) {
      case componentRoutes.passwordSetup:
        return <PasswordSetup history={history} />;

      case componentRoutes.yourShipingAddress:
        return <ShippingAddress history={history} />;

      case componentRoutes.bookSetup:
        return <EditBook history={history} books={booksList} />;

      case componentRoutes.scheduleCoachingCall:
        return <ScheduleCoachingCall history={history} />;

      default:
        return (
          <>
            <VideoModal
              title="Watch this video to see how to get started! 🤩"
              showModal={showModal}
              setShowModal={VideoModalButtonClick}
              videoSource={videoSource}
              icon={<ArrowRightTable width={20} height={20} />}
              onClick={VideoModalButtonClick}
            />
            <Modal
              show={showNotification}
              onClose={() => setShowNotification(false)}
              size="xl"
              dismissible
              position="center"
            >
              <Modal.Body className="flex flex-col justify-center items-center gap-4 p-8">
                <div className="w-[50px] h-[50px] bg-success-500 rounded-full p-1.5 flex items-center justify-center">
                  <StepCheckIcon stroke="#FFF" width={30} height={30} strokeWidth={3} />
                </div>
                {welcomeOrderId && <OrderTag className="mb-2">Order# {welcomeOrderId}</OrderTag>}
                <h3 className="text-base font-bold">
                  Great Job! 🥳 Just {taskCount - completedTaskCount} more tasks to go.{' '}
                </h3>
                <p className="text-sm text-neutral-500 font-normal">
                  {welcomeOrderId
                    ? 'Your order is confirmed!. Please allow 7-10 days for us to print and ship your order.'
                    : 'Fantastic job on taking care of your action steps!'}
                </p>
                <Button
                  color="light"
                  onClick={() => {
                    setCompletion(false);
                    setShowNotification(false);
                    handleNavigateClick('');
                  }}
                >
                  Continue
                </Button>
              </Modal.Body>
            </Modal>
            <div
              className="flex flex-col w-full items-center justify-center mb-6 gap-6 bg-neutral-50"
              onClick={isTooltipVisible ? handleClickOutside : null}
            >
              <div className="w-full">
                <h1 className="text-neutral-800 tracking-normal text-2xl font-[700] pb-0">
                  {welcomeJourneyTitle(customerName)}
                </h1>
                <p className="text-sm font-normal leading-[23px] text-[#818181] tracking-normal">
                  {welcomeJourneyIntroText}
                </p>
              </div>

              <CardWithVideo
                imageSource={thumbnailSource}
                title="Watch this video to see how to get started! 🤩"
                content="This one-minute video guides you through the main steps to get started. First, you'll add your headshot, logo, and bio. Then, you'll publish your book."
                iconClick={() => setShowModal(true)}
              />
              {!isScheduledLoading && !isEmpty(scheduledDataDetails) && (
                <Box className="w-full flex flex-col lg:flex-row justify-between gap-4 p-6">
                  <div className="flex gap-4 w-full">
                    <div className="bg-primary-400 flex flex-col items-center justify-center py-[14px] px-[16px] rounded-md w-[56px] h-[70px]">
                      <span className="font-semibold text-neutral-800 text-xl">
                        {getDateOnly(scheduledDataDetails?.start)}
                      </span>
                      <span className="uppercase text-neutral-800 text-sm font-normal">
                        {getMonthOnly(scheduledDataDetails?.start)}
                      </span>
                    </div>
                    <div className="flex flex-col gap-0 w-full overflow-hidden">
                      {getRemainingDays(scheduledDataDetails?.start, scheduledDataDetails?.end) && (
                        <div
                          className={cn(
                            'flex justify-center max-w-32 h-[24px] items-center bg-success-50 text-success-500 rounded-md px-2 py-1',
                            {
                              'bg-danger-50 text-danger-500 max-w-24':
                                getRemainingDays(
                                  scheduledDataDetails?.start,
                                  scheduledDataDetails?.end,
                                ) === COACHING_CALL_STATUS.MISSED,
                            },
                            {
                              'max-w-16':
                                getRemainingDays(
                                  scheduledDataDetails?.start,
                                  scheduledDataDetails?.end,
                                ) === COACHING_CALL_STATUS.TODAY,
                            },
                          )}
                        >
                          {getRemainingDays(scheduledDataDetails?.start, scheduledDataDetails?.end)}
                        </div>
                      )}
                      {!showScheduleCallCard && (
                        <div className="flex gap-1 w-full truncate">
                          <span className="text-base text-neutral-800 font-medium truncate">
                            {calendarEvent.title}
                          </span>
                          <ToolTip
                            arrow
                            trigger="hover"
                            style="dark"
                            placement="top"
                            content="They will give you an intro demo of Authorify and show you how other agents are having success with the program."
                            theme={{
                              base: 'rounded-md py-2 px-2 text-sm font-medium',
                              content:
                                'relative whitespace-normal z-20 max-w-[225px] min-h-[64px] break-word bg-neutral-800',
                            }}
                          >
                            <QuestionCircle />
                          </ToolTip>
                        </div>
                      )}
                      <span className="text-[#818181] text-sm font-normal truncate">
                        {calendarEvent.description}
                      </span>
                    </div>
                  </div>
                  {getRemainingDays(scheduledDataDetails?.start, scheduledDataDetails?.end) && (
                    <div className="flex flex-col md:flex-row w-full justify-center lg:flex-row lg:justify-end items-center mt-5 xl:mt-0 gap-2">
                      <Button
                        type="outlined"
                        onClick={() =>
                          window.open(calendarEvent?.address, 'Zoom Meeting', 'noopener')
                        }
                        disabled={isDisableMeetingBtn}
                        className="font-medium w-full xl:w-auto disabled:bg-neutral-200 disabled:text-neutral-500 disabled:border-neutral-200 h-[46px]"
                        wrapperClassName="font-medium disabled:bg-neutral-200 disabled:text-neutral-500 disabled:border-neutral-200 w-full xl:w-auto"
                        full={windowSize < 1920}
                      >
                        <VideoIcon
                          fill={!isDisableMeetingBtn ? '#fff' : '#F4F4F4'}
                          stroke={!isDisableMeetingBtn ? '#252525' : '#BABABA'}
                        />
                        <span
                          className={cn('mt-0.5 ml-2 text-sm font-semibold ', {
                            'text-neutral-800': !isDisableMeetingBtn,
                          })}
                        >
                          Join zoom call
                        </span>
                      </Button>
                      {isDisableMeetingBtn && (
                        <AddToCalendarButton
                          calendarEvent={calendarEvent}
                          className="w-full font-medium xl:w-auto"
                          buttonClassName="w-full font-medium xl:w-auto"
                          isTooltipVisible={isTooltipVisible}
                          handleClick={handleClick}
                        />
                      )}
                    </div>
                  )}
                </Box>
              )}

              <Box className="w-full flex flex-col">
                <div className="w-full flex items-center justify-center gap-y-4 gap-x-5 py-3.5 px-6 border-b h-[72px]">
                  <RouteSignIcon fill="#FF8A33" width={30} height={30} />
                  <div className="w-full flex flex-col gap-0">
                    <h3 className="text-base text-neutral-800 font-semibold pb-0 tracking-normal">
                      Your action steps
                    </h3>
                    <div className="text-cardTextColor">
                      {completedTaskCount} / {taskCount} steps completed
                    </div>
                  </div>
                </div>
                <div className="p-6 grid grid-cols-1 xl:grid-cols-2 gap-3">
                  {utilityCard.map((card) => {
                    return (
                      <TaskCard key={card.id} task={card} completedTask={completedTask[card.name]}>
                        <div className="p-5 font-light text-cardTextColor min-h-[80px] h-auto">
                          {card.content}
                        </div>
                        <div className="p-5 pt-0 flex flex-col items-start relative">
                          <Button
                            color="dark"
                            onClick={card.onClick}
                            disabled={
                              completedTask[card.name] !== 'todo' && !!completedTask[card.name]
                            }
                            className="disabled:bg-neutral-200 disabled:text-neutral-500 disabled:border-neutral-200 h-[46px] font-semibold"
                          >
                            {completedTask[card.name] !== 'todo' && !!completedTask[card.name]
                              ? 'Completed'
                              : 'Continue'}
                          </Button>
                        </div>
                      </TaskCard>
                    );
                  })}
                </div>
              </Box>
            </div>
          </>
        );
    }
  };
  return (
    <JourneyContextProvider>
      <DashboardTemplate
        sidebarWidth={sidebarWidth}
        isLoading={isLoading}
        marginRight={marginRight}
        hasSidebar
        isError={false}
        error={
          <ErrorState
            topMessage="Our system encountered an error while loading your plans information."
            bottomMessage={
              <>
                Please call our customer service at <b>{environments.SUPPORT_PHONE}</b>.
              </>
            }
          />
        }
      >
        {task && (
          <div
            className="text-sm font-normal text-neutral-700 gap-0 bg-neutral-50 md:max-w-[700px] lg:max-w-[1408px] mx-auto flex items-center cursor-pointer"
            onClick={() => history.push(ROUTE_WELCOME_JOURNEY)}
          >
            <ArrowRightTable className="realtive transform rotate-180 w-4 h-4 flex items-center justify-center" />
            <span className="px-2">Your action steps</span>
          </div>
        )}
        <div className="md:max-w-[700px] lg:max-w-[1408px] mx-auto mt-6 min-h-screen">
          {renderCurrentComponent(task)}
        </div>
      </DashboardTemplate>
    </JourneyContextProvider>
  );
}

export default compose(withOrders, withBooksStore)(WelcomeJourney);
