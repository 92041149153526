import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';
import { UPDATE_PASSWORD, UPDATE_PREFERENCES } from 'modules/v2/store/constants';
import { user as initialState } from 'modules/v2/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_PREFERENCES}_REQUESTED`: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          status: STATUS_LOADING,
        },
      };
    }

    case `${UPDATE_PREFERENCES}_SUCCEEDED`: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          status: STATUS_SUCCESS,
        },
      };
    }

    case `${UPDATE_PREFERENCES}_FAILED`: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          status: STATUS_ERROR,
        },
      };
    }

    case `${UPDATE_PASSWORD}_REQUESTED`: {
      return {
        ...state,
        password: {
          ...state.password,
          status: STATUS_LOADING,
        },
      };
    }

    case `${UPDATE_PASSWORD}_SUCCEEDED`: {
      return {
        ...state,
        password: {
          ...state.password,
          status: STATUS_SUCCESS,
        },
      };
    }

    case `${UPDATE_PASSWORD}_FAILED`: {
      return {
        ...state,
        password: {
          ...state.password,
          status: STATUS_ERROR,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
