import styled from 'styled-components';
import { Divider } from 'antd';
import { PrimaryButton } from 'modules/common/components';

const CenterSection = styled.div`
  text-align: center;
  padding: 20px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 576px) {
    padding: 0;
    height: 100vh;
  }
`;

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const FormTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

const FormDivider = styled(Divider)`
  margin: 0 !important;
`;

const InlinePrimaryButton = styled(PrimaryButton)`
  display: inline !important;
`;

export default {
  CenterSection,
  Frame,
  FormTitle,
  FormDivider,
  InlinePrimaryButton,
};
