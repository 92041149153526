import handlebars from 'handlebars';

import { mapImages } from 'modules/v2/editor/utils';

const compileTemplate = (template, elements) => {
  let compiled = '';

  let auxTemplate = template;

  const hasHousingLogo = elements.some(({ id }) => id === 'housingLogo');
  const hasRealtorLogo = elements.some(({ id }) => id === 'realtorLogo');

  const regexPosition = /transform="translate\((803.75|603.75) 2068.5\) scale\(0\.17\)"/g;

  if (!(hasHousingLogo && hasRealtorLogo)) {
    auxTemplate = template.replace(
      regexPosition,
      'transform="translate(703.75, 2068.5) scale(0.17)"',
    );
  }

  try {
    const compiler = handlebars.compile(auxTemplate);
    const variables = mapImages(elements);

    compiled = compiler(variables);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return compiled;
};

export { compileTemplate };
