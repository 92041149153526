import styled, { createGlobalStyle } from 'styled-components';
import { Skeleton as SkeletonBase, Typography, Dropdown as BaseDropdown } from 'antd';
import * as color from 'modules/common/theme/color';

const FilterCantainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin: 15px 0;
`;

const FilterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FilterLabel = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1e1e1e;
  margin-right: 6px;

  .table {
    color: ${color.gray100};
  }
`;

const ChartLegend = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
`;

const SkeletonImage = styled(SkeletonBase.Image)`
  .ant-skeleton-image {
    width: 100%;
    height: 400px;
    svg {
      display: none;
    }
  }
`;

const NoDataTableContainer = styled.div`
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DropdownStyle = createGlobalStyle`
  .export-dropdown {
    width: 300px !important;
  }
  .export-dropdown ul, .export-dropdown ul li {
    border-radius: 10px;
    &:hover {
      background: #fff;
    }
  }
`;

const Dropdown = styled(BaseDropdown)`
  .export-dropdown {
    width: 500px !important;
  }
`;

const ExportTitle = styled(Typography.Title)`
  font-weight: 800 !important;
`;

const ExportDescription = styled(Typography.Text)`
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
  display: block !important;
`;

const ExportDialog = styled.div`
  padding: 10px;
`;

export default {
  FilterCantainer,
  FilterItem,
  FilterLabel,
  SkeletonImage,
  ChartContainer,
  ChartLegend,
  NoDataTableContainer,
  ExportDialog,
  ExportTitle,
  ExportDescription,
  DropdownStyle,
  Dropdown,
};
