import { getInstance } from './instanceApiKey';

const instanceApiKey = getInstance();

export const getPaymentProfilesByEmail = (email) => {
  const url = 'payment-chargify/payment-profiles-list';

  const payload = {
    user: {
      email,
    },
  };

  return instanceApiKey.post(url, payload);
};

export const setCustomerPassword = (payload) => {
  const url = 'upsell/customer/set-password';

  return instanceApiKey.post(url, payload);
};
