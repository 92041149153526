import { Input as BaseInput } from 'antd';
import { Form as BaseForm } from 'formik';
import BaseInputMask from 'react-input-mask';
import styled from 'styled-components';

import { PrimaryButton, SecondaryButton } from 'modules/v2/common/components';
import * as color from 'modules/v2/common/theme/color';

const Form = styled(BaseForm)`
  display: block;
`;

const inputStyle = `
  height: 45px;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  height: 45px;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #2e3f50;

  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const Input = styled(BaseInput)`
  ${inputStyle}
`;

const InputMask = styled(BaseInputMask)`
  padding: 4px 10px;
`;

const Button = styled(PrimaryButton)`
  margin: 10px 15px;
  width: 100%;
`;

const CancelButton = styled(SecondaryButton)`
  border: none !important;
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 0 15px;

  .ant-select-selection__rendered {
    line-height: 41px;
  }

  .ant-select-selection--single {
    height: 45px;
  }
`;

const InfoText = styled.span`
  font-weight: 400;
  color: ${color.Body};
  padding: 15px;
`;

const Wrapper = styled.div`
  min-height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  flex: 1;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CreditCardInput = styled.div`
  width: 100%;
  flex: 1;

  iframe {
    width: 100% !important;
  }

  &:nth-child(2n) {
    margin-left: 10px;
  }
`;

export default {
  CreditCardInput,
  Button,
  CancelButton,
  FieldGroup,
  FieldControl,
  FlexContainer,
  Form,
  FormContainer,
  InfoText,
  Input,
  InputMask,
  Wrapper,
};
