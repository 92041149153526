import styled from 'styled-components';

import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';

const YourBookContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const BookContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media ${device.tabletS} {
    margin: auto;
  }
`;

const BookCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  width: calc(100% - 188px);

  .ant-btn {
    padding: 0;
  }
`;

const YourBookTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  margin-top: 10px;
`;

const BookLink = styled.a`
  max-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${color.Black};
`;

const FakeInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 344px;
  height: 48px;
  padding: 4px 12px;

  background-color: ${color.White};
  border: 1px solid ${color.gray200};
  border-radius: 4px;

  font-size: 16px;
`;

const BookLinksContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const BookBox = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${color.White};
  border-radius: 4px;
  border: 2px solid ${color.BoxBorder};
  display: flex;
  flex-direction: column;
  align-itens: center;
  justify-content: center;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

export default {
  YourBookContainer,
  BookContainer,
  BookCardContainer,
  YourBookTitle,
  FakeInput,
  BookLink,
  BookLinksContainer,
  BookBox,
};
