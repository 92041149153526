import { filter, has, map, transform } from 'lodash-es';

const BOOK_TITLE = 'bookTitleCheck';
const AUTHOR_NAME = 'authorNameCheck';
const CONTACT_DETAILS = 'contactDetailsCheck';
const AUTHOR_IMAGE_UPLOAD = 'imageUplaodCheck';
const AUTHOR_IMAGE_WIDTH_SIZE = 'authoreImageWidthCheck';
const AUTHOR_IMAGE_HEIGHT_SIZE = 'authorImageHeightCheck';
const AUTHOR_IMAGE_TYPE = 'authorImageTypeCheck';
const BROKER_LOGO = 'brokerLogoCheck';
const HOUSING_LOGO = 'housingLogoCheck';
const BROKER_INFORMATION = 'brokerInfoCheck';
const CONTENT = 'content';

const descriptions = {
  [BOOK_TITLE]: 'Book title present on the book cover?',
  [AUTHOR_NAME]: 'Author’s name present on the book cover',
  [CONTACT_DETAILS]: 'Contact details populated',
  [AUTHOR_IMAGE_UPLOAD]: 'Head shot image added to book cover',
  [AUTHOR_IMAGE_WIDTH_SIZE]: 'Head shot image meets minimum width (500 pixels)',
  [AUTHOR_IMAGE_HEIGHT_SIZE]: 'Head shot image meets minimum height (500 pixels)',
  [AUTHOR_IMAGE_TYPE]: 'Head shot image file type correct (jpeg, jpg, or png)',
  [BROKER_LOGO]: 'Broker logo added to back cover',
  [HOUSING_LOGO]: 'Fair Housing logo added to back cover',
  [BROKER_INFORMATION]: 'Broker information field populated',
  [CONTENT]: (displayName) => `Some edits are remaining for ${displayName} section`,
};

export const getPreflightCheckItems = (criteria = {}) => {
  const filterer = ({ status }) => !status;
  const mapper = ({ _id, displayName, status }) => {
    const getText = descriptions[CONTENT];
    return {
      id: _id,
      text: getText(displayName),
      checked: status,
    };
  };

  const transformer = (result, value, key) => {
    if (has(descriptions, key)) {
      if (key === CONTENT) {
        const filtered = filter(value, filterer);
        const mapped = map(filtered, mapper);
        result.push(...mapped);
      } else {
        const item = {
          text: descriptions[key],
          checked: value.status,
        };

        result.push(item);
      }
    }
  };

  return transform(criteria, transformer, []);
};
