import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './trustOffer.png';

const isProduction = env.ENVIRONMENT === 'production';

const OFFER_ID_PRODUCTION = '654b6da90329a5197ff40c1f';
const OFFER_ID_NON_PRODUCTION = '652d2b7ac5dbf3f3afac3c94';

const trustOffer = {
  offerDescription: [
    'The entire Instant Trust Package',
    'The Agent Comparison Chart',
    'The editable 18-Step Marketing Plan',
    'The Reference Letter Worksheet',
    'The Introduction Cover Letter',
    'The Photo Brochure that shows you’re an expert',
    'Complete A-to-Z Instructions on how to set up your Instant Trust Package and send it to sellers',
    'The Complete Guide to Finding Unlimited Leads',
    'The Guide on how to Outsource Seller Lead Research to a VA',
  ],
  productHandler: 'trust-offer',
  componentId: 'handle:clickfunnel-1',
  summaryPrice: '$1',
  summaryTitle:
    'Instant Trust Package Canada (Bonus: The Complete Guide to Finding Unlimited Leads)',
  offerImage,
  upsell: {
    productHandler: 'monthly-with-trial-10',
    productPricePointHandle: 'monthly-with-trial-10',
    componentId: isProduction ? '1824463' : '2197555',
    component: () => <Upsell productName="Trust Guide" />,
    offerId: isProduction ? OFFER_ID_PRODUCTION : OFFER_ID_NON_PRODUCTION,
  },
};

export default trustOffer;
