import { get, mapValues, merge } from 'lodash-es';

export const insertPreferenceByKey = (preferences, key, value) => {
  const mapper = (preferenceValue, preferenceKey) => {
    if (key !== preferenceKey) {
      return preferenceValue;
    }

    switch (preferenceKey) {
      case 'name':
      case 'email':
      case 'phone':
      case 'startChaptersInRecto': {
        return value;
      }

      case 'authorImageFront':
      case 'authorImageBack':
      case 'brokerInfo':
      case 'brokerLogo':
      case 'realtorLogo':
      case 'housingLogo': {
        return {
          ...preferenceValue,
          status: Boolean(value),
          value,
        };
      }

      default: {
        return preferenceValue;
      }
    }
  };

  return mapValues(preferences, mapper);
};

export const insertPreferenceStatusByKey = (preferences, key, status) => {
  const mapper = (preferenceValue, preferenceKey) => {
    if (key !== preferenceKey) {
      return preferenceValue;
    }

    switch (preferenceKey) {
      case 'brokerInfo':
      case 'brokerLogo':
      case 'realtorLogo':
      case 'housingLogo': {
        return {
          ...preferenceValue,
          status,
        };
      }

      default: {
        return preferenceValue;
      }
    }
  };

  return mapValues(preferences, mapper);
};

export const mapPreferences = (preferences, fields) => {
  const mapper = (value, key) => {
    switch (key) {
      case 'authorImageFront':
      case 'authorImageBack':
      case 'brokerInfo':
      case 'brokerLogo':
      case 'realtorLogo':
      case 'housingLogo': {
        return {
          status: Boolean(value),
          value: get(preferences, [key, 'value']),
        };
      }

      case 'name':
      case 'email':
      case 'phone':
      default: {
        return value;
      }
    }
  };

  const mapped = mapValues(fields, mapper);
  const merged = merge({}, preferences, mapped);

  return merged;
};

export const getPreferenceKeyByElementId = (id) => {
  switch (id) {
    case 'authorImageFront': {
      return 'authorImageFront';
    }
    case 'authorImageBack': {
      return 'authorImageBack';
    }

    case 'authorName': {
      return 'name';
    }

    case 'brokerInfo': {
      return 'brokerInfo';
    }

    case 'brokerLogo': {
      return 'brokerLogo';
    }

    case 'realtorLogo': {
      return 'realtorLogo';
    }

    default:
      return null;
  }
};
