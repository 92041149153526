import withStore from 'store/StoreContainer';

import {
  booksStateSelector,
  categoriesSelector,
  headshotSelector,
  preferencesSelector,
  getBooks,
  getCategories,
  selectCategory,
  uploadHeadshot,
  updatePreferences,
} from 'modules/dashboard/store';

const mapDispatchToProps = {
  getBooks,
  getCategories,
  selectCategory,
  uploadHeadshot,
  updatePreferences,
};

const mapStateToProps = (state) => ({
  books: booksStateSelector(state),
  categories: categoriesSelector(state),
  headshot: headshotSelector(state),
  preferences: preferencesSelector(state),
});

export default withStore(mapStateToProps, mapDispatchToProps);
