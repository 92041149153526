//
import { PrimaryButton } from 'modules/common/components';
import { Item, Address } from './components';
import S from './styles';

const OrderReview = ({ children, onComplete, 'data-testid': dataTestId }) => (
  <>
    <S.ListWrapper>{children}</S.ListWrapper>
    {onComplete && (
      <S.ButtonWrapper>
        <PrimaryButton data-testid={dataTestId} onClick={onComplete} type="primary">
          Complete Order
        </PrimaryButton>
      </S.ButtonWrapper>
    )}
  </>
);

OrderReview.Item = Item;
OrderReview.Address = Address;

export default OrderReview;
