import styled from 'styled-components';
import { CheckCircleFilled } from '@ant-design/icons';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-height: 67vh;
  padding: 24px;
`;

const Icon = styled(CheckCircleFilled)`
  font-size: 46px !important;
  color: ${color.SuccessSaving};
  border: 16px solid rgba(92, 163, 64, 0.3);
  border-radius: 50%;
`;

const OrderNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 282px;
  padding: 12px;
  background-color: ${color.White};
  border: 1px solid ${color.LightGray};
  border-radius: 4px;
`;

const Title = styled.p`
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  margin-right: 2px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  max-width: 532px;
  font-size: 14px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

const ButtonContainer = styled.div`
  min-width: 328px;
`;

const ButtonText = styled.span`
  text-transform: uppercase;
`;

export default {
  Container,
  Icon,
  Title,
  Description,
  OrderNumberContainer,
  ButtonContainer,
  ButtonText,
};
