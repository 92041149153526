import { Checkbox as CheckboxBase } from 'antd';
import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const Checkbox = styled(CheckboxBase)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${color.LightBlue} !important;
    border-color: ${color.LightBlue} !important;

    &:hover {
      background-color: ${color.LightBlue} !important;
      border-color: ${color.LightBlue} !important;
    }
  }
`;

export { Checkbox };
