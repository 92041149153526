export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const SUNDAY = 7;
export const SKIP_DAYS = 3;
export const SKIP_DAYS_WITH_WEEKEND = 5;

export const DATE_FORMAT = 'yyyy-LL-dd';
