import { Space, Typography } from 'antd';
import AddPaymentProfile from 'modules/v2/components/AddPaymentProfile';
import OtherCards from 'modules/v2/pages/Payments/components/OtherCards';
import PrimaryCard from 'modules/v2/pages/Payments/components/PrimaryCard';
import { useQuery } from 'react-query';

import env from 'environments';
import { getDefaultPaymentProfile, getPaymentProfilesList } from 'modules/api';
import { ErrorState } from 'modules/v2/common/components';
import { BillingSideNav } from 'modules/v2/components';
import { DashboardTemplate } from 'modules/v2/templates';

import S from './styles';

const { Text } = Typography;

export const Payments = () => {
  const {
    data: paymentData = [],
    isLoading: isFetching,
    refetch: refetchProfiles,
  } = useQuery('getPaymentProfilesList', getPaymentProfilesList);

  const { data: defaultProfile, refetch: refetchDefaultProfile } = useQuery(
    'getDefaultPaymentProfile',
    getDefaultPaymentProfile,
  );

  const profiles = paymentData?.filter((item) => item.id !== defaultProfile?.id);
  return (
    <DashboardTemplate
      sidebar={<BillingSideNav />}
      hasSidebar
      error={
        <ErrorState
          topMessage="Our system encountered an error while loading your billing information."
          bottomMessage={
            <>
              Please call our customer service at <b>{env.SUPPORT_PHONE}</b>.
            </>
          }
        />
      }
      isLoading={isFetching}
    >
      <S.TableWrap>
        <S.TopWrap>
          <Space direction="vertical">
            <S.Title>Payment Method</S.Title>
            <Text>
              Select or update your primary payment method. Your primary card will be used for your
              future billing
            </Text>
          </Space>
        </S.TopWrap>
        <S.Section>
          <PrimaryCard creditData={defaultProfile} />
        </S.Section>
        <S.Section>
          {profiles?.length > 0 && (
            <>
              <Text>Other saved cards</Text>
              <OtherCards
                profiles={profiles}
                refetchDefaultProfile={refetchDefaultProfile}
                refetchProfiles={refetchProfiles}
              />
            </>
          )}
        </S.Section>
        <S.Section>
          <AddPaymentProfile refetchOnSuccess={refetchProfiles} />
        </S.Section>
      </S.TableWrap>
    </DashboardTemplate>
  );
};

export default Payments;
