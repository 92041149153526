import withStore from 'store/StoreContainer';
import { passwordSelector, updatePassword } from 'modules/dashboard/store';

const mapDispatchToProps = {
  updatePassword,
};

const mapStateToProps = (state) => ({
  password: passwordSelector(state),
});

export default withStore(mapStateToProps, mapDispatchToProps);
