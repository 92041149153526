import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const UpdateCoach = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const EditBtn = styled(Link)`
  display: flex;
  width: 100%;
  border: none;
  color: ${color.SkyBlue};

  cursor: pointer;

  .anticon svg {
    font-size: 13px;
  }
`;

const DeteleBtn = styled.button`
  display: flex;
  width: 100%;
  border: none;
  background: none;
  color: ${color.SkyBlue};

  cursor: pointer;

  .anticon svg {
    font-size: 13px;
  }
`;

export default {
  UpdateCoach,
  EditBtn,
  DeteleBtn,
};
