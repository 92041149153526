import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { fetchSession } from 'modules/api';
import { Loader } from 'modules/common/components';
import { AuthorifyIcon } from 'modules/common/components/SvgIcon';
import { setSession, STEPS } from 'modules/onboard/utils';
import { getRouteNextStep } from 'modules/onboard/routes/navigation';
import { notification } from 'modules/common/utils';

const FinishOnboarding = ({ history }) => {
  const { offerCode, sessionId } = useParams();

  const queryString = window.location.search;

  const { isLoading: isSessionLoading, data: sessionData } = useQuery(
    'fetchSession',
    () => fetchSession(offerCode, sessionId),
    {
      onSuccess: ({ data }) => {
        setSession(data);
        history.push(getRouteNextStep(offerCode, STEPS[data.step].path, queryString));
      },
      onError: (error) => {
        notification.error({
          description: error.response.data.errors?.[0] || error?.data?.message || 'Network Error',
        });
      },
    },
  );
  if (isSessionLoading) {
    return (
      <Loader
        height="100%"
        hasIcon
        title="Please Wait"
        description="Please wait while we are loading finish onobarding page."
        icon={<AuthorifyIcon />}
      />
    );
  }
  return <div>{sessionData}</div>;
};

export default FinishOnboarding;
