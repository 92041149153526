import S from './styles';

const FormLabel = ({ title, children, required, space = false, error = false, ...extra }) => (
  <S.Label {...extra}>
    {space && <S.Space />}
    {title && <S.Title>{title}</S.Title>}
    {required && <S.RequiredSymbol>*</S.RequiredSymbol>}
    {children}
    {error && <S.Error>{error}</S.Error>}
  </S.Label>
);
export default FormLabel;
