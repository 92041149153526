import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BackArrowIcon as Icon } from 'modules/common/components';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Title = styled.h3`
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 24px !important;
  margin-top: 10px !important;

  font-size: 24px;
  font-family: ${font.LoraFont} !important;
  letter-spacing: 0px;
  line-height: 32px;
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 22px;
`;

const Description = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 22px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: fit-content;
  margin-bottom: 20px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: fit-content;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IframeWrapper = styled.div`
  height: 421px;
  margin-bottom: 20px;
`;

const BackArrowIcon = styled(Icon)``;

const BackPage = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 11px;

  font-size: 16px;
  color: ${color.PrimaryColor};

  cursor: pointer;

  &:hover {
    color: ${color.SecondaryColor} !important;
  }
`;

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 5px;
`;

const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-size: 16px;
  color: ${color.PrimaryColor};

  cursor: pointer;

  &:hover {
    color: ${color.SecondaryColor} !important;
  }
`;

const SelectFile = styled.input.attrs({ type: 'file' })`
  font-size: 16px;
  display: flex;
  padding: 20px;
  border: 1px dashed #dbdbdb;
  span {
    color: ${color.SkyBlue};
  }
  svg {
    width: 40px;
    height: 40px;
  }
  p {
    color: #8d8888;
  }
`;

const UploadText = styled.div`
  color: ${color.GreySelectDd};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  padding: 5px 0;
`;

const DragDropWrapper = styled.div`
  border: 2px dotted ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${({ showWhite }) => (showWhite ? color.White : color.GreyMenuItemHover)};
  font-weight: normal;
  font-size: 16px;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 200px;
  > div {
    width: 100%;
    outline: 0;
    cursor: pointer;
  }

  svg {
    fill: #9c9a9a;
    width: 75px;
    height: 45px;
    margin: 0 auto;
    display: block;
  }
`;

const SendToPrintButton = styled.button`
  color: rgb(255, 255, 255);
  font-size: 16px;
  border-radius: 5px !important;
  padding: 0px 25px !important;
  font-weight: normal !important;
  cursor: pointer !important;
  vertical-align: bottom;
  border: 2px dotted #1890ff;
  border-radius: 3px;
  background-color: #1890ff;
  margin: 20px;
  font-weight: normal;
  font-size: 16px;
  min-height: 90px;
  justify-content: center;
  text-align: center;
`;

export default {
  Title,
  ButtonContainer,
  PreviewContainer,
  SubTitle,
  Description,
  DescriptionContainer,
  DatePickerContainer,
  IframeWrapper,
  BackArrowIcon,
  BackPage,
  ContainerWrapper,
  DownloadButton,
  SelectFile,
  UploadText,
  DragDropWrapper,
  SendToPrintButton,
};
