import { DateTime } from 'luxon';
import S from './styles';

const TimeCard = ({ slot, timezone: zone, meeting }) => {
  const dateMessage = DateTime.fromISO(slot, { zone }).toFormat('DDDD t (z ZZ)');

  if (!slot) {
    return null;
  }

  return (
    <S.ScheduleCallDateWrapBox>
      <S.ScheduleCallDateHeading>This {meeting} will be scheduled on</S.ScheduleCallDateHeading>
      <S.ScheduleCallDate>{dateMessage}</S.ScheduleCallDate>
    </S.ScheduleCallDateWrapBox>
  );
};

export default TimeCard;
