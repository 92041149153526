import { hasAccessToDMPOneDotFive } from 'modules/auth';

import { routes as auth } from 'modules/auth/routes';
import { routes as dashboard } from 'modules/dashboard/routes';
import { routes as draft } from 'modules/draft/routes';
import { routes as editor } from 'modules/editor/routes';

/* 
  v2 should have the following structure:

  ├── dashboard
  │   ├── routes

  ├── editor
  │   ├── routes

  ├── common
  │   ├── routes


  We have an issue with the current structure because 
  the current dashboard module is the v2 content and 
  it should be wrapped by dashboard module inside the v2 folder.
  This change will be done in the future.
*/
import { routes as dashboardV2 } from 'modules/v2/routes'; // That should be v2/dashboard/routes
import { routes as draftV2 } from 'modules/v2/draft/routes';

import { routes as editorV2 } from 'modules/v2/editor/routes';

import { routes as onboard } from 'modules/onboard/routes';
import { routes as previewBook } from 'modules/previewBook/routes';
import { routes as landingPages } from 'modules/landingPages/routes';
import { routes as plusPlans } from 'modules/plusPlans/routes';
import { routes as holidaySale } from 'modules/holidaySale/routes';

import NotFound from 'modules/dashboard/pages/NoRoutesFound';

import { Public } from './types';

const routes = []
  .concat(auth)
  .concat(previewBook)
  .concat(!hasAccessToDMPOneDotFive() ? dashboard : dashboardV2)
  .concat(!hasAccessToDMPOneDotFive() ? draft : draftV2)
  .concat(!hasAccessToDMPOneDotFive() ? editor : editorV2)
  .concat(onboard)
  .concat(landingPages)
  .concat(plusPlans)
  .concat(holidaySale)
  .concat([{ type: Public, path: '*', component: NotFound }]);

export default routes;
