import styled from 'styled-components';
import { CSVLink as CSVLinkBase } from 'react-csv';

import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const CSVLink = styled(CSVLinkBase)`
  width: fit-content;
  padding: 10px 15px;

  border: none;
  border-radius: 2px;
  background-color: ${(props) =>
    props.$isActive ? color.PrimaryColor : color.OffButtonBackground};
  color: ${(props) => (props.$isActive ? color.White : color.OffButtonText)};
  font-size: 16px;
  font-family: ${font.FigtreeFont};
  text-align: center;

  transition: all 0.3s ease-in-out;

  :hover {
    color: ${(props) => (props.$isActive ? color.White : color.OffButtonText)};
    background-color: ${(props) =>
      props.$isActive ? color.SecondaryColor : color.OffButtonBackground};
  }
`;

export { CSVLink };
