import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import * as color from 'modules/common/theme/color';

const Title = styled.h3`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 20px 0 10px 0 !important;

  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
`;

const FieldGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
`;

const ProfileWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const ProfilePicture = styled.div`
  cursor: pointer;
  background: ${color.GreyDivider};
  width: 80px;
  height: 80px;
  border: 1px solid ${color.GreyFormBorder};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
  }
`;

const ImageRequirements = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 120px;
`;

const SaveBtn = styled.button`
  display: flex;
  align-items: center;

  height: 40px !important;
  padding: 0 18px;
  background-color: ${color.BlueButton};
  border-style: solid;
  border-color: ${color.BlueButton};
  border-radius: 2px !important;
  opacity: 1;

  color: ${color.White};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.6px;

  transition: transform 0.5s ease;

  cursor: pointer;

  &:hover {
    color: ${color.White};
    background-color: ${color.BlueButtonHover};
  }

  :disabled {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border: none;

    cursor: default;
  }
`;

const CancelBtn = styled(Link)`
  display: flex;
  align-items: center;

  height: 40px !important;
  padding: 0 18px;
  background-color: ${color.White};
  border: 1px solid ${color.BlueButton};
  border-radius: 2px !important;
  opacity: 1;

  color: ${color.BlueButton};

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.6px;

  transition: transform 0.5s ease;

  cursor: pointer;

  &:hover {
    color: ${color.BlueButtonHover};
    background-color: ${color.White};
    border-color: ${color.BlueButtonHover};
  }
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;

  width: 24px;
  height: 40px;

  cursor: pointer;
`;

const DeleteIcon = styled(DeleteOutlined)`
  color: ${color.PrimaryColor};
  font-size: 22px !important;
  text-align: center;
`;

export default {
  Title,
  FieldGroupColumn,
  ButtonContainer,
  ProfileWrapper,
  ProfilePicture,
  ImageRequirements,
  SaveBtn,
  CancelBtn,
  DeleteIcon,
  DeleteButton,
};
