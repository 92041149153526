import { get, map } from 'lodash-es';

import { getPayload } from 'modules/api';
import { addPrefixToBook } from '../common/utils';

export const getDraftsPayload = (response) => {
  const payload = getPayload(response);
  const drafts = get(payload, ['results'], []); // TODO: needs to be update after endpoint updated
  return {
    drafts: map(drafts, addPrefixToBook),
  };
};
