import withStore from 'store/StoreContainer';

import { creditsSelector, getUserCredits } from 'modules/publish/store';

const mapStateToProps = (state) => ({
  credits: creditsSelector(state),
});

const mapDispatchToProps = {
  getUserCredits,
};

export default withStore(mapStateToProps, mapDispatchToProps);
