import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  loginUser,
  autoLoginCheck,
  forgetPassword,
  resetPassword,
  checkExpiredLink,
  showAlert,
} from 'shared/store';

function Auth(Component) {
  return class extends React.Component {
    // method to check valid emailid

    // remove this as this is redundant code
    checkEmail = (email) => {
      const re = new RegExp(/^.+@.+\..+$/);
      if (re.test(email)) {
        return true;
      }

      return false;
    };

    render() {
      return <Component {...this.props} checkEmail={this.checkEmail} />;
    }
  };
}

const mapDispatchToProps = {
  loginUser,
  autoLoginCheck,
  forgetPassword,
  resetPassword,
  checkExpiredLink,
  showAlert,
};

export default compose(
  connect(({ auth, alert }) => ({ auth, alert }), mapDispatchToProps),
  Auth,
);
