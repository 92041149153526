import campaignName from './campaignName';
import campaignAllSegments from './campaignAllSegments';
import campaignSegments from './campaignSegments';
import campaignStartDate from './campaignStartDate';

export default {
  campaignName,
  campaignAllSegments,
  campaignSegments,
  campaignStartDate,
};
