import visaCard from 'assets/payments/VISA-min.png';
import masterCard from 'assets/payments/MASTERCARD-min.png';
import amexCard from 'assets/payments/AMEX-min.png';
import discover from 'assets/payments/DISCOVER-min.png';
import dinnerClub from 'assets/payments/DINNERCLUB-min.png';
import jcb from 'assets/payments/JCB-min.png';
import maestro from 'assets/payments/MAESTRO-min.png';
import solo from 'assets/payments/SOLO-min.png';
import dankort from 'assets/payments/DANKORT-min.png';
import forbrugsforeningen from 'assets/payments/FORBRUG-min.png';
import switchCard from 'assets/payments/SWITCH-min.png';
import laser from 'assets/payments/LASER-min.png';
import elo from 'assets/payments/ELO-min.png';
import hiper from 'assets/payments/HIPER-min.png';
import hiperCard from 'assets/payments/HIPERCARD-min.png';

const cardDetails = {
  visa: visaCard,
  master: masterCard,
  american_express: amexCard,
  maestro,
  diners_club: dinnerClub,
  discover,
  elo,
  hiper,
  hipercard: hiperCard,
  jcb,
  solo,
  dankort,
  forbrugsforeningen,
  switch: switchCard,
  laser,
};

export default cardDetails;
