import styled from 'styled-components';
import { Modal as AntModal, Tabs as AntTabs, Button as AntButton } from 'antd';
import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

interface ModalStyledProps {
  hasCloseButton: boolean;
}

const Modal = styled(AntModal)<ModalStyledProps>`
  .ant-modal-header {
    background: ${color.White};
    height: 52px;
    line-height: 37px;
    padding: 0 0 10px;
    border-bottom: none;

    .ant-modal-title {
      font-family: ${font.FigtreeFont};
      letter-spacing: 0.5px;
      font-size: 16px;
      font-weight: 700;
      line-height: 37px;
      color: ${color.neutral800};
      margin: 0;
      width: calc(100% - 25px) !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: left;
    }
  }

  .ant-modal-body {
    padding: 0;
    background-color: white;
    overflow-y: auto;
    max-height: 80vh;

    @media (max-height: 900px) {
      max-height: calc(100vh - 100px);
    }
  }

  .ant-modal-content {
    padding: 25px 30px;
    border-radius: 10px;
    overflow: hidden;
    background: ${color.White};
    height: 100%;

    .ant-modal-close {
      top: 20px;
      right: 25px;
      padding: 0 10px;
      border-radius: 6px;
      background: var(--00---Neutral-100, #f5f5f5);
      display: ${({ hasCloseButton }) => (hasCloseButton ? 'block' : 'none')} !important;

      .ant-modal-close-x {
        color: ${color.Black};
        text-align: right;
        line-height: 37px;
        width: 16px;
        height: 37px;
      }
    }
  }

  .ant-modal-footer {
    padding: 10px;
    text-align: right;
    background: ${color.White};
    border-top: none;
    border-radius: 0;
    height: 100%;
  }
`;

const BaseModal = styled(AntModal)`
  .ant-modal-header {
    background: ${color.White};
    line-height: 20px;
    padding: 0;

    .ant-modal-title {
      font-family: ${font.RobotoFont};
      letter-spacing: 0.5px;
      font-size: 16px;
      font-weight: bold;
      line-height: 37px;
      color: ${color.neutral800};
      margin: 0;
      width: calc(100% - 25px) !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    border-radius: 10px;
    overflow: auto;
    background: ${color.White};
    height: 100%;
  }

  .ant-modal-close {
    top: 25px;
    right: 30px;
    padding: 0 10px;
    border-radius: 6px;
    background: var(--00---Neutral-100, #f5f5f5);
    display: block !important;

    .ant-modal-close-x {
      color: ${color.Black};
      text-align: right;
      line-height: 37px;
      width: 16px;
      height: 37px;
    }
  }

  .ant-modal-body {
    padding: 0;
    background-color: white;
    height: fit-content;
    display: flex;
    flex-direction: column;

    @media (max-height: 900px) {
      max-height: calc(100vh - 100px);
    }
  }

  .ant-modal-footer {
    padding: 0;
    border-top: none;
  }
`;

const GeneralModal = styled(BaseModal)`
  .ant-modal-header {
    height: 52px;
    margin-bottom: 18px;
  }
`;

const ImagePickerModal = styled(BaseModal)<ModalStyledProps>`
  .ant-tabs-nav-wrap {
    margin-top: 12px;
    height: 38px;
    background-color: transparent !important;
  }

  .ant-modal-header {
    border: none !important;
  }

  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab {
    border: none !important;
    padding: 0;
    dispay: flex;
    flex-direction: column;
    margin-right: 32px !important;
  }

  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid #4cc9e1 !important;
  }

  .ant-tabs-tab-btn {
    color: #535353;
    font-weight: 400;
    .ant-tabs-tab-btn-active {
      color: red !important;
    }
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${color.Black} !important;
  }
`;

const Tabs = styled(AntTabs)`
  .ant-tabs-tabpane {
    max-height: 66vh;
  }

  .ant-tabs-nav-wrap {
    background: ${color.SecondaryColor};

    .ant-tabs-tab {
      font-family: ${font.FigtreeFont};
      font-size: 14px;
      font-weight: 800;
      border-radius: 0 !important;
      letter-spacing: 0.5px;
      color: ${color.White};
      font-weight: 700;
      border: 0 !important;
      border-right: 1px solid ${color.PrimaryColorLight} !important;
      margin: 0 !important;
      cursor: pointer;
      background: transparent !important;

      &.ant-tabs-tab-active {
        color: ${color.GreySelectDd};
        background: ${color.White} !important;
      }
    }
  }
`;

const Button = styled(AntButton)`
  line-height: 19px;

  span {
    font-weight: 800;
  }

  &:hover,
  &:focus {
    color: inherit;
    border-color: inherit;
  }
`;

Tabs.TabPane = AntTabs.TabPane;

export { Modal, ImagePickerModal, GeneralModal, Tabs, Button };
