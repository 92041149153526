import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Current password is required'),
  newPassword: validation.password,
  confirmPassword: validation.confirmPassword,
});

export default validationSchema;
