import PropTypes from 'prop-types';
import S from './index.styled';

function ExpiredLink({ isResent, handleResend, email }) {
  if (isResent === 0) {
    return <h1>Link has been Sent</h1>;
  }

  return (
    <S.Container>
      <S.LinkText>
        The link for <b>{email.username}</b> has expired. Kindly click on the below button to get
        the new activation link on your registered email address
      </S.LinkText>
      <S.LinkText>
        <S.Button type="primary" onClick={handleResend}>
          Resend Link
        </S.Button>
      </S.LinkText>
    </S.Container>
  );
}

ExpiredLink.propTypes = {
  isResent: PropTypes.number.isRequired,
  handleResend: PropTypes.func.isRequired,
  email: PropTypes.shape({
    username: PropTypes.string,
  }).isRequired,
};

export default ExpiredLink;
