import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const CoverImage = styled.div`
  text-align: center;
  display: inline-block;
  height: 100%;
`;

const UpgradeCoverImage = styled.div`
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  height: 100%;
`;

const UpgradeImage = styled.img`
  width: 100%;
  height: 100%;
`;

const upgradeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 180px;
  padding: 10px;
  background: rgb(18 16 16 / 78%);
  position: absolute;
  margin-top: 25%;
  z-index: 2;
`;
const upgradeSpan = styled.span`
  text-align: center;
  letter-spacing: 0px;
  color: ${color.White};
  opacity: 1;
`;
const PreviewSpan = styled.span`
  letter-spacing: 0px;
  color: ${color.LightBlue};
  cursor: pointer;
  opacity: 1;
`;

const upgradeButton = styled.button`
  text-align: center;
  letter-spacing: 0px;
  color: ${color.White};
  top: 825px;
  left: 777px;
  width: 135px;
  height: 40px;
  opacity: 1;
  background: ${color.LightBlue} 0% 0% no-repeat padding-box;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background: ${color.White};
    color: ${color.Black};
  }
`;
const UpgradeModal = styled.div`
  top: 27px;
  left: 161px;
  width: 1045px;
  height: 215px;
  background: ${color.White} 0% 0% no-repeat padding-box;
  opacity: 1;
`;
const ModalContent = styled.div`
  top: 67px;
  left: 203px;
  width: 961px;
  height: 235px;
`;

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  /* padding: 10px; */
`;
export default {
  CoverImage,
  Image,
  UpgradeCoverImage,
  UpgradeImage,
  PreviewSpan,
  upgradeDiv,
  upgradeSpan,
  upgradeButton,
  ContentDiv,
  UpgradeModal,
  ModalContent,
};
