import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { unsubscribeLead } from 'modules/api';
import { Loader } from 'modules/v2/common/components';
import { AuthorifyIcon } from 'modules/v2/common/components/SvgIcon';

import S from './styles';

const Unsubscribe = () => {
  const { id } = useParams();

  const { isLoading: isUnsubscribeLeadLoading, isError: isUnsubscribeLeadError } = useQuery(
    ['unsubscribeLead', id],
    () => unsubscribeLead(id),
  );

  if (isUnsubscribeLeadLoading) {
    return (
      <Loader
        icon={<AuthorifyIcon />}
        title="Please wait"
        description="Please wait while we unsubcribe your email"
      />
    );
  }

  if (isUnsubscribeLeadError) {
    return (
      <S.Container>
        <S.ErrorContainer>
          <S.Title>Something went wrong</S.Title>
          <S.Subtitle>Please try again later or contact us</S.Subtitle>
        </S.ErrorContainer>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.BoxContainer>
        <S.Title>Successfully Unsubscribed</S.Title>
        <S.Subtitle>Your account has been successfully unsubscribed from our system.</S.Subtitle>
      </S.BoxContainer>
    </S.Container>
  );
};
export default Unsubscribe;
