import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const PreviewWrapper = styled.div`
  position: absolute;
  opacity: 0;
  height: 330px;
  width: 216px;
  z-index: 1;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

const UpdateMembershipContainer = styled.div`
  display: flex;
  margin-left: 20px;
`;

const PreviewModalBody = styled.div`
  height: 550px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 24px;
  border-top: 1px solid ${color.neutral200};

  @media (max-width: 768px) {
    height: 100vh;
  }
`;

const UpgradePlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 280px;
  border-radius: 6px;
  border: 1px solid ${color.neutral200};
  padding: 25px;
`;
const IframeContainer = styled.div`
  border-radius: 6px;
  width: 740px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: ${color.yellow50};
  border-radius: 6px;
  margin-bottom: 14px;
  padding: 7px 10px;
`;
const UpgradeText = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  color: ${color.LightDescriptionGray};
  margin-bottom: 24px;
`;

const UpgradeHeading = styled.h2`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const Preview = styled.div`
  position: relative;
  transition: all 0.3s ease-in-out;
  &:hover {
    img {
      transition: all 0.3s ease-in-out;
      transform: scale(1.08);
    }
  }
`;

export default {
  Preview,
  PreviewWrapper,
  UpdateMembershipContainer,
  PreviewModalBody,
  UpgradePlanContainer,
  IconContainer,
  UpgradeHeading,
  UpgradeText,
  IframeContainer,
};
