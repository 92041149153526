import { useParams } from 'react-router-dom';
import { getSession } from 'modules/onboard/utils';

export const useIsRmOffer = () => {
  const { offerCode } = useParams();
  const { currentOffer } = getSession(offerCode);
  const { accountType } = currentOffer || {};
  const isRm = accountType === 'ReferralMarketing';

  return { isRm };
};
