/**
 * CSS Common Functions
 */

export const inlineBlock = () => `
  display: inline-block;
  vertical-align: middle;
`;

export const displayFlex = () => `
  display: flex;
`;

export const borderRadius = (radius = 0) => `
  border-radius: ${radius};
`;

export const transition = (timing = 0.3) => `
  transition: all ${timing}s cubic-bezier(.215, .61, .355, 1);
`;

export const boxShadow = (shadow = 'none') => `
  box-shadow: ${shadow};
`;
