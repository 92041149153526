import { useState } from 'react';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { isEmpty } from 'lodash-es';
import { useParams } from 'react-router';

import { getRouteEmailTemplates } from 'modules/v2/routes/navigation';
import { createCustomerTemplate, getCustomer } from 'modules/api';
import { DashboardTemplate } from 'modules/v2/templates';
import { notification } from 'modules/v2/common/utils';
import { CustomerTemplatesSideBar, CustomerTemplatesNavBar } from 'modules/v2/components';
import { buildCustomerTemplate } from 'modules/v2/components/CustomerTemplatesSideBar/utils';
import { GlobalLoader } from 'modules/v2/common/components';

import S from './styles';
import { INITIAL_FORM_VALUES } from './constants';
import validationSchema from './validationSchema';

const CreateCustomerTemplate = ({ history }) => {
  const [initialFormValues, setInitialFormValues] = useState(INITIAL_FORM_VALUES);
  const { templateBaseId } = useParams();

  const { isFetching: isFetchingTemplate } = useQuery(['getCustomer'], () => getCustomer(), {
    onSuccess: ({ attributes }) => {
      setInitialFormValues((initialValues) => {
        const firstValues = {
          ...initialValues,
          user: attributes || {},
        };

        if (!attributes) {
          firstValues.controls.hasSignature = false;
        }

        return firstValues;
      });
    },
    onError: () => {
      notification.error({
        title: 'Customer attributes could not be fetched. Please try again later.',
      });
    },
  });

  const {
    mutate: createCustomerTemplateMutation,
    isLoading: isLoadingCreateCustomerTemplate,
  } = useMutation(
    (payload) => createCustomerTemplate({ ...payload, emailTemplate: templateBaseId }),
    {
      onSuccess: () => history.replace(getRouteEmailTemplates()),
      onError: () =>
        notification.error({ description: 'There we an error. Please try again later.' }),
    },
  );

  const handleCancel = () => {
    history.push(getRouteEmailTemplates());
  };

  const handleSubmit = (data) => {
    if (isEmpty(data))
      return notification.error({
        description: 'Form was not submitted successfully. Please try again later.',
      });

    const content = buildCustomerTemplate({ formValues: data });

    const { name, subject, templateTitle, imageUrl, bodyContent } = data || {};

    return createCustomerTemplateMutation({
      name,
      subject,
      content,
      templateTitle,
      imageUrl,
      bodyContent,
      ...data.controls,
    });
  };

  if (isFetchingTemplate) {
    return <GlobalLoader />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <DashboardTemplate
        isLoading={isLoadingCreateCustomerTemplate}
        loadingTitle="Loading customer template"
        marginRight="0"
        hasSidebar
        noPaddingLeft
      >
        <S.Wrapper>
          <CustomerTemplatesSideBar onCancel={handleCancel} />
        </S.Wrapper>
      </DashboardTemplate>
    </Formik>
  );
};

export default CreateCustomerTemplate;
