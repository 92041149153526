import { CoverImage } from 'modules/editor/components';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { getRouteDraftGenerateByBookIdFromWelcomeJourney } from 'modules/v2/draft/routes/navigation';

const OrderList = ({ coverImage, bookId, history }) => {
  const handleNavigation = () => {
    const draftPath = getRouteDraftGenerateByBookIdFromWelcomeJourney(bookId, 'view');
    const url = `${draftPath}?welcomeJourney&turnoff`;
    history.push(url);
  };

  return (
    <div className="flex flex-col gap-2 p-2 rounded-lg mb-1">
      <div className="flex">
        <div className="w-[88px] h-[124px]">
          <CoverImage url={coverImage} />
        </div>
        <div className="flex flex-col gap-4 ml-3.5 w-[377px] h-[114px]">
          <div className="flex gap-4">
            <div>Once you're ready to order, complete the Welcome Journey and navigate to the “Books” section in the sidebar menu and select the book you'd like to order.</div>
          </div>
          <Button color="outlined" size="sm" onClick={handleNavigation}>
            View book
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
