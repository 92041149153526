import { call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';

import { isDraftEditable } from 'modules/v2/common/utils';

import { fetchBookById, fetchDraftById, resource } from 'modules/api';

import { goToRoot } from 'modules/editor/store';
import { GET_BOOK_BY_ID, GET_DRAFT_BY_ID } from 'modules/editor/store/constants';

import { filterBookPayload, filterDraftPayload } from './utils';

export function* onGetDraftById({ payload }) {
  try {
    const draftRequest = resource(GET_DRAFT_BY_ID, fetchDraftById, filterDraftPayload);
    const { draft } = yield call(draftRequest, payload);

    if (draft === null || !isDraftEditable(draft)) {
      yield put(goToRoot());
    }

    const bookId = get(draft, 'bookId');

    const bookRequest = resource(GET_BOOK_BY_ID, fetchBookById, filterBookPayload);
    const { book } = yield call(bookRequest, { bookId });

    if (book === null) {
      yield put(goToRoot());
    }
  } catch (err) {
    yield put(goToRoot());
  }
}

export function* watchGetDraftById() {
  yield takeEvery(GET_DRAFT_BY_ID, onGetDraftById);
}
