import { useState } from 'react';
import { DashboardTemplate } from 'modules/v2/templates';
import { SubMenu } from 'modules/v2/common/AtomicDesign/molecules';
import { PlansProvider } from './context';
// import BillingInformation from './BillingInformation';
import Invoices from './Invoices';
import PaymentMethods from './PaymentMethods';
import YourPlan from './YourPlan';

const PlansAndBilling = () => {
  const [subMenuItems, setSubMenuItems] = useState([
    { title: 'Your plan', id: 'your-plan', active: true },
    { title: 'Payment methods', id: 'payment-methods', active: false },
    // TODO: { title: 'Billing information', id: 'billing-information', active: true },
    { title: 'Invoices', id: 'invoices', active: false },
  ]);

  const changeActiveItem = (id) => {
    const newItems = subMenuItems.map((item) => {
      if (item.id === id) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setSubMenuItems(newItems);
  };

  const [page, setPage] = useState('your-plan');

  const subHeader = <SubMenu changeActiveItem={changeActiveItem} items={subMenuItems} />;

  const showCurrentComponent = (id) => {
    switch (id) {
      case 'your-plan':
        return <YourPlan changeActiveItem={changeActiveItem} />;
      case 'payment-methods':
        return <PaymentMethods />;
      // TODO:  case 'billing-information':
      //   return <BillingInformation />;
      case 'invoices':
        return <Invoices />;
      default:
        return <YourPlan setPage={setPage} />;
    }
  };

  return (
    <DashboardTemplate hasSidebar title="Plans and billing" subHeader={subHeader}>
      <PlansProvider>
        {showCurrentComponent(subMenuItems.find((item) => item.active).id)}
      </PlansProvider>
    </DashboardTemplate>
  );
};

export default PlansAndBilling;
