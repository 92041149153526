import { useContext, useState } from 'react';

import { QuestionCircleFilled } from '@ant-design/icons';
import { Col, Divider, Row, Tooltip, Typography } from 'antd';
import { get } from 'lodash-es';
import Modal from 'modules/v2/common/components/Modal';
import { SelectPaymentComponent } from 'modules/v2/components/Payments/SelectPayment';
import { PlansContext } from 'modules/v2/pages/Plans/contexts/UpgradePlan';
import { useMutation, useQuery } from 'react-query';

import { fetchUserCredits, migratePlanToChargify, upgradePlanSubscription } from 'modules/api';
import { Checkbox } from 'modules/v2/common/components';
import { getFlow } from 'modules/v2/pages/Plans/utils';

import { notification } from 'modules/v2/common/utils';
import { log } from 'modules/api/afyLogger/afyLogger';

import {
  upgradePlanEventAlreadyTriggered,
  upgradePlanSetEventTriggered,
} from 'modules/api/afyLogger/helpers';
import S from './styles';

const { Title, Text } = Typography;
const ReviewAndPayModal = ({ refetchPaymentProfile }) => {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const {
    isReviewAndPayModalOpened,
    toggleReviewAndPayModal,
    subscription,
    paymentProfiles,
    selectedPlan,
    userData,
    toggleOrderConfirmationModal,
    getNextMonthRenewal,
    getNextYearRenewal,
    toggleUpgradePlanModal,
    defaultProfile,
    setNewPlanName,
    stripePaymentProfiles,
    isStripeCustomer,
  } = useContext(PlansContext);
  const [currentCard, setCurrentCard] = useState(defaultProfile);
  const [shaking, setShaking] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const term = selectedPlan.isMonthly ? 'Monthly' : 'Yearly';
  const currentSubscriptionPlanName = get(subscription, 'component.name', 'Trialing Plan');
  const handleTermsToggle = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const { mutate, isLoading } = useMutation(['upgradePlanSubscription'], upgradePlanSubscription, {
    onSuccess: async (data) => {
      const planName = data?.result?.price_point_name ?? '';
      setNewPlanName(planName);
      toggleReviewAndPayModal();
      toggleOrderConfirmationModal();
      log('success-upgrade-plan', 'upgrade-plan', [`new-plan:${planName}`]);
    },
  });

  const { mutate: migrateToChargify, isLoading: isMigrating } = useMutation(
    ['migratePlanToChargify'],
    migratePlanToChargify,
    {
      onSuccess: async () => {
        setNewPlanName(selectedPlan.planTitle ?? '');
        toggleReviewAndPayModal();
        toggleOrderConfirmationModal();
      },
    },
  );

  const { data: userBookCredit } = useQuery(['getUserCredits'], fetchUserCredits);

  const bookCreditsLeft = get(userBookCredit, 'data.data.credits', 0);

  const PlanDetails = () => {
    const keys = Object.keys(selectedPlan.packageDetails);
    return keys.map((key) => {
      if (
        (selectedPlan.packageDetails[key].type &&
          selectedPlan.packageDetails[key].type.target.render.displayName === 'CloseOutlined') ||
        selectedPlan.packageDetails[key] === '0'
      )
        return null;
      return (
        <S.PlanDetailItem type="secondary" key={key}>
          {selectedPlan.packageDetails[key]} {key}
        </S.PlanDetailItem>
      );
    });
  };

  const editPlan = () => {
    toggleReviewAndPayModal();
    toggleUpgradePlanModal();
  };

  const handleShake = () => {
    setShaking(true);
    setTooltipOpen(true);

    setTimeout(() => {
      setShaking(false);
    }, 1000);

    setTimeout(() => {
      setTooltipOpen(false);
    }, 3000);
  };

  const handlePayment = () => {
    if (!isTermsChecked) {
      handleShake();
      return;
    }

    if (!isStripeCustomer && currentCard?.id === undefined) {
      notification.error({
        description: 'No payment method selected. Please add a payment method first.',
      });
      return;
    }
    if (isStripeCustomer) {
      const planComponentHandle = selectedPlan.productHandle;
      migrateToChargify(planComponentHandle);
      return;
    }

    const paymentProfileId = currentCard.id;
    const planComponentHandle = selectedPlan.productHandle;
    const flow = getFlow(subscription?.component?.component_handle, planComponentHandle);
    const params = {
      paymentProfileId,
      planComponentHandle,
      flow,
    };
    mutate(params);
  };

  return (
    <>
      <Modal
        isOpen={isReviewAndPayModalOpened}
        title="REVIEW AND PAY"
        onCancel={toggleReviewAndPayModal}
        footer={null}
        width={1000}
      >
        <S.MainSection>
          <Text type="secondary">
            Please make sure information below is correct before placing an order.
          </Text>
          <Row gutter={20}>
            <Col span={14}>
              <SelectPaymentComponent
                cards={paymentProfiles}
                currentCard={currentCard}
                setNewCard={setCurrentCard}
                onAddCard={refetchPaymentProfile}
                stripePaymentProfiles={stripePaymentProfiles}
                isStripeCustomer={isStripeCustomer}
              />
              <br />
              <S.Section>
                <Title level={5}>Billing Period</Title>
                <Text>
                  <Text strong>{term}</Text> (Renews on{' '}
                  {selectedPlan.isMonthly ? getNextMonthRenewal() : getNextYearRenewal()})
                </Text>
              </S.Section>

              <br />
              <S.Section>
                <Title level={5}>Email My Invoice at</Title>
                <Text>{userData?.email}</Text>
              </S.Section>
            </Col>
            <Col span={10}>
              <S.SummarySection>
                <Title level={5}>Plan Summary</Title>
                <S.PlanDetailsSection>
                  <S.PlanTitleSection>
                    <S.PlanTitle strong>
                      {selectedPlan.planTitle} {term}
                    </S.PlanTitle>
                    <S.EditLink onClick={editPlan}>Edit</S.EditLink>
                  </S.PlanTitleSection>
                  <S.PlanDetails>
                    <PlanDetails />
                  </S.PlanDetails>
                  {bookCreditsLeft > 0 && (
                    <>
                      <Divider />
                      <S.PlanTitleSection>
                        <S.PlanTitle strong>
                          Current Plan ({currentSubscriptionPlanName}){' '}
                          <Tooltip
                            placement="right"
                            title="Any unused printed book credits will automatically carry over to your next plan"
                          >
                            <QuestionCircleFilled />
                          </Tooltip>
                        </S.PlanTitle>
                      </S.PlanTitleSection>
                      <S.PlanDetails>
                        <S.PlanDetailItem type="secondary">
                          Your remaining {bookCreditsLeft} books credits will be carried over to
                          your new plan
                        </S.PlanDetailItem>
                      </S.PlanDetails>
                    </>
                  )}
                </S.PlanDetailsSection>
                <S.TotalPaymentSection>
                  <S.PlanTitleSection>
                    <S.PlanTitle strong>Total:</S.PlanTitle>
                    <S.TotalPayment>
                      <S.PlanTitle strong>{selectedPlan.price}</S.PlanTitle>
                      <br />
                      <Text type="secondary">
                        {selectedPlan.isMonthly ? 'per month' : 'per year'}
                      </Text>
                    </S.TotalPayment>
                  </S.PlanTitleSection>
                </S.TotalPaymentSection>
                <S.PayNowButton block onClick={handlePayment} loading={isLoading || isMigrating}>
                  Pay Now
                </S.PayNowButton>
                <Tooltip
                  open={tooltipOpen}
                  title="Please check terms and conditions to proceed"
                  placement="leftTop"
                >
                  <S.ShakeDiv shaking={shaking}>
                    <Checkbox checked={isTermsChecked} onChange={handleTermsToggle}>
                      By clicking “Pay Now”, I agree to the{' '}
                      <strong>
                        <a
                          href="https://authorify.com/terms-of-use/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          terms and conditions
                        </a>
                      </strong>{' '}
                      and acknowledge that I am enrolling in a subscription plan and hereby
                      authorize Authorify to charge my payment method on a monthly basis at the
                      then-current list price (plus applicable taxes), unless I choose to cancel.
                    </Checkbox>
                  </S.ShakeDiv>
                </Tooltip>
              </S.SummarySection>
            </Col>
          </Row>
        </S.MainSection>
      </Modal>
    </>
  );
};

export default ReviewAndPayModal;
