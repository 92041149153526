import classnames from 'classnames';
import S from './styles';

const ListArchived = ({ items = [], selectedId, onSelect }) => {

  const onSelectCallback = (e, item) => {
    e.stopPropagation();
    onSelect(item);
  };

  return (
    <S.ArchivedList>
      {items.map((item) => {
        const selected = item._id === selectedId;
        return   (
          <li key={item._id} className={classnames('ant-list-item', { selected })}>
            <div className="ant-list-item-content" onClick={(e) => onSelectCallback(e, item._id)}>
              <S.WrapperText>
                <S.SectionItemName title={item.displayName}>{item.displayName}</S.SectionItemName>
                <S.ViewLink>View</S.ViewLink>
              </S.WrapperText>
            </div>
          </li>
        )})}
    </S.ArchivedList>
  );
};

export default ListArchived;
