import { createSelector } from 'reselect';
import { find, get } from 'lodash-es';

import { dashboardSelector } from '../dashboard';

export const releaseNotesStateSelector = createSelector(dashboardSelector, (state) =>
  get(state, 'releaseNotes'),
);

export const latestReleaseNoteStateSelector = createSelector(releaseNotesStateSelector, (state) => {
  const latestOrder = get(state, 'latest');
  const releaseNotes = get(state, 'data', []);

  return find(releaseNotes, (releaseNote) => releaseNote.order === latestOrder);
});
