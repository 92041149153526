import { useState, useRef } from 'react';
import { Formik } from 'formik';
import { useQuery, useMutation } from 'react-query';

import { PrimaryBlackButton, SecondaryBlackButton, Modal, Loader } from 'modules/common/components';
import {
  createPaymentProfile,
  getBookCreditsList,
  getPaymentProfilesList,
  sendPayment,
} from 'modules/api';
import { notification } from 'modules/common/utils';
import { setItem } from 'modules/dashboard/utils/legacy';
import { WaitPage } from './WaitPage/index';
import { ReviewPay } from './ReviewPay/index';
import { OrderCredits } from './OrderCredits/index';
import { ConfirmOrder } from './Confirmation/index';
import { SelectPayment } from './SelectPayment/index';
import AddPaymentProfileForm from './AddPayment/index';
import OkButton from './components/OkButton/index';

import S from './styles';

export const PaymentSteps = ({
  showPaymentSteps,
  setShowPaymentSteps,
  getUserCredits,
  type = 'book',
}) => {
  const [step, setStep] = useState(1);
  const [creditValue, setCreditValue] = useState({});
  const [paymentValue, setPaymentValue] = useState({});
  const [subTransId, setSubTransId] = useState('');

  const { data: creditsData, isLoading: isBookLoading } = useQuery('getBookCreditsList', () =>
    getBookCreditsList({ type }),
  );

  const {
    data: paymentData,
    isLoading: ispaymentLoading,
    refetch,
  } = useQuery('getPaymentProfilesList', getPaymentProfilesList);

  const getStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const getSetCreditValue = (detail) => {
    setCreditValue(detail);
  };

  const getpaymentValue = (detail) => {
    setPaymentValue(detail);
  };

  const { mutate: payMutate } = useMutation(['completePayment'], sendPayment, {
    onSuccess: ({ data }) => {
      setSubTransId(data?.signup_payment_id);
      const { customer, id } = data || {};
      setItem('customerAndSubscriptionId', { customer, id });
      setStep(7);
    },
    onError: (error) => {
      notification.error({
        description: error.response.data.errors?.[0] || error?.data?.message || 'Network Error',
      });
    },
  });

  const chargifyForm = useRef();
  const chargify = useRef(new window.Chargify());

  const { mutate, isLoading } = useMutation(['createPaymentProfile'], createPaymentProfile, {
    onSuccess: () => {
      setStep(3);
      notification.success({
        description: 'Payment Profile Created Successfully',
      });
    },
    onError: (error) => {
      notification.error({
        description: error.response.data.errors?.[0] || error?.data?.message || 'Network Error',
      });
    },
  });

  const onSave = (formData) => mutate(formData, { onSuccess: refetch });

  const handlePayment = () => {
    try {
      chargify.current.token(
        chargifyForm.current,
        async (token) => {
          const payload = {
            chargifyToken: token,
          };
          return onSave(payload);
        },
        (error) => {
          notification.error({
            description: error?.errors || 'Something went wrong, please try after sometime',
          });
        },
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return false;
  };

  const completePayment = () => {
    setStep(6);
    const lastFour = paymentValue?.masked_card_number?.slice(-4);
    const createSubscriptionData = {
      customer_id: paymentValue.customer_id,
      product: {
        product_handle: creditValue?.product?.chargifyProductHandle,
        product_price_point_handle: creditValue?.product?.chargifyProductPriceHandle,
        component_id:creditValue?.product?.chargifyComponentId
      },
      customer: {
        id: paymentValue.customer_id,
        first_name: paymentValue.first_name,
        last_name: paymentValue.last_name,
        email: paymentValue.email,
      },
      credit_card: {
        first_name: paymentValue.first_name,
        last_name: paymentValue.last_name,
        card_type: paymentValue.card_type,
        expiration_month: paymentValue.expiration_month.toString(),
        expiration_year: paymentValue.expiration_year.toString(),
        last_four: lastFour,
        vault_token: paymentValue.vault_token,
        current_vault: paymentValue.current_vault,
        customer_vault_token: paymentValue.customer_vault_token,
      },
    };
    return payMutate(createSubscriptionData);
  };

  const finishPaymentFlow = () => {
    setStep(1);
    setShowPaymentSteps(false);
    getUserCredits();
  };

  const steps = [
    {
      step: 1,
      title: 'Buy Credits',
      content: !isBookLoading && !ispaymentLoading && (
        <OrderCredits
          getSetCreditValue={getSetCreditValue}
          creditValue={creditValue}
          creditsData={creditsData}
          paymentData={paymentData}
          type={type}
        />
      ),
      okButton: (
        <PrimaryBlackButton title="CONTINUE" style={{ width: '80%' }} onClick={() => setStep(2)}>
          CONTINUE
        </PrimaryBlackButton>
      ),
    },
    {
      step: 2,
      title: 'Review & Pay',
      content: !ispaymentLoading && (
        <ReviewPay
          getStep={getStep}
          creditValue={creditValue}
          paymentValue={paymentValue}
          paymentData={paymentData}
          getpaymentValue={getpaymentValue}
        />
      ),
      okButton: (
        <PrimaryBlackButton style={{ width: '80%' }} onClick={() => completePayment()}>
          Done
        </PrimaryBlackButton>
      ),
    },
    {
      step: 3,
      title: 'Select Payment Method',
      content: (
        <>
          {!ispaymentLoading && (
            <SelectPayment
              getStep={getStep}
              paymentValue={paymentValue}
              paymentData={paymentData}
              getpaymentValue={getpaymentValue}
            />
          )}
        </>
      ),
      okButton: !ispaymentLoading && (
        <PrimaryBlackButton
          disabled={(paymentData?.length === 1 && paymentData[0].expired) || !paymentData?.length}
          style={{ width: '80%' }}
          onClick={() => setStep(2)}
        >
          Select Payment Method
        </PrimaryBlackButton>
      ),
    },
    {
      step: 4,
      title: 'Add New Credit/Debit Card',
      content: (
        <AddPaymentProfileForm
          isLoading={isLoading}
          chargifyForm={chargifyForm}
          chargify={chargify}
        />
      ),
      okButton: <OkButton />,
      goBackButton: (
        <SecondaryBlackButton
          style={{ width: '80%', color: 'black', border: 'none' }}
          onClick={() => setStep(3)}
        >
          Go Back
        </SecondaryBlackButton>
      ),
    },
    {
      step: 5,
      title: 'Order Confirmed',
      content: <ConfirmOrder creditValue={creditValue} subTransId={subTransId} />,
      okButton: (
        <PrimaryBlackButton style={{ width: '80%' }} onClick={finishPaymentFlow}>
          DONE
        </PrimaryBlackButton>
      ),
    },
    {
      step: 6,
      title: 'Loading',
      content: <Loader title="Loading" height="70%" />,
    },
    {
      step: 7,
      title: 'Loading Payment',
      content: <WaitPage getStep={getStep} />,
    },
  ];

  const stepContent = steps.find((item) => {
    if (item.step === step) {
      return item;
    }
    return null;
  });

  return (
    <Formik validateOnChange={false} initialValues={{}} onSubmit={handlePayment}>
      <Modal
        isOpen={showPaymentSteps}
        onConfirm={() => setShowPaymentSteps(false)}
        onCancel={() => setShowPaymentSteps(false)}
        maskClosable
        hasCloseButton
        title={stepContent?.title}
        width="auto"
        footer={
          <>
            <S.FooterContainer>{stepContent.okButton}</S.FooterContainer>
            <S.FooterContainer>{stepContent.goBackButton}</S.FooterContainer>
          </>
        }
      >
        <S.ModalContent>{stepContent.content}</S.ModalContent>
      </Modal>
    </Formik>
  );
};
