import { call, select, takeEvery } from 'redux-saga/effects';

import { savePreferences, resource, getError } from 'modules/api';

import { draftIdSelector, preferencesSelector } from 'modules/editor/store';
import { SAVE_PREFERENCES } from 'modules/editor/store/constants';

export function* onSavePreferences() {
  try {
    const draftId = yield select(draftIdSelector);
    const preferences = yield select(preferencesSelector);

    const request = resource(SAVE_PREFERENCES, savePreferences);
    yield call(request, { draftId, preferences });

    return true;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);

    return false;
  }
}

export function* watchSavePreferences() {
  yield takeEvery(SAVE_PREFERENCES, onSavePreferences);
}
