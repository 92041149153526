import {
  CLEAR_DRAFT_PREVIEW,
  GENERATE_DRAFT_PREVIEW,
  GET_DRAFT_PREVIEW,
} from 'modules/v2/draft/store/constants';

export const clearDraftPreview = () => ({
  type: CLEAR_DRAFT_PREVIEW,
});

export const getDraftPreview = (draftId) => ({
  type: GET_DRAFT_PREVIEW,
  payload: {
    draftId,
  },
});

export const generateDraftPreview = (draftId) => ({
  type: GENERATE_DRAFT_PREVIEW,
  payload: {
    draftId,
  },
});
