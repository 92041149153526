import { min } from 'lodash-es';

import { AVATAR_MIN_WIDTH, AVATAR_MIN_HEIGHT, loadImageDetails } from '../../utils';

export const LOADING_FAILED_MESSAGE = 'Loading image details failed';

export const calculateMaxScale = async (file) => {
  try {
    const { width, height } = await loadImageDetails(file);

    // Get the percentage of the dimension
    const widthScale = AVATAR_MIN_WIDTH / width;
    const heightScale = AVATAR_MIN_HEIGHT / height;

    // Should return the minor scale
    const scale = min([widthScale, heightScale]);

    return (2 - scale).toFixed(1);
  } catch (error) {
    throw LOADING_FAILED_MESSAGE;
  }
};
