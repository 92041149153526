import withStore from 'store/StoreContainer';

import {
  goToEditDraftCover,
  goToEditDraftManageSections,
  goToEditDraftContent,
  saveDraft,
  toggleShowGuides,
  layoutSelector,
  draftIdSelector,
  sortedSectionsSelector,
  activeChaptersSelector,
  displayNameSelector,
  sectionTypeSelector,
  contentTemplateListSelector,
  dropdownSectionsSelector,
  toolbarSelector,
  updateContentElement,
  updateContentActiveTemplate,
  uploadContentImage,
} from 'modules/v2/editor/store';

const mapStateToProps = (state, { sectionId }) => ({
  layout: layoutSelector(state),
  chapters: activeChaptersSelector(state),
  draftId: draftIdSelector(state),
  sections: sortedSectionsSelector(state),
  toolbar: toolbarSelector(state),
  displayName: displayNameSelector(sectionId)(state),
  sectionType: sectionTypeSelector(sectionId)(state),
  templateList: contentTemplateListSelector(sectionId)(state),
  dropdown: dropdownSectionsSelector(state),
});

const mapDispatchToProps = {
  goToEditDraftCover,
  goToEditDraftManageSections,
  goToEditDraftContent,
  saveDraft,
  toggleShowGuides,
  updateContentElement,
  updateContentActiveTemplate,
  uploadContentImage,
};

export default withStore(mapStateToProps, mapDispatchToProps);
