import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const QrCode = styled.img`
  width: 64px;
  height: 64px;
`;

const AdditionalInfo = styled.div`
  min-height: 50px;
`;

const InputWrap = styled.div`
  padding: 0px 10px;
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  font-size: 14px;
  line-height: 24px;
  // padding-top: 0px;
  color: ${color.Black};
  padding-bottom: ${(props) => (props.noPaddingBottom ? '0px' : '')};
  position: relative;
  &:first-child {
    padding-left: 0px;
  }
  input {
    font-size: 16px;
    line-height: 24px;
    padding: 20px 20px;
    resize: none;
    margin-top: 10px;
  }
`;

const FieldSeprator = styled.div`
  display: flex;
  align-items: baseline;
  padding: 10px 0px;
  &:last-child {
    padding: 0px;
  }
`;

const FlexWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0px 10px 0px;
  margin-top: ${(props) => props.noMarginTop && '0'};
  h5 {
    font-size: 16px;
  }
  span {
    font-size: 26px;
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 37%;
  }
`;

const AddOfferImage = styled.div`
  padding: 10px 10px 0px 10px;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  color: ${color.Black};
  font-weight: 500;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '')};
  &:first-child {
    padding-left: 0px;
  }
`;

export default {
  AdditionalInfo,
  AddOfferImage,
  FieldSeprator,
  FlexWrap,
  InputWrap,
  QrCode,
};
