import { get, findIndex, includes, filter, sortBy } from 'lodash-es';
import {
  getRouteEditDraftContent,
  getRouteEditDraftManageSections,
} from 'modules/editor/routes/navigation';

// TODO: Move to selectors
export const getContentNavigationLinks = (sections, draftId, sectionId) => {
  const sortedSections = sortBy(sections, ({ order }) => order);
  const activeSections = filter(sortedSections, ({ currentStatus, type }) => {
    const isActive = currentStatus === 'active';
    const isAllowedSection = includes(['Content', 'Chapter'], type);

    return isActive && isAllowedSection;
  });

  const index = findIndex(activeSections, (section) => section._id === sectionId);
  const limit = activeSections.length;

  const previousLink = {
    link: '',
    disabled: false,
  };
  const nextLink = {
    link: '',
    disabled: true,
  };

  const previousIndex = index - 1;
  const nextIndex = index + 1;

  if (index === 0) {
    previousLink.link = getRouteEditDraftManageSections(draftId);
  }

  if (nextIndex < limit) {
    const nextId = get(activeSections, [nextIndex, '_id']);
    nextLink.link = getRouteEditDraftContent(draftId, nextId);
    nextLink.disabled = false;
  }

  if (previousIndex >= 0) {
    const previousId = get(activeSections, [previousIndex, '_id']);
    previousLink.link = getRouteEditDraftContent(draftId, previousId);
  }

  return {
    previous: previousLink,
    next: nextLink,
  };
};
