import { useQuery, useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import { getRouteNextStep } from 'modules/onboard/routes/navigation';
import { getCurrentStep, getSession, setSession, STEPS } from 'modules/onboard/utils';
import { createSession, fetchSession, resumeSession } from 'modules/api/digitalServices';
import { getRouteAutoLogin } from 'modules/dashboard/routes/navigation';

export const useStep = () => {
  const { offerCode } = useParams();
  const {
    location: { pathname },
    push,
  } = useHistory();

  const queryString = window.location.search;

  return {
    goToNextStep: (newStep) => {
      const {
        step: sessionStep,
        steps,
        offer,
        customer,
        autoLoginToken,
      } = getSession(offerCode) || {};

      const { email } = customer || {};
      const urlStep = getCurrentStep(pathname);

      // if there is no newStep param, we will use the current step in localStorage to move on.
      const findNextStepIndex = steps?.findIndex((step) =>
        newStep ? step === newStep : step === sessionStep,
      );

      const nextStepFromArrayIndex = findNextStepIndex + 1;
      const nextStepRoute = steps?.[nextStepFromArrayIndex];

      if (!newStep) {
        push(getRouteNextStep(offer.code, STEPS[nextStepRoute].path, queryString));
        return;
      }

      // This is a temporary logic
      // We should have information about the offer family in the session
      // So we can use it to redirect to the correct page
      const extraParams =
        offer?.code === 'rm-trial-offer'
          ? 'redirect=/referral-marketing/magazine-editor/my-magazine'
          : '';

      if (sessionStep === 'done' && autoLoginToken) {
        push(getRouteAutoLogin(email, autoLoginToken, extraParams));
        return;
      }

      if (newStep && newStep !== urlStep) {
        push(getRouteNextStep(offer.code, STEPS[newStep].path, queryString));
        return;
      }

      if (sessionStep !== urlStep) {
        setTimeout(() => {
          push(getRouteNextStep(offer.code, STEPS[sessionStep].path, queryString));
        }, 500);
      }
    },
  };
};

export const useCreateSession = () => {
  const { offerCode } = useParams();
  const location = useLocation();

  const {
    channel,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_content: utmContent,
    utm_term: utmTerm,
    affiliate_id: affiliateId,
    orderSystem,
    salesAgent,
  } = parse(location.search);
  const session = getSession(offerCode);

  const { isError, isLoading, isIdle } = useQuery(
    ['createSession', offerCode],
    () =>
      createSession(
        offerCode,
        channel,
        utmSource,
        utmMedium,
        utmContent,
        utmTerm,
        affiliateId,
        orderSystem,
        salesAgent,
      ),
    {
      enabled: !session,
      onSuccess: (result) => {
        setSession(result.data);
      },
    },
  );

  if (session) {
    return {
      session,
      isError,
      isLoading,
      isIdle,
    };
  }

  return { isLoading, isError, isIdle };
};

export const useResumeSession = () => {
  const { offerCode } = useParams();

  const { mutate } = useMutation(
    'resumeSession',
    ({ email, password }) => resumeSession({ email, password, offerCode }),
    {
      onSuccess: ({ data: resumeData, status }) => {
        if (status === 200 || status === 201) {
          setSession(resumeData);
        }
      },
    },
  );

  return { mutate };
};

export const useFetchSession = () => {
  const { offerCode } = useParams();
  const session = getSession(offerCode);

  const { isError, refetch } = useQuery('fetchSession', () => fetchSession(offerCode, session.id), {
    onSuccess: ({ data }) => {
      setSession(data);
    },
  });

  return { session, isError, refetchSession: refetch };
};
