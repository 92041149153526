import styled from 'styled-components';
import * as font from 'modules/common/theme/font';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  font-family: ${font.MontserratFont};
  color: ${color.GreyText};
  padding: 30px 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

export default {
  Footer,
  Text,
  Title,
  Wrapper,
};
