import { memo } from 'react';
import { Slider } from 'antd';
import { throttle } from 'lodash-es';

import { fixValue } from 'modules/editor/components/CheckoutForm/utils';
import {
  MIN_NUMBER_OF_PRINTS,
  PRINT_NUMBER_PROMO,
  STEP,
} from 'modules/editor/components/CheckoutForm/constants';

import S from './styles';

const THROTTLE_SLIDER_CHANGE = 300;

const CreditsSlider = ({ name, credits, field: { value }, setFieldValue, disabled }) => {
  const intValue = typeof value === 'number' ? value : 0;
  const promoMarkText = credits === PRINT_NUMBER_PROMO ? PRINT_NUMBER_PROMO : '';

  const marks = {
    [credits]: credits,
    [MIN_NUMBER_OF_PRINTS.toString()]: MIN_NUMBER_OF_PRINTS,
    [PRINT_NUMBER_PROMO.toString()]: promoMarkText,
  };

  const handleChange = throttle((newValue) => {
    setFieldValue(name, fixValue(newValue, intValue, credits));
  }, THROTTLE_SLIDER_CHANGE);

  return (
    <S.SliderWrapper>
      <Slider
        max={credits}
        min={MIN_NUMBER_OF_PRINTS}
        disabled={!credits || disabled}
        step={STEP}
        value={intValue}
        onChange={handleChange}
        marks={marks}
      />
    </S.SliderWrapper>
  );
};

export default memo(CreditsSlider);
