import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import DashboardTemplate from 'modules/v2/templates/DashboardTemplate';

import {
  getRouteProfile,
  getRouteShippingAddress,
  getRouteChangePassword,
  getRouteProfilePlans,
  getRoutePageProfile,
} from 'modules/v2/routes/navigation';

import {
  MenuItemProps,
} from 'modules/v2/common/AtomicDesign/molecules/SubMenu/types';

const ProfileTemplate = ({
  avatar,
  children,
  email,
  username,
  onLogout,
  sidebarWidth,
  noPaddingLeft,
  noPaddingRight,
}) => {
  const [items, setItems] = useState([
    { title: 'Profile', id: 'profile', active: true, route: '/profile' },
    { title: 'Change password', id: 'change-password', active: false, route: '/profile/password' },
    {
      title: 'Your shipping addresses',
      id: 'shipping-addresses',
      active: false,
      route: '/profile/shipping-address',
    },
  ]);
  useEffect(() => {
    // There are no specific actions to take here other than re-rendering the component
  }, [items]);

  const history = useHistory();

  const currentPath = history.location.pathname;

  const handleItemClick = (id: string) => {
    const updatedSubMenuItems: MenuItemProps[] = items.map((item) => {
      if (item.id === id && !item.active) {
        history.replace(item.route);
        return { ...item, active: true };
      } if (item.id === id && item.active) {
        history.replace('/profile');
        return { ...item, active: true }; // Manter o item ativo e redirecionar para /profile
      } if (item.id !== id && item.active) {
        return { ...item, active: false };
      }
      return item;
    });

    setItems(updatedSubMenuItems);
  };

  return (
    <DashboardTemplate
      avatar={avatar}
      email={email}
      title='Account Settings'
      username={username}
      onLogout={onLogout}
      sidebarWidth={sidebarWidth}
      noPaddingLeft={noPaddingLeft}
      noPaddingRight={noPaddingRight}
    >
      <div className="w-full overflow-x-auto flex border-b-[1px] border-neutral-200 gap-8 mb-[22px]">
        {items.map((item, index) => (
          <div
            key={item.id}
            className={classNames(
              item.route === currentPath ? 'border-primary-500 border-b-2 text-neutral-800' : 'border-0',
              'pb-4 text-sm whitespace-nowrap cursor-pointer text-neutral-600',
              'font-semibold',
            )}
            onClick={() => handleItemClick(item.id)}
          >
            {item.title}
          </div>
        ))}
      </div>
      {children}
    </DashboardTemplate>
  );
};

export default React.memo(ProfileTemplate);
