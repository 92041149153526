import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { get } from 'lodash-es';
import { useLocation, useHistory } from 'react-router-dom';

import env from 'environments';

import {
  // fetchUserCredits,
  getDefaultPaymentProfile,
  getActiveSubscriptions,
  getStripePaymentMethods,
  getSubscription,
  getSubscriptionService,
  getPlanDetails,
} from 'modules/api';
import { CreditCardImages, formatCreditCardNumber } from 'modules/v2/utils';
import { formatDateWithTimeZone, notification } from 'modules/v2/common/utils';
import { centsToDollar } from 'modules/v2/pages/Plans/utils';
import { getRoutePlanUpgrade } from 'modules/v2/routes/navigation';

import { BoltIcon, ClockCircleIcon } from 'modules/v2/common/components/SvgIcon';
import { Box, Button, QuestionLabel, SuggestionCard } from 'modules/v2/common/AtomicDesign/atoms';
import { ChangeDefaultPaymentModal, SuccessModal } from 'modules/v2/common/AtomicDesign/organisms';
import {
  contractedPlans,
  formatCurrency,
  nonContractedPlans,
} from 'modules/v2/pages/UpgradePlan/utils';
import { PlansContext } from '../context';
import { getPercentage } from '../utils';

const YourPlan = ({ changeActiveItem }) => {
  const {
    setDefaultProfile,
    setSubscription,
    setIsContracted,
    setIsTrialing,
    isTrialing,
    setStripePaymentProfiles,
    setIsStripeCustomer,
  } = useContext(PlansContext);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('planUpgraded')) {
      setShowSuccessModal(true);
    } else {
      setShowSuccessModal(false);
    }
  }, [location.search]);

  const history = useHistory();
  const handleChangePaymentMethodAction = () => {
    changeActiveItem('payment-methods');
  };

  const [planValues, setPlanValues] = useState({
    price: 0,
    licensedBooks: 0,
    digitalBooks: 0,
    printedBooks: 0,
    leadCaptureWebsites: 0,
    marketingTemplates: 0,
    trainingSiteAccess: 0,
    coaching: 0,
    retargeting: 0,
    googleMyBusiness: 0,
    socialMediaTrainingSite: 0,
  });
  const [defaultCardModal, setDefaultCardModal] = useState(false);

  const { data: subscription = {}, isLoading: isActiveSubscriptionLoading } = useQuery(
    ['getSubscription'],
    getActiveSubscriptions,
    {
      onError: (error) => {
        const errorMessage =
          error.response?.data?.errors?.[0] || error?.data?.message || 'Network Error';
        notification.error({
          description: `${errorMessage}. Please call our customer service at ${env.SUPPORT_PHONE}.`,
          autoClose: false,
        });
      },
    },
  );

  const { data: { value: isSubscriptionFromStripe } = {} } = useQuery(
    ['getSubscriptionService'],
    getSubscriptionService,
    {
      onSuccess: (data) => {
        setIsStripeCustomer(data.value);
      },
    },
  );

  const { data: subFromStripe, isLoading: isSubscriptionLoading } = useQuery(
    ['getSubscriptionFromStripe'],
    getSubscription,
  );

  const { data: { data: listData } = {} } = useQuery('getPlanDetails', getPlanDetails);

  // TODO GET BOOKS credits
  // const { data: userBookCredit, refetch: reactCreditQueryRefetch } = useQuery(
  //   ['getUserCredits'],
  //   fetchUserCredits,
  // );
  // const bookCredit = userBookCredit?.data?.data?.credits || 0;

  const isLoading = isActiveSubscriptionLoading || isSubscriptionLoading;

  const { digitalBookCount, leadSiteCaptureCount } = listData ?? {
    digitalBookCount: 0,
    leadSiteCaptureCount: 0,
  };

  const amount = centsToDollar(subscription?.component?.unitPrice * 100);
  const stripePlanName = get(subFromStripe, 'attributes.planName', 'Trialing');
  const subscriptionPlanName = isSubscriptionFromStripe
    ? stripePlanName
    : get(subscription, 'component.name', stripePlanName);

  const subscriptionInterval = get(subscription, 'product.interval_unit', '');
  const isMonthly = subscriptionInterval === 'month';
  const currentPlanRenewalDate = formatDateWithTimeZone(
    subscription?.current_period_ends_at,
    'MMMM D, YYYY',
    'America/New_York',
  );

  const regexPattern = /\bPlatinum\+ Annual\b/gi;
  const isPlatinumPlusAnnual = subscriptionPlanName.match(regexPattern);
  const upgradePlanIsAllowed = !isTrialing && !isPlatinumPlusAnnual?.length;

  useQuery('getStripePaymentMethods', getStripePaymentMethods, {
    onSuccess: (payments) => {
      if (payments?.data?.length) {
        setStripePaymentProfiles(payments.data);
      }
    },
  });

  const { data: defaultProfile } = useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile, {
    onSuccess: (data) => {
      setDefaultProfile(data);
    },
  });

  const { id: idPayment, card_type: card, masked_card_number: cardNumber } = defaultProfile || {};

  useEffect(() => {
    if (subscription?.component) {
      setSubscription(subscription);
      const subscriptionStatus = get(subscription, 'state');
      if (!isSubscriptionFromStripe) {
        setIsTrialing(subscriptionStatus === 'trialing');
      }
      setIsContracted(!subscription?.product?.name?.includes('Non Contracted'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, setSubscription, setIsContracted]);

  const isContracted = subscription?.component?.product_family_handle !== 'afy_noncontracted';

  const planName = subscriptionPlanName.replace(/ annual| monthly/gi, '');

  useEffect(() => {
    if (isContracted) {
      const planInfo = contractedPlans.find((plan) => plan.plan.includes(planName));

      if (isMonthly) {
        return setPlanValues(planInfo?.monthly);
      }
      return setPlanValues(planInfo?.annual);
    }
    const planInfo = nonContractedPlans.find((plan) => plan.plan.includes(planName));

    if (isMonthly) {
      return setPlanValues(planInfo?.monthly);
    }
    return setPlanValues(planInfo?.annual);
  }, [isContracted]);

  const planInformation = (title: string, description: string, count: number, key: string) => {
    if (planValues && Object.keys(planValues).length > 0) {
      const total = planValues[key] || 0;
      const remaining = total - count;
      const unusedResources = count === 0;

      return (
        <div className="grid gap-y-2 grid-cols-1">
          <div className="flex justify-between">
            <QuestionLabel title={title} description={description} />
            <span>{`${count} of ${total}`}</span>
          </div>
          <div className="w-full bg-neutral-100 rounded-full h-2.5">
            <div
              style={{ width: `${getPercentage(count, total)}%` }}
              className="bg-primary-500 h-2 rounded-full max-w-full"
            />
          </div>

          {!unusedResources && <span>{remaining} remaining</span>}
        </div>
      );
    }

    return (
      <div className="grid gap-y-2 grid-cols-1">
        <div className="flex justify-between">
          <QuestionLabel title={title} description={description} />
          <span>0 of 0</span>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex gap-6 max-[1350px]:flex-col">
      <div className="w-full">
        <Box className="p-6 mb-0">
          <div className="flex justify-between items-center mb-6 max-[470px]:flex-col gap-y-2">
            {isLoading ? (
              <div role="status" className="max-w-sm animate-pulse h-16 flex items-center">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-6" />
              </div>
            ) : (
              <>
                <div>
                  {isTrialing && (
                    <div className="bg-error-50 text-error-500 text-xs font-medium me-2 mb-2 px-2 py-1 rounded-full w-fit flex gap-2">
                      <ClockCircleIcon fill="#E4483D" />
                      <span>Your trial ends on {currentPlanRenewalDate}</span>
                    </div>
                  )}

                  <span className="text-lg font-bold">{`${planName} plan ${
                    isMonthly ? '(monthly)' : '(yearly)'
                  }`}</span>
                </div>
                {upgradePlanIsAllowed && (
                  <Button onClick={() => history.push(getRoutePlanUpgrade())}>
                    <BoltIcon />
                    <span className="ml-2">Upgrade plan</span>
                  </Button>
                )}
              </>
            )}
          </div>

          <div className="grid gap-y-6 grid-cols-1 mb-6">
            <div className="grid gap-y-2 grid-cols-1">
              <div className="flex justify-between">
                <QuestionLabel
                  title="Licensed books"
                  description="Access a library of real estate books templates featuring specialized categories, offering an extensive range of topics."
                />
                <span>{planValues?.licensedBooks || 0}</span>
              </div>
            </div>
            {planInformation(
              'Digital books',
              'Share and conduct presentations with your digital book with anyone in the world.',
              digitalBookCount,
              'digitalBooks',
            )}
            {planInformation(
              'Lead capture websites',
              'Attract and promote your business and collect leads. Get instant notifications for new leads, enabling you to follow up promptly and secure the deal.',
              leadSiteCaptureCount,
              'leadCaptureWebsites',
            )}
          </div>
          {upgradePlanIsAllowed && (
            <SuggestionCard
              description="Upgrade your plan to get monthly book credits and access to more benefits."
              icon={<BoltIcon fill="#FFB703" />}
            />
          )}
        </Box>
      </div>
      <div>
        <Box className="mb-6 min-w-[390px] max-[1350px]:mb-8 max-[450px]:min-w-[220px]">
          <div className="px-6 py-[22px] border-b-[1px] border-neutral-200 text-boxTitle font-semibold">
            Billing cycle
          </div>
          <div className="p-6 border-b-[1px] border-neutral-200">
            <p className="mb-2">
              <span className="font-semibold">{formatCurrency(amount, false)}</span>{' '}
              {isMonthly ? 'per month' : 'per year'}
            </p>
            <p>
              <span className="font-semibold">Next payment on</span> {currentPlanRenewalDate}
            </p>
          </div>
        </Box>
        <Box className="min-w-[390px]  max-[450px]:min-w-[220px]">
          <div className="px-6 py-[22px] border-b-[1px] border-neutral-200 text-boxTitle font-semibold">
            Payment method
          </div>
          <div className="p-6 text-boxTile">
            {card && (
              <div>
                <div className="flex items-center gap-2 mb-3.5">
                  <img src={CreditCardImages[card]} alt={card} className="w-[46px] h-[32px]" />
                  <div className="font-semibold text-sm">
                    {formatCreditCardNumber(cardNumber, true)}
                  </div>
                </div>
                <Button onClick={handleChangePaymentMethodAction} color="outlined" size="xs">
                  Change
                </Button>
              </div>
            )}
          </div>
        </Box>
      </div>
      <ChangeDefaultPaymentModal
        showModal={defaultCardModal}
        setShowModal={setDefaultCardModal}
        isDefault
      />
      <SuccessModal
        title={`Congratulations! You’re now a ${planName} member`}
        description={`Thank you for using Authorify. You now have access to ${planName} features and services. Your next billing period will be on ${currentPlanRenewalDate}`}
        showModal={showSuccessModal}
        setShowModal={setShowSuccessModal}
      />
    </div>
  );
};

export default YourPlan;
