import { notification } from 'modules/v2/common/utils';
import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  generateDraftFromBook,
  generateDentistDraftLinks,
  fetchPreFlightCheck,
  fetchDraftLinks,
  // addLandingPages,
} from 'modules/api';

export const useOrderGuide = ({ onSuccess }) => {
  const [draftId, setDraftId] = useState(null);
  const [guideId, setGuideId] = useState(null);
  const [getLinksLoopingActive, setGetLinksLoopingActive] = useState(false);

  // const { mutateAsync: addLandingPage, isLoading: isAddingLandingPageLoading } = useMutation(
  //   addLandingPages,
  // );

  const { mutateAsync: generateDraftLinksMutate, isLoading: isGenerateDraftLinksLoading } =
    useMutation(generateDentistDraftLinks);

  const successGenerateLinks = async ({ data }) => {
    const response = data?.data || {};

    const { status, url } = response;

    if (status === 'failed') {
      setGetLinksLoopingActive(false);

      notification.error({
        description: 'There was an error generating your guide. Please try again later.',
      });
    }

    if (status === 'ready') {
      setGetLinksLoopingActive(false);

      const {
        // book,
        site,
      } = url || {};

      // await addLandingPage({ guideId, type: 'GUIDE', link: site });

      if (onSuccess) onSuccess({ site, guideId });
    }
  };

  useQuery(['getLinks', { draftId }], () => fetchDraftLinks({ draftId }), {
    enabled: getLinksLoopingActive,
    refetchInterval: 3000,
    onSuccess: successGenerateLinks,
  });

  const { mutateAsync: generateDraft, isLoading: isGenerateDraftLoading } =
    useMutation(generateDraftFromBook);

  const { mutateAsync: preFlightCheckMutate, isLoading: isPreFlightCheckLoading } =
    useMutation(fetchPreFlightCheck);

  const isOrderGuideLoading =
    isGenerateDraftLinksLoading ||
    isGenerateDraftLoading ||
    // isAddingLandingPageLoading ||
    isPreFlightCheckLoading ||
    getLinksLoopingActive;

  const handleGenerateLandingPages = async (guideId) => {
    setGuideId(guideId);

    const draft = await generateDraft({ bookId: guideId });
    const draftId = draft?.data?.draftId;
    setDraftId(draftId);

    await preFlightCheckMutate({ draftId });
    await generateDraftLinksMutate({ draftId });

    setGetLinksLoopingActive(true);
  };

  return { handleGenerateLandingPages, isOrderGuideLoading };
};
