import { useQuery } from 'react-query';
import { get, isEmpty } from 'lodash-es';

import { DashboardTemplate } from 'modules/v2/templates';
import { getEmailAnalytics } from 'modules/api';
/// TODO: Please remove this line when UIComponents/table is deleted
import Table from 'modules/v2/common/components/Table';

import S from './styles';

const EmailCampaignAnalytics = () => {
  const {
    isError: isAnalyticsError,
    isLoading: isAnalyticsLoading,
    data: analyticsData,
  } = useQuery(['gettingEmailAnalytics'], getEmailAnalytics);
  const analytics = get(analyticsData, 'data');

  const tablePagination = { position: 'bottom', pageSize: 20 };
  const tableColumns = [
    {
      title: 'Campaign Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      key: 'sent',
    },
    {
      title: 'Opened',
      dataIndex: 'opened',
      key: 'opened',
    },
    {
      title: 'Clicked',
      dataIndex: 'clicked',
      key: 'clicked',
    },
    {
      title: 'Bounced',
      dataIndex: 'bounced',
      key: 'bounced',
    },
    {
      title: 'Unsubscribed',
      dataIndex: 'unsubscribed',
      key: 'unsubscribed',
    },
  ];

  return (
    <DashboardTemplate isError={isAnalyticsError} contentTitle="Campaign Analytics" hasSidebar>
      <S.DashboardSubTitle>
        The reports are of <strong>90 days</strong> period.
      </S.DashboardSubTitle>
      <S.TemplateWrapper>
        <Table
          hasData={!isEmpty(analytics)}
          rowKey="id"
          dataSource={analytics}
          columns={tableColumns}
          pagination={tablePagination}
          loading={isAnalyticsLoading}
        />
      </S.TemplateWrapper>
    </DashboardTemplate>
  );
};

export default EmailCampaignAnalytics;
