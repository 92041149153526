import { useState } from 'react';

import EditBookModal from 'modules/v2/common/AtomicDesign/organisms/EditBookModal';

import ModalContent from './ModalContent';

const PlainTextModal = ({ element, isOpen, onConfirm, onCancel }) => {
  const [state, setState] = useState(element);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(state);
    }
  };

  return (
    <EditBookModal
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      title="Edit author name"
      size="xl"
      saveButtonDisabled={!state?.defaultValue}
    >
      <ModalContent onChange={setState} state={state} />
    </EditBookModal>
  );
};

export default PlainTextModal;
