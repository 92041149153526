import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const Container = styled.div`
  max-height: 60vh;
  padding: 24px;
  overflow-y: scroll;
  overflow-x: hidden;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
  }

  @media (max-width: 768px) {
    width: 95vw;
    max-height: 100vh;
    padding: 16px;
  }
`;

const ModalDescription = styled.span`
  font-size: 18px;

  b {
    font-weight: 700;
    color: ${color.PrimaryColor};
    margin-right: 2px;
  }
`;

export default { Container, ModalDescription };
