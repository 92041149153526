//

import { map } from 'lodash-es';
import { withReleaseNotes } from 'modules/v2/containers';
import { Loader } from 'modules/v2/common/components';
import { isLoading, isFailed, isSucceeded } from 'store/status';

import { DashboardTemplate } from 'modules/v2/templates';

import { parseReleaseNotes } from './utils';
import S from './styles';

const ReleaseNotes = ({ releaseNotes }) => {
  const parsedNotes = parseReleaseNotes(releaseNotes.data);

  let content;

  if (isLoading(releaseNotes.status)) {
    content = <Loader height="500px" title="Loading Release Notes" />;
  }

  if (isSucceeded(releaseNotes.status)) {
    content = map(parsedNotes, ({ id, name, content: body, date }) => (
      <S.Wrapper key={id}>
        <S.Date>{date}</S.Date>
        <S.Title>{name}</S.Title>
        <S.ListDescription>What's included on this update:</S.ListDescription>

        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </S.Wrapper>
    ));
  }

  if (isFailed(releaseNotes.status)) {
    content = <S.ListDescription>There are no Release Notes available.</S.ListDescription>;
  }

  return (
    <DashboardTemplate
      title="What's New"
      subTitle="Check out the latest in features, updates, and more."
    >
      {content}
    </DashboardTemplate>
  );
};

ReleaseNotes.defaultProps = {
  releaseNotes: [],
};

export default withReleaseNotes(ReleaseNotes);
