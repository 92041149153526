import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const SelectFieldWrap = styled.div`
  .ant-select.ant-select-enabled {
    width: 205px;
    height: 32px;
    color: ${color.LoaderLabel};
    outline: none;
    border: none;
    box-shadow: none;
    font-weight: 600;
    &:hover {
      border: none;
    }
  }
`;

export default {
  SelectFieldWrap,
};
