import { get } from 'lodash-es';

import {
  SELECT_SECTION,
  SET_SECTIONS,
  REORDER_DRAFT_SECTIONS,
  ADD_NEW_CHAPTER,
  UPDATE_SECTION,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  data: [],
  selected: {},
  dropdown: [],
  chapterDropdown: [],
  contentDropdown: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${SELECT_SECTION}_REQUEST`:
      return {
        ...state,
        loading: true,
      };

    case SELECT_SECTION: {
      const type = get(action, 'section.type');

      if (type === 'Chapter') {
        return {
          ...state,
          loaded: true,
          dropdown: state.chapterDropdown,
          selected: action.section,
        };
      }
      return {
        ...state,
        loaded: true,
        dropdown: state.contentDropdown,
        selected: action.section,
      };
    }

    case `${SELECT_SECTION}_FAILURE`:
      return {
        ...state,
        loaded: true,
      };

    case `${SET_SECTIONS}_REQUEST`:
      return {
        ...state,
        loading: true,
      };

    case REORDER_DRAFT_SECTIONS: {
      const cover = state.data.length && state.data.find((section) => section.type === 'Cover');

      const content = [
        {
          id: 'contactform',
          displayName: 'Update Contact Form',
          redirect: true,
          url: '/personalize-form',
        },
        cover,
        {
          id: 'manage',
          type: 'manage',
          displayName: 'Manage Sections',
          redirect: true,
          order: 1,
          url: '/manage-book-sections',
        },
      ];

      const chapter = action.data.sections.find((section) => section.type === 'Chapter');

      const parentManuscript = {
        id: 'parentManuscript',
        type: 'parentManuscript',
        displayName: 'Manuscript',
        order: chapter && chapter.order,
      };

      const sections = action.data.sections.filter(
        (section) =>
          section.currentStatus === 'active' &&
          section.type !== 'Chapter' &&
          section.type !== 'TOC',
      );

      const data = [...content, ...sections, parentManuscript];

      data.sort((a, b) => a.order - b.order);

      return {
        ...state,
        dropdown: data,
      };
    }
    case SET_SECTIONS:
      const [first, ...rest] = action.sections;
      let chapters = [
        {
          id: 'manage',
          type: 'manage',
          displayName: 'Manage Sections',
          redirect: true,
          order: 1,
          url: '/manage-book-sections',
        },
      ];
      let content = [
        {
          id: 'contactform',
          displayName: 'Update Contact Form',
          redirect: true,
          url: '/personalize-form',
        },
        first,
        {
          id: 'manage',
          type: 'manage',
          displayName: 'Manage Sections',
          redirect: true,
          order: 1,
          url: '/manage-book-sections',
        },
      ];
      rest.forEach((section) => {
        if (section.currentStatus === 'active') {
          if (section.type === 'Chapter') {
            chapters = [...chapters, section];
          } else {
            content = [...content, section];
          }
        }
      });

      const firstChapter = chapters[1];

      const parentManuscript = {
        id: 'parentManuscript',
        type: 'parentManuscript',
        displayName: 'Manuscript',
        order: firstChapter && firstChapter.order,
      };

      content = [...content, parentManuscript];
      content = content.sort((a, b) => a.order - b.order);

      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.sections,
        contentDropdown: content,
        chapterDropdown: chapters,
      };

    case `${ADD_NEW_CHAPTER}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case ADD_NEW_CHAPTER: {
      const chapters = [action.payload.data, ...state.chapterDropdown];

      // Updating Sections data after adding newChapter

      const newData = [...state.data.filter((element) => element.type !== 'Chapter'), ...chapters];

      return { ...state, chapterDropdown: chapters, data: newData };
    }

    case `${ADD_NEW_CHAPTER}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }
    case `${UPDATE_SECTION}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_SECTION: {
      return { ...state, loaded: true, selected: action.section };
    }

    case `${UPDATE_SECTION}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    default:
      return { ...state };
  }
};
