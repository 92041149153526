import { useState } from 'react';
import { get } from 'lodash-es';
import { useQuery } from 'react-query';
import { DatePicker } from 'antd';
import { ArrowUpIcon } from 'modules/v2/common/components/SvgIcon';

import { getDraftList, getStats } from 'modules/api';
import { DashboardTemplate } from 'modules/v2/templates';
import { MyBooksSideNav } from 'modules/v2/components';
import { formatDate } from 'modules/v2/common/utils';
import { ErrorState } from 'modules/v2/common/components';

import BookCards from './components/BookCards';
import BookTable from './components/BookTable';

const { RangePicker } = DatePicker;
export const BookPerformanceAnalytics = () => {
  const dates = new Date();
  const fortNightDate = formatDate(dates.setDate(dates.getDate() - 90), 'YYYY-MM-DD');
  const currentDate = formatDate(new Date(), 'YYYY-MM-DD');
  const [startDate, setStartDate] = useState(fortNightDate);
  const [endDate, setEndDate] = useState(currentDate);
  const { isError: isStatsError, data: statsData } = useQuery(
    ['gettingStats', { startDate, endDate }],
    () => getStats(startDate, endDate),
  );
  const { isError: isListError, isLoading: isListLoading, data: listData } = useQuery(
    'gettingList',
    getDraftList,
  );

  const [visits, leads, digitalVisits] = get(statsData, 'data', []);
  const list = get(listData, 'data', []);

  const dataChange = (event, date) => {
    if (event && event.length === 2) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    }
  };

  return (
    <DashboardTemplate
      sidebar={<MyBooksSideNav />}
      hasSidebar
      title="Book analytics"
      contentTitle="Book Performance Analytics"
      isError={isListError || isStatsError}
      error={
        <ErrorState
          topMessage={
            <>
              {' '}
              Sorry, our server encounters an error while processing and loading your Leads. <br />
              Please try again later.
            </>
          }
          bottomMessage={
            <>
              If the issue continues, please let our team know by{' '}
              <a href="/help-center">clicking here.</a>
            </>
          }
        />
      }
    >
      <div className="flex w-full justify-between items-center bg-neutral-0 py-[15px] px-[24px] rounded-t-lg border-b mt-[24px] shadow max-[700px]:flex-col max-[700px]:gap-y-3">
        <p className="font-semibold text-base">Overview</p>
        <div>
          <RangePicker
            onChange={dataChange}
            suffixIcon={
              <div className="rotate-180">
                <ArrowUpIcon />
              </div>
            }
          />
        </div>
      </div>

      <BookCards visits={visits} leads={leads} digitalVisits={digitalVisits} loading={isListLoading}/>

      <BookTable bookData={list} loading={isListLoading} />
    </DashboardTemplate>
  );
};

export default BookPerformanceAnalytics;
