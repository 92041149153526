import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { get, map } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import getInstance from 'modules/api/instance';
import { DashboardTemplate } from 'modules/v2/templates';
import { getAttributes, isEmailVerified, sendAttributes, sendEmailVerification } from 'modules/api';
import {
  ErrorMessage,
  FieldControl,
  FieldGroup,
} from 'modules/common/components/UIComponents/Form';
import { notification } from 'modules/v2/common/utils/notify';
import { TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { Select } from 'antd';
import ImageUploaderModal from 'modules/v2/pages/ReferralMarketingMagazines/components/ImageUploaderModal';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import validationSchema from './ValidationSchema';
import S from './styles';

const { Option } = Select;

const SenderEmailConfiguration = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('Loading Email attribute...');
  const [verifiedEmail, setverifiedEmail] = useState(false);
  const [intialvalue, setInitialValue] = useState({});
  const [digitalBookData, setDigitalBookData] = useState([]);
  const [isBookLinkVisible, setIsBookLinkVisible] = useState(false);
  const [selectedDigitalBook, setSelectedDigitalBook] = useState('');
  const [isEditImage, setIsEditImage] = useState(false);
  const [brokerLogoModal, setBrokerLogoModal] = useState(false);

  const {
    isError: isAttributesError,
    isLoading: isAttributesLoading,
    data: attributes,
    refetch,
  } = useQuery(['getAttributes'], getAttributes);

  const attributesData = get(attributes, 'data', {});
  const checkEmailVerirification = () => {
    isEmailVerified({ email: customerEmail }).then((response) => {
      setverifiedEmail(response.data);
    });
  };

  useEffect(() => {
    async function getBookUrls() {
      const instance = getInstance();
      const { data } = await instance.get('user/links?properties=digitalBook');
      const digitalBookLinks = data?.data.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.digitalBook === value.digitalBook
        ))
      );
      digitalBookLinks.push({ digitalBook: 'other' });
      setDigitalBookData(digitalBookLinks);
    }

    if (attributesData && attributesData.email) {
      setInitialValue(attributesData);
      setSelectedDigitalBook(attributesData.memberBookUrl);
      isEmailVerified({ email: attributesData.email }).then((response) => {
        setverifiedEmail(response.data);
      });
    }

    getBookUrls();
  }, [attributesData, attributesData.memberBookUrl]);

  const { mutateAsync: sendDataAttributes, isLoading: isAttributesDataLoading } = useMutation(
    ['sendAttributes'],
    sendAttributes,
  );
  const { mutateAsync: sendEmailVerificatoinMutation } = useMutation(
    ['sendEmailVerification'],
    sendEmailVerification,
  );

  const onSave = (e) => {
    setLoadingMessage('Saving Changes On Email Attribute Setup');
    let attributeRequest = null;
    if (imageUrl) {
      attributeRequest = { ...e, ...{ imageUrl } };
    } else {
      attributeRequest = e;
    }
    attributeRequest.email = e?.memberEmail;
    sendDataAttributes(attributeRequest)
      .then(() => {
        refetch();
        setLoadingMessage('Loading Email attribute...');
        notification.success({
          description: 'Email attribute setup saved successfully',
          autoClose: 3000,
        });
      })
      .catch(() => {
        setLoadingMessage('Loading Email attribute...');
        notification.error({
          description:
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const handleVerify = () => {
    sendEmailVerificatoinMutation({ email: customerEmail })
      .then(() => {
        notification.success({
          description: 'Please check your email and click on the link to verify your email.',
          autoClose: 3000,
        });
      })
      .catch(() => {
        notification.error({
          description:
            'The system has encountered an error. Please try again or contact customer support!!!',
          autoClose: 3000,
        });
      });
  };

  const toggleBrokerLogoModal = () => {
    setBrokerLogoModal(!brokerLogoModal);
  };

  return (
    <DashboardTemplate
      isError={isAttributesError}
      isLoading={isAttributesLoading || isAttributesDataLoading}
      loadingTitle={loadingMessage}
      contentTitle="Sender Email Configuration"
      hasSidebar
    >
      <div className="text-2xl font-bold">Sender email</div>
      <div className="w-full bg-neutral-0 py-[24px] px-[24px] rounded-t-lg border-b-2 mt-[24px] shadow">
        <p className="font-semibold text-base">Manage sender</p>
        <span className="text-sm text-neutral-500">
          Who is sending this email campaign? This will appear across your email content.
        </span>
      </div>
      <div className="w-full bg-neutral-0 rounded-b-lg border-b-2 shadow-md py-[30px] px-[24px]">
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={intialvalue}
          onSubmit={onSave}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            isValid,
          }) => {
            setCustomerEmail(values.memberEmail?.toLowerCase() ?? '');
            const handleUpdateFields = (field, value) => {
              setIsBookLinkVisible(false);
              setFieldValue(field, value);
            };

            const handleSelectChange = (field) => (data) => {
              const value = get(data, 'target.value', data);
              const isOtherMemberBookUrl = value === 'other';
              setSelectedDigitalBook(value);
              return isOtherMemberBookUrl
                ? setIsBookLinkVisible(true)
                : handleUpdateFields(field, value);
            };

            const onRemoveImage = (path) => {
              setFieldValue(path, '');
              setImageUrl(null);
              setIsEditImage(false);
            };

            const onEditImage = () => {
              setIsEditImage(true);
              setImageUrl(values.imageUrl);
            };

            return (
              <Form onSubmit={handleSubmit}>
                <FieldGroup gap className="max-[700px]:flex-col">
                  <FieldControl>
                    <S.Label>First name</S.Label>
                    <TextInput
                      id="memberFirstName"
                      name="memberFirstName"
                      $hasError={errors.memberFirstName && touched.memberFirstName}
                      onChange={handleChange}
                      value={values.memberFirstName}
                      onBlur={handleBlur}
                    />
                    {errors.memberFirstName && touched.memberFirstName ? (
                      <ErrorMessage>{errors.memberFirstName}</ErrorMessage>
                    ) : null}
                  </FieldControl>
                  <FieldControl>
                    <S.Label>Last name</S.Label>
                    <TextInput
                      id="memberLastName"
                      name="memberLastName"
                      $hasError={errors.memberLastName && touched.memberLastName}
                      value={values.memberLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.memberLastName && touched.memberLastName ? (
                      <ErrorMessage>{errors.memberLastName}</ErrorMessage>
                    ) : null}
                  </FieldControl>
                </FieldGroup>
                <FieldGroup gap className="max-[700px]:flex-col">
                  <FieldControl>
                    <S.Label>Email address</S.Label>

                    <S.MainWrapper emaibutton>
                      <TextInput
                        id="memberEmail"
                        name="memberEmail"
                        className="w-full"
                        $hasError={errors.memberEmail && touched.memberEmail}
                        value={values.memberEmail?.toLowerCase() ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <S.EmailRefresh onClick={checkEmailVerirification} />
                    </S.MainWrapper>
                    {errors.memberEmail && touched.memberEmail ? (
                      <ErrorMessage>{errors.memberEmail}</ErrorMessage>
                    ) : null}

                    {!verifiedEmail ? (
                      <S.Message>This will be the email displayed the sender</S.Message>
                    ) : (
                      <S.VerifiedMessage>Email has been verified</S.VerifiedMessage>
                    )}
                  </FieldControl>
                  <FieldControl>
                    <S.Label>Phone number</S.Label>

                    <S.InputMask
                      id="memberPhone"
                      name="memberPhone"
                      mask="999-999-9999"
                      $hasError={errors.memberPhone && touched.memberPhone}
                      value={values.memberPhone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.memberPhone && touched.memberPhone ? (
                      <ErrorMessage>{errors.memberPhone}</ErrorMessage>
                    ) : null}
                  </FieldControl>
                </FieldGroup>

                <div className="w-full h-[1px] border-b my-[34px]" />

                <div>
                  <S.Label>Your photo</S.Label>
                  <ImageUploaderModal
                    imageDimensions={{ width: 520, height: 520 }}
                    type="rm_profile"
                    uploadImageType="rm_profile"
                    modalTitle="Profile Image"
                    imageUrl={values.imageUrl}
                    selectAreaWidth="100%"
                    selectAreaJustifyContent="start"
                    onSuccess={(value) => {
                      setFieldValue('imageUrl', value);
                      toggleBrokerLogoModal();
                    }}
                    isOpen={brokerLogoModal === 'photo'}
                    onCancel={toggleBrokerLogoModal}
                    handleOpenModal={() => setBrokerLogoModal('photo')}
                    onRemoveImage={(e) => {
                      onRemoveImage('imageUrl');
                      e.stopPropagation();
                    }}
                    onEditImage={(e) => {
                      onEditImage();
                      setBrokerLogoModal('photo');
                      e.stopPropagation();
                    }}
                    imageUrlForEdit={imageUrl}
                    isEditImage={isEditImage}
                    toggleCancelEdit={() => setIsEditImage(!isEditImage)}
                  />
                </div>

                <FieldGroup gap className="max-[700px]:flex-col">
                  {digitalBookData.length > 0 && (
                    <FieldControl>
                      <S.Label>Select digital book</S.Label>

                      <S.Select
                        bordered
                        id="memberBookUrl"
                        name="memberBookUrl"
                        className="ring-primary-500"
                        defaultValue={selectedDigitalBook}
                        onChange={handleSelectChange('memberBookUrl')}
                      >
                        {map(digitalBookData, ({ digitalBook }) =>
                          digitalBook ? (
                            <Option key={digitalBook} value={digitalBook}>
                              {digitalBook}
                            </Option>
                          ) : null,
                        )}
                      </S.Select>
                    </FieldControl>
                  )}
                  <FieldControl>
                    {isBookLinkVisible ? (
                      <>
                        <S.Label>Book link</S.Label>
                        <TextInput
                          id="bookLink"
                          name="bookLink"
                          onChange={(event) => setFieldValue('memberBookUrl', event.target.value)}
                          value={values.memberBookUrl}
                          onBlur={handleBlur}
                        />
                      </>
                    ) : null}
                  </FieldControl>
                </FieldGroup>

                <FieldGroup gap className="max-[700px]:flex-col">
                  <FieldControl>
                    <S.Label>Title</S.Label>

                    <TextInput
                      id="memberTitle"
                      name="memberTitle"
                      onChange={handleChange}
                      value={values.memberTitle}
                      onBlur={handleBlur}
                    />
                  </FieldControl>
                  <FieldControl>
                    <S.Label>Broker name</S.Label>

                    <TextInput
                      id="memberBrokerName"
                      name="memberBrokerName"
                      value={values.memberBrokerName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldControl>
                </FieldGroup>

                <FieldGroup gap>
                  <FieldControl>
                    <S.Label>Broker address</S.Label>

                    <TextInput
                      id="memberAddress"
                      name="memberAddress"
                      $hasError={errors.memberAddress && touched.memberAddress}
                      value={values.memberAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldControl>

                  <FieldControl />
                </FieldGroup>

                <div className="w-full h-[1px] border-b my-[34px]" />

                <div className="w-full flex justify-between">
                  <Button type="dark" onClick={handleSubmit} disabled={!isValid}>
                    Save changes
                  </Button>

                  {!verifiedEmail && (
                    <Button
                      onClick={handleVerify}
                      disabled={!(values.memberEmail && !errors.memberEmail)}
                    >
                      Send a verification email
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DashboardTemplate>
  );
};

export default SenderEmailConfiguration;
