import env from 'environments';

export const formatedPhoneNumber = () => {
  const phoneNumber = env.SUPPORT_PHONE;
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  const areaCode = digitsOnly.slice(1, 4);
  const prefix = digitsOnly.slice(4, 7);
  const lineNumber = digitsOnly.slice(7);

  return `(${areaCode}) ${prefix}-${lineNumber}`;
};
