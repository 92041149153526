import { LOCATION_CHANGE } from 'connected-react-router';
import { call, delay, put, race, take, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';

import { fetchDraftPreview, getPayload, getError } from 'modules/api';

import { GET_DRAFT_PREVIEW } from 'modules/draft/store/constants';
import {
  isPreviewStatusReady,
  isPreviewStatusModified,
  isPreviewStatusFailed,
} from 'modules/draft/utils/preview';

const RETRY_TIME = 1000;

export function* onGetDraftPreview({ payload: meta }) {
  yield put({
    type: `${GET_DRAFT_PREVIEW}_REQUESTED`,
    meta,
  });

  try {
    while (true) {
      const response = yield call(fetchDraftPreview, meta);
      const payload = getPayload(response);

      const status = get(payload, 'status');
      const isSucceeded = [
        isPreviewStatusReady,
        isPreviewStatusModified,
        isPreviewStatusFailed,
      ].some((check) => check(status));

      if (isPreviewStatusFailed(status)) {
        yield put({
          type: `${GET_DRAFT_PREVIEW}_FAILED`,
          payload,
        });
        return payload;
      }

      // The data may have the status failed, but the action has succeeded
      if (isSucceeded) {
        yield put({
          type: `${GET_DRAFT_PREVIEW}_SUCCEEDED`,
          payload,
        });

        return payload;
      }

      yield delay(RETRY_TIME);
    }
  } catch (error) {
    const payload = getError(error);

    yield put({
      type: `${GET_DRAFT_PREVIEW}_FAILED`,
      error: true,
      payload,
    });

    // eslint-disable-next-line no-console
    console.error(payload);

    return payload;
  }
}

export function* watchCancellation(action) {
  yield race([call(onGetDraftPreview, action), take(LOCATION_CHANGE)]);
}

export function* watchGetDraftPreview() {
  yield takeEvery(GET_DRAFT_PREVIEW, watchCancellation);
}
