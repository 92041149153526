import { Children } from 'react';
import { Row, Col } from 'antd';

import { AddIcon } from 'modules/common/components/SvgIcon';
import Item from './components/Item';

import S from './styles';

const CreateAddress = ({ onCreate }) => (
  <S.Empty onClick={onCreate}>
    <AddIcon width="60px" height="60px" />
    <span>Add New Address</span>
  </S.Empty>
);

const AddressList = ({ onCreate, children }) => (
  <Row type="flex" gutter={[32, 22]}>
    {onCreate && (
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <CreateAddress onCreate={onCreate} />
      </Col>
    )}
    {Children.map(children, (item, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <Col xs={24} sm={24} md={24} lg={12} xl={12} key={key}>
        {item}
      </Col>
    ))}
  </Row>
);

AddressList.Item = Item;

export default AddressList;
