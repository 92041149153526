import styled from 'styled-components';
import { device as BreakPoints } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';

const PreviewBook = styled.div`
  .center-heading {
    text-align: center;
    padding: 0px;
    width: 230px;
    margin: 0 auto;
    margin-bottom: 20px;
    @media screen and ${BreakPoints.tabletS} {
      width: 100%;
    }
  }
  font-family: 'Roboto' !important;
  margin: 0 30px;
  flex: 1;
  @media screen and ${BreakPoints.tabletS} {
    margin: 10px auto;
    order: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin: 0 30px;
  }
`;

const CoverWrap = styled.div`
  position: relative;
`;

const UploadedImageWrapper = styled.div`
  position: relative;
  width: 230px;
  margin: 0 auto !important;
`;

const UserUploadedImage = styled.img`
  object-fit: cover;
  @media screen and ${BreakPoints.tabletS} {
    object-fit: cover;
  }
`;

const BookImage = styled.img`
  width: 228px;
  height: 335px;
  display: ${(p) => (p.loading ? 'none' : 'block')};
`;

const BookText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${color.Black};
  width: 100%;
  text-align: center;
  margin-bottom: 5px !important;
  margin-top: 15px !important;
`;

const BookViewBack = styled.a`
  font-size: 16px;
  font-weight: 600;
  color: ${color.DefaultBlue};
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
`;

const AuthorNameBottom = styled.p`
  position: absolute;
  bottom: 22px;
  left: 50%;
  font-size: 11px;
  padding: 0 2px;
  min-width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
`;

const AuthorNameLeft = styled.p`
  position: absolute;
  left: -22px;
  color: ${color.White};
  padding: 0;
  bottom: 57px;
  line-height: 7px;
  font-size: 9px;
  min-width: 75px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform: rotate(88deg);
  font-weight: 700;
`;

export default {
  BookImage,
  BookText,
  BookViewBack,
  CoverWrap,
  PreviewBook,
  UploadedImageWrapper,
  UserUploadedImage,
  AuthorNameBottom,
  AuthorNameLeft,
};
