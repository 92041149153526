import { SHOW_ALERT } from '../constants';

const initialState = {
  open: false,
  message: '',
  variant: '',
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT: {
      return {
        ...state,
        message: action.message,
        variant: action.variant,
      };
    }
    default: {
      return { ...state };
    }
  }
};
