import S from '../AuthenticationTemplate/index.styled';

const LoginMessage = ({ children }) => (
  <S.LoginErrorWrap>
    <p>
      <strong>This doesn't look right.</strong>
    </p>
    <p>{children}</p>
  </S.LoginErrorWrap>
);

export default function LoginErrors({ invalidEmail, statusCode, statusMessage }) {
  let message;

  if (statusMessage === 'Inactive user') {
    message = (
      <>
        Your account is inactive. Please contact support at{' '}
        <S.ContactNumber>(904) 695-9933</S.ContactNumber>
      </>
    );
  } else if (statusMessage === 'No books assigned') {
    message = (
      <>
        You don't have any assigned books. Please contact us using{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://authorify.com/contact/"
          className="linked"
        >
          this form
        </a>
        .
      </>
    );
  } else if (statusMessage === 'Deleted user') {
    message = 'User not found.';
  } else if (invalidEmail) {
    message = 'Please enter a valid email address and password to try again.';
  } else if (statusCode === 1) {
    message = "You've entered an invalid email or password. Please check your entry and try again.";
  }

  if (!message) {
    return null;
  }

  return <LoginMessage>{message}</LoginMessage>;
}
