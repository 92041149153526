import { get } from 'lodash-es';

import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';
import { GET_RELEASE_NOTES, GET_LATEST_RELEASE_NOTE } from 'modules/v2/store/constants';
import { releaseNotes as initialState } from 'modules/v2/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_RELEASE_NOTES}_REQUESTED`:
    case `${GET_LATEST_RELEASE_NOTE}_REQUESTED`: {
      return {
        ...state,
        status: STATUS_LOADING,
      };
    }

    case `${GET_RELEASE_NOTES}_SUCCEEDED`: {
      const data = get(action, ['payload'], null);

      return {
        ...state,
        status: STATUS_SUCCESS,
        data,
      };
    }

    case `${GET_LATEST_RELEASE_NOTE}_SUCCEEDED`: {
      const latest = get(action, ['payload'], null);

      return {
        ...state,
        status: STATUS_SUCCESS,
        latest,
      };
    }

    case `${GET_RELEASE_NOTES}_FAILED`:
    case `${GET_LATEST_RELEASE_NOTE}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    default:
      return state;
  }
};

export default reducer;
