import { Radio as RadioButton, CustomFlowbiteTheme } from 'flowbite-react';
interface RadioProps {
  id: string;
  name: string;
  value: string;
  onChange: () => void;
  className?: string;
}

const defaultProps: Partial<RadioProps> = {
  className: '',
};

const customRadioTheme: CustomFlowbiteTheme['radio'] = {
  root: {
    base:
      'h-4 w-4 border border-neutral-300 text-neutral-300 focus:border-primary-500 focus:ring-2 focus:ring-primary-500 checked:border-primary-500 checked:bg-primary-500 checked:text-white',
  },
};

const Radio = ({ id, name, value, onChange, className }: RadioProps) => {
  return (
    <RadioButton
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      theme={customRadioTheme}
      className={className}
    />
  );
};

Radio.defaultProps = defaultProps;

export default Radio;
