import { all, call, cancel, fork, put, select, spawn, take } from 'redux-saga/effects';
import { getLocation, replace, LOCATION_CHANGE } from 'connected-react-router';
import { get } from 'lodash-es';

import ObjectId from 'bson-objectid';

import { fetchBookById, fetchDraftsByBookId, getPayload, getError } from 'modules/api';
import { clearDraftEditor, goToRoot } from 'modules/editor/store';
import { getRouteEditDraft } from 'modules/editor/routes/navigation';

import { GET_BOOK_BY_ID } from 'modules/editor/store/constants';

import { isEditorDraftRoute } from 'modules/editor/routes/routeMatchers';

export function* onGetBookById({ payload: meta }) {
  yield put({
    type: `${GET_BOOK_BY_ID}_REQUESTED`,
    meta,
  });

  try {
    const [bookResponse, draftsResponse] = yield all([
      call(fetchBookById, meta),
      call(fetchDraftsByBookId, meta),
    ]);

    const bookPayload = getPayload(bookResponse);
    const draftsPayload = getPayload(draftsResponse);

    const book = get(bookPayload, ['data', 'book'], null);
    const drafts = get(draftsPayload, ['results'], []);

    // Get only drafts that were not published by the user
    const existingDraft = drafts
      .filter((draft) => draft.milestone === 'draft')
      .map((draft) => draft.draftId)
      .find(ObjectId.isValid);

    const payload = {
      book,
    };

    yield put({
      type: `${GET_BOOK_BY_ID}_SUCCEEDED`,
      error: false,
      payload,
      meta,
    });

    if (book === null) {
      yield put(goToRoot());
    }

    if (existingDraft) {
      const location = getRouteEditDraft(existingDraft);

      // Use replace to avoid returning to the same URL with back button
      yield put(replace(location));
    }

    return payload;
  } catch (error) {
    const payload = getError(error);

    yield put({
      type: `${GET_BOOK_BY_ID}_FAILED`,
      error: true,
      payload,
      meta,
    });

    yield put(goToRoot());

    return payload;
  }
}

export function* onExitEditor(getBook) {
  yield cancel(getBook);
  yield put(clearDraftEditor());
}

const validFn = (location) => isEditorDraftRoute(location);

export function* watchGetBookById() {
  while (true) {
    const action = yield take(GET_BOOK_BY_ID);

    if (action) {
      const getBook = yield fork(onGetBookById, action);

      const location = yield take(LOCATION_CHANGE);

      if (location) {
        const next = yield select(getLocation);

        if (!validFn(next)) {
          yield onExitEditor(getBook);
        }
      }
    }
  }
}

export default function* saga() {
  yield spawn(watchGetBookById);
}
