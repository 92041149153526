import React from 'react';

import { useFormikContext } from 'formik';

import { FormLabel } from 'modules/common/components';

import S from './styles';

const ContactDetailsForm = ({ onConfirm, onCancel }) => {
  const { values, errors, handleChange } = useFormikContext();

  return (
    <S.Form className="address-form">
      <S.Form.Row>
        <FormLabel
          title="Email address"
          error={errors.email}
          htmlFor="email"
          required
          contactDetails
        >
          <S.Form.Input
            id="email"
            type="email"
            name="email"
            value={values.email}
            error={errors.email}
            onChange={handleChange}
          />
        </FormLabel>
      </S.Form.Row>
      <S.Form.Row>
        <FormLabel
          title="Phone number"
          error={errors.phone}
          htmlFor="phone"
          required
          contactDetails
        >
          <S.Form.InputMask
            alwaysShowMask
            id="phone"
            name="phone"
            type="text"
            error={errors.phone}
            mask="999-999-9999"
            value={values.phone}
            onChange={handleChange}
          />
        </FormLabel>
      </S.Form.Row>
      <S.BtnWrapper>
        <S.Btn type="button" onClick={onCancel}>
          Cancel
        </S.Btn>
        <S.PrimaryBtn type="button" onClick={onConfirm}>
          Save changes
        </S.PrimaryBtn>
      </S.BtnWrapper>
    </S.Form>
  );
};

export default React.forwardRef(ContactDetailsForm);
