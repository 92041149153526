import { useEffect } from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router';

import withStore from 'store/StoreContainer';
import { Empty } from 'modules/common/components';
import { editOrderedDraft } from 'modules/editor/store';

const withOrderBookRoute =
  (Component = Empty) =>
    (props) => {
      const { orderId } = useParams();

      useEffect(() => {
        if (orderId) {
          props.editOrderedDraft(orderId);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [orderId]);

      return <Component {...props} />;
    };

const mapDispatchToProps = {
  editOrderedDraft,
};

export default compose(withStore(undefined, mapDispatchToProps), withOrderBookRoute);
