import get from 'lodash/get';
import { ErrorMessage } from 'modules/v2/common/components';
import { checkWordLimit, getWordsCount } from 'modules/v2/utils/functions';
import { TextInput, Textarea } from 'flowbite-react';
import { defaultTextInputTheme } from 'modules/v2/common/AtomicDesign/atoms/TextInput/theme';
import { defaultTextAreaTheme } from 'modules/v2/common/AtomicDesign/atoms/TextArea/theme';

const ReferralPartner = ({ step = '', formikHandler }) => {
  const { values = {}, setFieldValue, errors, setFieldError } = formikHandler || {};
  const targetLocation = step.concat('.', '_formFields');

  const handleChange = (e) => {
    const {
      target: { name = '', value = '' },
    } = e || {};
    if (name === 'partnerDescription') {
      const { limitExceeded, isRemovingWords } = checkWordLimit(
        `${targetLocation}.${name}`,
        value,
        50,
      );

      if (limitExceeded && !isRemovingWords) {
        setFieldError(
          `${targetLocation}.${name}`,
          'Partner Description must not be more than 50 words.',
        );
        return;
      }
    }

    const locationToSave = step.concat('.', '_formFields', '.', name);
    setFieldValue(locationToSave, value);
  };

  const partnerDescription = get(values, `${targetLocation}.partnerDescription`) || '';
  const partnerDescriptionWordCount = getWordsCount(partnerDescription);

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Partner name</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerName"
              name="partnerName"
              type="text"
              value={get(values, `${targetLocation}.partnerName`)}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <ErrorMessage>{get(errors, `${targetLocation}.partnerName`) || ''}</ErrorMessage>
        </div>
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Partner header</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerHeader"
              name="partnerHeader"
              type="text"
              value={get(values, `${targetLocation}.partnerHeader`)}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <ErrorMessage>{get(errors, `${targetLocation}.partnerHeader`) || ''}</ErrorMessage>
        </div>
      </div>
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Marketing phone</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerPhone"
              name="partnerPhone"
              type="text"
              value={get(values, `${targetLocation}.partnerPhone`)}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <ErrorMessage>{get(errors, `${targetLocation}.partnerPhone`) || ''}</ErrorMessage>
        </div>
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Partner email</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerEmail"
              name="partnerEmail"
              type="text"
              value={get(values, `${targetLocation}.partnerEmail`)}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <ErrorMessage>{get(errors, `${targetLocation}.partnerEmail`) || ''}</ErrorMessage>
        </div>
      </div>
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Partner address</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerAddress"
              name="partnerAddress"
              type="text"
              value={get(values, `${targetLocation}.partnerAddress`)}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <ErrorMessage>{get(errors, `${targetLocation}.partnerAddress`) || ''}</ErrorMessage>
        </div>
      </div>
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-1">
          <label className="text-sm font-semibold text-neutral-800">Description</label>
          <span className="self-stretch text-neutral-500 text-sm font-medium">
            If you work with a company (mortgage broker, electrician, title company, plumber, etc.)
            you'd like to advertise for, this is the place to do it. Type in a short endorsement of
            their business, and upload a photo of their business card/logo or of work they've
            completed.
          </span>
          <Textarea
            theme={defaultTextAreaTheme}
            id="partnerDescription"
            name="partnerDescription"
            type="text"
            value={partnerDescription}
            onChange={handleChange}
            rows="3"
            placeholder=""
          />
          <div className="flex justify-between items-center mt-1">
            <ErrorMessage>{get(errors, `${targetLocation}.partnerDescription`) || ''}</ErrorMessage>
            <div className="overflow-hidden text-neutral-400 text-ellipsis  text-sm font-medium">
              {partnerDescriptionWordCount}/50 words
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralPartner;
