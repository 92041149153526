import AuthenticationTemplate from 'modules/v2/components/AuthenticationTemplate';
import { Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { WarnMessage } from 'modules/v2/common/AtomicDesign/molecules';
import { Link } from 'react-router-dom';
import LoginErrors from 'modules/v2/components/Login/LoginErrors';
import S from 'modules/v2/components/AuthenticationTemplate/index.styled';

function ForgotPassword({ statusCode, message, handleChange, handleSubmit, auth }) {
  return (
    <AuthenticationTemplate pageTitle="Forgot your password?">
      <p className="text-center text-neutral-500 text-sm mt-2 mb-8">
        Enter the email address associated with your account, and we'll email you a link to reset
        your password.
      </p>

      {statusCode === 0 && (
        <WarnMessage type="success">
          A link to reset your password has been sent to your email address.
        </WarnMessage>
      )}
      {message && statusCode === 1 && (
        <LoginErrors statusCode={statusCode} statusMessage={message} />
      )}

      <S.LoginForm layout="vertical" onFinish={handleSubmit}>
        <S.FormItem
          label="Email"
          name="username"
          rules={[{ required: true, message: 'Please enter a valid email!', type: 'email' }]}
        >
          <input
            className="w-full h-11 px-5 py-3 bg-white rounded-md border border-neutral-200 text-sm"
            size="large"
            name="username"
            placeholder="Please type your email here"
            onChange={handleChange}
          />
        </S.FormItem>
        <S.FormItem>
          <Button
            type="primary"
            buttonType="submit"
            full
            disabled={auth.loading && !auth.loaded}
            onClick={handleSubmit}
            size="xl"
            className="w-full"
          >
            Send reset link
          </Button>
        </S.FormItem>
        <S.FormItem>
          <div className="text-sm font-medium text-neutral-500 text-center">
            <span> Go back to </span>
            <Link className="text-teal-400 font-semibold leading-snug" to="/auth/login">
              login
            </Link>
          </div>
        </S.FormItem>
      </S.LoginForm>
    </AuthenticationTemplate>
  );
}

export default ForgotPassword;
