import { useState } from 'react';
import { useQuery } from 'react-query';
import { Skeleton } from 'antd';
import { compose } from 'redux';

import { fetchUserAddresses } from 'modules/api/user';
import { withAddress, withCountries } from 'modules/v2/containers';
import { notification } from 'modules/common/utils';
import ShippingAddressItem from './ShippingAddressItem';
import RelativeLoading from '../../../common/AtomicDesign/RelativeLoading';

type ObjectStoreType = {
  data: object[];
  status: string;
};

type ShippingAddress = {
  countries: ObjectStoreType;
  states: ObjectStoreType;
  addresses: ObjectStoreType;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  pincode: string;
  state: string;
  _id: string;
  phoneNumber: string;
  isDefault: boolean;
  editUserAddresses: (fields: object) => Promise<unknown>;
  deleteUserAddresses: (id: string) => void;
};

type Props = {
  countries: ObjectStoreType;
  states: ObjectStoreType;
  addresses: ObjectStoreType;
  shippingAddressList: ShippingAddress[];
  additionalShippingLoading?: boolean;
  editUserAddresses: (fields: object) => Promise<unknown>;
  deleteUserAddresses: (id: string) => void;
  selectedAddress?: string;
  setSelectedAddress?: (address: ShippingAddress) => void;
  isRefetching?: boolean;
};

const ShippingAddressSelect: React.FC<Props> = ({
  // Data from store
  countries,
  states,
  addresses,

  // Callcack functions
  editUserAddresses,
  deleteUserAddresses,

  shippingAddressList,
  additionalShippingLoading = false,
  setSelectedAddress,
  selectedAddress,
  isRefetching,
}) => {
  const [updatedShippingAddressList, setUpdatedShippingAddressList] = useState<ShippingAddress[]>(
    shippingAddressList,
  );

  const {
    refetch: fetchUserAddressesRefetch,
    isRefetching: isRefetchingUserAddresses,
    isLoading,
  } = useQuery('fetchUserAddresses', fetchUserAddresses, {
    onSuccess: (res) => {
      const {
        data: { data },
      } = res;
      const { shippingAddress } = data;
      setUpdatedShippingAddressList(shippingAddress);
      const defaultSelectedAddress = shippingAddress.find((address) => address.isDefault === true);
      setSelectedAddress(defaultSelectedAddress);
    },
    onError: (error) => {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    },
  });

  const renderLoadingSkeleton = () => {
    return (
      <>
        <div className="border-neutral-200 border-[2px] rounded-lg p-4 mb-5 hover:bg-neutral-100 transition duration-300 ease-in-out cursor-pointer">
          <Skeleton
            paragraph={{
              rows: 2,
              width: '100%',
            }}
            active
          />
        </div>
        <div className="border-neutral-200 border-[2px] rounded-lg p-4 mb-5 hover:bg-neutral-100 transition duration-300 ease-in-out cursor-pointer">
          <Skeleton
            paragraph={{
              rows: 2,
              width: '100%',
            }}
            active
          />
        </div>
      </>
    );
  };

  const renderAdditionalLoadingSkeleton = () => {
    return (
      <div className="border-neutral-200 border-[2px] rounded-lg p-4 mb-5 hover:bg-neutral-100 transition duration-300 ease-in-out cursor-pointer">
        <Skeleton
          paragraph={{
            rows: 2,
            width: '100%',
          }}
          active
        />
      </div>
    );
  };

  return (
    <>
      <RelativeLoading isLoading={isRefetchingUserAddresses || isRefetching || isLoading} />
      {updatedShippingAddressList.map((address: ShippingAddress, index) => (
        <ShippingAddressItem
          key={address._id}
          countries={countries.data}
          states={states.data}
          addressLine1={address.addressLine1}
          addressLine2={address?.addressLine2}
          selectedAddress={selectedAddress}
          city={address.city}
          country={address.country}
          addresses={addresses}
          email={address.email}
          firstName={address.firstName}
          lastName={address.lastName}
          pincode={address.pincode}
          state={address.state}
          phoneNumber={address?.phoneNumber}
          isDefault={address.isDefault}
          _id={address._id}
          onClick={() => setSelectedAddress(address)}
          editUserAddresses={editUserAddresses}
          isLoading={isRefetchingUserAddresses || isRefetching || isLoading}
          deleteUserAddresses={deleteUserAddresses}
          fetchUserAddressesRefetch={fetchUserAddressesRefetch}
          isLast={index === updatedShippingAddressList.length - 1}
        />
      ))}
    </>
  );
};

ShippingAddressSelect.defaultProps = {
  shippingAddressList: [],
};

const Composed = compose(withAddress, withCountries)(ShippingAddressSelect);

export default Composed;
