import { combineReducers } from 'redux';

import initialState from './initialState';

import links from './links';

const combined = combineReducers({
  links,
});

const reducer = (state = initialState, action) => combined(state, action);

export default reducer;
