import { STATUS_INIT } from 'store/status';

export const addresses = {
  data: [],
  status: STATUS_INIT,
};

export const books = {
  data: null,
  status: STATUS_INIT,
};

export const countries = {
  data: [],
  status: STATUS_INIT,
};

export const customerPortalLink = {
  data: null,
  status: STATUS_INIT,
};

export const categories = {
  data: null,
  selected: null,
  status: STATUS_INIT,
};

export const drafts = {
  data: null,
  status: STATUS_INIT,
};

export const orders = {
  data: null,
  status: STATUS_INIT,
};

export const intakeForm = {
  upload: {
    data: null,
    error: '',
    status: STATUS_INIT,
  },
};

export const profile = {
  avatar: {
    data: null,
    status: STATUS_INIT,
  },
  data: {
    email: '',
    firstName: '',
    isNewUser: null,
    lastName: '',
    phone: '',
  },
  status: STATUS_INIT,
};

export const releaseNotes = {
  data: null,
  latest: null,
  status: STATUS_INIT,
};

export const states = {
  data: null,
  status: STATUS_INIT,
};

export const support = {
  ticket: {
    status: STATUS_INIT,
  },
};

export const user = {
  preferences: {
    status: STATUS_INIT,
  },
  password: {
    status: STATUS_INIT,
  },
};

export const offers = {
  data: null,
  status: STATUS_INIT,
};

export default {
  addresses,
  books,
  categories,
  countries,
  customerPortalLink,
  drafts,
  intakeForm,
  offers,
  orders,
  profile,
  releaseNotes,
  states,
  support,
  user,
};
