import { Space } from 'antd';
import S from './styles';

const PaymentMethodCard = ({ card, cardNumber, image, getStep, bordered = true }) => {
  return (
    <S.Container bordered={bordered}>
      <S.CreditWrapper>
        <S.CardImg src={image} alt={card} />
        <S.CardWrapper>
          <S.CardName>{card?.charAt(0)?.toUpperCase() + card?.slice(1)}</S.CardName>
          <Space>
            <S.DotContainer>
              <S.Dot />
              <S.Dot />
              <S.Dot />
              <S.Dot />
            </S.DotContainer>
            <S.Description>
              {cardNumber?.substring(cardNumber?.length - 4, cardNumber?.length)}
            </S.Description>
          </Space>
        </S.CardWrapper>
      </S.CreditWrapper>
      <S.Change
        onClick={() => {
          getStep(3);
        }}
      >
        Change
      </S.Change>
    </S.Container>
  );
};

export default PaymentMethodCard;
