import { Alert as BaseAlert } from 'antd';
import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const BookWrapBox = styled.div`
  text-align: center;
  button {
    margin-top: 10px;
  }
`;

const Alert = styled(BaseAlert)`
  margin: 20px auto !important;
  width: ${({ width }) => (width ? `${width}` : '500px')};
  font-size: 16px;
  line-height: 24px;
  padding: 17px !important;
  .ant-alert-message {
    color: ${color.grayBox};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default {
  Alert,
  BookWrapBox,
};
