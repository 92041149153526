import env from 'environments';
import { useMutation } from 'react-query';
import { createUploads } from 'modules/api';

const useImageUpload = ({ onSuccess, path = '' }) => {
  const { mutate, isLoading } = useMutation(createUploads, { onSuccess });

  const uploadImage = async (image) => {
    const fileExtension = image.name.split('.').pop();

    mutate({
      payload: {
        bucket: env.DS_BUCKET,
        isPrivate: false,
        ext: fileExtension,
        contentType: image?.type,
        path,
      },
      callbackTargetKey: 'uploadUrl',
      callbackPayload: image,
    });
  };

  return { uploadImage, isLoading };
};

export default useImageUpload;
