import { Formik } from 'formik';
import { setItem } from 'modules/dashboard/utils/legacy';
import { useFetchSession, useStep } from 'modules/onboard/hooks';
import { DENTIST_ORDER_DATA } from 'modules/onboard/constants';
import { ImageSlider, DentistGuideDetailsForm } from 'modules/onboard/components';
import { OnboardTemplate } from 'modules/onboard/templates';

import {
  getGuideDentistDetailsInitialValues,
  guideDentistDetailsValidationSchema as validationSchema,
  formatDentistDetailsValuesBeforeSubmit as formatValuesBeforeSubmit,
} from 'modules/onboard/utils';

import S from './styles';

const DentistGuidesDetails = () => {
  const { session } = useFetchSession();
  const initialValues = getGuideDentistDetailsInitialValues(session);
  const { goToNextStep } = useStep();

  const bookOptions = session?.currentOffer?.bookOptions || [];

  const onSave = async (values) => {
    const formattedValues = formatValuesBeforeSubmit(values, session);

    setItem(DENTIST_ORDER_DATA, formattedValues);
    goToNextStep('your_book');
  };

  return (
    <OnboardTemplate currentStep="4" hideNavigation isDentist>
      <S.Title>Your Guide details</S.Title>
      <S.SubTitle>Information provided below will be added and show on your 3 guides</S.SubTitle>

      <S.Main>
        <S.SidePreview>
          <S.SidePreviewWrapper>
            <ImageSlider title="Guide Preview" images={bookOptions} />
          </S.SidePreviewWrapper>
        </S.SidePreview>

        <S.Content>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSave}
          >
            <DentistGuideDetailsForm />
          </Formik>
        </S.Content>
      </S.Main>
    </OnboardTemplate>
  );
};

export default DentistGuidesDetails;
