import { useEffect } from 'react';
import { compose } from 'redux';

import withStore from 'store/StoreContainer';
import { Empty } from 'modules/common/components';
import { getCoverPreview } from 'modules/v2/editor/store/actions';
import { previewSelector } from 'modules/v2/editor/store';

const withCoverPreview = (Component = Empty) => (props) => {
  useEffect(() => {
    props.getCoverPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Component {...props} />;
};

const mapStateToProps = (state) => ({
  preview: previewSelector(state),
});

const mapDispatchToProps = {
  getCoverPreview,
};

export default compose(withStore(mapStateToProps, mapDispatchToProps), withCoverPreview);
