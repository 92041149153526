import { useState } from 'react';

import { Modal } from 'modules/common/components';

import ModalContent from './ModalContent';

const RichTextModal = ({
  title,
  element,
  isOpen,
  scale,
  isSimple,
  isExtended,
  onConfirm,
  onCancel,
}) => {
  const [state, setState] = useState(element);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(state);
    }
  };

  return (
    <Modal
      width="700px"
      title={title}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      maskClosable={false}
    >
      <ModalContent
        element={element}
        scale={scale}
        isSimple={isSimple}
        isExtended={isExtended}
        onChange={setState}
      />
    </Modal>
  );
};

export default RichTextModal;
