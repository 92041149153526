import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import * as color from 'modules/common/theme/color';

const Link = styled(NavLink)`
  border-bottom: 1px solid ${color.PrimaryColor};
  display: flex;
  padding: 18px;
  color: ${color.GreySelectDd};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  transition: none;

  &.active,
  &:hover,
  &:focus {
    background: ${color.PrimaryColor};
    color: ${color.White};

    svg {
      fill: ${color.White} !important;
    }
  }
`;

const ContentWrapper = styled.span`
  max-width: 80%;
  display: block;
`;

const Description = styled.span`
  font-size: 16px;
  line-height: 19px;
  display: block;
  margin-top: 3px;
  font-weight: 400;
`;

const IconWrapper = styled.span`
  svg {
    fill: #2e3f50;
    width: 15px;
    height: 20px;
  }
`;

export default {
  ContentWrapper,
  Description,
  IconWrapper,
  Link,
};
