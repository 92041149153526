import { getInstance } from './instance';

const instance = getInstance();

const sendEmailVerification = (params) => {
  const saveUrl = 'email-campaigns/attributes/verify';
  return instance.post(saveUrl, params);
};

const sendAttributes = (params) => {
  const saveUrl = 'email-campaigns/attributes';
  return instance.post(saveUrl, params);
};

const getAttributes = () => {
  const getUrl = 'email-campaigns/attributes';
  return instance.get(getUrl);
};

const isEmailVerified = (params) => {
  const saveUrl = 'email-campaigns/attributes/isverified';
  return instance.post(saveUrl, params);
};

export { sendEmailVerification, sendAttributes, getAttributes, isEmailVerified };
