import Spinner from '../atoms/Spinner';

const RelativeLoading = ({ isLoading, className = '' }) => {
  return (
    <>
      {isLoading && (
        <div
          className={`rounded-lg absolute inset-0 z-50 flex justify-center items-center bg-white bg-opacity-80 ${className}`}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default RelativeLoading;
