const PasswordEyeOpenIcon = ({ fill = '#d7d7d7', ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.12533 9.99967C8.12533 8.96414 8.96479 8.12467 10.0003 8.12467C11.0359 8.12467 11.8753 8.96414 11.8753 9.99967C11.8753 11.0352 11.0359 11.8747 10.0003 11.8747C8.96479 11.8747 8.12533 11.0352 8.12533 9.99967Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66699 9.99967C1.66699 11.3658 2.02113 11.8259 2.7294 12.7461C4.14363 14.5834 6.51542 16.6663 10.0003 16.6663C13.4852 16.6663 15.857 14.5834 17.2712 12.7461C17.9795 11.8259 18.3337 11.3658 18.3337 9.99967C18.3337 8.63353 17.9795 8.17345 17.2712 7.25328C15.857 5.41597 13.4852 3.33301 10.0003 3.33301C6.51542 3.33301 4.14363 5.41597 2.7294 7.25328C2.02113 8.17345 1.66699 8.63353 1.66699 9.99967ZM10.0003 6.87467C8.27444 6.87467 6.87533 8.27378 6.87533 9.99967C6.87533 11.7256 8.27444 13.1247 10.0003 13.1247C11.7262 13.1247 13.1253 11.7256 13.1253 9.99967C13.1253 8.27378 11.7262 6.87467 10.0003 6.87467Z"
      fill={fill}
    />
  </svg>
);

export default PasswordEyeOpenIcon;
