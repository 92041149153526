import { SelectHTMLAttributes } from 'react';
import { useFormikContext } from 'formik';

import { IInitialValues } from 'modules/formBuilder/types';

import { SelectFull } from 'modules/common/components';
import { useStatesAndCountries } from 'modules/common/hooks/';
import { BILLING_ADDRESS_STATE } from 'modules/formBuilder/constants';

interface FormikStatesFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  $hasError?: boolean;
}

const FormikStatesField = (props: FormikStatesFieldProps) => {
  const { states, isStatesLoading } = useStatesAndCountries();
  const { values, setFieldValue } = useFormikContext<IInitialValues>();

  const selectedCountry = values?.billing_address?.country;
  const selectedStateList = states[selectedCountry];
  const parsedStateList = selectedStateList?.map((state) => ({
    label: state.title,
    value: state.title,
  }));

  const handleChange = (value: string) => {
    setFieldValue(BILLING_ADDRESS_STATE, value);
  };

  return (
    <SelectFull
      loading={isStatesLoading}
      {...props}
      onChange={handleChange}
      options={parsedStateList}
    />
  );
};

export default FormikStatesField;
