//
// TODO: We should not have any references to segments
// to make this component to be reusable
import { Select } from 'antd';
import { map } from 'lodash-es';

const SelectMultiField = ({
  options = [],
  field,
  form,
  placeholder = '',
  onChange,
  selectedSegment,
  maxCount = 1,
  disabled,
}) => {
  const getOnChangeHandle = (option) => {
    if (onChange) {
      onChange(option);
    }

    if (form && field && field.name) {
      form.setFieldValue(field.name, option);
    }
  };

  return (
    <Select
      defaultValue={selectedSegment}
      mode="multiple"
      placeholder={placeholder}
      onChange={getOnChangeHandle}
      maxTagCount={maxCount}
      disabled={disabled}
      showArrow
    >
      {map(options, (option) => (
        <Select.Option key={option.id} value={option.id}>
          {option.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectMultiField;
