import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';
import { CheckCircleFilled } from '@ant-design/icons';

export const CheckIconSuccess = styled(CheckCircleFilled)`
  background-color: ${color.White};
  color: ${color.successGreen}!important;
  border-radius: 50%;
  font-size: 50px;
  border: 10px solid #dff1e7;
  padding: 0;
  margin-bottom: 20px;
`;
