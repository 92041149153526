import { spawn } from 'redux-saga/effects';

import { watchGetAddresses } from './getAddresses';
import { watchAddAddress } from './addAddress';
import { watchEditAddress } from './editAddress';
import { watchDeleteAddress } from './deleteAddress';

export default function* saga() {
  yield spawn(watchGetAddresses);
  yield spawn(watchAddAddress);
  yield spawn(watchEditAddress);
  yield spawn(watchDeleteAddress);
}
