import { Authenticated } from 'modules/router/types';

import GenerateWelcomeDraft from 'modules/v2/pages/WelcomeJourney/Components/EditBook/components/Generate';
import {
  ROUTE_DRAFT,
  ROUTE_DRAFT_VIEW,
  ROUTE_GENERATE_DRAFT,
  ROUTE_GENERATE_DRAFT_WELCOME_JOURNEY,
  ROUTE_DRAFT_CHECKOUT,
} from './constants';

import { Draft, Generate, View, Checkout } from '../pages';

export const routes = [
  {
    type: Authenticated,
    path: ROUTE_GENERATE_DRAFT,
    component: Generate,
  },
  {
    type: Authenticated,
    path: ROUTE_GENERATE_DRAFT_WELCOME_JOURNEY,
    component: GenerateWelcomeDraft,
  },
  {
    type: Authenticated,
    path: ROUTE_DRAFT,
    component: Draft,

    children: [
      { path: ROUTE_DRAFT_CHECKOUT, component: Checkout, exact: true },
      { path: ROUTE_DRAFT_VIEW, component: View, exact: true },
    ],
  },
  { path: '/draft', redirect: '/', exact: true },
];
