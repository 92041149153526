import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';

interface OrderedDateTimeProps {
  value: Date;
  prefix?: string;
}

const OrderedDateTime: React.FC<OrderedDateTimeProps> = ({ value, prefix = 'Ordered on' }) => {
  const date = new Date(value);

  const formattedDate = date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <>
      <AiOutlineClockCircle className="text-red-400" size="24" />
      <span className="mr-4">
        <span className="font-semibold">{prefix}</span> {formattedDate}
      </span>
    </>
  );
};

export default OrderedDateTime;
