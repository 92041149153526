import { compose } from 'redux';
import { useMutation } from 'react-query';
import { withPassword, withProfile } from 'modules/dashboard/containers';
import { withAuth } from 'modules/auth/containers';
import { Col } from 'antd';
import { notification } from 'modules/common/utils/notify';
import { Formik, Form } from 'formik';
import { EyeOutlined } from '@ant-design/icons';
import { PrimaryButton, FormLabel, Loader, Modal } from 'modules/common/components';
import { useEffect, useState } from 'react';
import { ErrorWarning } from 'modules/common/theme/color';
import { AuthorifyIcon } from 'modules/common/components/SvgIcon';
import { getContactByemail, getError, updateAfyPassword } from 'modules/api';
import { DashboardTemplate } from 'modules/dashboard/templates';
import { AdminSideNav } from 'modules/dashboard/components';
import validationSchema from './validationSchema';
import S from './styles';

const ChangeAfyPasswordHubspot = () => {
  const [errorrs, setError] = useState({});
  const [email, setEmail] = useState('');
  const [contactEmail, setcontactEmail] = useState(null);
  const [contactCurrentPassword, setcontactCurrentPassword] = useState(null);
  const [contactName, setcontactName] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [uppercase, setuppercase] = useState(false);
  const [lowercase, setlowercase] = useState(false);
  const [hasNumber, sethasNumber] = useState(false);
  const [passwordIsvalid, setpasswordIsvalid] = useState(false);
  const [specialchar, setspecialchar] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);
  const initialValues = {
    email: contactEmail,
    firstname: '',
    password: '',
    passwordConfirmation: '',
  };
  const { mutateAsync: updateAfyPsswd, isLoading: isSaveLoading } = useMutation(updateAfyPassword);
  const [formvalues, setformvalues] = useState({});
  const resetForm = () => {
    setcontactEmail(null);
    setcontactCurrentPassword(null);
    setcontactName(null);
    setuppercase(false);
    setlowercase(false);
    sethasNumber(false);
    setpasswordIsvalid(false);
    setspecialchar(false);
  };

  const onChange = (event) => {
    event.persist();
    setEmail(event.target.value);
    resetForm();
  };

  const validate = (value) => {
    const error = {};
    const txtEmail = document.querySelector('#email');
    if (!value) {
      error.email = 'Enter email address';
      txtEmail.style.border = `2px solid ${ErrorWarning}`;
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      error.email = 'Email address is invalid';
      txtEmail.style.border = `2px solid ${ErrorWarning}`;
    }
    return error;
  };

  const checkEmail = (emailId) => {
    const re = new RegExp(/^.+@.+\..+$/);
    return re.test(emailId);
  };

  const GetContacts = async () => {
    try {
      if (checkEmail(email)) {
        setisLoading(true);
        const emailId = email;
        const results = await getContactByemail(emailId);
        setcontactEmail(results.properties.email.value);
        setisLoading(false);
        setcontactCurrentPassword(results.properties?.afy_password?.value);
        setcontactName(results.properties.firstname.value);
      } else {
        resetForm();
      }
    } catch (error) {
      setisLoading(false);
      resetForm();
      setIsOpen(true);
    }
  };

  const onSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(email));
    if (Object.keys(errorrs).length === 0) {
      GetContacts();
    }
  };

  const handleSave = async (formData, { setSubmitting, setErrors, setStatus }) => {
    try {
      const filtered = ['email', 'password', 'passwordConfirmation'].reduce(
        (obj, key) => ({ ...obj, [key]: formData[key] }),
        {},
      );
      updateAfyPsswd(filtered)
        .then(async () => {
          notification.success({
            title: 'Changes saved',
            description: 'New member password changed successfully',
          });
          setStatus({ success: true });
          resetForm();
        })
        .catch(() => {
          notification.error({
            title: 'Saving failed',
            description: 'Changes could not be saved',
          });
          resetForm();
        });
      return false;
    } catch (error) {
      const response = getError(error);
      notification.error({
        title: 'Saving failed',
        description: 'Changes could not be saved',
      });
      setStatus({ success: false });
      setSubmitting(false);
      resetForm();
      setErrors({ submit: error.message });
      return response;
    }
  };

  const PasswordPolicy = (
    <S.List>
      <S.AvailableAnymore>Password Requirements:</S.AvailableAnymore>
      <S.ListItem>
        <S.ListText>
          {passwordIsvalid ? <S.CheckOutlinedIcon /> : <S.CloseOutlinedIcon />} Minimum of 8
          characters
        </S.ListText>
      </S.ListItem>
      <S.ListItem>
        <S.ListText>
          {uppercase ? <S.CheckOutlinedIcon /> : <S.CloseOutlinedIcon />} At least 1 uppercase: A -
          Z
        </S.ListText>
      </S.ListItem>
      <S.ListItem>
        <S.ListText>
          {lowercase ? <S.CheckOutlinedIcon /> : <S.CloseOutlinedIcon />} At least 1 lowercase: a -
          z
        </S.ListText>
      </S.ListItem>
      <S.ListItem>
        <S.ListText>
          {hasNumber ? <S.CheckOutlinedIcon /> : <S.CloseOutlinedIcon />} At least 1 number: 0 - 9
        </S.ListText>
      </S.ListItem>
      <S.ListItem>
        <S.ListText>
          {specialchar ? <S.CheckOutlinedIcon /> : <S.CloseOutlinedIcon />} At least 1 special
          character: !@#$%^
        </S.ListText>
      </S.ListItem>
    </S.List>
  );

  function isOkPass(p) {
    const anUpperCase = /[A-Z]/;
    const aLowerCase = /[a-z]/;
    const aNumber = /[0-9]/;
    const aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

    if (p.length < 8) {
      setpasswordIsvalid(false);
    } else {
      setpasswordIsvalid(true);
    }
    for (let i = 0; i < p.length; i++) {
      if (anUpperCase.test(p[i])) setuppercase(true);
      else if (aLowerCase.test(p[i])) setlowercase(true);
      else if (aNumber.test(p[i])) sethasNumber(true);
      else if (aSpecial.test(p[i])) setspecialchar(true);
    }
  }

  useEffect(() => {
    if (
      formvalues &&
      formvalues.password !== '' &&
      contactCurrentPassword != null &&
      !passwordIsvalid
    ) {
      isOkPass(formvalues.password);
    }
  }, [formvalues.password, contactCurrentPassword, formvalues, passwordIsvalid]);

  if (isSaveLoading) {
    return (
      <Loader
        height="100%"
        title="Saving New Password"
        hasIcon
        description="Please wait while we save this new password."
        icon={<AuthorifyIcon />}
      />
    );
  }

  return (
    <DashboardTemplate
      sidebar={<AdminSideNav />}
      hasSidebar
      isLoading={isLoading}
      loadingTitle="Fetching current password."
      loaderDescription="Please wait while we fetch the current password."
    >
      <Modal
        isOpen={isOpen}
        width="600px"
        title="Member Account Does Not Exist"
        onConfirm={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        okText="Ok"
        footer={[
          <S.ButtonWrapper>
            <PrimaryButton key="submit" type="primary" onClick={() => setIsOpen(false)}>
              Ok
            </PrimaryButton>
          </S.ButtonWrapper>,
        ]}
      >
        <S.Content>
          Sorry, it seems that this member does not exist on our system, please make sure you enter
          the email correctly and try again.
        </S.Content>
      </Modal>
      <S.MainContain>
        <Col>
          <S.Title>Member Password Change</S.Title>
          <S.FieldGroup>
            <S.FieldControl>
              <FormLabel title="Member Email Address" htmlFor="email">
                <S.Input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Enter email address here"
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                />
                {errorrs.email && <S.Error>{errorrs.email}</S.Error>}
              </FormLabel>
            </S.FieldControl>
            <S.FieldControlLeft>
              <FormLabel title="Member Details">
                <p className="pdt12">
                  <S.Label>Name: </S.Label>
                  <S.Label2>{contactName} </S.Label2>
                </p>
              </FormLabel>
            </S.FieldControlLeft>
            <S.FieldControlLeft>
              <S.Subheading className="pdt12">
                <S.Label>Password: </S.Label>
                <S.Label2>{contactCurrentPassword} </S.Label2>
              </S.Subheading>
            </S.FieldControlLeft>
          </S.FieldGroup>
          <S.FieldGroup>
            <PrimaryButton
              type="submit"
              onClick={onSubmit}
              disabled={contactCurrentPassword !== null}
              style={{ margin: '10px 0' }}
            >
              Retrieve Current Password
            </PrimaryButton>
          </S.FieldGroup>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            innerRef={(formikActions) =>
              formikActions ? setformvalues(formikActions.values) : setformvalues({})
            }
          >
            {({ values, errors, handleSubmit, handleChange, isValid, dirty, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <S.FieldGroup className="pdt10">
                    <S.FieldControl>
                      <FormLabel title="Enter New Password" htmlFor="password">
                        <S.Input
                          id="password"
                          name="password"
                          type={ShowPassword ? 'text' : 'password'}
                          autoComplete="none"
                          placeholder="Enter new password here"
                          value={values.password}
                          disabled={contactEmail === null}
                          onChange={handleChange}
                        />
                        <S.EyeIcon>
                          <EyeOutlined onClick={() => setShowPassword(!ShowPassword)} />
                        </S.EyeIcon>
                        {errors.password && <S.Error>{errors.password}</S.Error>}
                      </FormLabel>
                    </S.FieldControl>
                    <S.FieldControlLeft>
                      <FormLabel title="Confirm New Password" htmlFor="passwordConfirmation">
                        <S.Input
                          autoComplete="none"
                          id="passwordConfirmation"
                          name="passwordConfirmation"
                          type="password"
                          placeholder="Re-enter new password here"
                          value={values.passwordConfirmation}
                          onChange={handleChange}
                          disabled={contactEmail === null}
                        />
                        {errors.passwordConfirmation && (
                          <S.Error>{errors.passwordConfirmation}</S.Error>
                        )}
                      </FormLabel>
                    </S.FieldControlLeft>
                  </S.FieldGroup>
                  <S.FieldGroup>{PasswordPolicy}</S.FieldGroup>
                  <S.FieldGroupRight>
                    <PrimaryButton
                      type="submit"
                      loading={isLoading}
                      onClick={handleSubmit}
                      disabled={dirty ? !isValid || isSubmitting : false || contactEmail === null}
                    >
                      Save New Password
                    </PrimaryButton>
                  </S.FieldGroupRight>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </S.MainContain>
    </DashboardTemplate>
  );
};

const Composed = compose(withPassword, withProfile, withAuth)(ChangeAfyPasswordHubspot);

export default Composed;
