import { getInstance } from './instance';

const instance = getInstance();

export const getTimeSlots = ({ queryKey }) => {
  const [, slots] = queryKey;
  return instance
    .get(`webinars/${slots}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const scheduleTraining = ({ payload, queryKey }) => {
  const { offerCode, sessionId } = queryKey;
  return instance
    .post(`onboard/webinar?offerCode=${offerCode}&sessionId=${sessionId}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
