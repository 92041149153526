import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Field } from 'formik';
import { Card } from 'antd';
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  background: ${color.White};
  padding: 2rem;
`;

const SubBtn = styled(Card)`
  width: 480px;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 25px;
  color: ${color.Black};
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    height: 40px !important;
    border-radius: 3px !important;
    font-size: 16px;
    padding: 4px 11px;
  }
`;

const SubHead = styled.span``;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  font-size: 18px;
  line-height: 22px;
  color: ${color.UserMenuText};
`;

const Heading = styled.span`
  font-weight: bold;
  color: ${color.Black};
`;
const Para = styled.p`
  font-size: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: ${color.Black};
`;
const Space = styled.span`
  margin: 0px 10px;
`;
const FileIcon = styled(FileTextOutlined)`
  color: ${color.LightGray};
  width: 24px;
  height: 25px;
  opacity: 1;
`;
const DeleteIcon = styled(DeleteOutlined)`
  color: ${color.SecondaryColor};
  width: 24px;
  height: 25px;
  opacity: 1;
`;
const CheckBox = styled.span`
  .ant-checkbox-wrapper {
    margin-top: 8px;
  }
  .ant-checkbox + span {
    font-size: 16px;
    color: ${color.Black};
  }
`;
const ButtonText = styled.a`
  color: ${color.White};
  color: ${color.SkyBlue};
  font-size: 1rem;
`;
export default {
  Wrapper,
  Label,
  FormikField,
  Heading,
  Para,
  SubBtn,
  SubHead,
  Space,
  FileIcon,
  DeleteIcon,
  CheckBox,
  ButtonText,
};
