import { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useFormikContext } from 'formik';
import { FilePicker, ErrorMessage } from 'modules/common/components';
import { useImageUpload } from 'modules/common/hooks';
import { useParams } from 'react-router';

import { useGetAttributes } from './hooks';
import { buildCustomerTemplate } from './utils';
import S from './styles';

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'link',
      'insertTable',
      '|',
      'imageUpload',
      '|',
      'undo',
      'redo',
    ],
  },
  image: {
    toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
  language: 'en',
};

const CustomerTemplatesSideBar = () => {
  const { setFieldValue, values, errors, resetForm } = useFormikContext();
  const [controls, setControls] = useState(values.controls || {});
  const [formValues, setFormValues] = useState(values);
  const { templateBaseId: isEdit } = useParams();

  const { uploadImage, isLoading } = useImageUpload({
    path: 'customer-templates-header',
    onSuccess: (imageUrl) => {
      const content = buildCustomerTemplate({ formValues: { ...formValues, imageUrl }, controls });
      setFieldValue('imageUrl', imageUrl);
      setFieldValue('content', content);
      setFormValues({ ...formValues, content, imageUrl });
    },
  });

  useEffect(() => {
    const content = buildCustomerTemplate({ formValues, controls });
    resetForm({ values: { ...values, content, controls } });

    // eslint-disable-next-line
  }, [controls]);

  const handleOnSuccessGettingAttributes = ({ data }) => {
    const {
      memberEmail,
      memberFirstName,
      memberLastName,
      memberPhone,
      imageUrl,
      memberBookUrl,
      memberBrokerName,
      memberTitle,
      memberAddress,
    } = data;

    const user = {
      avatar: imageUrl,
      firstName: memberFirstName,
      lastName: memberLastName,
      phone: memberPhone,
      memberBookUrl,
      memberBrokerName,
      memberAddress,
      memberTitle,
      email: memberEmail,
    };

    setFormValues({
      ...values,
      content: buildCustomerTemplate({ formValues, controls }),
      user,
    });

    const content = buildCustomerTemplate({ formValues, imageUrl, controls });
    resetForm({ values: { ...values, content, user } });
  };

  useGetAttributes(handleOnSuccessGettingAttributes);

  const handleChangeControls = (field) => (value) => {
    setControls({ ...controls, [field]: value });
  };

  const handleChange = (field) => (value) => {
    setFieldValue(field, value.target.value);
    setFormValues({ ...formValues, [field]: value.target.value });

    if (field === 'templateTitle') {
      const content = buildCustomerTemplate({
        formValues,
        controls,
      });

      setFieldValue('content', content);
    }
  };

  const handleTemplateContentChange = (event, editor) => {
    const editorData = editor.getData();

    const content = buildCustomerTemplate({
      formValues,
      controls,
    });
    setFieldValue('content', content);
    setFieldValue('bodyContent', editorData);
  };

  return (
    <>
      <S.Sidebar theme="light">
        <S.Wrapper>
          <form>
            {isEdit ? (
              <S.Title>{formValues.name}</S.Title>
            ) : (
              <>
                <S.Label>Template Name</S.Label>
                <S.Field
                  name="name"
                  value={formValues.name}
                  $hasErrors={errors.name}
                  placeholder="Untitled Email Title"
                  onChange={handleChange('name')}
                />
                {errors.name ? <ErrorMessage>{errors.name}</ErrorMessage> : ''}
              </>
            )}

            <S.Label>Email Subject</S.Label>
            <S.Field
              name="subject"
              value={formValues.subject}
              $hasErrors={errors.subject}
              placeholder="Untitled Email Subject"
              onChange={handleChange('subject')}
            />
            {errors.subject ? <ErrorMessage>{errors.subject}</ErrorMessage> : ''}

            <S.ControlList>
              <S.ControlItem>
                <S.ControlLabel>Add Header Image</S.ControlLabel>
                <S.ControlInput
                  name="hasHeader"
                  checked={controls.hasHeader}
                  onChange={handleChangeControls('hasHeader')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Add Header Title</S.ControlLabel>
                <S.ControlInput
                  name="hasHeaderTitle"
                  checked={controls.hasHeaderTitle}
                  onChange={handleChangeControls('hasHeaderTitle')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Add Email Signature</S.ControlLabel>
                <S.ControlInput
                  name="hasSignature"
                  checked={controls.hasSignature}
                  onChange={handleChangeControls('hasSignature')}
                />
              </S.ControlItem>
            </S.ControlList>
            {controls.hasSignature ? (
              <S.SubControlList data-testid="signature-container">
                <S.ControlItem>
                  <S.ControlLabel>First Name</S.ControlLabel>
                  <S.ControlInput
                    name="firstName"
                    checked={controls.hasFirstName}
                    size="small"
                    onChange={handleChangeControls('hasFirstName')}
                  />
                </S.ControlItem>

                <S.ControlItem>
                  <S.ControlLabel>Last Name</S.ControlLabel>
                  <S.ControlInput
                    name="lastName"
                    checked={controls.hasLastName}
                    size="small"
                    onChange={handleChangeControls('hasLastName')}
                  />
                </S.ControlItem>

                <S.ControlItem>
                  <S.ControlLabel>Member Title</S.ControlLabel>
                  <S.ControlInput
                    name="lastName"
                    checked={controls.hasMemberTitle}
                    size="small"
                    onChange={handleChangeControls('hasMemberTitle')}
                  />
                </S.ControlItem>

                <S.ControlItem>
                  <S.ControlLabel>Broker Name</S.ControlLabel>
                  <S.ControlInput
                    name="lastName"
                    checked={controls.hasMemberBrokerName}
                    size="small"
                    onChange={handleChangeControls('hasMemberBrokerName')}
                  />
                </S.ControlItem>

                <S.ControlItem>
                  <S.ControlLabel>Address</S.ControlLabel>
                  <S.ControlInput
                    name="address"
                    checked={controls.hasMemberAddress}
                    size="small"
                    onChange={handleChangeControls('hasMemberAddress')}
                  />
                </S.ControlItem>

                <S.ControlItem>
                  <S.ControlLabel>Book Url</S.ControlLabel>
                  <S.ControlInput
                    name="address"
                    checked={controls.hasMemberBookUrl}
                    size="small"
                    onChange={handleChangeControls('hasMemberBookUrl')}
                  />
                </S.ControlItem>

                <S.ControlItem>
                  <S.ControlLabel>Phone Number</S.ControlLabel>
                  <S.ControlInput
                    name="phone"
                    checked={controls.hasPhoneNumber}
                    size="small"
                    onChange={handleChangeControls('hasPhoneNumber')}
                  />
                </S.ControlItem>

                <S.ControlItem>
                  <S.ControlLabel>Email Address</S.ControlLabel>
                  <S.ControlInput
                    name="email"
                    checked={controls.hasEmailAddress}
                    size="small"
                    onChange={handleChangeControls('hasEmailAddress')}
                  />
                </S.ControlItem>

                <S.ControlItem>
                  <S.ControlLabel>Profile Image</S.ControlLabel>
                  <S.ControlInput
                    name="hasProfileImage"
                    checked={controls.hasProfileImage}
                    size="small"
                    onChange={handleChangeControls('hasProfileImage')}
                  />
                </S.ControlItem>
              </S.SubControlList>
            ) : null}
          </form>
        </S.Wrapper>
      </S.Sidebar>

      <S.WrapperContent>
        <S.TemplateWrapper>
          <S.TemplateContent>
            {controls.hasHeader ? (
              <>
                <S.TemplateImage data-testid="header-template-image">
                  {isLoading ? (
                    <S.LoadingWrapper>
                      <LoadingOutlined />
                    </S.LoadingWrapper>
                  ) : (
                    <S.FilePickerContainer>
                      <FilePicker
                        noMargin
                        icon=""
                        type="email-template-header"
                        uploadText={
                          formValues.imageUrl ? (
                            <img
                              alt="header-url"
                              style={{ width: '600px', height: '200px' }}
                              src={formValues.imageUrl}
                            />
                          ) : (
                            <S.TemplateImageContent>
                              <S.TemplateImageFile>
                                <img src="https://via.placeholder.com/80" alt="" />
                              </S.TemplateImageFile>

                              <S.TemplateImageDescription>
                                minimum width: 600px <br />
                                minimum height: 150px <br />
                                max file size: 5MB (MegaByte)
                              </S.TemplateImageDescription>
                            </S.TemplateImageContent>
                          )
                        }
                        handleImageChange={uploadImage}
                        isEditImage={false}
                        toggleCancelEdit={null}
                        imageUrlForEdit={null}
                        onSuccess={null}
                      />
                    </S.FilePickerContainer>
                  )}
                </S.TemplateImage>
              </>
            ) : null}

            {controls.hasHeaderTitle ? (
              <S.TemplateTitle
                type="text"
                data-testid="header-title"
                value={formValues.templateTitle}
                onChange={handleChange('templateTitle')}
                placeholder="[[Add Header Title]]"
              />
            ) : null}

            <S.TemplateBody>
              <S.CKEditor
                editor={ClassicEditor}
                data={formValues.bodyContent}
                onReady={(editor) => {
                  // eslint-disable-next-line no-param-reassign
                  editor.ui.view.editable.element.style.height = '99px';
                }}
                onChange={handleTemplateContentChange}
              />
            </S.TemplateBody>

            {controls.hasSignature ? (
              <S.TemplateSignature data-testid="template-signature">
                <S.TemplateSignatureItems>
                  <S.TemplateSignatureItem>
                    <strong>
                      {controls.hasFirstName && formValues.user.firstName}{' '}
                      {controls.hasLastName && formValues.user.lastName}
                    </strong>
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    <strong>{controls.hasMemberTitle && formValues.user.memberTitle}</strong>
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    <strong>
                      {controls.hasMemberBrokerName && formValues.user.memberBrokerName}
                    </strong>
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    {controls.hasEmailAddress && formValues.user.email}
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    {controls.hasPhoneNumber && formValues.user.phone}
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    {controls.hasMemberAddress && formValues.user.memberAddress}
                  </S.TemplateSignatureItem>
                  <S.TemplateSignatureItem>
                    {controls.hasMemberBookUrl && formValues.user.memberBookUrl}
                  </S.TemplateSignatureItem>
                </S.TemplateSignatureItems>

                {controls.hasProfileImage && (
                  <S.TemplateSignatureImage
                    data-testid="profile-image"
                    alt="Profile Image"
                    src={formValues.user.avatar}
                  />
                )}
              </S.TemplateSignature>
            ) : null}
          </S.TemplateContent>
        </S.TemplateWrapper>
      </S.WrapperContent>
    </>
  );
};

export default CustomerTemplatesSideBar;
