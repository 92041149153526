import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  width: 100%;
  padding: 20px 10% 96px 9px;

  @media (max-width: 1100px) {
    padding: 20px 9px 40px 9px;
  }
`;

const Title = styled.h3`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 20px 0 10px 0 !important;
  height: 40px;
  display: flex;
  align-items: center;
`;

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;

const SortBtn = styled.div`
  width: 165px;
  height: 40px;
  margin-right: 16px;

  .ant-select-selection--single {
    width: 165px;
    height: 40px !important;
    border: 1px solid ${color.GrayBorder};
    border-radius: 2px !important;
    background-color: ${color.White};
    color: ${color.DefaultBlue};
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ant-select-arrow {
    position: absolute;
    top: 14%;
    right: 11%;
    margin-top: 0px;
  }

  .ant-select-selection__rendered {
    margin-left: 18px;
  }

  .anticon svg {
    font-size: 30px;
    color: ${color.GreyDropDownArrow};
  }
`;

const CreateCoachBtn = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: fit-content;
  height: 40px !important;
  padding: 0 28px 0 20px;

  color: ${color.White};
  text-align: right;
  white-space: nowrap;
  font-weight: bold;
  letter-spacing: 0.6px;

  background-color: ${color.BlueButton};
  border: none;
  border-radius: 2px !important;
  opacity: 1;

  &:hover {
    color: ${color.White};
    background-color: ${color.BlueButtonHover};
    border-color: ${color.BlueButtonHover};
    cursor: pointer;
  }

  svg {
    font-size: 23px;
    margin-left: 6px;
  }
`;

const DeleteCoachBtn = styled.button`
  display: flex;
  align-items: center;

  height: 40px !important;
  padding: 0 20px;
  background-color: ${color.White};
  border: 1px solid ${color.PrimaryColor};
  border-radius: 2px !important;
  opacity: 1;

  color: ${color.PrimaryColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0px;

  transition: transform 0.5s ease;

  cursor: pointer;
`;

const NavLink = styled(Link)`
  cursor: pointer;
`;

const ImportBtn = styled.button`
  margin-top: 20px;
  width: 165px;
  height: 40px !important;
  background-color: ${color.White};
  color: ${color.SkyBlue};
  border-style: solid;
  border-color: ${color.SkyBlue};
  border-radius: 2px !important;
  margin-right: 16px;
  opacity: 1;
  font-weight: 700;
  text-align: left;
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  letter-spacing: 0.6px;

  &:hover {
    color: ${color.BlueButtonHover};
    background-color: ${color.White};
    border-color: ${color.BlueButtonHover};
  }

  .anticon svg {
    font-size: 23px;
    margin: 5px;
  }
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  .anticon svg {
    font-size: 23px !important;
    margin-left: 10px !important;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
`;

const SegmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
`;

const MeetingLink = styled.span`
  color: ${color.SkyBlue};
`;

export default {
  Container,
  Title,
  SortBtn,
  NavLink,
  CreateCoachBtn,
  DeleteCoachBtn,
  ImportBtn,
  ButtonText,
  MainWrapper,
  SegmentWrapper,
  CheckBox,
  MeetingLink,
};
