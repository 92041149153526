/**
 * Colors
 */

export const PrimaryColor = '#67CBFF';
export const PrimaryDisabledColor = '#67CBFF';
export const SecondaryColor = '#1B2F73';
export const PrimaryColorLight = '#67CBFF';
export const PrimaryColorHover = '#1B2F73';
export const HeaderButton = '#67CBFF';
export const Blue = '#0077B6';

export const LightBlue = '#61cdf1';
export const DarkBlue = '#191970';
export const BlueBorder = '#0096C7';
export const DarkWhite = '#eeecec';
export const GrayBorderColor = '#E5E7EB';
export const LightGreen = '#D1FAE5';
export const Green = '#24B165';

/* ************************** */

export const White = '#ffffff';
export const WhiteHEX = '255, 255, 255';
export const Black = '#000000';
export const OnboardingPlaceholder = '#1a1a1a';
export const TextDefault = '#ieieie';
export const GreySubtitle = '#616161';

/* ************************** */

export const GreyHover = '#F0F0F0';
export const GreyBoxBackgorund = '#F0F0F0';
export const GreyDropDown = '#A7A7A7';
export const GreyDropDownArrow = '#888888';
// export const GreySelectDd = "#555555";
export const GreySelectDd = '#2E3F50';
export const GreyDivider = '#E6E6E6';
export const GreyBookBorder = '#E4E4E4';
export const GreyMenuItemHover = '#f6f6f6';
export const GreyFooter = '#e9f0fd';
export const GreyText = '#707070';
export const GreyLightText = '#e9e9e9';
export const gray100 = '#666666';
export const gray200 = '#B3B3B3';
export const gray300 = '#D1D5DB';
export const grayDescription = '#909090';
export const grayTable = '#E6E5E8';
export const grayCheckBox = '#C1C1C1';
export const grayBackButton = '#6B6B6B';
export const GrayDividingLine = '#dadada';

export const separateLine = '#EDECF0';
export const BoxBorder = '#EAEAEA';
export const OnboardLine = '#DCDCDC';
export const OnboardConditionsText = '#494949';
export const BoxDescription = '#69696A';
export const BoxTitle = '#292929';
export const ScheduleDescription = '#69696A';
export const BoxTopics = '#837F7F';

/* ************************** */

export const GreyFormCheckbox = '#999999';
export const GreyFormBorder = '#CCCCCC';
export const RedFormText = '#DF4545';
export const SeparatorBorder = '#E1E6F7';
export const CreditsText = '#707070';
export const PhoneLink = '#048FCD';
export const InputFocusBorder = '#40a9ff';
export const DisabledInput = '#f5f5f5';

/* ************************** */

export const Body = '#2E3F50';
export const BodyMain = '#f7f8fc';
export const BrandColor = '#000000';

/* ************************** */

// export const ErrorWarning = "#FF0606";
export const ErrorWarningBorder = '#D40000';
export const ErrorWarning = '#F27575';

/* ************************** */

export const SuccessWarning = '#67cbff38';
export const SuccessWarningBorder = '#1B2F73';
export const SuccessSaving = '#5CA340';

/* ************************** */

export const StepOne = '#FFAB40';
export const StepTwo = '#80D8FF';
export const StepThree = '#69F0AE';
export const StepFour = '#B2DFDB';

/* ************************** */

// export const WelcomeTitle = "#515E63";
export const WelcomeTitle = '#2E3F50';
/* ************************** */

export const Loadergrey = '#d8d8d8';
export const LoaderLabel = '#999999';
export const FilterLabel = '#1e1e1e';

/* ************************** */

export const TitleBg = '#E6EFF7';
export const TitleBorder = '#CED4F1';
export const ImgBorder = '#6FADEB';
export const HighlightBg = '#e8f2f7';
export const IconBg = '#e7e7e7';

/* ************************** */

export const ActiveBookSectionsBg = '#f5f9fd';
export const DisabledBookSectionsBg = '#e6e8f1';
export const MenuDotsColor = '#9f9ab3';
export const MenuDotsColorBg = '#d8dcf3';
export const MenuListBg = '#ecefff';

/* ************************** */

/* *********** NEW COLORS *************** */

export const AuthLink = '#0090AF';
export const InputBorder = '#DBDBDB';

export const ErrorBg = '#fdedee';
export const ErrorBdr = '#f8a9ad';
export const FormMessageColor = '#3a3a3a';
export const BoxToolbar = '#29969f';
export const InputBrownBorder = '#d9d7d8';

export const flipbookBackground = '#cecece';

/* *********** NavBar COLORS ************* */

export const ActiveMenuItemColor = '#67CBFF';
export const NavBarSeparatorColor = 'rgba(255, 255, 255, 0.3)';
export const UserMenuText = '#2e3f50';
export const UserMenuIcon = '#CCCCCC';
export const UserMenuEmailText = '#999999';
export const GreyIcon = '#707070';

/* *********** BookCatalog COLORS ********** */
export const BookCatalogHeaderBg = '#569be6';
export const Grey3 = '#cecdcd';

/* *********** ViewPage COLORS ********** */
export const ViewBlueActive = '#0077ac';
export const ViewBlueButton = '#098ecd';
export const ViewBlueDisabledButton = '#9FC6DB';
export const ViewBorderColor = '#c7c7c7';
export const ViewFooterBackground = '#e6eaf0';
export const ViewFooterTitle = '#72d4ff';
export const TooltipTextColor = '#4D4D4D';
export const ViewFooterTitleBG = '#1c1c1c';
export const WarnMessageBackground = '#edfafc';
export const WarnMessageText = '#3997a9';

/* *********** New Palete ************* */
export const grey100 = '#d1d1d1';
export const successGreen = '#24b165';

/* ************* Help Page **************** */
export const InputText = '#727272';
export const DefaultBlue = '#048fcd';
export const ActiveBlue = '#0077AC';
export const DisabledButton = '#9FC6DB';
export const Placeholder = '#c9c9c9';
export const GrayBorder = '#ececec';

/*  ************ Notification *************** */
export const NotificationSuccessBG = '#cfffce';
export const NotificationSuccessBorder = '#059100';
export const NotificationTextColor = '#707070';
export const NotificationErrorBG = '#FFE5E8';
export const NotificationErrorBorder = '#980F20';
export const NotificationWarningBG = '#FFFFCE';
export const NotificationWarningBorder = '#D9D900';

/*  ************ Editor Footer *************** */
export const ResetButtonHoverBg = '#e8e8e8';

/*  ************ Onboard Forms *************** */
export const FormTitleBg = '#F9F9F9';

/* ****************Schedule Coaching*************** */
export const LightGray = '#D9D9D9';
export const LightHeadGray = '#8C8C8C';
export const LightDescriptionGray = '#6A6A6A';
export const LightDullGray = '#DBDBDB';
export const TimezoneGray = '#535353';
export const SkyBlue = '#1890FF';
export const grayBox = '#333333';
export const LilyWhite = '#e6f7ff';
export const OfferBackground = '#f0eff5';

export const GreyBorder = '#dbdbdb';
export const DangerRed = '#FF0020';

export const SideBarBackground = '#FBFBFB';
export const SideBarText = '#1E1E1E';
export const SideBarActiveText = '#FAFAFA';
export const SideBarActiveMenuText = '#0077B6';
export const SideBarSelectedBackground = '#0077B6';
export const SideBarSelectedMenuBackground = '#F4FBFF';
export const CharlestonGreen = '#2b2b2b';
export const lightBackground = '#fafafa';

/* ************************ Leads CRUD ***************************** */
export const DeleteLeadModalText = '#8c8c8c';
export const FormBoxShadowColor = 'rgba(24, 144, 255, 0.2)';
export const InputMaskDisabled = 'rgba(0, 0, 0, 0.25)';
export const InputMaskBackGroundDisabled = '#f5f5f5';

export const grayDot = '#A8A8A8';
export const defaultLightGray = '#EFEFEF';

export const transparent = 'transparent';
export const LeadsButtonHover = '#003a8c';

export const ProfileModalHeader = '#a21021';
export const reviewState = '#FAAD14';
export const approvedState = '#52C41A';

export const sidebarBoxShadow = '#00000040';
export const scrollbarThumb = '#bfbfbf';

/* ****************ANTD Table*************** */
export const TableHeader = '#F0F0F0';
export const TableFooter = '#F0F0F0';
export const TableBorder = '#E8E8E8';
export const TableRowSelected = '#F2FBFF';
export const ActivePage = '#048FCD';
export const ActivePageHover = '#0476A9';

/* *********************** Manage Member Password  ***************************** */
export const ViewBlueDisabledButtonFocus = 'rgba(0, 0, 0, 0.5)';
export const listTextGreyed = '#c2b6b6';

/* ****************USUAL CRUD COLORS*************** */
export const BlueButton = '#1890FF';
export const BlueButtonHover = '#003A8C';
export const GreyBackground = '#CCCCCC';
export const OnboardBlueButton = '#0096C7';

/* ****************Brokerage Modal*************** */
export const GreyPlatinum = '#e8e5e5';
// export const PlaceholderColor = '#757575';

// **************  STAUS COLORS  ***************
export const Success = '#52c41a';
export const Failure = '#f5222d';
export const EmptyAtribute = '#a6a6a6';

// **************  BUTTON COLORS  ***************
export const OffButtonBackground = '#eeeeee';
export const OffButtonText = '#cccccc';
export const PrimaryButton = '#048FCD';
export const PrimaryOnboardButton = '#0096C7';
export const PrimaryButtonHover = '#0476A9';
export const SecondaryButtonText = '#454545';
export const SecondaryButtonBorder = '#D1D5DB';
export const SecondaryButtonBorderHover = '#B5BFCD';
export const BlackButton = '#000000';
export const HoverBlackButton = '#333333';
export const GrayDisabled = '#8B8B8B';
export const DeleteButtonBackground = '#e4483d';

// **************  Doughnut COLORS  ***************
export const DoughnutDarkBlue = '#61CDF1';
export const DoughnutLightBlue = '#191970';
export const Doughnutscrollbartrack = '#f1f1f1';
export const Doughnutscrollbarthumb = '#555555';

// **************  METRICS COLORS  ***************
export const MetricDarkBlue = '#191970';
export const MetricLightBlue = '#87E3C3';
export const MetricLightMustard = '#EDC44A';
export const SentEmailsMetric = '#4cc9e1';
export const OpenRateMetric = '#38ab86';
export const ClickRateMetric = '#ff8a33';
export const ComplaintRateMetric = '#E4483D';
export const BounceRateMetric = '#ffc229';
export const DeliveryRateMetric = '#674CE1';
export const UnsubscribedMetric = '#e75a50';

// **************  STATUS CARD BADGE COLORS  ***************
export const StatusCardBadgeFGGreen = '#1CAF6B';
export const StatusCardBadgeBGGreen = '#D2FBDF';
export const StatusCardBadgeFGRed = '#E4483D';
export const StatusCardBadgeBGRed = '#FDE4E1';
export const StatusCardBadgeBGGrey = '#bfc5ca';
export const RadioButtonUncheckedColor = '#898989';
export const RadioButtonCheckedColor = '#0077B6';
export const UpgradePlanHeader = '#F3FBFF';

// **************  PLUS PLANS COLORS ***************
export const PlusPlanReviewAndPayBackground = '#f9f6f67a';
export const ActivePlusPlanSelection = '#048FCD';

// **************  BOX INFOMARTION ***************
export const BoxInformationBorder = '#B3B3B3';
export const BoxInformationBackground = '#FDFDFD';
export const BoxInformationTitle = '#1E1E1E';
export const BoxInformationDescription = '#666';

// ************** TAILWIND COLORS ***************
export const neutral50 = '#FAFAFA';
export const neutral100 = '#F5F5F5';
export const neutral200 = '#E9E9E9';
export const neutral300 = '#D7D7D7';
export const neutral500 = '#757575';
export const neutral600 = '#535353';
export const neutral700 = '#3C3C3C';
export const neutral800 = '#252525';

export const success50 = '#E6F5F0';
export const success400 = '#38AB86';
export const success500 = '#069668';

export const primary500 = '#4CC9E1';

export const yellow50 = '#FFF6DC';

export const yellow500 = '#FFB703';
export const yellow400 = '#FFC229';

export const blue400 = '#3FA1FF';

export const danger500 = '#E4483D';
export const danger400 = '#E75A50';

export const orange400 = '#FF8A33';
