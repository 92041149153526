import { Radio } from 'antd';
import S from './styles';

const CreditItem = ({ creditData }) => {
  const { id, credits, totalAmount, perAmount } = creditData;

  return (
    <S.Container>
      <Radio value={id}>
        <S.CreditWrapper>
          <S.Credit>{credits} Credits</S.Credit>
          <S.Description>${perAmount} each</S.Description>
        </S.CreditWrapper>
      </Radio>
      <S.Price>${totalAmount}</S.Price>
    </S.Container>
  );
};

export default CreditItem;
