import Yup from 'configuration/yup';
import validation from 'modules/v2/common/validationSchema';

const validationSchema = Yup.object().shape({
  name: validation.fullName,
  email: validation.email,
  phone: validation.phoneNumber,
});

export default validationSchema;
