import { STATUS_INIT } from 'store/status';

export const addresses = {
  data: [],
  status: STATUS_INIT,
};

export const countries = {
  data: [],
  status: STATUS_INIT,
};

export const credits = {
  data: 0,
  status: STATUS_INIT,
};

export const order = {
  data: null,
  status: STATUS_INIT,
};

export const preflight = {
  data: null,
  status: STATUS_INIT,
};

export const states = {
  data: null,
  status: STATUS_INIT,
};

export default {
  addresses,
  countries,
  credits,
  order,
  preflight,
  states,
};
