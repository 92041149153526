import classnames from 'classnames';
import { useRef } from 'react';
import { Scrollbars } from 'modules/common/components';
import { ScratchIcon } from 'modules/v2/common/components/SvgIcons';
import { EditIcon } from 'modules/common/components/SvgIcon';

type TemplateListProps = {
  templates: any[];
  selectedId: string;
  onSelect: (id: string) => void;
};

const TemplateListChapters = ({ templates, selectedId, onSelect }: TemplateListProps) => {
  const handleSelect = (id) => () => onSelect(id);
  const scrollbarRef = useRef();

  return (
    <Scrollbars ref={scrollbarRef} renderInLayers style={{ height: 'calc(100vh - 150px)' }}>
      {templates?.map(({ _id, name }, index) => {
        const selected = selectedId === _id;

        return (
          <div
            color="outlined"
            className={classnames(
              'group mx-4 flex justify-start block p-3.5 border mb-2 cursor-pointer rounded-lg border-neutral-200 flex justify-between',
              selected ? 'border-2 bg-primary-50 border-primary-400' : '',
            )}
            onClick={handleSelect(_id)}
            onKeyDown={handleSelect(_id)}
            key={_id}
          >
            <span className="flex items-center">
              {index === 0 ? <ScratchIcon className="mr-2" /> : null} {name}
            </span>{' '}
            {!selected && (
              <span className="group-hover:opacity-100 opacity-0 transition ease-in-out duration-200">
                <EditIcon />
              </span>
            )}
          </div>
        );
      })}
    </Scrollbars>
  );
};

export default TemplateListChapters;
