import React, { useState } from 'react';
import { compose } from 'redux';
import { get } from 'lodash-es';
import { Row, Col } from 'antd';

import { isPending } from 'store/status';

import { EditorTemplate } from 'modules/v2/editor/templates';
import {
  CoverImage,
  ContentPreview,
  SectionHeader,
  SectionSorting,
} from 'modules/v2/editor/components';
import {
  withBook,
  withDraft,
  withManageSections,
  withCoverPreview,
} from 'modules/v2/editor/containers';

import { Scrollbars } from 'modules/v2/common/components';

import { getElementsBySectionId } from 'modules/v2/editor/utils';

import S from './styles';

const ManageSections = ({
  layout,
  preview,
  sections,
  manuscript,
  content,
  draftId,
  goToEditDraftContent,
  activateSection,
  deactivateSection,
  reorderSection,
}) => {
  const [displayChapters, setDisplayChapters] = useState(false);

  const defaultSelectedSectionId = get(content, [0, '_id']);
  const [selectedSectionId, setSelectedSectionId] = useState(defaultSelectedSectionId);

  let currentSectionId = selectedSectionId;
  if (selectedSectionId === 'manuscript') {
    currentSectionId = get(manuscript, [0, '_id']);
  }

  // TODO: Move to selector
  const { elements } = getElementsBySectionId(sections, currentSectionId);

  // TODO: we are receiving the same URL to all options - frontCover, backCover and Thumbnail.
  // we need to discuss and decide about those name conventions and what do they mean.
  const coverPreview = get(preview.data, ['urls', 'front-cover']);

  // Responsible for discovering which action take based on the drag event
  const handleDragSection = (sectionId, source, destination) => {
    if (source === null || destination === null) {
      return;
    }

    setSelectedSectionId(sectionId);

    const { index: currentIndex, droppableId: current } = source;
    const { index: nextIndex, droppableId: next } = destination;

    const isSameBucket = next === current;
    const differentPosition = currentIndex !== nextIndex;
    const shouldActivate = next === 'active';

    // Reorder only the active sections
    if (isSameBucket && shouldActivate && differentPosition) {
      reorderSection(sectionId, nextIndex);
    }

    // Activate the section on the new position
    if (!isSameBucket && shouldActivate) {
      activateSection(sectionId, nextIndex);
    }

    // Deactivate the section on the new position
    if (!isSameBucket && !shouldActivate && sectionId !== 'manuscript') {
      deactivateSection(sectionId, nextIndex);
    }
  };

  const handleEditSection = (sectionId) => {
    if (sectionId === 'manuscript') {
      const selectedId = get(manuscript, [0, '_id']);
      setSelectedSectionId(selectedId);
      setDisplayChapters(true);
    } else {
      goToEditDraftContent(draftId, sectionId);
    }
  };

  const handleSelectSection = (sectionId) => {
    setSelectedSectionId(sectionId);
  };

  const handleNavigateBack = () => {
    const selectedId = get(content, [0, '_id']);
    setSelectedSectionId(selectedId);
    setDisplayChapters(false);
  };

  const renderCoverImage = <CoverImage url={coverPreview} loading={isPending(preview.status)} />;
  const renderContentPreview = <ContentPreview layout={layout} elements={elements} showGuides />;

  let renderSectionHeader = null;

  if (displayChapters) {
    renderSectionHeader = <SectionHeader onNavigateBack={handleNavigateBack} />;
  }

  const manageSections = displayChapters ? manuscript : content;
  const archivedItems = sections.filter(({ currentStatus }) => currentStatus === 'deprecated');
  const getScrollbarHeight = (header) => (header ? 'calc(100% - 42px)' : '100%');

  return (
    <EditorTemplate dropdownTitle="Manage Sections" className="border">
      <div className="w-full bg-neutral-50">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <S.Wrapper.Flex>
              <div className="w-full p-8">
                <S.Wrapper.Flex.Inner>
                  <S.Wrapper.CoverImageWrap>{renderCoverImage}</S.Wrapper.CoverImageWrap>
                  <S.Wrapper.SectionSortingWrap>
                    {renderSectionHeader}
                    <div className="w-full bg-neutral-50 h-full">
                      <Scrollbars style={{ height: getScrollbarHeight(renderSectionHeader) }}>
                        <SectionSorting
                          selectedId={selectedSectionId}
                          items={manageSections}
                          archivedItems={archivedItems}
                          onDrag={handleDragSection}
                          onEdit={handleEditSection}
                          onSelect={handleSelectSection}
                        />
                      </Scrollbars>
                    </div>
                  </S.Wrapper.SectionSortingWrap>
                  <S.Wrapper.ContentWrap>
                    <S.Wrapper.ContentWrap.Inner className="bg-neutral-100">
                      <Scrollbars style={{ height: '100%' }}>{renderContentPreview}</Scrollbars>
                    </S.Wrapper.ContentWrap.Inner>
                  </S.Wrapper.ContentWrap>
                </S.Wrapper.Flex.Inner>
              </div>
            </S.Wrapper.Flex>
          </Col>
        </Row>
      </div>
    </EditorTemplate>
  );
};

const MemoManageSections = React.memo(ManageSections);
const Composed = compose(
  withBook,
  withDraft,
  withManageSections,
  withCoverPreview,
)(MemoManageSections);

export default Composed;
