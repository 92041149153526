import { call, takeLatest } from 'redux-saga/effects';

import { fetchProfile, resource, getError } from 'modules/api';
import { GET_PROFILE } from 'modules/dashboard/store/constants';

import { getProfilePayload } from './utils';

export function* onGetProfile() {
  try {
    const request = resource(GET_PROFILE, fetchProfile, getProfilePayload);
    const payload = yield call(request);

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetProfile() {
  yield takeLatest(GET_PROFILE, onGetProfile);
}
