const BackArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_3375" data-name="Group 3375" transform="translate(-800 -262)">
      <rect
        id="Rectangle_1007"
        data-name="Rectangle 1007"
        width="24"
        height="24"
        transform="translate(800 262)"
        fill="none"
      />
      <path
        id="arrow-left"
        d="M163.756,164.641h-15.9l9.516-8.261a.216.216,0,0,0-.141-.38h-2.4a.436.436,0,0,0-.285.106l-10.269,8.91a.868.868,0,0,0,0,1.312l10.328,8.964a.214.214,0,0,0,.141.054h2.486a.216.216,0,0,0,.141-.38l-9.513-8.261h15.9a.218.218,0,0,0,.217-.217v-1.63A.218.218,0,0,0,163.756,164.641Z"
        transform="translate(658.026 108.5)"
        fill="#61cdf1"
      />
    </g>
  </svg>
);

export default BackArrowIcon;
