import styled from 'styled-components';

const Content = styled.div`
  padding: 30px;
  font-size: 18px;
  text-align: center;
`;

export default {
  Content,
};
