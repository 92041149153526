import { useState, useEffect } from 'react';
import { find, size } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import S from 'modules/publish/styles';

import { Loader, Scrollbars } from 'modules/v2/common/components';
import { MODAL_HEIGHT, views } from 'modules/publish/constants';
import { useStatesAndCountries } from 'modules/v2/common/hooks';
import { Drawer } from 'antd';
import { AddressFormView, AddressListView } from './views';

const AddressView = ({
  // Data
  activeView,
  addresses,
  countries,
  formAddressId,
  loading,
  selectedAddressId,
  states,
  isDentistProject = false,
  setAddresses = [],

  // Callbacks
  onCreate,
  onDelete,
  onEdit,
  onSelect,
  setActiveView,
  setFormAddressId,
}) => {
  const [open, setOpen] = useState(false);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return <Loader height="100%" title="Loading addresses" />;
  }

  // getting countries and states
  let newStates = states;
  let newCountires = countries;

  if (isDentistProject) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { states: statess, countries: countriess } = useStatesAndCountries();
    newCountires = countriess.map((items) => items.title);
    const updatedStates = {};
    for (const [country, items] of Object.entries(statess)) {
      updatedStates[country] = items.map((item) => item.title);
    }
    newStates = updatedStates;
  }

  // Setting initial state, view and id
  const setState = (view, id) => {
    setFormAddressId(id);
    setActiveView(view);
  };

  const handleCreate = () => {
    setState(views.ADDRESS_FORM, null);
    setOpen(!open);
  };

  const handleEdit = (id) => {
    setState(views.ADDRESS_FORM, id);
    setOpen(!open);
  };

  const handleDelete = (id) => {
    onDelete(id);
    if (isDentistProject) {
      const updatedAddresses = addresses.filter((item) => item._id !== id);
      setAddresses(updatedAddresses);
    }
    setState(views.ADDRESS_LIST, null);
  };

  const handleSubmit = (fields) => {
    const callback = !formAddressId ? onCreate : onEdit;
    const cbResult = callback(fields);

    if (isDentistProject) {
      const idName = uuidv4();
      let resultArray;
      if (formAddressId) {
        resultArray = addresses.filter((item) => item._id !== formAddressId);
      } else {
        resultArray = addresses;
      }
      cbResult
        .then((result) => {
          if (!formAddressId) {
            const response = result?.payload;
            response._id = idName;
            resultArray.push(response);
          } else {
            resultArray.push(result?.payload);
          }
          setAddresses(resultArray);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setState(views.ADDRESS_LIST, null);
    setOpen(!open);
  };

  const handleCancel = () => {
    setOpen(!open);
    setState(views.ADDRESS_LIST, null);
  };

  const handleSelected = (id) => {
    setState(views.ADDRESS_LIST, null);
    onSelect(id);
  };

  const selectedAddress = find(addresses, ({ _id }) => _id === formAddressId);
  const hasAddresses = size(addresses) > 0;

  const Renderer = () => {
    switch (activeView) {
      case views.ADDRESS_LIST:
        return (
          <AddressListView
            selectedAddressId={selectedAddressId}
            addresses={addresses}
            onCreate={handleCreate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onSelect={handleSelected}
          />
        );

      case views.ADDRESS_FORM:
        return (
          <Drawer
            open={open}
            width={windowSize > 768 ? 600 : 300}
            destroyOnClose
            title="Add new address"
            headerStyle={{ fontSize: '2rem' }}
            onClose={handleCancel}
          >
            <AddressFormView
              formAddressId={formAddressId}
              fields={selectedAddress}
              countries={newCountires}
              states={newStates}
              onSubmit={handleSubmit}
              onCancel={hasAddresses && handleCancel}
            />
          </Drawer>
        );

      default:
        return null;
    }
  };

  return <Renderer />;
};

export const ModalAddressView = (props) => (
  <Scrollbars style={{ height: MODAL_HEIGHT }}>
    <S.ViewTitle>Shipping Address</S.ViewTitle>
    <AddressView {...props} />
  </Scrollbars>
);

export default AddressView;
