import styled from 'styled-components';
import { Button, Layout } from 'antd';
import { Tooltip as TooltipBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';
import TextArea from 'antd/lib/input/TextArea';

const Wrapper = styled.div`
  input {
    width: 100%;
  }
`;

const Title = styled.h4`
  font-size: 16px !important;
`;

const Label = styled.label`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 12px;
  display: block;
`;

const LabelWithIcon = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Notes = styled(TextArea)``;
const SubmittedBtn = styled(Button)`
  color: ${color.White};
  background-color: ${color.SkyBlue};
  border-color: ${color.SkyBlue};
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  font-weight: 100;
  float: right;
  margin-top: 10px;
  border-radius: 0;
  font-size: 14px;
`;

const Tooltip = styled(TooltipBase)`
  color: ${color.SkyBlue};
`;

const Sidebar = styled(Layout.Sider)`
  flex-basis: 245px !important;
  width: 245px !important;
  max-width: 245px !important;
  box-shadow: 0px 3px 6px ${color.sidebarBoxShadow};
  background: ${color.lightBackground};
  padding: 30px 20px;
  // height: calc(100vh - 96px);
`;

export default {
  Notes,
  Label,
  LabelWithIcon,
  Title,
  Tooltip,
  Wrapper,
  Sidebar,
  SubmittedBtn,
};
