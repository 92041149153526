import { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useFormikContext } from 'formik';
import { FilePicker, ErrorMessage } from 'modules/v2/common/components';
import { useImageUpload } from 'modules/v2/common/hooks';
import { useParams } from 'react-router';
import { SwitchButton, Button } from 'modules/v2/common/AtomicDesign/atoms';

import { useGetAttributes } from './hooks';
import { buildCustomerTemplate } from './utils';
import CustomUploadAdapterPlugin from './UploadAdapter';
import S from './styles';

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'link',
      'insertTable',
      '|',
      'imageUpload',
      '|',
      'undo',
      'redo',
    ],
  },
  image: {
    toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
  language: 'en',
};

const CustomerTemplatesSideBar = ({ onCancel }) => {
  const {
    setFieldValue,
    values,
    errors,
    resetForm,
    submitForm,
    dirty,
    isValid,
  } = useFormikContext();
  const disabledSubmit = values.hasSavedControls ? !isValid || !dirty : false;
  const [controls, setControls] = useState(values.controls || {});
  const [formValues, setFormValues] = useState(values);
  const [imageUploads, setImageUploads] = useState([]);
  const { templateBaseId: isEdit } = useParams();

  const { uploadImage, isLoading } = useImageUpload({
    path: 'customer-templates-header',
    onSuccess: (imageUrl) => {
      const content = buildCustomerTemplate({ formValues: { ...formValues, imageUrl }, controls });
      setFieldValue('imageUrl', imageUrl);
      setFieldValue('content', content);
      setFormValues({ ...formValues, content, imageUrl });
    },
  });

  const handleOnSuccessGettingAttributes = ({ data }) => {
    const {
      memberEmail,
      memberFirstName,
      memberLastName,
      memberPhone,
      imageUrl,
      memberBookUrl,
      memberBrokerName,
      memberTitle,
      memberAddress,
    } = data;

    const user = {
      avatar: imageUrl,
      firstName: memberFirstName,
      lastName: memberLastName,
      phone: memberPhone,
      memberBookUrl,
      memberBrokerName,
      memberAddress,
      memberTitle,
      email: memberEmail,
    };

    setFormValues({
      ...values,
      content: buildCustomerTemplate({ formValues, controls }),
      user,
    });

    const content = buildCustomerTemplate({ formValues, imageUrl, controls });
    resetForm({ values: { ...values, content, user } });
  };

  useGetAttributes(handleOnSuccessGettingAttributes);

  const handleChangeControls = (field) => () => {
    setControls({ ...controls, [field]: !controls[field] });
    setFieldValue('controls', { ...controls, [field]: !controls[field] });
    setFormValues({ ...formValues, [field]: !controls[field] });
  };

  const handleChange = (field) => (value) => {
    setFieldValue(field, value.target.value);
    setFormValues({ ...formValues, [field]: value.target.value });

    if (field === 'templateTitle') {
      const content = buildCustomerTemplate({
        formValues,
        controls,
      });

      setFieldValue('content', content);
    }
  };

  const handleTemplateContentChange = (event, editor) => {
    const editorData = editor.getData();

    const content = buildCustomerTemplate({
      formValues,
      controls,
    });
    setFieldValue('content', content);
    setFieldValue('bodyContent', editorData);
  };

  return (
    <div className="w-full">
      <div>
        <h2 className="text-2xl font-extrabold">Create email template</h2>

        <div className="flex w-full justify-between items-center bg-neutral-0 py-[15px] px-[24px] rounded-t-lg border-b mt-[24px]">
          <p className="font-semibold text-base">Campaign details</p>
          <div className="flex gap-x-2">
            <Button type="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button disabled={disabledSubmit || imageUploads.length > 0} onClick={submitForm}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="flex shadow-box rounded-b-lg overflow-hidden">
        <div className="min-w-[360px] bg-neutral-0 p-6">
          {isEdit ? (
            <S.Title>{formValues.name}</S.Title>
          ) : (
            <div className="mb-6">
              <S.ControlLabel>Template name</S.ControlLabel>
              <S.Field
                name="name"
                value={formValues.name}
                $hasErrors={errors.name}
                onChange={handleChange('name')}
              />
              {errors.name ? <ErrorMessage>{errors.name}</ErrorMessage> : ''}
            </div>
          )}

          <div className="flex flex-col">
            <S.ControlLabel>Subject</S.ControlLabel>
            <span className="text-neutral-400">Add a subject line for this email</span>
          </div>

          <div className="mb-6">
            <S.Field
              name="subject"
              value={formValues.subject}
              $hasErrors={errors.subject}
              onChange={handleChange('subject')}
            />
            {errors.subject ? <ErrorMessage>{errors.subject}</ErrorMessage> : ''}
          </div>

          <S.ControlList>
            <S.ControlItem>
              <S.ControlLabel>Show header image</S.ControlLabel>
              <SwitchButton
                name="hasHeader"
                checked={controls.hasHeader}
                onChange={handleChangeControls('hasHeader')}
              />
            </S.ControlItem>

            <S.ControlItem>
              <S.ControlLabel>Show header title</S.ControlLabel>
              <SwitchButton
                name="hasHeaderTitle"
                checked={controls.hasHeaderTitle}
                onChange={handleChangeControls('hasHeaderTitle')}
              />
            </S.ControlItem>

            {formValues.user.firstName ? (
              <S.ControlItem>
                <S.ControlLabel>Show email signature</S.ControlLabel>
                <SwitchButton
                  name="hasSignature"
                  checked={controls.hasSignature}
                  onChange={handleChangeControls('hasSignature')}
                />
              </S.ControlItem>
            ) : null}
          </S.ControlList>
          {controls.hasSignature ? (
            <>
              <S.ControlItem>
                <S.ControlLabel>Show first name</S.ControlLabel>
                <SwitchButton
                  name="firstName"
                  checked={controls.hasFirstName}
                  onChange={handleChangeControls('hasFirstName')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Show last name</S.ControlLabel>
                <SwitchButton
                  name="lastName"
                  checked={controls.hasLastName}
                  onChange={handleChangeControls('hasLastName')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Show member title</S.ControlLabel>
                <SwitchButton
                  name="lastName"
                  checked={controls.hasMemberTitle}
                  onChange={handleChangeControls('hasMemberTitle')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Show broker name</S.ControlLabel>
                <SwitchButton
                  name="lastName"
                  checked={controls.hasMemberBrokerName}
                  onChange={handleChangeControls('hasMemberBrokerName')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Show broker address</S.ControlLabel>
                <SwitchButton
                  name="address"
                  checked={controls.hasMemberAddress}
                  onChange={handleChangeControls('hasMemberAddress')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Show book URL</S.ControlLabel>
                <SwitchButton
                  name="address"
                  checked={controls.hasMemberBookUrl}
                  onChange={handleChangeControls('hasMemberBookUrl')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Show broker phone number</S.ControlLabel>
                <SwitchButton
                  name="phone"
                  checked={controls.hasPhoneNumber}
                  onChange={handleChangeControls('hasPhoneNumber')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Show email address</S.ControlLabel>
                <SwitchButton
                  name="email"
                  checked={controls.hasEmailAddress}
                  onChange={handleChangeControls('hasEmailAddress')}
                />
              </S.ControlItem>

              <S.ControlItem>
                <S.ControlLabel>Show profile image</S.ControlLabel>
                <SwitchButton
                  name="hasProfileImage"
                  checked={controls.hasProfileImage}
                  onChange={handleChangeControls('hasProfileImage')}
                />
              </S.ControlItem>
            </>
          ) : null}
        </div>

        <S.TemplateWrapper>
          <S.TemplateContent>
            {controls.hasHeader ? (
              <>
                <S.TemplateImage data-testid="header-template-image">
                  {isLoading ? (
                    <S.LoadingWrapper>
                      <LoadingOutlined />
                    </S.LoadingWrapper>
                  ) : (
                    <S.FilePickerContainer>
                      <FilePicker
                        noMargin
                        icon=""
                        type="email-template-header"
                        uploadText={
                          formValues.imageUrl ? (
                            <img
                              alt="header-url"
                              style={{ width: '600px', height: '200px' }}
                              src={formValues.imageUrl}
                            />
                          ) : (
                            <S.TemplateImageContent>
                              <S.TemplateImageFile>
                                <img src="https://via.placeholder.com/80" alt="" />
                              </S.TemplateImageFile>
                              <S.TemplateImageDescription>
                                minimum width: 600px <br />
                                minimum height: 150px <br />
                                max file size: 5MB (MegaByte)
                              </S.TemplateImageDescription>
                            </S.TemplateImageContent>
                          )
                        }
                        handleImageChange={uploadImage}
                        isEditImage={false}
                        toggleCancelEdit={null}
                        imageUrlForEdit={null}
                        onSuccess={null}
                      />
                    </S.FilePickerContainer>
                  )}
                </S.TemplateImage>
              </>
            ) : null}

            {controls.hasHeaderTitle ? (
              <S.TemplateTitle
                type="text"
                data-testid="header-title"
                value={formValues.templateTitle}
                onChange={handleChange('templateTitle')}
                placeholder="[[Add Header Title]]"
              />
            ) : null}

            <S.TemplateBody>
              <S.CKEditor
                editor={ClassicEditor}
                data={formValues.bodyContent}
                onReady={(editor) => {
                  // eslint-disable-next-line no-param-reassign
                  editor.ui.view.editable.element.style.height = '99px';
                }}
                onChange={handleTemplateContentChange}
                config={{
                  extraPlugins: [
                    function(editor) {
                      return CustomUploadAdapterPlugin(editor, setImageUploads);
                    },
                  ],
                }}
              />
            </S.TemplateBody>

            {controls.hasSignature ? (
              <S.TemplateSignature data-testid="template-signature">
                <S.TemplateSignatureItems>
                  <S.TemplateSignatureItem>
                    <strong>
                      {controls.hasFirstName && formValues.user.firstName}{' '}
                      {controls.hasLastName && formValues.user.lastName}
                    </strong>
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    <strong>{controls.hasMemberTitle && formValues.user.memberTitle}</strong>
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    <strong>
                      {controls.hasMemberBrokerName && formValues.user.memberBrokerName}
                    </strong>
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    {controls.hasEmailAddress && formValues.user.email}
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    {controls.hasPhoneNumber && formValues.user.phone}
                  </S.TemplateSignatureItem>

                  <S.TemplateSignatureItem>
                    {controls.hasMemberAddress && formValues.user.memberAddress}
                  </S.TemplateSignatureItem>
                  <S.TemplateSignatureItem>
                    {controls.hasMemberBookUrl && formValues.user.memberBookUrl}
                  </S.TemplateSignatureItem>
                </S.TemplateSignatureItems>

                {controls.hasProfileImage && (
                  <S.TemplateSignatureImage
                    data-testid="profile-image"
                    alt="Profile Image"
                    src={formValues.user.avatar}
                  />
                )}
              </S.TemplateSignature>
            ) : null}
          </S.TemplateContent>
        </S.TemplateWrapper>
      </div>
    </div>
  );
};

export default CustomerTemplatesSideBar;
