import styled from 'styled-components';
import { Layout } from 'antd';
import * as color from 'modules/common/theme/color';

const MainLayout = styled(Layout)`
  min-height: 100vh;
`;

const Content = styled(Layout.Content)`
  padding: 5% 10%;
  background: ${color.White};

  @media (max-width: 576px) {
    padding: 0;
  }
`;

const FooterContainer = styled.div`
  position: relative !important;
`;

export default {
  Content,
  FooterContainer,
  MainLayout,
};
