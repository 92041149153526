import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';

import * as color from 'modules/common/theme/color';

const Link = styled(BaseLink)`
  color: ${color.White} !important;
  background-color: ${(props) => (!props.disabled ? color.PrimaryColor : '#dabec2')} !important;
  border-color: ${(props) => (!props.disabled ? color.PrimaryColor : 'transparent')} !important;
  text-shadow: 0 !important;
  box-shadow: 0 !important;
  letter-spacing: 1px;
  font-weight: 800;
`;

export default {
  Link,
};
