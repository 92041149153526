//

import S from './styles';

const RedirectConfirm = ({ title, onConfirm, onCancel }) => (
  <S.ContentConfirm>
    {title}
    <p>
      You are trying to navigate away from this page. Any unsaved and unfinished processes with your
      book will be lost.
    </p>
    <p>Are you sure you want to leave this page?</p>
    <S.ButtonGroup>
      <S.ButtonSecondary onClick={onConfirm}>Continue to Payment Methods</S.ButtonSecondary>
      <S.ButtonPrimary onClick={onCancel}>Cancel</S.ButtonPrimary>
    </S.ButtonGroup>
  </S.ContentConfirm>
);

export default RedirectConfirm;
