const CorrectIcon = ({ fill = '#069668', ...props }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8327 7.00033C12.8327 10.222 10.221 12.8337 6.99935 12.8337C3.77769 12.8337 1.16602 10.222 1.16602 7.00033C1.16602 3.77866 3.77769 1.16699 6.99935 1.16699C10.221 1.16699 12.8327 3.77866 12.8327 7.00033ZM9.35037 5.23263C9.52123 5.40349 9.52123 5.6805 9.35037 5.85135L6.43371 8.76802C6.26285 8.93887 5.98584 8.93887 5.81499 8.76802L4.64832 7.60135C4.47747 7.4305 4.47747 7.15349 4.64832 6.98263C4.81918 6.81178 5.09619 6.81178 5.26704 6.98263L6.12435 7.83994L7.428 6.53629L8.73166 5.23263C8.90251 5.06178 9.17952 5.06178 9.35037 5.23263Z"
      fill={fill}
    />
  </svg>
);

export default CorrectIcon;
