import styled from 'styled-components';
import { Button as BaseButton } from 'antd';
import { Link } from 'react-router-dom';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const LinkButton = styled(BaseButton)`
  color: ${color.White};
  background-color: ${color.PrimaryColor};
  border-color: ${color.PrimaryColor};
  text-shadow: none;
  box-shadow: none;
  font-family: ${font.NunitoFont};
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 40px !important;
  height: 40px;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    opacity: 0.9;
    transition: opacity 0.2s;
    color: ${color.White};
    background-color: ${color.PrimaryColor};
    border-color: ${color.PrimaryColor};
  }
`;

const BackLinkButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 12px 0;
  font-size: 16px;
  line-height: 24px;
  color: ${color.LightBlue};
  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: ${color.DarkBlue};
  }
`;

const LinkText = styled.span`
  margin: 0 8px;
`;

export default {
  LinkButton,
  BackLinkButton,
  LinkText,
};
