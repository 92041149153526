import getInstance from './instance';

const instance = getInstance();

export const getDraftList = () => {
  const url = 'draft/list';
  return instance.get(url);
};

export const getPlanDetails = () => {
  const url = 'book-count/list';
  return instance.get(url);
};

export const getStatesByCountryName = (Canada, USA) => {
  const url = `/state?country=${Canada}&country=${USA}`;
  return instance.get(url);
};
