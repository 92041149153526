function getDefaultProps() {
  return {
    width: '14',
    height: '14',
  };
}

const ArrowDownTable = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width,
        height,
      }}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6951 7.20542C11.9684 7.47878 11.9684 7.922 11.6951 8.19537L7.49507 12.3954C7.22171 12.6687 6.77849 12.6687 6.50512 12.3954L2.30512 8.19537C2.03176 7.922 2.03176 7.47878 2.30512 7.20542C2.57849 6.93205 3.02171 6.93205 3.29507 7.20542L6.3001 10.2104L6.3001 2.10039C6.3001 1.71379 6.6135 1.40039 7.0001 1.40039C7.3867 1.40039 7.7001 1.71379 7.7001 2.10039L7.7001 10.2104L10.7051 7.20542C10.9785 6.93205 11.4217 6.93205 11.6951 7.20542Z"
        fill="#252525"
      />
    </svg>
  );
};

ArrowDownTable.defaultProps = {
  width: getDefaultProps().width,
  height: getDefaultProps().height,
};

export default ArrowDownTable;
