import { createSelector } from 'reselect';
import { get } from 'lodash-es';

import { dashboardSelector } from 'modules/dashboard/store/selectors/dashboard';

export const supportStateSelector = createSelector(dashboardSelector, (state) =>
  get(state, 'support'),
);

export const supportTicketSelector = createSelector(supportStateSelector, (support) =>
  get(support, 'ticket', null),
);

export const supportTicketStatusSelector = createSelector(supportTicketSelector, (ticket) =>
  get(ticket, 'status', null),
);
