/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */

import { useState } from 'react';
import { useParams } from 'react-router';
import { useMutation } from 'react-query';
import { acceptAddon } from 'modules/api/digitalServices';

import env from 'environments';

import { notification } from 'modules/common/utils';
import { useFetchSession, useStep } from 'modules/onboard/hooks';

import { OnboardTemplate } from 'modules/onboard/templates';
import { getCurrentStepIndex, setSession } from 'modules/onboard/utils';

import chooseMagazine from './images/1-choose-magazine-cover.png';
import contactDetails from './images/2-add-contact-details.png';
import uploadHeadshot from './images/3-upload-headshot.png';
import createDigitalMagazine from './images/4-create-digital-magazine.png';
import socialMedia from './images/5-social-media-and-email-content.png';
import sendMagazine from './images/6-send-magazine.png';
import weMailThe10Magazines from './images/7-we-mail-the-10-magazines-to-your-contacts.png';
import banner from './images/banner-1.jpg';
import hpBookSpread from './images/hp-book-spread.png';
import hpPileOfBooks from './images/hp-pile-of-books2.png';
import hpPhoneAndTablet from './images/hp-phone-and-tablet.png';
import hpEmail from './images/hp-email.png';
import hpFbPost from './images/hp-fb-post.png';
import hpLapTop from './images/hp-laptop.png';
import personalizeImage from './images/personalize-image.png';
import authorifyLogo from './images/authorify_logo_white.png';
import useHubspotTrackCode from '../Common/HubspotTrackCode';

import './style.css';

const CallToAction = ({ onSubmit }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = (option) => () => {
    setClicked(true);
    onSubmit(option);
  };

  return (
    <div className="upsell-cta">
      <div className="wrapper buttons-group">
        <div className="flex-row">
          <div className="left-column cta-btn-container">
            <a className={`cancel-cta ${clicked ? 'disabled' : ''}`} onClick={handleClick(false)}>
              No, Thank You and <br />
              Continue To Schedule Coaching
            </a>
          </div>
          <div className="right-column cta-btn-container">
            <a className={`yes-cta ${clicked ? 'disabled' : ''}`} onClick={handleClick(true)}>
              Yes, I Want To Claim My 10 Free Referral Magazine and Continue To Schedule Coaching
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddonPage = () => {
  useHubspotTrackCode();
  const { offerCode } = useParams();
  const { goToNextStep } = useStep();

  let addonOffer;
  const { session } = useFetchSession();
  const currentStepIndex = getCurrentStepIndex({ step: 'place_order', steps: session.steps });
  const { mutate } = useMutation('acceptAddon', ({ code, accepted }) =>
    acceptAddon(code, offerCode, accepted),
  );

  if (session) {
    addonOffer = session.currentOffer;
  }

  const handleSubmit = (accepted) => {
    mutate(
      { code: addonOffer.code, accepted },
      {
        onSuccess: async (data) => {
          setSession(data.data);
          goToNextStep();
        },
        onError: (error) => {
          notification.error({ title: 'Something bad has happened!', description: error });
        },
      },
    );
  };

  return (
    <OnboardTemplate
      currentStep={currentStepIndex}
      paddingContent="0"
      isLoading={!session}
      stickyContent={<CallToAction onSubmit={handleSubmit} />}
    >
      <div className="upsell-page">
        <div className="wrapper">
          <div className="upsell-container">
            <div className="heading rounded-top">
              You Might Be Wondering Why I Put These Three $100 Bills Here...
            </div>

            <div className="sections">
              <img alt="" src={banner} className="img" />

              <p>
                Here's why. As a thank you for your purchase, I've just added a $300 credit to your
                Referral Marketing account...
              </p>
              <h2>Let me explain…</h2>
              <p>
                I recently put together a new referral marketing system. And because you decided to
                try out the Authorify Marketing System, I want you to try out my new Easy Referral
                Listings System for almost free.
              </p>
              <p>So, the $200 credit is how I’m going to make my best bargain possible… Today.</p>

              <h2>Why?</h2>
              <p>
                Well, if you don’t take my best deal, maybe you’ll never buy anything. So, that’s
                why I want to offer you my best deal today.
              </p>

              <h1 className="text-center">
                The Best Deal On Referral Marketing That I’ve Ever Offered
              </h1>

              <p>
                Now, normally when you sign up for my new Easy Referral Listings System, you’d have
                to invest $199 for the Setup Fee and $199 for the first 30 days of the Membership.
              </p>
              <p>
                But, because you decided to try out the Authorify Marketing System, I’m going to
                cover the $199 Setup Fee and let you try out my new Easy Referral Listings System
                for 30 Days for Free. That’s right.
              </p>

              <h1 className="text-center">
                You Can Try Out The New Easy Referral Listings System For Free.
              </h1>
              <h2 className="text-center">
                I’m so confident you’ll generate more referrals with your magazines that I’ll invest
                almost $300 for you to give them a try.
              </h2>

              <p>
                I’m so confident you’ll generate more referrals with your magazines that I’ll invest
                almost $300 for you to give them a try.
              </p>
            </div>

            <div className="heading arrow-bottom">
              Have You Ever Noticed That All The Top Agents Get Most Of Their Business From
              Referrals And Repeat Customers?
            </div>
            <svg width="100%" height="50" viewBox="0 0 100 102" preserveAspectRatio="none">
              <path d="M0 0 L50 100 L100 0 Z" fill="#0075B2" />
            </svg>

            <div className="sections">
              <h2>It's true.</h2>

              <ul>
                <li>
                  <strong>
                    Phil Byers sold 33 homes his first full year in the real estate business with
                    only 189 people in his database.
                  </strong>{' '}
                  <u>
                    His Referral Database continues to provide him with a predictable $20-25 Million
                    a year business year after year.
                  </u>
                </li>
                <li>
                  <strong>
                    DeLena Ciamacco has been the #1 RE/MAX Agent in Ohio for 20+ years
                  </strong>{' '}
                  <u>
                    and currently has over 40 active listings. She gets 60% of her business from
                    Referrals.
                  </u>
                </li>
                <li>
                  <strong>
                    Kim Lund has over $2 Billion in sales and is the #1 Global Producer for RE/MAX.
                  </strong>{' '}
                  <u>He gets 90% of his business from Referrals.</u>
                </li>
                <li>
                  <strong>Ronnie & Cathy Matthews sold 193 homes in the last 12 months,</strong>{' '}
                  <u>and about 60-70% of their business comes from referrals.</u>
                </li>
              </ul>

              <p>So, as you can see…</p>

              <h1 className="text-center">
                The Top Agents Focus On Referrals And Repeat Customers, Because That’s The Easiest
                Way To Grow A Real Estate Business
              </h1>

              <p>
                Studies show that you can sell 9X more homes when you focus on Referrals & Repeat
                Customers instead of cold calling, door knocking, or open houses.
              </p>
              <p>
                That’s why I created my Easy Referral Listings System and I want you to try it out
                with a massive discount.
              </p>

              <h2 className="text-center">
                Here Is Everything You'll Receive Along With Your 10 Free Magazines...
              </h2>

              <div className="rounded-box-with-shadow">
                <div className="flex-row">
                  <div className="three-column text-center">
                    <img alt="" src={hpBookSpread} className="img" />
                    <p className="small">
                      <strong>Your own personalized magazine.</strong> We'll customize the magazine
                      with your name, picture, and contact information.
                    </p>
                  </div>
                  <div className="three-column text-center">
                    <img alt="" src={hpPileOfBooks} className="img" />
                    <p className="small">
                      <strong>
                        Printed copies of your magazines mailed directly to your Past Clients and
                        SOI.
                      </strong>{' '}
                      We ship directly to your leads so you don't have to do anything!
                    </p>
                  </div>
                  <div className="three-column text-center">
                    <img alt="" src={hpPhoneAndTablet} className="img" />
                    <p className="small">
                      <strong>
                        A digital magazine to share with your Past Clients and Sphere.
                      </strong>{' '}
                      You can email it to them or post it on Social Media.
                    </p>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="three-column text-center">
                    <img alt="" src={hpLapTop} className="img" />
                    <p className="small">
                      <strong>A Lead Captures Web Page for generating new subscribers.</strong> Send
                      your magazine to them, and generate more referrals.
                    </p>
                  </div>
                  <div className="three-column text-center">
                    <img alt="" src={hpEmail} className="img" />
                    <p className="small">
                      <strong>
                        Weekly Emails for you to send to your Past Clients and Sphere.
                      </strong>{' '}
                      These emails will promote your digital magazine and generate more referrals.
                    </p>
                  </div>
                  <div className="three-column text-center">
                    <img alt="" src={hpFbPost} className="img" />
                    <p className="small">
                      <strong>Weekly Social Media Articles.</strong> Simply post them on Social
                      Media and generate more referrals.
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <h2 className="text-center">
                Here’s What Your New Personalized Magazine Could Look Like!
              </h2>
              <img alt="" src={personalizeImage} className="img" />
            </div>

            <div className="heading arrow-bottom">Here’s What Happens After You Sign Up</div>
            <svg width="100%" height="50" viewBox="0 0 100 102" preserveAspectRatio="none">
              <path d="M0 0 L50 100 L100 0 Z" fill="#0075B2" />
            </svg>

            <div className="sections sign-up-items">
              <div className="flex-row">
                <div className="two-column">
                  <h3>1. Choose Your Magazine Cover</h3>
                  <img alt="" src={chooseMagazine} className="img" />
                </div>
                <div className="two-column">
                  <h3>2. Add Your Contact Details For The Magazine</h3>
                  <img alt="" src={contactDetails} className="img" />
                </div>
              </div>

              <div className="flex-row">
                <div className="two-column">
                  <h3>3. Upload Your Headshot Photo</h3>
                  <img alt="" src={uploadHeadshot} className="img" />
                </div>
                <div className="two-column">
                  <h3>4. We Create Your Digital Magazine And Send It To You. </h3>
                  <img alt="" src={createDigitalMagazine} className="img" />
                </div>
              </div>

              <div className="flex-row">
                <div className="two-column">
                  <h3>5. We Send You The Social Media Content and Email Content.</h3>
                  <img alt="" src={socialMedia} className="img" />
                </div>
                <div className="two-column">
                  <h3>
                    6. Upload the 10 contacts that you want us to send your magazine to on your
                    behalf.
                  </h3>
                  <img alt="" src={sendMagazine} className="img" />
                </div>
              </div>

              <div className="flex-row">
                <div className="two-column">
                  <h3>7. We mail the 10 magazines to your contacts.</h3>
                  <img alt="" src={weMailThe10Magazines} className="img" />
                </div>
                <div className="two-column" />
              </div>

              <p>
                After that, every month, we’ll send 30 magazines to your Sphere, you’ll get a new
                Digital Magazine, new social media posts and email content! Ready to get started?
                Then click the button below to claim this offer now.
              </p>
              <h1 className="text-center">
                Yes, I Want To Claim My 10 Free Referral Magazines & Try Out The Easy Referral
                Listings System!
              </h1>
              <p>
                Please send me my 10 FREE magazines, and let me try out the Referral Marketing
                System for Free.
              </p>
            </div>
          </div>
        </div>
        <div className="upsell-footer">
          <div className="text-center">
            <img alt="" src={authorifyLogo} className="footer-logo" />
            <p>Call us Monday through Friday ({env.SUPPORT_OPPENING_HOURS})</p>
            <p>Email Address: support@authorify.com</p>
            <p>Phone Number: (904) 695-9933</p>
          </div>
          <br />
          <br />
          <p>
            Claim your 10 Free Magazines and try out our Referral Marketing System for 30 days. If
            for any reason you decide it’s not for you, simply let us know and we'll automatically
            stop all future payments. This offer is only available to new members and may not be
            combined with any other offers or discounts. The Authorify Referral Marketing Membership
            automatically renews 30 days after the trial order date at $199 a month. You can cancel
            your membership at any time by calling (904) 695-9933, emailing support@authorify.com,
            or by filling out the Contact Form on this website. All membership orders are final and
            cannot be canceled after being processed.
          </p>
          <br />
          <br />
          <div className="text-center">
            <h2 className="text-white">*This offer is not currently available in Canada.</h2>
          </div>
        </div>
      </div>
    </OnboardTemplate>
  );
};

export default AddonPage;
