import { generatePath } from 'react-router-dom';

import {
  ROUTE_REPORTS,
  // ROUTE_SOCIAL_MEDIA_CAMPAIGN_CONFIGURATION,
  ROUTE_SOCIAL_MEDIA_CAMPAIGN_TEMPLATE,
  ROUTE_REFERRAL_MARKETING_PAGES,
  ROUTE_REFERRAL_MARKETING_APPROVER,
  // ROUTE_PRODUCT_CONFIG,
  ROUTE_PRODUCT_MAINTENANCE,
  // ROUTE_PRODUCT_MAINTENANCE_CREATE,
  ROUTE_PRODUCT_MAINTENANCE_EDIT,
  ROUTE_COACH_MAINTENANCE_CREATE_COACH,
  ROUTE_COACH_MAINTENANCE_EDIT_COACH,
  ROUTE_CUSTOM_LANDING_PAGE,
  ROUTE_COACH_EMAIL_REMINDER,
  ROUTE_SALES_REPORT,
  ROUTE_TRIPWIRE_SALES_REPORT,
  ROUTE_CUSTOMER_EVENT_REPORT,
  ROUTE_EMAIL_REMINDER_RESCHEDULER,
  ROUTE_RM_MAGAZINES_REPORT,
  ROUTE_REFERRAL_MARKETING_MAILING_LIST,
  ROUTE_REFERRAL_MARKETING_MAILING_LIST_PREVIEW,
  ROUTE_OFFBOARDING,
  ROUTE_EMAIL_CAMPAIGN_REPORT,
  ROUTE_RM_MAGAZINES_PREVIEW,
  ROUTE_MEMBERS_360,
  ROUTE_CUSTOMERS_PAGE,
  SALES_REPORT_EXPORT,
  ROUTE_END_MONTH,
  ROUTE_CUSTOMER_MEETING_REPORT,
  ROUTE_BOOK_DRAFT_MAINTENANCE,
  ROUTE_COACH_MAINTENANCE,
  ROUTE_UPDATE_AFY_PASSWORD_HUBSPOT,
  ROUTE_CUSTOMER_PROPERTIES_LIST,
  ROUTE_SOCIAL_MEDIA_CONTENT_CREATE,
  ROUTE_SOCIAL_MEDIA_EDIT_CAMPAIGN,
  ROUTE_SOCIAL_MEDIA_CAMPAIGN_CAMPAIGNS,
  // ROUTE_SOCIAL_MEDIA_CAMPAIGN_CATALOG,
  ROUTE_OFFERS_LIST,
  ROUTE_LAUNCH_OFFERS,
  ROUTE_OFFERS_CREATE,
  ROUTE_TEAM_MEMBER_MEETING,
  ROUTE_TEAM_MEMBER_MEETING_REPORT,
  ROUTE_TEAM_MEMBER_CALL_REPORT,
  ROUTE_TEAM_MEMBER_CALL_PLAY,
  ROUTE_BOOK_PREVIEWS,
  ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR_ADMIN,
  ROUTE_TEAM_MEMBER_PHONE_CALL,
} from './constants';

export const getRouteReports = () => generatePath(ROUTE_REPORTS);

export const getRouteBookPreviews = () => generatePath(ROUTE_BOOK_PREVIEWS);

export const getRouteOffers = () => generatePath(ROUTE_OFFERS_LIST);

export const getRouteLaunchOffers = () => generatePath(ROUTE_LAUNCH_OFFERS);

export const getRouteCreateOffers = () => generatePath(ROUTE_OFFERS_CREATE);

export const getRouteEndMonth = () => generatePath(ROUTE_END_MONTH);

export const getRouteOnboardingReportExport = () => generatePath(SALES_REPORT_EXPORT);

export const getRouteBookDraftMaintenance = () => generatePath(ROUTE_BOOK_DRAFT_MAINTENANCE);

export const getRouteReferralMarketingApproverDetails = (id) =>
  generatePath(ROUTE_REFERRAL_MARKETING_APPROVER, { id });

export const getRouteReferralMarketingPages = (content) =>
  generatePath(ROUTE_REFERRAL_MARKETING_PAGES, { content });

// export const getSocialMediaConfiguration = (content) =>
//   generatePath(ROUTE_SOCIAL_MEDIA_CAMPAIGN_CONFIGURATION, { content });
//

export const getRouteCoachMaintenance = () => generatePath(ROUTE_COACH_MAINTENANCE);

export const getRouteCoachEmailReminder = () => generatePath(ROUTE_COACH_EMAIL_REMINDER);

export const getRouteOffBoarding = () => generatePath(ROUTE_OFFBOARDING);

// export const getRouteProductConfig = () => generatePath(ROUTE_PRODUCT_CONFIG);

export const getRouteProductMaintenance = () => generatePath(ROUTE_PRODUCT_MAINTENANCE);

// export const getRouteProductMaintenanceCreate = () =>
//   generatePath(ROUTE_PRODUCT_MAINTENANCE_CREATE);

export const getRouteProductMaintenanceEdit = (id) =>
  generatePath(ROUTE_PRODUCT_MAINTENANCE_EDIT, { id });

export const getRouteCustomersAdmin = () => generatePath(ROUTE_CUSTOMERS_PAGE);

export const getRouteCustomerMeetingReport = (customerEmail) =>
  generatePath(ROUTE_CUSTOMER_MEETING_REPORT, { customerEmail });

export const getRouteCustomLandingPage = () => generatePath(ROUTE_CUSTOM_LANDING_PAGE);

export const getRouteMembers360 = () => generatePath(ROUTE_MEMBERS_360);

export const getRouteCoachMaintenanceCreateCoach = () =>
  generatePath(ROUTE_COACH_MAINTENANCE_CREATE_COACH);

export const getRouteCoachMaintenanceEditCoach = (id) =>
  generatePath(ROUTE_COACH_MAINTENANCE_EDIT_COACH, { id });

export const getRouteTeamMemberMeetings = () => generatePath(ROUTE_TEAM_MEMBER_MEETING);

export const getRouteTeamMemberPhoneCall = () => generatePath(ROUTE_TEAM_MEMBER_PHONE_CALL);

export const getRouteTeamMemberMeetingReport = (teamMemberEmail) =>
  generatePath(ROUTE_TEAM_MEMBER_MEETING_REPORT, { teamMemberEmail });

export const getRouteTeamMemberCallReport = (teamMemberEmail, currToken, currPage) =>
  generatePath(ROUTE_TEAM_MEMBER_CALL_REPORT, { teamMemberEmail, currToken, currPage });

export const getRouteTeamMemberCallPlay = () => generatePath(ROUTE_TEAM_MEMBER_CALL_PLAY);

export const getSocialMediaTemplate = (content) =>
  generatePath(ROUTE_SOCIAL_MEDIA_CAMPAIGN_TEMPLATE, { content });

export const getSocialMediaCampaigns = (content) =>
  generatePath(ROUTE_SOCIAL_MEDIA_CAMPAIGN_CAMPAIGNS, { content });

// export const getSocialMediaCampaignsCatalog = (content) =>
//   generatePath(ROUTE_SOCIAL_MEDIA_CAMPAIGN_CATALOG, { content });
//
export const getRouteCreateSocialMediaCampaigns = (id) =>
  generatePath(ROUTE_SOCIAL_MEDIA_CONTENT_CREATE, { id });

export const getRouteEditSocialMediaCampaigns = (id) =>
  generatePath(ROUTE_SOCIAL_MEDIA_EDIT_CAMPAIGN, { id });

// export const getAdminChangeMemberHubspotPassword = () =>
//   generatePath(ROUTE_UPDATE_AFY_PASSWORD_HUBSPOT);

export const getRouteCustomerPropertiesList = () => generatePath(ROUTE_CUSTOMER_PROPERTIES_LIST);
export const getRouteReferralMarketingMailingList = () =>
  generatePath(ROUTE_REFERRAL_MARKETING_MAILING_LIST);

export const getRouteReferralMarketingMailingListPreview = () =>
  generatePath(ROUTE_REFERRAL_MARKETING_MAILING_LIST_PREVIEW);

export const getRouteEmailReminderRescheduler = () =>
  generatePath(ROUTE_EMAIL_REMINDER_RESCHEDULER);

export const getRouteOnboardingReport = () => generatePath(ROUTE_SALES_REPORT);
export const getRouteTripwireSalesReport = () => generatePath(ROUTE_TRIPWIRE_SALES_REPORT);
export const getRouteCustomerEventReport = (customerId) =>
  generatePath(ROUTE_CUSTOMER_EVENT_REPORT, { customerId });
export const getRouteRmMagazineReport = () => generatePath(ROUTE_RM_MAGAZINES_REPORT);
export const getRouteRmMagazinePreview = (id, customerId) =>
  generatePath(ROUTE_RM_MAGAZINES_PREVIEW, { id, customerId });
export const getRouteRmMagazineEdit = (
  year: string,
  month: string,
  customerId: string,
  previewId: string,
) =>
  generatePath(ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR_ADMIN, {
    year,
    month,
    customerId,
    previewId,
  });
export const getRouteEmailCampaignReport = () => generatePath(ROUTE_EMAIL_CAMPAIGN_REPORT);
