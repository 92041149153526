import { PrimaryButton } from 'modules/onboard/components/UIComponents';
import S from './styles';

const AlertWithButton = ({
  type = 'error',
  description,
  buttonTitle,
  width,
  handleClick,
  hideButton,
}) => (
  <S.BookWrapBox>
    <S.Alert message={description} type={type} width={width} />
    {!hideButton && <PrimaryButton onClick={handleClick}>{buttonTitle}</PrimaryButton>}
  </S.BookWrapBox>
);

export default AlertWithButton;
