import { PartitionOutlined, HomeOutlined } from '@ant-design/icons';

import env from 'environments';

import { hasSocialMediaTrainingAccess } from 'modules/dashboard/utils';

import Card from 'modules/dashboard/components/Card';
import S from '../../styles';

const TrainingSitesContainer = () => {
  const TrainingSiteUrl = `${env.SOCIAL_TRAINING_URL}`;
  const SocialMediaTrainingSiteUrl = `${env.SOCIAL_MEDIA_TRAINING_URL}`;
  const MortgageTrainingSiteUrl = `${env.SOCIAL_TRAINING_URL}/mortgage/`;

  return (
    <>
      <S.Title>Training Sites</S.Title>
      <S.Container>
        <Card
          icon={
            <S.RotateIcon>
              <PartitionOutlined />
            </S.RotateIcon>
          }
          title="Member's Training Hub"
          description="Your one-stop training hub for maximizing your membership and generating more sales."
          descriptionHeight="42px"
          buttonIsActive
          buttonText="View Member's Training Hub"
          link={TrainingSiteUrl}
          width="100%"
          height="201px"
          isNewTab
        />

        <Card
          icon={<HomeOutlined />}
          title="Mortgage Training"
          description="Training and templates specifically tailored to Authorify Mortgage Officer members."
          descriptionHeight="42px"
          buttonIsActive
          buttonText="View Mortgage Training"
          link={MortgageTrainingSiteUrl}
          width="100%"
          height="201px"
          isNewTab
        />

        {hasSocialMediaTrainingAccess() ? (
          <Card
            icon={<HomeOutlined />}
            title="Social Media Training"
            description="Use this training and monthly Top Agent Tips templates to build your Social Media Fanbase and generate more sales."
            buttonIsActive
            buttonText="View Social Media Training"
            link={SocialMediaTrainingSiteUrl}
            width="100%"
            height="201px"
            descriptionHeight="42px"
            isNewTab
          />
        ) : null}
      </S.Container>
    </>
  );
};

export default TrainingSitesContainer;
