import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
// import { Input } from 'modules/common/components/UIComponents/Form';
import { Upload } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  background: ${color.White};
  padding: 17px 0px 0px 19px;
`;

const PlusIcon = styled(PlusCircleOutlined)`
  font-size: 20px;
`;

const Text = styled.p`
  color: #2e3f50;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  padding: 5px 0;
`;

const UploadInput = styled(Upload)`
  .ant-upload {
    width: 660px;
    height: 210px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 190px;
    height: 205px;
    margin: 0px 240px;
  }
  .anticon-eye-o {
    display: none;
  }
`;

export default {
  PlusIcon,
  UploadInput,
  Wrapper,
  Text,
};
