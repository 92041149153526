import React from 'react';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { Tooltip } from 'modules/v2/common/AtomicDesign/molecules';
import { MenuDotsVertical } from 'modules/v2/common/components/SvgIcon';

interface ActionButtonProps {
  content: React.ReactNode;
}

const ActionButton = ({ content }: ActionButtonProps) => {
  return (
    <Tooltip arrow={false} trigger="click" style="light" placement="left" content={content}>
      <Button color="muted" size="xs" className="relative">
        <MenuDotsVertical />
      </Button>
    </Tooltip>
  );
};

export default ActionButton;
