import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { createPaymentProfile, setDefaultPaymentProfile } from 'modules/api';
import { ClosableModal, SuggestionCard, SwitchButton } from 'modules/v2/common/AtomicDesign/atoms';
import { Notification, WarnMessage } from 'modules/v2/common/AtomicDesign/molecules';
import { ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import { DangerTriangleIcon, LockIcon } from 'modules/v2/common/components/SvgIcon';
import { Drawer } from 'antd';
import buildConfig from '../utils';
import RelativeLoading from '../../../RelativeLoading';
import { customLoadingModalTheme } from './theme';

interface AddCardModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  invalidateQueries?: boolean;
  deleteDefaultCard?: boolean;
}

const defaultProps: Partial<AddCardModalProps> = {
  invalidateQueries: true,
  deleteDefaultCard: false,
};

const AddCardModal = ({
  showModal,
  setShowModal,
  invalidateQueries,
  deleteDefaultCard,
}: AddCardModalProps) => {
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const queryClient = useQueryClient();
  const chargifyForm = useRef();
  const [chargifyLoading, setChargifyLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const chargify = useRef(new window.Chargify());
  const config = buildConfig('card');

  useEffect(() => {
    setChargifyLoading(true);
    setTimeout(() => {
      setChargifyLoading(false); // Fake loading for a much smoother experience
    }, 2000);
    const chargifyRef = chargify.current;
    chargifyRef.load(config, { onThreeDsConfigError: console.error }); // eslint-disable-line no-console
    return () => {
      chargifyRef.unload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: mutateSetCardDefault } = useMutation(
    ['setDefaultPaymentProfile'],
    setDefaultPaymentProfile,
    {
      onSuccess: async () => {
        if (invalidateQueries) {
          queryClient.invalidateQueries('getDefaultPaymentProfile');
          queryClient.invalidateQueries('getPaymentProfilesList');
        }
        setIsLoading(false);
        setShowNotification(true);
        setShowModal(false);
      },
    },
  );

  useEffect(() => {
    let timer;
    setShowNotification(true);
    if (showNotification) {
      timer = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showNotification]);

  const { mutate } = useMutation(['createPaymentProfile'], createPaymentProfile);

  const onSave = (formData) => {
    setIsLoading(true);
    return mutate(formData, {
      onSuccess: async (data) => {
        if (checked) {
          mutateSetCardDefault(data?.payment_profile?.id);
        } else {
          queryClient.invalidateQueries('getDefaultPaymentProfile');
          queryClient.invalidateQueries('getPaymentProfilesList');
          setShowModal(false);
        }
      },
      onError: () => {
        setIsLoading(false);
        setErrorNotification(true);
      },
    });
  };

  const handlePayment = () => {
    try {
      setIsLoading(true);
      chargify.current.token(
        chargifyForm.current,
        async (token) => {
          const payload = {
            chargifyToken: token,
          };
          return onSave(payload);
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
      );
      return false;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setIsLoading(false);
    }
    return false;
  };

  const footerDrawer = (
    <div
      className="bg-primary-500 text-neutral-800 font-bold px-[18px] py-[13px] gap-2 rounded-md h-11 w-full flex justify-center items-center cursor-pointer"
      onClick={() => handlePayment()}
    >
      Add new payment
    </div>
  );

  return (
    <Drawer
      title="Add new payment method"
      open={showModal}
      onClose={() => setShowModal(false)}
      footer={footerDrawer}
    >
      <div className="w-[440-px] mb-4">
        {deleteDefaultCard && (
          <WarnMessage type="error">
            <div className="flex gap-3">
              <div className="w-4 h-4">
                <DangerTriangleIcon width={20} height={20} />
              </div>
              <div>
                This card is used as your default payment. To delete this card, add a new one.
              </div>
            </div>
          </WarnMessage>
        )}
        <SuggestionCard
          icon={<LockIcon fill="#069668" />}
          description="Your credit card details will be encrypted and is 100% safe with our payment processing partner, Chargify. Payment information is not stored on our servers."
        />
      </div>
      <div className="mb-6">
        <RelativeLoading isLoading={chargifyLoading} />
        <form onSubmit={handlePayment} ref={chargifyForm}>
          <div>
            <div id="chargify1" />
            <div id="chargify2" />
            <div id="chargify3" />
            <div id="chargify4" />
          </div>
        </form>
      </div>
      <div className="flex items-center gap-2 font-semibold text-sm mb-12">
        <SwitchButton checked={checked} setChecked={setChecked} />
        <span>Set as my default payment</span>
      </div>
      <ProcessingModal
        showModal={isLoading}
        title=""
        description="Adding new payment method..."
        theme={customLoadingModalTheme}
      />
      {errorNotification && (
        <Notification
          type="error"
          description="An error occurred while adding new payment method. Please try again or caontect the support."
        />
      )}
    </Drawer>
  );
};

AddCardModal.defaultProps = defaultProps;

export default AddCardModal;
