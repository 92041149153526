import { put, takeEvery } from 'redux-saga/effects';

import { markPreferences } from 'modules/editor/store';

import {
  UPDATE_PREFERENCES,
  UPDATE_PREFERENCE_BY_ELEMENT_ID,
  UPDATE_PREFERENCE_BY_KEY,
  UPDATE_PREFERENCE_STATUS_BY_KEY,
} from 'modules/editor/store/constants';

export function* onMarkPreferences() {
  const action = markPreferences();
  yield put(action);
}

export function* watchMarkPreferences() {
  const pattern = [
    UPDATE_PREFERENCES,
    UPDATE_PREFERENCE_BY_ELEMENT_ID,
    UPDATE_PREFERENCE_BY_KEY,
    UPDATE_PREFERENCE_STATUS_BY_KEY,
  ];

  yield takeEvery(pattern, onMarkPreferences);
}
