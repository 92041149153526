import dayjs from 'dayjs';
import { formatDate } from 'modules/v2/common/utils';

export const planHandle = {
  DIGITAL_MONTHLY: 'digital_monthly',
  DIGITAL_PLUS_MONTHLY: 'digitalplus_monthly',
  DIGITAL_ANNUAL: 'digital_annual',
  DIGITAL_PLUS_ANNUAL: 'digitalplus_annual',
  DIGITAL_PLUS_PLUS_ANNUAL: 'digitalplusplus_annual',
  PREMIUM_MONTHLY: 'premium_monthly',
  PREMIUM_PLUS_MONTHLY: 'premiumplus_monthly',
  PREMIUM_ANNUAL: 'premium_annual',
  PREMIUM_PLUS_ANNUAL: 'premiumplus_annual',
  PLATINUM_MONTHLY: 'platinum_monthly',
  PLATINUM_PLUS_MONTHLY: 'platinumplus_monthly',
  PLATINUM_ANNUAL: 'platinum_annual',
  PLATINUM_PLUS_ANNUAL: 'platinumplus_annual',
};

export const contractedPlans = [
  {
    plan: 'Digital+',
    monthly: {
      price: 149,
      licensedBooks: 2,
      digitalBooks: 2,
      printedBooks: 0,
      leadCaptureWebsites: 2,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 1,
      googleMyBusiness: 1,
      socialMediaTrainingSite: 0,
    },
    annual: {
      price: 124,
      priceYearly: 1490,
      licensedBooks: 2,
      digitalBooks: 2,
      printedBooks: 0,
      leadCaptureWebsites: 2,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 1,
      googleMyBusiness: 1,
      socialMediaTrainingSite: 0,
    },
  },
  {
    plan: 'Premium+',
    monthly: {
      price: 259,
      licensedBooks: 4,
      digitalBooks: 4,
      printedBooks: 20,
      leadCaptureWebsites: 4,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 1,
      googleMyBusiness: 1,
      socialMediaTrainingSite: 0,
    },
    annual: {
      price: 215,
      priceYearly: 2590,
      licensedBooks: 4,
      digitalBooks: 4,
      printedBooks: 240,
      leadCaptureWebsites: 4,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 1,
      googleMyBusiness: 1,
      socialMediaTrainingSite: 0,
    },
  },
  {
    plan: 'Platinum+',
    monthly: {
      price: 399,
      licensedBooks: 25,
      digitalBooks: 25,
      printedBooks: 50,
      leadCaptureWebsites: 25,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 1,
      googleMyBusiness: 1,
      socialMediaTrainingSite: 1,
    },
    annual: {
      price: 332,
      priceYearly: 3990,
      licensedBooks: 25,
      digitalBooks: 25,
      printedBooks: 600,
      leadCaptureWebsites: 25,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 1,
      googleMyBusiness: 1,
      socialMediaTrainingSite: 1,
    },
  },
];

export const nonContractedPlans = [
  {
    plan: 'Digital',
    monthly: {
      price: 149,
      licensedBooks: 2,
      digitalBooks: 2,
      printedBooks: 0,
      leadCaptureWebsites: 2,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 0,
      googleMyBusiness: 0,
      socialMediaTrainingSite: 0,
    },
    annual: {
      price: 124,
      priceYearly: 1490,
      licensedBooks: 2,
      digitalBooks: 2,
      printedBooks: 0,
      leadCaptureWebsites: 2,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 0,
      googleMyBusiness: 0,
      socialMediaTrainingSite: 0,
    },
  },
  {
    plan: 'Premium',
    monthly: {
      price: 259,
      licensedBooks: 4,
      digitalBooks: 4,
      printedBooks: 20,
      leadCaptureWebsites: 4,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 0,
      googleMyBusiness: 0,
      socialMediaTrainingSite: 0,
    },
    annual: {
      price: 215,
      priceYearly: 2590,
      licensedBooks: 4,
      digitalBooks: 4,
      printedBooks: 240,
      leadCaptureWebsites: 4,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 0,
      googleMyBusiness: 0,
      socialMediaTrainingSite: 0,
    },
  },
  {
    plan: 'Platinum',
    monthly: {
      price: 399,
      licensedBooks: 25,
      digitalBooks: 25,
      printedBooks: 50,
      leadCaptureWebsites: 25,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 0,
      googleMyBusiness: 0,
      socialMediaTrainingSite: 1,
    },
    annual: {
      price: 332,
      priceYearly: 3990,
      licensedBooks: 25,
      digitalBooks: 25,
      printedBooks: 600,
      leadCaptureWebsites: 25,
      marketingTemplates: 1,
      trainingSiteAccess: 1,
      coaching: 1,
      retargeting: 0,
      googleMyBusiness: 0,
      socialMediaTrainingSite: 1,
    },
  },
];

export const handleButtonDisabled = (
  productFamilyHandle: string,
  plan: string,
  actualPlan: string,
  actualPlanCycle: string,
  selectedPlanCycle: string,
) => {
  if (productFamilyHandle === 'noncontracted') {
    switch (actualPlan) {
      case 'digital':
        if (plan === 'Digital') {
          return true;
        }
        return false;
      case 'premium':
        if (plan === 'Digital') {
          return true;
        }
        return false;
      case 'platinum':
        if (actualPlanCycle === 'monthly' && selectedPlanCycle === 'annual' && plan === 'Platinum')
          return false;
        return true;
      default:
        return true;
    }
  } else {
    switch (actualPlan) {
      case 'digital+':
        if (plan === 'Digital') {
          return true;
        }
        return false;
      case 'digital++':
        return plan !== 'digital+' && plan !== 'premium';
      case 'premium':
        return plan === 'platinum';
      case 'platinum':
        if (actualPlanCycle === 'monthly') return false;
        return true;
      default:
        return true;
    }
  }
};

export const showPlanCard = (customerPlan) => {
  let planArray;

  if (customerPlan?.type === 'noncontracted') {
    planArray = nonContractedPlans.map((plans) => {
      // Digital Plan
      if (
        customerPlan.plan === 'digital' &&
        plans.plan === 'Digital' &&
        customerPlan.cycle === 'digital'
      ) {
        const { monthly, ...rest } = plans;
        return rest;
      }
      // Premium plan
      if (customerPlan.plan === 'premium') {
        if (plans.plan === 'Digital') {
          const { monthly, annual, ...rest } = plans;
          return rest;
        }
        if (customerPlan.cycle === 'annual' && plans.plan === 'Premium') {
          const { monthly, ...rest } = plans;
          return rest;
        }
      }
      // Platinum plan
      if (customerPlan.plan === 'platinum') {
        if (plans.plan === 'Digital' || plans.plan === 'Premium') {
          const { monthly, annual, ...rest } = plans;
          return rest;
        }
        if (customerPlan.cycle === 'annual' && plans.plan === 'Platinum') {
          const { monthly, ...rest } = plans;
          return rest;
        }
      }
      return plans;
    });
  } else {
    planArray = contractedPlans.map((plans) => {
      // Digital+ Plan
      if (
        customerPlan.plan === 'digitalplus' &&
        plans.plan === 'Digital+' &&
        customerPlan.cycle === 'digitalplus'
      ) {
        const { monthly, ...rest } = plans;
        return rest;
      }
      // Premium+ plan
      if (customerPlan.plan === 'premiumplus') {
        if (plans.plan === 'Digital+') {
          const { monthly, annual, ...rest } = plans;
          return rest;
        }
        if (customerPlan.cycle === 'annual' && plans.plan === 'Premium+') {
          const { monthly, ...rest } = plans;
          return rest;
        }
      }
      // Platinum+ plan
      if (customerPlan.plan === 'platinumplus') {
        if (plans.plan === 'Digital+' || plans.plan === 'Premium') {
          const { monthly, annual, ...rest } = plans;
          return rest;
        }
        if (customerPlan.cycle === 'annual' && plans.plan === 'Platinum+') {
          const { monthly, ...rest } = plans;
          return rest;
        }
      }
      return plans;
    });
  }

  return planArray;
};

export const formatCurrency = (number, showCent = true) => {
  const num = typeof number !== 'number' ? parseFloat(number) : number;
  return num.toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
    maximumFractionDigits: showCent ? 2 : 0,
  });
};

export const injectUserPlan = (userPlan, data) => {
  return data.map((column) => {
    if (typeof column.title === 'function') {
      return {
        ...column,
        title: column.title({ userPlan }),
      };
    }
    return column;
  });
};

export const hideLesserPlans = (yearlyData, monthlyData, userPlan, isYearly, isMonthly) => {
  const isUserCurrentPlanMonthly = userPlan?.includes('monthly');
  if (isYearly) {
    let activePlanIndex;
    if (isUserCurrentPlanMonthly) {
      activePlanIndex = monthlyData.findIndex((column) => column.key === userPlan);
    } else {
      activePlanIndex = yearlyData.findIndex((column) => column.key === userPlan);
    }
    const data = yearlyData.filter((column, index) => index >= activePlanIndex || index === 0);
    return injectUserPlan(userPlan, data);
  }
  if (isMonthly) {
    const activePlanIndex = monthlyData.findIndex((column) => column.key === userPlan);
    const data = monthlyData.filter((column, index) => index >= activePlanIndex || index === 0);
    return injectUserPlan(userPlan, data);
  }
  if (isUserCurrentPlanMonthly) {
    const activePlanIndex = monthlyData.findIndex((column) => column.key === userPlan);
    const data = yearlyData.filter((column, index) => index >= activePlanIndex || index === 0);
    return injectUserPlan(userPlan, data);
  }
  return injectUserPlan(userPlan, yearlyData);
};

export const getNextMonthRenewal = () => {
  const today = dayjs();
  const nextBillingDate = today.add(1, 'month');
  return formatDate(nextBillingDate, 'MMMM D, YYYY');
};

export const getNextYearRenewal = () => {
  const today = dayjs();
  const nextBillingDate = today.add(1, 'year');
  return formatDate(nextBillingDate, 'MMMM D, YYYY');
};

export const getFlow = (currentPlan, selectedPlan) => {
  const isCurrentPlanMonthly = currentPlan && !!currentPlan.includes('monthly');
  const isSelectedPlanMonthly = !!selectedPlan.includes('monthly');

  if (isCurrentPlanMonthly && isSelectedPlanMonthly) {
    return 'plan-change';
  }
  return 'family-change';
};

export const getFeature = (features, featureHandle) => {
  return features.find((feature) => feature.handle === featureHandle);
};

export const BASIC_PLANS = 'Basic';
export const PLUS_PLANS = 'Plus';
export type PlansType = typeof BASIC_PLANS | typeof PLUS_PLANS;

export const INTERVAL_YEARLY = 'annual';
export const INTERVAL_MONTHLY = 'monthly';
export type IntervalType = typeof INTERVAL_YEARLY | typeof INTERVAL_MONTHLY;

export const PLAN_ALIAS_DIGITAL = 'Digital';
export const PLAN_ALIAS_PREMIUM = 'Premium';
export const PLAN_ALIAS_PLATINUM = 'Platinum';

export const PRINTED_BOOKS_FEATURE = 'printed_books';
export const SOCIAL_MEDIA_FEATURE = 'social_media_training_site';
