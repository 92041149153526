//

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notification } from 'modules/common/utils';
import CopyIcon from 'modules/v2/common/components/SvgIcon/CopyIcon';
import { Tooltip } from 'flowbite-react';

import S from './styles';

const Link = ({ id, title, href }) => {
  const handleCopy = () => {
    notification.success({ description: 'The link has been copied to the clipboard' });
  };

  return (
    <S.InputWithCopy>
      <S.InputWithCopy.Label htmlFor={id}>{title}</S.InputWithCopy.Label>

      <S.InputWithCopy.InputWrapper>
        <S.InputWithCopy.Input id={id} type="text" value={href} readOnly />
        <Tooltip content="Copy Link" Style="dark" className="w-[90px] text-center">
          <CopyToClipboard text={href} onCopy={handleCopy}>
            <S.InputWithCopy.Button>
              <CopyIcon />
            </S.InputWithCopy.Button>
          </CopyToClipboard>
        </Tooltip>
      </S.InputWithCopy.InputWrapper>

      <S.InputWithCopy.Link href={href} target="_blank">
        Preview
      </S.InputWithCopy.Link>
    </S.InputWithCopy>
  );
};
export default Link;
