import { Tooltip } from 'modules/common/components';

const ElementWithTooltip = ({ children, title, isEnabled, ...props }) => {
  if (!isEnabled) {
    return children;
  }

  return (
    <Tooltip {...props} title={title}>
      <div>{children}</div>
    </Tooltip>
  );
};

export default ElementWithTooltip;
