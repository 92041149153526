import React from 'react';
import { noop } from 'lodash-es';
import { ImagePlaceholder } from 'modules/common/components';
import { EditIcon } from 'modules/common/components/SvgIcon';
import { BROKER_CONTACT } from './utils';

import S from './styles';

const CoverElement = ({ showGuides, element, scale, isLoading, onClick = noop }) => {
  const { id, _id, type, name, color, fontSize, defaultValue, isEditable: allowEdit } = element;
  const innerHTML = { __html: defaultValue };
  const isEditable = allowEdit || id === BROKER_CONTACT;

  const handleClick = (e) => {
    e.preventDefault();

    if (isEditable) {
      onClick(_id, id, type);
    }
  };

  const displayGuides = isEditable && showGuides;
  const title = isEditable ? name : undefined;

  switch (type) {
    case 'image': {
      const handleImageClick = !isLoading ? handleClick : noop;

      // It is necessary to increase the scale by default for the progressbar
      const placeholderScale = scale + 2;

      return (
        <S.Image
          title={title}
          element={element}
          isLoading={isLoading}
          isEditable={isEditable}
          showGuides={displayGuides}
          onClick={handleImageClick}
        >
          {isLoading && <ImagePlaceholder scale={placeholderScale} />}
        </S.Image>
      );
    }

    case 'text': {
      // TODO: Remove when the API has fixed this part
      // Temporary fix for elements that were supposed to be hidden
      if (fontSize === null || color === 'transparent') {
        return null;
      }

      if (id === BROKER_CONTACT && (defaultValue === '<p><br/></p>' || defaultValue === '')) {
        const BlankText = () => (
          <div className="h-full w-full flex items-center justify-center relative bg-[rgba(255,255,255,0.25)]">
            <div className="absolute flex items-center top-[24px] gap-[16px] right-[20px] rounded-3xl bg-white text-neutral-800 px-[32px] py-[8px] font-figtree text-4xl border-2 border-neutral-800">
              <EditIcon width={40} height={40} color="#252525" />
              Edit
            </div>
            <p>Add your email and contact number</p>
          </div>
        );
        return (
          <S.Text
            title={title}
            element={element}
            isEditable={isEditable}
            showGuides={displayGuides}
            onClick={handleClick}
          >
            <BlankText />
          </S.Text>
        );
      }
      return (
        <S.Text
          title={title}
          element={element}
          isEditable={isEditable}
          showGuides={displayGuides}
          onClick={handleClick}
          dangerouslySetInnerHTML={innerHTML}
        />
      );
    }

    default:
      return null;
  }
};

export default React.memo(CoverElement);
