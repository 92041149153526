import { useEffect } from 'react';
import { compose } from 'redux';
import withStore from 'store/StoreContainer';

import { Empty } from 'modules/common/components';
import { clearOrders, getOrders, ordersStateSelector } from 'modules/dashboard/store';
import { clearPublish, getPublish, submitReorder, orderSelector } from 'modules/publish/store';

const withOrders =
  (Component = Empty) =>
    (props) => {
      useEffect(() => {
        props.getOrders();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return <Component {...props} />;
    };

const mapDispatchToProps = {
  clearOrders,
  clearPublish,
  getOrders,
  getPublish,
  submitReorder,
};

const mapStateToProps = (state) => ({
  orders: ordersStateSelector(state),
  reorder: orderSelector(state),
});

export default compose(withStore(mapStateToProps, mapDispatchToProps), withOrders);
