interface SuggestionCardProps {
  description: string;
  icon: React.ReactNode;
}

const SuggestionCard = ({ description, icon }: SuggestionCardProps) => {
  return (
    <div className="flex space-x-3 bg-neutral-100 px-3 py-2.5 rounded-md">
      <div className="w-5 h-5">{icon}</div>
      <div className="">{description}</div>
    </div>
  );
};

export default SuggestionCard;
