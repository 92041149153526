import { Input } from 'antd';
import { EditableCellProps } from './types';

import S from './styles';

interface Rules {
  key: string;
  type: string;
  required: boolean;
  message: string;
  validateTrigger: string[];
}

const EditableCell = ({ field, lead, formId, key }: EditableCellProps) => {
  const getRule = (fieldName: string) => {
    let rules: Rules[];

    switch (fieldName) {
      case 'email': {
        rules = [
          {
            key: 'email',
            type: 'email',
            required: true,
            message: 'It is not a valid e-mail.',
            validateTrigger: ['onChange', 'onBlur'],
          },
        ];
        break;
      }
      case 'phone': {
        rules = [
          {
            key: 'phone',
            type: 'string',
            required: true,
            message: 'It is not a valid phone number.',
            validateTrigger: ['onChange', 'onBlur'],
          },
        ];
        break;
      }

      default: {
        rules = [
          {
            key: 'phone',
            type: 'string',
            required: true,
            message: `Please input ${field}`,
            validateTrigger: ['onChange', 'onBlur'],
          },
        ];
      }
    }

    return rules;
  };

  const getInput = (fieldName: string) => {
    const parsedValue = lead[field].replace(/"/g, '');

    if (field === 'phone') {
      return (
        <S.InputMask
          maskChar={null}
          mask="999-999-9999"
          defaultValue={lead[fieldName]}
          alwaysShowMask
        />
      );
    }

    return <Input type="text" defaultValue={parsedValue} placeholder={`type your ${field}`} />;
  };

  return (
    <S.FormItem
      key={key}
      name={`${formId}.${field}`}
      style={{ margin: 0 }}
      rules={getRule(field)}
      initialValue={lead[field]}
    >
      <S.ContentInput>{getInput(field)}</S.ContentInput>
    </S.FormItem>
  );
};

export default EditableCell;
