import createStyles from 'draft-js-custom-styles';
import { get } from 'lodash-es';

export const { styles, customStyleFn, exporter } = createStyles(
  [
    'font-size',
    'color',
    'background',
    'border',
    'padding',
    'font-family',
    'text-align',
    'display',
    'line-height',
  ],
  'PREFIX',
);

export const blockStyleFn = (block) => {
  switch (block.type) {
    case 'left-align':
      return 'align-left';
    case 'center-align':
      return 'align-center';
    case 'right-align':
      return 'align-right';
    case 'justify':
      return 'justify';
    case 'border':
      return 'border';
    case 'textIndent':
      return 'textIndent';
    case 'paddingLeft':
      return 'paddingLeft';
    case 'paddingTop':
      return 'paddingTop';
    case 'paddingBottom':
      return 'paddingBottom';

    default:
      return null;
  }
};

const getAlignmentStyles = (alignment) => {
  const alignmentStyles = {
    'margin-top': '8px',
    'margin-bottom': '8px',
  };

  if (alignment === 'left') {
    alignmentStyles['margin-right'] = '8px';
    alignmentStyles.float = 'left';
  }
  if (alignment === 'right') {
    alignmentStyles['margin-left'] = '8px';
    alignmentStyles.float = 'right';
  }

  return alignmentStyles;
};

const styleObjectToString = (styleObj) =>
  Object.entries(styleObj)
    .map(([k, v]) => `${k}:${v}`)
    .join(';');

export const getOptions = (editor) => ({
  inlineStyles: exporter(editor),
  blockStyleFn: (block) => {
    switch (block.type) {
      case 'center-align':
        return {
          style: {
            'text-align': 'center',
          },
        };

      case 'left-align':
        return {
          style: {
            'text-align': 'left',
          },
        };

      case 'right-align':
        return {
          style: {
            'text-align': 'right',
          },
        };

      case 'justify':
        return {
          style: {
            'text-align': 'justify',
          },
        };

      default:
        return {};
    }
  },
  blockRenderers: {
    atomic: (block) => {
      // eslint-disable-next-line react/destructuring-assignment
      const entity = block.getEntityAt(0);

      if (!entity) return null;

      const type = editor.getCurrentContent().getEntity(entity).getType();
      const { data } = editor.getCurrentContent().getEntity(entity);

      if (type === 'IMAGE') {
        const alignmentStyles = getAlignmentStyles(data.alignment);
        const styleString = styleObjectToString(alignmentStyles);
        const dataWidth = get(data, 'width');
        const width = dataWidth > 0 ? dataWidth : 40;

        if (data.alignment === 'center') {
          return `<div style="text-align: ${data.alignment};">
            <img src="${data.src}" style="${styleString}" width="${width}%" />
          </div>`;
        }

        return `<img src="${data.src}" style="${styleString}" width="${width}%" />`;
      }

      return null;
    },
  },
});
