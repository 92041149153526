import { Typography, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import S from './styles';
import { plusPlansProductInfo } from '../ProductInfo';
import RadioItem from './RadioItem';

const { Title } = Typography;
const PlanSelector = ({ setSelectedPlan }) => {
  const { planType }: { planType: string | undefined } = useParams();
  const plansData = plusPlansProductInfo.find((item) => item.planType === planType);

  const [selected, setSelected] = useState(plansData?.data[0]?.planHandle);

  const onSelectionChange = (e) => {
    setSelected(e.target.value);
    const planData = plusPlansProductInfo.find((item) => item.planType === planType);
    const selectedPlan = planData.data.find((item) => item.planHandle === e.target.value);
    setSelectedPlan({ ...selectedPlan, ...planData });
  };

  const defaultValue = () => {
    const planData = plusPlansProductInfo.find((item) => item.planType === planType);
    const selectedPlan = planData.data.find((item) => item.planHandle === selected);
    setSelectedPlan({ ...selectedPlan, ...planData });
  };

  useEffect(() => {
    defaultValue();
  }, []);

  return (
    <S.ContainerWrap>
      <Title level={5}>Choose your billing cycle</Title>
      <Radio.Group onChange={onSelectionChange} value={selected}>
        {plansData.data.map((item) => (
          <RadioItem key={item.planHandle} item={item} selected={selected} />
        ))}
      </Radio.Group>
    </S.ContainerWrap>
  );
};

export default PlanSelector;
