import styled from 'styled-components';
import { Skeleton } from 'antd';
import * as font from 'modules/v2/common/theme/font';

const Title = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  font-family: ${font.LoraFont};
  margin-bottom: 20px;
`;

const Description = styled.span`
  width: 152px;
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MagazineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
`;

const ImageWrapper = styled.a`
  width: 152px;
  height: 200px;

  overflow: hidden;

  img {
    height: 100%;
    transition: transform 0.3s ease-in-out;
  }
  img:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* IE 9 */
    transform: scale(1.1); /* IE 9 */
  }
`;

const SkeletonImage = styled(Skeleton.Image)`
  .ant-skeleton-image {
    width: 152px;
    height: 200px;
    svg {
      display: none;
    }
  }
`;

const SkeletonInput = styled(Skeleton.Input)`
  .ant-skeleton-input {
    min-width: auto !important;
  }
`;

export default {
  Container,
  Title,
  Description,
  MagazineContainer,
  ImageWrapper,
  FlexWrap,
  SkeletonImage,
  SkeletonInput,
};
