import firstName from './firstName';
import lastName from './lastName';
import fullName from './fullName';
import phoneNumber from './phoneNumber';
import email from './email';

export default {
  firstName,
  lastName,
  fullName,
  phoneNumber,
  email,
};
