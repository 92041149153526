export const STATUS_INIT = 'STATUS_INIT';
export const STATUS_LOADING = 'STATUS_LOADING';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_ERROR = 'STATUS_ERROR';

export const isPending = (status) => [STATUS_INIT, STATUS_LOADING].indexOf(status) >= 0;
export const isReady = (status) => [STATUS_SUCCESS, STATUS_ERROR].indexOf(status) >= 0;

export const isInitial = (status) => STATUS_INIT === status;
export const isLoading = (status) => STATUS_LOADING === status;
export const isSucceeded = (status) => STATUS_SUCCESS === status;
export const isFailed = (status) => STATUS_ERROR === status;
