import {
  SAVE_DRAFT,
  MARK_COVER,
  UNMARK_COVER,
  MARK_CONTENT,
  UNMARK_CONTENT,
  MARK_PREFERENCES,
  UNMARK_PREFERENCES,
  MARK_SECTIONS,
  UNMARK_SECTIONS,
} from 'modules/editor/store/constants';
import { save as initialState } from 'modules/editor/store/reducers/initialState';
import { checkPendingState } from './utils';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SAVE_DRAFT}_REQUESTED`: {
      return {
        ...state,
        saving: true,
      };
    }

    case `${SAVE_DRAFT}_SUCCEEDED`:
    case `${SAVE_DRAFT}_FAILED`: {
      return {
        ...state,
        saving: false,
      };
    }

    case MARK_COVER: {
      const { timestamp } = action.payload;

      return {
        ...state,
        changed: {
          ...state.changed,
          cover: timestamp,
        },
        pending: true,
      };
    }

    case UNMARK_COVER: {
      const { timestamp } = action.payload;

      const saved = {
        ...state.saved,
        cover: timestamp,
      };

      const pending = checkPendingState(state.changed, saved);

      return {
        ...state,
        saved,
        pending,
      };
    }

    case MARK_CONTENT: {
      const { sectionId, timestamp } = action.payload;

      return {
        ...state,
        changed: {
          ...state.changed,
          content: {
            ...state.changed.content,
            [sectionId]: timestamp,
          },
        },
        pending: true,
      };
    }

    case UNMARK_CONTENT: {
      const { sectionId, timestamp } = action.payload;

      const saved = {
        ...state.saved,
        content: {
          ...state.saved.content,
          [sectionId]: timestamp,
        },
      };

      const pending = checkPendingState(state.changed, saved);

      return {
        ...state,
        saved,
        pending,
      };
    }

    case MARK_PREFERENCES: {
      const { timestamp } = action.payload;

      return {
        ...state,
        changed: {
          ...state.changed,
          preferences: timestamp,
        },
        pending: true,
      };
    }

    case UNMARK_PREFERENCES: {
      const { timestamp } = action.payload;

      const saved = {
        ...state.saved,
        preferences: timestamp,
      };

      const pending = checkPendingState(state.changed, saved);

      return {
        ...state,
        saved,
        pending,
      };
    }

    case MARK_SECTIONS: {
      const { timestamp } = action.payload;

      return {
        ...state,
        changed: {
          ...state.changed,
          sections: timestamp,
        },
        pending: true,
      };
    }

    case UNMARK_SECTIONS: {
      const { timestamp } = action.payload;

      const saved = {
        ...state.saved,
        sections: timestamp,
      };

      const pending = checkPendingState(state.changed, saved);

      return {
        ...state,
        saved,
        pending,
      };
    }

    default:
      return state;
  }
};

export default reducer;
