/* eslint-disable camelcase */

import { IInitialValues } from 'modules/formBuilder/types';

export const getParsedPayload = (data: IInitialValues) => {
  const {
    customer,
    billing_address,
    credit_card,
    productHandler: product_handle,
    componentId: component_id,
  } = data;
  const product = {
    product_handle,
    component_id,
  };

  const { states, ...address } = billing_address;

  const { first_name, last_name } = customer;

  const creditCardData = {
    ...credit_card,
    first_name,
    last_name,
  };

  return { customer, credit_card: creditCardData, billing_address: address, product };
};
