// utils
import { DateTime } from 'luxon';

const MINUTE_IN_MS = 60 * 1000;

export interface CalendarEvent {
  title: string;
  description: string;
  startDate: Date;
  endDate?: Date;
  durationInMinutes?: number;
  address?: string;
}

const helpers = {
  formatTime(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return`${year}${month}${day}T${hours}${minutes}${seconds}Z`;
  },

  getEventDurationForYahoo(calendarEvent: CalendarEvent) {
    const eventDuration = calendarEvent.endDate
      ? (calendarEvent.endDate.getTime() - calendarEvent.startDate.getTime()) / MINUTE_IN_MS
      : calendarEvent.durationInMinutes || 0;

    const yahooHourDuration =
      eventDuration < 600
        ? `0${Math.floor(eventDuration / 60)}`
        : `${Math.floor(eventDuration / 60)}`;

    const yahooMinuteDuration =
      eventDuration % 60 < 10 ? `0${eventDuration % 60}` : `${eventDuration % 60}`;

    const yahooEventDuration = yahooHourDuration + yahooMinuteDuration;

    return yahooEventDuration;
  },
};

export function addMinutesToDate(date: Date, minutes: number) {
  const inputFormat = 'yyyy-MM-dd HH:mm:ss';
  const inputDateString = date;
  const dateTime = DateTime.fromFormat(inputDateString.toISOString(), inputFormat);
  const duration = { minutes };
  const newDateTime = dateTime.plus(duration);
  return newDateTime;
}

export function getEndTime(calendarEvent: CalendarEvent) {
  return (
    calendarEvent.endDate ??
    addMinutesToDate(calendarEvent.startDate, calendarEvent.durationInMinutes)
  );
}

export function formatDateForCalendarUrl(date: Date) {
  const newDate = date.toISOString().replace(/-|:|\.\d+/g, '');
  return newDate;
}

export function generateGoogleCalendarUrl(calendarEvent: CalendarEvent) {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

  const encodedUrl = encodeURI(
    [
      'https://www.google.com/calendar/render',
      '?action=TEMPLATE',
      `&text=${calendarEvent.title || ''}`,
      `&dates=${startDate || ''}`,
      `/${endDate || ''}`,
      `&details=${calendarEvent.description || ''}`,
      `&location=${calendarEvent.address || ''}`,
      '&sprop=&sprop=name:',
    ].join(''),
  );

  return encodedUrl;
}

export function generateIcsCalendarFile(calendarEvent: CalendarEvent) {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

  const encodedUrl = encodeURI(
    `data:text/calendar;charset=utf8,${[
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `URL:${document.URL}`,
      `DTSTART:${startDate || ''}`,
      `DTEND:${endDate || ''}`,
      `SUMMARY:${calendarEvent.title || ''}`,
      `DESCRIPTION:${calendarEvent.description || ''}`,
      `LOCATION:${calendarEvent.address || ''}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n')}`,
  );

  return encodedUrl;
}

export function generateYahooCalendarUrl(calendarEvent: CalendarEvent) {
  const st = helpers.formatTime(calendarEvent.startDate);
  const duration = helpers.getEventDurationForYahoo(calendarEvent);

  const encodedUrl = encodeURI(
    [
      'https://calendar.yahoo.com/?v=60&view=d&type=20',
      `&title=${calendarEvent.title || ''}`,
      `&st=${st || ''}`,
      `&dur=${duration || ''}`,
      `&desc=${calendarEvent.description || ''}`,
      `&in_loc=${calendarEvent.address || ''}`,
    ].join(''),
  );

  return encodedUrl;
}
