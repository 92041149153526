export const SpreadsheetIcon = ({ width = 32, height = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Spreadsheet">
      <g id="Icon">
        <path
          d="M0.75 6.75C0.75 3.43629 3.43629 0.75 6.75 0.75H20.9216C21.452 0.75 21.9607 0.960714 22.3358 1.33579L30.6642 9.66421C31.0393 10.0393 31.25 10.548 31.25 11.0784V33.25C31.25 36.5637 28.5637 39.25 25.25 39.25H6.75C3.43629 39.25 0.75 36.5637 0.75 33.25V6.75Z"
          fill="#FAFAFA"
        />
        <path
          d="M8.75 18.75C8.75 17.6454 9.64543 16.75 10.75 16.75H21.25C22.3546 16.75 23.25 17.6454 23.25 18.75V29.25C23.25 30.3546 22.3546 31.25 21.25 31.25H10.75C9.64543 31.25 8.75 30.3546 8.75 29.25V18.75Z"
          fill="#FAFAFA"
        />
        <path
          d="M13.75 16.75V31.25M8.75 21.75H23.25M0.75 6.75C0.75 3.43629 3.43629 0.75 6.75 0.75H20.9216C21.452 0.75 21.9607 0.960714 22.3358 1.33579L30.6642 9.66421C31.0393 10.0393 31.25 10.548 31.25 11.0784V33.25C31.25 36.5637 28.5637 39.25 25.25 39.25H6.75C3.43629 39.25 0.75 36.5637 0.75 33.25V6.75ZM10.75 31.25H21.25C22.3546 31.25 23.25 30.3546 23.25 29.25V18.75C23.25 17.6454 22.3546 16.75 21.25 16.75H10.75C9.64543 16.75 8.75 17.6454 8.75 18.75V29.25C8.75 30.3546 9.64543 31.25 10.75 31.25Z"
          stroke="#069668"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </g>
  </svg>
);
