import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Skeleton as SkeletonBase } from 'antd';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 315px;
  height: 220px;

  padding: 20px;
  padding-top: 15px;

  font-family: ${font.LoraFont};

  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid ${color.LightGray};
  border-radius: 2px;
  opacity: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  margin-left: 3px;
`;

const Icon = styled.div`
  font-size: 24px;
  text-align: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
`;

const Description = styled.div`
  width: 155px;
  height: 63px;

  margin-bottom: 10px;
  font-family: ${font.RobotoFont};
  font-size: 12px;
  line-height: 16px;
`;

const MetricContainer = styled.div`
  display: flex;
  gap: 10px;

  margin-bottom: 13px;
`;

const MetricColor = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${(props) => (props.color ? props.color : color.PrimaryColor)};
  border-radius: 2px;
`;

const MetricDescription = styled.div`
  font-size: 12px;
  font-weight: bold;
`;
const MetricValue = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;
  border: 17px solid ${(props) => (props.color ? props.color : color.PrimaryColor)};
  border-radius: 50%;

  span {
    font-size: 21px;
    font-weight: bold;
    font-family: ${font.RobotoFont};
    line-height: 24px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const AddLink = styled(Link)`
  height: 20px;
  text-align: center;
  color: ${color.PrimaryColor};
`;

const Skeleton = styled(SkeletonBase)`
  width: 100%;
  height: 100%;
`;

export default {
  CardContainer,
  TitleContainer,
  Icon,
  Title,
  Description,
  MetricContainer,
  MetricColor,
  MetricDescription,
  MetricValue,
  ButtonContainer,
  AddLink,
  Skeleton,
};
