import classnames from 'classnames';
import { AuthorifyLoadingIcon, SpinnerIcon } from '../../../../../common/components/SvgIcons';

const Spinner = ({ className }) => {
  return (
    <div className={classnames('relative flex justify-center items-center w-fit', className)}>
      <div className="animate-spin">
        <SpinnerIcon />
      </div>
      <div className="absolute">
        <AuthorifyLoadingIcon />
      </div>
    </div>
  );
};

export default Spinner;
