import { DateTime } from 'luxon';
import { formatDateTime2 } from 'modules/common/utils';
import * as color from 'modules/v2/common/theme/color';

export const dateSearchOptions = [
  { value: 7, label: 'Previous week' },
  { value: 30, label: 'Previous month' },
  { value: 90, label: 'Previous 90 days' },
  { value: 120, label: 'Last quarter' },
  { value: 0, label: 'Custom' },
];

export const metricsSearchOptionsMapper = {
  Send: {
    label: 'Total email sent',
    color: color.primary500,
    description: 'Opened emails',
  },
  Open: {
    label: 'Open rate',
    color: color.success400,
    description: 'Opened emails',
  },
  Click: {
    label: 'Click rate',
    color: color.ClickRateMetric,
    description: 'Clicked on your emails',
  },
  Complaint: {
    label: 'Complaint',
    color: color.ComplaintRateMetric,
    description: 'Marked it as spam',
  },
  Bounce: {
    label: 'Bounce rate',
    color: color.yellow400,
    description: 'Failed to receive your emails',
  },
  Delivery: {
    label: 'Delivered',
    color: color.orange400,
    description: 'Received your emails',
  },
  Unsubscribed: {
    label: 'Unsubscribed rate',
    color: color.danger400,
    description: 'Opted out of your emails',
  },
};

export const metricsSearchOptions = [
  { value: 'Send', label: 'Total emails sent', color: color.SentEmailsMetric },
  { value: 'Open', label: 'Open rate', color: color.OpenRateMetric },
  { value: 'Click', label: 'Click rate', color: color.ClickRateMetric },
  { value: 'Complaint', label: 'Complaint', color: color.ComplaintRateMetric },
  { value: 'Bounce', label: 'Bounce rate', color: color.BounceRateMetric },
  { value: 'Delivery', label: 'Delivered', color: color.DeliveryRateMetric },
  { value: 'Unsubscribed', label: 'Unsubscribed rate', color: color.UnsubscribedMetric },
];

export const showTableLines = [
  { value: '15', label: '15 items' },
  { value: '20', label: '20 items' },
  { value: '50', label: '50 items' },
];

const getIsRising = (current, previous) => {
  return current >= previous ?? false;
};

const getRiseOrFallValue = (current, previous) => {
  const percentage = previous ? (Math.abs(current - previous) / previous) * 100 : 0;
  return percentage ?? 0;
};

const getRate = (current, total) => {
  const percentage = total ? (current / total) * 100 : 0;
  return percentage ?? 0;
};

// TO DO: Refactor this function to use the metricsSearchOptionsMapper object
export const RateCardsFormatter = (data) => {
  const isDataUndefined = !data?.total?.current || !data?.total?.previous;
  const emptyData = {
    Send: 0,
    Open: 0,
    Click: 0,
    Bounce: 0,
    Delivery: 0,
    Unsubscribed: 0,
  };
  const { current } = isDataUndefined ? { current: emptyData } : data.total;
  const { previous } = isDataUndefined ? { previous: emptyData } : data.total;

  const totalSendValue = current.Send;
  const totalSendIsRising = getIsRising(current.Send, previous.Send);

  const openRateValue = getRate(current.Open, totalSendValue);
  const openRateTotal = current.Open;
  const openRateIsRising = getIsRising(current.Open, previous.Open);
  const openRateRiseOrFallValue = getRiseOrFallValue(current.Open, previous.Open);

  const clickRateValue = getRate(current.Click, totalSendValue);
  const clickRateTotal = current.Click;
  const clickRateIsRising = getIsRising(current.Click, previous.Click);
  const clickRateRiseOrFallValue = getRiseOrFallValue(current.Click, previous.Click);

  const bounceRateValue = getRate(current.Bounce, totalSendValue);
  const bounceRateTotal = current.Bounce;
  const bounceRateIsRising = getIsRising(current.Bounce, previous.Bounce);
  const bounceRateRiseOrFallValue = getRiseOrFallValue(current.Bounce, previous.Bounce);

  const unsubscribeRateValue = getRate(current.Bounce, totalSendValue);
  const unsubscribeRateTotal = current.Bounce;
  const unsubscribeRateIsRising = getIsRising(current.Bounce, previous.Bounce);
  const unsubscribeRateRiseOrFallValue = getRiseOrFallValue(current.Bounce, previous.Bounce);

  return [
    {
      statTitle: metricsSearchOptionsMapper.Send.label,
      subText: metricsSearchOptionsMapper.Send.description,
      color: metricsSearchOptionsMapper.Send.color,
      isRising: totalSendIsRising,
      total: totalSendValue,
    },
    {
      statTitle: metricsSearchOptionsMapper.Open.label,
      subText: metricsSearchOptionsMapper.Open.description,
      color: metricsSearchOptionsMapper.Open.color,
      rateValue: openRateValue,
      total: openRateTotal,
      isPercent: true,
      isRising: openRateIsRising,
      riseOrFallValue: openRateRiseOrFallValue,
      positiveMetric: true,
    },
    {
      statTitle: metricsSearchOptionsMapper.Click.label,
      subText: metricsSearchOptionsMapper.Click.description,
      color: metricsSearchOptionsMapper.Click.color,
      rateValue: clickRateValue,
      total: clickRateTotal,
      isPercent: true,
      isRising: clickRateIsRising,
      riseOrFallValue: clickRateRiseOrFallValue,
      positiveMetric: true,
    },
    {
      statTitle: metricsSearchOptionsMapper.Unsubscribed.label,
      subText: metricsSearchOptionsMapper.Unsubscribed.description,
      color: metricsSearchOptionsMapper.Unsubscribed.color,
      rateValue: unsubscribeRateValue,
      total: unsubscribeRateTotal,
      isPercent: true,
      isRising: unsubscribeRateIsRising,
      riseOrFallValue: unsubscribeRateRiseOrFallValue,
      positiveMetric: false,
    },
    {
      statTitle: metricsSearchOptionsMapper.Bounce.label,
      subText: metricsSearchOptionsMapper.Bounce.description,
      color: metricsSearchOptionsMapper.Bounce.color,
      rateValue: bounceRateValue,
      total: bounceRateTotal,
      isPercent: true,
      isRising: bounceRateIsRising,
      riseOrFallValue: bounceRateRiseOrFallValue,
      positiveMetric: false,
    },
  ];
};

export const findMetricItem = (item) => {
  return metricsSearchOptionsMapper[item];
};

export const sortPeriod = (a, b) => {
  return new Date(a.period) - new Date(b.period);
};

export const sortNumericValues = (a, b, key) => {
  const valueA = a[key] || 0;
  const valueB = b[key] || 0;
  return valueA - valueB;
};

export const formatCampaignMetrics = (data) => {
  const formattedMetrics = [];
  if (data.campaign) {
    const campaignName = data.campaign.name;
    data.metrics.forEach((metric) => {
      metric.events.forEach((event) => {
        const createDate = DateTime.fromISO(event.createdAt).toFormat('ff');
        formattedMetrics.push({
          'Campaign Name': campaignName,
          'Event Type': event.type,
          From: event.from,
          To: event.to,
          Timestamp: `"${createDate}"`,
        });
      });
    });
  }
  return formattedMetrics;
};

export const csvDownloader = (data, fileName, escapedHeaders) => {
  return new Promise((resolve, reject) => {
    try {
      const headers = Object.keys(data[0]);
      const csvContent = `data:text/csv;charset=utf-8,${[headers.join(',')]
        .concat(
          data.map((row) =>
            headers
              .map((header) => {
                if (escapedHeaders.includes(header)) {
                  return `"${row[header]}"`;
                }
                return row[header];
              })
              .join(','),
          ),
        )
        .join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${fileName}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const populateChartData = (previousData, dateRange, optionSelected) => {
  const startDateUTC = new Date(dateRange[0]);
  const endDateUTC = new Date(dateRange[1]);

  const columnData = [];
  const lineData = [];
  let hasData = false;

  const columnPeriod = new Map(
    previousData[0].map((item) => {
      return [new Date(item.period).toDateString(), item];
    }),
  );

  const lineDataAux = [];
  previousData[1].map((item) => {
    optionSelected.forEach((option) => {
      if (option === item.name) {
        lineDataAux.push([new Date(item.period).toDateString(), item]);
      }
    });
    return lineDataAux;
  });

  const findLineItems = (name, date) => {
    return lineDataAux.find((item) => {
      if (item[0] === date && item[1].name === name) {
        hasData = true;
        return item[0] === date && item[1].name === name;
      }
      return false;
    });
  };

  const currentDate = new Date(startDateUTC);
  while (currentDate < endDateUTC) {
    const currentDateStr = currentDate.toDateString();

    const existingColumnData = columnPeriod.get(currentDateStr);
    if (existingColumnData) {
      hasData = true;
      columnData.push(existingColumnData);
    } else {
      columnData.push({ period: formatDateTime2(currentDate), Send: 0 });
    }

    optionSelected.forEach((option) => {
      if (option === 'Send') return;
      const findItem = findLineItems(option, currentDateStr);
      if (findItem && findItem[0] === currentDateStr) {
        lineData.push(findItem[1]);
      } else {
        lineData.push({ name: option, count: 0, period: formatDateTime2(currentDate) });
      }
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }
  columnData.sort(sortPeriod);
  lineData.sort(sortPeriod);

  return { columnData, lineData, hasData };
};
