import { call, race, take, takeEvery } from 'redux-saga/effects';
import { notification } from 'modules/common/utils';

import { fetchPreFlightCheck, getError, resource } from 'modules/api';

import { CANCEL_PREFLIGHT_CHECK, GET_PREFLIGHT_CHECK } from 'modules/draft/store/constants';
import { clearPreflightCheck } from 'modules/draft/store';

export function* onGetPreflightCheck({ payload: meta }) {
  try {
    const request = resource(GET_PREFLIGHT_CHECK, fetchPreFlightCheck);
    const payload = yield call(request, meta);

    return payload;
  } catch (error) {
    const payload = getError(error);

    notification.error({ description: 'Something went wrong while pre-flight check' });

    // eslint-disable-next-line no-console
    console.error(payload);

    return payload;
  }
}

export function* watchCancellation(action) {
  const [cancelled] = yield race([take(CANCEL_PREFLIGHT_CHECK), call(onGetPreflightCheck, action)]);

  if (cancelled) {
    clearPreflightCheck();
  }
}

export function* watchGetPreflightCheck() {
  yield takeEvery(GET_PREFLIGHT_CHECK, watchCancellation);
}
