import { map } from 'lodash-es';

export const formatCampaigns = (campaigns) => {
  return map(campaigns, (campaign) => {
    let segmentsValue = 'All Segments';

    const {
      content: { emails: emailsArr, name: contentName },
      allSegments,
      segments,
      ...others
    } = campaign;

    if (!allSegments) {
      segmentsValue = map(segments, ({ name }) => name).join(', ');
    }

    return {
      segments: segmentsValue,
      emails: emailsArr.length,
      contentName,
      ...others,
    };
  });
};

export const formatStatus = (status) => {
  if (status === 'active') {
    return 'Current version';
  }
  return 'Old version';
};
