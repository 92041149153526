import styled from 'styled-components';
import { Typography } from 'antd';
import * as color from 'modules/v2/common/theme/color';

const { Title } = Typography;

const Container = styled.div`
  width: 100%;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-top: 24px;
  }
`;

const CreditContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  border: 1px solid ${color.GrayBorderColor};
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const NewCardLink = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;
  color: ${color.PhoneLink};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Line = styled.hr`
  width: 100vw;
  margin-left: -30px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  background-color: ${color.GrayBorderColor};
  color: ${color.GrayBorderColor};
  border: none;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ModalDescription = styled(Title)`
  font-weight: 800;
`;

export default {
  NewCardLink,
  Container,
  ModalDescription,
  Line,
  CreditContainer,
  LeftContent,
};
