//

import * as color from 'modules/common/theme/color';
import CheckmarkIcon from 'modules/common/components/SvgIcon/CheckmarkIcon';
import S from './styles';

const ConfirmComponent = ({ heading, subHeading, imgWidth = '450px', iconSize = '50px' }) => (
  <S.ScheduleWebinar imgWidth={imgWidth}>
    <CheckmarkIcon width={iconSize} height={iconSize} fill={color.DefaultBlue} />
    <S.ScheduleWebinarHeading>{heading}</S.ScheduleWebinarHeading>
    <S.ScheduleWebinarSubHeading>{subHeading}</S.ScheduleWebinarSubHeading>
  </S.ScheduleWebinar>
);

export default ConfirmComponent;
