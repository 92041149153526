import React from 'react';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { CalendarIcon } from 'modules/v2/common/components/SvgIcon';
import { cn } from 'modules/v2/common/utils/cn';
import AddToCalendarButtonTooltip from './AddToCalendarButtonTooltip';
import { CalendarEvent } from './utils';

interface AddToCalendarButtonProps {
  calendarEvent: CalendarEvent;
  windowSize: number;
  className?: string;
  buttonClassName?: string;
  isTooltipVisible: boolean;
  handleClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export default function AddToCalendarButton({
  calendarEvent,
  windowSize,
  className = '',
  buttonClassName = '',
  isTooltipVisible,
  handleClick,
}: AddToCalendarButtonProps) {
  return (
    <>
      <div
        className={cn('add-to-calendar-wrapper relative w-full', className, {
          'w-full': windowSize < 1920,
        })}
      >
        <Button
          type="outlined"
          className={cn('add-to-calendar-button w-full', buttonClassName)}
          onClick={handleClick}
          wrapperClassName={className}
          full={windowSize < 1920}
        >
          <CalendarIcon />
          <span className="mt-0.5 ml-2 text-sm font-semibold">Add to calendar</span>
        </Button>
        {isTooltipVisible && <AddToCalendarButtonTooltip calendarEvent={calendarEvent} />}
      </div>
    </>
  );
}
