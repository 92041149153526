import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const FooterWrap = styled.div`
  padding: 8px;
  color: ${color.White};
  font-size: 12px;
  background-color: ${color.GreySelectDd};
  text-align: center;
`;

export default {
  FooterWrap,
};
