import { spawn } from 'redux-saga/effects';

import { watchClearDigitalBook } from './clearDigitalBook';
import { watchGenerateDigitalBook } from './generateDigitalBook';
import { watchGenerateDraftLinks } from './generateDraftLinks';
import { watchGetDraftLinks } from './getDraftLinks';

export default function* saga() {
  yield spawn(watchClearDigitalBook);
  yield spawn(watchGenerateDigitalBook);
  yield spawn(watchGenerateDraftLinks);
  yield spawn(watchGetDraftLinks);
}
