import { stringify } from 'qs';
import getInstance from './instance';

const instance = getInstance();

export const fetchAllBooks = (queryParams = {}) => {
  const stringQueryParams = stringify(queryParams, {
    encodeValuesOnly: true,
  });
  const url = `/book/new_category/?${stringQueryParams}`;

  return instance.get(url);
};

export const fetchBookById = ({ bookId }) => {
  const url = `/book/getdefaultbook/${bookId}`;

  return instance.get(url);
};

export const fetchPresetLogos = () => {
  const url = '/book/logos';

  return instance.get(url);
};

export const generateDraftFromBook = ({ bookId }) => {
  const url = `/book/${bookId}/generate-draft`;

  return instance.post(url);
};

export const getBookSortOrder = () => {
  const url = '/book/getSortOrder';
  return instance.get(url);
};

export const getFlippingBookCanonicalLinkByBookId = async ({ bookId }) => {
  const url = `/book/fbonline/get-canonical-link-by-book-id/${bookId}`;
  try {
    const res = await instance.get(url);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateDraftPosition = ({ payload }) => {
  return instance
    .patch('/draft/update-book-position', payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};
