import axios from 'axios';
import qs from 'query-string';

import env from 'environments';

const instance = axios.create({
  baseURL: env.DIGITAL_INTEGRATION_SERVICES_URL,
  paramsSerializer: qs.stringify,
});

const getInstance = () => instance;

export default getInstance;
