/**
 * APP Theme
 */
import { inlineBlock, displayFlex } from 'modules/common/theme/function';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';
import { createGlobalStyle } from 'styled-components';

const AppStyle = createGlobalStyle`
  font-family: ${font.FigtreeFont};
  font-size: 14px;
  line-height: 19px;
  color: ${color.Body};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0px;
  }

  a {
    text-decoration: none;
    outline: 0;

    &:focus,
    &:selected,
    &:active,
    &:hover,
    &:visited {
      text-decoration: none;
      outline: 0;
    }
  }

  .inline--block {
    ${inlineBlock()};
  }

  .display--flex {
    ${displayFlex()};
  }

  .hvr-underline-from-left {
    display: flex;
    transform: perspective(1px) translateZ(0);
    box-shadow: none;

    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      right: 100%;
      bottom: 0;
      background: #ffffff;
      height: 4px;
      transition-property: right;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    &:hover {
      &::before {
        right: 0;
      }
    }
  }

  .text--center {
    text-align: center;
  }

  .container {
    max-width: 1326px;
    margin: 0 auto;
    padding: 0 20px;
  }

  h2 {
    font-size: 20px;
    line-height: 27px;
  }

  h6 {
    font-size: 16px;
    line-height: 22px;
  }

  .ant-form {
    .ant-form-item {
      margin-bottom: 11px;
    }

    .ant-form-item-label {
      display: block;
      line-height: 30px;
      text-align: left;

      label {
        color: ${color.GreySelectDd};
        font-weight: bold;
        line-height: 24px;
        display: block;

        &::after {
          display: none;
        }
      }
    }

    .ant-input {
      height: 40px;
      border: 1px solid ${color.gray200};
      border-radius: 3px;
      background-color: ${color.White};
    }
  }

  .ant-checkbox-wrapper,
  label,
  .ant-form-item,
  .ant-select {
    font-family: ${font.FigtreeFont};
  }

  .ant-checkbox-wrapper {
    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background: ${color.Blue};
          border-radius: 4px;

          &:after {
            border: 2px solid ${color.White};
            border-top: 0;
            border-left: 0;
          }
        }

        &:after {
          display: none;
          animation: none;
        }
      }
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    background: ${color.Blue};
    border: 1px solid ${color.grayCheckBox};
    border-radius: 2px;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${color.grayCheckBox};
    background: none;
  }

  tr.ant-table-row-selected {
    background: ${color.TableRowSelected} !important;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .ant-btn {
    border-radius: 4px;
    font-weight: 700;
    font-family: ${font.FigtreeFont};
    transition: all 0.2s;
    padding: 0 20px;
    outline: 0 !important;
    box-shadow: none !important;

    &::after {
      display: none !important;
    }

    &:hover {
      opacity: 0.9;
    }

    &.formBtn {
      display: block;
      width: 100%;
    }

    &.height--small {
      height: 40px;
    }

    &.ant-btn-primary {
      color: ${color.White};
      background-color: ${color.PrimaryColor};
      border-color: ${color.PrimaryColor};
      text-shadow: none;
      box-shadow: none;
      font-family: ${font.FigtreeFont};
      letter-spacing: 1px;

      &:hover {
        color: ${color.White};
        background-color: ${color.PrimaryColor};
        border-color: ${color.PrimaryColor};
      }

      &:focus {
        color: ${color.White};
        background-color: ${color.PrimaryColor};
        border-color: ${color.PrimaryColor};
      }
    }

    &.ant-btn-cancel {
      color: ${color.GreySelectDd};
      background-color: ${color.GreyFormBorder};
      border-color: ${color.GreyFormBorder};
      text-shadow: none;
      box-shadow: none;
      font-family: ${font.FigtreeFont};
      letter-spacing: 1px;

      &:hover {
        color: ${color.GreySelectDd};
        background-color: ${color.GreyFormBorder};
        border-color: ${color.GreyFormBorder};
      }

      &:focus {
        color: ${color.GreySelectDd};
        background-color: ${color.GreyFormBorder};
        border-color: ${color.GreyFormBorder};
      }
    }

    &.ant-btn-bordered {
      border: 2px solid ${color.White};
      background: transparent;
      height: 45px;
      line-height: 41px;
      color: ${color.White};
      padding-left: 20px;
      padding-right: 20px;
    }

    &.btnLink {
      border: 0;
      color: ${color.PrimaryColor};
      background: none;
    }
  }


  .editorWrapper .toolbarIconsWrap {
    width: calc(100% - 200px);
    left: 200px;
  }

  .toolbarIconsWrap {
    position: fixed;
    z-index: 99999;
    background: #F5F9FD;
    padding: 10px;
    bottom: 0;
    left: 0;
    width: 100%;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;

      li {
        display: inline-block;
        vertical-align: middle;

        a {
          height: 30px;
          font-weight: 700;
        }

        &.mainIcon {
          background-color: ${color.White};
          height: 32px;
          margin: 0 10px;
          border: 1px solid #E6E6E6;
          border-radius: 3px;

          .icon_inner {
            cursor: pointer;
          }

          svg {
            position: relative;
            top: 1px;
            fill: ${color.GreySelectDd};
          }

          > div {
            display: inline-block;
            vertical-align: middle;

            > div {
              display: inline-block;
              vertical-align: middle;
              line-height: 43px;
              width: 45px;
            }
          }

          .zoom_input {
            width: auto;

            .input-group {
              position: relative;
              top: -2px;
            }

            input {
              border: 1px solid ${color.GreyDivider};
              background-color: ${color.GreyMenuItemHover};
              height: 32px;
              display: inline-block;
              vertical-align: middle;
              width: 50px;
            }

            h6 {
              display: inline-block;
              vertical-align: middle;
              margin: 0;
              padding: 0 0 0 5px;
              color: ${color.GreySelectDd};
            }
          }

          .fullscreen_icon {
            border-right: 1px solid #E6E6E6;
            line-height: 30px;
            width: 35px;

            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }

  p {
    // Should not be here
    margin: 0;
  }

  .stepsWrap {
    width: 620px;
    margin: 0 auto;

    ul {
      margin: 0;padding: 0;
      list-style: none;

      li {
        position: relative;
        width: 25%;
        float: left;
        text-align: center;

        &::after {
          content: '';
          position: absolute;
          top: 8px;
          left: calc(50% + 18px);
          height: 3px;
          width: calc(100% - 36px);
          background: #FFAB40;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        .dot {
          width: 36px;
          height: 36px;
          position: relative;
          margin: 0 auto;

          span {
            background: #FFAB40;
            border-radius: 50%;
            margin: 0 auto;
            width: 20px;
            height: 20px;
            font-weight: 700;
            font-size: 14px;
            color: #fff;
            display: block;
            line-height: 20px;
            text-align: center;

            &::after {
              content: '';
              border: 3px solid #FFAB40;
              border-radius: 50%;
              width: 36px;
              height: 36px;
              position: absolute;
              top: -8px;
              left: 0px;
            }
          }
        }

        .text {margin: 10px 0 0 0;
          span {
            color: #2E3F50
            font-weight: 600;
            font-size: 14px;
            margin-top: 12px;
          }
        }
      }

      &::after {
        content: '';
        clear: both;
        display: block;
      }
    }
  }

  .editorWrapperFullscreen .mainEditor {
    height: 100vh;
  }

  h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 36px;
    padding-bottom: 20px;
  }

  h6 {
    font-family: ${font.FigtreeFont};
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 26px;
    padding-bottom: 10px;
  }

  h3 {
    font-family: ${font.FigtreeFont};
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 30px;
    padding-bottom: 5px;
    font-weight: 800;
    margin: 0;
  }

  h4 {
    font-family: ${font.FigtreeFont};
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    line-height: 30px;
  }

  h5 {
    font-family: ${font.FigtreeFont};
  }

  h6 {
    font-family: ${font.FigtreeFont};
  }

  .resizebtn {
    height: 32px !important;
  }
`;

export default AppStyle;
