import styled from 'styled-components';
import { getStyle } from 'modules/editor/utils';

const Wrapper = styled.div`
  top: 250px;
  left: 0;
  width: 100%;
  letter-spacing: 0;

  ${({ element, showGuides, scale }) => {
    const {
      margin,
      padding,
      lineHeight,
      fontFamily,
      fontWeight,
      fontSize,
      textAlign,
      order,
      border,
    } = getStyle(element, scale);

    return `
      font-family: ${fontFamily};
      font-size: ${fontSize};
      padding: ${padding};
      text-align: ${textAlign};
      color: black;
      margin: ${margin};
      order: ${order};
      font-weight: ${fontWeight};
      line-height: ${lineHeight};
      border: ${border};

      ${
  !showGuides &&
        `
          border-color: transparent;
        `
}
    `;
  }}

  .align-right {
    &,
    & div {
      text-align: right !important;
    }

    span {
      display: initial !important;
    }
  }

  .align-center {
    &,
    & div {
      text-align: center !important;
    }

    span {
      display: initial !important;
    }
  }

  .align-left {
    &,
    & div {
      text-align: left !important;
    }

    span {
      display: initial !important;
    }
  }

  .justify {
    &,
    & div {
      text-align: justify !important;
      text-align-last: left;
    }

    span {
      display: initial !important;
    }
  }

  .DraftEditor-editorContainer,
  .DraftEditor-root,
  .public-DraftEditor-content {
    text-align: inherit !important;
  }
`;

export default {
  Wrapper,
};
