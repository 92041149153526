import { useState, useRef, useEffect } from 'react';
import { useMutation } from 'react-query';
import { PrimaryButton, Loader } from 'modules/common/components';
import { notification } from 'modules/common/utils';
import { updateDraftPosition } from 'modules/api/books';
import { getRouteDraftGenerateByBookId } from 'modules/draft/routes/navigation';
import { DragAndRearrangeProps } from './types';
import { useGetBookPreviews } from '../admin/BookPreviews/hooks';
import BookPreview from './components/BookPreview';

import S from './styles';

export const DragAndRearrange = ({ books }: DragAndRearrangeProps) => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [bookList, setBookList] = useState(books);
  const [showSortButton, setShowSortButton] = useState(false);
  const [bookPreviews, setBookPreviews] = useState([]);

  useEffect(() => {
    setBookList(books);
  }, [books]);

  useGetBookPreviews({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    onSuccess: (response) => setBookPreviews(response?.data?.data),
    onError: (error) => {
      notification.error({
        title: 'Error',
        description: 'there was an error, please try again later',
      });
      throw new Error(error.message);
    },
  });

  const { mutateAsync: updateDraftPositions, isLoading: isBookSortOrderUpdating } = useMutation(
    updateDraftPosition,
    {
      onSuccess: (res) => {
        notification.success({
          description: 'Books Sort Order Updated Successfully',
        });
        setShowSortButton(false);
      },
    },
  );

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...bookList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setBookList(copyListItems);
    setShowSortButton(true);
  };

  const handlePosition = () => {
    const reArrangedArray = [];
    bookList.map((item, index) => {
      const obj = {
        bookId: item._id,
        position: index,
      };
      reArrangedArray.push(obj);
    });
    updateDraftPositions({
      payload: {
        bookSortOrderArray: reArrangedArray,
      },
    });
  };

  if (isBookSortOrderUpdating) {
    return (
      <Loader
        height="75vh"
        title="Updating Book Sort order"
        hasIcon={false}
        description="Please wait while we update the sort order."
      />
    );
  }

  return (
    <div>
      {showSortButton && (
        <S.ButtonWrapper>
          <PrimaryButton key="submit" onClick={handlePosition}>
            Save
          </PrimaryButton>
        </S.ButtonWrapper>
      )}

      <S.BookWrapper>
        {bookList &&
          bookList.map((book, index) => (
            <div
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              onDragEnd={drop}
              key={book?._id}
              draggable
            >
              <BookPreview
                book={book}
                bookPreviews={bookPreviews}
                linkUrl={getRouteDraftGenerateByBookId(book?._id)}
                key={book.name}
              />
            </div>
          ))}
      </S.BookWrapper>
    </div>
  );
};
