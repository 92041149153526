import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const ListWrapper = styled.div`
  border: 1px solid ${color.TitleBorder};
  border-radius: 3px;
  padding: 30px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  text-align: center;

  button {
    font-size: 16px !important;
    height: 38px;
    line-height: 38px;
    min-width: 200px;
  }
`;

export default {
  ButtonWrapper,
  ListWrapper,
};
