import { useRef, useEffect } from 'react';

import { Formik, Form } from 'formik';

import { Loader } from 'modules/v2/common/components';
import { notification } from 'modules/v2/common/utils';

import buildConfig from './chargifyFields';
import S from './styles';

const AddPaymentProfileForm = ({ onSave, isLoading, onCancel }) => {
  const chargifyForm = useRef();
  const chargify = useRef(new window.Chargify());
  const config = buildConfig('card');

  useEffect(() => {
    const chargifyRef = chargify.current;
    chargifyRef.load(config, { onThreeDsConfigError: console.error }); // eslint-disable-line no-console

    return () => {
      chargifyRef.unload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargify]);

  const handlePayment = () => {
    try {
      chargify.current.token(
        chargifyForm.current,
        async (token) => {
          const payload = {
            chargifyToken: token,
          };
          return onSave(payload);
        },
        (error) => {
          notification.error({
            description: error?.errors || 'Something went wrong, please try after sometime',
          });
        },
      );
      return false;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      onCancel();
    }
    return false;
  };

  if (isLoading) {
    return (
      <S.Wrapper>
        <Loader height="40px" hasIcon={false} description="Please wait creating payment profile" />
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.FormContainer>
        <Formik validateOnChange={false} initialValues={{}} onSubmit={handlePayment}>
          {({ handleSubmit }) => {
            return (
              <S.FormContainer>
                <Form onSubmit={handleSubmit} ref={chargifyForm}>
                  <S.FieldControl>
                    <S.FieldGroup>
                      <S.CreditCardInput id="chargify1" />
                      <S.CreditCardInput id="chargify2" />
                    </S.FieldGroup>
                  </S.FieldControl>
                  <S.FieldControl>
                    <S.FieldGroup>
                      <S.CreditCardInput id="chargify3" />
                      <S.CreditCardInput id="chargify4" />
                    </S.FieldGroup>
                  </S.FieldControl>

                  <S.FieldGroup>
                    <S.Button onClick={handleSubmit}>Add Card</S.Button>
                  </S.FieldGroup>
                  <S.FieldControl>
                    <S.FieldGroup>
                      <S.CancelButton block type="text" onClick={onCancel}>
                        Cancel
                      </S.CancelButton>
                    </S.FieldGroup>
                  </S.FieldControl>
                </Form>
              </S.FormContainer>
            );
          }}
        </Formik>
      </S.FormContainer>
    </S.Wrapper>
  );
};

export default AddPaymentProfileForm;
