import { GET_BOOK } from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_BOOK}_REQUEST`:
      return { ...state, loading: true };

    case `${GET_BOOK}`:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data.data.book,
      };

    case `${GET_BOOK}_FAILURE`:
      return { ...state, loaded: true };

    default:
      return { ...state };
  }
};
