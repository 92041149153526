import styled from 'styled-components';

const Address = styled.div`
  margin: 10px 0 8px 2.6rem;
  border: 1px solid #efefef;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 10px;
  font-size: 16px;

  p {
    margin: 0;
    padding: 0;
    strong {
      display: block;
    }
  }
`;

const ItemWrapper = styled.div`
  > div {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export default {
  Address,
  ItemWrapper,
};
