import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const CongratsModalWrapper = styled.div`
}`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
`;

const ModalTitle = styled.h3`
  font-size: 25px;
  font-weight: 600;
  color: ${color.Black};
  margin-bottom: 20px;
`;

export default {
  CongratsModalWrapper,
  ModalHeader,
  ModalTitle,
};
