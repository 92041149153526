import React, { useEffect, useState } from 'react';
import { Toast, CustomFlowbiteTheme } from 'flowbite-react';
import { CheckIconNew, InfoIconNew, CloseCircleIcon } from 'modules/v2/common/components/SvgIcon';

interface NotificationProps {
  type: 'success' | 'error' | 'info' | 'none';
  description: string | React.ReactNode;
  icon?: boolean;
}

const defaultProps: Partial<NotificationProps> = {
  icon: true,
};

const customTheme: CustomFlowbiteTheme['toast'] = {
  root: {
    base:
      'flex w-full max-w-xs items-center rounded-lg bg-neutral-800 px-3.5 py-2.5 text-neutral-50 shadow dark:bg-neutral-800 dark:text-neutral-400 absolute bottom-8 left-1/2 transform -translate-x-1/2 transition-transform duration-500 transform translate-y-full',
  },
  toggle: {
    base: 'hidden',
  },
};

const Notification = ({ type = 'success', description, icon }: NotificationProps) => {
  const [showNotification, setShowNotification] = useState(true);

  useEffect(() => {
    let timeoutId;
    if (showNotification) {
      timeoutId = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
    return () => clearTimeout(timeoutId);
  }, [showNotification]);

  const iconImage = () => {
    if (icon) {
      switch (type) {
        case 'success':
          return (
            <div className="bg-success-500 p-1 rounded-full">
              <CheckIconNew fill="#ffffff" />
            </div>
          );
        case 'error':
          return (
            <div className="bg-error-500 p-1 rounded-full">
              <CloseCircleIcon fill="#ffffff" />
            </div>
          );
        case 'info':
          return (
            <div className="bg-yellow-500 p-1 rounded-full">
              <InfoIconNew fill="#ffffff" />
            </div>
          );
        case 'none':
          return null;
        default:
          break;
      }

      return null;
    }
  };

  return (
    <>
      {showNotification && (
        <Toast theme={customTheme}>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg">
            {iconImage()}
          </div>
          <div className="ml-3 text-sm font-normal">{description}</div>
          <Toast.Toggle />
        </Toast>
      )}
    </>
  );
};

Notification.defaultProps = defaultProps;

export default Notification;
