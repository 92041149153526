import { withRouter } from 'react-router';
import { Menu } from 'antd';

import {
  getRouteBookCatalog,
  getRouteMyOrders,
  getRouteBookPerformance,
} from 'modules/dashboard/routes/navigation';
import { NavLink, NavItem } from 'modules/common/components/UIComponents';

const MyBooksSideNav = ({ location }) => {
  return (
    <Menu
      mode="inline"
      selectedKeys={location.pathname.split('/')}
      defaultOpenKeys={location.pathname.split('/')}
    >
      <NavItem key="book-catalog">
        <NavLink to={getRouteBookCatalog()}>
          <span>Book catalog</span>
        </NavLink>
      </NavItem>
      <NavItem key="my-orders">
        <NavLink to={getRouteMyOrders()}>
          <span>Ordered books</span>
        </NavLink>
      </NavItem>
      <NavItem key="book-performance">
        <NavLink to={getRouteBookPerformance()}>
          <span>Book Performance Analytics</span>
        </NavLink>
      </NavItem>
    </Menu>
  );
};

export default withRouter(MyBooksSideNav);
