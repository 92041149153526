import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter your Name'),
  email: Yup.string()
    .trim()
    .required('Please enter your Email')
    .email('Email is not valid'),
  phone: Yup.string()
    .trim()
    .required('Please confirm your phone')
    .min(10, 'Phone number must be at least 10 characters long')
    .max(17, 'Phone number must be at most 17 characters long')
    .matches(/^(\+?1[ -]?)?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4,})( x\d{4})?$/, {
      message: 'Please enter a valid Phone Number',
      excludeEmptyString: false,
    }),
});

export default validationSchema;
