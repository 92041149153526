import { call, put, select, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';

import { uploadUserImage, resource, getError } from 'modules/api';
import { UPLOAD_COVER_IMAGE } from 'modules/editor/store/constants';
import {
  draftIdSelector,
  coverElementByIdSelector,
  addLoadingCoverElements,
  removeLoadingCoverElements,
  replaceCoverImage,
} from 'modules/editor/store';
import { notification } from 'modules/common/utils';
import { getImageTypeByElementId } from 'modules/editor/utils';

export function* onUploadCoverImage({ payload: meta }) {
  const { elementId } = meta;
  const selector = coverElementByIdSelector(elementId);
  const { id } = yield select(selector);

  try {
    const { templateId, url, group, imageFormat } = meta;
    const draftId = yield select(draftIdSelector);

    const filename = `${draftId}-${id}`;

    let type = getImageTypeByElementId(id);

    yield put(addLoadingCoverElements(id));
    if (type === 'book_profile') {
      type = 'profile';
    }
    const request = resource(UPLOAD_COVER_IMAGE, uploadUserImage);
    const payload = yield call(request, {
      type,
      filename,
      data: url,
      imageFormat,
    });

    const imageId = get(payload, '_id');

    yield put(replaceCoverImage(templateId, elementId, imageId, group));
    yield put(removeLoadingCoverElements(id));

    notification.success({
      title: 'Image uploaded',
      description: 'Cover photo has changed successfully',
    });

    return payload;
  } catch (error) {
    const payload = getError(error);

    yield put(removeLoadingCoverElements(id));

    notification.error({
      title: 'Failed uploading image',
      description: 'Cover photo could not be uploaded',
    });

    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchUploadCoverImage() {
  yield takeEvery(UPLOAD_COVER_IMAGE, onUploadCoverImage);
}
