import { put, takeEvery } from 'redux-saga/effects';

import { markContent } from 'modules/editor/store';

import {
  UPDATE_CONTENT_ELEMENT,
  UPDATE_CONTENT_ACTIVE_TEMPLATE,
} from 'modules/editor/store/constants';

export function* onMarkContent({ payload }) {
  const { sectionId } = payload;
  const action = markContent(sectionId);
  yield put(action);
}

export function* watchMarkContent() {
  const pattern = [UPDATE_CONTENT_ELEMENT, UPDATE_CONTENT_ACTIVE_TEMPLATE];

  yield takeEvery(pattern, onMarkContent);
}
