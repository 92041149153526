//

import DraftEditor from '@draft-js-plugins/editor';
import { customStyleFn, blockStyleFn } from 'modules/common/utils';

import highlightDecoratorPlugin from './highlightPlugin';

import { filterState, shouldFilterOnPaste, getExtendedBlockRenderMap } from './utils';
import { pluginsGroup, AlignmentTool } from './plugins';
import { ImageEntityWrapper } from './components';

import 'draft-js/dist/Draft.css';

const Editor = ({ element, editorState, setEditorState, onFocus }) => {
  const handleChange = (nextEditorState) => {
    let filteredEditorState = nextEditorState;
    const shouldFilterEmojis = shouldFilterOnPaste(editorState, nextEditorState);

    if (shouldFilterEmojis) {
      filteredEditorState = filterState(nextEditorState);
    }

    setEditorState(filteredEditorState);
  };

  const extendedBlockRenderMap = getExtendedBlockRenderMap({
    atomic: {
      element: 'section',
      wrapper: <ImageEntityWrapper />,
    },
  });

  return (
    <div onClick={onFocus}>
      <DraftEditor
        onFocus={onFocus}
        onChange={handleChange}
        editorState={editorState}
        customStyleFn={customStyleFn}
        blockStyleFn={blockStyleFn}
        placeholder={element.placeholder}
        plugins={[...pluginsGroup, highlightDecoratorPlugin]}
        blockRenderMap={extendedBlockRenderMap}
        spellCheck
      />
      <AlignmentTool />
    </div>
  );
};

export default Editor;
