import PropTypes from 'prop-types';
import AuthenticationTemplate from 'modules/v2/components/AuthenticationTemplate';
import LoaderImg from 'assets/images/loader/loader.gif';
import S from './index.styled';

const AutoLoginCheck = ({ isLoading, hasError }) => (
  <AuthenticationTemplate>
    {hasError && (
      <S.ErrorMessage>
        There was an error <br />
        Please contact the support using the chat box below.
      </S.ErrorMessage>
    )}
    {isLoading && <img className="loaderimg" src={LoaderImg} alt="" />}
  </AuthenticationTemplate>
);

AutoLoginCheck.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default AutoLoginCheck;
