import { call, select, takeEvery } from 'redux-saga/effects';

import { reorderDraftSections, resource, getError } from 'modules/api';

import { draftIdSelector, sectionsSelector } from 'modules/editor/store';
import { SAVE_SECTIONS } from 'modules/editor/store/constants';

export function* onSaveSections() {
  try {
    const draftId = yield select(draftIdSelector);
    const sections = yield select(sectionsSelector);

    const request = resource(SAVE_SECTIONS, reorderDraftSections);
    yield call(request, { draftId, sections });

    return true;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);

    return false;
  }
}

export function* watchSaveSections() {
  yield takeEvery(SAVE_SECTIONS, onSaveSections);
}
