import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
`;

const ContainerWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin: 4px 0;
`;

const Label = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
`;

const Description = styled.span`
  font-size: 14px;
  text-transform: capitalize;
`;

export default { Container, ContainerWrapper, Label, Description };
