import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import environments from 'environments';

import { DashboardTemplate } from 'modules/v2/templates';
import { MyMagazineSideNav } from 'modules/v2/components';
import { isAdminEditing } from 'modules/v2/utils';
import { ErrorState } from 'modules/v2/common/components';
import { getCurrentMonth, getCurrentYear } from 'modules/v2/common/utils';
import { getAppConfig } from 'modules/api/digitalServices';
import { isAdminCustomer } from 'modules/v2/utils/isAdminCustomer';

import { ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR } from 'modules/dashboard/routes/constants';
import { monthNames } from './components/MagazineEditor/constant';

const ReferralMarketingMagazines = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location || {};
  const isMagazineEditorPage = pathname.includes('magazine-editor');

  let sidebarWidth;
  if (isAdminEditing()) {
    sidebarWidth = 250;
  } else {
    sidebarWidth = isMagazineEditorPage ? 450 : 290;
  }

  const marginRight = isMagazineEditorPage ? '0' : undefined;
  const { children } = props;

  const { data: appConfig } = useQuery(['getAppConfig'], getAppConfig, {
    staleTime: 1 * 60 * 60 * 1000,
  });

  const currentMonth = appConfig?.MONTH || getCurrentMonth().toLowerCase();
  const currentYear = appConfig?.YEAR || getCurrentYear();

  const isValidEdition = () => {
    const path = pathname.toLowerCase();

    const isEditor = pathname.includes('magazine-editor');
    const validMonth = path.includes(currentMonth);
    const validYear = path.includes(currentYear);

    const isAdmin = isAdminCustomer();

    return (validMonth && validYear && isEditor) || isAdmin || isAdminEditing() || !isEditor;
  };

  useEffect(() => {
    if (pathname.includes('editor')) {
      let targetPathname = pathname.split('editor').pop();
      const hasSlashInStart = targetPathname.startsWith('/');
      // handle no params
      if (hasSlashInStart) {
        targetPathname = targetPathname.slice(1);
      }
      if (!targetPathname) {
        const missingParams = `${hasSlashInStart ? '' : '/'}${currentMonth}/${currentYear}`;
        const newPath = pathname.concat(missingParams);
        history.replace(newPath);
        // eslint-disable-next-line
        return () => {};
      }
      const hasParams = decodeURIComponent(targetPathname.split('editor/').pop());
      const getParamsValue = hasParams.split('/').filter((i) => i?.length > 0);
      const parameters = {
        month: getParamsValue[0] || '',
        year: getParamsValue[1] || '',
      };
      const { month, year } = parameters;
      let newParams = hasParams?.replace(new RegExp('[^a-z0-9,. /-]', 'gi'), '');

      const isValidMonth = monthNames.includes(month?.toLowerCase());
      const isCurrentYear = year && year === currentYear;
      // handle no or 1 param value

      // ( ) doesnt work
      if (getParamsValue.length < 2) {
        if (!getParamsValue[1]) {
          if (!isValidMonth) {
            newParams = newParams.replace(new RegExp(`\\b${month}\\b`), `${currentMonth}/2022`);
          } else {
            const hasEndingSlash = newParams.endsWith('/');
            newParams = newParams.concat(`${hasEndingSlash ? '' : '/'}${currentYear}`);
          }
        }
      } else {
        if (!isValidMonth) {
          newParams = newParams.replace(new RegExp(`\\b${month}\\b`), currentMonth);
        }
        if (!isCurrentYear) {
          const paramYear = year.length === 4 && year > currentYear ? year : currentYear;
          newParams = newParams.replace(new RegExp(`\\b${year}\\b`), paramYear);
        }
      }
      if (pathname !== newParams) {
        const newPathname = pathname.replace(hasParams, newParams);
        // const removeSpecialCharacters = newPathname.replace(new RegExp('[^a-z0-9,. /-]', 'gi'), '');
        history.replace(newPathname);
      }
    }
    // eslint-disable-next-line
    return () => {};
  }, [pathname, currentMonth, currentYear, history]);

  if (!isValidEdition()) {
    const routeToRedirect = ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR.replace(
      ':month',
      currentMonth,
    ).replace(':year', currentYear);
    history.push(routeToRedirect);
  }

  return (
    <Formik
      initialValues={{
        current: 0,
        hasPreviousData: false,
        month: appConfig?.MONTH,
        year: appConfig?.YEAR,
      }}
    >
      <DashboardTemplate
        sidebar={<MyMagazineSideNav month={appConfig?.MONTH} />}
        sidebarWidth={sidebarWidth}
        isLoading={false}
        marginRight={marginRight}
        hasSidebar
        isError={false}
        error={
          <ErrorState
            topMessage="Our system encountered an error while loading your plans information."
            bottomMessage={
              <>
                Please call our customer service at <b>{environments.SUPPORT_PHONE}</b>.
              </>
            }
          />
        }
      >
        {children}
      </DashboardTemplate>
    </Formik>
  );
};

export default ReferralMarketingMagazines;
