import withStore from 'store/StoreContainer';

import { clearLinks, getLinks, linksSelector } from 'modules/bookLinks/store';

const mapStateToProps = (state) => ({
  links: linksSelector(state),
});

const mapDispatchToProps = {
  clearLinks,
  getLinks,
};

export default withStore(mapStateToProps, mapDispatchToProps);
