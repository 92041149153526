import S from './style';

function ImportBtn() {
  return (
    <S.ImportBtn>
      <S.SubWrapper>
        <S.PlusCircle /> Browse Files
      </S.SubWrapper>
      <S.SubContent>Accepted file format .csv</S.SubContent>
    </S.ImportBtn>
  );
}

export default ImportBtn;
