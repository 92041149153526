import { Authenticated, Public } from 'modules/router/types';

// LOGIN
import AuthLogin from 'modules/v2/login/pages/Login';
import AuthReset from 'modules/v2/login/containers/ForgotPassword';
import AuthToken from 'modules/v2/login/pages/AutoLoginCheck';
import AuthSetPassword from 'modules/v2/login/pages/AuthSetPassword';
import AuthNewPassword from 'modules/v2/login/containers/NewPassword.jsx';

import {
  HomePage,
  BookCatalog,
  HelpCenter,
  MyOrders,
  Password,
  PlanSettings,
  Profile,
  ReleaseNotes,
  ShippingAddress,
  Leads,
  ImportLeads,
  BookPerformanceAnalytics,
  Plans,
  Billing,
  SenderEmailConfiguration,
  EmailHistory,
  EmailTemplates,
  EmailCampaignAnalytics,
  EmailCampaign,
  OnDemandEmail,
  CreateOnDemandEmail,
  CreateEmailCampaign,
  CreateCustomerTemplate,
  EditCustomerTemplate,
  LaunchEmailCampaign,
  UpgradePlan,
  PlanConfirmation,
  PageProfile,
  SocialMediaTrainingSite,
  TrainingSite,
  ReferralMarketingMagazinesPage,
  CampaignHistory,
  CampaignMetrics,
  RedirectReferralMarketing,
  Unsubscribe,
  Payments,
  PlansAndBilling,
  PlanUpgrade,
} from 'modules/v2/pages/';

import {
  GeneratedMagazinePage,
  MagazineRecipientsPage,
  PreviousMagazinesPage,
} from 'modules/v2/pages/ReferralMarketingMagazines/components';
import MagazineEditorPage from 'modules/v2/pages/ReferralMarketingMagazines/components/MagazineEditor';

import dentistRoutes from 'modules/v2/routes/dentist';

import OrderedBooksListingPage from 'v2/pages/OrderedBooksListingPage';
import BuyCreditsJourney from 'v2/pages/BuyCredits';
import WelcomeJourney from 'v2/pages/WelcomeJourney';

import {
  ROUTE_HOMEPAGE,
  ROUTE_BOOK_CATALOG,
  ROUTE_SOCIAL_MEDIA_TRAINING_SITE,
  ROUTE_TRAINING_SITE,
  ROUTE_BOOK_PERFORMANCE,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_EDIT_SHIPPING_ADDRESS,
  ROUTE_HELP_CENTER,
  ROUTE_LEADS,
  ROUTE_IMPORT_LEADS,
  ROUTE_PLANS,
  ROUTE_MY_ORDERS,
  ROUTE_DIGITAL_BOOKS,
  ROUTE_EMAIL_TEMPLATES,
  ROUTE_EMAIL_SENDER_EMAIL_CONFIGURATION,
  ROUTE_EMAIL_CAMPAIGNS_ANALYTICS,
  ROUTE_EMAIL_LAUNCH_CAMPAIGN,
  ROUTE_CAMPAIGNS_HISTORY,
  ROUTE_EMAIL_CAMPAIGN,
  ROUTE_ON_DEMAND_EMAIL,
  ROUTE_ALL_CAMPAIGN_METRICS,
  ROUTE_CREATE_ON_DEMAND_EMAIL,
  ROUTE_CREATE_EMAIL_CAMPAIGN,
  ROUTE_PROFILE,
  ROUTE_RELEASE_NOTES,
  ROUTE_BILLING,
  ROUTE_UPGRADE_PLAN,
  ROUTE_PLAN_CONFIRMATION,
  ROUTE_PAGE_PROFILE,
  ROUTE_EMAIL_HISTORY,
  ROUTE_MANAGE_BOOK_VERSION,
  ROUTE_CREATE_CUSTOMER_TEMPLATE,
  ROUTE_EDIT_CUSTOMER_TEMPLATE,
  ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR,
  ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE,
  REFERRAL_MARKETING,
  ROUTE_REFERRAL_MARKETING_PREVIOUS_MAGAZINES,
  ROUTE_REFERRAL_MARKETING_MAGAZINE_RECIPIENTS,
  ROUTE_REFERRAL_MARKETING_REDIRECT_ROUTE,
  ROUTE_UNSUBSCRIBE,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE_PLANS,
  ROUTE_PLANS_AND_BILLING,
  ROUTE_PLAN_UPGRADE,
  ROUTE_BUY_CREDITS,
  ROUTE_WELCOME_JOURNEY,
} from './constants';

import ManageBookVersion from '../pages/ManageBookVersion';

const regularRoutes = [
  ...dentistRoutes,

  {
    type: Public,
    exact: true,
    path: '/auth/login',
    component: AuthLogin,
  },
  {
    type: Public,
    exact: true,
    path: '/auth/reset',
    component: AuthReset,
  },
  {
    type: Public,
    exact: true,
    path: '/auth/token',
    component: AuthToken,
  },
  {
    type: Public,
    exact: true,
    path: '/auth/setpassword',
    component: AuthSetPassword,
  },
  {
    type: Public,
    exact: true,
    path: '/auth/newpassword',
    component: AuthNewPassword,
  },

  // UNSUBSCRIBE
  {
    type: Public,
    exact: true,
    path: ROUTE_UNSUBSCRIBE,
    component: Unsubscribe,
  },

  // MY BOOKS
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_BOOK_CATALOG,
    component: BookCatalog,
  },
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_MY_ORDERS,
    component: OrderedBooksListingPage,
  },
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_DIGITAL_BOOKS,
    component: OrderedBooksListingPage,
  },
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_BOOK_PERFORMANCE,
    component: BookPerformanceAnalytics,
  },
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_MANAGE_BOOK_VERSION,
    component: ManageBookVersion,
  },

  // BUY CREDITS
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_BUY_CREDITS,
    component: BuyCreditsJourney,
  },

  // Campaigns
  {
    path: ROUTE_LEADS,
    component: Leads,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_IMPORT_LEADS,
    component: ImportLeads,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_EMAIL_TEMPLATES,
    component: EmailTemplates,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_EDIT_CUSTOMER_TEMPLATE,
    component: EditCustomerTemplate,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_CREATE_CUSTOMER_TEMPLATE,
    component: CreateCustomerTemplate,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_EMAIL_SENDER_EMAIL_CONFIGURATION,
    component: SenderEmailConfiguration,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_EMAIL_HISTORY,
    component: EmailHistory,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_EMAIL_CAMPAIGNS_ANALYTICS,
    component: EmailCampaignAnalytics,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_EMAIL_LAUNCH_CAMPAIGN,
    component: LaunchEmailCampaign,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_CREATE_EMAIL_CAMPAIGN,
    component: CreateEmailCampaign,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_CAMPAIGNS_HISTORY,
    component: CampaignHistory,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_ALL_CAMPAIGN_METRICS,
    component: CampaignMetrics,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_CREATE_ON_DEMAND_EMAIL,
    component: CreateOnDemandEmail,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_ON_DEMAND_EMAIL,
    component: OnDemandEmail,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_EMAIL_CAMPAIGN,
    component: EmailCampaign,
    exact: true,
    type: Authenticated,
  },

  // CUSTOMER / PLAN PAGES
  {
    path: ROUTE_PLANS_AND_BILLING,
    component: PlansAndBilling,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_PLAN_UPGRADE,
    component: PlanUpgrade,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_PLANS,
    component: Plans,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_PAYMENTS,
    component: Payments,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_UPGRADE_PLAN,
    component: UpgradePlan,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_PLAN_CONFIRMATION,
    component: PlanConfirmation,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_PROFILE_PLANS,
    component: PlanSettings,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_BILLING,
    component: Billing,
    exact: true,
  },
  {
    path: ROUTE_PLANS,
    component: Plans,
    exact: true,
  },
  {
    path: ROUTE_PAYMENTS,
    component: Payments,
    exact: true,
  },
  {
    path: ROUTE_UPGRADE_PLAN,
    component: UpgradePlan,
    exact: true,
  },
  {
    path: ROUTE_PLAN_CONFIRMATION,
    component: PlanConfirmation,
    exact: true,
  },
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_HELP_CENTER,
    component: HelpCenter,
  },
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_PROFILE,
    component: Profile,
  },
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_RELEASE_NOTES,
    component: ReleaseNotes,
  },
  {
    type: Authenticated,
    path: ROUTE_EDIT_SHIPPING_ADDRESS,
    component: ShippingAddress,
    exact: true,
  },
  {
    type: Authenticated,
    path: ROUTE_PAGE_PROFILE,
    component: PageProfile,
    exact: true,
  },
  {
    type: Authenticated,
    path: ROUTE_CHANGE_PASSWORD,
    component: Password,
    exact: true,
  },
  {
    type: Authenticated,
    path: ROUTE_PROFILE_PLANS,
    component: PlanSettings,
    exact: true,
  },

  // HOME PAGE
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_HOMEPAGE,
    component: HomePage,
  },

  //  SOCIAL MEDIA TRAINING
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_SOCIAL_MEDIA_TRAINING_SITE,
    component: SocialMediaTrainingSite,
  },

  // TRAINING SITE
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_TRAINING_SITE,
    component: TrainingSite,
  },

  // REFERRAL MARKETING
  {
    type: Authenticated,
    path: REFERRAL_MARKETING,
    component: ReferralMarketingMagazinesPage,
    children: [
      {
        exact: true,
        path: ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR,
        component: MagazineEditorPage,
      },
      {
        exact: true,
        path: ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE,
        component: GeneratedMagazinePage,
      },
      {
        exact: true,
        path: ROUTE_REFERRAL_MARKETING_PREVIOUS_MAGAZINES,
        component: PreviousMagazinesPage,
      },
      {
        exact: true,
        path: ROUTE_REFERRAL_MARKETING_MAGAZINE_RECIPIENTS,
        component: MagazineRecipientsPage,
      },
      {
        exact: true,
        path: ROUTE_REFERRAL_MARKETING_REDIRECT_ROUTE,
        component: RedirectReferralMarketing,
      },
    ],
  },

  // Welcome Journey
  {
    type: Authenticated,
    exact: true,
    path: ROUTE_WELCOME_JOURNEY,
    component: WelcomeJourney,
  },
];

export const routes = [].concat(regularRoutes);
