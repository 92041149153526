import styled from 'styled-components/macro';
import { Button } from 'antd';

import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  padding: 30px 0;
  text-align: center;
  border-bottom: 1px solid ${color.GreyBookBorder};
`;

const SideContent = styled.div`
  width: 212px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  text-align: left;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: auto;
  display: block;
  width: 212px;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  display: inline-block;
`;

const LinksList = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const PrimaryButton = styled(Button)`
  border-radius: 3px;
  display: inline-block;
  font-size: 16px;
  line-height: 32px;
  margin-right: 20px;
  padding: 0 20px;
  transition: all 0.2s;
  font-weight: normal !important;
  border: none;

  &:hover {
    opacity: 0.9;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    color: ${color.White};
    background: ${color.PrimaryColor};
  }
`;

const ButtonLink = styled(Button)`
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;

  &,
  &:hover,
  &:focus,
  &:active {
    color: ${color.PrimaryColor};
    border-color: ${color.PrimaryColor};
  }
`;

const BookName = styled.span`
  font-weight: 700;
  color: ${color.GreySelectDd};
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 15px;
`;

const Details = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

Details.Item = styled.li`
  padding: 3px 0;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
`;

Details.Title = styled.strong`
  font-family: ${font.NunitoFont};
  font-size: 16px;
  color: ${color.GreySelectDd};
  min-width: 160px;
`;

Details.Value = styled.span`
  font-size: 15px;
`;

const ConfirmTitle = styled.p`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: ${color.Black};
`;

const ConfirmText = styled.p`
  margin-top: 20px;
  margin-bottom: 0;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 640px;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContentTitle = styled.p`
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-right: 2px;

  span {
    color: ${color.DangerRed};
  }
`;

const ContentDescription = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

export default {
  BookName,
  ButtonLink,
  ConfirmText,
  ConfirmTitle,
  Details,
  ImageWrapper,
  LinksList,
  MainContent,
  PrimaryButton,
  SideContent,
  Title,
  Wrapper,
  ModalFooterContainer,
  ContentContainer,
  ContentTitle,
  ContentDescription,
};
