import React from 'react';
import { Slide, toast } from 'react-toastify';

import Notification from 'modules/common/components/Notification';
import { notifyDatadog } from 'modules/common/utils/datadogNotifyier';
import {
  DEFAULT_AUTO_CLOSE_MS,
  TYPE_ERROR,
  TYPE_SUCCESS,
  TYPE_WARNING,
} from 'modules/common/utils/variables';
import { setToastDescription } from 'modules/v2/common/utils/notify';

const notify = ({
  title,
  description,
  autoClose = DEFAULT_AUTO_CLOSE_MS,
  position = toast.POSITION.BOTTOM_CENTER,
  transition = Slide,
  type,
  sendToServer = false,
}) => {
  const content = { description };

  content.description = setToastDescription(type, description);

  const element = React.createElement(Notification, content);

  if (sendToServer || type === TYPE_ERROR) {
    notifyDatadog({
      title,
      description,
      type,
    });
  }

  return toast(element, {
    className: type,
    position,
    transition,
    autoClose,
  });
};

export const notification = {
  success: (args) => notify({ ...args, type: TYPE_SUCCESS }),
  error: (args) => notify({ ...args, type: TYPE_ERROR }),
  warning: (args) => notify({ ...args, type: TYPE_WARNING }),
};
