import { Button } from 'antd';
import styled, { css } from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from 'modules/draft/components';

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: ${color.White};
`;

Footer.Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  background: ${color.ViewFooterTitleBG};
  width: 100%;
  text-align: center;
  color: ${color.White};
  padding: 10px 0;

  span {
    color: ${color.ViewFooterTitle};
    text-transform: capitalize;
  }
`;

Footer.ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 25px 0;

  align-items: center;
  height: fit-content;
  min-height: 67px;
`;

const buttonStyle = css`
  background-color: ${color.ViewBlueButton};
  margin-right: 20px;
  padding: 0 20px;
  color: ${color.White};
  height: 44px;
  line-height: 44px;
  // Overwrite the antd style
  border-radius: 5px !important;
  padding: 0px 25px !important;
  opacity: 1 !important;
  font-weight: normal !important;
  border: none;
  font-size: 16px;

  & > span {
    line-height: 44px;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${color.White};
    background-color: ${color.ViewBlueActive};
  }

  &:disabled {
    background-color: ${color.ViewBlueDisabledButton}!important;
    border: none;

    &,
    &:hover {
      color: ${color.White};
    }
  }
`;

Footer.ButtonLink = styled(RouterLink)`
  ${buttonStyle}
`;

Footer.Button = styled(Button)`
  ${buttonStyle}
`;

Footer.GenerateButton = styled(Button)`
  ${buttonStyle}
  margin-right: 0;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 485px;
  z-index: 0;
  will-change: transform;
  flex-wrap: wrap;
  color: ${color.Black};
  border-left: 1px solid ${color.ViewBorderColor};
  overflow: hidden;
  margin-left: 20px;

  & > div {
    margin: 0 15px 15px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

LinksWrapper.Link = styled(Link)`
  margin-bottom: 10px;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 640px;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContentTitle = styled.p`
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-right: 2px;

  span {
    color: ${color.DangerRed};
  }
`;

const ContentDescription = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

export default {
  Footer,
  LinksWrapper,
  ModalFooterContainer,
  ContentContainer,
  ContentTitle,
  ContentDescription,
};
