import { CustomFlowbiteTheme } from 'flowbite-react';

const customTheme: CustomFlowbiteTheme = {
  pagination: {
    base: 'flex overflow-x-auto sm:justify-center',
    layout: {
      table: {
        base: 'text-sm text-gray-700',
        span: 'font-semibold text-gray-900',
      },
    },
    pages: {
      base: 'inline-flex items-center space-x-1',
      showIcon: 'inline-flex',
      previous: {
        base:
          'mr-2 rounded-md border border-gray-300 px-3 py-2 bg-white text-gray-700 text-sm font-medium hover:bg-gray-100 transition',
        icon: 'h-4 w-4',
      },
      next: {
        base:
          'ml-2 rounded-md border border-gray-300 px-3 py-2 bg-white text-gray-700 text-sm font-medium hover:bg-gray-100 transition',
        icon: 'h-4 w-4',
      },
      selector: {
        base: 'px-3 py-2 mx-1 text-gray-700 text-sm font-medium hover:bg-gray-100 transition',
        active: 'bg-gray-800 text-white rounded-md',
        disabled: 'cursor-not-allowed opacity-50',
      },
    },
  },
};

export default customTheme;
