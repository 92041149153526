import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Type first name'),
  lastName: Yup.string().trim().required('Type last name'),
  email: Yup.string().trim().required('Type email address').email('Email is not valid'),
  phone: Yup.string().trim().required('Type phone number'),
  address1: Yup.string().trim().required('Type address'),
  country: Yup.string().trim().required('Select country'),
  state: Yup.string().trim().required('Select state'),
  city: Yup.string().trim().required('Type city'),
  zip: Yup.string().trim().required('Type postal code'),
});

export default ValidationSchema;
