const DatabaseIcon = ({ strokeColor = '#FF8A33' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33301 15V5" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" />
    <path d="M16.667 5V15" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" />
    <path
      d="M9.99967 8.33341C13.6816 8.33341 16.6663 6.84103 16.6663 5.00008C16.6663 3.15913 13.6816 1.66675 9.99967 1.66675C6.31778 1.66675 3.33301 3.15913 3.33301 5.00008C3.33301 6.84103 6.31778 8.33341 9.99967 8.33341Z"
      stroke={strokeColor}
      stroke-width="1.5"
    />
    <path
      d="M16.6663 10C16.6663 11.8409 13.6816 13.3333 9.99967 13.3333C6.31778 13.3333 3.33301 11.8409 3.33301 10"
      stroke={strokeColor}
      stroke-width="1.5"
    />
    <path
      d="M16.6663 15C16.6663 16.8409 13.6816 18.3333 9.99967 18.3333C6.31778 18.3333 3.33301 16.8409 3.33301 15"
      stroke={strokeColor}
      stroke-width="1.5"
    />
  </svg>
);

export default DatabaseIcon;
