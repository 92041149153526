import { createBrowserHistory } from 'history';

let browserHistory = () => ({
  location: null,
});

if (typeof window === 'object') {
  browserHistory = createBrowserHistory();
}

const history = browserHistory;

export default history;
