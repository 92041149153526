const defaultToggleSwitchTheme = {
  root: {
    base: 'w-12 h-7 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ease-in-out',
    color: {
      off: 'bg-gray-300',
      on: 'bg-neutral-700',
    }
  },
  knob: {
    base: 'bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out',
    position: {
      off: 'translate-x-0',
      on: 'translate-x-5',
    }
  }
};

export { defaultToggleSwitchTheme };
