import React, { useEffect } from 'react';
import { Modal } from 'flowbite-react';
import ReactHlsPlayer from 'react-hls-player';
import { CloseCircleIcon, PlayIcon } from 'modules/v2/common/components/SvgIcon';
import Button from './Button';
import { cn } from '../../utils/cn';

interface VideoModalProps {
  title: string;
  showModal: boolean;
  setShowModal: () => void;
  videoSource: string;
  icon: React.ReactNode;
  buttonText: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const VideoModal = ({
  title,
  showModal,
  setShowModal,
  videoSource,
  icon,
  buttonText = 'Start my journey',
  onClick,
}: VideoModalProps) => {
  const [isPlaying, setIsPlaying] = React.useState(true);
  const playerRef = React.useRef<HTMLVideoElement>();

  const togglePlayback = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  };

  useEffect(() => {
    togglePlayback();
  }, [isPlaying]);

  if (!videoSource) return null;

  return (
    <Modal show={showModal} onClose={() => setShowModal()} size="4xl" dismissible position="center">
      <div className="mx-6 pt-6 pb-3 flex justify-between items-center font-bold">
        <span className="text-xl font-bold">{title}</span>
        <div
          className="flex items-center justify-center h-[38px] w-[38px] p-2.5 bg-neutral-100 rounded-md cursor-pointer hover:bg-neutral-200"
          onClick={() => setShowModal()}
        >
          <CloseCircleIcon width={36} height={36} />
        </div>
      </div>
      <Modal.Body className="p-8 relative pb-0" onClick={() => setIsPlaying(!isPlaying)}>
        <div
          className={cn(
            'w-auto flex justify-center items-center absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-20 cursor-pointer hover:opacity-50',
            { 'hidden ': isPlaying },
          )}
        >
          <PlayIcon width={50} height={50} />
        </div>
        <div className="h-full w-full flex items-center justify-center overflow-hidden rounded-lg bg-neutral-600">
          <ReactHlsPlayer
            src={videoSource}
            playerRef={playerRef}
            autoPlay
            controls={isPlaying}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            width="100%"
            height="auto"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="w-full border-none pb-[34px]">
        <Button type="primary" full onClick={onClick}>
          {buttonText} {icon && <span className="ml-1 mt-0.5">{icon}</span>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoModal;
