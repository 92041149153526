import CardItem from 'modules/v2/pages/Payments/components/OtherCards/CardItem';

import S from './styles';

const OtherCards = ({ profiles, refetchDefaultProfile, refetchProfiles }) => {
  return (
    <S.CardWrap>
      {profiles?.map((item) => {
        return (
          <CardItem
            creditData={item}
            key={item?.id}
            refetchDefaultProfile={refetchDefaultProfile}
            refetchProfiles={refetchProfiles}
          />
        );
      })}
    </S.CardWrap>
  );
};

export default OtherCards;
