import { useEffect } from 'react';
import { get } from 'lodash-es';

import { isFailed, isPending } from 'store/status';
import { Loader } from 'modules/common/components';
import { withAuth } from 'modules/auth/containers';
import { AuthenticationTemplate } from 'modules/auth/components';
import { isAutoSigninStatusFailed, isAutoSigninStatusProcessing } from 'modules/auth/store';

import S from './styles';

export const Authentication = ({ autoSignin, getAutoSignin }) => {
  const autoSigninDataStatus = get(autoSignin, ['data', 'status']);
  const autoSigninStatus = get(autoSignin, 'status');

  const isLoading =
    isPending(autoSigninStatus) || isAutoSigninStatusProcessing(autoSigninDataStatus);
  const hasError = isFailed(autoSigninStatus) || isAutoSigninStatusFailed(autoSigninDataStatus);

  useEffect(() => {
    getAutoSignin();
  }, [getAutoSignin]);

  return (
    <AuthenticationTemplate>
      {hasError && (
        <S.ErrorMessage data-testid="error">
          There was an error. <br />
          Please contact support using the chat box below.
        </S.ErrorMessage>
      )}

      {isLoading && (
        <Loader
          height="40px"
          hasIcon={false}
          description="Please wait while your credentials are being retrieved"
        />
      )}
    </AuthenticationTemplate>
  );
};

export default withAuth(Authentication);
