import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { getLastMagazine } from 'modules/api';
import { getRouteReferralMarketingMagazineEditor } from 'modules/v2/routes/navigation';
import { Title } from 'modules/v2/common/AtomicDesign/atoms';

import S from './styles';

const MagazineContainer = () => {
  const { data: lastMagazineData, isLoading: isAppConfigLoading } = useQuery(
    'getLastMagazine',
    getLastMagazine,
  );

  const { month, year, frontCoverDesign = [] } = lastMagazineData || {};

  const coversUrl = frontCoverDesign.map((design) => design.displayImage?.data?.attributes?.url);
  const [featureCoverUrl, ...extraCoversUrls] = coversUrl;

  return (
    <S.Container>
      <Title className="mb-3">My magazines</Title>
      <S.FlexWrap>
        {isAppConfigLoading && (
          <S.MagazineContainer>
            <S.ImageWrapper>
              <S.SkeletonImage active />
            </S.ImageWrapper>
            <S.Description>
              <S.SkeletonInput active block />
            </S.Description>
          </S.MagazineContainer>
        )}
        {!isAppConfigLoading && (
          <div>
            <div className="pb-0 flex flex-wrap gap-3.5 max-w-[463px] h-[296px] overflow-hidden mb-1">
              <div className="flex flex-wrap gap-3.5">
                <div className="w-HomeThumbnailMedium overflow-hidden rounded row-span-2 rounded h-[296px]">
                  <Link className="" to={getRouteReferralMarketingMagazineEditor(month, year)}>
                    <img
                      className="w-full h-full object-fill transition-all duration-100 hover:scale-110 rounded"
                      src={featureCoverUrl}
                      alt="magazine"
                    />
                  </Link>
                </div>

                <div className="flex gap-2 flex-wrap max-w-[254px] gap-3.5 max-md:flex overflow:hidden">
                  {extraCoversUrls.map((url) => (
                    <div className="overflow-hidden rounded w-[120px]" key={url}>
                      <Link to={getRouteReferralMarketingMagazineEditor(month, year)}>
                        <img
                          className="transition-all duration-300 hover:scale-110 rounded w-[120px] h-[144px]"
                          src={url}
                          alt="magazine"
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <S.Description className="text-left text-zinc-500">{`${month} ${year}`}</S.Description>
          </div>
        )}
      </S.FlexWrap>
    </S.Container>
  );
};

export default MagazineContainer;
