import { useState } from 'react';
import { get, capitalize } from 'lodash-es';
import { ErrorMessage } from 'modules/v2/common/components';
import { checkWordLimit } from 'modules/v2/utils/functions';
import { TextInput, Textarea } from 'flowbite-react';
import { defaultTextInputTheme } from 'modules/v2/common/AtomicDesign/atoms/TextInput/theme';
import { defaultTextAreaTheme } from 'modules/v2/common/AtomicDesign/atoms/TextArea/theme';

import ImageUploaderModal from '../ImageUploaderModal';
import { optionFieldsWithWordLimit } from '../../constants';

const ListingDetails = ({
  step = '',
  formikHandler,
  hasInputFields,
  hasTextArea,
  hasListingName,
  propertyKey = '',
  uploadImageTitle = 'Listing photo',
  presentImageLocation,
  uploadImageType,
  imageDimensions,
}) => {
  const { values = {}, setFieldValue, setFieldError, errors } = formikHandler || {};
  const targetLocation = step.concat('.', '_formFields');
  const [brokerLogoModal, setBrokerLogoModal] = useState(false);
  const [isEditImage, setIsEditImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const description = get(values, `${targetLocation}.description`) || '';
  const features = get(values, `${targetLocation}.features`) || '';

  const handleChange = (e) => {
    const {
      target: { name = '', value = '' },
    } = e || {};

    const formikValues = get(values, `${targetLocation}`) || {};
    const locationToSave = step.concat('.', '_formFields', '.', name);

    if (name === 'listingPrice' && value !== '') {
      const priceRegex = /^\d*\.?\d{0,2}$/;
      if (!priceRegex.test(value)) {
        return;
      }
    }

    if (optionFieldsWithWordLimit.includes(name)) {
      const { limitExceeded, isRemovingWords } = checkWordLimit(formikValues[name], value, 50);

      if (limitExceeded && !isRemovingWords) {
        setFieldError(
          `${targetLocation}.${name}`,
          `${capitalize(name)} must not be more than 50 words.`,
        );
        return;
      }
    }

    setFieldError(`${targetLocation}.${name}`, null);
    setFieldValue(locationToSave, value);
  };

  const toggleBrokerLogoModal = () => {
    setBrokerLogoModal(!brokerLogoModal);
  };

  const handleImageUpload = (value, keyName) => {
    const locationToSave = step.concat('.', '_formFields', '.', keyName);
    setFieldValue(locationToSave, value);
    toggleBrokerLogoModal();
  };

  const onRemoveImage = (e, propKey) => {
    setFieldValue(`${targetLocation}.${propKey}`, '');
    setImageUrl('');
    e.stopPropagation();
  };

  const onEditImage = () => {
    setIsEditImage(true);
    setImageUrl(presentImageLocation);
  };

  const toggleCancelEdit = () => {
    setIsEditImage(!isEditImage);
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-start">
          <div className="text-neutral-800 text-sm font-semibold">{uploadImageTitle}</div>
          <div className="text-neutral-500 text-sm font-medium">
            Recommended size: {imageDimensions.width} x {imageDimensions.height}
          </div>
        </div>
        <ImageUploaderModal
          imageDimensions={imageDimensions}
          type={uploadImageType}
          modalTitle="Listing image"
          isOpen={brokerLogoModal === 'photo'}
          onCancel={toggleBrokerLogoModal}
          handleOpenModal={() => setBrokerLogoModal('photo')}
          selectAreaWidth="50%"
          selectAreaJustifyContent="center"
          imageUrl={presentImageLocation}
          onSuccess={(value) => handleImageUpload(value, propertyKey)}
          onRemoveImage={(e) => onRemoveImage(e, propertyKey)}
          imageType={propertyKey}
          onEditImage={(e) => {
            onEditImage();
            e.stopPropagation();
          }}
          imageUrlForEdit={imageUrl}
          isEditImage={isEditImage}
          toggleCancelEdit={toggleCancelEdit}
        />
      </div>
      {hasListingName && (
        <div className="w-full pr-3 flex gap-4">
          <div className="w-full flex flex-col gap-0">
            <div className="w-full flex flex-col gap-1">
              <span className="font-semibold text-sm text-neutral-800">Listing name</span>
              <TextInput
                theme={defaultTextInputTheme}
                id="listingName"
                name="listingName"
                type="text"
                value={get(values, `${targetLocation}.listingName`) || ''}
                onChange={handleChange}
                placeholder=""
                className="rounded-md"
              />
              <div className="text-red-500 py-2 text-sm">
                {get(errors, `${targetLocation}.listingName`) || ''}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-0">
            <div className="w-full flex flex-col gap-1">
              <span className="font-semibold text-sm text-neutral-800">Listing address</span>
              <TextInput
                theme={defaultTextInputTheme}
                id="listingAddress"
                name="listingAddress"
                type="text"
                value={get(values, `${targetLocation}.listingAddress`)}
                onChange={handleChange}
                placeholder=""
                className="rounded-md"
              />
              <ErrorMessage>{get(errors, `${targetLocation}.listingAddress`) || ''}</ErrorMessage>
            </div>
          </div>
        </div>
      )}
      {hasInputFields && !hasListingName && (
        <div className="w-full flex gap-4">
          <div className="w-full flex flex-col gap-0">
            <div className="w-full flex flex-col gap-1">
              <span className="font-semibold text-sm text-neutral-800">Listing address</span>
              <TextInput
                theme={defaultTextInputTheme}
                id="listingAddress"
                name="listingAddress"
                type="text"
                value={get(values, `${targetLocation}.listingAddress`)}
                onChange={handleChange}
                placeholder=""
                className="rounded-md"
              />
              <ErrorMessage>{get(errors, `${targetLocation}.listingAddress`) || ''}</ErrorMessage>
            </div>
          </div>
          <div className="w-full flex gap-4">
            <div className="relative w-full flex flex-col gap-1">
              <span className="font-semibold text-sm text-neutral-800">Listing price</span>
              <TextInput
                id="listingPrice"
                name="listingPrice"
                type="text"
                addon="$"
                value={get(values, `${targetLocation}.listingPrice`)}
                onChange={handleChange}
                placeholder=""
              />
              <ErrorMessage>{get(errors, `${targetLocation}.listingPrice`) || ''}</ErrorMessage>
            </div>
          </div>
        </div>
      )}
      {hasTextArea && (
        <div className="flex flex-col gap-4 mt-4">
          <div className="w-full flex flex-col gap-0">
            <div className="w-full flex flex-col gap-4">
              <div className="relative w-full flex flex-col gap-1">
                <label className="font-semibold text-sm text-neuutral-800">
                  Listing description
                </label>
                <Textarea
                  theme={defaultTextAreaTheme}
                  id="description"
                  name="description"
                  onChange={handleChange}
                  value={description}
                  rows="3"
                  placeholder=""
                />
                <ErrorMessage>{get(errors, `${targetLocation}.description`)}</ErrorMessage>
              </div>
              <div className="relative w-full flex flex-col gap-1">
                <label className="font-semibold text-sm text-neuutral-800">
                  Listing features <br />
                  <span className="text-neutral-400 font-medium text-sm">
                    Use enter to add to add list
                  </span>
                </label>
                <Textarea
                  theme={defaultTextAreaTheme}
                  id="features"
                  name="features"
                  onChange={handleChange}
                  value={features}
                  rows="3"
                  placeholder=""
                />
                <ErrorMessage>{get(errors, `${targetLocation}.features`)}</ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListingDetails;
