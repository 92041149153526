import { map } from 'lodash-es';
import { DateTime } from 'luxon';

export const formatCampaigns = (campaigns) => {
  return map(campaigns, (campaign) => {
    let segmentsValue = 'All Segments';

    const {
      content: { emails, name: type },
      allSegments,
      segments,
      ...others
    } = campaign;

    if (!allSegments) {
      segmentsValue = map(segments, ({ name }) => name).join(', ');
    }

    return {
      ...others,
      segments: segmentsValue,
      allSegments,
      emails,
      type,
    };
  });
};

export const formatOnDemandEmails = (onDemandEmails) => {
  return map(onDemandEmails, (onDemandEmail) => {
    let segmentsValue = 'All Segments';

    const {
      templateName: name,
      scheduleDate: startDate,
      allSegments,
      segments,
      ...others
    } = onDemandEmail;

    if (!allSegments) {
      segmentsValue = map(segments, ({ name: segmentName }) => segmentName).join(', ');
    }

    return {
      ...others,
      name,
      segments: segmentsValue,
      allSegments,
      type: 'On Demand Email',
      startDate,
    };
  });
};

export const formatDateText = (emailData) => {
  const { usesRelativeDate, absoluteMonth, absoluteDay, relativeDays } = emailData;
  if (!usesRelativeDate) {
    return DateTime.fromObject({ month: absoluteMonth, day: absoluteDay }).toFormat('MMMM dd');
  }

  return `After a lead reaches ${relativeDays} days old`;
};
