import { Form } from 'formik';
import { useEffect } from 'react';

import { Loader } from 'modules/common/components';
import buildConfig from 'modules/dashboard/components/AddPaymentProfile/components/chargifyFields';

import S from './styles';

const AddPaymentProfileForm = ({ isLoading, chargifyForm, chargify }) => {
  const config = buildConfig('card');

  useEffect(() => {
    const chargifyRef = chargify.current;
    chargifyRef.load(config, { onThreeDsConfigError: console.error }); // eslint-disable-line no-console

    return () => {
      chargifyRef.unload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargify]);

  if (isLoading) {
    return (
      <S.Wrapper>
        <Loader height="40px" hasIcon={false} description="Please wait creating payment profile" />
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.FormContainer>
        <Form ref={chargifyForm}>
          <S.FieldControl>
            <S.FieldGroup>
              <S.CreditCardInput id="chargify1" />
              <S.CreditCardInput id="chargify2" />
            </S.FieldGroup>
          </S.FieldControl>
          <S.FieldControl>
            <S.FieldGroup>
              <S.CreditCardInput id="chargify3" />
              <S.CreditCardInput id="chargify4" />
            </S.FieldGroup>
          </S.FieldControl>
        </Form>
      </S.FormContainer>
    </S.Wrapper>
  );
};

export default AddPaymentProfileForm;
