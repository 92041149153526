import * as Yup from 'yup';
import { BILLING_ADDRESS_COUNTRY } from 'modules/formBuilder/constants';

const regexUSA = /(^[0-9]{5}(?:-[0-9]{4})?$)/;
const regexCanada = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]●?[0-9][A-Z][0-9]$/;

const zipUSA = {
  is: 'USA',
  then: Yup.string().matches(regexUSA, 'Postal Code for USA needs to be valid'),
};

const zipCanada = {
  is: 'Canada',
  then: Yup.string().matches(regexCanada, 'Postal Code to Canada needs to be valid'),
};

export const zip = Yup.string()
  .required('Postal Code is required')
  .when(BILLING_ADDRESS_COUNTRY, zipUSA)
  .when(BILLING_ADDRESS_COUNTRY, zipCanada);

const validationSchema = Yup.object().shape({
  customer: Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required('Type your first name'),
    last_name: Yup.string()
      .trim()
      .required('Type your last name'),
    phone: Yup.string()
      .trim()
      .required('Type your phone number')
      .min(10, 'Phone number must be at least 10 characters long')
      .max(17, 'Phone number must be at most 17 characters long')
      .matches(/^(\+?1[ -]?)?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4,})( x\d{4})?$/, {
        message: 'Please enter a valid Phone Number',
        excludeEmptyString: false,
      }),
    email: Yup.string()
      .trim()
      .required('Type your email address')
      .email('Email is not valid'),
    password: Yup.string()
      .trim()
      .required('Please enter your new password')
      .min(8, 'Password must be a minimum of 8-characters')
      .matches(/(\D+\d+)|(?:\d+\D+)/, {
        message: 'Password must be a combination of numbers and letters',
        excludeEmptyString: false,
      }),
  }),
  billing_address: Yup.object().shape({
    address: Yup.string()
      .trim()
      .required('Type your billing address'),
    country: Yup.string().required('Country is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip,
  }),
  credit_card: Yup.object().shape({
    full_number: Yup.string()
      .trim()
      .required('Type your card number')
      .max(19, 'Card number must be at most 19 characters long'),
    expiration_month: Yup.number()
      .min(1, 'Month must be between 1 and 12')
      .max(12, 'Month must be between 1 and 12'),
    expiration_year: Yup.number()
      .min(new Date().getFullYear(), 'Year must be a future year')
      .required('Type your card expiry date')
      .when('expiration_month', (expiration_month: number, schema) => {
        if (expiration_month) {
          return schema.test({
            test: (expiration_year) => {
              const currentYear = new Date().getFullYear();
              const currentMonth = new Date().getMonth() + 1;
              const currentExpiration = new Date(expiration_year, expiration_month - 1);

              if (expiration_year === currentYear && expiration_month < currentMonth) {
                return false;
              }

              return currentExpiration > new Date();
            },
            message: 'Card has expired',
          });
        }

        return schema;
      }),
    cvc: Yup.string()
      .trim()
      .required('Type your card CVC')
      .max(4, 'CVC must be at most 4 characters long'),
  }),
});

export default validationSchema;
