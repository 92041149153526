export const DownloadIconBadge = ({ width = 16, height = 16, fill = '#252525', className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="#FFF"
      strokeWidth="0.2"
      d="M1.3335 7.9987C1.3335 4.856 1.3335 3.28465 2.30981 2.30834C3.28612 1.33203 4.85747 1.33203 8.00016 1.33203C11.1429 1.33203 12.7142 1.33203 13.6905 2.30834C14.6668 3.28465 14.6668 4.856 14.6668 7.9987C14.6668 11.1414 14.6668 12.7127 13.6905 13.6891C12.7142 14.6654 11.1429 14.6654 8.00016 14.6654C4.85747 14.6654 3.28612 14.6654 2.30981 13.6891C1.3335 12.7127 1.3335 11.1414 1.3335 7.9987ZM8.00016 4.16536C8.2763 4.16536 8.50016 4.38922 8.50016 4.66536V8.12492L9.64661 6.97848C9.84187 6.78322 10.1585 6.78322 10.3537 6.97848C10.549 7.17374 10.549 7.49032 10.3537 7.68558L8.35372 9.68558C8.25995 9.77935 8.13277 9.83203 8.00016 9.83203C7.86755 9.83203 7.74038 9.77935 7.64661 9.68558L5.64661 7.68558C5.45135 7.49032 5.45135 7.17374 5.64661 6.97848C5.84187 6.78322 6.15845 6.78322 6.35372 6.97848L7.50016 8.12492V4.66536C7.50016 4.38922 7.72402 4.16536 8.00016 4.16536ZM5.3335 10.832C5.05735 10.832 4.8335 11.0559 4.8335 11.332C4.8335 11.6082 5.05735 11.832 5.3335 11.832H10.6668C10.943 11.832 11.1668 11.6082 11.1668 11.332C11.1668 11.0559 10.943 10.832 10.6668 10.832H5.3335Z"
      fill="#38AB86"
    />
  </svg>
);
