import { Tooltip } from 'antd';
import * as color from 'modules/common/theme/color';
import { findMetricItem } from '../../utils';
import S from '../../sections/Chart/styles';

const SelectedMetrics = ({ items }) => {
  return items.map((metric) => {
    const { name, value } = metric;
    const valueToFind = name || metric;
    const metricItem = valueToFind ? findMetricItem(valueToFind) : null;
    const metricLabel = metricItem.label;
    const metricColor = metricItem.color;
    const metricDescription = metricItem.description;
    if (!metricItem) return null;

    return (
      <Tooltip placement="top" color={color.gray100} title={metricDescription} key={valueToFind}>
        <S.TooltipItem>
          {valueToFind === 'Send' ? (
            <S.SentEmailIcon color={metricColor} />
          ) : (
            <S.MetricsIcon color={metricColor} />
          )}
          {value && <S.TooltipTitle>{value}</S.TooltipTitle>}
          <S.TooltipDescription>{metricLabel}</S.TooltipDescription>
        </S.TooltipItem>
      </Tooltip>
    );
  });
};

export default SelectedMetrics;
