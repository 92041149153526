import { useState } from 'react';
import { useQuery } from 'react-query';

import { fetchUserCredits, getGuidesOrders } from 'modules/api';
import { formatDateTime4 } from 'modules/v2/common/utils';
import {
  PrimaryButton,
  PrimaryBlackButton,
  SecondaryBlackButton,
  Modal,
  Loader,
} from 'modules/v2/common/components';
import {
  isGuideOrPacket,
  bookCreditsContentTitle,
  bookCreditsContentDescription,
  isGuide,
} from 'modules/v2/pages/Dentist/OrderGuideModal/constants';
import { CoverImage } from 'modules/editor/components';
import { DashboardTemplate } from 'modules/v2/templates';
import { DentistSideNav } from 'modules/v2/components';
import { PaymentSteps } from 'modules/v2/pages/PaymentSteps';
import OrderGuideModal from 'modules/v2/pages/Dentist/OrderGuideModal';
import { goToMyOrders } from 'modules/v2/draft/store';

import S from './styles';

const OrderedGuides = ({ draftId, onReorder }) => {
  getGuidesOrders();

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [showPaymentSteps, setShowPaymentSteps] = useState(false);
  const [currentOrderPage, setCurrentOrderPage] = useState(1);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [guidePrefillFormData, setGuidePrefillFormData] = useState({});

  const perPage = 10;
  const [guidePacketName, setGuidePacketName] = useState('guide');
  const { data: userBookCredit } = useQuery(['getUserCredits'], fetchUserCredits);
  const { data: guidesOrders, isLoading: isLoadingOrders, refetch: refetchGuides } = useQuery(
    ['getGuidesOrders', currentOrderPage, perPage],
    () => getGuidesOrders(currentOrderPage, perPage),
  );

  const orderList = guidesOrders?.data || [];
  const orderListMeta = guidesOrders?.meta || {};

  const handlePageChange = (newCurrentPage) => setCurrentOrderPage(newCurrentPage);

  const pagination = {
    pageSize: orderListMeta?.perPage,
    total: orderListMeta?.total,
    lastPage: orderListMeta?.lastPage,
    currentPage: orderListMeta?.currentPage,
    onChange: handlePageChange,
  };

  const bookCredit = userBookCredit?.data?.data?.credits || 0;

  // Handle Reorder Flow
  const [payloadData, setPayloadData] = useState({
    practiceName: '',
    practicePhone: '',
    practiceEmail: '',
    practiceLogo: '',
    practiceWebsite: '',
    quantity: 0,
    guideName: '',
    guideId: '',
    thumbnail: '',
    landingPage: '',
    frontCover: [],
    practiceAddress: {
      addressLine1: '',
      city: '',
      state: '',
      pincode: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    shippingAddress: {
      addressLine1: '',
      city: '',
      state: '',
      pincode: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  const handleReorder = (item) => {
    setGuidePacketName(isGuideOrPacket(item.guideId));

    const isCreditLow = isGuide(item.guideId) ? bookCredit < 10 : bookCredit < 30;
    setIsWarningModalOpen(isCreditLow);

    if (!isCreditLow) {
      setGuidePrefillFormData(item);
      setPayloadData({
        ...payloadData,
        guideName: item.guideName,
        thumbnail: item.thumbnail,
        guideId: item.guideId,
        landingPage: item.landingPage,
        practiceEmail: item.practiceEmail,
        practiceLogo: item.practiceLogo,
        practiceName: item.practiceName,
        practicePhone: item.practicePhone,
        practiceWebsite: item.practiceWebsite,
        frontCover: item.frontCover,
        practiceAddress: {
          addressLine1: item.practiceAddress?.addressLine1,
          city: item.practiceAddress?.city,
          state: item.practiceAddress?.state,
          pincode: item.practiceAddress?.pincode,
          country: item.practiceAddress?.country,
          email: item.practiceAddress?.email,
          firstName: item.practiceAddress?.firstName,
          lastName: item.practiceAddress?.lastName,
        },
      });
      setPublishModalOpen(true);
    }
  };

  const handleShowstepWarningModal = () => {
    setShowPaymentSteps(true);
    setIsWarningModalOpen(false);
  };

  const handleCancelPublish = () => {
    setPublishModalOpen(false);
  };

  const handleClosePublish = () => {
    setPublishModalOpen(false);
  };

  const noContent = <S.ModalFooterContainer>No Records Found</S.ModalFooterContainer>;

  const content = orderList.map((item) => {
    const shippingAddress = `${item.shippingAddress?.addressLine1}, ${item.shippingAddress?.city}, ${item.shippingAddress?.state}, ${item.shippingAddress?.pincode}, ${item.shippingAddress?.country}`;
    return (
      <S.Wrapper key={item.orderId}>
        <S.SideContent>
          <S.ImageWrapper>
            <CoverImage url={item.thumbnail} />
          </S.ImageWrapper>
        </S.SideContent>
        <S.MainContent>
          <S.BookName>{item.guideName}</S.BookName>
          <S.Details>
            <div>
              <S.DetailsTitle>Order Number: </S.DetailsTitle>
              <S.DetailsInformation>{item.orderId}</S.DetailsInformation>
            </div>
            <div>
              <S.DetailsTitle>Order Date: </S.DetailsTitle>
              <S.DetailsInformation>{formatDateTime4(item.createdAt)}</S.DetailsInformation>
            </div>
            <div>
              <S.DetailsTitle>Last Updated: </S.DetailsTitle>
              <S.DetailsInformation>{formatDateTime4(item.updatedAt)}</S.DetailsInformation>
            </div>
            <div>
              <S.DetailsTitle>Quantity: </S.DetailsTitle>
              <S.DetailsInformation>{item.quantity}</S.DetailsInformation>
            </div>
            <div>
              <S.DetailsTitle>Address: </S.DetailsTitle>
              <S.DetailsInformation>{shippingAddress}</S.DetailsInformation>
            </div>
          </S.Details>
          <S.LinksList>
            <PrimaryButton onClick={() => handleReorder(item)}>Re-order</PrimaryButton>
          </S.LinksList>
        </S.MainContent>
        <Modal
          isOpen={isWarningModalOpen}
          maskClosable
          title="Warning"
          width="auto"
          footer={[
            <S.ModalFooterContainer key={1}>
              <PrimaryBlackButton style={{ width: '80%' }} onClick={handleShowstepWarningModal}>
                Buy Credits
              </PrimaryBlackButton>
              <SecondaryBlackButton
                style={{ width: '80%' }}
                onClick={() => setIsWarningModalOpen(false)}
              >
                Cancel, I'll buy later
              </SecondaryBlackButton>
            </S.ModalFooterContainer>,
          ]}
        >
          <S.ContentContainer>
            <S.ContentTitle>
              You have <span>{bookCredit}</span> left
            </S.ContentTitle>
            <S.ContentDescription>{bookCreditsContentTitle(guidePacketName)}</S.ContentDescription>
            <S.ContentDescription>
              {bookCreditsContentDescription(guidePacketName)}
            </S.ContentDescription>
          </S.ContentContainer>
        </Modal>
        {showPaymentSteps && (
          <PaymentSteps
            setShowPaymentSteps={setShowPaymentSteps}
            showPaymentSteps={showPaymentSteps}
            type="guide"
          />
        )}
      </S.Wrapper>
    );
  });

  const paginationContent = (
    <S.PaginationContent>
      <S.Pagination
        current={pagination?.currentPage || 1}
        total={pagination?.total || 0}
        pageSize={pagination?.pageSize || 10}
        onChange={pagination?.onChange}
      />
    </S.PaginationContent>
  );

  const loadingContent = <Loader height="calc(100vh - 400px)" title="Loading Ordered Guides" />;

  return (
    <DashboardTemplate sidebar={<DentistSideNav />} contentTitle="Ordered Guides" hasSidebar>
      <S.Container>
        {isLoadingOrders ? (
          loadingContent
        ) : (
          <>
            {orderList.length ? content : noContent}
            {pagination?.lastPage > 1 && paginationContent}
          </>
        )}
      </S.Container>
      {publishModalOpen && (
        <OrderGuideModal
          isOpen
          draftId={draftId}
          title={`Title: ${payloadData.guideName.toUpperCase()}`}
          onCancel={handleCancelPublish}
          onClose={handleClosePublish}
          setPayloadData={setPayloadData}
          payloadData={payloadData}
          onComplete={goToMyOrders}
          guidePrefillFormData={guidePrefillFormData}
          refetchGuides={refetchGuides}
          fromScreen="OrderedGuides"
          guidePacketName={guidePacketName}
        />
      )}
    </DashboardTemplate>
  );
};

export default OrderedGuides;
