import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { HiChevronRight } from 'react-icons/hi2';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getOrderedBooksRoute } from 'modules/v2/routes/navigation';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import { compose } from 'redux';
import { Box, Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';

import ShippingIcon from 'modules/common/components/SvgIcon/ShippingIcon';
import DatabaseIcon from 'modules/common/components/SvgIcon/Database';
import { AddCircleIcon } from 'modules/v2/common/components/SvgIcon';
import InfoCircle from 'modules/common/components/SvgIcon/InfoCircle';
import QuantitySelector from 'modules/v2/common/AtomicDesign/molecules/QuantitySelector';
import { notification } from 'modules/common/utils';
import { ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import ConfirmationModal from 'modules/v2/common/AtomicDesign/organisms/ConfirmationModal';
import { AddressFormView } from 'modules/v2/common/views/AddressView/views';
import ShippingAddressSelect from 'modules/v2/draft/pages/Checkout/ShippingAddressSelect';
import ShippingNewAddress from 'modules/v2/draft/pages/Checkout/ShippingNewAddress';
import { withAddress } from 'modules/v2/containers';
import {
  fetchPreFlightCheck,
  fetchUserAddresses,
  fetchUserCredits,
  regenerateOrder,
} from 'modules/api';
import {
  MINIMUM_CHECKOUT_BOOK_QUANTITY,
  MINIMUM_CHECKOUT_BOOK_QUANTITY_FOR_DIGITAL_SUB,
} from 'modules/v2/pages/constants';
import { STATUS_SUCCESS } from 'store/status';
import { withPublish } from 'modules/v2/draft/containers';
import PaymentCardsSkeleton from 'modules/v2/common/AtomicDesign/atoms/PaymentCardsSkeleton';
import { BooksContext } from '../../context';
import { customLoadingModalTheme } from './theme';

const BookCheckout = ({
  setActivePage,
  countries,
  states,
  addUserAddresses,
  submitReorder,
  order,
  clearPublish,
}) => {
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [availableShippingAddresses, setAvailableShippingAddresses] = useState([]);
  const history = useHistory();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const isDigitalCustomer = useCustomerPlanInfo();
  const [isReadyToPreFlightCheck, setIsReadyToPreFlightCheck] = useState(false);

  const {
    bookQuantity,
    setBookQuantity,
    isBookQuantityValid,
    setIsBookQuantityValid,
    selectedOrderContext,
    setUserAddressesContext,
    setUserCredits,
    userCredits,
    clearContext,
  } = useContext(BooksContext);

  const addressFormRef = useRef();

  const validateQuantity = (value) => {
    if (bookQuantity >= MINIMUM_CHECKOUT_BOOK_QUANTITY && bookQuantity <= value) {
      setIsBookQuantityValid(true);
    } else {
      setIsBookQuantityValid(false);
    }
  };

  const {
    refetch: fetchUserAddressesRefetch,
    isRefetching: isRefetchingUserAddress,
    isLoading: isLoadingUserAddress,
  } = useQuery('fetchUserAddresses', fetchUserAddresses, {
    onSuccess: (res) => {
      const {
        data: { data },
      } = res;
      setUserAddressesContext(data);
      const { shippingAddress } = data;
      setAvailableShippingAddresses(shippingAddress);

      const defaultSelectedAddress = shippingAddress.find((address) => address.isDefault === true);
      if (defaultSelectedAddress) {
        setSelectedAddress(defaultSelectedAddress);
      } else if (shippingAddress.length === 1) {
        setSelectedAddress(shippingAddress[0]);
      } else {
        setSelectedAddress(null);
      }
    },
    onError: (error) => {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    },
  });

  const handleAddressFormAndSubmit = async () => {
    if (addressFormRef.current) {
      await addressFormRef.current.submit();
    }
    setIsReadyToPreFlightCheck(true);
  };

  const handleAddShippingAddress = async (fields: object) => {
    await addUserAddresses({ ...fields, isDefault: true });
    await fetchUserAddressesRefetch();
    setIsReadyToPreFlightCheck(true);
  };

  const validateQuantityEvent = (e) => {
    if (e.target.value >= MINIMUM_CHECKOUT_BOOK_QUANTITY && e.target.value <= userCredits) {
      setIsBookQuantityValid(true);
    } else {
      setIsBookQuantityValid(false);
    }
  };

  const {
    refetch: refetchUserCredits,
    isRefetching: isRefetchingUserCredits,
    isLoading: isLoadingUserCredits,
  } = useQuery('fetchUserCredits', fetchUserCredits, {
    onSuccess: (res) => {
      setUserCredits(res?.data?.data?.credits || 0);
      validateQuantity(parseInt(res?.data?.data?.credits));
    },
    onError: (error) => {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    },
  });

  const handleClosePreviewModal = () => {
    setShowShippingModal(false);
  };

  const { mutate: mutateRegenerateOrder } = useMutation(regenerateOrder, {
    onSuccess: (data) => {
      setShowConfirmationModal(true);
      setShowLoadingModal(false);
    },
    onError: () => {
      setShowLoadingModal(false);
      setShowConfirmationModal(false);
      notification.error({ description: 'Something went wrong during place order operation.' });
    },
  });

  const handlePreflightCheck = async () => {
    try {
      setShowLoadingModal(true);
      await fetchPreFlightCheck({ draftId: selectedOrderContext.draftId });
      mutateRegenerateOrder({
        draftId: selectedOrderContext.draftId,
        isDigital: false,
        numberOfPrints: bookQuantity,
        selectedAddressId: selectedAddress._id,
        parentOrderId: selectedOrderContext.orderId,
      });
    } catch (error) {
      console.error(error);
      setShowLoadingModal(false);
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Error during pre-flight check',
        autoClose: false,
      });
    }
  };

  const handleShowShippingModal = () => {
    setShowShippingModal(true);
  };

  const handleCheckoutSubmit = async () => {
    try {
      if (addressFormRef.current) {
        const validation = await addressFormRef?.current?.validate();
        if (Object.keys(validation).length > 0) {
          return;
        }
      } else if (!selectedAddress) {
        notification.warning({
          description: 'Please select a shipping address',
          autoClose: false,
        });
        return;
      }

      await handleAddressFormAndSubmit();
    } catch (error) {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    }
  };

  useEffect(() => {
    validateQuantityEvent({ target: { value: bookQuantity } });
  }, [bookQuantity, setBookQuantity]);

  const handleConfirmation = () => {
    clearContext();
    setActivePage({
      id: 'ordered-books',
      title: 'Ordered books',
    });
    history.push(getOrderedBooksRoute());
  };

  const thumbnailUrl = useMemo(() => selectedOrderContext?.thumbnail?.url, [selectedOrderContext]);
  const bookTitle = useMemo(() => selectedOrderContext?.bookTitle, [selectedOrderContext]);

  const BookThumbnail = ({ src, alt, className }) => (
    <div
      className={`h-32 md:h-48 overflow-hidden rounded-lg relative w-auto transition-transform duration-200 ${className}`}
    >
      <img
        src={src}
        alt={alt}
        className="h-full max-w-none object-cover rounded-lg transition-transform duration-200"
      />
    </div>
  );

  const renderInLineAddShippingAddress = () => {
    if (isLoadingUserAddress || isRefetchingUserAddress) {
      return <PaymentCardsSkeleton />;
    }
    return availableShippingAddresses.length === 0 ? (
      <AddressFormView
        countries={countries.data}
        states={states.data}
        onSubmit={handleAddShippingAddress}
        hasLeadingMessage={false}
        hasSubmitButton={false}
        hasDefaultToggle={false}
        addressFormRef={addressFormRef}
      />
    ) : (
      <Button onClick={handleShowShippingModal} type="bare">
        <AddCircleIcon className="mr-2" /> Add new address
      </Button>
    );
  };

  useEffect(() => {
    if (isReadyToPreFlightCheck) {
      handlePreflightCheck();
      setIsReadyToPreFlightCheck(false);
    }
  }, [isReadyToPreFlightCheck]);

  useEffect(() => {
    if (order.status === STATUS_SUCCESS) {
      setShowConfirmationModal(true);
      setShowLoadingModal(false);
      clearPublish();
    }
  }, [order]);

  return (
    <>
      <div id="breadcrumbs" className="flex gap-2 items-center">
        <span className="cursor-pointer hover:bg-neutral-100">
          {selectedOrderContext.bookTitle}
        </span>{' '}
        <HiChevronRight />
        <span className="border-b-[1px] border-neutral-300">Review order</span>
      </div>
      <Box className="mt-6">
        <div className="flex justify-between items-center border-b-[1px] p-7">
          <span className="text-lg font-semibold">Review your order</span>
        </div>

        <div className="p-7 flex max-[1365px]:flex-col gap-[24px]">
          <div className="w-1/2 max-[1365px]:w-full">
            <div id="available-credits" className="justify-items-start mb-7">
              <div className="flex gap-2 items-center mb-4">
                <DatabaseIcon /> <span className="text-sm font-semibold">Available credits</span>
              </div>
              <TextInput disabled value={`${userCredits} credits`} />
            </div>
            <div id="shipping-address-" className="justify-items-start">
              <div className="flex gap-2 items-center mb-4">
                <ShippingIcon /> <span className="text-sm font-semibold">Ship to</span>
              </div>
              <ShippingAddressSelect
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                shippingAddressList={availableShippingAddresses}
                isRefeching={isRefetchingUserAddress}
                additionalShippingLoading={isRefetchingUserAddress || isLoadingUserAddress}
              />
              <span className="flex items-center">{renderInLineAddShippingAddress()}</span>

              <div className="bg-neutral-100 rounded-lg p-4 mb-5 mt-5">
                <span className="text-sm text-neutral-700 flex items-center">
                  <InfoCircle className="mr-2" /> Any images added to the books will be printed in
                  black and white
                </span>
              </div>
            </div>
          </div>
          <div className="w-1/2 max-[1365px]:w-full">
            <div id="summary" className="justify-items-start bg-neutral-50 rounded-lg p-7">
              <span className="text-2xl font-semibold">Summary</span>

              <div className="mt-7 gap-[14px] flex max-[510px]:flex-col max-[510px]:items-center">
                <div className="flex gap-2 items-center mb-2">
                  <BookThumbnail
                    src={thumbnailUrl}
                    alt={bookTitle}
                    className="group-hover:scale-105 min-w-24"
                  />
                </div>

                <div>
                  <div className="flex gap-2 items-center mb-2">
                    <span className="text-sm font-semibold">{selectedOrderContext?.bookTitle}</span>
                  </div>
                  <QuantitySelector
                    onChange={({ target: { value } }) => setBookQuantity(value)}
                    value={bookQuantity}
                    maximum={userCredits}
                    minimum={
                      isDigitalCustomer.isDigital && !isDigitalCustomer.canOrderMoreThanTenBooks
                        ? MINIMUM_CHECKOUT_BOOK_QUANTITY_FOR_DIGITAL_SUB
                        : MINIMUM_CHECKOUT_BOOK_QUANTITY
                    }
                    disabled={
                      isDigitalCustomer.isDigital && !isDigitalCustomer.canOrderMoreThanTenBooks
                    }
                    step={4}
                    isValid={isBookQuantityValid}
                    errorMessage={
                      userCredits ? `Please enter a number between 8 and ${userCredits}` : ''
                    }
                    containerClassName="justify-start max-[510px]:justify-center"
                  />
                </div>
              </div>

              <div className="flex w-full justify-between items-center mt-6 mb-6">
                <div className=''>
                  <span className="text-sm text-neutral-400">Shipping</span>
                </div>

                <div className="">
                  <span className="text-sm text-neutral-400">free</span>
                </div>
              </div>

              <hr className="w-full border border-neutral-200 my-4" />

              <div className="flex w-full justify-between items-center mt-6 mb-6">
                <div className=''>
                  <span className="text-base font-semibold">Total due today</span>
                </div>

                <div className="">
                  <span className="text-base font-semibold">{bookQuantity} credits</span>
                </div>
              </div>

              <Row className="mt-7 w-full">
                <Button
                  onClick={handleCheckoutSubmit}
                  disabled={
                    isLoadingUserCredits || isRefetchingUserCredits || isRefetchingUserAddress
                  }
                  wrapperClassName="w-full"
                  full
                >
                  Place order
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </Box>

      <ShippingNewAddress
        openAddressForm={showShippingModal}
        setOpenAddressForm={setShowShippingModal}
        fetchUserAddressesRefetch={fetchUserAddressesRefetch}
      />

      <ProcessingModal
        title="Processing your order..."
        description="Please don’t close this window"
        showModal={showLoadingModal}
        setShowModal={setShowLoadingModal}
        theme={customLoadingModalTheme}
      />

      <ConfirmationModal
        title="Thank you. Your order is confirmed!"
        description="Please allow 7-10 days for us to print and ship your order."
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        onConfirmation={handleConfirmation}
        confirmationText="View order"
      />
    </>
  );
};

export default compose(withAddress, withPublish)(BookCheckout);
