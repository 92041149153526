import { call, select, takeEvery } from 'redux-saga/effects';

import { saveDraftPerSection, resource, getError } from 'modules/api';

import {
  bookIdSelector,
  draftIdSelector,
  draftNameSelector,
  sectionByIdSelector,
} from 'modules/editor/store';
import { SAVE_CONTENT } from 'modules/editor/store/constants';

export function* onSaveContent({ payload: meta }) {
  try {
    const { sectionId } = meta;
    const bookId = yield select(bookIdSelector);
    const draftId = yield select(draftIdSelector);
    const draftName = yield select(draftNameSelector);
    const section = yield select(sectionByIdSelector(sectionId));

    const request = resource(SAVE_CONTENT, saveDraftPerSection);
    yield call(request, {
      bookId,
      draftId,
      draftName,
      section,
    });

    return true;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);

    return false;
  }
}

export function* watchSaveContent() {
  yield takeEvery(SAVE_CONTENT, onSaveContent);
}
