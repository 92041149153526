export const LockIcon = ({ width = 20, height = 20, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.37533 8.37868V6.6665C4.37533 3.5599 6.89372 1.0415 10.0003 1.0415C13.1069 1.0415 15.6253 3.5599 15.6253 6.6665V8.37868C16.5543 8.44806 17.1592 8.62321 17.6014 9.0654C18.3337 9.79764 18.3337 10.9761 18.3337 13.3332C18.3337 15.6902 18.3337 16.8687 17.6014 17.6009C16.8692 18.3332 15.6907 18.3332 13.3337 18.3332H6.66699C4.30997 18.3332 3.13146 18.3332 2.39923 17.6009C1.66699 16.8687 1.66699 15.6902 1.66699 13.3332C1.66699 10.9761 1.66699 9.79764 2.39923 9.0654C2.84142 8.62321 3.44636 8.44806 4.37533 8.37868ZM5.62533 6.6665C5.62533 4.25026 7.58408 2.2915 10.0003 2.2915C12.4166 2.2915 14.3753 4.25026 14.3753 6.6665V8.33615C14.0561 8.33317 13.7099 8.33317 13.3337 8.33317H6.66699C6.29073 8.33317 5.9445 8.33317 5.62533 8.33615V6.6665Z"
      fill={fill}
    />
  </svg>
);
