import { Button as BaseButton } from 'antd';
import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const Content = styled.div`
  padding: 30px;
  font-size: 18px;
  font-size: 16px;
`;

const ContentConfirm = styled.div`
  padding: 30px 30px 0;
  font-size: 14px;
`;

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
  font-size: 22px;
`;

const ButtonPrimary = styled(BaseButton)`
  height: 40px;
  font-size: 16px;
  margin-bottom: 25px;
  margin-left: 10px;
  &,
  &:hover,
  &:focus {
    color: ${color.White};
    background-color: ${color.PrimaryColor};
    border-color: ${color.PrimaryColor};
  }
`;

const ButtonSecondary = styled(ButtonPrimary)`
  &,
  &:hover,
  &:focus {
    color: ${color.PrimaryColor};
    background-color: ${color.White};
    border-color: ${color.PrimaryColor};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default {
  Content,
  ContentConfirm,
  ButtonPrimary,
  ButtonSecondary,
  ButtonGroup,
  Title,
};
