const PlanIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_4265" data-name="Group 4265" transform="translate(-800 -262)">
      <rect
        id="Rectangle_1007"
        data-name="Rectangle 1007"
        width="24"
        height="24"
        transform="translate(800 262)"
        fill="none"
      />
      <path
        id="profile"
        d="M127.36,112H112.64a.639.639,0,0,0-.64.64v14.72a.639.639,0,0,0,.64.64h14.72a.639.639,0,0,0,.64-.64V112.64A.639.639,0,0,0,127.36,112Zm-.8,14.56H113.44V113.44h13.12Zm-6.96-8.8h3.68a.16.16,0,0,0,.16-.16v-.96a.16.16,0,0,0-.16-.16H119.6a.16.16,0,0,0-.16.16v.96A.16.16,0,0,0,119.6,117.76Zm0,2.88h3.68a.16.16,0,0,0,.16-.16v-.96a.16.16,0,0,0-.16-.16H119.6a.16.16,0,0,0-.16.16v.96A.16.16,0,0,0,119.6,120.64Zm0,2.88h3.68a.16.16,0,0,0,.16-.16v-.96a.16.16,0,0,0-.16-.16H119.6a.16.16,0,0,0-.16.16v.96A.16.16,0,0,0,119.6,123.52Zm-3.04-6.4a.8.8,0,1,0,.8-.8A.8.8,0,0,0,116.56,117.12Zm0,2.88a.8.8,0,1,0,.8-.8A.8.8,0,0,0,116.56,120Zm0,2.88a.8.8,0,1,0,.8-.8A.8.8,0,0,0,116.56,122.88Z"
        transform="translate(692 154)"
        fill="#003a8c"
      />
    </g>
  </svg>
);

export default PlanIcon;
