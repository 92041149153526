import env from 'environments';

/**
 * @summary Serializes and sets value in localStorage
 * @param {*} key: string
 * @param {*} value
 */
export const setItem = (key, value) => {
  let storedValue = value;

  if (typeof value !== 'string' && typeof value !== 'boolean') {
    storedValue = JSON.stringify(value);
  }

  return localStorage.setItem(key, storedValue);
};

/**
 * @summary Gets value from localStorage and Deserializes it.
 * @param {*} key: string
 * @param {*} type
 */
export const getItem = (key, type = 'string') => {
  const item = localStorage.getItem(key);

  if (type === 'string') {
    return item;
  }

  if (type === 'number') {
    return parseInt(item, 10);
  }

  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {
    return item;
  }
};

/**
 * @summary Removes a value from localStorage.
 * @param {*} key: string
 */
export const removeItem = (key) => localStorage.removeItem(key);

/**
 * @summary Gets a user details.
 */
export const getUser = () => ({ userId: env.USER_ID });

/**
 * @summary: Sets draft in localStorage
 */
export const setBook = (key, value) => {
  let book = getItem('book', 'object');
  book = { ...book, [key]: value };
  setItem('book', book);
};

/**
 * @summary: Gets drafts in localStorage
 */
export const getBook = (key) => {
  const book = getItem('book', 'object');
  return book[key];
};

/**
 * @summary: Sets draft in localStorage
 */
export const setDraft = (key, value) => {
  let draft = getItem('draft', 'object');
  draft = { ...draft, [key]: value };
  setItem('draft', draft);
};

/**
 * @summary: Gets drafts in localStorage
 */
export const getDraft = (key) => {
  const draft = getItem('draft', 'object');
  return draft ? draft[key] : null;
};

/**
 * @summary: Sets template in localStorage
 */
export const setTemplate = (key, value) => {
  let template = getItem('template', 'object');
  template = { ...template, [key]: value };
  setItem('template', template);
};

/**
 * @summary: Gets template in localStorage
 */
export const getTemplate = (key) => {
  const template = getItem('template', 'object');
  return template ? template[key] : null;
};

/**
 * @summary: Sets section in localStorage
 */
export const setSection = (key, value) => {
  let section = getItem('section', 'object');
  section = { ...section, [key]: value };
  setItem('section', section);
};

/**
 * @summary: Gets section in localStorage
 */
export const getSection = (key) => {
  const section = getItem('section', 'object');
  return section ? section[key] : null;
};

/**
 * @summary: Limits the amount of times a function is called
 */
export const debounce = (fn, delay) => {
  let inDebounce;
  return () => {
    const context = this;
    const args = '';
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => fn.apply(context, args), delay);
  };
};

/**
 * @summary: Clears the localStorage
 */
export const clearStorage = () => {
  localStorage.clear();
};
/**
 * @summary: Get Key By value
 */
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};
/**
 * @summary: Update Section Order w.r.t. to Book
 */
export const updateOrder = (book, unOrderedSections, sectionOrder, getKeyByValueCallback) => {
  unOrderedSections.forEach((section) => {
    const sectionFinder =
      (order, index) =>
        ({ _id }) =>
          _id === getKeyByValueCallback(order, index);

    const maxOrder =
      Object.values(sectionOrder).length > 0 ? Math.max(...Object.values(sectionOrder)) : 0;

    if (section.type === 'Cover') {
      // eslint-disable-next-line
      sectionOrder[section._id] = 0;
    } else if (section.type !== 'Chapter') {
      let i = 3;
      for (i = 3; i <= maxOrder; i++) {
        const accessedSection = book.sections.find(sectionFinder(sectionOrder, i));
        if (accessedSection.type === 'Chapter') {
          break;
        }
      }
      let j = i;
      for (j = maxOrder; j >= i; j--) {
        const accessedSection = book.sections.find(sectionFinder(sectionOrder, j));
        // eslint-disable-next-line
        sectionOrder[accessedSection._id] = j + 1;
      }
      // eslint-disable-next-line
      sectionOrder[section._id] = i;
    } else if (section.type === 'Chapter') {
      let i = 3;
      for (i = 3; i <= maxOrder; i++) {
        const accessedSection = book.sections.find(sectionFinder(sectionOrder, i));
        if (accessedSection.type === 'Chapter') {
          break;
        }
      }

      let j = i;
      for (j = i; j <= maxOrder; j++) {
        const accessedSection = book.sections.find(sectionFinder(sectionOrder, j));
        if (accessedSection.type !== 'Chapter') {
          break;
        }
      }
      if (j <= maxOrder) {
        let k = j;
        for (k = maxOrder; k >= j; k--) {
          const accessedSection = book.sections.find(sectionFinder(sectionOrder, k));
          // eslint-disable-next-line
          sectionOrder[accessedSection._id] = k + 1;
        }
      }
      // eslint-disable-next-line
      sectionOrder[section._id] = j;
    }
  });

  return sectionOrder;
};
