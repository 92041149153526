import { GET_PAGES } from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  odd: false,
  index: [],
  pages: [],
  pageHtml: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PAGES}_REQUEST`:
      return { ...state, loading: true };

    case `${GET_PAGES}`:
      const [front_cover_page] = action.payload.data;
      const pages = action.payload.data.filter((page) => page.type !== 'Cover');
      return {
        ...state,
        loading: false,
        loaded: true,
        pages,
        pageHtml: front_cover_page.pageHtml,
      };

    default:
      return { ...state };
  }
};
