import React from 'react';
import { Redirect, Switch } from 'react-router';

import { getRoute } from './types';

const Renderer = ({ config }) => (
  <Switch>
    {config.map(({ type, children, path = '', ...props }) => {
      const Route = getRoute(type);

      if (props.redirect) {
        return <Redirect key={path} path={path} to={props.redirect} />;
      }

      return (
        <Route key={path} path={path} {...props}>
          {Array.isArray(children) && <Renderer config={children} />}
        </Route>
      );
    })}
  </Switch>
);

export default React.memo(Renderer);
