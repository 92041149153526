import books from './books';

import sections from './sections';
import templates from './templates';
import browsebooks from './browsebooks';
import categories from './categories';
import drafts from './drafts';
import modal from './modal';
import alert from './alert';

import auth from './auth';
import dashboard from './dashboard';
import credit from './credit';
import useraddress from './useraddress';
import order from './order';
import contactform from './contactform';
import editprofile from './editprofile';
import admin from './admin';
import managesections from './managesections';
import adminControls from './adminControls';
import preview from './preview';
import leads from './leads';
import bundleAccess from './bundleAccess';

export default {
  bundleAccess,
  books,
  sections,
  templates,
  browsebooks,
  categories,
  drafts,
  modal,
  alert,
  preview,
  auth,
  dashboard,
  credit,
  useraddress,
  order,
  contactform,
  editprofile,
  admin,
  managesections,
  adminControls,
  leads,
};
