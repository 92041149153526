import withStore from 'store/StoreContainer';

import { createDraft, goToRoot } from 'modules/v2/editor/store';

const mapStateToProps = ({ editor }) => ({
  book: editor.book,
});

const mapDispatchToProps = {
  createDraft,
  goToRoot,
};

export default withStore(mapStateToProps, mapDispatchToProps);
