import { getInstance } from './instance';

const instance = getInstance();

export const updateAfyPassword = (body) => {
  const url = '/hubspot/update-afy-password';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getContactByemail = (email) => {
  const url = `/hubspot/contact/${email}`;
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const verifyProductByName = (body) => {
  const url = '/hubspot/verify-product';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const submitHSForm = (portalId, formId, fields, context) => {
  const payload = {
    portalId,
    formId,
    fields,
    context,
  };

  return instance
    .post('/hubspot/submit-form', payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getDealBySubscriptionId = (subscriptionId) => {
  const url = `/hubspot/deal/${subscriptionId}`;
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const createQuotation = (payload) => {
  const url = '/hubspot/create-quotation';
  return instance
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
