//

import { Item } from './components';

import S from './styles';

const OrderList = ({ children }) => {
  if (!children) {
    return null;
  }

  return <S.Wrapper>{children}</S.Wrapper>;
};

OrderList.Item = Item;

export default OrderList;
