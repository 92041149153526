import { getInstance } from '../instance';
import { getInstanceWithRefreshToken } from '../instanceWithRefreshToken';
import { setItem } from '../../../dashboard/utils/legacy';
import { log, logError } from '../../afyLogger/afyLogger';

const instance = getInstance();
const instanceWithRefreshToken = getInstanceWithRefreshToken();

const getAutoLoginAuthToken = (token: string, username: string) => {
  const url = '/auth/token';
  const params = { token, username };

  return instance
    .get(url, { params })
    .then((res) => res.data.data)
    .catch((error) => Promise.reject(error));
};

const authUser = (payload) => {
  const url = '/auth/signin';

  return instance
    .post(url, payload)
    .then((res) => {
      const { data } = res.data;
      if (data?.token) {
        setItem('token', data.token);
        setItem('refresh_token', data.refresh_token);
        setItem('userData', data.properties);
      }
      log('login', 'login-page');
      return res.data;
    })
    .catch((error) => {
      logError('login', 'login-page', payload?.username);
      return Promise.reject(error);
    });
};

const forgetPassword = (params) => {
  const url = '/auth/forgotpassword';

  return instance
    .get(url, { params })
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error));
};

const getNewAuthToken = () => {
  const url = '/auth/regenerate-token';

  return instanceWithRefreshToken
    .get(url)
    .then((res) => {
      const { data } = res.data;
      setItem('token', data.token);
      setItem('refresh_token', data.refresh_token);
      return res.data.data;
    })
    .catch((error) => Promise.reject(error));
};

export { getAutoLoginAuthToken, getNewAuthToken, authUser, forgetPassword };
