import { generatePath } from 'react-router-dom';

import {
  ROUTE_LANDING_PAGES_CLICKFUNNELS,
  ROUTE_LANDING_PAGES_CLICKFUNNELS_UPSELL,
  ROUTE_LANDING_PAGES_CONGRATS,
} from './constants';

export const getRouteLandingPageClickFunnel = (funnelCode: string) =>
  generatePath(ROUTE_LANDING_PAGES_CLICKFUNNELS, { funnelCode });

export const getRouteLandingPageClickFunnelUpsell = (funnelCode: string, queryString: string) =>
  generatePath(ROUTE_LANDING_PAGES_CLICKFUNNELS_UPSELL, { funnelCode, queryString }) +
  (queryString ? `${queryString}` : '');

export const getRouteLandingPageCongrats = (funnelCode: string, queryString: string) =>
  generatePath(ROUTE_LANDING_PAGES_CONGRATS, { funnelCode, queryString });
