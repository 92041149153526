import { FilePicker, Modal } from 'modules/v2/common/components';

import {
  COVER_IMAGE_GROUP_UPLOAD,
  COVER_IMAGE_GROUP_IMAGE,
  COVER_IMAGE_GROUP_LOGO,
} from 'modules/v2/editor/utils/constants';

import { ImagePicker } from './components';

const ModalContent = ({
  // DATA
  images,
  logos,
  type,
  isImagesLoading,
  isLogosLoading,

  // CALLBACKS
  onChangeTab,
  onDelete,
  onGalleryTabOpen,
  onLogosTabOpen,
  onSelect,
  onSuccess,
}) => {
  const uploadTab = (
    <Modal.Tabs.TabPane tab="Upload" key={COVER_IMAGE_GROUP_UPLOAD}>
      <FilePicker
        type={type}
        onSelect={onSelect}
        isEditImage={false}
        toggleCancelEdit={null}
        imageUrlForEdit={null}
        onSuccess={onSuccess}
      />
    </Modal.Tabs.TabPane>
  );

  const galleryTab = (
    <Modal.Tabs.TabPane tab="Photo gallery" key={COVER_IMAGE_GROUP_IMAGE}>
      <ImagePicker
        images={images}
        isLoading={isImagesLoading}
        type={COVER_IMAGE_GROUP_IMAGE}
        onDelete={onDelete}
        onOpen={onGalleryTabOpen}
        onSelect={onSelect}
      />
    </Modal.Tabs.TabPane>
  );

  const logoTab = type === COVER_IMAGE_GROUP_LOGO && (
    <Modal.Tabs.TabPane tab="Logo Gallery" key={COVER_IMAGE_GROUP_LOGO}>
      <ImagePicker
        images={logos}
        isLoading={isLogosLoading}
        type={COVER_IMAGE_GROUP_LOGO}
        onOpen={onLogosTabOpen}
        onSelect={onSelect}
      />
    </Modal.Tabs.TabPane>
  );

  return (
    <Modal.Tabs defaultActiveKey="1" type="card" onChange={onChangeTab}>
      {uploadTab}
      {galleryTab}
      {logoTab}
    </Modal.Tabs>
  );
};

export default ModalContent;
