import { useMutation } from 'react-query';
import { getPaymentProfilesByEmail } from 'modules/api';

interface IOptions {
  onSuccess: (data: any) => void;
  onError: (error: string, actions: any) => void;
}

const useGetPaymentProfiles = (options: IOptions) =>
  useMutation('getPaygmentsProfileByEmail', getPaymentProfilesByEmail, options);

export default useGetPaymentProfiles;
