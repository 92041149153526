//

import { Provider } from 'modules/common/components/DraftContext';
import Scrollbars from 'modules/common/components/Scrollbars';

import EditorToolbar from 'modules/v2/editor/components/EditorToolbar';
import EditorElement from 'modules/v2/editor/components/EditorElement';

import S from './styles';

const ModalContent = ({ element, isSimple, isExtended, scale, onChange }) => (
  <Provider elements={[element]} onChange={onChange}>
    <EditorToolbar simple={isSimple} extended={isExtended} />
    <Scrollbars
      renderInLayers
      style={{
        height: '300px',
      }}
    >
      <S.Wrapper>
        <EditorElement element={element} scale={scale} showGuides />
      </S.Wrapper>
    </Scrollbars>
  </Provider>
);

export default ModalContent;
