import { GuideCatalogProps } from './types';
import S from './styles';
import BookPreview from './components/BookPreview';
import { getRouteDentistGuidesViewer } from '../../../routes/dentist/navigation';

export const GuideGridPreview = ({ guides, handleStartOrder }: GuideCatalogProps) => {
  return (
    <div>
      <S.BookWrapper>
        {guides?.map((guide) => (
          <div key={guide?.guideId}>
            <BookPreview
              guide={guide}
              linkUrl={getRouteDentistGuidesViewer(guide?.guideId)}
              key={guide.guideId}
              handleStartOrder={handleStartOrder}
            />
          </div>
        ))}
      </S.BookWrapper>
    </div>
  );
};

export default GuideGridPreview;
