export const ADDRESS_FORM = 'address_form';
export const ADDRESS_LIST = 'address_list';
export const CHECKOUT = 'checkout';
export const CONFIRMATION = 'confirmation';
export const PREFLIGHT = 'preflight';
export const REVIEW = 'review';

export const MODAL_HEIGHT = '70vh';
export const MODAL_WIDTH = '900px';

export const views = {
  ADDRESS_FORM,
  ADDRESS_LIST,
  CHECKOUT,
  CONFIRMATION,
  PREFLIGHT,
  REVIEW,
};
