import { FormLabel } from 'modules/v2/common/components';

import S from './styles';

const ModalContent = ({ state, onChange }) => {
  const handleChange = (e) => {
    const defaultValue = e.target.value;

    onChange({
      ...state,
      defaultValue,
      rawValue: undefined,
    });
  };

  return (
    <S.Form.Row>
      <FormLabel title="Name" htmlFor="name">
        <S.Form.Input
          id="name"
          type="text"
          name="name"
          onChange={handleChange}
          value={state.defaultValue}
        />
      </FormLabel>
    </S.Form.Row>
  );
};

export default ModalContent;
