import { Icon } from '@ant-design/compatible';
import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const TickedIcon = styled(Icon)`
  fill: ${color.DefaultBlue};
  color: ${color.White};
`;

export { TickedIcon };
