import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  background-color: ${color.White};
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 640px;
  height: 200px;
  border: 1px solid ${color.LightDullGray};
  border-radius: 10px;
  background-color: ${color.FormTitleBg};
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-family: ${font.LoraFont};
`;

const Subtitle = styled.div`
  font-size: 16px;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 640px;
  height: 200px;
  border: 1px solid ${color.NotificationErrorBorder};
  border-radius: 10px;
  background-color: ${color.NotificationErrorBG};
`;

export default { Container, BoxContainer, ErrorContainer, Title, Subtitle };
