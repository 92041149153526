import { put, takeEvery } from 'redux-saga/effects';

import { markSections } from 'modules/editor/store';

import {
  REORDER_SECTION,
  ACTIVATE_SECTION,
  DEACTIVATE_SECTION,
} from 'modules/editor/store/constants';

export function* onMarkSections() {
  const action = markSections();
  yield put(action);
}

export function* watchMarkSections() {
  const pattern = [REORDER_SECTION, ACTIVATE_SECTION, DEACTIVATE_SECTION];

  yield takeEvery(pattern, onMarkSections);
}
