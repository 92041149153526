import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const SelectFieldWrap = styled.div`
  .ant-select.ant-select-enabled {
    width: 205px;
    height: 32px;
    color: ${color.LoaderLabel};
    outline: none;
    border: none;
    box-shadow: none;
    font-weight: 600;
    &:hover {
      border: none;
    }
  }

  outline: none !important;
  box-shadow: none !important;

  .ant-select-selection-search-input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-select-selection-search {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    justify-items: center;
    height: 38px !important;
    padding: 5px 14px !important;
  }

  .ant-select-selector {
    border: #d9d9d9 solid 1px;
    outline: #d9d9d9 solid 1px !important;

    &:focus {
      border: #4CC9E1 solid 1px !important;
      outline: #4CC9E1 solid 1px !important;
    }

    &:active {
      border: #4CC9E1 solid 1px !important;
      outline: #4CC9E1 solid 1px !important;
    }
`;

export default {
  SelectFieldWrap,
};
