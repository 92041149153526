const PinIcon = ({ color = '#AEAEAE' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99967 1.66602C6.31778 1.66602 3.33301 5.0015 3.33301 8.74935C3.33301 12.4678 5.46078 16.5097 8.78056 18.0614C9.55446 18.4231 10.4449 18.4231 11.2188 18.0614C14.5386 16.5097 16.6663 12.4678 16.6663 8.74935C16.6663 5.0015 13.6816 1.66602 9.99967 1.66602ZM9.99967 9.99935C10.9201 9.99935 11.6663 9.25316 11.6663 8.33268C11.6663 7.41221 10.9201 6.66602 9.99967 6.66602C9.0792 6.66602 8.33301 7.41221 8.33301 8.33268C8.33301 9.25316 9.0792 9.99935 9.99967 9.99935Z"
        stroke={color}
        strokeWidth={1.5}
        fill="none"
      />
    </svg>
  );
};

export default PinIcon;
