import { OnboardTemplate } from '../../templates';
import S from './styles';
import { ConfirmButton } from '../UIComponents';

interface IWelcomeProps {
  callback: () => void;
}
const Welcome = ({ callback }: IWelcomeProps) => {
  return (
    <OnboardTemplate isStepsVisible={false}>
      <S.Container>
        <S.Icon />
        <div>
          <S.Title>Welcome to Authorify!</S.Title>
          <S.Description>Your success coach will reach out to you soon.</S.Description>
        </div>
        <S.ButtonContainer>
          <ConfirmButton onClick={callback} size="large" block>
            <S.ButtonText>Login</S.ButtonText>
          </ConfirmButton>
        </S.ButtonContainer>
      </S.Container>
    </OnboardTemplate>
  );
};

export default Welcome;
