import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

const validationSchema = Yup.object().shape({
  firstName: validation.firstName,
  lastName: validation.lastName,
  phone: validation.phoneNumber,
});

export default validationSchema;
