//

import ReactDOM from 'react-dom';

import { Modal } from 'modules/common/components';
import { IntakeForm } from 'modules/dashboard/components/IntakeFormModal/components';

import S from './styles';

const IntakeFormModal = ({
  isOpen,
  fields,
  preferencesStatus,
  uploadStatus,
  uploadError,
  onSubmit,
  onUpload,
}) => {
  const modalContainer = document.getElementById('IntakeFormModal');
  const modalStyle = {
    borderRadius: '10px',
    paddingBottom: 0,
    overflow: 'hidden',
  };

  return ReactDOM.createPortal(
    <Modal
      footer={null}
      isOpen={isOpen}
      style={modalStyle}
      width="1000px"
      wrapClassName="transparent-bg"
      hasCloseButton={false}
    >
      <S.Wrapper>
        <S.Sidebox>
          <S.Sidebox.Text>Please Provide The Information You Want On Your Book</S.Sidebox.Text>
        </S.Sidebox>
        <S.IntakeFormWrapper>
          <IntakeForm
            fields={fields}
            preferencesStatus={preferencesStatus}
            uploadStatus={uploadStatus}
            uploadError={uploadError}
            onSubmit={onSubmit}
            onUpload={onUpload}
          />
        </S.IntakeFormWrapper>
      </S.Wrapper>
    </Modal>,
    modalContainer,
  );
};

export default IntakeFormModal;
