import { get } from 'lodash-es';

export const convertObjectToArrayOfObject = (obj, firstKey = 'keyword', secondKey = 'value') => {
  const enteries = Object.entries(obj);
  let result = [];
  if (enteries.length) {
    result = enteries.map(([key, value]) => ({ [firstKey]: key, [secondKey]: value }));
  }
  return result;
};

export const getWordsCount = (string) => {
  return string.split(' ').filter((str) => str !== '')?.length;
};

export const addKeyToArrayOfObject = (
  array = [],
  fetchValueFrom,
  newKeyname,
  processValue = (val) => val,
) => {
  return array.map((obj) => {
    const fetchedValue = get(obj, fetchValueFrom);
    const processedValue = processValue(fetchedValue);
    return { ...obj, [newKeyname]: processedValue };
  });
};

export const checkWordLimit = (textLastValue, textCurrentValue, maxLimit) => {
  const wordCount = getWordsCount(textCurrentValue);
  const limitExceeded = wordCount > maxLimit;
  const isRemovingWords = textCurrentValue.length < textLastValue.length;

  return { limitExceeded, isRemovingWords };
};
