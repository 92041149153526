import { Card, Col, Row, Typography } from 'antd';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import S from './styles';
import { HolidaySaleContext } from '../../components/context';
import { ROUTE_MY_BOOKS } from '../../routes/constants';

const { Title, Text } = Typography;

const PaymentConfirmation = () => {
  const history = useHistory();
  const { creditValue, orderId } = useContext(HolidaySaleContext);
  const continueToDMP = () => {
    history.push(ROUTE_MY_BOOKS);
  };

  return (
    <Card>
      <Row>
        <Col span={24}>
          <S.MainSection>
            <S.Section>
              <S.CheckIcon />
            </S.Section>
            <S.Section>
              <Title level={4}>Congratulations!</Title>
            </S.Section>
            <S.Section>
              <Text>
                Your order of <strong>{creditValue.credits} book credits</strong> has been
                confirmed. <br />
                Please wait <strong>5-10 minutes</strong> for it to reflect on your account. <br />{' '}
                <br />
                Please note your order number below
              </Text>
            </S.Section>
            <S.OrderNumberContainer>
              <S.Description>Your order number is</S.Description>
              <S.Description>
                <b>{orderId}</b>
              </S.Description>
            </S.OrderNumberContainer>
            <S.Section>
              <Text>
                Got Questions? <br /> <br />
                Contact our support team at: <br /> <br />
                <strong>+1 904-695-9933</strong> <br />
                <strong>support@authorify.com</strong>
              </Text>
            </S.Section>

            <S.Section>
              <S.OkButton onClick={continueToDMP}>Continue to Book catalog</S.OkButton>
            </S.Section>
          </S.MainSection>
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentConfirmation;
