import { isEmpty } from 'lodash-es';

import { Loader, Scrollbars } from 'modules/common/components';
import OrderReview from 'modules/dashboard/pages/Dentist/OrderGuideModal/publish/editor/OrderReview';
import { MODAL_HEIGHT, pluralise } from 'modules/dashboard/pages/Dentist/OrderGuideModal/constants';
import { orderDentistQuantity } from 'modules/common/utils';

import S from 'modules/publish/styles';

const ReviewView = ({
  loading,
  numberOfPrints: prints,
  selectedAddress,
  isDigitalBook,
  onChangeAddress,
  onEditQuantity,
  onComplete,
  payloadData,
  guidePacketName,
}) => {
  if (loading) {
    return <Loader height={MODAL_HEIGHT} title="Processing Order" />;
  }

  const quantity = orderDentistQuantity(prints, isDigitalBook, guidePacketName);

  let address = null;

  if (!isEmpty(selectedAddress)) {
    const {
      firstName = '',
      lastName = '',
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      pincode = '',
      country = '',
    } = selectedAddress;

    address = (
      <OrderReview.Address
        data-testid="change-address"
        firstName={firstName}
        lastName={lastName}
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        city={city}
        state={state}
        pincode={pincode}
        country={country}
        title="Shipping Address"
        linkTitle="Change"
        onClick={onChangeAddress}
      />
    );
  }

  return (
    <Scrollbars style={{ height: MODAL_HEIGHT }}>
      <S.ViewTitle>Review Order</S.ViewTitle>
      <S.ViewSubTitle>
        Please review your order details and then click print to order your{' '}
        {pluralise(guidePacketName)}. Your order number will appear on the next screen.
      </S.ViewSubTitle>
      <OrderReview data-testid="complete-review" onComplete={onComplete} payloadData={payloadData}>
        <OrderReview.Item
          data-testid="edit-quantity"
          title="Quantity"
          linkTitle="Edit"
          value={quantity}
          onClick={onEditQuantity}
        />
        {address}
      </OrderReview>
    </Scrollbars>
  );
};

export default ReviewView;
