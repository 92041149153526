import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import env from 'environments';
import { Modal } from 'flowbite-react';

import { DashboardTemplate } from 'modules/v2/templates';
import { fetchOrderList, fetchUserCredits, getDigitalBooks } from 'modules/api';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import OrderTag from 'modules/v2/common/AtomicDesign/atoms/OrderTag';

import { useGetBookPreviews } from '../admin/BookPreviews/hooks';
import { BooksContext, BooksProvider } from './context';
import OrderedBookCard from './OrderedBookCard';
import DigitalBookCard from './DigitalBookCard';
import { notification } from '../../common/utils';
import OrderedBookSkeleton from './loading/OrderedBookSkeleton';
import BookCheckout from './Checkout/BookCheckout';
import { Box } from '../../common/AtomicDesign/atoms';
import StepCheckIcon from '../../common/components/SvgIcon/StepCheckIcon';
import Button from '../../common/AtomicDesign/atoms/Button';

export interface Thumbnail {
  url: string;
}

export interface Address {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  state: string;
  city: string;
  pincode: string;
  addressLine1: string;
}

export interface OrdersResponse {
  canonicalLink: string;
  _id: string;
  ticketId: string;
  milestone: string;
  parentOrderId: null | string;
  status: string;
  transactionStatus: boolean;
  allowPersonalization: boolean;
  orderId: string;
  userId: string;
  bookId: string;
  draftId: string;
  name: string;
  thumbnail: Thumbnail;
  thumbnail_small: Thumbnail;
  bookName: string;
  bookTitle: string;
  bookDesc: string;
  billingAddress: Address;
  shippingAddress: Address;
  quantity: number;
  isDigital: boolean;
  creditUsed: number;
  profileImageUrl: null | string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  allowReorder: boolean;
}

interface FetchOrderListResponse {
  data: OrdersResponse[];
  message: string;
  status: boolean;
  statusCode: number;
}

const OrderedBooks = ({ setActivePage, activePage }) => {
  const [digitalBooksData, setDigitalBooksData] = useState([]);
  const isDigitalCustomer = useCustomerPlanInfo();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/my-books/digital-books' && activePage.id !== 'digital-books') {
      setActivePage({
        id: 'digital-books',
        title: 'Digital books',
      });
    }
  }, []);

  const { data: fetchOrderListResponse, isLoading, isRefetching, refetch } = useQuery<
    FetchOrderListResponse,
    Error
  >('fetchOrderList', fetchOrderList, {
    onError: (error) => {
      const errorMessage =
        error.response?.data?.errors?.[0] || error?.data?.message || 'Network Error';
      notification.error({
        description: `${errorMessage}. Please call our customer service at ${env.SUPPORT_PHONE}.`,
        autoClose: false,
      });
    },
  });

  const { isLoading: isDigitalBookLoading } = useQuery('getDigitalBooks', getDigitalBooks, {
    onSuccess: (res) => {
      if (isDigitalCustomer.isDigital) setDigitalBooksData(res.data.data);
    },
    onError: () => {
      notification.error({
        description: 'There was an error, please try again later',
      });
    },
  });

  useGetBookPreviews({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    onError: (error) => {
      notification.error({
        description: 'There was an error, please try again later',
      });
      throw new Error(error.message);
    },
  });

  const renderLoadingSkeleton = () => {
    const keys = ['dummyKey1', 'dummyKey2'];
    return Array(2)
      .fill(0)
      .map((_, index) => <OrderedBookSkeleton key={keys[index]} />);
  };

  const { setUserCredits } = useContext(BooksContext);

  useQuery('fetchUserCredits', fetchUserCredits, {
    onSuccess: (res) => {
      setUserCredits(res?.data?.data?.credits || 0);
    },
    onError: (error) => {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    },
  });

  const navigateToCheckout = () => {
    setActivePage({
      id: 'checkout',
      title: 'Checkout',
    });
    refetch();
  };

  useEffect(() => {
    refetch();
  }, [setActivePage, refetch]);

  if (isLoading || isRefetching || isDigitalBookLoading) {
    return renderLoadingSkeleton();
  }

  if (
    isDigitalCustomer.isDigital &&
    digitalBooksData?.length > 0 &&
    location.pathname === '/my-books/digital-books'
  ) {
    return digitalBooksData?.map((book) => <DigitalBookCard bookData={book} />);
  }

  if (fetchOrderListResponse?.data?.length > 0) {
    return fetchOrderListResponse.data.map((d) => (
      <OrderedBookCard key={d.bookId} orderData={d} onBuyAgain={navigateToCheckout} />
    ));
  }

  return (
    <Box className="mb-6 p-7 justify-center items-center font-figtree relative text-neutral-600 text-center">
      No orders available
    </Box>
  );
};

const OrderedBooksListingPage = () => {
  const [activePage, setActivePage] = useState({
    id: 'ordered-books',
    title: 'Ordered books',
  });
  const [welcomeJourneyCompletion, setWelcomeJourneyCompletion] = useState(false);
  const [showOrderId, setShowOrderId] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const welcomeOrderId = queryParams.get('welcomeOrderId');
    const hasPublishedBook = !!welcomeOrderId;
    setShowOrderId(welcomeOrderId);
    setWelcomeJourneyCompletion(hasPublishedBook);
  }, [location.search]);

  const showActivePage = (id: string) => {
    switch (id) {
      case 'ordered-books':
        return <OrderedBooks setActivePage={setActivePage} activePage={activePage} />;
      case 'checkout':
        return <BookCheckout setActivePage={setActivePage} />;
      default:
        return <OrderedBooks setActivePage={setActivePage} activePage={activePage} />;
    }
  };

  return (
    <DashboardTemplate hasSidebar title={activePage.title}>
      <BooksProvider>{showActivePage(activePage.id)}</BooksProvider>
      <Modal
        show={welcomeJourneyCompletion}
        onClose={() => setWelcomeJourneyCompletion(false)}
        size="xl"
        dismissible={false}
        position="center"
      >
        <Modal.Body className="flex flex-col justify-center items-center gap-4 p-8">
          <div className="w-[50px] h-[50px] bg-success-500 rounded-full p-1.5 flex items-center justify-center">
            <StepCheckIcon stroke="#FFF" width={30} height={30} strokeWidth={3} />
          </div>
          <OrderTag className="mb-2">Order# {showOrderId}</OrderTag>
          <h3 className="text-base font-bold">Great Job! 🥳 You have completed all your tasks!</h3>
          <p className="text-sm text-neutral-500 font-normal">
            Your order is confirmed!. Please allow 7-10 days for us to print and ship your order.
          </p>
          <Button
            color="light"
            onClick={() => {
              setWelcomeJourneyCompletion(false);
            }}
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </DashboardTemplate>
  );
};

export default OrderedBooksListingPage;
