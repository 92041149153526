import { Children } from 'react';
import { Menu as BaseMenu } from 'antd';

import { ChevronIcon } from 'modules/v2/common/components/SvgIcon';
import { Item } from './components';

import S from './styles';

const Menu = ({ title, children, to, href, onClick, dataTestId }) => {
  if (!children) {
    if (to) {
      const activeModule = window.location.pathname.split('/').filter((item) => item)[0] ?? null;
      return (
        <S.MenuNavLink
          exact
          isActive={() => to.match(activeModule)}
          to={to}
          data-testid={dataTestId}
        >
          <S.MenuSpan>{title}</S.MenuSpan>
        </S.MenuNavLink>
      );
    }
    if (href) {
      return (
        <S.MenuNavLink to={{ pathname: href }} target="_blank" data-testid={dataTestId}>
          <S.MenuSpan>{title}</S.MenuSpan>
        </S.MenuNavLink>
      );
    }

    return <S.MenuLink onClick={onClick}>{title}</S.MenuLink>;
  }

  const dropdownList = Array.isArray(children) && (
    <BaseMenu>
      {Children.map(children, (child) => (
        <BaseMenu.Item>{child}</BaseMenu.Item>
      ))}
    </BaseMenu>
  );

  return (
    <S.Dropdown menu={dropdownList} trigger={['click']}>
      <S.Button>
        <S.Button.Label>{title}</S.Button.Label>
        <S.Button.Icon>
          <ChevronIcon />
        </S.Button.Icon>
      </S.Button>
    </S.Dropdown>
  );
};

Menu.Item = Item;

export default Menu;
