import { Menu } from 'antd';
import { withRouter } from 'react-router';

// TODO: Remove them and use Antd Icons
import { PlanIcon, BillingIcon, BillingHistoryIcon } from 'modules/common/components/SvgIcons';
import { NavItem, NavLink } from 'modules/common/components/UIComponents';
import {
  getRouteBilling,
  getRoutePayments,
  getRoutePlans,
} from 'modules/dashboard/routes/navigation';

const BillingSideNav = ({ location }) => {
  const pathName = location.pathname.slice(1);
  const selectedKey = pathName.split('/')[0];
  return (
    <Menu mode="inline" selectedKeys={[selectedKey]}>
      <NavItem key="plans">
        <NavLink to={getRoutePlans()}>
          <PlanIcon />
          <span>Plans</span>
        </NavLink>
      </NavItem>

      <NavItem key="billing">
        <NavLink to={getRouteBilling()}>
          <BillingHistoryIcon />
          <span>Invoices</span>
        </NavLink>
      </NavItem>
      <NavItem key="payments">
        <NavLink to={getRoutePayments()}>
          <BillingIcon />
          <span>Payment Methods</span>
        </NavLink>
      </NavItem>
    </Menu>
  );
};

export default withRouter(BillingSideNav);
