/* eslint-disable no-console */
//

import { Wait } from 'modules/onboard/components';
import { useWait } from 'modules/onboard/hooks';

const PlaceOrderWait = () => {
  const { order } = useWait();

  return (
    <Wait
      order={order}
      title="Placing your Order"
      description="Please wait while we are processing your payment and order."
    />
  );
};

export default PlaceOrderWait;
