//

import { get, isEmpty, noop } from 'lodash-es';

import { Loader, Scrollbars } from 'modules/common/components';
import { getPreflightCheckItems } from 'modules/common/utils';
import { CriteriaList } from 'modules/editor/components';
import { MODAL_HEIGHT } from 'modules/publish/constants';

import S from 'modules/publish/styles';

const PreflightCheckView = ({ loading, preflight, onEditCover = noop, onEditSection = noop }) => {
  if (loading) {
    return <Loader height={MODAL_HEIGHT} title="Checking book" />;
  }

  const criteria = get(preflight, 'finalResponse');
  const items = getPreflightCheckItems(criteria);

  const handleEdit = (sectionId) => {
    if (sectionId) {
      onEditSection(sectionId);
    } else {
      onEditCover();
    }
  };

  const criteriaList = !isEmpty(items) && <CriteriaList items={items} onEdit={handleEdit} />;

  return (
    <Scrollbars style={{ height: MODAL_HEIGHT }}>
      <S.ViewTitle>Pre-Flight Check!</S.ViewTitle>
      <S.ViewSubTitle>
        We are checking your book and verifying all the essentials. Please use the edit feature to
        clear any pre-flight check errors.
      </S.ViewSubTitle>
      {criteriaList}
    </Scrollbars>
  );
};

export default PreflightCheckView;
