import { Card, Col, Row, Typography } from 'antd';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import S from './styles';
import { PlusPlansContext } from '../context';

const { Title } = Typography;

const AlreadyPlusPlan = () => {
  const history = useHistory();
  const { currentPlanName } = useContext(PlusPlansContext);
  const continueToDasboard = () => {
    history.push('/');
  };

  return (
    <Card>
      <Row>
        <Col span={24}>
          <S.MainSection>
            <S.Section>
              <S.CheckIcon />
            </S.Section>
            <S.Section>
              <Title level={4}>Your account is already upgraded to {currentPlanName}</Title>
            </S.Section>
            <S.Section>
              <S.OkButton onClick={continueToDasboard}>Continue to Dashboard</S.OkButton>
            </S.Section>
          </S.MainSection>
        </Col>
      </Row>
    </Card>
  );
};

export default AlreadyPlusPlan;
