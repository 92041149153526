function getDefaultProps() {
  return {
    width: '15',
    height: '15',
    fill: '#555',
  };
}

function ZoomOutIcon() {
  return (
    // <svg
    //   id="zoomout"
    //   width={getDefaultProps().width}
    //   height={getDefaultProps().height}
    //   fill={getDefaultProps().fill}
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M8.125 6.25h-5V5h5v1.25zM13.232 15l-4.616-4.617a5.59 5.59 0 0 1-2.991.867 5.625 5.625 0 1 1 5.625-5.625c0 1.1-.321 2.124-.867 2.99L15 13.234 13.232 15zm-7.607-5A4.38 4.38 0 0 0 10 5.625 4.38 4.38 0 0 0 5.625 1.25 4.38 4.38 0 0 0 1.25 5.625 4.38 4.38 0 0 0 5.625 10z"
    //     fillRule="nonzero"
    //   />
    // </svg>
    <svg
      id="zoomout"
      width={getDefaultProps().width}
      height={getDefaultProps().height}
      fill={getDefaultProps().fill}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="search-minus"
      className="svg-inline--fa fa-search-minus fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M312 196v24c0 6.6-5.4 12-12 12H116c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h184c6.6 0 12 5.4 12 12zm196.5 289.9l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L347.5 387.1c-2.3-2.3-3.5-5.3-3.5-8.5v-13.2c-36.5 31.5-84 50.6-136 50.6C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 52-19.1 99.5-50.6 136h13.2c3.2 0 6.2 1.3 8.5 3.5l121.4 121.4c4.7 4.7 4.7 12.3 0 17zM368 208c0-88.4-71.6-160-160-160S48 119.6 48 208s71.6 160 160 160 160-71.6 160-160z" />
    </svg>
  );
}

export default ZoomOutIcon;
