import {
  UPDATE_PREFERENCES,
  UPDATE_PREFERENCE_BY_KEY,
  UPDATE_PREFERENCE_STATUS_BY_KEY,
  UPDATE_PREFERENCE_BY_ELEMENT_ID,
} from 'modules/v2/editor/store/constants';
import { preferences as initialPreferencesState } from 'modules/v2/editor/store/reducers/initialState';

import {
  insertPreferenceByKey,
  insertPreferenceStatusByKey,
  mapPreferences,
  getPreferenceKeyByElementId,
} from './utils';

const reducer = (preferences = initialPreferencesState, action) => {
  switch (action.type) {
    case UPDATE_PREFERENCES: {
      const { preferences: fields } = action.payload;

      return mapPreferences(preferences, fields);
    }

    case UPDATE_PREFERENCE_BY_KEY: {
      const { key, value } = action.payload;
      return insertPreferenceByKey(preferences, key, value);
    }

    case UPDATE_PREFERENCE_STATUS_BY_KEY: {
      const { key, status } = action.payload;
      return insertPreferenceStatusByKey(preferences, key, status);
    }

    case UPDATE_PREFERENCE_BY_ELEMENT_ID: {
      const { id, value } = action.payload;

      const key = getPreferenceKeyByElementId(id);
      return insertPreferenceByKey(preferences, key, value);
    }

    default:
      return preferences;
  }
};

export default reducer;
