import S from './styles';

const ProfileAvatar = ({ size = '100px' }) => (
  <S.Wrapper size={size}>
    <svg
      data-testid="default-icon-image"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      version="1.1"
    >
      <path
        d="M81.25,62.5 C70.9041667,62.5 62.5,70.8958333 62.5,81.25 C62.5,91.6041667 70.9041667,100 81.25,100 C91.5958333,100 100,91.6041667 100,81.25 C100,70.8958333 91.5958333,62.5 81.25,62.5 Z M91.6666667,83.3333333 L83.3333333,83.3333333 L83.3333333,91.6666667 L79.1666667,91.6666667 L79.1666667,83.3333333 L70.8333333,83.3333333 L70.8333333,79.1666667 L79.1666667,79.1666667 L79.1666667,70.8333333 L83.3333333,70.8333333 L83.3333333,79.1666667 L91.6666667,79.1666667 L91.6666667,83.3333333 Z M61.75,100 L0.0208333333,100 L0,94.8291667 C0,84.3291667 0.829166667,78.2666667 13.2416667,75.4 C27.2625,72.1625 41.1083333,69.2625 34.45,56.9916667 C14.7291667,20.6208333 28.825,0 50,0 C78.1291667,0 81.275,31.6458333 65.1666667,56.9958333 C59.7833333,65.4583333 54.1666667,72.1208333 54.1666667,81.25 C54.1666667,88.5291667 57.0666667,95.1291667 61.75,100 Z"
        id="Add-User-Image"
      />
    </svg>
  </S.Wrapper>
);

export default ProfileAvatar;
