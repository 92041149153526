import { get, map } from 'lodash-es';

export const parseCustomerTemplates = (templates) =>
  map(templates, (template) => {
    const customTemplate = get(template, 'customTemplate');

    return customTemplate || template;
  });

export const parseHtmlTemplate = (html, userAttributes) => {
  const pattern = /({{.*?}})/g;
  const {
    memberEmail,
    memberFirstName,
    memberLastName,
    memberPhone,
    imageUrl,
    memberBookUrl,
    memberAddress,
    memberBrokerName,
  } = userAttributes;

  const replaceStringsMap = new Map([
    ['{{LEAD_FIRST_NAME}}', 'John'], // Lead's name is a data present only right before sending it - We should `John` just for preview purposes
    ['{{MEMBER_FIRST_NAME}}', memberFirstName],
    ['{{MEMBER_BROKER_NAME}}', memberBrokerName ?? 'Broker Name'],
    ['{{MEMBER_LAST_NAME}}', memberLastName],
    ['{{MEMBER_EMAIL}}', memberEmail],
    ['{{MEMBER_PHONE}}', memberPhone],
    ['{{MEMBER_ADDRESS}}', memberAddress ?? 'Main St'],
    ['{{MEMBER_BOOK_URL}}', memberBookUrl ?? 'https://authorify.com'],
    [
      '{{PROFILE_IMAGE}}',
      imageUrl ?? 'https://authorify.com/wp-content/themes/afy-website/img/favicon.ico',
    ],
  ]);

  return html.replace(
    pattern,
    (stringMatched) => replaceStringsMap.get(stringMatched) || stringMatched,
  );
};
