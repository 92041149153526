import gql from 'graphql-tag';

export default gql(`
    query {
            templates(where: { type_in: ["Content"] }) {
            _id
            name
            }
    }
`);
