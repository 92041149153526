import { useEffect, useState } from 'react';
import { get, isEmpty, map } from 'lodash-es';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  getRouteCreateEmailCampaign,
  getRouteCreateOnDemandEmail,
  getRouteEmailSenderEmailConfiguration,
} from 'modules/v2/routes/navigation';
import {
  deleteEmailCampaign,
  deleteOnDemandEmails,
  getCustomer,
  getEmailActiveCampaigns,
  getEmailCampaigns,
  getOnDemandEmails,
  isEmailVerified,
} from 'modules/api';
import { formatDateTime2, notification } from 'modules/v2/common/utils';
import { DashboardTemplate } from 'modules/v2/templates';
// TODO: Please remove this line when UIComponents/table is deleted
import Table from 'modules/v2/common/components/Table';
import { Modal } from 'modules/v2/common/components';
import { defaultCheckboxTheme } from 'modules/v2/common/AtomicDesign/atoms/Checkbox/theme';
import { Checkbox, Label } from 'flowbite-react';
import {
  AltArrowRight,
  ArrowRightTable,
  NurtureCampaignIcon,
  OnDemandCampaignIcon,
  TrashIcon,
} from 'modules/v2/common/components/SvgIcon';

import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import S from './styles';
import CampaignPreview from './components/campaignPreview';
import { formatCampaigns, formatOnDemandEmails } from './utils';

const LaunchEmailCampaign = ({ history }) => {
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const [dataSource, setDataSource] = useState([]);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [emailsDetails, setEmailsDetails] = useState([]);
  const [campaignToBeDeleted, setCampaignToBeDeleted] = useState({
    id: '',
    type: '',
  });
  const [checked, setChecked] = useState([]);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowSize < 1024;

  const { mutate: verifyEmail } = useMutation(isEmailVerified, {
    onSuccess: ({ data }) => {
      if (!data) setIsVerifyEmailModalOpen(true);
    },
    onError: () => {
      notification.error({
        title: 'Error',
        description: 'There is something wrong with this request. Please try again later.',
      });
    },
  });

  const { mutateAsync: getCustomerMutate } = useMutation(getCustomer);

  useEffect(() => {
    const runVerifyEmail = async () => {
      try {
        const customer = await getCustomerMutate();
        const email = customer?.attributes?.email || customer?.email;

        if (email) verifyEmail({ email });
      } catch (error) {
        notification.error({
          description: 'There is something wrong with this request. Please try again later.',
        });
      }
    };

    runVerifyEmail();
  }, []);

  const handleRedirectUser = () => {
    history.push(getRouteEmailSenderEmailConfiguration());
  };

  const { data: nurtureCampaignsData, isLoading: isLoadingCampaigns } = useQuery(
    'getEmailCampaigns',
    getEmailCampaigns,
  );
  const nurtureCampaigns = get(nurtureCampaignsData, 'data');

  const { isLoading: isLoadingOnDemandEmails } = useQuery('getOnDemandEmails', getOnDemandEmails, {
    onSuccess: (onDemandEmailsData) => {
      const onDemandEmails = formatOnDemandEmails(get(onDemandEmailsData, 'data', []));
      setDataSource(onDemandEmails);
    },
  });

  const { isLoading: isLoadingDataCampaigns } = useQuery(
    'getEmailActiveCampaigns',
    getEmailActiveCampaigns,
    {
      onSuccess: (campaignsData) => {
        const campaigns = formatCampaigns(get(campaignsData, 'data', []));
        setDataSource((data) => [...data, ...campaigns]);
      },
    },
  );

  const uniqueStatus = [...new Set(dataSource.map((obj) => obj.status))];

  const invalidateQueries = () => {
    queryClient.invalidateQueries('getOnDemandEmails');
    queryClient.invalidateQueries('getEmailActiveCampaigns');
  };

  const { mutate: deleteCampaign, isLoading: isLoadingDeleteCampaign } = useMutation(
    (campaignId) => deleteEmailCampaign(campaignId),
    {
      onSuccess: () => {
        setDeleteModalOpen(false);
        invalidateQueries();
        notification.success({ description: 'Campaign deleted successfully' });
      },
    },
  );

  const { mutate: deleteOnDemandEmail, isLoading: isLoadingDeleteOnDemandEmail } = useMutation(
    (campaignId) => deleteOnDemandEmails(campaignId),
    {
      onSuccess: () => {
        setDeleteModalOpen(false);
        invalidateQueries();
        notification.success({ description: 'Campaign deleted successfully' });
      },
    },
  );

  const openModal = (emails) => {
    setEmailsDetails(emails);
    setDetailsModalOpen(true);
  };

  const handleOpenDeleteModal = (id, type) => () => {
    setCampaignToBeDeleted({
      id,
      type,
    });
    setDeleteModalOpen(true);
  };

  const handleCategoryChange = (e, stat) => {
    const copyCheck = e.target.checked
      ? [...checked, stat]
      : checked.filter((item) => item !== stat);
    setChecked(copyCheck);
  };

  const tableColumns = [
    {
      title: 'Campaign name',
      dataIndex: 'name',
      key: 'name',
      width: '230px',
      fixed: 'left',
      padding: '18px 24px',
      render: (name, { type, emails }) =>
        type === 'On Demand Email' ? (
          name
        ) : (
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => openModal(emails)}
          >
            {name}
            <AltArrowRight />
          </div>
        ),
    },
    {
      title: 'Segments',
      dataIndex: 'segments',
      key: 'segments',
      width: '245px',
      render: (segments) => (
        <span className="bg-neutral-100 w-fit rounded-md px-2 py-1">{segments}</span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '170px',
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      key: 'emails',
      width: '150px',
      render: (emails) => (emails ? emails.length : 1),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '170px',
      render: (startDate) => <span>{formatDateTime2(startDate)}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      render: (status, { sendImmediately }) => {
        if (sendImmediately && status === 'scheduled')
          return <p className="first-letter:uppercase">Sending now</p>;
        return <p className="first-letter:uppercase">{status.replace('_', ' ')}</p>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '130px',
      fixed: 'right',
      render: ({ id, type, status }) => (
        <div className="flex gap-2 flex-row-reverse">
          <Button
            size="sm"
            type="muted"
            disabled={status !== 'scheduled' && type === 'On Demand Email'}
            onClick={handleOpenDeleteModal(id, type)}
          >
            <TrashIcon fill="#252525" />
          </Button>
        </div>
      ),
    },
  ];

  if (isMobile) {
    const actionsColumn = tableColumns.find((col) => col.key === 'action');
    const nameColumn = tableColumns.find((col) => col.key === 'name');
    if (actionsColumn && actionsColumn.fixed === 'right') {
      delete actionsColumn.fixed;
    }
    if (nameColumn && nameColumn.fixed === 'left') {
      delete nameColumn.fixed;
    }
  }

  const handleCreateOnDemandEmail = () => {
    history.push(getRouteCreateOnDemandEmail());
  };

  const handleCreateNurtureCampaign = (contentId) => {
    history.push(getRouteCreateEmailCampaign(contentId));
  };

  return (
    <DashboardTemplate
      isLoading={
        isLoadingCampaigns ||
        isLoadingDataCampaigns ||
        isLoadingDeleteCampaign ||
        isLoadingOnDemandEmails ||
        isLoadingDeleteOnDemandEmail
      }
      hasNoData={isEmpty(nurtureCampaigns)}
      loadingTitle="Loading Campaigns"
      noDataTitle="No Campaigns Available"
      contentTitle="Launch Email Campaign"
      hasSidebar
    >
      <h2 className="text-2xl font-bold">Create email campaign</h2>
      <div className="w-full py-[24px] flex gap-6 max-[700px]:flex-col">
        <div
          className="grow p-6 bg-neutral-0 rounded-lg shadow cursor-pointer hover:border-primary-500 border-transparent border-2"
          onMouseEnter={() => setHover1(true)}
          onMouseLeave={() => setHover1(false)}
          onKeyUp={handleCreateOnDemandEmail}
          onClick={handleCreateOnDemandEmail}
        >
          <div className="p-[10px] bg-neutral-100 w-fit rounded-lg mb-3">
            <OnDemandCampaignIcon />
          </div>
          <h4 className="text-base">Create on demand campaign</h4>
          <p className="text-neutral-500 mb-3">
            Create and send a regular email campaign to your selected leads.
          </p>
          <div className="w-fit relative">
            <div className="flex items-center font-bold h-[30px] overflow-hidden">
              <div className="whitespace-nowrap transition-all duration-300 ease-in-out transform">
                Continue
              </div>
              <ArrowRightTable
                className={`bg-white w-[100px] transition-transform duration-300 ease-in-out ${
                  !hover1 ? '-translate-x-[60px]' : 'translate-x-1'
                }`}
                width={20}
                height={20}
              />
            </div>
          </div>
        </div>
        {map(nurtureCampaigns, ({ id: contentId }) => (
          <div
            className="grow p-6 bg-neutral-0 rounded-lg shadow cursor-pointer hover:border-primary-500 border-transparent border-2"
            onMouseEnter={() => setHover2(true)}
            onMouseLeave={() => setHover2(false)}
            onKeyUp={() => handleCreateNurtureCampaign(contentId)}
            onClick={() => handleCreateNurtureCampaign(contentId)}
          >
            <div className="p-[10px] bg-neutral-100 w-fit rounded-lg mb-3">
              <NurtureCampaignIcon />
            </div>
            <h4 className="text-base">Create nurture campaign</h4>
            <p className="text-neutral-500 mb-3">
              Schedule a series of emails that personalize your strategy and save you time.
            </p>
            <div className="w-fit relative">
              <div className="flex items-center font-bold h-[30px] overflow-hidden">
                <div className="whitespace-nowrap transition-all duration-300 ease-in-out transform">
                  Continue
                </div>
                <ArrowRightTable
                  className={`bg-white w-[100px] transition-transform duration-300 ease-in-out ${
                    !hover2 ? '-translate-x-[60px]' : 'translate-x-1'
                  }`}
                  width={20}
                  height={20}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-end bg-neutral-0 rounded-t-lg shadow py-[22px] px-[24px]">
        {uniqueStatus.map((stat) => (
          <div className="flex flex-row items-center gap-2 ml-4" key={stat}>
            <Checkbox
              theme={defaultCheckboxTheme}
              id={stat}
              onClick={(e) => handleCategoryChange(e, stat)}
            />
            <Label className="cursor-pointer first-letter:uppercase" htmlFor={stat}>
              {stat.replace('_', ' ')}
            </Label>
          </div>
        ))}
      </div>
      <Table
        hasData={!isEmpty(dataSource)}
        rowKey="id"
        dataSource={
          checked.length
            ? dataSource.filter((item) => checked.some((status) => item.status.includes(status)))
            : dataSource
        }
        columns={tableColumns}
      />

      <Modal
        isOpen={isVerifyEmailModalOpen}
        title="Verify your email"
        okText="Verify my email"
        hasCloseButton
        onCancel={() => history.goBack()}
        footer={[
          <div className="flex justify-end gap-x-2">
            <Button type="outlined" onClick={() => history.goBack()}>
              Do it later
            </Button>

            <Button onClick={handleRedirectUser}>
              Resend verification email
            </Button>
          </div>
        ]}
      >
        <S.ModalEmailWrapper>
          To start sending email campaigns, please verify or changing your email address in “Sender email” or resend verification email.
        </S.ModalEmailWrapper>
      </Modal>

      <Modal
        isOpen={isDetailsModalOpen}
        title="Campaign Preview"
        hasCloseButton
        onCancel={() => setDetailsModalOpen(false)}
        footer={<></>}
        width="700px"
      >
        <S.ModalWrapper>
          <CampaignPreview emails={emailsDetails} />
        </S.ModalWrapper>
      </Modal>

      <Modal
        title="Delete Campaign"
        isOpen={isDeleteModalOpen}
        hasCloseButton
        onCancel={() => setDeleteModalOpen(false)}
        footer={
          <div className="flex justify-end h-[48px] gap-x-2">
            <Button type="outlined" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button
              type="danger"
              onClick={() =>
                campaignToBeDeleted.type === 'On Demand Email'
                  ? deleteOnDemandEmail(campaignToBeDeleted.id)
                  : deleteCampaign(campaignToBeDeleted.id)
              }
            >
              Yes, Delete campaign
            </Button>
          </div>
        }
      >
        <S.ModalWrapper>
          <div className="">
            Deleting campaigns is permanent and cannot be undone.
            <br />
            Are you sure you want to delete this campaign?
          </div>
        </S.ModalWrapper>
      </Modal>
    </DashboardTemplate>
  );
};

export default LaunchEmailCampaign;
