import styled from 'styled-components';
import { Button as BaseButton, Switch as BaseSwitch } from 'antd';
import { UpdateIcon as Icon } from 'modules/v2/common/components';

import * as color from 'modules/v2/common/theme/color';

const Button = styled(BaseButton)`
  margin: 10px 15px;
  height: 40px;
  font-size: 16px;

  &,
  &:hover,
  &:focus {
    color: ${color.White};
    background-color: ${color.PrimaryColor};
    border-color: ${color.PrimaryColor};
  }
`;

const Choice = styled.div`
  flex: 1;
  line-height: 60px;
  text-align: left;
  font-size: 16px;
`;

const ContentBody = styled.div`
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  padding: 20px;
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
`;

const ContentButton = styled(BaseButton)`
  font-weight: normal;
  font-size: 16px;
  padding-bottom: 20px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ContentTitle = styled.h3`
  font-size: 22px;
  font-weight: bold;
`;

const ContentWrapper = styled.div``;

const Footer = styled.div`
  display: flex;
`;

const Switch = styled(BaseSwitch)`
  margin-right: 10px;
  position: relative;
  bottom: 2px;
`;

const UpdateIcon = styled(Icon)`
  display: block;
`;

export default {
  Button,
  Choice,
  ContentBody,
  ContentButton,
  ContentHeader,
  ContentTitle,
  ContentWrapper,
  Footer,
  Switch,
  UpdateIcon,
};
