type TypeInvalidIconProps = {
  fill?: string;
};

const InvalidIcon = ({ fill = '#e4483d', ...props }: TypeInvalidIconProps) => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.63964 10.4519C8.28759 3.98396 10.1116 0.75 13 0.75C15.8884 0.75 17.7124 3.98395 21.3604 10.4519L21.8149 11.2578C24.8464 16.6326 26.3621 19.32 24.9922 21.285C23.6223 23.25 20.233 23.25 13.4546 23.25H12.5454C5.76695 23.25 2.37771 23.25 1.00782 21.285C-0.362072 19.32 1.15364 16.6326 4.18506 11.2578L4.63964 10.4519ZM13 6.0625C13.5178 6.0625 13.9375 6.48223 13.9375 7V13.25C13.9375 13.7678 13.5178 14.1875 13 14.1875C12.4822 14.1875 12.0625 13.7678 12.0625 13.25V7C12.0625 6.48223 12.4822 6.0625 13 6.0625ZM13 18.25C13.6904 18.25 14.25 17.6904 14.25 17C14.25 16.3096 13.6904 15.75 13 15.75C12.3096 15.75 11.75 16.3096 11.75 17C11.75 17.6904 12.3096 18.25 13 18.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default InvalidIcon;
