import { createSelector } from 'reselect';

import {
  draftModuleSelector,
  getDraftState,
  getDraft,
  getDraftMilestone,
  getDraftId,
  getDraftName,
  getDraftUpdatedAt,
} from '../utils';

export const draftStateSelector = createSelector(draftModuleSelector, getDraftState);

export const draftSelector = createSelector(draftStateSelector, getDraft);

export const draftIdSelector = createSelector(draftSelector, getDraftId);

export const draftMilestoneSelector = createSelector(draftSelector, getDraftMilestone);

export const draftUpdatedAtSelector = createSelector(draftSelector, getDraftUpdatedAt);

export const draftNameSelector = createSelector(draftSelector, getDraftName);
