import {
  DELETE_USER_IMAGE,
  GET_USER_IMAGES,
  GET_PRESET_LOGOS,
} from 'modules/editor/store/constants';

export const deleteUserImage = ({ imageId, imageType }) => ({
  type: DELETE_USER_IMAGE,
  payload: { imageId, imageType },
});

export const getUserImages = (type) => ({
  type: GET_USER_IMAGES,
  payload: {
    type,
  },
});

export const getPresetLogos = () => ({
  type: GET_PRESET_LOGOS,
});
