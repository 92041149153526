import { string, object } from 'yup';

const validationSchema = object().shape({
  address1: string().required('Address is required'),
  state: string().required('State is required'),
  country: string().required('Country is required'),
  city: string().required('City is required'),
  zipCode: string().required('Postal Code is required'),
});

export default validationSchema;
