//

import { Wait } from 'modules/onboard/components';
import { useWait } from 'modules/onboard/hooks';

const BookDetailsWait = () => {
  const { order } = useWait();

  return (
    <Wait
      order={order}
      title="Generating Your Digital Book"
      description="Please wait while we generate your digital book."
    />
  );
};

export default BookDetailsWait;
