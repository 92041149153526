import { TextInput as BaseInput } from 'flowbite-react';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import classnames from 'classnames';
import { defaultTextInputTheme } from './theme';

type TextInputProps = {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: string;
  disabled?: boolean;
  className?: string;
  errorMessage?: string;
  sizing?: 'sm' | 'md' | 'lg';
  color?: 'gray' | 'failure';
  isValid?: boolean;
  type?: 'text' | 'password' | 'email' | 'number' | 'tel';
  id?: string;
  name?: string;
  placeholder?: string;
  placeholderIcon?: any;
};

const TextInput = ({
  onChange,
  onBlur,
  value,
  sizing = 'md',
  errorMessage = 'Invalid input',
  disabled,
  className,
  color = 'gray',
  type,
  isValid,
  id,
  name,
  placeholder,
  placeholderIcon,
  ...props
}: TextInputProps) => {
  return (
    <>
      <BaseInput
        icon={placeholderIcon}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        theme={defaultTextInputTheme}
        type={type}
        color={color}
        sizing={sizing}
        className={classnames(className)}
        id={id}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />
      {isValid === false && <span className="text-error-500 text-sm">{errorMessage}</span>}
    </>
  );
};

export default TextInput;
