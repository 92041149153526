import styled from 'styled-components';
import { SelectMultiField } from 'modules/v2/common/components';


const StyledSelectMultiField = styled(SelectMultiField)`
  .ant-select-selection-placeholder {
    color: #818181;
  }
`;

// NOTE: This isn't the most re-usable component, it's hard coupled to the segments data model and workflow
// But it encapsulates the common logic for selecting segments.
const ALL_SEGMENTS = 'All segments';
function SelectSegmentsMultiField({
  segmentsData,
  setFieldValue,
  values,
  maxCount,
  className,
  ...props
}) {
  return (
    <StyledSelectMultiField
      className={`w-full border-neutral-200 border rounded text-black ${className}`}
      name="segments"
      placeholder="Select Segment(s)"
      type="text"
      options={[{ id: ALL_SEGMENTS, name: ALL_SEGMENTS }, ...(segmentsData?.data || [])]}
      onChange={(segmentIdArray) => {
        if (segmentIdArray.includes(ALL_SEGMENTS)) {
          setFieldValue('segments', []);
          setFieldValue('allSegments', true);
          return;
        }
        if (!segmentIdArray.length) {
          setFieldValue('segments', []);
          setFieldValue('allSegments', false);
          return;
        }
        setFieldValue('segments', segmentIdArray);
      }}
      value={values.allSegments ? [ALL_SEGMENTS] : values.segments}
      maxCount={maxCount}
      {...props}
    />
  );
}

export default SelectSegmentsMultiField;
