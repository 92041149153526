import withStore from 'store/StoreContainer';

import { supportTicketStatusSelector, createTicket } from 'modules/dashboard/store';

const mapDispatchToProps = {
  createTicket,
};

const mapStateToProps = (state) => ({
  ticketStatus: supportTicketStatusSelector(state),
});

export default withStore(mapStateToProps, mapDispatchToProps);
