import styled from 'styled-components';
import { Layout } from 'antd';
import * as color from 'modules/common/theme/color';

const { Sider } = Layout;

const Wrapper = styled(Sider)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${color.SideBarBackground} padding-box;
  box-shadow: 0px 3px 6px #00000040;

  .ant-menu {
    max-height: calc(100vh - 102px);
    overflow-x: hidden;
    width: 100%;
    background-color: transparent !important;
    border: none;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${color.scrollbarThumb};
    }

    ::-webkit-scrollbar-thumb {
      background: ${color.sidebarBoxShadow};
      border-radius: 6px;
    }

    .ant-menu-item {
      color: ${color.SideBarText};

      :hover {
        color: ${color.SideBarActiveText};
        background-color: ${color.SideBarSelectedBackground};
      }
    }

    .ant-menu-item:after {
      display: none;
    }

    .ant-menu-item-selected {
      color: ${color.SideBarActiveText};
      background-color: ${color.SideBarSelectedBackground};
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        color: ${color.SideBarText};
      }
    }

    .ant-menu-submenu-selected {
      color: ${color.SideBarActiveText};
      background-color: ${color.SideBarSelectedBackground};
    }

    .ant-menu .ant-menu-sub .ant-menu-inline {
      background: ${color.SideBarSelectedMenuBackground} !important;
    }

    .ant-menu-item .ant-menu-item-only-child {
      background: ${color.SideBarSelectedMenuBackground} !important;
    }

    .ant-menu-submenu-open {
      &,
      .ant-menu-submenu-title,
      .ant-menu-sub,
      .ant-menu-item {
        background-color: ${color.SideBarSelectedBackground};
        width: 100%;
      }

      .ant-menu-item-selected {
        color: ${color.SideBarActiveText};
        background-color: ${color.LilyWhite};
      }

      .ant-menu-submenu-title {
        color: ${color.SideBarActiveText};
      }
    }
  }
`;

export default {
  Wrapper,
};
