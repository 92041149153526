import styled from 'styled-components';
import { Tooltip as TooltipBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  background-color: ${color.White};
  th {
    font-weight: bold;
  }
  tr {
    border: 1px solid ${color.LightDullGray};
  }
  tr td:last-child {
    text-align: left;
  }
`;

const AddCampaignBtn = styled.div`
  display: block;
  button {
    margin-top: 12px;
  }
`;

const RowIcons = styled.div`
  display: inline-block;

  span.anticon {
    margin-right: 10px;
    vertical-align: middle;
    font-size: 16px;
  }
  span.anticon[aria-label='star'] {
    color: ${color.LightDullGray};
  }
  span.anticon[aria-label='eye'] {
    color: ${color.SkyBlue};
  }
`;

const Name = styled.span`
  color: ${color.SkyBlue};
`;

const HeaderSection = styled.div`
  display: flex;
  margin: 20px 0;
`;

const HeaderTitle = styled.div`
  color: ${color.Black};
  flex: 3;
  font-size: 24px;
  margin: 0;
  font-weight: bold;
  padding: 0;
  margin: 8px 0 0 0;
`;

const Search = styled.div`
  flex: 0 0 1;
  margin-top: 54px;
  .ant-input-search {
    width: 247px;
  }
`;

const Tooltip = styled(TooltipBase)`
  color: ${color.SkyBlue};
`;

const RowActions = styled.span`
  color: ${color.SkyBlue};
  font-size: 16px;
  vertical-align: super;
  font-weight: bold;
  cursor: pointer;
`;
const Text = styled.h3`
  margin: 20px 0 10px 0 !important;
  color: ${color.DeleteLeadModalText};
`;
const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 10px 0 !important;
  text-align: center;
`;
const Wrapper = styled.div`
  background: ${color.White};
  padding: 2rem;
`;

export default {
  Container,
  RowIcons,
  Name,
  HeaderSection,
  HeaderTitle,
  Search,
  Tooltip,
  AddCampaignBtn,
  RowActions,
  Text,
  Title,
  Wrapper,
};
