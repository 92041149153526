import styled from 'styled-components';
import { DatePicker as DatePickerBase, Layout, Switch } from 'antd';
import { Tooltip as TooltipBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';
import { Field as BaseField } from 'formik';
import { CKEditor as BaseCkEditor } from '@ckeditor/ckeditor5-react';

import { Input as UIInput } from 'modules/common/components/UIComponents/Form';

const Wrapper = styled.div`
  input {
    width: 100%;
  }
`;

const Title = styled.h4`
  font-size: 16px !important;
`;

const Label = styled.label`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 12px;
  display: block;
`;

const DatePicker = styled(DatePickerBase)`
  width: 100%;
  margin-bottom: 20px;
`;

const LabelWithIcon = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tooltip = styled(TooltipBase)``;

const Sidebar = styled(Layout.Sider)`
  flex-basis: 245px !important;
  width: 260px !important;
  max-width: 260px !important;
  box-shadow: 0px 3px 6px #00000040;

  background: ${color.lightBackground};
  padding: 30px 20px 30px 40px;
`;

const FormikField = styled(BaseField).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  border-radius: 3px;
  background-color: ${color.White};
`;

const Field = styled(UIInput)`
  border: 1px solid ${({ $hasErrors }) => ($hasErrors ? color.DangerRed : color.LightGray)};
  height: 32px;
  font-size: 14px;
`;

const ControlList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ControlItem = styled.li`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const ControlLabel = styled.span`
  font-size: 12px;
`;

const ControlInput = styled(Switch)`
  position: relative;
  right: 2px;
  top: 0px;
`;

const SubControlList = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    height: 30px;
  }
`;

const WrapperContent = styled.div`
  padding: 16px 0 26px 0;
  flex-direction: column;
  margin: 0 auto;
  background-color: ${color.White};
`;

const TemplateWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TemplateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  width: 600px;
`;

const TemplateImage = styled.div`
  width: 600px;
  margin: 0 0 20px;
  height: 200px;
  color: ${color.Black};

  &:hover {
    color: ${color.Black};
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  flex: 1;
`;

const TemplateImageContent = styled.div`
  width: 312px;
  display: flex;
  margin: 0 auto;
  position: relative;
  align-itens: center;
  justify-content: center;
  background: #ccc;
`;

const TemplateImageFile = styled.div`
  width: 100%;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-itens: center;
  color: ${color.GreySelectDd};
  position: absolute;
  z-index: 0;
`;

const TemplateImageDescription = styled.div`
  margin-top: 10px;
  padding-top: 60px !important;
  text-align: center;
  position: absolute;
  z-index: 1;
`;

const TemplateTitle = styled(BaseField)`
  margin-bottom: 20px;
  margin-top: 20px;
  width: 500px;
  border: 1px dashed #dbdbdb;
  padding: 10px;
  text-align: center;
  font-size: 24px;
  color: ${color.Black};
  font-weight: bold;
  outline: none;
`;

const TemplateBody = styled.div`
  border: 1px dashed #dbdbdb;
  padding: 10px;
  width: 500px;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 160px;

  .ck-editor__editable_inline {
    min-height: 99px;
    border: none;
  }
  .ck-toolbar {
    border: none;
  }
`;

const TemplateSignature = styled.div`
  text-align: center;
  width: 100%;
  padding: 40px;
  background: #1b2f73;
  color: ${color.White};
  font-size: 16px;
  display: flex;

  textarea::-webkit-input-placeholder {
    color: ${color.White};
  }

  textarea:-moz-placeholder {
    color: ${color.White};
  }

  textarea::-moz-placeholder {
    color: ${color.White};
  }

  textarea:-ms-input-placeholder {
    color: ${color.White};
  }

  textarea::placeholder {
    color: ${color.White};
  }
`;

const TemplateSignatureItems = styled.div`
  flex: 1;
`;

const TemplateSignatureImage = styled.img`
  height: 80px;
  width: 80px;
`;

const TemplateSignatureItem = styled.div`
  text-align: left;
  font-size: 12px;

  strong {
    font-size: 13px;
  }
`;

const TemplateIframe = styled.div`
  width: 800px;
  margin-left: 40px;
`;

const CKEditor = styled(BaseCkEditor)`
  height: 500px;
`;

const UploadedImage = styled.div``;

const SignatureTextField = styled.textarea`
  background: transparent;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  color: ${color.White};
`;

const FilePickerContainer = styled.div`
  div {
    min-width: 600px !important;
    padding: 0;
    height: 200px;
  }
`;

export default {
  UploadedImage,
  CKEditor,
  TemplateWrapper,
  TemplateContent,
  TemplateIframe,
  TemplateImage,
  TemplateImageFile,
  TemplateImageContent,
  TemplateImageDescription,
  TemplateTitle,
  TemplateSignature,
  TemplateSignatureItems,
  TemplateSignatureItem,
  TemplateSignatureImage,
  TemplateBody,
  ControlList,
  ControlItem,
  ControlInput,
  ControlLabel,
  SubControlList,
  Field,
  DatePicker,
  Label,
  LabelWithIcon,
  LoadingWrapper,
  Title,
  Tooltip,
  Wrapper,
  Sidebar,
  FormikField,
  WrapperContent,
  SignatureTextField,
  FilePickerContainer,
};
