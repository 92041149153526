// replaceNewLineWithBR
export function replaceNewLineWithBR(str) {
  return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
}
// replaceBrWithNewLine
export function replaceBrWithNewLine(str) {
  return str.replace(/<br ?\/?>/g, '\n');
}

export function replaceFinalPayload(rest) {
  return rest.payload.selection.dynamicFields.reduce((acc, curr) => {
    if (curr.keyword === 'frontInsideCoverText') {
      return [
        ...acc,
        {
          keyword: curr.keyword,
          value: replaceNewLineWithBR(curr.value),
        },
      ];
    }
    return acc;
  }, []);
}
