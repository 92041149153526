import { useReducer } from 'react';
import { isEmpty } from 'lodash';

const initialTodos = {
  todos: [],
  completedTask: [],
};

const COMPLETED = 'COMPLETED';
const INITIALIZE_TODOS = 'INITIALIZE_TODOS';

const updateTaskStatus = (data, taskName) => {
  if (isEmpty(taskName)) return data;

  for (const key in data) {
    if (data[key].name === taskName) {
      const { steps } = data[key];
      for (const step of steps) {
        if (step.name === taskName) {
          step.isCompleted = true;
          step.completedAt = new Date().toISOString();
          break;
        }
      }
      break;
    }
  }

  return data;
};

const reducer = (state, action) => {
  switch (action.type) {
    case COMPLETED: {
      const { payload } = action;
      const completedTask = state.completedTask ?? [];
      const todoTask = state.todos ?? [];

      const taskExists = (array, task) => {
        if (!array) return false;
        return Array.from(array).some((item) => {
          return Object.keys(task).every((key) => item[key] === task[key]);
        });
      };

      if (!taskExists(completedTask, payload)) {
        state.completedTask.push(payload);
      }

      if (!taskExists(todoTask, payload)) {
        const key = Object.keys(payload);
        const res = updateTaskStatus(todoTask, key[0]);
        const nState = { ...state.todos, ...res };
        return nState;
      }

      return state;
    }
    case INITIALIZE_TODOS: {
      const { payload } = action;
      const tasks = payload.reduce((acc, todo) => {
        acc[todo.name] = {
          taskId: todo.id,
          name: todo.name,
          stepId: todo.steps.reduce((stepsAcc, step) => {
            stepsAcc[step.name] = step.id;
            return stepsAcc;
          }, {}),
        };
        return acc;
      }, {});

      const res = {
        ...state,
        todos: payload,
        tasks,
      };
      return res;
    }

    default:
      return state;
  }
};

const useTodos = () => {
  const [state, dispatch] = useReducer(reducer, initialTodos);

  return {
    ...state,
    todos: state.todos,
    completedTask: state.completedTask,
    tasks: state.tasks,
    initializeTodos: (payload) => dispatch({ type: INITIALIZE_TODOS, payload }),
    completed: (payload) => dispatch({ type: COMPLETED, payload }),
  };
};

export default useTodos;
