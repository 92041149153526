import { datadogLogs } from '@datadog/browser-logs';
import env from 'environments';
import { TYPE_ERROR, TYPE_SUCCESS, TYPE_WARNING } from 'modules/common/utils/variables';

export const datadogNotificationTypeMapper = (type) => {
  switch (type) {
    case TYPE_SUCCESS:
      return 'info';
    case TYPE_ERROR:
      return 'error';
    case TYPE_WARNING:
      return 'warning';
    default:
      return 'info';
  }
};
export const notifyDatadog = ({ title, description, type, service = env.DATADOG_SERVICE_NAME }) => {
  const message = `${title} - ${description}`;
  const statusType = datadogNotificationTypeMapper(type);
  datadogLogs.logger.log(
    message,
    {
      title,
      description,
      service,
    },
    statusType,
  );
};
