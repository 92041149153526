import React, { useEffect } from 'react';
import { compose } from 'redux';
import { get, map, isEmpty } from 'lodash-es';

import { isPending } from 'store/status';
import { Loader, Modal, Scrollbars, ContentPlaceholder } from 'modules/common/components';
import { BookLinks } from 'modules/bookLinks/components';

import { withBookLinks } from './containers';

const BookLinksModal = ({ isOpen, links, clearLinks, getLinks, onCancel }) => {
  const items = get(links, ['data'], null);
  const status = get(links, ['status']);

  useEffect(() => {
    getLinks();

    return () => clearLinks();
  }, [clearLinks, getLinks]);

  const bookLinks = (
    <BookLinks>
      {map(
        items,
        ({ _id, digitalBook, landingPage, marketingMaterials, orderDate, bookSource }, index) => (
          <BookLinks.Item
            key={`${_id}-${index}`}
            digitalBookUrl={digitalBook}
            landingPageUrl={landingPage}
            marketingMaterialsUrl={marketingMaterials}
            orderDate={orderDate}
            title={bookSource || 'Untitled'}
          />
        ),
      )}
    </BookLinks>
  );

  const renderBookLinks = isEmpty(items) ? (
    <ContentPlaceholder>No links available</ContentPlaceholder>
  ) : (
    bookLinks
  );

  const content = isPending(status) ? (
    <Loader title="Loading Book Links" height="100%" />
  ) : (
    renderBookLinks
  );

  return (
    <Modal footer={null} isOpen={isOpen} onCancel={onCancel} title="My Book Links">
      <Scrollbars
        renderInLayers
        style={{
          height: '580px',
        }}
      >
        {content}
      </Scrollbars>
    </Modal>
  );
};

const MemoBookLinksModal = React.memo(BookLinksModal);
const Composed = compose(withBookLinks)(MemoBookLinksModal);

export default Composed;
