import withStore from 'store/StoreContainer';

import { goToEditDraft, goToEditDraftContent, goToEditDraftCover } from 'modules/editor/store';

import {
  addressesSelector,
  addUserAddresses,
  clearPublish,
  countriesSelector,
  creditsSelector,
  deleteUserAddresses,
  editUserAddresses,
  getUserCredits,
  orderSelector,
  preflightSelector,
  statesSelector,
} from 'modules/publish/store';

const mapStateToProps = (state) => ({
  addresses: addressesSelector(state),
  credits: creditsSelector(state),
  preflight: preflightSelector(state),
  countries: countriesSelector(state),
  states: statesSelector(state),
  order: orderSelector(state),
});

const mapDispatchToProps = {
  addUserAddresses,
  editUserAddresses,
  clearPublish,
  deleteUserAddresses,
  goToEditDraft,
  goToEditDraftCover,
  goToEditDraftContent,
  getUserCredits,
};

export default withStore(mapStateToProps, mapDispatchToProps);
