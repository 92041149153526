import { useHistory } from 'react-router-dom';
import { CustomFlowbiteTheme, Modal } from 'flowbite-react';

import { getRouteBuyCredits, getRoutePlansAndBillings } from 'modules/v2/routes/navigation';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { BoltIcon, DangerTriangleSolidIcon } from 'modules/v2/common/components/SvgIcon';

const customTheme: CustomFlowbiteTheme['modal'] = {
  root: {
    show: {
      on: 'flex bg-neutral-900 bg-opacity-80',
      off: 'hidden',
    },
  },
  content: {
    base: 'relative h-full max-sm:w-full md:h-auto font-[figtree]',
  },
  body: {
    base: 'px-[30px]',
  },
  footer: {
    base:
      'flex space-x-2 rounded-b border-neutral-200 min-h-[91px] px-6 pb-[13px] pt-[34px] border-neutral-600',
  },
};

const InsuficientCreditsModal = ({ showModal, setShowModal, credits }) => {
  const history = useHistory();

  const handleBuyCredits = () => {
    history.push(getRouteBuyCredits());
  };

  const handleUpgradePlan = () => {
    history.push(getRoutePlansAndBillings());
  };

  return (
    <Modal
      theme={customTheme}
      show={showModal}
      onClose={() => setShowModal(false)}
      size="lg"
      popup
      dismissible
    >
      <Modal.Body className="flex flex-col items-center justify-center pt-[25px] font-figtree max-sm:w-full">
        <DangerTriangleSolidIcon />
        <div className="mt-3.5 mb-6 font-semibold text-base">
          You have <span className="text-danger-500">{credits}</span> credits left.
        </div>
        <div className="font-medium">
          Insufficient credits. To order printed books, you need a minimum of 8 credits. You can
          upgrade your plan to get more monthly credits or buy more credits.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex items-center justify-end gap-2">
          <Button
            color="outlined"
            onClick={() => handleBuyCredits()}
            className="border-neutral-200"
          >
            Buy credits
          </Button>
          <Button onClick={() => handleUpgradePlan()}>
            <div className="flex items-center gap-2">
              <BoltIcon />
              <span>Upgrade plan</span>
            </div>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InsuficientCreditsModal;
