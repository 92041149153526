import { useState } from 'react';
import { compose } from 'redux';
import { useQuery as useReactQuery } from 'react-query';
import {
  isGuideOrPacket,
  bookCreditsContentTitle,
  bookCreditsContentDescription,
  isGuide,
} from 'modules/v2/pages/Dentist/OrderGuideModal/constants';
import {
  fetchUserCredits,
  getShowCreditsButton,
  getGuideCatalog,
  getGuidesOrders,
  getGuideOnboardData,
} from 'modules/api';
import { withAuth } from 'modules/auth/containers';
import { pluralize } from 'modules/v2/common/utils';
import {
  PrimaryBlackButton,
  SecondaryBlackButton,
  Modal,
  Loader,
} from 'modules/v2/common/components';
import { DentistSideNav } from 'modules/v2/components';
import { DashboardTemplate } from 'modules/v2/templates';
import {
  withBooksStore,
  withProfile,
  withUserCredits,
  withReleaseNotes,
} from 'modules/v2/containers';
import OrderGuideModal from 'modules/v2/pages/Dentist/OrderGuideModal';
import { goToMyOrders } from 'modules/v2/draft/store';
import { PaymentSteps } from '../../PaymentSteps';
import S from './styles';
import { GuideGridPreview } from './GuideGridPreview';

const GuideCatalog = () => {
  const [showPaymentSteps, setShowPaymentSteps] = useState(false);
  const [showBuyCredits, setShowBuyCredits] = useState(true);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [guidePacketName, setGuidePacketName] = useState('guide');
  // For getting latest ordered Data for pre filling Guide Forms
  const currentOrderPage = 1;
  const perPage = 1;
  const { data: guidesOrders } = useReactQuery(['getGuidesOrders', currentOrderPage, perPage], () =>
    getGuidesOrders(currentOrderPage, perPage),
  );
  const { data: guidesOnboardData } = useReactQuery('guidesOnboardData', getGuideOnboardData);

  const guidePrefillFormData = guidesOnboardData || guidesOrders?.data[0] || {};

  // Handle Reorder Flow
  const [payloadData, setPayloadData] = useState({
    practiceName: '',
    practicePhone: '',
    practiceEmail: '',
    practiceLogo: '',
    practiceWebsite: '',
    quantity: 0,
    guideName: '',
    guideId: '',
    thumbnail: '',
    landingPage: '',
    frontCover: [],
    practiceAddress: {
      addressLine1: '',
      city: '',
      state: '',
      pincode: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    shippingAddress: {
      addressLine1: '',
      city: '',
      state: '',
      pincode: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  let creditsSubHeader = <S.Loading>Loading credits...</S.Loading>;
  let content = <Loader height="calc(100vh - 400px)" title="Loading Guide Catalog" />;

  const {
    data: userBookCredit,
    isLoading: isCreditLoading,
    refetch: reactCreditQueryRefetch,
  } = useReactQuery(['getUserCredits'], fetchUserCredits);
  const bookCredit = userBookCredit?.data?.data?.credits;

  useReactQuery('getShowCreditsButton', getShowCreditsButton, {
    onSuccess: (res) => {
      setShowBuyCredits(res);
    },
  });

  const { data: guides, isLoading: isGettingGuides } = useReactQuery(
    'getGuideCatalog',
    getGuideCatalog,
  );

  const handleCancelPublish = () => {
    setPublishModalOpen(false);
  };

  const handleClosePublish = () => {
    setPublishModalOpen(false);
  };

  const handleShowstepWarningModal = () => {
    setShowPaymentSteps(true);
    setIsWarningModalOpen(false);
  };

  const handleStartOrder = (item) => {
    setGuidePacketName(isGuideOrPacket(item.guideId));

    const isCreditLow = isGuide(item.guideId) ? bookCredit < 10 : bookCredit < 30;
    setIsWarningModalOpen(isCreditLow);

    if (!isCreditLow) {
      setPayloadData({
        ...payloadData,
        guideName: item.guideName,
        thumbnail: item.thumbnail,
        guideId: item.guideId,
      });
      setPublishModalOpen(true);
    }
  };

  if (!isCreditLoading) {
    creditsSubHeader = (
      <S.Credits isCreditEnough={bookCredit >= 8}>
        {pluralize('Credit', 'Credits', bookCredit)}
      </S.Credits>
    );
  }

  if (!isGettingGuides) {
    content = <GuideGridPreview guides={guides} handleStartOrder={handleStartOrder} />;
  }

  const subHeader = (
    <S.SubHeader>
      <S.SubHeaderWrapper>
        <S.CreditsWrapper>
          {creditsSubHeader}
          <PrimaryBlackButton onClick={() => setShowPaymentSteps(true)}>
            Buy Credits
          </PrimaryBlackButton>
        </S.CreditsWrapper>
      </S.SubHeaderWrapper>
    </S.SubHeader>
  );

  return (
    <DashboardTemplate sidebar={<DentistSideNav />} contentTitle="Guide Catalog" hasSidebar>
      {showBuyCredits && subHeader}
      {content}

      <Modal
        isOpen={isWarningModalOpen}
        maskClosable
        title="Warning"
        width="auto"
        footer={[
          <S.ModalFooterContainer key={1}>
            <PrimaryBlackButton style={{ width: '80%' }} onClick={handleShowstepWarningModal}>
              Buy Credits
            </PrimaryBlackButton>
            <SecondaryBlackButton
              style={{ width: '80%' }}
              onClick={() => setIsWarningModalOpen(false)}
            >
              Cancel, I'll buy later
            </SecondaryBlackButton>
          </S.ModalFooterContainer>,
        ]}
      >
        <S.ContentContainer>
          <S.ContentTitle>
            You have <span>{bookCredit} credits</span> left
          </S.ContentTitle>
          <S.ContentDescription>{bookCreditsContentTitle(guidePacketName)}</S.ContentDescription>
          <S.ContentDescription>
            {bookCreditsContentDescription(guidePacketName)}
          </S.ContentDescription>
        </S.ContentContainer>
      </Modal>
      <PaymentSteps
        setShowPaymentSteps={setShowPaymentSteps}
        showPaymentSteps={showPaymentSteps}
        getUserCredits={reactCreditQueryRefetch}
        type="guide"
      />
      {publishModalOpen && (
        <OrderGuideModal
          isOpen
          draftId="draftId"
          title={`Title: ${payloadData.guideName.toUpperCase()}`}
          onCancel={handleCancelPublish}
          onClose={handleClosePublish}
          setPayloadData={setPayloadData}
          payloadData={payloadData}
          onComplete={goToMyOrders}
          guidePrefillFormData={guidePrefillFormData}
          fromScreen="GuideCatalog"
          guidePacketName={guidePacketName}
        />
      )}
    </DashboardTemplate>
  );
};

export default compose(
  withAuth,
  withProfile,
  withUserCredits,
  withBooksStore,
  withReleaseNotes,
)(GuideCatalog);
