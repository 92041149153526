export const PLAIN_TEXT_ELEMENTS = [
  'authorName',
  'bookTitle',
  'bookTitleFrontcover',
  'spineAuthor',
  'spineName',
];

export const ELEMENT_SIMPLE_TOOLBAR = ['brokerInfo'];

export const CONTACT_DETAILS_MODAL = Symbol('CONTACT_DETAILS_MODAL');
export const FILE_PICKER_MODAL = Symbol('FILE_PICKER_MODAL');
export const RICH_TEXT_MODAL = Symbol('RICH_TEXT_MODAL');
export const PLAIN_TEXT_MODAL = Symbol('PLAIN_TEXT_MODAL');
