//

import { Modal, PrimaryButton } from 'modules/common/components';
import S from './styles';

const ResetDraftModal = ({ title, isOpen, onReset, onResetSection, onCancel }) => {
  const bookTitle = `Book Title: ${title}`;

  const footer = (
    <S.Footer>
      <div>
        <PrimaryButton key="reset" type="link" onClick={onReset}>
          Reset Book
        </PrimaryButton>
      </div>

      <div>
        <Modal.Button key="reset" type="cancel" onClick={onResetSection}>
          Reset Section
        </Modal.Button>
        <Modal.Button key="submit" type="primary" onClick={onCancel}>
          Keep Editing
        </Modal.Button>
      </div>
    </S.Footer>
  );

  return (
    <Modal
      footer={footer}
      title={bookTitle}
      width="590px"
      isOpen={isOpen}
      maskClosable={false}
      onCancel={onCancel}
    >
      <S.Wrapper>
        <S.Title>Resetting Your Book</S.Title>
        <S.Text>
          When your book is reset back to the default version, you will lose <br />
          any changes you've made up to this point.
        </S.Text>
        <S.Text>Would you like to continue?</S.Text>
      </S.Wrapper>
    </Modal>
  );
};

export default ResetDraftModal;
