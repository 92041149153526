import { compact, flatMap, noop } from 'lodash-es';

import S from './styles';

const intersperse = (items, divider = noop) => {
  const filtered = compact(items);
  const flatten = flatMap(filtered, (item, index) => (index ? [divider(index), item] : [item]));

  return flatten;
};

const Link = ({ title, to }) => (
  <S.LinkWrapper>
    <S.Link target="_blank" href={to}>
      {title}
    </S.Link>
  </S.LinkWrapper>
);

const Item = ({ landingPageUrl, orderDate, digitalBookUrl, marketingMaterialsUrl, title }) => {
  const links = [
    ['Digital Book', digitalBookUrl],
    ['Landing Page', landingPageUrl],
    ['Marketing Materials', marketingMaterialsUrl],
  ].map(([name, to]) => to && <Link key={name} title={name} to={to} />);

  const items = intersperse(links, (key) => <S.Divider key={key}>|</S.Divider>);

  return (
    <S.Wrapper>
      <S.Header>
        <span>{title}</span> -<span>{orderDate}</span>
      </S.Header>
      {items}
    </S.Wrapper>
  );
};

export default Item;
