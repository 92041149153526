const PublishIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.718 11.0395L17.1494 9.4296L17.1494 9.42959C17.6529 7.55036 17.9047 6.61075 17.7151 5.7976C17.5654 5.15555 17.2287 4.57232 16.7475 4.12164C16.1381 3.55087 15.1985 3.2991 13.3192 2.79556C11.44 2.29203 10.5004 2.04026 9.68722 2.22985C9.04517 2.37956 8.46193 2.71629 8.01126 3.19747C7.52258 3.71923 7.26774 4.48306 6.88851 5.87143C6.82482 6.10458 6.75763 6.35535 6.68523 6.62554L6.68518 6.62573L6.25382 8.2356C5.75028 10.1148 5.49851 11.0545 5.68811 11.8676C5.83781 12.5096 6.17454 13.0929 6.65572 13.5436C7.26513 14.1143 8.20474 14.3661 10.084 14.8696L10.084 14.8696C11.7778 15.3235 12.7083 15.5728 13.4708 15.4788C13.5543 15.4685 13.6358 15.4541 13.716 15.4353C14.358 15.2856 14.9413 14.9489 15.3919 14.4677C15.9627 13.8583 16.2145 12.9187 16.718 11.0395Z"
        fill="#252525"
      />
      <path
        d="M2.08682 12.7625L2.51819 14.3724C3.02173 16.2516 3.2735 17.1912 3.84427 17.8007C4.29494 18.2818 4.87818 18.6186 5.52023 18.7683C6.33337 18.9579 7.27299 18.7061 9.15222 18.2026L9.15222 18.2026C11.0315 17.699 11.9711 17.4473 12.5805 16.8765C12.6311 16.8291 12.68 16.7803 12.7274 16.7301C12.4489 16.7067 12.168 16.6619 11.8825 16.6051C11.3024 16.4898 10.6132 16.3051 9.79801 16.0866L9.709 16.0628L9.68838 16.0573C8.8014 15.8196 8.06035 15.6206 7.46865 15.4069C6.8464 15.1821 6.28101 14.905 5.80091 14.4553C5.13929 13.8356 4.67628 13.0337 4.47044 12.1509C4.32106 11.5103 4.36377 10.8821 4.48023 10.2308C4.59183 9.60668 4.79225 8.85874 5.03239 7.96262L5.03239 7.96261L5.47772 6.30064L5.49338 6.24219C3.89293 6.67332 3.05052 6.9284 2.48873 7.45457C2.00755 7.90524 1.67082 8.48848 1.52111 9.13053C1.33152 9.94367 1.58329 10.8833 2.08682 12.7625Z"
        fill="#252525"
      />
    </svg>
  );
};

export default PublishIcon;
