import { useMemo } from 'react';
import { Checkbox } from 'antd';
import { map, get } from 'lodash-es';
import { Form, Formik } from 'formik';
import { FormErrors, SelectField } from 'modules/common/components';
import Modal from 'modules/common/components/Modal';
import {
  bookLabelStylesForModal,
  getBookById,
  handleStylingForUserDetailsModal,
} from 'modules/previewBook/utils';
import { withCountries } from 'modules/dashboard/containers';
import { CustomButton } from '../UIComponents';
import S from './styles';

import validationSchema from './validationSchema';

const UserDetailsModal = ({
  onCancel,
  selectedBookId,
  userUploadedImage,
  generateBook,
  authorName,
  countries,
  states,
  fields,
  loading,
}) => {
  const imageStyle = useMemo(
    () => handleStylingForUserDetailsModal(selectedBookId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBookId],
  );

  const nameStyle = useMemo(
    () => bookLabelStylesForModal(selectedBookId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBookId],
  );

  const selectedBook = useMemo(() => getBookById(selectedBookId)?.front, [selectedBookId]);

  const onSave = (values) => {
    generateBook(values);
  };

  return (
    <Modal
      isOpen
      footer={null}
      onCancel={onCancel}
      width="1000px"
      destroyOnClose
      onConfirm={onCancel}
      hasCloseButton={false}
    >
      <S.BackgroundBox>
        <S.TopHeading onClick={onCancel}>
          <S.Icon>X</S.Icon>
        </S.TopHeading>
        <S.ModalHeading>Want a Free Printed Copy of your Book Mailed to you?</S.ModalHeading>
        <S.ModalSubHeading>Where can we ship your book?</S.ModalSubHeading>
        <S.BookBackBox>
          <S.BookForm>
            <Formik
              initialValues={fields}
              validationSchema={validationSchema}
              onSubmit={onSave}
              enableReinitialize
            >
              {({
                errors,
                handleChange,
                setFieldValue,
                values,
                handleSubmit,
                touched,
                isValid,
              }) => {
                const optionBuilder = (item) => ({ title: item, value: item });
                const selectedCountry = values.country;
                const selectedStateList = get(states?.data || [], selectedCountry);
                const countryList = map(countries?.data || [], optionBuilder);
                const stateList = map(selectedStateList, optionBuilder);
                const handleCountryChange = (e) => setFieldValue('country', e);
                const handleStateChange = (e) => setFieldValue('state', e);
                return (
                  <Form onSubmit={handleSubmit}>
                    <FormErrors errors={errors} />
                    <S.InputWrap
                      placeholder="Type name here"
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      disabled
                    />
                    <S.InputWrap
                      placeholder="Type email address here"
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      disabled
                    />
                    <S.InputWrap
                      placeholder="Type phone number here"
                      name="phone"
                      id="phone"
                      value={values.phone}
                      onChange={handleChange}
                      disabled
                    />
                    <S.CheckboxWrap>
                      <Checkbox
                        name="textMessageOptIn"
                        onChange={handleChange}
                        checked={values.textMessageOptIn}
                      />
                      <S.CheckboxText>Text me the digital preview instantly</S.CheckboxText>
                    </S.CheckboxWrap>
                    <S.InputWrap
                      placeholder="Shipping Address"
                      id="address"
                      name="address"
                      onChange={handleChange}
                      error={errors.address && touched.address}
                    />
                    <S.FlexContainer>
                      <S.FlexItem
                        className="country-select"
                        error={errors.country && touched.country}
                      >
                        <SelectField
                          placeholder="Country"
                          name="country"
                          id="country"
                          onChange={handleCountryChange}
                          options={countryList}
                          defaultValue={values.country}
                        />
                      </S.FlexItem>
                      <S.FlexItem error={errors.state && touched.state}>
                        <SelectField
                          placeholder="State"
                          name="state"
                          id="state"
                          onChange={handleStateChange}
                          options={stateList.sort((a, b) => a.title.localeCompare(b.title))}
                          defaultValue={values.state}
                        />
                      </S.FlexItem>
                    </S.FlexContainer>
                    <S.FlexContainer>
                      <S.InputWrap
                        placeholder="City"
                        className="city"
                        name="city"
                        id="city"
                        onChange={handleChange}
                        error={errors.city && touched.city}
                      />
                      <S.InputWrap
                        placeholder="ZIP"
                        name="zip"
                        id="zip"
                        onChange={handleChange}
                        error={errors.zip && touched.zip}
                      />
                    </S.FlexContainer>
                    <CustomButton
                      disabled={loading || !isValid}
                      loading={loading}
                      htmlType="submit"
                    >
                      Ship My Book
                    </CustomButton>
                  </Form>
                );
              }}
            </Formik>
          </S.BookForm>
          <S.PreviewBook>
            <S.CoverWrap>
              {userUploadedImage ? (
                <S.UserUploadedImage src={userUploadedImage} alt="user-avatar" style={imageStyle} />
              ) : null}
              <S.BookImage src={selectedBook} />
              <S.AuthorNameBottom style={nameStyle}>{authorName}</S.AuthorNameBottom>
              <S.AuthorNameLeft>{authorName}</S.AuthorNameLeft>
            </S.CoverWrap>
          </S.PreviewBook>
        </S.BookBackBox>
        <S.DisclaimerText>
          Please allow 7-10 business days for delivery. If you want to preview your book instantly,
          then check the box “Text me the digital preview instantly” to receive the book via an
          immediate text message. By providing us with your information you are consenting to the
          collection and use of your information in accordance with our Terms of Service and Privacy
          Policy.
        </S.DisclaimerText>
      </S.BackgroundBox>
    </Modal>
  );
};
export default withCountries(UserDetailsModal);
