import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { FileImageOutlined, SyncOutlined } from '@ant-design/icons';
import { Input } from 'modules/common/components/UIComponents/Form';
import { Modal } from 'modules/common/components';
import { Field } from 'formik';
import { Select as BaseSelect } from 'antd';
import BaseInputMask from 'react-input-mask';

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: 245px;
  }
  .ant-modal-content {
    width: 700px;
  }
`;

const inputStyle = `
  border: 1px solid #E9E9E9;
  border-radius: 6px !important;
  background-color: ${color.White};
  width: 100%;
  height: 44px;
  display: flex;
  font-size: 14px;
  line-height: 22px;
  outline: none;

  .ant-input::placeholder {
    color: ${color.grayDescription} !important;
  }

  svg {
    color: ${color.grayDescription};
  }

  &:focus {
    border: 2px solid  ${color.LightBlue} !important;
  }

  &:active {
    border: 2px solid  ${color.LightBlue} !important;
  }
`;

const InputMask = styled(BaseInputMask)`
  ${inputStyle}
  padding: 9px 14px;

  ${(props) =>
    props.$hasError &&
    `
      border: 1px solid ${color.ErrorWarning};
    `}

  ${({ disabled }) =>
    disabled
      ? `
      color: ${color.InputMaskDisabled};
      background-color: ${color.InputMaskBackGroundDisabled};
    `
      : ''}
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 4px;
`;

const { TextArea } = Input;

const InputTextArea = styled(TextArea)`
  height: 64px;
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  font-size: 18px;
  line-height: 22px;
  color: ${color.UserMenuText};
`;

const Message = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: ${(props) => (props.primary ? 'flex-end' : ' ')};
  line-height: 17px;
  margin-top: 10px;
`;
const VerifiedMessage = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: ${(props) => (props.primary ? 'flex-end' : ' ')};
  line-height: 17px;
  margin-top: 10px;
  color: green;
`;

const footerWrapper = { marginTop: '40px', width: '227px', float: 'right' };
const MainWrapper = styled.div`
  ${(props) => props.fotter && footerWrapper};
  ${(props) =>
    props.fotterleft && {
      marginTop: '40px',
      marginLeft: '40px',
      width: '227px',
      float: 'right',
    }};
  ${(props) =>
    props.emaibutton && {
      display: 'flex',
    }};
`;

const Button = styled.button`
  float: right;
  padding: 10px 20px;
  border: none;
  background: #1890ff;
`;

const EmailVerificationBtn = styled.button`
  width: 227px;
  height: 40px !important;
  background-color: ${color.SkyBlue};
  color: ${color.White};
  border: none;
  border-radius: 2px !important;
  opacity: 1;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.3px;

  &:hover {
    color: ${color.White};
    background-color: ${color.LeadsButtonHover};
    border-color: ${color.LeadsButtonHover};
    cursor: pointer;
  }
`;

const ButtonText = styled.span`
  margin-right: 14px;
`;

const EmailVerificationMessage = styled.div`
  font-size: 14px;
`;

const UploadPicture = styled.div`
  display: flex;
`;

const ProfilePicture = styled.div`
  cursor: pointer;
  background: ${color.GreyFormBorder};
  width: 80px;
  height: 80px;
  border: 1px solid ${color.GreyFormBorder};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashboardSubTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #059100;
`;

const UploadButton = styled(FileImageOutlined)`
  svg {
    width: 35px;
    height: 35px;
  }
`;

const EmailInput = styled(Input)`
  display: flex;
  text-transform: lowercase;
`;

const ImageRequirementsMessage = styled.div`
  font-size: 14px;
  margin-left: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const EmailRefresh = styled(SyncOutlined)`
  font-size: 20px;
  margin: 12px;
  color: #2e3f50;
  &:hover {
    color: ${color.SkyBlue};
  }
  cursor: pointer;
`;

const Select = styled(BaseSelect)`
  width: 100%;
  .ant-select-selector {
    border-radius: 6px !important;
    border: 1px solid ${color.neutral200} !important;

    height: 44px !important;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: ${color.neutral200};
    }
    ::-webkit-scrollbar-thumb {
      background: ${color.neutral100};
      border-radius: 4px;
    }
  }
  .ant-select-selection-overflow {
    height: 44px;
  }
  .ant-select-selection-item {
    border-radius: 6px;
  }
  .ant-select-item .ant-select-item-option {
    border-radius: 6px;
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 800;
  color: ${color.Black};
  margin-bottom: 24px;
`;

export default {
  Label,
  FormikField,
  MainWrapper,
  Message,
  VerifiedMessage,
  ProfilePicture,
  UploadPicture,
  UploadButton,
  EmailInput,
  ImageRequirementsMessage,
  Button,
  EmailVerificationBtn,
  ButtonText,
  EmailVerificationMessage,
  InputTextArea,
  ModalWrapper,
  DashboardSubTitle,
  Image,
  EmailRefresh,
  Select,
  Heading,
  InputMask,
};
