//

import { map, noop } from 'lodash-es';

import CriteriaItem from './components/CriteriaItem';

import S from './styles';

const CriteriaList = ({ items, onEdit = noop }) => {
  const handleEdit = (id) => () => onEdit(id);

  return (
    <div className="">
      <S.ListWrapper>
        {map(items, ({ id, text, checked }) => (
          <CriteriaItem key={text} text={text} checked={checked} onEdit={handleEdit(id)} />
        ))}
      </S.ListWrapper>
    </div>
  );
};

export default CriteriaList;
