import { spawn } from 'redux-saga/effects';

import { watchUploadAvatar } from './uploadAvatar';
import { watchGetProfile } from './getProfile';
import { watchUpdateProfile } from './updateProfile';

export default function* saga() {
  yield spawn(watchUploadAvatar);
  yield spawn(watchGetProfile);
  yield spawn(watchUpdateProfile);
}
