import jwt from 'jwt-decode';

import env from 'environments';
import store from 'configuration/store';
import { getItem } from 'modules/dashboard/utils/legacy';
import { APP_RESET } from 'store/constants';
import { ROUTE_DRAFT, ROUTE_GENERATE_DRAFT_WELCOME_JOURNEY } from 'modules/v2/draft/routes/constants';
import { ROUTE_EDIT_DRAFT } from 'modules/v2/editor/routes/constants';
import { ROUTE_WELCOME_JOURNEY, ROUTE_HOMEPAGE } from 'modules/v2/routes/constants';
import { buildRedirect } from './utils';

const prefix = env.ADMIN_PREFIX;

const allowWelcomeJourney = [
  ROUTE_DRAFT,
  ROUTE_EDIT_DRAFT,
  ROUTE_WELCOME_JOURNEY,
  ROUTE_GENERATE_DRAFT_WELCOME_JOURNEY,
  ROUTE_HOMEPAGE
];

export const getData = () => {
  const token = getItem('token', 'string');
  const refreshToken = getItem('refresh_token', 'string');
  const userData = getItem('userData', 'object');
  const admin = getItem('admin', 'boolean');

  return {
    token,
    refreshToken,
    userData,
    admin,
  };
};

export const hasAccessToDMPOneDotFive = () => {
  return true;
};

export const validateToken = (token) => {
  try {
    const decoded = jwt(token);
    const now = Date.now() / 1000;

    return decoded.exp > now;
  } catch {
    return false;
  }
};

export const authGuard = ({ location, match }) => {
  const { path } = match;
  const { token, userData, refreshToken, admin } = getData();

  const isLogged = !!token && !!userData && !!refreshToken && !admin;
  const isValid = validateToken(token) && validateToken(refreshToken);

  if (userData?.welcomeJourneyEligible && !allowWelcomeJourney.includes(path)) {
    return 'notFound';
  }

  if (isLogged && isValid) {
    return null;
  }



  // Resetting APP state
  store.dispatch({ type: APP_RESET });

  return buildRedirect('/auth/login', location);
};


export const adminGuard = ({ location }) => {
  const { token, userData, admin } = getData();

  const isLogged = !!token && !!userData && !!admin;
  const isValid = validateToken(token);

  if (isLogged && isValid) {
    return null;
  }

  return buildRedirect(`/${prefix}/login`, location);
};

export const holidaySaleGuard = ({ location }) => {
  const { token, userData, refreshToken, admin } = getData();

  const isLogged = !!token && !!userData && !!refreshToken && !admin;
  const isValid = validateToken(token) && validateToken(refreshToken);

  if (isLogged && isValid) {
    return null;
  }

  // Resetting APP state
  store.dispatch({ type: APP_RESET });

  return buildRedirect('/holiday-sale/auth/login', location);
};
