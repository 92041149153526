import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Title = styled.h3`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 20px 0 10px 0 !important;

  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
`;

const FieldGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
`;

const ProfileWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const ProfilePicture = styled.div`
  cursor: pointer;
  background: ${color.GreyDivider};
  width: 80px;
  height: 80px;
  border: 1px solid ${color.GreyFormBorder};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
  }
`;

const ImageRequirements = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 20px;
`;

export default {
  Title,
  FieldGroupColumn,
  ButtonContainer,
  ProfileWrapper,
  ProfilePicture,
  ImageRequirements,
};
