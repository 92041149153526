import { Radio } from 'antd';
import { get } from 'lodash-es';
import { getSession } from './steps';

export function getInitialFields(offerCode) {
  const session = getSession(offerCode);
  const customer = get(session, 'customer', null);

  if (customer) {
    return customer;
  }

  return {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    billing: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US', // USA should be the default value
    },
    periodPay: 'monthly',
    optinReminder: false,
  };
}
