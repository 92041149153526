//

import { includes } from 'lodash-es';

import { AddressList } from 'modules/editor/components';
import S from './styles';

const FORBIDDEN_EDITABLE = ['billing', 'externalShipping'];

const AddressCard = ({ address, selectedAddressId, onSelect, onEdit, onDelete }) => {
  const { _id, firstName, lastName, addressLine1, addressLine2, city, state, pincode, country } =
    address;

  const isSelectable = typeof onSelect === 'function';
  const isAllowed = !includes(FORBIDDEN_EDITABLE, _id);
  const isSelected = _id === selectedAddressId;
  const handleSelect = isSelectable ? () => onSelect(_id) : null;
  const handleDelete = isAllowed ? () => onDelete(_id) : null;

  return (
    <S.Wrapper key={_id}>
      <AddressList.Item
        firstName={firstName}
        lastName={lastName}
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        city={city}
        state={state}
        pincode={pincode}
        country={country}
        isSelected={isSelected}
        onEdit={isAllowed && (() => onEdit(_id))}
        onSelect={handleSelect}
        onDelete={handleDelete}
      />
    </S.Wrapper>
  );
};

export default AddressCard;
