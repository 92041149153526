import { Button as AntdButton, Input as BaseInput } from 'antd';
import { FormLabel as BaseFormLabel } from 'modules/common/components';
import BaseInputMask from 'react-input-mask';
import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Form = styled.div`
  text-align: left;
`;

const FormLabel = styled(BaseFormLabel)`
  display: block;
  margin: 0 0 20px 15px;
  position: relative;
  text-transform: uppercase;

  & > span:first-of-type {
    text-transform: uppercase;
  }
`;

const Input = styled(BaseInput)`
  height: 45px;
  width: 100%;
  display: block;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 0 15px;
  font-size: 16px;
  line-height: 22px;
  color: ${color.GreySelectDd};

  ::placeholder {
    text-transform: uppercase;
  }
`;

const InputMask = Input.withComponent(BaseInputMask);
const TextArea = styled(Input.withComponent(BaseInput.TextArea))`
  padding: 5px 15px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding: 10px 0;
  flex-wrap: wrap;

  .ant-btn.ant-btn-primary {
    font-weight: 600;
  }
`;

const RequiredTextWrapper = styled.div`
  display: inline-block;
  margin-left: 15px;
  font-weight: 600;
`;

const Button = styled(AntdButton)`
  height: 38px;
  line-height: 38px !important;
  text-transform: uppercase;
  background-color: #e4e4e4;
  border: 1px solid #cecdcd;

  svg {
    fill: white;
    height: 13px;
    margin-left: 5px;
  }
`;

export default {
  Button,
  ButtonRow,
  Form,
  FormLabel,
  Input,
  InputMask,
  RequiredTextWrapper,
  TextArea,
};
