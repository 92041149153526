import { useFormikContext } from 'formik';

import Modal from 'modules/common/components/Modal';

import { ContactDetailsForm } from './components';
import S from './styles';

const ContactDetailsModal = ({ isOpen, onCancel }) => {
  const { submitForm } = useFormikContext();

  return (
    <Modal
      width="513px"
      title="Edit your contact information"
      isOpen={isOpen}
      onCancel={onCancel}
      maskClosable={false}
      footer={false}
    >
      <S.Wrapper className="p-0">
        <ContactDetailsForm onConfirm={submitForm} onCancel={onCancel} />
      </S.Wrapper>
    </Modal>
  );
};

export default ContactDetailsModal;
