import { useState } from 'react';
import { useMutation } from 'react-query';
import { Drawer } from 'antd';
import { Alert, Radio } from 'flowbite-react';
import { map } from 'lodash-es';

import { updateDefaultAddress } from 'modules/api/user/';
import { notification } from 'modules/common/utils';
import DeleteModal from 'modules/v2/common/AtomicDesign/organisms/DeleteModal';
import { AddressList } from 'modules/v2/editor/components';
import { Button, Box } from 'modules/v2/common/AtomicDesign/atoms';
import AlertWarning from 'modules/v2/common/components/SvgIcon/AlertWarning';
import { AddCircleIcon } from 'modules/v2/common/components/SvgIcon/AddCircleIcon';
import AddressCard from './components/AddressCard';

const AddressListView = ({ addresses, onSelect, onCreate, onEdit, onDelete }) => {
  const [addressEditId, setAddressEditId] = useState('');
  const [asNewDefaultAddress, setAsNewDefaultAddress] = useState('');

  const { mutate: mutateDefaultAddress } = useMutation(updateDefaultAddress, {
    onSuccess: () => {
      if (asNewDefaultAddress) {
        onDelete(addressEditId);
      }
    },
    onError: (error) => {
      notification.error({
        description: `Something went wrong, ${error.message}`,
      });
    },
  });

  const handleCancel = () => {
    setAddressEditId('');
  };

  const handleOpenConfirmModal = (id) => {
    setAddressEditId(id);
  };

  const setDefaultAddress = (e) => {
    const { value } = e.target;
    setAsNewDefaultAddress(value);
  };

  const handleDeleteConfirm = () => {
    if (asNewDefaultAddress) {
      mutateDefaultAddress(asNewDefaultAddress);
      return;
    }

    onDelete(addressEditId);
  };

  const handleSelectedAddress = (id) => {
    onSelect(id);
    mutateDefaultAddress(id);
  };

  const selectedAddress = addresses.find((address) => address._id === addressEditId);

  const hasSelectedAddress = Boolean(selectedAddress);
  const deleteDefaultAddress = hasSelectedAddress && selectedAddress.isDefault;

  const handleDelete = (id) => handleOpenConfirmModal(id);
  const { addressLine1, pincode, country, city, state, firstName, lastName, phoneNumber } =
    selectedAddress || {};

  return (
    <Box>
      <div className="text-base font-medium py-4 px-6 border-b text-neutral-800">
        Your shipping address
      </div>
      <div className="p-6">
        <AddressList onCreate={onCreate}>
          {map(addresses, (address) => (
            <AddressCard
              key={address._id}
              address={address}
              selectedAddressId={addressEditId}
              onDelete={handleDelete}
              onSelect={handleSelectedAddress}
              onEdit={onEdit}
            />
          ))}

          <Drawer
            open={deleteDefaultAddress}
            width={600}
            // eslint-disable-next-line react/jsx-boolean-value
            destroyOnClose={true}
            title="Change default shipping address"
            headerStyle={{ fontSize: '2rem' }}
            onClose={handleCancel}
          >
            <Alert color="warning" className="bg-secondary-orange-50 mb-6">
              <div className="w-full flex gap-4">
                <AlertWarning />
                <span className="font-medium text-orange-600">
                  This address is being used as your default address. To delete, select or add new
                  address.
                </span>
              </div>
            </Alert>
            <div>
              <div className="text-sm text-neutral-800 font-semibold mb-2">
                Select new default shipping address
              </div>
              <ul className="flex flex-col gap-3">
                {map(addresses, (address) => {
                  if (address._id !== addressEditId && !address.default) {
                    return (
                      <li key={address._id} className="rounded-lg border">
                        <fieldset className="p-6 flex gap-3">
                          <Radio
                            id={address._id}
                            name="shippping_address"
                            value={address._id}
                            onChange={setDefaultAddress}
                          />
                          <div className="flex flex-col gap-1">
                            <div className="text-sm text-neutral-800 font-semibold mb-2">
                              {address.firstName} {address.lastName}
                            </div>
                            <div className="text-sm text-neutral-500 font-medium">
                              {address.addressLine1}
                            </div>
                            {address.addressLine2 && (
                              <div className="text-sm text-neutral-500 font-medium">
                                {address.addressLine2}
                              </div>
                            )}
                            <div className="text-sm text-neutral-500 font-medium">
                              {address.city} {address.state} {address.pincode}
                            </div>
                            <div className="text-sm text-neutral-500 font-medium">
                              {address.country}
                            </div>
                            {address.phoneNumber && (
                              <div className="text-sm text-neutral-500 font-medium">
                                Phone: {address.phoneNumber}
                              </div>
                            )}
                          </div>
                        </fieldset>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
              <Button onClick={onCreate} type="bare">
                <AddCircleIcon className="mr-2" /> Add new address
              </Button>
            </div>
            <div className="flex absolute bottom-0 left-0 border-t pt-6 pb-8 px-6 w-full">
              <Button
                color="light"
                onClick={handleDeleteConfirm}
                full
                className="block bg-neutral-200 flex justify-center"
              >
                Save changes
              </Button>
            </div>
          </Drawer>

          <DeleteModal
            title="Delete address"
            showModal={hasSelectedAddress && !deleteDefaultAddress}
            setShowModal={handleCancel}
            handleDelete={() => handleDeleteConfirm()}
            deleteButton="Yes, Delete address"
          >
            <div className="rounded-lg bg-primary-50 p-6">
              <div className="text-sm text-neutral-800 font-semibold mb-2">
                {firstName} {lastName}
              </div>
              <div className="text-sm text-neutral-500 font-medium">{addressLine1}</div>
              <div className="text-sm text-neutral-500 font-medium">
                {city} {state} {pincode}
              </div>
              <div className="text-sm text-neutral-500 font-medium">{country}</div>
              {phoneNumber && (
                <div className="text-sm text-neutral-500 font-medium">Phone: {phoneNumber}</div>
              )}
            </div>
          </DeleteModal>
        </AddressList>
      </div>
    </Box>
  );
};

export default AddressListView;
