import S from './styles';

const ErrorState = ({ topMessage, bottomMessage }) => (
  <S.ErrorStateWrapper>
    <S.ErrorMessage>{topMessage}</S.ErrorMessage>
    <S.ErrorMessage>{bottomMessage}</S.ErrorMessage>
  </S.ErrorStateWrapper>
);

export default ErrorState;
