import { Link } from 'react-router-dom';

import LogoImg from 'assets/images/logo/logo2.png';
import Sidebg from 'assets/images/logo/side-bg.jpg';

import S from './styles';

const AuthenticationTemplate = ({ children }) => (
  <S.LoginWrapper>
    <S.Container>
      <S.FormContent>
        <S.LoginLogo>
          <Link to="/">
            <img src={LogoImg} alt="Authorify Logo" />
          </Link>
        </S.LoginLogo>

        {children}
      </S.FormContent>

      <S.Sidebar>
        {/* This image can be a link  */}
        <img src={Sidebg} alt="" />
      </S.Sidebar>
    </S.Container>
  </S.LoginWrapper>
);

export default AuthenticationTemplate;
