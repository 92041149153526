import { get, isObject } from 'lodash-es';

export const addressToString = (address) => {
  if (!isObject(address)) {
    return '';
  }

  const addressLine1 = get(address, 'addressLine1');
  const addressLine2 = get(address, 'addressLine2');
  const city = get(address, 'city');
  const state = get(address, 'state');
  const country = get(address, 'country');
  const pincode = get(address, 'pincode');

  const location = [addressLine1, addressLine2].filter(Boolean).join(' ');
  const province = [city, state].filter(Boolean).join(' - ');
  const federal = [pincode, country].filter(Boolean).join(' ');

  return [location, province, federal].filter(Boolean).join(', ');
};
