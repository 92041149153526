//

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { RichUtils } from 'draft-js';
import classnames from 'classnames';
import { noop } from 'lodash-es';

import { options } from 'modules/v2/editor/components/ContentEditor/utils';

const Highlight = ({ editor, onChange = noop }) => {
  const { type, style } = options.highlight;

  const handleStyle = (e) => {
    e.preventDefault();
    onChange(RichUtils.toggleInlineStyle(editor, style.toUpperCase()));
  };

  const active = editor.getCurrentInlineStyle().has(style);

  return (
    <span className={classnames('normal', { active })} onClick={handleStyle}>
      <LegacyIcon type={type} />
    </span>
  );
};

export default Highlight;
