import { get } from 'lodash-es';
import { views } from 'modules/publish/constants';

export const getViewTitle = (view) => {
  const titles = {
    [views.PREFLIGHT]: 'Pre-Flight Check',
    [views.CHECKOUT]: 'Book Quantity',
    [views.ADDRESS_LIST]: 'Shipping Address',
    [views.REVIEW]: 'Review Order',
  };

  return get(titles, view, '');
};

export const convertStepToView = (step) => {
  switch (step) {
    case 0:
      return views.PREFLIGHT;

    case 1:
      return views.CHECKOUT;

    case 2:
      return views.ADDRESS_LIST;

    case 3:
      return views.REVIEW;

    default:
      return '';
  }
};

export const convertViewToStep = (view) => {
  switch (view) {
    case views.PREFLIGHT:
      return 0;

    case views.CHECKOUT:
      return 1;

    case views.ADDRESS_LIST:
    case views.ADDRESS_FORM:
      return 2;

    case views.REVIEW:
      return 3;

    default:
      return 0;
  }
};

const buildStep = (isReady, isOpaque, isDisabled) => {
  if (isReady) {
    return {
      status: 'finish',
      disabled: isDisabled,
    };
  }

  return {
    status: isOpaque ? 'wait' : 'process',
    disabled: isDisabled,
  };
};

export const checkStep =
  ({ selectedAddressId, numberOfPrints }) =>
    (current, next) => {
      const hasAddressSelected = selectedAddressId !== null;
      const isPrinted = numberOfPrints !== null && numberOfPrints > 0;

      switch (next) {
        case views.PREFLIGHT: {
          const isReady = current !== views.PREFLIGHT;
          const isDisabled = true;
          const isOpaque = false;

          return buildStep(isReady, isOpaque, isDisabled);
        }

        case views.CHECKOUT: {
          const isReady = isPrinted;
          const isDisabled = !isPrinted && current === views.PREFLIGHT;
          const isOpaque = !isReady && current !== views.CHECKOUT;

          return buildStep(isReady, isOpaque, isDisabled);
        }

        case views.ADDRESS_LIST: {
          const isReady = isPrinted && hasAddressSelected;
          const isDisabled = !isPrinted;
          const isOpaque = !isReady && !isPrinted;

          return buildStep(isReady, isOpaque, isDisabled);
        }

        case views.REVIEW: {
          const isReady = false;
          const isDisabled = !(isPrinted && hasAddressSelected);
          const isOpaque = !isReady && !(isPrinted && hasAddressSelected);

          return buildStep(isReady, isOpaque, isDisabled);
        }

        default:
          return { status: 'wait', disabled: true };
      }
    };
