import styled from 'styled-components';
import {
  CustomerServiceOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const IconsStyle = styled.div`
  color: ${color.LightBlue};
  margin-right: 20px;
  font-size: 36px;
  position: relative;
  top: 5px;
`;

const MessageIcon = IconsStyle.withComponent(MessageOutlined);
const QuestionIcon = IconsStyle.withComponent(QuestionCircleOutlined);
const CustomerServiceIcon = IconsStyle.withComponent(CustomerServiceOutlined);

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 20px;
  font-size: 12px;
  font-family: ${font.MontserratFont};

  a {
    color: ${color.LightBlue};
  }
`;

const BoxGroup = styled.div`
  display: flex;
  width: 100%;
`;

const Box = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${color.GrayBorder};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  padding: 30px;
  margin-bottom: 20px;
  font-size: 14px;

  p {
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 10px;
    margin-left: 15px;
  }
`;

Box.Title = styled.h3`
  font-size: 24px;
  font-weight: normal !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid ${color.GreyBookBorder};
  padding: 5px 0 20px !important;
`;

Box.Text = styled.p`
  line-height: 25px !important;
  padding-bottom: 25px;
  margin-bottom: 0 !important;

  em {
    font-weight: bold;
    font-style: normal;
  }
`;

const Greetings = styled.span`
  font-weight: bold;
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
`;

export default {
  Wrapper,
  BoxGroup,
  Box,
  Greetings,
  MessageIcon,
  QuestionIcon,
  CustomerServiceIcon,
};
