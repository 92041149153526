import { useState } from 'react';
import { get, sortBy } from 'lodash-es';
import { Collapse, Switch } from 'antd';
import { useFormikContext } from 'formik';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';

import {
  FormLabel,
  LabelTitle,
  ImageUploader,
  ErrorMessage,
  SelectField,
} from 'modules/common/components';

import { useStatesAndCountries } from 'modules/common/hooks';
import { COUNTRY_NAME } from 'modules/common/constants';

import {
  DeleteButton,
  PrimaryButton,
  FieldControl,
  InputMask,
  Input,
} from 'modules/common/components/UIComponents';

import S from './styles';

const { Panel } = Collapse;

const DentistGuideDetailsForm = ({ hideButton = false }) => {
  const [addNewDentists, setAddNewDentists] = useState(false);
  const { states, countries } = useStatesAndCountries();
  const formik = useFormikContext();
  const {
    values,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
  } = formik;

  const handleAddNewDentist = () => {
    const frontCover = values?.frontCover;

    if (frontCover.length === 3) {
      return false;
    }

    const newFrontCover = [
      ...frontCover,
      {
        id: values.frontCover.length + 1,
        image: '',
        name: '',
        title: '',
      },
    ];

    return setFieldValue('frontCover', newFrontCover);
  };

  const renderError = (fieldPath) => {
    const fieldTouched = get(touched, fieldPath);
    const fieldError = get(errors, fieldPath);

    return fieldTouched && fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null;
  };

  const hasError = (fieldPath) => {
    const fieldTouched = get(touched, fieldPath);
    const fieldError = get(errors, fieldPath);

    return !!fieldTouched && !!fieldError;
  };

  const removePartner = (index) => {
    if (values.frontCover.length === 1) {
      setAddNewDentists(false);
    }

    const newPartners = [...values.frontCover];
    newPartners.splice(index, 1);
    return setFieldValue('frontCover', newPartners);
  };

  const selectedCountry = COUNTRY_NAME[values?.shippingAddress?.country];
  const selectedStateList = get(states, selectedCountry);

  const stateList = sortBy(selectedStateList, 'title');
  const handleCountryChange = () => setFieldValue('shippingAddress.state', undefined);

  const handleChangeAddNewDentists = (isEnabled) => {
    if (!isEnabled) {
      setFieldValue('frontCover', []);
    }

    if (isEnabled && !values.frontCover?.length) {
      handleAddNewDentist();
    }
    setAddNewDentists(isEnabled);
  };

  return (
    <form onSubmit={handleSubmit}>
      <S.FieldGroup gap>
        <FieldControl>
          <FormLabel title="Practice name" htmlFor="practiceName" required>
            <Input
              id="practiceName"
              name="practiceName"
              value={values?.practiceName}
              $hasError={hasError('practiceName')}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            {renderError('practiceName')}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.FieldGroup>
        <FieldControl>
          <FormLabel title="Address" htmlFor="address" required>
            <Input
              id="practiceAddress"
              name="shippingAddress.addressLine1"
              value={values?.shippingAddress?.addressLine1}
              placeholder="Address"
              $hasError={
                errors.shippingAddress?.addressLine1 && touched.shippingAddress?.addressLine1
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            {renderError('shippingAddress.addressLine1')}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.FieldGroup gap>
        <FieldControl>
          <FormLabel title="City" id="city" required>
            <Input
              id="city"
              name="shippingAddress.city"
              value={values?.shippingAddress?.city}
              placeholder="City"
              $hasError={errors.shippingAddress?.city && touched.shippingAddress?.city}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            {errors.shippingAddress?.city && touched.shippingAddress?.city ? (
              <ErrorMessage>{errors.shippingAddress.city}</ErrorMessage>
            ) : null}
          </FormLabel>
        </FieldControl>
        <FieldControl>
          <FormLabel title="Country" id="country" required>
            <S.FormikField
              id="country"
              component={SelectField}
              name="shippingAddress.country"
              placeholder="Choose your country"
              type="text"
              value={values?.shippingAddress?.country}
              options={countries}
              $hasError={errors.shippingAddress?.country && touched.shippingAddress?.country}
              onChange={handleCountryChange}
              onBlur={handleCountryChange}
              disabled={isSubmitting}
            />
            {errors.shippingAddress?.country && touched.shippingAddress?.country ? (
              <ErrorMessage>{errors.shippingAddress.country}</ErrorMessage>
            ) : null}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.FieldGroup gap>
        <FieldControl>
          <FormLabel title="State" htmlFor="state" required>
            <S.FormikField
              id="state"
              component={SelectField}
              name="shippingAddress.state"
              placeholder="Choose your state"
              value={values?.shippingAddress?.state}
              options={stateList}
              $hasError={errors.shippingAddress?.state && touched.shippingAddress?.state}
              disabled={isSubmitting}
            />
            {errors.shippingAddress?.state && touched.shippingAddress?.state ? (
              <ErrorMessage>{errors.shippingAddress.state}</ErrorMessage>
            ) : null}
          </FormLabel>
        </FieldControl>
        <FieldControl>
          <FormLabel title="Postal Code" htmlFor="pincode" required>
            <Input
              id="pincode"
              name="shippingAddress.pincode"
              value={values?.shippingAddress?.pincode}
              $hasError={errors.shippingAddress?.pincode && touched.shippingAddress?.pincode}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            {errors.shippingAddress?.pincode && touched.shippingAddress?.pincode ? (
              <ErrorMessage>{errors.shippingAddress.pincode}</ErrorMessage>
            ) : null}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.FieldGroup gap>
        <FieldControl>
          <FormLabel title="Practice email address" htmlFor="practiceEmail">
            <Input
              id="practiceEmail"
              name="practiceEmail"
              value={values?.practiceEmail}
              $hasError={hasError('practiceEmail')}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            {renderError('practiceEmail')}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.FieldGroup>
        <FieldControl>
          <FormLabel title="Phone number" htmlFor="phone" required>
            <InputMask
              id="phone"
              name="practicePhone"
              type="text"
              mask="999-999-9999"
              value={values?.practicePhone}
              $hasError={hasError('practicePhone')}
              onChange={handleChange}
              onBlur={handleBlur}
              alwaysShowMask
              disabled={isSubmitting}
            />
            {renderError('practicePhone')}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.FieldGroup gap>
        <FieldControl>
          <FormLabel title="Website" htmlFor="practiceWebsite" required>
            <Input
              id="practiceWebsite"
              name="practiceWebsite"
              addonBefore="http://"
              $hasError={hasError('practiceWebsite')}
              value={values?.practiceWebsite}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            {renderError('practiceWebsite')}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.FieldGroup>
        <S.UploadWrapper $hasError={hasError('practiceLogo')}>
          <FormLabel title="Practice Logo" htmlFor="practiceWebsite" required>
            <ImageUploader
              imagePlaceholder={values?.practiceLogo}
              label="Upload logo"
              callback={(imageUrl) => {
                setFieldValue('practiceLogo', imageUrl);
              }}
            />
          </FormLabel>
        </S.UploadWrapper>
      </S.FieldGroup>

      <S.FieldGroup gap>
        <FieldControl>
          <FormLabel title="Full name" htmlFor="fullName" required>
            <Input
              id="name"
              name="firstDentist.name"
              value={values?.firstDentist?.name}
              $hasError={hasError('firstDentist.name')}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={40}
              addonBefore="Dr."
              disabled={isSubmitting}
            />
            {renderError('firstDentist.name')}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.FieldGroup gap>
        <FieldControl>
          <FormLabel
            title={<LabelTitle text="Title" description="(ie: DDS, DMD)" />}
            htmlFor="Title"
            required
          >
            <Input
              id="title"
              name="firstDentist.title"
              maxLength={40}
              value={values?.firstDentist?.title}
              $hasError={hasError('firstDentist.title')}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            {renderError('firstDentist.title')}
          </FormLabel>
        </FieldControl>
      </S.FieldGroup>

      <S.UploadWrapper $hasError={hasError('firstDentist.image')}>
        <ImageUploader
          imagePlaceholder={values?.firstDentist?.image}
          label="Upload photo"
          callback={(imageUrl) => setFieldValue('firstDentist.image', imageUrl)}
        />
      </S.UploadWrapper>

      <S.WarnMessage>
        <S.InfoCircleFilled />
        <p>
          You can only add up to 3 additional partners. If you want to add more or need any help,
          contact support at <strong>(904) 695-9933</strong>{' '}
        </p>
      </S.WarnMessage>

      <S.SwitchContainer>
        <Switch onChange={handleChangeAddNewDentists} checked={addNewDentists} />
        <p>I want to add additional dentist partners</p>
      </S.SwitchContainer>

      {addNewDentists
        ? values.frontCover.map((dentist, frontCoverIndex) => (
          <S.DentistCollapse key={dentist.id}>
            <Collapse ghost expandIconPosition="end" defaultActiveKey={[1]}>
              <Panel header={dentist.title || 'Dentist'} key={dentist.id}>
                <S.UploadWrapper $hasError={hasError(`frontCover[${frontCoverIndex}].image`)}>
                  <ImageUploader
                    imagePlaceholder={values?.frontCover[frontCoverIndex].image}
                    label="Upload photo"
                    callback={(imageUrl) =>
                      setFieldValue(`frontCover[${frontCoverIndex}].image`, imageUrl)
                    }
                  />
                </S.UploadWrapper>

                <S.FieldGroup gap>
                  <FieldControl>
                    <FormLabel title="Full name" htmlFor="fullName" required>
                      <Input
                        id="name"
                        name={`frontCover[${frontCoverIndex}].name`}
                        maxLength={40}
                        value={values.frontCover[frontCoverIndex].name}
                        $hasError={hasError(`frontCover[${frontCoverIndex}].name`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                      />
                      {renderError(`frontCover[${frontCoverIndex}].name`)}
                    </FormLabel>
                  </FieldControl>

                  <FieldControl>
                    <FormLabel
                      title={<LabelTitle text="Title" description="(ie: DDS, DMD)" />}
                      htmlFor="Title"
                      required
                    >
                      <Input
                        id="title"
                        name={`frontCover[${frontCoverIndex}].title`}
                        value={values.frontCover[frontCoverIndex].title}
                        maxLength={40}
                        $hasError={hasError(`frontCover[${frontCoverIndex}].title`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                      />
                      {renderError(`frontCover[${frontCoverIndex}].title`)}
                    </FormLabel>
                  </FieldControl>
                </S.FieldGroup>
                <DeleteButton onClick={() => removePartner(frontCoverIndex)}>Remove</DeleteButton>
              </Panel>
            </Collapse>
          </S.DentistCollapse>
        ))
        : null}

      {addNewDentists ? (
        <S.PrimaryBlackButton
          disabled={values?.frontCover?.length === 3}
          icon={<PlusOutlined />}
          onClick={handleAddNewDentist}
        >
          Add more
        </S.PrimaryBlackButton>
      ) : null}

      {!hideButton && (
        <S.NavigationContainer>
          <PrimaryButton htmlType="submit">
            Next <ArrowRightOutlined />
          </PrimaryButton>
        </S.NavigationContainer>
      )}
    </form>
  );
};

export default DentistGuideDetailsForm;
