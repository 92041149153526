import { EditOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { Modal } from 'modules/common/components';
import { useState } from 'react';
import { get } from 'lodash-es';
import { useQuery } from 'react-query';
import { getRouteEmailHistory } from 'modules/dashboard/routes/navigation';
import { getStatesByCountryName } from 'modules/api';
import EditLeads from '../EditLeads';
import DeleteLeads from '../DeleteLeads';
import S from './styles';

const LeadAction = ({ hide, data, onSave, onDelete, segmentsData }) => {
  const [modalVisible, setmodalVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editdata, setEditData] = useState({});
  const [dis, setDisabled] = useState(false);
  const countries = ['USA', 'Canada'];
  const Canada = 'Canada';
  const USA = 'USA';
  const { data: states } = useQuery(['getStatesByCountryName', { Canada, USA }], () =>
    getStatesByCountryName(Canada, USA),
  );
  const stateList = get(states, 'data.data', {});
  const canada = [];
  const usa = [];
  Object.keys(stateList).forEach((x) => {
    if (stateList[x].country === 'Canada') {
      canada.push(stateList[x].state);
    } else {
      usa.push(stateList[x].state);
    }
  });
  const statesObject = {
    Canada: canada,
    USA: usa,
  };
  const onEditClick = () => {
    setmodalVisible(true);
    hide();
  };

  const onConfirm = () => {
    setmodalVisible(false);
    hide();
    onSave(editdata);
  };

  const onDeleteAction = () => {
    setDeleteModal(false);
    onDelete(data);
  };

  const onDeleteClick = () => {
    setDeleteModal(true);
    hide();
  };

  const buttonValidation = () => {
    if (
      editdata &&
      editdata.firstName &&
      editdata.lastName &&
      editdata.email &&
      editdata.segments &&
      editdata.segments.length &&
      !editdata.allSegments &&
      !editdata.segments.some((segment) =>
        typeof segment === 'number'
          ? segmentsData.find((s) => s.id === segment)?.name === 'RM Mailing List'
          : segment.name === 'RM Mailing List',
      )
    ) {
      setIsError(false);
      setDisabled(false);
    } else if (
      editdata &&
      editdata.firstName &&
      editdata.lastName &&
      editdata.email &&
      (editdata?.allSegments ||
        (!editdata?.allSegments &&
          editdata.segments.some((segment) =>
            typeof segment === 'number'
              ? segmentsData.find((s) => s.id === segment)?.name === 'RM Mailing List'
              : segment.name === 'RM Mailing List',
          )))
    ) {
      setIsError(false);
      setDisabled(false);
    } else {
      setDisabled(true);
      setIsError(true);
    }
  };

  return (
    <>
      <S.UpdateLeads>
        <S.EditBtn onClick={() => onEditClick()}>
          <S.text>
            <EditOutlined /> Edit Lead
          </S.text>
        </S.EditBtn>
        <br />
        <S.HistoryBtn
          to={() =>
            getRouteEmailHistory(
              data?.id,
              data?.firstName,
              data?.lastName,
              data?.address,
              data?.email,
              data?.phone,
              data?.segments,
            )
          }
        >
          <S.text>
            <MailOutlined /> Email History
          </S.text>
        </S.HistoryBtn>
        <br />
        <S.EditBtn onClick={() => onDeleteClick()}>
          <S.text>
            <DeleteOutlined /> Delete Lead
          </S.text>
        </S.EditBtn>
      </S.UpdateLeads>

      <S.ModalWrapper>
        <Modal
          title="Edit Lead"
          isOpen={modalVisible}
          onConfirm={() => onConfirm()}
          okButtonProps={{ disabled: dis }}
          onCancel={() => setmodalVisible(false)}
          okText="Update Lead"
        >
          <EditLeads
            isError={isError}
            data={data}
            getData={(d) => setEditData(d)}
            disabled={() => buttonValidation()}
            segmentsData={segmentsData}
            states={statesObject}
            countries={countries}
          />
        </Modal>
      </S.ModalWrapper>

      <S.ModalWrapper>
        <Modal
          title="Delete Lead(s)"
          isOpen={deleteModal}
          onConfirm={() => onDeleteAction()}
          onCancel={() => setDeleteModal(false)}
          okText="Delete Lead(s)"
          cancelText="Cancel"
        >
          <DeleteLeads />
        </Modal>
      </S.ModalWrapper>
    </>
  );
};

export default LeadAction;
