const EyeOpenIcon = ({ fill = '#d7d7d7', width='20', height='20', ...props }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Iconer">
      <path id="Vector" d="M2.68327 10.1974C2.11665 9.46131 1.83334 9.09324 1.83334 8.00033C1.83334 6.90741 2.11665 6.53934 2.68327 5.80321C3.81465 4.33336 5.71209 2.66699 8.50001 2.66699C11.2879 2.66699 13.1854 4.33336 14.3167 5.80321C14.8834 6.53934 15.1667 6.90741 15.1667 8.00033C15.1667 9.09324 14.8834 9.46131 14.3167 10.1974C13.1854 11.6673 11.2879 13.3337 8.50001 13.3337C5.71209 13.3337 3.81465 11.6673 2.68327 10.1974Z" stroke="#252525" strokeWidth="1.5"/>
      <path id="Vector_2" d="M10.5 8C10.5 9.10457 9.60457 10 8.5 10C7.39543 10 6.5 9.10457 6.5 8C6.5 6.89543 7.39543 6 8.5 6C9.60457 6 10.5 6.89543 10.5 8Z" stroke="#252525" strokeWidth="1.5"/>
    </g>
  </svg>
);

export default EyeOpenIcon;
