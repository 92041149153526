import { generatePath } from 'react-router-dom';
import {
  ROUTE_ORDER_BOOK,
  ROUTE_EDIT_DRAFT,
  ROUTE_EDIT_DRAFT_COVER,
  ROUTE_EDIT_DRAFT_MANAGE_SECTIONS,
  ROUTE_EDIT_DRAFT_CONTENT,
  ROUTE_EDIT_DRAFT_CONTENT_SECTION,
} from './constants';

export const getRouteOrderBook = (orderId) => generatePath(ROUTE_ORDER_BOOK, { orderId });

export const getRouteEditDraft = (draftId) => generatePath(ROUTE_EDIT_DRAFT, { draftId });

export const getRouteEditDraftCover = (draftId) =>
  generatePath(ROUTE_EDIT_DRAFT_COVER, { draftId });

export const getRouteEditDraftManageSections = (draftId) =>
  generatePath(ROUTE_EDIT_DRAFT_MANAGE_SECTIONS, { draftId });

export const getRouteEditDraftContent = (draftId, sectionId) => {
  if (sectionId) {
    return generatePath(ROUTE_EDIT_DRAFT_CONTENT_SECTION, { draftId, sectionId });
  }
  return generatePath(ROUTE_EDIT_DRAFT_CONTENT, { draftId });
};
