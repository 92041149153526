import styled from 'styled-components';

import * as color from 'modules/common/theme/color';

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  border: 1px solid ${color.GrayBorder};
  border-radius: 2px;
  background-color: ${color.White};
  margin-top: 20px;
`;

const InnerItem = styled.div`
  border-bottom: 1px solid ${color.GrayBorder};
  border-top: 1px solid ${color.GrayBorder};
  padding: 20px;
  flex: 1;
  border-right: 1px solid ${color.GrayBorder};
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: ${color.Black};
  margin: 0px;
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${color.Black};
  margin: 0px;
`;

const FlexWrap = styled.div`
  display: flex;
`;

const PlanTerm = styled(Description)`
  text-transform: capitalize;
`;

export default {
  MainContain,
  InnerItem,
  Title,
  Description,
  FlexWrap,
  PlanTerm,
};
