import { all, call, put, takeEvery } from 'redux-saga/effects';
import { map } from 'lodash-es';

import { fetchStatesByCountryName, getError } from 'modules/api';

import { GET_STATES_BY_COUNTRIES } from 'modules/publish/store/constants';

import { combineStatesPayload } from 'modules/publish/store/sagas/utils';

export function* onGetStatesByCountries({ payload: countries }) {
  const meta = {
    countries,
  };

  try {
    yield put({
      type: `${GET_STATES_BY_COUNTRIES}_REQUESTED`,
      meta,
    });

    const requests = map(countries, (country) => call(fetchStatesByCountryName, { country }));
    const response = yield all(requests);

    const payload = combineStatesPayload(response);

    yield put({
      type: `${GET_STATES_BY_COUNTRIES}_SUCCEEDED`,
      error: false,
      payload,
      meta,
    });

    return payload;
  } catch (error) {
    const payload = getError(error);

    yield put({
      type: `${GET_STATES_BY_COUNTRIES}_FAILED`,
      error: true,
      payload,
      meta,
    });

    return payload;
  }
}

export function* watchGetStatesByCountries() {
  yield takeEvery(GET_STATES_BY_COUNTRIES, onGetStatesByCountries);
}
