import moment from 'moment-timezone';

export const getDateOnly = (date) => {
  return moment(date).utc().format('DD');
};

export const getMonthOnly = (date) => {
  return moment(date).utc().format('MMM');
};

export const getCoachFullName = (coach) => {
  return `${coach?.firstName} ${coach?.lastName}`;
};

export const getDescriptionDetails = (start, end) => {
  const startTime = moment.tz(start, 'UTC');
  const endTime = moment.tz(end, 'UTC');
  const localTimezone = moment.tz.guess();
  const localStartTime = startTime.tz(localTimezone);
  const localEndTime = endTime.tz(localTimezone);
  return `${localStartTime.format('dddd, MMM D')} · ${localStartTime.format('h:mm')}  – ${localEndTime.format('h:mm')} ${localStartTime.format('A')}`;
};

export const getTimeDifference = (start, end) => {
  const startTime = moment.tz(start, 'UTC');
  const endTime = moment.tz(end, 'UTC');
  return endTime.diff(startTime, 'minutes');
};

export const getZoomJoinBtn = (start, end) => {
  const startTime = moment.tz(start, 'UTC');
  const endTime = moment.tz(end, 'UTC');
  const currentDateUTC = moment().utc();
  const easternStartTime = startTime.tz('America/New_York');
  const easternEndTime = endTime.tz('America/New_York');
  const currentDateEastern = currentDateUTC.tz('America/New_York');
  if (currentDateEastern.isAfter(easternStartTime) && currentDateEastern.isBefore(easternEndTime)) {
    return false;
  }
  return true;
};

export const getRemainingDays = (date, end) => {
  const targetDate = moment(date);
  const currentDate = moment();
  const remDays = targetDate.diff(currentDate, 'days');

  const endTime = moment.tz(end, 'UTC');
  const currentDateUTC = moment().utc();
  const easternEndTime = endTime.tz('America/New_York');
  const currentDateEastern = currentDateUTC.tz('America/New_York');
  if (currentDateEastern.isAfter(easternEndTime)) {
    return null;
  }

  if(remDays === 0){
    return 'Today';
  }
  if(remDays === 1){
    return 'Starts tomorrow';
  }
  return `Starts in ${remDays} days`;
};


export const getFullAddress = (address, countries, states) => {
  if (!address) return '';

  const country = countries.find(item => item.value === address.country);
  const stateList = states[country?.title] || [];
  const finalState = stateList.find(item => item.value === address.state);

  const fullAddress = [
    address.addressLine1,
    address.addressLine2,
    address.city,
    finalState?.title || address.state,
    address.pincode,
    country?.title || address.country
  ].filter(Boolean).join(', ');

  return fullAddress;
};