import { Checkbox as BaseCheckbox, Progress, Button as BaseButton } from 'antd';
import styled from 'styled-components';

import * as color from 'modules/common/theme/color';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px;
`;

const ProgressBar = styled(Progress)`
  width: 250px;
  margin: 0 0 10px;
`;

const Checkbox = styled(BaseCheckbox)``;

const Content = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ContentParagraph = styled.p`
  margin-bottom: 0;
  font-size: 16px;
`;

const IconWrapper = styled.div`
  text-align: center;
  font-size: 40px;
  flex-basis: 75px;

  svg {
    fill: ${color.BookCatalogHeaderBg};
  }
`;

const CriteriaListWrapper = styled.div`
  margin: 15px 0;
`;

// TODO: To be extracted to its own button component
const Button = styled(BaseButton)`
  background-color: ${color.ViewBlueButton};
  padding: 0 20px;
  color: ${color.White};
  height: 44px;
  line-height: 44px;
  text-transform: uppercase;
  border-radius: 5px;
  // Overwrite the antd style
  padding: 0px 25px !important;
  font-weight: normal !important;
  opacity: 1 !important;
  width: 150px;
  align-self: flex-end;
  margin-top: 15px;

  & > span {
    line-height: 44px;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${color.White};
    background-color: ${color.ViewBlueActive};
    border: 1px solid ${color.grey100};
  }
`;

export default {
  Checkbox,
  ContentParagraph,
  Content,
  CriteriaListWrapper,
  Footer,
  IconWrapper,
  ProgressBar,
  Title,
  Button,
};
