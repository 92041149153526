import { useEffect } from 'react';
import { compose } from 'redux';

import {
  getProfile,
  updateProfile,
  uploadAvatar,
  avatarStateSelector,
  profileStateSelector,
} from 'modules/dashboard/store';
import { Empty } from 'modules/common/components';
import withStore from 'store/StoreContainer';
import { isInitial } from 'store/status';

const withProfile =
  (Component = Empty) =>
    (props) => {
      const { profile, getProfile: getProfileOnce } = props;

      useEffect(() => {
        if (isInitial(profile.status)) {
          getProfileOnce();
        }
      }, [profile, getProfileOnce]);

      return <Component {...props} />;
    };

const mapDispatchToProps = {
  getProfile,
  uploadAvatar,
  updateProfile,
};

const mapStateToProps = (state) => ({
  avatar: avatarStateSelector(state),
  profile: profileStateSelector(state),
});

export default compose(withStore(mapStateToProps, mapDispatchToProps), withProfile);
