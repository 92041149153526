import { useEffect } from 'react';
import { compose } from 'redux';
import withStore from 'store/StoreContainer';

import { Empty } from 'modules/v2/common/components';
import { countriesSelector, getCountries, statesSelector } from 'modules/v2/store';

const mapDispatchToProps = { getCountries };

const withCountries = (Component = Empty) => (props) => {
  useEffect(() => {
    props.getCountries();
  }, []);

  return <Component {...props} />;
};

const mapStateToProps = (state) => ({
  countries: countriesSelector(state),
  states: statesSelector(state),
});

export default compose(withStore(mapStateToProps, mapDispatchToProps), withCountries);
