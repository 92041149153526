import { generatePath } from 'react-router-dom';
import { replace } from 'connected-react-router';

import {
  ROUTE_ONBOARD_BOOK_DETAILS,
  ROUTE_ONBOARD_SCHEDULE_COACHING,
  ROUTE_ONBOARD_TRAINING_WEBINAR,
  ROUTE_ONBOARD_YOUR_BOOK,
  ROUTE_ONBOARD_ERROR,
  ROUTE_ONBOARD_ORDER_CONFIRMATION,
  ROUTE_ONBOARD,
  ROUTE_ONBOARD_BOOK_SUMMARY,
  ROUTE_ONBOARD_CONFIRM_TRIAL,
  ROUTE_ONBOARD_DENTIST_GUIDE_DETAILS,
  ROUTE_ONBOARD_GUIDE_CONFIRMATION,
} from './constants';

export const getConfirmTrial = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_CONFIRM_TRIAL, { offerCode }) + (queryString ? `${queryString}` : '');

export const getRouteScheduleCoaching = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_SCHEDULE_COACHING, { offerCode }) +
  (queryString ? `${queryString}` : '');

export const getRouteTrainingWebinar = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_TRAINING_WEBINAR, { offerCode }) +
  (queryString ? `${queryString}` : '');

export const getRouteBookDetails = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_BOOK_DETAILS, { offerCode }) + (queryString ? `${queryString}` : '');

export const getRouteGuideDetails = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_DENTIST_GUIDE_DETAILS, { offerCode }) +
  (queryString ? `${queryString}` : '');

export const getRouteNextStep = (offerCode, nextStep, queryString) =>
  generatePath(`${ROUTE_ONBOARD}/${nextStep}/${offerCode}`) + (queryString ? `${queryString}` : '');

export const getRouteOrderConfirmation = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_ORDER_CONFIRMATION, { offerCode }) +
  (queryString ? `${queryString}` : '');

export const getRouteGuideConfirmation = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_GUIDE_CONFIRMATION, { offerCode }) +
  (queryString ? `${queryString}` : '');

export const getRouteError = () => generatePath(ROUTE_ONBOARD_ERROR);

export const getRouteYourBook = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_YOUR_BOOK, { offerCode }) + (queryString ? `${queryString}` : '');

export const getRouteBookSummary = (offerCode, queryString) =>
  generatePath(ROUTE_ONBOARD_BOOK_SUMMARY, { offerCode }) + (queryString ? `${queryString}` : '');

export const goToScheduleCoaching = (offerCode, queryString) =>
  replace(getRouteScheduleCoaching(offerCode)) + (queryString ? `${queryString}` : '');

export const goToTrainingWebinar = (offerCode, queryString) =>
  replace(getRouteTrainingWebinar(offerCode)) + (queryString ? `${queryString}` : '');

export const goToBookDetails = (offerCode, queryString) =>
  replace(getRouteBookDetails(offerCode)) + (queryString ? `${queryString}` : '');

export const goToError = () => replace(getRouteError());
