import { flatten, map, groupBy, mapValues } from 'lodash-es';
import { getPayload } from 'modules/api';

export const combineStatesPayload = (payload) => {
  const countryMapper = ({ country }) => country;
  const stateMapper = ({ state }) => state;

  const states = map(payload, getPayload);
  const flattened = flatten(states);

  const grouped = groupBy(flattened, countryMapper);

  return mapValues(grouped, (value) => map(value, stateMapper));
};
