import { useFormikContext } from 'formik';
import { NavBar as BaseNavBar } from 'modules/nav/components';

const OnDemandEmailsNavBar = ({ onCancel }) => {
  const { submitForm, dirty, isValid } = useFormikContext();
  const disabledSubmit = !isValid || !dirty;

  return (
    <BaseNavBar
      fixed
      sideContent={
        <>
          <BaseNavBar.Button theme="secondary" onClick={onCancel}>
            Cancel
          </BaseNavBar.Button>
          <BaseNavBar.Button disabled={disabledSubmit} onClick={submitForm}>
            Save
          </BaseNavBar.Button>
        </>
      }
    />
  );
};

export default OnDemandEmailsNavBar;
