import { getItem, setItem } from 'modules/dashboard/utils/legacy';

const upgradePlanEventAlreadyTriggered = (event: string): boolean => {
  return Boolean(getItem(`upgrade_plan_event:${event}`));
};

const upgradePlanSetEventTriggered = (event: string): void => {
  setItem(`upgrade_plan_event:${event}`, true);
};

export { upgradePlanEventAlreadyTriggered, upgradePlanSetEventTriggered };
