import {  useLayoutEffect, useState, useRef } from 'react';

import AvatarEditor from 'react-avatar-editor';
import { clamp } from 'lodash-es';

import { ResetIcon, ZoomInIcon, ZoomOutIcon } from 'modules/v2/common/components/SvgIcon';

import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { calculateMaxScale } from './utils';
import S from './styles';

const IMAGE_ROTATION_DIRECTION = -90;
const IMAGE_ZOOM_SCALE = 0.1;
const IMAGE_ZOOM_MIN = 1;
const IMAGE_ZOOM_MAX = 5;

const AvatarCropper = ({ image, onChange, handleCancel }) => {
  const ref = useRef(null);

  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [maxScale, setMaxScale] = useState(IMAGE_ZOOM_MAX);

  useLayoutEffect(() => {
    calculateMaxScale(image).then(setMaxScale).catch(window.alert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);


  const handleChange = () => {
    const canvas = ref.current.getImage();
    onChange(canvas);
  };

  const onRotate = () => {
    setRotation(rotation + IMAGE_ROTATION_DIRECTION);
  };

  const handleScale = (event) => {
    setScale(parseFloat(event.target.value));
  };

  const handleZoomOut = () => {
    const newScale = clamp(scale - IMAGE_ZOOM_SCALE, IMAGE_ZOOM_MIN, maxScale);
    setScale(parseFloat(newScale));
  };

  const handleZoomIn = () => {
    const newScale = clamp(scale + IMAGE_ZOOM_SCALE, IMAGE_ZOOM_MIN, maxScale);
    setScale(parseFloat(newScale));
  };

  return (
    <S.AvatarCropperWrapper>
      <div className="innerEditorWrap">
        <div className="innerEditor">
          <section className="imgs">
            <div className="content">
              <div className="cropWrap">
                <p>Click &amp; drag to center image</p>
                <div className="userCrop flex items-center justify-center">
                  <AvatarEditor
                    ref={ref}
                    image={image}
                    scale={scale}
                    rotate={rotation}
                    border={0}
                    width={500}
                    height={500}
                    color={[152, 15, 32, 0.45]} // RGBA
                  />
                </div>
                <div className="imgDetails">
                  <ul>
                    <li>
                      <span onClick={onRotate}>
                        <ResetIcon />
                      </span>
                    </li>

                    <li>
                      <span onClick={handleZoomOut}>
                        <ZoomOutIcon />
                      </span>
                    </li>
                    <li>
                      <input
                        name="scale"
                        type="range"
                        onChange={handleScale}
                        min={IMAGE_ZOOM_MIN}
                        max={maxScale}
                        step={IMAGE_ZOOM_SCALE}
                        value={scale}
                      />
                    </li>
                    <li>
                      <span onClick={handleZoomIn}>
                        <ZoomInIcon />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <div className="flex gap-x-2 justify-end">
            <Button type="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="dark" onClick={handleChange}>
              Save changes
            </Button>
          </div>
        </div>
      </div>
    </S.AvatarCropperWrapper>
  );
};

export default AvatarCropper;
