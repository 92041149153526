import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import { get } from 'lodash-es';
import { updateUpsellSession, getAutoLoginAuthToken } from 'modules/api';
import { getOnboardSession } from 'modules/auth';
import { setSession } from 'modules/onboard/utils';
import {
  useGetPaymentProfiles,
  useFinishPayment,
  useFetchSession,
} from 'modules/landingPages/hooks';
import { getItem, setItem } from 'modules/dashboard/utils/legacy';
import { notification } from 'modules/common/utils';
import { Modal, Loader, PrimaryBlackButton } from 'modules/common/components';
import { useWaitUpsellPayment, useSetCustomerPassword } from './hooks';
import { CongratsModal } from '../ClickFunnels/components';
import UpsellContent from './UpsellContent';
import * as funnels from '../../definitions';
import UpsellModalContent from './components/UpsellModalContent';

import S from './styles';

const Upsell = ({ history }) => {
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [offerCode, setOfferCode] = useState(null);
  const [modalMessage, setModalMessage] = useState(
    'Please wait while we get your payment profile.',
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [approvedPayment, setApprovedPayment] = useState(false);

  const { funnelCode } = useParams();
  const { productHandler, componentId, offerId, component, productPricePointHandle } =
    funnels[funnelCode].upsell;

  const queryString = window.location.search;

  useFetchSession(offerCode, sessionId, {
    enabled: !!sessionId && !!offerCode,
    onSuccess: ({ data }) => setSession(data),
  });

  const { mutate: mutateSetCustomerPassword } = useSetCustomerPassword();

  const { session } = getOnboardSession(offerCode);
  const email = session?.customer?.email;
  const autoLoginToken = session?.autoLoginToken;
  const { mutate: autoLoginMutate } = useMutation(
    ['autoLoginAuthToken'],
    () => getAutoLoginAuthToken(autoLoginToken, email),
    {
      onSuccess: (res) => {
        if (res.token) {
          setItem('token', res.token);
          setItem('refresh_token', res.refresh_token);
        }
        setPaymentLoading(false);
        setIsConfirmationModalOpen(true);
      },
    },
  );

  if (approvedPayment && session) {
    autoLoginMutate(autoLoginToken, email);
    setApprovedPayment(false);
  }

  const handleWaitPaymentSuccess = (data) => {
    const { isApproved, offerCode: onboardOfferCode, sessionId: onboardSessionId } = data || {};

    setSessionId(onboardSessionId);
    setOfferCode(onboardOfferCode);

    if (isApproved) {
      setApprovedPayment(true);
      notification.success({
        title: 'Success',
        description: 'Your payment was successful.',
      });
    }

    const { customer } = JSON.parse(getItem('customer'));
    const password = customer?.password;

    mutateSetCustomerPassword({ sessionId: onboardSessionId, password });
  };

  const { waitPayment } = useWaitUpsellPayment({
    onSuccess: handleWaitPaymentSuccess,
    startWaitingForPaymentResponse: paymentLoading,
  });

  const handleError = (error, actions) => {
    const defaultErrorMessage = 'We could not get your payment profile.';
    const { setSubmitting } = actions;

    notification.error({
      title: 'Error',
      description: typeof error === 'string' ? error : defaultErrorMessage,
    });

    // eslint-disable-next-line no-unused-expressions
    setSubmitting ?? setSubmitting(false);
  };

  const { mutate: getPaymentProfileMutation } = useGetPaymentProfiles({
    onSuccess: ({ data }) => {
      const paymentProfile = get(data, '[0]');

      if (!paymentProfile) {
        notification.error({
          title: 'Error',
          description:
            'There is no payment profile for this user. \n Please start the process again.',
        });

        setTimeout(() => {
          history.push('/offers/one-book-way');
        }, 3000);
      }

      setItem('paymentProfile', paymentProfile);
    },
    onError: () => {
      notification.error({
        title: 'Error',
        description: 'We could not get your payment profile.',
      });
    },
  });

  useEffect(() => {
    const { customer } = JSON.parse(getItem('customer')) || {};

    getPaymentProfileMutation(customer?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentSuccess = ({ data }) => {
    setOrderNumber(data?.signup_payment_id);

    const { customer, id } = data || {};

    setItem('customerAndSubscriptionId', { customer, id });
    setModalMessage('Please wait one more step while we process your order.');

    waitPayment();
  };

  const { handleProfilePaymentFinish } = useFinishPayment({
    onSuccess: handlePaymentSuccess,
    onError: handleError,
    productHandler,
    productPricePointHandle,
    componentId,
    offerId,
  });

  const handleBuyUpsell = () => {
    setPaymentLoading(true);
    handleProfilePaymentFinish();

    return false;
  };

  const handleCancelUpsell = () => {
    history.push(`/landing-pages/${funnelCode}/congrats`);
  };

  const renderUpsellComponent = () => {
    return React.createElement(UpsellContent, {
      onCancel: handleCancelUpsell,
    });
  };

  const { mutate } = useMutation(updateUpsellSession);

  const handleAcceptOffer = () => {
    const urlParams = new URLSearchParams(queryString.slice(1));
    const parameters = {};

    urlParams.forEach((value, parameter) => {
      const camelCaseKey = parameter.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
      parameters[camelCaseKey] = value;
    });

    const params = {
      marketingParameters: {
        ...parameters,
      },
      sessionId,
    };

    mutate(params);

    history.push(`/onboard/schedule-coaching/${offerCode + queryString}`);
  };

  const congratsFooter = (
    <S.ModalFooter>
      <PrimaryBlackButton size="large" onClick={handleAcceptOffer}>
        START SETTING UP MY BOOK NOW
      </PrimaryBlackButton>
    </S.ModalFooter>
  );

  return [
    <Modal key="paymentModal" isOpen={paymentLoading} footer={null}>
      <Loader title="Loading" description={modalMessage} height="500px" />
    </Modal>,

    <CongratsModal
      key="congratsModal"
      isOpen={isConfirmationModalOpen}
      content={<UpsellModalContent orderNumber={orderNumber} />}
      footer={congratsFooter}
    />,
    renderUpsellComponent(),
  ];
};

export default Upsell;
