import { useState } from 'react';
import { useQuery } from 'react-query';
import { get } from 'lodash';
import { ReadOutlined } from '@ant-design/icons';
import { getRouteBookCatalog } from 'modules/dashboard/routes/navigation';
import { getRouteDraftGenerateByBookId } from 'modules/draft/routes/navigation';
import { fetchAllBooks } from 'modules/api';

import Card from 'modules/dashboard/components/Card';
import { Skeleton } from 'antd';
import { formatBookList } from './utils';
import S from './styles';

const BooksContainer = (props) => {
  const numberOfPlaceholder = [1, 2];
  const [bookList, setBookList] = useState([]);

  const { isLoading: isListLoading } = useQuery(
    'fetchAllBooks',
    () => fetchAllBooks({ limit: 2, isAllowed: true }),
    {
      onSuccess: (data) => {
        const bookListData = get(data, 'data.data.books', []);
        const formattedBookList = formatBookList(bookListData);

        setBookList(formattedBookList);
      },
    },
  );

  return (
    <S.Container>
      <S.Title>Books</S.Title>
      <S.FlexWrap>
        {isListLoading &&
          numberOfPlaceholder.map((key) => {
            return (
              <S.BooksContainer key={key}>
                <S.Book>
                  <S.SkeletonImage active />
                </S.Book>
                <S.Description>
                  <Skeleton.Input active block size="small" />
                </S.Description>
              </S.BooksContainer>
            );
          })}
        {!isListLoading &&
          bookList.map((book) => {
            return (
              <S.BooksContainer key={book.id}>
                <S.Book href={getRouteDraftGenerateByBookId(book.id)}>
                  <img src={`${book.imageUrl}?random=33`} alt={book.title} />
                </S.Book>
                <S.Description>{book.title}</S.Description>
              </S.BooksContainer>
            );
          })}
        <S.Card>
          <Card
            icon={<ReadOutlined />}
            title="Books"
            description="Create, generate and order your personalize book(s). Personalize again and/or re-order book."
            buttonIsActive
            buttonText="View More Books"
            link={getRouteBookCatalog()}
            width="220px"
            height="200px"
          />
        </S.Card>
      </S.FlexWrap>
    </S.Container>
  );
};

export default BooksContainer;
