const defaultProps = {
  width: '17',
  height: '16',
  fill: 'none',
};

function EditIcon(props) {

  const { width, height, fill } = { ...defaultProps, ...props };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 16" fill={fill}>
      <path d="M10.074 2.71902L10.6919 2.1011C11.7157 1.0773 13.3757 1.0773 14.3995 2.1011C15.4233 3.12491 15.4233 4.78482 14.3995 5.80862L13.7815 6.42654M10.074 2.71902C10.074 2.71902 10.1513 4.0321 11.3099 5.1907C12.4685 6.3493 13.7815 6.42654 13.7815 6.42654M10.074 2.71902L4.3932 8.39985C4.00843 8.78463 3.81604 8.97701 3.65058 9.18914C3.45541 9.43937 3.28807 9.71012 3.15155 9.9966C3.03581 10.2395 2.94977 10.4976 2.77769 11.0138L2.04852 13.2013M13.7815 6.42654L8.10072 12.1074C7.71594 12.4921 7.52356 12.6845 7.31143 12.85C7.0612 13.0452 6.79045 13.2125 6.50397 13.349C6.26111 13.4648 6.003 13.5508 5.48677 13.7229L3.29926 14.452M3.29926 14.452L2.76455 14.6303C2.5105 14.715 2.23042 14.6488 2.04107 14.4595C1.85172 14.2701 1.7856 13.9901 1.87028 13.736L2.04852 13.2013M3.29926 14.452L2.04852 13.2013" stroke="#252525" stroke-width="1.5" />
    </svg>
  );
}

export default EditIcon;
