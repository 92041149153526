import S from './styles';

const NewPlanTable = ({ planName, planTerm, amountPaid, currentPeriodStart, renewalDate }) => (
  <S.MainContain>
    <S.InnerItem>
      <S.Title>New Plan:</S.Title>
      <S.PlanTerm>{planName}</S.PlanTerm>
    </S.InnerItem>
    <S.FlexWrap>
      <S.InnerItem>
        <S.Title>Billing Term:</S.Title>
        <S.PlanTerm>{planTerm}</S.PlanTerm>
      </S.InnerItem>
      <S.InnerItem>
        <S.Title>Changes Effective:</S.Title>
        <S.Description> {currentPeriodStart}</S.Description>
      </S.InnerItem>
    </S.FlexWrap>
    <S.FlexWrap>
      <S.InnerItem>
        <S.Title>Total Amount Paid:</S.Title>
        <S.Description>{amountPaid}</S.Description>
      </S.InnerItem>
      <S.InnerItem>
        <S.Title>Your plan will renew on:</S.Title>
        <S.Description>{renewalDate}</S.Description>
      </S.InnerItem>
    </S.FlexWrap>
  </S.MainContain>
);

export default NewPlanTable;
