export const TickRound = ({ width = 20, height = 20, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconer">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3334 9.99984C18.3334 14.6022 14.6024 18.3332 10 18.3332C5.39765 18.3332 1.66669 14.6022 1.66669 9.99984C1.66669 5.39746 5.39765 1.6665 10 1.6665C14.6024 1.6665 18.3334 5.39746 18.3334 9.99984ZM13.3586 7.47456C13.6027 7.71864 13.6027 8.11437 13.3586 8.35845L9.19196 12.5251C8.94788 12.7692 8.55216 12.7692 8.30808 12.5251L6.64141 10.8584C6.39733 10.6144 6.39733 10.2186 6.64141 9.97456C6.88549 9.73048 7.28122 9.73048 7.5253 9.97456L8.75002 11.1993L10.6124 9.33692L12.4747 7.47456C12.7188 7.23048 13.1146 7.23048 13.3586 7.47456Z"
        fill="#38AB86"
      />
    </g>
  </svg>
);
