import { useState } from 'react';
import { useQuery } from 'react-query';
import { map } from 'lodash-es';

import { fetchUserCredits } from 'modules/api';
import { formatDate } from 'modules/common/utils';
import { CoverImage } from 'modules/editor/components';
import {
  PrimaryButton,
  SecondaryButton,
  PrimaryBlackButton,
  SecondaryBlackButton,
  ConfirmModal,
  Modal,
} from 'modules/common/components';
import { getRouteDraftGenerateByOrderId } from 'modules/draft/routes/navigation';
import { PaymentSteps } from 'modules/dashboard/pages/PaymentSteps';
import { useGetCustomerSubscriptionStatus } from 'modules/dashboard/utils';

import S from './styles';

const Item = ({
  draftId,
  address,
  bookName,
  bookTitle,
  coverImage,
  createdAt,
  orderId,
  quantity,
  updatedAt,
  onReorder,
  allowPersonalization,
  allowReorder,
  history,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [showPaymentSteps, setShowPaymentSteps] = useState(false);

  const { data: userBookCredit } = useQuery(['getUserCredits'], fetchUserCredits);

  const customerSubscriptionStatus = useGetCustomerSubscriptionStatus();

  const isTrialCustomer = customerSubscriptionStatus === 'trialing';

  const bookCredit = userBookCredit?.data?.data?.credits || 0;

  const details = [
    { title: 'Order Number', value: orderId },
    { title: 'Order Date', value: formatDate(createdAt) },
    { title: 'Last Updated', value: formatDate(updatedAt) },
    { title: 'Book Source', value: bookName },
    { title: 'Quantity', value: quantity },
    { title: 'Address', value: address },
  ];

  const handleReorder = () => {
    const isCreditLow = bookCredit < 8;
    setIsWarningModalOpen(isCreditLow);

    if (!isCreditLow) {
      onReorder(draftId);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    const path = getRouteDraftGenerateByOrderId(orderId);
    setIsOpen(false);

    history.push(path);
  };

  const handlePersonalizeAgain = () => {
    setIsOpen(true);
  };

  const handleShowstepWarningModal = () => {
    setShowPaymentSteps(true);
    setIsWarningModalOpen(false);
  };

  const confirmText = (
    <>
      <S.ConfirmTitle>Personalize Again</S.ConfirmTitle>
      <S.ConfirmText>
        When your book is personalize again, you will lose any changes you've made up to this point.
      </S.ConfirmText>
      <S.ConfirmText>Would you like to continue?</S.ConfirmText>
    </>
  );

  return (
    <S.Wrapper>
      <S.SideContent>
        <S.ImageWrapper>
          <CoverImage url={coverImage} />
        </S.ImageWrapper>
        <S.Title>{bookName}</S.Title>
      </S.SideContent>
      <S.MainContent>
        <S.BookName>{bookTitle}</S.BookName>
        <S.Details>
          {map(details, ({ title, value }) => (
            <S.Details.Item key={title}>
              <S.Details.Title>{title} :</S.Details.Title>
              <S.Details.Value>{value}</S.Details.Value>
            </S.Details.Item>
          ))}
        </S.Details>
        <S.LinksList>
          {allowPersonalization && (
            <PrimaryButton onClick={handlePersonalizeAgain}>Personalize again</PrimaryButton>
          )}
          {allowReorder && <SecondaryButton onClick={handleReorder}>Re-order Book</SecondaryButton>}
        </S.LinksList>
      </S.MainContent>
      {isOpen && (
        <ConfirmModal
          isOpen
          title={`Book title: ${bookTitle}`}
          text={confirmText}
          confirmLabel="Cancel"
          cancelLabel="Continue"
          width="500px"
          onConfirm={handleCancel}
          onCancel={handleCancel}
          cancelButtonProps={{ onClick: handleConfirm }}
        />
      )}
      <Modal
        isOpen={isWarningModalOpen}
        maskClosable
        title="Warning"
        width="auto"
        footer={[
          <S.ModalFooterContainer key={1}>
            <PrimaryBlackButton
              style={{ width: '80%' }}
              onClick={handleShowstepWarningModal}
              disabled={isTrialCustomer}
            >
              Buy Credits
            </PrimaryBlackButton>
            <SecondaryBlackButton
              style={{ width: '80%' }}
              onClick={() => setIsWarningModalOpen(false)}
            >
              Cancel, I'll buy later
            </SecondaryBlackButton>
          </S.ModalFooterContainer>,
        ]}
      >
        <S.ContentContainer>
          <S.ContentTitle>
            You have <span>{bookCredit} credits</span> left
          </S.ContentTitle>
          <S.ContentDescription>1 Credit = 1 Printed Book</S.ContentDescription>
          <S.ContentDescription>
            {isTrialCustomer
              ? 'Please check with support to learn more about Authorify plans.'
              : 'Buy credits to print more books. Minimum per order is 8 credits.'}
          </S.ContentDescription>
        </S.ContentContainer>
      </Modal>
      {showPaymentSteps && (
        <PaymentSteps
          setShowPaymentSteps={setShowPaymentSteps}
          showPaymentSteps={showPaymentSteps}
        />
      )}
    </S.Wrapper>
  );
};

Item.defaultProps = {
  allowPersonalization: true,
  allowReorder: true,
};

export default Item;
