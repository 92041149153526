//

import S from './styles';
import Item from '../Item';

const Address = ({
  title,
  firstName,
  lastName,
  addressLine1,
  addressLine2,
  city,
  state,
  pincode,
  country,
  linkTitle,
  onClick,
  'data-testid': dataTestId,
}) => {
  const fullName = `${firstName} ${lastName}`;
  const location = `${city}, ${state} ${pincode}`;
  return (
    <>
      <S.ItemWrapper>
        <Item data-testid={dataTestId} title={title} linkTitle={linkTitle} onClick={onClick} />
      </S.ItemWrapper>
      <S.Address>
        <div>
          <strong>{fullName}</strong>
        </div>
        <div>{addressLine1}</div>
        {addressLine2 && <div>{addressLine2}</div>}
        <div>{location}</div>
        <div>{country}</div>
      </S.Address>
    </>
  );
};

Address.defaultProps = {
  linkTitle: undefined,
  onClick: undefined,
  addressLine2: null,
};

export default Address;
