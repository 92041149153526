import { getInstance } from './instance';

const instance = getInstance();

const getGuidesOrders = (page, perPage) => {
  const url = '/guide-orders';
  const params = {
    perPage,
    page,
  };
  return instance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const getGuideOnboardData = () => {
  const url = '/guide-orders/get-onboard-data';
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const getGuideCatalog = () => {
  const url = '/guide-catalog';
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const getGuideDetails = ({ guideId }) => {
  const url = '/guide-catalog/filter';
  const params = {
    guideId,
  };
  return instance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const addLandingPages = ({ guideId, link, type }) => {
  const url = '/customers/add-landing-page-website';

  const payload = {
    guideId,
    link,
    type,
  };

  return instance
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export { getGuidesOrders, addLandingPages, getGuideDetails, getGuideCatalog, getGuideOnboardData };
