import {
  CLEAR_PREFLIGHT_CHECK,
  GET_PREFLIGHT_CHECK,
  CANCEL_PREFLIGHT_CHECK,
} from 'modules/draft/store/constants';

export const clearPreflightCheck = () => ({
  type: CLEAR_PREFLIGHT_CHECK,
});

export const getPreflightCheck = (draftId) => ({
  type: GET_PREFLIGHT_CHECK,
  payload: {
    draftId,
  },
});

export const cancelPreflightCheck = () => ({
  type: CANCEL_PREFLIGHT_CHECK,
});
