import styled from 'styled-components';

const Wrapper = styled.main`
  background: transparent;
  display: flex;
  height: 100%;

  .ck-editor {
    border: none;
  }
`;

export default {
  Wrapper,
};
