import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

import { SUBMITTED, FOR_REVIEW } from '../../constants';

const Container = styled.div`
  background-color: ${color.White};
  th {
    font-weight: bold;
  }
  tr {
    border: 1px solid ${color.LightDullGray};
  }
`;

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.h3`
  font-size: 24px !important;
  line-height: 46px !important;
  color: ${color.Black};
  margin-top: 10px !important;
  font-weight: bold !important;
`;

const MarketingText = styled.p`
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  color: ${color.Black};
  margin-top: -10px !important;
  margin-bottom: -10px !important;
`;

const GridFilter = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 20px;
  position: relative;
  bottom: -14px;

  span.filterLabel {
    color: ${color.Black};
    font-size: 14px;
    margin: 8px 10px 0 0;
  }

  .filterSelect {
    width: 160px;
    .ant-select-selection {
      fonts-size: 14px;
      border-radius: 2px;
      border: 1px solid ${color.Black};

      :hover,
      :focus {
        border: 1px solid ${color.SkyBlue};
      }
    }
  }
`;

const PaginationWrap = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .ant-pagination-item,
  .ant-pagination-item-link,
  .ant-select-selection {
    background: ${color.lightBackground} 0% 0% no-repeat padding-box;
    border-radius: 3px;
    font-family: 'Montserrat Sans', sans-serif;
  }
  .ant-pagination-item-active {
    background: ${color.White} !important;
    border-color: ${color.SkyBlue} !important;
  }
`;

const Name = styled.span`
  color: ${color.SkyBlue};
  font-size: 14px;
  cursor: pointer;
`;

const Status = styled.span`
  color: ${(props) => {
    if (props.status === SUBMITTED) return `${color.SideBarActiveText}`;
    if (props.status === FOR_REVIEW) return `${color.reviewState}`;
    return `${color.approvedState}`;
  }};
  text-transform: capitalize;
`;

const EmptyColumn = styled.div`
  padding: 15px 0px;
  background-color: ${color.White};
`;

const SortArrow = styled.span`
  color: ${color.GreyFormCheckbox};
  cursor: pointer;
`;

export default {
  Container,
  MainContain,
  Title,
  MarketingText,
  GridFilter,
  PaginationWrap,
  Name,
  Status,
  EmptyColumn,
  SortArrow,
};
