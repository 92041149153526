//

import { NavBar } from 'modules/nav/components';

import S from './styles';

const helpTitle = (
  <>
    <S.Item.Icon type="question-circle" />
    Help
  </>
);

const HelpMenu = ({ to }) => <NavBar.Menu title={helpTitle} to={to} />;

export default HelpMenu;
