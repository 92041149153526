import React from 'react';
import { compose } from 'redux';

import {
  withBook,
  withCover,
  withDraft,
  withImageLibrary,
  withPreferences,
} from 'modules/v2/editor/containers';
import { TemplateList } from 'modules/v2/editor/components';

const TemplatesList = ({ templateList, templateId, updateCoverActiveTemplate }) => {
  return (
    <TemplateList
      templates={templateList}
      selectedId={templateId}
      onSelect={updateCoverActiveTemplate}
    />
  );
};

const MemoCover = React.memo(TemplatesList);

const Composed = compose(
  withCover,
  withPreferences,
  withBook,
  withDraft,
  withImageLibrary,
)(MemoCover);

export default Composed;
