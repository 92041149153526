import { generatePath } from 'react-router-dom';

import { ROUTE_DRAFT_VIEW, ROUTE_GENERATE_DRAFT } from './constants';

export const getRouteDraftGenerate = (entity, id) =>
  generatePath(ROUTE_GENERATE_DRAFT, { entity, id });

export const getRouteDraftGenerateByBookId = (bookId) => getRouteDraftGenerate('book', bookId);

export const getRouteDraftGenerateByDraftId = (draftId) => getRouteDraftGenerate('draft', draftId);

export const getRouteDraftGenerateByOrderId = (orderId) => getRouteDraftGenerate('order', orderId);

export const getRouteDraftView = (draftId) => generatePath(ROUTE_DRAFT_VIEW, { draftId });
