import { map } from 'lodash-es';

import { getPayload } from 'modules/api';
import { addPrefixToBook } from '../common/utils';

export const getOrdersPayload = (response) => {
  const payload = getPayload(response);
  const orders = map(payload, addPrefixToBook);

  return orders;
};
