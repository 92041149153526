import withStore from 'store/StoreContainer';

import {
  profileStateSelector,
  headshotSelector,
  preferencesSelector,
  uploadHeadshot,
  updatePreferences,
} from 'modules/dashboard/store';

const mapDispatchToProps = {
  uploadHeadshot,
  updatePreferences,
};

const mapStateToProps = (state) => ({
  profile: profileStateSelector(state),
  headshot: headshotSelector(state),
  preferences: preferencesSelector(state),
});

export default withStore(mapStateToProps, mapDispatchToProps);
