import image from 'assets/images/logo/authorify-logo-black.svg';

const AuthorifyIcon = ({ height, width }) => {
  return <img alt="authorify-icon" height={height} src={image} width={width} />;
};

AuthorifyIcon.defaultProps = {
  width: '64',
  height: '64',
};

export default AuthorifyIcon;
