import { useState } from 'react';
import get from 'lodash/get';
import { ErrorMessage } from 'modules/v2/common/components';
import { TextInput } from 'flowbite-react';
import { defaultTextInputTheme } from 'modules/v2/common/AtomicDesign/atoms/TextInput/theme';
import ImageUploaderModal from '../ImageUploaderModal';

const SingleInput = ({
  step = '',
  formikHandler,
  uploadImageTitle,
  propertyKey = '',
  imageDimensions,
  uploadImageType,
}) => {
  const [brokerLogoModal, setBrokerLogoModal] = useState(false);
  const { values = {}, setFieldValue, errors } = formikHandler || {};
  const targetLocation = step.concat('.', '_formFields');
  const [isEditImage, setIsEditImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const handleChange = (e) => {
    const {
      target: { name = '', value = '' },
    } = e || {};
    const locationToSave = step.concat('.', '_formFields', '.', name);
    setFieldValue(locationToSave, value);
  };

  const { baseReplacers = {}, magazine } = values;

  const uploadedImagePath = `baseReplacers.${propertyKey}`;

  const toggleBrokerLogoModal = () => {
    setBrokerLogoModal(!brokerLogoModal);
  };

  const handleImageUpload = (value, keyName) => {
    const locationToSave = step.concat('.', '_formFields', '.', keyName);
    setFieldValue(locationToSave, value);
    setFieldValue('baseReplacers', {
      ...get(magazine, '_formFields'),
      ...baseReplacers,
      [propertyKey]: value,
    });
    toggleBrokerLogoModal();
  };

  const onRemoveImage = (e) => {
    setFieldValue(uploadedImagePath, '');
    e.stopPropagation();
  };

  const onEditImage = () => {
    setIsEditImage(true);
    setImageUrl(get(values, uploadedImagePath));
  };

  const toggleCancelEdit = () => {
    setIsEditImage(!isEditImage);
  };

  return (
    <>
      <div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col items-start">
            <div className="text-neutral-800 text-sm font-semibold">{uploadImageTitle}</div>
            <div className="text-neutral-500 text-sm font-medium">
              Recommended size: {imageDimensions.width} x {imageDimensions.height}
            </div>
          </div>
          <ImageUploaderModal
            imageDimensions={imageDimensions}
            type={uploadImageType}
            modalTitle="Book Image"
            isOpen={brokerLogoModal === 'photo'}
            onCancel={toggleBrokerLogoModal}
            handleOpenModal={() => setBrokerLogoModal('photo')}
            selectAreaWidth="50%"
            selectAreaJustifyContent="center"
            imageUrl={get(values, uploadedImagePath)}
            onSuccess={(value) => handleImageUpload(value, propertyKey)}
            onRemoveImage={onRemoveImage}
            imageType={propertyKey}
            onEditImage={(e) => {
              onEditImage();
              e.stopPropagation();
            }}
            imageUrlForEdit={imageUrl}
            isEditImage={isEditImage}
            toggleCancelEdit={toggleCancelEdit}
          />
        </div>
      </div>
      <div className="w-[49%]">
        <div className="flex flex-col gap-1">
          <label className="text-sm font-semibold text-neutral-800">Book link</label>
          <TextInput
            theme={defaultTextInputTheme}
            id="bookLink"
            name="bookLink"
            type="text"
            value={get(values, `${targetLocation}.bookLink`)}
            onChange={handleChange}
            placeholder=""
          />
        </div>
        <ErrorMessage>{get(errors, `${targetLocation}.bookLink`) || ''}</ErrorMessage>
      </div>
    </>
  );
};

export default SingleInput;
