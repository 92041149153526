import { getInstance } from './instance';

const instance = getInstance();

const cleanQueryParams = (params) => {
  return Object.keys(params).reduce((acc, key) => {
    if (params[key] && params[key] !== '') {
      acc[key] = params[key];
    }
    return acc;
  }, {});
};

// params: start, end, textSearch, sortBy
export const getCampaignMetrics = (param) => {
  const url = 'email-campaigns/campaigns/email/metrics';

  return instance
    .get(url, { params: cleanQueryParams(param) })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const exportCampaignMetrics = (params) => {
  const url = 'email-campaigns/campaigns/email/metrics-export';

  return instance
    .post(url, params)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
