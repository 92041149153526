import { useState } from 'react';

import { get } from 'lodash-es';
import Modal from 'modules/common/components/Modal';
import { useMutation } from 'react-query';
import { compose } from 'redux';

import { createPaymentProfile } from 'modules/api';
import { notification } from 'modules/common/utils';
import { withProfile } from 'modules/dashboard/containers';

import AddPaymentProfileForm from './components';
import S from './styles';

const AddPaymentProfile = ({ profile, refetchOnSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { email: userEmail } = get(profile, 'data');

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const { mutate, isLoading } = useMutation(['createPaymentProfile'], createPaymentProfile, {
    onSuccess: () => {
      onCancel();
      notification.success({
        description: 'Payment Profile Created Successfully',
      });
    },
    onError: (error) => {
      onCancel();
      notification.error({
        description: error.response.data.errors?.[0] || error?.data?.message || 'Network Error',
      });
    },
  });

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const onSave = (formData) => mutate(formData, { onSuccess: refetchOnSuccess });

  return (
    <>
      {isModalOpen ? (
        <Modal
          width={500}
          footer={null}
          isOpen={isModalOpen}
          onCancel={onCancel}
          title="Add New Credit Card"
        >
          <AddPaymentProfileForm
            onSave={onSave}
            isLoading={isLoading}
            userEmail={userEmail}
            onCancel={onCancel}
          />
        </Modal>
      ) : null}
      <S.AddPaymentMethod onClick={handleClick}>+ Add Payment Method</S.AddPaymentMethod>
    </>
  );
};

export default compose(withProfile)(AddPaymentProfile);
