const CoverIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 6V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V9C20 7.34315 18.6569 6 17 6H4ZM4 6V5"
      stroke="#252525"
      strokeWidth="1.5"
    />
    <circle cx="12" cy="13" r="3" stroke="#252525" strokeWidth="1.5" />
    <path
      d="M18 5.99999V6.74999H18.75V5.99999H18ZM15.7172 2.32611L15.6111 1.58365L15.7172 2.32611ZM4.91959 3.86862L4.81353 3.12616H4.81353L4.91959 3.86862ZM5.07107 6.74999H18V5.24999H5.07107V6.74999ZM18.75 5.99999V4.30601H17.25V5.99999H18.75ZM15.6111 1.58365L4.81353 3.12616L5.02566 4.61108L15.8232 3.06857L15.6111 1.58365ZM4.81353 3.12616C3.91638 3.25432 3.25 4.02267 3.25 4.92892H4.75C4.75 4.76914 4.86749 4.63368 5.02566 4.61108L4.81353 3.12616ZM18.75 4.30601C18.75 2.6325 17.2678 1.34698 15.6111 1.58365L15.8232 3.06857C16.5763 2.961 17.25 3.54532 17.25 4.30601H18.75ZM5.07107 5.24999C4.89375 5.24999 4.75 5.10624 4.75 4.92892H3.25C3.25 5.93467 4.06532 6.74999 5.07107 6.74999V5.24999Z"
      fill="#252525"
    />
    <path d="M10 19H14" stroke="#252525" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default CoverIcon;
