import {
  GET_USERDETAILS,
  SET_USERDETAILS,
  CHANGE_PASSWORD,
  PROFILE_UPLOAD_USER_IMAGE,
} from '../constants';
const initialState = {
  flag: 1,
  data: {},
  imageData: null,
  loading: false,
  loaded: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_CLICK_HANDLER':
      return {
        ...state,
        flag: action.payload,
      };
    case `${GET_USERDETAILS}_REQUEST`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_USERDETAILS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        loaded: true,
      };
    case `${GET_USERDETAILS}_FAILURE`:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case `${SET_USERDETAILS}_REQUEST`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case SET_USERDETAILS:
      return {
        ...state,
        statusCode: action.payload.statusCode,
        data: action.payload,
      };
    case `${SET_USERDETAILS}_FAILURE`:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case `${PROFILE_UPLOAD_USER_IMAGE}_REQUEST`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case PROFILE_UPLOAD_USER_IMAGE:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
        loading: false,
        loaded: true,
      };
    case `${PROFILE_UPLOAD_USER_IMAGE}_FAILURE`:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case `${CHANGE_PASSWORD}_REQUEST`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case `${CHANGE_PASSWORD}_FAILURE`:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    default: {
      return {
        ...state,
      };
    }
  }
};
