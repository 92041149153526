import { useQuery } from 'react-query';
import { HiChevronRight } from 'react-icons/hi2';
import { Col, Row } from 'antd';
import { useContext, useState } from 'react';
import { PageMapping } from '../../index';
import { Box } from '../../../../common/AtomicDesign/atoms';
import RelativeLoading from '../../../../common/AtomicDesign/RelativeLoading';
import { BuyCreditsContext } from '../../context';
import { fetchUserCredits, getBookCreditsList } from '../../../../../api';
import CreditOfferCard from '../CreditOfferCard';
import { handleHttpError } from '../../helper/errorHandling';

type Props = {
  setActivePage: (value: PageMapping) => void;
  isLoading: boolean;
};

const BuyCreditsOfferPage: React.FC<Props> = ({ setActivePage, isLoading }) => {
  const [bestDealId, setBestDealId] = useState<string | null>(null);
  const [fakeLoadingOnId, setFakeLoadingOnId] = useState(false);

  const {
    userCredits,
    setUserCredits,
    setBookCreditsList,
    bookCreditsList,
    setSelectedCreditsOffer,
  } = useContext(BuyCreditsContext);

  const {
    isLoading: isLoadingGetBookCreditsList,
    isRefetching: isRefetchingGetBookCreditsList,
  } = useQuery('getBookCreditsList', () => getBookCreditsList({ type: 'book' }), {
    onSuccess: (data) => {
      let minId = null;
      let minPerAmount = Infinity;

      data.forEach((item) => {
        if (item.perAmount < minPerAmount) {
          minPerAmount = item.perAmount;
          minId = item.id;
        }
      });

      setBestDealId(minId);
      setBookCreditsList(data.reverse() || []);
    },
    onError: (error) => handleHttpError(error),
  });

  const handleOfferSelection = (offer) => () => {
    setSelectedCreditsOffer(offer);
    setFakeLoadingOnId(offer.id);
    setTimeout(() => {
      setFakeLoadingOnId(null);
      setActivePage({
        id: 'checkout',
        title: 'Buy credits',
      });
    }, 300);
  };

  const {
    refetch: refetchUserCredits,
    isRefetching: isRefetchingUserCredits,
    isLoading: isLoadingUserCredits,
  } = useQuery('fetchUserCredits', fetchUserCredits, {
    onSuccess: (res) => {
      setUserCredits(res?.data?.data?.credits || 0);
    },
    onError: (error) => handleHttpError(error),
  });

  const navigateBack = () => {
    setActivePage({
      id: 'buy-credits',
      title: 'Buy credits',
    });
  };

  const handleOfferType = (item) => {
    if (item.id === bestDealId) return 'best-deal';
    if (item.credits === 100) return 'most-popular';
    return 'normal';
  };

  return (
    <>
      <div id="breadcrumbs" className="flex gap-2 items-center font-figtree">
        <span className="cursor-pointer hover:bg-neutral-100" onClick={navigateBack}>
          Book catalog
        </span>
        <HiChevronRight />
        <span className="border-b-[1px] border-neutral-300">Credits</span>
      </div>

      <Box className="mt-6 relative font-figtree">
        <RelativeLoading
          isLoading={
            isLoadingGetBookCreditsList ||
            isRefetchingGetBookCreditsList ||
            isLoading ||
            isRefetchingUserCredits ||
            isLoadingUserCredits
          }
        />
        <div className="flex flex-col border-b-[1px] p-[22px] ps-6 pe-6">
          <span className="text-base font-semibold">You have {userCredits} credits</span>
          <span className="text-sm text-neutral-500">
            Use credits to buy printed books. 1 credit = 1 printed book. The more credits you get,
            the more you save.
          </span>
        </div>

        <Row className="p-6 md:p-2 lg:p-3 xl:p-4 items-center justify-center" gutter={[24, 24]}>
          {bookCreditsList.map((item) => {
            return (
              <Col className="mb-6 md:w-1/3 lg:w-1/4 xl:w-1/5 items-center min-w-48" key={item.id}>
                <CreditOfferCard
                  price={4}
                  id={item.id}
                  perAmount={item.perAmount}
                  totalAmount={item.totalAmount}
                  credits={item.credits}
                  isActive={item.isActive}
                  type={handleOfferType(item)}
                  onBuy={handleOfferSelection(item)}
                  isLoading={fakeLoadingOnId === item.id}
                  theme="primary"
                />
              </Col>
            );
          })}
        </Row>
      </Box>
    </>
  );
};

export default BuyCreditsOfferPage;
