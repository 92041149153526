import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device as breakPoints } from 'modules/common/theme/mediaqueries';

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0;
  width: 650px;
  font-size: 14px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;

  @media screen and ${breakPoints.tabletS} {
    display: none;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media screen and ${breakPoints.tabletS} {
    margin-bottom: 10px;
    font-size: 10px;
  }
`;

const IconNextStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  font-weight: 400;
  color: ${color.gray100};
  border-radius: 50%;
  border: 1px solid ${color.LightGray};

  @media screen and ${breakPoints.tabletS} {
    width: 23px;
    height: 23px;
    font-weight: normal;
    font-size: 11px;
  }
`;

const NextStepActive = styled.img`
  @media screen and ${breakPoints.tabletS} {
    width: 23px;
    height: 23px;
  }
`;

export default {
  StepContainer,
  StepWrapper,
  IconNextStep,
  NextStepActive,
};
