import { createSelector } from 'reselect';
import { get } from 'lodash-es';

import { STATUS_INIT } from 'store/status';
import { dashboardSelector } from 'modules/dashboard/store/selectors/dashboard';

export const draftsStateSelector = createSelector(dashboardSelector, (state) =>
  get(state, 'drafts', {}),
);
export const draftsSelector = createSelector(draftsStateSelector, (state) =>
  get(state, 'data', null),
);
export const draftsStatusSelector = createSelector(draftsStateSelector, (state) =>
  get(state, 'status', STATUS_INIT),
);
