import { Input } from 'antd';
import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const { TextArea } = Input;

const Title = styled.div`
  font-size: 24px;
  margin: 20px 0px;
  font-weight: bold;
`;

const SubTitle = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Text = styled.div`
  font-size: 14px;
`;

const Divider = styled.div`
  border: 1px solid ${color.LightDullGray};
  width: 100%;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: end;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

const Textarea = styled(TextArea)`
  width: 100%;
  border: 1px solid ${color.Black};
  resize: none;
  padding: 15px 15px 7px 15px;
  font-size: 16px;

  &:focus {
    outline: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${color.SideBarBackground};
    border-radius: 2px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    background: ${color.scrollbarThumb};
  }
`;

const HtmlTemplate = styled.iframe`
  width: 100%;
  height: 100%;
  border: 1px solid ${color.Black};
  min-height: 460px;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  margin-right: ${(props) => (props.marginRight ? `${props.marginRight}px` : 0)};
  .ant-input {
    min-height: 460px;
  }
  @media only screen and (min-width: 1600px) {
    .ant-input {
      height: 55rem;
    }
  }
`;

const ContentWrapperRight = styled.div`
  width: 100%;
  flex: 1.7;
  margin-right: ${(props) => (props.marginRight ? `${props.marginRight}px` : 0)};
  @media only screen and (max-width: 1400px) {
    margin-top: 20px;
  }
`;

export default {
  ContentWrapper,
  ContentWrapperRight,
  Divider,
  FlexEnd,
  Footer,
  HtmlTemplate,
  SubTitle,
  Text,
  Textarea,
  Title,
  Wrapper,
};
