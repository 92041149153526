import { SET_SELECTED_LEADS, SET_UNSELECTED_LEADS } from 'modules/v2/store/constants';

export const setSelectedLeads = (leads) => ({
  type: SET_SELECTED_LEADS,
  payload: leads,
});

export const setUnselectedLeads = (leads) => ({
  type: SET_UNSELECTED_LEADS,
  payload: leads,
});
