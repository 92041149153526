import { Field } from 'formik';
import styled from 'styled-components';
import { Button as BaseButton } from 'antd';

import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
const IframeContainer = styled.div`
  max-width: 800px;
  min-width: 300px;
  width: 70%;
`;

const IframeWrapper = styled.div`
  height: 380px;
  margin: 0 auto;
`;

const DescriptionContainer = styled.div`
  min-width: 280px;
  width: 30%;
`;

const Title = styled.h3`
  line-height: 24px !important;
  font-size: 24px !important;
  color: ${color.Black};
  margin-bottom: 20px !important;
  font-weight: 700 !important;

  @media (max-width: 768px) {
    line-height: 32px !important;
    margin-bottom: 20px !important;
    padding-bottom: 0 !important;
  }
`;

const Description = styled.p`
  line-height: 24px !important;
  font-size: 16px !important;
  color: ${color.Black};
  margin-bottom: 30px !important;

  span {
    font-weight: 700 !important;
  }
`;

const LinksWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;

  > div {
    margin: 15px 15px 15px;
  }

  label {
    text-align: right;
    margin-right: 20px;
  }
`;

const Button = styled(BaseButton)`
  margin: 10px 15px;
  height: 40px;
  font-size: 16px;

  &,
  &:hover,
  &:focus {
    color: ${color.White};
    background-color: ${color.PrimaryColor};
    border-color: ${color.PrimaryColor};
  }
`;

const BookDetails = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 1000px;
  margin: 0 auto;
  color: ${color.White};
  background: ${color.ViewFooterTitleBG};
`;

BookDetails.Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${color.White};
  padding: 10px 0;

  span {
    color: ${color.ViewFooterTitle};
    text-transform: capitalize;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
`;

const ShareLabel = styled.label`
  display: block;
  line-height: 26px;
  white-space: nowrap;
  width: 100px;
  text-align: right;
`;

const ModalWrapper = styled.div`
  padding: 20px 40px;
  .ant-input {
    border-color: ${color.InputBorder};
  }
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  width: 100%;
  flex: 1;
  border-radius: 3px;
  font-size: 18px;
  line-height: 22px;
  color: ${color.UserMenuText};
  .ant-select-selector {
    height: 40px !important;
    border: 1px solid ${color.InputBorder} !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-size: 16px;
  }
`;

export default {
  BookDetails,
  Button,
  LinksWrapper,
  IframeContainer,
  IframeWrapper,
  Wrapper,
  IconsWrapper,
  ShareLabel,
  Title,
  Description,
  DescriptionContainer,
  ModalWrapper,
  FormikField,
};
