import { GET_CATEGORIES, GET_BOOKS_BY_CATEGORY } from '../constants';

const initialState = {
  data: [],
  loading: false,
  loaded: false,
  selected: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_CATEGORIES}_REQUEST`:
      return { ...state, loading: true };

    case GET_CATEGORIES:
      // sort categories in the ascending order
      const categories = action.payload.data.categories.sort((category1, category2) => {
        return category1.order - category2.order;
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: [{ _id: '', name: 'Show All' }, ...categories],
      };

    case `${GET_CATEGORIES}_FAILURE`:
      return { ...state, loading: true, loaded: false };

    case GET_BOOKS_BY_CATEGORY:
      return { ...state, selected: action.category };

    default:
      return { ...state };
  }
};
