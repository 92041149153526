import styled, { css } from 'styled-components';

import { Button } from 'antd';
import { Field } from 'formik';
import * as font from 'modules/common/theme/font';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-start;
  border: 1px solid rgb(206, 212, 241);

  .ant-select.ant-select-focused {
    outline: -webkit-focus-ring-color auto 1px;
  }
  .ant-select-selection-selected-value,
  .ant-select-selection__placeholder,
  .ant-select-selection {
    font-family: ${font.MontserratFont};
    font-size: 14px;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    line-height: 45px;
    height: 45px;
    margin: 0;
    font-weight: normal;
    text-align: left;
    padding: 0 20px 0 0;
    border: none;
    position: static;

    &:focus {
      box-shadow: none;
    }
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  color: rgb(46, 63, 80);
  margin: 0 10px 20px;
`;

const SelectField = styled(Field)`
  .ant-select-selection--single {
    height: 45px;
  }

  &.form-input-error {
    border: 1px solid #f27575;
  }
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  flex-basis: 45px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  padding: 0 15px;
  font-size: 18px;
  line-height: 22px;
  color: rgb(46, 63, 80);

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid #f27575;
    `}
`;

const ButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  padding: 0 10px;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  border: none;
  font-weight: bold;
  background-color: rgb(152, 15, 32);
  margin-right: 20px;
  padding: 0 20px;
  &:hover {
    background-color: rgb(152, 15, 32);
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: rgb(46, 63, 80);
  margin: 0 0 10px;
  padding: 0;
  margin-top: 20px;
  font-weight: 400;
`;

const CancelButton = styled(Button)`
  color: #980f20;
  &:hover {
    color: #980f20;
  }

  ${(props) =>
    props.type !== 'link' &&
    css`
      color: rgb(46, 63, 80);
      background-color: rgb(204, 204, 204);
      padding: 0 20px;
      border-color: rgb(204, 204, 204);
      font-weight: bold;
      &:hover {
        color: rgb(46, 63, 80);
        background-color: rgb(204, 204, 204);
      }
    `}
`;

export default {
  Wrapper,
  FieldGroup,
  FieldControl,
  Label,
  FormikField,
  SubmitButton,
  CancelButton,
  ButtonsWrapper,
  Paragraph,
  SelectField,
};
