import { useState } from 'react';
import { Formik, Form } from 'formik';
import { SelectMultiField } from 'modules/common/components';
import { FieldGroup, FieldControl } from 'modules/common/components/UIComponents/Form';
import { notification } from 'modules/v2/common/utils/notify';
import { Upload, Checkbox } from 'antd';
import ImportBtn from './ImportBtn';
import S from './style';
import { importLead } from '../../utils';

const ImportLeads = ({ getData, getSegmentList, segmentsData, getIsAllSegments }) => {
  const [importLeads, setImportLeads] = useState({});
  const [isAllSegment, setIsAllSegment] = useState(false);

  const uploadChange = (e) => {
    if (e.file.type.search('csv') > -1 || e.file.type.search('excel') > -1) {
      setImportLeads(e.file);
    } else {
      notification.error({
        description:
          'Sorry, an error occurs while importing your leads. Please check the required data field and try again.',
        autoClose: 3000,
      });
    }
  };

  const beforeUploadChange = (file) => {
    if (file.type.search('csv') > -1 || file.type.search('excel') > -1) {
      return true;
    }
    return false;
  };

  const deleteChange = (event) => {
    setImportLeads({});
    event.preventDefault();
  };

  const downloadFile = () => {
    const json = [
      {
        firstName: 'Test',
        lastName: 'Test Last name',
        email: 'test@mail.com',
        phone: '7778889996',
      },
    ];

    const exportFileName = 'sampleLead.csv';
    const blob = new Blob([importLead(json)], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportFileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportFileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <Formik enableReinitialize initialValues={importLeads}>
      {({ values }) => {
        getData(values);
        if (values && Object.keys(values).some((s) => s && s === 'segments')) {
          getSegmentList(values.segments);
        }
        return (
          <S.Wrapper>
            <Form>
              <FieldGroup gap>
                <FieldControl>
                  <S.ButtonText onClick={downloadFile}>Download Sample File</S.ButtonText>
                  <S.Label>
                    Add File(s)
                    {!Object.keys(importLeads).length ? (
                      <Upload
                        accept="application/vnd.ms-excell,.csv"
                        onChange={uploadChange}
                        showUploadList={false}
                        beforeUpload={beforeUploadChange}
                        name="leadupload"
                      >
                        <ImportBtn />
                      </Upload>
                    ) : (
                      <S.SubBtn>
                        <S.SubHead>
                          <S.FileIcon />
                          <S.Space>{importLeads?.name}</S.Space>
                          <S.DeleteIcon onClick={deleteChange} />
                        </S.SubHead>
                      </S.SubBtn>
                    )}
                  </S.Label>
                </FieldControl>
              </FieldGroup>
              <FieldGroup gap>
                <FieldControl>
                  <S.Para>
                    <S.Heading>Disclaimer: </S.Heading>
                    Please make sure that you have proper permission from all leads you are
                    importing, otherwise their email platform may tag your emails as spam.
                  </S.Para>
                </FieldControl>
              </FieldGroup>

              <FieldGroup gap>
                <S.Label>
                  Add To Segment *
                  <S.FormikField
                    disabled={isAllSegment}
                    component={SelectMultiField}
                    name="segments"
                    placeholder={isAllSegment ? 'All Segments' : 'Select Segment(s)'}
                    type="text"
                    options={segmentsData}
                  />
                </S.Label>
              </FieldGroup>
              <FieldGroup gap>
                <FieldControl>
                  <S.CheckBox>
                    <Checkbox
                      name="allSegments"
                      id="allSegments"
                      onChange={(e) => {
                        setIsAllSegment(e.target.checked);
                        getIsAllSegments(e.target.checked);
                      }}
                    >
                      All Segments
                    </Checkbox>
                  </S.CheckBox>
                </FieldControl>
              </FieldGroup>
            </Form>
          </S.Wrapper>
        );
      }}
    </Formik>
  );
};

export default ImportLeads;
