import { compose } from 'redux';
import { Drawer } from 'antd';

import { withAddress } from 'modules/v2/containers';
import { AddressFormView } from 'modules/v2/common/views/AddressView/views';
import { useEffect, useState } from 'react';


const ShippingNewAddress = ({
  countries,
  states,
  addUserAddresses,
  openAddressForm,
  setOpenAddressForm,
  fetchUserAddressesRefetch,
}) => {
  // Handle drawer close
  const handleCancel = () => setOpenAddressForm(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = (fields: object) => {
    const callback = addUserAddresses;
    const cbResult = callback(fields);
    if (cbResult) {
      cbResult.then(async () => {
        fetchUserAddressesRefetch();
        setOpenAddressForm(false);
      });
    }
  };

  return (
    <Drawer
      open={openAddressForm}
      width={windowSize > 768 ? 600 : 300}
      destroyOnClose
      title="Add new address"
      headerStyle={{ fontSize: '2rem' }}
      onClose={handleCancel}
    >
      <AddressFormView
        fields={{}}
        countries={countries.data}
        states={states.data}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Drawer>
  );
};

export default compose(withAddress)(ShippingNewAddress);
