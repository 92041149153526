import styled from 'styled-components';
import { Radio } from 'antd';
import * as color from 'modules/common/theme/color';

const { Group: BaseGroup } = Radio;

const Title = styled.div`
  font-size: 24px;
  margin: 20px 0px;
  font-weight: bold;
  color: ${color.Black};
`;

const SubTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${color.Black};
`;

const Group = styled(BaseGroup)`
  display: flex;
  flex-wrap: wrap;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  align-items: center;

  img {
    display: block;
    height: 136px;
    width: 104px;
  }
`;

const OptionText = styled.div`
  font-size: 14px;
`;

const Main = styled.main`
  margin-top: 24px;
`;

const Footer = styled.footer`
  margin: 24px 0;
  display: flex;
  justify-content: end;
  gap: 8px;
`;

const SpBlack = styled.span`
  color: ${color.Black};
`;

const SpRed = styled.span`
  color: ${color.DangerRed};
`;

const ULStyleNone = styled.ul`
  list-style: none;
`;

const TextLineHeightDimensions = styled(Text)`
  font-size: 11px;
  line-height: 13px;
`;

export default {
  Title,
  SubTitle,
  Radio,
  Group,
  Option,
  OptionText,
  Main,
  Footer,
  SpBlack,
  SpRed,
  ULStyleNone,
  TextLineHeightDimensions,
};
