export const BoltIcon = ({ width = 20, height = 20, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconer">
      <path
        id="Vector"
        d="M4.72452 8.26193L7.27631 4.8094C8.92577 2.57769 9.75051 1.46183 10.52 1.69764C11.2894 1.93344 11.2894 3.30204 11.2894 6.03922V6.29731C11.2894 7.28455 11.2894 7.77818 11.6049 8.0878L11.6216 8.10384C11.9438 8.40693 12.4576 8.40693 13.4851 8.40693C15.3342 8.40693 16.2587 8.40693 16.5712 8.96771C16.5763 8.977 16.5814 8.98636 16.5863 8.99579C16.8812 9.56526 16.3459 10.2895 15.2753 11.738L12.7235 15.1905C11.074 17.4222 10.2493 18.5381 9.47978 18.3023C8.71032 18.0665 8.71034 16.6979 8.71038 13.9606L8.71038 13.7027C8.7104 12.7154 8.7104 12.2218 8.39495 11.9121L8.37826 11.8961C8.056 11.593 7.54224 11.593 6.51471 11.593C4.66565 11.593 3.74112 11.593 3.42867 11.0322C3.42349 11.0229 3.41846 11.0136 3.41358 11.0042C3.11862 10.4347 3.65392 9.71044 4.72452 8.26193Z"
        fill={fill}
      />
    </g>
  </svg>
);
