import styled from 'styled-components';

import * as color from 'modules/common/theme/color';

const DashboardWrapper = styled.div`
  margin-top: 64px;
  background-color: ${color.White};
`;

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
`;

const Title = styled.h5`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 0px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.Black};
`;

const TopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 20px 0px;
`;

const CardWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px 20px 0px;
`;

const CardNumber = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.Black};
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${color.grayDot};
  border-radius: 50%;
  margin-right: 5px;
`;

const DefaultWrap = styled.div`
  width: 73px;
  height: 30px;
  background-color: ${({ $transparent }) =>
    $transparent ? 'transparent' : color.defaultLightGray};
  border-radius: 2px;
  margin: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.TooltipTextColor};
`;

const AddPaymentMethod = styled.a`
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  color: ${color.InputFocusBorder};
  text-decoration: underline !important;
  margin-bottom: 40px;
  display: block;
`;

const CardImage = styled.div`
  margin-right: 15px;
  margin-right: 15px;
  background-color: ${({ $icon }) =>
    // eslint-disable-next-line no-nested-ternary
    $icon === 'visa' ? 'rgba(0,58,140,1)' : $icon === 'mastercard' ? color.grayBox : ''};
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`;

const Panel = styled.div`
  display: flex;
`;

const DashboardContent = styled.div`
  width: 70%;
`;

const TableWrap = styled.div`
  margin-bottom: 30px;
`;

const InfoIcon = styled.span`
  margin-left: 8px;
  vertical-align: middle;

  svg {
    margin-top: 4px;
    cursor: pointer;
  }
`;

const FakeLink = styled.span`
  color: ${color.SkyBlue};
  cursor: pointer;
`;

const CardImg = styled.img`
  height: 30px;
`;

const CardText = styled.span`
  font-size: 14px;
  line-height: 24px;
  margin-left: 5px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin: 15px 0;
`;

const FilterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FilterLabel = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${color.FilterLabel};
  margin-right: 6px;

  .table {
    color: ${color.gray100};
  }
`;

export default {
  MainContain,
  Title,
  Description,
  TopWrap,
  CardWrap,
  CardNumber,
  Dot,
  DefaultWrap,
  InfoIcon,
  AddPaymentMethod,
  CardImage,
  Panel,
  DashboardWrapper,
  DashboardContent,
  TableWrap,
  FakeLink,
  CardImg,
  CardText,
  FilterContainer,
  FilterItem,
  FilterLabel,
};
