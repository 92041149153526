import { Modal, CustomFlowbiteTheme } from 'flowbite-react';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { CheckIconNew } from 'modules/v2/common/components/SvgIcon';

interface SuccessModalProps {
  title: string;
  description?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const defaultProps: Partial<SuccessModalProps> = {
  description: '',
};

const customTheme: CustomFlowbiteTheme['modal'] = {
  content: {
    base: 'relative h-full w-fit w-[440px] md:h-auto',
  },
  footer: {
    base:
      'flex space-x-2 rounded-b border-neutral-200 min-h-[91px] px-6 py-[13px] dark:border-neutral-600',
  },
};

const SuccessModal = ({ title, description, showModal, setShowModal }: SuccessModalProps) => {
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Modal theme={customTheme} show={showModal} dismissible onClose={() => setShowModal(false)}>
      <Modal.Body>
        <div className="mx-auto w-[52px] h-[52px] border-2 border-success-500 rounded-full flex items-center justify-center">
          <CheckIconNew fill="#069668" width={34} height={34} />
        </div>
        <div className="text-center w-[513px] sm:w-full mt-6">
          <p className="font-bold mb-3.5">{title}</p>
          {description && <p className="text-md">{description}</p>}
        </div>
        <div className="flex justify-center mt-6">
          <Button onClick={handleCloseModal} type="dark" className="w-[116px]">
            Got it
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

SuccessModal.defaultProps = defaultProps;

export default SuccessModal;
