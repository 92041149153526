import { LOCATION_CHANGE } from 'connected-react-router';
import { call, delay, put, race, take, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';
import { notification } from 'modules/common/utils';

import { fetchDraftLinks, getError, getPayload } from 'modules/api';

import { GET_DRAFT_LINKS } from 'modules/draft/store/constants';
import {
  isPreviewStatusEmpty,
  isPreviewStatusReady,
  isPreviewStatusModified,
  isPreviewStatusFailed,
} from 'modules/draft/utils/preview';

const RETRY_TIME = 1000;

export function* onGetDraftLinks({ payload: meta }) {
  const notify = get(meta, 'notify', false);

  yield put({
    type: `${GET_DRAFT_LINKS}_REQUESTED`,
    meta,
  });

  try {
    while (true) {
      const response = yield call(fetchDraftLinks, meta);
      const payload = getPayload(response);

      const status = get(payload, 'status');
      const isResolved = [
        isPreviewStatusEmpty,
        isPreviewStatusReady,
        isPreviewStatusModified,
        isPreviewStatusFailed,
      ].some((check) => check(status));

      if (isPreviewStatusFailed(status)) {
        yield put({
          type: `${GET_DRAFT_LINKS}_FAILED`,
          error: true,
          payload,
        });

        if (notify) {
          notification.error({ description: 'Something went wrong while generating digital book' });
        }

        return payload;
      }

      if (isPreviewStatusReady(status)) {
        if (notify) {
          notification.success({
            description: 'Your digital book has been generated successfully',
          });
        }
      }

      // The data may have the status failed, but the action has succeeded
      if (isResolved) {
        yield put({
          type: `${GET_DRAFT_LINKS}_SUCCEEDED`,
          payload,
        });

        return payload;
      }

      yield delay(RETRY_TIME);
    }
  } catch (error) {
    const payload = getError(error);

    yield put({
      type: `${GET_DRAFT_LINKS}_FAILED`,
      error: true,
      payload,
    });

    if (notify) {
      notification.error({ description: 'Something went wrong while generating digital book' });
    }

    // eslint-disable-next-line no-console
    console.error(payload);

    return payload;
  }
}

export function* watchCancellation(action) {
  yield race([call(onGetDraftLinks, action), take(LOCATION_CHANGE)]);
}

export function* watchGetDraftLinks() {
  yield takeEvery(GET_DRAFT_LINKS, watchCancellation);
}
