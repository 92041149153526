import { findIndex, get, map } from 'lodash-es';

import {
  UPDATE_COVER_IMAGE,
  UPDATE_COVER_ELEMENT,
  UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
  UPDATE_CONTENT_ELEMENT,
} from 'modules/v2/editor/store/constants';

import {
  updateCoverElementImages,
  updateCoverElementRichText,
  updateCoverElementsByPreferences,
} from './utils';

const reducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_COVER_IMAGE: {
      const { elementId, defaultValue } = action.payload;
      const index = findIndex(state, ({ _id }) => _id === elementId);

      if (index < 0) {
        return state;
      }

      const id = get(state, [index, 'id']);
      const mapper = updateCoverElementImages(id, defaultValue);

      return map(state, mapper);
    }

    case UPDATE_COVER_ELEMENT: {
      const { elementId, rawValue, defaultValue } = action.payload;
      const index = findIndex(state, ({ _id }) => _id === elementId);

      if (index < 0) {
        return state;
      }

      const id = get(state, [index, 'id']);
      const mapper = updateCoverElementRichText(id, rawValue, defaultValue);

      return map(state, mapper);
    }

    case UPDATE_COVER_ELEMENTS_BY_PREFERENCES: {
      const { preferences } = action.payload;
      const mapper = updateCoverElementsByPreferences(preferences);

      return map(state, mapper);
    }

    case UPDATE_CONTENT_ELEMENT: {
      const { elementId, rawValue, defaultValue } = action.payload;
      const index = findIndex(state, ({ _id }) => _id === elementId);

      if (index < 0) {
        return state;
      }

      const elementDefaultValue = get(state, [index, 'defaultValue']);

      if (elementDefaultValue === defaultValue) {
        return state;
      }

      const element = {
        ...state[index],
        rawValue,
        defaultValue,
      };

      return [...state.slice(0, index), element, ...state.slice(index + 1)];
    }

    default:
      return state;
  }
};

export default reducer;
