import withStore from 'store/StoreContainer';
import {
  getDraftById,
  generateDraftFromBook,
  generateDraftFromOrder,
  goToRoot,
  draftStateSelector,
  draftIdSelector,
  draftNameSelector,
} from 'modules/draft/store';

const mapStateToProps = (state) => ({
  draft: draftStateSelector(state),
  draftId: draftIdSelector(state),
  draftName: draftNameSelector(state),
});

const mapDispatchToProps = {
  getDraftById,
  generateDraftFromBook,
  generateDraftFromOrder,
  goToRoot,
};

export default withStore(mapStateToProps, mapDispatchToProps);
