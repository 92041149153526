import * as color from 'modules/common/theme/color';
import styled from 'styled-components';

const PreviewWrapper = styled.div`
  position: absolute;
  opacity: 0;
  top: 50px;
  left: 4px;
  height: 230px;
  width: 205px;
  z-index: 1;
  color: ${color.White};
  text-align: center;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 2px;
`;

const UpdateMembershipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const PreviewModalBody = styled.div`
  height: calc(100vh - 220px);

  @media (max-width: 768px) {
    height: 100vh;
  }
`;

const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
`;

const Preview = styled.div`
  position: relative;

  &:hover {
    ${PreviewWrapper} {
      opacity: 1;
    }
  }
`;

export default {
  Preview,
  PreviewWrapper,
  UpdateMembershipContainer,
  PreviewModalBody,
  ModalFooterContainer,
};
