import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  allSegments: Yup.boolean(),
  segments: Yup.array().when('allSegments', {
    is: false,
    then: Yup.array().of(Yup.number().min(1)).required('Please select at least one segment'),
  }),
  startDate: Yup.string().required('Please select a start date'),
});

export default validationSchema;
