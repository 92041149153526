import { useEffect } from 'react';

function useHubspotTrackCode() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/3424767.js';
    script.charset = 'utf-8';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, []);
}

export default useHubspotTrackCode;
