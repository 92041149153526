export const CHECKING_PREFLIGHT = 'CHECKING_PREFLIGHT';
export const GENERATING_LINKS = 'GENERATING_LINKS';

export const views = {
  CHECKING_PREFLIGHT,
  GENERATING_LINKS,
};

export const modalWidth = {
  CHECKING_PREFLIGHT: '900px',
  GENERATING_LINKS: '650px',
};
