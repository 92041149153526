import styled from 'styled-components';
import { DatePicker as DatePickerBase, Layout as LayoutBase, Select as SelectBase } from 'antd';
import { Tooltip as TooltipBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';
import { Field } from 'formik';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
`;

const Wrapper = styled.div`
  input,
  .ant-select {
    width: 100%;
  }
`;

const WrapperContent = styled(LayoutBase.Content)`
  padding: 20px;
  background-color: ${color.White};
  height: 100%;
  overflow: auto;
`;

const TemplateView = styled.div`
  width: 600px;
  margin: 20px auto 0;
  min-height: 647px;
  border-radius: 2px;

  img {
    max-width: 560px;
  }
`;

const Title = styled.h4`
  font-size: 16px !important;
`;

const Label = styled.label`
  margin-bottom: 10px;
  margin-top: ${({ $noMarginTop }) => ($noMarginTop ? 0 : '20px')};
  font-size: 12px;
  display: block;
`;

const DatePicker = styled(DatePickerBase)`
  width: 100%;
  margin-bottom: 20px;
`;

const LabelWithIcon = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextField = styled(Field)`
  border: 1px solid #d9d9d9;
`;

const Select = styled(SelectBase)`
  width: 100%;
`;

const Tooltip = styled(TooltipBase)``;

const Sidebar = styled(LayoutBase.Sider)`
  flex-basis: 280px !important;
  width: 280px !important;
  max-width: 280px !important;
  box-shadow: 0px 3px 6px #00000040;

  background: ${color.lightBackground};
  padding: 30px 20px 30px 40px;
`;

export default {
  Layout,
  DatePicker,
  Label,
  LabelWithIcon,
  Title,
  Tooltip,
  Wrapper,
  Sidebar,
  WrapperContent,
  TemplateView,
  Select,
  TextField,
};
