import styled from 'styled-components';
import { Layout } from 'antd';

const Wrapper = styled(Layout)`
  background: transparent;
  min-height: 90vh;

  .ck-editor {
    border: none;
  }
`;

export default {
  Wrapper,
};
