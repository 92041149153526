import React from 'react';

import S from './styles';

const Sidebar = ({ title, children }) => (
  <S.Sidebar>
    <S.Title>{title}</S.Title>
    {children}
  </S.Sidebar>
);

export default React.memo(Sidebar);
