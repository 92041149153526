import { isEmpty, isObject, forEach } from 'lodash-es';

import S from './styles';

const renderErrors = (errors, errorKey, arrOfErrors) => {
  if (isObject(errors)) {
    forEach(errors, (nestedError, index) => {
      renderErrors(nestedError, index, arrOfErrors);
    });
  } else {
    arrOfErrors.push(<S.Item key={errorKey}>{errors}</S.Item>);
  }

  return arrOfErrors;
};

const FormErrors = ({ errors }) => {
  if (isEmpty(errors)) {
    return null;
  }

  const items = renderErrors(errors, '', []);

  return <S.Wrapper>{items}</S.Wrapper>;
};

export default FormErrors;
