import React, { createContext, useState } from 'react';

export const HolidaySaleContext = createContext(null);

type ContextValue = {
  paymentProfiles: object[];
  setPaymentProfiles: React.Dispatch<React.SetStateAction<[]>>;
  defaultProfile: {
    id: string;
  };
  setDefaultProfile: React.Dispatch<
    React.SetStateAction<{
      id: string;
    }>
  >;
  currentProduct: string;
  setCurrentProduct: React.Dispatch<React.SetStateAction<string>>;
  creditValue: {
    id: string;
  };
  setCreditValue: React.Dispatch<
    React.SetStateAction<{
      id: string;
    }>
  >;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  paymentValue: {
    id: string;
  };
  setPaymentValue: React.Dispatch<
    React.SetStateAction<{
      id: string;
    }>
  >;
  orderId: string;
  setOrderId: React.Dispatch<React.SetStateAction<string>>;
};

export const HolidaySaleProvider = ({ children }) => {
  const [paymentProfiles, setPaymentProfiles] = useState([]);
  const [defaultProfile, setDefaultProfile] = useState({
    id: '',
  });
  const [currentProduct, setCurrentProduct] = useState('');
  const [creditValue, setCreditValue] = useState({
    id: undefined,
  });
  const [step, setStep] = useState(1);

  const [paymentValue, setPaymentValue] = useState({
    id: undefined,
  });

  const [orderId, setOrderId] = useState('');

  if (!children) {
    return null;
  }

  const contextValue: ContextValue = {
    paymentProfiles,
    defaultProfile,
    setPaymentProfiles,
    setDefaultProfile,
    currentProduct,
    setCurrentProduct,
    creditValue,
    setCreditValue,
    step,
    setStep,
    paymentValue,
    setPaymentValue,
    orderId,
    setOrderId,
  };

  return (
    <HolidaySaleContext.Provider value={contextValue || null}>
      {children}
    </HolidaySaleContext.Provider>
  );
};
