const defaultCheckboxTheme = {
  root: {
    base: 'h-4 w-4 rounded border border-neutral-300 focus:ring-2',
    color: {
      default: 'text-primary-500 focus:ring-primary-400',
      checked: 'text-primary-700 bg-primary-500 border-transparent',
    },
  },
};

export { defaultCheckboxTheme };
