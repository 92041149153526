import styled from 'styled-components';

import * as color from 'modules/v2/common/theme/color';

const Wrapper = styled.div`
  border: 1px solid ${color.PrimaryColor};
  border-bottom: 0;
  border-radius: 3px;
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: 200px;
  height: fit-content;
`;

export default {
  Wrapper,
};
