import getInstance from 'modules/api/instance';

const instance = getInstance();

export const createTicket = ({ message }) => {
  const url = '/support/ticket';

  const body = { message };

  return instance.post(url, body);
};
