import {
  CLEAR_DRAFT,
  GET_DRAFT_BY_ID,
  CLEAR_DIGITAL_BOOK,
  GENERATE_DIGITAL_BOOK,
  CANCEL_GENERATE_DIGITAL_BOOK,
  GENERATE_DRAFT_FROM_BOOK,
  GENERATE_DRAFT_FROM_ORDER,
} from 'modules/v2/draft/store/constants';

export const clearDraft = () => ({
  type: CLEAR_DRAFT,
});

export const getDraftById = (draftId) => ({
  type: GET_DRAFT_BY_ID,
  payload: {
    draftId,
    reduced: true,
  },
});

export const clearDigitalBook = () => ({
  type: CLEAR_DIGITAL_BOOK,
});

export const generateDigitalBook = (draftId) => ({
  type: GENERATE_DIGITAL_BOOK,
  payload: {
    draftId,
  },
});

export const cancelGenerateDigitalBook = () => ({
  type: CANCEL_GENERATE_DIGITAL_BOOK,
});

export const generateDraftFromBook = (bookId) => ({
  type: GENERATE_DRAFT_FROM_BOOK,
  payload: {
    bookId,
  },
});

export const generateDraftFromOrder = (orderId) => ({
  type: GENERATE_DRAFT_FROM_ORDER,
  payload: {
    orderId,
  },
});
