export const RestartIcon = ({ width = 16, height = 16, fill = '#252525' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      d="M12.2427 5.36749L11.7713 4.89608C9.68852 2.81329 6.31164 2.81329 4.22885 4.89608C2.14605 6.97888 2.14605 10.3558 4.22885 12.4386C6.31164 14.5214 9.68852 14.5214 11.7713 12.4386C12.9825 11.2273 13.4894 9.57849 13.2919 8.001M12.2427 5.36749H9.41429M12.2427 5.36749V2.53906"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
