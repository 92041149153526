import styled from 'styled-components';
import { device } from 'modules/common/theme/mediaqueries';

const PageTitle = styled.h3`
  font-size: 25px;
  font-weight: normal;
  line-height: 24px;
  vertical-align: center;
  margin-left: 0px !important;
  ${({ $noTransform }) => ($noTransform ? '' : 'text-transform: capitalize;')}

  @media ${device.tabletS} {
    font-size: 20px !important;
    margin: 20px 0 0;
  }
`;

const BookDetailsTitle = styled(PageTitle)`
  margin-bottom: 38px;
  margin-top: 30px;
`;

const PageSubTitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 8px !important;

  @media ${device.tabletS} {
    font-size: 18px !important;
  }
`;

const PageDescription = styled.p`
  font-size: 14px;
  font-weight: normal;

  @media ${device.tabletS} {
    font-size: 14px;
  }
`;

export { PageTitle, PageSubTitle, PageDescription, BookDetailsTitle };
