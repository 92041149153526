import { get } from 'lodash-es';

import {
  REORDER_SECTION,
  ACTIVATE_SECTION,
  DEACTIVATE_SECTION,
  UPDATE_COVER_IMAGE,
  UPDATE_COVER_ELEMENT,
  UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
  UPDATE_COVER_ACTIVE_TEMPLATE,
  UPDATE_CONTENT_ELEMENT,
  UPDATE_CONTENT_ACTIVE_TEMPLATE,
} from 'modules/v2/editor/store/constants';
import {
  TYPE_COVER,
  TYPE_CONTENT,
  TYPE_CHAPTER,
  getSectionById,
  getSectionIndexById,
  getSectionIndexByType,
  getActiveChaptersLength,
} from 'modules/v2/editor/utils';

import templatesReducer from './templates';
import { activateSection, deactivateSection, reorderSection } from './utils';

const reducer = (sections = [], action) => {
  switch (action.type) {
    case REORDER_SECTION: {
      const { sectionId, nextIndex } = action.payload;
      const section = getSectionById(sections, sectionId);

      if (sectionId !== 'manuscript' && section === undefined) {
        return sections;
      }

      const type = sectionId === 'manuscript' ? TYPE_CONTENT : section.type;

      return reorderSection(sections, sectionId, type, nextIndex);
    }

    case ACTIVATE_SECTION: {
      const { sectionId, nextIndex } = action.payload;
      const section = getSectionById(sections, sectionId);

      if (sectionId === 'manuscript' || section === undefined) {
        return sections;
      }

      return activateSection(sections, sectionId, section.type, nextIndex);
    }

    case DEACTIVATE_SECTION: {
      const { sectionId, nextIndex } = action.payload;
      const section = getSectionById(sections, sectionId);
      const chaptersLength = getActiveChaptersLength(sections);

      // Do not allow to deactivate the manuscript
      if (sectionId === 'manuscript' || section === undefined) {
        return sections;
      }

      // Avoid to deactivate all chapters
      if (section.type === TYPE_CHAPTER && chaptersLength === 1) {
        return sections;
      }

      return deactivateSection(sections, sectionId, section.type, nextIndex);
    }

    case UPDATE_COVER_IMAGE:
    case UPDATE_COVER_ELEMENT:
    case UPDATE_COVER_ELEMENTS_BY_PREFERENCES:
    case UPDATE_COVER_ACTIVE_TEMPLATE: {
      const index = getSectionIndexByType(sections, TYPE_COVER);

      if (index < 0) {
        return sections;
      }

      const templates = get(sections, [index, 'templates'], []);

      const section = {
        ...sections[index],
        templates: templatesReducer(templates, action),
      };

      return [...sections.slice(0, index), section, ...sections.slice(index + 1)];
    }

    case UPDATE_CONTENT_ELEMENT:
    case UPDATE_CONTENT_ACTIVE_TEMPLATE: {
      const { sectionId } = action.payload;
      const index = getSectionIndexById(sections, sectionId);

      if (index < 0) {
        return sections;
      }

      const templates = get(sections, [index, 'templates'], []);

      const section = {
        ...sections[index],
        templates: templatesReducer(templates, action),
      };

      return [...sections.slice(0, index), section, ...sections.slice(index + 1)];
    }

    default:
      return sections;
  }
};

export default reducer;
