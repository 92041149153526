import styled, { ThemedStyledProps } from 'styled-components';
import { Card, CardProps, Col, Space } from 'antd';
import * as color from 'modules/common/theme/color';

interface PercentRiseOrFallProps {
  isRising: boolean;
  value: number;
}

const PercentRiseOrFall = styled.div<ThemedStyledProps<PercentRiseOrFallProps, any>>`
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateY(-50%);
  border-radius: 40px;
  font-size: 12px;
  display: ${(props) => (!props.value ? 'none' : 'block')};
  color: ${(props) => {
    return props.isRising ? color.StatusCardBadgeFGGreen : color.StatusCardBadgeFGRed;
  }};
  background: ${(props) => {
    return props.isRising ? color.StatusCardBadgeBGGreen : color.StatusCardBadgeBGRed;
  }};
  height: 20px;
  width: ${(props) => (!props.value ? '50px' : '80px')};
  text-align: center;
`;

const ColGrid = styled(Col)`
  flex-basis: 20%;
  width: 20%;
`;

interface CustomCardProps extends CardProps {
  hasBorderRight?: boolean;
}

const CardRadius = styled(Card)<CustomCardProps>`
  border-radius: 0;
  background: ${color.White};
  height: 90px;
  border-right: ${(props) => (props.hasBorderRight ? `1px solid ${color.neutral200}` : 'none')};
`;

const SpaceFullWidth = styled(Space)`
  width: 100%;
`;

const BallIcon = styled.div`
  width: 8px;
  height: 8px;
  border: 2px solid ${(props) => (props.color ? props.color : color.Black)};
  background-color: ${(props) => (props.color ? props.color : color.Black)};
  border-radius: 50%;
`;

export default { PercentRiseOrFall, ColGrid, CardRadius, SpaceFullWidth, BallIcon };
