import produce from 'immer';
import { filter, find, findIndex, forEach, map, sortBy } from 'lodash-es';

export const TYPE_COVER = 'Cover';
export const TYPE_CONTENT = 'Content';
export const TYPE_CHAPTER = 'Chapter';
export const TYPE_MANUSCRIPT = 'Manuscript';

export const STATUS_ACTIVE = 'active';
export const STATUS_INACTIVE = 'inActive';

export function getSectionById(sections, sectionId) {
  return find(sections, ({ _id }) => _id === sectionId);
}

export function getSectionIndexByType(sections, sectionType) {
  return findIndex(sections, ({ type }) => type === sectionType);
}

export function getSectionIndexById(sections, sectionId) {
  return findIndex(sections, ({ _id }) => _id === sectionId);
}

export function getActiveChapters(sections) {
  const filterer = ({ type, currentStatus }) =>
    type === TYPE_CHAPTER && currentStatus === STATUS_ACTIVE;
  return filter(sections, filterer);
}

export function getActiveChaptersLength(sections) {
  const activeChapters = getActiveChapters(sections);
  return activeChapters.length;
}

// Create the manuscript entry
export const createManuscript = (order = 1) => ({
  _id: 'manuscript',
  type: TYPE_MANUSCRIPT,
  displayName: 'Manuscript',
  isActive: true,
  order,
});

// Map a section to the bucket structure
export const mapSectionToBucket = (sections) => {
  let chapter = 1;
  return map(sections, ({ _id, type, displayName, order, currentStatus }) => {
    let chapterNumber;

    if (type === TYPE_CHAPTER) {
      // eslint-disable-next-line no-plusplus
      chapterNumber = chapter++;
    }

    return {
      _id,
      type,
      order,
      displayName,
      chapterNumber,
      isActive: currentStatus === STATUS_ACTIVE,
    };
  });
};

// Arrange the order property of sections based on the bucket splitting and each section index
export const changeBucketOrderByIndex = (buckets) => {
  // Set the data structure that will receive the new sorted items
  const content = {
    active: [],
    inactive: [],
  };

  const manuscript = {
    active: [],
    inactive: [],
  };

  let order = 1;
  let chapterNumber = 1;

  // Firstly sorts the content active
  forEach(buckets.content.active, (section) => {
    content.active.push({
      ...section,
      isActive: true,
      order,
    });

    // When the manuscript item if found, it iterates over the manuscript bucket
    if (section.type === TYPE_MANUSCRIPT) {
      // Sort the manuscript active
      forEach(buckets.manuscript.active, (chapter) => {
        manuscript.active.push({
          ...chapter,
          isActive: true,
          chapterNumber,
          order,
        });

        // eslint-disable-next-line no-plusplus
        order++;
        // eslint-disable-next-line no-plusplus
        chapterNumber++;
      });

      // Sort the manuscript inactive
      forEach(buckets.manuscript.inactive, (chapter) => {
        manuscript.inactive.push({
          ...chapter,
          isActive: false,
          chapterNumber,
          order,
        });

        // eslint-disable-next-line no-plusplus
        order++;
        // eslint-disable-next-line no-plusplus
        chapterNumber++;
      });
    } else {
      // eslint-disable-next-line no-plusplus
      order++;
    }
  });

  // Lastly sorts the content inactive
  forEach(buckets.content.inactive, (section) => {
    content.inactive.push({
      ...section,
      isActive: false,
      order,
    });

    // eslint-disable-next-line no-plusplus
    order++;
  });

  return {
    content,
    manuscript,
  };
};

// Convert the type of buckets from object to array, considering the proper sorting
export function convertBucketsToMap(buckets) {
  const { content, manuscript } = buckets;

  const mapped = []
    .concat(content.active, manuscript.active, manuscript.inactive, content.inactive)
    .map((section) => [section._id, section]);

  return new Map(mapped);
}

// Convert all sections for the bucket structure for a better data manipulation
export const convertSectionsToBuckets = (sections) => {
  // Create the bucket list
  const buckets = {
    content: {
      active: [],
      inactive: [],
    },
    manuscript: {
      active: [],
      inactive: [],
    },
  };

  // Map the sections for the appropriate bucket structure
  const mapped = mapSectionToBucket(sections);

  // Sort all sections by the order
  const sorted = sortBy(mapped, ({ order }) => order);
  const filtered = sortBy(sorted, ({ type }) => type === TYPE_CONTENT || type === TYPE_CHAPTER);

  let hasManuscript = false;

  // Execute a single loop for distributing the sections to each bucket
  const reducer = produce((acc, section) => {
    const { type, order, isActive } = section;
    const bucket = type === TYPE_CONTENT ? 'content' : 'manuscript';
    const status = isActive ? 'active' : 'inactive';

    // Push the manuscript bucket with the first chapter order if it has not been added yet
    if (!hasManuscript && type === TYPE_CHAPTER && isActive) {
      const manuscript = createManuscript(order);
      acc.content.active.push(manuscript);
      hasManuscript = true;
    }

    // Distribute the sections to it's bucket
    if (type === TYPE_CONTENT || type === TYPE_CHAPTER) {
      acc[bucket][status].push(section);
    }
  });

  return filtered.reduce(reducer, buckets);
};

export const convertSectionsBucketArray = (sections) => {
  const buckets = convertSectionsToBuckets(sections);

  const content = [].concat(buckets.content.active).concat(buckets.content.inactive);

  const manuscript = [].concat(buckets.manuscript.active).concat(buckets.manuscript.inactive);

  return {
    content,
    manuscript,
  };
};
