//

import Loader from 'modules/common/components/Loader';

import S from './styles';

const OverlayLoader = ({ title, isOpen }) =>
  isOpen ? (
    <S.Wrapper>
      <Loader height="50px" title={title} />
    </S.Wrapper>
  ) : null;

export default OverlayLoader;
