import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  padding-left: 41px;
  height: 35px;
  max-width: 100%;
  border: 1px solid ${color.GrayBorder};
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  font-family: ${font.NunitoFont};
`;

Table.Link = styled(Link)`
  color: ${color.SkyBlue};
  text-decoration: none !important;
  font-weight: normal !important;
`;

Table.Container = styled.div`
  flex-direction: column;
  background-color: ${color.GreyHover};
  border: 1px solid ${color.GreyBorder};
  max-width: 100%;
  margin: 20px 0 0 0px;
  border-radius: 5px;
`;

Table.HeadRow = styled.tr`
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  height: 35px;
  overflow: hidden;
  box-shadow: 0 0 20px ${color.Black} 0.1;
  background-color: ${color.GreyHover};
  border: 1px solid ${color.GrayBorder};
  opacity: 1;
`;

Table.BodyRow = styled.tr`
  background: 0% 0% no-repeat padding-box;
  border: 1px solid ${color.GrayBorder};
  border-left: 0;
  border-right: 0;
  opacity: 1;
`;

Table.Heading = styled.th`
  background-color: ${color.GreyHover};
  font-size: 16px;
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
  font-weight: 700;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
  padding-left: 20px;
`;

Table.Body = styled.tbody`
  border-bottom: 1px solid ${color.TableBorder};
`;

Table.Footer = styled.tfoot`
  height: 10px;
  background: ${color.GreyHover};
  border: 1px solid ${color.LightDullGray};
  margin-top: -3px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
`;

Table.NoData = styled.td.attrs({
  colSpan: 5,
})`
  background: ${color.White} padding-box;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
  height: 45px;
  padding-left: 20px;

  a {
    text-decoration: underline !important;
    font-weight: bold;
  }
`;

Table.Data = styled.td`
  background: ${color.White} padding-box;
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
  font-size: 14px;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
  height: 45px;
  padding-left: 20px;
`;

Table.CheckBox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;

Table.Footer = styled.tfoot`
  display: table-footer-group;
`;

export { Table };
