import { Row, Col, Typography, Divider, Tooltip, Space } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useContext, useState } from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { get } from 'lodash-es';
import { useParams } from 'react-router';
import {
  fetchUserCredits,
  getDefaultPaymentProfile,
  getActiveSubscriptions,
  getPaymentProfilesList,
  getSubscriptionService,
  migratePlanToChargify,
  upgradePlanSubscription,
  submitHSForm,
} from 'modules/api';
import { Checkbox, Loader } from 'modules/common/components';
import {
  getRoutePlusPlansAlreadyPlus,
  getRoutePlusPlansReviewConfirmed,
} from 'modules/plusPlans/routes/navigation';
import { PlusPlansContext } from 'modules/plusPlans/components/context';
import { SelectPaymentComponent } from 'modules/dashboard/components/Payments/SelectPayment';
import PlanSelector from 'modules/plusPlans/components/PlanSelector';
import S from './styles';
import { PLUS_PLANS_FORM_IDS, PORTAL_ID } from './constants';
import { getItem } from '../../../dashboard/utils/legacy';

const { Title, Text } = Typography;

const SHAKE_DURATION = 1000;
const TOOLTIP_DURATION = 3000;

const PlusPlansReviewAndPay = ({ history }) => {
  const {
    planType,
  }: {
    planType: string | undefined;
  } = useParams();
  const {
    paymentProfiles,
    setDefaultProfile,
    setPaymentProfiles,
    setNewPlanName,
    setCurrentPlanName,
  } = useContext(PlusPlansContext);

  const [currentCard, setCurrentCard] = useState({
    id: '',
  });
  const [selectedPlan, setSelectedPlan] = useState({
    name: '',
    price: '',
    isMonthly: false,
    planHandle: '',
    perks: [],
  });
  const [shaking, setShaking] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const { data: userBookCredit } = useQuery('getUserCredits', fetchUserCredits);

  const bookCreditsLeft = get(userBookCredit, 'data.data.credits', 0);

  useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile, {
    onSuccess: (data) => {
      setDefaultProfile(data);
      setCurrentCard(data);
    },
  });

  const { data: subscription = {}, isLoading: isFetchingSubscription } = useQuery(
    'getSubscription',
    getActiveSubscriptions,
    {
      retry: 0,
      onSuccess: (data) => {
        if (data.component.product_family_handle === 'authorify_plus') {
          setCurrentPlanName(data.component.name);
          history.push(getRoutePlusPlansAlreadyPlus(planType));
        }
      },
    },
  );

  const currentSubscriptionPlanName = get(subscription, 'component.name', 'Trialing Plan');

  const { refetch } = useQuery('getPaymentProfilesList', getPaymentProfilesList, {
    onSuccess: (data) => {
      if (data?.length) {
        const filteredData = data.filter((item) => item.masked_card_number !== null);
        setPaymentProfiles(filteredData);
      }
    },
  });

  const handleTermsToggle = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const { mutate, isLoading } = useMutation('upgradePlanSubscription', upgradePlanSubscription, {
    onSuccess: async (data) => {
      if (data?.result?.component_handle === selectedPlan.planHandle) {
        setNewPlanName(data?.result?.price_point_name ?? '');
        const userDataString = getItem('userData');
        const userData = userDataString ? JSON.parse(userDataString) : null;
        const fields = [
          {
            objectTypeId: '0-1',
            name: 'firstname',
            value: userData.firstname,
          },
          {
            objectTypeId: '0-1',
            name: 'lastname',
            value: userData.lastname,
          },
          {
            objectTypeId: '0-1',
            name: 'email',
            value: userData.email,
          },
          {
            objectTypeId: '0-1',
            name: 'i_agree',
            value: true,
          },
        ];
        const context = {
          pageUri: window.location.href,
          pageName: 'Plus Plans Upgrade - DMP',
        };
        await submitHSForm(PORTAL_ID, PLUS_PLANS_FORM_IDS[planType], fields, context);
        history.push(getRoutePlusPlansReviewConfirmed(planType));
      }
    },
  });

  const { mutate: migrateToChargify, isLoading: isMigrating } = useMutation(
    'migratePlanToChargify',
    migratePlanToChargify,
  );

  const { data: { value: isSubscriptionFromStripe } = {} } = useQuery(
    'getSubscriptionService',
    getSubscriptionService,
  );

  const handleShake = () => {
    setShaking(true);
    setTooltipOpen(true);

    setTimeout(() => {
      setShaking(false);
    }, SHAKE_DURATION);

    setTimeout(() => {
      setTooltipOpen(false);
    }, TOOLTIP_DURATION);
  };

  const handleStripeSubscription = () => {
    const planComponentHandle = selectedPlan.planHandle;
    migrateToChargify(planComponentHandle);
  };

  const handleChargifySubscription = () => {
    const paymentProfileId = currentCard.id;
    const planComponentHandle = selectedPlan.planHandle;
    const params = {
      paymentProfileId,
      planComponentHandle,
      flow: 'family-change',
      isPlusPlan: true,
    };
    mutate(params);
  };

  const handlePayment = () => {
    if (!isTermsChecked || currentCard.id === '') {
      handleShake();
      return;
    }

    if (isSubscriptionFromStripe) {
      handleStripeSubscription();
      return;
    }

    handleChargifySubscription();
  };

  const PlanDetails = () => {
    return selectedPlan.perks.map((item) => (
      <S.PlanDetailItem key={item} type="secondary">
        <Space direction="horizontal">
          <S.CheckOutlined />
          <Text>{item}</Text>
        </Space>
      </S.PlanDetailItem>
    ));
  };

  const pricingTrail = selectedPlan.isMonthly ? 'per month' : 'per year';

  if (isFetchingSubscription) {
    return <Loader height="100%" title="Checking subscription..." />;
  }

  return (
    <S.Card>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <S.MainLayout>
            <Title level={4}>Review and Pay</Title>
            <S.Section>
              <PlanSelector setSelectedPlan={setSelectedPlan} />
            </S.Section>
            <S.Section>
              <SelectPaymentComponent
                cards={paymentProfiles}
                currentCard={currentCard}
                setNewCard={setCurrentCard}
                onAddCard={refetch}
                stripePaymentProfiles={[]}
                isStripeCustomer={false}
              />
            </S.Section>
          </S.MainLayout>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <S.MainLayout>
            <Title level={5}>Summary</Title>
            <S.SummarySection>
              <Title level={5}>Plan Summary</Title>
              <S.PlanDetailsSection>
                <S.PlanTitleSection>
                  <S.PlanTitle strong>{selectedPlan.name}</S.PlanTitle>
                </S.PlanTitleSection>
                {PlanDetails()}
                <S.PlanDetails>{/* <PlanDetails /> */}</S.PlanDetails>
                {bookCreditsLeft > 0 && (
                  <>
                    <Divider />
                    <S.PlanTitleSection>
                      <S.PlanTitle strong>
                        Current Plan ({currentSubscriptionPlanName}){' '}
                        <Tooltip
                          placement="right"
                          title="Any unused printed book credits will automatically carry over to your next plan"
                        >
                          <QuestionCircleFilled />
                        </Tooltip>
                      </S.PlanTitle>
                    </S.PlanTitleSection>
                    <S.PlanDetails>
                      <S.PlanDetailItem type="secondary">
                        Your remaining {bookCreditsLeft} printed books credits will be carried over
                        to your new plan
                      </S.PlanDetailItem>
                    </S.PlanDetails>
                  </>
                )}
              </S.PlanDetailsSection>
              <S.TotalPaymentSection>
                <S.PlanTitleSection>
                  <S.PlanTitle strong>Total:</S.PlanTitle>
                  <S.TotalPayment>
                    <S.PlanTitle strong>{selectedPlan.price}</S.PlanTitle>
                    <br />
                    <Text type="secondary">{pricingTrail}</Text>
                  </S.TotalPayment>
                </S.PlanTitleSection>
              </S.TotalPaymentSection>
              <S.PayNowButton block onClick={handlePayment} loading={isLoading || isMigrating}>
                Pay Now
              </S.PayNowButton>
              <Tooltip
                open={tooltipOpen}
                title="Please check terms and conditions to proceed"
                placement="leftTop"
              >
                <S.ShakeDiv shaking={shaking}>
                  <Checkbox checked={isTermsChecked} onChange={handleTermsToggle}>
                    By clicking “Pay Now”, I agree to the{' '}
                    <strong>
                      <a
                        href="https://authorify.com/terms-of-use/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms and conditions
                      </a>
                    </strong>{' '}
                    and acknowledge that I am enrolling in a subscription plan and hereby authorize
                    Authorify to charge my payment method on a monthly basis at the then-current
                    list price (plus applicable taxes), unless I choose to cancel.
                  </Checkbox>
                </S.ShakeDiv>
              </Tooltip>
            </S.SummarySection>
          </S.MainLayout>
        </Col>
      </Row>
    </S.Card>
  );
};

export default PlusPlansReviewAndPay;
