import styled from 'styled-components';

const SliderWrapper = styled.div`
  .ant-slider {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .ant-slider-track {
    height: 20px;
    border-radius: 10px;
  }

  .ant-slider-rail {
    height: 20px;
    background-color: #e1e1e1;
    border-radius: 10px;
  }
  .ant-slider-dot {
    display: none;
  }

  .ant-slider-handle,
  .ant-slider-dot {
    height: 30px;
    width: 30px;
  }

  .ant-slider-mark-text {
    top: 15px;
  }

  .ant-slider-dot {
    top: -6px;
  }
`;

export default {
  SliderWrapper,
};
