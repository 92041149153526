import { get } from 'lodash-es';
import join from 'url-join';

import { getPayload } from 'modules/api';
import env from 'environments';
import { getOnlyNumbers } from 'modules/common/utils';

const getAvatarPayload = (payload) => {
  const avatar = get(payload, 'afy_customer_profile_image_url');

  if (!avatar) {
    return '';
  }

  if (avatar.startsWith('http')) {
    return avatar;
  }

  return join(env.BASE_AUTH_URL_STATIC_FILES, avatar);
};

export const getPhonePayload = (payload) => {
  const phone = get(payload, 'phone', '');
  const onlyNumbers = getOnlyNumbers(phone);

  return onlyNumbers;
};

export const getProfilePayload = (response) => {
  const payload = getPayload(response);

  const avatar = getAvatarPayload(payload);
  const city = get(payload, 'city', '');
  const country = get(payload, 'country', '');
  const email = get(payload, 'email', '');
  const firstName = get(payload, 'firstname', '');
  const isNewUser = get(payload, 'isNewUser', true);
  const lastName = get(payload, 'lastname', '');
  const phone = getPhonePayload(payload);
  const state = get(payload, 'state', '');
  const zip = get(payload, 'zip', '');

  const profile = {
    city,
    country,
    email,
    firstName,
    isNewUser,
    lastName,
    phone,
    state,
    zip,
  };

  return { profile, avatar };
};
