import { getInstance } from './instance';

const instance = getInstance();

export const getCustomerSubscription = () => {
  const url = '/subscriptions/find-one';

  try {
    return instance
      .get(url)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  } catch (e) {
    return Promise.reject(e);
  }
};
