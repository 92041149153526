import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { DashboardTemplate } from 'modules/v2/templates';
import { exportCampaignMetrics, getCampaignMetrics } from 'modules/api';
import { DatePicker, Input, SecondaryButton, Select } from 'modules/v2/common/components';
import { formatDateTime2, formatDateTime3, notification } from 'modules/common/utils';
import Table from 'modules/v2/common/components/Table';
import { getItem } from 'modules/v2/utils/legacy';
import { validateEmail } from 'modules/v2/pages/CampaignMetrics/validation';
import environments from 'environments';

import { Box, TextInput } from 'v2/common/AtomicDesign/atoms';

import RateCards from './sections/RateCards';
import Chart from './sections/Chart';
import SelectedMetrics from './components/SelectedMetrics';
import {
  csvDownloader,
  formatCampaignMetrics,
  metricsSearchOptions,
  populateChartData,
  sortNumericValues,
  sortPeriod,
} from './utils';
import S from './styles';

const CampaignMetrics = () => {
  const queryClient = useQueryClient();

  const userData = JSON.parse(getItem('userData'));
  const [dateRange, setDateRange] = useState([
    moment(new Date()).add(-7, 'days'),
    moment(new Date()),
  ]);

  const [textSearch, setTextSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [lastSortValue, setLastSortValue] = useState(undefined);
  const [page, setPage] = useState(1);
  const [selectedMetrics, setSelectedMetrics] = useState(['Send', 'Delivery', 'Bounce']);
  const [exportData, setExportData] = useState([]);
  const [exportRecipient, setExportRecipient] = useState(userData?.email);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const perPage = 15;

  const { isLoading, data: metricsData } = useQuery(
    ['campaignMetrics', dateRange, sortBy, perPage, textSearch],
    () =>
      getCampaignMetrics({
        start: dateRange[0],
        end: dateRange[1],
        sortBy,
        textSearch,
        perPage,
      }),
  );

  const campaignsPagination = metricsData?.meta || {};
  const { total, lastPage } = campaignsPagination;
  const pageChange = (newpage) => {
    setPage(newpage);
  };

  const pagination = {
    defaultCurrent: page,
    pageSize: perPage,
    total,
    lastPage,
    currentPage: page,
    onChange: pageChange,
  };

  const { mutateAsync: exportMetrics, isLoading: isExporting } = useMutation(
    exportCampaignMetrics,
    {
      onSuccess: () => {
        notification.success({
          description: 'Export sent successfully!',
        });
      },
    },
  );

  const handleTableChange = (filters, sorter) => {
    const { order, field } = sorter;
    if (!field) return;
    setLastSortValue(order);

    if (lastSortValue === 'descend') {
      setSortBy('');
      queryClient.invalidateQueries(['campaignMetrics', dateRange, sortBy, perPage, textSearch]);
      return;
    }

    const sortData = {};
    sortData.key = field;
    sortData.value = order === 'ascend' ? 1 : -1;

    setSortBy(`${sortData.key}:${sortData.value}`);
    queryClient.invalidateQueries(['campaignMetrics', dateRange, sortBy, perPage, textSearch]);
  };

  const chartData = [];
  const chartColumnData = [];
  const chartLineData = [];
  const tableData = [];

  if (!isLoading && metricsData?.data) {
    metricsData.data.forEach(({ metrics, campaign, metricsMeta }, metricIndex) => {
      if (!metrics) return;

      metrics.forEach(({ meta, date }) => {
        if (!meta || !date) return;

        Object.entries(meta).forEach(([key, value]) => {
          if (!selectedMetrics.includes(key)) return;

          if (key === 'Send') {
            const chartColumnItem = {
              period: formatDateTime2(date),
              Send: meta.Send || 0,
            };
            chartColumnData.push(chartColumnItem);
            return;
          }

          const chartLineItem = {
            name: key,
            count: value,
            period: formatDateTime2(date),
          };
          chartLineData.push(chartLineItem);
        });
      });

      const tableItem = {};
      Object.entries(campaign).forEach(([key, value]) => {
        tableItem[key] = value;
      });

      Object.entries(metricsMeta).forEach(([key, value]) => {
        tableItem[key] = value;
      });
      tableItem.key = metricIndex;

      tableData.push(tableItem);
    });

    chartColumnData.sort(sortPeriod);
    chartLineData.sort(sortPeriod);

    chartData.push(populateChartData([chartColumnData, chartLineData], dateRange, selectedMetrics));
  }

  const handleTextSearchChange = (e) => {
    setTextSearch(e?.target?.value);
    queryClient.invalidateQueries(['campaignMetrics', dateRange, sortBy, perPage, textSearch]);
  };

  const showChart = () => {
    if (isLoading) {
      return <S.SkeletonImage active />;
    }
    return <Chart chartData={chartData} dateRange={dateRange} />;
  };

  const handleSelectMetricsChange = (values) => {
    const containsSendValue = values.includes('Send');
    setSelectedMetrics(containsSendValue ? values : [values, 'Send']);
  };

  const handleSingleExportButton = async () => {
    const exportKey = exportData[0];
    const data = metricsData.data[exportKey.key];
    const formattedData = formatCampaignMetrics(data);
    if (!formattedData.length) {
      return notification.warning({
        message: 'No Data',
        description: 'There is no data to export',
      });
    }
    const downloaded = await csvDownloader(formattedData, `campaign-metrics-${Date.now()}`, [
      'Date Created',
      'Segments',
    ]);
    if (downloaded) {
      return notification.success({
        description: 'Campaign metrics exported successfully',
      });
    }
    return notification.error({
      description: 'Failed to receive your emails',
    });
  };

  const handleMultipleExportButton = async () => {
    const campaignIds = [];
    exportData.forEach((item) => {
      campaignIds.push(item.id);
    });
    exportMetrics({
      start: dateRange[0],
      end: dateRange[1],
      campaignIds,
      email: exportRecipient,
      bucket: environments.DS_BUCKET,
    });
  };

  const handleEmailChanged = (e) => {
    const email = e.target.value;
    const isValid = validateEmail(email);
    setIsEmailValid(isValid);
    setExportRecipient(email);
  };

  const exportMenu = [
    {
      key: '1',
      disabled: true,
      label: (
        <S.ExportDialog>
          <S.ExportTitle level={5}>Export file is too large!</S.ExportTitle>
          <S.ExportDescription type="secondary">
            Once export is complete we will send file to:
          </S.ExportDescription>
          <Input
            defaultValue={userData.email}
            onChange={handleEmailChanged}
            value={exportRecipient}
            type="email"
            status={!isEmailValid && 'error'}
          />
          <SecondaryButton loading={isExporting} block onClick={handleMultipleExportButton}>
            Export CSV
          </SecondaryButton>
        </S.ExportDialog>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setExportData(selectedRows);
    },
  };

  const tableColumns = [
    {
      title: 'Campaigns',
      dataIndex: 'name',
      key: 'name',
      width: 240,
      sorter: handleTableChange,
      fixed: windowSize > 1200 ? 'left' : false,
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: handleTableChange,
      width: 150,
      render: (createdAt) => <span>{formatDateTime2(createdAt)}</span>,
    },
    {
      title: 'Segments',
      dataIndex: 'segments',
      sorter: (a, b) => a.segments.length - b.segments.length,
      key: 'segments',
      width: 243,
      render: (s) => {
        const segments = s.map((segment) => segment.name) || [];
        const maxSegments = 3;

        return segments.length <= 3 ? (
          <span>{segments.join(', ')}</span>
        ) : (
          <div>
            <span>
              {[segments.slice(0, 2), `...(+${+segments.length - maxSegments})`].join(', ')}
              {/* {[segments.slice(0, 2), `...(+${+segments.length - maxSegments})`].join(', ')} */}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Sent',
      dataIndex: 'Send',
      key: 'Send',
      width: 100,
      sorter: (a, b) => sortNumericValues(a, b, 'Send'),
      render: (Send) => <span className="font-bold text-primary-500">{Send || 0}</span>,
    },
    {
      title: 'Delivered',
      dataIndex: 'Delivery',
      key: 'Delivery',
      width: 127,
      sorter: (a, b) => sortNumericValues(a, b, 'Delivery'),
      render: (Delivery) => <span className="font-bold text-blue-400">{Delivery || 0}</span>,
    },
    {
      title: 'Bounce rate',
      dataIndex: 'Bounce',
      key: 'Bounce',
      width: 220,
      sorter: (a, b) => sortNumericValues(a, b, 'Bounce'),
      render: (Bounce) => <span className="font-bold text-yellow-500">{Bounce || 0}</span>,
    },
    {
      title: 'Unsubscribed',
      dataIndex: 'Unsubscribed',
      key: 'Unsubscribed',
      width: 205,
      sorter: (a, b) => sortNumericValues(a, b, 'Unsubscribed'),
      render: (Unsubscribed) => (
        <span className="font-bold text-error-500">{Unsubscribed || 0}</span>
      ),
    },
  ];

  return (
    <DashboardTemplate contentTitle="Campaign Metrics" hasSidebar>
      <div className="flex flex-col gap-[22px]">
        <Box>
          <div className="px-6 py-[22px] border-bottom border-neutral-200">
            <DatePicker
              value={dateRange}
              isDateRange
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              format="MMM DD, YYYY"
              disabled={isLoading}
              onChange={(value) => {
                setDateRange(value);
              }}
              size="large"
            />
          </div>
          <RateCards campaignMetricsData={metricsData} isLoading={isLoading} />
        </Box>
        <Box>
          <div className="p-6 border-bottom border-neutral-200">
            <span className="font-semibold mr-6">Metrics</span>
            <Select
              mode="multiple"
              value={selectedMetrics}
              onChange={handleSelectMetricsChange}
              style={{ minWidth: '200px' }}
            >
              {metricsSearchOptions.map((option) => {
                return (
                  <Select.Option
                    key={option.value}
                    value={option.value}
                    disabled={option.value === 'Send'}
                  >
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div className="border-t border-neutral-200 p-6">
            <div className="flex gap-2 flex-wrap justify-center mb-2">
              <SelectedMetrics items={selectedMetrics} />
            </div>
            <S.ChartContainer>{showChart()}</S.ChartContainer>
          </div>
        </Box>
        <Box>
          <div className="flex items-center justify-between flex-wrap px-6 py-[22px] max-[700px]:justify-center">
            <div className="font-semibold max-[700px]:mb-2">Campaign details</div>
            <div className="flex items-center gap-2 max-[700px]:flex-col">
              <TextInput
                placeholder="Search for campaign name"
                onChange={handleTextSearchChange}
                className="w-[320px]"
                sizing="md"
                errorMessage="Invalid input"
                color="gray"
              />
              {exportData.length > 1 ? (
                <>
                  <S.DropdownStyle />
                  <S.Dropdown
                    menu={{ items: exportMenu }}
                    placement="bottomRight"
                    trigger={['click']}
                    overlayClassName="export-dropdown"
                    destroyPopupOnHide
                  >
                    <SecondaryButton>
                      <DownloadOutlined />
                      Export
                    </SecondaryButton>
                  </S.Dropdown>
                </>
              ) : (
                <SecondaryButton
                  onClick={handleSingleExportButton}
                  disabled={exportData.length === 0}
                >
                  <DownloadOutlined />
                  Export
                </SecondaryButton>
              )}
            </div>
          </div>
          <Table
            rowKey="createdAt"
            columns={tableColumns}
            dataSource={tableData}
            loading={isLoading}
            hasData={!isEmpty(tableData)}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            onChange={handleTableChange}
            noContentMessage={`You did not send any emails from ${formatDateTime3(
              dateRange[0],
            )} - ${formatDateTime2(dateRange[1])}. Please select a different time frame.`}
            pagination={pagination}
            scroll={{ x: 'max-content' }}
          />
        </Box>
      </div>
    </DashboardTemplate>
  );
};

export default CampaignMetrics;
