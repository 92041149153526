import { Radio } from 'antd';
import styled, { css } from 'styled-components';
import { PrimaryButton } from 'modules/common/components/UIComponents';
import { Form as BaseForm, Field } from 'formik';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';
import { device } from 'modules/common/theme/mediaqueries';
import {
  FieldGroup,
  FieldControl,
  PageTitle as UIPageTitle,
} from 'modules/onboard/components/UIComponents';

const PageTitle = styled(UIPageTitle)`
  margin-top: 20px !important;

  @media ${device.tabletS} {
    padding: 0;
    margin-right: 20px;
    font-size: 24px !important;
  }
`;

const Form = styled(BaseForm)`
  display: block;
`;

const InfoText = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${color.Body};
  padding: 0 15px 15px;
  display: block;
  text-align: center;
  margin-top: 20px;
  opacity: 0.6;

  @media ${device.tabletS} {
    font-size: 14px;
    width: 100%;
  }
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['$hasError'].includes(prop),
})`
  display: flex;
  border: 1px solid ${color.gray200};

  .ant-select-selector {
    height: 45px !important;
  }

  .ant-select-selection-search-input,
  .ant-select-selection-placeholder {
    line-height: 45px !important;
  }

  ${(props) =>
    props.$hasError &&
    css`
      border: 1px solid #f27575;
    `}
`;

const FormTitle = styled.h4`
  padding: 8px 15px;
  font-size: 25px;
  font-weight: 400;

  @media ${device.tabletS} {
    text-align: center;
    font-size: 16px !important;
  }
`;

const BlockForm = styled.div`
  padding: 15px;
`;

const BlockFormCard = styled.div`
  padding: 0 15px;

  @media ${device.tabletS} {
    display: flex;
    flex-direction: column;
  }
`;

const SubTitle = styled.h4`
  font-size: 18px;
  margin-bottom: 20px !important;
`;

const CardWrapper = styled.div`
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  padding: 13px;
`;

const CreditCardWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const CreditCardInput = styled.div`
  width: 100%;
  flex: 1;
  height: 40px;
  font-family: ${font.RobotoFont};
  color: ${color.Black};

  iframe {
    width: 100% !important;
  }
`;

const CardFront = styled.div`
  display: flex;
  flex-flow: column;
`;

const CardBack = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
`;

const SubLabel = styled.span`
  content: '\a ';
  white-space: pre;
  display: block;
  text-align: left;
  margin-left: 23px;
  font-size: 12px;
  letter-spacing: 0px;
  color: ${color.LightHeadGray};
`;

const PlaceOrderPrimaryButton = styled(PrimaryButton)`
  margin-top: 20px;
  width: 328px;

  @media ${device.tabletS} {
    margin: 20px auto 0;
  }
`;

const Note = styled.div`
  font-size: 14px;
  color: ${color.Body}
  display: block;
  margin-top: 20px;
`;

const Summary = styled.div`
  padding: 20px;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 5px;
  text-align: center;
`;

Summary.Title = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  margin-bottom: 10px;
`;

Summary.Price = styled.div`
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 10px;
`;

Summary.SubTitle = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 10px;
`;

Summary.Info = styled.div`
  border-radius: 20px;
  background-color: ${color.DefaultBlue};
  color: ${color.White};
  display: inline-block;
  margin: 0 auto;
  padding: 3px 10px;
`;

Summary.Extra = styled.p`
  color: ${color.Black};
  font-size: 16px !important;
  font-weight: 400;
  margin-top: 10px;
  line-height: 24px;
`;

const SummaryHeader = styled.strong`
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;
  display: block;
`;

const ConditionsText = styled.p`
  text-align: center;
  margin-top: 10px;
  color: ${color.OnboardConditionsText};
`;

const CardFieldGroup = styled(FieldGroup)`
  height: 100px;
  padding: 1px; // hack for firefox

  ${FieldControl} {
    padding-bottom: 50px;
  }
`;

const OptionText = styled.span`
  text-transform: capitalize;
`;

export default {
  BlockForm,
  BlockFormCard,
  CardBack,
  CardFront,
  CardWrapper,
  CreditCardInput,
  CreditCardWrapper,
  Form,
  FormTitle,
  FormikField,
  InfoText,
  Summary,
  Note,
  PageTitle,
  SubLabel,
  SubTitle,
  PlaceOrderPrimaryButton,
  ConditionsText,
  SummaryHeader,
  CardFieldGroup,
  OptionText,
};
