import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const ScheduleCallDateWrapBox = styled.div`
  text-align: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-top: 25px;
  }
  button {
    padding: 11px 20px;
    border-radius: 2px;
    background: ${color.PhoneLink};
    border: 1px solid ${color.PhoneLink};
    font-size: 20px;
    color: ${color.White};
    font-weight: normal;
    outline: none;
    cursor: pointer;
    margin-top: 30px;
    height: 48px;
    &:hover {
      background: ${color.ViewBlueActive};
      color: ${color.White};
      border: 1px solid ${color.PhoneLink};
    }
    &:focus {
      background: ${color.ViewBlueActive};
      color: ${color.White};
      border: 1px solid ${color.PhoneLink};
    }
  }
`;
const ScheduleCallDateHeading = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  font-weight: normal;
`;
const ScheduleCallDate = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  font-weight: bold;
  span {
    font-weight: normal;
  }
`;

export default {
  ScheduleCallDateWrapBox,
  ScheduleCallDateHeading,
  ScheduleCallDate,
};
