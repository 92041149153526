import { Space } from 'antd';
import { BasicOfferTemplate } from 'modules/landingPages/templates';
import { CheckIconSuccess } from 'modules/common/components/UIComponents';
import env from 'environments';

import S from './styles';

const Congrats = () => {
  return (
    <BasicOfferTemplate>
      <S.Wrapper>
        <CheckIconSuccess />

        <S.Title>Thank you for using Authorify!</S.Title>
        <S.Text>Please let us know if we can do anything else for you!</S.Text>

        <S.ContactWrapper>
          <S.Text>Contact our support team at:</S.Text>
          <S.Text>
            {env.SUPPORT_PHONE} <br />
            {env.SUPPORT_EMAIL}
          </S.Text>
        </S.ContactWrapper>
      </S.Wrapper>
    </BasicOfferTemplate>
  );
};

export default Congrats;
