import { combineReducers } from 'redux';

import { CLEAR_DRAFT_EDITOR } from 'modules/v2/editor/store/constants';
import initialState from './initialState';

import book from './book';
import draft from './draft';
import loadingElements from './loadingElements';
import preview from './preview';
import reset from './reset';
import resetSection from './resetSection';
import save from './save';
import toolbar from './toolbar';
import user from './user';

const combined = combineReducers({
  book,
  draft,
  loadingElements,
  preview,
  reset,
  resetSection,
  save,
  toolbar,
  user,
});

const reducer = (state = initialState, action) => {
  if (action.type === CLEAR_DRAFT_EDITOR) {
    return combined({ ...initialState }, action);
  }

  return combined(state, action);
};

export default reducer;
