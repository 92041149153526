import { Input } from 'antd';
import styled, { css } from 'styled-components';
import * as color from 'modules/common/theme/color';

const Form = styled.form`
  text-align: left;
`;

const inputStyle = css`
  height: 45px;
  font-weight: 500;
  width: 100%;
  display: block;
  border: ${({ error }) =>
    error
      ? `border: 1px solid ${color.ErrorWarningBorder} !important`
      : `1px solid ${color.GreyFormBorder}`};
  border-radius: 3px;
  background-color: ${color.White};
  padding: 0 15px;
  font-size: 16px;
  line-height: 22px;
  color: ${color.GreySelectDd};
`;

Form.Row = styled.div`
  margin: 10px 0;

  :first-of-type {
    margin-top: 0;
  }
`;

Form.Input = styled(Input)`
  ${inputStyle}
`;

export default {
  Form,
};
