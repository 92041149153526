import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const OptionImage = styled.img`
  object-fit: cover;
  width: 100%;
  margin: 20px 0px 10px 0px;
  border: 1px solid
    ${(props) => (props.isSelected ? color.SideBarActiveText : color.GreyFormBorder)};
  cursor: pointer;
`;

const OptionsWrapper = styled.div`
  display: flex;
`;

const Option = styled.div`
  text-align: center;
  margin-bottom: 20px;
  flex: 1;
  width: 25%;
  margin-right: 20px;
  overflow: hidden;
  &:last-child {
    margin-right: 0px;
  }
`;

const SelectionArea = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: baseline;
  color: ${(props) => (props.isSelected ? color.RadioButtonCheckedColor : color.Black)};

  .ant-radio-checked .ant-radio-inner {
    border-color: ${color.RadioButtonCheckedColor};
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${color.RadioButtonCheckedColor};
  }

  &:hover {
    color: ${color.SkyBlue};
    .ant-radio .ant-radio-inner {
      border-color: ${color.SkyBlue};
    }
  }
`;

export default {
  SelectionArea,
  Option,
  OptionImage,
  OptionsWrapper,
};
