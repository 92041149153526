import { PlansProvider } from 'modules/v2/pages/Plans/contexts/UpgradePlan';

import { PaymentPlans } from './components/PaymentPlans/index';

function Plans() {
  return (
    <PlansProvider>
      <PaymentPlans />
    </PlansProvider>
  );
}

export default Plans;
