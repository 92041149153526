//

import S from './styles';

const Tooltip = ({ children, title, ...props }) => (
  <S.Tooltip {...props} getPopupContainer={(triggerNode) => triggerNode} title={title}>
    {children}
  </S.Tooltip>
);

export default Tooltip;
