import { useEffect } from 'react';
import classNames from 'classnames';
import { SubMenuProps, MenuItemProps } from './types';

const SubMenu = ({ items, changeActiveItem }: SubMenuProps) => {
  const handleItemClick = (id: string) => {
    changeActiveItem(id);
  };

  return (
    <div className="w-full overflow-x-auto flex border-b-[1px] border-neutral-200 gap-8 mb-[22px]">
      {items.map((item, index) => (
        <div
          key={item.id}
          className={classNames(
            item.active ? 'border-primary-500 border-b-2 text-neutral-800' : 'border-0',
            'pb-4 text-sm whitespace-nowrap cursor-pointer text-neutral-600',
            'font-semibold',
          )}
          onClick={() => handleItemClick(item.id)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default SubMenu;
