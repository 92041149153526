import { currentYear } from 'modules/common/utils';

import S from './styles';

const Footer = () => (
  <S.FooterContainer>
    <S.FooterWrapper>
      Authorify © {currentYear} - All Rights Reserved <br />
      3500 Beachwood Court #203 Jacksonville, FL 32216 <br />
      <a href="https://authorify.com/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>{' '}
      |{' '}
      <a href="https://authorify.com/terms" target="_blank" rel="noreferrer">
        Terms Of Use
      </a>
    </S.FooterWrapper>
  </S.FooterContainer>
);

export default Footer;
