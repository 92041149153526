import { GET_CATEGORIES, SELECT_CATEGORY } from 'modules/v2/store/constants';

export const getCategories = () => ({
  type: GET_CATEGORIES,
});

export const selectCategory = (categoryId) => ({
  type: SELECT_CATEGORY,
  payload: {
    categoryId,
  },
});
