import { get } from 'lodash-es';
import { STATUS_SUCCESS, STATUS_ERROR, STATUS_LOADING } from 'store/status';

import {
  GET_DRAFT_BY_ID,
  UPDATE_PREFERENCES,
  UPDATE_PREFERENCE_BY_KEY,
  UPDATE_PREFERENCE_STATUS_BY_KEY,
  UPDATE_PREFERENCE_BY_ELEMENT_ID,
  REORDER_SECTION,
  ACTIVATE_SECTION,
  DEACTIVATE_SECTION,
  UPDATE_COVER_IMAGE,
  UPDATE_COVER_ELEMENT,
  UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
  UPDATE_COVER_ACTIVE_TEMPLATE,
  UPDATE_CONTENT_ELEMENT,
  UPDATE_CONTENT_ACTIVE_TEMPLATE,
} from 'modules/editor/store/constants';
import { draft as initialState } from 'modules/editor/store/reducers/initialState';

import sectionsReducer from './sections';
import preferencesReducer from './preferences';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PREFERENCES:
    case UPDATE_PREFERENCE_BY_KEY:
    case UPDATE_PREFERENCE_STATUS_BY_KEY:
    case UPDATE_PREFERENCE_BY_ELEMENT_ID: {
      const preferences = get(state.data, 'preferences', {});

      const data = {
        ...state.data,
        preferences: preferencesReducer(preferences, action),
      };

      return {
        ...state,
        data,
      };
    }

    case REORDER_SECTION:
    case ACTIVATE_SECTION:
    case DEACTIVATE_SECTION:
    case UPDATE_COVER_IMAGE:
    case UPDATE_COVER_ELEMENT:
    case UPDATE_COVER_ELEMENTS_BY_PREFERENCES:
    case UPDATE_COVER_ACTIVE_TEMPLATE:
    case UPDATE_CONTENT_ELEMENT:
    case UPDATE_CONTENT_ACTIVE_TEMPLATE: {
      const sections = get(state.data, 'sections', []);

      const data = {
        ...state.data,
        sections: sectionsReducer(sections, action),
      };

      return {
        ...state,
        data,
      };
    }

    case `${GET_DRAFT_BY_ID}_REQUESTED`: {
      return {
        ...state,
        data: null,
        status: STATUS_LOADING,
      };
    }

    case `${GET_DRAFT_BY_ID}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${GET_DRAFT_BY_ID}_SUCCEEDED`: {
      const data = get(action, ['payload', 'draft'], null);

      return {
        ...state,
        data,
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
};

export default reducer;
