export const PREVIEW_STATUS_EMPTY = 'empty';
export const PREVIEW_STATUS_PROCESSING = 'processing';
export const PREVIEW_STATUS_FAILED = 'failed';
export const PREVIEW_STATUS_READY = 'ready';
export const PREVIEW_STATUS_MODIFIED = 'modified';

export const isPreviewStatusEmpty = (status) => status === PREVIEW_STATUS_EMPTY;
export const isPreviewStatusFailed = (status) => status === PREVIEW_STATUS_FAILED;
export const isPreviewStatusModified = (status) => status === PREVIEW_STATUS_MODIFIED;
export const isPreviewStatusProcessing = (status) => status === PREVIEW_STATUS_PROCESSING;
export const isPreviewStatusReady = (status) => status === PREVIEW_STATUS_READY;
