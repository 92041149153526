import * as Yup from 'yup';

const password = Yup.string()
  .trim()
  .matches(/[a-z]+/, ' ')
  .matches(/[A-Z]+/, ' ')
  .matches(/[^\w\s]/, ' ')
  .matches(/\d/, ' ')
  .min(8, ' ')
  .required('Password is required');

export default password;
