const OrderTag = ({ className, children, ...props }) => {
  return (
    <span
      className={`whitespace-nowrap inline-block text-sm px-[6px] py-[6px] leading-none rounded-md border-[1px] font-figtree ${className}`}
      style={{
        flexGrow: 1,
        maxWidth: 'fit-content',
      }}
      {...props}
    >
      {children}
    </span>
  );
};

export default OrderTag;
