import { call, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';

import { resource, getError, uploadIntakeFormHeadshot } from 'modules/api';

import { UPLOAD_INTAKE_FORM_HEADSHOT } from 'modules/dashboard/store/constants';
import { formatImageUrl } from 'modules/dashboard/utils';

import { getErrorMessage } from './utils';

export function* onIntakeFormUpload({ payload: meta }) {
  try {
    const data = get(meta, 'file');
    const request = resource(
      UPLOAD_INTAKE_FORM_HEADSHOT,
      uploadIntakeFormHeadshot,
      formatImageUrl,
      getErrorMessage,
    );
    const payload = yield call(request, { data });

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);

    return payload;
  }
}

export function* watchIntakeFormUpload() {
  yield takeEvery(UPLOAD_INTAKE_FORM_HEADSHOT, onIntakeFormUpload);
}
