import React from 'react';
import { Sidebar } from 'modules/common/components';
import { isDentistCustomer } from 'modules/dashboard/utils';
import DashboardTemplate from 'modules/dashboard/templates/DashboardTemplate';
import {
  getRouteProfile,
  getRouteShippingAddress,
  getRouteChangePassword,
  getRouteProfilePlans,
  // getRoutePageProfile,
} from 'modules/dashboard/routes/navigation';

import S from './styles';

const { Item } = Sidebar;

const ProfileTemplate = ({
  avatar,
  children,
  email,
  title,
  username,
  onLogout,
  sidebarWidth,
  noPaddingLeft,
  noPaddingRight,
}) => {
  const isDentist = isDentistCustomer();
  return (
    <DashboardTemplate
      avatar={avatar}
      email={email}
      title={title}
      username={username}
      onLogout={onLogout}
      sidebarWidth={sidebarWidth}
      noPaddingLeft={noPaddingLeft}
      noPaddingRight={noPaddingRight}
    >
      <S.Wrapper>
        <Sidebar style={{ width: '350px' }}>
          <Item
            exact
            title="Account Settings"
            description="Manage your personal data"
            to={getRouteProfile()}
          />
          {/* pages and links below will be implemented in scope of another task */}
          <Item
            title="Shipping Address"
            description="Add or remove an address"
            to={getRouteShippingAddress()}
          />
          <Item
            title="Password Update"
            description="Change your account password"
            to={getRouteChangePassword()}
          />
          {!isDentist && (
            <Item
              title="Plan Settings"
              description="Manage your subscription plan"
              to={getRouteProfilePlans()}
            />
          )}
          {/* <Item
          title="Landing Page Profile"
          description="Manage data to display in the footer of your landing pages"
          to={getRoutePageProfile()}
        /> */}
        </Sidebar>
        <S.ContentWrapper>{children}</S.ContentWrapper>
      </S.Wrapper>
    </DashboardTemplate>
  );
};

export default React.memo(ProfileTemplate);
