import { Modal, Space, Typography, Button } from 'antd';
import environments from 'environments';
import S from '../../styles';

const { Title, Text } = Typography;
const NeedHelp = ({ open, toggleOpen }) => {
  const showIntercom = () => {
    const intercom = window?.Intercom;
    if (intercom) intercom('show');
  };

  return (
    <>
      {open && <S.ResetModal />}
      <Modal
        open={open}
        title={
          <Space direction="vertical">
            <Title level={4}>Need help with anything?</Title>
          </Space>
        }
        onCancel={toggleOpen}
        footer={null}
        width={600}
      >
        <S.Section>
          <Space direction="vertical">
            <Text strong>Call us</Text>
            <Text>
              The best way to get in contact with us is through our dedicated customer support phone
              number at <Text strong>{environments.SUPPORT_PHONE}</Text> .
            </Text>
          </Space>
        </S.Section>
        <S.Section>
          <Space direction="vertical">
            <Text strong>Chat with a LIVE customer support</Text>
            <Text>
              You can also contact us with our LIVE chat service. Our customer support team will be
              happy to assist you.
            </Text>
            <Button size="large" onClick={showIntercom}>
              Begin Chat
            </Button>
          </Space>
        </S.Section>
        <S.Section>
          <Text strong>The best time to contact us is Monday- Friday, 9:00AM – 6:00PM EST</Text>
        </S.Section>
        <S.FooterSection>
          <S.OkayButton onClick={toggleOpen}>Okay</S.OkayButton>
        </S.FooterSection>
      </Modal>
    </>
  );
};

export default NeedHelp;
