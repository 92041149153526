import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  subject: Yup.string().trim().required('Subject is required'),
  allSegments: Yup.boolean().required(),
  segments: Yup.array().when('allSegments', {
    is: false,
    then: Yup.array()
      .of(Yup.number().min(1))
      .required('Please select at least one segment')
      .nullable(),
  }),
});

export default validationSchema;
