export const RoundArrowDown = ({
  width = '20',
  height = '20',
  fill = '#252525',
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66666 9.99996C1.66666 14.6023 5.39762 18.3333 9.99999 18.3333C14.6024 18.3333 18.3333 14.6023 18.3333 9.99996C18.3333 5.39759 14.6024 1.66663 9.99999 1.66663C5.39762 1.66663 1.66666 5.39759 1.66666 9.99996ZM12.9419 10.3913C13.186 10.6354 13.186 11.0312 12.9419 11.2752L10.4419 13.7752C10.1979 14.0193 9.80212 14.0193 9.55805 13.7752L7.05805 11.2752C6.81397 11.0312 6.81397 10.6354 7.05805 10.3913C7.30213 10.1473 7.69785 10.1473 7.94193 10.3913L9.37499 11.8244V6.66663C9.37499 6.32145 9.65481 6.04163 9.99999 6.04163C10.3452 6.04163 10.625 6.32145 10.625 6.66663V11.8244L12.058 10.3913C12.3021 10.1473 12.6979 10.1473 12.9419 10.3913Z"
        fill={fill}
      />
    </svg>
  );
};
