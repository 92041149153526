import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const RecipientsContainer = styled.div`
  & > * {
    margin: 14px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 22px;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
`;

const ShowSelectedCheckbox = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  padding: 22px 24px;
  border-bottom: 1px solid ${color.neutral200};

  & > div {
    margin-right: 16px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }

  & > div:nth-child(2) {
    margin-left: auto;
  }

  strong {
    margin-right: 4px;
  }
`;

const LeadsText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${color.neutral800};
`;

const LeadsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 22px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 22px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Select = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const PaginationInfo = styled.span`
  font-size: 14px;
  color: #666;
`;

const PaginationControls = styled.div`
  display: flex;
  gap: 5px;

  button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

const SaveButton = styled.button`
  display: flex;
  padding: 13px 18px;
  justify-content: center;
  align-items: center;

  background-color: #4cc9e1;
  color: #252525;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
`;

export default {
  RecipientsContainer,
  Title,
  Subtitle,
  SearchContainer,
  SearchInput,
  ShowSelectedCheckbox,
  Summary,
  LeadsContainer,
  Footer,
  Pagination,
  Select,
  PaginationInfo,
  PaginationControls,
  SaveButton,
  Checkbox,
  LeadsText,
  CheckboxWrapper,
};
