import styled from 'styled-components';
import { PrimaryButton } from 'modules/common/components';

const Container = styled.div`
  text-align: center;
  max-width: 60%;
`;

const Button = styled(PrimaryButton)`
  margin-top: 30px;
`;

const LinkText = styled.p`
  padding-top: 40px;
`;

export default {
  Container,
  Button,
  LinkText,
};
