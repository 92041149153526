import { call, put, select, takeEvery } from 'redux-saga/effects';
import { startsWith } from 'lodash-es';

import env from 'environments';

import { copyImageToDraft, resource, getError } from 'modules/api';
import { COVER_IMAGE_GROUP_LOGO } from 'modules/editor/utils/constants';
import { GENERATE_ELEMENT_IMAGE_URL, REPLACE_COVER_IMAGE } from 'modules/editor/store/constants';
import {
  addLoadingCoverElements,
  draftIdSelector,
  coverElementByIdSelector,
  removeLoadingCoverElements,
  updateCoverImage,
} from 'modules/editor/store';

export function* onReplaceCoverImage({ payload: meta }) {
  try {
    const { templateId, elementId, value, group } = meta;

    const selector = coverElementByIdSelector(elementId);
    const { id } = yield select(selector);
    const draftId = yield select(draftIdSelector);

    yield put(addLoadingCoverElements(id));

    let newUrl = value;
    let prefix = '';

    if (group !== COVER_IMAGE_GROUP_LOGO) {
      const request = resource(GENERATE_ELEMENT_IMAGE_URL, copyImageToDraft);
      const payload = yield call(request, { draftId, id, image: value });

      // The prefix should be added to image URLs, except the logo group
      prefix = env.BASE_AUTH_URL_STATIC_FILES;
      newUrl = payload.url;
    }

    if (newUrl) {
      const defaultValue = startsWith(newUrl, 'http') ? newUrl : `${prefix}${newUrl}`;
      const action = updateCoverImage(templateId, elementId, defaultValue);

      yield put(action);
    }

    yield put(removeLoadingCoverElements(id));

    return {
      ...meta,
      url: newUrl,
    };
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchReplaceCoverImage() {
  yield takeEvery(REPLACE_COVER_IMAGE, onReplaceCoverImage);
}
