import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

const importLeadSchema = Yup.object({
  segments: validation.campaignSegments,
  invalidRows: Yup.array().of(
    Yup.object({
      firstName: validation.firstName,
      lastName: validation.lastName,
      email: validation.email,
      phone: validation.phone,
    }),
  ),
});

export default importLeadSchema;
