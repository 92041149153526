import { useEffect, useState } from 'react';
import { Radio } from 'antd';
import AddPaymentProfile from 'modules/v2/components/AddPaymentProfile';
import CardItemStripe from 'modules/v2/components/Payments/CardItemStripe';
import CardItem from '../CardItem';
import S from './styles';

export const SelectPaymentComponent = ({
  currentCard,
  cards,
  setNewCard,
  onAddCard,
  stripePaymentProfiles,
  isStripeCustomer,
}) => {
  const [value, setValue] = useState(currentCard?.id);

  const onCardChange = (e) => {
    const selectedItem = cards?.find((item) => item.id === e.target.value);
    if (selectedItem) {
      setNewCard(selectedItem);
    }
  };

  useEffect(() => {
    setValue(currentCard?.id);
  }, [currentCard?.id]);

  return (
    <S.Container>
      {!isStripeCustomer ? (
        <>
          <S.ModalDescription level={5}>Select Payment Method</S.ModalDescription>
          <Radio.Group onChange={onCardChange} value={value}>
            {cards.map((creditData) => (
              <CardItem
                key={creditData?.id}
                creditData={creditData}
                selectedId={currentCard?.id}
                isActive={value === creditData?.id}
              />
            ))}
          </Radio.Group>
          <AddPaymentProfile refetchOnSuccess={onAddCard} />
        </>
      ) : (
        <Radio.Group onChange={onCardChange} value={stripePaymentProfiles[0].id}>
          {stripePaymentProfiles.map((creditData) => (
            <CardItemStripe key={creditData.id} creditData={creditData} isActive />
          ))}
        </Radio.Group>
      )}
    </S.Container>
  );
};
