import styled from 'styled-components';
import { White, Black, GreyPlatinum, SecondaryColor } from 'modules/v2/common/theme/color';
import { Tabs } from 'antd';

const Wrapper = styled.div`
  display: flex;
`;

const Tab = styled(Tabs)`
  .ant-tabs-nav-scroll {
    border-radius: 0px 0px 0px 0px !important;
  }

  .ant-tabs-nav-wrap {
    background: ${SecondaryColor};

    .ant-tabs-tab {
      color: ${White};
      border-radius: 0 !important;
      margin-right: 0 !important;
      background: inherit !important;
      border: 0px !important;
    }

    .ant-tabs-tab-active {
      background: ${White} !important;
      color: ${Black} !important;
    }
  }
`;

const { TabPane } = Tabs;

const TabPaneWrapper = styled.div`
  width: 740px;
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  margin: 0px 5px 10px 5px;
  cursor: pointer;
  border: 2px solid ${GreyPlatinum};
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1px;
  background: ${GreyPlatinum};
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 250px;
  margin: auto;
  justify-content: flex-start;
  padding-left: 10px;
`;

const NoData = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  GalleryContainer,
  ImageWrapper,
  NoData,
  Wrapper,
  Tab,
  TabPane,
  TabPaneWrapper,
  UploadedImage,
};
