import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device as BreakPoints } from 'modules/common/theme/mediaqueries';

const Heading = styled.h2`
  font-size: 24px !important;
  font-weight: 600 !important;
  color: ${color.Black};
  letter-spacing: unset !important;
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Roboto' !important;
  padding-left: ${({ paddingLeftReq }) => (paddingLeftReq ? `${paddingLeftReq}px` : 0)};
  @media screen and ${BreakPoints.tabletS} {
    text-align: center;
    font-size: 20px !important;
  }
`;

export { Heading };
