import gql from 'graphql-tag';

export default gql(`
query{
  books:books{
    _id
    name,
    updatedAt,
    createdAt,
    category,
    status
    
 }
 }
 
`);
