import styled, { css } from 'styled-components';
import * as colorTheme from 'modules/common/theme/color';

import { getStyle } from 'modules/editor/utils';

const Panel = styled.div`
  border: 3px solid ${colorTheme.GreyBookBorder};
  transform-origin: top;
  display: flex;
  flex-direction: column;

  ${({ layout }) => {
    const { height, marginTop, marginBottom, marginLeft, marginRight } = getStyle(layout);

    return css`
      min-height: ${height};
      padding-top: ${marginTop};
      padding-left: ${marginLeft};
      padding-right: ${marginRight};
      padding-bottom: ${marginBottom};
    `;
  }}
`;

const Wrapper = styled.div`
  .textfield-wrapper {
    border: 1px dotted grey;
  }

  .textfield-wrapper {
    overflow: hidden;

    ul {
      padding-inline-start: 25px;
    }

    .content-title,
    .title {
      margin: 0 !important;
    }
  }
`;

const Element = styled.div`
  ${({ showGuides, element, isEmpty }) => {
    const {
      order,
      margin,
      padding,
      lineHeight,
      fontFamily,
      fontSize,
      fontWeight,
      textAlign,
      color,
      border,
    } = getStyle(element);

    return css`
      color: ${!isEmpty ? color : 'rgb(145, 151, 163)'};
      order: ${order};
      font-size: ${fontSize};
      margin: ${margin};
      text-align: ${textAlign};
      line-height: ${lineHeight};
      font-family: ${fontFamily};
      font-weight: ${fontWeight};
      padding: ${padding};
      border: ${border};

      ${!showGuides &&
      `
          border-color: transparent;
        `}
    `;
  }}
`;

export default {
  Wrapper,
  Panel,
  Element,
};
