import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

const Title = styled.h5`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  padding-right: 10px;
  padding-bottom: '0px';
  font-weight: bold;
  margin: ${(props) => props.marginLeft ?? '0px'};
`;

const TitleNoPaddingRight = styled(Title)`
  padding-right: 0px;
`;

const TitlePaddingBottom = styled(Title)`
  padding-bottom: 20px;
`;

const Text = styled.p`
  color: ${(props) => (props.color ? props.color : color.Black)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
`;

const TextCursorPointer = styled(Text)`
  cursor: pointer;
`;

const TextWrap = styled.div`
  display: block;
`;

const PageContainer = styled.div`
  display: flex;
  position: relative;
  .ant-menu-submenu-title {
    margin-top: 0;
  }
`;

const TextAreaWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
  align-items: center;
  font-weight: 600;
  color: ${color.Black};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 33px;
    left: 15px;
    border-left: 1px dashed ${color.LightDullGray};
    height: 20px;
  }
  &:last-child:after {
    display: none;
  }
`;

const SubSideBar = styled.div`
  width: 500px;
  background-color: ${color.lightBackground};
  padding: 20px 13px 20px 29px;
  box-shadow: inset 0px 0px 6px ${color.sidebarBoxShadow};
  height: calc(100vh - 96px);
`;

const StepCount = styled.div`
  background-color: ${(props) => props.stepColor};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  color: ${color.White};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`;

const MenuItem = styled.span`
  padding-left: 20px;
`;

export default {
  IconContainer,
  MenuItem,
  PageContainer,
  SubSideBar,
  StepCount,
  Text,
  TextAreaWrap,
  TextWrap,
  TextCursorPointer,
  TitleNoPaddingRight,
  Title,
  TitlePaddingBottom,
};
