import { useContext } from 'react';

import { QuestionCircleFilled } from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { PlansContext } from 'modules/v2/pages/Plans/contexts/UpgradePlan';

import { planHandle } from 'modules/v2/pages/Plans/constants';

import S from '../../styles';

const { Title, Text } = Typography;

export const nonContractedPlanData = [
  {
    key: '1',
    name: (
      <Space direction="horizontal">
        <S.TableText>Licensed Books</S.TableText>
        <Tooltip
          placement="right"
          title="Dive into our meticulously curated collection of licensed books authored by industry experts. When you offer your clients valuable resources through licensed books, you demonstrate your commitment to their success and build trust, making them more likely to choose you as their agent."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Licensed Books',
    digitalPlan: '2',
    premiumPlan: '4',
    platinumPlan: '25',
  },
  {
    key: '2',
    name: (
      <Space direction="horizontal">
        <S.TableText>Digital Books</S.TableText>
        <Tooltip
          placement="right"
          title="You can share and conduct presentations with your digital book with anyone in the world. Allowing you to expand your reach and connect with clients regardless of their location."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Digital Books',
    digitalPlan: '2',
    premiumPlan: '4',
    platinumPlan: '25',
  },

  {
    key: '3',
    name: (
      <Space direction="horizontal">
        <S.TableText>Print Credits</S.TableText>
        <Tooltip
          placement="right"
          title="Our printed books can be customized with your branding elements, including your logo, contact information, and personalized messages.

This creates a powerful branding opportunity, reinforcing your professional image and ensuring that clients remember you long after they've finished reading."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Print Credits',
    digitalPlan: '0',
    premiumPlan: 20,
    platinumPlan: 50,
  },

  {
    key: '4',
    name: (
      <Space direction="horizontal">
        <S.TableText>Lead Capture Websites</S.TableText>
        <Tooltip
          placement="right"
          title="Grow your real estate business with our powerful lead capture sites. Get instant notifications for new leads, enabling you to follow up promptly and secure the deal."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Lead Capture Websites',
    digitalPlan: '2',
    premiumPlan: '4',
    platinumPlan: '25',
  },

  {
    key: '5',
    name: (
      <Space direction="horizontal">
        <S.TableText>Marketing Templates</S.TableText>
        <Tooltip
          placement="right"
          title="Save time and effort while maximizing your reach with our ready-to-use and customizable marketing templates and scripts to boost lead generation.

Our templates are designed to grab attention and convey your expertise, allowing you to effectively communicate your unique value proposition and differentiate yourself as a trusted authority in the real estate market."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Marketing Templates',
    digitalPlan: <S.CheckOutlined />,
    premiumPlan: <S.CheckOutlined />,
    platinumPlan: <S.CheckOutlined />,
  },
  {
    key: '6',
    name: (
      <Space direction="horizontal">
        <S.TableText>Training Site Access</S.TableText>
        <Tooltip
          placement="right"
          title="Designed to supercharge your sales and maximize the potential of your Authorify membership, providing you with the following essential resources: Success Training, Marketing Templates, Live Coaching, and Member Interviews."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Training Site Access',
    digitalPlan: <S.CheckOutlined />,
    premiumPlan: <S.CheckOutlined />,
    platinumPlan: <S.CheckOutlined />,
  },

  {
    key: '7',
    name: (
      <Space direction="horizontal">
        <S.TableText>Weekly Live Coaching</S.TableText>
        <Tooltip
          placement="right"
          title="Benefit from weekly live coaching sessions with industry experts who will guide you through personalized strategies and provide valuable insights. "
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Weekly Live Coaching',
    digitalPlan: <S.CheckOutlined />,
    premiumPlan: <S.CheckOutlined />,
    platinumPlan: <S.CheckOutlined />,
  },
  {
    key: '8',
    name: (
      <Space direction="horizontal">
        <S.TableText>Social Media Training Site</S.TableText>
        <Tooltip
          placement="right"
          title="Harness the power of social media with our training site access, mastering the art of engagement and building a loyal reader community."
        >
          <QuestionCircleFilled />
        </Tooltip>
      </Space>
    ),
    attribute: 'Social Media Training Site',
    digitalPlan: <S.CloseOutlined />,
    premiumPlan: <S.CloseOutlined />,
    platinumPlan: <S.CheckOutlined />,
  },
];

export const TitleTemplate = (isActive, planTitle, description, price, plan, productHandle) => {
  const { setSelectedPlan, toggleReviewAndPayModal, toggleUpgradePlanModal } =
    useContext(PlansContext);
  const handleClick = () => {
    const isMonthly = price.includes('mo');
    const priceOnly = price?.split('/')[0]?.trim();
    const planDetails = {
      plan,
      price: priceOnly,
      productHandle,
      planTitle,
      isMonthly,
    };
    const packageDetails = {};
    nonContractedPlanData.forEach((item) => {
      const { attribute } = item;
      if (attribute === 'Print Credits' && typeof item[plan] === 'number') {
        if (isMonthly) {
          packageDetails[attribute] = `${item[plan]} monthly`;
          return;
        }
        packageDetails[attribute] = `${item[plan] * 12} yearly`;
        return;
      }
      packageDetails[attribute] = item[plan];
    });
    planDetails.packageDetails = packageDetails;
    setSelectedPlan(planDetails);
    toggleReviewAndPayModal();
    toggleUpgradePlanModal();
  };
  return (
    <S.PerPlanBox isActive={isActive}>
      <Title level={4}>{planTitle}</Title>
      <Text type="secondary">{description}</Text>
      <S.PlanPrice strong>{price}</S.PlanPrice>
      <S.UpgradePlanButton block disabled={isActive} onClick={handleClick}>
        {isActive ? 'Current Plan' : 'Upgrade Plan'}
      </S.UpgradePlanButton>
    </S.PerPlanBox>
  );
};

export const renderText = (text, isMonthly = true) => {
  let finalText = text;
  if (typeof text === 'number') {
    if (isMonthly) {
      finalText = `${text} credits per month`;
    } else {
      finalText = `${text * 12} credits per year`;
    }
  }
  return <S.TableText>{finalText}</S.TableText>;
};
export const nonContractedColumnsMonthly = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: '25%',
    render: (text) => <S.TableText>{text}</S.TableText>,
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.DIGITAL_MONTHLY,
        'Digital Plan',
        'Ideal for agents seeking to establish an online presence. A cost-effective solution, marking the initial step to a successful digital journey.',
        '$149 / mo',
        'digitalPlan',
        planHandle.DIGITAL_MONTHLY,
      ),

    dataIndex: 'digitalPlan',
    key: planHandle.DIGITAL_MONTHLY,
    width: '25%',
    render: (text) => renderText(text),
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.PREMIUM_MONTHLY,
        'Premium Plan',
        'A level up, retaining all previous benefits with unique advantages added. Perfect for those wanting to elevate their success further.',
        '$259 / mo',
        'premiumPlan',
        planHandle.PREMIUM_MONTHLY,
      ),

    dataIndex: 'premiumPlan',
    key: planHandle.PREMIUM_MONTHLY,
    width: '25%',
    render: (text) => renderText(text),
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.PLATINUM_MONTHLY,
        'Platinum Plan',
        'Combines everything from previous offerings along with exclusive advantages. For those seeking the ultimate level of services.',
        '$399 / mo',
        'platinumPlan',
        planHandle.PLATINUM_MONTHLY,
      ),

    dataIndex: 'platinumPlan',
    key: planHandle.PLATINUM_MONTHLY,
    width: '25%',
    render: (text) => renderText(text),
  },
];

export const nonContractedColumnsYearly = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: '25%',
    render: (text) => <S.TableText>{text}</S.TableText>,
    fixed: true,
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.DIGITAL_ANNUAL,
        'Digital Plan',
        'Ideal for agents seeking to establish an online presence. A cost-effective solution, marking the initial step to a successful digital journey.',
        '$1490 / year',
        'digitalPlan',
        planHandle.DIGITAL_ANNUAL,
      ),
    dataIndex: 'digitalPlan',
    key: planHandle.DIGITAL_ANNUAL,
    width: '25%',
    render: (text) => renderText(text, false),
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.PREMIUM_ANNUAL,
        'Premium Plan',
        'A level up, retaining all previous benefits with unique advantages added. Perfect for those wanting to elevate their success further.',
        '$2590 / year',
        'premiumPlan',
        planHandle.PREMIUM_ANNUAL,
      ),

    dataIndex: 'premiumPlan',
    key: planHandle.PREMIUM_ANNUAL,
    width: '25%',
    render: (text) => renderText(text, false),
  },
  {
    title: ({ userPlan }) =>
      TitleTemplate(
        userPlan === planHandle.PLATINUM_ANNUAL,
        'Platinum Plan',
        'Combines everything from previous offerings along with exclusive advantages. For those seeking the ultimate level of services.',
        '$3990 / year',
        'platinumPlan',
        planHandle.PLATINUM_ANNUAL,
      ),

    dataIndex: 'platinumPlan',
    key: planHandle.PLATINUM_ANNUAL,
    width: '25%',
    render: (text) => renderText(text, false),
  },
];
