import * as color from 'modules/v2/common/theme/color';
import styled from 'styled-components';

export const Container = styled.div``;
export const IframeBox = styled.iframe`
  width: 100%;
`;
export const Image = styled.img`
  width: 80px;
  height: 80px;
`;
export const FilesPreview = styled.div`
  display: flex;
`;
export const RegectedFiles = styled.div`
  width: 50%;
`;
export const AceptedFiles = styled.div`
  width: 50%;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #aaa;
`;
export const FilesContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
`;
export const DragContainer = styled.div`
  padding: 20px 10px;
  border: 2px dotted #aaa;
  border-radius: 10px;
  background-color: #eee;
  text-align: center;
`;

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const TrashImage = styled.img`
  margin-right: 10px;
  cursor: pointer;
`;

const AvatarImage = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  svg {
    position: relative;
    top: 0px;
  }
  img {
    border-radius: 4px !important;
    width: 100%;
    height: 100%;
  }
`;

const AvatarDescription = styled.div`
  padding: 20px 10px;
  font-size: 16px;
  font-weight: 400;
  .imageSize {
    color: ${color.GrayDisabled};
  }
`;

const DisabledText = styled.span`
  color: ${color.GrayDisabled};
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const EmptyAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 100%;
  height: 114px;
  font-size: 16px;

  padding: 14px 12px;

  border-radius: 4px;
  border: 1px dashed ${color.GrayDividingLine};
  border-width: 2px;

  cursor: pointer;
`;

const AvatarImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 114px;
  font-size: 16px;

  padding: 14px 12px;

  background-color: ${color.White};

  border-radius: 4px;
  border: 1px solid ${color.GrayDividingLine};
`;

export default {
  AvatarContainer,
  AvatarDescription,
  AvatarImage,
  AvatarImageContainer,
  AvatarWrapper,
  EmptyAvatarContainer,
  TrashImage,
  DisabledText,
};
