import { get } from 'lodash-es';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'store/status';

import {
  ADD_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  EDIT_USER_ADDRESS,
  GET_USER_ADDRESSES,
} from 'modules/v2/publish/store/constants';

import { addresses as initialState } from './initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USER_ADDRESSES}_REQUESTED`:
    case `${ADD_USER_ADDRESS}_REQUESTED`:
    case `${EDIT_USER_ADDRESS}_REQUESTED`:
    case `${DELETE_USER_ADDRESS}_REQUESTED`: {
      return {
        ...state,
        status: STATUS_LOADING,
      };
    }

    case `${GET_USER_ADDRESSES}_FAILED`:
    case `${ADD_USER_ADDRESS}_FAILED`:
    case `${EDIT_USER_ADDRESS}_FAILED`:
    case `${DELETE_USER_ADDRESS}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${GET_USER_ADDRESSES}_SUCCEEDED`: {
      const data = get(action, ['payload'], []);

      return {
        ...state,
        data,
        status: STATUS_SUCCESS,
      };
    }

    case `${ADD_USER_ADDRESS}_SUCCEEDED`:
    case `${EDIT_USER_ADDRESS}_SUCCEEDED`:
    case `${DELETE_USER_ADDRESS}_SUCCEEDED`: {
      return {
        ...state,
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
};

export default reducer;
