import { call, takeEvery, select } from 'redux-saga/effects';

import { fetchCoverPreview, resource, getError } from 'modules/api';
import { draftIdSelector } from 'modules/editor/store';

import { GET_COVER_PREVIEW } from 'modules/editor/store/constants';
import { getCoverPreviewPayload } from './utils';

export function* onGetCoverPreview() {
  try {
    const draftId = yield select(draftIdSelector);

    const request = resource(GET_COVER_PREVIEW, fetchCoverPreview, getCoverPreviewPayload);
    const payload = yield call(request, { draftId });

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetCoverPreview() {
  yield takeEvery(GET_COVER_PREVIEW, onGetCoverPreview);
}
