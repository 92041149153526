import { useMutation } from 'react-query';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import { getRouteLaunchCampaign } from 'modules/dashboard/routes/navigation';
import { createOnDemandEmail } from 'modules/api';

import { DashboardTemplate } from 'modules/dashboard/templates';
import { OnDemandEmailsNavBar, OnDemandEmailsSideBar } from 'modules/dashboard/components';

import { notification } from 'modules/common/utils';

import validationSchema from './validationSchema';

const CreateOnDemandEmail = ({ history }) => {
  const formFields = {
    templateId: '',
    subject: '',
    segments: [],
    allSegments: false,
    timezone: 'America/New_York',
    scheduleDate: new Date(),
    sendImmediately: true,
  };

  const { mutate: scheduleOnDemandEmail, isLoading: isLoadingSchedule } = useMutation(
    createOnDemandEmail,
    {
      onSuccess: () => {
        history.replace(getRouteLaunchCampaign());
      },
      onError: () => {
        notification.error({ description: 'There we an error. Please try again later.' });
      },
    },
  );

  const handleCancel = () => {
    history.push(getRouteLaunchCampaign());
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formFields}
      validationSchema={validationSchema}
      onSubmit={scheduleOnDemandEmail}
      F
    >
      <DashboardTemplate
        isLoading={isLoadingSchedule}
        hasNoData={false}
        loadingTitle="Loading Campaigns"
        noDataTitle="No Campaigns Available"
        marginRight="0"
        hasSidebar
        navBar={<OnDemandEmailsNavBar onCancel={handleCancel} />}
        noPaddingLeft
      >
        <OnDemandEmailsSideBar />
      </DashboardTemplate>
    </Formik>
  );
};

export default withRouter(CreateOnDemandEmail);
