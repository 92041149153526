import React from 'react';
import { useDraft } from 'modules/common/components/DraftContext';

import { Editor } from './components';
import S from './styles';

const EditorElement = ({ element, scale, showGuides }) => {
  const { editorState, setEditorState, setSelected } = useDraft(element);
  return (
    <S.Wrapper element={element} scale={scale} showGuides={showGuides}>
      <Editor
        element={element}
        setEditorState={setEditorState}
        editorState={editorState}
        onFocus={() => setSelected(element)}
      />
    </S.Wrapper>
  );
};

export default React.memo(EditorElement);
