import { filter, map, reduce } from 'lodash-es';
import {
  getRouteEditDraftCover,
  getRouteEditDraftContent,
  getRouteEditDraftManageSections,
} from 'modules/v2/editor/routes/navigation';
import { TYPE_CHAPTER, TYPE_MANUSCRIPT } from 'modules/v2/editor/utils';

export const getChapterSections = (draftId, sections) => {
  let chapterNumber = 1;

  const filterer = ({ type }) => type === TYPE_CHAPTER;

  const mapper = ({ _id, displayName, type }) => ({
    _id,
    type,
    displayName,
    // eslint-disable-next-line no-plusplus
    chapterNumber: chapterNumber++,
    to: getRouteEditDraftContent(draftId, _id),
  });

  const filtered = filter(sections, filterer);
  return map(filtered, mapper);
};

const sectionsMenuBuilder = (draftId, sections) => {
  let hasManuscript = false;

  const manuscript = {
    _id: 'manuscript',
    type: TYPE_MANUSCRIPT,
    displayName: 'Manuscript',
    children: getChapterSections(draftId, sections),
  };

  return (acc, { _id, displayName, type }) => {
    // Ignore chapter sections if the manuscript has been injected
    if (type === TYPE_CHAPTER && hasManuscript) {
      return acc;
    }

    // Inject the manuscript section on the first chapter order
    if (type === TYPE_CHAPTER) {
      hasManuscript = true;
      return acc.concat(manuscript);
    }

    // Add the rest of sections except chapters
    const content = {
      _id,
      type,
      displayName,
      to: getRouteEditDraftContent(draftId, _id),
    };

    return acc.concat(content);
  };
};

export const getDropdownSections = (draftId, sections) => {
  const bookCover = {
    _id: 'book-cover',
    displayName: 'Book Cover',
    to: getRouteEditDraftCover(draftId),
  };

  const manageSections = {
    _id: 'manage-sections',
    displayName: 'Manage Sections',
    to: getRouteEditDraftManageSections(draftId),
  };

  const sectionList = reduce(sections, sectionsMenuBuilder(draftId, sections), []);

  return [bookCover, manageSections, ...sectionList];
};
