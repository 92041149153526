import styled from 'styled-components';
import { Layout } from 'antd';
import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';

const contentWidth = '1200px';

const Wrap = styled(Layout.Header)`
  background-color: ${color.Black};
  height: 70px;
  justify-content: center;
  display: flex;

  @media ${device.tabletS} {
    padding: 0;
  }
`;

const InnerWrap = styled.div`
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${contentWidth};
  margin: 0 auto;

  @media ${device.tabletS} {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

const HeaderText = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: ${color.White};
  margin: 0px;

  @media ${device.tabletS} {
    font-size: 14px;
    margin-bottom: 20px;
    oultine: 1px solid white;
    position: absolute;
    right: 10px;
    bottom: 0;
  }

  @media ${device.mobileL} {
    display: none;
  }
`;

const Logo = styled.img`
  width: 205px;

  @media ${device.tabletS} {
    margin: 10px 0;
  }
`;

export default {
  Wrap,
  HeaderText,
  Logo,
  InnerWrap,
};
