import * as Yup from 'yup';

const confirmPassword = Yup.string()
  .trim()
  .when('newPassword', {
    is: (newPassword) => !!(newPassword && newPassword.length > 0),
    then: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords don’t match'),
  })
  .required('Re-enter password is required');

export default confirmPassword;
