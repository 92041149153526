import { Onboard } from 'modules/router/types';

import {
  PlaceOrderPage,
  ConfirmTrialPage,
  ErrorPage,
  ScheduleCoachingPage,
  TrainingWebinarPage,
  BookDetailsPage,
  PlaceOrderWaitPage,
  BookDetailsWaitPage,
  DentistGuideDetailsPage,
  AddonPage,
  FinishOnboarding,
  OrderConfirmation,
  BookSummary,
  GuideConfirmation,
  AccountWait,
} from 'modules/onboard/pages';

import {
  ROUTE_ONBOARD_PLACE_ORDER,
  ROUTE_ONBOARD_CONFIRM_TRIAL,
  ROUTE_ONBOARD_ERROR,
  ROUTE_ONBOARD_SCHEDULE_COACHING,
  ROUTE_ONBOARD_TRAINING_WEBINAR,
  ROUTE_ONBOARD_BOOK_DETAILS,
  ROUTE_ONBOARD_DENTIST_GUIDE_DETAILS,
  ROUTE_ONBOARD_YOUR_BOOK,
  ROUTE_ONBOARD_YOUR_GUIDE,
  ROUTE_ONBOARD_PLACE_ORDER_WAIT,
  ROUTE_ONBOARD_ORDER_CONFIRMATION,
  ROUTE_ONBOARD_ADDON,
  ROUTE_ONBOARD_BOOK_DETAILS_WAIT,
  ROUTE_FINISH_ONBOARDING,
  ROUTE_ONBOARD_BOOK_SUMMARY,
  ROUTE_ONBOARD_GUIDE_CONFIRMATION,
  ROUTE_ONBOARD_ACCOUNT_WAIT,
} from './constants';

const routes = [
  {
    type: Onboard,
    path: ROUTE_ONBOARD_PLACE_ORDER,
    component: PlaceOrderPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_CONFIRM_TRIAL,
    component: ConfirmTrialPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_FINISH_ONBOARDING,
    component: FinishOnboarding,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_ADDON,
    component: AddonPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_ERROR,
    component: ErrorPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_SCHEDULE_COACHING,
    component: ScheduleCoachingPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_TRAINING_WEBINAR,
    component: TrainingWebinarPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_BOOK_DETAILS,
    component: BookDetailsPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_DENTIST_GUIDE_DETAILS,
    component: DentistGuideDetailsPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_YOUR_GUIDE,
    component: BookSummary,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_GUIDE_CONFIRMATION,
    component: GuideConfirmation,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_YOUR_BOOK,
    component: BookSummary,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_BOOK_DETAILS_WAIT,
    component: BookDetailsWaitPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_PLACE_ORDER_WAIT,
    component: PlaceOrderWaitPage,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_ORDER_CONFIRMATION,
    component: OrderConfirmation,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_BOOK_SUMMARY,
    component: BookSummary,
    exact: true,
  },
  {
    type: Onboard,
    path: ROUTE_ONBOARD_ACCOUNT_WAIT,
    component: AccountWait,
    exact: true,
  },
];

export { routes };
