import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const ScheduleCalendar = styled.div`
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-left: 26%;
    margin-right: 26%;
  }
  @media (max-width: 620px) {
    margin-left: 20%;
    margin-right: 20%;
  }
  @media (max-width: 540px) {
    margin-left: 15%;
    margin-right: 15%;
  }
  @media (max-width: 460px) {
    margin-left: 10%;
    margin-right: 10%;
  }
  @media (max-width: 420px) {
    margin-left: unset;
    margin-right: unset;
    width: auto;
  }

  .date-picker-wrap {
    width: 100%;
    background: ${color.White} 0% 0% no-repeat padding-box;
    border: 1px solid ${color.LightGray};
    border-radius: 2px;
  }

  .ant-fullcalendar.date-picker-wrap {
    border: none;
  }

  .ant-fullcalendar-header {
    border-bottom: 1px solid ${color.LightGray};
  }

  .ant-fullcalendar-column-header span {
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    color: ${color.LightHeadGray};
    width: 3.4rem;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ant-fullcalendar-value {
    color: ${color.LightHeadGray};
    font-weight: normal;
    outline: none;
    margin: 0;
    height: 37px;
    width: 38px;
    padding: 6px 0 0 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 0 !important;

    @media (max-width: 768px) {
      width: 39px;
    }

    &:hover {
      background: ${color.PhoneLink};
      cursor: pointer;
      color: ${color.LilyWhite} !important;
    }
  }

  .ant-fullcalendar-disabled-cell {
    opacity: 0.5;

    .ant-fullcalendar-value {
      color: ${color.GreyHover}!important;

      &:hover {
        background: none !important;
      }
    }
  }

  .ant-fullcalendar-today .ant-fullcalendar-value {
    color: ${color.PhoneLink} !important;
    background: ${color.White} !important;
    border: 1px solid ${color.PhoneLink} !important;
    box-shadow: unset;
  }

  .ant-fullcalendar-selected-day .ant-fullcalendar-value {
    background: ${color.SkyBlue};
    color: ${color.LilyWhite};
  }

  .ant-fullcalendar td {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const Title = styled.h4`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: ${color.Black};
  text-align: center;
`;
const CalendarHeader = styled.div`
  border-bottom: 1px solid ${color.LightGray};
  padding: 6px 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ArrowButton = styled.button`
  &:after {
    border-color: ${color.GreyFormBorder};
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: block;
    height: 9px;
    width: 9px;
    transform: ${(props) => (props.previous ? 'rotate(-135deg)' : 'rotate(45deg)')};
  }
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: ${(props) => (props.previous ? 'unset' : '20px')};
  left: ${(props) => (props.previous ? '20px' : 'unset')};
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;
  &:hover {
    opacity: 1;
  }
`;

export default {
  ScheduleCalendar,
  Title,
  CalendarHeader,
  ArrowButton,
};
