import { useMutation, useQuery, useQueryClient } from 'react-query';
import classNames from 'classnames';
import { HiChevronRight } from 'react-icons/hi2';
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { Box, Button, Radio } from 'modules/v2/common/AtomicDesign/atoms';
import RelativeLoading from 'modules/v2/common/AtomicDesign/RelativeLoading';
import { AddCardModal, ChangeDefaultPaymentModal, ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import { AddCircleIcon } from 'modules/v2/common/components/SvgIcon';
import ConfirmationModal from 'modules/v2/common/AtomicDesign/organisms/ConfirmationModal';
import { getDefaultPaymentProfile, getPaymentProfilesList, sendPaymentResolved } from 'modules/api';
import { CreditCardImages } from 'modules/v2/utils';
import { ROUTE_BOOK_CATALOG } from 'modules/v2/routes/constants';
import { CreditCardIcon } from 'modules/common/components/SvgIcon';
import ObjectSet from 'modules/v2/common/utils/set';
import PaymentCardsSkeleton from 'modules/v2/common/AtomicDesign/atoms/PaymentCardsSkeleton';
import { PageMapping } from '../../index';
import { BuyCreditsContext } from '../../context';
import { toUSD } from '../../helper/stringHelper';
import { customLoadingModalTheme } from './theme';

type Props = {
  setActivePage: (value: PageMapping) => void;
};

const BuyCreditsCheckoutPage: React.FC<Props> = ({ setActivePage }) => {
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showPaymentLoadingModal, setShowPaymentLoadingModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState(new ObjectSet());

  const [defaultPaymentOption, setDefaultPaymentOption] = useState(null);
  const [defaultCardModal, setDefaultCardModal] = useState(false);

  const queryClient = useQueryClient();
  const history = useHistory();

  const { userCredits, selectedCreditsOffer } = useContext(BuyCreditsContext);

  const {
    data: defaultProfile,
    isLoading: defaultProfileLoading,
    isRefetching: defaultProfileRefetching,
  } = useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile, {
    onSuccess: (data) => {
      setDefaultPaymentOption(data);
    },
  });

  const {
    data: paymentData = [],
    isLoading: loadingGetPaymentProfilesList,
    isRefetching: refetchingGetPaymentProfilesList,
  } = useQuery('getPaymentProfilesList', getPaymentProfilesList, {
    onSuccess: (data) => {
      const set = new ObjectSet();
      data.forEach((item) => set.add(item));
      setAvailablePaymentOptions(set);
    },
  });

  const navigateBack = () => {
    setActivePage({
      id: 'buy-credits',
      title: 'Buy credits',
    });
  };

  const isDefaultPayment = (itemId: number) => {
    return itemId === defaultPaymentOption?.id;
  };

  useEffect(() => {
    if (defaultPaymentOption) {
      const currentSet = availablePaymentOptions;
      currentSet.add(defaultPaymentOption);
      setAvailablePaymentOptions(currentSet);
      setSelectedPayment(defaultPaymentOption);
    }
  }, [defaultPaymentOption]);

  const handleAddNewPaymentMethod = () => {
    setShowAddCardModal(true);
  };

  function styleMask(item) {
    return (
      item?.masked_card_number.slice(0, -4).replace(/./g, '*') + item?.masked_card_number.slice(-4)
    );
  }

  const { mutate: mutateSendPayment, isLoading: isPaymentLoading } = useMutation(
    'sendPaymentResolved',
    sendPaymentResolved,
    {
      onSuccess: async () => {
        setShowPaymentLoadingModal(false);
        setShowConfirmationModal(true);
      },
      onError: (error: { response: { data: { errors: string[] } }; data: { message: string } }) => {
        setShowPaymentLoadingModal(false);
        console.error(error.response.data.errors?.[0] || error?.data?.message || 'Network Error');
      },
    },
  );

  const handlePayment = () => {
    setShowPaymentLoadingModal(true);
    const paymentPayload = {
      customer_id: selectedPayment.customer_id,
      product: {
        product_handle: selectedCreditsOffer.product.chargifyProductHandle,
        component_id: selectedCreditsOffer.product.chargifyComponentId,
      },
      customer: {
        id: selectedPayment.customer_id,
        first_name: selectedPayment.first_name,
        last_name: selectedPayment.last_name,
        email: selectedPayment.email,
      },
      credit_card: {
        first_name: selectedPayment.first_name,
        last_name: selectedPayment.last_name,
        card_type: selectedPayment.card_type,
        expiration_month: selectedPayment.expiration_month,
        expiration_year: selectedPayment.expiration_year,
        last_four: selectedPayment.masked_card_number.slice(-4),
        vault_token: selectedPayment.vault_token,
        current_vault: selectedPayment.current_vault,
        customer_vault_token: selectedPayment.customer_vault_token,
      },
    };
    mutateSendPayment(paymentPayload);
  };

  return (
    <>
      <div id="breadcrumbs" className="flex gap-2 items-center font-figtree">
        <span className="cursor-pointer hover:bg-neutral-100" onClick={navigateBack}>
          Credits
        </span>{' '}
        <HiChevronRight />
        <span className="border-b-[1px] border-neutral-300">Payment</span>
      </div>

      <Box className="mt-6 relative font-figtree">
        <RelativeLoading isLoading={showNotification} className="rounded-lg" />

        <div className="flex flex-col border-b-[1px] p-[22px] ps-6 pe-6">
          <span className="text-base font-semibold">Review and pay</span>
        </div>

        <Row className="p-6 justify-center max-[1250px]:flex-col" gutter={[24, 24]}>
          <Col span={24} sm={24} md={24} lg={24} xl={12} className="min-w-80">
            <div className="flex items-center gap-2 mb-4 text-neutral-900">
              <CreditCardIcon /> Choose payment method
            </div>

            <div className="max-xl:overflow-x-auto relative">
              <div className="mb-6">
                {loadingGetPaymentProfilesList ||
                defaultProfileLoading ||
                defaultProfileRefetching ||
                refetchingGetPaymentProfilesList ? (
                    <PaymentCardsSkeleton />
                  ) : (
                    Array.from(availablePaymentOptions.toArray()).map((item, index) => {
                      const selected =
                      'border-primary-500 border-[2px] rounded-lg flex gap-4 items-center p-4 mb-2 hover:bg-primary-50 transition duration-300 ease-in-out cursor-pointer';
                      const defaultStyle =
                      'border-neutral-200 gap-4 items-center flex border-[2px] rounded-lg p-4 mb-2 hover:bg-neutral-100 transition duration-300 ease-in-out cursor-pointer';
                      return (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                        <label
                          className={selectedPayment?.id === item.id ? selected : defaultStyle}
                          onClick={() => setSelectedPayment(item)}
                          htmlFor={item.id}
                          key={item.id}
                        >
                          <Radio
                            id={item.id}
                            name="cards"
                            value={item.id}
                            onChange={() => setSelectedPayment(item)}
                            className={classNames(
                              selectedPayment?.id === item.id && 'border-4 border-primary-500',
                            )}
                          />
                          <img
                            src={CreditCardImages[item?.card_type]}
                            alt={item?.card_type}
                            className="w-[46px] h-[32px] rounded-sm"
                          />
                          <span className="text-sm font-semibold">
                          Card {styleMask(item)} {isDefaultPayment(item.id) && '(Default)'}
                          </span>
                        </label>
                      );
                    })
                  )}

                <Button
                  wrapperClassName="flex items-center"
                  onClick={handleAddNewPaymentMethod}
                  type="bare"
                >
                  <AddCircleIcon className="mr-2" /> Add new payment method
                </Button>
              </div>
            </div>
            <ChangeDefaultPaymentModal
              showModal={defaultCardModal}
              setShowModal={setDefaultCardModal}
              isDefault
            />
          </Col>
          <Col span={12} sm={24} md={24} lg={24} xl={12} className="min-w-80">
            <div
              id="summary"
              className="justify-items-center bg-neutral-50 rounded-lg p-7 min-w-80"
            >
              <span className="text-2xl font-semibold">Summary</span>

              <Row gutter={24} className="mt-7 mb-7">
                <Col span={6}>
                  <span className="text-base">{selectedCreditsOffer.credits} credits</span>
                </Col>

                <Col
                  span={18}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <span className="text-base">{toUSD(selectedCreditsOffer.totalAmount)}</span>
                </Col>
              </Row>

              <hr />

              <Row gutter={24} className="mt-7">
                <Col span={10}>
                  <span className="text-lg font-semibold">Total due today</span>
                </Col>

                <Col
                  span={14}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <span className="text-lg font-semibold">
                    {toUSD(selectedCreditsOffer.totalAmount)}
                  </span>
                </Col>
              </Row>

              <Row className="mt-7">
                <Button
                  onClick={handlePayment}
                  disabled={!selectedPayment?.id}
                  wrapperClassName="w-full"
                  size="lg"
                >
                  Pay now
                </Button>
              </Row>

              <Row>
                <span className="text-sm text-neutral-500 mt-4">
                  By clicking “Pay now”, I agree to the{' '}
                  <span className="w-full underline underline-offset-2 text-primary-500 hover:bg-primary-50 rounded transition duration-300 ease-in-out px-[2px] -mx-[2px] hover:p-[4px] hover:-mx-[4px]">
                    <a
                      className="underline underline-offset-2 text-primary-500"
                      href="https://authorify.com/terms-of-use/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions.
                    </a>
                  </span>
                </span>
              </Row>
            </div>
          </Col>
        </Row>
      </Box>

      {showAddCardModal && (
        <AddCardModal showModal={showAddCardModal} setShowModal={setShowAddCardModal} />
      )}

      <ProcessingModal
        title="Processing your payment..."
        description="Please don’t close this window"
        showModal={showPaymentLoadingModal || isPaymentLoading}
        setShowModal={setShowPaymentLoadingModal}
        theme={customLoadingModalTheme}
      />

      <ConfirmationModal
        title={`You've successfully purchased ${selectedCreditsOffer.credits} credits!`}
        description={`You now have a total of ${parseInt(selectedCreditsOffer.credits, 10) +
          parseInt(
            userCredits,
            10,
          )} credits in your account. If its not reflected on your account yet, please wait at least 5 minutes.`}
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        onConfirmation={() => {
          history.push(ROUTE_BOOK_CATALOG);
        }}
      />
    </>
  );
};

export default BuyCreditsCheckoutPage;
