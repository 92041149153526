import { includes } from 'lodash-es';

import env from 'environments';

export default (client) => () => (next) => (action) => {
  const { graphql, type, ...rest } = action;

  if (!graphql) {
    return Promise.resolve(next(action));
  }

  const SUCCESS = type;

  const REQUEST = `${type}_REQUEST`;
  const FAILURE = `${type}_FAILURE`;

  next({ ...rest, type: REQUEST });

  return client
    .query({
      query: graphql.query,
      variables: graphql.variables || {},
      fetchPolicy: 'network-only',
    })
    .then((res) => {
      return next({ ...rest, payload: res.data, type: SUCCESS });
    })
    .catch((error) => {
      if (
        error &&
        error.graphQLErrors &&
        error.graphQLErrors.length &&
        error.graphQLErrors[0].extensions &&
        error.graphQLErrors[0].extensions.exception &&
        error.graphQLErrors[0].extensions.exception.output &&
        error.graphQLErrors[0].extensions.exception.output.statusCode === 401
      ) {
        window.location.href = includes(window.location.href, env.ADMIN_PREFIX)
          ? `/${env.ADMIN_PREFIX}/login`
          : '/auth/login';
      } else {
        return next({ ...rest, error, type: FAILURE });
      }
    });
};
