import styled from 'styled-components';
import { Button } from 'antd';
import * as color from 'modules/v2/common/theme/color';

const Status = styled.span`
  text-transform: capitalize;
`;

const TemplateButton = styled(Button)`
  font-weight: normal !important;
  color: ${color.SkyBlue};

  svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  overflow: auto;
  max-height: 450px;
  background: ${color.White};
  padding: 24px 32px;
`;

const ModalBody = styled.h3`
  margin: 20px 0 10px 0 !important;
  color: ${color.DeleteLeadModalText};
`;

export default {
  Status,
  TemplateButton,
  Wrapper,
  ModalBody,
};
