import styled, { css } from 'styled-components';
import BaseInputMask from 'react-input-mask';
import { Input as BaseInput, Button as BaseButton } from 'antd';
import { Form as BaseForm } from 'formik';
import * as color from 'modules/common/theme/color';

const Form = styled(BaseForm)`
  display: block;
`;

const inputStyle = `
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  height: 45px;
  
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #2e3f50;
  
  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const Input = styled(BaseInput)`
  ${inputStyle}
  height: ${(props) => (props.primary ? '64px' : '45px')};
`;

const { TextArea } = Input;

const InputTextArea = styled(TextArea)`
  ${inputStyle}
  height: 64px;
`;

const InputMask = styled(BaseInputMask)`
  ${inputStyle}
  padding: 4px 10px;
`;

const disabledStyle = css`
  color: ${color.White}!important;
  background-color: ${color.PrimaryDisabledColor}!important;
  border: none;
`;

const Button = styled(BaseButton)`
  margin: 10px 15px;
  height: 40px;
  width: 250px;
  font-size: 16px;

  &,
  &:hover,
  &:focus {
    color: ${color.White};
    background-color: ${color.PrimaryColor};
    border-color: ${color.PrimaryColor};

    ${({ disabled }) => (disabled ? disabledStyle : null)};
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 15px;
`;

const InfoText = styled.span`
  font-weight: 400;
  color: ${color.Body};
  padding: 15px;
`;

const AddressText = styled.span`
  font-weight: 400;
  color: ${color.Body};
  padding: 5px 0px 0px 144px;
`;

const UploadField = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
  color: ${color.TitleBorder};
  font-weight: bold;
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  cursor: pointer;

  .anticon {
    font-size: 48px;
  }
`;

const Image = styled.img`
  height: 190px;
`;

export default {
  Button,
  FieldGroup,
  FieldControl,
  Form,
  InfoText,
  Input,
  InputMask,
  UploadField,
  AddressText,
  InputTextArea,
  Image,
};
