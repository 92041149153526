function getDefaultProps() {
  return {
    width: '20',
    height: '20',
    fill: '#ccc',
  };
}

function SettingsIcon() {
  return (
    // <svg
    //   id="settings"
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={getDefaultProps().width}
    //   height={getDefaultProps().height}
    //   fill={getDefaultProps().fill}
    // >
    //   <path
    //     d="M19.999 11.347V8.653c-1.376-.489-2.245-.626-2.683-1.682V6.97c-.439-1.06.084-1.778.706-3.09l-1.904-1.903c-1.3.618-2.027 1.145-3.09.706-1.057-.439-1.196-1.314-1.682-2.683H8.652c-.485 1.363-.624 2.243-1.682 2.683h-.001c-1.06.44-1.777-.082-3.09-.706L1.977 3.88c.62 1.307 1.145 2.028.705 3.089C2.242 8.03 1.361 8.168 0 8.653v2.694c1.36.483 2.243.624 2.682 1.682.442 1.069-.095 1.805-.705 3.09l1.904 1.904c1.301-.619 2.028-1.145 3.089-.705 1.06.438 1.197 1.315 1.683 2.682h2.694c.485-1.363.625-2.242 1.689-2.685 1.052-.437 1.767.084 3.082.71l1.904-1.906c-.62-1.302-1.145-2.027-.706-3.088.439-1.06 1.323-1.2 2.684-1.684zm-10 1.986a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.667z"
    //     fillRule="nonzero"
    //   />
    // </svg>
    <svg
      width={getDefaultProps().width}
      height={getDefaultProps().height}
      fill={getDefaultProps().fill}
      id="settings"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="cog"
      className="svg-inline--fa fa-cog fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" />
    </svg>
  );
}

export default SettingsIcon;
