import { Public, Authenticated } from 'modules/router/types';

import AuthLogin from 'modules/v2/login/pages/Login';
import AuthReset from 'modules/v2/login/containers/ForgotPassword';
import AuthToken from 'modules/v2/login/pages/AutoLoginCheck';
import AuthSetPassword from 'modules/v2/login/pages/AuthSetPassword';
import { ROUTE_HOMEPAGE } from 'modules/v2/routes/constants';
import { HomePage } from 'modules/v2/pages/HomePage';

const regularRoutes = [
  {
    type: Public,
    exact: true,
    path: '/auth/login',
    component: AuthLogin,
  },
  {
    type: Public,
    exact: true,
    path: '/auth/reset',
    component: AuthReset,
  },
  {
    type: Public,
    exact: true,
    path: '/auth/token',
    component: AuthToken,
  },
  {
    type: Public,
    exact: true,
    path: '/auth/setpassword',
    component: AuthSetPassword,
  },

  {
    type: Authenticated,
    exact: true,
    path: ROUTE_HOMEPAGE,
    component: HomePage,
  },
];

export const loginRoutes = [].concat(regularRoutes);
