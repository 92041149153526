import { put } from 'redux-saga/effects';

import { watchExitRoute } from 'modules/router/sagas';
import { isViewDraftRoute } from 'modules/draft/routes/routeMatchers';

import { clearDraft } from 'modules/draft/store';

const watchFn = (location) => isViewDraftRoute(location);
const validFn = (location) => !isViewDraftRoute(location);

export function* onExitDraft() {
  yield put(clearDraft());
}

export function* watchExitDraft() {
  yield watchExitRoute(watchFn, validFn, onExitDraft);
}
