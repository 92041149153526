import getInstance from './instance';
import getJourneyInstance from './instanceJourney';

const instance = getInstance();
const journeyInstance = getJourneyInstance();

export const fetchCountryList = () => {
  const url = '/country';

  return instance.get(url);
};

export const fetchStatesByCountryName = ({ country }) => {
  const url = '/state';
  const params = {
    country,
  };

  return instance.get(url, { params });
};

export const fetchJourneyTodos = async () => {
  const url = '/journey/fetch';
  try {
    const res = await journeyInstance.get(url);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const completeSchedulingTaskForStaging = async () => {
  const url = '/journey/complete-scheduling-task';
  try {
    return await journeyInstance.post(url);
  } catch (error) {
    console.error('Error completing scheduling task:', error);
    return Promise.reject(error);
  }
};

export const fetchSubscriptionJourney = async () => {
  const url = '/journey/fetch/subscription';
  try {
    const res = await journeyInstance.get(url);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postJourneyTodos = async (todos) => {
  const url = '/journey/complete/step';
  try {
    const res = await journeyInstance.post(url, todos);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const completeWelcomeJourney = async () => {
  const url = '/journey/complete-welcome-journey';
  try {
    const res = await journeyInstance.post(url);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
