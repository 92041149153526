import { useState } from 'react';

import { Modal } from 'modules/common/components';

import ModalContent from './ModalContent';

const PlainTextModal = ({ element, isOpen, onConfirm, onCancel, scale, title }) => {
  const [state, setState] = useState(element);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(state);
    }
  };

  return (
    <Modal
      width="700px"
      title={title}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      maskClosable={false}
    >
      <ModalContent scale={scale} element={element} onChange={setState} />
    </Modal>
  );
};

export default PlainTextModal;
