import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: relative;
    bottom: 11px;
  }
`;

export default {
  Wrapper,
};
