import { centsToDollar } from 'modules/dashboard/pages/Plans/utils';
import { formatCurrency } from 'modules/dashboard/utils';

import S from './styles';

const renderAmount = (amount) =>
  amount > 0 ? (
    formatCurrency(centsToDollar(amount))
  ) : (
    <span>{formatCurrency(centsToDollar(amount))}</span>
  );

const UpgradeTable = ({ planData }) => {
  const { total_in_cents: amountRemaining, line_items: lineItems = [] } = planData || {};
  const amountToPay = formatCurrency(centsToDollar(amountRemaining));
  return (
    <>
      <S.Table data-testid="table-wrap">
        <S.TableRow>
          <S.TableHeading>Items</S.TableHeading>
          <S.TableHeading>Price</S.TableHeading>
        </S.TableRow>
        {lineItems.map(({ memo, amount_in_cents: amount }) => (
          <S.TableRow>
            <S.TableData>{memo}</S.TableData>
            <S.TableData>{renderAmount(amount)}</S.TableData>
          </S.TableRow>
        ))}
        <S.TableRow>
          <S.FooterData>Total</S.FooterData>
          <S.FooterData>{amountToPay}</S.FooterData>
        </S.TableRow>
      </S.Table>
      <S.BottomLine />
    </>
  );
};

export default UpgradeTable;
