import { useState } from 'react';
import { compose } from 'redux';
import { map, debounce } from 'lodash-es';
import { Pagination, DatePicker } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { CloudDownloadOutlined } from '@ant-design/icons';

import { DashboardTemplate } from 'modules/dashboard/templates';
import { AdminSideNav } from 'modules/dashboard/components';
import { withProfile } from 'modules/dashboard/containers';
import { Table } from 'modules/common/components/UIComponents';
import { getReport, getLandingStats, downloadReports } from 'modules/api';
import { notification } from 'modules/common/utils';
import { element } from 'modules/common/utils/element';
import { PrimaryButton, Loader, SearchIconBlack, ReportIcon } from 'modules/common/components';
import { ReportCard } from 'modules/dashboard/components/ReportCard';

import G from '../../BookPerformanceAnalytics/styles';
import S from './style';

const LoadingComponent = () => <Loader height="calc(100vh - 250px)" icon={<ReportIcon />} />;

const { RangePicker } = DatePicker;

const Reports = () => {
  const [search, setSearch] = useState('');
  const [isSort, setIsSort] = useState(false);
  const [paginationData, setPaginationData] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data: statsData } = useQuery(
    ['gettingStats', { startDate, endDate }],
    () => getLandingStats(startDate, endDate),
    {
      onError: (error) => {
        notification.error({ description: error?.data?.message || 'Network Error' });
      },
    },
  );

  const sort = isSort ? 'asc' : 'desc';
  const { data: reportsData, isFetching: isFetchingReports } = useQuery(
    ['gettingReports', search, sort],
    () => getReport(search, sort),
    {
      onError: (error) => {
        notification.error({
          description: error?.data?.message || 'Network Error',
        });
      },
    },
  );

  const { mutate: getCSVReports, isLoading: isFetchingReportsCSV } = useMutation(downloadReports, {
    onSuccess: (data) => {
      element(data, 'Reports');
    },
    onError: () => {
      toast.dismiss();
      toast.error('Oops Something went Wrong!!!', {
        className: 'Errortoaster',
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const dataTable = {
    header: ['Members Name', 'Members Email Address', 'Total Visit Counts'],
  };

  const debounced = debounce((value) => setSearch(value), 1000);

  const onSearch = (e) => {
    debounced(e?.target?.value);
  };

  const reports =
    reportsData?.data?.slice(paginationData * pageSize - pageSize, paginationData * pageSize) || [];

  const calanderChange = (event, date) => {
    if (event && event.length === 2) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    } else if (!event || !event.length) {
      setStartDate('');
      setEndDate('');
    }
  };

  return (
    <DashboardTemplate sidebar={<AdminSideNav />} hasSidebar>
      <S.MainContain>
        <S.Title>Reports</S.Title>
        <S.BtnGroup>
          <PrimaryButton onClick={getCSVReports} disabled={isFetchingReportsCSV}>
            <S.ButtonText>
              Export Reports <CloudDownloadOutlined />
            </S.ButtonText>
          </PrimaryButton>
        </S.BtnGroup>
      </S.MainContain>
      <S.ReportText>
        The reports are from <b>90 days</b> period.
      </S.ReportText>
      <S.DatePicker>
        Choose Date Range <RangePicker onChange={calanderChange} />
      </S.DatePicker>
      <G.CardWrapper>
        {map(statsData?.data, (card) => (
          <ReportCard {...card} key={card.name} />
        ))}
      </G.CardWrapper>
      <S.LandingBox>
        <S.Title>Landing Page Report</S.Title>
        <S.SearchBox>
          <S.SearchInput
            type="text"
            placeholder="Search Member's Name/Email Address"
            onChange={onSearch}
          />
          <S.IconSearch>
            <SearchIconBlack />
          </S.IconSearch>
        </S.SearchBox>
      </S.LandingBox>
      <S.BottomContainer>
        {isFetchingReports ? (
          <S.EmptyColumn colSpan={3}>
            <LoadingComponent />
          </S.EmptyColumn>
        ) : (
          <Table.Container>
            <Table>
              <Table.Body>
                <Table.HeadRow>
                  {map(dataTable.header, (text, index) => (
                    <Table.Heading key={text} onClick={() => setIsSort(!isSort)}>
                      {text} {index === 2 ? <S.SortArrow>{isSort ? '▼' : '▲'}</S.SortArrow> : null}
                    </Table.Heading>
                  ))}
                </Table.HeadRow>
                {map(reports, ({ name, email, count }) => (
                  <Table.BodyRow key={name}>
                    <Table.Data>{name}</Table.Data>
                    <Table.Data>{email}</Table.Data>
                    <Table.Data>{count}</Table.Data>
                  </Table.BodyRow>
                ))}
              </Table.Body>
            </Table>
          </Table.Container>
        )}
      </S.BottomContainer>
      {!isFetchingReports && !!reports.length && (
        <S.PaginationWrap>
          <Pagination
            showSizeChanger
            onShowSizeChange={(e, b) => setPageSize(b)}
            defaultCurrent={paginationData}
            total={reportsData?.data?.length || 0}
            onChange={(e) => setPaginationData(e)}
          />
        </S.PaginationWrap>
      )}
    </DashboardTemplate>
  );
};

export default compose(withProfile)(Reports);
