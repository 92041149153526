import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';
import { PageTitle as UIPageTitle } from 'modules/onboard/components/UIComponents';

const PageTitle = styled(UIPageTitle)`
  margin-top: 20px !important;
`;

const InfoText = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${color.Body};
  padding: 0 15px 15px;
  display: block;
  width: 380px;
  max-width: 100%;
  text-align: center;
  margin-top: 20px;

  @media ${device.tabletS} {
    font-size: 14px;
    width: 100%;
  }
`;

const FormTitle = styled.h4`
  padding: 8px 15px;
  background: ${color.FormTitleBg};
  border: 1px solid #ececec;
  margin-bottom: 20px !important;

  @media ${device.tabletS} {
    text-align: center;
    font-size: 14px !important;
  }
`;

const BlockForm = styled.div`
  padding: 5px 15px;
`;

const ButtonBlockForm = styled(BlockForm)`
  width: 500px;
  margin: 0 auto;
`;

export default {
  PageTitle,
  FormTitle,
  BlockForm,
  ButtonBlockForm,
  InfoText,
};
