//

import { compose } from 'redux';
import { get } from 'lodash-es';
import env from 'environments';

import OpenText from 'assets/images/open-text.svg';
import { withSupport, withProfile } from 'modules/dashboard/containers';
import { DashboardTemplate } from 'modules/dashboard/templates';

import { HelpForm, FrequentAskedQuestions } from './components';

import S from './styles';

const HelpCenter = ({ createTicket, ticketStatus, profile }) => {
  const { firstName } = get(profile, 'data', {});
  const initiateChatSession = () => {
    if (!window.Intercom) return;

    window.Intercom('showNewMessage', env.SUPPORT_NEW_MESSAGE);
  };

  return (
    <DashboardTemplate subHeader={null}>
      <S.Wrapper>
        <S.BoxGroup>
          <S.Box marginRight="20px">
            <S.Box.Title bgImage={OpenText}>
              <S.MessageIcon />
              Welcome To Support Center
            </S.Box.Title>

            <S.Box.Text>
              <S.Greetings>Hi {firstName},</S.Greetings>
              Our Member Support team is ready to help you. Just fill up the form below or send an
              email to <a href={`mailto:${env.SUPPORT_EMAIL}`}>{env.SUPPORT_EMAIL}</a> and a member
              of our Support team will get back to you within 8 business hours.
            </S.Box.Text>

            <HelpForm onSubmit={createTicket} ticketStatus={ticketStatus} />
          </S.Box>

          <S.Box>
            <S.Box.Title>
              <S.CustomerServiceIcon />
              Need Urgent Assistance?
            </S.Box.Title>
            <S.Box.Text>
              You can get <em>LIVE assistance</em> from <em>Member Support</em> Monday to Friday
              between <em>{env.SUPPORT_OPPENING_HOURS}</em> by choosing any of the options below.
            </S.Box.Text>
            <S.Box.Text>
              <em>1.</em> Initiate a Chat session by clicking the{' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={initiateChatSession}>chat icon</a> in the lower right-hand corner of any
              page or,
              <br />
              <em>2.</em> Call our support line at{' '}
              <a href={`tel:${env.SUPPORT_PHONE}`}>{env.SUPPORT_PHONE}</a>, and press option 2
            </S.Box.Text>
          </S.Box>
        </S.BoxGroup>

        <S.BoxGroup>
          <S.Box>
            <S.Box.Title>
              <S.QuestionIcon />
              Frequently Asked Questions
            </S.Box.Title>
            <S.Box.Text>Here are some of the most frequently asked member questions:</S.Box.Text>

            <FrequentAskedQuestions />
          </S.Box>
        </S.BoxGroup>
      </S.Wrapper>
    </DashboardTemplate>
  );
};

export default compose(withProfile, withSupport)(HelpCenter);
