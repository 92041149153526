import { ADMIN_USER_LOGIN } from '../constants';
import { ADMIN_LOGIN } from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  data: {},
  statusCode: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${ADMIN_USER_LOGIN}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case `${ADMIN_USER_LOGIN}`: {
      return {
        data: action.payload.data,
        loading: false,
        loaded: true,
        statusCode: action.payload.statusCode,
      };
    }

    case `${ADMIN_USER_LOGIN}_FAILURE`: {
      return {
        ...state,
        loaded: true,
      };
    }

    case `${ADMIN_LOGIN}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case `${ADMIN_LOGIN}`: {
      return {
        data: action.payload,
        loading: false,
        loaded: true,
        statusCode: 0,
      };
    }
    case `${ADMIN_LOGIN}_FAILURE`: {
      return {
        ...state,
        loading: false,
        statusCode: 1,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
