import styled from 'styled-components';
import { List as BaseList } from 'antd';
import * as color from 'modules/common/theme/color';

const SectionItemName = styled.span`
  display: block;
  max-width: 84%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SortingList = styled(BaseList)`
  .ant-list-empty-text {
    background: ${color.White};
  }

  .ant-list-item {
    padding: 0;
    border-bottom: 1px solid ${color.TitleBorder};

    .ant-list-item-content {
      position: relative;
      padding: 5px 0 5px 30px !important;
      display: flex !important;
      align-items: center !important;
      background: ${color.White};
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: ${color.GreySelectDd};
      padding: 5px 17px;
      line-height: 34px;
      border-radius: 3px;
      display: block;
      width: 100%;
    }

    &:last-child {
      border-bottom: 0;
    }

    .dotsDDWrap {
      position: absolute;
      right: 10px;
      padding: 2px 4px;
      border: 0;
      border-radius: 50%;
      height: 28px;
      display: none;
      background: ${color.MenuDotsColorBg};
      cursor: pointer;

      .tooltipWrap {
        background: none;
        padding: 0;
        border: 0;
        width: auto;
        height: auto;

        svg {
          fill: ${color.MenuDotsColor};
          width: 15px;
          height: auto;
          position: relative;
          top: -4px;
          #edit {
            height: 10px;
            width: 10px;
          }
        }
      }
    }

    &:hover,
    &.selected {
      .ant-list-item-content {
        background: ${color.MenuListBg};
        .dotsDDWrap {
          display: block;
        }
      }
    }

    &.draggable {
      .ant-list-item-content {
        cursor: grab;
      }
    }
  }

  .dropSpacing {
    padding: 15px 20px 120px !important;
  }
`;

const MenuWrap = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const EditLink = styled.button`
  background: none;
  color: ${color.GreySelectDd};
  cursor: pointer;
  border: 0;
  font-weight: 600;
  height: 35px;
  line-height: 35px;

  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled.div`
  padding: 0 10px;
  position: absolute;
  left: 5px;
  top: 7px;
`;

export default {
  SectionItemName,
  SortingList,
  MenuWrap,
  EditLink,
  Icon,
};
