import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import reducers from 'shared/store/reducers';

import auth from 'modules/auth/store/reducers';
import bookLinks from 'modules/bookLinks/store/reducers';
import dashboard from 'modules/dashboard/store/reducers';
import draft from 'modules/draft/store/reducers';
import editor from 'modules/editor/store/reducers';
import publish from 'modules/publish/store/reducers';

import initialState from './initialState';
import { APP_RESET } from './constants';

const createRootReducer = (history) => {
  const combined = combineReducers({
    router: connectRouter(history),
    ...reducers,
    authModule: auth, // TODO: rename this to auth after refactoring login page
    bookLinks,
    dashboardModule: dashboard, // TODO: rename this to dashboard after refactoring
    draftModule: draft, // TODO: rename this to draft after refactoring
    editor,
    publish,
  });

  return (state = initialState, action) => {
    if (action.type === APP_RESET) {
      return combined({ ...initialState }, action);
    }

    return combined(state, action);
  };
};

export default createRootReducer;
