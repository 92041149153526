import { getInstance } from './instance';

const instance = getInstance();

const getMagazinePreview = (id, email, year, month) => {
  const url = '/referral-marketing/generated-magazines/magazine-preview';
  const params = { id, email, year, month };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

export { getMagazinePreview };
