import { call, takeLatest } from 'redux-saga/effects';

import { fetchDrafts, resource, getError } from 'modules/api';
import { GET_DRAFTS } from 'modules/dashboard/store/constants';

import { getDraftsPayload } from './utils';

function* onGetDrafts() {
  try {
    const request = resource(GET_DRAFTS, fetchDrafts, getDraftsPayload);
    const payload = yield call(request);

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetDrafts() {
  yield takeLatest(GET_DRAFTS, onGetDrafts);
}
