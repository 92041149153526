import styled from 'styled-components';

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
`;

export default {
  ListWrapper,
};
