import checkCircleOutlined from 'assets/images/check-circle-outlined.svg';
import checkCircleFilled from 'assets/images/check-circle-filled.svg';

import S from './styles';

const ONBOARD_STATUS = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  INACTIVE: 'inactive',
};

const getIconByStatus = (status: string) => {
  let icon: string;

  switch (status) {
    case ONBOARD_STATUS.ACTIVE:
      icon = checkCircleOutlined;
      break;

    case ONBOARD_STATUS.FINISHED:
      icon = checkCircleFilled;
      break;

    default:
      break;
  }

  return icon;
};

const Step = ({ label, status, stepNumber }) => {
  const icon = getIconByStatus(status);
  const isInactiveStep = status === ONBOARD_STATUS.INACTIVE;

  return (
    <S.StepWrapper>
      {icon ? <S.NextStepActive src={icon} alt="Step" /> : null}
      {isInactiveStep ? <S.IconNextStep>{stepNumber}</S.IconNextStep> : null}
      {label}
    </S.StepWrapper>
  );
};

const Steps = ({ stepsData, currentStep }) => (
  <S.StepContainer>
    {stepsData.map(({ stepNumber, label, status }) => {
      const isCurrentStep = currentStep === stepNumber;
      const isPreviousStep = currentStep > stepNumber;
      const stepStatus = isCurrentStep ? ONBOARD_STATUS.ACTIVE : ONBOARD_STATUS.INACTIVE;
      const getStatus = isPreviousStep ? ONBOARD_STATUS.FINISHED : stepStatus;
      const isInactiveStep = status === ONBOARD_STATUS.INACTIVE;
      if (isInactiveStep) return null;

      return <Step key={stepNumber} stepNumber={stepNumber} label={label} status={getStatus} />;
    })}
  </S.StepContainer>
);

export default Steps;
