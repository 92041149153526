//

import { Row, Col } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';
import { filter } from 'lodash-es';
import classnames from 'classnames';

import { ListSorting } from './components';
import S from './styles';
import ListArchived from './components/ListArchived';

const SectionSorting = ({ items, selectedId, onDrag, onEdit, onSelect, archivedItems }) => {
  const handleDrag = ({ draggableId, source, destination }) => {
    onDrag(draggableId, source, destination);
  };

  const isArchived = new Set();
  archivedItems.forEach(({ _id }) => {
    isArchived.add(_id);
  });

  const active = filter(items, (item) => item.isActive);
  const inactive = filter(items, (item) => !item.isActive && !isArchived.has(item._id));

  return (
    <S.SectionSorter>
      <DragDropContext onDragEnd={handleDrag}>
        <Row>
          <Col span={24}>
            <S.ActiveSortingGroup>
              <S.Sections
                className={classnames({
                  dropSpacing: active.length,
                })}
              >
                <S.SectionTitle>Active book sections</S.SectionTitle>

                <ListSorting
                  droppableId="active"
                  selectedId={selectedId}
                  items={active}
                  onDrag={onDrag}
                  onEdit={onEdit}
                  onSelect={onSelect}
                />
              </S.Sections>
            </S.ActiveSortingGroup>
          </Col>

          <Col span={24}>
            <S.Sections
              className={classnames({
                dropSpacing: inactive.length,
              })}
            >
              <S.SectionTitle>Inactive book sections</S.SectionTitle>

              <S.Info>
                <S.Info.Icon />
                Click, drag and drop inactive sections to active book sections at the top to start
                editing.
              </S.Info>

              <ListSorting
                droppableId="inactive"
                selectedId={selectedId}
                items={inactive}
                onDrag={onDrag}
                onSelect={onSelect}
              />
            </S.Sections>
          </Col>

          <Col span={24}>
            <S.Sections
              className={classnames({
                dropSpacing: archivedItems.length,
              })}
            >
              <S.SectionTitle>Archived book sections</S.SectionTitle>

              <S.Info>
                <S.Info.Icon />
                These are outdated versions of your book and available for viewing only
              </S.Info>

              <ListArchived
                droppableId="archived"
                selectedId={selectedId}
                items={archivedItems}
                onSelect={onSelect}
              />
            </S.Sections>
          </Col>
        </Row>
      </DragDropContext>
    </S.SectionSorter>
  );
};

export default SectionSorting;
