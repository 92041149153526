import { InputMask } from 'modules/onboard/components';
import { FormikCountryField, FormikStatesField } from 'modules/formBuilder/components';
import { BILLING_ADDRESS_COUNTRY } from 'modules/formBuilder/constants';

import { FormDefinition } from './types';

export const defaultFormDefinition: FormDefinition[] = [
  {
    section: 'Customer Information',
    sectionId: '1',
    blocks: [
      {
        blockId: 'customer-1',
        fields: [
          {
            id: 'customer.first_name',
            label: 'First Name',
            required: true,
          },
          {
            id: 'customer.last_name',
            label: 'Last Name',
            required: true,
          },
        ],
      },
      {
        blockId: 'customer-2',
        fields: [
          {
            id: 'customer.email',
            label: 'Email Address',
            required: true,
          },
          {
            id: 'customer.phone',
            label: 'Phone Number',
            component: InputMask,
            mask: '999-999-9999',
            alwaysShowMask: true,
            required: true,
          },
        ],
      },
      {
        blockId: 'customer-3',
        fields: [
          {
            id: 'customer.password',
            label: 'Password',
            type: 'password',
            required: true,
          },
        ],
      },
    ],
  },
  {
    section: 'Billing Information',
    sectionId: '2',
    blocks: [
      {
        blockId: 'billing-1',
        fields: [
          {
            id: 'billing_address.address',
            label: 'Billing Address',
            required: true,
          },
        ],
      },
      {
        blockId: 'billing-2',
        fields: [
          {
            id: 'billing_address.city',
            label: 'City',
            required: true,
          },
          {
            id: BILLING_ADDRESS_COUNTRY,
            label: 'Country',
            component: FormikCountryField,
            showSearch: true,
            required: true,
            options: [
              { value: 'USA', label: 'United States' },
              { value: 'Canada', label: 'Canada' },
            ],
          },
        ],
      },
      {
        blockId: 'billing-3',
        fields: [
          {
            id: 'billing_address.state',
            label: 'State',
            component: FormikStatesField,
          },
          {
            id: 'billing_address.zip',
            label: 'Postal Code',
            defaultValue: '',
            required: true,
          },
        ],
      },
    ],
  },
  {
    section: 'Payment Information',
    sectionId: '3',
    blocks: [
      {
        blockId: 'payment-1',
        fields: [
          {
            id: 'credit_card.full_number',
            label: 'Card Number',
            component: InputMask,
            mask: '9999 9999 9999 9999',
            alwaysShowMask: true,
            required: true,
          },
        ],
      },
    ],
  },
  {
    sectionId: '4',
    blocks: [
      {
        blockId: 'payment-2',
        fields: [
          {
            id: 'credit_card.expiration_month',
            label: 'Month',
            component: InputMask,
            mask: '99',
            alwaysShowMask: true,
            required: true,
          },
          {
            id: 'credit_card.expiration_year',
            label: 'Year',
            component: InputMask,
            mask: '9999',
            alwaysShowMask: true,
            required: true,
          },
          {
            id: 'credit_card.cvc',
            label: 'CVC',
            component: InputMask,
            mask: '9999',
            alwaysShowMask: true,
            required: true,
          },
        ],
      },
    ],
  },
];
