import { LOCATION_CHANGE } from 'connected-react-router';
import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';

import { isDraftEditable } from 'modules/common/utils';

import { fetchDraftById, resource, getError } from 'modules/api';

import { goToRoot } from 'modules/draft/store';
import { GET_DRAFT_BY_ID } from 'modules/draft/store/constants';

import { filterDraftPayload } from './utils';

export function* onGetDraftById({ payload: meta }) {
  try {
    const request = resource(GET_DRAFT_BY_ID, fetchDraftById, filterDraftPayload);
    const payload = yield call(request, meta);
    const draft = get(payload, 'draft', null);

    if (draft === null || !isDraftEditable(draft)) {
      yield put(goToRoot());
    }

    return draft;
  } catch (error) {
    const payload = getError(error);

    // eslint-disable-next-line no-console
    console.error(payload);
    yield put(goToRoot());

    return payload;
  }
}

export function* watchCancellation(action) {
  yield race([call(onGetDraftById, action), take(LOCATION_CHANGE)]);
}

export function* watchGetDraftById() {
  yield takeEvery(GET_DRAFT_BY_ID, watchCancellation);
}
