import {
  FILTER_SECTIONS,
  REORDER_DRAFT_SECTIONS,
  ADD_NEW_CHAPTER_SECTION,
  ACTIVATE_SECTION,
  INACTIVATE_SECTION,
  REORDER_SECTION,
} from '../constants';

const initialState = {
  saving: false,
  data: [],
  buckets: {
    enabled: [],
    disabled: [],
  },
  manuscript: {
    enabled: [],
    disabled: [],
  },
};

const pushSection = (arr, item, index = 0) => [...arr.slice(0, index), item, ...arr.slice(index)];

const reorderSection = (arr, id, index) => {
  const found = arr.find((item) => item._id === id);
  return found !== undefined
    ? pushSection(
        arr.filter((item) => item._id !== id),
        found,
        index,
      )
    : arr;
};

const moveSection = (source, destination, id, index) => {
  const found = source.find((item) => item._id === id);
  return found !== undefined
    ? [source.filter((item) => item._id !== id), pushSection(destination, found, index)]
    : [source, destination];
};

const sortSections = ({ buckets, manuscript, ...state }) => {
  const fn = (a, b) => a.order - b.order;
  return {
    ...state,
    buckets: {
      enabled: [...buckets.enabled].sort(fn),
      disabled: [...buckets.disabled].sort(fn),
    },
    manuscript: {
      enabled: [...manuscript.enabled].sort(fn),
      disabled: [...manuscript.disabled].sort(fn),
    },
  };
};

const resortSections = ({ buckets, manuscript, ...state }) => {
  let sortedBuckets = {
    enabled: [],
    disabled: [],
  };
  let sortedManuscript = {
    enabled: [],
    disabled: [],
  };

  let order = 1;
  for (const bucket of buckets.enabled) {
    const newBucket = { ...bucket, currentStatus: 'active', order: order++ };
    sortedBuckets.enabled.push(newBucket);

    if (newBucket.type === 'parentManuscript') {
      let chapterOrder = newBucket.order;
      for (const chapter of manuscript.enabled) {
        const newChapter = { ...chapter, currentStatus: 'active', order: chapterOrder++ };
        sortedManuscript.enabled.push(newChapter);
      }
      for (const chapter of manuscript.disabled) {
        const newChapter = { ...chapter, currentStatus: 'inActive', order: chapterOrder++ };
        sortedManuscript.disabled.push(newChapter);
      }
      order = chapterOrder;
    }
  }

  for (const bucket of buckets.disabled) {
    sortedBuckets.disabled.push({ ...bucket, currentStatus: 'inActive', order: order++ });
  }

  return {
    ...state,
    buckets: sortedBuckets,
    manuscript: sortedManuscript,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_SECTIONS: {
      if (!Array.isArray(action.sections)) {
        return initialState;
      }

      const manuscript = { enabled: [], disabled: [] };
      const buckets = { enabled: [], disabled: [] };

      let firstChapterOrder;

      for (const section of action.sections) {
        if (section.type === 'Chapter') {
          if (section.currentStatus === 'active') {
            manuscript.enabled.push(section);

            if (firstChapterOrder === undefined || section.order <= firstChapterOrder) {
              firstChapterOrder = section.order;
            }
          } else {
            manuscript.disabled.push(section);
          }
        }

        if (section.type === 'Content') {
          if (section.currentStatus === 'active') {
            buckets.enabled.push(section);
          } else {
            buckets.disabled.push(section);
          }
        }
      }

      buckets.enabled.push({
        _id: 'manuscript',
        type: 'parentManuscript',
        displayName: 'Manuscript',
        order: firstChapterOrder,
        currentStatus: 'active',
      });

      return sortSections({
        ...state,
        buckets,
        manuscript,
      });
    }

    case ADD_NEW_CHAPTER_SECTION: {
      const manuscript = {
        ...state.manuscript,
        enabled: pushSection(state.manuscript.enabled, action.payload),
      };

      return resortSections({
        ...state,
        manuscript,
      });
    }

    case ACTIVATE_SECTION: {
      const { sectionId, nextIndex, isChapter } = action.payload;
      let { buckets, manuscript } = state;

      const activate = (group) => {
        const [disabled, enabled] = moveSection(
          group.disabled,
          group.enabled,
          sectionId,
          nextIndex,
        );
        return { enabled, disabled };
      };

      if (isChapter) {
        manuscript = activate(manuscript);
      } else {
        buckets = activate(buckets);
      }

      return resortSections({
        ...state,
        buckets,
        manuscript,
      });
    }

    case INACTIVATE_SECTION: {
      const { sectionId, nextIndex, isChapter } = action.payload;
      let { buckets, manuscript } = state;

      const inactivate = (group) => {
        const [enabled, disabled] = moveSection(
          group.enabled,
          group.disabled,
          sectionId,
          nextIndex,
        );
        return { enabled, disabled };
      };

      if (isChapter) {
        // Only allow to inactivate if there's more than one chapter active
        if (manuscript.enabled.length > 1) {
          manuscript = inactivate(manuscript);
        }
      } else {
        // Only allow to inactivate content different of manuscript
        if (sectionId !== 'manuscript') {
          buckets = inactivate(buckets);
        }
      }

      return resortSections({
        ...state,
        buckets,
        manuscript,
      });
    }

    case REORDER_SECTION: {
      const { sectionId, nextIndex, isChapter } = action.payload;
      let { buckets, manuscript } = state;

      const reorder = (group) => ({
        enabled: reorderSection(group.enabled, sectionId, nextIndex),
        disabled: reorderSection(group.disabled, sectionId, nextIndex),
      });

      if (isChapter) {
        manuscript = reorder(manuscript);
      } else {
        buckets = reorder(buckets);
      }

      return resortSections({
        ...state,
        buckets,
        manuscript,
      });
    }

    case `${REORDER_DRAFT_SECTIONS}_REQUEST`:
    case `${REORDER_DRAFT_SECTIONS}_FAILURE`:
      return { ...state, saving: true };

    case REORDER_DRAFT_SECTIONS:
      return { ...state, saving: false };

    default:
      return { ...state };
  }
};
