import S from './styles';

const Item = ({
  // Data
  addressLine1,
  addressLine2,
  city,
  country,
  firstName,
  isSelected,
  lastName,
  pincode,
  state,

  // Callbacks
  onDelete,
  onEdit,
  onSelect,
}) => {
  const fullName = `${firstName} ${lastName}`;
  const location = `${city}, ${state} ${pincode}`;

  return (
    <S.CardWrapper isSelected={isSelected}>
      <S.AddressWrapper>
        <p>
          <b>{fullName}</b>
        </p>
        <p>{addressLine1}</p>
        {addressLine2 && <p>{addressLine2}</p>}
        <p>{location}</p>
        <p>{country}</p>
      </S.AddressWrapper>
      <S.ButtonWrapper>
        {onSelect && (
          <S.Button onClick={onSelect} type="primary">
            Select
          </S.Button>
        )}
        {onEdit && (
          <S.ButtonLink type="link" onClick={onEdit}>
            Edit
          </S.ButtonLink>
        )}
        {onDelete && (
          <S.ButtonLink type="link" onClick={onDelete}>
            Delete
          </S.ButtonLink>
        )}
      </S.ButtonWrapper>
    </S.CardWrapper>
  );
};

Item.defaultProps = {
  addressLine2: undefined,
};

export default Item;
