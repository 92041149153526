import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 16px;
  padding-left: 16px;
`;

const Title = styled.h3`
  padding-top: 8px;
`;

export default { Wrapper, Title };
