import React, { useState } from 'react';
import { find, get } from 'lodash-es';
import env from 'environments';

import { ModalAddressView as AddressView } from 'modules/common/views';
import { useGetCustomerSubscriptionStatus } from 'modules/dashboard/utils';
import { PreflightView, CheckoutView, ReviewView, ConfirmationView } from 'modules/publish/views';
import Wizard from 'modules/publish/components/Wizard';
import { views } from 'modules/publish/constants';
import { isPending, isLoading } from 'store/status';
import S from 'modules/publish/styles';

const ViewRenderer = ({
  // Data
  activeView,
  addresses,
  countries,
  credits,
  order,
  preflight,
  states,

  // Callbacks
  onDone,
  onCreateAddress,
  onDeleteAddress,
  onEditAddress,
  onEditCover,
  onEditSection,
  onSubmitOrder,
  setActiveView,
}) => {
  const [fields, setFields] = useState({
    selectedAddressId: null,
    numberOfPrints: null,
    isDigital: null,
  });
  const [formAddressId, setFormAddressId] = useState(null);

  // Should display only when the publish is not being processed or is completed
  const isPublishing = activeView === views.REVIEW && isLoading(order.status);
  const isPublished = activeView === views.CONFIRMATION;
  const displayWizard = !isPublishing && !isPublished;

  const customerSubscriptionStatus = useGetCustomerSubscriptionStatus();
  const isTrialCustomer = customerSubscriptionStatus === 'trialing';

  const handleSubmitCheckout = ({ isDigital, numberOfPrints }) => {
    const isDigitalOnly = isDigital && numberOfPrints === 0;
    const hasAddressSelected = fields.selectedAddressId !== null;
    const nextFields = {
      ...fields,
      numberOfPrints,
      isDigital,
    };

    let nextView = views.ADDRESS_LIST;

    if (isDigitalOnly) {
      nextView = views.REVIEW;
    }

    if (!isDigitalOnly && hasAddressSelected) {
      nextView = views.REVIEW;
    }

    setFields(nextFields);
    setActiveView(nextView);
  };

  const handleSelectAddress = (selectedAddressId) => {
    setFields({ ...fields, selectedAddressId });
    setActiveView(views.REVIEW);
  };

  const handleEditQuantity = () => setActiveView(views.CHECKOUT);

  const handleChangeAddress = () => setActiveView(views.ADDRESS_LIST);

  const handleComplete = () => {
    onSubmitOrder(fields);
  };

  const Views = () => {
    switch (activeView) {
      case views.PREFLIGHT: {
        const loading = isPending(preflight.status);

        return (
          <PreflightView
            loading={loading}
            preflight={preflight.data}
            onEditCover={onEditCover}
            onEditSection={onEditSection}
          />
        );
      }

      case views.CHECKOUT: {
        const loading = isPending(credits.status);
        return (
          <CheckoutView
            loading={loading}
            credits={credits.data}
            numberOfPrints={fields.numberOfPrints}
            isDigital={fields.isDigital}
            onSubmit={handleSubmitCheckout}
            isTrialCustomer={isTrialCustomer}
          />
        );
      }

      case views.ADDRESS_LIST:
      case views.ADDRESS_FORM: {
        const loading = [addresses.status, countries.status, states.status].some(isPending);
        const { selectedAddressId } = fields;

        return (
          <AddressView
            loading={loading}
            addresses={addresses.data}
            countries={countries.data}
            states={states.data}
            onSelect={handleSelectAddress}
            onCreate={onCreateAddress}
            onEdit={onEditAddress}
            onDelete={onDeleteAddress}
            activeView={activeView}
            setActiveView={setActiveView}
            selectedAddressId={selectedAddressId}
            formAddressId={formAddressId}
            setFormAddressId={setFormAddressId}
          />
        );
      }

      case views.REVIEW: {
        const { isDigital, numberOfPrints, selectedAddressId } = fields;
        const selectedAddress = find(addresses.data, ({ _id }) => _id === selectedAddressId);
        const isDigitalOnly = numberOfPrints === 0 && isDigital;
        const loading = isLoading(order.status);

        return (
          <ReviewView
            loading={loading}
            numberOfPrints={numberOfPrints}
            selectedAddress={!isDigitalOnly && selectedAddress}
            isDigitalBook={isDigital}
            onEditQuantity={handleEditQuantity}
            onChangeAddress={handleChangeAddress}
            onComplete={handleComplete}
          />
        );
      }

      case views.CONFIRMATION: {
        const orderId = get(order.data, 'orderId', '');
        const shippingAddress = get(order.data, 'shippingAddress');
        const contactPhone = env.SUPPORT_PHONE;
        const contactEmail = env.SUPPORT_EMAIL;

        return (
          <ConfirmationView
            orderId={orderId}
            shippingAddress={shippingAddress}
            contactPhone={contactPhone}
            contactEmail={contactEmail}
            onDone={onDone}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <S.Wrapper>
      {displayWizard && <Wizard view={activeView} fields={fields} onChange={setActiveView} />}
      <Views />
    </S.Wrapper>
  );
};

export default React.memo(ViewRenderer);
