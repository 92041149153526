import styled from 'styled-components';

const ModalContent = styled.div`
  width: 640px;
  min-height: 260px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default { ModalContent, FooterContainer };
