import * as color from 'modules/common/theme/color';
import { bookCoverArray, bookLabelStyles, bookStyles } from './constants';

export const handlePreviewImageChange = (image, showFrontCover) => {
  const imagesStyle = {
    borderRadius: '50%',
    position: 'absolute',
    left: '20px',
    top: '20px',
  };
  if (showFrontCover) {
    switch (image) {
      case 0:
        imagesStyle.width = '70.5px';
        imagesStyle.height = '70.5px';
        imagesStyle.left = '0px';
        imagesStyle.transform = 'matrix(1, -0.2, 0.1, 1, 87, 90)';
        break;

      case 1:
        imagesStyle.width = '79px';
        imagesStyle.height = '80px';
        imagesStyle.transform = 'matrix(1.06, -0.45, 0.3, 1, 64, 166)';
        break;

      case 2:
        imagesStyle.width = '79px';
        imagesStyle.height = '80px';
        imagesStyle.transform = 'matrix(1.06, -0.44, 0.3, 1, 63, 167)';
        break;

      case 3:
        imagesStyle.width = '88px';
        imagesStyle.height = '88px';
        imagesStyle.transform = 'matrix(1.06, -0.44, 0.3, 1, 60, 168)';
        break;

      case 4:
        imagesStyle.width = '100px';
        imagesStyle.height = '102px';
        imagesStyle.transform = 'matrix(1.1, -0.3, 0.2, 1, 53, 65)';
        break;

      default:
        break;
    }
  } else {
    imagesStyle.width = '42px';
    imagesStyle.height = '44px';
    imagesStyle.transform = 'translate(-4px, 125px)';
    imagesStyle.borderRadius = 'unset';
  }
  return imagesStyle;
};

export const getDeviceWidthAndSetValue = (small, tablet, defaultValue) => {
  if (window.innerWidth < 769) {
    return small;
  }
  if (window.innerWidth < 1200) {
    return tablet;
  }
  return defaultValue;
};

export const handleStylingForUserDetailsModal = (coverId) => {
  const imagesStyle = {
    borderRadius: '50%',
    position: 'absolute',
    left: '20px',
    top: '20px',
  };

  switch (coverId) {
    case 0:
      imagesStyle.width = getDeviceWidthAndSetValue('55px', '78px', '98px');
      imagesStyle.height = getDeviceWidthAndSetValue('55px', '75px', '99px');
      imagesStyle.transform = getDeviceWidthAndSetValue(
        'matrix(1, -0.2, 0.1, 1, 128, 62)',
        'matrix(1, -0.2, 0.1, 1, 150, 97)',
        'matrix(1, -0.2, 0.1, 1, 206.5, 137.5)',
      );
      break;

    case 1:
      imagesStyle.width = getDeviceWidthAndSetValue('67px', '97px', '119px');
      imagesStyle.height = getDeviceWidthAndSetValue('67px', '89px', '119px');
      imagesStyle.transform = getDeviceWidthAndSetValue(
        'matrix(1, -0.2, 0.1, 1, 124, 118)',
        'matrix(1, -0.2, 0.1, 1, 141, 175)',
        'matrix(1, -0.26, 0.13, 1, 197, 242)',
      );
      break;

    case 2:
      imagesStyle.width = getDeviceWidthAndSetValue('67px', '96px', '120px');
      imagesStyle.height = getDeviceWidthAndSetValue('62px', '87px', '116px');
      imagesStyle.transform = getDeviceWidthAndSetValue(
        'matrix(1, -0.3, 0.2, 1, 122, 121)',
        'matrix(1, -0.3, 0.2, 1, 141, 177)',
        'matrix(1, -0.3, 0.15, 1, 196, 245)',
      );
      break;

    case 3:
      imagesStyle.width = getDeviceWidthAndSetValue('70px', '105px', '130px');
      imagesStyle.height = getDeviceWidthAndSetValue('70px', '96px', '127px');
      imagesStyle.transform = getDeviceWidthAndSetValue(
        'matrix(1, -0.3, 0.15, 1, 122, 121)',
        'matrix(1, -0.3, 0.15, 1, 138, 177)',
        'matrix(1, -0.3, 0.15, 1, 194, 246)',
      );
      break;

    case 4:
      imagesStyle.width = getDeviceWidthAndSetValue('85px', '120px', '148px');
      imagesStyle.height = getDeviceWidthAndSetValue('85px', '120px', '148px');
      imagesStyle.transform = getDeviceWidthAndSetValue(
        'translate(113px, 38px)',
        'translate(128px, 62px)',
        'translate(181px, 99px)',
      );
      break;

    default:
      break;
  }
  return imagesStyle;
};

export const getBookStyle = (id) => bookStyles.find((styles) => styles.id === id)?.style;

export const getBookById = (id) => bookCoverArray.find((cover) => cover.id === id);

export const handleNameBottom = (image, showFrontCover) => {
  const imagesStyle = {};
  if (showFrontCover) {
    switch (image) {
      case 0:
        imagesStyle.transform = 'matrix(1, -0.15, 0.2, 1, -33, 0)';
        imagesStyle.color = '#427bb3';
        imagesStyle.fontWeight = '700';
        break;

      case 1:
        imagesStyle.transform = 'matrix(1, -0.1, 0.1, 1, -38, -132)';
        imagesStyle.color = color.White;
        imagesStyle.fontWeight = '700';
        break;

      case 2:
        imagesStyle.transform = 'matrix(1, -0.1, 0, 1, -31, -20)';
        imagesStyle.color = color.White;
        imagesStyle.fontWeight = '700';
        break;

      case 3:
        imagesStyle.transform = 'matrix(1, -0.18, 0.2, 1, -35, -10)';
        imagesStyle.color = color.White;
        imagesStyle.fontWeight = '700';
        break;

      case 4:
        imagesStyle.transform = 'matrix(1, -0.18, 0.2, 1, -33, 2)';
        break;

      default:
        break;
    }
  } else {
    imagesStyle.transform = 'matrix(1, -0.15, 0.2, 1, -33, 1)';
  }
  return imagesStyle;
};

export const getBookLabelStyle = (id) => bookLabelStyles.find((styles) => styles.id === id)?.style;

export const bookLabelStylesForModal = (coverId) => {
  switch (coverId) {
    case 0:
      return {
        transform: 'matrix(1, -0.2, 0.2, 1, -20, -3)',
        color: '#427bb3',
      };
    case 1:
      return {
        transform: 'matrix(1, -0.1, 0, 1, -28, -192)',
        color: color.White,
      };
    case 2:
      return {
        transform: 'matrix(1, -0.1, 0.2, 1, -23, -21)',
        color: color.White,
      };
    case 3:
      return {
        transform: 'matrix(1, -0.1, 0.2, 1, -20, -5)',
        color: color.White,
      };
    case 4:
      return {
        transform: 'matrix(1, -0.18, 0.2, 1, -25, -4)',
      };
    default:
      return {};
  }
};
