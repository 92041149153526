type SvgIconProps = {
  stroke?: string;
};

const FrontArrowIcon = ({ stroke = '#252525', ...props }: SvgIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.80005 8H12.4M12.4 8L8.45483 4M12.4 8L8.45483 12"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FrontArrowIcon;
