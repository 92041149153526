import { Route } from 'react-router';

import { authGuard } from 'modules/auth';
import { withGuard } from '../containers';

const AuthenticatedRoute = ({ component, path, exact, children }) => {
  const render = (props) => withGuard(component, authGuard)({ ...props, children });

  return <Route exact={exact} path={path} render={render} />;
};

export default AuthenticatedRoute;
