import styled from 'styled-components';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

export const NoRoutes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: ${color.ActiveBookSectionsBg};
  height: 100vh;

  .contentWrap {
    color: ${color.GreySelectDd};
    max-width: 620px;
    margin: 0 auto;

    h1 {
      font-size: 26px;
      font-weight: bold;
      line-height: 32px;
      font-family: ${font.MontserratFont};
      color: ${color.GreySelectDd};
      margin: 0;
      padding-bottom: 23px;
    }

    .card {
      text-align: center;

      .icon {
        svg {
          margin-top: 30px;
          margin-bottom: 25px;
          height: 90px;
          width: 90px;
          path {
            fill: ${color.SecondaryColor};
          }
        }
      }

      .button {
        padding: 27px 0px;
        display: flex;
        justify-content: center;
        a {
          height: 40px;
          width: 91px;
          display: flex;
          padding: 10px;
          align-items: center;
        }
      }
    }
  }
`;
