import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

const validationSchema = Yup.object().shape({
  subject: validation.templateSubject,
  allSegments: validation.campaignAllSegments,
  segments: validation.campaignSegments,
});

export default validationSchema;
