import env from 'environments';

const urlPrefix = env.GRAPHQL_URL;

const handleThumbnail = (url) => {
  if (url && url.length > 45) {
    return url;
  }
  if (url) {
    return `${urlPrefix}${url}`;
  }
  return null;
};

export const formatBookList = (bookList) => {
  const formattedBookList = bookList.map((book) => {
    return {
      id: book._id,
      name: book.name,
      title: book.title,
      milestone: book.milestone,
      isAllowed: book.isAllowed,
      imageUrl: handleThumbnail(book.thumbnail.url),
      status: book?.links?.status,
    };
  });

  const books = [];
  formattedBookList.forEach((book) => {
    if (book.isAllowed) {
      books.push(book);
    }
  });

  return books;
};
