import { useParams } from 'react-router-dom';
import { getItem } from './legacy';

export const isDentistCustomer = () => {
  const userData = getItem('userData', 'object');
  const { accountType } = userData || {};

  return accountType === 'Dentist';
};

export const isDentistOffer = () => {
  const { offerCode } = useParams();

  // TODO: we should have a property in the offer to identify if it is a dentist offer
  return offerCode === 'dentist-premium';
};
