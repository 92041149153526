import { Button } from 'flowbite-react';
import { BrokerLogoModal } from 'modules/v2/common/components';
import S from '../../styles';

const ImageUploaderModal = ({
  type,
  isOpen,
  imageUrl,
  modalTitle,
  onSuccess,
  onCancel,
  handleOpenModal,
  onRemoveImage,
  imageUrlForEdit,
  isEditImage,
  toggleCancelEdit,
}) => {
  return (
    <div>
      <BrokerLogoModal
        title={modalTitle}
        type={type}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onSuccess}
        imageUrlForEdit={imageUrlForEdit}
        isEditImage={isEditImage}
        toggleCancelEdit={toggleCancelEdit}
      />
      <>
        <div
          className="w-full lg:w-1/2 pr-2 flex justify-start mb-6 max-[600px]:overflow-x-auto"
          onClick={handleOpenModal}
        >
          {imageUrl ? (
            <div className="flex gap-3 items-center">
              {type === 'rm_profile' ? (
                <S.ProfileImage src={imageUrl} alt={type} />
              ) : (
                <S.UploadedImage src={imageUrl} alt={type} />
              )}
              <Button
                color="light"
                onClick={handleOpenModal}
                className="border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50 h-11"
              >
                Change
              </Button>
              <Button
                color="light"
                onClick={onRemoveImage}
                className="border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50 h-11"
              >
                Remove
              </Button>
            </div>
          ) : (
            <div className="w-full gap-4 py-[13px] px-[17px] border border-neutral-300 text-center rounded-lg cursor-pointer">
              Upload photo
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default ImageUploaderModal;
