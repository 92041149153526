export const buildCustomerTemplate = ({ formValues }) => {
  const { templateTitle, imageUrl, user, bodyContent, controls } = formValues;

  const {
    hasHeader,
    hasHeaderTitle,
    hasSignature,
    hasFirstName,
    hasLastName,
    hasMemberBookUrl,
    hasMemberBrokerName,
    hasMemberTitle,
    hasMemberAddress,
    hasEmailAddress,
    hasPhoneNumber,
    hasProfileImage,
  } = controls;

  const getHeader = hasHeader
    ? `
        <thead>
          <tr>
            <th height="212px" class="tg-c3ow" col-span="2">
              <img width="600px" height="200px" src="${imageUrl}" alt="" />
            </th>
          </tr>
        </thead>`
    : '';

  const showFirstName = hasFirstName ? user.firstName : '';
  const showLastName = hasLastName ? user.lastName : '';
  const showFullName = `
      <strong style="font-size: 14px;">
        ${showFirstName} ${showLastName}
      </strong>
      <br />
    `;

  const showMemberTitle = hasMemberTitle
    ? `
        <strong style="font-size: 14px">${user.memberTitle}</strong>
        <br />
        `
    : '';

  const showMemberBrokerName = hasMemberBrokerName
    ? ` 
        <strong style="font-size: 14px;">${user.memberBrokerName}</strong>
        <br />
      `
    : '';

  const showEmail = hasEmailAddress ? `${user.email} <br />` : '';
  const showPhoneNumber = hasPhoneNumber ? `${user.phone}<br />` : '';
  const showMemberAddress = hasMemberAddress ? `${user.memberAddress} <br />` : '';
  const showMemberBookUrl = hasMemberBookUrl ? `${user.memberBookUrl} <br />` : '';
  const showProfileImage = hasProfileImage
    ? ` 
        <td style="width: 100px; text-align: center;"  class="tg-0pky">
          <img width="80px" height="80px" src="${user.avatar}" alt="" /> <br />
        </td>`
    : '';

  const getSignature = hasSignature
    ? `<tr
          style="
            background-color: #1b2f73;
            color: white;
            height: 205px;
            padding: 40px;
            display: block;
            font-size: 18px;
            line-height: 22px;"
        >
          <td class="tg-0pky" style="width: 360px; font-size: 13px;">
            ${showFullName}
            ${showMemberTitle}
            ${showMemberBrokerName}
            ${showEmail}
            ${showPhoneNumber}
            ${showMemberAddress}
            ${showMemberBookUrl}
          </td>
          ${showProfileImage}
        </tr>`
    : '';

  const showTitle = hasHeaderTitle
    ? `
        <tr>
          <td
            height="94px"
            class="tg-c3ow"
            col-span="2"
            style="text-align: center; font-size: 32px; font-weight: bold"
          >
            ${templateTitle}
          </td>
        </tr>`
    : '';

  const getBodyContent = `<tr>
      <td
        class="tg-c3ow"
        style="min-height: 170px; display: block; padding: 0 40px; font-size: 18px; line-height: 24px;" col-span="2">
        ${bodyContent}
      </td>
    </tr>`;

  const getCustomStyling = `
    body {
      background-color: '#ccc';
    }
    .tg {
        border-collapse: collapse;
        border-spacing: 0;
        margin: 0 auto;
        font-family: Arial, sans-serif;
      }
    .tg td {
        border: none;
        font-size: 14px;
        overflow: hidden;
        padding: 10px 5px;
        word-break: normal;
      }
    .tg th {
        border: none;
        border-style: solid;
        border-width: 1px;
        overflow: hidden;
        padding: 10px 5px;
        word-break: normal;
      }
    .tg .tg-c3ow {
        border: none;
      }
    .tg .tg-0pky {
      border-color: inherit;
    }`;

  return `
      <html lang="en">
        <head>
          <meta char-set="utf-8" />
          <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
          <title>${templateTitle}</title>
          <style type="text/css">${getCustomStyling}</style>
        </head>
        <body>
          <table
            class="tg"
            frame-border="0"
            style="background: white; border: none; width: 600px;" 
          >
            ${getHeader}
            <tbody>
              ${showTitle}
              ${getBodyContent}
              ${getSignature}
            </tbody>
          </table>
        </body>
      </html>`;
};

export default buildCustomerTemplate;
