import * as Yup from 'yup';

const phoneNumber = Yup.string()
  .transform((value, originalValue) => {
    return originalValue ? originalValue.replace(/-/g, '') : originalValue;
  })
  .trim()
  .required('Phone number is required')
  .matches(/^(?!.*_).+$/, 'Phone number must be valid');

export default phoneNumber;
