import { Wait } from 'modules/onboard/components';
import { useStep } from 'modules/onboard/hooks';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { getStep } from '../../../api';
import { getSession, setSession } from '../../utils';
import Welcome from '../../components/Welcome';
import { addShippingAddress } from '../../../api/onboardShippingAddress';

const AccountWait = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pooling, setPooling] = useState(true);
  const { offerCode } = useParams<{ offerCode: string }>();

  const session = getSession(offerCode);
  const { offer, id, step } = session;
  const { goToNextStep } = useStep();

  // Go to next step
  function handleNextStep() {
    setSession(Object.assign(session, { step: 'done' }));
    goToNextStep('done');
  }

  const { mutateAsync: mutateShippingAddress } = useMutation(addShippingAddress, {
    onSuccess: () => {
      localStorage.removeItem('onboard-shipping-address');
    },
  });

  // Pooling until the step is done
  useQuery('pooling', () => getStep(offer.code, id), {
    enabled: !!pooling,
    onSuccess: (result) => {
      try {
        const shippingAddress = JSON.parse(localStorage.getItem('onboard-shipping-address'));
        mutateShippingAddress(shippingAddress);
      } catch (error) {
        console.error('Error adding shipping address:', error);
      }

      if (step !== result.data) {
        setPooling(false);
        setIsLoading(false);
      }
    },
    refetchInterval: 15000,
  });

  return (
    <>
      {isLoading ? (
        <Wait title="Waiting" description="Please wait while we create your account." />
      ) : (
        <Welcome callback={() => handleNextStep()} />
      )}
    </>
  );
};

export default AccountWait;
