import styled from 'styled-components';
import { Carousel } from 'antd';
import { device } from 'modules/common/theme/mediaqueries';

import * as color from 'modules/common/theme/color';
import { PrimaryBlackButton, PrimaryButton } from 'modules/common/components/UIComponents';

const ScheduleHead = styled.h2`
  font-weight: bold;
  font-size: 24px !important;
  line-height: 24px !important;
  text-align: center;
  padding: 10px 0;
  color: ${color.Black};

  @media (max-width: 768px) {
    padding: 10px 15px;
    font-size: 20px !important;
  }
`;

const ScheduleBodyPicker = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 0 15px;
    flex-wrap: wrap;
  }
`;

const ScheduleDate = styled.div`
  width: 100%;
  padding-right: 20px;
  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`;

const ScheduleDateHead = styled.h4`
  font-size: 18px !important;
  line-height: 24px !important;
  color: ${color.Black};
  font-weight: bold !important;
  text-align: center;
`;

const ScheduleTimeZone = styled.p`
  color: ${color.TimezoneGray};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 4px;
`;

const ScheduleDateSubHeading = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 40px;
`;

const ScheduleTimeBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScheduleTimeWrap = styled.div`
  display: block;
`;

const ScheduleMeetingCards = styled.div`
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const ScheduleButton = styled.div`
  text-align: center;
  padding-top: 30px;

  @media (max-width: 768px) {
    margin-top: 25px;
  }
`;

const ResultWrap = styled.div`
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
`;

const ScheduleCardInfo = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ScheduleCardImage = styled.div`
  margin-right: 20px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
  svg {
    width: 100px;
    height: 100px;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
`;

const ScheduleCardDescription = styled.div`
  padding-right: 10px;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const ScheduleCardHeading = styled.h4`
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: ${color.Black};
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const SchedulePosition = styled.p`
  font-weight: regular;
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  margin-top: 2px !important;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ScheduleSubHeading = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  margin-top: 10px !important;
`;

const NewCoachButton = styled(PrimaryButton)`
  margin-top: 10px;
  font-size: 16px;
  height: 32px;
`;

const SubmitButton = styled(PrimaryButton)`
  margin: 20px auto;
`;

const SlotsList = styled.div`
  display: inline-flex !important;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-bottom: 50px;

  @media ${device.tabletS} {
    display: flex !important;
    justify-content: center;
  }
`;

const SlotCarousel = styled(Carousel)`
  margin: 0 auto;
  width: 600px;

  @media ${device.tabletS} {
    width: 100%;
  }

  .slick-dots {
    li {
      & {
        button {
          background-color: ${color.DefaultBlue}!important;
          border-radius: 50%;
          height: 15px;
          width: 15px !important;
          margin-right: 10px;
        }
      }
      &.slick-active {
        button {
          background: ${color.ActiveBlue};
        }
      }
    }
  }
`;

const Slot = styled.div`
  color: ${color.grayBox};
  border: ${({ $isSelectedSlot }) =>
    $isSelectedSlot ? '1.5px #0096C7 solid' : `1.5px solid ${color.GrayBorder}`};
  transition: all 0.4s;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  width: 282px;
  height: 60px;
  font-size: 12px !important;
  padding: 0 5px;
  text-transform: uppercase;

  &:hover {
    border: 1.5px solid #0096c7;
  }

  @media ${device.tabletS} {
    padding: 0;
    width: 230px;
  }
`;

const SlotDay = styled.div`
  flex: 1;
  padding-left: 10px;
`;

const SlotHour = styled.div`
  padding: 0 10px;
  text-align: center;
`;

const SlotsNavigation = styled.div`
  display: flex;
  justify-content: center;
`;

const SlotDatePreview = styled.span`
  font-size: 12px;
`;

const ButtonStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  margin-top: 30px;

  @media ${device.tabletS} {
    justify-content: center;
  }
`;

const UpperCase = styled.span`
  text-transform: uppercase;
  font-weight: normal;
`;

export default {
  ScheduleHead,
  ScheduleBodyPicker,
  ScheduleDate,
  ScheduleDateHead,
  ScheduleDateSubHeading,
  ScheduleTimeZone,
  ScheduleTimeBox,
  ScheduleTimeWrap,
  ScheduleMeetingCards,
  ScheduleButton,
  ResultWrap,
  ScheduleCardInfo,
  ScheduleCardImage,
  ScheduleCardDescription,
  ScheduleCardHeading,
  SchedulePosition,
  ScheduleSubHeading,
  NewCoachButton,
  SlotsList,
  Slot,
  SlotCarousel,
  SlotDay,
  SlotHour,
  SlotsNavigation,
  SubmitButton,
  SlotDatePreview,
  ButtonStepsContainer,
  UpperCase,
};
