import { Children } from 'react';
import { UserAvatar } from 'modules/common/components';
import { CaretDownOutlined } from '@ant-design/icons';
import { DefaultAvatar } from 'modules/common/components/SvgIcon';
import { Item } from 'modules/nav/components/NavBar/components/Menu/components';
import { Version } from './components';

import { getVersion } from './utils';
import S from './styles';

const UserMenu = ({ username, email, avatar, children }) => {
  const version = getVersion();

  const dropdownList = (
    <S.Menu>
      <S.Menu.Item>
        <S.DropdownAvatar>
          <UserAvatar avatar={avatar} defaultAvatar={<DefaultAvatar />} size="32px" scale={0.5} />
        </S.DropdownAvatar>
        <S.Details>
          <S.Details.Name>{username}</S.Details.Name>
          <S.Details.Email title={email}>{email}</S.Details.Email>
        </S.Details>
      </S.Menu.Item>
      {Children.map(children, (child) => {
        return child && <S.Menu.Item className="child-item">{child}</S.Menu.Item>;
      })}

      {version && (
        <S.Menu.Item disabled>
          <Version version={version} />
        </S.Menu.Item>
      )}
    </S.Menu>
  );

  return (
    <S.Dropdown trigger={['click']} dropdownRender={() => dropdownList}>
      <S.Button tabIndex="0">
        <S.Button.Label>
          <S.AvatarWrapper>
            <UserAvatar avatar={avatar} defaultAvatar={<DefaultAvatar />} size="32px" scale={0.5} />
          </S.AvatarWrapper>
          <S.UserName>{username}</S.UserName>
        </S.Button.Label>
        <S.Button.Icon>
          <CaretDownOutlined />
        </S.Button.Icon>
      </S.Button>
    </S.Dropdown>
  );
};

UserMenu.Item = Item;

export default UserMenu;
