import { combineReducers } from 'redux';

import { CLEAR_DRAFT } from 'modules/v2/draft/store/constants';

import initialState from './initialState';
import links from './links';
import draft from './draft';
import preflight from './preflight';
import preview from './preview';

const combined = combineReducers({
  links,
  draft,
  preflight,
  preview,
});

const reducer = (state = initialState, action) => {
  if (action.type === CLEAR_DRAFT) {
    return combined({ ...initialState }, action);
  }

  return combined(state, action);
};

export default reducer;
