import styled from 'styled-components';

import * as color from 'modules/common/theme/color';

const Wrapper = styled.div``;

Wrapper.Flex = styled.div`
  display: flex;
  align-items: center;
`;

Wrapper.Flex.Inner = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100vh - 130px);
  position: relative;
  justify-content: center;
  border: 0;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 10px -6px ${color.neutral200};
  -moz-box-shadow: 0 10px 10px -6px ${color.neutral200};
       box-shadow: 0 10px 10px -6px ${color.neutral200};
`;

Wrapper.CoverImageWrap = styled.div`
  align-items: center;
  background-color: ${color.DisabledBookSectionsBg};
  display: flex;
  height: 100%;
  max-height: 100%;
`;

Wrapper.SectionSortingWrap = styled.div`
  height: 100%;
  flex: 1;
`;

Wrapper.SectionSortingWrap.Inner = styled.div`
  border: 3px solid ${color.GreyBookBorder}
  background-color: ${color.DisabledBookSectionsBg};
  height: 100%;
  float: left;
  width: 100%;
  overflow-y: hidden;
`;

Wrapper.ContentWrap = styled.div`
  flex: 1;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 500px;
  }
`;

Wrapper.ContentWrap.Inner = styled.div`
  width: 100%;
  height: 100%;
`;

const Page = styled.div`
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default { Page, Wrapper };
