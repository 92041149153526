import { Input } from 'modules/common/components/UIComponents';
import styled from 'styled-components';
import { DatePicker as DatePickerBase } from 'antd';
import { LineOutlined, MoreOutlined } from '@ant-design/icons';
import * as color from 'modules/common/theme/color';

const { RangePicker } = DatePickerBase;

const CoantainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 22px;
  gap: 16px;
  span {
    margin: 0;
  }
`;

const Title = styled.h3`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 20px 0 10px 0 !important;
  height: 40px;
  display: flex;
  align-items: center;
`;

const DatePicker = styled(RangePicker)`
  margin: 10px 0 20px 0;

  .ant-calendar-picker-input.ant-input {
    width: 316px;
    height: 40px;
  }
`;

const CardsReport = styled.div`
  width: 100%;
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;

  width: 100%;
  margin-bottom: 20px;

  .selected {
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: all 0.3s ease;
  }

  .ant-card {
    width: calc((100% - 60px) / 5);
    min-width: 185px;
    cursor: pointer;
  }

  .ant-card-head {
    background: ${color.TableHeader};
  }

  .ant-card-head-title {
    font-weight: bold;
    padding: 10px 0 !important;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 145px;
  }
`;

const PercentageContainer = styled.div`
  position: absolute;
  top: 60px;
  left: calc(50% + 60px);
  width: 100%;
  justify-content: flex-end;

  .ant-progress-inner {
    width: 44px !important;
    height: 44px !important;
    font-size: 14px !important;
  }
`;

const FocusedCard = styled.p`
  color: ${color.Black};
  font-size: 32px;
  font-weight: bold;
`;

const DescriptionCard = styled.p`
  color: ${color.Black};
  font-size: 16px;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const EmptyTableAtribute = styled(LineOutlined)`
  font-size: 20px;
  color: ${color.EmptyAtribute};
  margin-left: 20%;
`;

const CapitalizeSpan = styled.span`
  text-transform: capitalize;
`;

const AutoLoginSpan = styled.span`
  margin-left: 20%;
  .checkOutlined {
    color: ${color.Success};
  }
  .closeOutlined {
    color: ${color.Failure};
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 4px 0;
  color: ${color.PrimaryColor};
  cursor: pointer;

  :hover {
    color: ${color.SecondaryColor};
  }
`;

const ActionIcon = styled(MoreOutlined)`
  transform: rotate(90deg);
  cursor: pointer;
`;

const FilterInput = styled(Input)`
  height: 30px;
`;

const SearchButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ButtonWrapper = styled.div`
  margin-left: 19px;
  margin-bottom: 19px;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
`;

const MultipleSelect = styled.div`
  .ant-select-selector {
    min-width: 336px;
    max-width: 624px;
    overflow: hidden;
  }
`;

export default {
  CoantainerWrapper,
  HeaderContainer,
  Title,
  DatePicker,
  CardsReport,
  CardWrapper,
  PercentageContainer,
  FocusedCard,
  DescriptionCard,
  TableWrapper,
  CapitalizeSpan,
  EmptyTableAtribute,
  AutoLoginSpan,
  ActionsWrapper,
  ActionIcon,
  FilterInput,
  ButtonWrapper,
  SearchButtonWrapper,
  SearchContainer,
  MultipleSelect,
};
