import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First Name is required'),
  lastName: Yup.string()
    .trim()
    .required('Last Name is required'),
  phone: Yup.string()
    .trim()
    .required('Phone Number is required')
    .min(10, 'Phone number must be at least 10 characters long')
    .max(17, 'Phone number must be at most 17 characters long')
    .matches(/^(\+?1[ -]?)?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4,})( x\d{4})?$/, {
      message: 'Please enter a valid Phone Number',
      excludeEmptyString: false,
    }),
});

export default validationSchema;
