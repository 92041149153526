import { DualAxes } from '@ant-design/plots';
import { NoContent } from 'modules/common/components';
import { formatDateTime2, formatDateTime3 } from 'modules/common/utils';
import Tooltip from '../../components/Tooltip';
import { findMetricItem } from '../../utils';

const Chart = ({ chartData, dateRange }) => {
  const customTooltip = (title, items) => {
    return <Tooltip title={title} items={items} />;
  };

  const hasData = chartData[0]?.hasData;
  if (!hasData) {
    return (
      <NoContent
        message={`You did not send any emails from ${formatDateTime3(
          dateRange[0],
        )} - ${formatDateTime2(dateRange[1])}. Please select a different time frame.`}
      />
    );
  }

  const { columnData, lineData } = chartData[0];

  const config = {
    data: [columnData, lineData],
    xField: 'period',
    yField: ['Send', 'count'],
    geometryOptions: [
      {
        geometry: 'column',
        columnWidthRatio: 0.4,
      },
      {
        geometry: 'line',
        seriesField: 'name',
        lineStyle: ({ name }) => {
          if (findMetricItem(name)) {
            return {
              lineWidth: 2,
              stroke: findMetricItem(name).color,
            };
          }
          return {
            lineWidth: 2,
          };
        },
      },
    ],
    tooltip: {
      shared: true,
      showMarkers: false,
      customContent: customTooltip,
    },
    legend: false,
  };

  return <DualAxes {...config} style={{ width: '100%' }} />;
};

export default Chart;
