import axios from 'axios';

import configureStore from 'store/configureStore';
import getMiddlewares from 'store/middlewares';
import getReducers from 'store/reducers';
import history from 'store/history';
import sagas from 'store/sagas';

import { configureAxios } from './axios';
import graphql from './graphql';

const reducers = getReducers(history);
const middlewares = getMiddlewares(axios, graphql, history);

const store = configureStore(reducers, sagas, middlewares);

configureAxios(store, axios);

export default store;
