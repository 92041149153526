import { useEffect } from 'react';
import { compose } from 'redux';
import env from 'environments';
import { withProfile } from 'modules/v2/containers';
import { Box } from 'modules/v2/common/AtomicDesign/atoms';

function ScheduleCoachingCall({ profile, history }) {
  const appendHubspotWidgetScript = () => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    const cleanup = appendHubspotWidgetScript();
    return () => {
      cleanup();
    };
  }, []);

  const { firstName, lastName, email, phone } = profile?.data;
  const meetingUrl = `${env.VITE_MEETING_SCHEDULE_STEP_TOOL_URL}&email=${email}&firstname=${firstName}&lastname=${lastName}&phone=${phone}`;

  return (
    <div className="flex flex-col mt-8 gap-8 bg-neutral-50 h-full">
      <Box>
        <div className="flex flex-col justify-center items-start w-full">
          <div className="flex gap-3 text-base font-semibold px-6 py-[22px] text-boxTitle border-b border-neutral-200 w-full">
            (Bonus) Schedule Your VIP Coaching Call
          </div>
          <div className="px-6 py-[30px] w-full">
            <p className="font-medium text-neutral-600 mb-5">
              Pick the time on the calendar below that works best for you.
            </p>
            <div className="meetings-iframe-container" data-src={meetingUrl}></div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default compose(withProfile)(ScheduleCoachingCall);
