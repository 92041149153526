import { call, put, takeEvery } from 'redux-saga/effects';
import { get, noop } from 'lodash-es';

// TODO: Filter the error globally
export const getPayload = (response) => get(response, ['data', 'data'], response.data);
export const getError = (response) => get(response, ['data'], response);
export const getStatus = (response) => get(response, ['status']);
export const getData = (response) => get(response, ['data'], response);

export const resource = (
  actionType,
  fetch = noop,
  filterPayload = getPayload,
  filterError = getError,
) =>
  function* generator(meta) {
    yield put({
      type: `${actionType}_REQUESTED`,
      meta,
    });

    try {
      const response = yield call(fetch, meta);
      const payload = filterPayload(response);

      yield put({
        type: `${actionType}_SUCCEEDED`,
        error: false,
        payload,
        meta,
      });

      return payload;
    } catch (error) {
      const payload = filterError(error);

      yield put({
        type: `${actionType}_FAILED`,
        error: true,
        payload,
        meta,
      });

      throw payload;
    }
  };

export const resourceData = (
  actionType,
  fetch = noop,
  filterPayload = getData,
  filterError = getError,
) =>
  function* generator(meta) {
    yield put({
      type: `${actionType}_REQUESTED`,
      meta,
    });

    try {
      const response = yield call(fetch, meta);
      const payload = filterPayload(response);

      yield put({
        type: `${actionType}_SUCCEEDED`,
        error: false,
        payload,
        meta,
      });

      return payload;
    } catch (error) {
      const payload = filterError(error);

      yield put({
        type: `${actionType}_FAILED`,
        error: true,
        payload,
        meta,
      });

      throw payload;
    }
  };

// TODO: Abstract sagas into the consumer instead of using directly the resource
export function* consumer(actionType, fetch) {
  function* generator({ payload }) {
    const request = resource(actionType, fetch);
    yield call(request, payload);
  }

  yield takeEvery(actionType, generator);
}
