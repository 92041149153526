import { getInstance } from './instance';

const instance = getInstance();
const CUSTOMER_TEMPLATES_URL = '/email-campaigns/customer-templates';

export const getCustomerTemplatesDropdown = () => {
  const url = `${CUSTOMER_TEMPLATES_URL}/dropdown/list`;

  return instance.get(url);
};

export const getCustomerTemplates = () => {
  const url = CUSTOMER_TEMPLATES_URL;

  return instance.get(url);
};

export const getCustomerTemplate = (id) => {
  const url = `${CUSTOMER_TEMPLATES_URL}/${id}`;

  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const createCustomerTemplate = (payload) => {
  const url = CUSTOMER_TEMPLATES_URL;

  return instance
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const editCustomerTemplate = async ({ id, data }) => {
  const url = `${CUSTOMER_TEMPLATES_URL}/${id}`;

  try {
    return await instance.patch(url, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteCustomerTemplates = async (id) => {
  const url = `${CUSTOMER_TEMPLATES_URL}/${id}`;

  try {
    return await instance.delete(url);
  } catch (error) {
    return Promise.reject(error);
  }
};
