import { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Card, Col, Row, Typography } from 'antd';
import { get } from 'lodash-es';

import env from 'environments';

import {
  getDefaultPaymentProfile,
  getActiveSubscriptions,
  getPaymentProfilesList,
  getStripePaymentMethods,
  getSubscription,
  getSubscriptionService,
} from 'modules/api';

import { ErrorState } from 'modules/v2/common/components';
import { formatDateWithTimeZone, notification } from 'modules/v2/common/utils';
import { BillingSideNav } from 'modules/v2/components';
import cardDetails from 'modules/v2/pages/PaymentSteps/payments';
import { centsToDollar } from 'modules/v2/pages/Plans/utils';
import { getRoutePayments } from 'modules/v2/routes/navigation';
import { DashboardTemplate } from 'modules/v2/templates';

import PaymentMethodCard from 'modules/v2/pages/PaymentSteps/components/PaymentMethodCard';
import CurrentPlanDetails from 'modules/v2/pages/Plans/components/CurrentPlanDetails';
import OrderConfirmationModal from 'modules/v2/pages/Plans/components/OrderConfirmation';
import ReviewAndPayModal from 'modules/v2/pages/Plans/components/ReviewAndPay';
import UpgradePlanModal from 'modules/v2/pages/Plans/components/UpgradePlan';
import { PlansContext } from 'modules/v2/pages/Plans/contexts/UpgradePlan';

import S from '../../styles';

const { Title, Text } = Typography;

export const PaymentPlans = () => {
  const history = useHistory();
  const {
    toggleUpgradePlanModal,
    setPaymentProfiles,
    setDefaultProfile,
    setSubscription,
    setIsContracted,
    setIsTrialing,
    isTrialing,
    setStripePaymentProfiles,
    setIsStripeCustomer,
  } = useContext(PlansContext);
  const { data: subscription = {}, isFetching: isFetchingPlan } = useQuery(
    ['getSubscription'],
    getActiveSubscriptions,
    {
      retry: 0,
      onError: (error) => {
        const errorMessage =
          error.response?.data?.errors?.[0] || error?.data?.message || 'Network Error';
        notification.error({
          description: `${errorMessage}. Please call our customer service at ${env.SUPPORT_PHONE}.`,
          autoClose: false,
        });
      },
    },
  );

  const { data: { value: isSubscriptionFromStripe } = {}, isError: hasNoSubscriptionService } =
    useQuery(['getSubscriptionService'], getSubscriptionService, {
      onSuccess: (data) => {
        setIsStripeCustomer(data.value);
      },
    });

  const { data: subFromStripe } = useQuery(['getSubscriptionFromStripe'], getSubscription);

  const amount = centsToDollar(subscription?.component?.unitPrice * 100);
  const stripePlanName = get(subFromStripe, 'attributes.planName', 'Trialing Plan');
  const subscriptionPlanName = isSubscriptionFromStripe
    ? stripePlanName
    : get(subscription, 'component.name', stripePlanName);
  const subscriptionInterval = get(subscription, 'product.interval_unit', '');
  const isMonthly = subscriptionInterval === 'month';
  const currentPlanRenewalDate = formatDateWithTimeZone(
    subscription?.current_period_ends_at,
    'MMMM D, YYYY',
    'America/New_York',
  );

  const { isLoading: isPaymentLoading, refetch } = useQuery(
    'getPaymentProfilesList',
    getPaymentProfilesList,
    {
      onSuccess: (data) => {
        if (data?.length) {
          const filteredData = data.filter((item) => item.masked_card_number !== null);
          setPaymentProfiles(filteredData);
        }
      },
    },
  );

  useQuery('getStripePaymentMethods', getStripePaymentMethods, {
    onSuccess: (payments) => {
      if (payments?.data?.length) {
        setStripePaymentProfiles(payments.data);
      }
    },
  });

  const { data: defaultProfile } = useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile, {
    onSuccess: (data) => {
      setDefaultProfile(data);
    },
  });

  const { id: idPayment, card_type: card, masked_card_number: cardNumber } = defaultProfile || {};

  const isPageLoading = isFetchingPlan;
  const handleCardChangeClick = () => {
    const paymentRoute = getRoutePayments();
    history.push(paymentRoute);
  };

  useEffect(() => {
    if (subscription?.component) {
      setSubscription(subscription);
      const subscriptionStatus = get(subscription, 'state');
      if (!isSubscriptionFromStripe) {
        setIsTrialing(subscriptionStatus === 'trialing');
      }
      setIsContracted(!subscription?.product?.name?.includes('Non Contracted'));
    }
  }, [subscription, setSubscription, setIsContracted]);

  return (
    <DashboardTemplate
      sidebar={<BillingSideNav />}
      isLoading={isPageLoading && !hasNoSubscriptionService}
      hasSidebar
      isError={hasNoSubscriptionService}
      error={
        <ErrorState
          topMessage="Our system encountered an error while checking your Chargify Plan."
          bottomMessage={
            <>
              Please call our customer service at <b>{env.SUPPORT_PHONE}</b>.
            </>
          }
        />
      }
    >
      <S.MainContain>
        <S.TopWrap>
          <S.Section>
            <S.Title>Plans</S.Title>
          </S.Section>
          <Row gutter={20}>
            <Col span={16}>
              <Card>
                <S.Section>
                  <S.Space direction="vertical" size={0}>
                    <S.Space direction="horizontal">
                      <div>
                        <div>
                          {!isTrialing ? (
                            <Text type="secondary">{isMonthly ? 'MONTHLY' : 'YEARLY'}</Text>
                          ) : (
                            <Text type="secondary">Trialing Only</Text>
                          )}
                        </div>
                        <S.PlanName strong>{subscriptionPlanName}</S.PlanName>
                        <br />
                      </div>
                      {!isTrialing && (
                        <S.UpgradeButton onClick={toggleUpgradePlanModal}>
                          UPGRADE PLAN
                        </S.UpgradeButton>
                      )}
                    </S.Space>
                    <UpgradePlanModal />
                    <ReviewAndPayModal refetchPaymentProfile={refetch} />
                    <OrderConfirmationModal />
                    {!isTrialing && !isSubscriptionFromStripe && (
                      <Title level={5}>
                        ${amount} / {isMonthly ? 'month' : 'year'}
                      </Title>
                    )}
                  </S.Space>
                </S.Section>
                {!isSubscriptionFromStripe && <CurrentPlanDetails />}

                <S.Section>
                  {isTrialing ? (
                    <Text type="secondary">
                      You are currently a trialing member. Licensed books, digital books, printed
                      book credits, and lead capture websites will only replenish if your trial
                      ended. You can upgrade your plan after trialing.
                    </Text>
                  ) : (
                    <Text type="secondary">
                      Your licensed books, digital books, and lead capture websites will replenish
                      every monthly cycle. If you want to increase your limit, you can{' '}
                      <S.FakeLink onClick={toggleUpgradePlanModal}>upgrade your plan</S.FakeLink>.
                    </Text>
                  )}
                </S.Section>
              </Card>
            </Col>
            {!isSubscriptionFromStripe && (
              <Col span={8}>
                <Card
                  cover={
                    <S.BillingCycleSection>
                      <Title level={5}>BILLING CYCLE</Title>
                      <Text strong>Next Billing: {currentPlanRenewalDate}</Text>
                      <br />
                      <Title level={4}>
                        ${amount} / {isMonthly ? 'month' : 'year'}
                      </Title>
                    </S.BillingCycleSection>
                  }
                  loading={isPaymentLoading}
                >
                  <Card.Meta
                    title="Payment Method"
                    description="Change how you pay for your plan"
                  />
                  {card && (
                    <PaymentMethodCard
                      key={idPayment}
                      card={card}
                      cardNumber={cardNumber}
                      bordered={false}
                      image={cardDetails[card]}
                      getStep={handleCardChangeClick}
                    />
                  )}
                </Card>
              </Col>
            )}
          </Row>
        </S.TopWrap>
      </S.MainContain>
    </DashboardTemplate>
  );
};

export default PaymentPlans;
