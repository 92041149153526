import withStore from 'store/StoreContainer';

import {
  updatePreferences,
  updatePreferenceStatusByKey,
  updatePreferenceByKey,
  preferencesSelector,
} from 'modules/editor/store';

const mapStateToProps = (state) => ({
  preferences: preferencesSelector(state),
});

const mapDispatchToProps = {
  updatePreferences,
  updatePreferenceStatusByKey,
  updatePreferenceByKey,
};

export default withStore(mapStateToProps, mapDispatchToProps);
