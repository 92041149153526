import templateBodyContent from './templateBodyContent';
import templateContent from './templateContent';
import templateEmail from './templateEmail';
import templateName from './templateName';
import templateSubject from './templateSubject';
import templateTitle from './templateTitle';

export default {
  templateBodyContent,
  templateContent,
  templateEmail,
  templateName,
  templateSubject,
  templateTitle,
};
