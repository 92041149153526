import getInstance from 'modules/api/instanceBBA';

const instance = getInstance();

export const restoreVersions = (payload) => {
  return instance
    .patch(`/draft/update-active-draft/${payload.bookId}/${payload.draftId}`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};
