import { CustomFlowbiteTheme, Modal } from 'flowbite-react';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { CloseCircleIcon } from 'modules/v2/common/components/SvgIcon';

const EditBookModal = ({
  children,
  onCancel,
  onConfirm,
  isOpen,
  size = '2xl',
  title,
  saveButtonDisabled = false,
}) => {
  const customTheme: CustomFlowbiteTheme['modal'] = {
    body: {
      base: `flex-1 overflow-auto ${title && 'pt-6 px-6'}`,
    },
    content: {
      inner: 'bg-white rounded-lg overflow-hidden ',
    },
    footer: {
      base: 'py-5 px-5 ',
      popup: 'border-none',
    },
  };

  return (
    <Modal show={isOpen} theme={customTheme} size={size}>
      <Modal.Body>
        {title && (
          <div className="mb-4 pb-3 flex justify-between items-center font-bold border-b border-neutral-200">
            <span className="text-base font-bold">{title}</span>
            <div
              className="p-2.5 bg-neutral-100 rounded-md cursor-pointer hover:bg-neutral-200"
              onClick={onCancel}
            >
              <CloseCircleIcon />
            </div>
          </div>
        )}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex items-center gap-2 justify-end ">
          <Button onClick={onCancel} type="outlined">
            Cancel
          </Button>
          <Button onClick={onConfirm} disabled={saveButtonDisabled} type="dark">
            Save changes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditBookModal;
