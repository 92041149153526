import { useQuery } from 'react-query';

import { get } from 'lodash-es';
import { BarChartOutlined, MailOutlined, TeamOutlined } from '@ant-design/icons';

import {
  ROUTE_BOOK_PERFORMANCE,
  ROUTE_LEADS,
  ROUTE_ALL_CAMPAIGN_METRICS,
} from 'modules/dashboard/routes/constants';

import { getStats, getLeadsList, getEmailActiveCampaigns } from 'modules/api';

import { formatDate } from 'modules/common/utils';
import * as color from 'modules/common/theme/color';

import MetricCard from 'modules/dashboard/components/MetricCard';
import S from '../../styles';

const AppsContainer = () => {
  const newDate = new Date();
  const startDate = formatDate(newDate.setDate(newDate.getDate() - 90), 'YYYY-MM-DD');
  const endDate = formatDate(new Date(), 'YYYY-MM-DD');

  const { data: bookAnalyticsData, isLoading: isBookAnalyticsLoading } = useQuery(
    ['gettingStats', { startDate, endDate }],
    () => getStats(startDate, endDate),
  );
  const bookAnalytics = get(bookAnalyticsData, 'data', []);
  const { value: bookAnalyticsValue } = bookAnalytics[1] || {};

  const perPage = 1;
  const page = 1;
  const sortBy = '';
  const filter = '';
  const { isLoading: isLeadsLoading, data: leadsData } = useQuery(
    ['gettingLeads', { perPage, page, sortBy, filter }],
    () => getLeadsList(perPage, page, sortBy, filter),
    { keepPreviousData: true },
  );
  const leadsManagement = get(leadsData, 'data.meta', {});
  const { total: leadsManagementValue } = leadsManagement || {};

  const {
    isLoading: isEmailCampaignLoading,
    data: emailCampaignData,
  } = useQuery('getEmailActiveCampaigns', getEmailActiveCampaigns, { keepPreviousData: true });
  const emailCampaign = get(emailCampaignData, 'meta', []);
  const { total: emailCampaignValue } = emailCampaign || {};

  return (
    <>
      <S.Title>Apps</S.Title>
      <S.Container>
        <MetricCard
          icon={<BarChartOutlined />}
          title="Book analytics"
          description="Get data on how many visited your landing page, digital book, and clients' conversion."
          metricColor={color.MetricDarkBlue}
          metricValue={bookAnalyticsValue}
          metricDescription="Landing Page Conversion"
          buttonText="View Book Analytics"
          link={ROUTE_BOOK_PERFORMANCE}
          isLoading={isBookAnalyticsLoading}
        />

        <MetricCard
          icon={<TeamOutlined />}
          title="Leads Management"
          description="Add, delete, import and export your leads, view lead email history and status."
          metricColor={color.MetricLightBlue}
          metricValue={leadsManagementValue}
          metricDescription="Total Leads"
          buttonText={leadsManagementValue > 0 ? 'View Leads' : 'Add Leads'}
          link={ROUTE_LEADS}
          isExternalLink={false}
          isLoading={isLeadsLoading}
        />

        <MetricCard
          icon={<MailOutlined />}
          title="Email Campaign"
          description="Send an email campaign to your prospect clients using on-demand, onboarding and nurture campaign."
          metricColor={color.MetricLightMustard}
          metricValue={emailCampaignValue}
          metricDescription="Emails Sent In 30 Days"
          buttonText={emailCampaignValue > 0 ? 'View Email Campaign' : 'Create Email Campaign'}
          link={ROUTE_ALL_CAMPAIGN_METRICS}
          isExternalLink={false}
          isLoading={isEmailCampaignLoading}
        />
      </S.Container>
    </>
  );
};

export default AppsContainer;
