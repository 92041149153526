import { Route } from 'react-router';

// TODO: we are using the same guards from Onboard for now.
import { onboardGuard } from 'modules/auth';
import { withOnboardGuard } from '../containers';

const DentistRoute = ({ component, path, exact, children }) => {
  const render = (props) =>
    withOnboardGuard(component, onboardGuard)({ ...props, children, isDentist: true });

  return <Route exact={exact} path={path} render={render} />;
};

export default DentistRoute;
