import { IInitialValues } from 'modules/formBuilder/types';
import { getInstance } from './instance';

const instance = getInstance();

export const sendPayment = (payload: IInitialValues) => {
  const url = 'subscriptions/';

  return instance.post(url, payload);
};

export type CustomerInfo = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};

export type ProductInfo = {
  product_handle: string;
  product_price_point_handle: string;
};

export type CreditCardInfo = {
  first_name: string;
  last_name: string;
  card_type: string;
  expiration_month: string;
  expiration_year: string;
  last_four: string;
  vault_token: string;
  current_vault: string;
  customer_vault_token: null | string;
};

export type OrderDetails = {
  customer_id: number;
  product: ProductInfo;
  customer: CustomerInfo;
  credit_card: CreditCardInfo;
};

export const sendPaymentResolved = (payload: OrderDetails) => {
  const url = 'subscriptions/';

  return instance
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
