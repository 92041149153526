import { spawn } from 'redux-saga/effects';

import { watchAddress } from './address';
import { watchGetCountries } from './countries';
import { watchExitPublish } from './exitPublish';
import { watchGetPublishData } from './publish';
import { watchGetStatesByCountries } from './state';
import { watchGetSubmitOrder } from './order';
import { watchGetSubmitReorder } from './reorder';
import { watchGetUserCredits } from './credits';

export default function* saga() {
  yield spawn(watchAddress);
  yield spawn(watchGetCountries);
  yield spawn(watchExitPublish);
  yield spawn(watchGetPublishData);
  yield spawn(watchGetStatesByCountries);
  yield spawn(watchGetSubmitOrder);
  yield spawn(watchGetSubmitReorder);
  yield spawn(watchGetUserCredits);
}
