import gql from 'graphql-tag';

export default gql(`
query($filter:JSON){
        sections:sections(where:$filter){
            _id
            name
            type
            bookId
          }
    }
`);
