import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

export const EditorStyle = styled.div`
  &::after {
    content: '';
    clear: both;
    display: block;
  }

  .dispflx {
    float: left;
    width: 100%;
    display: flex;

    .sidebar {
      width: 200px;
      height: calc(100vh - 50px);
      border-right: 1px solid ${color.TitleBorder};
      float: left;
      background: white;
      flex: 0 0 200px;

      .title {
        h4 {
          font-family: 'Roboto';
          letter-spacing: 0.5px;
          padding: 15px 10px;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: ${color.GreySelectDd};
          background: ${color.TitleBg};
          border-bottom: 1px solid ${color.TitleBorder};
        }
      }

      .templateList {
        padding: 20px 0;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            padding: 0 0 10px 0;
            width: 127px;
            margin: 0 auto;

            p {
              color: ${color.GreySelectDd};
              padding: 5px 0 0 0;
              text-transform: capitalize;
            }

            a {
              img {
                height: 190px;
                width: 100%;
                display: block;
                object-fit: cover;
                border: 2px solid ${color.GreyFormBorder};
              }

              &:hover,
              &.selected {
                img {
                  border-color: ${color.ImgBorder};
                }
              }
            }
          }
        }
      }
    }

    .rgt {
      float: left;
      flex: 1;

      .editorWrapper {
        width: 100%;
        height: calc(100vh - 64px);
        float: left;
        position: relative;

        .highlightsWrap {
          /* background-color: ${color.HighlightBg}; */
          /* border-radius: 0 0 3px 0; */
          padding: 6px 1px 6px 10px;
          width: auto;

          label {
            font-size: 12px;
            font-weight: bold;
            line-height: 17px;
            color: ${color.GreySelectDd};
          }
        }

        .zoom_wrap {
          line-height: 30px;
          border-right: 1px solid #e6e6e6;
          .icon_inner {
            line-height: 30px;
            height: auto;
            // width: 30px;

            svg {
              width: 16px;
              height: 16px;
              //    position: relative; top: 3px;
            }
          }

          .input-group {
            line-height: 30px;
            h6 {
              font-size: 14px;
              color: #999999;
              position: relative;
              top: 2px;
            }
          }
        }
      }

      .mainEditorWrap.nopadding {
        padding: 0 0 40px;
      }

      .mainEditorWrap {
        .mainEditor {
          height: calc(100vh - 116px);
          position: relative;
          letter-spacing: 0px;
          .pagedjs_pages ul {
            list-style-position: inside;
          }

          .editorViewPort {
            overflow: hidden !important;
          }

          &.mainContentEditor {
            /* overflow: auto; */
            height: calc(100vh - 152px);
            //margin: 30px 0 0 0;
            margin: 0;

            .btns {
              text-align: center;
              position: absolute;
              bottom: 10px;
              left: 0;
              width: 100%;

              button {
                margin: 5px;
                line-height: 40px;
                height: 40px;
              }
            }
          }
        }
      }

      .editorContainer {
        &.withHighlights {
          .textfield-wrapper,
          .imageEditor {
            &::after {
              content: '';
              border: 5px dotted ${color.GreySelectDd};
              position: absolute;
              top: -5px;
              left: -5px;
              width: calc(100% + 10px);
              height: calc(100% + 10px);
              background: rgba(255, 255, 255, 0.25);
            }
          }
          #spineAuthor,
          #spineName,
          #price,
          #brokerContact,
          #housingLogo {
            &::after {
              display: none;
            }
          }
          #price,
          #brokerContact {
            cursor: text !important;
          }
        }
      }

      .pagedjs_pages {
        width: var(--width);
        margin: 0 auto;
      }

      .resetBtn {
        margin: 0 10px 0 0;
      }
      .resetTemplate {
        position: absolute;
        bottom: -22px;
        right: 25px;
        width: 200px;
        text-align: center;
        z-index: 100000;
        cursor: pointer;

        button {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          color: ${color.PrimaryColor};
          border: 0;
          box-shadow: none;
          background: none;
          text-decoration: underline;
          cursor: pointer;
        }

        p {
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
          color: ${color.GreySelectDd};
          letter-spacing: 0.5px;
        }
      }

      .lastModified {
        position: absolute;
        bottom: -20px;
        left: 25px;
        text-align: left;
        z-index: 10000;
        width: calc(100% - (200px + 660px));

        p {
          font-size: 14px;
          line-height: 19px;
          font-weight: 700;
          color: ${color.GreySelectDd};

          span {
            display: inline-block;
            vertical-align: middle;
          }

          small {
            font-weight: 400;
            text-transform: capitalize;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 190px);
            display: inline-block;
            white-space: nowrap;
            vertical-align: middle;
          }
        }
      }
      // .hidescroll div:nth-child(2) {
      //     display: none !important;

      // }

      @media only screen and (min-width: 1450px) and (max-width: 1823px) {
        .hidescroll {
          height: calc(100vh - 23%) !important;
        }
      }
      @media only screen and (min-width: 1824px) {
        .hidescroll {
          height: calc(100vh - 18%) !important;
        }
      }
      .ant-btn {
        display: flex;
        align-items: center;
      }
      .disabled {
        pointer: default !important;
      }
    }
  }
`;
