import { useState } from 'react';
import { useQuery } from 'react-query';
import {
  BellOutlined,
  CreditCardOutlined,
  LinkOutlined,
  PoweroffOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { BookLinksModal } from 'modules/bookLinks';
import { NavBar } from 'modules/nav/components';
import {
  hasSocialMediaTrainingAccess,
  isPilotCustomer,
  isDentistCustomer,
  hasBookAccess,
  hideBookAccess,
} from 'modules/dashboard/utils';

import {
  getRouteBookCatalog,
  getRouteDashboard,
  getRouteHelpCenter,
  getRoutePlans,
  getRouteProfile,
  getRouteReferralMarketingMagazineEditor,
  getRouteReleaseNotes,
} from 'modules/dashboard/routes/navigation';

import { getRouteDentistGuidesCatalog } from 'modules/dashboard/routes/dentist/navigation';

// import { getAdminChangeMemberHubspotPassword } from 'modules/dashboard/routes/admin/navigation';

import { getAppConfig } from 'modules/api';

import { isAdminCustomer, IsFeatureVisible } from 'modules/dashboard/utils/isAdminCustomer';
import { HelpMenu } from './components';

const DashboardNavBar = ({ email, avatar, username, onLogout }) => {
  const [isMyBookLinksModalOpen, setIsMyBookLinksModalOpen] = useState(false);
  const handleMyBookLinksClick = () => setIsMyBookLinksModalOpen(true);
  const handleMyBookLinksCancel = () => setIsMyBookLinksModalOpen(false);
  const { data: appConfig } = useQuery(['getAppConfig'], getAppConfig, {
    staleTime: 1 * 60 * 60 * 1000,
  });
  const { MONTH, YEAR } = appConfig || {};
  const isAdmin = isAdminCustomer();
  const isDentist = isDentistCustomer();

  const defaultContent = (
    <>
      {hasBookAccess() && !hideBookAccess() ? (
        <NavBar.Menu title="Books" to={getRouteBookCatalog()} dataTestId="my-books" />
      ) : null}
      <NavBar.Menu
        title="Campaigns"
        className="active"
        to={getRouteDashboard()}
        dataTestId="dashboard"
      />
      {isPilotCustomer() ? (
        <NavBar.Menu title="Magazine" to={getRouteReferralMarketingMagazineEditor(MONTH, YEAR)} />
      ) : null}

      {/* {isAdmin && (
        <NavBar.Menu title="Admin" to={getAdminChangeMemberHubspotPassword()} dataTestId="admin" />
      )} */}
    </>
  );

  const dentistContent = (
    <>
      <NavBar.Menu title="Guides" to={getRouteDentistGuidesCatalog()} dataTestId="my-guides" />
      <NavBar.Menu
        title="Training"
        href="https://dentist.authorify.com/"
        dataTestId="dentist-training"
      />
    </>
  );

  const mainContent = isDentist ? dentistContent : defaultContent;

  const bookLinksModal = isMyBookLinksModalOpen && (
    <BookLinksModal isOpen onCancel={handleMyBookLinksCancel} />
  );

  const sideContent = (
    <>
      {!isDentist && (
        <>
          <NavBar.SitesDropdown isPremiumUser={hasSocialMediaTrainingAccess()} />
          <HelpMenu to={getRouteHelpCenter()} />
        </>
      )}

      <NavBar.UserMenu avatar={avatar} username={username} email={email}>
        {IsFeatureVisible('FEATURE_UPGRADE_PLANS') && !isDentist && (
          <NavBar.UserMenu.Item
            icon={<CreditCardOutlined />}
            title="Plans and Invoices"
            to={getRoutePlans()}
          />
        )}
        <NavBar.UserMenu.Item
          icon={<SettingOutlined />}
          title="Account Settings"
          to={getRouteProfile()}
        />

        {!isDentist && (
          <NavBar.UserMenu.Item
            icon={<LinkOutlined />}
            title="My Book Links"
            onClick={handleMyBookLinksClick}
          />
        )}

        <NavBar.UserMenu.Item
          icon={<BellOutlined />}
          title="What's New"
          to={getRouteReleaseNotes()}
        />
        <NavBar.UserMenu.Item icon={<PoweroffOutlined />} title="Logout" onClick={onLogout} />
      </NavBar.UserMenu>
      {bookLinksModal}
    </>
  );

  return <NavBar fixed mainContent={mainContent} sideContent={sideContent} />;
};

export default DashboardNavBar;
