import PropTypes from 'prop-types';
import AuthenticationTemplate from 'modules/dashboard/components/AuthenticationTemplate';
import S from 'modules/dashboard/components/AuthenticationTemplate/index.styled';

function ForgotPassword({
  statusCode,
  message,
  handleChange,
  handleSubmit,
  auth,
  setShowPasswordReset,
  noBackground = false,
  noFooter = false,
  noLogo = false,
}) {
  return (
    <AuthenticationTemplate noBackground={noBackground} noFooter={noFooter} noLogo={noLogo}>
      <S.LoginForm layout="vertical" onFinish={handleSubmit}>
        <h3>Reset Password</h3>
        <p>
          Please enter the email address you'd like your <br />
          password reset information sent to.
        </p>
        <br />
        <S.FormItem
          label="Email Address"
          name="username"
          rules={[{ required: true, message: 'Please enter a valid email!', type: 'email' }]}
        >
          <S.LoginInput
            size="large"
            name="username"
            placeholder="Please type your email here"
            onChange={handleChange}
          />
        </S.FormItem>
        <S.FormItem>
          <S.LoginButton
            type="primary"
            htmlType="submit"
            disabled={auth.loading && !auth.loaded}
            block
          >
            Submit
          </S.LoginButton>
        </S.FormItem>
        <S.FormItem>
          <S.BackWrapper>
            <S.Description> Go back to </S.Description>
            {!noBackground ? (
              <S.LoginLink to="/auth/login">login</S.LoginLink>
            ) : (
              <S.LoginLink
                onClick={() => {
                  setShowPasswordReset(false);
                }}
              >
                login
              </S.LoginLink>
            )}
          </S.BackWrapper>
        </S.FormItem>
      </S.LoginForm>

      {message && (
        <S.LoginErrorWrap>
          {statusCode === 1 ? (
            <div>
              <p>
                <span>This doesn't look right.</span>
              </p>
              <p>
                The email you've entered does not appear in our records. Please check your entry and
                try again.
              </p>
            </div>
          ) : (
            <div>
              <p>
                <span>Something doesn't look right.</span>
              </p>
              <p>Please enter a valid email address and try again.</p>
            </div>
          )}
        </S.LoginErrorWrap>
      )}
    </AuthenticationTemplate>
  );
}

ForgotPassword.defaultProps = {
  noLogo: false,
  noBackground: false,
  noFooter: false,
};

ForgotPassword.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  statusCode: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  auth: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    expiredLink: PropTypes.number.isRequired,
    login: PropTypes.shape({ statusCode: PropTypes.number }),
    forgotPassword: PropTypes.shape({ statusCode: PropTypes.number.isRequired }),
    resetPassword: PropTypes.shape({ statusCode: PropTypes.number.isRequired }),
    checked: PropTypes.bool,
  }).isRequired,
  setShowPasswordReset: PropTypes.func.isRequired,
  noBackground: PropTypes.bool,
  noFooter: PropTypes.bool,
  noLogo: PropTypes.bool,
};

export default ForgotPassword;
