import { Children } from 'react';
import { Menu } from 'antd';
import { get } from 'lodash-es';
import classnames from 'classnames';

import { ChevronIcon } from 'modules/v2/common/components/SvgIcon';

import { Grid } from './components';

import S from './styles';

const Dropdown = ({ children, title, className }) => {
  const Overlay = (
    <Menu className="userDropdown editordropdown rounded-lg overflow-hidden py-2">
      {Children.map(children, (child) => {
        const key = get(child, ['props', 'title']);
        return (
          <Menu.Item key={key} className="py-[16px] px-[20px]">
            {child}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <S.Dropdown
      menu={Overlay}
      trigger={['click']}
      overlayClassName="draftDropdown"
      dropdownRender={() => Overlay}
    >
      <div className={classnames('!flex justify-between pl-2 pr-3', className)}>
        <div className="">{title}</div>

        <S.Button.Icon>
          <ChevronIcon />
        </S.Button.Icon>
      </div>
    </S.Dropdown>
  );
};

Dropdown.Grid = Grid;

export default Dropdown;
