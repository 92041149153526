import { findIndex, get } from 'lodash-es';

import {
  UPDATE_COVER_IMAGE,
  UPDATE_COVER_ELEMENT,
  UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
  UPDATE_CONTENT_ELEMENT,
} from 'modules/v2/editor/store/constants';

import elementsReducer from './elements';

const reducer = (bookpage = [], action) => {
  switch (action.type) {
    case UPDATE_COVER_IMAGE:
    case UPDATE_COVER_ELEMENT:
    case UPDATE_COVER_ELEMENTS_BY_PREFERENCES:
    case UPDATE_CONTENT_ELEMENT: {
      const index = findIndex(bookpage, ({ status }) => status === 'active');

      if (index < 0) {
        return bookpage;
      }

      const elements = get(bookpage, [index, 'elements'], []);

      const page = {
        ...bookpage[index],
        elements: elementsReducer(elements, action),
      };

      return [...bookpage.slice(0, index), page, ...bookpage.slice(index + 1)];
    }

    default:
      return bookpage;
  }
};

export default reducer;
