import { useQuery } from 'react-query';
import { Badge } from 'flowbite-react';
import classNames from 'classnames';
import { getBillingHistory } from 'modules/api';
import { formatDateTime2 } from 'modules/common/utils';
import { CreditCardImages, formatCreditCardNumber, formatCurrency } from 'modules/v2/utils';
import { Box } from 'modules/v2/common/AtomicDesign/atoms';
import { DownloadIcon } from 'modules/v2/common/components/SvgIcon';

const Invoices = () => {
  const page = 1;
  const perPage = 50;

  const { data: { data: billingHistory } = [] } = useQuery(
    ['getBillingHistory', page, perPage],
    getBillingHistory,
  );

  return (
    <Box>
      <div className="text-base font-semibold px-6 py-[22px]  text-boxTitle">Invoices</div>
      <div className="mb-6 overflow-x-auto">
        {/* table Header */}
        <div className="flex w-full border-t border-tableBorder py-3 font-semibold">
          <div className="min-w-[220px] w-full pl-6">Invoice</div>
          <div className="min-w-[162px] w-full">Date</div>
          <div className="min-w-[111px] w-full">Amount</div>
          <div className="min-w-[116px] w-full ">Status</div>
          <div className="min-w-[316px] w-full ">Payment method</div>
          <div className="min-w-[175px] w-full ">Action</div>
        </div>
        {/* table Body */}
        <div>
          {billingHistory?.map((item) => {
            const paymentInformation = item.payments[0]?.payment_method;
            const paymentMethod = (
              <div className="flex items-center gap-2">
                <img
                  src={CreditCardImages[paymentInformation?.card_brand]}
                  alt={CreditCardImages[paymentInformation?.card_brand]}
                  className="w-11 h-8"
                />
                <span className="text-sm font-semibold">
                  {formatCreditCardNumber(paymentInformation?.masked_card_number ?? '')}
                </span>
              </div>
            );
            return (
              <div
                key={item.id}
                className="flex items-center border-t border-tableBorder py-[18px] font-semibold"
              >
                <div className="min-w-[220px] w-full pl-6">{item.product_name}</div>
                <div className="min-w-[162px] w-full">{formatDateTime2(item.created_at)}</div>
                <div className="min-w-[111px] w-full">{formatCurrency(item.total_amount)}</div>
                <div className="min-w-[116px] w-full">
                  <Badge
                    className={classNames(
                      item.status === 'paid'
                        ? 'text-success-500 bg-success-50'
                        : 'text-error-500 bg-error-50',
                      'w-fit rounded-full',
                    )}
                  >
                    {item.status}
                  </Badge>
                </div>
                <div className="min-w-[316px] w-full">{paymentMethod}</div>
                <div className="min-w-[175px] w-full">
                  <a
                    href={item.invoice_download}
                    download
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-2 w-fit py-2.5 pl-3 pr-3.5 border border-neutral-200 cursor-pointer rounded-md text-neutral-800 font-bold hover:bg-neutral-100 hover:text-neutral-900"
                  >
                    <DownloadIcon />
                    <span>Download</span>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

export default Invoices;
