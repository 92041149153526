import { noop } from 'lodash-es';

import { AltArrowLeft, AltArrowRight } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';

import { NoContent } from 'modules/v2/common/components';

import S from './styles';

const Table = (props) => {
  const {
    hasData,
    loading,
    dataSource,
    rowKey,
    columns,
    title,
    pagination,
    scroll,
    noContentMessage = '',
    ...extra
  } = props;

  const customPagination = (currentRender, type) => {
    const isPageActive = currentRender === pagination?.current;
    const isOnlyPage = pagination?.lastPage === 1;
    if (type === 'prev') {
      return (
        <Button
          type="outlined"
          onClick={() => pagination?.current > 1 && pagination?.onChange(pagination?.current - 1)}
          disabled={pagination?.current === 1}
          className="flex items-center justify-center gap-2"
        >
          <AltArrowLeft />
          <span className="ml-2">Prev</span>
        </Button>
      );
    }
    if (type === 'page') {
      return (
        <Button type={isPageActive || isOnlyPage ? 'dark' : 'outlined'} className="border-none">
          {currentRender}
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button
          type="outlined"
          onClick={() => pagination?.current > 1 && pagination?.onChange(pagination?.current - 1)}
          disabled={pagination?.current === 1}
          className="flex items-center justify-center gap-2"
        >
          <span className="mr-2">Next</span>
          <AltArrowRight />
        </Button>
      );
    }
    return <span>•••</span>;
  };

  const tablePagination = {
    position: 'bottom',
    pageSize: pagination?.perPage || 15,
    size: 'default',
    itemRender: customPagination,
    showSizeChanger: false,
    showQuickJumper: false,
    ...pagination,
  };

  const tableScroll = { x: false, y: false, ...scroll };

  const noDataTable = () => {
    return <NoContent message={noContentMessage} />;
  };

  const customNoDataMessage = {
    emptyText: noDataTable,
  };

  const LoadingTable = (
    <div
      role="status"
      className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
    >
      {[...Array(5)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
        </div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );

  if (loading) {
    return LoadingTable;
  }

  return (
    <S.Table
      loading={loading}
      hasData={hasData}
      rowKey={rowKey}
      bordered={false}
      columns={columns}
      dataSource={dataSource}
      size="middle"
      title={title}
      pagination={pagination ? tablePagination : false}
      footer={noop}
      scroll={tableScroll}
      locale={customNoDataMessage}
      {...extra}
    />
  );
};

export default Table;
