import { views } from 'modules/v2/pages/Dentist/OrderGuideModal/constants';

export const getViewTitle = (view) => {
  const titles = {
    [views.GUIDEDETAILS]: 'Guide Details',
    [views.CHECKOUT]: 'Select Quantity',
    [views.ADDRESS_LIST]: 'Shipping Address',
    [views.REVIEW]: 'Review Order',
  };

  return titles[view] || '';
};

export const convertStepToView = (step) => {
  const stepToViewMap = {
    0: views.GUIDEDETAILS,
    1: views.CHECKOUT,
    2: views.ADDRESS_LIST,
    3: views.REVIEW,
  };

  return stepToViewMap[step] || '';
};

export const convertViewToStep = (view) => {
  const viewToStepMap = {
    [views.GUIDEDETAILS]: 0,
    [views.CHECKOUT]: 1,
    [views.ADDRESS_LIST]: 2,
    [views.ADDRESS_FORM]: 2,
    [views.REVIEW]: 3,
  };

  return viewToStepMap[view] || 0;
};

const buildStep = (isReady, isOpaque, isDisabled) => {
  if (isReady) {
    return {
      status: 'finish',
      disabled: isDisabled,
    };
  }

  return {
    status: isOpaque ? 'wait' : 'process',
    disabled: isDisabled,
  };
};

export const checkStep =
  ({ selectedAddressId, numberOfPrints }) =>
    (current, next) => {
      const hasAddressSelected = selectedAddressId !== null;
      const isPrinted = numberOfPrints !== null && numberOfPrints > 0;

      switch (next) {
        case views.GUIDEDETAILS: {
          const isReady = current !== views.GUIDEDETAILS;
          const isDisabled = true;
          const isOpaque = false;

          return buildStep(isReady, isOpaque, isDisabled);
        }

        case views.CHECKOUT: {
          const isReady = isPrinted;
          const isDisabled = !isPrinted && current === views.GUIDEDETAILS;
          const isOpaque = !isReady && current !== views.CHECKOUT;

          return buildStep(isReady, isOpaque, isDisabled);
        }

        case views.ADDRESS_LIST: {
          const isReady = isPrinted && hasAddressSelected;
          const isDisabled = !isPrinted;
          const isOpaque = !isReady && !isPrinted;

          return buildStep(isReady, isOpaque, isDisabled);
        }

        case views.REVIEW: {
          const isReady = false;
          const isDisabled = !(isPrinted && hasAddressSelected);
          const isOpaque = !isReady && !(isPrinted && hasAddressSelected);

          return buildStep(isReady, isOpaque, isDisabled);
        }

        default:
          return { status: 'wait', disabled: true };
      }
    };
