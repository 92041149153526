import { useState } from 'react';

import arrow from 'assets/images/arrow.svg';
import check from 'assets/images/check.svg';

import S from './styles';

const ImageSlider = ({ images, bookId, title, onClick }) => {
  const [wordData, setWordData] = useState(images[0]);
  const [imageIndex, setImageIndex] = useState(0);
  const [activeImage, setActiveImage] = useState(0);

  const handleClick = async (index) => {
    if (onClick) {
      await onClick(index);
    }

    setActiveImage(index);
    const wordSlider = images[index];
    setWordData(wordSlider);
    bookId ?? bookId(wordSlider.id);
  };

  const handleNext = () => {
    const index = imageIndex < images.length - 1 ? imageIndex + 1 : imageIndex;
    setImageIndex(index);
  };

  const handlePrevious = () => {
    const index = imageIndex <= images.length - 1 && imageIndex > 0 ? imageIndex - 1 : imageIndex;
    setImageIndex(index);
  };

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.SelectedImage src={wordData.image} alt="guide-cover" />
      <S.SliderContainer>
        <S.ArrowButton onClick={handlePrevious} disabled={imageIndex === 0}>
          <img src={arrow} alt="arrow" />
        </S.ArrowButton>
        <S.CoverContainer>
          {images.map((data, i) => {
            return (
              <S.ImageCoverContainer
                active={i === activeImage}
                index={imageIndex}
                length={images.length}
                key={data.id}
              >
                {i === activeImage && <S.CheckIcon src={check} alt="check" />}
                <S.Image
                  src={data.image}
                  alt="test"
                  onClick={() => handleClick(i)}
                  index={imageIndex}
                  length={images.length}
                />
              </S.ImageCoverContainer>
            );
          })}
        </S.CoverContainer>
        <S.ArrowButton onClick={handleNext} disabled={imageIndex >= images.length - 3}>
          <img src={arrow} alt="arrow" style={{ transform: 'rotate(180deg)' }} />
        </S.ArrowButton>
      </S.SliderContainer>
    </S.Container>
  );
};

export default ImageSlider;
