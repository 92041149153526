import { Modal } from 'flowbite-react';
import { compose } from 'redux';
import { useEffect } from 'react';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { SuccessToastIcon } from 'modules/v2/common/components/SvgIcon';
import { MINIMUM_CHECKOUT_BOOK_QUANTITY } from 'modules/v2/pages/constants';
import { withCredits } from 'modules/publish/containers';

const customTheme = {
  content: {
    base: 'relative h-full w-fit w-[513px] md:h-auto',
  },
  footer: {
    base: 'flex space-x-2 rounded-b border-neutral-100 min-h-[91px] px-6 py-[13px]',
  },
};

const GenerateLinksView = ({ publishRoute, onCancel, openModal, credits, getUserCredits }) => {
  useEffect(() => {
    getUserCredits();
  }, []);

  const userCredits = Number.isNaN(+credits?.data) ? 0 : parseInt(credits.data, 10);

  const customerPlanInfo = useCustomerPlanInfo();

  const closeModalLinks = () => {
    onCancel();
  };

  return (
    <Modal theme={customTheme} show={openModal} dismissible onClose={() => closeModalLinks}>
      <Modal.Body>
        <div className="flex flex-col items-center justify-center p-1.5 gap-y-6">
          <div className="w-[52px] h-[52px] rounded-full border-2 border-success-300 flex items-center justify-center">
            <SuccessToastIcon width={34} height={34} stroke="#51B695" strokeWidth={1} />
          </div>
          <div className="flex flex-col items-center justify-center w-full">
            <h2 className="mb-3.5 text-base font-bold">Successfully published your book</h2>
            <p className="text-neutral-500">You can now order the latest copy of your book.</p>
          </div>

          <div className="flex items-center justify-center w-full gap-x-2">
            <Button color="outlined" onClick={closeModalLinks}>
              <span className="font-semibold">Okay</span>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default compose(withCredits)(GenerateLinksView);
