import styled from 'styled-components';
import { FieldControl as FieldControlBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  padding: 24px 16px;
  background: ${color.White};
`;

const FlexForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const FieldControl = styled(FieldControlBase)`
  flex-basis: ${({ full }) => (full ? '100%' : '48%')};
`;

const Footer = styled.footer`
  display: flex;
  justify-content: end;
  padding: 16px;
  gap: 8px;
`;

export default {
  Wrapper,
  FlexForm,
  FieldControl,
  Footer,
};
