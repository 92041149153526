export const AVATAR_MIN_WIDTH = 500;
export const AVATAR_MIN_HEIGHT = 500;

export const loadImageDetails = (file) =>
  new Promise((resolve, reject) => {
    const image = new Image();

    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));

    image.src = typeof file === 'string' ? file : URL.createObjectURL(file);
  });
