import { cn } from 'modules/v2/common/utils';
import S from './styles';

const ListArchived = ({ items = [], selectedId, onSelect }) => {
  const onSelectCallback = (e, item) => {
    e.stopPropagation();
    onSelect(item);
  };

  return (
    <S.ArchivedList>
      {items.map((item) => {
        const selected = item._id === selectedId;
        return (
          <li
            key={item._id}
            className={cn(
              'group h-12 p-3.5 bg-white rounded-lg border border-neutral-200 text-neutral-800 text-sm mb-2 hover:outline hover:border-transparent hover:outline-primary-400 hover:outline-2 hover:bg-primary-50',
              { 'border-primary-400 outline-2 bg-primary-50': selected },
            )}
          >
            <div className="ant-list-item-content" onClick={(e) => onSelectCallback(e, item._id)}>
              <S.WrapperText>
                <S.SectionItemName title={item.displayName}>{item.displayName}</S.SectionItemName>
                <div className="group-hover:opacity-100 opacity-0 transition-all ease-in-out duration-200">
                  View
                </div>
              </S.WrapperText>
            </div>
          </li>
        );
      })}
    </S.ArchivedList>
  );
};

export default ListArchived;
