import { get } from 'lodash-es';

import { PrimaryButton } from 'modules/v2/common/components';
import { formatDate, formatDateTime } from 'modules/v2/common/utils';
import { BillingSideNav } from 'modules/v2/components';
import { withProfile } from 'modules/v2/containers';
import { ROUTE_PLANS } from 'modules/v2/routes/constants';
import { DashboardTemplate } from 'modules/v2/templates';
import { formatCurrency } from 'modules/v2/utils';

import { NewPlanTable } from './components';
import S from './styles';
import { intervalType } from '../../constants';
import { generatePlanName } from '../../utils';

const PlanConfirmation = ({ history, location, profile }) => {
  const { data: planData = {}, selectedPlan } = location?.state || {};
  const { amount } = selectedPlan || {};
  const { email } = get(profile, 'data', {});
  const isMonthlyPlan = get(planData, 'price_point_name', '')
    .toLowerCase()
    ?.includes(intervalType.MONTHLY);
  const interval = isMonthlyPlan ? intervalType.MONTHLY : intervalType.ANNUALLY;
  const planAmount = formatCurrency(amount);
  const name = generatePlanName(get(planData, 'price_point_name'), amount);
  const createdAt = get(planData, 'allocation.created_at');
  const currentPeriodStart = formatDateTime(createdAt, 'at');
  const renewalDate = formatDate(planData?.renewalDate, 'MMMM D, YYYY');
  const userAction =
    selectedPlan?.operation === 'Upgrade' ||
    selectedPlan?.operation?.toLowerCase()?.includes('annual')
      ? 'upgraded'
      : 'downgraded';
  return (
    <DashboardTemplate sidebar={<BillingSideNav />} hasSidebar>
      <S.MainContain>
        <S.TopWrap>
          <div>
            <S.Title>Confirmation</S.Title>
            <S.PlanName>
              New Plan: <b>{name}</b>
            </S.PlanName>
          </div>
        </S.TopWrap>
        <S.Divider />
        {/* <S.Description>Your order number is:</S.Description> */}
        {/* TODO: change the order id once available from backend. */}
        {/* <S.Title>AFY0000000123</S.Title> */}
        <S.BottomDescription>Your subscription has been {userAction}.</S.BottomDescription>
        <S.BottomDescription>
          A copy of your updated subscription and details have been emailed to you at{' '}
          <S.EmailLink href={`mailto:${email}`}>{email}</S.EmailLink>
        </S.BottomDescription>
        <NewPlanTable
          planName={name}
          planTerm={interval}
          amountPaid={planAmount}
          currentPeriodStart={currentPeriodStart}
          renewalDate={renewalDate}
        />
        <S.BottomDescription>
          If you have questions check out our{' '}
          <S.EmailLink href="/help-center">help page</S.EmailLink> or{' '}
          <S.EmailLink href="/help-center">start a chat</S.EmailLink>.
        </S.BottomDescription>
        <S.ButtonWrap>
          <PrimaryButton onClick={() => history.push(ROUTE_PLANS)}>Done</PrimaryButton>
        </S.ButtonWrap>
      </S.MainContain>
    </DashboardTemplate>
  );
};

export default withProfile(PlanConfirmation);
