import { operationType, TOTAL_MONTHS } from './constants';

export const generatePlanName = (name, price) => `Authorify ${name} Membership $${price}`;

const calcTotal = (value, isYearly) => (isYearly ? Number(value) * TOTAL_MONTHS : Number(value));

export const getLicensedBooks = (currentValue, selectedValue) => ({
  [operationType.DOWNGRADE]: Number(currentValue) - Number(selectedValue),
  [operationType.UPGRADE]: selectedValue,
});

export const getPrintedBooks = (currentValue, selectedValue, isYearly) => ({
  [operationType.DOWNGRADE]: calcTotal(currentValue, isYearly) - calcTotal(selectedValue, isYearly),
  [operationType.UPGRADE]: calcTotal(selectedValue, isYearly),
});

export const splitFirstWord = (str) => (str ? str?.split(' ')[0] : str);

export const centsToDollar = (amount) => amount / 100;

export const injectUserPlan = (userPlan, data) => {
  return data.map((column) => {
    if (typeof column.title === 'function') {
      return {
        ...column,
        title: column.title({ userPlan }),
      };
    }
    return column;
  });
};

export const hideLesserPlans = (yearlyData, monthlyData, userPlan, isYearly, isMonthly) => {
  const isUserCurrentPlanMonthly = userPlan?.includes('monthly');
  if (isYearly) {
    let activePlanIndex;
    if (isUserCurrentPlanMonthly) {
      activePlanIndex = monthlyData.findIndex((column) => column.key === userPlan);
    } else {
      activePlanIndex = yearlyData.findIndex((column) => column.key === userPlan);
    }
    const data = yearlyData.filter((column, index) => index >= activePlanIndex || index === 0);
    return injectUserPlan(userPlan, data);
  }
  if (isMonthly) {
    const activePlanIndex = monthlyData.findIndex((column) => column.key === userPlan);
    const data = monthlyData.filter((column, index) => index >= activePlanIndex || index === 0);
    return injectUserPlan(userPlan, data);
  }
  if (isUserCurrentPlanMonthly) {
    const activePlanIndex = monthlyData.findIndex((column) => column.key === userPlan);
    const data = yearlyData.filter((column, index) => index >= activePlanIndex || index === 0);
    return injectUserPlan(userPlan, data);
  }
  return injectUserPlan(userPlan, yearlyData);
};

export const getFlow = (currentPlan, selectedPlan) => {
  const isCurrentPlanMonthly = currentPlan && !!currentPlan.includes('monthly');
  const isSelectedPlanMonthly = !!selectedPlan.includes('monthly');

  if (isCurrentPlanMonthly && isSelectedPlanMonthly) {
    return 'plan-change';
  }
  return 'family-change';
};
