function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function executeAfterDelay(callback: CallableFunction, delay: number) {
  await sleep(delay);
  await callback();
}

export { sleep, executeAfterDelay };
