import { string, object, boolean, number } from 'yup';

const validationSchema = object().shape({
  name: string().required('Name is required'),
  email: string().email('Invalid email').required('Email is required'),
  hubspotId: string().required('Hubspot ID is required'),
  calendarId: string().email('Invalid CalendarID email').required('Calendar ID is required'),
  meetingLink: string()
    .url('Meeting Link must be a valid URL')
    .required('Meeting Link is required'),
  enabled: boolean().required('This option is required'),
  schedulingPoints: number()
    .typeError('Scheduling Points is required')
    .required('Scheduling Points is required'),
  image: string().optional(),
});

export default validationSchema;
