import { PrimaryButton } from 'modules/common/components';

import S from './styles';

const MetricCard = ({
  icon,
  title,
  description,
  metricColor,
  metricValue,
  metricDescription,
  buttonText,
  link,
  isLoading,
}) => {
  const handleClick = () => {
    window.location.assign(link);
  };

  return (
    <S.CardContainer>
      <S.TitleContainer>
        <S.Icon>{icon}</S.Icon>
        <S.Title>{title}</S.Title>
      </S.TitleContainer>

      {isLoading ? (
        <S.Skeleton active paragraph={{ rows: 3 }} />
      ) : (
        <>
          <S.Description>{description}</S.Description>

          <S.MetricValue color={metricColor}>
            <span>{metricValue || '0'}</span>
          </S.MetricValue>

          <S.MetricContainer>
            <S.MetricColor color={metricColor} />
            <S.MetricDescription>{metricDescription}</S.MetricDescription>
          </S.MetricContainer>

          <S.ButtonContainer>
            <PrimaryButton type="link" onClick={handleClick}>
              {buttonText}
            </PrimaryButton>
          </S.ButtonContainer>
        </>
      )}
    </S.CardContainer>
  );
};

export default MetricCard;
