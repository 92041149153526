import { Children } from 'react';
import { PlusIcon } from 'modules/v2/common/components/SvgIcon/PlusIcon';
import Item from './components/Item';

import S from './styles';

const CreateAddress = ({ onCreate }) => (
  <S.Empty onClick={onCreate}>
    <PlusIcon width={50} height={50} className="flex items-center justify-center -mb-3" />
    <span className="text-neutral-600 font-semibold">Add new address</span>
  </S.Empty>
);

const AddressList = ({ onCreate, children }) => (
  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
    {onCreate && (
      <div className="w-full">
        <CreateAddress onCreate={onCreate} />
      </div>
    )}
    {Children.map(children, (item) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="w-full">{item}</div>
    ))}
  </div>
);

AddressList.Item = Item;

export default AddressList;
