import {
  CLEAR_PUBLISH,
  GET_PREFLIGHT_CHECK,
  GET_PUBLISH,
  SUBMIT_ORDER,
  SUBMIT_REORDER,
} from 'modules/v2/publish/store/constants';

export const getPreflightCheck = (draftId) => ({
  type: GET_PREFLIGHT_CHECK,
  payload: {
    draftId,
  },
});

export const getPublish = (draftId) => ({
  type: GET_PUBLISH,
  payload: {
    draftId,
  },
});

export const clearPublish = () => ({
  type: CLEAR_PUBLISH,
});

export const submitOrder = ({ draftId, isDigital, numberOfPrints, selectedAddressId }) => ({
  type: SUBMIT_ORDER,
  payload: {
    draftId,
    isDigital,
    numberOfPrints,
    selectedAddressId,
  },
});

export const submitReorder = ({
  draftId,
  isDigital,
  numberOfPrints,
  selectedAddressId,
  parentOrderId,
}) => ({
  type: SUBMIT_REORDER,
  payload: {
    draftId,
    isDigital,
    numberOfPrints,
    parentOrderId,
    selectedAddressId,
  },
});
