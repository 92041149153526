import { ReactNode } from 'react';
import { PlusPlansProvider } from './components/context';
import PlusPlansRoute from './pages';

type PlusPlansProps = {
  children: ReactNode;
};

const PlusPlans = ({ children }: PlusPlansProps) => {
  return (
    <PlusPlansProvider>
      <PlusPlansRoute>{children}</PlusPlansRoute>
    </PlusPlansProvider>
  );
};

export default PlusPlans;
