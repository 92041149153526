export const checkPendingSave = (change, saved) => {
  // There are no changes
  if (!change) {
    return false;
  }

  // There are changes, but was not saved
  if (!saved) {
    return true;
  }

  // The last changes happened after the last save
  return change > saved;
};
