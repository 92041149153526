import { startsWith } from 'lodash-es';

import getInstance from 'modules/api/instance';

import { getFileFromDataURI } from '../user/utils';
import { formatDraftForSaving, formatSectionsForSaving } from './utils';

const instance = getInstance();

export const fetchDrafts = () => {
  const url = '/draft';

  return instance.get(url);
};

export const fetchDraftById = ({ draftId, reduced }) => {
  const url = '/draft';
  const params = {
    draft_id: draftId,
    reduced,
  };

  return instance.get(url, { params });
};

export const getDraftLinksById = ({ draftId }) => {
  return instance
    .get(`/draft/${draftId}/links`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const fetchDraftPdf = ({ draftId, force = true }) => {
  const url = `/draft/generatepdf/${draftId}`;
  const params = {
    force,
  };

  return instance.get(url, { params });
};

export const fetchDraftsByBookId = ({ bookId }) => {
  const url = '/draft';
  const params = {
    book_id: bookId,
  };

  return instance.get(url, { params });
};

export const fetchPreFlightCheck = ({ draftId }) => {
  const url = `/draft/preflightcheck/${draftId}`;

  return instance.get(url);
};

export const fetchCoverPreview = ({ draftId }) => {
  const url = `/draft/preview-image/${draftId}`;

  return instance.get(url);
};

export const fetchDraftPreview = ({ draftId }) => {
  const url = `/draft/${draftId}/preview`;

  return instance.get(url);
};

export const generateDraftPreview = ({ draftId }) => {
  const url = `/draft/${draftId}/preview`;

  return instance.post(url);
};

export const fetchDraftLinks = ({ draftId }) => {
  const url = `/draft/${draftId}/links`;

  return instance.get(url);
};

export const createDraft = ({ bookId, thumbnail, preferences }) => {
  const {
    name,
    email,
    phone,
    brokerInfo = false,
    brokerLogo = false,
    realtorLogo = false,
    housingLogo = false,
  } = preferences;

  const url = '/draft/preferences';

  const body = {
    draft_id: null,
    book_id: bookId,
    name: 'untitled',
    milestone: 'draft',
    thumbnail: {
      url: thumbnail,
    },
    preferences: {
      name,
      email,
      phone,
      brokerInfo: {
        status: brokerInfo,
      },
      brokerLogo: {
        status: brokerLogo,
      },
      realtorLogo: {
        status: realtorLogo,
      },
      housingLogo: {
        status: housingLogo,
      },
    },
  };

  return instance.post(url, body);
};

export const savePreferences = ({ draftId, preferences }) => {
  const url = '/draft/preferences';

  const body = {
    draft_id: draftId,
    milestone: 'draft',
    preferences,
  };
  return instance.post(url, body);
};

export const saveDraftPerSection = ({ bookId, draftId, draftName, section }) => {
  const url = '/draft/save';

  const data = formatDraftForSaving(bookId, draftId, draftName, section);

  return instance.post(url, data);
};

export const generateDraftLinks = ({ draftId }) => {
  const url = `/draft/${draftId}/links/realtor/`;

  return instance.post(url);
};

export const generateDentistDraftLinks = ({ draftId, practiceName = null }) => {
  const practiceNameValue = encodeURIComponent(practiceName);
  const url = `/draft/${draftId}/links/dentist/${practiceNameValue}`;

  return instance.post(url);
};

export const addNewChapter = ({ draftId }) => {
  const url = '/draft/createchapter';
  const body = {
    draft_id: draftId,
  };

  return instance.post(url, body);
};

export const reorderDraftSections = ({ draftId, sections }) => {
  const url = '/draft/reorderdraftsections';
  const body = {
    draft_id: draftId,
    sections: formatSectionsForSaving(sections),
  };

  return instance.post(url, body);
};

export const editOrderedDraft = ({ orderId }) => {
  const url = '/draft/editOrderedDraft';
  const body = {
    order_id: orderId,
  };

  return instance.post(url, body);
};

export const resetDraft = ({ draftId }) => {
  const url = `/draft/${draftId}/reset`;

  return instance.post(url);
};

export const resetSection = ({ draftId, sectionId }) => {
  const url = `/draft/${draftId}/section/${sectionId}/reset`;

  return instance.post(url);
};

export const copyImageToDraft = ({ draftId, id, image, imageType }) => {
  const url = `/draft/${draftId}/copy-image-to-draft`;
  const params = {
    element_id: id,
    image_type: imageType,
  };
  const formData = new FormData();

  if (startsWith(image, 'data')) {
    const filename = `${draftId}`;
    const file = getFileFromDataURI(image, filename);

    formData.append('image', file);
  } else {
    params.image_id = image;
  }

  return instance.post(url, formData, { params });
};
