import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';

import { generateDraftPreview, getError, resource } from 'modules/api';

import { getDraftPreview, clearDraftPreview } from 'modules/draft/store';

import {
  CANCEL_GENERATE_DRAFT_PREVIEW,
  GENERATE_DRAFT_PREVIEW,
} from 'modules/draft/store/constants';
import { isPreviewStatusFailed } from 'modules/draft/utils/preview';

export function* onGenerateDraftPreview({ payload: meta }) {
  try {
    const request = resource(GENERATE_DRAFT_PREVIEW, generateDraftPreview);
    const payload = yield call(request, meta);

    const status = get(payload, 'status');

    if (isPreviewStatusFailed(status)) {
      yield put({
        type: `${GENERATE_DRAFT_PREVIEW}_FAILED`,
        payload,
      });
      return payload;
    }

    const draftId = get(meta, 'draftId');
    yield put(getDraftPreview(draftId));

    return payload;
  } catch (error) {
    const payload = getError(error);

    yield put({
      type: `${GENERATE_DRAFT_PREVIEW}_FAILED`,
      error: true,
      payload,
    });

    // eslint-disable-next-line no-console
    console.error(payload);

    return payload;
  }
}

export function* watchCancellation(action) {
  const [cancelled] = yield race([
    take(CANCEL_GENERATE_DRAFT_PREVIEW),
    call(onGenerateDraftPreview, action),
  ]);

  if (cancelled) {
    clearDraftPreview();
  }
}

export function* watchGenerateDraftPreview() {
  yield takeEvery(GENERATE_DRAFT_PREVIEW, watchCancellation);
}
