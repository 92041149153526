import { createSelector } from 'reselect';

import { editorSelector } from 'modules/editor/store/selectors/editor';

export const userSelector = createSelector(editorSelector, (editor) => editor.user);
export const userLogosSelector = createSelector(userSelector, (user) => user.logos);
export const userImagesSelector = createSelector(userSelector, (user) => user.images);
export const userImagesUploadSelector = createSelector(
  userImagesSelector,
  (images) => images.upload,
);
