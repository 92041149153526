import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  background-color: ${color.White};
  th {
    font-weight: bold;
  }
  tr {
    border: 1px solid ${color.LightDullGray};
  }
`;

const Title = styled.h3`
  font-size: 24px !important;
  line-height: 46px !important;
  color: ${color.Black};
  margin-top: 10px !important;
  font-weight: bold !important;
`;

const ReportText = styled.p`
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  color: ${color.Black};
  margin-top: -10px !important;
  margin-bottom: -10px !important;
`;

const DatePicker = styled.div`
  margin: 24px 0;
  .ant-input {
    width: 60%;
    display: block;
  }
`;

const LandingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -15px;
  margin-bottom: -10px;
`;

const SearchBox = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  padding: 10px 20px;
  padding-right: 50px;
  border: 1px solid ${color.SideBarBackground};
  color: ${color.GreyFormCheckbox};
  font-size: 14px;
  font-weight: 600;
  border-radius: 2px;
  opacity: 1;
  width: 325px;
  :focus {
    outline: none;
  }
  &::placeholder {
    color: ${color.GreyFormBorder};
  }
`;

const IconSearch = styled.div`
  position: absolute;
  top: 13px;
  right: 20px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const PaginationWrap = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .ant-pagination-item,
  .ant-pagination-item-link,
  .ant-select-selection {
    background: ${color.lightBackground} 0% 0% no-repeat padding-box;
    border-radius: 3px;
    font-family: 'Montserrat Sans', sans-serif;
  }
  .ant-pagination-item-active {
    background: ${color.White} !important;
    border-color: ${color.SkyBlue} !important;
  }
`;

const SortArrow = styled.span`
  color: ${color.GreyFormCheckbox};
  cursor: pointer;
`;

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BtnGroup = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 20px;
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  .anticon svg {
    font-size: 23px !important;
    margin-left: 10px !important;
  }
`;

const ExportBtn = styled.button`
  margin-top: 20px
  width: 165px;
  height: 40px !important;
  background-color: ${color.DefaultBlue};
  color: ${color.White};
  border: none;
  border-radius: 2px !important;
  opacity: 1;
  text-align: left;
  padding: 0 18px;
  display: flex;;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  .anticon svg {
    font-size: 24px;
    margin-left: 10px;
  }
  &:disabled{
    opacity: 0.5;
  }
`;

const EmptyColumn = styled.div`
  padding: 15px 0px;
  background-color: ${color.White};
`;

const BottomContainer = styled.div`
  > div {
    margin-top: 24px;
  }
`;

export default {
  ReportText,
  LandingBox,
  SearchInput,
  SearchBox,
  IconSearch,
  Title,
  PaginationWrap,
  SortArrow,
  MainContain,
  BtnGroup,
  ButtonText,
  ExportBtn,
  EmptyColumn,
  Container,
  BottomContainer,
  DatePicker,
};
