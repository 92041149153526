const SectionsIcon = ({ stroke = '#fafafa', fill, ...props }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.16699 14.6666C2.16699 13.0952 2.16699 12.3096 2.65515 11.8214C3.1433 11.3333 3.92898 11.3333 5.50033 11.3333H15.5003C17.0717 11.3333 17.8573 11.3333 18.3455 11.8214C18.8337 12.3096 18.8337 13.0952 18.8337 14.6666C18.8337 16.2379 18.8337 17.0236 18.3455 17.5118C17.8573 17.9999 17.0717 17.9999 15.5003 17.9999H5.50033C3.92898 17.9999 3.1433 17.9999 2.65515 17.5118C2.16699 17.0236 2.16699 16.2379 2.16699 14.6666Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M2.16699 5.50008C2.16699 3.92873 2.16699 3.14306 2.65515 2.6549C3.1433 2.16675 3.92898 2.16675 5.50033 2.16675H15.5003C17.0717 2.16675 17.8573 2.16675 18.3455 2.6549C18.8337 3.14306 18.8337 3.92873 18.8337 5.50008C18.8337 7.07143 18.8337 7.8571 18.3455 8.34526C17.8573 8.83341 17.0717 8.83341 15.5003 8.83341H5.50033C3.92898 8.83341 3.1433 8.83341 2.65515 8.34526C2.16699 7.8571 2.16699 7.07143 2.16699 5.50008Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path d="M9.66699 5.5H15.5003" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.5 5.5H7.16667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9.66699 14.6667H15.5003" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.5 14.6667H7.16667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default SectionsIcon;
