import { useEffect } from 'react';
import { compose } from 'redux';
import withStore from 'store/StoreContainer';

import { Empty } from 'modules/v2/common/components';
import {
  getReleaseNotes,
  getLatestReleaseNotes,
  releaseNotesStateSelector,
  latestReleaseNoteStateSelector,
} from 'modules/v2/store';

const withReleaseNotes =
  (Component = Empty) =>
    (props) => {
      useEffect(() => {
        props.getLatestReleaseNotes();
        props.getReleaseNotes();

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return <Component {...props} />;
    };

const mapDispatchToProps = {
  getReleaseNotes,
  getLatestReleaseNotes,
};

const mapStateToProps = (state) => ({
  releaseNotes: releaseNotesStateSelector(state),
  latestReleaseNote: latestReleaseNoteStateSelector(state),
});

export default compose(withStore(mapStateToProps, mapDispatchToProps), withReleaseNotes);
