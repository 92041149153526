const AuthorifyOnboardIcon = ({ ...props }) => {
  return (
    <svg width="182" height="34" viewBox="0 0 182 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M48.5904 10.8924C49.9538 12.0965 50.6372 13.909 50.6372 16.3337V25.6429H46.1767V23.6119C45.2795 25.1276 43.6107 25.8851 41.1654 25.8851C39.903 25.8851 38.8094 25.6721 37.8822 25.2483C36.9551 24.8231 36.2473 24.2368 35.7597 23.4896C35.2697 22.7415 35.0259 21.8933 35.0259 20.9417C35.0259 19.4252 35.6011 18.2337 36.7515 17.3658C37.9027 16.4962 39.6797 16.0615 42.0839 16.0615H45.8705C45.8705 15.0326 45.5565 14.2372 44.9245 13.6817C44.2924 13.1254 43.3464 12.8477 42.0839 12.8477C41.2073 12.8477 40.3456 12.9842 39.5013 13.2564C38.6555 13.5294 37.939 13.8995 37.3473 14.3635L35.6366 11.0597C36.533 10.4324 37.6076 9.94637 38.8591 9.60314C40.1121 9.25911 41.3998 9.08789 42.7238 9.08789C45.2701 9.08868 47.2261 9.69072 48.5904 10.8924ZM44.5268 22.1419C45.1588 21.7679 45.607 21.2171 45.8705 20.4888V18.8216H42.6031C40.6478 18.8216 39.6702 19.4583 39.6702 20.7318C39.6702 21.3378 39.9093 21.816 40.3874 22.1703C40.8648 22.5269 41.5221 22.7029 42.3585 22.7029C43.1736 22.7021 43.8955 22.5159 44.5268 22.1419Z" fill="#252525"/>
      <path d="M70.5557 9.33203V25.6432H66.0337V23.7021C65.4033 24.4107 64.6497 24.9512 63.7747 25.3252C62.898 25.6976 61.9504 25.8854 60.9333 25.8854C58.7745 25.8854 57.0638 25.2708 55.799 24.0351C54.5373 22.8034 53.9053 20.976 53.9053 18.5481V9.33203H58.6719V17.8529C58.6719 20.4789 59.7821 21.7926 62.0017 21.7926C63.1419 21.7926 64.0579 21.4234 64.7507 20.6864C65.4427 19.9479 65.7899 18.8527 65.7899 17.3961V9.33203H70.5557Z" fill="#252525"/>
      <path d="M85.9521 24.8558C85.4834 25.1998 84.909 25.4579 84.2264 25.6291C83.5431 25.8011 82.8259 25.8855 82.0723 25.8855C80.1179 25.8855 78.6045 25.3916 77.5361 24.3998C76.4662 23.4103 75.9312 21.9561 75.9312 20.0355V13.3342H73.396V9.69509H75.9312V5.72461H80.6978V9.69509H84.7906V13.3342H80.6978V19.974C80.6978 20.6597 80.8762 21.1923 81.232 21.5679C81.5871 21.9395 82.0937 22.1273 82.7438 22.1273C83.4974 22.1273 84.1404 21.9245 84.6699 21.5205L85.9521 24.8558Z" fill="#252525"/>
      <path d="M103.566 10.9083C104.819 12.1211 105.444 13.9201 105.444 16.3054V25.643H100.678V17.0345C100.678 15.7389 100.393 14.7739 99.8231 14.1371C99.2526 13.5011 98.4296 13.1824 97.3462 13.1824C96.1453 13.1824 95.189 13.5524 94.4749 14.2894C93.7632 15.0279 93.4065 16.1231 93.4065 17.5789V25.6438H88.6431V3.14648H93.4065V11.0291C94.0393 10.4033 94.8008 9.92281 95.6979 9.58983C96.5943 9.25607 97.5719 9.088 98.6308 9.088C100.668 9.08879 102.313 9.69399 103.566 10.9083Z" fill="#252525"/>
      <path d="M111.998 24.8096C110.621 24.0908 109.548 23.0942 108.773 21.8215C107.998 20.5512 107.615 19.1056 107.615 17.4873C107.615 15.8697 107.997 14.425 108.773 13.1515C109.547 11.878 110.621 10.883 111.998 10.1649C113.371 9.44691 114.924 9.08789 116.656 9.08789C118.389 9.08789 119.935 9.4477 121.3 10.1649C122.665 10.8822 123.732 11.878 124.509 13.1515C125.281 14.4242 125.669 15.8697 125.669 17.4873C125.669 19.1056 125.281 20.5512 124.509 21.8215C123.732 23.0942 122.665 24.0908 121.3 24.8096C119.935 25.5245 118.39 25.8843 116.656 25.8843C114.924 25.8851 113.371 25.5253 111.998 24.8096ZM119.665 20.7768C120.45 19.9586 120.844 18.8618 120.844 17.4873C120.844 16.1136 120.45 15.0152 119.665 14.1985C118.883 13.3795 117.879 12.97 116.656 12.97C115.433 12.97 114.425 13.3795 113.632 14.1985C112.839 15.016 112.439 16.1136 112.439 17.4873C112.439 18.8618 112.839 19.9586 113.632 20.7768C114.425 21.5935 115.433 22.0054 116.656 22.0054C117.879 22.0054 118.882 21.5935 119.665 20.7768Z" fill="#252525"/>
      <path d="M134.788 9.69388C135.756 9.29068 136.871 9.08789 138.134 9.08789V13.4545C137.603 13.4142 137.247 13.3945 137.063 13.3945C135.7 13.3945 134.633 13.7725 133.855 14.5307C133.082 15.289 132.697 16.4244 132.697 17.9402V25.6429H127.929V9.33171H132.483V11.485C133.051 10.696 133.821 10.101 134.788 9.69388Z" fill="#252525"/>
      <path d="M141.797 6.2997C141.246 5.79471 140.973 5.16821 140.973 4.42019C140.973 3.67218 141.246 3.04568 141.797 2.54148C142.349 2.03491 143.061 1.7832 143.937 1.7832C144.813 1.7832 145.525 2.02465 146.077 2.50991C146.627 2.99439 146.9 3.60038 146.9 4.32867C146.9 5.11771 146.627 5.76946 146.077 6.28471C145.525 6.79996 144.813 7.05719 143.937 7.05719C143.061 7.05719 142.35 6.80548 141.797 6.2997ZM141.553 9.33201H146.322V25.6432H141.553V9.33201Z" fill="#252525"/>
      <path d="M156.435 9.69325H160.647V13.3323H156.554V25.6422H151.79V13.3323H149.251V9.69325H151.79V8.96733C151.79 7.10833 152.342 5.63203 153.453 4.54078C154.563 3.44874 156.13 2.9043 158.142 2.9043C158.856 2.9043 159.534 2.98005 160.173 3.13154C160.817 3.28304 161.35 3.50003 161.778 3.78329L160.525 7.20933C159.975 6.82586 159.333 6.63254 158.602 6.63254C157.155 6.63254 156.434 7.42159 156.434 8.99652L156.435 9.69325Z" fill="#252525"/>
      <path d="M181.789 9.33282L174.368 26.6445C173.613 28.5248 172.68 29.8496 171.573 30.6181C170.462 31.3851 169.12 31.7678 167.555 31.7678C166.699 31.7678 165.851 31.6352 165.016 31.3756C164.181 31.1129 163.499 30.7483 162.968 30.282L164.711 26.9191C165.079 27.2394 165.5 27.4943 165.978 27.675C166.455 27.8573 166.928 27.9496 167.399 27.9496C168.051 27.9496 168.58 27.7926 168.987 27.4777C169.396 27.1653 169.763 26.6437 170.089 25.917L170.147 25.7647L163.031 9.33203H167.95L172.561 20.396L177.205 9.33203L181.789 9.33282Z" fill="#252525"/>
      <path d="M10.0649 30.3729C11.183 30.7532 12.3753 30.9513 13.5999 30.9513C14.8158 30.9513 15.9994 30.7548 17.1088 30.384V29.2896L10.0649 29.2793V30.3729Z" fill="#252525"/>
      <path d="M14.1507 12.8692L14.1396 19.2683C14.531 19.3772 14.8829 19.5879 15.1536 19.8956L15.2285 19.9832L15.3051 20.0771L15.3382 20.1379C15.3753 20.1994 15.4061 20.2618 15.4368 20.3265L15.4968 20.4464L15.5094 20.4922C15.8014 21.3041 15.4763 22.2249 14.6572 22.7031C13.6962 23.2601 12.4298 22.9887 11.8285 22.1002C11.2257 21.2078 11.5168 20.0337 12.4779 19.4774C12.6657 19.3662 12.8653 19.2991 13.0697 19.2502L13.0815 12.8676L12.1575 12.8652C11.464 16.6905 9.56001 18.7129 7.09424 21.2646L10.0666 28.5467C16.218 28.5554 10.4958 28.5467 17.1112 28.557L20.1103 21.2859C17.415 18.4217 15.8929 16.5469 15.0778 12.87L14.1507 12.8692Z" fill="#252525"/>
      <path d="M24.5805 13.9985L13.6001 0L2.61896 13.9985C-1.33811 19.0436 -0.757372 25.906 3.99742 30.3175C6.64388 32.773 10.122 34 13.5993 34C17.0774 34 20.554 32.773 23.202 30.3175C27.9568 25.906 28.5383 19.0436 24.5805 13.9985ZM21.4101 28.655C20.1808 29.7952 18.7116 30.6316 17.109 31.1279C15.9917 31.4751 14.8121 31.6557 13.6001 31.6557C12.3802 31.6557 11.1904 31.4703 10.0652 31.1192C8.4721 30.6237 7.01158 29.7889 5.79013 28.655C1.92144 25.068 1.45038 19.4847 4.66811 15.3817L13.6009 3.99415L22.5337 15.3817C25.7506 19.4847 25.2788 25.0688 21.4101 28.655Z" fill="#252525"/>
    </svg>

  );
};

export default AuthorifyOnboardIcon;
