function OnDemandCampaignIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64306 4.30932C1.66675 5.28563 1.66675 6.85698 1.66675 9.99967C1.66675 13.1424 1.66675 14.7137 2.64306 15.69C3.61937 16.6663 5.19072 16.6663 8.33341 16.6663H11.6667C14.8094 16.6663 16.3808 16.6663 17.3571 15.69C18.3334 14.7137 18.3334 13.1424 18.3334 9.99967C18.3334 6.85698 18.3334 5.28563 17.3571 4.30932C16.3808 3.33301 14.8094 3.33301 11.6667 3.33301H8.33341C5.19072 3.33301 3.61937 3.33301 2.64306 4.30932ZM15.4802 6.26623C15.7012 6.5314 15.6654 6.9255 15.4002 7.14648L13.5698 8.67181C12.8312 9.28736 12.2325 9.78627 11.7041 10.1261C11.1537 10.4801 10.6176 10.7037 10.0001 10.7037C9.38252 10.7037 8.84648 10.4801 8.29606 10.1261C7.76768 9.78628 7.16901 9.28737 6.43038 8.67182L4.59997 7.14648C4.33479 6.9255 4.29897 6.5314 4.51994 6.26623C4.74092 6.00105 5.13502 5.96522 5.4002 6.1862L7.19928 7.68544C7.97674 8.33332 8.51652 8.78169 8.97223 9.07478C9.41337 9.3585 9.71252 9.45374 10.0001 9.45374C10.2876 9.45374 10.5868 9.3585 11.0279 9.07478C11.4836 8.78169 12.0234 8.33332 12.8009 7.68543L14.6 6.1862C14.8651 5.96522 15.2592 6.00105 15.4802 6.26623Z"
        fill="#252525"
      />
    </svg>
  );
}

export default OnDemandCampaignIcon;
