import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Card = styled.div`
  display: grid;
  min-width: 744px;
  margin-bottom: 20px;
  border: 1px solid ${color.GreyBorder};
  border-radius: 2px;
  grid-template-areas:
    'header header header header header'
    'image templateForm templateForm days days'
    'footer footer footer footer footer';
`;

const CardHeader = styled.div`
  background: ${color.GreyHover};
  grid-area: header;
  line-height: 35px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid ${color.GreyBorder};
  padding: 0 0 0 20px;
`;

const CardImage = styled.div`
  grid-area: image;
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const CardTemplate = styled.div`
  grid-area: templateForm;
  padding: 20px;
`;

const CardDays = styled.div`
  grid-area: days;
  padding-right: 20px;
  padding: 20px;
`;

const CardFooter = styled.div`
  height: 10px;
  background: ${color.GreyHover};
  grid-area: footer;
  border-top: 1px solid ${color.GreyBorder};
`;

const Label = styled.label`
  margin-bottom: 10px;
  display: block;
`;

export default {
  Card,
  CardHeader,
  CardImage,
  CardTemplate,
  CardDays,
  CardFooter,
  Label,
};
