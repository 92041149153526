import { get } from 'lodash-es';
import { STATUS_SUCCESS, STATUS_ERROR, STATUS_LOADING } from 'store/status';

import { CLEAR_LINKS, GET_LINKS } from 'modules/bookLinks/store/constants';

import { links as initialState } from '../initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_LINKS}_REQUESTED`: {
      return {
        ...state,
        status: STATUS_LOADING,
      };
    }

    case `${GET_LINKS}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${GET_LINKS}_SUCCEEDED`: {
      const payload = get(action, ['payload'], []);

      const data = payload.map((link) => {
        const title = get(link, 'bookSource', '');
        const unformattedDate = get(link, 'orderDate', '');
        const orderDate = new Date(unformattedDate).toLocaleDateString('en-US', {
          dateStyle: 'medium',
        });

        return {
          ...link,
          title,
          orderDate,
        };
      });

      return {
        ...state,
        data,
        status: STATUS_SUCCESS,
      };
    }

    case CLEAR_LINKS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default reducer;
