import join from 'url-join';
import { get, map, startsWith } from 'lodash-es';

import env from 'environments';
import { getPayload } from 'modules/api';

export const mapImageUrlsPayload = (response) => {
  const data = getPayload(response);
  const files = get(data, ['files'], null);

  const mapped = map(files, (image) => {
    const rawUrl = get(image, 'url');
    const prefixedUrl = join(env.BASE_AUTH_URL_STATIC_FILES, image.url);
    const url = startsWith(rawUrl, 'http') ? rawUrl : prefixedUrl;
    return {
      ...image,
      url,
    };
  });

  return mapped;
};
