import { call, takeEvery } from 'redux-saga/effects';
import { resource, regenerateOrder, getError } from 'modules/api';
import { SUBMIT_REORDER } from 'modules/publish/store/constants';

export function* onSubmitReorder({ payload: meta }) {
  try {
    const request = resource(SUBMIT_REORDER, regenerateOrder);
    const payload = yield call(request, meta);

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetSubmitReorder() {
  yield takeEvery(SUBMIT_REORDER, onSubmitReorder);
}
