import { spawn } from 'redux-saga/effects';

import { watchDeleteUserImage } from './deleteUserImage';
import { watchGetPresetLogos } from './getPresetLogos';
import { watchGetUserImages } from './getUserImages';

export default function* saga() {
  yield spawn(watchDeleteUserImage);
  yield spawn(watchGetPresetLogos);
  yield spawn(watchGetUserImages);
}
