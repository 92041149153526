import styled from 'styled-components';
import { Button } from 'antd';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const getSizeStyles = (size) => {
  switch (size) {
    case 'large':
      return `
        min-width: 62px;
        height: 67px;
        font-size: 16px !important;
        line-height: 20px;
      `;
    case 'middle':
      return `
        min-width: 50px;
        height: 46px;
        font-size: 14px !important;
        line-height: 16px;
      `;
    case 'small':
      return `
        min-width: 40px;
        height: 32px;
        font-size: 12px !important;
        line-height: 14px;
      `;
    default:
      return `
        min-width: 50px;
        height: 46px;
        font-size: 14px;
        line-height: 16px;
      `;
  }
};

const CustomButton = styled(Button)`
  ${({ size }) => getSizeStyles(size)}
  display: flex !important;
  justify-content: center !important;
  align-content: center;
  align-items: center;

  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    padding: 10px 15px;

    border-radius: 4px;
    font-family: ${font.RobotoFont} !important;
    text-align: center;

    cursor: pointer;
    transition: all 0.3s ease-in-out;

    span {
      position: relative;
      top: 2px;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CancelButton = styled(CustomButton)`
  background-color: ${color.White} !important;
  border: 1px solid ${color.gray300} !important;
`;
// color: ${color.CancelButtonText} !important;

const PrimaryButton = styled(CustomButton)`
  color: ${color.White};
  background-color: ${color.PrimaryButton};
  border: none;

  &:enabled,
  &:focus,
  &:active,
  &:visited {
    color: ${color.White};
    background-color: ${color.PrimaryButtonHover};
    border: none;
  }

  :hover {
    background-color: ${color.PrimaryButtonHover} !important;
    border: none;
  }

  &:disabled {
    color: ${color.White};
    background-color: ${color.PrimaryButtonHover};
    border: none;
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SecondaryButton = styled(CustomButton)`
  color: ${color.SecondaryButtonText};
  border: 1px solid ${color.SecondaryButtonBorder};

  &:hover:enabled,
  &:focus,
  &:active,
  &:visited {
    color: ${color.SecondaryButtonText};
    border: 1px solid ${color.SecondaryButtonBorderHover};
  }
`;

const TertiaryButton = styled(CustomButton)`
  background: none !important;
  text-transform: capitalize;
  border: none;
  color: ${color.PrimaryButton} !important;
  :hover {
    color: ${color.PrimaryButtonHover} !important;
  }
`;

const PrimaryBlackButton = styled(CustomButton)`
  background-color: ${color.BlackButton} !important;
  color: ${color.White} !important;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8 !important;
  }

  :disabled {
    background-color: rgba(0, 0, 0, 0.4) !important;
    cursor: not-allowed;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }
`;

const SecondaryBlackButton = styled(CustomButton)`
  font-weight: 400;
  margin: 0 !important;
  background-color: ${color.transparent} !important;
  color: ${color.BlackButton} !important;

  &:hover,
  &:active,
  &:focus {
    background-color: ${color.transparent} !important;
  }

  :disabled {
    background-color: rgba(0, 0, 0, 0.4) !important;
    cursor: not-allowed;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }
`;

const BaseTableButton = styled(Button)`
  height: 43px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 30px !important;
  vertical-align: middle;
  text-align: center;
  background-color: none;

  svg {
    margin: 0 5px;
  }

  .disabled {
    opacity: 0.5;
  }
  .ant-pagination-disabled {
    opacity: 0.5;
  }
`;

const TableButton = styled(BaseTableButton)`
  border: none !important;
  background-color: ${(props) => (!props.$isActive ? color.White : color.ActivePage)} !important;
  color: ${(props) => (!props.$isActive ? color.gray100 : color.White)} !important;

  :hover {
    background-color: ${color.ActivePageHover} !important;
    color: ${color.White} !important;
  }
`;

const PreviousButton = styled(BaseTableButton)`
  background-color: ${color.White};
  border: 1px solid ${color.gray200} !important;
  color: ${color.gray100} !important;

  span {
    margin-bottom: 1px;

    svg {
      margin: 0 5px;
    }
  }

  :hover {
    border: 1px solid ${color.ActivePage} !important;
    color: ${color.ActivePageHover} !important;
  }
`;

const NextButton = styled(BaseTableButton)`
  background-color: ${color.ActivePage} !important;
  border: none;
  color: ${color.White} !important;

  span {
    margin-bottom: 1px;

    svg {
      margin: 0 5px;
    }
  }

  :hover {
    color: ${color.White} !important;
    background-color: ${color.ActivePageHover} !important;
  }
`;

const DeleteButton = styled(CustomButton)`
  background-color: ${color.DeleteButtonBackground};
  border-radius: 6px;
  border: 1px solid ${color.DeleteButtonBackground};
  color: ${color.White}!important;

  &:hover:enabled,
  &:focus,
  &:active,
  &:visited {
    background-color: ${color.DeleteButtonBackground};
    color: ${color.SecondaryButtonText};
    border: 1px solid ${color.SecondaryButtonBorderHover};
  }
`;

const CountButton = styled(Button)`
  ${({ size }) => getSizeStyles(size)}
  ${({ disabled }) =>
    disabled ? `background: ${color.White} !important;` : ''}

  display: flex !important;
  justify-content: center !important;
  align-content: center;
  align-items: center;

  border: none !important;

  .ant-btn {
    padding: 0;
    background-color: none !important;

    border-radius: 4px;
    font-family: ${font.RobotoFont} !important;
    font-weight: normal !important;
    text-align: center;

    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background-color: none !important;
    color: ${color.GrayDisabled} !important;
    cursor: not-allowed;
  }
`;

export {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  PrimaryBlackButton,
  SecondaryBlackButton,
  CancelButton,
  TableButton,
  PreviousButton,
  NextButton,
  CountButton,
  DeleteButton,
};
