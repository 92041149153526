import { call, takeEvery } from 'redux-saga/effects';

import { fetchCustomerPortalLink, resource, getError } from 'modules/api';

import { GET_CUSTOMER_PORTAL_LINK } from 'modules/dashboard/store/constants';

export function* onGetCustomerPortalLink({ payload: meta }) {
  try {
    const request = resource(GET_CUSTOMER_PORTAL_LINK, fetchCustomerPortalLink);
    const result = yield call(request, meta);

    return result;
  } catch (error) {
    const result = getError(error);

    // eslint-disable-next-line no-console
    console.error(result);
    return result;
  }
}

export function* watchGetCustomerPortalLink() {
  yield takeEvery(GET_CUSTOMER_PORTAL_LINK, onGetCustomerPortalLink);
}
