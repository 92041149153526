import React from 'react';
import { NavBar } from 'modules/nav/components';
import { SaveIcon, ExitIcon } from 'modules/common/components/SvgIcon';

import { EditorDropdown } from './components';

const getSaveLabel = (saving, pending) => {
  if (saving) return 'Saving...';
  if (pending) return 'Save';

  return 'Saved';
};

const EditorNavbar = ({
  bookTitle,
  dropdownTitle,
  dropDownItems,
  isSaving,
  isPendingSave,
  onSave,
  onExit,
}) => {
  const saveLabel = getSaveLabel(isSaving, isPendingSave);
  const isSaveDisabled = isSaving || !isPendingSave;

  const mainContent = <NavBar.Title>{bookTitle}</NavBar.Title>;

  const sideContent = (
    <>
      <EditorDropdown title={dropdownTitle} items={dropDownItems} />
      <NavBar.ButtonGroup>
        <NavBar.Button
          data-testid="save"
          icon={SaveIcon}
          onClick={onSave}
          disabled={isSaveDisabled}
        >
          {saveLabel}
        </NavBar.Button>
      </NavBar.ButtonGroup>
      <NavBar.Button data-testid="exit" icon={ExitIcon} onClick={onExit}>
        Exit
      </NavBar.Button>
    </>
  );
  return <NavBar mainContent={mainContent} sideContent={sideContent} hasSeparator />;
};

export default React.memo(EditorNavbar);
