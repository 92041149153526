import DefaultImage from 'assets/images/logo/authorify-logo-black.svg';
import { useState, useEffect } from 'react';
import { NewBookIcon, ArrowDownTable, SkeletonImageIcon } from 'modules/v2/common/components/SvgIcon';
import { formatDateTime2 } from 'modules/common/utils';
import { v4 as uuidv4 } from 'uuid';
import env from 'environments';

const BookTable = ({ bookData, loading }) => {
  const [books, setBooks] = useState(bookData);

  const [sortColumns, setSortColumns] = useState({
    visitCount: false,
    leadCount: false,
    readCount: false,
    created: true,
  });

  function sortBooks(column, ascending = false) {
    return [...books].sort((p1, p2) => {
      if (ascending) {
        if (p1[column] < p2[column]) {
          return -1;
        }
        if (p1[column] > p2[column]) {
          return 1;
        }
        return 0;
      }
      if (p1[column] < p2[column]) {
        return 1;
      }
      if (p1[column] > p2[column]) {
        return -1;
      }
      return 0;
    });
  }

  const bookFiltered = (column = '') => {
    if (!column) {
      setBooks(bookData);
      return;
    }

    setSortColumns({ ...sortColumns, [column]: !sortColumns[column] });
    const newBook = sortBooks(column, sortColumns[column]);
    setBooks(newBook);
  };

  useEffect(() => {
    bookFiltered();
  }, [bookData]);

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-b rounded-b-lg shadow-md">
        <thead className="w-full bg-neutral-0 mb-[12px] border-b">
          <tr className="cursor-pointer">
            <th className="flex items-center w-[454px] py-[12px] px-[18px] font-semibold">
              Book landing page
            </th>
            <th className="py-[12px] px-[18px] font-semibold whitespace-nowrap">
              <div
                className="flex items-center break-keep gap-1"
                onClick={() => bookFiltered('visitCount')}
              >
                Landing visits{' '}
                <div className={`${sortColumns.visitCount ? '' : 'rotate-180'}`}>
                  <ArrowDownTable />
                </div>
              </div>
            </th>
            <th
              className="py-[12px] px-[18px] font-semibold whitespace-nowrap"
              onClick={() => bookFiltered('leadCount')}
            >
              <div className="flex items-center gap-1">
                Leads collected{' '}
                <div className={`${sortColumns.leadCount ? '' : 'rotate-180'}`}>
                  <ArrowDownTable />
                </div>
              </div>
            </th>
            <th
              className="py-[12px] px-[18px] font-semibold whitespace-nowrap"
              onClick={() => bookFiltered('readCount')}
            >
              <div className="flex items-center gap-1">
                Digital visits{' '}
                <div className={`${sortColumns.readCount ? '' : 'rotate-180'}`}>
                  <ArrowDownTable />
                </div>
              </div>
            </th>
            <th
              className="py-[12px] px-[18px] font-semibold whitespace-nowrap"
              onClick={() => bookFiltered('created')}
            >
              <div className="flex items-center gap-1">
                Date created{' '}
                <div className={`${sortColumns.created ? '' : 'rotate-180'}`}>
                  <ArrowDownTable />
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody className="w-full bg-neutral-0 mb-[12px]">
          {loading ? 
            Array.from({ length: 5 }).map(() => (
              <tr className="animate-pulse">
                <div className="flex items-center h-[156px] py-[12px] px-[18px]">
                  <div className="flex items-center justify-center w-[77px] h-[120px] bg-gray-300 rounded dark:bg-gray-700">
                    <SkeletonImageIcon width={30} height={30}/>
                  </div>
                  <div className="ml-[14px] w-[310px]">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 w-[310px]" />
                    <div className="h-8 bg-gray-200 mt-2 rounded-lg dark:bg-gray-700 w-[310px]" />
                  </div>
                </div>
                <td className="py-[12px] px-[18px] font-medium" aria-label="skeleton-td">
                  <div className="h-4 w-4 bg-gray-200 dark:bg-gray-700" />
                </td>
                <td className="py-[12px] px-[18px] font-medium" aria-label="skeleton-td">
                  <div className="h-4 w-4 bg-gray-200 dark:bg-gray-700" />
                </td>
                <td className="py-[12px] px-[18px] font-medium" aria-label="skeleton-td">
                  <div className="h-4 w-4 bg-gray-200 dark:bg-gray-700" />
                </td>
                <td className="py-[12px] px-[18px] font-medium" aria-label="skeleton-td">
                  <div className="h-4 w-24 bg-gray-200 dark:bg-gray-700" />  
                </td>
              </tr>
            ))
            : 
            (
              books.map(
                ({ thumbnailUrl = '', title, name, visitCount, leadCount, readCount, created }) => {
                  let parsedThumbnail = thumbnailUrl;

                  if (thumbnailUrl && !thumbnailUrl.includes('http')) {
                    parsedThumbnail = `${env.BASE_URL}${thumbnailUrl}`;
                  }

                  return (
                    <tr className="border-b" key={`${title}-${uuidv4()}`}>
                      <td className="flex items-center h-[156px] py-[12px] px-[18px]">
                        <img
                          className="h-[120px]"
                          src={parsedThumbnail ?? DefaultImage}
                          alt="LandingPageBooks"
                        />
                        <div className="ml-[14px] font-semibold lowercase first-letter:uppercase break-words max-w-[310px]">
                          {title}
                          <div className="flex bg-neutral-100 py-[4px] px-[8px] gap-2 w-fit rounded-lg mt-[8px]">
                            <NewBookIcon />
                            {name}
                          </div>
                        </div>
                      </td>
                      <td className="py-[12px] px-[18px] font-medium">
                        {visitCount?.toLocaleString() ?? 0}
                      </td>
                      <td className="py-[12px] px-[18px] font-medium">
                        {leadCount?.toLocaleString() ?? 0}
                      </td>
                      <td className="py-[12px] px-[18px] font-medium">
                        {readCount?.toLocaleString() ?? 0}
                      </td>
                      <td className="py-[12px] px-[18px] font-medium">{formatDateTime2(created)}</td>
                    </tr>
                  );
                }
              )
            )}
          
        </tbody>
      </table>
    </div>
  );
};

export default BookTable;
