import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { Dropdown as BaseDropdown } from 'antd';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Dropdown = styled(BaseDropdown)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MenuLink = styled(Link)`
  margin-right: 0;
  background: none;
  border: none;
  outline: none;

  color: ${color.White};
  font-weight: 500;
  font-family: ${font.FigtreeFont};
  text-transform: capitalize;
  line-height: 64px;
  letter-spacing: 0px;
  white-space: nowrap;

  cursor: pointer;

  transition: background-color 0.2s ease;

  :hover {
    color: ${color.ActiveMenuItemColor};
    transition: 0.2s ease all;
  }

  :focus,
  :active,
  &.active {
    color: ${color.ActiveMenuItemColor};
  }
`;

const MenuNavLink = MenuLink.withComponent(NavLink);

const MenuSpan = styled.span`
  padding: 21px 20px;

  :hover {
    border-bottom: 3px solid ${color.PrimaryColor};
    transition: 0.2s ease all;
  }
`;

const Button = styled.button``;

Button.Label = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
`;

Button.Icon = styled.div`
  display: flex;
  padding: 0 0 0 10px;
  vertical-align: middle;

  & > svg {
    display: block;
    height: 20px;
  }
`;

export default {
  Button,
  Dropdown,
  MenuLink,
  MenuNavLink,
  MenuSpan,
};
