import React from 'react';
import { Alert, CustomFlowbiteTheme } from 'flowbite-react';
import classNames from 'classnames';

import { CloseCircleIcon, CheckIconNew } from 'modules/v2/common/components/SvgIcon';

interface NotificatiionProps {
  type?: 'success' | 'error';
  title?: string;
  description: string | React.ReactNode;
}

const defaultProps: Partial<NotificatiionProps> = {
  type: 'success',
  title: '',
};

const customTheme: CustomFlowbiteTheme['alert'] = {
  base:
    'fixed top-16 right-2.5 p-[17px] text-sm bg-white border border-notificationBorder rounded-md shadow-notification',
  color: {
    notifcation: 'text-neutral-800',
  },
};

const Notification = ({ type, title, description }: NotificatiionProps) => {
  const icon = () => (
    <div
      className={classNames(
        'w-[21px] h-[21px] flex items-center justify-center rounded-full',
        type === 'error' ? 'bg-error-500' : 'bg-success-500',
      )}
    >
      {type === 'success' ? <CheckIconNew fill="#fff" /> : <CloseCircleIcon fill="#fff" />}
    </div>
  );

  return (
    <Alert theme={customTheme} color="notification" className="fixed top-[64px] right-2.5">
      <div className="flex items-center gap-2">
        {icon()}
        <div>
          <span className="font-medium">{title}</span>
          {description}
        </div>
      </div>
    </Alert>
  );
};

Notification.defaultProps = defaultProps;

export default Notification;
