import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import env from 'environments';
import LogoImg from 'assets/images/logo/authorify-logo-black.svg';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { AuthorifyOnboardIcon } from 'modules/v2/common/components/SvgIcon';

import S from './index.styled';

const isNewLogin = env.AFY_NEW_LOGIN;

const AuthenticationTemplate = ({
  children,
  noBackground,
  pageTitle = 'Welcome back to Authorify!',
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isTablet = windowSize < 768;
  const isMobile = windowSize < 500;
  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
            html { 
              font-size: 16px; 
            }

            html #root {
              font-family: '"Figtree", sans-serif'!important
            }
          `}
        </style>
      </Helmet>
      {isNewLogin ? (
        <div className="w-full h-full flex flex-col items-center font-figtree">
          <div className="w-full h-[84px] flex items-center justify-center border-t-[5px] border-primary-500">
            <div className="w-[1408px] flex items-center px-[40px] justify-between">
              {isTablet ? (
                <img className="h-[34px]" src={LogoImg} alt="Authorify Logo" />
              ) : (
                <AuthorifyOnboardIcon className="min-w-[190px]" />
              )}
              <div className="text-neutral-500 text-center text-sm font-normal flex items-center gap-[20px]">
                {!isMobile && <p className="text-nowrap">Not yet a member?</p>}
                <Button size="sm" className="font-semibold">
                  <a
                    href={env.PRICING_PLANS}
                    target="_blank"
                    rel="noreferrer"
                    className="text-inherit"
                  >
                    See our plans & pricing
                  </a>
                </Button>
              </div>
            </div>
          </div>
          {children}
        </div>
      ) : (
        <S.LoginWrapper noBackground={noBackground}>
          <div className="w-[398px] m-auto flex flex-col items-center">
            <Link to="/">
              <img className="h-[57px] mb-[6px]" src={LogoImg} alt="Authorify Logo" />
            </Link>
            <span className="text-neutral-800 text-2xl font-extrabold">{pageTitle}</span>
            {children}
          </div>
        </S.LoginWrapper>
      )}
    </>
  );
};

export default AuthenticationTemplate;
