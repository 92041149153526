import classnames from 'classnames';
import { Link as BaseLink } from 'react-router-dom';

type LinkProps = {
  className?: string;
  type?: 'primary' | 'secondary';
  to: string;
};

const typeLinkStyles = {
  primary: 'text-primary-600 border-b border-primary-600',
  secondary: 'text-neutral-800 border-b border-neutral-800',
};

// For exernal links, we will not be able to use React Router <Link /> component and we will have to use <a /> tag
// In that case, we can use the below style for primary links and secondary links
export const primaryLinkStyle = typeLinkStyles.primary;
export const secondaryLinkStyle = typeLinkStyles.secondary;

const Link = ({ className, type = 'primary', ...props }: LinkProps) => (
  <BaseLink
    className={classnames('text-sm font-semibold', typeLinkStyles[type], className)}
    {...props}
  />
);

export default Link;
