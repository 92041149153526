import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { FileImageOutlined, SyncOutlined } from '@ant-design/icons';
import { Input } from 'modules/common/components/UIComponents/Form';
import { Modal } from 'modules/common/components';
import { Field } from 'formik';
import { Select as BaseSelect } from 'antd';

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: 245px;
  }
  .ant-modal-content {
    width: 700px;
  }
`;

const Wrapper = styled.div`
  background: ${color.White};
  padding: 2rem;
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
  color: ${color.UserMenuText};
  margin-bottom: 10px;
`;

const { TextArea } = Input;

const InputTextArea = styled(TextArea)`
  height: 64px;
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  font-size: 18px;
  line-height: 22px;
  color: ${color.UserMenuText};
`;

const Message = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: ${(props) => (props.primary ? 'flex-end' : ' ')};
  line-height: 17px;
  margin-top: 10px;
`;
const VerifiedMessage = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: ${(props) => (props.primary ? 'flex-end' : ' ')};
  line-height: 17px;
  margin-top: 10px;
  color: green;
`;

const footerWrapper = { marginTop: '40px', width: '227px', float: 'right' };
const MainWrapper = styled.div`
  padding-bottom: 24px;
  ${(props) => props.fotter && footerWrapper};
  ${(props) =>
    props.fotterleft && {
      marginTop: '40px',
      marginLeft: '40px',
      width: '227px',
      float: 'right',
    }};
  ${(props) =>
    props.emaibutton && {
      display: 'flex',
    }};
`;

const Button = styled.button`
  float: right;
  padding: 10px 20px;
  border: none;
  background: #1890ff;
`;

const EmailVerificationBtn = styled.button`
  width: 227px;
  height: 40px !important;
  background-color: ${color.SkyBlue};
  color: ${color.White};
  border: none;
  border-radius: 2px !important;
  opacity: 1;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.3px;

  &:hover {
    color: ${color.White};
    background-color: ${color.LeadsButtonHover};
    border-color: ${color.LeadsButtonHover};
    cursor: pointer;
  }
`;

const ButtonText = styled.span`
  margin-right: 14px;
`;

const EmailVerificationMessage = styled.div`
  font-size: 14px;
`;

const UploadPicture = styled.div`
  display: flex;
`;

const ProfilePicture = styled.div`
  cursor: pointer;
  background: ${color.GreyFormBorder};
  width: 80px;
  height: 80px;
  border: 1px solid ${color.GreyFormBorder};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashboardSubTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #059100;
`;

const UploadButton = styled(FileImageOutlined)`
  svg {
    width: 35px;
    height: 35px;
  }
`;

const EmailInput = styled(Input)`
  display: flex;
  text-transform: lowercase;
`;

const ImageRequirementsMessage = styled.div`
  font-size: 14px;
  margin-left: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const EmailRefresh = styled(SyncOutlined)`
  font-size: 20px;
  margin: 12px;
  color: #2e3f50;
  &:hover {
    color: ${color.SkyBlue};
  }
  cursor: pointer;
`;

const Select = styled(BaseSelect)`
  display: block;
  .ant-select-selection-selected-value {
    font-size: 16px;
    color: black;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px !important;
    padding: 7px 11px !important;
    width: 100% !important;
    border-radius: 3px !important;
    font-size: 16px !important;
    display: block;
  }
`;

export default {
  Wrapper,
  Label,
  FormikField,
  MainWrapper,
  Message,
  VerifiedMessage,
  ProfilePicture,
  UploadPicture,
  UploadButton,
  EmailInput,
  ImageRequirementsMessage,
  Button,
  EmailVerificationBtn,
  ButtonText,
  EmailVerificationMessage,
  InputTextArea,
  ModalWrapper,
  DashboardSubTitle,
  Image,
  EmailRefresh,
  Select,
  SelectContainer,
};
