import { PaginationProps } from 'antd/lib/pagination';
import { BookPreview } from 'modules/dashboard/pages/admin/BookPreviews/types';
import { getInstance } from './instance';

const instance = getInstance();
const PATH = '/book-previews';

export const getBookPreviews = async (pagination: PaginationProps = {}) => {
  const { current: page, pageSize: perPage } = pagination;

  const params = { page, perPage };

  try {
    const response = await instance.get(PATH, { params });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createBookPreviews = async (payload: BookPreview) => {
  try {
    const response = await instance.post(PATH, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateBookPreviews = async (payload: BookPreview) => {
  const { id } = payload;

  try {
    const response = await instance.patch(`${PATH}/${id}`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const deleteBookPreviews = async (id: string) => {
  try {
    const response = await instance.delete(`${PATH}/${id}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
