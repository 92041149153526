import { useEffect, useRef, useState } from 'react';
import { TextInput as BaseInput } from 'flowbite-react';
import { defaultOtpInputTheme } from './theme';

const OtpInput = ({ length, onSubmit, hasErrors = false }) => {
  const [value, setValue] = useState(new Array(length).fill(''));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const onChangeHandler = ({ target: { value: inputValue } }, index) => {
    if (Number.isNaN(inputValue)) return;

    const newValue = [...value];
    newValue[index] = inputValue.slice(-1);
    setValue(newValue);

    setValue(newValue);

    const finalValue = newValue.join('');
    if (finalValue.length === length) {
      onSubmit(finalValue);
    }

    if (inputValue && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === 'ArrowRight' && index < length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (e.key === 'Backspace') {
      e.preventDefault();
      const newValue = [...value];
      newValue[index] = '';
      setValue(newValue);
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text/plain').trim();

    if (pasteData.length > 0) {
      const newValue = [...value];
      let pasteIndex = 0;
      for (let i = index; i < length; i++) {
        if (pasteIndex < pasteData.length) {
          newValue[i] = pasteData[pasteIndex];
          pasteIndex += 1;
        } else {
          break;
        }
      }

      setValue(newValue);

      const finalValue = newValue.join('');
      if (finalValue.length === length) {
        onSubmit(finalValue);
      }

      if (pasteIndex < length) {
        inputRefs.current[index + pasteIndex].focus();
      }
    }
  };

  return (
    <div className="flex gap-2">
      {value.map((item, index) => (
        <BaseInput
          sizing="otp"
          key={index}
          value={item}
          color={hasErrors ? 'failure' : 'gray'}
          onChange={(e) => onChangeHandler(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1}
          theme={defaultOtpInputTheme}
          ref={(el) => (inputRefs.current[index] = el)}
        />
      ))}
    </div>
  );
};

export default OtpInput;
