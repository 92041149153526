import Highlight from '../Highlight';

const getEntityStrategy = (mutability) => (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    if (entityKey === null) {
      return false;
    }

    return contentState.getEntity(entityKey).getMutability() === mutability;
  }, callback);
};

const HighlightDecorator = {
  strategy: getEntityStrategy('IMMUTABLE'),
  component: Highlight,
};

export default HighlightDecorator;
