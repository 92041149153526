import { useState } from 'react';
import { useQuery } from 'react-query';
import { partition } from 'lodash-es';
import { useFormikContext } from 'formik';
import { Form, Col, Row } from 'antd';
import { getStatesByCountryName } from 'modules/api';

import {
  FieldGroup,
  FieldControl,
  Input,
  Select,
  ErrorMessage,
} from 'modules/common/components/UIComponents/Form';

import S from './styles';

const { Option } = Select;

const EditAddress = () => {
  const { values, setFieldValue, handleSubmit, errors, dirty } = useFormikContext();
  const [selectedCountry, setSelectedCountry] = useState(values.country || 'US');

  const { data: statesData } = useQuery('getStatesByCountryName', () =>
    getStatesByCountryName('Canada', 'USA'),
  );

  const generalStatesList = statesData?.data?.data || [];
  const [canadaStates, usaStates] = partition(
    generalStatesList,
    (state) => state.country === 'Canada',
  );

  const countryStates = selectedCountry === 'US' ? usaStates : canadaStates;
  const parsedCountryStates = countryStates.map(({ state }) => ({
    label: state,
    value: state,
  }));

  const handleSelectCountryChange = (value) => {
    setSelectedCountry(value);
    setFieldValue('state', '');
    setFieldValue('country', value);
  };

  const handleSelectStateChange = (value) => {
    setFieldValue('state', value);
  };

  const handleChange = (field) => (data) => {
    const value = data?.target?.value;

    setFieldValue(field, value);
  };

  return (
    <S.ModalWrapper>
      <Form layout="vertical" initialValues={values} onFinish={handleSubmit}>
        <Row gutter={20}>
          <Col flex={1}>
            <FieldGroup>
              <FieldControl>
                <Form.Item label="Street Address" name="address1" rules={[{ required: true }]}>
                  <Input onChange={handleChange('address1')} value={values.address1} />
                  {errors.address1 && dirty ? <ErrorMessage>{errors.address1}</ErrorMessage> : null}
                </Form.Item>
              </FieldControl>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col style={{ width: '50%' }}>
            <FieldGroup>
              <FieldControl>
                <Form.Item label="Country" name="country" rules={[{ required: true }]}>
                  <Select onChange={handleSelectCountryChange} value={values.country} size="large">
                    <Option key="USA" value="US" selected="selected">
                      USA
                    </Option>
                    <Option key="Canada" value="Canada">
                      Canada
                    </Option>
                  </Select>
                  {errors.country && dirty ? <ErrorMessage>{errors.country}</ErrorMessage> : null}
                </Form.Item>
              </FieldControl>
            </FieldGroup>
          </Col>

          <Col style={{ width: '50%' }}>
            <FieldControl>
              <Form.Item label="State" name="state" rules={[{ required: true }]}>
                <Select
                  disabled={!parsedCountryStates.length}
                  onChange={handleSelectStateChange}
                  size="large"
                  placeholder="State"
                  value={values.state}
                  options={parsedCountryStates}
                />
                {errors.state && dirty ? <ErrorMessage>{errors.state}</ErrorMessage> : null}
              </Form.Item>
            </FieldControl>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col style={{ width: '50%' }}>
            <FieldGroup>
              <FieldControl>
                <Form.Item label="City" name="city" rules={[{ required: true }]}>
                  <Input onChange={handleChange('city')} value={values.city} />
                  {errors.city && dirty ? <ErrorMessage>{errors.city}</ErrorMessage> : null}
                </Form.Item>
              </FieldControl>
            </FieldGroup>
          </Col>

          <Col style={{ width: '50%' }}>
            <FieldGroup>
              <FieldControl>
                <Form.Item label="Postal Code" name="zipCode" rules={[{ required: true }]}>
                  <Input onChange={handleChange('zipCode')} value={values.zipCode} />
                  {errors.zipCode && dirty ? <ErrorMessage>{errors.zipCode}</ErrorMessage> : null}
                </Form.Item>
              </FieldControl>
            </FieldGroup>
          </Col>
        </Row>
      </Form>
    </S.ModalWrapper>
  );
};

export default EditAddress;
