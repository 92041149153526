/* eslint-disable react/jsx-no-target-blank */
import { ReactNode } from 'react';
import { Header, Footer } from 'modules/landingPages/components';

import S from './styles';

interface BasicOfferTemplateProps {
  formComponent?: ReactNode;
  advertisementComponent?: ReactNode;
  bodyContent?: ReactNode;
  children?: ReactNode;
}

const BasicOfferTemplate = ({
  formComponent,
  advertisementComponent,
  children,
}: BasicOfferTemplateProps) => {
  return (
    <S.Layout>
      <Header />

      <S.Wrapper>
        <S.Container>
          <S.FullWrap>
            {children ? <S.BodyContent>{children}</S.BodyContent> : null}
            {formComponent ? <S.FormContainer>{formComponent}</S.FormContainer> : null}
            {advertisementComponent ? (
              <S.AddBlockWrap>{advertisementComponent}</S.AddBlockWrap>
            ) : null}
          </S.FullWrap>
        </S.Container>
      </S.Wrapper>

      <Footer />
    </S.Layout>
  );
};

export default BasicOfferTemplate;
