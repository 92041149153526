import styled from 'styled-components';
import { List as BaseList } from 'antd';

const ArchivedList = styled(BaseList)``;

const SectionItemName = styled.span`
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const WrapperText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

export default {
  ArchivedList,
  SectionItemName,
  WrapperText,
};
