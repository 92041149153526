import { LOCATION_CHANGE } from 'connected-react-router';
import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';
import { notification } from 'modules/common/utils';

import { getError } from 'modules/api';

import { CANCEL_GENERATE_DIGITAL_BOOK, GENERATE_DIGITAL_BOOK } from 'modules/draft/store/constants';
import { clearDigitalBook } from 'modules/draft/store';

import { onGetPreflightCheck } from 'modules/draft/store/sagas/preflight/getPreflightCheck';
import { onGenerateDraftLinks } from 'modules/draft/store/sagas/links/generateDraftLinks';

export function* onGenerateDigitalBook(action) {
  try {
    const preflight = yield call(onGetPreflightCheck, action);
    const checkFinalPass = get(preflight, 'finalCheckPass', false);

    if (checkFinalPass) {
      yield call(onGenerateDraftLinks, action);
    }
  } catch (error) {
    const payload = getError(error);

    notification.error({ description: 'Something went wrong while generating digital book' });

    // eslint-disable-next-line no-console
    console.error(payload);
  }
}

export function* watchCancellation(action) {
  const [cancelled, location] = yield race([
    take(CANCEL_GENERATE_DIGITAL_BOOK),
    take(LOCATION_CHANGE),
    call(onGenerateDigitalBook, action),
  ]);

  if (cancelled || location) {
    yield put(clearDigitalBook());
  }
}

export function* watchGenerateDigitalBook() {
  yield takeEvery(GENERATE_DIGITAL_BOOK, watchCancellation);
}
