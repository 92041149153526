/**
 * Responsive MediaQueries
 */

const size = {
  mobileS: '320px',
  mobileM: '480px',
  mobileL: '640px',
  tabletS: '768px',
  tabletL: '992px',
  laptopS: '1024px',
  laptopL: '1200px',
  desktop: '1325px',
};

export const device = {
  desktopL: `(max-width: ${size.desktop})`,
  desktop: `(max-width: ${size.desktop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  laptopS: `(max-width: ${size.laptopS})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tabletL: `(max-width: ${size.tabletL})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileS: `(max-width: ${size.mobileS})`,
};
