import { Space } from 'antd';
import cardDetails from 'modules/v2/pages/PaymentSteps/payments';
import S from './styles';

const CardItem = ({ creditData, selectedId, isActive }) => {
  const { id, card_type: card, masked_card_number: cardNumber, expired: expiredCard } = creditData;

  const defaultCard = selectedId === id;
  return (
    <S.Container isActive={isActive}>
      <S.Radio value={id} disabled={expiredCard}>
        <S.CreditWrapper direction="horizontal">
          <div style={{ display: 'inline-flex' }}>
            <S.CardImg src={cardDetails[card]} alt={card} />
            <S.CardWrapper>
              <S.CardName>{card}</S.CardName>
              <Space direction="horizontal">
                <S.DotContainer>
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                </S.DotContainer>
                <S.Description>
                  {cardNumber?.substring(cardNumber?.length - 4, cardNumber?.length)}
                </S.Description>
              </Space>
            </S.CardWrapper>
          </div>
          {defaultCard && <S.CardTag>Primary</S.CardTag>}
          {expiredCard && <S.ExpiredTag>Expired</S.ExpiredTag>}
        </S.CreditWrapper>
      </S.Radio>
    </S.Container>
  );
};

export default CardItem;
