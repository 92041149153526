const TrainingIcon = ({ active }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.15234 3.41637C9.33652 2.86121 10.6636 2.86121 11.8477 3.41637L17.4237 6.03053C18.6366 6.59916 18.6366 8.56754 17.4237 9.13617L11.8478 11.7503C10.6636 12.3055 9.33659 12.3055 8.15242 11.7503L2.57641 9.13614C1.36352 8.5675 1.36353 6.59912 2.57642 6.03049L8.15234 3.41637Z"
      fill={active ? '#3C3C3C' : ''}
      stroke={active ? '' : '#757575'}
      strokeWidth={active ? '' : '1.5'}
    />
    <path d="M1.66675 7.58337V12.1667" stroke="#757575" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M15.8334 10.0834V14.3546C15.8334 15.1946 15.4138 15.981 14.679 16.388C13.4553 17.0657 11.4968 18 10.0001 18C8.50341 18 6.54483 17.0657 5.32119 16.388C4.58633 15.981 4.16675 15.1946 4.16675 14.3546V10.0834"
      fill={active ? '#3C3C3C' : ''}
      stroke={active ? '' : '#757575'}
      strokeWidth={active ? '' : '1.5'}
    />
  </svg>
);

export default TrainingIcon;
