type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}

const defaultProps: Props = {
  fill: '#252525',
  width: 20,
  height: 20,
  className: '',
};


const ShieldWarningIcon = ({ fill, height, width, className }: Props) => {
  return <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
      d="M2.5 8.68057C2.5 6.01591 2.5 4.68359 2.8146 4.23536C3.12919 3.78714 4.38194 3.35832 6.88743 2.50068L7.36477 2.33728C8.67082 1.89022 9.32384 1.66669 10 1.66669C10.6762 1.66669 11.3292 1.89022 12.6352 2.33728L13.1126 2.50068C15.6181 3.35832 16.8708 3.78714 17.1854 4.23536C17.5 4.68359 17.5 6.01591 17.5 8.68057V9.99282C17.5 14.6912 13.9675 16.9713 11.7512 17.9394C11.15 18.202 10.8494 18.3334 10 18.3334C9.15062 18.3334 8.85001 18.202 8.2488 17.9394C6.03247 16.9713 2.5 14.6912 2.5 9.99282V8.68057ZM10 6.04169C10.3452 6.04169 10.625 6.32151 10.625 6.66669V10C10.625 10.3452 10.3452 10.625 10 10.625C9.65482 10.625 9.375 10.3452 9.375 10V6.66669C9.375 6.32151 9.65482 6.04169 10 6.04169ZM10 13.3334C10.4602 13.3334 10.8333 12.9603 10.8333 12.5C10.8333 12.0398 10.4602 11.6667 10 11.6667C9.53976 11.6667 9.16667 12.0398 9.16667 12.5C9.16667 12.9603 9.53976 13.3334 10 13.3334Z"
      fill={fill} />
  </svg>;

};

ShieldWarningIcon.defaultProps = defaultProps;

export default ShieldWarningIcon;
