import {
  SAVE_DRAFT,
  SAVE_COVER,
  SAVE_CONTENT,
  SAVE_PREFERENCES,
  SAVE_SECTIONS,
  MARK_COVER,
  UNMARK_COVER,
  MARK_CONTENT,
  UNMARK_CONTENT,
  MARK_PREFERENCES,
  UNMARK_PREFERENCES,
  MARK_SECTIONS,
  UNMARK_SECTIONS,
} from 'modules/v2/editor/store/constants';

export const saveDraft = () => ({
  type: SAVE_DRAFT,
});

export const saveCover = () => ({
  type: SAVE_COVER,
});

export const saveContent = (sectionId) => ({
  type: SAVE_CONTENT,
  payload: {
    sectionId,
  },
});

export const savePreferences = () => ({
  type: SAVE_PREFERENCES,
});

export const saveSections = () => ({
  type: SAVE_SECTIONS,
});

export const markPreferences = () => ({
  type: MARK_PREFERENCES,
  payload: {
    timestamp: Date.now(),
  },
});

export const unmarkPreferences = (timestamp) => ({
  type: UNMARK_PREFERENCES,
  payload: {
    timestamp,
  },
});

export const markSections = () => ({
  type: MARK_SECTIONS,
  payload: {
    timestamp: Date.now(),
  },
});

export const unmarkSections = (timestamp) => ({
  type: UNMARK_SECTIONS,
  payload: {
    timestamp,
  },
});

export const markCover = () => ({
  type: MARK_COVER,
  payload: {
    timestamp: Date.now(),
  },
});

export const unmarkCover = (timestamp) => ({
  type: UNMARK_COVER,
  payload: {
    timestamp,
  },
});

export const markContent = (sectionId) => ({
  type: MARK_CONTENT,
  payload: {
    sectionId,
    timestamp: Date.now(),
  },
});

export const unmarkContent = (sectionId, timestamp) => ({
  type: UNMARK_CONTENT,
  payload: {
    sectionId,
    timestamp,
  },
});
