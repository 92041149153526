import { get } from 'lodash-es';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'store/status';
import { SUBMIT_ORDER, SUBMIT_REORDER } from 'modules/v2/publish/store/constants';

import { order as initialState } from './initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SUBMIT_REORDER}_REQUESTED`:
    case `${SUBMIT_ORDER}_REQUESTED`: {
      return {
        ...state,
        data: null,
        status: STATUS_LOADING,
      };
    }

    case `${SUBMIT_REORDER}_FAILED`:
    case `${SUBMIT_ORDER}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${SUBMIT_REORDER}_SUCCEEDED`:
    case `${SUBMIT_ORDER}_SUCCEEDED`: {
      const data = get(action, ['payload'], null);

      return {
        ...state,
        data,
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
};

export default reducer;
