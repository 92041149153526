import styled from 'styled-components';
import { Card as BaseCard, Layout as BaseLayout, Typography } from 'antd';
import { CheckOutlined as BaseCheck } from '@ant-design/icons';

const { Title: BaseTitle } = Typography;

const Card = styled(BaseCard)`
  .ant-card-body {
    padding: 0 !important;
  }
`;

const Layout2 = styled(BaseLayout)`
  padding: 30px;
  width: 100%;
`;

const Layout1 = styled.div`
  padding: 100px 30px 30px 30px;
  width: 100%;

  @media (max-width: 576px) {
    padding: 40px 0 30px 0;
  }
`;

const CenterHorizontalVerticalDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const CenterSection = styled.div`
  text-align: center;
  text-wrap: unset;
`;

const LeftSection = styled.div`
  text-align: left;
  padding-left: 60px;

  @media (max-width: 576px) {
    padding-left: 0;
  }
`;

const CheckOutlined = styled(BaseCheck)`
  font-size: 24px;
  color: green;
  font-weight: bolder;
`;

const Title = styled(BaseTitle)`
  width: 100%;
`;

export default {
  Card,
  Layout2,
  Layout1,
  CenterSection,
  CenterHorizontalVerticalDiv,
  CheckOutlined,
  LeftSection,
  Title,
};
