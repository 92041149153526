import styled from 'styled-components';
import { DatePicker as DatePickerBase, Input as InputBase, Spin } from 'antd';
import { LineOutlined } from '@ant-design/icons';
import * as color from 'modules/common/theme/color';

const { Search } = InputBase;

const { RangePicker } = DatePickerBase;

const CoantainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 22px;

  span {
    margin: 0;
  }
`;

const ButtonsHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 1px;
`;

const Description = styled.div`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 20px;

  b {
    margin: 0 4px;
  }
`;

const DatePicker = styled(RangePicker)`
  margin: 10px 0 20px 0;

  .ant-calendar-picker-input.ant-input {
    width: 316px;
    height: 40px;
  }
`;

const CardsReport = styled.div`
  width: 100%;
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;

  width: 100%;
  margin-bottom: 20px;

  .selected {
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: all 0.3s ease;
  }

  .ant-card {
    width: calc((100% - 30px) / 3);
    cursor: pointer;
  }

  .ant-card-head {
    background: ${color.TableHeader};
  }

  .ant-card-head-title {
    font-weight: bold;
    padding: 10px 0 !important;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 145px;
  }
`;

const FocusedCard = styled.p`
  color: ${color.Black};
  font-size: 32px;
  font-weight: bold;
`;

const DescriptionCard = styled.p`
  color: ${color.Black};
  font-size: 16px;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const EmptyTableAtribute = styled(LineOutlined)`
  font-size: 20px;
  color: #a6a6a6;
  margin-left: 20%;
`;

const PreviewLink = styled.span`
  cursor: pointer;
  color: ${color.PrimaryColor};

  :hover {
    color: ${color.SecondaryColor};
  }
`;

const PreviewOff = styled.span`
  color: ${color.EmptyAtribute};
  cursor: not-allowed;
`;

const ExportContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const ActionsButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-left: 8px;

  :hover {
    color: ${color.BlueButton};
  }
`;

const GenerateButton = styled.div`
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 4px;
  border-style: solid;
  border-color: ${color.SkyBlue};
  border-radius: 4px !important;
  width: 90px !important;
  opacity: 1;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer !important;
  white-space: nowrap;
  letter-spacing: 0.6px;
  color: ${color.SkyBlue};
  &:hover {
    color: ${color.SkyBlue};
    background-color: ${color.White};
    border-color: ${color.SkyBlue};
  }

  .anticon svg {
    font-size: 23px;
    margin: 5px;
  }
`;

const ImportBtn = styled.button`
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 4px;
  border-style: solid;
  background-color: ${color.SkyBlue};
  border-color: ${color.SkyBlue};
  border-radius: 4px !important;
  width: 90px !important;
  opacity: 1;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer !important;
  white-space: nowrap;
  letter-spacing: 0.6px;
  color: ${color.White};
  &:hover {
    color: ${color.SkyBlue};
    background-color: ${color.White};
    border-color: ${color.SkyBlue};
  }

  .anticon svg {
    font-size: 23px;
    margin: 5px;
  }
`;

const ImportBtnDisabled = styled.button`
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 4px;
  border-style: solid;
  background-color: ${color.Grey3};
  border-color: ${color.Grey3};
  border-radius: 4px !important;
  width: 90px !important;
  opacity: 1;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: no-drop !important;
  white-space: nowrap;
  letter-spacing: 0.6px;
  color: ${color.White};
  &:hover {
    color: ${color.Grey3};
    background-color: ${color.White};
    border-color: ${color.Grey3};
  }

  .anticon svg {
    font-size: 23px;
    margin: 5px;
  }
`;

const Spinner = styled(Spin)`
  min-width: 90px;
  text-align: center;
  margin-top: 6px;
`;

const ButtonText = styled.span`
  margin-right: 14px;
`;

export default {
  CoantainerWrapper,
  HeaderContainer,
  Title,
  Description,
  DatePicker,
  CardsReport,
  CardWrapper,
  FocusedCard,
  DescriptionCard,
  TableWrapper,
  EmptyTableAtribute,
  PreviewLink,
  PreviewOff,
  ExportContainer,
  ButtonsHeaderContainer,
  ActionsWrapper,
  ActionsButton,
  ImportBtn,
  ImportBtnDisabled,
  ButtonText,
  GenerateButton,
  Spinner,
};
