import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.GreyBoxBackgorund};
  border-bottom-width: 8px;
  border-bottom: 1px solid ${color.GreyLightText};
`;

const Header = styled.header`
  background-color: ${color.lightBackground};
  font-weight: 800;
  padding: 6px 8px;
`;

const Section = styled.section`
  display: flex;
  padding: 18px 24px;
  gap: 16px;
  background-color: ${color.lightBackground};
`;

const Image = styled.div`
  border-radius: 8px;
  overflow: hidden;
  img {
    width: 164px;
    height: 80px;
  }
`;

const Column = styled.div`
  flex: 2;
  display; flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  header {
    white-space: nowrap;
    font-size: 14px;
    margin-bottom: 16px;
  }
  main {
    background-color: ${color.GreyBoxBackgorund};
    padding: 6px 4px;
  }
`;

export default {
  Main,
  Header,
  Section,
  Image,
  Column,
};
