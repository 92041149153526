import jwtDecode from 'jwt-decode/lib';
import { getItem } from './legacy';
import environments, { boolean } from '../../../environments';

export function isAdminCustomer() {
  const token = getItem('token');
  const tokenDecoded = token ? jwtDecode(token) : null;
  return tokenDecoded?.data?.isAdmin === true;
}

export const IsFeatureVisible = (featureFlag: string): boolean => {
  if (isAdminCustomer()) {
    return true;
  }
  return boolean(environments[featureFlag]);
};
