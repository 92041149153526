import gql from 'graphql-tag';

export default gql(`
    query {
        sections: sections(limit:-1){
            _id
            name
        }
    }
`);
