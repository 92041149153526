import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 8px;
`;

const ModalWrapper = styled.div`
  padding: 2rem;
  background: ${color.White};
`;

const ModalTitle = styled.h3`
  margin: 20px 0 10px 0 !important;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;

const ModalText = styled.h3`
  margin: 20px 0 10px 0 !important;
  color: ${color.DeleteLeadModalText};
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default {
  MainWrapper,
  ActionsWrapper,
  ModalWrapper,
  ModalTitle,
  ModalText,
  ButtonText,
};
