import * as color from 'modules/v2/common/theme/color';
import styled from 'styled-components';

const ViewWrapper = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
`;

const ViewContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
  position: relative;
`;

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: ${color.White};
  border-top: 1px solid ${color.neutral200};
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  .ant-btn {
    width: 30%;
  }
`;

export default {
  ViewWrapper,
  ViewContent,
  FooterContainer,
};
