import { formatCurrency } from 'modules/v2/utils';

import S from './styles';

const FeaturesList = ({ feature, interval, printedBooks, licensedBooks, saveAmount }) => {
  const renderPoint = (point) =>
    point
      .replace('{saveAmount}', formatCurrency(saveAmount))
      .replace('{licensedBooks}', licensedBooks)
      .replace('{printedBooks}', printedBooks)
      .replace('{interval}', interval);

  return (
    <S.List>
      <S.AvailableAnymore>{feature?.heading}</S.AvailableAnymore>
      {feature?.points.map((point) => (
        <S.ListItem key={point}>
          <S.TickedIcon type={feature?.icon} colorCode={feature?.color} />
          <S.ListText>{renderPoint(point)}</S.ListText>
        </S.ListItem>
      ))}
    </S.List>
  );
};

export default FeaturesList;
