import { useEffect } from 'react';
import { useParams } from 'react-router';

import { Loader } from 'modules/common/components';
import { withDraft } from 'modules/draft/containers';

import { isPending } from 'store/status';

const Draft = ({ draft, getDraftById, children }) => {
  const { draftId } = useParams();

  useEffect(() => {
    getDraftById(draftId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId]);

  if (isPending(draft.status)) {
    return <Loader title="Loading Book" />;
  }

  return children;
};

export default withDraft(Draft);
