import { get } from 'lodash-es';

import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';
import { GET_CUSTOMER_PORTAL_LINK } from 'modules/v2/store/constants';
import { customerPortalLink as initialState } from 'modules/v2/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_CUSTOMER_PORTAL_LINK}_REQUESTED`: {
      return {
        ...state,
        data: null,
        status: STATUS_LOADING,
      };
    }

    case `${GET_CUSTOMER_PORTAL_LINK}_SUCCEEDED`: {
      const data = get(action, ['payload', 'url'], null);

      return {
        ...state,
        status: STATUS_SUCCESS,
        data: data ? `${data}/payment-methods` : null,
      };
    }

    case `${GET_CUSTOMER_PORTAL_LINK}_FAILED`: {
      return { ...state, status: STATUS_ERROR };
    }

    default:
      return state;
  }
};

export default reducer;
