import styled from 'styled-components/macro';
import { Button, Pagination as PaginationBase } from 'antd';

import * as color from 'modules/v2/common/theme/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  height: 100%;
  min-height: calc(100vh - 64px);

  padding-top: 30px;

  margin-bottom: 60px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  padding: 30px 0;
  text-align: center;
  border-bottom: 1px solid ${color.GreyBookBorder};
`;

const SideContent = styled.div`
  width: 212px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  text-align: left;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: auto;
  display: block;
  width: 212px;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  display: inline-block;
`;

const LinksList = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  .ant-btn {
    width: 147px;
  }
`;

const PrimaryButton = styled(Button)`
  border-radius: 3px;
  display: inline-block;
  font-size: 16px;
  line-height: 32px;
  margin-right: 20px;
  padding: 0 20px;
  transition: all 0.2s;
  font-weight: normal !important;
  border: none;

  &:hover {
    opacity: 0.9;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    color: ${color.White};
    background: ${color.PrimaryColor};
  }
`;

const ButtonLink = styled(Button)`
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;

  &,
  &:hover,
  &:focus,
  &:active {
    color: ${color.PrimaryColor};
    border-color: ${color.PrimaryColor};
  }
`;

const BookName = styled.span`
  font-weight: 700;
  color: ${color.GreySelectDd};
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 15px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  div {
    display: flex;
    align-items: center;
  }
`;

const DetailsTitle = styled.div`
  color: ${color.Body};
  font-weight: 600;
  font-size: 16px;
  line-height: 23.57px;
  width: 160px;
`;

const DetailsInformation = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 25.14px;
`;

const ConfirmTitle = styled.p`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: ${color.Black};
`;

const ConfirmText = styled.p`
  margin-top: 20px;
  margin-bottom: 0;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 640px;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContentTitle = styled.p`
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-right: 2px;

  span {
    color: ${color.DangerRed};
  }
`;

const ContentDescription = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

const PaginationContent = styled.div`
  margin: 20px;
  .ant-pagination {
    display: flex;
  }
`;

const Pagination = styled(PaginationBase)`
  .ant-pagination {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    height: 42px;
    border: 1px solid ${color.GreyBorder} !important;
    border-radius: 4px;
  }

  .ant-pagination-item-active {
    background-color: ${color.ActivePage} !important;
    border-color: ${color.ActivePage} !important;

    a {
      color: ${color.White} !important;
    }
  }

  .ant-pagination-next {
    margin-left: auto !important;
  }

  .ant-pagination-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    margin-right: auto;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    margin-left: auto;
    padding: 10px 14px;
  }

  .ant-pagination-disabled {
    opacity: 0.5;
  }

  .ant-pagination-item {
    border: none;
  }

  .ant-pagination-item .ant-pagination-item-active {
    a {
    }
    background-color: ${color.ActivePage} !important;
    border-color: ${color.ActivePage} !important;
    color: ${color.White} !important;
  }

  .ant-pagination-next,
  .ant-pagination-jump-next {
    .ant-btn {
      :hover {
        border: none;
        border-radius: 4px;
        color: ${color.White} !important;
        background-color: ${color.ActivePageHover} !important;
      }
    }
  }
`;

export default {
  Container,
  BookName,
  ButtonLink,
  ConfirmText,
  ConfirmTitle,
  Details,
  DetailsTitle,
  DetailsInformation,
  ImageWrapper,
  LinksList,
  MainContent,
  PrimaryButton,
  SideContent,
  Title,
  Wrapper,
  ModalFooterContainer,
  ContentContainer,
  ContentTitle,
  ContentDescription,
  PaginationContent,
  Pagination,
};
