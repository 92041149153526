import styled from 'styled-components';

const NoticeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;

  span {
    max-width: 215px;
    text-align: center;
    font-size: 16px;
  }
`;

export default { NoticeContainer };
