import styled from 'styled-components';

import * as color from 'modules/v2/common/theme/color';

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
`;

const Title = styled.h5`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 0px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.Black};
`;

const TopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 0px 0px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${color.LightGray};
  margin: 20px 0px;
`;

const BottomDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.Black};
  margin-top: 20px !important;
`;

const EmailLink = styled.a`
  text-decoration: underline !important;
`;

const ButtonWrap = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
`;

const PlanName = styled(Description)`
  text-transform: capitalize;
`;

export default {
  MainContain,
  Title,
  Description,
  TopWrap,
  Divider,
  BottomDescription,
  ButtonWrap,
  EmailLink,
  PlanName,
};
