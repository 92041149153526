import withStore from 'store/StoreContainer';

import { getAutoSignin, authSignoutRequest, autoSigninSelector } from 'modules/auth/store';

const mapStateToProps = (state) => ({
  autoSignin: autoSigninSelector(state),
});

const mapDispatchToProps = {
  getAutoSignin,
  signOut: authSignoutRequest,
};

export default withStore(mapStateToProps, mapDispatchToProps);
