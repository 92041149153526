import { createContext, useMemo, useState } from 'react';
import { getItem } from 'modules/v2/utils/legacy';

export const BuyCreditsContext = createContext(null);

export function BuyCreditsProvider({ children }) {
  const userDataString = getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const [userCredits, setUserCredits] = useState(0);
  const [bookCreditsList, setBookCreditsList] = useState([]);
  const [selectedCreditsOffer, setSelectedCreditsOffer] = useState(null);

  const clearContext = () => {
    setUserCredits(0);
    setBookCreditsList([]);
    setSelectedCreditsOffer(null);
  };

  let contextData = {
    clearContext,
    userData,
    userCredits,
    setUserCredits,
    bookCreditsList,
    setBookCreditsList,
    selectedCreditsOffer,
    setSelectedCreditsOffer,
  };

  const memoizedContextData = useMemo(() => (contextData), [
    userData,
    userCredits,
    bookCreditsList,
    selectedCreditsOffer,
  ]);

  return (
    <BuyCreditsContext.Provider value={memoizedContextData}>
      {children}
    </BuyCreditsContext.Provider>
  );
}
