import styled from 'styled-components';

import { device } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';

const FormTitle = styled.h4`
  padding: 8px 15px;
  background: ${color.FormTitleBg};
  border: 1px solid #ececec;
  margin-bottom: 20px !important;

  @media ${device.tabletS} {
    text-align: center;
    font-size: 14px !important;
  }
`;

const BlockForm = styled.div`
  padding: 5px 15px;
`;

export default {
  FormTitle,
  BlockForm,
};
