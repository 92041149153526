export const QuestionCircleOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8.00016" cy="7.99967" r="6.66667" stroke="#252525" strokeWidth="1.5" />
      <path
        d="M6.75 5.91699C6.75 5.22664 7.30964 4.66699 8 4.66699C8.69036 4.66699 9.25 5.22664 9.25 5.91699C9.25 6.37529 9.00336 6.77598 8.63558 6.99358C8.31869 7.18106 8 7.46547 8 7.83366V8.66699"
        stroke="#252525"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <ellipse cx="8.00016" cy="10.6667" rx="0.666667" ry="0.666667" fill="#252525" />
    </svg>
  );
};
