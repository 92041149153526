function getDefaultProps() {
  return {
    width: '100',
    height: '100',
    fill: '#FFF',
  };
}

function DeleteIcon() {
  return (
    // <svg
    //   id="delete"
    //   width={getDefaultProps().width}
    //   height={getDefaultProps().height}
    //   fill={getDefaultProps().fill}
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 100 100"
    // >
    //   <path
    //     d="M4.1666667 25l12.5 75h50l12.5-75h-75zM83.3333333 8.3333333v8.3333334H0V8.3333333h23.7958333c3.75 0 6.7958334-4.5791666 6.7958334-8.3333333h22.15c0 3.7541667 3.0416666 8.3333333 6.7958333 8.3333333h23.7958333z"
    //     fillRule="nonzero"
    //     stroke="none"
    //     strokeWidth="1"
    //   />
    // </svg>
    <svg
      id="delete"
      width={getDefaultProps().width}
      height={getDefaultProps().height}
      fill={getDefaultProps().fill}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="trash"
      className="svg-inline--fa fa-trash fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />
    </svg>
  );
}

export default DeleteIcon;
