import { useFormikContext } from 'formik';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { PaperPlaneIcon } from 'modules/common/components/SvgIcons';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getRouteLaunchCampaign } from 'modules/dashboard/routes/navigation';

const Header = () => {
  const { submitForm, setFieldValue, validateForm, isValid } = useFormikContext();
  const history = useHistory();

  useEffect(() => {
    validateForm();
  }, [isValid]);

  const handleDraftSubmit = () => {
    setFieldValue('status', 'draft');
    submitForm();
  };

  const handleFormSubmit = () => {
    setFieldValue('status', 'active');
    submitForm();
  };

  const handleCancel = () => {
    history.push(getRouteLaunchCampaign());
  };

  return (
    <div
      id="header"
      className="flex p-6 justify-between items-center w-full border-b-[1px] border-neutral-200 max-[630px]:flex-col"
    >
      <span className="text-lg font-semibold">Campaign details</span>
      <span className="flex flex-row gap-x-2">
        <Button buttonType="submit" type="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button buttonType="submit" disabled={!isValid} onClick={handleDraftSubmit}>
          Save as draft
        </Button>
        <Button buttonType="submit" disabled={!isValid} onClick={handleFormSubmit}>
          <PaperPlaneIcon className={`mr-2 ${false ? 'opacity-20' : ''}`} />
          Send
        </Button>
      </span>
    </div>
  );
};

export default Header;
