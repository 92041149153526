import { get } from 'lodash-es';

import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';

import { UPLOAD_INTAKE_FORM_HEADSHOT } from 'modules/dashboard/store/constants';
import { intakeForm as initialState } from '../initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPLOAD_INTAKE_FORM_HEADSHOT}_REQUESTED`: {
      return {
        ...state,
        upload: {
          data: null,
          status: STATUS_LOADING,
        },
      };
    }

    case `${UPLOAD_INTAKE_FORM_HEADSHOT}_SUCCEEDED`: {
      const data = get(action, ['payload'], null);

      return {
        ...state,
        upload: {
          ...state.upload,
          data,
          status: STATUS_SUCCESS,
        },
      };
    }

    case `${UPLOAD_INTAKE_FORM_HEADSHOT}_FAILED`: {
      const error = get(action, ['payload'], null);

      return {
        ...state,
        upload: {
          ...state.upload,
          error,
          status: STATUS_ERROR,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
