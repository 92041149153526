import { call, put, takeLatest } from 'redux-saga/effects';

import { notification } from 'modules/common/utils';
import { updateProfile, resource, getError } from 'modules/api';
import { getProfile } from 'modules/dashboard/store';
import { UPDATE_PROFILE } from 'modules/dashboard/store/constants';

export function* onUpdateProfile({ payload: meta }) {
  try {
    const request = resource(UPDATE_PROFILE, updateProfile);
    const payload = yield call(request, meta);

    yield put(getProfile());

    notification.success({
      title: 'Changes saved',
      description: 'Your profile data has been updated successfully',
    });

    return payload;
  } catch (error) {
    const payload = getError(error);

    notification.error({
      title: 'Saving failed',
      description: 'Your profile data could not be saved',
    });

    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE, onUpdateProfile);
}
