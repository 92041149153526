import { compose } from 'redux';

import { Loader, OverlayLoader, NavigationPrompt } from 'modules/common/components';
import { withBook, withDraft, withDraftRoute } from 'modules/v2/editor/containers';
import { ROUTE_EDIT_DRAFT } from 'modules/v2/editor/routes/constants';
import { JourneyContextProvider } from 'modules/v2/context/JourneyContextProvider';

import { isPending, isLoading } from 'store/status';

const Draft = ({ book, children, draft, reset, resetSection, isSaving, isPendingSave }) => {
  if (isPending(book.status) || isPending(draft.status)) {
    return <Loader title="Loading Book Editor" />;
  }

  return (
    <>
      <JourneyContextProvider>
        <NavigationPrompt
          path={ROUTE_EDIT_DRAFT}
          block={isSaving || isPendingSave}
          title="You have unsaved changes"
          text="If you leave now your changes will be permanently lost. Would you still like to leave?"
        />
        {children}
        <OverlayLoader title="Resetting Your Book" isOpen={isLoading(reset.status)} />
        <OverlayLoader
          title="Resetting the current section"
          isOpen={isLoading(resetSection.status)}
        />
      </JourneyContextProvider>
    </>
  );
};

export default compose(withDraftRoute, withBook, withDraft)(Draft);
