import { useFormikContext } from 'formik';

import Modal from 'modules/common/components/Modal';

import { ContactDetailsForm } from './components';
import S from './styles';

const ContactDetailsModal = ({ isOpen, title, onCancel }) => {
  const { submitForm } = useFormikContext();

  return (
    <Modal
      width="400px"
      title={title}
      isOpen={isOpen}
      onConfirm={submitForm}
      onCancel={onCancel}
      maskClosable={false}
    >
      <S.Wrapper>
        <ContactDetailsForm />
      </S.Wrapper>
    </Modal>
  );
};

export default ContactDetailsModal;
