//

import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from 'modules/v2/common/components/SvgIcon/CopyIcon';
import { Tooltip } from 'flowbite-react';

import { notification } from 'v2/common/utils';
import S from './styles';

const Link = ({ id, title, href, showPreview = true }) => {
  const handleCopy = () => {
    if (!href) return false;
    notification.success({
      description: 'Copied to clipboard!',
    });
    return true;
  };

  return (
    <S.InputWithCopy>
      <S.InputWithCopy.Label htmlFor={id}>{title}</S.InputWithCopy.Label>

      <S.InputWithCopy.InputWrapper>
        <S.InputWithCopy.Input id={id} type="text" value={href} readOnly />

        <CopyToClipboard text={href} onCopy={handleCopy}>
          <S.InputWithCopy.Button>
            <Tooltip content="Copy Link" Style="dark" className="w-[90px] text-center">
              <CopyIcon />
            </Tooltip>
          </S.InputWithCopy.Button>
        </CopyToClipboard>
      </S.InputWithCopy.InputWrapper>

      {href && showPreview && (
        <S.InputWithCopy.Link className="mt-1  hover:text-primary-600" href={href} target="_blank">
          <span className="border-neutral-300 border-b border-solid">Preview</span>
        </S.InputWithCopy.Link>
      )}
    </S.InputWithCopy>
  );
};
export default Link;
