import { get } from 'lodash-es';
import { STATUS_SUCCESS, STATUS_ERROR, STATUS_LOADING } from 'store/status';

import { CLEAR_PREFLIGHT_CHECK, GET_PREFLIGHT_CHECK } from 'modules/draft/store/constants';

import { preflight as initialState } from '../initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PREFLIGHT_CHECK}_REQUESTED`: {
      return {
        ...state,
        data: null,
        status: STATUS_LOADING,
      };
    }

    case `${GET_PREFLIGHT_CHECK}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${GET_PREFLIGHT_CHECK}_SUCCEEDED`: {
      const data = get(action, ['payload'], null);

      return {
        ...state,
        data,
        status: STATUS_SUCCESS,
      };
    }

    case `${CLEAR_PREFLIGHT_CHECK}`: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default reducer;
