export const CalendarIcon = ({ width = 20, height = 20, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.66602 10.0002C1.66602 6.85747 1.66602 5.28612 2.64233 4.30981C3.61864 3.3335 5.18999 3.3335 8.33268 3.3335H11.666C14.8087 3.3335 16.3801 3.3335 17.3564 4.30981C18.3327 5.28612 18.3327 6.85747 18.3327 10.0002V11.6668C18.3327 14.8095 18.3327 16.3809 17.3564 17.3572C16.3801 18.3335 14.8087 18.3335 11.666 18.3335H8.33268C5.18999 18.3335 3.61864 18.3335 2.64233 17.3572C1.66602 16.3809 1.66602 14.8095 1.66602 11.6668V10.0002Z" stroke={fill} strokeWidth="1.5"/>
    <path d="M5.83398 3.3335V2.0835" stroke={fill} strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M14.166 3.3335V2.0835" stroke={fill} strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M2.08398 7.5H17.9173" stroke={fill} strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);
