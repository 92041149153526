import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { get } from 'lodash-es';

import { isSucceeded } from 'store/status';
import { Provider } from 'modules/common/components/DraftContext';
import Scrollbars from 'modules/common/components/Scrollbars';
import { SettingsIcon } from 'modules/common/components/SvgIcon';

import {
  ContentEditor,
  EditorFooter,
  EditorNavbar,
  EditorToolbar,
  Sidebar,
  TemplateList,
} from 'modules/editor/components';
import { EditorTemplate } from 'modules/editor/templates';
import {
  withContent,
  withContentRoute,
  withDraft,
  withBook,
  withImageLibrary,
  withPreferences,
} from 'modules/editor/containers';
import { Toolbar } from 'modules/nav/components';
import { getElementsBySectionId, getContentNavigationLinks } from 'modules/editor/utils';

const Content = ({
  book,
  images,
  layout,
  sections,
  dropdown,
  draftId,
  sectionId,
  displayName,
  preferences,
  sectionType,
  templateList,
  toolbar,
  reset,
  resetSection,
  resetCurrentSection,
  isSaving,
  isPendingSave,
  deleteUserImage,
  goToDraftView,
  getUserImages,
  resetDraft,
  saveDraft,
  toggleShowGuides,
  updateContentElement,
  updateContentActiveTemplate,
  updatePreferenceByKey,
  uploadContentImage,
}) => {
  // TODO: Move link, templateId, template, elements to selectors
  const links = getContentNavigationLinks(sections, draftId, sectionId);
  const { templateId, template, elements } = getElementsBySectionId(sections, sectionId);
  const { showGuides } = toolbar;
  const wasReset = isSucceeded(reset.status) || isSucceeded(resetSection.status);

  const { startChaptersInRecto } = preferences;

  const bookTitle = get(book, ['data', 'name']);
  const dropdownTitle = sectionType === 'Content' ? displayName : 'Manuscript';

  const handleResetSection = () => resetCurrentSection(sectionId);

  const handleShowGuides = (e) => {
    const { checked } = e.target;

    toggleShowGuides(checked);
  };

  const handleContentChange = ({ _id: elementId, rawValue, defaultValue }) => {
    updateContentElement(sectionId, templateId, elementId, rawValue, defaultValue);
  };

  const handleSelectTemplate = (selectedTemplateId) => {
    updateContentActiveTemplate(sectionId, selectedTemplateId);
  };

  const updatePreference = (key) => (e) => {
    const { checked } = e.target;

    updatePreferenceByKey(key, checked);
  };

  const renderNavbar = (
    <EditorNavbar
      bookTitle={bookTitle}
      dropdownTitle={dropdownTitle}
      dropDownItems={dropdown}
      isSaving={isSaving}
      isPendingSave={isPendingSave}
      onSave={saveDraft}
      onExit={() => goToDraftView(draftId)}
    />
  );

  const renderSidebar = (
    <Sidebar title="Available Templates">
      <TemplateList
        templates={templateList}
        selectedId={templateId}
        onSelect={handleSelectTemplate}
      />
    </Sidebar>
  );

  const toolbarSettings = (
    <Toolbar.Options title="Preferences" icon={<SettingsIcon />}>
      <Toolbar.Options.Item
        onChange={updatePreference('startChaptersInRecto')}
        checked={startChaptersInRecto}
        text="Always start chapters on the right-hand page"
      />
    </Toolbar.Options>
  );

  const handleResetDraft = () => resetDraft(draftId);
  const renderToolbar = (
    <EditorFooter
      bookTitle={bookTitle}
      onReset={handleResetDraft}
      onResetSection={handleResetSection}
    >
      <Toolbar.Link to={links.previous.link} disabled={links.previous.disabled}>
        Back
      </Toolbar.Link>
      <Toolbar.Controls onChange={handleShowGuides} checked={showGuides} text="Show Guides" />
      {toolbarSettings}
      <Toolbar.Link to={links.next.link} disabled={links.next.disabled}>
        Next
      </Toolbar.Link>
    </EditorFooter>
  );

  const handleFilePickerConfirm = (value) => {
    uploadContentImage(value);
  };

  const imageHandlerProps = {
    images,
    onConfirmFilePickerModal: handleFilePickerConfirm,
    onDelete: (imageId) => deleteUserImage({ imageId, imageType: 'content' }),
    onGalleryTabOpen: () => getUserImages('content'),
  };

  return (
    <EditorTemplate navbar={renderNavbar} sidebar={renderSidebar} toolbar={renderToolbar}>
      <Provider
        wasReset={wasReset}
        sectionId={sectionId}
        templateId={template._id}
        elements={elements}
        onChange={handleContentChange}
      >
        <EditorToolbar imageHandlerProps={imageHandlerProps} />
        <Scrollbars style={{ height: 'calc(100vh - 180px)' }}>
          <ContentEditor layout={layout} elements={elements} showGuides={showGuides} />
        </Scrollbars>
      </Provider>
    </EditorTemplate>
  );
};

const MemoContent = React.memo(Content);
const Composed = compose(
  withRouter,
  withContentRoute,
  withBook,
  withDraft,
  withContent,
  withPreferences,
  withImageLibrary,
)(MemoContent);

export default Composed;
