import styled from 'styled-components';
import { List as BaseList } from 'antd';
import * as color from 'modules/common/theme/color';

const ArchivedList = styled(BaseList)`
  border: 1px solid ${color.TitleBorder} !important;

  .ant-list-empty-text {
    background: ${color.White};
  }

  .ant-list-item {
    padding: 0;
    border-bottom: 1px solid ${color.TitleBorder};

    .ant-list-item-content {
      position: relative;
      padding: 5px 16px !important;
      display: flex !important;
      align-items: center !important;
      background: ${color.White};
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: ${color.GreySelectDd};
      padding: 5px 17px;
      line-height: 34px;
      border-radius: 3px;
      display: block;
      width: 100%;
    }

    &:last-child {
      border-bottom: 0;
    }

    .dotsDDWrap {
      position: absolute;
      right: 10px;
      padding: 2px 4px;
      border: 0;
      border-radius: 50%;
      height: 28px;
      display: none;
      background: ${color.MenuDotsColorBg};
      cursor: pointer;

      .tooltipWrap {
        background: none;
        padding: 0;
        border: 0;
        width: auto;
        height: auto;

        svg {
          fill: ${color.MenuDotsColor};
          width: 15px;
          height: auto;
          position: relative;
          top: -4px;
          #edit {
            height: 10px;
            width: 10px;
          }
        }
      }
    }

    &:hover,
    &.selected {
      .ant-list-item-content {
        background: ${color.MenuListBg};
        .dotsDDWrap {
          display: block;
        }
      }
    }
  }
`;


const SectionItemName = styled.span`
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const WrapperText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

const ViewLink = styled.span`
  cursor: pointer;
  border: 0;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

export default {
  ArchivedList,
  SectionItemName,
  ViewLink,
  WrapperText,
};
