import { number, object, string } from 'yup';

const validationSchema = object().shape({
  socialMediaValue: object({
    id: number(),
    name: string(),
  }).required(),
  pageAddress: string().required(),
  securityKey: string().required(),
  secretKey: string().required(),
});

export default validationSchema;
