export const ArrowLeft = ({ width = 46, height = 47 }) => (
  <svg width={width} height={height} viewBox="0 0 46 47" fill="none">
    <path
      d="M29 23.5H17M17 23.5L22 18.5M17 23.5L22 28.5"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
