import { Select } from 'antd';
import { map } from 'lodash-es';

const SelectField = ({ options = [], field, form, placeholder = '', onChange, ...props }) => {
  const getOnChangeHandle = (option) => {
    if (onChange) {
      onChange(option);
    }

    if (form && field && field.name) {
      form.setFieldValue(field.name, option);
    }
  };

  return (
    <Select {...props} placeholder={placeholder} onChange={getOnChangeHandle}>
      {map(options, (option) => (
        <Select.Option id={option.value} key={option.value} value={option.value}>
          {option.title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectField;
