/* eslint-disable */
import React from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { withAuth } from 'modules/v2/containers';
import { AuthenticationTemplate, SetPassword, ExpiredLink } from 'modules/v2/components';
import { setItem, removeItem } from 'modules/v2/utils/legacy';
import env from 'environments';
import { ROUTE_RESET_PASSWORD } from 'modules/v2/routes/constants';

/**
 * Container for Reset Password from user facing app
 * This container will be loaded from the link which is sent to user in email
 */
class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      message: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.destroySession();
  }

  componentDidMount() {
    const params = this.getQueryParams();
    // Verify the link
    this.props
      .checkExpiredLink(params)
      .then(() => {})
      .catch(() => {
        toast.dismiss();
        toast.error('Oops Something went Wrong!!!', {
          className: 'Errortoaster',
          position: toast.POSITION.TOP_RIGHT,
        });
      }); 
  }

  componentDidUpdate() {
    const { auth, history } = this.props;
    const isNewLogin = env.AFY_NEW_LOGIN;

    if (auth.expiredLink === 1 && isNewLogin) {
      history.push(ROUTE_RESET_PASSWORD);
    }
  }

  /**
   * Extracts the users username and passcode from the link
   */
  getQueryParams = () => {
    const queryString = new URLSearchParams(this.props.location.search);
    const username = queryString.get('username');
    const passcode = queryString.get('passcode');
    return { username, passcode };
  };

  /**
   * Link expires in 30 days
   * Handles the request for resending the link again
   */

  handleResend = () => {
    this.props
      .forgetPassword(this.props.auth.data.username, 'template_activation_link')
      .then(() => {})
      .catch(() => {
        toast.dismiss();
        toast.error('Oops Something went Wrong!!!', {
          className: 'Errortoaster',
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  /**
   * Log's in user after successfull change of password
   */
  setSession = () => {
    const { auth, history } = this.props;
    if (auth.login.data.token) {
      setItem('token', auth.login.data.token);
      setItem('refresh_token', auth.login.data.refresh_token);
      setItem('userData', auth.login.data.properties);
      this.setState({
        message: [],
        newPassword: '',
        confirmPassword: '',
      });
      history.push('/');
      this.setState({
        message: [],
        newPassword: '',
        confirmPassword: '',
      });
    }
  };

  /**
   * Validates new password and passcode
   * After successfull validation LogIn user with new Password
   */

  resetPassword = ({ newPassword }) => {
    const params = this.getQueryParams();
    this.props
      .resetPassword({
        ...params,
        new_password: newPassword,
      })
      .then((res) => {
        if (res.payload && res.payload.data && res.payload.statusCode === 0) {
          return this.props.loginUser({
            username: res.payload.data.username,
            password: newPassword,
          });
        }
        return null;
      })
      .then((res) => {
        if (res && res.payload && res.payload.data && res.payload.statusCode === 0) {
          setTimeout(() => {
            this.setSession();
          }, 3000);
        }
      })
      .catch(() => {
        toast.dismiss();
        toast.error('Oops Something went Wrong!!!', {
          className: 'Errortoaster',
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  /**
   * destroys ongoing user session as user is about to change his/her password
   */
  destroySession() {
    removeItem('token');
    removeItem('refresh_token');
  }

  render() {
    const { auth } = this.props;
    const isNewLogin = env.AFY_NEW_LOGIN;
    return (
      isNewLogin ? 
      <AuthenticationTemplate pageTitle="Update your password">
        <div className='w-full h-full flex flex-col items-center justify-center border-t border-neutral-200'>
          <div className='w-[400px] h-full flex flex-col mt-[75px] max-[450px]:w-full max-[450px]:px-5'>
            {!auth.loading &&
              auth.loaded &&
              auth.expiredLink !== 1 &&
              auth.forgotPassword.statusCode !== 0 && (
              <>
                <h2 className='font-extrabold text-2xl text-center mb-2'>Update your password</h2>
                <span className='text-neutral-500 text-center font-medium mb-[30px]'>Almost done. Enter your new password and you’re good to go.</span>
                <SetPassword
                  handleChange={this.handleChange}
                  handleReset={this.resetPassword}
                  message={this.state.message}
                  resetPassword={auth.resetPassword.statusCode}
                />
            </>
          )}
          </div>
        </div>
      </AuthenticationTemplate>
      
      :
      
      <AuthenticationTemplate pageTitle="Update your password">
        {!auth.loading &&
          auth.loaded &&
          auth.expiredLink !== 1 &&
          auth.forgotPassword.statusCode !== 0 && (
            <>
              <p className="text-center text-neutral-500 text-sm mt-2 mb-8">
                Almost done. Enter your new password and you’re good to go.
              </p>
              <SetPassword
                handleChange={this.handleChange}
                handleReset={this.resetPassword}
                message={this.state.message}
                resetPassword={auth.resetPassword.statusCode}
              />
            </>
          )}

        {auth.expiredLink === 1 && (
          <ExpiredLink
            email={auth.data}
            isResent={auth.forgotPassword.statusCode}
            handleResend={this.handleResend}
          />
        )}
      </AuthenticationTemplate>
    );
  }
}
export default withRouter(withAuth(ResetPassword));
