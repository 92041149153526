import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { get, noop } from 'lodash-es';

import { NavigationPrompt, Modal } from 'modules/common/components';
import { withDraft } from 'modules/editor/containers';
import { withPublish } from 'modules/publish/containers';
import { isPending, isSucceeded, isLoading } from 'store/status';

import { views, MODAL_WIDTH } from './constants';
import { ViewRenderer } from './components';

const PublishModal = ({
  // Input
  draftId,
  isOpen,
  title,

  // State
  addresses,
  countries,
  credits,
  order,
  preflight,
  states,

  // Actions
  addUserAddresses,
  deleteUserAddresses,
  editUserAddresses,
  goToEditDraftContent,
  goToEditDraftCover,

  // Callbacks
  onCancel,
  onClose,
  onOpen,
  onComplete = noop,
  onSubmit,
}) => {
  const [activeView, setActiveView] = useState(views.PREFLIGHT);
  const isSubmitting = isLoading(order.status);
  useEffect(() => {
    // It clears on exit the /editor/draft/:draftId/publish
    onOpen(draftId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loading = isPending(preflight.status);

    if (!loading && activeView === views.PREFLIGHT) {
      const checkFinalPass = get(preflight, ['data', 'finalCheckPass'], false);

      if (checkFinalPass) {
        setActiveView(views.CHECKOUT);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preflight.data, activeView]);

  useEffect(() => {
    const loading = isPending(addresses.status);

    if (!loading && activeView === views.ADDRESS_LIST) {
      const length = get(addresses, ['data', 'length'], 0);

      if (length === 0) {
        setActiveView(views.ADDRESS_FORM);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses.data, activeView]);

  useEffect(() => {
    const succeeded = isSucceeded(order.status);

    if (succeeded) {
      setActiveView(views.CONFIRMATION);
    }
  }, [order.status, activeView]);

  const handleCloseModal = () => {
    if (activeView === views.CONFIRMATION) {
      onComplete();
    }

    onClose();
  };

  const handleEditCover = () => goToEditDraftCover(draftId);
  const handleEditSection = (sectionId) => goToEditDraftContent(draftId, sectionId);
  const handleSubmitOrder = (fields) => {
    const { numberOfPrints, isDigital, selectedAddressId } = fields;
    const dataOrder = {
      draftId,
      selectedAddressId,
      numberOfPrints,
      isDigital,
    };

    onSubmit(dataOrder);
  };

  return (
    <Modal
      footer={null}
      isOpen={isOpen}
      title={title}
      width={MODAL_WIDTH}
      hasCloseButton={!isSubmitting}
      maskClosable={false}
      onCancel={handleCloseModal}
    >
      <NavigationPrompt
        block={isSubmitting}
        title="We are publishing your book"
        text="If you leave now you may not publish your book. Would you still like to leave?"
      />

      <ViewRenderer
        activeView={activeView}
        addresses={addresses}
        credits={credits}
        preflight={preflight}
        countries={countries}
        order={order}
        states={states}
        onCancel={onCancel}
        onDone={handleCloseModal}
        onCreateAddress={addUserAddresses}
        onDeleteAddress={deleteUserAddresses}
        onEditAddress={editUserAddresses}
        onEditCover={handleEditCover}
        onEditSection={handleEditSection}
        onSubmitOrder={handleSubmitOrder}
        setActiveView={setActiveView}
      />
    </Modal>
  );
};

const MemoPublish = React.memo(PublishModal);
const Composed = compose(withDraft, withPublish)(MemoPublish);

export default Composed;
