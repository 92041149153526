import { Scrollbars } from 'modules/common/components';
import ConfirmationScreen from 'modules/dashboard/pages/Dentist/OrderGuideModal/components/ConfirmationScreen';
import { MODAL_HEIGHT, pluralise } from 'modules/dashboard/pages/Dentist/OrderGuideModal/constants';
import S from 'modules/publish/styles';

const ConfirmationView = ({
  contactEmail: email,
  contactPhone: phone,
  orderId,
  shippingAddress: address,
  onDone,
  guidePacketName,
}) => (
  <Scrollbars style={{ height: MODAL_HEIGHT }}>
    <S.ViewTitle>Congratulations!</S.ViewTitle>
    <S.ViewSubTitle>
      Please note your order number below. If your order contains printed{' '}
      {pluralise(guidePacketName)}, please allow 7-10 days for printing and shipping.
    </S.ViewSubTitle>

    <S.Content>
      <ConfirmationScreen
        contactEmail={email}
        contactPhone={phone}
        orderId={orderId}
        shippingAddress={address}
      />

      <S.ConfirmationButton type="primary" onClick={onDone}>
        All Done
      </S.ConfirmationButton>
    </S.Content>
  </Scrollbars>
);

export default ConfirmationView;
