import gql from 'graphql-tag';

export default gql(`
    query($templateId: ID!) {
        template(id: $templateId) {
            _id
            name
            templateHTML
            order
            thumbnail {
                _id
                url
                mime
            }
            thumbnail_small {
                _id
                url
                mime
            }
            bookpage {
                _id
                elements
                
            }
            type
            elements
        }
    }
`);
