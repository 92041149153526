export const AltArrowLeft = ({ width = 16, height = 16, fill = '#252525' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <g id="Iconer">
      <path
        id="Vector"
        d="M10 4L6 8L10 12"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
