//

import { bookCoverArray } from 'modules/previewBook/constants';

const PreFetchImages = () => (
  <div style={{ display: 'none' }}>
    {bookCoverArray.map((item) => (
      <div key={item.id}>
        <img src={item.back} alt="none" />
        <img src={item.front} alt="none" />
      </div>
    ))}
  </div>
);

export default PreFetchImages;
