import { useQuery } from 'react-query';
import { fetchAllBooks } from 'modules/api';
import { get } from 'lodash';
import { getValueFromToken } from './getValueFromToken';

function useBooks() {
  return useQuery('fetchAllBooks', () => fetchAllBooks({ limit: 3, isAllowed: true }), {
    select: (data) => {
      const books = get(data, 'data.data.books', []) || get(data, 'data.books', []);
      return books.some((book) => book?.isAllowed);
    },
  });
}

export function useBookAccess() {
  const { data: hasBooks, isLoading: booksLoading } = useBooks();
  const tokenAccess = getValueFromToken('hasBookAccess');

  return {
    hasBookAccess: !booksLoading && hasBooks && tokenAccess,
    isLoading: booksLoading,
  };
}
