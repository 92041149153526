import { get } from 'lodash-es';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

// implementation: here the margin bottom is what I need to to overried [mb-2] with [mb-0] or [mb-3] on the basis of condition
// <div className={cn('w-auto mb-2', { 'mb-0': !isSelected }, { 'mb-3': !defaultBilling.allowed || isSelected })}>
export const cn = (...args) => {
  return twMerge(clsx(args));
};

export const convertObjectToArrayOfObject = (obj, firstKey = 'keyword', secondKey = 'value') => {
  const enteries = Object.entries(obj);
  let result = [];
  if (enteries.length) {
    result = enteries.map(([key, value]) => ({ [firstKey]: key, [secondKey]: value }));
  }
  return result;
};

export const getWordsCount = (string) => {
  return string.split(' ').filter((str) => str !== '')?.length;
};

export const addKeyToArrayOfObject = (
  array = [],
  fetchValueFrom,
  newKeyname,
  processValue = (val) => val,
) => {
  return array.map((obj) => {
    const fetchedValue = get(obj, fetchValueFrom);
    const processedValue = processValue(fetchedValue);
    return { ...obj, [newKeyname]: processedValue };
  });
};

export const checkWordLimit = (textLastValue, textCurrentValue, maxLimit) => {
  const wordCount = getWordsCount(textCurrentValue);
  const limitExceeded = wordCount > maxLimit;
  const isRemovingWords = textCurrentValue.length < textLastValue.length;

  return { limitExceeded, isRemovingWords };
};

export const isSuccessfulStatus = (statusCode) => {
  if(statusCode >= 200 && statusCode < 400){
    return true;
  }
  return false;
};
