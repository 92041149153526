import { SkeletonImageIcon } from 'modules/v2/common/components/SvgIcon';

import { CSSProperties } from 'react';
import S from './styles';

type IframeProps = {
  isLoading: boolean;
  hasError: boolean;
  onRetry: () => void;
  src: string;
  height: string;
  width: string;
  style: CSSProperties;
  allowFullscreen: boolean;
};

function renderLoading(height: string) {
  return (
    <div role="status" className="animate-pulse">
      <div
        className="flex items-center justify-center w-full bg-gray-300 rounded dark:bg-gray-700"
        style={{ height }}
      >
        <SkeletonImageIcon />
      </div>
    </div>
  );
}

function renderError(onRetry: () => void) {
  const message = (
    <>
      Something went wrong while generating the preview. <br />
      Please{' '}
      <S.Button type="link" onClick={onRetry}>
        Try Again
      </S.Button>
      .
    </>
  );
  return <S.Alert message={message} type="error" />;
}

const Iframe = ({
  isLoading,
  hasError,
  src,
  onRetry,
  height,
  width = '100%',
  style = {},
  allowFullscreen = false,
}: IframeProps) => {
  if (isLoading) return renderLoading(height);
  if (hasError) return renderError(onRetry);

  return (
    <iframe
      title="digitalBook"
      width={width}
      height={height}
      style={style}
      src={src}
      allowfullscreen={allowFullscreen ? 'allowfullscreen' : null}
    />
  );
};

export default Iframe;
