import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { get, noop } from 'lodash-es';

import { generateDraftFromBook, generateDraftFromOrder, resource, getError } from 'modules/api';

import { goToRoot } from 'modules/draft/store';
import { GENERATE_DRAFT_FROM_BOOK, GENERATE_DRAFT_FROM_ORDER } from 'modules/draft/store/constants';
import { getRouteDraftView } from 'modules/draft/routes/navigation';

const generateDraft = (type) => {
  switch (type) {
    case GENERATE_DRAFT_FROM_BOOK:
      return generateDraftFromBook;

    case GENERATE_DRAFT_FROM_ORDER:
      return generateDraftFromOrder;
    default:
      return noop;
  }
};

export function* onGenerateDraft({ type, payload: meta }) {
  try {
    const request = resource(type, generateDraft(type));
    const payload = yield call(request, meta);
    const draftId = get(payload, 'draftId', null);

    if (draftId === null) {
      yield put(goToRoot());
    }

    const route = getRouteDraftView(draftId);
    // Needs to replace the history
    yield put(replace(route));

    return meta;
  } catch (error) {
    const payload = getError(error);

    // eslint-disable-next-line no-console
    console.error(payload);
    yield put(goToRoot());

    return payload;
  }
}

export function* watchCancellation(action) {
  yield race([call(onGenerateDraft, action), take(LOCATION_CHANGE)]);
}

export function* watchGenerateDraft() {
  const pattern = [GENERATE_DRAFT_FROM_BOOK, GENERATE_DRAFT_FROM_ORDER];
  yield takeEvery(pattern, watchCancellation);
}
