//

import S from './styles';

const ProgressBar = ({ scale }) => (
  <S.Progress scale={scale}>
    <S.Bar scale={scale} data-testid="loading-bar" />
  </S.Progress>
);

ProgressBar.defaultProps = {
  scale: 1,
};

export default ProgressBar;
