import styled from 'styled-components';

const TemplateWrapper = styled.div`
  margin-right: 60px;
`;
const DashboardSubTitle = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

export default {
  TemplateWrapper,
  DashboardSubTitle,
};
