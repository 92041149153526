import join from 'url-join';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import env from 'environments';

import {
  ROUTE_HOMEPAGE,
  ROUTE_BILLING_INFO,
  ROUTE_BOOK_CATALOG,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_EDIT_SHIPPING_ADDRESS,
  ROUTE_HELP_CENTER,
  ROUTE_LOGIN,
  ROUTE_MY_ORDERS,
  ROUTE_PROFILE,
  ROUTE_AUTO_LOGIN,
  ROUTE_RELEASE_NOTES,
  ROUTE_LEADS,
  ROUTE_EMAIL_SENDER_EMAIL_CONFIGURATION,
  ROUTE_EMAIL_TEMPLATES,
  ROUTE_EMAIL_CAMPAIGNS_ANALYTICS,
  ROUTE_CREATE_EMAIL_CAMPAIGN,
  ROUTE_EMAIL_LAUNCH_CAMPAIGN,
  ROUTE_CAMPAIGNS_HISTORY,
  ROUTE_ALL_CAMPAIGN_METRICS,
  ROUTE_EMAIL_CAMPAIGN,
  ROUTE_ON_DEMAND_EMAIL,
  ROUTE_CREATE_ON_DEMAND_EMAIL,
  ROUTE_BOOK_PERFORMANCE,
  ROUTE_PLANS,
  ROUTE_BILLING,
  ROUTE_UPGRADE_PLAN,
  ROUTE_PAGE_PROFILE,
  ROUTE_EMAIL_HISTORY,
  ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR,
  ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE,
  ROUTE_MANAGE_BOOK_VERSION,
  ROUTE_CREATE_CUSTOMER_TEMPLATE,
  ROUTE_EDIT_CUSTOMER_TEMPLATE,
  ROUTE_REFERRAL_MARKETING_PREVIOUS_MAGAZINES,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE_PLANS,
} from './constants';

export const getRouteHomePage = () => generatePath(ROUTE_HOMEPAGE);

export const getLinkBillingInfo = (data) => {
  const path = generatePath(ROUTE_BILLING_INFO, { data });
  return join(env.UPDATE_BILLING_INFO, path);
};

export const getRouteBookCatalog = () => generatePath(ROUTE_BOOK_CATALOG);

export const getRouteMyOrders = () => generatePath(ROUTE_MY_ORDERS);

export const getRouteDashboard = () => generatePath(ROUTE_LEADS);

export const getRouteBookPerformance = () => generatePath(ROUTE_BOOK_PERFORMANCE);

export const getRouteHelpCenter = () => generatePath(ROUTE_HELP_CENTER);

export const getRouteProfile = () => generatePath(ROUTE_PROFILE);

export const getRouteAutoLogin = (username, token, extraParams = '') =>
  generatePath(`${ROUTE_AUTO_LOGIN}/?username=${username}&token=${token}&${extraParams}`);

export const getRouteReleaseNotes = () => generatePath(ROUTE_RELEASE_NOTES);

export const getRouteShippingAddress = () => generatePath(ROUTE_EDIT_SHIPPING_ADDRESS);

export const getRouteChangePassword = () => generatePath(ROUTE_CHANGE_PASSWORD);
export const getRouteProfilePlans = () => generatePath(ROUTE_PROFILE_PLANS);

export const getRoutePageProfile = () => generatePath(ROUTE_PAGE_PROFILE);

export const getRouteLogin = () => generatePath(ROUTE_LOGIN);

export const getRouteLeads = () => generatePath(ROUTE_LEADS);

export const getRouteBilling = () => generatePath(ROUTE_BILLING);

export const getRoutePlans = () => generatePath(ROUTE_PLANS);
export const getRoutePayments = () => generatePath(ROUTE_PAYMENTS);

export const getRouteEmailHistory = (
  leadId,
  firstName,
  lastName,
  address,
  email,
  phone,
  segments,
) =>
  generatePath(ROUTE_EMAIL_HISTORY, {
    leadId,
    firstName,
    lastName,
    address,
    email,
    phone,
    segments,
  });

export const getRouteEmailSenderEmailConfiguration = () =>
  generatePath(ROUTE_EMAIL_SENDER_EMAIL_CONFIGURATION);

export const getRouteEmailTemplates = () => generatePath(ROUTE_EMAIL_TEMPLATES);

export const getRouteEmailCampaignsAnalytics = () => generatePath(ROUTE_EMAIL_CAMPAIGNS_ANALYTICS);

export const getRouteCampaignHistory = (id) => generatePath(ROUTE_CAMPAIGNS_HISTORY, { id });

export const getRouteAllCampaignMetrics = () => generatePath(ROUTE_ALL_CAMPAIGN_METRICS);

export const getManageBookVersion = () => generatePath(ROUTE_MANAGE_BOOK_VERSION);

export const getRouteCreateOnDemandEmail = () => generatePath(ROUTE_CREATE_ON_DEMAND_EMAIL);

export const getRouteOnDemandEmail = (id) => generatePath(ROUTE_ON_DEMAND_EMAIL, { id });

export const getRouteLaunchCampaign = () => generatePath(ROUTE_EMAIL_LAUNCH_CAMPAIGN);

export const getRouteEmailCampaign = (id) => generatePath(ROUTE_EMAIL_CAMPAIGN, { id });

export const getRouteCreateEmailCampaign = (content) =>
  generatePath(ROUTE_CREATE_EMAIL_CAMPAIGN, { content });

export const getUpgradePlan = () => generatePath(ROUTE_UPGRADE_PLAN);

export const getRouteCreateCustomerTemplate = (templateBaseId) =>
  generatePath(ROUTE_CREATE_CUSTOMER_TEMPLATE, { templateBaseId });

export const getRouteCreateCustomerTemplateFromScratch = () =>
  generatePath(ROUTE_CREATE_CUSTOMER_TEMPLATE);

export const getRouteEditCustomerTemplate = (id) =>
  generatePath(ROUTE_EDIT_CUSTOMER_TEMPLATE, { id });

export const goToHelpCenter = () => {
  const route = getRouteHelpCenter();

  return push(route);
};

export const getRouteReferralMarketingMagazineEditor = (
  month = env.RMM_CURRENT_MONTH || 'august',
  year = '2022',
) => generatePath(ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR, { month, year });

export const getRouteReferralMarketingGeneratedMagazine = () =>
  generatePath(ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE);

export const getRouteReferralMarketingPreviousMagazines = () =>
  generatePath(ROUTE_REFERRAL_MARKETING_PREVIOUS_MAGAZINES);
