import { filter, find, map, slice } from 'lodash-es';

import {
  TYPE_CHAPTER,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  getSectionById,
  convertBucketsToMap,
  convertSectionsToBuckets,
  changeBucketOrderByIndex,
} from 'modules/v2/editor/utils';

// Push a section to a specific index in a list of sections
function pushSectionAtIndex(sections, section, index = 0) {
  return [...slice(sections, 0, index), section, ...slice(sections, index)];
}

function moveSectionOnBuckets(source, destination, sectionId, index) {
  const section = find(source, (item) => item._id === sectionId);

  if (!section) {
    return {
      source,
      destination,
    };
  }

  return {
    source: filter(source, (item) => item._id !== sectionId),
    destination: pushSectionAtIndex(destination, section, index),
  };
}

// Move a section to a specific index inside a list of sections
function moveSectionByIdToIndex(sections, sectionId, index = 0) {
  const section = getSectionById(sections, sectionId);

  if (!section) {
    return sections;
  }

  const filtered = filter(sections, ({ _id }) => _id !== sectionId);
  return pushSectionAtIndex(filtered, section, index);
}

// Reorder the indexes of sections inside a bucket
const reindexSectionBucket = (bucket, sectionId, nextIndex) => ({
  active: moveSectionByIdToIndex(bucket.active, sectionId, nextIndex),
  inactive: moveSectionByIdToIndex(bucket.inactive, sectionId, nextIndex),
});

const activateSectionBucket = (bucket, sectionId, nextIndex) => {
  const { source, destination } = moveSectionOnBuckets(
    bucket.inactive,
    bucket.active,
    sectionId,
    nextIndex,
  );

  return {
    active: destination,
    inactive: source,
  };
};

const deactivateSectionBucket = (bucket, sectionId, nextIndex) => {
  const { source, destination } = moveSectionOnBuckets(
    bucket.active,
    bucket.inactive,
    sectionId,
    nextIndex,
  );

  return {
    active: source,
    inactive: destination,
  };
};

// Apply the changes of buckets to the actual sections array
function applyChangesToSections(sections, buckets) {
  const mapped = convertBucketsToMap(buckets);

  return map(sections, (section) => {
    const newSection = mapped.get(section._id);

    if (newSection) {
      // TODO: Replace the chapter number with the new order

      return {
        ...section,
        order: newSection.order,
        currentStatus: newSection.isActive ? STATUS_ACTIVE : STATUS_INACTIVE,
      };
    }

    return section;
  });
}

export function arrangeSection(sections, sectionId, type, nextIndex, sortingFn) {
  // Converts the sections to buckets, for a better organization
  let { manuscript, content } = convertSectionsToBuckets(sections);
  const isChapter = type === TYPE_CHAPTER;

  // Reindex the chapter/content based on the type of section.
  // It's important because the data received from the action is based on the bucket indexes
  if (isChapter) {
    manuscript = sortingFn(manuscript, sectionId, nextIndex);
  } else {
    content = sortingFn(content, sectionId, nextIndex);
  }

  // Sorts the buckets and fix the order property on each section
  const sorted = changeBucketOrderByIndex({ manuscript, content });

  // Applies the order and status to the actual sections array
  return applyChangesToSections(sections, sorted);
}

export const reorderSection = (sections, sectionId, type, nextIndex) =>
  arrangeSection(sections, sectionId, type, nextIndex, reindexSectionBucket);

export function activateSection(sections, sectionId, type, nextIndex) {
  return arrangeSection(sections, sectionId, type, nextIndex, activateSectionBucket);
}

export function deactivateSection(sections, sectionId, type, nextIndex) {
  return arrangeSection(sections, sectionId, type, nextIndex, deactivateSectionBucket);
}
