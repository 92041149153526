import { useLocation } from 'react-router-dom';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function setBookLabel(label, isAllowed = true, currentBookPreview) {
  if (!isAllowed && currentBookPreview) {
    return 'Upgrade';
  }

  const formattedLabel = {
    draft: 'Editing',
    modified: 'Editing',
    done: 'Published',
    ready: 'Published',
  };

  return formattedLabel[label] || '';
}

export function setLabelColor(label) {
  const colorLabel = {
    draft: 'text-orange-500',
    modified: 'text-orange-500',
    done: 'text-success-500',
    ready: 'text-success-500',
  };

  return colorLabel[label] || '';
}
