import React from 'react';

import { useFormikContext } from 'formik';

import { FormLabel } from 'modules/common/components';

import S from './styles';

const ContactDetailsForm = () => {
  const { values, errors, handleChange } = useFormikContext();

  return (
    <S.Form className="address-form">
      <S.Form.Row>
        <FormLabel title="Email" error={errors.email} htmlFor="email" required>
          <S.Form.Input
            id="email"
            type="email"
            name="email"
            value={values.email}
            error={errors.email}
            onChange={handleChange}
          />
        </FormLabel>
      </S.Form.Row>
      <S.Form.Row>
        <FormLabel title="Phone" error={errors.phone} htmlFor="phone" required>
          <S.Form.InputMask
            alwaysShowMask
            id="phone"
            name="phone"
            type="text"
            error={errors.phone}
            mask="999-999-9999"
            value={values.phone}
            onChange={handleChange}
          />
        </FormLabel>
      </S.Form.Row>
    </S.Form>
  );
};

export default React.forwardRef(ContactDetailsForm);
