import styled from 'styled-components';

import { Button as BaseButton } from 'antd';
import * as font from 'modules/common/theme/font';
import * as color from 'modules/common/theme/color';

const Form = styled.form`
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

Form.Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

Form.Label = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  transition: all 0.2s ease;
  margin-bottom: 10px;
`;

Form.MessageLength = styled.div`
  font-size: 14px;
  flex: 1;
`;

Form.Characters = styled.span`
  display: inline-block;
  width: 28px;
  text-align: right;
`;

Form.FieldTitle = styled.span`
  font-size: 14px;
  margin-bottom: 10px;
`;

Form.SubmitButtonWrapper = styled.div`
  float: right;
`;

Form.Textarea = styled.textarea`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid ${color.Black};
  border-radius: 3px;
  flex: 1;

  &:focus {
    outline: none;
  }
`;

Form.SubmitButton = styled(BaseButton)`
  background-color: ${color.LightBlue};
  font-family: ${font.RobotoFont};
  font-size: 16px;
  height: 40px;
  color: ${color.White};
  border-radius: 2px !important;
  font-weight: normal !important;
  border: 0;

  &:hover,
  &:focus,
  &:active {
    color: ${color.White};
    background-color: ${color.ActiveBlue};
  }
  &:disabled,
  &:disabled:hover {
    color: ${color.White};
    background-color: ${color.DisabledButton};
  }
`;

const TooltipContent = styled.div`
  text-align: center;
`;

const SpaceBorder = styled.hr`
  margin-bottom: 25px;
  margin-top: 0;
  border-top: 0;
  border-bottom: 1px solid ${color.GrayBorder};
  width: 100%;
`;

export default {
  Form,
  TooltipContent,
  SpaceBorder,
};
