import { WarnMessage } from 'modules/v2/common/AtomicDesign/molecules';
import { DangerTriangleIcon } from 'modules/v2/common/components/SvgIcon';
import { danger500 } from 'modules/common/theme/color';

export const WarningMessage = (message) => {
  return (
    <WarnMessage color="failure" className="text-red-500 mb-6">
      <div className="flex gap-3">
        <div className="w-4 h-4">
          <DangerTriangleIcon width={20} height={20} fill={danger500} />
        </div>
        <div>{message}</div>
      </div>
    </WarnMessage>
  );
};
