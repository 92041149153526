//

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { RichUtils } from 'draft-js';
import classnames from 'classnames';
import { map, noop } from 'lodash-es';

import { options } from 'modules/v2/editor/components/ContentEditor/utils';

const Block = ({ editor, onChange = noop }) => {
  const { blockStyles } = options;

  const key = editor.getSelection().getStartKey();

  const blockType = editor
    .getCurrentContent()
    .getBlockForKey(key)
    .getType();

  const handleAlignment = (textAlign) => (e) => {
    e.preventDefault();
    const state = RichUtils.toggleBlockType(editor, textAlign);
    onChange(state);
  };

  return map(blockStyles, ({ icon, type, style }) => (
    <span
      key={type}
      className={classnames('normal', { active: type === blockType })}
      onClick={handleAlignment(style)}
    >
      <LegacyIcon type={icon} />
    </span>
  ));
};

export default Block;
