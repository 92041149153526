import { Iframe } from '../../../draft/components';

const FlippingBookEmbed = ({ src, height='1000px' }) => {
  return (
    <div className="rounded p-6">
      <Iframe src={src} height={height} />
    </div>
  );
};

export default FlippingBookEmbed;
