import { put, takeEvery } from 'redux-saga/effects';

import { clearDraftPreview } from 'modules/draft/store';

import { CANCEL_GENERATE_DRAFT_PREVIEW, CLEAR_DRAFT_PREVIEW } from 'modules/draft/store/constants';

export function* onClearDraftPreview() {
  yield put(clearDraftPreview());
}

export function* watchClearDraftPreview() {
  const pattern = [CANCEL_GENERATE_DRAFT_PREVIEW, CLEAR_DRAFT_PREVIEW];
  yield takeEvery(pattern, onClearDraftPreview);
}
