import { isString } from 'lodash-es';

export const getOnlyNumbers = (phone) => {
  if (!isString(phone)) {
    return '';
  }
  const filter = /[^\d]/g;
  const formatter = '';

  return phone.replace(filter, formatter);
};

export const formatPhone = (phone) => {
  const filter = /(\d{3})(\d{3})(\d{4})/;
  const formatter = '$1-$2-$3';
  const numbers = getOnlyNumbers(phone);

  return numbers.replace(filter, formatter);
};
