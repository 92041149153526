import * as color from 'modules/common/theme/color';
import styled from 'styled-components';
import { Tooltip as TooltipBase } from 'modules/common/components';
import { DatePicker as DatePickerBase } from 'antd';

const HeadingWrap = styled.div`
  width: 100%;
  background-color: #f0f0f0;
  border-bottom: 1px solid #dbdbdb;
  padding: 0px 20px;
  h5 {
    font-size: 16px;
  }
`;

const Title = styled.h5`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  // line-height: 46px;
  padding: 7px 0px;
  font-weight: bold;
  margin: ${(props) => props.marginLeft ?? '0px'};
`;

const TopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px 20px 0px;
`;

const TableWrap = styled.div`
  margin-bottom: 30px;
`;

const AllOfferFeaturesWrap = styled.div`
  margin: 20px 0px;
  padding: 0px 20px;
`;

const SelectWrap = styled.select`
  // width: 100px;
  font-size: 14;
  margin: 0px 10px;
`;

const SelectOption = styled.option`
  font-size: 14;
`;

const TabsWrap = styled.div`
  .ant-tabs-bar {
    border-bottom: 1px solid transparent;
  }
  .ant-tabs-ink-bar {
    border-bottom: 3px solid #d9d9d9;
    background-color: #d9d9d9;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #000;
    font-weight: 600;
  }
`;

const SubContainer = styled.div`
  // padding: 20px 20px;
  // border: 1px solid #dbdbdb;
  // border-radius: 2px;
  width: 90%;
`;

const InputWrap = styled.div`
  padding: 5px;
  width: ${(props) => (props.fullWidth ? '100%' : '300px')};
  font-size: 14px;
  line-height: 10px;
  color: #000;
  padding-bottom: ${(props) => (props.noPaddingBottom ? '0px' : '')};
  position: relative;
  &:first-child {
    padding-left: 0px;
  }
  input,
  textarea,
  .ant-select {
    font-size: 16px;
    line-height: 24px;
    padding: 20px 20px;
    resize: none;
    margin-top: 10px;
  }
  textarea {
    height: 100px;
  }
  .ant-select {
    width: 100%;
    padding: 0px;
  }
  .ant-select-selection {
    padding: 5px 10px;
  }
  .ant-select-selection--single {
    height: unset;
  }
`;

const FieldSeprator = styled.div`
  display: flex;
  // align-items: baseline;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TextAreaWrap = styled.div`
  display: flex;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : '')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
`;

const AddOfferImage = styled.div`
  padding: 10px;
  width: 450px;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  &:first-child {
    padding-left: 0px;
  }
`;

const OptionalField = styled.div`
  margin: 40px 0px;
  border: 1px solid #dbdbdb;
  border-bottom: 10px solid #dbdbdb;
  border-radius: 2px;
`;

const SubHeading = styled.h6`
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: ;10px
`;

const BottomWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const ImageSizeText = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
`;

const SelectFile = styled.div`
  font-size: 16px;
  display: flex;
  padding: 20px;
  border: 1px dashed #dbdbdb;
  span {
    color: ${color.SkyBlue};
  }
  svg {
    width: 40px;
    height: 40px;
  }
  p {
    color: #8d8888;
  }
`;

const FileWrap = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  height: 100px;
  // border: 1px solid #d9d9d9;
  // border-radius: 2px;
  // margin-top: 10px;
  position: relative;
`;

const ImageConatainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 10px;
  p {
    margin-left: 20px;
  }
`;

const FileInput = styled.input`
  position: absolute;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  background-size: 60px 60px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-top: 10px;
  position: relative;
  input {
    position: relative;
    text-indent: 30px;
    margin-top: 0px;
  }
  span {
    position: absolute;
    left: 30px;
  }
  svg {
    color: ${(props) => (props.hasValue ? '#000' : '#cccccc')};
  }
`;

const ImageWrap = styled.div`
  border: 1px dashed #dbdbdb;
  position: relative;
  svg {
    position: absolute;
    top: -8px;
    right: 72px;
    color: #980f20;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
  }
`;

const OfferImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  padding: 10px;
`;

const InclusionContainer = styled.div`
  // position: relative;
  // width: 450px;
`;

const InclusionList = styled.div`
  // display: flex;
  // flex-wrap: wrap;
`;

const InclusionItemCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  // margin-top: 10px;

  span.closeIcon {
    position: absolute;
    right: 16px;
    top: 8px;
    background: #f0f0f0;
    cursor: pointer;
  }
`;

const InclusionItem = styled.div`
  font-size: 14px;
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 6px 4px 4px;
  postion: relative;
  -webkit-line-clamp: 1;
  // overflow: hidden;
  // text-overflow: ellipsis;
  position: relative;
  &:last-child {
    margin-right: 0px;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 92%;
  }
`;

const Text = styled.p`
  cursor: ${(props) => (props.cursorPointer ? 'pointer' : '')};
  color: ${(props) => (props.color ? props.color : '#000')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
`;

const TextWrap = styled.div`
  display: block;
`;

const PageContainer = styled.div`
  display: flex;
`;

const SubSideBar = styled.div`
  width: 25%;
  margin-right: 20px;
  // background-color: #fafafa;
  padding: 10px 13px 20px 29px;
  box-shadow: 0px 3px 6px #00000040;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  height: 80px;
  width: 150px;
  overflow: hidden;
  border: 3px solid ${color.GreyBookBorder};
  transition: border 0.5s ease;

  img {
    will-change: transform;
    transition: transform 0.5s ease;
    transform: scale(1.03);
  }

  &:hover {
    border-color: ${color.BookCatalogHeaderBg};

    img {
      transform: scale(1);
    }
  }
`;

const TextRight = styled.h5`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  padding: 7px 0px;
  font-weight: bold;
  float: right;
  margin: ${(props) => props.marginLeft ?? '0px'};
`;
const Label = styled.label`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 12px;
  display: block;
`;
const LabelWithIcon = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Tooltip = styled(TooltipBase)``;
const DatePicker = styled(DatePickerBase)`
  width: 100%;
  margin-bottom: 20px;
`;

export default {
  Actions,
  AddOfferImage,
  AllOfferFeaturesWrap,
  BottomWrap,
  FileInput,
  FileWrap,
  HeadingWrap,
  IconContainer,
  ImageConatainer,
  ImageSizeText,
  ImageWrap,
  InclusionContainer,
  InclusionItem,
  InclusionItemCover,
  InclusionList,
  InputWrap,
  FieldSeprator,
  OfferImage,
  OptionalField,
  PageContainer,
  SelectFile,
  SelectOption,
  SelectWrap,
  SubContainer,
  SubHeading,
  SubSideBar,
  TableWrap,
  TabsWrap,
  Text,
  TextWrap,
  TextAreaWrap,
  Title,
  TopWrap,
  ImageWrapper,
  TextRight,
  LabelWithIcon,
  Tooltip,
  DatePicker,
};
