const BooksIcon = ({ stroke = '#fafafa', fill = '#fafafa', ...props }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.33301 5.50008V16.3334C3.33301 17.7141 4.4523 18.8334 5.83301 18.8334H14.1663C15.5471 18.8334 16.6663 17.7141 16.6663 16.3334V8.00008C16.6663 6.61937 15.5471 5.50008 14.1663 5.50008H3.33301ZM3.33301 5.50008V4.66675"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <circle cx="10" cy="11.3333" r="2.5" stroke={stroke} strokeWidth="1.5" />
      <path
        d="M14.9997 5.50002V6.25002H15.7497V5.50002H14.9997ZM13.0973 2.43845L12.9912 1.69599L13.0973 2.43845ZM4.09934 3.72387L3.99327 2.98141H3.99327L4.09934 3.72387ZM4.22556 6.25002H14.9997V4.75002H4.22556V6.25002ZM15.7497 5.50002V4.08837H14.2497V5.50002H15.7497ZM12.9912 1.69599L3.99327 2.98141L4.2054 4.46634L13.2034 3.18091L12.9912 1.69599ZM3.99327 2.98141C3.18407 3.09701 2.58301 3.79004 2.58301 4.60746H4.08301C4.08301 4.53652 4.13517 4.47637 4.2054 4.46634L3.99327 2.98141ZM15.7497 4.08837C15.7497 2.61771 14.4471 1.48801 12.9912 1.69599L13.2034 3.18091C13.7556 3.10202 14.2497 3.53053 14.2497 4.08837H15.7497ZM4.22556 4.75002C4.14683 4.75002 4.08301 4.68619 4.08301 4.60746H2.58301C2.58301 5.51462 3.31841 6.25002 4.22556 6.25002V4.75002Z"
        stroke={stroke}
        fill={fill}
      />
      <path d="M8.33301 16.3333H11.6663" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default BooksIcon;
