//

import { AddressForm } from 'modules/editor/components';

import S from 'modules/publish/styles';

const AddressFormView = ({ fields, countries, states, onSubmit, onCancel }) => (
  <>
    <S.ViewSubTitle>Enter your new shipping address</S.ViewSubTitle>
    <AddressForm
      fields={fields}
      countries={countries}
      states={states}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  </>
);

export default AddressFormView;
