//

import { noop } from 'lodash-es';

import { isSucceeded } from 'store/status';

import {
  PreflightCheckView,
  GenerateLinksView,
} from 'modules/draft/components/GenerateDigitalBookModal/views';
import { views } from 'modules/draft/components/GenerateDigitalBookModal/constants';

const ViewRenderer = ({ activeView, preflight, onEditCover = noop, onEditSection = noop }) => {
  switch (activeView) {
    case views.CHECKING_PREFLIGHT: {
      const isPreflightSucceeded = isSucceeded(preflight.status);

      return (
        <PreflightCheckView
          loading={!isPreflightSucceeded}
          preflight={preflight.data}
          onEditCover={onEditCover}
          onEditSection={onEditSection}
        />
      );
    }

    case views.GENERATING_LINKS: {
      return <GenerateLinksView />;
    }

    default:
      return null;
  }
};

export default ViewRenderer;
