//

import { Scrollbars } from 'modules/common/components';
import { Confirmation } from 'modules/publish/components';
import { MODAL_HEIGHT } from 'modules/publish/constants';

import S from 'modules/publish/styles';

const ConfirmationView = ({ contactEmail, contactPhone, orderId, shippingAddress, onDone }) => (
  <Scrollbars style={{ height: MODAL_HEIGHT }}>
    <S.ViewTitle>Congratulations!</S.ViewTitle>
    <S.ViewSubTitle>
      Please note your order number below. If your order contains printed books, please allow 7-10
      days for printing and shipping.
    </S.ViewSubTitle>

    <S.Content>
      <Confirmation
        contactEmail={contactEmail}
        contactPhone={contactPhone}
        orderId={orderId}
        shippingAddress={shippingAddress}
      />

      <S.ConfirmationButton type="primary" onClick={onDone}>
        All Done
      </S.ConfirmationButton>
    </S.Content>
  </Scrollbars>
);

export default ConfirmationView;
