import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import { Card, Space, Typography } from 'antd';
import { compose } from 'redux';
import { get } from 'lodash-es';

import { getActiveSubscriptions, getSubscription, getSubscriptionService } from 'modules/api';
import { withAuth } from 'modules/auth/containers';
import { withProfile } from 'modules/v2/containers';
import { ProfileTemplate } from 'modules/v2/templates';
import { centsToDollar } from 'modules/v2/pages/Plans/utils';
import { getRoutePlans } from 'modules/v2/routes/navigation';
import { PrimaryButton, SecondaryButton } from 'modules/v2/common/components';
import NeedHelp from 'modules/v2/pages/PlanSettings/components/NeedHelp';

import S from './styles';

const { Text } = Typography;

const PlanSettings = ({ avatar, profile, signOut }) => {
  const history = useHistory();
  const [needHelpModalOpen, setNeedHelpModalOpen] = useState(false);
  const { data } = profile;
  const email = get(data, 'email');
  const firstName = get(data, 'firstName');

  const toggleHelpModal = () => {
    setNeedHelpModalOpen(!needHelpModalOpen);
  };

  const handleViewPlan = () => {
    history.push(getRoutePlans());
  };

  const { data: subscription = {} } = useQuery(['getSubscription'], getActiveSubscriptions);

  const { data: { value: isSubscriptionFromStripe } = {} } = useQuery(
    ['getSubscriptionService'],
    getSubscriptionService,
  );

  const { data: subFromStripe } = useQuery(['getSubscriptionFromStripe'], getSubscription);
  const stripePlanName = get(subFromStripe, 'attributes.planName', 'Trialing Plan');

  const subscriptionPlanName = isSubscriptionFromStripe
    ? stripePlanName
    : get(subscription, 'component.name', stripePlanName);

  const amount = centsToDollar(subscription?.component?.unitPrice * 100);

  const planDetails = amount
    ? `${subscriptionPlanName} - $${amount} / month`
    : subscriptionPlanName;

  return (
    <ProfileTemplate
      avatar={avatar}
      email={email}
      title="Plan Settings"
      username={firstName}
      onLogout={signOut}
      sidebarWidth={0}
      noPaddingLeft
      noPaddingRight
    >
      <Card>
        <S.CardWrap direction="horizontal">
          <Space direction="vertical">
            <Text type="secondary">Your current plan</Text>
            <S.PlanTitle>{planDetails}</S.PlanTitle>
          </Space>
          <Space>
            <PrimaryButton onClick={handleViewPlan}>View Plan</PrimaryButton>
            <SecondaryButton onClick={toggleHelpModal}>Need Help?</SecondaryButton>
            <NeedHelp open={needHelpModalOpen} toggleOpen={toggleHelpModal} />
          </Space>
        </S.CardWrap>
      </Card>
    </ProfileTemplate>
  );
};

const Composed = compose(withProfile, withAuth)(PlanSettings);

export default Composed;
