export const initialValues = {
  currentStep: 0,
  selectedOption: '',
  magazine: {
    _formFields: {},
  },
  frontCover: {
    selectedOption: '',
    _formFields: {},
  },
  frontInsideCover: {
    selectedOption: '',
    _formFields: { frontInsideCoverText: '' },
  },
  backCover: {
    selectedOption: '',
    _formFields: {},
  },
  backInsideCover: {
    selectedOption: '',
    _formFields: {},
  },
};
