import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.span`
  font-size: 16px;
  text-transform: capitalize;
  line-height: 24px;
  vertical-align: middle;
  color: ${color.Black};
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  gap: 5px;

  transition: transform 0.3s ease-in-out;
`;

const CoverContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  max-width: 285px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
`;

const Image = styled.img`
  width: 76.17px;
  /* height: 125px; */
  border-radius: 2px;

  object-fit: cover;
  cursor: pointer;
`;

const ImageCoverContainer = styled.div`
  z-index: 2;
  position: relative;
  top: 0;
  left: 0;

  border-radius: 4px;
  border: ${({ active }) =>
    active ? `2px solid ${color.BlueBorder}` : `2px solid ${color.LightGray}`};
  border-radius: 4px;
  background: none;

  display: flex;
  justify-content: center;
  align-items: center;

  transform: translateX(
    ${({ index, length }) => {
    if (index >= length - 1) return (index - 1) * -95.17;
    if (length - index > 2) return index * -95.17;
    return index * -95.17;
  }}px
  );
  transition: transform 0.3s ease-in-out;
`;

const CheckIcon = styled.img`
  position: absolute;
`;

const SelectedImage = styled.img`
  object-fit: cover;
  border-radius: 4px;
  max-width: 100%;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export default {
  Container,
  Title,
  SliderContainer,
  CoverContainer,
  Image,
  ImageCoverContainer,
  SelectedImage,
  ArrowButton,
  CheckIcon,
};
