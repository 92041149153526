import { Form, Formik } from 'formik';
import { SelectField } from 'modules/common/components';
import { get, isEmpty, map } from 'lodash-es';

import Toggle from 'modules/v2/common/AtomicDesign/Toggle';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import validationSchema from './validationSchema';
import S from './styles';

const AddressForm = forwardRef(
  ({ fields, countries, states, onSubmit, hasSubmitButton, hasDefaultToggle }, ref) => {
    const formikRef = useRef();

    function touchingFieldsManually() {
      formikRef.current.setTouched({
        firstName: true,
        lastName: true,
        phoneNumber: true,
        addressLine1: true,
        city: true,
        country: true,
        pincode: true,
        state: true,
      });
    }

    function renderDefaultToggle(setDefaultAddress, values) {
      return (
        hasDefaultToggle && (
          <div className="mb-10 ml-3 flex items-center gap-2 font-bold text-sm">
            <Toggle onChange={setDefaultAddress} enabled={values.isDefault} /> Set as my default
            address
          </div>
        )
      );
    }

    return (
      <Formik
        innerRef={formikRef}
        initialValues={fields}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useImperativeHandle(ref, () => ({
            submit: () => {
              return onSubmit(values);
            },

            validate: () => {
              touchingFieldsManually();
              return formikRef.current.validateForm();
            },
          }));

          const optionBuilder = (item) => ({
            title: item,
            value: item,
          });

          const selectedCountry = values.country;
          const selectedStateList = get(states, selectedCountry);

          const countryList = map(countries, optionBuilder);
          const stateList = map(selectedStateList, optionBuilder);

          const handleCountryChange = () => setFieldValue('state', undefined);
          const setDefaultAddress = () => setFieldValue('isDefault', !values.isDefault);
          const handleChangePhone = (event) => {
            const { value } = event.target;
            const strippedInput = value.replace(/[^\d]/g, '');
            let maskedPhoneNumber = '';

            for (let i = 0; i < strippedInput.length && i < 10; i++) {
              if (i === 3 || i === 6) {
                maskedPhoneNumber += '-';
              }
              maskedPhoneNumber += strippedInput[i];
            }
            setFieldValue('phoneNumber', maskedPhoneNumber);
          };

          function renderSubmitButton() {
            return (
              hasSubmitButton && (
                <div className="absolute w-full bottom-0 left-0 pt-4 pb-8 px-6 border-t bg-white">
                  <S.FieldControl>
                    <S.ButtonsWrapper>
                      <S.SubmitButton onClick={handleSubmit}>
                        {!isEmpty(fields) ? 'Save Changes' : 'Add new address'}
                      </S.SubmitButton>
                    </S.ButtonsWrapper>
                  </S.FieldControl>
                </div>
              )
            );
          }

          return (
            <Form onSubmit={handleSubmit} className="address-form p-0 w-full">
              <S.Wrapper>
                <S.FieldGroup>
                  <S.FieldControl>
                    <S.Label>
                      First name
                      <S.FormikField
                        onChange={handleChange}
                        hasError={touched.firstName && errors.firstName}
                        type="text"
                        name="firstName"
                      />
                      {errors.firstName && touched.firstName && (
                        <div className="text-sm text-red-400 font-normal mt-1">
                          {errors.firstName}
                        </div>
                      )}
                    </S.Label>
                  </S.FieldControl>

                  <S.FieldControl>
                    <S.Label>
                      Last name
                      <S.FormikField
                        onChange={handleChange}
                        hasError={touched.lastName && errors.lastName}
                        type="text"
                        name="lastName"
                      />
                      {errors.lastName && touched.lastName && (
                        <div className="text-sm text-red-400 font-normal mt-1">
                          {errors.lastName}
                        </div>
                      )}
                    </S.Label>
                  </S.FieldControl>
                </S.FieldGroup>

                <S.FieldGroup>
                  <S.FieldControl>
                    <S.Label>
                      Phone number
                      <S.FormikField
                        onChange={handleChangePhone}
                        type="tel"
                        name="phoneNumber"
                        placeholder="Optional"
                        mask="999-999-9999"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      />
                    </S.Label>
                  </S.FieldControl>
                </S.FieldGroup>

                <S.FieldGroup>
                  <S.FieldControl>
                    <S.Label>
                      Street address
                      <S.FormikField
                        onChange={handleChange}
                        hasError={errors.addressLine1 && touched.addressLine1}
                        type="text"
                        name="addressLine1"
                      />
                      {errors.addressLine1 && touched.addressLine1 && (
                        <div className="text-sm text-red-400 font-normal mt-1">
                          {errors.addressLine1}
                        </div>
                      )}
                    </S.Label>
                  </S.FieldControl>
                </S.FieldGroup>

                <S.FieldGroup>
                  <S.FieldControl>
                    <S.Label>
                      Apartment, suite, unit, building, etc.
                      <S.FormikField
                        onChange={handleChange}
                        type="text"
                        name="addressLine2"
                        placeholder="Optional"
                      />
                    </S.Label>
                  </S.FieldControl>
                </S.FieldGroup>

                <S.FieldGroup>
                  <S.FieldControl>
                    <S.Label>
                      City
                      <S.FormikField
                        onChange={handleChange}
                        hasError={errors.city && touched.city}
                        type="text"
                        name="city"
                      />
                      {errors.city && touched.city && (
                        <div className="text-sm text-red-400 font-normal mt-1">{errors.city}</div>
                      )}
                    </S.Label>
                  </S.FieldControl>
                  <S.FieldControl>
                    <S.Label>
                      Country
                      <S.FormikField
                        component={SelectField}
                        name="country"
                        placeholder="Country"
                        hasError={errors.country && touched.country}
                        type="text"
                        value={values.country}
                        options={countryList}
                        onChange={handleCountryChange}
                        onBlur={handleCountryChange}
                      />
                      {errors.country && touched.country && (
                        <div className="text-sm text-red-400 font-normal mt-1">
                          {errors.country}
                        </div>
                      )}
                    </S.Label>
                  </S.FieldControl>
                </S.FieldGroup>

                <S.FieldControl>
                  <S.FieldGroup>
                    <S.Label>
                      ZIP/Postal Code
                      <S.FormikField
                        onChange={handleChange}
                        hasError={errors.pincode && touched.pincode}
                        type="text"
                        name="pincode"
                      />
                      {errors.pincode && touched.pincode && (
                        <div className="text-sm text-red-400 font-normal mt-1">
                          {errors.pincode}
                        </div>
                      )}
                    </S.Label>
                    <S.Label>
                      State/Province
                      <S.FormikField
                        component={SelectField}
                        name="state"
                        hasError={errors.state && touched.state}
                        placeholder="Select"
                        value={values.state}
                        options={stateList}
                      />
                      {errors.state && touched.state && (
                        <div className="text-sm text-red-400 font-normal mt-1">{errors.state}</div>
                      )}
                    </S.Label>
                  </S.FieldGroup>
                </S.FieldControl>

                {renderDefaultToggle(setDefaultAddress, values)}
              </S.Wrapper>

              {renderSubmitButton()}
            </Form>
          );
        }}
      </Formik>
    );
  },
);

AddressForm.defaultProps = {
  fields: {},
  countries: [],
  states: {},
  hasSubmitButton: true,
  hasDefaultToggle: true,
};

export default AddressForm;
