import { call, takeLatest } from 'redux-saga/effects';

import { fetchCategories, resource, getError } from 'modules/api';
import { GET_CATEGORIES } from 'modules/dashboard/store/constants';

export function* onGetCategories({ payload: meta }) {
  try {
    const request = resource(GET_CATEGORIES, fetchCategories);
    const payload = yield call(request, meta);

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetCategories() {
  yield takeLatest(GET_CATEGORIES, onGetCategories);
}
