import { HolidaySale, Public } from 'modules/router/types';

import {
  ROUTE_HOLIDAY_SALE,
  ROUTE_HOLIDAY_SALE_AUTH,
  ROUTE_HOLIDAY_SALE_BUY,
  ROUTE_HOLIDAY_SALE_CONFIRMED,
  ROUTE_HOLIDAY_SALE_LOGIN,
} from './constants';

import { IsFeatureVisible } from '../../dashboard/utils/isAdminCustomer';
import HolidaySaleMain from '../index';
import HolidaySaleLogin from '../pages/Login';
import PlusPlansReviewAndPay from '../pages/ReviewAndPay';
import PaymentConfirmation from '../pages/PaymentConfirmation';

export const routes = IsFeatureVisible('ENABLE_HOLIDAY_SALE')
  ? [
    {
      type: Public,
      path: ROUTE_HOLIDAY_SALE_AUTH,
      component: HolidaySaleMain,
      children: [
        {
          path: ROUTE_HOLIDAY_SALE_LOGIN,
          component: HolidaySaleLogin,
          exact: true,
        },
      ],
    },
    {
      type: HolidaySale,
      path: ROUTE_HOLIDAY_SALE,
      component: HolidaySaleMain,
      children: [
        {
          path: ROUTE_HOLIDAY_SALE_BUY,
          component: PlusPlansReviewAndPay,
          exact: true,
        },
        {
          path: ROUTE_HOLIDAY_SALE_CONFIRMED,
          component: PaymentConfirmation,
          exact: true,
        },
      ],
    },
  ]
  : [];
