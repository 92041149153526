import { call, takeLatest } from 'redux-saga/effects';

import { fetchReleaseNotes, resource, getError } from 'modules/api';
import { GET_RELEASE_NOTES } from 'modules/dashboard/store/constants';

function* onGetReleaseNotes() {
  try {
    const request = resource(GET_RELEASE_NOTES, fetchReleaseNotes);
    const response = yield call(request);

    return response;
  } catch (error) {
    const response = getError(error);
    // eslint-disable-next-line no-console
    console.error('Error ===>', response);

    return response;
  }
}

export function* watchGetReleaseNotes() {
  yield takeLatest(GET_RELEASE_NOTES, onGetReleaseNotes);
}
