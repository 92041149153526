import { FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';

import { Modal } from 'modules/common/components';
import { Form } from 'antd';
import { PrimaryButton } from 'modules/common/components/UIComponents';
import { notification } from 'modules/common/utils';
import { EditableCell } from './components';
import { useSaveNewLead } from './hooks';
import { NewLead } from '../../types';
import { ImportedLeadsFixProps } from './types';

import S from './styles';

const ImportedLeadsFix: FC<ImportedLeadsFixProps> = ({
  data,
  isOpen,
  setIsOpen,
  selectedSegments,
}) => {
  const [leads, setLeads] = useState<NewLead[]>(data);
  const [selectedFormId, setSelectedFormId] = useState<string>(null);
  const queryClient = useQueryClient();

  const handleDeleteRow = (id: string) => {
    setLeads((currentLeadsState) => currentLeadsState.filter(({ key }) => key !== id));
  };

  const handleSaveNewLeadSuccess = () => {
    notification.success({ title: 'Import Leads', description: 'Lead was saved successfully!' });

    handleDeleteRow(selectedFormId);
    queryClient.invalidateQueries('gettingLeads');
  };

  const handleSaveNewLeadError = (error) => {
    notification.error({
      title: 'Error',
      description: 'Something went wrong. Please try again later.',
    });
    console.error(error);
  };

  const { saveNewLead, isSaveNewLeadLoading } = useSaveNewLead(
    leads,
    handleSaveNewLeadSuccess,
    handleSaveNewLeadError,
  );

  const handleCloseModal = () => setIsOpen(false);

  // Everytime leads state gets updated
  // we will check if we will have any missing lead to be fixed.
  // if we don't, close the modal
  useEffect(() => {
    if (!leads.length) handleCloseModal();
  }, [leads]);

  const handleOnFinishSubForm = (values: NewLead, formId: string) => {
    const isAllSegments = !selectedSegments.length;

    const newLead = { allSegments: isAllSegments, segments: selectedSegments };

    Object.keys(values).forEach((leadField: string) => {
      const field = leadField.split('.')[1];
      const value = values[leadField];
      newLead[field] = value;
    });

    setSelectedFormId(formId);
    saveNewLead(newLead);
  };

  const getLoadingButtonStatus = (formId: string): boolean =>
    selectedFormId === formId && isSaveNewLeadLoading;

  return (
    <Modal
      title="Imported Lead(s)"
      isOpen={isOpen}
      onCancel={handleCloseModal}
      width="1000px"
      footer={null}
    >
      <S.Wrapper>
        <S.HeaderText>
          One or more of your lead(s) has been rejected, please review and fix the details and add
          them again.
        </S.HeaderText>
        <Form.Provider>
          <S.FormWrapper>
            {leads.map((lead) => {
              return (
                <Form
                  key={nanoid()}
                  name={`form_${lead.key}`}
                  onFinish={(values) => handleOnFinishSubForm(values, lead.key)}
                >
                  <S.LeadWrapper>
                    {Object.keys(lead).map((field: 'firstName' | 'lastName' | 'key') => {
                      return field !== 'key' ? (
                        <EditableCell key={nanoid()} lead={lead} field={field} formId={lead.key} />
                      ) : null;
                    })}
                    <PrimaryButton loading={getLoadingButtonStatus(lead.key)} htmlType="submit">
                      Save lead
                    </PrimaryButton>
                    <PrimaryButton onClick={() => handleDeleteRow(lead.key)}>
                      Remove lead
                    </PrimaryButton>
                  </S.LeadWrapper>
                </Form>
              );
            })}
          </S.FormWrapper>
        </Form.Provider>
        <p>All field(s) should not be blank.</p>
        <p>
          <strong>Disclaimer: </strong> Please make sure that you have proper permission from all
          leads you are importing, otherwise their email platform may tag your emails as spam.{' '}
        </p>
      </S.Wrapper>
    </Modal>
  );
};

export default ImportedLeadsFix;
