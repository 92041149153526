import { call, takeEvery } from 'redux-saga/effects';

import { notification } from 'modules/common/utils';
import { resource, generateOrder, getError } from 'modules/api';
import { SUBMIT_ORDER } from 'modules/publish/store/constants';

export function* onSubmitOrder({ payload: meta }) {
  try {
    const request = resource(SUBMIT_ORDER, generateOrder);
    const payload = yield call(request, meta);

    return payload;
  } catch (error) {
    const payload = getError(error);

    notification.error({ description: 'Something went wrong while ordering your book' });

    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetSubmitOrder() {
  yield takeEvery(SUBMIT_ORDER, onSubmitOrder);
}
