import styled from 'styled-components';
import { Layout as LayoutBase } from 'antd';

const Wrapper = styled(LayoutBase.Content)`
  padding: 20px;
  height: calc(100vh - 96px);
`;

const Layout = styled.div`
  display: flex;
  height: 100%;
`;

export default {
  Wrapper,
  Layout,
};
