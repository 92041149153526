import { get } from 'lodash-es';

import { AUTH_AUTO_SIGNIN } from 'modules/auth/store/constants';
import { STATUS_SUCCESS, STATUS_ERROR, STATUS_LOADING } from 'store/status';

import { links as initialState } from 'modules/draft/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${AUTH_AUTO_SIGNIN}_REQUESTED`: {
      return {
        ...state,
        data: null,
        status: STATUS_LOADING,
      };
    }

    case `${AUTH_AUTO_SIGNIN}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${AUTH_AUTO_SIGNIN}_SUCCEEDED`: {
      const data = get(action, ['payload'], null);

      return {
        ...state,
        data,
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
};

export default reducer;
