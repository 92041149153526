type SvgIconProps = {
  [key: string]: string;
};

const ScratchIcon = ({ stroke = '#ff994d', ...props }: SvgIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.927 10.5395L17.3584 8.9296C17.8619 7.05037 18.1137 6.11075 17.9241 5.2976C17.7744 4.65555 17.4376 4.07232 16.9565 3.62164C16.3471 3.05087 15.4074 2.7991 13.5282 2.29556C11.649 1.79203 10.7093 1.54026 9.8962 1.72985C9.25416 1.87956 8.67092 2.21629 8.22024 2.69747C7.73156 3.21923 7.47672 3.98306 7.09749 5.37143C7.0338 5.6046 6.9666 5.85539 6.8942 6.12562L6.89417 6.12573L6.4628 7.7356C5.95926 9.61484 5.70749 10.5545 5.89709 11.3676C6.0468 12.0096 6.38353 12.5929 6.86471 13.0436C7.47411 13.6143 8.41372 13.8661 10.2929 14.3696L10.293 14.3696C11.9868 14.8235 12.9173 15.0728 13.6798 14.9788C13.7633 14.9685 13.8447 14.9541 13.925 14.9353C14.567 14.7856 15.1502 14.4489 15.6009 13.9677C16.1717 13.3583 16.4235 12.4187 16.927 10.5395Z"
      stroke={stroke}
      strokeWidth="1.5"
    />
    <path
      d="M13.6785 14.9784C13.5048 15.5105 13.1993 15.9918 12.7885 16.3766C12.1791 16.9473 11.2395 17.1991 9.36023 17.7026C7.481 18.2062 6.54138 18.4579 5.72824 18.2683C5.08619 18.1186 4.50295 17.7819 4.05228 17.3007C3.48151 16.6913 3.22974 15.7517 2.7262 13.8725L2.29483 12.2626C1.79129 10.3834 1.53952 9.44375 1.72912 8.6306C1.87883 7.98856 2.21556 7.40532 2.69674 6.95464C3.30614 6.38387 4.24576 6.13211 6.12499 5.62857C6.48052 5.5333 6.80242 5.44705 7.09619 5.37109"
      stroke={stroke}
      strokeWidth="1.5"
    />
    <path
      d="M9.81445 8.33325L13.8391 9.41166"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M9.16602 10.748L11.5808 11.3951"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default ScratchIcon;
