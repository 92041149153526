import { useEffect } from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router';

import withStore from 'store/StoreContainer';
import { Empty } from 'modules/common/components';
import { getBookById } from 'modules/editor/store/actions';

const withBookRoute =
  (Component = Empty) =>
    (props) => {
      const { bookId } = useParams();

      useEffect(() => {
        props.getBookById(bookId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [bookId]);

      return <Component {...props} />;
    };

const mapDispatchToProps = {
  getBookById,
};

export default compose(withStore(undefined, mapDispatchToProps), withBookRoute);
