import { useQuery } from 'react-query';
import { groupBy, get } from 'lodash-es';

import { fetchCountryList, fetchStatesByCountryName } from 'modules/api';
import { COUNTRY_CODE } from 'modules/v2/common/constants';

const stateBuilder = (item) => ({ title: item.state, value: item.code, country: item.country });
const countryBuilder = (item) => ({ title: item, value: COUNTRY_CODE[item] });

const useStatesAndCountries = () => {
  const { data: countries } = useQuery(['fetchCountriesList'], fetchCountryList);
  const countriesList = get(countries, 'data.data', []);
  const countryList = countriesList?.map(({ name }) => name);
  const { data: states, isLoading: isStatesLoading } = useQuery(
    ['fetchStateList'],
    () => fetchStatesByCountryName({ country: countryList }),
    { enabled: !!countriesList.length },
  );
  const statesList = get(states, 'data.data', []);

  const mappedState = statesList.map(stateBuilder);
  const groupedState = groupBy(mappedState, 'country');
  const mappedCountries = countryList.map(countryBuilder);

  return { states: groupedState || {}, countries: mappedCountries || {}, isStatesLoading };
};

export default useStatesAndCountries;
