export const TOGGLE_SHOW_GUIDES = 'editor/TOGGLE_SHOW_GUIDES';

// DRAFT LOAD
export const GET_DRAFT_BY_ID = 'editor/GET_DRAFT_BY_ID';
export const GET_BOOK_BY_ID = 'editor/GET_BOOK_BY_ID';
export const CREATE_DRAFT = 'editor/CREATE_DRAFT';
export const RESET_DRAFT = 'editor/RESET_DRAFT';
export const RESET_SECTION = 'editor/RESET_SECTION';

// PREFERENCES
export const UPDATE_PREFERENCES = 'editor/UPDATE_PREFERENCES';
export const UPDATE_PREFERENCE_BY_ELEMENT_ID = 'editor/UPDATE_PREFERENCE_BY_ELEMENT_ID';
export const UPDATE_PREFERENCE_BY_KEY = 'editor/UPDATE_PREFERENCE_BY_KEY';
export const UPDATE_PREFERENCE_STATUS_BY_KEY = 'editor/UPDATE_PREFERENCE_STATUS_BY_KEY';

// MANAGE SECTIONS
export const REORDER_SECTION = 'editor/REORDER_SECTION';
export const ACTIVATE_SECTION = 'editor/ACTIVATE_SECTION';
export const DEACTIVATE_SECTION = 'editor/DEACTIVATE_SECTION';

// EDIT
export const EDIT_ORDERED_DRAFT = 'editor/EDIT_ORDERED_DRAFT';
export const GENERATE_ELEMENT_IMAGE_URL = 'editor/GENERATE_ELEMENT_IMAGE_URL';
export const REPLACE_COVER_IMAGE = 'editor/REPLACE_COVER_IMAGE';
export const UPLOAD_CONTENT_IMAGE = 'editor/UPLOAD_CONTENT_IMAGE';
export const UPLOAD_COVER_IMAGE = 'editor/UPLOAD_COVER_IMAGE';
export const UPDATE_COVER_IMAGE = 'editor/UPDATE_COVER_IMAGE';
export const UPDATE_COVER_ELEMENT = 'editor/UPDATE_COVER_ELEMENT';
export const UPDATE_COVER_ELEMENTS_BY_PREFERENCES = 'editor/UPDATE_COVER_ELEMENTS_BY_PREFERENCES';
export const UPDATE_COVER_ACTIVE_TEMPLATE = 'editor/UPDATE_COVER_ACTIVE_TEMPLATE';
export const UPDATE_CONTENT_ELEMENT = 'editor/UPDATE_CONTENT_ELEMENT';
export const UPDATE_CONTENT_ACTIVE_TEMPLATE = 'editor/UPDATE_CONTENT_ACTIVE_TEMPLATE';

// PREVIEW
export const GET_COVER_PREVIEW = 'editor/GET_COVER_PREVIEW';
// SAVE
export const SAVE_DRAFT = 'editor/SAVE_DRAFT';

export const SAVE_COVER = 'editor/SAVE_COVER';
export const MARK_COVER = 'editor/MARK_COVER';
export const UNMARK_COVER = 'editor/UNMARK_COVER';

export const SAVE_CONTENT = 'editor/SAVE_CONTENT';
export const MARK_CONTENT = 'editor/MARK_CONTENT';
export const UNMARK_CONTENT = 'editor/UNMARK_CONTENT';

export const SAVE_PREFERENCES = 'editor/SAVE_PREFERENCES';
export const MARK_PREFERENCES = 'editor/MARK_PREFERENCES';
export const UNMARK_PREFERENCES = 'editor/UNMARK_PREFERENCES';

export const SAVE_SECTIONS = 'editor/SAVE_SECTIONS';
export const MARK_SECTIONS = 'editor/MARK_SECTIONS';
export const UNMARK_SECTIONS = 'editor/UNMARK_SECTIONS';

// LOADING
export const ADD_LOADING_COVER_ELEMENTS = 'editor/ADD_LOADING_COVER_ELEMENTS';
export const REMOVE_LOADING_COVER_ELEMENTS = 'editor/REMOVE_LOADING_COVER_ELEMENTS';

// EXIT
export const CLEAR_DRAFT_EDITOR = 'editor/CLEAR_DRAFT_EDITOR';

// USER
export const DELETE_USER_IMAGE = 'editor/DELETE_USER_IMAGE';
export const GET_USER_IMAGES = 'editor/GET_USER_IMAGES';
export const GET_CONTENT_IMAGES = 'editor/GET_CONTENT_IMAGES';
export const GET_PRESET_LOGOS = 'editor/GET_PRESET_LOGOS';
