import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import { isDevelopmentEnvironment } from 'modules/core/settings';
import { injectClient, GraphQL } from 'shared/store/middlewares';
import { isFSA } from 'flux-standard-action';

const actionFilter = () => (next) => (action) => {
  if (isDevelopmentEnvironment()) {
    if (!isFSA(action)) {
      const warning = `The action ${action.type} must comply to a flux standard action: https://github.com/redux-utilities/flux-standard-action#actions`;
      // eslint-disable-next-line no-console
      console.warn(warning);
    }

    // Accepts only the pattern module/ACTION_NAME
    const pattern = /^(@@)?([a-z]+\/)?[A-Z_]+$/;

    if (!pattern.test(action.type)) {
      const warning = `The pattern of the action ${action.type} must follow the convention: module/ACTION_NAME`;
      // eslint-disable-next-line no-console
      console.warn(warning);
    }
  }

  return next(action);
};

export default (client, graphql, history) => [
  actionFilter,
  routerMiddleware(history),

  // LEGACY MIDDLEWARES
  injectClient(client),
  GraphQL(graphql),
  thunk,
];
