import { Card as BaseCard } from 'antd';
import styled from 'styled-components';

const CardWrap = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Card = styled(BaseCard)`
  margin-top: 10px;
`;

export default {
  CardWrap,
  Card,
};
