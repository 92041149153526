import { get } from 'lodash-es';

import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';

import {
  DELETE_USER_IMAGE,
  GET_PRESET_LOGOS,
  GET_USER_IMAGES,
  UPLOAD_CONTENT_IMAGE,
} from 'modules/editor/store/constants';
import { user as initialState } from 'modules/editor/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PRESET_LOGOS}_REQUESTED`: {
      return {
        ...state,
        logos: {
          data: null,
          status: STATUS_LOADING,
        },
      };
    }

    case `${GET_USER_IMAGES}_REQUESTED`:
    case `${DELETE_USER_IMAGE}_REQUESTED`: {
      return {
        ...state,
        images: {
          data: null,
          status: STATUS_LOADING,
        },
      };
    }

    case `${GET_PRESET_LOGOS}_FAILED`: {
      return {
        ...state,
        logos: {
          data: null,
          status: STATUS_ERROR,
        },
      };
    }

    case `${GET_USER_IMAGES}_FAILED`:
    case `${DELETE_USER_IMAGE}_FAILED`: {
      return {
        ...state,
        images: {
          data: null,
          status: STATUS_ERROR,
        },
      };
    }

    case `${GET_PRESET_LOGOS}_SUCCEEDED`: {
      const data = get(action, ['payload', 'files'], null);

      return {
        ...state,
        logos: {
          status: STATUS_SUCCESS,
          data,
        },
      };
    }

    case `${GET_USER_IMAGES}_SUCCEEDED`:
    case `${DELETE_USER_IMAGE}_SUCCEEDED`: {
      const data = get(action, ['payload'], null);

      return {
        ...state,
        images: {
          status: STATUS_SUCCESS,
          data,
        },
      };
    }

    case `${UPLOAD_CONTENT_IMAGE}_REQUESTED`: {
      const images = get(state, ['images'], null);

      return {
        ...state,
        images: {
          ...images,
          upload: {
            data: null,
            status: STATUS_LOADING,
          },
        },
      };
    }
    case `${UPLOAD_CONTENT_IMAGE}_SUCCEEDED`: {
      const images = get(state, ['images'], null);
      const data = get(action, ['payload'], null);

      return {
        ...state,
        images: {
          ...images,
          upload: {
            data,
            status: STATUS_SUCCESS,
          },
        },
      };
    }
    case `${UPLOAD_CONTENT_IMAGE}_FAILED`: {
      const images = get(state, ['images'], null);

      return {
        ...state,
        images: {
          ...images,
          upload: {
            status: STATUS_ERROR,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
