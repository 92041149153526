import withStore from 'store/StoreContainer';

import {
  goToEditDraftContent,
  saveDraft,
  reorderSection,
  activateSection,
  deactivateSection,
  layoutSelector,
  draftIdSelector,
  sortedSectionsSelector,
  dropdownSectionsSelector,
  toolbarSelector,
  previewSelector,
  manageSectionsManuscriptSelector,
  manageSectionsContentSelector,
} from 'modules/v2/editor/store';

const mapStateToProps = (state) => ({
  layout: layoutSelector(state),
  draftId: draftIdSelector(state),
  sections: sortedSectionsSelector(state),
  toolbar: toolbarSelector(state),
  dropdown: dropdownSectionsSelector(state),
  preview: previewSelector(state),
  manuscript: manageSectionsManuscriptSelector(state),
  content: manageSectionsContentSelector(state),
});

const mapDispatchToProps = {
  goToEditDraftContent,
  saveDraft,
  reorderSection,
  activateSection,
  deactivateSection,
};

export default withStore(mapStateToProps, mapDispatchToProps);
