import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .ant-radio-wrapper {
    align-items: center;
  }
  .ant-radio {
    margin-right: 8px;
  }
`;

const CardImg = styled.img`
  width: 60px;
  height: 40px;
`;

const CreditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

const Credit = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const CardName = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const DefaultCard = styled.div`
  padding: 3px 15px;
  border: 1px solid #1890ff;
  border-radius: 15px;
  background-color: #1890ff;
  color: #fff;
`;

const ExpiredCard = styled.div`
  padding: 3px 15px;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  background-color: #e8e8e8;
  color: #fff;
`;

export default {
  Container,
  CreditWrapper,
  Credit,
  CardWrapper,
  Description,
  CardImg,
  CardName,
  DefaultCard,
  ExpiredCard,
};
