import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import * as color from 'modules/common/theme/color';

const NonLink = styled.button`
  display: inline-block;
  width: 40px;
  height: 36px;
  padding: 0 !important;
  line-height: 32px !important;
  border: 1px solid ${color.GreyDivider};
  text-align: center;
  background-color: ${color.White};
  font-size: 14px;
  font-weight: 600;
  touch-action: manipulation;
  outline: none;
  cursor: pointer;
  color: ${color.UserMenuText};
  vertical-align: middle;
  border-radius: 6px;

  &:hover {
    border: 1px solid ${color.Black};
    color: ${color.UserMenuText};
    background-color: ${color.GreyMenuItemHover};
  }
`;

const Link = styled(NonLink.withComponent(RouterLink))``;

export default {
  Link,
  NonLink,
};
