import { useState } from 'react';
import { BookPreview as IBookPreview } from 'modules/v2/pages/admin/BookPreviews/types';
import { Modal } from 'modules/v2/common/components';
import { Iframe } from 'modules/v2/draft/components';
import { BookList } from 'modules/v2/components';
import { LightningBoltIcon } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { useHistory } from 'react-router-dom';
import { getRoutePlansAndBillings } from 'modules/v2/routes/navigation';

import { Book } from '../../types';
import S from './styles';

const BookPreview = ({
  book,
  linkUrl,
  currentBookPreview,
}: {
  book: Book;
  linkUrl: string;
  currentBookPreview: IBookPreview;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name: bookName, title: bookTitle, thumbnail: bookThumbnail, isAllowed } = book;
  const history = useHistory();

  if (!bookName) return null;

  const handlePreviewOpen = () => setIsOpen(true);
  const handlePreviewClose = () => setIsOpen(false);

  const iframeStyle = {
    borderRadius: '6px',
    overflow: 'hidden',
  };

  const getPreviewWrapper =
    currentBookPreview && !isAllowed ? (
      <>
        <S.PreviewWrapper onClick={handlePreviewOpen} />
        <Modal
          title="Unlock this book cover"
          isOpen={isOpen}
          width="1100px"
          wrapClassName="transparent-bg"
          hasCloseButton
          onCancel={handlePreviewClose}
          footer={false}
        >
          <S.PreviewModalBody>
            <S.IframeContainer>
              <Iframe
                height="500px"
                width="740px"
                style={iframeStyle}
                src={currentBookPreview?.pdfUrl}
                allowFullscreen
              />
            </S.IframeContainer>

            <S.UpgradePlanContainer>
              <S.IconContainer>
                <LightningBoltIcon />
              </S.IconContainer>
              <S.UpgradeHeading>Upgrade your plan</S.UpgradeHeading>
              <S.UpgradeText>
                Upgrade to unlock this book template but also 25 more. Effortlessly customize it to
                suit your needs. Showcase your expertise, generate leads, nurture client
                relationships, and much more.
              </S.UpgradeText>
              <Button onClick={() => history.push(getRoutePlansAndBillings())}>
                <div className="w-[16px] h-[16px] mr-2">
                  <LightningBoltIcon height="100%" width="100%" fill="#252525" />
                </div>
                Upgrade plan
              </Button>
            </S.UpgradePlanContainer>
          </S.PreviewModalBody>
        </Modal>
      </>
    ) : null;

  return (
    <S.Preview>
      {getPreviewWrapper}
      <BookList>
        <BookList.BookCard
          name={bookName}
          title={bookTitle}
          image={bookThumbnail?.url}
          to={linkUrl}
          isAllowed={isAllowed}
        />
      </BookList>
    </S.Preview>
  );
};

export default BookPreview;
