import { call, put, takeEvery } from 'redux-saga/effects';

import { getError, resource, upsertUserAddress } from 'modules/api';

import { EDIT_USER_ADDRESS } from 'modules/dashboard/store/constants';
import { getUserAddresses } from 'modules/dashboard/store/actions';

export function* onEditUserAddress({ payload: meta }) {
  try {
    const request = resource(EDIT_USER_ADDRESS, upsertUserAddress);
    const payload = yield call(request, meta);

    yield put(getUserAddresses());

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchEditAddress() {
  yield takeEvery(EDIT_USER_ADDRESS, onEditUserAddress);
}
