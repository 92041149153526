import { useState, useMemo } from 'react';
import {
  getBookById,
  getDeviceWidthAndSetValue,
  handleNameBottom,
  handlePreviewImageChange,
} from 'modules/previewBook/utils';
import { Heading } from '../UIComponents';
import S from './styles';

const SelectedBookPreview = ({ uploadedImage, selectedBookId, authorName }) => {
  const [showFrontCover, setShowFrontCover] = useState(true);
  const selectedBook = useMemo(() => getBookById(selectedBookId), [selectedBookId]);

  const selectedImageStyle = useMemo(
    () => handlePreviewImageChange(selectedBookId, showFrontCover),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBookId, showFrontCover],
  );

  const selectedImageNameBottom = useMemo(
    () => handleNameBottom(selectedBookId, showFrontCover),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBookId, showFrontCover],
  );

  const handleCoverChange = () => setShowFrontCover(!showFrontCover);
  return (
    <S.PreviewBook>
      <Heading paddingLeftReq={30} className="center-heading">
        {getDeviceWidthAndSetValue('Customize Your Book Preview', 'Preview', 'Preview')}
      </Heading>
      <S.CoverWrap>
        <S.UploadedImageWrapper>
          {uploadedImage ? (
            <S.UserUploadedImage src={uploadedImage} alt="user-avatar" style={selectedImageStyle} />
          ) : null}
          <S.BookImage src={showFrontCover ? selectedBook.front : selectedBook.back} />
          {showFrontCover ? (
            <>
              <S.AuthorNameBottom style={selectedImageNameBottom}>{authorName}</S.AuthorNameBottom>
              <S.AuthorNameLeft>{authorName}</S.AuthorNameLeft>
            </>
          ) : null}
        </S.UploadedImageWrapper>
      </S.CoverWrap>
      <S.BookText>{selectedBook.text}</S.BookText>
      <S.BookViewBack onClick={handleCoverChange}>
        View {showFrontCover ? 'Back' : 'Front'} Cover
      </S.BookViewBack>
    </S.PreviewBook>
  );
};

export default SelectedBookPreview;
