import { flatten, get, map, groupBy, mapValues } from 'lodash-es';
import { getPayload } from 'modules/api';

export const getCreditsPayload = (response) => {
  const payload = getPayload(response);
  return get(payload, 'credits');
};

export const getCountriesPayload = (response) => {
  const payload = getPayload(response);
  return map(payload, (country) => get(country, 'name'));
};

export const combineStatesPayload = (payload) => {
  const countryMapper = ({ country }) => country;
  const stateMapper = ({ state }) => state;

  const states = map(payload, getPayload);
  const flattened = flatten(states);

  const grouped = groupBy(flattened, countryMapper);

  return mapValues(grouped, (value) => map(value, stateMapper));
};
