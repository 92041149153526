import styled from 'styled-components';

const ImageWrapper = styled.div`
  width: 130px;
  height: 170px;
  display: flex;
  margin: 0px 0px 10px 0px;
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  overflow: auto;
`;

const TextCenter = styled.p`
  text-align: center;
`;

const MagazineContainer = styled.div`
  margin: 0px 37px 40px 0px;
  cursor: pointer;
`;

export default {
  GalleryContainer,
  ImageWrapper,
  MagazineContainer,
  TextCenter,
  UploadedImage,
};
