import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 212px);
  column-gap: 16px;
  justify-content: space-between;
  row-gap: 15px;
  overflow: hidden;
`;

export default { Wrapper };
