import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import classNames from 'classnames';
import {
  getDefaultPaymentProfile,
  getPaymentProfilesList,
  setDefaultPaymentProfile,
} from 'modules/api';
import { CreditCardImages, formatCreditCardNumber } from 'modules/v2/utils';
import { Button, ClosableModal, Notification, Radio } from 'modules/v2/common/AtomicDesign/atoms';
import { WarnMessage } from 'modules/v2/common/AtomicDesign/molecules';
import { AddCardModal } from 'modules/v2/common/AtomicDesign/organisms';
import { AddCircleIcon, DangerTriangleIcon } from 'modules/v2/common/components/SvgIcon';

interface ChangeDefaultPaymentModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  invalidateQueries?: boolean;
  isDefault?: boolean;
}

const defaultProps: Partial<ChangeDefaultPaymentModalProps> = {
  invalidateQueries: true,
  isDefault: false,
};

const ChangeDefaultPaymentModal = ({
  showModal,
  setShowModal,
  invalidateQueries,
  isDefault,
}: ChangeDefaultPaymentModalProps) => {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [card, setCard] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const queryClient = useQueryClient();

  const { data: paymentData = [] } = useQuery('getPaymentProfilesList', getPaymentProfilesList);

  const { data: defaultProfile } = useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile);

  const profiles = paymentData?.filter((item) => item.id !== defaultProfile?.id);

  const { mutate: mutateSetCardDefault } = useMutation(
    ['setDefaultPaymentProfile'],
    setDefaultPaymentProfile,
    {
      onSuccess: async () => {
        if (invalidateQueries) {
          queryClient.invalidateQueries('getDefaultPaymentProfile');
          queryClient.invalidateQueries('getPaymentProfilesList');
        }
        setCard(selectedPayment);
        setShowNotification(true);
        setSelectedPayment(null);
      },
    },
  );

  useEffect(() => {
    let timer;
    if (showNotification) {
      timer = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showNotification]);

  const cardNumber = card?.masked_card_number;
  const cardType = card?.card_type;

  const handleSetDefault = () => {
    mutateSetCardDefault(selectedPayment.id);
    setShowModal(false);
  };

  const handleAddNewPaymentMethod = () => {
    setShowAddCardModal(true);
    setShowModal(false);
  };

  const footerModal = (
    <Button onClick={() => handleSetDefault()} disabled={selectedPayment === null} full>
      Save changes
    </Button>
  );

  return (
    <>
      <ClosableModal
        title="Change default payment method"
        showModal={showModal}
        setShowModal={setShowModal}
        footer={footerModal}
      >
        <div className="flex flex-col gap-[18px]">
          {isDefault && (
            <WarnMessage type="error">
              <div className="flex gap-3">
                <div className="w-4 h-4">
                  <DangerTriangleIcon width={20} height={20} />
                </div>
                <div>
                  This card is used as your default payment. To delete this card, select or add new
                  payment.
                </div>
              </div>
            </WarnMessage>
          )}
          <fieldset className="flex flex-col gap-2">
            {profiles.map((item) => {
              if (!item) return null;
              return (
                <div
                  key={item.id}
                  className={classNames(
                    'flex items-center rounded-md p-3.5 font-semibold',
                    selectedPayment?.id === item.id
                      ? 'border-2 border-primary-500'
                      : 'border border-tableBorder',
                  )}
                >
                  <Radio
                    id={item.id}
                    name="cards"
                    value={item}
                    onChange={() => setSelectedPayment(item)}
                    className={classNames(
                      selectedPayment?.id === item.id && 'border-4 border-primary-500',
                    )}
                  />
                  <div className="flex items-center gap-2">
                    <div className="w- px-6 ">
                      <img
                        src={CreditCardImages[item?.card_type]}
                        alt={item?.card_type}
                        className="w-[46px] h-[32px]"
                      />
                    </div>
                    <div className="w- ">
                      {formatCreditCardNumber(item.masked_card_number, false)}
                    </div>
                  </div>
                </div>
              );
            })}
          </fieldset>
          <button
            type="button"
            className="mb-8 flex items-center gap-2 p-0 font-semibold cursor-pointer"
            onClick={() => handleAddNewPaymentMethod()}
            color="outined"
          >
            <AddCircleIcon />
            <span>Add new payment method</span>
          </button>
        </div>
      </ClosableModal>
      {showNotification && (
        <Notification
          description={`Your default card has been changed to ${cardType?.toUpperCase()} ${cardNumber.slice(
            -4,
          )}`}
        />
      )}
      {showAddCardModal && (
        <AddCardModal showModal={showAddCardModal} setShowModal={setShowAddCardModal} />
      )}
    </>
  );
};

ChangeDefaultPaymentModal.defaultProps = defaultProps;

export default ChangeDefaultPaymentModal;
