import { call, takeEvery } from 'redux-saga/effects';

import { resource, fetchUserImages, getError } from 'modules/api';

import { GET_USER_IMAGES } from 'modules/editor/store/constants';

import { mapImageUrlsPayload } from './utils';

export function* onGetUserImages({ payload: meta }) {
  try {
    const request = resource(GET_USER_IMAGES, fetchUserImages, mapImageUrlsPayload);
    const payload = yield call(request, meta);

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetUserImages() {
  yield takeEvery(GET_USER_IMAGES, onGetUserImages);
}
