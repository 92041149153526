import getInstance from './instance';

const instance = getInstance();

export const getLandingStats = (startDate, endDate) => {
  const params = { startDate, endDate };
  const url = 'landing/stats';

  return instance.get(url, { params });
};

export const getReport = (search, sort) => {
  const url = `landing/report?q=${search}&sort=${sort}&size=200`;

  return instance.get(url);
};

export const downloadReports = () => {
  const downloadUrl = 'landing/report/export';
  return instance.get(downloadUrl, {
    responseType: 'blob',
  });
};
