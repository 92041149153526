import { compose } from 'redux';
import { get } from 'lodash-es';

import { withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';
import { DashboardNavBar } from 'modules/v2/components';
import env from 'environments';

const SocialMediaTrainingSite = ({ avatar, profile, signOut }) => {
  const { email, firstName } = get(profile, 'data', {});
  const socialMediaTrainingSiteUrl = `${env.SOCIAL_MEDIA_TRAINING_URL}`;

  return (
    <>
      <DashboardNavBar email={email} avatar={avatar} username={firstName} onLogout={signOut} />
      <iframe
        src={socialMediaTrainingSiteUrl}
        title="Training site"
        style={{ marginTop: '5%', height: '80vh' }}
        width="100%"
      />
    </>
  );
};
export default compose(withProfile, withAuth)(SocialMediaTrainingSite);
