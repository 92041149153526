import { Typography } from 'antd';
import styled from 'styled-components';

import * as color from 'modules/v2/common/theme/color';

const AddPaymentMethod = styled.a`
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  color: ${color.InputFocusBorder};
  text-decoration: underline !important;
  margin-bottom: 40px;
  display: inline-block;
`;

const CardTitle = styled(Typography.Text)`
  font-size: 16px;
  font-weight: bolder;
`;

export default { AddPaymentMethod, CardTitle };
