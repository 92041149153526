import { includes } from 'lodash-es';

import {
  AVATAR_MIN_WIDTH,
  AVATAR_MIN_HEIGHT,
  loadImageDetails,
} from 'modules/common/components/FilePicker/utils';

const getValidationSchema = (imageType) => {
  switch (imageType) {
    case 'background':
      return {
        fileSize: {
          value: 4000000,
          message: 'File size must not exceed 4 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          minWidth: 2000,
          minHeight: 2000,
          message: 'Image dimensions must be greater than 2000px',
        },
      };
    case 'preview-book-image':
      return {
        fileSize: {
          value: 15000000,
          message: 'File size must not exceed 15 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          maxWidth: '100%',
          maxHeight: '100%',
        },
      };

    case 'profile':
      return {
        fileSize: {
          value: 2000000,
          message: 'File size must not exceed 2 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          minWidth: AVATAR_MIN_WIDTH,
          minHeight: AVATAR_MIN_HEIGHT,
          message: 'Image dimensions must be greater than 500px',
        },
      };

    case 'rm_profile':
      return {
        fileSize: {
          value: 2000000,
          message: 'File size must not exceed 2 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          maxWidth: 540,
          maxHeight: 200,
          message: 'Image dimensions must be 520 x 520',
        },
        allowedResolutions: {
          width: 520,
          height: 520,
        },
      };

    case 'brokerageLogo':
      return {
        fileSize: {
          value: 5000000,
          message: 'File size must not exceed 5 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          maxWidth: 540,
          maxHeight: 200,
          message: 'Image dimensions must be 540px x 192px',
        },
        allowedResolutions: {
          width: 540,
          height: 192,
        },
      };

    case 'backInsideCoverImageOption3':
    case 'backInsideCoverImageOption4':
      return {
        fileSize: {
          value: 5000000,
          message: 'File size must not exceed 5 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          maxWidth: 1440,
          maxHeight: 960,
          message: 'Image dimensions must be 1440 x 960',
        },
        allowedResolutions: {
          width: 1440,
          height: 960,
        },
      };

    case 'backCoverImageOption4':
    case 'backCoverImageOption5':
      return {
        fileSize: {
          value: 5000000,
          message: 'File size must not exceed 5 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          maxWidth: 960,
          maxHeight: 750,
          message: 'Image dimensions must be 960 x 750',
        },
        allowedResolutions: {
          width: 960,
          height: 750,
        },
      };
    case 'backInsideCoverImageOption6':
      return {
        fileSize: {
          value: 5000000,
          message: 'File size must not exceed 5 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          maxWidth: 960,
          maxHeight: 672,
          message: 'Image dimensions must be 960 x 672',
        },
        allowedResolutions: {
          width: 960,
          height: 672,
        },
      };

    case 'backCoverImageOption3':
      return {
        fileSize: {
          value: 5000000,
          message: 'File size must not exceed 5 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          maxWidth: 600,
          maxHeight: 960,
          message: 'Image dimensions must be 600 x 960',
        },
        allowedResolutions: {
          width: 600,
          height: 960,
        },
      };

    default:
      return {
        fileSize: {
          value: 2000000,
          message: 'File size must not exceed 2 MB.',
        },
        mimeTypes: {
          value: ['image/jpeg', 'image/png'],
          message: 'Invalid file type selected.',
        },
        dimensions: {
          minWidth: AVATAR_MIN_WIDTH,
          minHeight: AVATAR_MIN_HEIGHT,
          message: 'Image dimensions must be greater than 500px',
        },
      };
  }
};

const difference = (num1, num2) => {
  return Math.abs(num1 - num2);
};

const includesXY = (allowedResolutions, width, height) => {
  const widthDiff = difference(allowedResolutions.width, width);
  const heightDiff = difference(allowedResolutions.height, height);
  return {
    widthDiff,
    heightDiff,
  };
};

export const validateImage = async (file, mimeTypes, imageType = 'default') => {
  const validationSchema = getValidationSchema(imageType);

  const mime = mimeTypes || validationSchema.mimeTypes.value;
  const { type, size } = file;

  if (size > validationSchema.fileSize.value) {
    throw new Error(validationSchema.fileSize.message);
  }

  if (!includes(mime, type)) {
    throw new Error(validationSchema.mimeTypes.message);
  }

  try {
    const image = await loadImageDetails(file);
    const { width, height } = image;

    if (imageType === 'preview-book-image') {
      if (
        width > validationSchema.dimensions.maxWidth ||
        height > validationSchema.dimensions.minHeight
      ) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    if (imageType === 'rm_profile' || imageType === 'default') {
      if (
        width < validationSchema.dimensions.minWidth ||
        height < validationSchema.dimensions.minHeight
      ) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    return image;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const validateImageURL = async (width, height, imageType) => {
  const validationSchema = getValidationSchema(imageType);

  try {
    if (imageType === 'preview-book-image') {
      if (
        width > validationSchema.dimensions.maxWidth ||
        height > validationSchema.dimensions.minHeight
      ) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    if (
      imageType === 'rm_profile' ||
      imageType === 'brokerageLogo' ||
      imageType === 'backInsideCoverImageOption3' ||
      imageType === 'backInsideCoverImageOption4' ||
      imageType === 'backInsideCoverImageOption6' ||
      imageType === 'backCoverImageOption4' ||
      imageType === 'backCoverImageOption5' ||
      imageType === 'backCoverImageOption3'
    ) {
      const dimensionsDiff = includesXY(validationSchema.allowedResolutions, width, height);

      if (dimensionsDiff.widthDiff >= 50 || dimensionsDiff.heightDiff >= 50) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    if (imageType === 'profile' || imageType === 'default') {
      if (
        width < validationSchema.dimensions.minWidth ||
        height < validationSchema.dimensions.minHeight
      ) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};
