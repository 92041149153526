const AuthorifyLoadingIcon = ({ fill = '#70D4E7' }) => {
  return <svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4912 30.8727C11.6011 31.253 12.7846 31.451 14.0002 31.451C15.2072 31.451 16.382 31.2546 17.4833 30.8837V29.7893L10.4912 29.7791V30.8727Z"
      fill={fill} />
    <path
      d="M14.5466 13.3694L14.5356 19.7686C14.9241 19.8775 15.2735 20.0882 15.5421 20.3959L15.6165 20.4835L15.6925 20.5774L15.7254 20.6381C15.7622 20.6997 15.7928 20.762 15.8233 20.8267L15.8828 20.9466L15.8954 20.9924C16.1852 21.8043 15.8625 22.7251 15.0495 23.2033C14.0955 23.7604 12.8383 23.4889 12.2415 22.6005C11.6431 21.7081 11.9321 20.534 12.8861 19.9777C13.0725 19.8664 13.2707 19.7994 13.4736 19.7504L13.4853 13.3678L12.5681 13.3655C11.8796 17.1908 9.98965 19.2131 7.54199 21.7649L10.4925 29.047C16.5987 29.0557 10.9186 29.047 17.4854 29.0572L20.4625 21.7862C17.7869 18.9219 16.276 17.0472 15.4669 13.3702L14.5466 13.3694Z"
      fill={fill} />
    <path
      d="M24.9 14.4985L14.0002 0.5L3.09972 14.4985C-0.828282 19.5436 -0.251809 26.406 4.46806 30.8175C7.09509 33.273 10.5477 34.5 13.9994 34.5C17.452 34.5 20.903 33.273 23.5316 30.8175C28.2515 26.406 28.8287 19.5436 24.9 14.4985ZM21.7528 29.155C20.5325 30.2952 19.0741 31.1316 17.4833 31.6279C16.3743 31.9751 15.2033 32.1557 14.0002 32.1557C12.7893 32.1557 11.6082 31.9703 10.4913 31.6192C8.90988 31.1237 7.46008 30.2889 6.24761 29.155C2.40733 25.568 1.93973 19.9847 5.13383 15.8817L14.001 4.49415L22.8682 15.8817C26.0615 19.9847 25.5931 25.5688 21.7528 29.155Z"
      fill={fill} />
  </svg>;

};

export default AuthorifyLoadingIcon;
