import { get } from 'lodash-es';

import { leads as initialState } from 'modules/v2/store/reducers/initialState';
import { SET_SELECTED_LEADS, SET_UNSELECTED_LEADS } from 'modules/v2/store/constants';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_LEADS: {
      return {
        ...state,
        selectedLeads: get(action, ['payload', 'selectedLeads'], []),
      };
    }

    case SET_UNSELECTED_LEADS: {
      return {
        ...state,
        unselectedLeads: get(action, ['payload', 'unselectedLeads'], []),
      };
    }

    default:
      return state;
  }
};

export default reducer;
