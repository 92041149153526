/* eslint-disable react/require-default-props */
import { RoundArrowDown, RoundArrowUp } from 'modules/v2/common/components/SvgIcon';
import S from './styles';

const StatCard = ({ item, hasBorderRight }) => {
  const { statTitle, subText, rateValue, total, isLoading, color, positiveMetric } = item;

  let iconColor = '#818181';
  if (rateValue < 0) iconColor = '#E75A50';
  if (rateValue > 0) iconColor = '#069668';

  const rateIcon = () => {
    if (!rateValue) return null;

    const icon =
      rateValue < 0 ? <RoundArrowDown fill={iconColor} /> : <RoundArrowUp fill={iconColor} />;
    const roundedRateValue = Math.round(rateValue);

    return (
      <div className="flex gap-1">
        <div className={`rotate-${positiveMetric && roundedRateValue > 0 ? 180 : 0}`}>{icon}</div>
        <span className={`text-${iconColor} text-xs font-semibold`}>{roundedRateValue}%</span>
      </div>
    );
  };

  return (
    <S.CardRadius loading={isLoading} bordered={false} hasBorderRight={hasBorderRight}>
      {isLoading ? (
        <>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
        </>
      ) : (
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center gap-1">
              <S.BallIcon color={color} />
              <span className="text-sm font-medium">{statTitle}</span>
            </div>
            <p className="text-xs text-neutral-400 font-medium">{subText}</p>
          </div>
          <div className="flex flex-col items-end">
            <p className="text-xl font-bold">{total}</p>
            {rateIcon()}
          </div>
        </div>
      )}
    </S.CardRadius>
  );
};

export default StatCard;
