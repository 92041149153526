import { useState, useMemo } from 'react';

import { get, map } from 'lodash-es';
import { useQuery, useMutation } from 'react-query';
import { compose } from 'redux';

import { allocateComponent, getPaymentProfiles } from 'modules/api/digitalServices';
import { Loader, NoContent, PrimaryButton, SecondaryButton } from 'modules/v2/common/components';
import { notification } from 'modules/v2/common/utils';
import { BillingSideNav, PaymentMethods } from 'modules/v2/components';
import { withProfile } from 'modules/v2/containers';
import { ROUTE_PLAN_CONFIRMATION } from 'modules/v2/routes/constants';
import { DashboardTemplate } from 'modules/v2/templates';

import FeaturesList from './components/FeaturesList';
import UpgradeTable from './components/UpgradeTable';
import S from './styles';
import { FEATURES, intervalType, LOADER, planType } from '../../constants';
import { generatePlanName, getLicensedBooks, getPrintedBooks, splitFirstWord } from '../../utils';

const UpgradePlan = ({ location, history }) => {
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const {
    planData = {},
    isYearly,
    currentPlan,
    selectedPlan,
    featuresInfo,
  } = location?.state || {};
  const {
    licensedBooks: selectedLicensedBooks = 0,
    printedBooks: selectedPrintedBooks = 0,
    saveAmount,
    amount,
    operation,
    planName: selectedPlanName,
  } = selectedPlan || {};
  const { component } = currentPlan || {};
  const currentPlanAmount = get(component, 'unitPrice');
  const currentPlanName = generatePlanName(component?.name, Number(currentPlanAmount).toFixed(2));

  const licensedBooks = getLicensedBooks(featuresInfo?.licensedBooks, selectedLicensedBooks)[
    operation
  ];
  const printedBooks = getPrintedBooks(
    splitFirstWord(featuresInfo?.printedBooks),
    splitFirstWord(selectedPrintedBooks),
    isYearly,
  )[operation];
  const selectedInterval = isYearly ? intervalType.ANNUALLY : intervalType.MONTHLY;

  const { mutate, isLoading } = useMutation('allocateComponent', allocateComponent);

  const onUpgrade = () => {
    setIsUpgrade(true);
    const payload = {
      subscriptionId: currentPlan.id,
      newComponentId: Number(selectedPlan.priceId),
    };
    mutate(payload, {
      onSuccess: (data) => {
        if (data?.component_id) {
          setIsUpgrade(false);
          history.push(ROUTE_PLAN_CONFIRMATION, {
            data,
            selectedPlan,
          });
        }
      },
      onError: (error) => {
        notification.error({
          description: error?.response?.data.errors[0] || error?.response?.data?.message,
        });
      },
    });
  };

  const structurePaymentProfileResponse = (paymentProfilesList = []) => {
    return map(paymentProfilesList, (paymentProfile) => {
      const {
        id,
        card_type: brand,
        expiration_month: expMonth,
        expiration_year: expYear,
        masked_card_number: cardNumber,
      } = get(paymentProfile, 'payment_profile', {});
      const last4 = cardNumber?.substr(cardNumber.length - 4);
      return { id, brand, expMonth, expYear, last4 };
    });
  };

  const {
    data: paymentMethods = [],
    isFetching: isGetPaymentMethodsLoading,
    isError,
  } = useQuery(['gettingPaymentMethods'], getPaymentProfiles, {
    onError: (error) => {
      notification.error({
        description: error.response.data.errors?.[0] || error?.data?.message || 'Network Error',
      });
    },
  });

  const hasData = paymentMethods && !isGetPaymentMethodsLoading;
  const { paymentsProfiles = [] } = useMemo(() => {
    if (hasData) {
      const filteredProfiles = paymentMethods.filter(
        (method) => get(method, 'payment_profile.payment_type') === 'credit_card',
      );
      return {
        paymentsProfiles: structurePaymentProfileResponse(filteredProfiles),
      };
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData]);

  const loaderTitle = isGetPaymentMethodsLoading
    ? 'Fetching payment methods'
    : LOADER[operation]?.title;

  const loaderDescription = isGetPaymentMethodsLoading
    ? 'Fetching payment methods'
    : LOADER[operation]?.description;

  return (
    <DashboardTemplate sidebar={<BillingSideNav />} hasSidebar>
      {isUpgrade || isGetPaymentMethodsLoading ? (
        <Loader title={loaderTitle} description={loaderDescription} />
      ) : (
        <S.MainContain>
          <S.TopWrap>
            <div>
              <S.Title>{planType[operation]} Plan</S.Title>
              <S.Description>
                Current Plan: <b>{currentPlanName}</b>
              </S.Description>
            </div>
          </S.TopWrap>
          <UpgradeTable
            planData={planData}
            planName={selectedPlanName}
            isYearly={isYearly}
            price={amount}
            saveAmount={saveAmount}
          />
          <FeaturesList
            saveAmount={saveAmount}
            licensedBooks={licensedBooks}
            printedBooks={printedBooks}
            interval={selectedInterval}
            feature={FEATURES[operation]}
          />
          <S.Title>Payment Methods</S.Title>
          {isError ? (
            <NoContent message="No Data Available" />
          ) : (
            <PaymentMethods
              checkbox
              paymentMethods={paymentsProfiles}
              selectedMethod={paymentMethod}
              cardClick={(e) => setPaymentMethod(e.target.value)}
            />
          )}
          <S.ButtonsWrap>
            <SecondaryButton onClick={() => history.goBack()}>Cancel</SecondaryButton>
            <PrimaryButton
              disabled={isLoading || !paymentMethod}
              onClick={() => onUpgrade()}
            >{`${planType[operation]} Plan`}</PrimaryButton>
          </S.ButtonsWrap>
        </S.MainContain>
      )}
    </DashboardTemplate>
  );
};

export default compose(withProfile)(UpgradePlan);
