//

import { ClosePublishIcon, SaveIcon } from 'modules/common/components/SvgIcon';

import S from './styles';

const CriteriaItem = ({ checked, text, onEdit }) => {
  const icon = checked ? <SaveIcon /> : <ClosePublishIcon />;

  return (
    <S.ListItem checked={checked}>
      <S.ItemIcon checked={checked}>{icon}</S.ItemIcon>
      {text}

      {!checked && (
        <S.EditButton type="link" className="editLink" onClick={onEdit}>
          Edit
        </S.EditButton>
      )}
    </S.ListItem>
  );
};

export default CriteriaItem;
