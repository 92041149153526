import { ReactNode } from 'react';
import { Header, Footer } from '../../landingPages/components';
import S from './styles';

const HolidaySaleRoute = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <S.MainLayout>
      <Header />
      <S.Content>{children}</S.Content>
      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>
    </S.MainLayout>
  );
};

export default HolidaySaleRoute;
