import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  address: Yup.string().trim().required('Please enter your Shipping Address'),
  country: Yup.string().trim().required('Please select your Country'),
  state: Yup.string().trim().required('Please select your State'),
  city: Yup.string().trim().required('Please enter your City'),
  zip: Yup.string().trim().required('Please enter your Postal Code'),
});

export default validationSchema;
