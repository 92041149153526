import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Content = styled.div`
  background: ${color.White};
  width: 100%;
`;

const DetailsSection = styled.div`
  .ApproverDetailsSideBar {
    background: ${color.lightBackground};
    width: 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  h2 {
    color: ${color.Black};
    text-align: center;
    font-size: 22px;
  }
  p {
    font-size: 16px;
    color: ${color.LightHeadGray};
  }
`;

const Wrapper = styled.div`
  .ApproverDetailsHeaderMenu div div:nth-child(1) {
    left: 0;
    z-index: 1;
  }
`;

const EmptyColumn = styled.div`
  padding: 15px 0px;
  background-color: ${color.White};
`;

const ModalBoxFooter = styled.div`
  .exitWithOutSave {
    float: left;
  }
  .saveExit {
    background: ${color.LightHeadGray};
    color: ${color.Black};
  }
`;

export default {
  EmptyColumn,
  Content,
  DetailsSection,
  Wrapper,
  ModalContent,
  ModalBoxFooter,
};
