const ValidIcon = ({ fill = '#069668', ...props }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5 13C25.5 19.9036 19.9036 25.5 13 25.5C6.09644 25.5 0.5 19.9036 0.5 13C0.5 6.09644 6.09644 0.5 13 0.5C19.9036 0.5 25.5 6.09644 25.5 13ZM18.0379 9.21209C18.404 9.5782 18.404 10.1718 18.0379 10.5379L11.7879 16.7879C11.4218 17.154 10.8282 17.154 10.4621 16.7879L7.96209 14.2879C7.59597 13.9218 7.59597 13.3282 7.96209 12.9621C8.3282 12.596 8.9218 12.596 9.28791 12.9621L11.125 14.7992L13.9185 12.0056L16.7121 9.21209C17.0782 8.84597 17.6718 8.84597 18.0379 9.21209Z"
        fill={fill}
      />
    </svg>
  );
};

export default ValidIcon;
