import styled from 'styled-components';
import { Collapse as BaseCollapse } from 'antd';

import * as color from 'modules/v2/common/theme/color';

const Collapse = styled(BaseCollapse)`
  border-top: 1px solid ${color.GrayBorder};

  .ant-collapse-item {
    border: none !important;
  }

  &.ant-collapse-borderless {
    background: none !important;
  }

  i {
    display: none !important;
  }

  .ant-collapse-header {
    color: ${color.LightBlue} !important;
    position: relative;
    border-bottom: 1px solid ${color.GrayBorder} !important;
    border-top: 0 !important;
    padding: 8px !important;
    padding-left: 0 !important;

    &::after {
      content: '+';
      position: absolute;
      right: 8px;
      top: 8px;
      font-size: 31px;
      transition: 0.3s all ease;
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      &::after {
        transform: rotate(45deg);
      }
    }
  }
`;

Collapse.Title = styled.p`
  font-weight: bold;
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  font-size: 16px;
`;

const Panel = styled(BaseCollapse.Panel)`
  &.ant-collapse-item-active {
    .ant-collapse-content {
      margin-top: 20px;
      border-bottom: 1px solid ${color.GrayBorder};
    }
  }
`;

export default {
  Collapse,
  Panel,
};
