import { Formik, Form } from 'formik';
import { FormErrors, FormLabel } from 'modules/common/components';
import validationSchema from './validationSchema';

import S from './styles';

const EditProfileForm = ({ fields, onSave }) => (
  <Formik initialValues={fields} validationSchema={validationSchema} onSubmit={onSave}>
    {({ dirty, values, errors, handleSubmit, handleChange }) => (
      <Form onSubmit={handleSubmit}>
        <FormErrors errors={errors} />

        <S.FieldGroup>
          <S.FieldControl>
            <FormLabel title="First Name" htmlFor="firstName" required>
              <S.Input
                id="firstName"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
              />
            </FormLabel>
          </S.FieldControl>
          <S.FieldControl>
            <FormLabel title="Last Name" htmlFor="lastName" required>
              <S.Input
                id="lastName"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
              />
            </FormLabel>
          </S.FieldControl>
        </S.FieldGroup>

        <S.FieldGroup>
          <S.FieldControl>
            <FormLabel title="Email">
              <S.Input name="email" value={values.email} disabled />
            </FormLabel>
          </S.FieldControl>
          <S.FieldControl>
            <FormLabel title="Phone" htmlFor="phone" required>
              <S.InputMask
                id="phone"
                name="phone"
                type="text"
                mask="999-999-9999"
                value={values.phone}
                onChange={handleChange}
                alwaysShowMask
              />
            </FormLabel>
          </S.FieldControl>
        </S.FieldGroup>

        <S.FieldGroup>
          <S.Button disabled={!dirty} onClick={handleSubmit}>
            Save
          </S.Button>
        </S.FieldGroup>
        <S.FieldGroup>
          <S.InfoText>* Indicates required field</S.InfoText>
        </S.FieldGroup>
      </Form>
    )}
  </Formik>
);

export default EditProfileForm;
