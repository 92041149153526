import {
  EDIT_ORDERED_DRAFT,
  GET_DRAFT_BY_ID,
  CREATE_DRAFT,
  UPDATE_PREFERENCES,
  UPDATE_PREFERENCE_BY_KEY,
  UPDATE_PREFERENCE_STATUS_BY_KEY,
  UPDATE_PREFERENCE_BY_ELEMENT_ID,
  REORDER_SECTION,
  ACTIVATE_SECTION,
  DEACTIVATE_SECTION,
  REPLACE_COVER_IMAGE,
  UPLOAD_COVER_IMAGE,
  ADD_LOADING_COVER_ELEMENTS,
  REMOVE_LOADING_COVER_ELEMENTS,
  UPDATE_COVER_IMAGE,
  UPDATE_COVER_ELEMENT,
  UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
  UPDATE_COVER_ACTIVE_TEMPLATE,
  UPDATE_CONTENT_ELEMENT,
  UPDATE_CONTENT_ACTIVE_TEMPLATE,
  UPLOAD_CONTENT_IMAGE,
  RESET_DRAFT,
  RESET_SECTION,
} from 'modules/v2/editor/store/constants';

export const getDraftById = (draftId) => ({
  type: GET_DRAFT_BY_ID,
  payload: {
    draftId,
  },
});

export const createDraft = (bookId, thumbnail = '', preferences = {}) => ({
  type: CREATE_DRAFT,
  payload: {
    bookId,
    thumbnail,
    preferences,
  },
});

export const updatePreferences = (preferences) => ({
  type: UPDATE_PREFERENCES,
  payload: {
    preferences,
  },
});

export const updatePreferenceByKey = (key, value) => ({
  type: UPDATE_PREFERENCE_BY_KEY,
  payload: {
    key,
    value,
  },
});

export const updatePreferenceStatusByKey = (key, status) => ({
  type: UPDATE_PREFERENCE_STATUS_BY_KEY,
  payload: {
    key,
    status,
  },
});

export const updatePreferenceByElementId = (id, value) => ({
  type: UPDATE_PREFERENCE_BY_ELEMENT_ID,
  payload: {
    id,
    value,
  },
});

export const reorderSection = (sectionId, nextIndex) => ({
  type: REORDER_SECTION,
  payload: {
    sectionId,
    nextIndex,
  },
});

export const activateSection = (sectionId, nextIndex) => ({
  type: ACTIVATE_SECTION,
  payload: {
    sectionId,
    nextIndex,
  },
});

export const deactivateSection = (sectionId, nextIndex) => ({
  type: DEACTIVATE_SECTION,
  payload: {
    sectionId,
    nextIndex,
  },
});

export const replaceCoverImage = (templateId, elementId, value, group) => ({
  type: REPLACE_COVER_IMAGE,
  payload: {
    templateId,
    elementId,
    value,
    group,
  },
});

export const uploadCoverImage = (templateId, elementId, url, group, imageFormat) => ({
  type: UPLOAD_COVER_IMAGE,
  payload: {
    templateId,
    elementId,
    url,
    group,
    imageFormat,
  },
});

export const uploadContentImage = (image) => ({
  type: UPLOAD_CONTENT_IMAGE,
  payload: {
    image,
  },
});

export const addLoadingCoverElements = (...elements) => ({
  type: ADD_LOADING_COVER_ELEMENTS,
  payload: elements,
});

export const removeLoadingCoverElements = (...elements) => ({
  type: REMOVE_LOADING_COVER_ELEMENTS,
  payload: elements,
});

export const updateCoverImage = (templateId, elementId, defaultValue) => ({
  type: UPDATE_COVER_IMAGE,
  payload: {
    templateId,
    elementId,
    defaultValue,
  },
});

export const updateCoverElement = (templateId, elementId, rawValue, defaultValue) => ({
  type: UPDATE_COVER_ELEMENT,
  payload: {
    templateId,
    elementId,
    rawValue,
    defaultValue,
  },
});

export const updateCoverElementsByPreferences = (templateId, preferences) => ({
  type: UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
  payload: {
    templateId,
    preferences,
  },
});

export const updateCoverActiveTemplate = (templateId) => ({
  type: UPDATE_COVER_ACTIVE_TEMPLATE,
  payload: {
    templateId,
  },
});

export const updateContentElement = (sectionId, templateId, elementId, rawValue, defaultValue) => ({
  type: UPDATE_CONTENT_ELEMENT,
  payload: {
    sectionId,
    templateId,
    elementId,
    rawValue,
    defaultValue,
  },
});

export const updateContentActiveTemplate = (sectionId, templateId) => ({
  type: UPDATE_CONTENT_ACTIVE_TEMPLATE,
  payload: {
    sectionId,
    templateId,
  },
});

export const editOrderedDraft = (orderId) => ({
  type: EDIT_ORDERED_DRAFT,
  payload: {
    orderId,
  },
});

export const resetDraft = (draftId) => ({
  type: RESET_DRAFT,
  payload: {
    draftId,
  },
});

export const resetSection = (sectionId) => ({
  type: RESET_SECTION,
  payload: {
    sectionId,
  },
});
