import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  padding: 30px;
  height: ${({ height }) => height};
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  text-align: center;
  font-size: 26px;
  color: ${color.Black};
  font-weight: bold;

  @media ${device.tabletS} {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 36px;
  color: ${color.GreyFormCheckbox};

  @media ${device.tabletS} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const IconWrapper = styled.div`
  text-align: center;
`;

export default {
  IconWrapper,
  Title,
  Description,
  Wrapper,
};
