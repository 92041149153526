//

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { RichUtils } from 'draft-js';
import classnames from 'classnames';
import { map, noop } from 'lodash-es';

import { options } from 'modules/editor/components/ContentEditor/utils';

const Inline = ({ editor, onChange = noop }) => {
  const { inlineStyles } = options;

  const handleStyle = (style) => (e) => {
    e.preventDefault();
    const state = RichUtils.toggleInlineStyle(editor, style.toUpperCase());
    onChange(state);
  };

  return map(inlineStyles, ({ icon, type, style }) => {
    const active = editor.getCurrentInlineStyle().has(style);

    return (
      <span key={type} className={classnames('normal', { active })} onClick={handleStyle(style)}>
        <LegacyIcon type={icon} />
      </span>
    );
  });
};

export default Inline;
