import { useMutation } from 'react-query';
import { withRouter, useParams } from 'react-router-dom';
import { Layout } from 'antd';
import { Formik } from 'formik';

import { getRouteLaunchCampaign } from 'modules/dashboard/routes/navigation';
import { editOnDemandEmail } from 'modules/api';

import { DashboardTemplate } from 'modules/dashboard/templates';
import { OnDemandEmailsNavBar, OnDemandEmailsSideBar } from 'modules/dashboard/components';
import { notification } from 'modules/common/utils';

import validationSchema from './validationSchema';

const OnDemandEmail = ({ history }) => {
  const { id: onDemandEmailId } = useParams();

  const formFields = {
    templateId: '',
    subject: '',
    segments: [],
    allSegments: false,
    timezone: 'America/New_York',
    scheduleDate: new Date(),
    sendImmediately: false,
    sendLater: true,
    allAvailableSegments: [],
  };

  const { mutate: updateOnDemandEmail, isLoading: isLoadingEdit } = useMutation(
    (formData) => {
      editOnDemandEmail(formData, onDemandEmailId);
    },
    {
      onSuccess: () => {
        history.replace(getRouteLaunchCampaign());
      },
      onError: (error) => {
        notification.error({ description: error });
      },
    },
  );

  const handleCancel = () => {
    history.push(getRouteLaunchCampaign());
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formFields}
      validationSchema={validationSchema}
      onSubmit={updateOnDemandEmail}
    >
      <DashboardTemplate
        isLoading={isLoadingEdit}
        loadingTitle="Loading Campaigns"
        noDataTitle="No Campaigns Available"
        marginRight="0"
        hasSidebar
        navBar={<OnDemandEmailsNavBar onCancel={handleCancel} />}
        noPaddingLeft
      >
        <Layout>
          <OnDemandEmailsSideBar onDemandEmailId={onDemandEmailId} />
        </Layout>
      </DashboardTemplate>
    </Formik>
  );
};

export default withRouter(OnDemandEmail);
