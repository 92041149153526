import * as Yup from 'yup';

// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'phoneValidation', function () {
  return this.test({
    name: 'phone',
    message: 'Phone is not valid',
    test: (value = '') => {
      const onlyNumbers = value.replace(/-|_/g, '');

      return onlyNumbers.length === 10;
    },
  });
});

export default Yup;
