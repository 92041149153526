import { call, select, takeEvery } from 'redux-saga/effects';

import { copyImageToDraft, resource, getError } from 'modules/api';
import { UPLOAD_CONTENT_IMAGE } from 'modules/editor/store/constants';
import { draftIdSelector } from 'modules/editor/store';
import { notification } from 'modules/common/utils';

import { fixImageUrlPayload } from './utils';

export function* onUploadContentImage({ payload: meta }) {
  try {
    const { image } = meta;

    const draftId = yield select(draftIdSelector);
    const imageType = 'content';

    const request = resource(UPLOAD_CONTENT_IMAGE, copyImageToDraft, fixImageUrlPayload);
    const payload = yield call(request, { imageType, image, draftId });

    notification.success({
      description: 'Image uploaded',
    });

    return payload;
  } catch (error) {
    const payload = getError(error);

    notification.error({
      description: 'Failed uploading image',
    });

    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchUploadContentImage() {
  yield takeEvery(UPLOAD_CONTENT_IMAGE, onUploadContentImage);
}
