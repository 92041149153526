export const PlayIcon = ({ width = 30, height = 30, fill = '#fff' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 31"
      fill="#252525"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="16" r="15" fill="#252525" />
      <path
        d="M20.1774 14.2187L14.0026 10.311C12.7191 9.49876 11 10.3721 11 11.8364V19.6519C11 21.1161 12.7191 21.9895 14.0026 21.1773L20.1774 17.2696C21.3241 16.5439 21.3241 14.9444 20.1774 14.2187Z"
        fill={fill}
      />
    </svg>
  );
};
