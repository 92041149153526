import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { PrimaryButton } from 'modules/common/components';
import * as color from 'modules/common/theme/color';
import { Link } from 'react-router-dom';

const LoginWrapper = styled.div`
  background-color: #2e3f50;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .loader {
    height: auto;
  }
`;

const Container = styled.div`
  border-radius: 12px;
  width: 1280px;
  background-color: ${color.White};
  height: 720px;
  display: flex;
  margin: 0 20px;

  @media only screen and (max-width: 1024px) {
    background-image: none;
    padding: 0;
  }
}
`;

const FormContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const Sidebar = styled.div`
  width: 514px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const LoginInput = styled(Input)`
  border-radius: 7px !important;
`;

const LoginButton = styled(PrimaryButton)`
  margin: 30px 0 0 0;
  width: 100%;
  &.ant-btn {
    height: 42px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 7px;
    letter-spacing: 1px;
    line-height: 20px;
  }
`;

const LoginLink = styled(Link)`
  color: ${color.AuthLink};
  font-weight: bold;
  letter-spacing: 0.5px;
  align-self: center;
`;

const LoginForm = styled(Form)`
  width: 321px;
  display: flex;
  flex-direction: column;
  && {
    padding-top: 50px;
  }
`;

const DefaultLink = styled.a`
  &:hover {
    text-decoration: underline !important;
  }
`;

const LoginLogo = styled.div`
  margin: 0 0 15px 0;
`;

const LoginErrorWrap = styled.div`
  text-align: left;
  border-radius: 3px;
  background: ${color.ErrorBg};
  padding: 10px 15px 10px;
  margin: 20px auto 0;
  border: 1px solid ${color.ErrorBdr};
  p {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0 0 0 8px;
    font-weight: 400;
    position: relative;
    padding: 3px 10px;
    color: ${color.GreySelectDd};
    text-align: left;
  }
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    left: -8px;
    margin-right: 10px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: ${color.GreySelectDd};
  }
}
`;

export default {
  Container,
  FormContent,
  Sidebar,
  LoginWrapper,
  LoginForm,
  LoginInput,
  LoginButton,
  LoginLogo,
  LoginLink,
  DefaultLink,
  LoginErrorWrap,
};
