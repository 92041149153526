import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 141px);
`;

const ViewGuidesContainer = styled.div`
  height: 84%;
  background-color: ${color.flipbookBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 16%;
  background-color: ${color.ViewFooterBackground};
  padding: 25px 0px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const VerticalLine = styled.div`
  height: 62px;
  width: 1px;
  background-color: ${color.ViewBorderColor};
  margin-left: 20px;
`;

const LandingPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .ant-btn {
    padding: 0px;
  }
`;

const FakeInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;

  width: 344px;
  height: 48px;

  background-color: ${color.White};
  border: 1px solid ${color.gray200};
  border-radius: 4px;

  font-size: 16px;
`;

const LandingPageLink = styled.a`
  height: 32px;
  vertical-align: middle;
  padding: 4px 0px 4px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${color.flipbookBackground};
`;

const ViewLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: ${color.ViewBlueButton};

  p {
    text-decoration: underline;
  }
`;

const IframeWrapper = styled.div`
  /* max-height: 455px; */
  height: 100%;
  width: 100%;
`;

const GuideTitle = styled.div`
  width: 100%;
  height: 45px;
  background-color: ${color.Black};

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  color: white;

  span {
    color: ${color.ViewFooterTitle};
    text-transform: capitalize;
    margin-left: 5px;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: 100%;
  text-align: center;
  display: block;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 640px;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContentTitle = styled.p`
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-right: 2px;

  span {
    color: ${color.DangerRed};
  }
`;

const ContentDescription = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

export default {
  Container,
  ViewGuidesContainer,
  FooterContainer,
  ButtonsContainer,
  VerticalLine,
  LandingPageContainer,
  FakeInput,
  LandingPageLink,
  ViewLink,
  IframeWrapper,
  GuideTitle,
  Image,
  ImageContainer,
  ModalFooterContainer,
  ContentContainer,
  ContentTitle,
  ContentDescription,
};
