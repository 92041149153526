import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './buyer-guide-new.png';

const isProduction = env.ENVIRONMENT === 'production';

const buyerGuideDefinitions = {
  offerDescription: [
    '12 Ready to Go Buyer Guides that you can give to sellers.',
    'Easily customize the guides to you by simply stapling your business card on the front cover.',
    'Show home buyers you’re a Competent Professional with these guides.',
    'Demonstrate that you care about marketing with these guides.',
    'Prove your value with all of the details and in-depth information inside the guides.',
  ],
  productHandler: 'buyer-guide',
  componentId: 'handle:clickfunnel-7',
  summaryPrice: '$7',
  summaryTitle: 'Buyer Guide (12 Printed Guides)',
  offerImage,
  upsell: {
    productHandler: 'authorify-monthly-non-contracted',
    productPricePointHandle: 'monthly_trial_0',
    componentId: isProduction ? '1824465' : '2197557',
    component: () => <Upsell productName="Buyer Guide" />,
    offerId: isProduction ? '65048c90b8cad4bdeb9f2051' : '64dd0eb5a92b674c230273b6',
  },
  USOnly: true,
};

export default buyerGuideDefinitions;
