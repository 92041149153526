function AlertWarning({ width = 20, height = 20, stroke = '#B35109', fill = '#B35109', ...props }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.42626 8.96791C6.85823 4.65597 8.07422 2.5 9.99984 2.5C11.9255 2.5 13.1414 4.65597 15.5734 8.96791L15.8765 9.50522C17.8974 13.0884 18.9079 14.88 17.9946 16.19C17.0814 17.5 14.8219 17.5 10.3029 17.5H9.69678C5.1778 17.5 2.91831 17.5 2.00505 16.19C1.09179 14.88 2.10226 13.0884 4.12321 9.50523L4.42626 8.96791Z"
        stroke={stroke}
        strokeWidth="2"
      />
      <path d="M10 6.66602V10.8327" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <circle cx="9.99984" cy="13.3333" r="0.833333" fill={fill} />
    </svg>
  );
}

export default AlertWarning;
