import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const ScheduleCardInfo = styled.div`
  display: flex;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const ScheduleCardImage = styled.div`
  margin-right: 20px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
  svg {
    width: 100px;
    height: 100px;
  }
  img {
    width: 100px;
    height: 100px;
  }
`;
const ScheduleCardDescription = styled.div`
  padding-right: 10px;
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;
const ScheduleCardHeading = styled.h4`
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: ${color.Black};
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const SchedulePosition = styled.p`
  font-weight: regular;
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  margin-top: 2px !important;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const ScheduleSubHeading = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  margin-top: 10px !important;
`;

export default {
  ScheduleCardInfo,
  ScheduleCardImage,
  ScheduleCardDescription,
  ScheduleCardHeading,
  SchedulePosition,
  ScheduleSubHeading,
};
