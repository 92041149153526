import { get } from 'lodash-es';
import { useParams } from 'react-router';
import { formatDate } from 'modules/common/utils';
import { useStatesAndCountries } from 'modules/common/hooks';
import { setItem } from 'modules/dashboard/utils/legacy';
import { isDentistOffer } from 'modules/dashboard/utils';
import { useCreateSession, useResumeSession } from 'modules/onboard/hooks';
import { OnboardTemplate } from 'modules/onboard/templates';
import { PlaceOrderForm, Advertisement } from 'modules/onboard/components';
import { getInitialFields, getCurrentStepIndex } from 'modules/onboard/utils';
import { getConfirmTrial } from 'modules/onboard/routes/navigation';
import useHubspotTrackCode from '../Common/HubspotTrackCode';
import S from './styles';

const PlaceOrderPage = (props) => {
  const { history } = props;
  const isDentist = isDentistOffer();

  useHubspotTrackCode();
  const { states, countries } = useStatesAndCountries();
  const { offerCode } = useParams();
  const { session, isError, isLoading } = useCreateSession();
  const currentStepIndex = getCurrentStepIndex(session || {}) || 0;

  const offer = get(session, 'offer', null);
  const product = get(offer, 'productInfo[0]', '');
  const { mutate } = useResumeSession();
  const handlePasswordBlur = ({ email, password }) => {
    mutate({ email, password });
  };

  const queryString = window.location.search;

  const handlePaymentDone = () => {
    setItem('payment_confirmed', 0);
    history.replace(getConfirmTrial(offerCode, queryString));
  };

  return (
    <OnboardTemplate
      hideNavigation
      currentStep={currentStepIndex}
      isStepsVisible={false}
      paddingContent="0"
      isLoading={isLoading}
      isError={isError}
    >
      {offer ? (
        <S.FullWrap>
          <S.PlaceOrderContainer>
            <PlaceOrderForm
              countries={countries}
              states={states}
              product={product}
              isDentist={isDentist}
              nextPayment={offer.description1}
              fields={getInitialFields(offerCode)}
              onPasswordBlur={handlePasswordBlur}
              offerCode={offerCode}
              onPaymentDone={handlePaymentDone}
              isContracted={offer?.accountType === 'Contracted'}
              offerTitle={offer?.title}
              paymentOptions={offer?.paymentOptions}
              freeBooks={offer?.freeBooks}
            />
          </S.PlaceOrderContainer>

          <S.AdBlockWrap>
            <Advertisement offer={offer} date={formatDate(new Date(), 'MM/DD/YYYY')} />
          </S.AdBlockWrap>
        </S.FullWrap>
      ) : null}
    </OnboardTemplate>
  );
};

export default PlaceOrderPage;
