import { Route } from 'react-router';

import { withGuard } from '../containers';

const PublicRoute = ({ component, path, exact, children }) => {
  const render = (props) => withGuard(component)({ ...props, children });

  return <Route exact={exact} path={path} render={render} />;
};

export default PublicRoute;
