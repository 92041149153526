import S from './styles';

const FormLabel = ({
  title,
  children,
  required,
  contactDetails,
  space = false,
  error = false,
  ...extra
}) => (
  <S.Label {...extra}>
    {space && <S.Space />}
    {title && <S.Title>{title}</S.Title>}
    {required && !contactDetails && <S.RequiredSymbol>*</S.RequiredSymbol>}
    {children}
    {error && <S.Error>{error}</S.Error>}
  </S.Label>
);
export default FormLabel;
