import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Pagination = {};

Pagination.Section = styled.div`
  width:100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  font-family: ${font.MontserratFont};
  color: ${color.GreyText};

  .ant-select-selection {
    color: ${color.GreyText};
    margin-left: 16px;
    width: 118px;
  }

  .ant-select-selection__rendered {
    font-family: ${font.MontserratFont};
`;

export { Pagination };
