import { getInstance } from './instance';

const instance = getInstance();

export const getPaymentMethods = () => {
  const url = '/payments/customer/payment-methods';

  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getPayments = () => {
  const url = '/payments/customer/payments';

  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getSubscription = () => {
  const url = '/payments/customer/subscription';
  const params = { active: false };

  return instance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getActiveSubscription = () => {
  const url = '/payments/customer/subscription';
  const params = { active: true };

  return instance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getProductPackages = ({ queryKey }) => {
  const [, isPlanPlus = false] = queryKey;
  const url = `payments/product-plans?filters[plusPlan]=${isPlanPlus}`;

  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const upgradePlan = ({ payload }) => {
  const url = '/payments/subscription/proration';

  return instance
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updatePlan = ({ payload }) => {
  const url = '/payments/subscription/upgrade';

  return instance
    .patch(url, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
