import { useState, useEffect } from 'react';
import { Tooltip } from 'modules/common/components';
import { isLoading, isSucceeded } from 'store/status';

import S from './styles';

const MESSAGE_MAX_LENGTH = 600;

const HelpForm = ({ onSubmit, ticketStatus }) => {
  const [formHidden, setFormHidden] = useState(true);
  const [message, setMessage] = useState('');
  const isCreatingTicket = isLoading(ticketStatus);
  const isTicketCreated = isSucceeded(ticketStatus);
  const invalidForm = !formHidden && message.length === 0;
  const isTooltipVisible = invalidForm && !isCreatingTicket;

  useEffect(() => {
    if (isTicketCreated) {
      setMessage('');
      setFormHidden(true);
    }
  }, [isTicketCreated, ticketStatus]);

  const handleChangeMessage = (e) => {
    const { value } = e.target;

    setMessage(value);
  };

  const handleRequestSupport = () => {
    if (formHidden) {
      setFormHidden(false);
      return;
    }

    onSubmit(message);
  };

  const tooltipContent = (
    <S.TooltipContent>
      Fill out the message before <br /> you can submit this form
    </S.TooltipContent>
  );

  return (
    <S.Form>
      {!formHidden && (
        <>
          <S.SpaceBorder />
          <S.Form.Label htmlFor="message">
            <S.Form.FieldTitle>Message</S.Form.FieldTitle>

            <S.Form.Textarea
              name="message"
              id="message"
              cols="30"
              rows="3"
              maxLength={MESSAGE_MAX_LENGTH}
              disabled={isCreatingTicket}
              placeholder="Type your message here"
              onChange={handleChangeMessage}
              value={message}
            />
          </S.Form.Label>
        </>
      )}

      <S.Form.Footer>
        {!formHidden && (
          <S.Form.MessageLength>
            <S.Form.Characters>{message.length}</S.Form.Characters> /{MESSAGE_MAX_LENGTH}{' '}
            character(s)
          </S.Form.MessageLength>
        )}
        <Tooltip placement="top" title={tooltipContent} visible={isTooltipVisible}>
          <S.Form.SubmitButtonWrapper>
            <S.Form.SubmitButton
              loading={isCreatingTicket}
              disabled={invalidForm}
              onClick={handleRequestSupport}
            >
              {formHidden ? 'Request Support' : 'Submit'}
            </S.Form.SubmitButton>
          </S.Form.SubmitButtonWrapper>
        </Tooltip>
      </S.Form.Footer>
    </S.Form>
  );
};

export default HelpForm;
