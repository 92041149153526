import styled from 'styled-components';

import * as color from 'modules/v2/common/theme/color';

const Wrapper = styled.div`
  text-align: left;
  border-radius: 3px;
  background: ${color.ErrorBg};
  padding: 10px 15px 10px;
  margin: 0 12px 20px 12px;
  border: 1px solid ${color.ErrorBdr};
`;

const Item = styled.p`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0 0 0 8px;
  font-weight: 400;
  padding: 3px 10px !important;
  color: ${color.GreySelectDd};
  text-align: left !important;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: -8px;
    margin-right: 10px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: ${color.GreySelectDd};
  }
`;

export default { Wrapper, Item };
