import { Typography, Space as BaseSpace } from 'antd';
import styled, { css, keyframes } from 'styled-components';

import { PrimaryButton } from 'modules/v2/common/components';
import * as color from 'modules/v2/common/theme/color';

const Section = styled.div`
  margin-bottom: 25px;
`;

const MainSection = styled.div`
  padding: 20px;
`;

const SummarySection = styled.div`
  padding: 20px;
  background-color: ${color.TableHeader};
`;

const PlanDetailsSection = styled.div`
  padding: 10px;
  background-color: ${color.White};
`;

const TotalPaymentSection = styled.div`
  margin-top: 20px;
`;

const TotalPayment = styled.div`
  text-align: right;
`;

const PlanDetails = styled.div`
  margin-top: 5px;
`;

const PlanDetailItem = styled(Typography.Text)`
  display: block;
`;

const PlanTitleSection = styled(BaseSpace)`
  justify-content: space-between;
  width: 100%;
`;

const PlanTitle = styled(Typography.Text)`
  font-size: 16px !important;
`;

const EditLink = styled.div`
  font-size: 14px;
  color: ${color.PhoneLink};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`;

const ShakeDiv = styled.div`
  cursor: pointer;
  ${({ shaking }) =>
    shaking &&
    css`
      animation: ${shakeAnimation} 0.5s;
    `}
`;

const PayNowButton = styled(PrimaryButton)`
  width: 100% !important;
  margin-bottom: 20px !important;

  &:disabled {
    background: ${color.PrimaryButtonHover} !important;
    color: ${color.White} !important;
  }
`;

export default {
  Section,
  MainSection,
  SummarySection,
  PlanDetailsSection,
  PlanDetails,
  PlanDetailItem,
  PlanTitleSection,
  PlanTitle,
  EditLink,
  TotalPaymentSection,
  TotalPayment,
  PayNowButton,
  ShakeDiv,
};
