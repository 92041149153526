//

import { useDraft } from 'modules/common/components/DraftContext';

import { Inline, List, Block, Highlight, FontFamily, FontSize, Image } from './components';

import S from './styles';

const EditorToolbar = ({ simple, extended, imageHandlerProps = {} }) => {
  const { editorState, setEditorState, selected } = useDraft();

  const props = { editor: editorState, onChange: setEditorState };
  const { images, onConfirmFilePickerModal, onDelete, onGalleryTabOpen } = imageHandlerProps;
  const fontFamily = (selected && selected.fontFamily) || undefined;
  const fontSize = (selected && selected.fontSize) || undefined;

  return (
    <S.Toolbar className="items-center bg-white border-b basis-[58px] border-b-neutral-200 flex w-full justify-center">
      <Inline {...props} />
      {!simple && <List {...props} />}
      {!simple && <Block {...props} />}
      {!simple && (
        <Image
          {...props}
          images={images}
          onConfirmFilePickerModal={onConfirmFilePickerModal}
          onDelete={onDelete}
          onGalleryTabOpen={onGalleryTabOpen}
        />
      )}

      {extended && (
        <>
          <Highlight {...props} />
          <FontFamily {...props} defaultFontFamily={fontFamily} />
          <FontSize {...props} defaultFontSize={fontSize} />
        </>
      )}
    </S.Toolbar>
  );
};

export default EditorToolbar;
