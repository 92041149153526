function getDefaultProps() {
  return {
    width: '14',
    height: '14',
    className: '',
  };
}

const ArrowRightTable = ({ width, height, className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width,
          height,
        }}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M3.5 10H15.5M15.5 10L10.5685 5M15.5 10L10.5685 15"
          stroke="#252525"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

ArrowRightTable.defaultProps = {
  width: getDefaultProps().width,
  height: getDefaultProps().height,
};

export default ArrowRightTable;
