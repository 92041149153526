import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { createQuotation, getActiveSubscriptions, getDealBySubscriptionId } from 'modules/api';
import { Divider, Space, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import { Loader } from 'modules/common/components';
import { MODAL_HEIGHT } from 'modules/publish/constants';
import S from './styles';
import { getRoutePlusPlansSchedule } from '../../routes/navigation';

const { Text } = Typography;

const PlusPlansContractBinding = () => {
  const history = useHistory();
  const [site, setSite] = useState('');
  const [isDealReady, setIsDealReady] = useState(false);
  const {
    planType,
  }: {
    planType: string | undefined;
  } = useParams();
  const { mutate: createQuote } = useMutation('createQuotation', createQuotation, {
    onSuccess: (data) => {
      setTimeout(() => {
        setSite(data?.quoteLink);
      }, 20000); // 20 seconds to accommodate any delay from hubspot
    },
  });

  const { mutate: getDeal } = useMutation('getDealBySubscriptionId', getDealBySubscriptionId, {
    onSuccess: (data) => {
      const authorifyProduct = data?.properties?.authorify_product;
      const chargifyId = data?.properties?.chargify_subscription_id;
      if (authorifyProduct?.toLowerCase().includes('plus')) {
        const dealId = data?.id;
        createQuote({
          dealId,
        });
      } else {
        setTimeout(() => {
          getDeal(chargifyId);
        }, 2000);
      }
    },
  });

  const continueToScheduleCall = () => {
    history.push(getRoutePlusPlansSchedule(planType));
  };

  useQuery('getActiveSubscriptions', getActiveSubscriptions, {
    enabled: !isDealReady,
    onSuccess: (data) => {
      if (data.component.product_family_handle === 'authorify_plus') {
        setIsDealReady(true);
        const subId = data?.id;
        if (subId) {
          getDeal(subId);
        }
      }
    },
  });

  if (!isDealReady) {
    return <Loader height={MODAL_HEIGHT} title="Checking upgrade status..." />;
  }

  if (!site) {
    return <Loader height={MODAL_HEIGHT} title="Generating contract for plan..." />;
  }

  return (
    <S.CenterSection>
      <S.FormTitle>Contract Binding Form</S.FormTitle>

      <S.FormDivider />
      <S.Frame
        title="Contract Quote"
        src={site}
        width="1000"
        height="1000"
        allowFullScreen
        id="hubspot-contract"
        scrolling="yes"
      />
      <Divider />
      <Space direction="vertical" style={{ textAlign: 'center' }}>
        <Text strong>Please make sure to read and sign the contract above.</Text>
        <Text strong>After signing, please press the continue button:</Text>
        <S.InlinePrimaryButton onClick={continueToScheduleCall}>Continue</S.InlinePrimaryButton>
      </Space>
    </S.CenterSection>
  );
};

export default PlusPlansContractBinding;
