import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
`;

const LoaderImg = styled.img`
  margin: 30px auto;
  display: block;
`;

export default {
  Wrapper,
  LoaderImg,
};
