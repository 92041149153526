import { useEffect, useState } from 'react';
import { getItem } from 'modules/dashboard/utils/legacy';
import environments from 'environments';
import S from './styles';

const PlusPlansSchedule = () => {
  const [site, setSite] = useState('');
  const userDataString = getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const setDetails = () => {
    if (userData?.email && userData?.firstname && userData?.lastname) {
      setSite(
        `${environments.CSR_MEETING_LINK}?embed=true&firstname=${userData.firstname}&lastname=${userData.lastname}&email=${userData.email}`,
      );
    } else {
      setSite(`${environments.CSR_MEETING_LINK}?embed=true`);
    }
  };

  useEffect(() => {
    setDetails();
  }, []);

  return (
    <S.CenterSection>
      <S.Frame
        title="Schedule"
        src={site}
        width="1000"
        height="1000"
        allowFullScreen
        id="hubspot-schedule"
      />
    </S.CenterSection>
  );
};

export default PlusPlansSchedule;
