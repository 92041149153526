import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const AddPaymentMethod = styled.a`
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  color: ${color.InputFocusBorder};
  text-decoration: underline !important;
  margin-bottom: 40px;
  display: block;
`;

export default { AddPaymentMethod };
