import styled from 'styled-components';

const Container = styled.div`
  width: 60%;
  max-width: 600px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 408px;

  .form-label {
    width: 420px;
  }
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default { Container, FormContainer, ContainerWrapper, SaveContainer };
