import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const Warning = styled.div`
  width: 310px;
  background-color: ${color.SuccessWarning};
  border: 1px solid ${color.SuccessWarningBorder};
  border-radius: 3px;
  padding: 20px 20px 30px 20px;
  text-align: left;
`;

Warning.Title = styled.strong`
  display: block;
  font-size: 16px;
  font-weight: 700;
`;

Warning.Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  line-height: 30px;
  padding: 3px 0px;
  width: 100%;
`;

Warning.BackToLogin = styled.div`
  display: flex;
  margin-top: 20px;
`;

Warning.LoginDescription = styled.span`
  align-self: center;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
`;

Warning.LoginLink = styled(Link)`
  align-self: center;
  font-size: 18px;
  color: ${color.PrimaryColor};
  line-height: 24px;
  letter-spacing: 0px;

  margin-left: 5px;

  :hover {
    color: ${color.SecondaryColor};
    transition: all 0.3s ease-in-out;
  }
`;

export default {
  Warning,
};
