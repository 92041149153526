import styled from 'styled-components';
import { Radio, Col } from 'antd';

import { SecondaryButton as BaseSecondaryButton } from 'modules/onboard/components';

import { device } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';

const BoxTitle = styled.span`
  font-size: 16px;
`;

const BoxSelectCover = styled.div`
  padding: 0 20px;
  margin-top: 88px;
`;

const BoxUserForm = styled.div`
  width: 600px;
  padding: 0 20px;

  @media ${device.tabletS} {
    max-width: 100%;
    margin-top: 22px;
  }
`;

const FormDescription = styled.p`
  margin-bottom: 20px;
`;

const PreviewLink = styled.a`
  font-size: 16px;
  float: right;
`;

const PreviewGallery = styled(Radio.Group)`
  border-radius: 3px;
  padding: 20px !important;
  margin-top: 10px !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const PreviewItem = styled.label`
  list-style-type: none;
  display: flex;
  cursor: pointer;
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media ${device.tabletS} {
    margin: 0 auto 20px;
  }
`;

const PreviewInput = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
`;

const PreviewImage = styled.img`
  margin: 0 auto;
  width: 95px;
`;

const SubmitBox = styled(Col)`
  display: flex;
  justify-content: center;
`;

const LineSeparator = styled.div`
  width: 1px;
  height: 754px;
  background-color: ${color.GrayDividingLine};

  @media ${device.tabletS} {
    display: none;
  }
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
  background-color: ${color.neutral50};
  @media ${device.tabletS} {
    flex-wrap: wrap;
    margin-top: 0;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const TrashImage = styled.img`
  margin-right: 10px;
  cursor: pointer;
`;

const AvatarImage = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  svg {
    position: relative;
    top: 0px;
  }
  img {
    border-radius: 4px !important;
    width: 100%;
    height: 100%;
  }
`;

const CloseAvatar = styled.span`
  cursor: pointer;
  font-size: 18px;
  left: 82px;
  top: -13px;
  position: absolute;
  padding: 10px;
`;

const AvatarDescription = styled.div`
  padding: 20px 10px;
  font-size: 16px;
  font-weight: 400;
  .imageSize {
    color: ${color.GrayDisabled};
  }
`;

const DisabledText = styled.span`
  color: ${color.GrayDisabled};
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const EmptyAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 100%;
  height: 114px;
  font-size: 16px;

  padding: 14px 12px;

  border-radius: 4px;
  border: 1px dashed ${color.GrayDividingLine};
  border-width: 2px;

  cursor: pointer;
`;

const AvatarImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 114px;
  font-size: 16px;

  padding: 14px 12px;

  background-color: ${color.White};

  border-radius: 4px;
  border: 1px solid ${color.GrayDividingLine};
`;

const GenerateBookContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`;

const GenerateBookDescription = styled.p`
  color: ${color.BoxDescription};
`;

const FakeInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 600px;
  height: 48px;
  padding: 4px 16px;

  background-color: ${color.White};
  border-radius: 4px;
  border: 1px solid ${color.gray200};

  font-size: 16px;
  transition: all 0.3s ease-in-out;

  button {
    padding: 0 0 0 16px;
  }

  @media ${device.tabletS} {
    font-size: 12px;
  }
`;

const BookLink = styled.a`
  max-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${color.Black};
`;

const ButtonStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  background-color: ${color.neutral50};

  padding: 30px 0;

  @media ${device.tabletS} {
    justify-content: center;
  }
`;

const UpperCase = styled.span`
  text-transform: uppercase;
  font-weight: normal;
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
  color: ${color.Black};

  @media ${device.tabletS} {
    font-size: 12px;
  }
`;

const SecondaryButton = styled(BaseSecondaryButton)`
  @media ${device.tabletS} {
    margin: 0 auto;
  }
`;

export default {
  BoxTitle,
  BoxSelectCover,
  SecondaryButton,
  BoxUserForm,
  PreviewLink,
  PreviewGallery,
  PreviewItem,
  PreviewInput,
  PreviewImage,
  SubmitBox,
  MainContent,
  AvatarWrapper,
  AvatarDescription,
  AvatarImage,
  AvatarImageContainer,
  CloseAvatar,
  GenerateBookContainer,
  GenerateBookDescription,
  FakeInput,
  BookLink,
  ButtonStepsContainer,
  UpperCase,
  EmptyAvatarContainer,
  DisabledText,
  AvatarContainer,
  TrashImage,
  FormDescription,
  LineSeparator,
  Title,
};
