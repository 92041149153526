import styled from 'styled-components';
import { Tooltip as TooltipBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  background-color: ${color.White};
  th {
    font-weight: bold;
  }
  tr {
    border: 1px solid ${color.LightDullGray};
  }

  table tr th:first-child {
    width: 40%;
  }
`;

const RowIcons = styled.div`
  display: inline-block;
  cursor: pointer;

  span.anticon {
    margin-right: 10px;
    vertical-align: middle;
    font-size: 16px;
  }
  span.anticon[aria-label='star'] {
    color: ${color.LightDullGray};
  }
  span.anticon[aria-label='eye'] {
    color: ${color.SkyBlue};
  }
`;

const Name = styled.span`
  color: ${color.SkyBlue};
`;

const HeaderSection = styled.div`
  display: flex;
  margin: 20px 0;
`;

const HeaderTitle = styled.div`
  color: ${color.Black};
  flex: 3;
  font-size: 24px;
  margin: 0;
  font-weight: bold;
  padding: 0;
  margin: 8px 0 0 0;
`;

const Search = styled.div`
  flex: 0 0 1;
  .ant-input-search {
    width: 247px;
  }
`;

const Tooltip = styled(TooltipBase)`
  color: ${color.SkyBlue};
`;

const ModalBoxFooter = styled.div`
  .saveExit {
    background: ${color.LightHeadGray};
    color: ${color.Black};
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  h2 {
    color: ${color.Black};
    text-align: center;
    font-size: 22px;
  }
  p {
    font-size: 16px;
    color: ${color.LightHeadGray};
  }
`;
const EmptyColumn = styled.div`
  padding: 15px 0px;
  background-color: ${color.White};
`;

const TemplateWrapper = styled.div`
  max-width: 710px;
  margin: 20px auto;
  border-radius: 2px;
  border: 1px solid ${color.GreyBorder};
  padding: 20px;

  img {
    margin: 10px auto;
    max-width: 30%;
  }
`;

const Image = styled.img`
  margin: 10px auto;
  max-width: 30%;
`;
const Title = styled.h5`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  // line-height: 46px;
  padding: 7px 0px;
  font-weight: bold;
  margin: ${(props) => props.marginLeft ?? '0px'};
`;

export default {
  Title,
  Image,
  Container,
  RowIcons,
  Name,
  HeaderSection,
  HeaderTitle,
  Search,
  Tooltip,
  ModalBoxFooter,
  ModalContent,
  EmptyColumn,
  TemplateWrapper,
};
