import { PureComponent, createContext, useContext } from 'react';
import { convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { debounce, noop } from 'lodash-es';

import { getOptions } from 'modules/common/utils';

import { createContextObject, getStateFromElements } from './utils';

export const DEBOUNCE_EDITOR_CHANGE = 300;

export const Context = createContext({});

export class Provider extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    const isDifferentTemplate = props.templateId !== state.templateId;
    const isDifferentSection = props.sectionId !== state.sectionId;
    const isReset = props.wasReset !== state.wasReset;

    if (isDifferentTemplate || isDifferentSection || isReset) {
      return {
        sectionId: props.sectionId,
        templateId: props.templateId,
        editorState: getStateFromElements(props.elements),
        wasReset: props.wasReset,
        selected: state.selected,
      };
    }
    return null;
  }

  handleChange = debounce((editorState) => {
    const { onChange = noop } = this.props;
    const { selected } = this.state;

    if (selected === null) {
      return;
    }

    const content = editorState.getCurrentContent();
    const options = getOptions(editorState);

    const element = {
      ...selected,
      defaultValue: stateToHTML(content, options),
      rawValue: convertToRaw(content),
    };

    onChange(element);
  }, DEBOUNCE_EDITOR_CHANGE);

  constructor(props, context) {
    super(props, context);

    this.state = {
      sectionId: props.sectionId,
      templateId: props.templateId,
      editorState: getStateFromElements(props.elements),
      wasReset: props.wasReset,
      selected: null,
    };
  }

  setEditorState = (editorState) => {
    this.setState({ editorState });
  };

  setSelected = (selected) => {
    this.setState({
      selected,
    });
  };

  render() {
    const { children } = this.props;
    const { editorState, selected } = this.state;

    const context = {
      selected,
      editorState,
      setSelected: this.setSelected,
      setEditorState: this.setEditorState,
      onChange: this.handleChange,
    };

    return <Context.Provider value={context}>{children}</Context.Provider>;
  }
}

export const useDraft = (element) => {
  const context = useContext(Context);

  return createContextObject(context, element);
};
