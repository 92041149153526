export const PlusCircleIcon = ({ width = 30, height = 30, stroke = '#535353' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        d="M13.4 7.09961V13.4996M13.4 13.4996V19.8996M13.4 13.4996H19.8M13.4 13.4996L7 13.4996"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
