export const VideoIcon = ({ width = 20, height = 21, fill = '#fff', stroke = '#252525' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66663 10.0835C1.66663 7.34393 1.66663 5.97414 2.42326 5.05219C2.56177 4.8834 2.71653 4.72864 2.88532 4.59013C3.80727 3.8335 5.17706 3.8335 7.91663 3.8335C10.6562 3.8335 12.026 3.8335 12.9479 4.59013C13.1167 4.72864 13.2715 4.8834 13.41 5.05219C14.1666 5.97414 14.1666 7.34393 14.1666 10.0835V10.9168C14.1666 13.6564 14.1666 15.0262 13.41 15.9481C13.2715 16.1169 13.1167 16.2717 12.9479 16.4102C12.026 17.1668 10.6562 17.1668 7.91663 17.1668C5.17706 17.1668 3.80727 17.1668 2.88532 16.4102C2.71653 16.2717 2.56177 16.1169 2.42326 15.9481C1.66663 15.0262 1.66663 13.6564 1.66663 10.9168V10.0835Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M14.1666 8.41666L14.7153 8.14235C16.3368 7.33156 17.1476 6.92617 17.7405 7.29256C18.3333 7.65895 18.3333 8.56544 18.3333 10.3784V10.6216C18.3333 12.4346 18.3333 13.341 17.7405 13.7074C17.1476 14.0738 16.3368 13.6684 14.7153 12.8576L14.1666 12.5833V8.41666Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};
