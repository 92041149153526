import { ChangeEvent } from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { get } from 'lodash-es';

import { Checkbox, FormLabel } from 'modules/common/components';
import { ErrorMessage, Input, InputMask, FieldControl } from 'modules/onboard/components';
import { IInitialValues } from 'modules/formBuilder/types';

function hasError(formikProps: FormikProps<IInitialValues>, field: string) {
  return get(formikProps.errors, field) && get(formikProps.touched, field);
}

function getError(formikProps: any, field: string) {
  return hasError(formikProps, field) ? get(formikProps.errors, field) : null;
}

interface FieldProps {
  id: string;
  label: string;
  component?: any;
  required?: boolean;
}

interface FormComponentProps {
  field: FieldProps;
}

const Field = ({ field }: FormComponentProps) => {
  const formikProps = useFormikContext<IInitialValues>();

  const { id, label, component: Component = Input, required, ...rest } = field;

  const handleInputMaskChange =
    (formikProps: FormikProps<IInitialValues>) => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const newValue = value.replace(/\D/g, '');

      formikProps.setFieldValue(field.id, newValue);
    };

  const title = Component !== Checkbox ? label : null;

  const componentProps = {
    id,
    name: id,
    $hasError: hasError(formikProps, id),
    onBlur: formikProps.handleBlur,
    disabled: formikProps.isSubmitting,
    value: get(formikProps.values, id),
    onChange:
      Component === InputMask ? handleInputMaskChange(formikProps) : formikProps.handleChange,
    ...rest,
  };

  const ComponentToRender = <Component {...componentProps} />;

  return (
    <FieldControl>
      <FormLabel title={title} htmlFor={id} required={required}>
        {ComponentToRender}
        {hasError(formikProps, id) && <ErrorMessage>{getError(formikProps, id)}</ErrorMessage>}
      </FormLabel>
    </FieldControl>
  );
};

export default Field;
