function changeToDateFormat(num) {
  return num.toString().padStart(2, '0');
}

export const handleShowDate = (date) => {
  return `${date.getFullYear()}-${changeToDateFormat(date.getMonth() + 1)}-${changeToDateFormat(
    date.getDate(),
  )}`;
};

export const handleFormatDate = (date) => {
  return date.replaceAll('/', '-');
};

export function formatSecondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes.toString();
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}
