import React, { useState } from 'react';
import { defaultToggleSwitchTheme as theme } from './theme';
import { CheckSwitchIcon } from '../../components/SvgIcon';


const Toggle = ({
  enabled,
  onChange,
  label,
}) => {
  const [isOn, setIsOn] = useState(enabled);

  const toggleSwitch = () => {
    const newIsOn = !isOn;
    setIsOn(newIsOn);
    onChange(newIsOn);
  };

  return (
    <div className='flex items-center gap-2'>
      <div className={`${theme.root.base} ${isOn ? theme.root.color.on : theme.root.color.off}`} onClick={toggleSwitch}>
        <div
          className={`transform duration-300 ease-in-out ${
            isOn ? theme.knob.position.on : theme.knob.position.off
          } flex items-center justify-center w-5 h-5 rounded-full bg-white`}
        >
          <CheckSwitchIcon />
        </div>
      </div>
      {label && <span className="text-sm text-neutral-700 font-semibold">{label}</span>}
    </div>
  );
};

export default Toggle;
