import { UPDATE_PASSWORD, UPDATE_PREFERENCES } from 'modules/v2/store/constants';

export const updatePreferences = ({ name, email, phone, brokerInfo, headshotId }) => ({
  type: UPDATE_PREFERENCES,
  payload: {
    name,
    email,
    phone,
    brokerInfo,
    headshotId,
  },
});

export const updatePassword = (oldPassword, newPassword) => ({
  type: UPDATE_PASSWORD,
  payload: {
    oldPassword,
    newPassword,
  },
});
