import {
  GET_BOOKS_BY_CATEGORY,
  GET_SELECTED_BOOK,
  SET_INDICES,
  CHECK_USER_DRAFTS,
  TOGGLE_VIEW,
  TOGGLE_READMORE,
  SET_CONTENT_HEIGHT,
  GET_BOOK_PDF,
  GET_ALL_BOOKS,
  TO_ORDER_BOOK_SECTION,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  data: [],
  isCalculated: false,
  indices: {
    start: 1,
    end: 5,
  },
  drafts: {
    loading: false,
    loaded: false,
    data: [],
  },
  selected: null,
  view: 0,
  pdf: {
    url: '',
    loading: false,
    loaded: false,
  },
  booksDropdown: [],
  toOrder: false,
  // 0 : grid-view , 1: list-view
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TO_ORDER_BOOK_SECTION: {
      return {
        ...state,
        toOrder: action.flag,
      };
    }

    case `${CHECK_USER_DRAFTS}_REQUEST`: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          loading: true,
          loaded: false,
        },
      };
    }

    case CHECK_USER_DRAFTS: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          loading: false,
          loaded: true,
          data: action.payload.data,
        },
      };
    }

    case `${CHECK_USER_DRAFTS}_FAILURE`: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          loaded: true,
        },
      };
    }

    case GET_ALL_BOOKS: {
      // sort all books in ascending order
      const books = action.payload.data.books.sort((book1, book2) => {
        return book1.order - book2.order;
      });
      return {
        ...state,
        booksDropdown: books,
      };
    }

    case `${GET_BOOKS_BY_CATEGORY}_REQUEST`: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_BOOKS_BY_CATEGORY: {
      // sort books in ascending  order
      const books = action.payload.data.books.sort((book1, book2) => {
        return book1.order - book2.order;
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: books.map((book, index) => {
          return { ...book, order: index, expanded: false, height: null };
        }),
      };
    }

    case `${GET_BOOKS_BY_CATEGORY}_FAILURE`: {
      return { ...state, loaded: false };
    }

    case `${SET_CONTENT_HEIGHT}`: {
      return {
        ...state,
        data: state.data.map((book, index) => {
          return { ...book, height: action.height[index] };
        }),
        isCalculated: true,
      };
    }

    case TOGGLE_READMORE: {
      return {
        ...state,
        data: state.data.map((book) => {
          if (book._id === action.item._id) {
            if (!book.expanded) {
              return { ...book, expanded: true };
            } else {
              return { ...book, expanded: false };
            }
          }
          return book;
        }),
      };
    }

    case `${GET_SELECTED_BOOK}_REQUEST`: {
      return { ...state, loading: true, loaded: false };
    }

    case GET_SELECTED_BOOK: {
      return {
        ...state,
        loading: false,
        loaded: true,
        selected: action.data,
      };
    }

    case `${GET_BOOK_PDF}_FAILURE`: {
      return { ...state, loaded: false };
    }

    case `${GET_BOOK_PDF}_REQUEST`: {
      return {
        ...state,
        pdf: {
          loading: true,
        },
      };
    }

    case GET_BOOK_PDF: {
      return {
        ...state,
        pdf: {
          loading: false,
          loaded: true,
          url: action.payload.data.url,
        },
      };
    }

    case `${GET_SELECTED_BOOK}_FAILURE`: {
      return { ...state, pdf: { loaded: false } };
    }

    case TOGGLE_VIEW: {
      return { ...state, view: action.view };
    }

    case SET_INDICES:
      return {
        ...state,
        indices: {
          ...state.indices,
          start:
            action.indices + 5 < state.data.length
              ? action.indices !== 0
                ? action.indices + 5
                : action.indices + 1
              : action.indices !== 0
                ? state.data.length - action.indices + 1
                : action.indices + 1,
          end: action.indices + 5 < state.data.length ? action.indices + 5 : state.data.length,
        },
      };

    default:
      return { ...state };
  }
};
