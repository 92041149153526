import styled from 'styled-components';
import { Field } from 'formik';
import * as color from 'modules/common/theme/color';

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${color.neutral800};
  margin: 0;
`;

const SelectField = styled(Field)`
  .ant-select-selector--single {
    height: 36px;
  }
  &.form-input-error {
    border: 1px solid ${color.ErrorWarning};
  }
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  height: 38px !important;
  border: 1px solid ${color.GreyLightText};
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 9px 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${color.neutral800};
  align-self: stretch;
  overflow: hidden;

  .ant-select-selector {
    height: 38px !important;
    border: none !important;
    display: flex;
    align-items: center;
  }

  .ant-select .ant-select-single .ant-select-show-arrow {
    height: 38px !important;
  }

  &:focus {
    outline: none;
    border: 1px solid ${color.primary500};
    box-shadow: none;
  }
`;

export default {
  FieldControl,
  Label,
  FormikField,
  SelectField,
};
