import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Radio } from 'flowbite-react';
import { get } from 'lodash-es';
import io from 'socket.io-client';
import env from 'environments';
import ToolTip from 'modules/v2/common/AtomicDesign/molecules/Tooltip';
import QuestionCircle from 'modules/v2/common/components/SvgIcon/QuestionCircle';
import { useHistory } from 'react-router-dom';
import { getRoutePlansAndBillings } from 'modules/v2/routes/navigation';

import {
  fetchUserCredits,
  getActiveSubscriptions,
  getDealBySubscriptionId,
  getDefaultPaymentProfile,
  getPaymentProfilesList,
  getSubscriptionService,
  migratePlanToChargify,
  reAuthenticate,
  setDefaultPaymentProfile,
  upgradePlanSubscription,
  upgradePlanSubscriptionNewUpgrade,
} from 'modules/api';
import { CreditCardImages, formatCreditCardNumber } from 'modules/v2/utils';
import { getItem } from 'modules/v2/utils/legacy';
import { Box, Button, QuestionLabel } from 'modules/v2/common/AtomicDesign/atoms';
import { Notification } from 'modules/v2/common/AtomicDesign/molecules';
import {
  AddCircleIcon,
  CalendarIcon,
  CardIconNew,
  InfoIconNew,
} from 'modules/v2/common/components/SvgIcon';
import {
  AddCardModal,
  HelpModal,
  ProcessingModal,
  SuccessModal,
} from 'modules/v2/common/AtomicDesign/organisms';
import { notification } from 'modules/v2/common/utils';
import { Loader, Modal } from 'modules/v2/common/components';
import { formatCurrency, getNextMonthRenewal, getNextYearRenewal } from '../utils';
import { createQuote } from '../../../../api/afyPlans/plans';
import AlertWarning from '../../../common/components/SvgIcon/AlertWarning';
import { getUser, log } from '../../../../api/afyLogger/afyLogger';
import { executeAfterDelay } from '../../../common/utils/timer';

const Payment = ({ plan, cycle, setUpgradeFlow, upgradeFlow }) => {
  const billingOptions = plan.billingOptions.find((item) => item.interval === cycle);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showModalContract, setShowModalContract] = useState<boolean>(false);
  const [contractUrl, setContractUrl] = useState<string>(null);
  const [contractId, setContractId] = useState(null);
  const [deal, setDeal] = useState(null);

  const history = useHistory();
  const CHARGIFY_HUBSPOT_WAITING_TIME = 10000; // 10 seconds

  useEffect(() => {
    if (!contractId) {
      return;
    }

    const socket = io(env.SOCKET_IO, { transports: ['websocket'], withCredentials: true });
    socket.on('quote_signed_in', (data) => {
      notification.success({ description: 'Your contract has been signed successfully' });
      if (Number(data.id) === Number(contractId)) {
        prepareToPay();
        handlePayment();
        socket.disconnect();
      }
    });
  }, [contractId]);

  const translateCycle = (cycle) => {
    const cycles = {
      monthly: 'month',
      annual: 'year',
    };

    return cycles[cycle] ?? cycle;
  };

  const { data: paymentData = [] } = useQuery('getPaymentProfilesList', getPaymentProfilesList);

  const { data: defaultProfile } = useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile);

  const userDataString = getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const profiles = paymentData?.filter((item) => item.id !== defaultProfile?.id);

  profiles.unshift(defaultProfile);

  const [selectedPaymentId, setSelectedPaymentId] = useState(defaultProfile?.id || null);
  const [disabledPayment, setDisabledPayment] = useState(!selectedPaymentId);

  const isDefault = (itemId: number) => itemId === selectedPaymentId;

  const { mutate: mutateSetCardDefault } = useMutation(
    ['setDefaultPaymentProfile'],
    setDefaultPaymentProfile,
    {
      onSettled: () => {
        setDisabledPayment(false);
      },
    },
  );

  const handleChangePaymentId = (id) => {
    setDisabledPayment(true);
    setSelectedPaymentId(id);
    mutateSetCardDefault(id);
  };

  const { data: subscription = {} } = useQuery(['getSubscription'], getActiveSubscriptions, {
    retry: 0,
  });

  useQuery(['getDeal'], () => getDealBySubscriptionId(subscription.id), {
    onSuccess: (data) => {
      setDeal(data);
    },
  });

  const componentHandle = subscription?.component?.component_handle.split('_');

  const actualPlan =
    componentHandle[0].charAt(0).toUpperCase() + componentHandle[0].slice(1).replace(/\plus/g, '+');

  const { data: { value: isSubscriptionFromStripe } = {} } = useQuery(
    ['getSubscriptionService'],
    getSubscriptionService,
  );

  const { mutate: migrateToChargify } = useMutation(
    ['migratePlanToChargify'],
    migratePlanToChargify,
  );

  const { mutate: mutateUpgradePlan } = useMutation(
    ['upgradePlanSubscription'],
    upgradePlanSubscriptionNewUpgrade,
    {
      onSuccess: async () => {
        const { email } = getUser();
        await executeAfterDelay(() => reAuthenticate(email), CHARGIFY_HUBSPOT_WAITING_TIME);
        setShowLoadingModal(false);
        log('Re-authenticated the user after plan upgrade', 'upgradePlanSubscription', ['re-auth']);
        history.push(`${getRoutePlansAndBillings()}?planUpgraded`);
        return (
          <Notification description="Your plan has been successfully upgraded" type="success" />
        );
      },
      onError: async (error) => {
        setShowLoadingModal(false);
        notification.error({
          description: 'We were unable to update your plan, please try again later',
        });
      },
    },
  );

  const { mutate: generateContractMutation, isLoading: loadingGenerateContract } = useMutation(
    ['generateContract'],
    createQuote,
    {
      onSuccess: async ({ data }) => {
        setTimeout(() => {
          setContractUrl(data?.url ?? null);
          setContractId(data?.id ?? null);
        }, 20000);
      },
      onError: async (error) => {
        setShowModalContract(false);
        notification.error({
          description: `We were unable to update your plan, please try again later - ${error?.response?.data?.message}`,
          autoClose: 10000,
        });
      },
    },
  );

  const handlePayment = () => {
    const { componentHandle: component, productHandle } = billingOptions;

    if (isSubscriptionFromStripe) {
      migrateToChargify(component);
      return;
    }

    const params = {
      chargifyId: subscription.customer.id,
      componentHandle: component,
      productHandle,
    };

    mutateUpgradePlan(params);
    setShowLoadingModal(true);
  };

  const generateContract = () => {
    if (!deal) {
      notification.error({
        description:
          'Your subscription does not have a deal associated with it. Please contact support.',
      });
      return;
    }

    setShowModalContract(true);

    if (contractUrl) {
      return;
    }

    generateContractMutation({
      chargifyCustomerId: subscription.customer.id,
      chargifyComponentHandle: billingOptions.componentHandle,
    });
  };

  const handlePaymentOrContract = () => {
    if (upgradeFlow === 'payment') {
      handlePayment();
    } else {
      generateContract();
    }
  };

  const prepareToPay = () => {
    if (deal) {
      setUpgradeFlow('payment');
    }
    setShowModalContract(false);
  };

  const handleModalCancel = () => {
    setShowModalContract(false);
  };

  const { data: userBookCredit } = useQuery(['getUserCredits'], fetchUserCredits);

  const bookCreditsLeft = get(userBookCredit, 'data.data.credits', 0);

  return (
    <>
      <Modal
        style={{ height: '80%' }}
        title="Complete the agreement below to proceed"
        isOpen={showModalContract}
        hasCloseButton
        onCancel={handleModalCancel}
        width="60%"
        footer={<></>}
      >
        <div className="w-full min-h-10 px-3 py-2.5 bg-orange-50 rounded-md justify-start items-center gap-3 mb-6 inline-flex">
          <AlertWarning stroke="#D9630B" fill="#D9630B" className="min-w-[20px] min-h-[20px]" />
          <p className="text-orange-600 text-sm font-semibold leading-tight">
            Please check your email to sign your contract and come back here to continue upgrading
            your plan.
          </p>
        </div>
        {contractUrl && (
          <iframe
            src={contractUrl}
            style={{ height: '500px', width: '100%', border: '1px solid #ccc' }}
          />
        )}

        {!contractUrl && (
          <Loader
            height="100%"
            title="Generating contract"
            description="Please wait don't close this window"
          />
        )}
      </Modal>
      <Box>
        <div className="text-base font-semibold px-6 py-[22px] border-b-[1px] border-neutral-200 text-boxTitle flex justify-between">
          <div>Review and pay</div>
        </div>
        <div className="flex justify-between p-6 gap-[170px] max-[1320px]:flex-col max-[1320px]:gap-6">
          <div className="text-sm w-1/2 max-[1320px]:w-full">
            <div className="font-semibold mb-3.5 flex items-center gap-2">
              <CardIconNew fill="#FF8A33" width={20} height={20} />
              <span>Choose payment method</span>
            </div>
            <div className="mb-3.5">
              <fieldset className="flex flex-col gap-2 mb-12">
                {profiles.map((item) => {
                  if (!item) return null;
                  return (
                    <label
                      key={item.id}
                      className={`flex items-center border rounded-md p-3.5 font-semibold hover:bg-neutral-50 cursor-pointer ${
                        isDefault(item.id)
                          ? 'border-primary-500 bg-primary-50 hover:bg-primary-50'
                          : 'border-tableBorder'
                      }`}
                    >
                      <Radio
                        id={item.id}
                        name="cards"
                        value={item}
                        defaultChecked={isDefault(item.id)}
                        onChange={() => handleChangePaymentId(item.id)}
                      />
                      <div className="flex items-center gap-2">
                        <div className="w- px-6 ">
                          <img
                            src={CreditCardImages[item.card_type]}
                            alt={item?.card_type}
                            className="w-[46px] h-[32px]"
                          />
                        </div>
                        <div className="w- ">
                          {formatCreditCardNumber(item.masked_card_number, isDefault(item.id))}
                        </div>
                      </div>
                    </label>
                  );
                })}
              </fieldset>
            </div>
            <button
              type="button"
              className="mb-8 flex items-center gap-2 p-0 font-semibold cursor-pointer"
              onClick={() => setShowAddCardModal(true)}
              color="outined"
            >
              <AddCircleIcon />
              <span>Add new payment method</span>
            </button>
            <div className="mb-8">
              <div className="font-semibold mb-2 flex items-center gap-2">
                <CalendarIcon fill="#FF8A33" />
                <span>Billing period</span>
              </div>
              <span className="text-neutral-600 text-sm">
                {cycle === 'monthly'
                  ? `Monthly (Renews ${getNextMonthRenewal()})`
                  : `Yearly (Renews ${getNextYearRenewal()})`}
              </span>
            </div>

            <div className="mb-8">
              <div className="font-semibold mb-2">Email my invoice at</div>
              <span>{userData?.email}</span>
            </div>

            <div className="flex space-x-3 bg-neutral-100 px-3 py-2.5 rounded-md">
              <InfoIconNew fill="#252525" width={20} height={20} />
              <div className="font-semibold">
                Having issues upgrading?{' '}
                <u className="cursor-pointer" onClick={() => setShowHelpModal(true)}>
                  Contact our dedicated customer support team
                </u>
              </div>
            </div>
          </div>
          <div className="p-6 bg-neutral-50 rounded-lg w-1/2 max-[1320px]:w-full">
            <div className="text-2xl font-bold mb-6">Summary</div>
            <div className="mb-5">
              <div className="flex justify-between">
                <div className="font-semibold text-base">{plan.alias} plan</div>
                <div className="text-sm">
                  {`${formatCurrency(billingOptions.value / 100, false)}/${translateCycle(cycle)}`}
                </div>
              </div>
              {plan.features.map((item) => {
                return (
                  <div>
                    {item?.amount > 0 ? item?.amount : ''} {item?.prefix} {item?.name}{' '}
                    {item?.suffix}
                  </div>
                );
              })}
            </div>
            <div className="pb-5 mb-6 border-b-[1px] border-neutral-200">
              <div className="inline-flex font-semibold text-base	gap-1 items-center">
                {`Current plan (${actualPlan} plan)`}
                <ToolTip
                  arrow
                  trigger="hover"
                  style="dark"
                  placement="top"
                  content="Your remaining credits will be carried over to your new plan"
                  theme={{
                    base: 'z-50 rounded-md py-2 px-3 max-w-80 text-sm font-medium',
                  }}
                >
                  <QuestionCircle />
                </ToolTip>
              </div>
              {bookCreditsLeft > 0 && <div>{`+${bookCreditsLeft} credits`}</div>}
            </div>
            <div className="flex justify-between font-semibold mb-6">
              <div className="font-semibold text-base">Total due today</div>
              <div className="text-sm">{formatCurrency(billingOptions.value / 100, false)}</div>
            </div>
            <Button
              onClick={() => handlePaymentOrContract()}
              size="lg"
              full
              disabled={disabledPayment || !deal}
            >
              Pay Now
            </Button>
            <div className="mt-6 text-neutral-500 text-xs">
              By clicking “Pay now”, I agree to the{' '}
              <a
                href="https://authorify.com/terms-of-use/"
                target="_blank"
                rel="noreferrer"
                className="text-primary-600 underline"
              >
                <u>terms and conditions</u>
              </a>
              {` and I acknowledge that I’m authorizing Authorify to automatically charge my default
            payment method today and then every ${
              cycle === 'monthly' ? '30 days' : 'year'
            }  (recurring payment) unless I cancel.`}
            </div>
          </div>
        </div>
        <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
        <ProcessingModal
          title="Processing your payment..."
          description="Please don’t close this window"
          showModal={showLoadingModal}
          setShowModal={setShowLoadingModal}
        />
        {showAddCardModal && (
          <AddCardModal showModal={showAddCardModal} setShowModal={setShowAddCardModal} />
        )}
      </Box>
    </>
  );
};

export default Payment;
