import { Row, Col } from 'antd';

import { Scrollbars } from 'modules/common/components';

import S from './styles';

const getScrollbarHeight = (header) => (header ? 'calc(100% - 42px)' : '100%');

const Template = ({ navbar, coverImage, sectionHeader, contentPreview, toolbar, children }) => (
  <S.Page>
    {navbar}
    <S.Wrapper>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <S.Wrapper.Flex>
            <S.Wrapper.Flex.Inner>
              <S.Wrapper.CoverImageWrap>{coverImage}</S.Wrapper.CoverImageWrap>
              <S.Wrapper.SectionSortingWrap>
                {sectionHeader}
                <S.Wrapper.SectionSortingWrap.Inner>
                  <Scrollbars style={{ height: getScrollbarHeight(sectionHeader) }}>
                    {children}
                  </Scrollbars>
                </S.Wrapper.SectionSortingWrap.Inner>
              </S.Wrapper.SectionSortingWrap>
              <S.Wrapper.ContentWrap>
                <S.Wrapper.ContentWrap.Inner>
                  <Scrollbars style={{ height: '100%' }}>{contentPreview}</Scrollbars>
                </S.Wrapper.ContentWrap.Inner>
              </S.Wrapper.ContentWrap>
            </S.Wrapper.Flex.Inner>
          </S.Wrapper.Flex>
        </Col>
      </Row>
    </S.Wrapper>
    {toolbar}
  </S.Page>
);

export default Template;
