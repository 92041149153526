import { guidesInformation, guidesPacketInformation } from 'modules/common/utils';
import { DENTIST_ORDER_DATA } from 'modules/onboard/constants';
import { getItem } from 'modules/dashboard/utils/legacy';

export const getGuideDentistDetailsInitialValues = (session) => {
  const cachedData = getItem(DENTIST_ORDER_DATA, 'object');
  const { id, name, thumbnailUrl } = guidesPacketInformation || {};

  if (cachedData) return cachedData;

  const {
    customer: {
      billing: { address1, zip: pincode, city, country, state },
    },
  } = session;

  const address = {
    addressLine1: address1,
    city,
    state,
    pincode,
    country,
  };

  const initialValues = {
    practiceName: '',
    practiceAddress: address,
    practicePhone: '',
    practiceLogo: '',
    practiceWebsite: '',
    practiceEmail: '',
    quantity: 150,

    guideId: id,
    guideName: name,
    thumbnail: thumbnailUrl,
    firstDentist: {
      id: 0,
      image: '',
      name: '',
      title: '',
    },
    frontCover: [],

    shippingAddress: address,
  };

  return initialValues;
};
