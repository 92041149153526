import { useState } from 'react';

import { Toolbar } from 'modules/nav/components';
import { ResetButton, ResetDraftModal } from './components';

const EditorFooter = ({ bookTitle, children, onReset, onResetSection }) => {
  const [isResetDraftModalOpen, setIsResetDraftModalOpen] = useState(false);

  const handleResetDraftModalOpen = () => setIsResetDraftModalOpen(true);
  const handleResetDraftModalCancel = () => setIsResetDraftModalOpen(false);
  const handleResetDraftModalConfirm = () => {
    onReset();
    setIsResetDraftModalOpen(false);
  };
  const handleResetSection = () => {
    onResetSection();
    setIsResetDraftModalOpen(false);
  };

  return (
    <Toolbar>
      <ResetButton hasDivider onClick={handleResetDraftModalOpen}>
        Reset
      </ResetButton>
      {children}
      <ResetDraftModal
        title={bookTitle}
        isOpen={isResetDraftModalOpen}
        onCancel={handleResetDraftModalCancel}
        onReset={handleResetDraftModalConfirm}
        onResetSection={handleResetSection}
      />
    </Toolbar>
  );
};

export default EditorFooter;
