import env from 'environments';

const buildConfig = (paymentType) => ({
  // selector where the iframe will be included in the host's HTML (i.e. '#chargify-form')
  // optional if you have a `selector` on each and every field
  selector: '#chargify-form',

  // (i.e. '1a2cdsdn3lkn54lnlkn')
  publicKey: env.CHARGIFY_PUBLIC_KEY,

  // form type (possible values: 'card' or 'bank')
  type: paymentType || 'card',

  // points to your Chargify site
  serverHost: env.CHARGIFY_SERVER_HOST,
  // Enable 3D secure
  threeDSecure: true,

  fields: {
    number: {
      selector: '#chargify1',
      required: true,
      next_focus: 'cvv',
    },
    cvv: {
      selector: '#chargify2',
      required: true,
      next_focus: 'month',
    },
    month: {
      selector: '#chargify3',
      required: true,
      next_focus: 'year',
    },
    year: {
      selector: '#chargify4',
      required: true,
    },
  },
});

export default buildConfig;
