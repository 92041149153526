import { pluralize } from './pluralize';

export const orderQuantity = (total = 0, isDigital = false) => {
  const plural = pluralize('book', 'books', total);

  if (isDigital) {
    if (total) {
      return `${plural} and digital`;
    }
    return 'Digital';
  }

  return `${plural}`;
};

export const orderDentistQuantity = (total = 0, isDigital = false, guidePacketName = 'guide') => {
  const pluralValue = `${guidePacketName}s`;
  const plural = pluralize(guidePacketName, pluralValue, total);

  if (isDigital) {
    if (total) {
      return `${plural} and digital`;
    }
    return 'Digital';
  }

  return `${plural}`;
};
