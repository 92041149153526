import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './seller-guide-orig.png';

const isProduction = env.ENVIRONMENT === 'production';

const sellerGuideDefinitionsCA = {
  offerDescription: [
    '12 Ready to Go Seller Guides that you can give to sellers.',
    'Easily customize the guides to you by simply stapling your business card on the front cover.',
    'Show sellers you’re a Competent Professional with these guides.',
    'Demonstrate that you care about marketing with these guides.',
    'Prove your value with all of the details and in-depth information inside the guides.',
  ],
  productHandler: 'sellerguide',
  componentId: 'handle:clickfunnel-7',
  summaryTitle: 'Seller Guide Canada(12 Printed Guides)',
  summaryPrice: '$7',
  offerImage,
  upsell: {
    productHandler: 'authorify-monthly-non-contracted',
    productPricePointHandle: 'monthly_trial_0',
    componentId: isProduction ? '1824465' : '2197555',
    component: () => <Upsell productName="Seller Guide" />,
    offerId: isProduction ? '654b6e6e0329a5197ff40c22' : '6544d2bc85541e99200416f5',
  },
};

export default sellerGuideDefinitionsCA;
