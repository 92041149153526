import { Route } from 'react-router';

import { onboardGuard } from 'modules/auth';
import { withOnboardGuard } from '../containers';

const OnboardRoute = ({ component, path, exact, children }) => {
  const render = (props) => withOnboardGuard(component, onboardGuard)({ ...props, children });

  return <Route exact={exact} path={path} render={render} />;
};

export default OnboardRoute;
