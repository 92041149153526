const ManuscriptIcon = ({ stroke = '#fafafa', fill = '#3c3c3c', ...props }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 8.83341C2.5 5.69072 2.5 4.11937 3.47631 3.14306C4.45262 2.16675 6.02397 2.16675 9.16667 2.16675H10.8333C13.976 2.16675 15.5474 2.16675 16.5237 3.14306C17.5 4.11937 17.5 5.69072 17.5 8.83341V12.1667C17.5 15.3094 17.5 16.8808 16.5237 17.8571C15.5474 18.8334 13.976 18.8334 10.8333 18.8334H9.16667C6.02397 18.8334 4.45262 18.8334 3.47631 17.8571C2.5 16.8808 2.5 15.3094 2.5 12.1667V8.83341Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path d="M6.66602 10.5H13.3327" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6.66602 7.16675H13.3327" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6.66602 13.8333H10.8327" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default ManuscriptIcon;
