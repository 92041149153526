import { useState } from 'react';
import { Button, Tooltip } from 'flowbite-react';
import { Drawer } from 'antd';
import { cn } from 'modules/v2/common/utils';

import { AddressFormView } from 'modules/v2/common/views/AddressView/views';
import { PencilIcon } from 'modules/v2/common/components/SvgIcon/PencilIcon';
import { DeleteIconNew } from 'modules/v2/common/components/SvgIcon/DeleteIconNew';
import DeleteModal from 'modules/v2/common/AtomicDesign/organisms/DeleteModal';

type ItemProps = {
  countries: object;
  states: object;
  addresses: object;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: string;
  firstName: string;
  pincode: string;
  state: string;
  _id: string;
  phoneNumber?: string;
  isDefault: boolean;
  selectedShippingAddressId?: string;
  email?: string;
  lastName?: string;
  selectedAddress?: string;
  onClick?: () => void;
  editUserAddresses: (fields: object) => Promise<unknown>;
  deleteUserAddresses: (id: string) => void;
  fetchUserAddressesRefetch?: () => void;
  isLast: boolean;
  isLoading?: boolean;
};

const ShippingAddressItem: React.FC<ItemProps> = ({
  // Data from store
  countries,
  states,
  addresses,

  // Callcack functions
  editUserAddresses,
  deleteUserAddresses,

  addressLine1,
  addressLine2,
  city,
  country,
  firstName,
  pincode,
  state,
  _id,
  phoneNumber,
  isDefault,
  lastName,
  onClick,
  selectedAddress,
  fetchUserAddressesRefetch,
  isLast,
  isLoading,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [hasSelectedAddress, setHasSelectedAddress] = useState(false);

  const handleCancel = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleCancelDelete = () => {
    setHasSelectedAddress(!hasSelectedAddress);
  };

  const handleSubmit = (fields: object) => {
    const callback = editUserAddresses;
    const cbResult = callback(fields);
    if (cbResult) {
      cbResult.then((res) => {
        fetchUserAddressesRefetch();
        setOpenDrawer(!openDrawer);
      });
    }
  };

  const handleDeleteConfirm = () => {
    deleteUserAddresses(_id);
    fetchUserAddressesRefetch();
  };

  const isSelectedAddress = () => {
    return selectedAddress && selectedAddress._id === _id;
  };

  return (
    <>
      <Drawer
        open={openDrawer}
        width={600}
        destroyOnClose
        title="Edit address"
        headerStyle={{ fontSize: '2rem' }}
        onClose={handleCancel}
      >
        <AddressFormView
          fields={selectedAddress}
          countries={countries}
          states={states}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Drawer>

      <DeleteModal
        title="Delete address"
        showModal={hasSelectedAddress}
        setShowModal={handleCancelDelete}
        handleDelete={() => handleDeleteConfirm()}
        deleteButton="Yes, Delete address"
      >
        <div className="rounded-lg bg-primary-50 p-6">
          <div className="text-sm text-neutral-800 font-semibold mb-2">
            {firstName} {lastName}
          </div>
          <div className="text-sm text-neutral-500 font-medium">{addressLine1}</div>
          <div className="text-sm text-neutral-500 font-medium">
            {city} {state} {pincode}
          </div>
          <div className="text-sm text-neutral-500 font-medium">{country}</div>
          {phoneNumber && (
            <div className="text-sm text-neutral-500 font-medium">Phone: {phoneNumber}</div>
          )}
        </div>
      </DeleteModal>

      <div
        className={cn('border rounded-lg p-4 mb-[14px] transition duration-300 ease-in-out cursor-pointer' ,{
          'border-primary-500 hover:bg-primary-50': isSelectedAddress(),
          'border-neutral-200 hover:bg-neutral-100': !isSelectedAddress(),
          'mb-2': isLast,
        })}
        onClick={onClick}
        onKeyUp={onClick}
      >
        <p className="text-sm font-semibold">
          {firstName} {lastName} {isDefault && '(Default)'}
        </p>
        <p className="text-sm text-neutral-500">
          {addressLine1}, {city}, {state} {pincode}, {country}
        </p>
        {addressLine2 && <p className="text-sm text-neutral-500"> {addressLine2} </p>}
        {phoneNumber && <p className="text-sm text-neutral-500"> Phone: {phoneNumber} </p>}

        <Button.Group className="mt-3">
          <Button
            color="gray"
            className="px-0 w-10"
            onClick={() => {
              setOpenDrawer(!openDrawer);
            }}
          >
            <Tooltip content="Edit">
              <PencilIcon />
            </Tooltip>
          </Button>
          <Button
            color="gray"
            className="px-0 w-10"
            onClick={() => setHasSelectedAddress(!hasSelectedAddress)}
            disabled={isDefault}
          >
            <Tooltip content="Delete">
              <DeleteIconNew fill="#252525" />
            </Tooltip>
          </Button>
        </Button.Group>
      </div>
    </>
  );
};

export default ShippingAddressItem;
