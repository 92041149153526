import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  max-width: 1326px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding-left: 40px;
`;

export default { ContentWrapper, Wrapper };
