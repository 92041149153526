import { useQuery } from 'react-query';
import { getActiveSubscriptions } from 'modules/api';
import { log } from 'modules/api/afyLogger/afyLogger';

type CustomerPlanInfo = {
  id: number;
  name: string;
  kind: string;
  unitName: string;
  currency: string;
  allocatedQuantity: number;
  pricingScheme: string;
  componentId: number;
  componentHandle: string;
  allowFractionalQuantities: boolean;
  subscriptionId: number;
  recurring: boolean;
  upgradeCharge: number | null;
  downgradeCredit: number | null;
  description: string;
  archivedAt: string | null;
  pricePointId: number;
  pricePointHandle: string;
  pricePointType: string;
  pricePointName: string;
  useSiteExchangeRate: boolean;
  productFamilyId: number;
  productFamilyHandle: string;
  displayOnHostedPage: boolean;
  createdAt: string;
  updatedAt: string;
  unitPrice: string;
  isDigital: boolean;
  isTrialing: boolean;
  canOrderMoreThanTenBooks: boolean;
};

export const useCustomerPlanInfo = (): CustomerPlanInfo => {
  const { data: subscription = {} } = useQuery(['getSubscription'], getActiveSubscriptions, {
    retry: 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: () => {
      log('Subscription fetched successfully', 'useDigitalCustomer', ['digital-customer']);
    },
    onError: () => {
      log('Error fetching subscription', 'useDigitalCustomer', ['digital-customer']);
    },
  });

  const isDigitalPlan = subscription?.component?.component_handle.match(/digital/);
  const isTrialing = subscription?.state === 'trialing';

  const allowedDigitalPlansComponentHandles = [
    'digitalplusplus_annual',
    'digitalplus_monthly',
    'digitalplus_annual',
  ];
  const canOrderMoreThanTenBooks =
    allowedDigitalPlansComponentHandles.includes(subscription?.component?.component_handle) &&
    !isTrialing;

  return {
    id: subscription?.id,
    name: subscription?.name,
    kind: subscription?.kind,
    unitName: subscription?.unit_name,
    currency: subscription?.currency,
    allocatedQuantity: subscription?.allocated_quantity,
    pricingScheme: subscription?.pricing_scheme,
    componentId: subscription?.component?.id,
    componentHandle: subscription?.component?.component_handle,
    allowFractionalQuantities: subscription?.allow_fractional_quantities,
    subscriptionId: subscription?.subscription_id,
    recurring: subscription?.recurring,
    upgradeCharge: subscription?.upgrade_charge,
    downgradeCredit: subscription?.downgrade_credit,
    description: subscription?.description,
    archivedAt: subscription?.archived_at,
    pricePointId: subscription?.price_point?.id,
    pricePointHandle: subscription?.price_point?.handle,
    pricePointType: subscription?.price_point?.type,
    pricePointName: subscription?.price_point?.name,
    useSiteExchangeRate: subscription?.use_site_exchange_rate,
    productFamilyId: subscription?.product_family?.id,
    productFamilyHandle: subscription?.product_family?.handle,
    displayOnHostedPage: subscription?.display_on_hosted_page,
    createdAt: subscription?.created_at,
    updatedAt: subscription?.updated_at,
    unitPrice: subscription?.unit_price,
    isDigital: !!isDigitalPlan,
    isTrialing,
    canOrderMoreThanTenBooks,
  };
};
