import { getInstance } from './instance';
import { getSessionId } from './utils';

const instance = getInstance();

export const bookDetails = ({ payload, offerCode }) => {
  const sessionId = getSessionId(offerCode);
  return instance
    .post(`onboard/book-details?offerCode=${offerCode}&sessionId=${sessionId}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
