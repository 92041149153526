import gql from 'graphql-tag';

export default gql(`
    query($bookId: ID!) {
        book(id: $bookId) {
            _id
            name
            category
            fonts
            description
            thumbnail {
                _id
                url
            }
            thumbnail_small {
                _id
                url
            }
            layout {
                id
                name
                width
                height
                marginTop
                marginLeft
                marginRight
                marginBottom
                paddingTop
                paddingLeft
                paddingRight
                paddingBottom
                _id
            }
            sections{
                _id
                name
                type
                order
                displayName
            }
            sectionOrder
            status
        }
    }
`);
