import { select, take } from 'redux-saga/effects';
import { getLocation, LOCATION_CHANGE } from 'connected-react-router';
import { noop } from 'lodash-es';

export function* watchExitRoute(watchFn = noop, validFn = noop, callback = noop) {
  while (true) {
    const action = yield take('*');

    if (action) {
      const current = yield select(getLocation);

      if (watchFn(current)) {
        const location = yield take(LOCATION_CHANGE);

        if (location) {
          const next = yield select(getLocation);

          if (validFn(next)) {
            yield callback();
          }
        }
      }
    }
  }
}
