import React, { useState } from 'react';
import { compose } from 'redux';
import { get } from 'lodash-es';

import { isPending } from 'store/status';

import { Toolbar } from 'modules/nav/components';
import {
  CoverImage,
  ContentPreview,
  EditorFooter,
  EditorNavbar,
  SectionHeader,
  SectionSorting,
} from 'modules/editor/components';
import { getRouteEditDraftCover, getRouteEditDraftContent } from 'modules/editor/routes/navigation';
import { ManageSectionsTemplate } from 'modules/editor/templates';
import {
  withBook,
  withDraft,
  withManageSections,
  withCoverPreview,
} from 'modules/editor/containers';

import { getElementsBySectionId } from 'modules/editor/utils';

const ManageSections = ({
  book,
  layout,
  preview,
  sections,
  manuscript,
  content,
  draftId,
  dropdown,
  isSaving,
  isPendingSave,
  goToDraftView,
  goToEditDraftContent,
  activateSection,
  deactivateSection,
  reorderSection,
  resetDraft,
  resetCurrentSection,
  saveDraft,
}) => {
  const [displayChapters, setDisplayChapters] = useState(false);

  const defaultSelectedSectionId = get(content, [0, '_id']);
  const [selectedSectionId, setSelectedSectionId] = useState(defaultSelectedSectionId);

  let currentSectionId = selectedSectionId;
  if (selectedSectionId === 'manuscript') {
    currentSectionId = get(manuscript, [0, '_id']);
  }

  // TODO: Move to selector
  const { elements } = getElementsBySectionId(sections, currentSectionId);

  const bookTitle = get(book, ['data', 'name']);
  // TODO: we are receiving the same URL to all options - frontCover, backCover and Thumbnail.
  // we need to discuss and decide about those name conventions and what do they mean.
  const coverPreview = get(preview.data, ['urls', 'front-cover']);

  // Responsible for discovering which action take based on the drag event
  const handleDragSection = (sectionId, source, destination) => {
    if (source === null || destination === null) {
      return;
    }

    setSelectedSectionId(sectionId);

    const { index: currentIndex, droppableId: current } = source;
    const { index: nextIndex, droppableId: next } = destination;

    const isSameBucket = next === current;
    const differentPosition = currentIndex !== nextIndex;
    const shouldActivate = next === 'active';

    // Reorder only the active sections
    if (isSameBucket && shouldActivate && differentPosition) {
      reorderSection(sectionId, nextIndex);
    }

    // Activate the section on the new position
    if (!isSameBucket && shouldActivate) {
      activateSection(sectionId, nextIndex);
    }

    // Deactivate the section on the new position
    if (!isSameBucket && !shouldActivate && sectionId !== 'manuscript') {
      deactivateSection(sectionId, nextIndex);
    }
  };

  const handleEditSection = (sectionId) => {
    if (sectionId === 'manuscript') {
      const selectedId = get(manuscript, [0, '_id']);
      setSelectedSectionId(selectedId);
      setDisplayChapters(true);
    } else {
      goToEditDraftContent(draftId, sectionId);
    }
  };

  const handleSelectSection = (sectionId) => {
    setSelectedSectionId(sectionId);
  };

  const handleNavigateBack = () => {
    const selectedId = get(content, [0, '_id']);
    setSelectedSectionId(selectedId);
    setDisplayChapters(false);
  };

  const renderNavbar = (
    <EditorNavbar
      bookTitle={bookTitle}
      dropdownTitle="Manage Sections"
      dropDownItems={dropdown}
      isSaving={isSaving}
      isPendingSave={isPendingSave}
      onSave={saveDraft}
      onExit={() => goToDraftView(draftId)}
    />
  );

  const handleResetSection = () => resetCurrentSection(selectedSectionId);
  const renderCoverImage = <CoverImage url={coverPreview} loading={isPending(preview.status)} />;
  const renderContentPreview = <ContentPreview layout={layout} elements={elements} showGuides />;

  const handleResetDraft = () => resetDraft(draftId);
  const renderToolbar = (
    <EditorFooter
      bookTitle={bookTitle}
      onReset={handleResetDraft}
      onResetSection={handleResetSection}
    >
      <Toolbar.Link to={getRouteEditDraftCover(draftId)}>Back</Toolbar.Link>
      <Toolbar.Spacer width="120px" />
      <Toolbar.Link to={getRouteEditDraftContent(draftId)}>Next</Toolbar.Link>
    </EditorFooter>
  );

  let renderSectionHeader = null;

  if (displayChapters) {
    renderSectionHeader = <SectionHeader onNavigateBack={handleNavigateBack} />;
  }

  const archivedItems = sections.filter(({ currentStatus }) => currentStatus === 'deprecated');
  const manageSections = displayChapters ? manuscript : content;

  return (
    <ManageSectionsTemplate
      navbar={renderNavbar}
      coverImage={renderCoverImage}
      contentPreview={renderContentPreview}
      toolbar={renderToolbar}
      sectionHeader={renderSectionHeader}
    >
      <SectionSorting
        selectedId={selectedSectionId}
        items={manageSections}
        archivedItems={archivedItems}
        onDrag={handleDragSection}
        onEdit={handleEditSection}
        onSelect={handleSelectSection}
      />
    </ManageSectionsTemplate>
  );
};

const MemoManageSections = React.memo(ManageSections);
const Composed = compose(
  withBook,
  withDraft,
  withManageSections,
  withCoverPreview,
)(MemoManageSections);

export default Composed;
