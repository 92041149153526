import { clamp, mapValues, flow, pick } from 'lodash-es';
import parseUnit from 'parse-unit';
import parseColor from 'parse-color';

export const getStyle = (props, scale) => {
  const fixFontSize = ({ fontSize, ...style }) => {
    let newFontSize = fontSize;

    if (fontSize && scale) {
      newFontSize = clamp(fontSize * scale, 14, 56);
    }

    return {
      ...style,
      fontSize: newFontSize,
    };
  };

  const mapRotation = ({ width, height, left, ...style }) => ({
    ...style,
    width: style.rotationAngle ? height : width,
    height: style.rotationAngle ? width : height,
    left: style.rotationAngle ? Number(width) + Number(left) : left,
  });

  const mapRotationAngle = ({ rotationAngle, ...style }) => {
    if (!rotationAngle) {
      return style;
    }

    const [value, unit] = parseUnit(rotationAngle);

    switch (unit) {
      case undefined:
      case '':
      case 'deg':
        return {
          ...style,
          rotationAngle: `${value}deg`,
        };

      default:
        return {
          ...style,
          rotationAngle: '0deg',
        };
    }
  };

  const mapColor = ({ color, ...style }) => {
    if (!color) {
      return style;
    }

    const parsed = parseColor(color);
    const rgba = Array.isArray(parsed.rgba) ? parsed.rgba : [0, 0, 0, 0.7];

    return {
      ...style,
      color: `rgba(${rgba.join(',')})`,
    };
  };

  const getTextAlign = ({ textAlign, ...style }) => {
    if (!textAlign) {
      return {
        ...style,
        textAlign: 'inherit',
      };
    }

    const adjusted = `${textAlign}`.trim().toLowerCase();

    const allowed = ['left', 'center', 'right', 'justify'];
    const align = allowed.indexOf(adjusted) >= 0 ? adjusted : 'inherit';

    return {
      ...style,
      textAlign: align,
    };
  };

  const getFontWeight = ({ fontWeight, ...style }) => {
    if (!fontWeight) {
      return {
        ...style,
        fontWeight: 'normal',
      };
    }

    const adjusted = `${fontWeight}`.trim().toLowerCase();

    const allowed = [
      'normal',
      'bold',
      'bolder',
      'lighter',
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'initial',
      'inherited',
    ];
    const weight = allowed.indexOf(adjusted) >= 0 ? adjusted : 'normal';

    return {
      ...style,
      fontWeight: weight,
    };
  };

  const mapUnits = (style) => {
    const styles = [
      'margin',
      'marginTop',
      'marginBottom',
      'marginLeft',
      'marginRight',
      'padding',
      'paddingTop',
      'paddingBottom',
      'paddingLeft',
      'paddingRight',
      'top',
      'bottom',
      'left',
      'right',
      'width',
      'height',
      'minWidth',
      'maxWidth',
      'minHeight',
      'maxHeight',
      'lineHeight',
      'fontSize',
    ];
    const properties = pick(style, styles);

    const fixed = mapValues(properties, (property) => {
      if (property === 'auto') {
        return property;
      }

      const [value, unit] = parseUnit(`${property}`.trim());

      if (unit === undefined || unit === '') {
        return `${value}px`;
      }

      return `${value}${unit}`;
    });

    return {
      ...style,
      ...fixed,
    };
  };

  return flow(
    fixFontSize,
    mapRotation,
    mapRotationAngle,
    mapUnits,
    getFontWeight,
    mapColor,
    getTextAlign,
  )(props);
};
