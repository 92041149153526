import { Space } from 'antd';
import cardDetails from 'modules/v2/pages/PaymentSteps/payments';
import S from '../CardItem/styles';

const CardItemStripe = ({ creditData, isActive }) => {
  const { id, brand: card, last4 } = creditData;

  return (
    <S.Container isActive={isActive}>
      <S.Radio value={id}>
        <S.CreditWrapper direction="horizontal">
          <div style={{ display: 'inline-flex' }}>
            <S.CardImg src={cardDetails[card]} alt={card} />
            <S.CardWrapper>
              <S.CardName>{card}</S.CardName>
              <Space direction="horizontal">
                <S.DotContainer>
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                </S.DotContainer>
                <S.Description>{last4}</S.Description>
              </Space>
            </S.CardWrapper>
          </div>
          <S.CardTag>Primary</S.CardTag>
        </S.CreditWrapper>
      </S.Radio>
    </S.Container>
  );
};

export default CardItemStripe;
