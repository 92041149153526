import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const convert = (value, scale = 1) => Math.round(scale * value);

const Progress = styled.div`
  display: inline-block;
  width: 100%;
  max-height: ${({ scale }) => convert(6, scale)}px;
  margin: ${({ scale }) => convert(30, scale)}px;
  max-width: ${({ scale }) => convert(250, scale)}px;
}`;

const Bar = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${color.Loadergrey};
  border-radius: ${({ scale }) => convert(3, scale)}px;
  height: ${({ scale }) => convert(6, scale)}px;
  min-width: ${({ scale }) => convert(50, scale)}px;

  &::before {
    display: block;
    position: absolute;
    content: '';
    left: -250px;
    width: 50%;
    height: ${({ scale }) => convert(6, scale)}px;
    background-color: ${color.PrimaryColor};
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    from {
      left: -250px;
      width: 30%;
    }
    50% {
      width: 30%;
    }
    70% {
      width: 70%;
    }
    80% {
      left: 50%;
    }
    95% {
      left: 120%;
    }
    to {
      left: 100%;
    }
  }
`;

export default {
  Progress,
  Bar,
};
