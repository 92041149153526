import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withAuth } from 'modules/v2/containers';
import { setItem, getItem, removeItem } from 'modules/v2/utils/legacy';
import { Loader } from 'modules/v2/common/components';
import env from 'environments';

import Login from 'modules/v2/components/Login';
import NewLogin from 'modules/v2/components/NewLogin';
import { checkEmail, notification } from 'modules/v2/common/utils';
import { getLoginAuth } from 'modules/api/auth';
import { toast } from 'react-toastify';
import { log, logError } from 'modules/api/afyLogger/afyLogger';

/**
 * Login Container at User Facing App
 */
class LoginWrap extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    username: '',
    password: '',
    message: '',
    malformedEmail: false,
    statusCode: 99,
  };

  componentDidMount() {
    this.clearStorage();

    const userData = JSON.parse(getItem('userData'));

    // Check if userData exists in LocalStorage
    // Auto populates only  user email in username if user checked the option of Remember Me
    if (userData) {
      this.setState({
        username: userData.email,
      });
    }

    const searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.get('expired')) {
      notification.warning({
        title: 'Your session has expired',
        description: 'Please login again',
        autoClose: 10000,
      });
    }
  }

  /**
   * makes the call to login API if username and password field are not empty
   * Redirects to dashboard page on sucessfull Authentication
   */
  handleLogin = () => {
    const { loginUser } = this.props;
    const { username, password } = this.state;

    // make API call when there is a valid email and password entered
    if (username && password) {
      if (checkEmail(username)) {
        return loginUser({ username, password })
          .then((res) => {
            if (res.payload.statusCode === 1) {
              this.setState({
                statusCode: res.payload.statusCode,
                message: res.payload.message,
              });
            }
            this.setSession(res?.payload?.data?.properties?.email);
            log('login', 'login-page');
          })
          .catch(() => {
            toast.dismiss();
            logError('login-error', 'login-page', username);
          });
      }
      return this.setState({
        malformedEmail: true,
      });
    }
    return this.setState({
      malformedEmail: true,
      message: 'Email/Password is required',
    });
  };

  /**
   * Sets Token, RefreshToken and Userdata in localStorage after successfull authentication of user
   * Note: All Api calls will require Token for validating user
   */
  setSession = async (username) => {
    let welcomeJourneyEligible = false;
    try {
      const user = await getLoginAuth(username);
      if (user?.welcomeJourneyEligible) {
        welcomeJourneyEligible = true;
      }
    } catch (error) {
      welcomeJourneyEligible = false;
    }
    const searchParams = new URLSearchParams(document.location.search);
    let redirectParams;

    const { auth, history } = this.props;
    const {
      login: {
        data: { token },
      },
    } = auth;

    if (auth?.login?.data?.token) {
      const userDataUpdated = auth?.login?.data?.properties;
      userDataUpdated.welcomeJourneyEligible = welcomeJourneyEligible;

      setItem('token', auth.login.data.token);
      setItem('refresh_token', auth.login.data.refresh_token);
      setItem('userData', userDataUpdated);
      if (searchParams.get('redirect')) {
        redirectParams = searchParams.get('redirect');
      } else if (welcomeJourneyEligible) {
        redirectParams = '/welcome-journey';
      } else {
        redirectParams = '/';
      }
      history.push(redirectParams);
    }
  };

  /**
   * On Change handler for username and password
   */
  handleChange = (event) => {
    if (!event || !event.target) {
      return;
    }

    const { target } = event;
    const { name } = event.target;

    if (name === 'username') {
      this.props.setUserEmail(target.value);
    }

    this.setState({
      [name]: target.value,
    });
  };

  /**
   * Removes all the localStorage contents except userData
   */
  clearStorage = () => {
    removeItem('token');
    removeItem('refresh_token');
    removeItem('selectedAddress');
    removeItem('address');
    removeItem('numberOfPrints');
    removeItem('draft');
    removeItem('section');
    removeItem('template');
    removeItem('book');
    removeItem('numberOfPrints');
    removeItem('adminBookId');
    removeItem('admin');
    removeItem('adminSectionId');
    removeItem('adminTemplateId');
    removeItem('adminBookPageId');
    removeItem('userData');
    removeItem('dentistOrderData');
    removeItem('landingPages');
  };

  render() {
    const { username, message, malformedEmail, statusCode } = this.state;
    const { auth, alert } = this.props;
    const isLoaded = !auth.loading && auth.loaded;

    if (!isLoaded) {
      return <Loader title="Logging in" />;
    }

    const LoginComponent = env.AFY_NEW_LOGIN ? NewLogin : Login;

    return (
      <LoginComponent
        username={username}
        handleChange={this.handleChange}
        message={message}
        handleLogin={this.handleLogin}
        alert={alert}
        invalidEmail={malformedEmail}
        statusCode={statusCode}
      />
    );
  }
}

LoginWrap.propTypes = {
  auth: PropTypes.shape({
    login: PropTypes.shape({
      data: PropTypes.shape({
        token: PropTypes.string.isRequired,
        properties: PropTypes.shape({
          accountType: PropTypes.string.isRequired,
          city: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          country: PropTypes.string.isRequired,
          firstname: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      refresh_token: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(withAuth(LoginWrap));
