//

import { bookCoverArray } from 'modules/previewBook/constants';
import { getBookStyle, getBookLabelStyle } from 'modules/previewBook/utils';
import { Heading } from '../UIComponents';
import S from './styles';

const AllBooksPreview = ({ uploadedImage, handleBookSelect, selectedBookId, authorName }) => (
  <S.ChooseAnotherBookCover>
    <Heading>Choose Another Book Cover</Heading>
    <S.BookBox>
      {bookCoverArray.map(({ id, front, text }) => (
        <S.BookBoxMain key={id} onClick={() => handleBookSelect(id, text)}>
          <S.ImageBox active={id === selectedBookId}>
            {uploadedImage ? (
              <img src={uploadedImage} alt="user-avatar" style={getBookStyle(id)} />
            ) : null}
            <S.ChooseAnotherBookImage src={front} />
            <S.AuthorNameBottom style={getBookLabelStyle(id)}>{authorName}</S.AuthorNameBottom>
            <S.AuthorNameLeft>{authorName}</S.AuthorNameLeft>
          </S.ImageBox>
          <S.BooksText>{text}</S.BooksText>
        </S.BookBoxMain>
      ))}
    </S.BookBox>
  </S.ChooseAnotherBookCover>
);

export default AllBooksPreview;
