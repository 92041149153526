import S from '../../sections/Chart/styles';
import SelectedMetrics from '../SelectedMetrics';

const Tooltip = ({ title, items }) => {
  return (
    <S.TooltipWrapper>
      <S.TooltipTitleWrapper>
        <S.TooltipTitle>All Campaigns</S.TooltipTitle>
        <S.TooltipSubTitle>{title}</S.TooltipSubTitle>
      </S.TooltipTitleWrapper>
      <S.TooltipDescriptionWrapper>
        <SelectedMetrics items={items} />
      </S.TooltipDescriptionWrapper>
    </S.TooltipWrapper>
  );
};

export default Tooltip;
