import styled from 'styled-components';
import { InputNumber } from 'antd';

const StyledInputNumber = styled(InputNumber)`
  margin-right: 15px;
  outline: none;
`;

export default {
  StyledInputNumber,
};
