import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CustomFlowbiteTheme, Modal } from 'flowbite-react';
import { deletePaymentProfile } from 'modules/api';
import { Button, Notification } from 'modules/v2/common/AtomicDesign/atoms';

const customTheme: CustomFlowbiteTheme['modal'] = {
  root: {
    show: {
      on: 'flex bg-neutral-900 bg-opacity-50 dark:bg-opacity-80',
      off: 'hidden',
    },
  },
  content: {
    base: 'relative h-full w-fit w-[440px] md:h-auto',
  },
  footer: {
    base:
      'flex space-x-2 rounded-b border-neutral-200 min-h-[91px] px-6 py-[13px] dark:border-neutral-600',
  },
};

const DeleteCardModal = ({ showModal, setShowModal, cardInformation }) => {
  const queryClient = useQueryClient();

  const [showNotification, setShowNotification] = useState(false);

  const { mutate: removeCard } = useMutation(['deletePaymentProfile'], deletePaymentProfile, {
    onSuccess: async () => {
      queryClient.invalidateQueries('getDefaultPaymentProfile');
      queryClient.invalidateQueries('getPaymentProfilesList');
      setShowNotification(true);
    },
  });

  const handleDelete = (id: number) => {
    removeCard(id);
    setShowModal(false);
  };

  useEffect(() => {
    let timer;
    if (showNotification) {
      timer = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showNotification]);

  return (
    <>
      <Modal
        show={showModal}
        size="sm"
        onClose={() => setShowModal(false)}
        theme={customTheme}
        popup
      >
        <Modal.Body>
          <div className="text-center pt-6">
            <p className="font-bold mb-6">Are you sure you want to delete this card?</p>
            <p className="text-sm mb-3.5">This card will be deleted.</p>
            <div className="p-6 bg-primary-50 text-sm mb-6 flex justify-center items-center gap-2">
              <img
                src={cardInformation.cardImage}
                alt={cardInformation.cardImage}
                className="w-11 h-8"
              />
              <span className="text-sm font-semibold">{cardInformation.cardNumber}</span>
            </div>
            <div className="flex justify-center gap-4">
              <Button color="muted" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button color="danger" onClick={() => handleDelete(cardInformation.id)}>
                Yes, Delete card
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showNotification && <Notification description="Your card has been deleted successfully." />}
    </>
  );
};

export default DeleteCardModal;
