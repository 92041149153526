import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { noop } from 'lodash-es';

import { TableButton, PreviousButton, NextButton, NoContent } from 'modules/common/components';

import S from './styles';

const Table = (props) => {
  const {
    hasData,
    loading,
    dataSource,
    rowKey,
    columns,
    title,
    pagination,
    scroll,
    noContentMessage = '',
    ...extra
  } = props;

  const customPagination = (currentRender, type) => {
    const isPageActive = currentRender === pagination?.current;
    const isOnlyPage = pagination?.lastPage === 1;
    if (type === 'prev') {
      return (
        <PreviousButton type="link">
          <ArrowLeftOutlined /> Previous
        </PreviousButton>
      );
    }
    if (type === 'page') {
      return (
        <TableButton $isActive={isPageActive || isOnlyPage} type="link">
          {currentRender}
        </TableButton>
      );
    }
    if (type === 'next') {
      return (
        <NextButton type="link">
          Next <ArrowRightOutlined />
        </NextButton>
      );
    }
    return <span>•••</span>;
  };

  const tablePagination = {
    position: 'bottom',
    pageSize: pagination?.perPage || 15,
    size: 'default',
    itemRender: customPagination,
    showSizeChanger: false,
    showQuickJumper: false,
    ...pagination,
  };
  const tableScroll = { x: false, y: false, ...scroll };

  const noDataTable = () => {
    return <NoContent message={noContentMessage} />;
  };

  const customNoDataMessage = {
    emptyText: noDataTable,
  };

  return (
    <S.Table
      loading={loading}
      hasData={hasData}
      rowKey={rowKey}
      bordered
      columns={columns}
      dataSource={dataSource}
      size="middle"
      title={title}
      pagination={pagination ? tablePagination : false}
      footer={noop}
      scroll={tableScroll}
      locale={customNoDataMessage}
      {...extra}
    />
  );
};

export default Table;
