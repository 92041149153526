import styled from 'styled-components';
import { InputNumber } from 'antd';

const StyledInput = styled(InputNumber)`
  margin-right: 15px;
  outline: none;
`;

export default {
  StyledInput,
};
