export const AltArrowRight = ({ width = 16, height = 16, fill = '#252525' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <g id="Iconer">
      <path
        id="Vector"
        d="M6.3999 4.80078L9.5999 8.00078L6.3999 11.2008"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
