import { Alert as BaseAlert, Button as BaseButton } from 'antd';
import styled from 'styled-components';

const Alert = styled(BaseAlert)`
  margin: 0 auto;
  text-align: center;
`;

const Button = styled(BaseButton)`
  padding: 0 !important;
`;

export default {
  Alert,
  Button,
};
