import React, { useState } from 'react';
import { find, get } from 'lodash-es';
import env from 'environments';

import { ModalAddressView as AddressView } from 'modules/v2/common/views';
import { useGetCustomerSubscriptionStatus } from 'modules/v2/utils';
import {
  PreflightView,
  CheckoutView,
  ReviewView,
  ConfirmationView,
} from 'modules/v2/pages/Dentist/OrderGuideModal/publish/views';
import Wizard from 'modules/v2/pages/Dentist/OrderGuideModal/components/Wizard';
import { views } from 'modules/v2/pages/Dentist/OrderGuideModal/constants';
import { fetchUserCredits, fetchUserAddresses } from 'modules/api';
import { useQuery } from 'react-query';
import { isLoading } from 'store/status';
import S from 'modules/publish/styles';

const ViewRendererScreen = ({
  // Data
  activeView,
  countries,
  order,
  states,
  setPayloadData,
  payloadData,
  isOrderLoading,
  guidePrefillFormData,
  guidePacketName,
  // Callbacks
  onDone,
  onCreateAddress,
  onDeleteAddress,
  onEditAddress,
  onEditCover,
  onEditSection,
  onSubmitOrder,
  setActiveView,
}) => {
  const customerSubscriptionStatus = useGetCustomerSubscriptionStatus();
  const isTrialCustomer = customerSubscriptionStatus === 'trialing';

  // getting addresses:
  const [addresses, setAddresses] = useState([]);
  const { data: userAddress, isLoading: isAddressLoading } = useQuery(
    ['getUserAddress'],
    fetchUserAddresses,
    {
      onSuccess: (data) => {
        const result = data?.data?.data;
        let resultAddresses = [];
        if (result?.billingAddress) {
          resultAddresses.push(result.billingAddress);
        }
        if (result?.shippingAddress?.length) {
          resultAddresses = [...resultAddresses, ...result.shippingAddress];
        }
        setAddresses(resultAddresses);
      },
    },
  );

  // Getting Credits
  const { data: userBookCredit, isLoading: isCreditLoading } = useQuery(
    ['getUserCredits'],
    fetchUserCredits,
  );
  const credits = userBookCredit?.data?.data?.credits || 0;

  const [fields, setFields] = useState({
    selectedAddressId: null,
    numberOfPrints: null,
    isDigital: null,
  });
  const [formAddressId, setFormAddressId] = useState(null);

  // Should display only when the publish is not being processed or is completed
  const isPublishing = activeView === views.REVIEW && isLoading(order.status);
  const isPublished = activeView === views.CONFIRMATION;
  const displayWizard = !isPublishing && !isPublished;

  const handleSubmitCheckout = ({ isDigital, numberOfPrints }) => {
    const isDigitalOnly = isDigital && numberOfPrints === 0;
    const hasAddressSelected = fields.selectedAddressId !== null;
    const nextFields = {
      ...fields,
      numberOfPrints,
      isDigital,
    };

    let nextView = views.ADDRESS_LIST;

    if (isDigitalOnly) {
      nextView = views.REVIEW;
    }

    if (!isDigitalOnly && hasAddressSelected) {
      nextView = views.REVIEW;
    }
    setPayloadData({ ...payloadData, quantity: numberOfPrints });
    setFields(nextFields);
    setActiveView(nextView);
  };

  const handleSubmitGuideDetails = (data) => {
    const frontCoverDentist = [data.firstDentist];
    const frontCover = data.frontCover
      ? [...frontCoverDentist, ...data.frontCover]
      : [...frontCoverDentist];
    setPayloadData({
      ...payloadData,
      practiceName: data.practiceName,
      practicePhone: data.practicePhone,
      practiceEmail: data.practiceEmail,
      practiceLogo: data.practiceLogo,
      practiceWebsite: data.practiceWebsite,
      practiceAddress: {
        addressLine1: data.shippingAddress?.addressLine1,
        city: data.shippingAddress?.city,
        state: data.shippingAddress?.state,
        pincode: data.shippingAddress?.pincode,
        country: data.shippingAddress?.country,
      },
      frontCover,
    });
    const nextView = views.CHECKOUT;
    setActiveView(nextView);
  };

  const handleSelectAddress = (selectedAddressId) => {
    const result = addresses.filter((items) => items._id === selectedAddressId);
    if (result.length) {
      const response = {
        addressLine1: result[0].addressLine1 || '',
        city: result[0].city || '',
        state: result[0].state || '',
        pincode: result[0].pincode || '',
        country: result[0].country || '',
        email: result[0].email || '',
        firstName: result[0].firstName || '',
        lastName: result[0].lastName || '',
      };
      setPayloadData({ ...payloadData, shippingAddress: response });
    }
    setFields({ ...fields, selectedAddressId });
    setActiveView(views.REVIEW);
  };

  const handleEditQuantity = () => setActiveView(views.CHECKOUT);

  const handleChangeAddress = () => setActiveView(views.ADDRESS_LIST);

  const Views = () => {
    switch (activeView) {
      case views.GUIDEDETAILS: {
        const loading = false;
        return (
          <PreflightView
            loading={loading}
            setPayloadData={setPayloadData}
            payloadData={payloadData}
            onEditCover={onEditCover}
            onEditSection={onEditSection}
            onSubmit={handleSubmitGuideDetails}
            guidePrefillFormData={guidePrefillFormData}
          />
        );
      }

      case views.CHECKOUT: {
        return (
          <CheckoutView
            numberOfPrints={fields.numberOfPrints}
            isDigital={fields.isDigital}
            onSubmit={handleSubmitCheckout}
            credits={credits}
            isCreditLoading={isCreditLoading}
            guidePacketName={guidePacketName}
            isTrialCustomer={isTrialCustomer}
          />
        );
      }

      case views.ADDRESS_LIST:
      case views.ADDRESS_FORM: {
        const { selectedAddressId } = fields;

        return (
          <AddressView
            loading={isAddressLoading}
            addresses={addresses}
            countries={countries}
            states={states}
            onSelect={handleSelectAddress}
            onCreate={onCreateAddress}
            onEdit={onEditAddress}
            onDelete={onDeleteAddress}
            activeView={activeView}
            setActiveView={setActiveView}
            selectedAddressId={selectedAddressId}
            formAddressId={formAddressId}
            setFormAddressId={setFormAddressId}
            setAddresses={setAddresses}
            isDentistProject
          />
        );
      }

      case views.REVIEW: {
        const { isDigital, numberOfPrints, selectedAddressId } = fields;
        const selectedAddress = find(addresses, ({ _id }) => _id === selectedAddressId);
        const isDigitalOnly = numberOfPrints === 0 && isDigital;
        const loading = isOrderLoading;

        return (
          <ReviewView
            loading={loading}
            numberOfPrints={numberOfPrints}
            selectedAddress={!isDigitalOnly && selectedAddress}
            isDigitalBook={isDigital}
            onEditQuantity={handleEditQuantity}
            onChangeAddress={handleChangeAddress}
            onComplete={onSubmitOrder}
            payloadData={payloadData}
            guidePacketName={guidePacketName}
          />
        );
      }

      case views.CONFIRMATION: {
        const orderId = get(order.data, 'orderId', '');
        const shippingAddress = get(order.data, 'shippingAddress');
        const contactPhone = env.SUPPORT_PHONE;
        const contactEmail = env.SUPPORT_EMAIL;

        return (
          <ConfirmationView
            orderId={orderId}
            shippingAddress={shippingAddress}
            contactPhone={contactPhone}
            contactEmail={contactEmail}
            onDone={onDone}
            guidePacketName={guidePacketName}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <S.Wrapper>
      {displayWizard && <Wizard view={activeView} fields={fields} onChange={setActiveView} />}
      <Views />
    </S.Wrapper>
  );
};

export default React.memo(ViewRendererScreen);
