//

import SelectField from 'modules/common/components/SelectField';
import { getCurrentTimeZone, getTZDataTimeZonesList } from 'modules/common/utils/timezone';

import S from './styles';

const TimeZoneDropDown = ({ onChange, defaultValue = getCurrentTimeZone }) => (
  <S.SelectFieldWrap>
    <SelectField
      defaultValue={defaultValue}
      options={getTZDataTimeZonesList()}
      onChange={onChange}
      showSearch
    />
  </S.SelectFieldWrap>
);

export default TimeZoneDropDown;
