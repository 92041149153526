import { useState, useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { TextInput } from 'flowbite-react';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { defaultTextInputTheme } from 'modules/v2/common/AtomicDesign/atoms/TextInput/theme';
import { SwitchButton } from 'modules/v2/common/AtomicDesign/atoms';
import { ErrorMessage } from 'modules/v2/common/components';
import ImageUploaderModal from '../../../ImageUploaderModal';

import { inputErrorStyles } from '../../constants';

const Magazine = ({ step = 'magazine', updateMagazineData }) => {
  const { magazineDataFormik, setFormikContext } = updateMagazineData;
  const [brokerLogoModal, setBrokerLogoModal] = useState(false);
  const [isEditImage, setIsEditImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [toggleBrokerInfo, setToggleBrokerInfo] = useState(false);

  const { values = {}, setFieldValue, errors } = magazineDataFormik || {};
  const targetLocation = step.concat('.', '_formFields');

  useEffect(() => {
    const {
      _formFields: { brokerName, address, brokerLogo },
    } = values.magazine;
    if (!isEmpty(brokerName) || !isEmpty(address) || !isEmpty(brokerLogo)) {
      setToggleBrokerInfo(true);
    }
  }, [values]);

  const handleChange = async (e) => {
    const {
      target: { name = '', value = '', type = '' },
    } = e || {};

    const { validateForm } = magazineDataFormik || {};
    const locationToSave = step.concat('.', '_formFields', '.', name);
    let newValue = value;

    await validateForm().then((err) => {
      if (Object.prototype.hasOwnProperty.call(err, step)) {
        return true;
      }
      return false;
    });

    if (type === 'tel') {
      const strippedInput = value.replace(/[^\d]/g, '');
      let maskedPhoneNumber = '';
      for (let i = 0; i < strippedInput.length && i < 10; i++) {
        if (i === 3 || i === 6) {
          maskedPhoneNumber += '-';
        }
        maskedPhoneNumber += strippedInput[i];
      }
      newValue = maskedPhoneNumber;
    }

    setFieldValue(locationToSave, newValue);
  };

  const handleKeyup = async () => {
    const { validateForm } = magazineDataFormik || {};
    await validateForm().then((err) => {
      if (Object.prototype.hasOwnProperty.call(err, step)) {
        return true;
      }
      return false;
    });
  };

  const toggleBrokerLogoModal = () => {
    setBrokerLogoModal(!brokerLogoModal);
  };

  const toggleCancelEdit = () => {
    setIsEditImage(!isEditImage);
  };

  const errorMsg = {
    agentName: get(errors, `${targetLocation}.agentName`),
    brokerName: get(errors, `${targetLocation}.brokerName`),
    website: get(errors, `${targetLocation}.website`),
    email: get(errors, `${targetLocation}.email`),
    phoneNumber: get(errors, `${targetLocation}.phoneNumber`),
    address: get(errors, `${targetLocation}.address`),
  };

  const handleImageUpload = (value, keyName) => {
    const locationToSave = step.concat('.', '_formFields', '.', keyName);
    setFieldValue(locationToSave, value);
    toggleBrokerLogoModal();
  };

  const onRemoveImage = (path) => {
    setFieldValue(path, '');
    setImageUrl(null);
    setIsEditImage(false);
  };

  const allowedResolutionsTextProfile = { width: 520, height: 520 };
  const allowedResolutionsTextLogo = { width: 540, height: 192 };

  const profileImage = `${targetLocation}.profileImage`;
  const brokerageLogo = `${targetLocation}.brokerageLogo`;

  const onEditImage = (type) => {
    setIsEditImage(true);
    if (type === 'rm_profile') {
      setImageUrl(get(values, profileImage));
    } else if (type === 'brokerageLogo') {
      setImageUrl(get(values, brokerageLogo));
    }
  };

  const onHandleResetFormFields = () => {
    // eslint-disable-next-line no-underscore-dangle
    const formFields = values.magazine._formFields;

    if (isEmpty(formFields)) return;

    Object.keys(formFields).forEach((field) => {
      const locationToSave = `${targetLocation}.${field}`;
      const {
        values: { baseReplacers },
      } = magazineDataFormik;
      if (isEmpty(baseReplacers)) {
        setFieldValue(locationToSave, '');
      } else {
        setFieldValue(locationToSave, baseReplacers[field]);
      }
    });

    setFieldValue(`${targetLocation}.brokerName`, '');
    setFieldValue(`${targetLocation}.address`, '');
    setImageUrl(get(values, profileImage));
    setImageUrl(get(values, brokerageLogo));
    setToggleBrokerInfo(false);
    setFormikContext('current', -1);
  };

  return (
    <div>
      <div className="left flex flex-col justify-center items-start self-stretch mt-[37px]">
        <div className="flex flex-col items-start">
          <div className="text-neutral-800 text-sm font-semibold">Your photo</div>
          <div className="text-neutral-500 text-sm font-medium">Recommended size: 520 x 520</div>
        </div>
        <div className="flex items-center gap-6 self-stretch mt-3.5">
          <div className="w-full">
            <ImageUploaderModal
              imageDimensions={allowedResolutionsTextProfile}
              type="rm_profile"
              uploadImageType="rm_profile"
              modalTitle="Profile Image"
              imageUrl={get(values, profileImage)}
              selectAreaWidth="100%"
              selectAreaJustifyContent="start"
              onSuccess={(value) => handleImageUpload(value, 'profileImage')}
              isOpen={brokerLogoModal === 'photo'}
              onCancel={toggleBrokerLogoModal}
              handleOpenModal={() => setBrokerLogoModal('photo')}
              onRemoveImage={(e) => {
                onRemoveImage(profileImage);

                e.stopPropagation();
              }}
              onEditImage={(e) => {
                onEditImage('rm_profile');
                setBrokerLogoModal('photo');
                e.stopPropagation();
              }}
              imageUrlForEdit={imageUrl}
              isEditImage={isEditImage}
              toggleCancelEdit={toggleCancelEdit}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="w-full flex flex-col gap-4">
          <div className="w-full flex-col gap-y-4 lg:flex lg:flex-row gap-4">
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex flex-col gap-2">
                Agent name
                <TextInput
                  theme={defaultTextInputTheme}
                  style={errorMsg.agentName && inputErrorStyles}
                  id="agentName"
                  name="agentName"
                  type="text"
                  value={get(values, `${targetLocation}.agentName`) || ''}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
              <ErrorMessage>{errorMsg.agentName}</ErrorMessage>
            </div>
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex flex-col gap-2">
                Phone number
                <TextInput
                  theme={defaultTextInputTheme}
                  style={errorMsg.phoneNumber && inputErrorStyles}
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  inputMode="tel"
                  value={get(values, `${targetLocation}.phoneNumber`) || ''}
                  onChange={handleChange}
                  onKeyUp={handleKeyup}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                />
              </div>
              <ErrorMessage>{errorMsg.phoneNumber}</ErrorMessage>
            </div>
          </div>
          <div className="w-full flex-col gap-y-4 lg:flex lg:flex-row gap-4">
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex flex-col gap-2">
                Email address
                <TextInput
                  theme={defaultTextInputTheme}
                  style={errorMsg.email && inputErrorStyles}
                  id="email"
                  name="email"
                  type="email"
                  inputMode="email"
                  placeholder=""
                  value={get(values, `${targetLocation}.email`) || ''}
                  onChange={handleChange}
                  onKeyUp={handleKeyup}
                />
              </div>
              <ErrorMessage>{errorMsg.email}</ErrorMessage>
            </div>
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex flex-col gap-2">
                Website
                <TextInput
                  theme={defaultTextInputTheme}
                  style={errorMsg.website && inputErrorStyles}
                  id="website"
                  name="website"
                  type="url"
                  value={get(values, `${targetLocation}.website`) || ''}
                  onChange={handleChange}
                  onKeyUp={handleKeyup}
                  placeholder="Optional"
                />
              </div>
              <ErrorMessage>{errorMsg.website}</ErrorMessage>
            </div>
          </div>
          <div className="w-full my-[34px] h-[1px] border-t" />
          <div className="w-full flex gap-4">
            <div className="inline-flex items-center cursor-pointer">
              <SwitchButton checked={toggleBrokerInfo} setChecked={setToggleBrokerInfo} />
              <span className="ms-3 text-sm font-normal text-neutral-900">
                I want to add my brokerage information
              </span>
            </div>
          </div>
          <div className={`${toggleBrokerInfo ? 'flex' : 'hidden'} mt-6 flex flex-col gap-[8px]`}>
            <div className="flex flex-col items-start">
              <div className="text-neutral-800 text-sm font-semibold">Broker company logo</div>
              <div className="text-neutral-500 text-sm font-medium">
                {`Recommended size: ${allowedResolutionsTextLogo.width}x${allowedResolutionsTextLogo.height}`}
              </div>
            </div>
            <div>
              <ImageUploaderModal
                imageDimensions={allowedResolutionsTextLogo}
                type="brokerageLogo"
                uploadImageType="brokerageLogo"
                imageUrl={get(values, brokerageLogo)}
                selectAreaWidth="100%"
                selectAreaJustifyContent="center"
                onSuccess={(value) => handleImageUpload(value, 'brokerageLogo')}
                isOpen={brokerLogoModal === 'logo'}
                onCancel={toggleBrokerLogoModal}
                handleOpenModal={() => setBrokerLogoModal('logo')}
                onRemoveImage={(e) => {
                  onRemoveImage(brokerageLogo);
                  e.stopPropagation();
                }}
                onEditImage={(e) => {
                  onEditImage('brokerageLogo');
                  setBrokerLogoModal('logo');
                  e.stopPropagation();
                }}
                imageUrlForEdit={imageUrl}
                isEditImage={isEditImage}
                toggleCancelEdit={toggleCancelEdit}
              />
            </div>
            <div className="w-full flex-col gap-y-4 lg:flex lg:flex-row  gap-4">
              <div className="w-full flex flex-col gap-0">
                <div className="w-full flex flex-col gap-2">
                  Broker name
                  <TextInput
                    theme={defaultTextInputTheme}
                    style={errorMsg.brokerName && inputErrorStyles}
                    id="brokerName"
                    name="brokerName"
                    type="text"
                    value={get(values, `${targetLocation}.brokerName`)}
                    onChange={handleChange}
                    placeholder="Optional"
                  />
                </div>
                <ErrorMessage>{errorMsg.brokerName}</ErrorMessage>
              </div>
              <div className="w-full flex flex-col gap-0">
                <div className="w-full flex flex-col gap-2">
                  Brokerage address
                  <TextInput
                    theme={defaultTextInputTheme}
                    style={errorMsg.address && inputErrorStyles}
                    id="address"
                    name="address"
                    type="text"
                    value={get(values, `${targetLocation}.address`)}
                    onChange={handleChange}
                    placeholder="Optional"
                  />
                </div>
                <ErrorMessage>{errorMsg.address}</ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 justify-start mt-6">
        <Button buttonType="button" color="light" onClick={onHandleResetFormFields}>
          Cancel
        </Button>
        <Button color="dark" buttonType="submit" onClick={magazineDataFormik.handleSubmit}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

export default Magazine;
