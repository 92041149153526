import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchCountryList, resource, getError } from 'modules/api';

import { GET_COUNTRIES } from 'modules/dashboard/store/constants';
import { getStatesByCountries } from 'modules/dashboard/store/actions';

import { getCountriesPayload } from './utils';

export function* onGetCountryAndState() {
  try {
    const request = resource(GET_COUNTRIES, fetchCountryList, getCountriesPayload);
    const payload = yield call(request);

    yield put(getStatesByCountries(payload));

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetCountries() {
  yield takeEvery(GET_COUNTRIES, onGetCountryAndState);
}
