//

import { NO_PRINTS, STEP } from 'modules/editor/components/CheckoutForm/constants';
import { fixValue } from 'modules/editor/components/CheckoutForm/utils';

import S from './styles';

const CreditsNumericInput = ({
  name,
  credits,
  field,
  setFieldValue,
  disabled,
  'data-testid': dataTestId,
}) => {
  const handleChange = (newValue) => {
    if (newValue !== '') {
      setFieldValue(name, fixValue(newValue, field.value, credits));
    }
  };

  return (
    <S.StyledInputNumber
      min={NO_PRINTS}
      max={credits}
      value={field && field.value}
      onChange={handleChange}
      disabled={disabled}
      step={STEP}
      size="large"
      data-testid={dataTestId}
    />
  );
};

export default CreditsNumericInput;
