import { call, takeEvery } from 'redux-saga/effects';

import { fetchUserCredits, resource, getError } from 'modules/api';

import { GET_USER_CREDITS } from 'modules/publish/store/constants';

import { getCreditsPayload } from './utils';

export function* onGetUserCredits() {
  try {
    const request = resource(GET_USER_CREDITS, fetchUserCredits, getCreditsPayload);
    const payload = yield call(request);

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}
export function* watchGetUserCredits() {
  yield takeEvery(GET_USER_CREDITS, onGetUserCredits);
}
