const UpdateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
    <g transform="translate(-193)">
      <path d="M0 0H64V64H0z" style={{ fill: '#fff' }} transform="translate(193)" />
      <g transform="translate(-476 -231.997)">
        <path
          d="M0 0H56V56H0z"
          style={{ fill: 'rgba(255,255,255,0)' }}
          transform="translate(673 236.001)"
        />
        <path
          d="M15.852 48.941a21.9 21.9 0 005.742.931 15.932 15.932 0 005.7-.914v-1.763l-11.443-.017zM22.796 20.738l-.019 10.31a3.347 3.347 0 011.634 1.01l.122.141.122.151.054.1a3.287 3.287 0 01.157.3l.1.194.022.074a2.959 2.959 0 01-1.373 3.562 3.466 3.466 0 01-4.558-.974 2.935 2.935 0 011.045-4.226 3.4 3.4 0 01.953-.365l.019-10.282h-1.49c-1.118 6.162-4.185 9.421-8.157 13.533L16.215 46l11.351.017 4.831-11.715c-4.341-4.615-6.794-7.635-8.109-13.561z"
          style={{ fill: '#972023' }}
          transform="translate(679.09 235.997)"
        />
        <path
          d="M39.603 22.557L21.909 0 4.218 22.557a19.13 19.13 0 002.221 26.3 22.708 22.708 0 0015.471 5.93 22.715 22.715 0 0015.473-5.931 19.131 19.131 0 002.221-26.294zM34.49 46.174a18 18 0 01-6.927 3.982 19.2 19.2 0 01-11.35-.013 17.943 17.943 0 01-6.888-3.97 15.558 15.558 0 01-1.808-21.387L21.909 6.44l14.388 18.347a15.557 15.557 0 01-1.8 21.386z"
          style={{ fill: '#972023' }}
          transform="translate(679.09 235.997)"
        />
      </g>
    </g>
  </svg>
);

export default UpdateIcon;
