import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { completeSchedulingTaskForStaging, fetchJourneyTodos } from 'modules/api/common';
import { isEmpty } from 'lodash';
import { getEnv } from 'modules/api/utils';

const JourneyContext = createContext(null);

export function JourneyContextProvider({ children }) {
  const [initialTodo, setTodo] = useState([]);

  const { data: todos, refetch, isLoading } = useQuery('fetchJourneyTodos', () =>
    fetchJourneyTodos(),
  );

  useEffect(() => {
    const environment = getEnv();
    const completeTask = async () => {
      if (environment === 'development' || environment === 'staging') {
        try {
          await completeSchedulingTaskForStaging();
        } catch (error) {
          console.error('Error completing scheduling task:', error);
        }
      }
    };
    completeTask();
  }, []);

  useEffect(() => {
    if (!isEmpty(todos)) {
      setTodo(todos.data);
    }
  }, [todos]);

  return (
    <JourneyContext.Provider
      value={{
        todos: initialTodo,
        refetchTodos: refetch,
        isLoadingTodos: isLoading,
      }}
    >
      {children}
    </JourneyContext.Provider>
  );
}

export function useTodoJourney() {
  const context = useContext(JourneyContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
