import { Button, Link, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { PasswordInput } from 'modules/v2/common/AtomicDesign/molecules';

import { primaryLinkStyle } from 'modules/v2/common/AtomicDesign/atoms/Link';
import env from 'environments';
import S from '../AuthenticationTemplate/index.styled';

import LoginErrors from './LoginErrors';
import { AuthenticationTemplate } from '../index';

const forgotPasswordUrl = '/auth/reset';

function Login({ handleChange, handleLogin, username = '', invalidEmail, statusCode, message }) {
  return (
    <AuthenticationTemplate>
      <LoginErrors invalidEmail={invalidEmail} statusCode={statusCode} statusMessage={message} />
      <S.LoginForm onFinish={handleLogin} layout="vertical">
        <div className="mt-6 max-[430px]:px-2">
          <S.FormItem
            rules={[{ required: true, message: 'Please enter a valid email!', type: 'email' }]}
            name="username"
            label="Email"
          >
            <TextInput
              type="email"
              name="username"
              value={username}
              onChange={handleChange}
              onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
            />
          </S.FormItem>
          <S.FormItem
            rules={[{ required: true, message: 'Please enter password!' }]}
            name="password"
            label="Password"
          >
            <PasswordInput
              type="password"
              autoComplete="password"
              name="password"
              onChange={handleChange}
              onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
            />
          </S.FormItem>

          <div className="flex justify-end mb-4 mt-3">
            <Link to={forgotPasswordUrl} className="">
              Forgot Password?
            </Link>
          </div>

          <S.FormItem>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleLogin}
              size="xl"
              className="w-full"
              full
            >
              Login
            </Button>
          </S.FormItem>

          <p className="text-neutral-500 text-center text-sm font-medium">
            Not yet a member?
            <a
              href={env.PRICING_PLANS}
              target="_blank"
              rel="noreferrer"
              className={`${primaryLinkStyle} ml-2`}
            >
              See our plans & pricing
            </a>
          </p>
        </div>
      </S.LoginForm>
    </AuthenticationTemplate>
  );
}

export default Login;
