import { call, takeLatest } from 'redux-saga/effects';

import { resource, createTicket, getError } from 'modules/api';
import { notification } from 'modules/common/utils';
import { CREATE_TICKET } from 'modules/dashboard/store/constants';

export function* onCreateTicket({ payload: meta }) {
  try {
    const request = resource(CREATE_TICKET, createTicket);
    const payload = yield call(request, meta);

    const title = 'Message Successfully Submitted';
    const description =
      'Thank you. Your message has been sent to Member Support. Please wait for a response by email from one of our team members. For urgent concerns, please call or chat with us.';

    if (payload) {
      notification.success({ title, description });
    }

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchCreateTicket() {
  yield takeLatest(CREATE_TICKET, onCreateTicket);
}
