import join from 'url-join';
import { get, map, startsWith } from 'lodash-es';

import env from 'environments';
import { getPayload } from 'modules/api';

const filterElements = (element) => {
  const rawValue = get(element, 'rawValue');

  if (typeof rawValue !== 'string') {
    return element;
  }

  try {
    return {
      ...element,
      rawValue: JSON.parse(rawValue),
    };
  } catch (err) {
    return element;
  }
};

const filterBookpage = (bookpage) => {
  const elements = get(bookpage, 'elements', []);
  return {
    ...bookpage,
    elements: map(elements, filterElements),
  };
};

const filterTemplate = (template) => {
  const bookpage = get(template, 'bookpage', []);
  return {
    ...template,
    bookpage: map(bookpage, filterBookpage),
  };
};

const filterSection = (section) => {
  const templates = get(section, 'templates', []);

  return {
    ...section,
    templates: map(templates, filterTemplate),
  };
};

const filterDraft = (draft) => {
  const sections = get(draft, 'sections', []);

  return {
    ...draft,
    sections: map(sections, filterSection),
  };
};

export const filterDraftPayload = (response) => {
  const data = getPayload(response);
  const draft = get(data, ['results'], null);

  const newDraft = filterDraft(draft);

  return {
    draft: newDraft,
  };
};

export const filterBookPayload = (response) => {
  const data = getPayload(response);
  const book = get(data, ['data', 'book'], null);

  return { book };
};

export const fixImageUrlPayload = (response) => {
  const data = getPayload(response);

  const rawUrl = get(data, 'url');
  const prefixedUrl = join(env.BASE_AUTH_URL_STATIC_FILES, data.url);
  const url = startsWith(rawUrl, 'http') ? rawUrl : prefixedUrl;
  return {
    ...data,
    url,
  };
};
