import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

function useFacebookPixel() {
  useEffect(() => {
    const facebookScript = document.createElement('script');
    facebookScript.src = 'https://connect.facebook.net/en_US/fbevents.js';
    facebookScript.async = true;
    ReactPixel.init('2454265141546968'); // facebookPixelId
    ReactPixel.track('PageView');
    ReactPixel.pageView();

    document.getElementsByTagName('head')[0].appendChild(facebookScript);
  }, []);

  return null;
}

export default useFacebookPixel;
