function getDefaultProps() {
  return {
    width: '10',
    height: '10',
    fill: '#555',
  };
}

function EditIcon() {
  return (
    <svg
      id="edit"
      width={getDefaultProps().width}
      height={getDefaultProps().height}
      fill={getDefaultProps().fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.237 4.135L2.97 9.4 0 10l.6-2.97 5.266-5.267 2.371 2.372zm.59-.59L10 2.37 7.629 0 6.455 1.174l2.371 2.371z"
        fillRule="nonzero"
      />
    </svg>
    // <svg
    // id="edit"
    //     width={getDefaultProps().width}
    //     height={getDefaultProps().height}
    //     fill={getDefaultProps().fill}
    //     aria-hidden="true" focusable="false"
    //     data-prefix="fas" data-icon="pencil" class="svg-inline--fa fa-pencil fa-w-16"
    //     role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
    //  >
    //     <path
    //     d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z">
    //     </path>
    // </svg>
  );
}

export default EditIcon;
