import { GET_CREDITS, GET_PASSCODE } from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  credits: '',
  passcode: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_CREDITS}_REQUEST`:
      return { ...state, loading: true };
    case GET_CREDITS:
      return {
        ...state,
        loading: false,
        loaded: true,
        credits: action.payload.data.credits,
      };
    case `${GET_CREDITS}_FAILURE`:
      return { ...state, loaded: true };

    case `${GET_PASSCODE}_REQUEST`:
      return { ...state };
    case GET_PASSCODE:
      return {
        ...state,
        passcode: action.payload.data.passcode,
      };
    case `${GET_PASSCODE}_FAILURE`:
      return { ...state };

    default:
      return { ...state };
  }
};
