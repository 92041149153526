import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const RemindersText = styled.p`
  font-size: 16px;
  line-height: 16px;
  color: ${color.Black};
`;
const DatePicker = styled.div`
  margin: 15px 0;
  .ant-input {
    width: 60%;
    display: block;
  }
`;

export default {
  DatePicker,
  RemindersText,
};
