import { getSessionId } from './utils';
import { getInstance } from './instance';

const instance = getInstance();

export const acceptAddon = (code, offerCode, accepted) => {
  try {
    const sessionId = getSessionId(offerCode);
    const payload = { code, accepted };
    const url = `/onboard/addon?offerCode=${offerCode}&sessionId=${sessionId}`;

    return instance.post(url, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return {};
};
