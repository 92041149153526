import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const ModalWrapper = styled.div`
  padding: 20px 40px;
  .ant-input {
    border-color: ${color.InputBorder};
  }
`;

const Label = styled.label`
  margin-bottom: 10px;
  display: block;
  font-size: 16px !important;
`;
export default {
  ModalWrapper,
  Label,
};
