import styled from 'styled-components';
import { device } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';
import { Layout as AntLayout } from 'antd';

const contentWidth = '1200px';

const Layout = styled(AntLayout)`
  background: #f5f5f5 0% 0% no-repeat padding-box;
  min-height: calc(100vh);
  position: relative;
`;

const Wrapper = styled.div`
  width: ${contentWidth};
  background: #fcfcfc;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  margin: 0px auto 30px;

  @media ${device.tabletS} {
    width: 100%;
  }
`;

const Container = styled.div<{ paddingContent?: string }>`
  min-height: 200px;
  padding: ${({ paddingContent }) => paddingContent};

  h3 {
    margin-left: 15px;
    font-weight: 700;
  }

  @media ${device.tabletS} {
    padding-top: 0;
  }
`;

const FormContainer = styled.div`
  width: 624px;
  padding-bottom: 20px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const AddBlockWrap = styled.div`
  display: block;
  width: 576px;
  background-color: ${color.OfferBackground};
  padding: 40px 20px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const FullWrap = styled.div`
  display: flex;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const BodyContent = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export default {
  Layout,
  Wrapper,
  Container,
  AddBlockWrap,
  FullWrap,
  FormContainer,
  BodyContent,
};
