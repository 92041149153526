import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
import { map, get, find, isEmpty } from 'lodash-es';
import { Form, useFormikContext } from 'formik';

import {
  getAttributes,
  getEmailTemplate,
  getEmailTemplatesDropdown,
  getCustomerTemplatesDropdown,
  getCustomerTemplate,
  getOnDemandEmail,
  getEmailSegments,
} from 'modules/api';

import {
  SelectMultiField,
  TimeZoneDropDown,
  Loader,
  ErrorMessage,
} from 'modules/v2/common/components';
import Box from 'v2/common/AtomicDesign/atoms/Box';
import { Button, Radio } from 'v2/common/AtomicDesign/atoms';
import PaperPlaneIcon from 'modules/common/components/SvgIcons/PaperPlaneIcon';
import { useSelector } from 'react-redux';
import { ALL_SEGMENTS_ID } from 'v2/pages/CreateOnDemandEmail';
import S from './styles';
import { parseHtmlTemplate } from './utils';

const { Option, OptGroup } = S.Select;

const OnDemandEmailsSidebar = ({ campaignName, onDemandEmailId }) => {
  const queryClient = useQueryClient();
  const { values, setFieldValue, setValues, errors, setFieldTouched, touched } = useFormikContext();
  const { isRm, isBooks } = useSelector((data) => data.bundleAccess);

  const { templateId, scheduleDate, allSegments, sendImmediately, segments, timezone } = values;

  const [templateContent, setTemplateContent] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(templateId);
  const [selectedCustomerTemplateId, setSelectedCustomerTemplateId] = useState(templateId);
  const [customerTemplates, setCustomerTemplates] = useState([]);
  const [userAttributes, setUserAttributes] = useState({
    memberEmail: '',
    memberFirstName: '',
    memberLastName: '',
    memberPhone: '',
    imageUrl: '',
    memberBookUrl: '',
    memberAddress: '',
    memberBrokerName: '',
  });

  const { submitForm, dirty, isValid } = useFormikContext();
  const disabledSubmit = !isValid || !dirty;

  useQuery('getUserAttributes', getAttributes, {
    onSuccess: (data) => {
      const attributes = get(data, 'data', {});
      setUserAttributes({ ...attributes });
    },
  });

  const { mutate: mutateCustomerTemplatesDropdown } = useMutation(getCustomerTemplatesDropdown, {
    onSuccess: ({ data }) => setCustomerTemplates(data),
  });

  const { data: templatesData } = useQuery('getEmailTemplatesDropdown', getEmailTemplatesDropdown, {
    onSuccess: (data) => {
      if (!onDemandEmailId) {
        const id = get(data, 'data[0].id');
        setSelectedTemplateId(id);
        setFieldValue('templateId', id);
      }

      mutateCustomerTemplatesDropdown();
    },
  });

  const templates = get(templatesData, 'data');

  useQuery(['getOnDemandEmail', onDemandEmailId], () => getOnDemandEmail(onDemandEmailId), {
    enabled: !!onDemandEmailId && !!templates,
    onSuccess: ({ templateName, ...currentValues }) => {
      const template = find(templates, ({ name }) => name === templateName);

      setValues({ templateId: template.id, ...currentValues });

      setSelectedTemplateId(template.id);
    },
  });

  function removeAnchorLinkFromHTML(parsedHtml) {
    return parsedHtml.replaceAll(
      '<a href="{{LEAD_UNSUBSCRIBE_URL}}"',
      '<a class="text-blue-500 hover:text-blue-700" style="pointer-events: none; cursor: default;"',
    );
  }

  const { isLoading: isLoadingTemplate } = useQuery(
    ['getTemplate', selectedTemplateId],
    () => getEmailTemplate(selectedTemplateId),
    {
      enabled: !!templates && !!selectedTemplateId,
      onSuccess: ({ data }) => {
        const content = get(data, 'content');
        const parsedHtml = parseHtmlTemplate(content, userAttributes);
        setTemplateContent(removeAnchorLinkFromHTML(parsedHtml));
      },
    },
  );

  useQuery(
    ['getCustomerTemplate', selectedCustomerTemplateId],
    () => getCustomerTemplate(selectedCustomerTemplateId),
    {
      enabled: !!customerTemplates && !!selectedCustomerTemplateId,
      onSuccess: (data) => {
        const content = get(data, 'content');
        const parsedHtml = parseHtmlTemplate(content, userAttributes);
        setTemplateContent(removeAnchorLinkFromHTML(parsedHtml));
        queryClient.invalidateQueries('getOnDemandEmails');
      },
    },
  );

  const { mutate: mutateTemplate } = useMutation(getEmailTemplate, {
    onSuccess: ({ data }) => {
      const content = get(data, 'content');
      const parsedHtml = parseHtmlTemplate(content, userAttributes);
      setTemplateContent(removeAnchorLinkFromHTML(parsedHtml));
      queryClient.invalidateQueries('getOnDemandEmails');
    },
  });

  const { data: segmentsData } = useQuery('getEmailSegments', getEmailSegments, {
    onSuccess: ({ data }) => {
      setValues({
        ...values,
        segments:
          isRm && !isBooks ? [data.find((segment) => segment.name === 'RM Mailing List').id] : [],
        allAvailableSegments: data.map(({ id }) => id),
      });
      data.unshift({ id: ALL_SEGMENTS_ID, name: 'Select all' });
    },
  });

  const segmentsList = get(segmentsData, 'data')?.filter((segment) => {
    if (!isRm && isBooks) {
      return segment.name !== 'RM Mailing List';
    }

    if (isRm && !isBooks) {
      return segment.name === 'RM Mailing List';
    }

    return true;
  });

  const handleChange = ({ field, value: newValue = null, type = '' }) => {
    return (fieldData) => {
      let value = fieldData;

      if (type === 'radio-card') {
        value = newValue;
      }

      if (type === 'checkbox') {
        value = fieldData.target.checked;
      }

      if (type === 'input') {
        value = fieldData.target.value;
      }

      setFieldValue(field, value);
    };
  };

  const handleTemplateChange = (id) => {
    const customerTemplate = find(customerTemplates, { id });
    const newTemplateId = customerTemplate ? customerTemplate.templateId : id;

    setFieldValue('templateId', newTemplateId);

    if (customerTemplate) setSelectedCustomerTemplateId(customerTemplate.id);

    return mutateTemplate(newTemplateId);
  };

  if (isLoadingTemplate || !selectedTemplateId) {
    return <Loader title="Loading template" />;
  }

  const selected =
    'border-primary-500 border-[2px] rounded-lg flex gap-4 items-center p-4 mb-2 hover:bg-primary-50 transition duration-300 ease-in-out cursor-pointer';
  const defaultStyle =
    'border-neutral-200 gap-4 items-center flex border-[2px] rounded-lg p-4 mb-2 hover:bg-neutral-100 transition duration-300 ease-in-out cursor-pointer';

  function handleSendLater() {
    return handleChange({
      field: 'sendImmediately',
      type: 'radio-card',
      value: false,
    });
  }

  function handleSendNow() {
    return handleChange({
      field: 'sendImmediately',
      type: 'radio-card',
      value: true,
    });
  }

  const handleSelectChange = (segmentIdArray) => {
    if (segmentIdArray.includes(ALL_SEGMENTS_ID)) {
      setFieldValue('segments', []);
      setFieldValue('allSegments', true);
      return;
    }
    if (!segmentIdArray.length) {
      setFieldValue('segments', []);
      setFieldValue('allSegments', false);
      return;
    }
    setFieldValue('segments', segmentIdArray);
  };

  return (
    <>
      <h1 className="font-figtree text-2xl leading-7 font-bold">Create on demand campaign</h1>

      <Box className="flex flex-row font-figtree min-w-[452px] max-[510px]:min-w-0">
        <div
          id="header"
          className="flex p-6 justify-between items-center w-full border-b-[1px] border-neutral-200"
        >
          <span className="text-base font-semibold">Campaign details</span>

          <Button disabled={disabledSubmit} onClick={submitForm}>
            <PaperPlaneIcon className={`mr-2 ${disabledSubmit ? 'opacity-20' : ''}`} />
            Save
          </Button>
        </div>

        <div className="flex w-full max-[1365px]:flex-col">
          <div className="p-6 min-w-[600px] max-[510px]:min-w-0 max-[1440px]:min-w-[380px]">
            <S.Wrapper>
              <Form>
                <div className="flex flex-col">
                  <span className="text-sm font-semibold">Select template</span>
                  <span className="text-sm text-neutral-400 mb-1">
                    Pick your design content for email
                  </span>
                </div>

                <S.Select
                  showSearch
                  defaultValue={templateId || selectedTemplateId}
                  name="templateId"
                  placeholder="Select template here"
                  onChange={handleTemplateChange}
                  className="border-[1px] accent-neutral-500 active:accent-primary-500"
                >
                  {isEmpty(templates) ? null : (
                    <OptGroup label="Templates">
                      {map(templates, ({ name: templateName, id }) => (
                        <Option key={id} value={id}>
                          {templateName}
                        </Option>
                      ))}
                    </OptGroup>
                  )}

                  {isEmpty(customerTemplates) ? null : (
                    <OptGroup label="Customer Templates">
                      {map(customerTemplates, ({ name, id }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </S.Select>

                <div className="flex flex-col mt-4">
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold">Subject</span>
                    <span className="text-sm text-neutral-400 mb-1">
                      Add a subject line for this email
                    </span>
                  </div>
                  <S.TextField
                    name="subject"
                    $hasErrors={errors.subject}
                    placeholder="Untitled Subject"
                    onBlur={() => setFieldTouched('subject')}
                  />
                  {touched.subject && errors.subject ? (
                    <ErrorMessage>{errors.subject}</ErrorMessage>
                  ) : (
                    ''
                  )}
                </div>

                <div className="flex flex-col mt-4">
                  <span className="text-sm font-semibold">Select recipients</span>
                  <span className="text-sm text-neutral-400 mb-1">
                    Who are you sending this email to?
                  </span>
                </div>
                <S.Field
                  disabled={isRm && !isBooks}
                  hideAllSegmentsOption
                  component={SelectMultiField}
                  onBlur={() => {
                    setFieldTouched('segments');
                  }}
                  name="segments"
                  placeholder="Select segment here"
                  mode="multiple"
                  options={segmentsList}
                  onChange={handleSelectChange}
                  value={values.allSegments ? [ALL_SEGMENTS_ID] : values.segments}
                  selectedSegment={segments}
                  $hasErrors={errors.segments && touched.segments}
                />
                {values.allSegments === false && errors.segments && touched.segments ? (
                  <ErrorMessage>{errors.segments}</ErrorMessage>
                ) : (
                  ''
                )}

                <div className="flex flex-col mt-4 mb-1">
                  <span className="text-sm font-semibold">Select timezone</span>
                </div>

                <TimeZoneDropDown
                  defaultValue={timezone}
                  name="timezone"
                  onChange={handleChange({ field: 'timezone' })}
                />

                <div className="flex flex-col mt-4">
                  <span className="text-sm font-semibold">Send time</span>
                  <span className="text-sm text-neutral-400">When should we send this email?</span>
                </div>

                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <label
                  className={`mt-3 ${sendImmediately ? selected : defaultStyle}`}
                  onClick={handleSendNow()}
                  onKeyUp={handleSendNow()}
                  htmlFor="sendImmediately"
                  key="sendImmediately"
                >
                  <Radio
                    id="sendImmediately"
                    name="wow"
                    onChange={handleSendNow()}
                    className={sendImmediately && 'border-4 border-primary-500'}
                  />
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold">Send now</span>
                    <span className="text-sm text-neutral-400">Immediately send this email</span>
                  </div>
                </label>

                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <label
                  className={`mt-3 ${!sendImmediately ? selected : defaultStyle}`}
                  onClick={handleSendLater()}
                  onKeyUp={handleSendLater()}
                  htmlFor="sendLater"
                  key="sendLater"
                >
                  <Radio
                    id="sendLater"
                    name="wow"
                    value={false}
                    onChange={handleSendLater()}
                    className={!sendImmediately && 'border-4 border-primary-500'}
                  />
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold">Schedule a time</span>
                  </div>
                </label>

                <div className="flex flex-col mt-4 mb-1">
                  <span className="text-sm">Set delivery date and time</span>
                </div>
                <S.DatePicker
                  defaultValue={moment(scheduleDate)}
                  name="scheduleDate"
                  format="YYYY-MM-DD HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  onChange={handleChange({ field: 'scheduleDate' })}
                  placeholder="Select date and time"
                  disabled={sendImmediately}
                />
              </Form>
            </S.Wrapper>
          </div>

          <div className="p-4 h-fit w-full bg-neutral-100 overflow-auto max-[1365px]:p-0 max-[1365px]:py-4">
            <S.TemplateView dangerouslySetInnerHTML={{ __html: templateContent }} />
          </div>
        </div>
      </Box>
    </>
  );
};

export default OnDemandEmailsSidebar;
