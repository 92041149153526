import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Badge } from 'flowbite-react';
import classNames from 'classnames';
import {
  getDefaultPaymentProfile,
  getPaymentProfilesList,
  setDefaultPaymentProfile,
} from 'modules/api';
import { CreditCardImages, formatCreditCardNumber } from 'modules/v2/utils';
import { Box, Button, Notification } from 'modules/v2/common/AtomicDesign/atoms';
import { ActionButton } from 'modules/v2/common/AtomicDesign/molecules';
import {
  DeleteCardModal,
  AddCardModal,
  ChangeDefaultPaymentModal,
} from 'modules/v2/common/AtomicDesign/organisms';

const PaymentMethods = () => {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [showAddCardModal, setAddCardShowModal] = useState(false);
  const [defaultCardModal, setDefaultCardModal] = useState(false);
  const [changeDefaultCard, setChangeDefaultCard] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [cardInformation, setCardInformation] = useState({
    id: 0,
    cardImage: '',
    cardNumber: '',
  });

  const queryClient = useQueryClient();

  const { data: paymentData = [] } = useQuery('getPaymentProfilesList', getPaymentProfilesList);

  const { data: defaultProfile } = useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile);

  useEffect(() => {
    const isDefault = defaultProfile?.id === cardInformation?.id;

    if (!showAddCardModal && !defaultCardModal) {
      setChangeDefaultCard(false);
    }

    if (!showAddCardModal && !defaultCardModal && showDeleteModal && isDefault) {
      return setDeleteShowModal(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddCardModal, defaultCardModal, showDeleteModal, defaultProfile]);

  const profiles = paymentData?.filter((item) => item.id !== defaultProfile?.id);

  profiles.unshift(defaultProfile);

  const handleExpireDate = (month: number, year: number) => {
    const formattedMonth = month?.toString().padStart(2, '0');
    return `${formattedMonth}/${year}`;
  };

  const isDefault = (itemId: number) => itemId === defaultProfile?.id;

  const handleDeleteCard = (id: number, cardImage: string, cardNumber: string) => {
    setCardInformation({ id, cardImage, cardNumber });
    setChangeDefaultCard(true);

    if (isDefault(id)) {
      if (profiles.length > 1) return setDefaultCardModal(true);
      return setAddCardShowModal(true);
    }

    setDeleteShowModal(true);
  };

  const handleAddNewPaymentMethod = () => {
    setAddCardShowModal(true);
  };

  const { mutate: mutateSetCardDefault } = useMutation(
    ['setDefaultPaymentProfile'],
    setDefaultPaymentProfile,
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('getDefaultPaymentProfile');
        queryClient.invalidateQueries('getPaymentProfilesList');
        setShowNotification(true);
      },
    },
  );

  useEffect(() => {
    let timer;
    if (showNotification) {
      timer = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showNotification]);

  const handleSetDefault = (id) => {
    mutateSetCardDefault(id);
  };

  return (
    <>
      <Box>
        <div className="text-base font-semibold px-6 py-[22px] text-boxTitle flex justify-between items-center flex-wrap gap-3">
          <div>Payment Methods</div>
          <Button onClick={() => handleAddNewPaymentMethod()}>Add new payment method</Button>
        </div>
        <div className="max-xl:overflow-x-auto">
          <div className="mb-6 min-w-[670px]">
            {/* table Header */}
            <div className="flex  font-semibold">
              <div className="min-w-[145px] w-full border-t border-tableBorder py-3   px-6 ">
                Type
              </div>
              <div className="min-w-[350px] w-full border-t border-tableBorder py-3 ">Details</div>
              <div className="min-w-[236px] w-full border-t border-tableBorder py-3 ">
                Expiry date
              </div>
              <div className="min-w-[84px]  w-full border-t border-tableBorder py-3">Status</div>
              <div className="min-w-[86px]  w-full border-t border-tableBorder py-3">Action</div>
            </div>
            {/* table Body */}
            <div>
              {profiles.map((item) => {
                if (!item) return null;
                return (
                  <div
                    key={item.id}
                    className="flex items-center border-t border-tableBorder py-[18px] font-semibold w-full"
                  >
                    <div className="min-w-[145px] w-full px-6 ">
                      <img
                        src={CreditCardImages[item?.card_type]}
                        alt={item?.card_type}
                        className="min-w-11 h-8"
                      />
                    </div>
                    <div className="min-w-[350px] w-full">
                      {formatCreditCardNumber(item.masked_card_number, isDefault(item.id))}
                    </div>
                    <div className="min-w-[236px] w-full">
                      {handleExpireDate(item.expiration_month, item.expiration_year)}
                    </div>
                    <div className="min-w-[84px] w-full">
                      {item.disabled ? (
                        <Badge className="text-error-500 bg-error-50 w-fit rounded-full">
                          Expired
                        </Badge>
                      ) : (
                        <Badge className="text-success-500 bg-success-50 w-fit rounded-full">
                          Valid
                        </Badge>
                      )}
                    </div>
                    <div className="min-w-[86px] w-full relative">
                      <ActionButton
                        content={
                          <div className="w-64 py-3 flex flex-col items-start">
                            {!isDefault(item.id) && (
                              <button
                                type="button"
                                className={classNames(
                                  'py-2 px-5 hover:bg-neutral-50 cursor-pointer w-full flex',
                                )}
                                onClick={() => handleSetDefault(item.id)}
                              >
                                Set as default
                              </button>
                            )}

                            <button
                              type="button"
                              className={classNames(
                                'py-2 px-5 text-error-500 hover:bg-neutral-50 cursor-pointer w-full flex',
                              )}
                              onClick={() =>
                                handleDeleteCard(
                                  item.id,
                                  CreditCardImages[item.card_type],
                                  formatCreditCardNumber(
                                    item.masked_card_number,
                                    isDefault(item.id),
                                  ),
                                )
                              }
                            >
                              Delete
                            </button>
                          </div>
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Box>

      {showAddCardModal && (
        <AddCardModal
          showModal={showAddCardModal}
          setShowModal={setAddCardShowModal}
          deleteDefaultCard={changeDefaultCard}
        />
      )}
      <DeleteCardModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        cardInformation={cardInformation}
      />
      <ChangeDefaultPaymentModal
        showModal={defaultCardModal}
        setShowModal={setDefaultCardModal}
        isDefault={changeDefaultCard}
      />
      {showNotification && (
        <Notification
          description={`Your default card has been changed to ${cardInformation?.cardImage?.toUpperCase()} ${cardInformation?.cardNumber.slice(
            -4,
          )}`}
        />
      )}
    </>
  );
};

export default PaymentMethods;
