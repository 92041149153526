import { generatePath } from 'react-router-dom';

import {
  ROUTE_DENTIST,
  ROUTE_DENTIST_GUIDES_ANALYTICS,
  ROUTE_DENTIST_GUIDES_CATALOG,
  ROUTE_DENTIST_GUIDES_ORDERED,
  ROUTE_DENTIST_GUIDES_VIEWER,
  ROUTE_DENTIST_CAMPAIGNS,
  ROUTE_DENTIST_GUIDES_WELCOME,
} from './constants';

export const getRouteDentist = () => generatePath(ROUTE_DENTIST);

export const getRouteDentistGuidesWelcome = () => generatePath(ROUTE_DENTIST_GUIDES_WELCOME);

// Guides
export const getRouteDentistGuidesAnalytics = () => generatePath(ROUTE_DENTIST_GUIDES_ANALYTICS);

export const getRouteDentistGuidesCatalog = () => generatePath(ROUTE_DENTIST_GUIDES_CATALOG);

export const getRouteDentistGuidesOrdered = () => generatePath(ROUTE_DENTIST_GUIDES_ORDERED);

export const getRouteDentistGuidesViewer = (id: string) =>
  generatePath(ROUTE_DENTIST_GUIDES_VIEWER, { id });

// Campaigns
export const getRouteDentistCampaigns = () => generatePath(ROUTE_DENTIST_CAMPAIGNS);
