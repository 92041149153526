import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';
import { notification } from 'modules/common/utils';

import { generateDraftLinks, getError, resource } from 'modules/api';

import { getDraftLinks, clearDraftLinks } from 'modules/draft/store';
import { CANCEL_GENERATE_DRAFT_LINKS, GENERATE_DRAFT_LINKS } from 'modules/draft/store/constants';
import { isPreviewStatusFailed } from 'modules/draft/utils/preview';

export function* onGenerateDraftLinks({ payload: meta }) {
  try {
    const request = resource(GENERATE_DRAFT_LINKS, generateDraftLinks);
    const payload = yield call(request, meta);

    const status = get(payload, 'status');

    if (isPreviewStatusFailed(status)) {
      notification.error({ description: 'Something went wrong while generating digital book' });
    }

    const draftId = get(meta, 'draftId');
    yield put(getDraftLinks(draftId));

    return payload;
  } catch (error) {
    const payload = getError(error);

    notification.error({ description: 'Something went wrong while generating digital book' });

    // eslint-disable-next-line no-console
    console.error(payload);

    return payload;
  }
}

export function* watchCancellation(action) {
  const [cancelled] = yield race([
    take(CANCEL_GENERATE_DRAFT_LINKS),
    call(onGenerateDraftLinks, action),
  ]);

  if (cancelled) {
    clearDraftLinks();
  }
}

export function* watchGenerateDraftLinks() {
  yield takeEvery(GENERATE_DRAFT_LINKS, watchCancellation);
}
