function NurtureCampaignIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M18.3334 10.0003C18.3334 14.6027 14.6025 18.3337 10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39795 18.3334 10.0003Z"
        fill="#252525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 6.04199C10.3453 6.04199 10.6251 6.32181 10.6251 6.66699V9.74144L12.5254 11.6417C12.7694 11.8858 12.7694 12.2815 12.5254 12.5256C12.2813 12.7697 11.8855 12.7697 11.6415 12.5256L9.55814 10.4423C9.44093 10.3251 9.37508 10.1661 9.37508 10.0003V6.66699C9.37508 6.32181 9.6549 6.04199 10.0001 6.04199Z"
        fill="white"
      />
    </svg>
  );
}

export default NurtureCampaignIcon;
