import {
  SELECT_TEMPLATE,
  UPDATE_TEMPLATE_BEHAVIOR,
  UPDATE_TEMPLATE_ELEMENT,
  UPDATE_TEMPLATE_PAGES,
  SET_TEMPLATES,
  GET_TEMPLATE,
  SELECT_SECTION,
  CHECK_UNSAVED_CHANGES,
  UPDATE_TEMPLATE_ELEMENT_ALIGNMENT,
  POPULATE_PREFERENCES,
  UPDATE_SPINE,
  UPDATE_BOOKPAGE_ELEMENT,
  UPDATE_ACTIVE_ELEMENT,
} from '../constants';

const initialState = {
  selected: {
    loading: false,
    loaded: false,
    template: {},
  },
  data: {
    loading: false,
    loaded: false,
    templates: [],
  },
  isDirty: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${SELECT_TEMPLATE}_REQUEST`:
      return {
        ...state,
        selected: {
          loading: true,
          loaded: false,
          ...state.selected,
        },
      };

    case SELECT_TEMPLATE:
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: false,
          loaded: true,
          template: action.template,
        },
      };

    case `${SELECT_TEMPLATE}_FAILURE`:
      return {
        ...state,
        selected: {
          ...state.selected,
          loaded: true,
        },
      };

    case SELECT_SECTION:
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: true,
        },
      };

    case `${GET_TEMPLATE}_REQUEST`:
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: true,
          loaded: false,
        },
      };

    case GET_TEMPLATE:
      if (action.condition === 'new') {
        let authorName = state.selected.template.bookpage[0].elements.find(
          (element) => element.id === 'spineAuthor',
        );
        let brokerContact = state.selected.template.bookpage[0].elements.find(
          (element) => element.id === 'brokerContact',
        );
        let data = [];

        state.selected.template.bookpage[0].elements.forEach((element) => {
          action.payload.data.data.template.bookpage[0].elements.forEach((temp) => {
            if (element.id === temp.id) {
              data.push(temp);
            }
          });
        });

        let activeElement = action.payload.data.data.template.bookpage[0].elements.find(
          (element) => element.id === 'content',
        );

        return {
          ...state,
          selected: {
            ...state.selected,
            loading: false,
            loaded: true,
            template: {
              ...action.payload.data.data.template,
              bookpage: action.payload.data.data.template.bookpage.map((page) => {
                return {
                  ...page,
                  elements: data.map((element, i) => {
                    if (element.id === 'spineAuthor' || element.id === 'authorName') {
                      return {
                        ...element,
                        defaultValue: authorName.defaultValue,
                      };
                    } else if (element.id === 'brokerContact') {
                      return {
                        ...element,
                        defaultValue: brokerContact.defaultValue,
                      };
                    } else {
                      return { ...element, order: i + 1 };
                    }
                  }),
                };
              }),
            },
            activeElement: { ...activeElement, order: 2 },
          },
        };
      } else {
        let template = {
          ...action.payload.data,
          bookpage: [
            {
              ...action.payload.data.bookpage[0],
              elements: action.payload.data.bookpage[0].elements.map((element, i) => {
                return { ...element, order: i + 1 };
              }),
            },
          ],
        };

        let activeElement = template.bookpage[0].elements.find(
          (element) => element.id === 'content',
        );

        return {
          ...state,
          selected: {
            ...state.selected,
            loading: false,
            loaded: true,
            template,
            activeElement,
          },
        };
      }

    case `${GET_TEMPLATE}_FAILURE`:
      return {
        ...state,
        selected: {
          ...state.selected,
          loaded: true,
        },
      };

    case `${SET_TEMPLATES}_REQUEST`:
      return {
        ...state,
        data: {
          ...state.data,
          loading: true,
          loaded: false,
        },
      };

    case SET_TEMPLATES:
      // sort templates in ascending order
      const templates = action.templates.data.sort((template1, template2) => {
        return template1.order - template2.order;
      });

      if (action.templates.status === 'loading') {
        return {
          ...state,
          data: {
            ...state.data,
            loading: true,
          },
        };
      }

      if (action.templates.status === 'loaded') {
        return {
          ...state,
          data: {
            ...state.data,
            loading: false,
            loaded: true,
            templates,
          },
        };
      }

      return { ...state };

    case UPDATE_TEMPLATE_BEHAVIOR:
      if (!!action.elements.blur) {
        return {
          ...state,
          selected: {
            ...state.selected,
            template: {
              ...state.selected.template,
              bookpage: state.selected.template.bookpage.map((page) => {
                return {
                  ...page,
                  elements: page.elements.map((element) => {
                    if (element.id === action.elements.id) {
                      return element;
                    }

                    return {
                      ...element,
                      isEditable: false,
                      filter: 15,
                    };
                  }),
                };
              }),
            },
          },
        };
      } else if (!action.elements.blur) {
        return {
          ...state,
          selected: {
            ...state.selected,
            template: {
              ...state.selected.template,
              bookpage: state.selected.template.bookpage.map((page) => {
                return {
                  ...page,
                  elements: page.elements.map((element) => {
                    if (element.id === 'spineAuthor' || element.id === 'spineName') {
                      return {
                        ...element,
                        filter: null,
                      };
                    }

                    return {
                      ...element,
                      isEditable: true,
                      filter: null,
                    };
                  }),
                };
              }),
            },
          },
        };
      } else {
        return { ...state };
      }

    case UPDATE_TEMPLATE_PAGES:
      const bookpage = action.pages.map((page, index) => ({
        number: index + 1,
        content: page,
        elements: [],
      }));

      return {
        ...state,
        selected: {
          ...state.selected,
          template: {
            ...state.selected.template,
            bookpage,
          },
        },
      };

    case UPDATE_TEMPLATE_ELEMENT:
      const linkedEl = state.selected.template.bookpage[0].elements.find(
        (element) => element.id === action.element.elementId,
      );

      return {
        ...state,
        selected: {
          ...state.selected,
          template: {
            ...state.selected.template,
            bookpage: state.selected.template.bookpage.map((page) => {
              return {
                ...page,
                elements: page.elements.map((element) => {
                  if (element.name === linkedEl.name) {
                    return {
                      ...element,
                      defaultValue: action.element.value,
                      templateHTML: action.element.template,
                    };
                  } else {
                    return { ...element };
                  }
                }),
              };
            }),
          },
        },
        isDirty: true,
      };

    case UPDATE_TEMPLATE_ELEMENT_ALIGNMENT:
      const linkedElement = state.selected.template.bookpage[0].elements.find(
        (element) => element.id === action.element.id,
      );

      return {
        ...state,
        selected: {
          ...state.selected,
          template: {
            ...state.selected.template,
            bookpage: state.selected.template.bookpage.map((page) => {
              return {
                ...page,
                elements: page.elements.map((element) => {
                  if (element.name === linkedElement.name) {
                    return {
                      ...element,
                      textAlign: action.element.textAlign,
                    };
                  } else {
                    return { ...element };
                  }
                }),
              };
            }),
          },
        },
      };

    case POPULATE_PREFERENCES:
      return {
        ...state,
        selected: {
          ...state.selected,
          template: {
            ...state.selected.template,
            bookpage: state.selected.template.bookpage.map((page) => {
              return {
                ...page,
                elements: page.elements.map((element) => {
                  if (element.name === 'Author Name') {
                    return {
                      ...element,
                      defaultValue: action.payload.name,
                    };
                  }
                  if (element.name === 'Broker Contact') {
                    return {
                      ...element,
                      defaultValue: `<p>${action.payload.phone}<br>${action.payload.email}</p>`,
                    };
                  } else {
                    return { ...element };
                  }
                }),
              };
            }),
          },
        },
      };

    case CHECK_UNSAVED_CHANGES:
      return {
        ...state,
        isDirty: action.data,
      };

    case UPDATE_SPINE:
      return {
        ...state,
        selected: {
          ...state.selected,
          template: {
            ...state.selected.template,
            bookpage: state.selected.template.bookpage.map((page) => {
              return {
                ...page,
                elements: page.elements.map((element) => {
                  if (element.id === 'spineName') {
                    return {
                      ...element,
                      defaultValue: action.element.data,
                    };
                  } else {
                    return { ...element };
                  }
                }),
              };
            }),
          },
        },
      };

    case UPDATE_BOOKPAGE_ELEMENT: {
      return {
        ...state,
        selected: {
          ...state.selected,
          template: {
            ...state.selected.template,
            bookpage: state.selected.template.bookpage.map((page) => {
              return {
                ...page,
                elements: page.elements.map((element) => {
                  if (element.id === action.payload.elementId) {
                    return {
                      ...element,
                      rawValue: action.payload.rawValue,
                      defaultValue: action.payload.defaultValue,
                    };
                  } else {
                    return element;
                  }
                }),
              };
            }),
          },
        },
      };
    }

    case UPDATE_ACTIVE_ELEMENT: {
      return {
        ...state,
        selected: {
          ...state.selected,
          activeElement: action.element,
        },
      };
    }

    default:
      return { ...state };
  }
};
