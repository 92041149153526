import axios from 'axios';
import qs from 'query-string';
import { get } from 'lodash-es';

import env from 'environments';

const instance = axios.create({
  baseURL: env.VITE_AUTH_URL,
  paramsSerializer: qs.stringify,
});

instance.interceptors.response.use((config) => {
  const status = get(config, ['data', 'status']);

  if (status === false) {
    return Promise.reject(config);
  }

  return config;
});

const getAuthInstance = () => instance;

export default getAuthInstance;
