import { Button as BaseButton, CustomFlowbiteTheme } from 'flowbite-react';
import { cn } from 'modules/v2/common/utils';

const buttonTheme: CustomFlowbiteTheme['button'] = {
  color: {
    primary: 'bg-primary-500 border border-primary-500 text-neutral-800 hover:bg-primary-400',
    outlined:
      'bg-white border border-neutral-200 text-neutral-800 hover:bg-neutral-100 hover:border-neutral-800',
    dark: 'bg-neutral-800 border border-neutral-800 text-neutral-50 hover:bg-neutral-700',
    danger: 'bg-error-500 border border-error-500 text-neutral-50 hover:bg-error-400',
    dangerOutlined: 'bg-white border border-error-400 text-error-400 hover:bg-error-50',
    icon: 'hover:bg-neutral-200 hover:border-neutral-200',
    disabled: 'text-zinc-400 border bg-neutral-300 text-neutral-400',
    muted: 'bg-neutral-100 border border-neutral-100 text-neutral-800 hover:bg-neutral-200',
    bare: 'bg-white border border-white text-neutral-800 hover:bg-neutral-100',
    link: 'text-red-500 text-sm font-figtree underline',
  },
  size: {
    xs: 'text-xs leading-none flex items-center px-2.5 h-[26px] ',
    sm: 'text-xs  px-3.5 flex items-center h-[36px]',
    base: 'text-sm leading-tight flex items-center px-[18px] h-[44px]',
    lg: 'text-base flex items-center px-5 leading-tight h-[50px]',
    xl: 'text-lg flex items-center px-6 h-[62px]',
  },
};

type ButtonProps = {
  type:
    | 'primary'
    | 'outlined'
    | 'dark'
    | 'danger'
    | 'dangerOutlined'
    | 'icon'
    | 'muted'
    | 'bare'
    | 'link';
  size: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
  color: string;
  buttonType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  full?: boolean;
  className: string;
  children: React.ReactNode;
  isLoading?: boolean;
};

const Button = ({
  type = 'primary',
  size = 'base',
  disabled,
  buttonType,
  full,
  children,
  className,
  isLoading,
  ...props
}: ButtonProps) => {
  const color = !disabled ? type : 'disabled';

  return (
    <BaseButton
      disabled={disabled}
      theme={buttonTheme}
      color={color}
      size={size}
      type={buttonType}
      className={cn(
        'rounded-md font-figtree font-semibold items-center justify-center index-1 relative transition-none duration-0 p-0 box-border',
        className,
        disabled,
        {
          'w-full': full,
          'w-fit': !full,
        },
      )}
      {...props}
    >
      {children}
    </BaseButton>
  );
};

Button.defaultProps = {
  buttonType: 'button',
  disabled: false,
  full: false,
  isLoading: false,
};

export default Button;
