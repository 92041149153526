import { map } from 'lodash-es';
import dayjs from 'dayjs';

export const parseReleaseNotes = (releaseNotes) =>
  map(releaseNotes, ({ id, name, date, content: body }) => ({
    name,
    content: body,
    date: dayjs(date).format('MMMM DD, YYYY'),
    id,
  }));
