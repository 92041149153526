import { ArrowLeftOutlined } from '@ant-design/icons';

import S from './styles';

export const LinkButton = ({ children, href, ...props }) => (
  <S.LinkButton href={href} {...props}>
    {children}
  </S.LinkButton>
);

export const BackLinkButton = ({ title, href, ...props }) => (
  <S.BackLinkButton to={href} {...props}>
    <ArrowLeftOutlined />
    <S.LinkText>{title}</S.LinkText>
  </S.BackLinkButton>
);
