import { useEffect, useState } from 'react';
import { get, map, size } from 'lodash-es';
import { Row, Col } from 'antd';
import classnames from 'classnames';

import { ConfirmModal, Loader, Scrollbars } from 'modules/common/components';
import { DeleteIcon } from 'modules/common/components/SvgIcon';
import { COVER_IMAGE_GROUP_IMAGE } from 'modules/editor/utils/constants';

import S from './styles';

const ListUploads = ({ images, type, onSelect, onDelete }) => {
  const [current, setCurrent] = useState(null);

  const onSelectImage = (image) => {
    const imageId = get(image, '_id');
    let value = get(image, 'url');
    if (type === COVER_IMAGE_GROUP_IMAGE) {
      value = get(image, '_id');
    }
    onSelect(value);
    setCurrent(imageId);
  };

  return map(images, (image) => {
    const imageId = get(image, '_id');
    const imageUrl = get(image, 'url');
    const isSelected = current === imageId;

    const handleOnClickImage = () => onSelectImage(image);
    const handleOnClickDeleteButton = () => onDelete(imageId);

    return (
      <Col xs={22} sm={20} md={8} lg={8} xl={8} key={imageId}>
        <div className={classnames('list-portrait', { selected: isSelected })}>
          <div className="imgWrap">
            <div onClick={handleOnClickImage}>
              <img alt="" src={imageUrl} />
            </div>

            <div>
              {onDelete && (
                <button className="delIcon" type="button" onClick={handleOnClickDeleteButton}>
                  <DeleteIcon />
                </button>
              )}
            </div>
          </div>
        </div>
      </Col>
    );
  });
};

const ImagePicker = ({ isLoading, images, type, onSelect, onDelete, onOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);

  const handleDelete = (imageId) => {
    setIsOpen(true);
    setSelectedImageId(imageId);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setSelectedImageId(null);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    if (onDelete && selectedImageId) {
      onDelete(selectedImageId);
    }
    setSelectedImageId(null);
  };

  const deleteConfirmModal =
    onDelete && isOpen ? (
      <ConfirmModal
        isOpen
        title="Delete Image"
        text="Are you sure you want to delete this image?"
        confirmLabel="Yes"
        cancelLabel="No"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    ) : null;

  useEffect(() => {
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader title="Loading Images" height="100%" />;
  }

  if (size(images) === 0) {
    return <S.NoImages>No Pictures Available</S.NoImages>;
  }

  return (
    <S.ImageSelectorWrapper>
      <div className="imgListing">
        <Scrollbars
          renderInLayers
          style={{
            height: 'calc(100vh - 480px)',
          }}
        >
          <Row type="flex" justify="center">
            <ListUploads
              images={images}
              type={type}
              onSelect={onSelect}
              onDelete={onDelete && handleDelete}
            />
          </Row>
        </Scrollbars>
      </div>
      {deleteConfirmModal}
    </S.ImageSelectorWrapper>
  );
};

export default ImagePicker;
