import styled from 'styled-components';
import { Col as baseCol, Row as baseRow } from 'antd';
import { Field } from 'formik';
import { FormLabel as baseFormLabel } from 'modules/common/components';
import * as color from 'modules/common/theme/color';
import { SecondaryButton as BaseSecondaryButton } from 'modules/common/components/UIComponents';

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0 !important;
  font-size: 16px;
  font-weight: bold;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction; column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
`;

const MainContain = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  font-weight: 600 !important;
`;

const Row = styled(baseRow)`
  display: flex;
  flex: 1;
`;

const Col = styled(baseCol)`
  flex: 0.5;
  margin-right: 16px;
`;

const FormLabel = styled(baseFormLabel)`
  width: 100%;
  display: flex;
  flex-direction: column;F
`;

const FormFooter = styled.footer`
  display: flex;
  justify-content: end;
  margin-right: 16px;
`;

const SecondaryButton = styled(BaseSecondaryButton)`
  ${(props) =>
    props.mr
      ? `
    margin-bottom: 0px !important;
    margin-top: 38px !important;`
      : null}
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  border-radius: 3px;
  font-size: 18px;
  line-height: 22px;
  color: ${color.UserMenuText};
  .ant-select-selection {
    height: 43px !important;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-size: 16px;
  }
`;

export default {
  Subtitle,
  MainWrapper,
  MainContain,
  FieldGroup,
  FieldControl,
  Row,
  Col,
  FormLabel,
  FormFooter,
  SecondaryButton,
  FormikField,
};
