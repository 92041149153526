import Yup from 'configuration/yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'The Author Name is too short').required('Author Name is required'),

  email: Yup.string().email('Invalid e-mail').required('E-mail is required'),

  phone: Yup.string().required('Phone is required').phoneValidation(),
});

export default validationSchema;
