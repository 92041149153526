import { Authenticated } from 'modules/router/types';

import { Draft, Cover, ManageSections, Content, OrderBook } from 'modules/v2/editor/pages';
import {
  ROUTE_ORDER_BOOK,
  ROUTE_EDIT_DRAFT,
  ROUTE_EDIT_DRAFT_COVER,
  ROUTE_EDIT_DRAFT_MANAGE_SECTIONS,
  ROUTE_EDIT_DRAFT_CONTENT,
  ROUTE_EDIT_DRAFT_CONTENT_SECTION,
} from './constants';

export const routes = [
  {
    type: Authenticated,
    path: ROUTE_EDIT_DRAFT,
    component: Draft,

    children: [
      { path: ROUTE_EDIT_DRAFT_COVER, component: Cover, exact: true },
      { path: ROUTE_EDIT_DRAFT_MANAGE_SECTIONS, component: ManageSections },
      { path: ROUTE_EDIT_DRAFT_CONTENT, component: Content, exact: true },
      { path: ROUTE_EDIT_DRAFT_CONTENT_SECTION, component: Content },
    ],
  },
  {
    type: Authenticated,
    path: ROUTE_ORDER_BOOK,
    component: OrderBook,
    exact: true,
  },
  { path: '/editor', redirect: '/', exact: true },
  { path: '/editor/draft', redirect: '/', exact: true },
];
