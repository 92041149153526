import styled from 'styled-components';
import { Skeleton as SkeletonBase } from 'antd';
import * as font from 'modules/common/theme/font';

const Title = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  font-family: ${font.LoraFont};
  margin-bottom: 20px;
`;

const Description = styled.span`
  width: 100%;
  font-size: 14px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
  margin-bottom: 20px;
`;

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
`;

const Book = styled.a`
  width: 120px;
  height: 200px;

  overflow: hidden;

  img {
    width: 120px;
    height: 200px;
    transition: transform 0.3s ease-in-out;
  }

  img:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* IE 9 */
    transform: scale(1.1); /* IE 9 */
  }
`;

const BooksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const Card = styled.div`
  height: 200px;
  margin-bottom: 34px;
`;

const Skeleton = styled(SkeletonBase)``;

const SkeletonImage = styled(SkeletonBase.Image)`
  .ant-skeleton-image {
    width: 120px;
    height: 200px;
    svg {
      display: none;
    }
  }
`;

export default {
  Container,
  FlexWrap,
  Title,
  Description,
  BooksContainer,
  Book,
  Card,
  Skeleton,
  SkeletonImage,
};
