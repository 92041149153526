//

import { isLoading, isSucceeded, isFailed } from 'store/status';

import S from './styles';

const UploadInput = ({ uploadStatus, uploadError, onClick }) => {
  const uploadIsLoading = isLoading(uploadStatus);
  const uploadIsSucceeded = isSucceeded(uploadStatus);
  const uploadIsFailed = isFailed(uploadStatus);
  const uploadErrorMessage = uploadError || 'Upload failed';

  return (
    <S.Upload>
      <S.Upload.Button onClick={onClick}>Choose File</S.Upload.Button>
      {uploadIsLoading && 'Uploading...'}
      {uploadIsSucceeded && <S.Upload.Succeeded>File has been uploaded</S.Upload.Succeeded>}
      {uploadIsFailed && (
        <S.Upload.Failed>
          Error:
          {uploadErrorMessage}
        </S.Upload.Failed>
      )}
    </S.Upload>
  );
};

export default UploadInput;
