import { STATUS_SUCCESS } from 'store/status';
import { GET_OFFERS } from 'modules/dashboard/store/constants';
import { offers as initialState } from '../initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERS:
      return {
        ...state,
        data: action.payload,
        status: STATUS_SUCCESS,
      };
    default:
      return state;
  }
};

export default reducer;
