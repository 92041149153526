import { spawn } from 'redux-saga/effects';

import commonWatchers from './common';
import bookWatchers from './book';
import draftWatchers from './draft';
import previewWatchers from './preview';
import saveWatchers from './save';
import userWatchers from './user';

export default function* saga() {
  yield spawn(commonWatchers);
  yield spawn(bookWatchers);
  yield spawn(draftWatchers);
  yield spawn(previewWatchers);
  yield spawn(saveWatchers);
  yield spawn(userWatchers);
}
