import { put } from 'redux-saga/effects';

import { watchExitRoute } from 'modules/router/sagas';

import { clearDraftEditor } from 'modules/editor/store';

import { isEditorDraftRoute } from 'modules/editor/routes/routeMatchers';

const watchFn = (location) => isEditorDraftRoute(location);
const validFn = (location) => !isEditorDraftRoute(location);

export function* onExitEditor() {
  yield put(clearDraftEditor());
}

export function* watchExitEditor() {
  yield watchExitRoute(watchFn, validFn, onExitEditor);
}
