import { useQuery } from 'react-query';
import { getCustomerSubscription } from 'modules/api';

export const useGetCustomerSubscriptionStatus = () => {
  const { data: customerSubscription } = useQuery('customerSubscription', getCustomerSubscription);

  const subscriptionStatus = customerSubscription?.status || null;

  return subscriptionStatus;
};
