import { map } from 'lodash-es';
import { formatDateText } from 'modules/dashboard/pages/LaunchEmailCampaign/utils';
import S from './styles';

const CampaignPreview = ({ emails }) =>
  map(emails, (email) => {
    const { id, templateName, image } = email;
    return (
      <S.Main key={id}>
        <S.Section>
          <S.Image>
            <img src={image} alt={templateName} />
          </S.Image>
          <div>
            <div className='flex text-sm text-neutral-500'>
              Template name: <p className='text-sm text-neutral-900 font-semibold ml-[4px]'>
                {templateName}
              </p>
            </div>
            <div className='flex text-sm text-neutral-500'>
              Scheduled date: <p className='text-sm text-neutral-900 font-semibold ml-[4px]'>
                {formatDateText(email)}
              </p>
            </div>
          </div>
        </S.Section>
      </S.Main>
    );
  });

export default CampaignPreview;
