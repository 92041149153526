export const CardIconNew = ({ width = 20, height = 20, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66602 10.0002C1.66602 6.85747 1.66602 5.28612 2.64233 4.30981C3.61864 3.3335 5.18999 3.3335 8.33268 3.3335H11.666C14.8087 3.3335 16.3801 3.3335 17.3564 4.30981C18.3327 5.28612 18.3327 6.85747 18.3327 10.0002C18.3327 13.1429 18.3327 14.7142 17.3564 15.6905C16.3801 16.6668 14.8087 16.6668 11.666 16.6668H8.33268C5.18999 16.6668 3.61864 16.6668 2.64233 15.6905C1.66602 14.7142 1.66602 13.1429 1.66602 10.0002Z"
      stroke={fill}
      strokeWidth="1.5"
    />
    <path d="M8.33333 13.3335H5" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M11.666 13.3335H10.416" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M1.66602 8.3335L18.3327 8.3335"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
