import { withAuth } from 'modules/v2/containers';
import { AuthenticationTemplate } from 'modules/v2/components';
import { useEffect, useState } from 'react';
import SetMethodPassword from 'modules/v2/login/pages/AuthNewPassword/SetMethodPassword';
import SetEmailValidation from 'modules/v2/login/pages/AuthNewPassword/SetEmailValidation';
import { ROUTE_LOGIN } from 'modules/v2/routes/constants';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import { sendOtpRequest } from 'modules/api/auth';
import { notification } from 'modules/v2/common/utils';
import { NEW_PASSWORD_METHOD, TIME_TO_RESEND_OTP } from '../pages/AuthNewPassword/utils';

function AuthNewPassword({ auth }) {
  const [authMethod, setAuthMethod] = useState(null);
  const [timeTracker, setTimeTracker] = useState(0);
  const history = useHistory();

  useQuery('sendOtpRequest', () => sendOtpRequest(), { enabled: false });

  useEffect(() => {
    if (!auth.userEmail) {
      history.push(ROUTE_LOGIN);
    }
  }, []);

  useEffect(() => {
    const intervalTime = setInterval(() => {
      if (timeTracker > 0) {
        setTimeTracker(timeTracker - 1);
      }
    }, 1000);
    return () => clearInterval(intervalTime);
  }, [timeTracker]);

  const handleSendOtpRequest = async () => {
    try {
      await sendOtpRequest({ email: auth.userEmail, type: NEW_PASSWORD_METHOD.EMAIL });
      setTimeTracker(TIME_TO_RESEND_OTP);
    } catch ({ message, response }) {
      if (response.status === 429) {
        setTimeTracker(response.data.errors[0]);
        notification.error({
          description: 'We have already sent you the OTP, please wait for some time to send again.',
        });
      } else {
        notification.error({
          description: message,
        });
      }
      throw new Error(message);
    }
  };

  const defineMethod = (method) => {
    if (method === NEW_PASSWORD_METHOD.EMAIL) {
      setAuthMethod(NEW_PASSWORD_METHOD.EMAIL);
      handleSendOtpRequest();
    }
    if (method === NEW_PASSWORD_METHOD.SMS) {
      setAuthMethod(NEW_PASSWORD_METHOD.SMS);
      console.log('validation via sms coming soon');
    }
  };
  const handleNavigation = () => {
    if (!authMethod) {
      history.push(ROUTE_LOGIN);
    }
    setAuthMethod(null);
  };

  const viewRender = () => {
    switch (authMethod) {
      case NEW_PASSWORD_METHOD.EMAIL: {
        return (
          <SetEmailValidation
            auth={auth}
            history={history}
            timeTracker={timeTracker}
            reSendOtpRequest={handleSendOtpRequest}
          />
        );
      }
      case NEW_PASSWORD_METHOD.SMS: {
        return <div className="flex justify-center w-full font-extrabold">Coming soon</div>;
      }

      default:
        return <SetMethodPassword defineMethod={defineMethod} />;
    }
  };
  return (
    <AuthenticationTemplate>
      <div className="w-full h-full flex flex-col items-center justify-center border-t border-neutral-200">
        <div className="w-[400px] h-full flex flex-col mt-[75px] max-[450px]:w-full max-[450px]:px-5">
          <div className="flex justify-start mt-3 mb-3.5">
            <div
              className="font-semibold text-primary-600 underline underline-offset-4 cursor-pointer"
              onClick={handleNavigation}
            >
              Back
            </div>
          </div>
          {viewRender()}
        </div>
      </div>
    </AuthenticationTemplate>
  );
}

export default withAuth(AuthNewPassword);
