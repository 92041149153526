export const TrashIcon = ({ width = 16, height = 16, fill = '#E4483D' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.11377 2.66732C6.38833 1.89052 7.12915 1.33398 7.99997 1.33398C8.87078 1.33398 9.6116 1.89052 9.88616 2.66732"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M13.6669 4H2.3335" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M12.5554 5.66602L12.2488 10.2654C12.1308 12.0354 12.0718 12.9203 11.4952 13.4598C10.9185 13.9993 10.0315 13.9993 8.25768 13.9993H7.7421C5.96824 13.9993 5.0813 13.9993 4.50463 13.4598C3.92796 12.9203 3.86896 12.0354 3.75096 10.2654L3.44434 5.66602"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6.3335 7.33398L6.66683 10.6673"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M9.66683 7.33398L9.3335 10.6673"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
