import { getInstance } from './instance';

const instance = getInstance();

const getAllMagazineMetrics = (month, year) => {
  const url = '/referral-marketing/magazines/reports/all';

  const params = { month, year };

  return instance
    .get(url, { params })
    .then((res) => res.data)
    .catch((error) => error);
};

const getRmMagazineMetricsEditing = (month, year, page, perPage) => {
  const url = '/referral-marketing/magazines/reports/magazine-editing';
  const params = { month, year, page, perPage };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetricsSentToPrint = (month, year, page, perPage) => {
  const url = '/referral-marketing/magazines/reports/magazine-sent-to-print';
  const params = { month, year, page, perPage };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetricsMagazineGenerated = (month, year, page, perPage) => {
  const url = '/referral-marketing/generated-magazines/reports/all';
  const params = { month, year, page, perPage };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetrics = (month, year, page, perPage) => {
  const url = '/referral-marketing/magazines/reports';
  const params = { month, year, page, perPage };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetricsBySearch = (searchQuery, year, month) => {
  const url = '/referral-marketing/magazines/metrics/reports/search';

  const params = {
    searchQuery,
    month,
    year,
  };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const postRmMagazineGenerate = (month, year, userEmail, customerId, isPreview) => {
  const url = '/admin/referral-marketing/generate-magazine';
  const params = {
    month,
    year,
    userEmail,
    customerId,
    isPreview,
  };
  return instance
    .post(url, params)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const generateReferralMagazineAdmin = (data) => {
  return postRmMagazineGenerate(
    data.payload.month,
    data.payload.year,
    data.payload.userEmail,
    data.payload.customerId,
    data.isPreview,
  );
};

export const uploadCustomCover = (body) => {
  const url = '/admin/referral-marketing/upload-custom-cover';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const startMonthlyTurnOver = (body) => {
  const url = 'admin/referral-marketing/monthly-turn-over';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const getMonthlyTurnOverStatus = () => {
  const url = 'admin/referral-marketing/monthly-turn-over';

  return instance
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
};

const schedulePermanentLinkTurnOver = (body) => {
  const url = 'admin/referral-marketing/permanent-link-turn-over';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const getSchedulePermanentLinkTurnOver = () => {
  const url = 'admin/referral-marketing/permanent-link-turn-over';

  return instance
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
};

const getMagazineAdmin = ({ queryKey }) => {
  const [, year, month, customerId] = queryKey;
  return instance
    .get(`/admin/referral-marketing/${year}/${month}/${customerId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazineAdmin = ({ queryKey }) => {
  const [, year, month, customerId] = queryKey;
  return instance
    .get(`/admin/referral-marketing/generated-magazines/${year}/${month}/${customerId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const updateReferralMagazineAdmin = (req) => {
  const { month, year, customerId, ...rest } = req;

  return instance
    .patch(`/admin/referral-marketing/${year}/${month}/${customerId}`, rest?.payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const createSetupTicket = ({ id, customerId }) => {
  return instance
    .post(`/admin/referral-marketing/${id}/${customerId}/create-ticket`, {})
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export {
  getAllMagazineMetrics,
  getRmMagazineMetrics,
  getRmMagazineMetricsBySearch,
  getRmMagazineMetricsEditing,
  getRmMagazineMetricsSentToPrint,
  getRmMagazineMetricsMagazineGenerated,
  postRmMagazineGenerate,
  generateReferralMagazineAdmin,
  startMonthlyTurnOver,
  getMonthlyTurnOverStatus,
  schedulePermanentLinkTurnOver,
  getSchedulePermanentLinkTurnOver,
  getMagazineAdmin,
  updateReferralMagazineAdmin,
  createSetupTicket,
};
