import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { PreviewHover } from 'v2/common/theme/color';

const InputWithCopy = styled.div`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
`;

InputWithCopy.Label = styled.label`
  margin-right: 10px;
  display: block;
  line-height: 26px;
  text-align: left;
  white-space: nowrap;
  width: 100px;
`;

InputWithCopy.Input = styled.input`
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  padding: 6px 10px;
  border-radius: 6px;
  overflow: hidden;

  &:focus {
    outline: none !important;
  }
`;

InputWithCopy.InputWrapper = styled.div`
  list-style-type: none;
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  flex-grow: 2;
  margin-right: 0;
`;

InputWithCopy.Button = styled.button`
  position: absolute;
  right: 1px;
  top: 1px;
  text-align: center;
  background-color: #fafafa;
  color: ${color.White};
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
  padding: 0 10px;
  outline: none;
  border-left: 1px solid #e9e9e9;
  font-size: 12px;
  height: 38px;

  &:active,
  &:focus,
  &:hover {
    color: ${color.White};
    background-color: #efefef;
    opacity: 0.8;
  }
`;

InputWithCopy.Link = styled.a`
  maargin-top: 8px;
  color: #252525;
  line-height: 26px;
  text-decoration: underline !important;
  text-underline-offset: 4px;
  width: auto;

  &:hover {
    color: ${PreviewHover};
  }
`;

export default {
  InputWithCopy,
};
