export const PlainOutline = ({ width = 20, height = 20, fill = '#FAFAFA' }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M15.3085 12.9361L16.6817 8.81664C17.8813 5.21793 18.4811 3.41858 17.5312 2.46876C16.5814 1.51895 14.7821 2.11873 11.1834 3.3183L7.06389 4.69146C4.15939 5.65963 2.70714 6.14371 2.29445 6.85359C1.90185 7.5289 1.90185 8.36295 2.29445 9.03827C2.70713 9.74814 4.15939 10.2322 7.06389 11.2004C7.53026 11.3559 7.76344 11.4336 7.95835 11.5641C8.14723 11.6905 8.3095 11.8528 8.43595 12.0417C8.56642 12.2366 8.64416 12.4698 8.79961 12.9361C9.76777 15.8406 10.2519 17.2929 10.9617 17.7056C11.637 18.0981 12.4711 18.0981 13.1464 17.7056C13.8563 17.2929 14.3404 15.8406 15.3085 12.9361Z"
      stroke={fill}
      strokeWidth="1.5"
    />
    <path
      d="M13.8336 7.22703C14.1265 6.93414 14.1265 6.45926 13.8336 6.16637C13.5407 5.87348 13.0659 5.87348 12.773 6.16637L13.8336 7.22703ZM8.53033 12.5303L13.8336 7.22703L12.773 6.16637L7.46967 11.4697L8.53033 12.5303Z"
      fill={fill}
    />
  </svg>
);
