import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { NavBar } from 'modules/nav';

import { createHubspotTicket, updateContact, uploadImage } from 'modules/api/bookPreview';
import { notification } from 'modules/common/utils';
import { Footer } from 'modules/common/components';
import S from './styles';
import {
  GenerateBookForm,
  AllBooksPreview,
  SelectedBookPreview,
  UserDetailsModal,
  PreFetchImages,
} from './components';

import useFacebookPixel from './useFacebookPixel';

const initialValues = {
  name: '',
  email: '',
  phone: '',
};

const modalInitialValues = {
  name: '',
  email: '',
  phone: '',
  address: '',
  country: 'USA',
  state: '',
  city: '',
  zip: '',
  textMessageOptIn: true,
  content: '',
};

const GenerateYourPreviewBook = () => {
  const [uploadedImage, setUploadedImage] = useState('');
  const [selectedBookCoverId, setSelectedBookCoverId] = useState(0);
  const [selectedBookName, setSelectedBookName] = useState('Business Card Book');
  const [showUserDetailsPreview, setShowUserDetailsPreview] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [authorName, setAuthorName] = useState();
  const [uploadedFile, setUploadedFile] = useState({});

  useFacebookPixel();

  const { mutateAsync: mutateImageUpload, isLoading: loadingImage } = useMutation(uploadImage);
  const {
    data,
    mutateAsync: mutateTicket,
    isLoading: loadingTicket,
  } = useMutation('createHubspot', (body) => createHubspotTicket(body), {
    onError: (error) =>
      notification.error({
        description:
          error?.response?.data?.errors || error?.response?.data?.message[0] || 'Network Error',
      }),
  });

  const goToHomePage = () => {
    window.open('https://go.authorify.com/oba-preview-generator', '_self');
  };

  const { mutate, isLoading } = useMutation(
    'updateContact',
    ({ id, body }) => updateContact({ id, body }),
    {
      onSuccess: () => goToHomePage(),
      onError: (error) => {
        notification.error({
          description: error?.response?.data?.message || 'Network Error',
        });
      },
    },
  );

  useEffect(() => {
    if (userDetails?.email) {
      setShowUserDetailsPreview(true);
    }
  }, [userDetails]);

  const selectedBook = (id, text) => {
    setSelectedBookCoverId(id);
    setSelectedBookName(text);
  };

  const handleShowUserDetails = () => {
    setShowUserDetailsPreview(!showUserDetailsPreview);
    setUserDetails({});
  };

  const generateBook = (values) => {
    const contactData = { ...values, phone: values.phone.toString() };
    mutate({ id: data.ticketId, body: contactData });
  };

  const downloadBook = async (values) => {
    if (data?.ticketId) {
      setUserDetails(values);
    } else {
      const bookData = { ...values, phone: values.phone.toString(), bookName: selectedBookName };
      if (uploadedFile?.path) {
        const formData = new FormData();
        formData.append('file', uploadedFile);
        mutateImageUpload(formData).then(async (response) => {
          await mutateTicket({
            ...bookData,
            content: response.Location || '',
          });
        });
      } else {
        await mutateTicket({ ...bookData, content: '' });
      }
      setUserDetails(values);
    }
  };

  const imageUpload = (image, file) => {
    setUploadedImage(image);
    setUploadedFile(file);
  };

  const removeUploadedImage = () => {
    setUploadedImage('');
    setUploadedFile();
  };

  const handleAuthorName = (e) => {
    setAuthorName(e?.target?.value.slice(0, 14));
  };

  return (
    <>
      <NavBar />
      <>
        <S.BlackOverlay />
        <S.PreviewWrapper>
          <S.Heading>Become A Published Real Estate Authority & Win More Listings</S.Heading>
          <S.Description>
            Get a free personalized sample of the listing getting book that makes it easy to get
            listings.
          </S.Description>
          <GenerateBookForm
            fields={initialValues}
            onSave={downloadBook}
            uploadedImage={uploadedImage}
            setUploadedImage={imageUpload}
            handleNameChange={handleAuthorName}
            removeUploadedImage={removeUploadedImage}
            loading={loadingImage || loadingTicket}
          />
          <SelectedBookPreview
            uploadedImage={uploadedImage}
            selectedBookId={selectedBookCoverId}
            authorName={authorName}
          />
          <AllBooksPreview
            uploadedImage={uploadedImage}
            handleBookSelect={selectedBook}
            downloadBook={downloadBook}
            selectedBookId={selectedBookCoverId}
            authorName={authorName}
          />
          <PreFetchImages />
        </S.PreviewWrapper>
        {showUserDetailsPreview ? (
          <UserDetailsModal
            onCancel={handleShowUserDetails}
            userDetails={userDetails}
            selectedBookId={selectedBookCoverId}
            userUploadedImage={uploadedImage}
            generateBook={generateBook}
            authorName={authorName}
            loading={isLoading}
            fields={{ ...modalInitialValues, ...userDetails, bookName: selectedBookName }}
          />
        ) : null}
      </>
      <Footer tiny={false} />
    </>
  );
};
export default GenerateYourPreviewBook;
