import React, { createContext, useState } from 'react';

export const PlusPlansContext = createContext(null);

type ContextValue = {
  paymentProfiles: object[];
  setPaymentProfiles: React.Dispatch<React.SetStateAction<[]>>;
  defaultProfile: {
    id: string;
  };
  setDefaultProfile: React.Dispatch<
    React.SetStateAction<{
      id: string;
    }>
  >;
  newPlanName: string;
  setNewPlanName: React.Dispatch<React.SetStateAction<string>>;
  currentPlanName: string;
  setCurrentPlanName: React.Dispatch<React.SetStateAction<string>>;
};

export const PlusPlansProvider = ({ children }) => {
  const [paymentProfiles, setPaymentProfiles] = useState([]);
  const [defaultProfile, setDefaultProfile] = useState({
    id: '',
  });
  const [newPlanName, setNewPlanName] = useState('');
  const [currentPlanName, setCurrentPlanName] = useState('');

  if (!children) {
    return null;
  }

  const contextValue: ContextValue = {
    paymentProfiles,
    defaultProfile,
    setPaymentProfiles,
    setDefaultProfile,
    newPlanName,
    setNewPlanName,
    currentPlanName,
    setCurrentPlanName,
  };

  return (
    <PlusPlansContext.Provider value={contextValue || null}>{children}</PlusPlansContext.Provider>
  );
};
