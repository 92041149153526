import { useCallback, useState, useRef } from 'react';

import { debounce } from 'lodash-es';

import { ResetIcon, ZoomInIcon, ZoomOutIcon } from 'modules/v2/common/components/SvgIcon';

import 'cropperjs/dist/cropper.css';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';

import S from './styles';

const DEBOUNCE_IMAGE_CHANGE = 300;
const IMAGE_ROTATION_DIRECTION = -90;
const IMAGE_ZOOM_SCALE = 0.1;

const LogoCropper = ({ image, onChange, handleCancel }) => {
  const ref = useRef(null);
  const [rotate, setRotate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce(() => {
      try {
        setIsLoading(true);
        const canvas = ref.current.getCroppedCanvas();
        onChange(canvas);
      } catch (error) {
        onChange(null);
      }
    }, DEBOUNCE_IMAGE_CHANGE),
    [],
  );

  const onRotate = () => {
    const rotationValue = rotate + IMAGE_ROTATION_DIRECTION;
    setRotate(rotationValue);
    ref.current.cropper.rotateTo(rotationValue);
  };

  const onZoomIn = () => {
    try {
      ref.current.cropper.zoom(IMAGE_ZOOM_SCALE);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onZoomOut = () => {
    try {
      ref.current.cropper.zoom(-IMAGE_ZOOM_SCALE);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <S.LogoCropperWrapper>
      <div className="innerEditorWrap">
        <div className="innerEditor">
          <section className="imgs">
            <div className="content">
              <div className="cropWrap">
                <p>Click and drag on the preview image to select your desired area</p>
                <div className="userCrop">
                  <S.Cropper
                    ref={ref}
                    src={image}
                    guides
                    zoomable
                    zoomOnWheel
                    rotatable
                    background={false}
                    wheelZoomRatio={IMAGE_ZOOM_SCALE}
                    viewMode={1}
                  />
                </div>
                <div className="imgDetails">
                  <ul>
                    <li>
                      <span onClick={onRotate}>
                        <ResetIcon />
                      </span>
                    </li>
                    <li>
                      <span onClick={onZoomOut}>
                        <ZoomOutIcon />
                      </span>
                    </li>
                    <li>
                      <span onClick={onZoomIn}>
                        <ZoomInIcon />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <div className="flex gap-x-2 justify-end">
            <Button type="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="dark" onClick={handleChange} disabled={isLoading}>
              Save changes
            </Button>
          </div>
        </div>
      </div>
    </S.LogoCropperWrapper>
  );
};

export default LogoCropper;
