import { Dropdown } from 'flowbite-react';
import { DeleteIconNew } from 'modules/v2/common/components/SvgIcon/DeleteIconNew';
import { PencilIcon } from 'modules/v2/common/components/SvgIcon/PencilIcon';
import { StarIcon } from 'modules/v2/common/components/SvgIcon/StarIcon';
import { cn } from 'modules/v2/utils/functions';

const Item = ({
  // Data
  addressLine1,
  addressLine2,
  phoneNumber,
  city,
  country,
  firstName,
  isSelected,
  lastName,
  pincode,
  state,
  defaultBilling,

  // Callbacks
  onDelete,
  onEdit,
  onSelect,
}) => {
  const fullName = `${firstName} ${lastName}`;
  const location = `${city}, ${state} ${pincode}`;

  return (
    <div className="border border-neutral-200 rounded-lg h-52 p-6 gap-3 flex flex-row-reverse">
      <div className="w-1/6 flex justify-end relative">
        {defaultBilling.allowed && (
          <Dropdown
            theme={{
              content: 'border-transparent focus:border-transparent focus:ring-0',
              inlineWrapper: 'relative w-full flex justify-end',
              base: 'z-10 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none',
              floating: {
                base: cn(
                  'bg-white border border-neutral-200 rounded-lg shadow-sm border-transparent focus:border-transparent focus:ring-0 focus:shadow-none -left-20 py-2 max-w-60',
                ),
                item: {
                  container: 'flex mr-50',
                  base:
                    'w-full flex items-center py-2 px-4 hover:bg-neutral-100 cursor-pointer w-60 border-transparent focus:border-transparent focus:ring-0',
                },
                target: 'w-fit',
              },
            }}
            dismissOnClick={false}
            renderTrigger={() => (
              <span className="text-xl font-semibold transform rotate-90 bg-neutral-200 pt-0 px-2.5 pb-4 h-9 rounded-lg cursor-pointer ">
                ...
              </span>
            )}
          >
            {!isSelected && (
              <Dropdown.Item icon={StarIcon} onClick={onSelect}>
                <span className="text-neutral-800 text-sm font-medium ps-2">Set as default</span>
              </Dropdown.Item>
            )}
            <Dropdown.Item icon={PencilIcon} onClick={onEdit}>
              <span className="text-neutral-800 text-sm font-medium ps-2">Edit address</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item icon={DeleteIconNew} onClick={onDelete}>
              <span className="text-danger-500 text-sm font-medium ps-2">Delete</span>
            </Dropdown.Item>
          </Dropdown>
        )}
      </div>
      <div className="w-5/6 text-sm text-neutral-500 font-medium flex flex-col items-start">
        <div
          className={cn(
            'w-auto mb-2',
            { 'mb-0': !isSelected },
            { 'mb-3': !defaultBilling.allowed || isSelected },
          )}
        >
          {!defaultBilling.allowed && (
            <div className="text-primary-700 text-xs py-1 px-2 bg-primary-50 rounded-md capitalize">
              {defaultBilling.id}
            </div>
          )}
          {isSelected && (
            <div className="text-primary-700 text-xs py-1 px-2 bg-primary-50 rounded-md">
              Default
            </div>
          )}
        </div>
        <div className="font-semibold text-neutral-800">{fullName}</div>
        <div>{addressLine1}</div>
        {addressLine2 && <div>{addressLine2}</div>}
        <div>{location}</div>
        <div>{country}</div>
        {phoneNumber && <div>Phone: {phoneNumber}</div>}
      </div>
    </div>
  );
};

Item.defaultProps = {
  addressLine2: undefined,
};

export default Item;
