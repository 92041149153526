import { useEffect } from 'react';
import { compose } from 'redux';
import withStore from 'store/StoreContainer';

import { Empty } from 'modules/common/components';
import { getCountries, countriesSelector, statesSelector } from 'modules/dashboard/store';

const mapDispatchToProps = { getCountries };

const withCountries =
  (Component = Empty) =>
    (props) => {
      useEffect(() => {
        props.getCountries();

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return <Component {...props} />;
    };

const mapStateToProps = (state) => ({
  countries: countriesSelector(state),
  states: statesSelector(state),
});

export default compose(withStore(mapStateToProps, mapDispatchToProps), withCountries);
