import { pluralize } from 'modules/common/utils/pluralize';
import { Loader, Scrollbars } from 'modules/common/components';
import { MODAL_HEIGHT, pluralise } from 'modules/dashboard/pages/Dentist/OrderGuideModal/constants';
import S from 'modules/publish/styles';
import CheckoutForm from '../../editor/CheckoutForm/index';

const CheckoutView = ({
  numberOfPrints,
  isDigital,
  onSubmit,
  credits: creditValue,
  isCreditLoading,
  guidePacketName,
}) => {
  if (isCreditLoading) {
    return <Loader height={MODAL_HEIGHT} title="Checking credits" />;
  }
  const credits = guidePacketName === 'guide' ? creditValue : Math.floor(creditValue / 3);

  const MIN_NUMBER_OF_PRINTS = 10;

  const intCredits = credits !== undefined ? Number(credits) : 0;
  const intNumberOfPrints = numberOfPrints === null ? intCredits : numberOfPrints;

  const plural = pluralize('credit', 'credits', creditValue);

  const fields = {
    numberOfPrints: intNumberOfPrints,
    isDigital,
  };

  return (
    <Scrollbars style={{ height: MODAL_HEIGHT }}>
      <S.ViewTitle>Select Quantity</S.ViewTitle>
      <S.ViewSubTitle>
        You have <strong>{plural}</strong> left
        <br /> <br />
        Minimum of <strong> {MIN_NUMBER_OF_PRINTS} </strong> {pluralise(guidePacketName)} per order
        is required.
        {guidePacketName === 'packet' && (
          <div>
            <br />1 Packet = 3 Credits
          </div>
        )}
      </S.ViewSubTitle>
      <S.Content>
        <CheckoutForm
          fields={fields}
          credits={intCredits}
          onSubmit={onSubmit}
          guidePacketName={guidePacketName}
        />
      </S.Content>
    </Scrollbars>
  );
};

export default CheckoutView;
