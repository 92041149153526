import styled from 'styled-components';
import { includes } from 'lodash-es';
import { getStyle } from 'modules/editor/utils';

const Text = styled('textarea', {
  shouldForwardProp: (prop) => !includes(['element', 'scale'], prop),
})`
  border: none;
  width: 100%;
  min-height: 290px;
  resize: none;

  :focus {
    outline: none;
  }

  ${({ element, scale }) => {
    const { fontFamily, fontSize, textAlign, lineHeight } = getStyle(element, scale);

    return `
      font-family: ${fontFamily};
      font-size: ${fontSize};
      text-align: ${textAlign};
      line-height: ${lineHeight};
    `;
  }}
`;

export default {
  Text,
};
