const GridIcon = ({ active = false }) => {
  const notActive = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M11.25 13.4166C11.25 11.8452 11.25 11.0596 11.7382 10.5714C12.2263 10.0833 13.012 10.0833 14.5833 10.0833C16.1547 10.0833 16.9404 10.0833 17.4285 10.5714C17.9167 11.0596 17.9167 11.8452 17.9167 13.4166V15.0833C17.9167 16.6546 17.9167 17.4403 17.4285 17.9284C16.9404 18.4166 16.1547 18.4166 14.5833 18.4166C13.012 18.4166 12.2263 18.4166 11.7382 17.9284C11.25 17.4403 11.25 16.6546 11.25 15.0833V13.4166Z"
        stroke="#757575"
        strokeWidth="1.5"
      />
      <path
        d="M1.66675 7.58325C1.66675 9.1546 1.66675 9.94027 2.1549 10.4284C2.64306 10.9166 3.42873 10.9166 5.00008 10.9166C6.57143 10.9166 7.3571 10.9166 7.84526 10.4284C8.33341 9.94027 8.33341 9.1546 8.33341 7.58325V5.91659C8.33341 4.34524 8.33341 3.55956 7.84526 3.07141C7.3571 2.58325 6.57143 2.58325 5.00008 2.58325C3.42873 2.58325 2.64306 2.58325 2.1549 3.07141C1.66675 3.55956 1.66675 4.34524 1.66675 5.91659V7.58325Z"
        stroke="#757575"
        strokeWidth="1.5"
      />
      <path
        d="M11.25 5.08325C11.25 4.30668 11.25 3.9184 11.3769 3.61211C11.546 3.20373 11.8705 2.87928 12.2789 2.71012C12.5851 2.58325 12.9734 2.58325 13.75 2.58325H15.4167C16.1932 2.58325 16.5815 2.58325 16.8878 2.71012C17.2962 2.87928 17.6206 3.20373 17.7898 3.61211C17.9167 3.9184 17.9167 4.30668 17.9167 5.08325C17.9167 5.85982 17.9167 6.24811 17.7898 6.55439C17.6206 6.96277 17.2962 7.28723 16.8878 7.45638C16.5815 7.58325 16.1932 7.58325 15.4167 7.58325H13.75C12.9734 7.58325 12.5851 7.58325 12.2789 7.45638C11.8705 7.28723 11.546 6.96277 11.3769 6.55439C11.25 6.24811 11.25 5.85982 11.25 5.08325Z"
        stroke="#757575"
        strokeWidth="1.5"
      />
      <path
        d="M1.66675 15.9167C1.66675 16.6933 1.66675 17.0816 1.79362 17.3879C1.96277 17.7963 2.28723 18.1207 2.69561 18.2899C3.00189 18.4167 3.39018 18.4167 4.16675 18.4167H5.83341C6.60998 18.4167 6.99827 18.4167 7.30455 18.2899C7.71293 18.1207 8.03739 17.7963 8.20655 17.3879C8.33341 17.0816 8.33341 16.6933 8.33341 15.9167C8.33341 15.1402 8.33341 14.7519 8.20655 14.4456C8.03739 14.0372 7.71293 13.7128 7.30455 13.5436C6.99827 13.4167 6.60998 13.4167 5.83341 13.4167H4.16675C3.39018 13.4167 3.00189 13.4167 2.69561 13.5436C2.28723 13.7128 1.96277 14.0372 1.79362 14.4456C1.66675 14.7519 1.66675 15.1402 1.66675 15.9167Z"
        stroke="#757575"
        strokeWidth="1.5"
      />
    </svg>
  );

  const isActive = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M10.8333 13.3333C10.8333 11.6048 10.8333 10.7406 11.3824 10.2036C11.9316 9.66663 12.8155 9.66663 14.5833 9.66663C16.351 9.66663 17.2349 9.66663 17.7841 10.2036C18.3333 10.7406 18.3333 11.6048 18.3333 13.3333V15.1666C18.3333 16.8951 18.3333 17.7594 17.7841 18.2963C17.2349 18.8333 16.351 18.8333 14.5833 18.8333C12.8155 18.8333 11.9316 18.8333 11.3824 18.2963C10.8333 17.7594 10.8333 16.8951 10.8333 15.1666V13.3333Z"
        fill="#3C3C3C"
      />
      <path
        d="M1.66675 7.66663C1.66675 9.39511 1.66675 10.2594 2.21592 10.7963C2.7651 11.3333 3.64898 11.3333 5.41675 11.3333C7.18451 11.3333 8.0684 11.3333 8.61757 10.7963C9.16675 10.2594 9.16675 9.39511 9.16675 7.66663V5.83329C9.16675 4.10481 9.16675 3.24057 8.61757 2.7036C8.0684 2.16663 7.18451 2.16663 5.41675 2.16663C3.64898 2.16663 2.7651 2.16663 2.21592 2.7036C1.66675 3.24057 1.66675 4.10481 1.66675 5.83329V7.66663Z"
        fill="#3C3C3C"
      />
      <path
        d="M10.8333 5.08329C10.8333 4.1773 10.8333 3.7243 10.976 3.36696C11.1663 2.89052 11.5313 2.51199 11.9907 2.31464C12.3353 2.16663 12.7721 2.16663 13.6458 2.16663H15.5208C16.3944 2.16663 16.8312 2.16663 17.1758 2.31464C17.6352 2.51199 18.0002 2.89052 18.1905 3.36696C18.3333 3.7243 18.3333 4.1773 18.3333 5.08329C18.3333 5.98929 18.3333 6.44229 18.1905 6.79962C18.0002 7.27607 17.6352 7.6546 17.1758 7.85195C16.8312 7.99996 16.3944 7.99996 15.5208 7.99996H13.6458C12.7721 7.99996 12.3353 7.99996 11.9907 7.85195C11.5313 7.6546 11.1663 7.27607 10.976 6.79962C10.8333 6.44229 10.8333 5.98929 10.8333 5.08329Z"
        fill="#3C3C3C"
      />
      <path
        d="M1.66675 15.9167C1.66675 16.8227 1.66675 17.2757 1.80947 17.633C1.99978 18.1094 2.36479 18.488 2.82422 18.6853C3.16879 18.8333 3.60561 18.8333 4.47925 18.8333H6.35425C7.22789 18.8333 7.66471 18.8333 8.00928 18.6853C8.46871 18.488 8.83372 18.1094 9.02402 17.633C9.16675 17.2757 9.16675 16.8227 9.16675 15.9167C9.16675 15.0107 9.16675 14.5577 9.02402 14.2003C8.83372 13.7239 8.46871 13.3454 8.00928 13.148C7.66471 13 7.22789 13 6.35425 13H4.47925C3.60561 13 3.16879 13 2.82422 13.148C2.36479 13.3454 1.99978 13.7239 1.80947 14.2003C1.66675 14.5577 1.66675 15.0107 1.66675 15.9167Z"
        fill="#3C3C3C"
      />
    </svg>
  );

  return active ? isActive : notActive;
};

export default GridIcon;
