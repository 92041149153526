import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Template Name is required'),
  subject: Yup.string().trim().required('Email Subject is required'),
  content: Yup.string().trim().required('Content is required'),
  bodyContent: Yup.string(),
  imageUrl: Yup.string(),
  templateTitle: Yup.string(),
  emailTemplate: Yup.number(),
});

export default validationSchema;
