import { useState } from 'react';
import { noop } from 'lodash-es';

import { Modal } from 'modules/common/components';

import ModalContent from './ModalContent';

// TODO: needs to be moved on into common module components
const FilePickerModal = ({
  // DATA
  images,
  logos,
  type,
  isOpen,
  title,
  isConfirmLoading,
  isImagesLoading,
  isLogosLoading,

  // CALLBACKS
  onConfirm,
  onCancel,
  onDelete,
  onGalleryTabOpen = noop,
  onLogosTabOpen,
}) => {
  const [image, setImage] = useState(null);
  const [imageFormat, setImageFormat] = useState();
  const [group, setGroup] = useState('upload');

  const handleConfirm = () => {
    if (onConfirm) {
      if (image instanceof HTMLCanvasElement) {
        onConfirm(image.toDataURL(imageFormat, 0.7), group, imageFormat);
      } else if (typeof image === 'string') {
        onConfirm(image, group, imageFormat);
      }
    }
  };

  const handleSelect = (img, format) => {
    setImage(img);
    setImageFormat(format);
  };

  return (
    <Modal
      isOpen={isOpen}
      confirmLoading={isConfirmLoading}
      maskClosable={false}
      title={title}
      width="700px"
      onConfirm={handleConfirm}
      okButtonProps={{ disabled: image === null }}
      onCancel={onCancel}
    >
      <ModalContent
        type={type}
        images={images}
        logos={logos}
        isImagesLoading={isImagesLoading}
        isLogosLoading={isLogosLoading}
        onChangeTab={setGroup}
        onSelect={handleSelect}
        onDelete={onDelete}
        onGalleryTabOpen={onGalleryTabOpen}
        onLogosTabOpen={onLogosTabOpen}
      />
    </Modal>
  );
};

export default FilePickerModal;
