import { useState, useRef } from 'react';

import { ResetIcon, ZoomInIcon, ZoomOutIcon } from 'modules/common/components/SvgIcon';

import 'cropperjs/dist/cropper.css';

import S from './styles';

const IMAGE_ROTATION_DIRECTION = -90;
const IMAGE_ZOOM_SCALE = 0.1;

const LogoCropper = ({ image, onChange, handleCancel }) => {
  const ref = useRef(null);
  const [rotate, setRotate] = useState(0);

  const handleChange = () => {
    const canvas = ref.current.getCroppedCanvas();
    onChange(canvas);
  };


  const onRotate = () => {
    const rotationValue = rotate + IMAGE_ROTATION_DIRECTION;
    setRotate(rotationValue);
    ref.current.cropper.rotateTo(rotationValue);
  };

  const onZoomIn = () => {
    try {
      ref.current.cropper.zoom(IMAGE_ZOOM_SCALE);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onZoomOut = () => {
    try {
      ref.current.cropper.zoom(-IMAGE_ZOOM_SCALE);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <S.LogoCropperWrapper>
      <div className="innerEditorWrap">
        <div className="innerEditor">
          <section className="imgs">
            <div className="content">
              <div className="cropWrap">
                <p>Click and drag on the preview image to select your desired area</p>
                <div className="userCrop">
                  <S.Cropper
                    ref={ref}
                    src={image}
                    guides
                    zoomable
                    zoomOnWheel
                    rotatable
                    background={false}
                    wheelZoomRatio={IMAGE_ZOOM_SCALE}
                    viewMode={1}
                  />
                </div>
                <div className="imgDetails">
                  <ul>
                    <li>
                      <span onClick={onRotate}>
                        <ResetIcon />
                      </span>
                    </li>
                    <li>
                      <span onClick={onZoomOut}>
                        <ZoomOutIcon />
                      </span>
                    </li>
                    <li>
                      <span onClick={onZoomIn}>
                        <ZoomInIcon />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <S.BtnWrapper>
            <S.Btn type="button" onClick={handleCancel}>
                Cancel
            </S.Btn>
            <S.PrimaryBtn type="button" onClick={handleChange}>
                Save changes
            </S.PrimaryBtn>
          </S.BtnWrapper>
        </div>
      </div>
    </S.LogoCropperWrapper>
  );
};

export default LogoCropper;
