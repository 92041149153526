import styled from 'styled-components';
import { Steps as BaseSteps } from 'antd';

import * as color from 'modules/common/theme/color';

const Steps = styled(BaseSteps)`
  padding: 0;
  margin-bottom: 25px;
  border-bottom: 1px solid ${color.TitleBorder};
`;

const Step = styled(BaseSteps.Step)`
  margin-bottom: -1px;

  // Prevent text selection on double click
  user-select: none;

  &.ant-steps-item-disabled {
    cursor: not-allowed;
  }
`;

export default {
  Steps,
  Step,
};
