import { Public } from 'modules/router/types';
import { Authentication } from 'modules/auth/pages';

export const routes = [
  {
    type: Public,
    exact: true,
    path: '/auth/auto',
    component: Authentication,
  },
];
