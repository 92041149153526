import axios from 'axios';
import qs from 'query-string';

import env from 'environments';

const adminInstance = axios.create({
  baseURL: env.ADMIN_URL,
  paramsSerializer: qs.stringify,
});

const getAdminInstance = () => adminInstance;

export default getAdminInstance;
