import { push } from 'connected-react-router';
import {
  getRouteEditDraft,
  getRouteEditDraftCover,
  getRouteEditDraftManageSections,
  getRouteEditDraftContent,
} from 'modules/v2/editor/routes/navigation';
import { getRouteDraftView } from 'modules/v2/draft/routes/navigation';

import { isDevelopmentEnvironment } from 'modules/core/settings';

export const goToDraftView = (draftId) => {
  const route = getRouteDraftView(draftId);
  return push(route);
};

export const goToEditDraft = (draftId) => {
  const route = getRouteEditDraft(draftId);
  return push(route);
};

export const goToEditDraftCover = (draftId) => {
  const route = getRouteEditDraftCover(draftId);
  return push(route);
};

export const goToEditDraftManageSections = (draftId) => {
  const route = getRouteEditDraftManageSections(draftId);
  return push(route);
};

export const goToEditDraftContent = (draftId, sectionId) => {
  const route = getRouteEditDraftContent(draftId, sectionId);
  return push(route);
};

export const goToRoot = () => {
  if (isDevelopmentEnvironment()) {
    // TODO: Fix error handlers
    // eslint-disable-next-line no-console
    console.warn('If you are handling an error, consider using the toast instead');
  }

  const route = '/';
  return push(route);
};
