import { PageSubTitle } from 'modules/onboard/components';
import { guidesInformation, guidesPacketInformation } from 'modules/common/utils';

import S from './styles';

const GuideContent = () => {
  const guides = [...guidesInformation];

  return (
    <>
      <PageSubTitle>Your Guides</PageSubTitle>
      <S.GuideBox>
        <S.GuidePacketContainer>
          <img src={guidesPacketInformation.thumbnailUrl} alt="guides packet" />
          <S.GuideCardContainer>
            <S.YourGuideTitle>{guidesPacketInformation.name}</S.YourGuideTitle>
            <ul>
              {guides.map(({ name }) => (
                <S.PageSubTitleDescription>50 copies - {name}</S.PageSubTitleDescription>
              ))}
            </ul>
          </S.GuideCardContainer>
        </S.GuidePacketContainer>
        <S.YourGuideContainer>
          {guides?.length &&
            guides.map(({ thumbnailUrl, name }) => (
              <S.GuideWrapper key={thumbnailUrl}>
                <S.GuideContainer>
                  <img src={thumbnailUrl} alt={name} />
                </S.GuideContainer>
              </S.GuideWrapper>
            ))}
        </S.YourGuideContainer>
      </S.GuideBox>
    </>
  );
};

export default GuideContent;
