import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const OrderNumber = styled.span`
  font-size: 16px;
  font-weight: 400;
  border: 1px solid ${color.gray300};
  border-radius: 4px;
  padding: 20px;
  color: ${color.GreyText};
  margin-bottom: 40px;
  text-align: center;
  padding: 10px 80px;

  strong {
    font-weight: 700;
  }
`;

const ModalText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${color.GreyText};
  margin-bottom: 20px;
  text-align: center;
  display: inline-block;
`;

const UpsellModalContent = ({ orderNumber }) => (
  <>
    <ModalText>Thank you for subscribing!</ModalText>
    <ModalText>
      Your payment was successfully processed. <br />
      Proceed with scheduling with a consultant and setting up your first book.
    </ModalText>

    <OrderNumber>
      Your reference number: <br />
      <strong>{orderNumber}</strong>
    </OrderNumber>
  </>
);

export default UpsellModalContent;
