import { delay, fork, put, race, select, take } from 'redux-saga/effects';

import { saveDraft, isSavingSelector, isPendingSaveSelector } from 'modules/editor/store';

import {
  MARK_COVER,
  UNMARK_COVER,
  MARK_CONTENT,
  UNMARK_CONTENT,
  MARK_PREFERENCES,
  UNMARK_PREFERENCES,
  MARK_SECTIONS,
  UNMARK_SECTIONS,
  RESET_DRAFT,
  SAVE_DRAFT,
  SAVE_PREFERENCES,
} from 'modules/editor/store/constants';

const AUTO_SAVE_MILLISECONDS = 5 * 1000;

const trigger = [
  MARK_COVER,
  UNMARK_COVER,
  MARK_CONTENT,
  UNMARK_CONTENT,
  MARK_PREFERENCES,
  UNMARK_PREFERENCES,
  MARK_SECTIONS,
  UNMARK_SECTIONS,
];

const cancel = [SAVE_PREFERENCES, SAVE_DRAFT, RESET_DRAFT];

export function* onAutoSave() {
  const action = saveDraft();
  yield put(action);
}

export function* debounceAutoSave() {
  while (true) {
    let action = yield take(trigger);

    if (action) {
      while (true) {
        const [debounced, triggered, cancelled] = yield race([
          delay(AUTO_SAVE_MILLISECONDS),
          take(trigger),
          take(cancel),
        ]);

        // Stops the exection case a cancel is fired
        if (cancelled) {
          break;
        }

        const saving = yield select(isSavingSelector);
        const pending = yield select(isPendingSaveSelector);

        // Stops the exection case it's already saving or there are no unsaved changes
        if (saving === true || pending === false) {
          break;
        }

        // Triggers the save
        if (debounced) {
          yield fork(onAutoSave, action);
          break;
        }

        action = triggered;
      }
    }
  }
}

export function* watchAutoSave() {
  yield fork(debounceAutoSave);
}
