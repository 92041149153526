import * as Yup from 'yup';

const campaignSegments = Yup.array()
  .when('allSegments', {
    is: false,
    then: Yup.array().of(Yup.number().min(1))
      .required('Select at least one segment'),
  });

export default campaignSegments;
