import styled from 'styled-components';

import * as font from 'modules/common/theme/font';

const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;

  margin-bottom: 12px;

  h3 {
    font-size: 24px;
    font-family: ${font.NunitoFont};
    letter-spacing: 0px;
    line-height: 32px;
  }
`;

const IframeWrapper = styled.div`
  height: 421px;
  margin-bottom: 20px;
`;

export default {
  Title,
  IframeWrapper,
};
