import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Input, Button, Form } from 'antd';
import LoginBackground from 'assets/images/login-bg.png';
import * as font from 'modules/common/theme/font';
import * as color from 'modules/common/theme/color';

const { Password } = Input;

const formItem = Form.Item;

const FULL_VIEWPORT_HEIGHT = 'calc(100vh - 32px)';
const PARTIAL_VIEWPORT_HEIGHT = 'calc(40vh - 32px)';

const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${(props) => (!props.noBackground ? FULL_VIEWPORT_HEIGHT : PARTIAL_VIEWPORT_HEIGHT)};
  background-image: ${(props) => (!props.noBackground ? `url(${LoginBackground})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;

  font-family: ${font.RobotoFont};

  .loader {
    height: auto;
  }
`;

const BackWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => (!props.noBackground ? '62px' : '0')};

  max-width: 1400px;
  width: 100%;
  height: 100%;
  margin: 0 20px;

  text-align: center;

  @media only screen and (max-width: 1024px) {
    background-image: none;
    padding: 0;
  }
`;

const FormContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  width: 440px;
  height: 100%;
  padding: ${(props) => (!props.noLogo ? '40px' : '0')};
  border-radius: 2px;
  background-color: ${color.White};
`;

const Sidebar = styled.div`
  img {
    max-width: 700px;
    width: 100%;
  }

  width: 100%;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const LoginInput = styled(Input)`
  border: 1px solid ${color.Black};
  width: 360px !important;
  height: 60px !important;
  border-radius: 2px !important;

  &:hover {
    border-color: ${color.InputFocusBorder} !important;
  }
`;

const LoginPasswordInput = styled(Password)`
  width: 360px !important;
  height: 60px !important;
  border: 1px solid ${color.Black};
  border-radius: 2px !important;

  .ant-input {
    border: none !important;
  }
`;

const LoginButton = styled(Button)`
  width: 50%;
  margin-bottom: 20px;
  margin-top: 10px;

  &.ant-btn {
    height: 48px;
    font-size: 22px !important;
    font-family: ${font.RobotoFont} !important;
    font-weight: normal !important;
    border-radius: 2px;
    line-height: 30px;
  }

  &.ant-btn.ant-btn-primary:hover {
    background-color: ${color.SecondaryColor};
    border-color: ${color.SecondaryColor};
    transition: all 0.3s ease-in-out;
  }
`;

const Description = styled.span`
  align-self: center;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
`;

const LoginLink = styled(Link)`
  align-self: center;
  font-size: 18px;
  color: ${color.PrimaryColor};
  line-height: 24px;
  letter-spacing: 0px;

  :hover {
    color: ${color.SecondaryColor};
    transition: all 0.3s ease-in-out;
  }
`;

const LoginForm = styled(Form)`
  width: 360px;

  && {
    padding-top: 20px;

    .ant-form-item {
      font-size: 24px !important;
    }
  }
`;

const FormItem = styled(formItem)`
  text-align: left;

  && {
    .ant-form-item-label {
      padding: 0 0 2px;
    }

    .ant-input {
      color: ${color.Black};
      font-size: 18px;
      border: 1px solid ${color.Black};
      border-radius: 2px;

      ::-webkit-input-placeholder {
        color: ${color.Black};
      }

      :-ms-input-placeholder {
        color: ${color.Black};
      }

      ::placeholder {
        color: ${color.Black};
      }
    }
  }
`;

const DefaultLink = styled.a`
  &:hover {
    text-decoration: underline !important;
  }
`;

const ContactNumber = styled.span`
  font-size: 16px;
  color: ${color.PrimaryColor};
  margin-left: 5px;
`;

const LoginLogo = styled.div`
  margin: 0 0 15px 0;
`;

const LoginErrorWrap = styled.div`
  text-align: left;
  border-radius: 3px;
  background: ${color.ErrorBg};
  padding: 10px 15px 10px;
  margin: 20px auto 0;
  border: 1px solid ${color.ErrorBdr};

  p {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0 0 0 8px;
    font-weight: 400;
    position: relative;
    padding: 3px 10px;
    color: ${color.GreySelectDd};
    text-align: left;
  }

  &::after {
    content: "";
    position: absolute;
    top: 10px;
    left: -8px;
    margin-right: 10px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: ${color.GreySelectDd};
  }
}
`;

export default {
  Container,
  FormContent,
  Sidebar,
  LoginWrapper,
  LoginForm,
  FormItem,
  LoginInput,
  LoginPasswordInput,
  LoginButton,
  LoginLogo,
  LoginLink,
  DefaultLink,
  LoginErrorWrap,
  Description,
  BackWrapper,
  ContactNumber,
};
