import { Flowbite, Pagination } from 'flowbite-react';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import customTheme from '../../utils/customTheme';

interface TablePagination {
  pageSize: number | string;
  total: number | string;
  lastPage: number | string;
  currentPage: number | string;
  onChange: (page: number) => void;
}

const PaginationFlowBite: React.FC<{
  tablePagination: TablePagination,
  handlePerPage: (selectedOption: any, firstItemIndex: number) => void,
}> = ({ tablePagination, handlePerPage }) => {
  const {
    pageSize: perPage,
    total: totalPage,
    lastPage: lastPageNumber,
    currentPage,
    onChange,
  } = tablePagination;
  const [showIcons, setShowIcons] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 430) {
        setShowIcons(false);
      } else {
        setShowIcons(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const total = Number(totalPage);
  const lastPage = Number(lastPageNumber);

  const numNextPage = Number(currentPage);
  const numPerPage = Number(perPage);
  const firstItemIndex = (numNextPage - 1) * numPerPage + 1;
  const lastItemIndex = numNextPage * numPerPage;
  const lastItemValueShow = lastItemIndex >= total ? total : lastItemIndex;
  const content = `Showing ${firstItemIndex} to ${lastItemValueShow} of ${total}`;
  const lastItem = Math.floor(Number(total) / Number(perPage));

  const itemsPerPage = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
  ];

  return (
    <div className="flex justify-between items-center max-[1180px]:flex-col h-full">
      <div className="flex items-center flex-wrap justify-center">
        View
        <Select
          className="w-20 mx-2"
          defaultValue={itemsPerPage.find((option) => option.value === numPerPage)}
          onChange={(value) => handlePerPage && handlePerPage(value, firstItemIndex)}
          options={itemsPerPage}
        />
        {content}
      </div>
      <div>
        <Flowbite theme={{ theme: customTheme }}>
          <Pagination
            currentPage={numNextPage}
            totalPages={lastPage || 100}
            previousLabel="Prev"
            onPageChange={onChange}
            showIcons={showIcons}
          />
        </Flowbite>
      </div>
    </div>
  );
};

export default PaginationFlowBite;
