import withStore from 'store/StoreContainer';
import { getUserCredits, creditsSelector } from 'modules/publish/store';

const mapDispatchToProps = {
  getUserCredits,
};

const mapStateToProps = (state) => ({
  credits: creditsSelector(state),
});

export default withStore(mapStateToProps, mapDispatchToProps);
