import styled from 'styled-components';

const Avatar = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

Avatar.Image = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: ${({ $rounded }) => ($rounded ? '60px' : '0')};
  align-items: center;
`;

Avatar.Wrapper = styled.div`
  border-radius: ${({ $rounded }) => ($rounded ? '50%' : '0')};
  background-color: rgb(230, 230, 230);
`;

export default {
  Avatar,
};
