import styled from 'styled-components';

export const ShareToSocialMedia = styled.div`
  display: flex;
`;

export const PlatformIcon = styled.a`
  cursor: pointer;
  margin-left: 10px;
`;

export default {
  ShareToSocialMedia,
  PlatformIcon,
};
