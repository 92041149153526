import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';
import * as font from 'modules/v2/common/theme/font';

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  border-radius: 10px;

  .ant-modal-body {
    padding: 0;
    background-color: transparent;
  }
`;

const IntakeFormWrapper = styled.div`
  flex: 2;
  align-items: stretch;
  padding: 40px;
  background-color: rgb(232, 240, 254);
`;

const Sidebox = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex: 1;
  padding: 0 40px;
  position: relative;
  background: ${color.PrimaryColor};
  font-family: ${font.NunitoFont};
  color: ${color.White};
  font-size: 28px;
  font-weight: 700;

  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 0px;
    margin-top: -25px;
    border-top: solid 25px transparent;
    border-bottom: solid 25px transparent;
    border-left: solid 15px ${color.PrimaryColor};
  }
`;

Sidebox.Text = styled.p`
  margin-bottom: 0;
`;

export default { Wrapper, IntakeFormWrapper, Sidebox };
