import styled from 'styled-components';
import { Button } from 'antd';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Wrapper = styled.div`
  padding: 25px 30px;
  background-color: #f5f9fd;
`;

Wrapper.Container = styled.div`
  @media only screen and (min-width: 1823px) {
    margin: 0 50px;
    padding: 0 20px;
    max-width: none;
  }
  @media only screen and (min-width: 2560px) {
    max-width: 1700px;
    margin: 0 auto;
    padding: 0 20px;
  }
`;

Wrapper.Flex = styled.div`
  display: flex;
  height: calc(100vh - 100px);
  width: 100%;
`;

Wrapper.Flex.Inner = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
`;

Wrapper.CoverImageWrap = styled.div`
  height: 100%;
  text-align: center;
`;

const ViewTitle = styled.h1`
  font-size: 36px;
  font-weight: bold;
  line-height: 32px;
  font-family: ${font.MontserratFont};
  color: ${color.GreySelectDd};
  padding: 20px 0 30px;
  text-align: center;
`;

const ViewSubTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  padding: 0 0 20px 0;
  margin: 0;
  color: ${color.GreySelectDd};
  text-align: center;

  strong {
    color: ${({ fewCredits }) => (fewCredits ? color.DangerRed : color.Black)};
  }
`;

const Page = styled.div`
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  padding: 30px 10px;
  justify-content: center;
  align-items: stretch;
  height: calc(100vh - 60px);
`;

const Content = styled.div`
  margin: 20px auto 30px;
`;

const ConfirmationButton = styled(Button)`
  display: block;
  margin: 20px auto 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 100%;
  padding: 24px;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContentDescription = styled.p`
  font-size: 16px;
  text-align: center;
  b {
    font-weight: 700;
    margin-right: 2px;
  }
`;

export default {
  Page,
  Wrapper,
  Row,
  ViewTitle,
  ViewSubTitle,
  Content,
  ConfirmationButton,
  ContentContainer,
  ContentDescription,
};
