import styled from 'styled-components';

import * as color from 'modules/v2/common/theme/color';
import { TickedIcon as BaseTickedIcon } from 'modules/onboard/components/UIComponents';

const AvailableAnymore = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: ${color.Black};
  margin-bottom: 10px !important;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px !important;
`;

const ListText = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${color.Black};
  margin-left: 8px !important;
`;

const TickedIcon = styled(BaseTickedIcon)`
  color: ${(props) => (props.colorCode ? color[props.colorCode] : color.DefaultBlue)};
  font-size: 16px;
`;

const List = styled.div`
  margin-bottom: 30px;
`;

export default {
  List,
  ListItem,
  ListText,
  AvailableAnymore,
  TickedIcon,
};
