import styled from 'styled-components';
import { TertiaryButton } from 'modules/common/components';
import * as color from 'modules/common/theme/color';

const TemplateButton = styled(TertiaryButton)`
  font-weight: normal !important;

  svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
    font-size: 16px;
  }
`;

const TemplateWrapper = styled.div`
  max-width: 710px;
  margin: 20px auto;
  border-radius: 2px;
  border: 1px solid ${color.GreyBorder};
  padding: 20px;
  max-height: 450px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  img {
    margin: 10px auto;
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  overflow: auto;
  max-height: 450px;
  background: ${color.White};
  padding: 18px;
  border-top: 1px solid ${color.neutral200};
`;

const ModalBody = styled.h3`
  margin: 20px 0 10px 0 !important;
  color: ${color.DeleteLeadModalText};
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0 !important;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
`;

const SwitchLabel = styled.span`
  margin-right: 10px;
  font-size: 16px;
  display: inline-block;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default {
  ModalBody,
  SwitchContainer,
  SwitchLabel,
  TemplateButton,
  TemplateWrapper,
  Toolbar,
  Wrapper,
  ButtonWrapper,
};
