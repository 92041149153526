import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { get } from 'lodash-es';

import { isLoading, isSucceeded, isFailed } from 'store/status';
import { fetchUserCredits } from 'modules/api';
import { getFlipbook } from 'modules/common/utils';
import { getRouteEditDraftCover } from 'modules/editor/routes/navigation';
import { withAuth } from 'modules/auth/containers';
import { withProfile } from 'modules/dashboard/containers';
import { useGetCustomerSubscriptionStatus } from 'modules/dashboard/utils';
import { PublishModal } from 'modules/publish';
import {
  PrimaryButton,
  Modal,
  PrimaryBlackButton,
  SecondaryBlackButton,
} from 'modules/common/components';
import { DraftTemplate } from 'modules/draft/templates';
import { withDraft, withPublish, withView } from 'modules/draft/containers';
import { ElementWithTooltip, Iframe, GenerateDigitalBookModal } from 'modules/draft/components';
import { isPreviewStatusReady, isPreviewStatusProcessing } from 'modules/draft/utils/preview';

import S from './styles';

const View = ({
  avatar,
  draftId,
  draftName,
  links,
  preflight,
  preview,
  profile,
  getPublish,
  clearDigitalBook,
  cancelGenerateDigitalBook,
  clearPublish,
  signOut,
  submitOrder,
  generateDigitalBook,
  generateDraftPreview,
  getDraftPreview,
  getDraftLinks,
  goToEditDraftCover,
  goToEditDraftContent,
  goToMyOrders,
}) => {
  const [isGenerationModalOpen, setIsGenerationModalOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [userBookCredit, setUserBookCredit] = useState(null);

  const firstName = get(profile, ['data', 'firstName'], '');
  const email = get(profile, ['data', 'email'], '');
  const modalTitle = `Book Title: ${draftName}`;

  const digitalBookStatus = get(links, ['data', 'status']);
  const isDigitalBookReady = isSucceeded(links.status) && isPreviewStatusReady(digitalBookStatus);
  const isGenerateDisabled = isLoading(links.status) || isPreviewStatusReady(digitalBookStatus);
  const isGenerateLoading = isLoading(links.status) || isPreviewStatusProcessing(digitalBookStatus);

  const isPreviewLoading = isLoading(preview.status);
  const isPreviewFailed = isFailed(preview.status);
  const previewUrl = get(preview, ['data', 'url']);
  const previewLastModified = get(preview, ['data', 'lastModified']);
  const flipbookUrl = getFlipbook(previewUrl, previewLastModified);

  const landingPageUrl = get(links, ['data', 'url', 'site'], '');
  const digitalBookUrl = get(links, ['data', 'url', 'book'], '');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserCredits();
        setUserBookCredit(response.data);
      } catch (error) {
        console.error('Error fetching user credits:', error);
      }
    };
    fetchData();
  }, []);

  const bookCredit = userBookCredit?.data?.credits || 0;
  const customerSubscriptionStatus = useGetCustomerSubscriptionStatus();
  const isTrialCustomer = customerSubscriptionStatus === 'trialing';

  useEffect(() => {
    getDraftPreview(draftId);
    getDraftLinks(draftId, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isPreflightCheckFailed = isFailed(preflight.status);
    const isDigitalBookFailed = isFailed(links.status);

    if (isPreflightCheckFailed || isDigitalBookFailed) {
      setIsGenerationModalOpen(false);
      clearDigitalBook();
    }

    if (isDigitalBookReady) {
      setIsGenerationModalOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preflight.status, links.status, isDigitalBookReady]);

  const handlePublishDraft = () => {
    if (isTrialCustomer && bookCredit < 8) {
      return setIsWarningModalOpen(true);
    }
    return setPublishModalOpen(true);
  };

  const handleCancelPublish = () => {
    setPublishModalOpen(false);
    clearPublish();
  };

  const handleClosePublish = () => {
    setPublishModalOpen(false);
    clearPublish();
  };

  const handleGenerateDigitalBookClick = () => {
    setIsGenerationModalOpen(true);
  };

  const handleGenerationModalOpen = () => {
    generateDigitalBook(draftId);
  };

  const handleGenerationModalClose = () => {
    setIsGenerationModalOpen(false);
  };

  const handleGenerationModalCancel = () => {
    setIsGenerationModalOpen(false);
    cancelGenerateDigitalBook();
  };

  const handleGenerationModalEditCover = () => {
    goToEditDraftCover(draftId);
  };

  const handleGenerationModalEditSection = (sectionId) => {
    goToEditDraftContent(draftId, sectionId);
  };

  const handleRetry = () => {
    generateDraftPreview(draftId);
  };

  const handleClickEditBook = () => {
    window.location.assign(getRouteEditDraftCover(draftId));
  };

  const footer = (
    <S.Footer>
      <S.Footer.Title>
        Book Title: <span>{draftName}</span>
      </S.Footer.Title>

      <S.Footer.ButtonWrapper>
        <PrimaryButton type="link" key="editBook1" onClick={handleClickEditBook}>
          Edit Book
        </PrimaryButton>
        <PrimaryButton key="orderBook" onClick={handlePublishDraft}>
          Order Books
        </PrimaryButton>

        <ElementWithTooltip
          isEnabled={isPreviewStatusReady(digitalBookStatus)}
          overlayStyle={{ maxWidth: '265px' }}
          title="To generate a new version of your book, please make edits to it."
        >
          <PrimaryButton
            disabled={isGenerateDisabled}
            key="generateDigitalBook"
            loading={isGenerateLoading}
            onClick={handleGenerateDigitalBookClick}
          >
            Generate Digital Book
          </PrimaryButton>
        </ElementWithTooltip>

        {isDigitalBookReady && (
          <S.LinksWrapper>
            <S.LinksWrapper.Link
              key="landingPage"
              id="landingPage"
              title="Landing Page"
              href={landingPageUrl}
            />
            <S.LinksWrapper.Link
              key="digitalBook"
              id="digitalBook"
              title="Digital Book"
              href={digitalBookUrl}
            />
          </S.LinksWrapper>
        )}
      </S.Footer.ButtonWrapper>
    </S.Footer>
  );

  return (
    <DraftTemplate
      avatar={avatar}
      email={email}
      footer={footer}
      username={firstName}
      onLogout={signOut}
    >
      <Iframe
        isLoading={isPreviewLoading}
        hasError={isPreviewFailed}
        src={flipbookUrl}
        onRetry={handleRetry}
      />
      {publishModalOpen && (
        <PublishModal
          isOpen
          draftId={draftId}
          title={modalTitle}
          onCancel={handleCancelPublish}
          onClose={handleClosePublish}
          onComplete={goToMyOrders}
          onOpen={getPublish}
          onSubmit={submitOrder}
        />
      )}
      {isWarningModalOpen && (
        <Modal
          isOpen={isWarningModalOpen}
          maskClosable
          title="Warning"
          width="auto"
          footer={[
            <S.ModalFooterContainer key={1}>
              <PrimaryBlackButton style={{ width: '80%' }} disabled>
                Buy Credits
              </PrimaryBlackButton>
              <SecondaryBlackButton
                style={{ width: '80%' }}
                onClick={() => {
                  setIsWarningModalOpen(false);
                  setPublishModalOpen(false);
                }}
              >
                Cancel, I'll buy later
              </SecondaryBlackButton>
            </S.ModalFooterContainer>,
          ]}
        >
          <S.ContentContainer>
            <S.ContentTitle>
              You have <span>{bookCredit} credits</span> left
            </S.ContentTitle>
            <S.ContentDescription>1 Credit = 1 Printed Book</S.ContentDescription>
            <S.ContentDescription>
              Please check with support to learn more about Authorify plans.
            </S.ContentDescription>
          </S.ContentContainer>
        </Modal>
      )}
      <GenerateDigitalBookModal
        isOpen={isGenerationModalOpen}
        draftId={draftId}
        preflight={preflight}
        title={modalTitle}
        onOpen={handleGenerationModalOpen}
        onCancel={handleGenerationModalCancel}
        onClose={handleGenerationModalClose}
        onEditCover={handleGenerationModalEditCover}
        onEditSection={handleGenerationModalEditSection}
      />
    </DraftTemplate>
  );
};

export default compose(withAuth, withProfile, withDraft, withPublish, withView)(View);
