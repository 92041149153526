import qs from 'query-string';
import env from 'environments';

const prefix = env.BASE_AUTH_URL_STATIC_FILES;

export const getFlipbook = (url, timestamp) => {
  // The file url needs to have the timestamp
  const params = qs.stringify({ timestamp });

  // The timestamp is added as query params
  const file = window.btoa(`${url}?${params}`);

  // The file url becomes another param
  const query = qs.stringify({ file });

  // The file url in injected into the iframe url as a param
  return `${prefix}/pdf-flipbook/index.html?${query}`;
};
