import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Enter new password')
    .matches(/[a-z]+/, 'One lowercase character')
    .matches(/[A-Z]+/, 'One uppercase character')
    .matches(/[@$!%*#?&]+/, 'One special character')
    .matches(/\d+/, 'One number')
    .min(8, 'Must be 8 characters or more'),
  passwordConfirmation: Yup.string()
    .trim()
    .required('Enter new password')
    .when('password', {
      is: (password) => !!(password && password.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Password does not match'),
    }),
});

export default validationSchema;
