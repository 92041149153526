import { Input } from 'antd';
import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';
import * as color from 'modules/common/theme/color';

const Form = styled.form`
  text-align: left;
`;

// TODO: Fix with .withComponent()
const inputStyle = css`
  height: 45px;
  font-weight: 500;
  width: 100%;
  display: block;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  padding: 0 15px;
  font-size: 16px;
  line-height: 22px;
  color: ${color.GreySelectDd};

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${color.ErrorWarningBorder} !important;
    `}
`;

Form.Row = styled.div`
  margin: 10px 0;

  :first-of-type {
    margin-top: 0;
  }
`;

Form.Input = styled(Input)`
  ${inputStyle}
`;

Form.InputMask = styled(InputMask)`
  ${inputStyle}
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 32px;
`;

const Btn = styled.button`
  border: 1px solid ${color.neutral200};
  padding: 13px 18px;
  font-weight: 700;
  border-radius: 6px;
`;

const PrimaryBtn = styled(Btn)`
  background-color: ${color.Black};
  color: ${color.White};
`;

export default {
  Form,
  BtnWrapper,
  Btn,
  PrimaryBtn,
};
