export default function YahooIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33331 4.36768H3.94788L5.47031 8.26266L7.0125 4.36768H9.55821L5.72494 13.5884H3.16309L4.21239 11.145L1.33331 4.36768Z"
        fill="#5F01D2"
      />
      <path
        d="M11.1392 11.1621C12.0139 11.1621 12.723 10.453 12.723 9.57831C12.723 8.70358 12.0139 7.99447 11.1392 7.99447C10.2644 7.99447 9.55532 8.70358 9.55532 9.57831C9.55532 10.453 10.2644 11.1621 11.1392 11.1621Z"
        fill="#5F01D2"
      />
      <path d="M13.2432 7.41276H10.3919L12.9225 1.3335H15.7632L13.2432 7.41276Z" fill="#5F01D2" />
    </svg>
  );
}
