type Props = {
  className?: string;
  stroke?: string;
  strokeWidth?: number;
  strokeLinecap?: string;
  strokeLinejoin?: string;
};

const DownloadMinimalisticIcon: React.FC<Props> = ({
  className,
  stroke = '#252525',
  strokeWidth = '1.5',
  strokeLinejoin = 'round',
  strokeLinecap = 'round',
}) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10C2 11.8856 2 12.8284 2.58579 13.4142C3.17157 14 4.11438 14 6 14H10C11.8856 14 12.8284 14 13.4142 13.4142C14 12.8284 14 11.8856 14 10"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
      />
      <path
        d="M7.99967 1.99935V10.666M7.99967 10.666L10.6663 7.74935M7.99967 10.666L5.33301 7.74935"
        stroke="#252525"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
      />
    </svg>
  );
};

export default DownloadMinimalisticIcon;
