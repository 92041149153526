import { useContext } from 'react';

import { Typography } from 'antd';
import Modal from 'modules/v2/common/components/Modal';
import { PlansContext } from 'modules/v2/pages/Plans/contexts/UpgradePlan';

import { useMutation } from 'react-query';
import { getNewAuthToken } from 'modules/api';
import S from './styles';

const { Title, Text } = Typography;

const OrderConfirmationModal = () => {
  const {
    isOrderConfirmationModalOpened,
    toggleOrderConfirmationModal,
    userData,
    getNextMonthRenewal,
    selectedPlan,
    newPlanName,
  } = useContext(PlansContext);

  const { mutate, isLoading } = useMutation('getNewAuthToken', getNewAuthToken, {
    onSuccess: () => {
      toggleOrderConfirmationModal();
      window.location.reload();
    },
  });

  return (
    <>
      <Modal
        isOpen={isOrderConfirmationModalOpened}
        onCancel={toggleOrderConfirmationModal}
        footer={null}
        width={600}
      >
        <S.MainSection>
          <S.Section>
            <S.CheckIcon />
          </S.Section>
          <S.Section>
            <Title level={4}>
              Congratulations! You’re now a {newPlanName || selectedPlan.planTitle} Member
            </Title>
          </S.Section>
          <S.Section>
            <Text>
              Thank you for using Authorify. You now have access to{' '}
              {newPlanName || selectedPlan.planTitle} Member features and services. Your next
              billing period will be on {getNextMonthRenewal()}.
            </Text>
          </S.Section>
          <S.Section>
            <Text>
              Your receipt is sent to your email at <strong>{userData?.email}</strong> or you can
              view and download all your receipts from your billing history at any time.
            </Text>
          </S.Section>
          <S.Section>
            <S.OkButton onClick={mutate} loading={isLoading}>
              Okay
            </S.OkButton>
          </S.Section>
        </S.MainSection>
      </Modal>
    </>
  );
};

export default OrderConfirmationModal;
