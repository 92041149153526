export const operationType = {
  SWITCH_TO_MONTHLY: 'Switch To Monthly',
  SWITCH_TO_ANNUAL: 'Switch To Annual',
  CURRENT_PLAN: 'Current Plan',
  DOWNGRADE: 'Downgrade',
  UPGRADE: 'Upgrade',
};

export const planType = {
  [operationType.DOWNGRADE]: 'Downgrade',
  [operationType.UPGRADE]: 'Upgrade',
  [operationType.SWITCH_TO_MONTHLY]: 'Update',
  [operationType.SWITCH_TO_ANNUAL]: 'Update',
};

export const intervalType = {
  MONTHLY: 'monthly',
  MONTH: 'month',
  ANNUALLY: 'annually',
  YEAR: 'year',
  ANNUAL: 'annual',
};

export const planNames = ['Platinum+ Plus', 'Platinum+', 'Platinum+Plus', 'Social Media Pro Plus'];

export const planFeatures = [
  {
    name: 'Licensed Books',
    tooltip:
      'We have niche books to help you convert FSBOs, Expired, Buyers, Divorcing, Homeowners, Inherited Homeowners and many others.',
  },
  {
    name: 'Digital Books',
    tooltip: 'A digital version of your book that you can send to anyone via email or text.',
  },
  {
    name: 'Lead Capture Websites',
    tooltip:
      'A powerful mobile-friendly lead capture site that is strategically optimized to convert visitors into hot leads.',
  },
  {
    name: 'Printed Books',
    tooltip:
      'Your very own printed book instantly transforms you into a trusted authority so you can easily convert prospects into clients.',
  },
];

export const buttonTooltip = {
  [operationType.DOWNGRADE]:
    'Some of the features and benefits will not be available anymore if you downgrade your plan.',
  [operationType.UPGRADE]: 'Save {saveAmount} by going {planTerm} to {planName} plan.',
  [operationType.SWITCH_TO_MONTHLY]:
    'You will lose {saveAmount} annually when you switch to monthly plan.',
  [operationType.SWITCH_TO_ANNUAL]: 'Save {saveAmount} by going annual plan.',
};

export const LOADER = {
  [operationType.DOWNGRADE]: {
    title: 'Downgrading Your Plan',
    description: 'Please wait while we downgrade your plan.',
  },
  [operationType.UPGRADE]: {
    title: 'Upgrading Your Plan',
    description: 'Please wait while we upgrade your plan.',
  },
  [operationType.SWITCH_TO_MONTHLY]: {
    title: 'Updating Your Plan',
    description: 'Please wait while we update your plan.',
  },
  [operationType.SWITCH_TO_ANNUAL]: {
    title: 'Updating Your Plan',
    description: 'Please wait while we update your plan.',
  },
};

export const FEATURES = {
  [operationType.DOWNGRADE]: {
    heading: 'Some of the features and benefits will not be available anymore',
    icon: 'cross',
    color: 'PrimaryColor',
    points: [
      'You will lose {licensedBooks} licensed books',
      'You will lose {printedBooks} printed books {interval}',
      'You could save {saveAmount} by going annual',
    ],
  },
  [operationType.UPGRADE]: {
    heading: 'Features and benefits you will get when you upgrade',
    icon: 'check',
    color: 'DefaultBlue',
    points: [
      'You will get {licensedBooks} licensed books',
      'You will get {printedBooks} printed books {interval}',
      'You could save {saveAmount} by going annual',
    ],
  },
  [operationType.SWITCH_TO_MONTHLY]: {
    heading: 'Features and benefits you will get when you switch to monthly',
    icon: 'cross',
    color: 'PrimaryColor',
    points: ['Your save {saveAmount} by going annual'],
  },
  [operationType.SWITCH_TO_ANNUAL]: {
    heading: 'Features and benefits you will get when you switch to annual',
    icon: 'check',
    color: 'DefaultBlue',
    points: ['Save {saveAmount} by going annual'],
  },
};

export const TOTAL_MONTHS = 12;

export const SUBSCRIPTION_TRAILING = 'Trailing';
export const SUBSCRIPTION_DIGITAL = 'Digital';
export const SUBSCRIPTION_DIGITAL_PLUS = 'Digital+ Plus';

export const planHandle = {
  DIGITAL_MONTHLY: 'digital_monthly',
  DIGITAL_PLUS_MONTHLY: 'digitalplus_monthly',
  DIGITAL_ANNUAL: 'digital_annual',
  DIGITAL_PLUS_ANNUAL: 'digitalplus_annual',
  DIGITAL_PLUS_PLUS_ANNUAL: 'digitalplusplus_annual',
  PREMIUM_MONTHLY: 'premium_monthly',
  PREMIUM_PLUS_MONTHLY: 'premiumplus_monthly',
  PREMIUM_ANNUAL: 'premium_annual',
  PREMIUM_PLUS_ANNUAL: 'premiumplus_annual',
  PLATINUM_MONTHLY: 'platinum_monthly',
  PLATINUM_PLUS_MONTHLY: 'platinumplus_monthly',
  PLATINUM_ANNUAL: 'platinum_annual',
  PLATINUM_PLUS_ANNUAL: 'platinumplus_annual',
};
