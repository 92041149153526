import styled from 'styled-components';
import * as font from 'modules/common/theme/font';
import * as color from 'modules/common/theme/color';
import { Modal as AntModal, Select as BaseSelect, Switch as BaseSwitch } from 'antd';
import { SelectFull } from 'modules/common/components';

const Select = styled(SelectFull)`
  min-width: 300px;
`;
const Option = styled(BaseSelect.Option)``;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;
const SearchBar = styled.div`
  width: 316px;
  height: 40px;
  border-color: ${color.GrayBorder};

  .ant-input-affix-wrapper {
    height: 40px !important;

    input::placeholder {
      color: ${color.DefaultBlue};
    }
  }

  .ant-input-search-icon {
    font-size: 24px;
    color: ${color.GreyDropDownArrow};
  }
`;

const BtnGroup = styled.div`
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
  gap: 10px;
  margin-right: 0px;
  flex-direction: row;
  justify-content: end;
`;
const SortBtn = styled.div`
  width: 165px;
  height: 40px;
  margin-right: 16px;

  .ant-select-selection--single {
    width: 165px;
    height: 40px !important;
    border: 1px solid ${color.GrayBorder};
    border-radius: 2px !important;
    background-color: ${color.White};
    color: ${color.DefaultBlue};
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .ant-select-arrow {
    position: absolute;
    top: 14%;
    right: 11%;
    margin-top: 0px;
  }

  .ant-select-selection__rendered {
    margin-left: 18px;
  }

  .anticon svg {
    font-size: 30px;
    color: ${color.GreyDropDownArrow};
  }
`;

const ExportBtn = styled.button`
  width: 179px;
  font-size: 16px;
  height: 40px !important;
  background-color: ${color.SkyBlue};
  color: ${color.White};
  border: none;
  border-radius: 2px !important;
  opacity: 1;
  text-align: right;
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  font-weight: bold;
  letter-spacing: 0.6px;

  &:hover {
    color: ${color.White};
    background-color: ${color.LeadsButtonHover};
    border-color: ${color.LeadsButtonHover};
    cursor: pointer;
  }

  .anticon svg {
    font-size: 23px;
    margin: 6px;
  }
`;

const ImportBtn = styled.button`
  width: 165px;
  height: 40px !important;
  background-color: ${color.White};
  color: ${color.SkyBlue};
  border-style: solid;
  border-color: ${color.SkyBlue};
  border-radius: 2px !important;
  margin-right: 16px;
  opacity: 1;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  letter-spacing: 0.6px;

  &:hover {
    color: ${color.LeadsButtonHover};
    background-color: ${color.White};
    border-color: ${color.LeadsButtonHover};
  }

  .anticon svg {
    font-size: 23px;
    margin: 5px;
  }
`;

const AddLead = styled.button`
  font-size: 16px;
  font-family: ${font.RobotoFont};
  height: 40px !important;
  background: none;
  color: ${color.LightBlue};
  border-style: none;
  opacity: 1;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  letter-spacing: 0.6px;

  &:hover {
    color: ${color.LeadsButtonHover};
  }

  .anticon svg {
    font-size: 16px;
    margin-left: 5px;
  }
`;

const SegmentButton = styled.button`
  height: 40px !important;
  background: none;
  border-style: none;
  opacity: 1;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: ${color.LeadsButtonHover};
  }

  .anticon svg {
    font-size: 20px;
    color: ${(props) => (props.primary ? color.PrimaryColor : color.NotificationErrorBorder)};
  }
`;

const ButtonText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    vertical-align: center;
  }

  svg {
    font-size: 23px !important;
    margin-left: 10px !important;
  }
`;

const ActionBtn = styled.div`
  whitespace: 'nowrap';
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: left;
  margin-right: 50%;
  transform: rotate(90deg);
  color: ${color.DefaultBlue};
`;

const ModalDiv = styled.div`
  .modalStyle .ant-modal-header {
    background-color: red;
  }
`;

const Loaderdiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled(AntModal)`
  .ant-modal-footer {
    background: red !important;
  }
`;

const LeadFooter = styled.div`
  height: 10px;
  background: ${color.GreyHover};
  grid-area: footer;
  border-top: 1px solid ${color.GreyBorder};
`;

const MainWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 35px;
`;

const Label = styled.label`
  width: inherit;
  margin-right: 1rem;
  flex: 1;
  font-size: 16px;
  line-height: 25px;
  color: ${color.Black};
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
`;

const SegmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
`;

const allSegmentsCheck = styled.div``;

const Switch = styled(BaseSwitch)`
  margin-left: 10px;
`;

export default {
  SearchBar,
  BtnGroup,
  SortBtn,
  ExportBtn,
  ImportBtn,
  AddLead,
  ActionBtn,
  ModalDiv,
  Loaderdiv,
  ModalWrapper,
  ButtonText,
  MainWrapper,
  LeadFooter,
  SegmentButton,
  Label,
  SegmentWrapper,
  CheckBox,
  allSegmentsCheck,
  HeaderWrapper,
  SwitchContainer,
  Switch,
  Select,
  Option,
};
