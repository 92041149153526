import { createSelector } from 'reselect';

import { draftModuleSelector, getDraftState, getDraft, getDraftId, getDraftName } from '../utils';

export const draftStateSelector = createSelector(draftModuleSelector, getDraftState);

export const draftSelector = createSelector(draftStateSelector, getDraft);

export const draftIdSelector = createSelector(draftSelector, getDraftId);

export const draftNameSelector = createSelector(draftSelector, getDraftName);
