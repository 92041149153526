import { useState } from 'react';
import { useMutation } from 'react-query';

import env from 'environments';

import { createTicket } from 'modules/api';
import { notification } from 'modules/v2/common/utils';
import { formatedPhoneNumber } from 'modules/v2/utils';
import { ChatRoundIcon, HeadphonesRound, PlainOutline } from 'modules/v2/common/components/SvgIcon';
import { Button, ClosableModal } from 'modules/v2/common/AtomicDesign/atoms';

interface HelpModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const HelpModal = ({ showModal, setShowModal }: HelpModalProps) => {
  const [hideForm, setHideForm] = useState(true);
  const [value, setValue] = useState('');

  const { mutate } = useMutation(createTicket, {
    onSuccess: () => {
      notification.success({ description: 'Your ticket has been submitted successfully' });
      setShowModal(false);
    },
    onError: () => {
      notification.error({ description: 'Something went wrong. Please try again' });
    },
  });

  const showIntercom = () => {
    const intercom = window?.Intercom;
    if (intercom) intercom('show');
  };

  const handleFormSubmit = () => {
    mutate({ message: value });
    setHideForm(true);
    setShowModal(false);
  };

  const handleChat = () => {
    showIntercom();
    setShowModal(false);
  };

  return (
    <ClosableModal
      title="Need help with something?"
      showModal={showModal}
      setShowModal={setShowModal}
      overlayClass={false}
    >
      <div className="w-[400px] max-sm:w-fit h-full">
        <div className="mb-32 font-medium">
          <div className="mb-6">
            <div className="font-bold mb-0.5 text-base">Call us</div>
            <div className="text-neutral-500 text-sm">
              Our Member Support team is ready to help you. Just fill up the form or send an email
              to <u className="text-blue-500 text-sm font-semibold">{env.SUPPORT_EMAIL}</u> and we
              will get back to you within <span className="font-semibold">8 business hours</span>.
            </div>
            {hideForm ? (
              <Button
                type="dark"
                className="mt-6"
                size="sm"
                buttonType="button"
                onClick={() => setHideForm(false)}
              >
                <div className="flex items-center gap-2">
                  <ChatRoundIcon fill="#ffffff" />
                  <span>Request support</span>
                </div>
              </Button>
            ) : (
              <div className="mt-6 pb-[34px] w-full border-b border-neutral-200 flex flex-col gap-6">
                <textarea
                  rows={4}
                  name="comment"
                  id="comment"
                  className="block w-full rounded-md border-0 border-neutral-200 py-[13px]  px-5 text-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-neutral-400 placeholder:font-figtree placeholder:font-medium"
                  placeholder="Type your issue or concern here"
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />

                <Button type="dark" size="sm" buttonType="submit" onClick={handleFormSubmit}>
                  <div className="flex items-center gap-2">
                    <PlainOutline />
                    <span>Send</span>
                  </div>
                </Button>
              </div>
            )}
          </div>
          <div className="mb-6">
            <div className="font-bold mb-0.5 text-base">Need urgent assistance?</div>
            <div className="text-neutral-500 text-sm">
              <p>
                You can get LIVE assistance from Member Support from{' '}
                <span className="font-semibold text-neutral-700">
                  Monday-Friday, {env.SUPPORT_OPPENING_HOURS}
                </span>{' '}
                or call our support line at{' '}
                <u className="text-blue-500">+1{formatedPhoneNumber()}</u>
              </p>
            </div>
            <Button type="dark" className="mt-6" size="sm" buttonType="button" onClick={handleChat}>
              <div className="flex items-center gap-2">
                <HeadphonesRound fill="#ffffff" />
                <span>Chat with LIVE assistance</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </ClosableModal>
  );
};

export default HelpModal;
