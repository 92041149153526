export const formKeywordList = {
  magazine: 'magazine',
  frontCoverStrip: 'frontCoverStrip',
  frontCoverDesign: 'frontCoverDesign',
  frontInsideCover: 'frontInsideCover',
  backInsideCover: 'backInsideCover',
  backCover: 'backCover',
};

export const magazineFormikStepsList = {
  0: 'magazine',
  1: 'frontCover',
  2: 'frontInsideCover',
  3: 'backInsideCover',
  4: 'backCover',
  5: 'additionalInformation',
};

export const monthNames = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
