import styled from 'styled-components';

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export default {
  SectionHeader,
};
