/**
 * APP Theme
 */

import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const CommonModalWrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100%;

  .commonModalInner {
    background: ${color.White};

    height: calc(100vh - 0px);
    overflow: hidden;
  }
`;

export default CommonModalWrapper;
