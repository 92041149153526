import { getInstance } from './instance';

const instance = getInstance();

export const getStep = (offerCode, session) => {
  const url = `/onboard/step?offerCode=${offerCode}&sessionId=${session}`;

  return instance.get(url);
};

export const createSession = (
  offerCode,
  channel,
  utmSource,
  utmMedium,
  utmContent,
  utmTerm,
  affiliateId,
  orderSystem,
  salesAgent,
) => {
  const params = {
    offerCode,
    channel,
    utmSource,
    utmMedium,
    utmContent,
    utmTerm,
    affiliateId,
    orderSystem,
    salesAgent,
  };
  const url = '/onboard/session';

  return instance.post(
    url,
    {},
    {
      params,
    },
  );
};

export const resumeSession = ({ email, password, offerCode }) => {
  const url = `/onboard/session/resume?offerCode=${offerCode}`;

  const params = {
    email,
    password,
  };

  return instance.post(url, params);
};

export const getSummary = ({ offerCode, sessionId }) => {
  const url = `/onboard/summary?offerCode=${offerCode}&sessionId=${sessionId}`;

  return instance.get(url);
};

export const fetchSession = (offerCode, sessionId) => {
  const url = `/onboard/session?offerCode=${offerCode}&sessionId=${sessionId}`;

  return instance.get(url);
};

export const postFirstLogin = ({ email, password }) => {
  const url = 'onboard/login';

  const params = {
    email,
    password,
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  return instance.post(url, params, { headers });
};

export const updateUpsellSession = (parameters) => {
  const url = 'upsell/update-params';

  return instance.post(url, parameters);
};
