//

import Prompt from 'react-router-navigation-prompt';
import { matchPath } from 'react-router';

import ConfirmModal from 'modules/common/components/ConfirmModal';

const NavigationPrompt = ({ path, block, title, text }) => {
  const blocker = (current, next) => {
    if (next === undefined || !path) {
      return block;
    }

    const match = matchPath(next.pathname, path);
    const isEditorRoute = match !== null;

    return block && !isEditorRoute;
  };

  return (
    <Prompt renderIfNotActive when={blocker}>
      {({ isActive, onConfirm, onCancel }) => (
        <ConfirmModal
          isOpen={isActive}
          title={title}
          text={text}
          confirmLabel="Stay"
          cancelLabel="Leave"
          onConfirm={onCancel}
          onCancel={onConfirm}
        />
      )}
    </Prompt>
  );
};

export default NavigationPrompt;
