export const ADMIN = '/admin';
// export const ROUTE_ADMIN = `${ADMIN}`;

export const ROUTE_REPORTS = `${ADMIN}/reports`;
export const ROUTE_BOOK_PREVIEWS = `${ADMIN}/book-previews`;
export const ROUTE_SOCIAL_MEDIA_CAMPAIGN = `${ADMIN}/social-media-campaign`;
export const REFERRAL_MARKETING_ADMIN = `${ADMIN}/referral-marketing`;

export const ROUTE_SOCIAL_MEDIA_CAMPAIGN_CONFIGURATION = `${ROUTE_SOCIAL_MEDIA_CAMPAIGN}/social-media-configuration`;
export const ROUTE_SOCIAL_MEDIA_CAMPAIGN_TEMPLATE = `${ROUTE_SOCIAL_MEDIA_CAMPAIGN}/templates`;
export const ROUTE_SOCIAL_MEDIA_CAMPAIGN_CATALOG = `${ROUTE_SOCIAL_MEDIA_CAMPAIGN}/available-campaigns`;
export const ROUTE_SOCIAL_MEDIA_CAMPAIGN_CAMPAIGNS = `${ROUTE_SOCIAL_MEDIA_CAMPAIGN}/campaigns`;

export const ROUTE_SOCIAL_MEDIA_CONTENT_CREATE = `${ROUTE_SOCIAL_MEDIA_CAMPAIGN}/create-campaigns/:id`;
export const ROUTE_SOCIAL_MEDIA_EDIT_CAMPAIGN = `${ROUTE_SOCIAL_MEDIA_CAMPAIGN}/edit-campaigns/:id`;
export const ROUTE_OFFERS = `${ADMIN}/offers`;
export const ROUTE_END_MONTH = `${ADMIN}/end-month`;
export const SALES_REPORT_EXPORT = `${ADMIN}/onboarding-report-export`;
export const ROUTE_BOOK_DRAFT_MAINTENANCE = `${ADMIN}/book-draft-maintenance`;
export const ROUTE_OFFERS_CREATE = `${ROUTE_OFFERS}/create`;
export const ROUTE_OFFERS_EDIT = `${ROUTE_OFFERS}/edit`;
export const ROUTE_OFFERS_LIST = `${ROUTE_OFFERS}/list`;
export const ROUTE_LAUNCH_OFFERS = `${ROUTE_OFFERS}/launch`;
export const ROUTE_REFERRAL_MARKETING_PAGES = `${ADMIN}/referral-marketing-pages`;
export const ROUTE_REFERRAL_MARKETING_APPROVER = `${ADMIN}/referral-marketing-approver-details/:id`;
export const ROUTE_PRODUCT_CONFIG = `${ADMIN}/products-config`;
export const ROUTE_PRODUCT_MAINTENANCE = `${ADMIN}/products`;
export const ROUTE_PRODUCT_MAINTENANCE_CREATE = `${ADMIN}/products/create`;
export const ROUTE_PRODUCT_MAINTENANCE_EDIT = `${ADMIN}/products/edit/:id`;

export const ROUTE_COACH_MAINTENANCE = `${ADMIN}/coaches`;
export const ROUTE_COACH_EMAIL_REMINDER = `${ADMIN}/coach-email`;
export const ROUTE_OFFBOARDING = `${ADMIN}/offboarding`;
export const ROUTE_COACH_MAINTENANCE_CREATE_COACH = `${ADMIN}/coaches/create`;
export const ROUTE_COACH_MAINTENANCE_EDIT_COACH = `${ADMIN}/coaches/edit/:id`;
export const ROUTE_TEAM_MEMBER_MEETING = `${ADMIN}/team-member-meeting`;
export const ROUTE_TEAM_MEMBER_PHONE_CALL = `${ADMIN}/team-member-phone-call`;
export const ROUTE_TEAM_MEMBER_MEETING_REPORT = `${ADMIN}/team-member-meeting/:teamMemberEmail`;
export const ROUTE_TEAM_MEMBER_CALL_REPORT = `${ADMIN}/team-member-phone-call/:teamMemberEmail/:currToken/:currPage?`;
export const ROUTE_TEAM_MEMBER_CALL_PLAY = `${ADMIN}/team-member-phone-play`;
export const ROUTE_CUSTOMERS_PAGE = `${ADMIN}/customers`;
export const ROUTE_CUSTOMER_MEETING_REPORT = `${ADMIN}/customers/:customerEmail`;

export const ROUTE_CUSTOM_LANDING_PAGE = `${ADMIN}/custom-landing-page`;
export const ROUTE_EMAIL_REMINDER_RESCHEDULER = `${ADMIN}/email-reminder-rescheduler`;

export const ROUTE_SALES_REPORT = `${ADMIN}/onboarding-report`;
export const ROUTE_TRIPWIRE_SALES_REPORT = `${ADMIN}/tripwire-report`;
export const ROUTE_CUSTOMER_EVENT_REPORT = `${ADMIN}/customer-event/:customerId`;
export const ROUTE_RM_MAGAZINES_REPORT = `${ADMIN}/rm-magazine-report`;
export const ROUTE_EMAIL_CAMPAIGN_REPORT = `${ADMIN}/email-campaign-report`;
export const ROUTE_RM_MAGAZINES_PREVIEW = `${ADMIN}/rm-magazine-report/preview/:id/:customerId?`;

export const ROUTE_MEMBERS_360 = `${ADMIN}/members-360`;

export const ROUTE_REFERRAL_MARKETING_MAILING_LIST = `${ADMIN}/mailing-list`;
export const ROUTE_REFERRAL_MARKETING_MAILING_LIST_PREVIEW = `${ADMIN}/mailing-list/preview`;
export const ROUTE_UPDATE_AFY_PASSWORD_HUBSPOT = `${ADMIN}/change-password`;
export const ROUTE_CUSTOMER_PROPERTIES_LIST = `${ADMIN}/customer-properties-list`;

export const ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR_ADMIN = `${REFERRAL_MARKETING_ADMIN}/magazine-editor/:month/:year/:customerId/:previewId?`;
