//

import { map, size } from 'lodash-es';

import { NavBar } from 'modules/nav/components';

const { Grid } = NavBar.Dropdown;

const EditorGrid = ({ items }) => {
  if (size(items) === 0) {
    return null;
  }

  const content = map(items, ({ _id, to, onClick, chapterNumber }) => (
    <Grid.Item key={_id} to={to} onClick={onClick} title={chapterNumber} />
  ));

  return <Grid>{content}</Grid>;
};

export default EditorGrid;
