export const GET_PUBLISH = 'publish/GET_PUBLISH';
export const CLEAR_PUBLISH = 'publish/CLEAR_PUBLISH';

export const GET_PREFLIGHT_CHECK = 'publish/GET_PREFLIGHT_CHECK';
export const GET_USER_CREDITS = 'publish/GET_USER_CREDITS';
export const GET_COUNTRIES = 'publish/GET_COUNTRIES';
export const GET_STATES_BY_COUNTRIES = 'publish/GET_STATES_BY_COUNTRIES';
export const GET_USER_ADDRESSES = 'publish/GET_USER_ADDRESSES';
export const ADD_USER_ADDRESS = 'publish/ADD_USER_ADDRESS';
export const EDIT_USER_ADDRESS = 'publish/EDIT_USER_ADDRESS';
export const DELETE_USER_ADDRESS = 'publish/DELETE_USER_ADDRESS';
export const SUBMIT_ORDER = 'publish/SUBMIT_ORDER';
export const SUBMIT_REORDER = 'publish/SUBMIT_REORDER';
