import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { get, isEmpty, map } from 'lodash-es';
import { Popover } from 'antd';
import { ArrowLeftOutlined, FieldTimeOutlined } from '@ant-design/icons';

import { DashboardTemplate } from 'modules/v2/templates';
import { MyBooksSideNav } from 'modules/v2/components';
import Table from 'modules/v2/common/components/Table';
import { fetchAllBooks, getBookVersions, restoreVersions } from 'modules/api';
import { formatDate, notification, formatDateTime } from 'modules/v2/common/utils';
import { ErrorState, Modal } from 'modules/v2/common/components';
import { deleteVersions } from 'modules/api/deleteVersion';

import { formatStatus } from './utils';

import S from './style';

const ManageBookVersion = () => {
  const [listOfBooks, setlistOfBooks] = useState(true);
  const [listOfBooksVersion, setlistOfBooksVersion] = useState(false);
  const [ModalRestore, setModalRestore] = useState(false);
  const [ModalDelete, setModalDelete] = useState(false);
  const [modalTitle, setmodalTitle] = useState('');
  const [modalContent, setmodalContent] = useState('');
  const [bookVersionsData, setbookVersionsData] = useState([]);
  const [versionbookId, setversionbookId] = useState('');
  const [draftIds, setdraftId] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [boxChecked, setBoxChecked] = useState(false);
  const [headChecked, setHeadChecked] = useState(false);
  const [bookTitle, setbookTitle] = useState('');
  const [bookName, setbookName] = useState('');

  let versionList = [];
  const { mutate: getBookVersion, isLoading: isVersionsLoading } = useMutation(
    (bookId) => getBookVersions(bookId),
    {
      onSuccess: (data) => {
        versionList = get(data, 'data', []);
        versionList = map(versionList, (obj) => ({ ...obj, isSelected: false }));
        setbookVersionsData(versionList);
      },
    },
  );

  const payload = {
    bookId: '',
    draftId: '',
  };

  const clearStates = () => {
    setdraftId(null);
    setModalRestore(false);
    setModalDelete(false);
  };

  const { mutateAsync: restoreVersion, isLoading: isRestoreLoading } = useMutation(restoreVersions);

  const handleRestoreVersion = async (draftId) => {
    try {
      payload.bookId = versionbookId;
      payload.draftId = draftId;
      restoreVersion(payload)
        .then(async () => {
          notification.success({
            title: 'Changes saved',
            description: 'Version(s) has been restored successfully',
          });
          clearStates();
          await getBookVersion(versionbookId);
        })
        .catch(() => {
          notification.error({
            title: 'Saving failed',
            description: 'Changes could not be saved',
          });
        });
      return false;
    } catch (error) {
      return error;
    }
  };

  const { mutateAsync: deleteVersion, isLoading: isDeleteLoading } = useMutation(deleteVersions);

  const onMultipleDeleteVersions = async (version) => {
    await deleteVersion(version?.id).catch(() => {
      notification.error({
        description:
          'The system has encountered an error. Please try again or contact customer support!!!',
        autoClose: 3000,
      });
    });
  };

  const onDeleteVersion = async (draftId) => {
    await deleteVersion(draftId).catch(() => {
      notification.error({
        description:
          'The system has encountered an error. Please try again or contact customer support!!!',
        autoClose: 3000,
      });
    });
  };

  const handleDeleteVersion = async (draftId) => {
    setModalDelete(false);
    const selectedVersions = checkAll
      ? bookVersionsData
      : bookVersionsData.filter((version) => version.isSelected);
    const allPromises = [];

    selectedVersions.map((version) => {
      allPromises.push(onMultipleDeleteVersions(version));
      return version;
    });

    if (draftId) {
      allPromises.push(onDeleteVersion(draftId));
    }

    setCheckAll(false);
    setHeadChecked(false);
    setBoxChecked(false);

    await Promise.all(allPromises);
    notification.success({ description: 'Version(s) has been deleted', autoClose: 3000 });

    await getBookVersion(versionbookId);
  };

  const {
    isError: isListError,
    isLoading: isListLoading,
    data: listData,
  } = useQuery('fetchAllBooks', fetchAllBooks);

  const bookListData = get(listData, 'data.data.books', []);
  const handleViewVersions = (bookId, name) => {
    setbookTitle(name);
    setbookName(name);
    setlistOfBooks(false);
    setlistOfBooksVersion(true);
    setversionbookId(bookId);
    getBookVersion(bookId);
  };

  const tableColumns = [
    {
      title: <S.SpanBold>Book Name</S.SpanBold>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <S.SpanBold>Actions</S.SpanBold>,
      key: 'actions',
      render: ({ _id, name }) => (
        <S.Name onClick={() => handleViewVersions(_id, name)}>View Versions</S.Name>
      ),
    },
  ];

  const handleBackTOBookList = () => {
    setlistOfBooks(true);
    setlistOfBooksVersion(false);
  };

  const handleDelete = (draftId) => {
    setdraftId(draftId);
    setmodalTitle('Delete Version(s)');
    setmodalContent(
      'If you delete this version, you can no longer retrieve this. Would you like to delete this version?',
    );
    setModalDelete(true);
  };

  const ButtonRestore = (draftId) => {
    return (
      <S.ButtonSection>
        <S.SecondaryButtonOutlined onClick={() => handleRestoreVersion(draftId)}>
          Restore
        </S.SecondaryButtonOutlined>
        <S.SecondaryButton onClick={() => clearStates()}>Cancel</S.SecondaryButton>
      </S.ButtonSection>
    );
  };

  const ButtonDelete = (draftId) => {
    return (
      <div>
        <S.SecondaryButtonOutlined onClick={() => handleDeleteVersion(draftId)}>
          Delete Version(s)
        </S.SecondaryButtonOutlined>
        <S.SecondaryButton onClick={() => clearStates()}>Cancel</S.SecondaryButton>
      </div>
    );
  };

  const handleRestore = (draftId) => {
    setdraftId(draftId);
    setmodalTitle('Restore Version');
    setModalRestore(true);
    setmodalContent(
      `Your current book will revert to the version from ${formatDate(
        new Date(),
        'MM-DD-YYYY - h:mm a',
      )} Would you like to restore this version?`,
    );
  };

  const tableColumnsTest = [
    {
      title: <S.SpanBold>Draft ID</S.SpanBold>,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: <S.SpanBold>Date and Time</S.SpanBold>,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => <span>{formatDateTime(updatedAt)}</span>,
    },

    {
      title: <S.SpanBold>Status</S.SpanBold>,
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span>{formatStatus(status)}</span>,
    },
    {
      title: <S.SpanBold>Actions</S.SpanBold>,
      key: 'actions',
      dataIndex: 'id',
      render: (id, { status }) =>
        status !== 'active' && (
          <Popover
            placement="leftTop"
            content={
              <S.List>
                <S.List.Item onClick={() => handleRestore(id)}>
                  <FieldTimeOutlined />
                  <S.ListSubItem> Restore Version</S.ListSubItem>
                </S.List.Item>
              </S.List>
            }
            trigger="click"
          >
            <S.MoreAction>...</S.MoreAction>
          </Popover>
        ),
    },
  ];

  const isPageLoading = isListLoading || isRestoreLoading || isDeleteLoading || isVersionsLoading;

  return (
    <DashboardTemplate
      sidebar={<MyBooksSideNav />}
      hasSidebar
      contentTitle="Manage Book Versions"
      isError={isListError}
      error={
        <ErrorState
          topMessage={
            <>
              {' '}
              Sorry, our server encounters an error while processing and loading your versions.{' '}
              <br />
              Please try again later.
            </>
          }
          bottomMessage={
            <>
              If the issue continues, please let our team know by{' '}
              <a href="/help-center">clicking here.</a>
            </>
          }
        />
      }
    >
      <Modal
        isOpen={ModalRestore}
        width="600px"
        title={modalTitle}
        onCancel={() => setModalRestore(false)}
        okText="Ok"
        footer={[ButtonRestore(draftIds)]}
      >
        <S.Content>{modalContent}</S.Content>
      </Modal>
      <Modal
        isOpen={ModalDelete}
        width="600px"
        title={modalTitle}
        onCancel={() => setModalDelete(false)}
        okText="Ok"
        footer={[ButtonDelete(draftIds)]}
      >
        <S.Content>{modalContent}</S.Content>
      </Modal>
      {listOfBooks && (
        <Table
          rowKey="id"
          hasData={!isEmpty(bookListData)}
          columns={tableColumns}
          dataSource={bookListData}
          loading={isPageLoading}
        />
      )}
      {listOfBooksVersion && (
        <S.Container>
          <S.InfoText onClick={handleBackTOBookList}>
            {' '}
            <ArrowLeftOutlined />
            <S.SpanBackToManage>Back to Manage Book Versions</S.SpanBackToManage>
          </S.InfoText>
          <S.HeaderSection>
            <S.HeaderTitle className="skyBlue">{bookName}</S.HeaderTitle>
          </S.HeaderSection>
          <S.SubTitlediv>
            <S.SubTitle>Book Title: </S.SubTitle>
            {bookTitle}
          </S.SubTitlediv>
          {boxChecked || headChecked || checkAll ? (
            <S.DeleteButton onClick={() => handleDelete()}>
              <S.ButtonTitle>Delete Version(s)</S.ButtonTitle>
            </S.DeleteButton>
          ) : (
            ''
          )}
          <Table
            rowKey="id"
            hasData={!isEmpty(bookVersionsData)}
            columns={tableColumnsTest}
            dataSource={bookVersionsData}
            loading={isPageLoading}
          />
        </S.Container>
      )}
    </DashboardTemplate>
  );
};

export default ManageBookVersion;
