export const SettingIcon = ({ width = 18, height = 19, fill = '#3C3C3C' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 19" fill="none">
    <path
      d="M5.88231 3.10159C7.40385 2.20053 8.16462 1.75 9 1.75C9.83538 1.75 10.5962 2.20053 12.1177 3.10159L12.6323 3.40634C14.1538 4.3074 14.9146 4.75793 15.3323 5.5C15.75 6.24207 15.75 7.14313 15.75 8.94524V9.55476C15.75 11.3569 15.75 12.2579 15.3323 13C14.9146 13.7421 14.1538 14.1926 12.6323 15.0937L12.1177 15.3984C10.5962 16.2995 9.83538 16.75 9 16.75C8.16462 16.75 7.40385 16.2995 5.88231 15.3984L5.36769 15.0937C3.84615 14.1926 3.08538 13.7421 2.66769 13C2.25 12.2579 2.25 11.3569 2.25 9.55476V8.94524C2.25 7.14313 2.25 6.24207 2.66769 5.5C3.08538 4.75793 3.84615 4.3074 5.36769 3.40634L5.88231 3.10159Z"
      stroke={fill}
      strokeWidth="1.5"
    />
    <circle cx="9" cy="9.25" r="2.25" stroke={fill} strokeWidth="1.5" />
  </svg>
);
