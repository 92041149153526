import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';
import { CREATE_TICKET } from 'modules/v2/store/constants';
import { support as initialState } from 'modules/v2/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_TICKET}_REQUESTED`: {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          status: STATUS_LOADING,
        },
      };
    }

    case `${CREATE_TICKET}_SUCCEEDED`: {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          status: STATUS_SUCCESS,
        },
      };
    }

    case `${CREATE_TICKET}_FAILED`: {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          status: STATUS_ERROR,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
