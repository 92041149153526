import { compose } from 'redux';
import { useHistory } from 'react-router';
import { Box, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { withProfile } from 'modules/dashboard/containers';
import { useEffect, useState } from 'react';
import { defaultCheckboxTheme } from 'modules/v2/common/AtomicDesign/atoms/Checkbox/theme';
import { Checkbox } from 'flowbite-react';
import { get, debounce } from 'lodash-es';
import { getLeadsList, updateDeliverableStatus } from 'modules/api';
import { removeNullOrEmptyProps, serialize } from 'modules/v2/pages/Leads/utils';
import { notification } from 'modules/common/utils';
import checkCircleFilled from 'assets/images/check-circle-filled.svg';
import PaginationFlowBite from 'modules/v2/common/components/Pagination/PaginationFlowBite';
import { getRouteEmailHistory } from 'modules/v2/routes/navigation';
import { useSelector, useDispatch } from 'react-redux';
import { setUnselectedLeads, setSelectedLeads } from 'modules/v2/store/actions/leads';
import LeadCard from './components/LeadCard/index';
import S from './styles';

const MagazineRecipients = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [leads, setLeads] = useState([]);
  const [rmDeliverableTotal, setRmDeliverableTotal] = useState(0);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [sortBy] = useState('');
  const [leadsPagination, setLeadsPagination] = useState({
    perPage: 10,
    currentPage: 1,
    total: 0,
    lastPage: 1,
  });
  const [magazinesLeftCount, setMagazinesLeftCount] = useState(0);
  const [originalSelectedLeads, setOriginalSelectedLeads] = useState([]);

  const selectedLeads = useSelector((state) => state.leads.selectedLeads);
  const unselectedLeads = useSelector((state) => state.leads.unselectedLeads);

  const fetchLeads = async (isRMDeliverable = false) => {
    const data = await getLeadsList(
      itemsPerPage,
      showSelectedOnly ? 1 : currentPage,
      sortBy,
      serialize(removeNullOrEmptyProps(filters), 'filters'),
      !isRMDeliverable,
      isRMDeliverable,
    );
    const meta = get(data.data, 'meta', {});
    setRmDeliverableTotal(Number(meta.rmDeliverableTotal) || 0);
    setMagazinesLeftCount(meta.magazineCount - meta.rmDeliverableTotal);

    setLeadsPagination({
      perPage: meta.perPage,
      currentPage: meta.currentPage,
      total: meta.total,
      lastPage: meta.lastPage,
    });

    let fetchedLeads = get(data.data, 'data', []);
    if (!Array.isArray(fetchedLeads)) {
      fetchedLeads = [];
    }
    fetchedLeads = fetchedLeads.map((lead) => {
      if (lead.address) {
        Object.assign(lead, lead.address);
        delete lead.address;
      }
      return lead;
    });

    const formattedLeads = fetchedLeads.map((lead) => ({
      id: lead.id,
      name: `${lead.firstName} ${lead.lastName}`,
      email: lead.email,
      phone: lead.phone,
      address: `${lead.address1}, ${lead.address2}, ${lead.city}, ${lead.state}, ${lead.country}, ${lead.zip}`,
      addedSince: new Date(lead.createdAt).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      }),
      selected: lead.isRMDeliverable || false,
    }));

    setOriginalSelectedLeads(formattedLeads.filter((lead) => lead.selected).map((lead) => lead.id));

    formattedLeads.map((lead) => {
      if (selectedLeads.includes(lead.id)) {
        lead.selected = true;
      }
      if (unselectedLeads.includes(lead.id)) {
        lead.selected = false;
      }
      return lead;
    });

    setLeads(formattedLeads);
  };

  const saveSelectedLeads = () => {
    updateDeliverableStatus({
      markAsDeliverable: selectedLeads,
      markAsNotDeliverable: unselectedLeads,
    })
      .then((response) => {
        notification.success({ description: 'Successfully updated your magazine recipients.' });
        dispatch(setSelectedLeads([]));
        dispatch(setUnselectedLeads([]));
        setShowSelectedOnly(false);
        fetchLeads();
      })
      .catch((error) => {
        notification.error({ description: `Error updating leads: ${error}` });
      });
  };

  useEffect(() => {
    fetchLeads(showSelectedOnly);
  }, [currentPage, itemsPerPage, showSelectedOnly, filters]);

  const totalLeads = leadsPagination.total;

  const getMaganizesLeft = () => {
    return magazinesLeftCount - selectedLeads.length + unselectedLeads.length;
  };

  const getRmDeliverable = () => {
    return rmDeliverableTotal + selectedLeads.length - unselectedLeads.length;
  };

  const updateLeadsState = (leadsState, id, selected) =>
    leadsState.map((lead) => (lead.id === id ? { ...lead, selected } : lead));

  const addToSet = (set, item) => {
    const newSet = new Set(set);
    newSet.add(item);
    return Array.from(newSet);
  };

  const removeFromSet = (set, item) => {
    const newSet = new Set(set);
    newSet.delete(item);
    return Array.from(newSet);
  };

  const setSelectedLead = (id) => {
    const shouldUpdateUnselectedLeads =
      originalSelectedLeads.includes(id) && !unselectedLeads.includes(id);
    const shouldRemoveFromSelectedLeads = selectedLeads.includes(id);
    const updatedUnselectedLeads = shouldUpdateUnselectedLeads
      ? addToSet(unselectedLeads, id)
      : unselectedLeads;
    const updatedSelectedLeads = shouldRemoveFromSelectedLeads
      ? removeFromSet(selectedLeads, id)
      : selectedLeads;

    if (shouldUpdateUnselectedLeads) {
      dispatch(setUnselectedLeads(updatedUnselectedLeads));
    }

    if (shouldRemoveFromSelectedLeads) {
      dispatch(setSelectedLeads(updatedSelectedLeads));
    }
  };

  const setUnselectedLead = (id) => {
    const shouldUpdateSelectedLeads =
      !selectedLeads.includes(id) && !originalSelectedLeads.includes(id);
    const shouldRemoveFromUnselectedLeads = unselectedLeads.includes(id);
    const updatedSelectedLeads = shouldUpdateSelectedLeads
      ? addToSet(selectedLeads, id)
      : selectedLeads;
    const updatedUnselectedLeads = shouldRemoveFromUnselectedLeads
      ? removeFromSet(unselectedLeads, id)
      : unselectedLeads;

    if (shouldUpdateSelectedLeads) {
      dispatch(setSelectedLeads(updatedSelectedLeads));
    }

    if (shouldRemoveFromUnselectedLeads) {
      dispatch(setUnselectedLeads(updatedUnselectedLeads));
    }
  };

  const handleSelectLead = (id) => {
    const selectedLead = leads.find((lead) => lead.id === id);

    if (!selectedLead) return;

    const isLeadSelected = selectedLead.selected;
    const newLeadsState = updateLeadsState(leads, id, !isLeadSelected);

    if (isLeadSelected) {
      setSelectedLead(id);
    } else if (getMaganizesLeft() > 0) {
      setUnselectedLead(id);
    } else {
      notification.error({ description: 'There are no magazines left' });
      return;
    }

    setLeads(newLeadsState);
  };

  const handleOnClickDetail = (id) => {
    history.push(getRouteEmailHistory(id, 'Magazine'));
  };

  const handleItemsPerPageChange = (selectedOption) => {
    const newItemsPerPage = Number(selectedOption.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const pushFilter = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const disableSaveButton = selectedLeads.length === 0 && unselectedLeads.length === 0;

  const handleFilter = debounce((e) => {
    const { value } = e.target;
    pushFilter('email', value);
  }, 500);

  const tablePagination = {
    pageSize: itemsPerPage,
    total: leadsPagination.total,
    lastPage: leadsPagination.lastPage,
    currentPage,
    onChange: (newPage) => handlePageChange(newPage),
  };

  return (
    <S.RecipientsContainer>
      <S.Title>Magazine Recipients</S.Title>
      <S.Subtitle>
        Each month, we’ll deliver these magazines to your leads. Please choose the recipients for
        this month’s delivery. You have the option to revise this list each month before the 21st.
        If no changes are made, we will use the same list for the following month.
      </S.Subtitle>
      <Box className="p-y-6">
        <div className="flex  gap-3 items-center flex-wrap py-[22px] px-6 border-b border-neutral-200  max-[770px]:justify-center">
          <TextInput
            placeholder="Search for name or email"
            onChange={handleFilter}
            className="w-[494px]"
            sizing="md"
            errorMessage="Invalid input"
          />
          <div className="ml-auto  max-[1250px]:ml-0">
            <S.CheckboxWrapper>
              <Checkbox
                checked={showSelectedOnly}
                onChange={() => setShowSelectedOnly(!showSelectedOnly)}
                theme={defaultCheckboxTheme}
              />
              <span>Show selected leads only</span>
            </S.CheckboxWrapper>
          </div>
        </div>
        <S.Summary className="py-[22px] px-6 border-b border-neutral-200">
          <div>
            <S.LeadsText>Leads ({totalLeads})</S.LeadsText>
          </div>
          <div>
            {rmDeliverableTotal > 0 && (
              <img
                src={checkCircleFilled}
                alt="Check Circle Filled"
                style={{ width: '16px', height: '16px', marginRight: '8px' }}
              />
            )}
            <strong>{getRmDeliverable()}</strong> leads selected
          </div>
          <div>
            <strong>{getMaganizesLeft()}</strong> magazines left
          </div>
        </S.Summary>
        <S.LeadsContainer>
          {leads.map((lead) => (
            <LeadCard
              key={lead.id}
              lead={lead}
              onClickDetail={handleOnClickDetail}
              onSelect={handleSelectLead}
            />
          ))}
        </S.LeadsContainer>
        <div className="border-t border-neutral-200 px-6 py-[22px]">
          <PaginationFlowBite
            handlePerPage={handleItemsPerPageChange}
            tablePagination={tablePagination}
          />
        </div>
        <div className="flex  gap-3 items-center flex-wrap py-[22px] px-6 border-b border-neutral-200  max-[770px]:justify-center">
          <S.SaveButton disabled={disableSaveButton} onClick={() => saveSelectedLeads()}>
            Save Changes
          </S.SaveButton>
        </div>
      </Box>
    </S.RecipientsContainer>
  );
};

export default compose(withProfile)(MagazineRecipients);
