import * as Yup from 'yup';

const passwordCheck = Yup.string()
  .trim()
  .matches(/[a-z]+/, ' ')
  .matches(/[A-Z]+/, ' ')
  .matches(/[\d\W]/, ' ')
  .min(8, ' ')
  .required('Password is required');

export default passwordCheck;
