//

import { Formik, Form } from 'formik';
import { FormErrors, SelectField } from 'modules/common/components';
import classnames from 'classnames';
import { get, map } from 'lodash-es';

import validationSchema from './validationSchema';
import S from './styles';

const AddressForm = ({ fields = {}, countries, states, onSubmit, onCancel }) => (
  <Formik
    initialValues={fields}
    validationSchema={validationSchema}
    validateOnChange={false}
    onSubmit={onSubmit}
    enableReinitialize
  >
    {({ values, errors, handleSubmit, handleChange, setFieldValue }) => {
      const optionBuilder = (item) => ({ title: item, value: item });

      const selectedCountry = values.country;
      const selectedStateList = get(states, selectedCountry);

      const countryList = map(countries, optionBuilder);
      const stateList = map(selectedStateList, optionBuilder);

      const handleCountryChange = () => setFieldValue('state', undefined);

      return (
        <Form onSubmit={handleSubmit} className="address-form">
          <S.Wrapper>
            <FormErrors errors={errors} />

            <S.FieldGroup>
              <S.FieldControl>
                <S.Label>
                  First Name*
                  <S.FormikField
                    onChange={handleChange}
                    hasError={errors.firstName}
                    type="text"
                    name="firstName"
                  />
                </S.Label>
              </S.FieldControl>

              <S.FieldControl>
                <S.Label>
                  Last Name*
                  <S.FormikField
                    onChange={handleChange}
                    hasError={errors.lastName}
                    type="text"
                    name="lastName"
                  />
                </S.Label>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.FieldControl>
                <S.Label>
                  Address Line 1*
                  <S.FormikField
                    onChange={handleChange}
                    hasError={errors.addressLine1}
                    type="text"
                    name="addressLine1"
                  />
                </S.Label>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.FieldControl>
                <S.Label>
                  Address Line 2
                  <S.FormikField onChange={handleChange} type="text" name="addressLine2" />
                </S.Label>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldControl>
              <S.FieldGroup>
                <S.Label>
                  Country*
                  <S.FormikField
                    component={SelectField}
                    name="country"
                    placeholder="Choose your country"
                    hasError={errors.country}
                    type="text"
                    value={values.country}
                    options={countryList}
                    onChange={handleCountryChange}
                    onBlur={handleCountryChange}
                  />
                </S.Label>
                <S.Label>
                  Postal Code*
                  <S.FormikField
                    onChange={handleChange}
                    hasError={errors.pincode}
                    type="text"
                    name="pincode"
                  />
                </S.Label>
              </S.FieldGroup>
            </S.FieldControl>

            <S.FieldGroup>
              <S.FieldControl>
                <S.Label>
                  City*
                  <S.FormikField
                    onChange={handleChange}
                    hasError={errors.city}
                    type="text"
                    name="city"
                  />
                </S.Label>
              </S.FieldControl>
              <S.FieldControl>
                <S.Label>
                  State*
                  <S.FormikField
                    component={SelectField}
                    name="state"
                    hasError={errors.state}
                    placeholder="Choose your state"
                    value={values.state}
                    options={stateList}
                  />
                </S.Label>
              </S.FieldControl>
            </S.FieldGroup>

            <S.FieldGroup>
              <S.FieldControl>
                <S.ButtonsWrapper>
                  <S.SubmitButton
                    onClick={handleSubmit}
                    className={classnames('ant-btn', 'ant-btn-primary', 'resizebtn')}
                  >
                    Save
                  </S.SubmitButton>
                  {onCancel && (
                    <S.CancelButton onClick={onCancel} type="link">
                      Cancel
                    </S.CancelButton>
                  )}
                </S.ButtonsWrapper>
              </S.FieldControl>
            </S.FieldGroup>
            <S.Paragraph>* Indicates required field</S.Paragraph>
          </S.Wrapper>
        </Form>
      );
    }}
  </Formik>
);

export default AddressForm;
