const LeadIcon = ({ active = false }) => {
  const notActive = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <circle cx="7.50008" cy="5.49935" r="3.33333" stroke="#757575" strokeWidth="1.5"/>
    <path d="M12.5 8C13.8807 8 15 6.88071 15 5.5C15 4.11929 13.8807 3 12.5 3" stroke="#757575" strokeWidth="1.5" strokeLinecap="round"/>
    <ellipse cx="7.50008" cy="14.6673" rx="5.83333" ry="3.33333" stroke="#757575" strokeWidth="1.5"/>
    <path d="M15 12.166C16.4619 12.4866 17.5 13.2985 17.5 14.2493C17.5 15.1071 16.6552 15.8518 15.4167 16.2247" stroke="#757575" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>;

  const isActive = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <circle cx="7.50081" cy="5.49935" r="3.33333" fill="#252525"/>
    <ellipse cx="7.50081" cy="14.6673" rx="5.83333" ry="3.33333" fill="#252525"/>
    <path d="M17.4996 14.6664C17.4996 16.0471 15.8033 17.1664 13.7322 17.1664C14.3424 16.4995 14.762 15.6623 14.762 14.6676C14.762 13.6717 14.3414 12.8338 13.7301 12.1665C15.8012 12.1665 17.4996 13.2857 17.4996 14.6664Z" fill="#252525"/>
    <path d="M14.9996 5.5C14.9996 6.88071 13.8803 8 12.4996 8C12.1985 8 11.9099 7.94678 11.6426 7.84923C12.0368 7.15582 12.262 6.35374 12.262 5.49907C12.262 4.64503 12.0372 3.8435 11.6435 3.15045C11.9105 3.0531 12.1989 3 12.4996 3C13.8803 3 14.9996 4.11929 14.9996 5.5Z" fill="#252525"/>
  </svg>;

  return active ? isActive : notActive;
};
export default LeadIcon;
