import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Field } from 'formik';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  .ant-select {
    width: 100%;
  }
`;

const DeleteButton = styled.button`
  margin-top: 20px
  height: 40px !important;
  background: none;
  color: ${color.SkyBlue};
  border-style: none;
  opacity: 1;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: ${color.LeadsButtonHover};
  }

  .anticon svg {
    font-size: 20px;
  }
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 25px;
  color: ${color.UserMenuText};
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  /* font-size: 16px;
  flex: 1;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  height: 40px !important;
  background: none;
  color: ${color.SkyBlue};
  border-style: none;
  opacity: 1;
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  letter-spacing: 0.6px;
  text-align: center;
  width: 20vw; */
  /* .ant-select {
    width: 20vw!important;
  }

  .ant-select-selection--multiple {
    width: 252px;
    height: 40px;
  }
  .ant-select-dropdown .ant-select-dropdown-menu {
    width: 200px;
  }
  .ant-select-dropdown-menu .ant-select-dropdown-menu-root .ant-select-dropdown-menu-vertical {
    width: 10px !important;
  }
  .ant-select-selection__rendered {
    margin-top: 4px;
  } */
`;

export default {
  Label,
  FormikField,
  Wrapper,
  DeleteButton,
};
