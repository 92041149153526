//

const Version = ({ version }) => (
  <small>
    Version:
    {version}
  </small>
);

export default Version;
