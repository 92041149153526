import { useFetchSession } from 'modules/onboard/hooks';

import { getRouteAutoLogin } from 'modules/dashboard/routes/navigation';
import { CongratulationsOffer } from 'modules/onboard/components';
import { ROUTE_DENTIST_GUIDES_CATALOG } from 'modules/dashboard/routes/dentist/constants';

const GuideConfirmation = ({ history }) => {
  const { session } = useFetchSession();
  const orderNumber = session?.order;

  const handleClick = () => {
    const {
      autoLoginToken,
      customer: { email },
    } = session;

    const extraParams = `redirect=${ROUTE_DENTIST_GUIDES_CATALOG}`;

    const redirectUrl = autoLoginToken
      ? getRouteAutoLogin(email, autoLoginToken, extraParams)
      : '/';

    return history.push(redirectUrl);
  };

  return <CongratulationsOffer callback={handleClick} label="View my Guides" order={orderNumber} />;
};

export default GuideConfirmation;
