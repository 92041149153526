import { DateTime } from 'luxon';
import { WEDNESDAY, THURSDAY, FRIDAY, SATURDAY } from './constants';

export const getFirstAvailableDay = (day = DateTime.now()) => {
  let skipDays;
  const { weekday } = day;

  switch (weekday) {
    case WEDNESDAY:
    case FRIDAY:
      skipDays = 5;
      break;

    case THURSDAY:
    case SATURDAY:
      skipDays = 4;
      break;

    default:
      skipDays = 3;
  }

  return day.plus({ days: skipDays });
};

const COACH_ORDER_PRIORITY = 0;

export const parseSlots = (data) =>
  data.map(({ date, coachesAvailableTime = [] }) => ({
    day: date,
    slots: coachesAvailableTime.map(({ startTime: start, endTime: end, coaches }) => ({
      start,
      end,
      coachEmail: coaches[COACH_ORDER_PRIORITY].email,
    })),
  }));
