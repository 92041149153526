import styled from 'styled-components';

import ReactCropper from 'react-cropper';
import * as color from 'modules/common/theme/color';

const Cropper = styled(ReactCropper)`
  height: 200px;
  width: 100%;
`;

const LogoCropperWrapper = styled.div`
  .cropWrap {
    border: 2px dotted ${color.GreyFormBorder};
    border-radius: 3px;
    background-color: ${color.GreyMenuItemHover};
    margin: 20px;
    padding: 10px 20px;
    text-align: center;

    canvas {
      background-color: ${color.GreyMenuItemHover};
      width: 140px !important;
      height: 140px !important;
    }

    > p {
      font-size: 12px;
      font-weight: bold;
      line-height: 17px;
      color: ${color.GreySelectDd};
      padding: 0 0 5px;
      text-align: center;
      margin: 0;
    }

    .imgDetails {
      display: flex;
      padding: 8px 0 0;
      justify-content: center;

      p {
        width: 40%;
        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        color: ${color.GreySelectDd};
        text-align: left;
        position: relative;
        top: 3px;

        svg {
          width: 15px;
          display: inline-block;
          vertical-align: middle;
          fill: ${color.GreySelectDd};
          height: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100px;
          white-space: nowrap;
        }

        span {
          display: inline-block;
          vertical-align: middle;
          padding: 0 0 0 5px;
          width: calc(100% - 24px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      ul {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        width: 60%;
        position: relative;
        z-index: 10000;
        cursor: pointer;

        li {
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
          padding: 0 5px;

          svg {
            fill: ${color.GreySelectDd};
            width: 14px;
            height: auto;
          }
        }
      }
    }
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin: 0 20px;
`;

const Btn = styled.button`
  border: 1px solid ${color.neutral200};
  padding: 13px 18px;
  font-weight: 700;
  border-radius: 6px;
`;

const PrimaryBtn = styled(Btn)`
  background-color: ${color.Black};
  color: ${color.White};
`;

export default { LogoCropperWrapper, Cropper, BtnWrapper, Btn, PrimaryBtn };
