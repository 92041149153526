import { getInstance } from './instance';

const instance = getInstance();

const getBookCreditsList = ({ type = 'book' }) => {
  const url = 'book-credits';
  return instance
    .get(url, { params: { type } })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export { getBookCreditsList };
