import { useMutation } from 'react-query';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import { getRouteLaunchCampaign } from 'modules/v2/routes/navigation';
import { createOnDemandEmail } from 'modules/api';

import { DashboardTemplate } from 'modules/v2/templates';
import { OnDemandEmailsSideBar } from 'modules/v2/components';

import { notification } from 'modules/v2/common/utils';

import validationSchema from './validationSchema';

export const ALL_SEGMENTS_ID = 99;

const CreateOnDemandEmail = ({ history }) => {
  const formFields = {
    templateId: '',
    subject: '',
    segments: [],
    allSegments: false,
    timezone: 'America/New_York',
    scheduleDate: new Date(),
    sendImmediately: true,
    allAvailableSegments: [],
  };

  const { mutate: scheduleOnDemandEmail, isLoading: isLoadingSchedule } = useMutation(
    createOnDemandEmail,
    {
      onSuccess: () => {
        history.push(getRouteLaunchCampaign());
      },
      onError: () => {
        notification.error({ description: 'There we an error. Please try again later.' });
      },
    },
  );

  const handleFormSubmit = (formData) => {
    if (formData.segments.includes(ALL_SEGMENTS_ID)) {
      formData.allSegments = true;
      formData.segments = formData.allAvailableSegments;
    }
    scheduleOnDemandEmail(formData);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formFields}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      <DashboardTemplate
        isLoading={isLoadingSchedule}
        hasNoData={false}
        loadingTitle="Loading Campaigns"
        noDataTitle="No Campaigns Available"
        marginRight="0"
        hasSidebar
        noPaddingLeft
      >
        <OnDemandEmailsSideBar />
      </DashboardTemplate>
    </Formik>
  );
};

export default withRouter(CreateOnDemandEmail);
