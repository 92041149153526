const EyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.72904 12.7464C2.02076 11.8262 1.66663 11.3662 1.66663 10C1.66663 8.63386 2.02076 8.17378 2.72904 7.25362C4.14326 5.41631 6.51505 3.33334 9.99996 3.33334C13.4849 3.33334 15.8567 5.41631 17.2709 7.25362C17.9792 8.17378 18.3333 8.63386 18.3333 10C18.3333 11.3662 17.9792 11.8262 17.2709 12.7464C15.8567 14.5837 13.4849 16.6667 9.99996 16.6667C6.51505 16.6667 4.14326 14.5837 2.72904 12.7464Z"
        stroke="#252525"
        strokeWidth="1.5"
      />
      <path
        d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10Z"
        stroke="#252525"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default EyeIcon;
