import { get } from 'lodash-es';
import jwtDecode from 'jwt-decode';
import { getItem } from 'modules/v2/utils/legacy';

export const getValueFromToken = (value) => {
  const tokenData = getItem('token', 'string');

  if (tokenData) {
    const decodedData = jwtDecode(tokenData);
    return get(decodedData, ['data', value], false);
  }

  return false;
};
