import { put, select, takeEvery } from 'redux-saga/effects';

import { UPDATE_COVER_ACTIVE_TEMPLATE, UPDATE_PREFERENCES } from 'modules/editor/store/constants';
import {
  updateCoverElementsByPreferences,
  coverTemplateIdSelector,
  preferencesSelector,
} from 'modules/editor/store';

export function* onUpdateCoverElementsByPreferences() {
  const templateId = yield select(coverTemplateIdSelector);
  const preferences = yield select(preferencesSelector);

  const action = updateCoverElementsByPreferences(templateId, preferences);
  yield put(action);
}

export function* watchUpdateCoverElementsByPreferences() {
  const pattern = [UPDATE_COVER_ACTIVE_TEMPLATE, UPDATE_PREFERENCES];

  yield takeEvery(pattern, onUpdateCoverElementsByPreferences);
}
