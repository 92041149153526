import { useEffect } from 'react';
import { compose } from 'redux';
import { useParams } from 'react-router';

import withStore from 'store/StoreContainer';
import { Empty } from 'modules/common/components';
import { getDraftById } from 'modules/editor/store';

const withDraftRoute =
  (Component = Empty) =>
    (props) => {
      const { draftId } = useParams();

      useEffect(() => {
        props.getDraftById(draftId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [draftId]);

      return <Component {...props} draftId={draftId} />;
    };

const mapDispatchToProps = {
  getDraftById,
};

export default compose(withStore(undefined, mapDispatchToProps), withDraftRoute);
