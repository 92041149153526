import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './offerImage.png';

const isProduction = env.ENVIRONMENT === 'production';

const loanEducationPacketOfferCA = {
  offerDescription: [
    '12 Printed Loan Education Packets that you can give to buyers.',
    'Easily customize the guides to you by simply stapling your business card on the front cover.',
    'Show buyers that you care with the helpful information.',
    'Demonstrate that you’re a knowledgeable and helpful professional.',
    'Prove your value with all of the details and in-depth information inside the Loan Education Packets.',
  ],
  productHandler: 'loan-education-packet-offer',
  componentId: 'handle:clickfunnel-7',
  summaryPrice: '$7.00 for Shipping and Handling',
  summaryTitle: 'Loan Education Packet Canada(12 Guides each)',
  offerImage,
  upsell: {
    productHandler: 'authorify-monthly-non-contracted',
    productPricePointHandle: 'monthly_trial_0',
    componentId: isProduction ? '1824465' : '2197557',
    component: () => <Upsell productName="Loan Education Guide" />,
    offerId: isProduction ? '654b6df90329a5197ff40c20' : '654a0522efce4135b4cec4c4',
  },
};

export default loanEducationPacketOfferCA;
