import { Formik, Form } from 'formik';
import { FieldGroup } from 'modules/common/components/UIComponents/Form';
import { SelectMultiField } from 'modules/common/components';
import S from './styles';

const SegmentsLeads = ({ getSegmentList, segmentsData, isAllSegments }) => (
  <Formik enableReinitialize initialValues={{ segments: [] }}>
    {({ values }) => {
      const segmentsList =
        values && Object.keys(values).some((segment) => segment && segment === 'segments');
      if (segmentsList) {
        getSegmentList(values.segments);
      }
      return (
        <S.Wrapper>
          <Form>
            <FieldGroup>
              <S.Wrapper>
                <S.FormikField
                  component={SelectMultiField}
                  name="segments"
                  placeholder={isAllSegments ? 'All Segments' : 'Select Segment(s)'}
                  buttonname="Save"
                  type="text"
                  options={segmentsData}
                  disabled={isAllSegments}
                />
              </S.Wrapper>
            </FieldGroup>
          </Form>
        </S.Wrapper>
      );
    }}
  </Formik>
);

export default SegmentsLeads;
