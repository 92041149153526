import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { Field } from 'formik';

const Wrapper = styled.div`
  background: ${color.White};
  padding: 2rem;
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 16px;
  line-height: 25px;
  color: ${color.Black};
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    height: 40px !important;
    border-radius: 3px !important;
    font-size: 16px;
    padding: 4px 11px;
  }
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  width: 100%;
  flex: 1;
  border-radius: 3px;
  font-size: 18px;
  line-height: 22px;
  color: ${color.UserMenuText};
  .ant-select-selection {
    height: 43px !important;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-size: 16px;
  }
`;
const SelectContainer = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
    border-radius: 3px !important;
    font-size: 16px;
    padding: 4px 11px;
  }
`;
const Message = styled.span`
  font-size: 16px;
  font-style: italic;
  display: flex;
  justify-content: ${(props) => (props.primary ? 'flex-end' : ' ')};
  line-height: 19px;
  margin-top: -5px;
  color: ${color.Black};
`;
const CheckBox = styled.span`
  .ant-checkbox-wrapper {
    margin-top: 8px;
  }
  .ant-checkbox + span {
    color: black;
    font-size: 16px;
  }
`;

export default {
  Wrapper,
  Label,
  FormikField,
  Message,
  CheckBox,
  SelectContainer,
};
