const defaultProps = {
  width: '20',
  height: '20',
  fill: 'none',
};

function DragIcon(props) {
  const { width, height, fill } = { ...defaultProps, ...props };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill={fill}>
      <path d="M8.25 7.5C7.55964 7.5 7 6.94036 7 6.25C7 5.55964 7.55964 5 8.25 5C8.94036 5 9.5 5.55964 9.5 6.25C9.5 6.94036 8.94036 7.5 8.25 7.5Z" fill="#252525" />
      <path d="M8.25 11.25C7.55964 11.25 7 10.6904 7 10C7 9.30964 7.55964 8.75 8.25 8.75C8.94036 8.75 9.5 9.30964 9.5 10C9.5 10.6904 8.94036 11.25 8.25 11.25Z" fill="#252525" />
      <path d="M8.25 15C7.55964 15 7 14.4404 7 13.75C7 13.0596 7.55964 12.5 8.25 12.5C8.94036 12.5 9.5 13.0596 9.5 13.75C9.5 14.4404 8.94036 15 8.25 15Z" fill="#252525" />
      <path d="M12.25 7.5C11.5596 7.5 11 6.94036 11 6.25C11 5.55964 11.5596 5 12.25 5C12.9404 5 13.5 5.55964 13.5 6.25C13.5 6.94036 12.9404 7.5 12.25 7.5Z" fill="#252525" />
      <path d="M12.25 11.25C11.5596 11.25 11 10.6904 11 10C11 9.30964 11.5596 8.75 12.25 8.75C12.9404 8.75 13.5 9.30964 13.5 10C13.5 10.6904 12.9404 11.25 12.25 11.25Z" fill="#252525" />
      <path d="M12.25 15C11.5596 15 11 14.4404 11 13.75C11 13.0596 11.5596 12.5 12.25 12.5C12.9404 12.5 13.5 13.0596 13.5 13.75C13.5 14.4404 12.9404 15 12.25 15Z" fill="#252525" />
    </svg>
  );
}

export default DragIcon;
