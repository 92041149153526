export const CLEAR_DRAFT = 'draft/CLEAR_DRAFT';
export const GET_DRAFT_BY_ID = 'draft/GET_DRAFT_BY_ID';

export const CANCEL_GENERATE_DRAFT_PREVIEW = 'draft/CANCEL_GENERATE_DRAFT_PREVIEW';
export const CLEAR_DRAFT_PREVIEW = 'draft/CLEAR_DRAFT_PREVIEW';
export const GENERATE_DRAFT_PREVIEW = 'draft/GENERATE_DRAFT_PREVIEW';
export const GET_DRAFT_PREVIEW = 'draft/GET_DRAFT_PREVIEW';

export const CLEAR_DRAFT_LINKS = 'draft/CLEAR_DRAFT_LINKS';
export const GET_DRAFT_LINKS = 'draft/GET_DRAFT_LINKS';
export const GENERATE_DRAFT_LINKS = 'draft/GENERATE_DRAFT_LINKS';
export const CANCEL_GENERATE_DRAFT_LINKS = 'draft/CANCEL_GENERATE_DRAFT_LINKS';

export const GENERATE_DRAFT_FROM_BOOK = 'draft/GENERATE_DRAFT_FROM_BOOK';
export const GENERATE_DRAFT_FROM_ORDER = 'draft/GENERATE_DRAFT_FROM_ORDER';

export const CLEAR_DIGITAL_BOOK = 'draft/CLEAR_DIGITAL_BOOK';
export const GENERATE_DIGITAL_BOOK = 'draft/GENERATE_DIGITAL_BOOK';
export const CANCEL_GENERATE_DIGITAL_BOOK = 'draft/CANCEL_GENERATE_DIGITAL_BOOK';

export const CLEAR_PREFLIGHT_CHECK = 'draft/CLEAR_PREFLIGHT_CHECK';
export const GET_PREFLIGHT_CHECK = 'draft/GET_PREFLIGHT_CHECK';
export const CANCEL_PREFLIGHT_CHECK = 'draft/CANCEL_PREFLIGHT_CHECK';
