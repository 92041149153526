import mime from 'mime/lite';

export const getFileFromDataURI = (data, filename, imageFormat) => {
  const arr = data.split(',');
  const mimeType = imageFormat || arr[0].match(/:(.*?);/)[1];

  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const extension = mime.getExtension(mimeType);

  return new File([u8arr], `${filename}.${extension}`, { type: mimeType });
};
