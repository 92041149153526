import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as color from 'modules/common/theme/color';

const UpdateLeads = styled.div`
  display: flex;
  flex-direction: column;
  align-item: flex-start;
  justify-content: space-between;
`;

const EditBtn = styled.button`
  display: flex;
  border: none;
  background: ${color.White};
  color: ${color.SkyBlue};
  cursor: pointer;
  .anticon svg {
    font-size: 13px;
    margin: 0 10 0 5px;
  }
`;

const HistoryBtn = styled(Link)`
  display: flex;
  border: none;
  background: ${color.White};
  color: ${color.SkyBlue};
  margin-left: 5px;
  .anticon svg {
    font-size: 13px;
    margin: 0 10 0 5px;
  }
`;
const ModalWrapper = styled.div`
  background: ${color.White} !important;
  .ant-modal {
    background: ${color.White} !important;
  }
`;

const text = styled.span``;

export default {
  UpdateLeads,
  EditBtn,
  ModalWrapper,
  text,
  HistoryBtn,
};
