import styled from 'styled-components';
import { FieldControl as FieldControlBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';

const Wrapper = styled.div`
  padding: 24px 16px;
  background: ${color.White};
  height: 450px;
  overflow: auto;
`;

const FlexForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const FieldControl = styled(FieldControlBase)`
  flex-basis: 48%;
`;

export default {
  Wrapper,
  FlexForm,
  FieldControl,
};
