import env from 'environments';

import { includes, merge } from 'lodash-es';
import { getItem, setItem } from 'modules/dashboard/utils/legacy';
import { getNewToken } from 'shared/store';

export const configureAxios = (store, axios) => {
  const requestSuccess = (config) => {
    // If does not contain API auth
    if (!includes(config.url, env.BASE_URL)) {
      if (getItem('admin')) {
        const token = getItem('token');
        return merge({}, config, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      return config;
    }

    const token = getItem('token');
    const refreshtoken = getItem('refresh_token');

    return merge({}, config, {
      headers: {
        Authorization: `Bearer ${token}`,
        refreshtoken,
      },
    });
  };

  const responseError = (error) => {
    if (error.response && error.response.status === 401) {
      if (error.config.url === `${env.BASE_URL}/auth/refreshtoken`) {
        window.location = '/auth/login';
      } else {
        return store.dispatch(getNewToken()).then((res) => {
          setItem('token', res.payload.data.token);
          setItem('refresh_token', res.payload.data.refresh_token);

          return axios(error.config);
        });
      }
    }
    return Promise.reject(error);
  };

  // Add a request interceptor
  axios.interceptors.request.use(requestSuccess, Promise.reject);

  // Add a response interceptor
  axios.interceptors.response.use(undefined, responseError);
};
