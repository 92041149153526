import { getRouteWelcomeJourney } from 'modules/v2/routes/navigation';

export const generateClickUrl = (stepName) => {
  return getRouteWelcomeJourney(stepName);
};

export const taskProperCase = (task) => {
  return task
    .split('-')
    .map((word, i) => (i === 0 ? `${word.charAt(0).toUpperCase()}${word.slice(1)} ` : `${word} `))
    .join('');
};
