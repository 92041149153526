import { object, string, number } from 'yup';

const validationSchema = object().shape({
  product: string().required('Product is required'),
  title: string().required('Title is required'),
  productProperty: string().required('Product Property is required'),
  chargifyId: string().required('Chargify Id is required'),
  chargifyComponentId: string().required('Chargify Component Id is required'),
  chargifyProductHandle: string().required('Chargify Product Handle is required'),
  chargifyProductPriceHandle: string().required('Chargify Product Price Handle is required'),
  stripeId: string().required('Stripe Id is required'),
  type: string().required('Type is required'),
  price: string().required('Price is required'),
  priceProperty: string().required('Price Property is required'),
  value: number().typeError('Price Property is required').required('Price Property is required'),
  bookPackages: string(),
  creditsOnce: number().typeError('Credits Once is required').required('Credits Once is required'),
  creditsRecur: number()
    .typeError('Credits Recurrence is required')
    .required('Credits Recurrence is required'),
});

export default validationSchema;
