import { call, put, takeEvery } from 'redux-saga/effects';

import { resource, fetchPresetLogos } from 'modules/api';

import { goToRoot } from 'modules/editor/store';
import { GET_PRESET_LOGOS } from 'modules/editor/store/constants';

export function* onGetPresetLogos() {
  try {
    const request = resource(GET_PRESET_LOGOS, fetchPresetLogos);
    yield call(request);
  } catch (err) {
    yield put(goToRoot());
  }
}

export function* watchGetPresetLogos() {
  yield takeEvery(GET_PRESET_LOGOS, onGetPresetLogos);
}
