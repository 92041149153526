import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { get } from 'lodash-es';

import { isPending } from 'store/status';
import { AddressView } from 'modules/v2/common/views';
import { views } from 'modules/publish/constants';
import { ProfileTemplate } from 'modules/v2/templates';
import { withAddress, withCountries, withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';

const ShippingAddress = ({
  // DATA
  avatar,
  addresses,
  countries,
  profile,
  states,

  // Callbacks
  signOut,
  addUserAddresses,
  editUserAddresses,
  deleteUserAddresses,
  getUserAddresses,
}) => {
  const [activeView, setActiveView] = useState(views.ADDRESS_LIST);
  const [formAddressId, setFormAddressId] = useState(null);
  const [updatedShippingAddressId, setUpdatedShippingAddressList] = useState();

  const { data } = profile;
  const email = get(data, 'email');
  const username = get(data, 'firstName');
  useEffect(() => {
    getUserAddresses();
  }, []);

  const getSelectedAddress = (id) => {
    if (id) {
      setUpdatedShippingAddressList(id);
    }
  };

  useEffect(() => {
    getUserAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedShippingAddressId]);

  const loading = [addresses.status, countries.status, profile.status, states.status].some(
    isPending,
  );

  const content = (
    <AddressView
      loading={loading}
      addresses={addresses.data}
      countries={countries.data}
      states={states.data}
      onCreate={addUserAddresses}
      onEdit={editUserAddresses}
      onDelete={deleteUserAddresses}
      onSelect={getSelectedAddress}
      activeView={activeView}
      setActiveView={setActiveView}
      formAddressId={formAddressId}
      setFormAddressId={setFormAddressId}
    />
  );

  return (
    <ProfileTemplate
      avatar={avatar}
      email={email}
      title="Shipping Address"
      username={username}
      onLogout={signOut}
      sidebarWidth={0}
      noPaddingLeft
      noPaddingRight
    >
      {content}
    </ProfileTemplate>
  );
};

const Composed = compose(withAddress, withProfile, withAuth, withCountries)(ShippingAddress);

export default Composed;
