//

import { get, isEmpty } from 'lodash-es';

import S from './styles';

const Confirmation = ({ orderId, shippingAddress, contactPhone, contactEmail }) => {
  let address = null;

  if (!isEmpty(shippingAddress)) {
    const firstName = get(shippingAddress, 'firstName', '');
    const lastName = get(shippingAddress, 'lastName', '');
    const addressLine1 = get(shippingAddress, 'addressLine1', '');
    const addressLine2 = get(shippingAddress, 'addressLine2', '');
    const city = get(shippingAddress, 'city', '');
    const state = get(shippingAddress, 'state', '');
    const country = get(shippingAddress, 'country', '');
    const pincode = get(shippingAddress, 'pincode', '');

    const fullName = `${firstName} ${lastName}`;
    const location = `${city}, ${state} ${pincode}`;

    address = (
      <S.AddressWrapper>
        <h4>Your shipping address is:</h4>
        <S.Address>
          <p>
            <strong>{fullName}</strong>
          </p>
          <p>{addressLine1}</p>
          {addressLine2 && <p>{addressLine2}</p>}
          <p>{location}</p>
          <p>{country}</p>
        </S.Address>
      </S.AddressWrapper>
    );
  }

  return (
    <S.ConfirmationWrapper>
      <h4>Your order number is:</h4>
      <S.OrderNumber>{orderId}</S.OrderNumber>
      {address}
      <S.ContactInfoWrapper>
        <h4>Got Questions?</h4>
        <h4>Contact our support team at:</h4>
        <S.Phone>{contactPhone}</S.Phone>
        <h4>{contactEmail}</h4>
      </S.ContactInfoWrapper>
    </S.ConfirmationWrapper>
  );
};

export default Confirmation;
