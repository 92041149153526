//

import { withRouter } from 'react-router';
import { Menu } from 'antd';
import map from 'lodash/map';
import { useFormikContext } from 'formik';

import { NavItem, NavLink } from 'modules/common/components/UIComponents';
import {
  getRouteReferralMarketingGeneratedMagazine,
  getRouteReferralMarketingMagazineEditor,
  getRouteReferralMarketingPreviousMagazines,
} from 'modules/dashboard/routes/navigation';
import { rmMagzineEditorSteps } from 'modules/dashboard/pages/ReferralMarketingMagazines/constants';
import * as color from 'modules/common/theme/color';
import { isAdminEditing } from 'modules/dashboard/utils';
import S from './styles';

const MyMagazineSideNav = ({ location, month }) => {
  const keys = location.pathname.split('/');
  const { values } = useFormikContext();
  const { current, hasPreviousData } = values;
  const isMagazineEditorPage = window.location.pathname.includes('magazine-editor');

  const getStepColor = (stepIndex) => {
    const isPendingStep = current < stepIndex;
    const isCurrentStep = current === stepIndex;
    let stepColor;
    if (isPendingStep) {
      stepColor = hasPreviousData ? color.SideBarText : color.LightDullGray;
    } else if (isCurrentStep) {
      stepColor = color.LeadsButtonHover;
    } else {
      stepColor = color.SideBarText;
    }
    return stepColor;
  };

  const renderRMSteps = () => {
    const stepsToRender = map(rmMagzineEditorSteps, (step, index) => {
      const stepBackgroundColor = getStepColor(index);
      return (
        <S.TextAreaWrap fontSize="16px" key={step + index}>
          <S.StepCount stepColor={stepBackgroundColor}>{index + 1}</S.StepCount>
          <S.Text color={stepBackgroundColor} fontSize="16px">
            {step}
          </S.Text>
        </S.TextAreaWrap>
      );
    });
    return stepsToRender;
  };

  const subSideBarHeading = current === 0 ? 'RM Magazine Editor' : 'Referral Marketing Pages';

  return (
    <S.PageContainer>
      {!isAdminEditing() && (
        <Menu mode="inline" selectedKeys={keys} defaultOpenKeys={keys}>
          <NavItem key="magazine-editor">
            <NavLink to={getRouteReferralMarketingMagazineEditor(month)}>
              <S.MenuItem>RM Magazine Editor</S.MenuItem>
            </NavLink>
          </NavItem>
          <NavItem key="generated-magazine">
            <NavLink to={getRouteReferralMarketingGeneratedMagazine()}>
              <S.MenuItem>Active Magazine</S.MenuItem>
            </NavLink>
          </NavItem>
          <NavItem key="previous-magazines">
            <NavLink to={getRouteReferralMarketingPreviousMagazines()}>
              <S.MenuItem>Previous Magazine</S.MenuItem>
            </NavLink>
          </NavItem>
        </Menu>
      )}

      {isMagazineEditorPage ? (
        <S.SubSideBar>
          <S.TitlePaddingBottom fontSize="16px">{subSideBarHeading}</S.TitlePaddingBottom>
          {renderRMSteps()}
        </S.SubSideBar>
      ) : null}
    </S.PageContainer>
  );
};

export default withRouter(MyMagazineSideNav);
