import { Redirect } from 'react-router';

import { Empty } from 'modules/common/components';
import { isDentistCustomer } from 'modules/dashboard/utils';

const withOnboardGuard =
  (Component = Empty, guard = () => null) =>
    (props) => {
      const isDentist = isDentistCustomer();

      const redirect = guard(props);

      if (redirect === null) {
        return <Component {...props} isDentist={isDentist} />;
      }

      return <Redirect to={redirect} />;
    };

export default withOnboardGuard;
