import gql from 'graphql-tag';

export default gql(`
    query($bookId: ID!) {
        book(id:$bookId){
            _id
            name
            category
            fonts
            description
            status
            thumbnail {
                _id
                url
            }
            thumbnail_small {
                _id
                url
            }
            layout {
                id
                name
                width
                height
                marginTop
                marginLeft
                marginRight
                marginBottom
                paddingTop
                paddingLeft
                paddingRight
                paddingBottom
                _id
            }
            sections{
                _id
                name
                type
                currentStatus
              	displayName
              	templates{
                  _id
                  name
                  thumbnail{
                    _id
                    url
                  }
                  thumbnail_small{
                    _id
                    url
                  }
                  isActive
                  bookpage{
                    _id
                    status
                    elements
                  }
                }
            }
            
        }

    }
`);
