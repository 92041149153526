import styled from 'styled-components';
import { DatePicker as DatePickerBase } from 'antd';
import * as color from 'modules/common/theme/color';

const { RangePicker } = DatePickerBase;

const datePickerStyle = `
  border: 1px solid ${color.gray200};
  border-radius: 4px;
  background-color: ${color.White};
  color: ${color.gray100};

  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px ${color.FormBoxShadowColor} ;
  }
`;

const DatePicker = styled(RangePicker)`
  ${datePickerStyle}

  .anticon svg {
    color: ${color.gray100};
  }

  .ant-calendar-picker-input.ant-input {
    width: 316px;
    height: 40px;
  }

  .ant-picker-input > input {
    color: ${color.gray100};
  }

  :hover {
    ${datePickerStyle}
  }
`;

export default { DatePicker };
