import React from 'react';
import { Form, useFormikContext } from 'formik';
import { PrimaryButton } from 'modules/common/components/UIComponents';
import { FieldGroup } from 'modules/onboard/components';

import { Field } from './components';
import { defaultFormDefinition } from './defaultFormDefinition';

import { FormDefinition } from './types';
import S from './styles';

interface IFormBuilderProps {
  mainTitle: string;
  submitTitle?: string;
  formDefinitions?: FormDefinition[];
  orderSummary?: React.ReactNode;
}

const FormBuilder = ({
  mainTitle,
  submitTitle,
  formDefinitions,
  orderSummary,
}: IFormBuilderProps) => {
  const formDataConfig: FormDefinition[] = formDefinitions ?? defaultFormDefinition;
  const { handleSubmit, isSubmitting } = useFormikContext();

  return (
    <Form key="payment-form" onSubmit={handleSubmit}>
      {mainTitle && <S.PageTitle>{mainTitle}</S.PageTitle>}

      {formDataConfig.map(({ section, sectionId, blocks }: FormDefinition) => (
        <div key={sectionId}>
          {section && <S.FormTitle>{section}</S.FormTitle>}

          {blocks.map(({ blockId, fields }) => (
            <S.BlockForm key={blockId}>
              <FieldGroup gap>
                {fields.map((field) => (
                  <Field key={field.id} field={field} />
                ))}
              </FieldGroup>
            </S.BlockForm>
          ))}
        </div>
      ))}

      {orderSummary}

      <S.ButtonBlockForm>
        <FieldGroup justifyContent="center">
          <PrimaryButton
            block
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            {submitTitle ?? 'Submit'}
          </PrimaryButton>
        </FieldGroup>
      </S.ButtonBlockForm>
    </Form>
  );
};

export default FormBuilder;
