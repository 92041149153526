import { spawn } from 'redux-saga/effects';

import authSagas from 'modules/auth/store/sagas';
import bookLinksSagas from 'modules/bookLinks/store/sagas';
import dashboardSagas from 'modules/dashboard/store/sagas';
import draftSagas from 'modules/draft/store/sagas';
import editorSagas from 'modules/editor/store/sagas';
import publishSagas from 'modules/publish/store/sagas';

export default function* rootSaga() {
  yield spawn(authSagas);
  yield spawn(bookLinksSagas);
  yield spawn(dashboardSagas);
  yield spawn(draftSagas);
  yield spawn(editorSagas);
  yield spawn(publishSagas);
}
