export const ADDRESS_FORM = 'address_form';
export const ADDRESS_LIST = 'address_list';
export const CHECKOUT = 'checkout';
export const CONFIRMATION = 'confirmation';
export const GUIDEDETAILS = 'guide_details';
export const REVIEW = 'review';

export const MODAL_HEIGHT = '70vh';
export const MODAL_WIDTH = '900px';

export const views = {
  ADDRESS_FORM,
  ADDRESS_LIST,
  CHECKOUT,
  CONFIRMATION,
  GUIDEDETAILS,
  REVIEW,
};

export const isGuideOrPacket = (id) => {
  if (id === '657c4e963cef5c001c2a6be0') {
    return 'packet';
  }
  return 'guide';
};

export const isGuide = (id) => {
  if (id === '657c4e963cef5c001c2a6be0') {
    return false;
  }
  return true;
};

export const bookCreditsContentTitle = (name) => {
  if (name === 'guide') {
    return '1 Credit = 1 Printed Guide';
  }
  return '3 Credits = 1 Printed Packet';
};

export const bookCreditsContentDescription = (name) => {
  if (name === 'guide') {
    return 'Buy credits to print more guides. Minimum per order is 10 credits.';
  }
  return 'Buy credits to print more packets. Minimum per order is 30 credits.';
};

export const firstLetterCapital = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const pluralise = (name) => {
  return `${name}s`;
};

export const quantityContentDescription = (name) => {
  if (name === 'guide') {
    return 'Minimum of 10 per order is required.';
  }
  return 'Minimum of 30 per order is required.';
};
