import { put, takeLatest } from 'redux-saga/effects';

import { markCover } from 'modules/editor/store';

import {
  UPDATE_COVER_IMAGE,
  UPDATE_COVER_ELEMENT,
  UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
  UPDATE_COVER_ACTIVE_TEMPLATE,
} from 'modules/editor/store/constants';

export function* onMarkCover() {
  const action = markCover();
  yield put(action);
}

export function* watchMarkCover() {
  const pattern = [
    UPDATE_COVER_IMAGE,
    UPDATE_COVER_ELEMENT,
    UPDATE_COVER_ELEMENTS_BY_PREFERENCES,
    UPDATE_COVER_ACTIVE_TEMPLATE,
  ];

  yield takeLatest(pattern, onMarkCover);
}
