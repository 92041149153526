import * as Yup from 'yup';

const regexUSA = /(^[0-9]{5}(?:-[0-9]{4})?$)/;
const regexCanada = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]●?[0-9][A-Z][0-9]$/;

const zipUSA = {
  is: 'USA',
  then: Yup.string().matches(regexUSA, 'Postal Code for USA needs to be valid'),
};

const zipCanada = {
  is: 'Canada',
  then: Yup.string().matches(regexCanada, 'Postal Code to Canada needs to be valid'),
};

export const pincode = Yup.string()
  .required('Postal Code is required')
  .when('country', zipUSA)
  .when('country', zipCanada);

const firstName = Yup.string()
  .min(2, 'First name needs to have 2 characters or more')
  .required('First name is required');

const lastName = Yup.string().required('Last name is required');
const addressLine1 = Yup.string().required('Address line one is required');
const country = Yup.string().required('Country is required');
const city = Yup.string().required('City is required');
const state = Yup.string().required('State is required');

const validationSchema = Yup.object().shape({
  firstName,
  lastName,
  addressLine1,
  country,
  pincode,
  city,
  state,
});

export default validationSchema;
