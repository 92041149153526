import PropTypes from 'prop-types';

import { AuthenticationTemplate } from '../index';
import LoginErrors from './LoginErrors';
import S from '../AuthenticationTemplate/index.styled';

const forgotPasswordUrl = '/auth/reset';

function Login({
  handleChange,
  handleLogin,
  username = '',
  invalidEmail,
  statusCode,
  message,
  setShowPasswordReset,
  noBackground = false,
  noFooter = false,
  noLogo = false,
}) {
  return (
    <AuthenticationTemplate noBackground={noBackground} noFooter={noFooter} noLogo={noLogo}>
      <LoginErrors invalidEmail={invalidEmail} statusCode={statusCode} statusMessage={message} />
      <S.LoginForm onFinish={handleLogin} layout="vertical">
        <S.FormItem
          rules={[{ required: true, message: 'Please enter a valid email!', type: 'email' }]}
          name="username"
          label="Email Address"
        >
          <S.LoginInput
            size="large"
            name="username"
            placeholder="Your Email Address"
            value={username}
            onChange={handleChange}
          />
        </S.FormItem>
        <S.FormItem
          rules={[{ required: true, message: 'Please enter password!' }]}
          name="password"
          label="Password"
        >
          <S.LoginPasswordInput
            size="large"
            autoComplete="password"
            name="password"
            placeholder="Your Password"
            onChange={handleChange}
          />
        </S.FormItem>
        <S.FormItem>
          <S.LoginButton type="primary" htmlType="submit" block>
            Log In
          </S.LoginButton>
        </S.FormItem>
        {!noBackground ? (
          <S.LoginLink to={forgotPasswordUrl} target="_blank" rel="noopener noreferrer">
            Forgot Password?
          </S.LoginLink>
        ) : (
          <S.LoginLink
            onClick={() => {
              setShowPasswordReset(true);
            }}
          >
            Forgot Password?
          </S.LoginLink>
        )}
      </S.LoginForm>
    </AuthenticationTemplate>
  );
}

Login.defaultProps = {
  noBackground: false,
  noFooter: false,
  noLogo: false,
};

Login.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  invalidEmail: PropTypes.bool.isRequired,
  statusCode: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  setShowPasswordReset: PropTypes.func.isRequired,
  noBackground: PropTypes.bool,
  noFooter: PropTypes.bool,
  noLogo: PropTypes.bool,
};

export default Login;
