import { compose } from 'redux';

import { GlobalLoader } from 'modules/common/components';
import { withBook, withBookRoute } from 'modules/v2/editor/containers';
import { isPending } from 'store/status';

const Book = ({ book, children }) => {
  if (isPending(book.status)) {
    return <GlobalLoader />;
  }

  return children;
};

export default compose(withBookRoute, withBook)(Book);
