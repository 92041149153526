import { getInstance } from '../instance';

const instance = getInstance();

const updateBillingAddress = (body) => {
  const url = 'user/address';

  return instance.post(url, body);
};

const getDigitalBooks = () => {
  const url = 'draft/completed-updated-list';
  const params = {
    limit: 100,
    page: 0,
  };

  return instance.get(url, { params });
};

const unpublishBook = ({ payload }) => {
  return instance
    .post('book/unpublish', payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export { updateBillingAddress, getDigitalBooks, unpublishBook };
