import { Formik, Form } from 'formik';
import { FormLabel } from 'modules/v2/common/components';
import { Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import validationSchema from './validationSchema';

import S from './styles';

const EditProfileForm = ({ fields, onSave }) => (
  <Formik initialValues={fields} validationSchema={validationSchema} onSubmit={onSave}>
    {({ dirty, values, setFieldValue, errors, handleSubmit, handleChange, isValid }) => {
      const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, '');
        const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');

        return formatted;
      };

      const handleChangeNumber = (event) => {
        const { name, value } = event.target;
        const formattedValue = formatPhoneNumber(value);
        setFieldValue(name, formattedValue);
      };

      const isDisabled = !isValid || !dirty;

      return (
        <Form onSubmit={handleSubmit}>
          <div className="flex gap-3.5 mb-3.5">
            <S.FieldControl>
              <FormLabel title="First name" htmlFor="firstName" required>
                <TextInput
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
                {errors.firstName ? (
                  <span className="text-error-500 mt-2">{errors.firstName}</span>
                ) : null}
              </FormLabel>
            </S.FieldControl>
            <S.FieldControl>
              <FormLabel title="Last name" htmlFor="lastName" required>
                <TextInput
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
                {errors.lastName ? (
                  <span className="text-error-500 mt-2">{errors.lastName}</span>
                ) : null}
              </FormLabel>
            </S.FieldControl>
          </div>

          <div className="flex gap-3.5 mb-3.5">
            <S.FieldControl>
              <FormLabel title="Email address">
                <TextInput name="email" value={values.email} disabled />
              </FormLabel>
            </S.FieldControl>
            <S.FieldControl>
              <FormLabel title="Phone number" htmlFor="phone" required>
                <TextInput
                  id="phone"
                  name="phone"
                  type="tel"
                  value={formatPhoneNumber(values.phone)}
                  onChange={handleChangeNumber}
                  mask="999-999-9999"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                />
                {errors.phone ? <span className="text-error-500 mt-2">{errors.phone}</span> : null}
              </FormLabel>
            </S.FieldControl>
          </div>
          <Button buttonType="submit" type="dark" disabled={isDisabled} onClick={handleSubmit}>
            Save Changes
          </Button>
        </Form>
      );
    }}
  </Formik>
);

export default EditProfileForm;
