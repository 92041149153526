import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Dropdown as BaseDropdown } from 'antd';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Dropdown = styled(BaseDropdown)`
  display: block;
  min-width: 70px;
  max-width: 230px;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
  cursor: pointer;
`;

const NonLink = styled.span`
  line-height: 40px;
  display: block;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 600;
  touch-action: manipulation;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  color: #2e3f50;
  margin: 0 !important;
`;

const Link = NonLink.withComponent(RouterLink);

const Button = styled.div`
  color: ${color.White};
  font-weight: 500;
  background: ${color.PrimaryColorLight};
  border: 1px solid ${color.PrimaryColorLight};
  height: 36px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: ${font.MontserratFont};
  border-radius: 0;
  padding: 8px 15px;
  margin: 0 !important;
`;

Button.Label = styled.span`
  display: inline-block;
  max-width: calc(100% - 21.99px);
  min-width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Button.Icon = styled.div`
  float: right;
  padding: 0 0 0 10px;
  vertical-align: middle;

  & > svg {
    display: block;
    height: 20px;
  }
`;

export default {
  Dropdown,
  Button,
  Link,
  NonLink,
};
