import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const ImageSelectorWrapper = styled.div`
  padding: 10px;

  > p {
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    color: ${color.GreySelectDd};
    padding: 0 0 10px;
    margin: 0;
  }

  .imgListing {
    .list-portrait {
      text-align: center;

      .imgWrap {
        border: 2px solid #e8e5e5;
        display: block;
        margin: 15px;
        position: relative;
        cursor: pointer;

        img {
          padding: 1px;
          background: #f3f3f3;
          width: 100%;
          height: auto;
        }

        .imgDetails {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: ${color.GreySelectDd};
          padding: 10px;
          display: none;

          p {
            font-size: 10px;
            font-weight: 600;
            line-height: 14px;
            color: ${color.White};
            text-align: left;
            margin: 0;

            svg {
              width: 15px;
              display: inline-block;
              vertical-align: middle;
              fill: ${color.White};
              height: auto;
            }

            span {
              display: inline-block;
              vertical-align: middle;
              padding: 0 0 0 5px;
              width: calc(100% - 25px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .delIcon {
          position: absolute;
          top: 0;
          right: 0;
          background: ${color.GreySelectDd};
          width: 35px;
          height: 35px;
          border: 0;
          box-shadow: none;
          padding: 10px;
          cursor: pointer;
          display: none;

          svg {
            fill: ${color.White};
            width: 15px;
            height: auto;
          }
        }
      }

      &:hover {
        .imgWrap {
          border: 2px solid #a49f9f;

          .imgDetails {
            display: block;
          }

          .delIcon {
            display: block;
          }
        }
      }

      &.selected {
        .imgWrap {
          border: 2px solid ${color.ImgBorder};

          .imgDetails {
            display: block;
          }

          .delIcon {
            display: block;
          }
        }
      }
    }
  }
`;

const NoImages = styled.div`
  height: 250px;
  line-height: 250px;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
`;

export default { ImageSelectorWrapper, NoImages };
