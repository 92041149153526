import { getInstance } from './instance';

const instance = getInstance();

export const generateOnboardOrder = (payload) => {
  const url = '/onboard/order/book-and-update-session';

  return instance.post(url, payload);
};

export const generateMultipleOrders = (payload) => {
  const url = '/guides/multiple-order';

  return instance.post(url, payload);
};

export const storeOnboardGuideDetails = (payload) => {
  const url = '/guide-orders/store-onboard-data';

  return instance.post(url, payload);
};

export const generateGuideOrder = (payload) => {
  const url = '/guide-orders';

  return instance.post(url, payload);
};
