import axios from 'axios';
import qs from 'query-string';

import env from 'environments';

const instance = axios.create({
  baseURL: env.DIGITAL_SERVICES_URL,
  paramsSerializer: qs.stringify,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `afy-api-key ${env.DS_API_KEY}`,
    Accept: 'application/json',
  },
});

export const getInstance = () => instance;
