import classNames from 'classnames';
import React from 'react';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
}

const defaultProps: Partial<BoxProps> = {
  className: '',
};

const Box = ({ children, className }: BoxProps) => (
  <div className={classNames('bg-white w-full h-fit rounded-lg shadow-box mb-2', className)}>
    {children}
  </div>
);

Box.defaultProps = defaultProps;

export default Box;
