import { FC } from 'react';
import { get, sortBy } from 'lodash-es';
import { useFormikContext } from 'formik';
import {
  BILLING_ADDRESS_STATES,
  BILLING_ADDRESS_COUNTRY,
  BILLING_ADDRESS_STATE,
} from 'modules/formBuilder/constants';

import { useStatesAndCountries } from 'modules/common/hooks';
import { SelectFull } from 'modules/common/components';
import { FieldDefinition, IInitialValues } from 'modules/formBuilder/types';

const FormikCountryField: FC<FieldDefinition> = (props) => {
  const formik = useFormikContext<IInitialValues>();

  const { states } = useStatesAndCountries();

  const handleChange = (selectedCountry: string) => {
    const selectedStateList = get(states, selectedCountry);
    const stateList = sortBy(selectedStateList, 'title');
    const parsedStateList = stateList.map((state) => ({
      key: state.title,
      label: state.title,
      value: state.title,
    }));

    formik.setFieldValue(BILLING_ADDRESS_STATE, undefined);
    formik.setFieldValue(BILLING_ADDRESS_STATES, parsedStateList);
    formik.setFieldValue(BILLING_ADDRESS_COUNTRY, selectedCountry);
  };

  return <SelectFull {...props} onChange={handleChange} />;
};

export default FormikCountryField;
