import { createUploads, getCustomer } from 'modules/api';
import env from 'environments';

class UploadAdapter {
  constructor(loader, id, setImageUploads) {
    this.loader = loader;
    this.id = id;
    this.setImageUploads = setImageUploads;
  }

  upload() {
    return this.loader.file.then(async (image) => {
      const fileExtension = image.name.split('.').pop();

      if (!fileExtension) {
        throw new Error('File extension is empty');
      }

      return new Promise((resolve, reject) => {
        this.setImageUploads((prevValue) => {
          return [...prevValue, image.name];
        });

        createUploads({
          payload: {
            bucket: env.DS_BUCKET,
            isPrivate: false,
            ext: fileExtension,
            contentType: image?.type,
            path: `email-campaign/${this.id}`,
          },
          callbackTargetKey: 'uploadUrl',
          callbackPayload: image,
        })
          .then((imageUrl) => {
            this.setImageUploads((prevValue) => {
              return prevValue.filter((imageName) => imageName !== image.name);
            });
            resolve({ default: imageUrl });
          })
          .catch((error) => reject(error));
      });
    });
  }
}

const CustomUploadAdapterPlugin = function(editor, setImageUploads) {
  getCustomer().then((value) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader, value._id, setImageUploads);
    };
  });
};

export default CustomUploadAdapterPlugin;
