import { useParams } from 'react-router-dom';

import { isDentistOffer } from 'modules/dashboard/utils';
import { ConfirmButton } from 'modules/onboard/components';
import { getRouteScheduleCoaching } from 'modules/onboard/routes/navigation';
import { OnboardTemplate } from '../../templates';
import S from './styles';

const ConfirmTrial = ({ history }) => {
  const { offerCode } = useParams();
  const isDentist = isDentistOffer();

  const PRODUCT = isDentist ? 'guide' : 'book';

  const queryString = window.location.search;

  const handleClick = () => {
    history.replace(getRouteScheduleCoaching(offerCode, queryString));
  };

  return (
    <OnboardTemplate hideNavigation isStepsVisible={false}>
      <S.Container>
        <S.Icon />
        <div>
          <S.Title>Congratulations! Welcome to Authorify!</S.Title>
          <S.Description>
            Thank you for subscribing. You are now an Authorify trialing member.
          </S.Description>
          <S.Description>
            Proceed with scheduling with a consultant and setting up your first {PRODUCT}.
          </S.Description>
        </div>

        <S.ButtonContainer>
          <ConfirmButton onClick={handleClick} size="large" block>
            <S.ButtonText>Start setting up my {PRODUCT}</S.ButtonText>
          </ConfirmButton>
        </S.ButtonContainer>
        <div>
          <S.Description>Contact our support team at:</S.Description>
        </div>
        <div>
          <S.Description>+1 904-695-9933</S.Description>
          <S.Description>support@authorify.com</S.Description>
        </div>
      </S.Container>
    </OnboardTemplate>
  );
};

export default ConfirmTrial;
