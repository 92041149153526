import { useEffect, useState } from 'react';
import { get, noop } from 'lodash-es';

import { isSucceeded } from 'store/status';
import { Modal } from 'modules/v2/common/components';
import { getPreflightCheckItems } from 'modules/common/utils';

import { ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import {
  PreflightCheckView,
  GenerateLinksView,
} from 'modules/v2/draft/components/GenerateDigitalBookModal/views';
import { views } from './constants';

const GenerateDigitalBookModal = ({
  isOpen,
  preflight,
  publishRoute,
  draftRoute = null,
  status,
  onCancel = noop,
  onClose = noop,
  onEditCover = noop,
  onEditSection = noop,
  onOpen = noop,
  isRouting = false,
}) => {
  const [activeView, setActiveView] = useState('');
  const [openModalLinks, setOpenModalLinks] = useState(false);
  const [loadingText, setLoadingText] = useState('Personalizing your book');
  const loadingTexts = [
    'Personalizing your book',
    'Tailoring your landing page',
    'Generating personalized links',
    'Updating your information',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prevText) => {
        const currentIndex = loadingTexts.indexOf(prevText);
        const nextIndex = (currentIndex + 1) % loadingTexts.length;
        return loadingTexts[nextIndex];
      });
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setOpenModalLinks(true);
      setActiveView(views.CHECKING_PREFLIGHT);
      onOpen();
    } else {
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const isPreflightSucceeded = isSucceeded(preflight.status);
    const checkFinalPass = get(preflight.data, 'finalCheckPass', false);

    if (isPreflightSucceeded) {
      const criteria = get(preflight.data, 'finalResponse');
      const items = getPreflightCheckItems(criteria);
      const hasUncheckedItem = items.some((item) => !item.checked);

      if (checkFinalPass && status) {
        setActiveView(views.GENERATING_LINKS);
      } else if (hasUncheckedItem) {
        setActiveView(views.PREFLIGHT_FAIL);
      }
    }
  }, [preflight.status, preflight.data, status]);

  const handleCancel = () => {
    // Should cancel the whole generation if the preflight check is dismissed
    if (activeView === views.PREFLIGHT_FAIL) {
      onCancel();
    }

    // Should just close if the confirmation modal is opened
    if (activeView === views.GENERATING_LINKS) {
      setOpenModalLinks(false);
      onClose();
      setActiveView('');
    }
  };

  const viewRender = () => {
    switch (activeView) {
      case views.CHECKING_PREFLIGHT: {
        return (
          <ProcessingModal
            title="Publishing your book..."
            description={loadingText}
            showModal={isOpen}
            onCancel={handleCancel}
          />
        );
      }

      case views.PREFLIGHT_FAIL: {
        const isPreflightSucceeded = isSucceeded(preflight.status);

        return (
          <Modal
            hasCloseButton
            title="Pre-flight check"
            width="630px"
            isOpen={isOpen}
            onCancel={handleCancel}
            footer={null}
          >
            <div className="border-t border-neutral-200">
              <PreflightCheckView
                loading={!isPreflightSucceeded}
                preflight={preflight.data}
                onEditCover={onEditCover}
                onEditSection={onEditSection}
              />
            </div>
          </Modal>
        );
      }

      case views.GENERATING_LINKS: {
        if(isRouting){
          draftRoute();
          return null;
        }
        return (
          <GenerateLinksView
            publishRoute={publishRoute}
            openModal={openModalLinks}
            onCancel={handleCancel}
          />
        );
      }

      default:
        return null;
    }
  };

  return viewRender();
};

export default GenerateDigitalBookModal;
