const DatabaseIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.66663 12V4" stroke="#4CC9E1" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M13.3334 4V12" stroke="#4CC9E1" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M7.99996 6.66683C10.9455 6.66683 13.3333 5.47292 13.3333 4.00016C13.3333 2.5274 10.9455 1.3335 7.99996 1.3335C5.05444 1.3335 2.66663 2.5274 2.66663 4.00016C2.66663 5.47292 5.05444 6.66683 7.99996 6.66683Z"
        stroke="#4CC9E1"
        strokeWidth="1.5"
      />
      <path
        d="M13.3333 8C13.3333 9.47276 10.9455 10.6667 7.99996 10.6667C5.05444 10.6667 2.66663 9.47276 2.66663 8"
        stroke="#4CC9E1"
        strokeWidth="1.5"
      />
      <path
        d="M13.3333 12C13.3333 13.4728 10.9455 14.6667 7.99996 14.6667C5.05444 14.6667 2.66663 13.4728 2.66663 12"
        stroke="#4CC9E1"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default DatabaseIcon;
