import get from 'lodash/get';
import { Alert, Textarea } from 'flowbite-react';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { defaultTextAreaTheme } from 'modules/v2/common/AtomicDesign/atoms/TextArea/theme';
import InfoIcon from 'modules/v2/common/components/SvgIcon/InfoIcon';

const GenerateMagazine = ({ updateMagazineData }) => {
  const {
    magazine,
    handleUpdateMagazine,
    magazineDataFormik,
    setFormikContext,
  } = updateMagazineData;

  const handleTextChange = (e) => {
    const value = get(e, 'target.value');
    const name = get(e, 'target.name');
    handleUpdateMagazine(value, name);
  };

  const onHandleResetFormFields = () => {
    handleUpdateMagazine('', 'additionalInformation');
    setFormikContext('current', -1);
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <Alert color="info" className="mt-8">
          <div className="flex w-full gap-3 items-start">
            <InfoIcon fill="#1F8599" />
            <span>
              For additional instructions or request, fill in the form below or contact support for
              help. Any extensive customization may be subject for review first and to a
              professional fee starting at $39.
            </span>
          </div>
        </Alert>
        <div className="w-full flex flex-col gap-2">
          Additional instructions or request
          <Textarea
            theme={defaultTextAreaTheme}
            id="additionalInformation"
            name="additionalInformation"
            onChange={handleTextChange}
            value={get(magazine, 'additionalInformation')}
            type="text"
            rows="3"
            placeholder="Optional"
          />
        </div>
        <div className="flex justify-start w-full gap-2">
          <Button color="light" onClick={onHandleResetFormFields}>
            Cancel
          </Button>
          <Button color="dark" onClick={magazineDataFormik.handleSubmit}>
            Save changes
          </Button>
        </div>
      </div>
    </>
  );
};

export default GenerateMagazine;
