import { get, startsWith } from 'lodash-es';
import join from 'url-join';

import env from 'environments';

const getPrefixedThumbnail = (url, prefix) => {
  if (typeof url !== 'string') {
    return url;
  }

  if (startsWith(url, 'http')) {
    return url;
  }

  return join(prefix, url);
};

const injectThumbnailPrefix = (thumbnail, prefix) => {
  const url = get(thumbnail, 'url');

  return {
    ...thumbnail,
    url: getPrefixedThumbnail(url, prefix),
  };
};

export const addPrefixToBook = (book) => {
  const thumbnail = get(book, ['thumbnail'], {});
  const thumbnailSmall = get(book, ['thumbnail_small'], {});

  return {
    ...book,
    thumbnail: injectThumbnailPrefix(thumbnail, env.GRAPHQL_URL),
    thumbnail_small: injectThumbnailPrefix(thumbnailSmall, env.BASE_AUTH_URL_STATIC_FILES),
  };
};
