import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border: 1px solid ${color.GreyBoxBackgorund};
  border-bottom-width: 8px;
`;

const Header = styled.header`
  background-color: ${color.GreyBoxBackgorund};
  font-weight: 800;
  padding: 6px 8px;
`;

const Section = styled.section`
  display: flex;
  padding: 16px 8px;
  gap: 16px;
`;

const Image = styled.div`
  flex: 1;
  img {
    max-width: 164px;
    max-height: 80px;
  }
`;

const Column = styled.div`
  flex: 2;
  display; flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  header {
    white-space: nowrap;
    font-size: 14px;
    margin-bottom: 16px;
  }
  main {
    background-color: ${color.GreyBoxBackgorund};
    padding: 6px 4px;
  }
`;

export default {
  Main,
  Header,
  Section,
  Image,
  Column,
};
