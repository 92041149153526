//

import { NextIcon } from 'modules/common/components/SvgIcon';

import S from './styles';

const Item = ({ description, title, to }) => (
  <S.Link exact to={to}>
    <S.ContentWrapper>
      {title}
      <S.Description>{description}</S.Description>
    </S.ContentWrapper>
    <S.IconWrapper>
      <NextIcon />
    </S.IconWrapper>
  </S.Link>
);

export default Item;
