import { GET_DASHBOARD_PAGE } from '../constants';
const initialState = {
  loading: false,
  loaded: false,
  data: {},
};
export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_DASHBOARD_PAGE}_REQUEST`:
      return {
        ...state,
        loading: true,
      };

    case GET_DASHBOARD_PAGE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data,
      };

    case `${GET_DASHBOARD_PAGE}_FAILURE`:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    default:
      return {
        ...state,
      };
  }
};
