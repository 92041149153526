import { string, object, array } from 'yup';

export const guideDentistDetailsValidationSchema = object().shape({
  practiceName: string().required('Practice name is required'),
  practicePhone: string().required('Practice phone is required'),
  shippingAddress: object().shape({
    addressLine1: string().required('Address is required'),
    city: string().required('City is required'),
    state: string().required('State is required'),
    pincode: string().required('Postal Code is required'),
  }),
  practiceLogo: string().required('Practice logo is required'),
  practiceWebsite: string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!',
    )
    .required('Practice website is required'),
  practiceEmail: string().email('Practice email is not valid!'),
  frontCover: array().of(
    object().shape({
      image: string().required('Image url is required'),
      name: string().required('Name is required'),
      title: string().required('Title is required'),
    }),
  ),
  firstDentist: object().shape({
    image: string().required('Image url is required'),
    name: string().required('Name is required'),
    title: string().required('Title is required'),
  }),
});
