import styled from 'styled-components';
import { Input as InputBase } from 'antd';

import * as color from 'modules/common/theme/color';

const { Search: SearchBase } = InputBase;

const Search = styled(SearchBase)`
  width: unset !important;

  .ant-input {
    border-radius: 4px;
    border: 1px solid ${color.gray200};
  }

  .ant-input-search-button {
    border-radius: 0 4px 4px 0;
  }

  .ant-btn.ant-btn-primary {
    border: 1px solid ${color.gray200};
    border-radius: 0 4px 4px 0;
  }

  .ant-input-group {
    width: 336px;
  }
  .ant-input-group .ant-input {
    height: 44px;
  }

  .ant-btn {
    height: 44px;
  }

  .ant-btn.ant-btn-primary {
    background-color: ${color.White};
    border: none;
    border: 1px solid ${color.LightGray};
    border-radius: 4px;
  }

  .anticon-search {
    display: block;
  }

  svg {
    color: ${color.LightHeadGray};
  }
`;

export { Search };
