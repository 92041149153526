import styled, { css } from 'styled-components';

import * as color from 'modules/common/theme/color';
import { Button } from 'antd';

const ItemIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 2px;

  svg {
    fill: ${color.ErrorWarning};
    width: 16px;
    height: 16px;

    path {
      fill: ${color.ErrorWarning};
    }
  }

  ${({ checked }) =>
    checked &&
    css`
      svg {
        fill: ${color.SuccessWarning} !important;
        path {
          fill: ${color.SuccessWarning} !important;
        }
      }
    `};
`;

const ListItem = styled.li`
  border-bottom: 1px solid ${color.TitleBorder};
  font-weight: 600;
  color: #333333;
  padding: 8px 0 8px 30px;
  text-align: left;
  position: relative;
  font-size: 18px;
  line-height: 24px;
`;

const EditButton = styled(Button)`
  height: 20px;
  padding: 0 !important;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: ${color.ErrorWarning};
  margin: 0 0 0 10px;

  &:hover {
    text-decoration: underline !important;
  }
`;

export default {
  ItemIcon,
  ListItem,
  EditButton,
};
