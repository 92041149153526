import { CoverImage } from 'modules/editor/components';

import S from './styles';

const BookCard = ({ name, image, to, isAllowed }) => {
  if (isAllowed) {
    return (
      <S.WrapperLink to={to} data-testid="link">
        <S.ImageWrapper>
          <CoverImage url={image} isAllowed={isAllowed} />
        </S.ImageWrapper>
        <S.Title>{name}</S.Title>
      </S.WrapperLink>
    );
  }
  return (
    <div>
      <S.ImageWrapper>
        <CoverImage url={image} isAllowed={isAllowed} />
      </S.ImageWrapper>
      <S.Title>{name}</S.Title>
    </div>
  );
};

BookCard.defaultProps = {
  name: '',
  image: '',
  to: '',
};

export default BookCard;
