import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

const validationSchema = Yup.object().shape({
  name: validation.templateName,
  subject: validation.templateSubject,
  content: validation.templateContent,
  bodyContent: validation.templateBodyContent,
  imageUrl: validation.imageUrl,
  templateTitle: validation.templateTitle,
  emailTemplate: validation.emailTemplate,
});

export default validationSchema;
