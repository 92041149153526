import { get, find } from 'lodash-es';

import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';
import { GET_CATEGORIES, SELECT_CATEGORY } from 'modules/dashboard/store/constants';
import { categories as initialState } from 'modules/dashboard/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_CATEGORIES}_REQUESTED`: {
      return {
        ...state,
        data: null,
        status: STATUS_LOADING,
      };
    }

    case `${GET_CATEGORIES}_FAILED`: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case `${GET_CATEGORIES}_SUCCEEDED`: {
      const data = get(action, ['payload', 'categories'], null);
      const defaultCountry = find(data, { order: 1 });
      const selected = get(defaultCountry, '_id', null);

      return {
        ...state,
        data,
        selected,
        status: STATUS_SUCCESS,
      };
    }

    case SELECT_CATEGORY: {
      const selected = get(action, ['payload', 'categoryId'], null);

      return {
        ...state,
        selected,
      };
    }

    default:
      return state;
  }
};

export default reducer;
