import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { get, map } from 'lodash-es';
import { FormLabel, SelectMultiField, SelectField } from 'modules/common/components';
import {
  FieldGroup,
  FieldControl,
  Input,
  ErrorMessage,
  InputMask,
} from 'modules/common/components/UIComponents/Form';
import { Checkbox } from 'antd';

import validationSchema from './ValidationSchema';

import S from './style';

const EditLeads = ({ data, getData, countries, states, disabled, segmentsData, isError }) => {
  const [editLeadData, setEditLeadData] = useState(null);
  const [isAllSegment, setIsAllSegment] = useState(false);

  const onAllSegmentChange = (e) => {
    setIsAllSegment(e.target.checked);
  };

  useEffect(() => {
    if (data) {
      const leadData = data;
      if (!data.segments && !data.allSegments) {
        leadData.segments = [];
      } else if (data && data.allSegments) {
        setIsAllSegment(true);
      }
      setEditLeadData(leadData);
    }
  }, [data]);

  return (
    editLeadData && (
      <div className="max-h-[500px] ">
        <Formik enableReinitialize validationSchema={validationSchema} initialValues={editLeadData}>
          {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => {
            getData(values);
            disabled();
            const optionBuilder = (item) => ({ title: item, value: item });

            const selectedCountry = values.country;
            const selectedStateList = get(states, selectedCountry);
            const countryList = map(countries, optionBuilder);
            const stateList = map(selectedStateList, optionBuilder);
            const handleCountryChange = () => setFieldValue('state', undefined);
            return (
              <S.Wrapper>
                <Form>
                  <FieldGroup gap>
                    <FieldControl>
                      {isError ? (
                        <S.Message>* Please enter all mandatory fields.</S.Message>
                      ) : (
                        false
                      )}
                    </FieldControl>
                  </FieldGroup>
                  <FieldGroup gap>
                    <FieldControl>
                      <FormLabel title="First Name" htmlFor="firstName" required>
                        <Input
                          id="firstName"
                          name="firstName"
                          placeholder="Type first name here"
                          value={values?.firstName}
                          $hasError={errors.firstName && touched.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.firstName && touched.firstName ? (
                          <ErrorMessage>{errors.firstName}</ErrorMessage>
                        ) : null}
                      </FormLabel>
                    </FieldControl>
                    <FieldControl>
                      <FormLabel title="Last Name" htmlFor="lastName" required>
                        <Input
                          id="lastName"
                          name="lastName"
                          placeholder="Type last name here"
                          value={values?.lastName}
                          $hasError={errors.lastName && touched.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.lastName && touched.lastName ? (
                          <ErrorMessage>{errors.lastName}</ErrorMessage>
                        ) : null}
                      </FormLabel>
                    </FieldControl>
                  </FieldGroup>
                  <FieldGroup gap>
                    <FieldControl>
                      <FormLabel title="Email Address" htmlFor="email" required>
                        <Input
                          id="email"
                          name="email"
                          placeholder="Type email address here"
                          value={values?.email}
                          $hasError={errors.email && touched.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <ErrorMessage>{errors.email}</ErrorMessage>
                        ) : null}
                      </FormLabel>
                    </FieldControl>
                    <FieldControl>
                      <FormLabel title="Phone Number" htmlFor="phone" required>
                        <InputMask
                          id="phone"
                          name="phone"
                          placeholder="Type phone number here"
                          type="text"
                          alwaysShowMask
                          mask="999-999-9999"
                          value={values?.phone}
                          $hasError={errors.phone && touched.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <ErrorMessage>{errors.phone}</ErrorMessage>
                        ) : null}
                      </FormLabel>
                    </FieldControl>
                  </FieldGroup>
                  <FieldGroup gap>
                    <FieldControl>
                      <FormLabel title="Address 1" htmlFor="address1" required>
                        <Input
                          id="address1"
                          name="address1"
                          placeholder="Type address 1 here"
                          $hasError={errors.address1 && touched.address1}
                          value={values?.address1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address1 && touched.address1 ? (
                          <ErrorMessage>{errors.address1}</ErrorMessage>
                        ) : null}
                      </FormLabel>
                    </FieldControl>
                    <FieldControl>
                      <FormLabel title="Address 2 (Optional)" htmlFor="address2">
                        <Input
                          id="address2"
                          name="address2"
                          placeholder="Type address 2 here"
                          value={values?.address2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormLabel>
                    </FieldControl>
                  </FieldGroup>
                  {/* <FieldGroup gap> */}
                  <FieldControl>
                    <FormLabel title="Country" htmlFor="country" required>
                      <S.SelectContainer>
                        <S.FormikField
                          component={SelectField}
                          name="country"
                          placeholder="Choose your country"
                          type="text"
                          options={countryList}
                          $hasError={errors.country && touched.country}
                          onChange={handleCountryChange}
                          onBlur={handleCountryChange}
                          value={values?.country}
                        />
                      </S.SelectContainer>
                      {errors.country && touched.country ? (
                        <ErrorMessage>{errors.country}</ErrorMessage>
                      ) : null}
                    </FormLabel>
                  </FieldControl>
                  <FieldControl>
                    <FormLabel title="State/Province" htmlFor="state" required>
                      <S.SelectContainer>
                        <S.FormikField
                          component={SelectField}
                          name="state"
                          placeholder="Choose your state"
                          options={stateList}
                          value={values?.state}
                          $hasError={errors.state && touched.state}
                        />
                      </S.SelectContainer>
                      {errors.state && touched.state ? (
                        <ErrorMessage>{errors.state}</ErrorMessage>
                      ) : null}
                    </FormLabel>
                  </FieldControl>
                  {/* </FieldGroup> */}
                  <FieldGroup gap>
                    <FieldControl>
                      <FormLabel title="City" htmlFor="city" required>
                        <Input
                          id="city"
                          name="city"
                          placeholder="Type city here"
                          value={values?.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          $hasError={errors.city && touched.city}
                        />
                        {errors.city && touched.city ? (
                          <ErrorMessage>{errors.city}</ErrorMessage>
                        ) : null}
                      </FormLabel>
                    </FieldControl>
                    <FieldControl>
                      <FormLabel title="Postal Code" htmlFor="zip" required>
                        <Input
                          id="zip"
                          name="zip"
                          placeholder="Type postal code here"
                          value={values?.zip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          $hasError={errors.zip && touched.zip}
                        />
                        {errors.zip && touched.zip ? (
                          <ErrorMessage>{errors.zip}</ErrorMessage>
                        ) : null}
                      </FormLabel>
                    </FieldControl>
                  </FieldGroup>

                  <FieldGroup gap>
                    <S.Label>
                      Add To Segment *
                      <S.FormikField
                        disabled={isAllSegment}
                        component={SelectMultiField}
                        name="segments"
                        placeholder={isAllSegment ? 'All Segments' : 'Select Segment(s)'}
                        type="text"
                        options={segmentsData}
                        maxCount="8"
                        // selectedSegment={
                        //   editLeadData && editLeadData.allSegments
                        //     ? []
                        //     : editLeadData && editLeadData.segments.map((d) => d.id)
                        // }
                      />
                    </S.Label>
                  </FieldGroup>

                  <FieldGroup gap>
                    <FieldControl>
                      <S.CheckBox>
                        <Checkbox
                          defaultChecked={editLeadData.allSegments}
                          name="allSegments"
                          id="allSegments"
                          onChange={(e) => {
                            onAllSegmentChange(e);
                            handleChange(e);
                          }}
                        >
                          All Segments
                        </Checkbox>
                      </S.CheckBox>
                    </FieldControl>
                  </FieldGroup>
                </Form>
              </S.Wrapper>
            );
          }}
        </Formik>
      </div>
    )
  );
};

export default EditLeads;
