import { useState } from 'react';
import { Radio } from 'antd';

import CreditItem from './CreditItem/CreditItem';

import S from './styles';

export const OrderCredits = ({ getSetCreditValue, creditValue, creditsData, paymentData }) => {
  let val = creditsData[0]?.id;
  if (creditValue?.id !== undefined) {
    val = creditValue?.id;
  } else {
    getSetCreditValue(creditsData[0]);
  }
  const [value, setValue] = useState(val);
  const handleChange = (e) => {
    const newValue = e.target.value;
    const selectedCredit = creditsData.find((item) => item.id === newValue);
    if (selectedCredit) {
      getSetCreditValue(selectedCredit);
    }
    setValue(newValue);
  };

  if (!paymentData.length) {
    return null;
  }

  return (
    <S.Container>
      <form>
        <Radio.Group onChange={handleChange} value={value}>
          {creditsData?.map((item) => <CreditItem key={item.id} creditData={item} />)}
        </Radio.Group>
      </form>
    </S.Container>
  );
};
