import {
  GET_USER_ADDRESSES,
  ADD_USER_ADDRESS,
  EDIT_USER_ADDRESS,
  DELETE_USER_ADDRESS,
} from 'modules/v2/store/constants';

export const getUserAddresses = () => ({
  type: GET_USER_ADDRESSES,
});

export const addUserAddresses = ({
  firstName,
  lastName,
  phoneNumber,
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  pincode,
  isDefault,
}) => ({
  type: ADD_USER_ADDRESS,
  payload: {
    firstName,
    lastName,
    phoneNumber,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    pincode,
    isDefault,
  },
});

export const editUserAddresses = ({
  _id,
  firstName,
  lastName,
  phoneNumber,
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  pincode,
  isDefault,
}) => ({
  type: EDIT_USER_ADDRESS,
  payload: {
    _id,
    firstName,
    lastName,
    phoneNumber,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    pincode,
    isDefault,
  },
});

export const deleteUserAddresses = (addressId) => ({
  type: DELETE_USER_ADDRESS,
  payload: {
    addressId,
  },
});
