import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const ListContainer = styled.div`
  border: 1px solid ${color.TitleBorder};
  border-radius: 3px;
  padding: 25px;
  margin: 0 23px 30px;
`;

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li:last-child {
    border-bottom: none;
  }
`;

export default {
  ListContainer,
  ListWrapper,
};
