import { createSelector } from 'reselect';

import { editorSelector } from 'modules/editor/store/selectors/editor';
import { convertSectionsBucketArray } from 'modules/editor/utils/sections';

import { getDropdownSections } from './dropdown';

import {
  getDraftState,
  getDraft,
  getDraftId,
  getDraftName,
  getPreferences,
  getSections,
  getSectionById,
  getCoverSection,
  getCoverElements,
  getSortedSections,
  getActiveSections,
  getChapterSections,
  getContentSections,
  getDisplayName,
  getType,
  getTemplates,
  getActiveTemplate,
  getSortedTemplates,
  mapTemplateList,
  getTemplateId,
  getBookpages,
  getActiveBookpage,
  getElements,
  getElementById,
  getContactInfo,
} from './utils';

export const draftStateSelector = createSelector(editorSelector, getDraftState);
export const draftSelector = createSelector(draftStateSelector, getDraft);
export const draftIdSelector = createSelector(draftSelector, getDraftId);
export const draftNameSelector = createSelector(draftSelector, getDraftName);

export const preferencesSelector = createSelector(draftSelector, getPreferences);
export const contactInfoSelector = createSelector(preferencesSelector, getContactInfo);

export const sectionsSelector = createSelector(draftSelector, getSections);
export const sectionByIdSelector = (sectionId) =>
  createSelector(sectionsSelector, getSectionById(sectionId));

export const displayNameSelector = (sectionId) =>
  createSelector(sectionByIdSelector(sectionId), getDisplayName);

export const sectionTypeSelector = (sectionId) =>
  createSelector(sectionByIdSelector(sectionId), getType);

export const sortedSectionsSelector = createSelector(sectionsSelector, getSortedSections);

export const activeSectionsSelector = createSelector(sortedSectionsSelector, getActiveSections);

export const activeChaptersSelector = createSelector(activeSectionsSelector, getChapterSections);

export const activeContentSections = createSelector(activeSectionsSelector, getContentSections);

export const dropdownSectionsSelector = createSelector(
  [draftIdSelector, activeContentSections],
  (draftId, sections) => getDropdownSections(draftId, sections),
);

export const manageSectionsSelector = createSelector(
  sortedSectionsSelector,
  convertSectionsBucketArray,
);

export const manageSectionsContentSelector = createSelector(
  manageSectionsSelector,
  (buckets) => buckets.content,
);

export const manageSectionsManuscriptSelector = createSelector(
  manageSectionsSelector,
  (buckets) => buckets.manuscript,
);

export const contentSectionsSelector = createSelector(sortedSectionsSelector, getContentSections);

export const activeContentSectionsSelector = createSelector(
  activeSectionsSelector,
  getContentSections,
);

export const coverSectionSelector = createSelector(sectionsSelector, getCoverSection);

export const coverSectionTemplatesSelector = createSelector(coverSectionSelector, getTemplates);
export const coverSectionSortedTemplatesSelector = createSelector(
  coverSectionTemplatesSelector,
  getSortedTemplates,
);

export const coverActiveTemplateSelector = createSelector(
  coverSectionTemplatesSelector,
  getActiveTemplate,
);

export const coverTemplateIdSelector = createSelector(coverActiveTemplateSelector, getTemplateId);

export const bookpagesSelector = createSelector(coverActiveTemplateSelector, getBookpages);
export const activeBookpageSelector = createSelector(bookpagesSelector, getActiveBookpage);
export const activeBookpageElementsSelector = createSelector(activeBookpageSelector, getElements);

export const coverElementsSelector = createSelector(
  [activeBookpageElementsSelector, preferencesSelector],
  getCoverElements,
);

export const coverElementByIdSelector = (elementId) =>
  createSelector(activeBookpageElementsSelector, getElementById(elementId));

export const coverTemplateListSelector = createSelector(
  coverSectionSortedTemplatesSelector,
  mapTemplateList,
);

export const contentSectionByIdSelector = (sectionId) =>
  createSelector(sectionsSelector, getSectionById(sectionId));

export const contentTemplatesBySectionIdSelector = (sectionId) =>
  createSelector(contentSectionByIdSelector(sectionId), getTemplates);

export const contentSortedTemplatesBySectionIdSelector = (sectionId) =>
  createSelector(contentTemplatesBySectionIdSelector(sectionId), getSortedTemplates);

export const contentTemplateListSelector = (sectionId) =>
  createSelector(contentSortedTemplatesBySectionIdSelector(sectionId), mapTemplateList);
