//

import ProgressBar from '../ProgressBar';

import S from './styles';

const ImagePlaceholder = ({ width, height, scale, ...props }) => (
  <S.Wrapper width={width} height={height} {...props}>
    <ProgressBar scale={scale} />
  </S.Wrapper>
);

ImagePlaceholder.defaultProps = {
  width: '100%',
  height: '100%',
  scale: 1,
};

export default ImagePlaceholder;
