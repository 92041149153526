import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Container = styled.div`
  width: 640px;
  padding: 24px;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const CreditContainer = styled.div`
  width: 590px;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  border: 1px solid ${color.GrayBorderColor};
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ContainerTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
`;

const Line = styled.hr`
  width: 100vw;
  margin-left: -30px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  background-color: ${color.GrayBorderColor};
  color: ${color.GrayBorderColor};
  border: none;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ModalDescription = styled.span`
  font-size: 15px;

  b {
    font-weight: 800;
    color: ${color.PrimaryColor};
    margin-right: 2px;
  }
`;

export default { ContainerTitle, Container, ModalDescription, Line, CreditContainer, LeftContent };
