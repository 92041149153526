import { call, put, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { createDraft, resource } from 'modules/api';
import { getRouteEditDraft } from 'modules/editor/routes/navigation';
import { goToRoot } from 'modules/editor/store';

import { CREATE_DRAFT } from 'modules/editor/store/constants';

export function* onCreateDraft({ payload }) {
  try {
    const request = resource(CREATE_DRAFT, createDraft);
    const { draftId } = yield call(request, payload);

    if (draftId) {
      const location = getRouteEditDraft(draftId);

      // Use replace to avoid returning to the same URL with back button
      yield put(replace(location));
    }
  } catch (err) {
    yield put(goToRoot());
  }
}

export function* watchCreateDraft() {
  yield takeEvery(CREATE_DRAFT, onCreateDraft);
}
