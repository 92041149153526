import { compose } from 'redux';
import { get } from 'lodash-es';

import { withProfile } from 'modules/dashboard/containers';
import { withAuth } from 'modules/auth/containers';
import {
  isPilotCustomer,
  isDentistCustomer,
  hasBookAccess,
  hideBookAccess,
} from 'modules/dashboard/utils';
import { DashboardNavBar } from 'modules/dashboard/components';
import { getRouteDentistGuidesCatalog } from 'modules/dashboard/routes/dentist/navigation';

import { Footer } from 'modules/common/components';

import { useEffect } from 'react';
import AppsContainer from './sections/Apps';
import BooksContainer from './sections/Books';
import MagazineContainer from './sections/Magazine';
import TrainingSitesContainer from './sections/TrainingSites';
import { INTERCOM_CONTROL } from './constants';

import S from './styles';

export const HomePage = ({ history, avatar, profile, signOut }) => {
  const isDentist = isDentistCustomer();

  useEffect(() => {
    if (isDentist) {
      history.push(getRouteDentistGuidesCatalog());
    }
    const intercom = window?.Intercom;
    const intercomShow = localStorage.getItem(INTERCOM_CONTROL);
    if (intercom && !intercomShow) intercom('show');
    localStorage.setItem(INTERCOM_CONTROL, '1');
  }, []);

  const { email, firstName } = get(profile, 'data', {});

  return (
    <>
      <DashboardNavBar email={email} avatar={avatar} username={firstName} onLogout={signOut} />
      <S.ExternalContainer>
        <S.PageContainer>
          <S.ContainerWrapper>
            {hasBookAccess() && !hideBookAccess() ? <BooksContainer /> : null}
            {isPilotCustomer() ? <MagazineContainer /> : null}
          </S.ContainerWrapper>
          <AppsContainer />
          <TrainingSitesContainer />
        </S.PageContainer>
      </S.ExternalContainer>
      <Footer />
    </>
  );
};

export default compose(withProfile, withAuth)(HomePage);
