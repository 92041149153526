import styled from 'styled-components';
import { DatePicker as DatePickerBase, Layout } from 'antd';
import { Tooltip as TooltipBase } from 'modules/common/components';
import * as color from 'modules/common/theme/color';
import { Field as BaseField } from 'formik';

import { Input as UIInput } from 'modules/common/components/UIComponents/Form';

const Wrapper = styled.div`
  input {
    width: 100%;
  }
`;

const Title = styled.h4`
  font-size: 16px !important;
`;

const Label = styled.label`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 12px;
  display: block;
`;

const DatePicker = styled(DatePickerBase)`
  width: 100%;
  margin-bottom: 20px;
`;

const LabelWithIcon = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tooltip = styled(TooltipBase)``;

const Sidebar = styled(Layout.Sider)`
  flex-basis: 245px !important;
  width: 245px !important;
  max-width: 245px !important;
  box-shadow: 0px 3px 6px #00000040;

  background: ${color.lightBackground};
  padding: 30px 20px 30px 40px;
`;

const FieldContainer = styled.div`
  .ant-checkbox-wrapper,
  label,
  .ant-form-item,
  .ant-select {
    width: 100%;
  }
`;

const FormikField = styled(BaseField).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  border-radius: 3px;
  background-color: ${color.White};
`;

const Field = styled(UIInput)`
  border: 1px solid ${color.LightGray};
  height: 32px;
  font-size: 14px;
`;

export default {
  Field,
  DatePicker,
  Label,
  LabelWithIcon,
  Title,
  Tooltip,
  Wrapper,
  Sidebar,
  FormikField,
  FieldContainer,
};
