import styled, { css } from 'styled-components';

import { getStyle } from 'modules/v2/editor/utils';

const Image = styled.div`
  position: absolute;
  overflow: hidden;

  ${({ isEditable, showGuides, isLoading, element }) => {
    const { top, left, width, height, textAlign, color } = getStyle(element);

    let effect;

    const dropback = css`
      background: rgba(0, 0, 0, 0.25);
    `;

    const guide = css`
      background: rgba(255, 255, 255, 0.25);
    `;

    const hover = css`
      &:hover {
        background: rgba(255, 255, 255, 0.25);
      }
    `;

    if (isLoading) {
      effect = dropback;
    } else if (isEditable) {
      effect = showGuides ? guide : hover;
    }

    return css`
      top: ${top};
      left: ${left};
      width: ${width};
      height: ${height};
      text-align: ${textAlign};
      color: ${color};

      cursor: ${isEditable ? 'pointer' : 'default'};

      ${showGuides &&
        css`
          background: rgba(255, 255, 255, 0.25);
        `};
      ${effect}
    `;
  }}
`;

const Text = styled.div`
  position: absolute;
  overflow: hidden;

  // Prevent text selection on double click
  user-select: none;

  ul {
    margin-left: 40px !important;
  }

  ${({ isEditable, showGuides, element }) => {
    const {
      top,
      left,
      width,
      height,
      fontFamily,
      fontSize,
      textAlign,
      color,
      rotationAngle,
    } = getStyle(element);

    let effect;

    const guide = css`
      background: rgba(255, 255, 255, 0.25);
    `;

    const hover = css`
      &:hover {
        background: rgba(255, 255, 255, 0.25);
      }
    `;

    if (isEditable) {
      effect = showGuides ? guide : hover;
    }

    return css`
      top: ${top};
      left: ${left};
      width: ${width};
      height: ${height};

      font-family: ${fontFamily};
      font-size: ${fontSize};
      line-height: ${fontSize};
      text-align: ${textAlign};
      color: ${color};

      ${rotationAngle &&
        css`
          transform: rotate(${rotationAngle});
        `}

      transform-origin: left top;

      cursor: ${isEditable ? 'pointer' : 'default'};

      ${effect}
    `;
  }}
`;

export default { Image, Text };
