import { STATUS_INIT } from 'store/status';

export const links = {
  data: null,
  status: STATUS_INIT,
};

export default {
  links,
};
