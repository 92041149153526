import { useQuery } from 'react-query';
import { ReadOutlined } from '@ant-design/icons';

import { getAppConfig } from 'modules/api';
import { getRouteReferralMarketingMagazineEditor } from 'modules/dashboard/routes/navigation';
import Card from 'modules/dashboard/components/Card';

import S from './styles';

const MagazineContainer = () => {
  const { data: appConfigData, isLoading: isAppConfigLoading } = useQuery(
    'getAppConfig',
    getAppConfig,
  );

  const { MONTH, YEAR, RM_COVER_IMAGE } = appConfigData || {};

  return (
    <S.Container>
      <S.Title>Magazine</S.Title>
      <S.FlexWrap>
        {isAppConfigLoading && (
          <S.MagazineContainer>
            <S.ImageWrapper>
              <S.SkeletonImage active />
            </S.ImageWrapper>
            <S.Description>
              <S.SkeletonInput active block />
            </S.Description>
          </S.MagazineContainer>
        )}
        {!isAppConfigLoading && (
          <S.MagazineContainer>
            <S.ImageWrapper href={getRouteReferralMarketingMagazineEditor(MONTH, YEAR)}>
              <img src={RM_COVER_IMAGE} alt="magazine" />
            </S.ImageWrapper>
            <S.Description>{`${MONTH} ${YEAR}`}</S.Description>
          </S.MagazineContainer>
        )}

        <Card
          icon={<ReadOutlined />}
          title="Magazine"
          description="Create your personally branded magazine to generate more referrals from your sphere."
          buttonIsActive
          buttonText="View Magazine"
          link={getRouteReferralMarketingMagazineEditor(MONTH, YEAR)}
          width="220px"
          height="200px"
        />
      </S.FlexWrap>
    </S.Container>
  );
};

export default MagazineContainer;
