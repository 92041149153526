function SaveIcon() {
  return (
    // <svg
    //   width="100px"
    //   height="100px"
    //   viewBox="0 0 100 100"
    //   version="1.1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   fill="#fff"
    // >
    //   <path
    //     d="M50 0C22.3875 0 0 22.3875 0 50s22.3875 50 50 50 50-22.3875 50-50S77.6125 0 50 0zm-5.2083333 72.05l-18.75-18.1833333 7.7375-7.7416667 11.0125 10.4416667 23.5125-24.1 7.7375 7.7375-31.25 31.8458333z"
    //     stroke="none"
    //     strokeWidth="1"
    //   />
    // </svg>
    <svg
      id="SaveIcon"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="check-circle"
      className="svg-inline--fa fa-check-circle fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
    </svg>
  );
}

export default SaveIcon;
