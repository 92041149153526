import * as color from 'modules/v2/common/theme/color';

import styled from 'styled-components';

const Description = styled.strong`
  color: ${color.GreyText};
`;

export default {
  Description,
};
