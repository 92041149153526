import { useHistory, useParams } from 'react-router';
import { ReactNode, useEffect } from 'react';
import { getData, validateToken } from 'modules/auth';
import { getRoutePlusPlansLogin, getRoutePlusPlansReview } from '../routes/navigation';
import { AVAILABLE_PLUS_PLANS, PLANS_ROUTE } from '../routes/constants';
import { Header, Footer } from '../../landingPages/components';
import S from './styles';

const PlusPlansRoute = (props: { children: ReactNode }) => {
  const history = useHistory();
  const {
    planType,
  }: {
    planType: string | undefined;
  } = useParams();
  const { children } = props;

  const checkAuth = () => {
    const { token, userData, refreshToken, admin } = getData();
    const isLogged = !!token && !!userData && !!refreshToken && !admin;
    const isValid = validateToken(token) && validateToken(refreshToken) && isLogged;

    const plan =
      !planType && !AVAILABLE_PLUS_PLANS.includes(planType) ? AVAILABLE_PLUS_PLANS[0] : planType;

    if (history.action !== 'POP') {
      return null;
    }

    if (!isValid) {
      return history.push(getRoutePlusPlansLogin(plan));
    }

    if (PLANS_ROUTE.includes(history.location.pathname)) {
      return history.push(getRoutePlusPlansReview(plan));
    }

    return null;
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <S.MainLayout>
      <Header />
      <S.Content>{children}</S.Content>
      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>
    </S.MainLayout>
  );
};

export default PlusPlansRoute;
