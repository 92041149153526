import { replace } from 'connected-react-router';
import { put, call, takeEvery } from 'redux-saga/effects';

import { removeItem } from 'modules/dashboard/utils/legacy';
import { APP_RESET } from 'store/constants';

import { AUTH_SIGNOUT_REQUEST } from 'modules/auth/store/constants';
import { getRouteLogin } from 'modules/dashboard/routes/navigation';

export function* onSignOut() {
  yield call(removeItem, 'refresh_token');
  yield call(removeItem, 'token');
  yield call(removeItem, 'userData');
  yield call(removeItem, 'upgrade_plan_event:click-upgrade-plan');
  yield call(removeItem, 'upgrade_plan_event:abandon-upgrade-plan');
  yield call(removeItem, 'upgrade_plan_event:success-upgrade-plan');

  yield put({
    type: APP_RESET,
  });

  const location = getRouteLogin();

  // Use replace to avoid returning to the same URL with back button
  yield put(replace(location));
}

export function* watchSignOut() {
  yield takeEvery(AUTH_SIGNOUT_REQUEST, onSignOut);
}
