import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';
import { Button, Select as BaseSelect } from 'antd';
import { EditIcon, DownloadIconBadge, DotIconBadge } from 'modules/v2/common/components/SvgIcon';

const Status = styled.span`
  text-transform: capitalize;
`;
const DashboardSubTitle = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 13px;
`;
const FirstCol = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SecondCol = styled.div`
  margin-right: 22px;
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LeadName = styled.div`
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
`;
const LeadValue = styled.div`
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  margin-left: 5px;
  color: ${color.Black};
  opacity: 1;
`;
const FilterBy = styled.div`
  font-size: 16px;
  padding-right: 12px;
  text-align: right;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
`;

const Title = styled.h3`
  font-size: 24px;
  padding-top: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 46px;
  color: ${color.White};
  font-family: 'Roboto', sans-serif;
`;

const ContentTitle = styled(Title)`
  color: ${color.Black};
  margin-top: 5px;
`;

const TemplateButton = styled(Button)`
  font-weight: normal !important;
  color: ${color.SkyBlue};
  text-align: right;
  svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
    font-size: 14px;
  }
`;
const TemplateWrapper = styled.div`
  max-width: 710px;
  margin: 20px auto;
  border-radius: 2px;
  border: 1px solid ${color.GreyBorder};
  padding: 20px;

  img {
    margin: 10px auto;
    max-width: 100%;
  }
`;

const Select = styled(BaseSelect)`
  .ant-select-selector {
    border-radius: 6px !important;
    border: 1px solid ${color.neutral200} !important;
    max-height: 44px;

    overflow: auto;

    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: ${color.neutral200};
    }
    ::-webkit-scrollbar-thumb {
      background: ${color.neutral100};
      border-radius: 4px;
    }
  }

  .ant-select-selection-overflow {
    max-height: 44px;
  }

  .ant-select-selection-item {
    border-radius: 6px;
  }

  .ant-select-item .ant-select-item-option {
    border-radius: 6px;
  }
`;

const EditButton = styled.button`
  background: #f5f5f5;
  border: none;
  cursor: pointer;
  border-radius: 20%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MyEditIcon = styled(EditIcon)`
  font-size: 10px;
  color: #4b5563;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const PinIconWrapper = styled.div`
  margin-left: 5px;
  color: ${color.orange400};
  fill: ${color.orange400} !important;
  background-color: none;
  background: none;
`;

const Badge = styled.div`
  display: inline-flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  background: #fff;
  color: #000;
  font-size: 12px;
`;

const BadgeContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;

const DownloadIcon = styled(DownloadIconBadge)`
  width: 12px;
  height: 12px;
  fill: #3ab886;
  margin-right: 4px;
`;

const DotIcon = styled(DotIconBadge)`
  font-size: 20px;
  fill: #3ab886;
  margin-right: 4px;
`;

const LeadPhone = styled.span`
  color: #555;

  input {
    border: none;
    background-color: transparent;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    pointer-events: none; /* Previne interação do usuário */
    width: 100%; /* Garante que o input ocupe o espaço necessário */
  }
`;

export default {
  Status,
  DashboardSubTitle,
  Title,
  ContentTitle,
  LeadName,
  LeadValue,
  MainContain,
  FilterBy,
  FirstCol,
  SecondCol,
  TemplateButton,
  TemplateWrapper,
  Select,
  EditButton,
  MyEditIcon,
  NameContainer,
  PinIconWrapper,
  Badge,
  BadgeContainer,
  DownloadIcon,
  DotIcon,
  LeadPhone,
};
