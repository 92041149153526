function getDefaultProps() {
  return {
    width: '100',
    height: '100',
    fill: '#FFF',
  };
}

function AlertLarge() {
  return (
    //   <svg
    //     id="alertred"
    //     width={getDefaultProps().width}
    //     height={getDefaultProps().height}
    //     fill={getDefaultProps().fill}
    //     xmlns="http://www.w3.org/2000/svg"
    //   >

    // <path d="M70.713 0H29.288L0 29.288v41.42L29.288 100h41.42L100 70.713V29.288L70.713 0zm-24.88 20.833h8.334V62.5h-8.334V20.833zM50 80.208A5.21 5.21 0 0 1 44.792 75 5.21 5.21 0 0 1 50 69.792 5.21 5.21 0 0 1 55.208 75 5.21 5.21 0 0 1 50 80.208z" fill="#000" fill-rule="nonzero"/>
    //   </svg>
    <svg
      id="alert"
      width={getDefaultProps().width}
      height={getDefaultProps().height}
      fill={getDefaultProps().fill}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="exclamation-triangle"
      className="svg-inline--fa fa-exclamation-triangle fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
    </svg>
  );
}

export default AlertLarge;
