import { useState } from 'react';
import { useFormikContext } from 'formik';
import { ConfirmModal } from 'modules/v2/common/components';

import { NavBar as BaseNavBar } from 'modules/v2/nav/components';

const NavBar = ({ onCampaignStatusUpdate, onCancel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { submitForm, dirty, isValid, values } = useFormikContext();

  const { status, id } = values;
  const disabledSubmit = !isValid || !dirty;
  const notStoppedCampaign = status !== 'stopped';
  const disabledUpdateCampaign = disabledSubmit || notStoppedCampaign;
  const disableStopCampaign = status !== 'active';

  const handleStopCampaign = () => {
    setIsOpen(true);
  };

  const handleStatusUpdate = (newStatus) => () => {
    onCampaignStatusUpdate({ id, status: newStatus });
  };

  const handleStopCampaignConfirm = () => {
    setIsOpen(false);

    handleStatusUpdate('stopped')();
  };

  return (
    <BaseNavBar
      fixed
      sideContent={
        <>
          <BaseNavBar.Button theme="secondary" onClick={onCancel}>
            Cancel
          </BaseNavBar.Button>

          <BaseNavBar.Button disabled={disableStopCampaign} onClick={handleStopCampaign}>
            Stop Campaign
          </BaseNavBar.Button>

          <BaseNavBar.Button disabled={!disableStopCampaign} onClick={handleStatusUpdate('active')}>
            Activate Campaign
          </BaseNavBar.Button>

          <BaseNavBar.Button disabled={disabledUpdateCampaign} onClick={submitForm}>
            Update Campaign
          </BaseNavBar.Button>

          <ConfirmModal
            isOpen={isOpen}
            title="Stop Campaign"
            text="Stop Campaign"
            confirmLabel="Cancel"
            cancelLabel="Continue"
            hasCloseButton={false}
            maskClosable={false}
            width="500px"
            onCancel={handleStopCampaignConfirm}
            onConfirm={() => setIsOpen(false)}
          />
        </>
      }
    />
  );
};

export default NavBar;
