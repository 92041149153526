import { get, find, map, omit, pick } from 'lodash-es';

const formatElements = (elements) =>
  map(elements, (element) => {
    const fields = omit(element, ['rawValue']);

    if (element.rawValue) {
      fields.rawValue = JSON.stringify(element.rawValue);
    }

    return fields;
  });

const formatBookpage = (bookpage) =>
  map(bookpage, (page) => {
    const fields = pick(page, ['_id', 'status', 'align', 'content']);
    const elements = get(page, 'elements', []);

    return {
      ...fields,
      elements: formatElements(elements),
    };
  });

const formatTemplate = (templates) => {
  const template = find(templates, ({ isActive }) => isActive);
  const fields = pick(template, [
    '_id',
    'name',
    'displayName',
    'type',
    'thumbnail',
    'thumbnail_small',
  ]);
  const bookpage = get(template, 'bookpage', []);

  return {
    ...fields,
    bookpage: formatBookpage(bookpage),
  };
};

const formatSection = (section) => {
  const fields = pick(section, ['_id', 'name', 'displayName', 'type']);
  const status = get(section, 'currentStatus');
  const templates = get(section, 'templates', []);

  return {
    ...fields,
    status,
    template: formatTemplate(templates),
  };
};

export const formatDraftForSaving = (bookId, draftId, draftName, section) => ({
  book_id: bookId,
  draft_id: draftId,
  name: draftName,
  milestone: 'draft',
  section: formatSection(section),

  // TODO: Remove! Currently, it's necessary because of the API
  thumbnail: {
    url: '',
  },
});

export const formatSectionsForSaving = (sections) => {
  const fields = ['_id', 'order', 'type', 'currentStatus'];
  const mapper = (section) => pick(section, fields);

  return map(sections, mapper);
};
