import styled from 'styled-components';

import { PrimaryButton as BaseButton } from 'modules/common/components';

const CardWrap = styled.span`
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
`;

const PrimaryButton = styled(BaseButton)`
  margin: 0 !important;
`;

export default {
  CardWrap,
  PrimaryButton,
};
