import styled from 'styled-components';
import { DatePicker as DatePickerBase, Layout } from 'antd';
import { Tooltip as TooltipBase } from 'modules/v2/common/components';
import * as color from 'modules/v2/common/theme/color';
import { Field as BaseField } from 'formik';

import { TextInput } from 'v2/common/AtomicDesign/atoms';
import { BounceRateMetric, LightGray } from 'modules/v2/common/theme/color';

const Wrapper = styled.div`
  input {
    width: 100%;
  }
`;

const Title = styled.h4`
  font-size: 16px !important;
`;

const Label = styled.label`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 12px;
  display: block;
`;

const DatePicker = styled(DatePickerBase)`
  width: 100%;
  margin-bottom: 20px;
`;

const LabelWithIcon = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tooltip = styled(TooltipBase)``;

const FieldContainer = styled.div`
  .ant-checkbox-wrapper,
  label,
  .ant-form-item,
  .ant-select {
    width: 100%;
  }
`;

const FormikField = styled(BaseField).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  border-radius: 3px;
  background-color: ${color.White};
`;

const Field = styled(TextInput)``;

const SelectField = styled(FormikField)`
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px !important;

  .ant-select-selection-search {
    box-shadow: none;
  }

  .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    justify-items: center;
    height: 38px !important;
    padding: 5px 14px !important;
  }

  .ant-select-selection-search-input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-select-selector {
    border: ${LightGray} solid 1px;
    outline: ${LightGray} solid 1px !important;

    &:focus {
      border: ${BounceRateMetric} solid 1px !important;
      outline: ${BounceRateMetric} solid 1px !important;
    }

    &:active {
      border: ${BounceRateMetric} solid 1px !important;
      outline: ${BounceRateMetric} solid 1px !important;
    }
  }
`;

export default {
  Field,
  SelectField,
  DatePicker,
  Label,
  LabelWithIcon,
  Title,
  Tooltip,
  Wrapper,
  FormikField,
  FieldContainer,
};
