import { forwardRef } from 'react';

import S from './styles';

const Scrollbars = forwardRef(({ renderInLayers, ...props }, ref) => (
  <S.Scrollbars
    ref={ref}
    autoHide
    hideTracksWhenNotNeeded
    renderInLayers={renderInLayers}
    {...props}
  />
));

export default Scrollbars;
