import {
  GET_ALL_SECTIONS,
  SELECT_DROPDOWN,
  GET_ALL_BOOKSDETAILS,
  EDIT_THIS_BOOK,
  ADD_NEW_SECTION,
  UPDATE_TEMPLATE,
  ADD_NEW_BOOK,
  FETCH_TEMPLATE_ELEMENTS,
  ADMIN_SELECT_SECTION,
  ADD_NEW_TEMPLATE,
  REORDER_BOOK_SECTIONS,
  UPDATE_BOOK_SECTIONS_ORDER,
  GET_SECTION_BY_ID,
  GET_TEMPLATES_BY_TYPE,
  GET_TEMPLATE_BY_ID,
  GET_ALL_CATEGORIES,
  UPDATE_FIELD_VALUE,
  UPDATE_SUGGESTED_BOOKS,
  SUBMIT_BOOK,
  UPDATE_TEMPLATE_FIELD,
  SUBMIT_SECTION,
  GET_FILTERED_SECTIONS,
  SHOW_SUGGESTED_SECTIONS,
  CREATE_BOOKPAGE,
  UPDATE_BOOKPAGE,
  ADD_TO_GLOBAL_SECTIONS,
  CATEGORY_CHECKBOX,
  GET_FILTERED_BOOKS,
  UPDATE_BOOK_STATUS,
  PERSIST_SECTIONS,
  GET_PERSIST_SECTIONS,
  CLEAR_BOOK_UPDATING_STATUS,
  GET_BOOK_LAYOUTS,
  SAVE_BOOK,
  GET_ALL_FONTS,
  ADD_NEW_LAYOUT,
  RESET_LAYOUT,
  BOOK_VALIDATIONS,
  UPDATE_ACTIVE_BOOKPAGE_ELEMENT,
  SAVE_BOOKPAGE_ELEMENT,
} from '../constants';
import { updateOrder, getItem } from 'modules/dashboard/utils/legacy';

const initialState = {
  loading: false,
  loaded: false,
  books: {
    loading: false,
    loaded: false,
    data: [],
    suggestions: [],
  },
  layouts: {
    loading: false,
    loaded: false,
    data: [],
  },
  sections: {
    loading: false,
    loaded: false,
    data: [],
  },
  templates: {
    data: [],
    allData: [],
    loading: false,
    loaded: false,
  },
  fonts: {
    data: [],
    loading: false,
    loaded: false,
  },
  selectedSections: [],
  selectedSection: null,
  selectedCategories: [],
  elements: [],
  newCategory: {},
  selectedSectionId: null,
  selectedTemplateId: null,
  selectedBookPageId: null,

  newSectionDetails: null,
  selectedBook: null,
  saving: false,
  saved: true,
  bookpage: {},
  sectionInputField: '',
  globalSectionList: {
    data: [],
    loading: false,
    loaded: false,
  },
  updatingBook: false,
  persistedSectionOrder: {},
  selectedBookTitle: '',
  selectedSectionTitle: '',
  addLayout: {
    data: {},
    loading: false,
    loaded: false,
  },
  bookValidation: {
    loading: false,
    loaded: false,
    data: {},
  },
  saveBook: {
    data: {},
    loading: false,
    loaded: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    /*
 CASE FOR ADMIN BOOK LISTING
 */

    case `${GET_ALL_BOOKSDETAILS}_REQUEST`: {
      return {
        ...state,
        books: {
          loading: true,
          loaded: false,
        },
      };
    }

    case GET_ALL_BOOKSDETAILS:
      let books = action.payload.books.map((book) => {
        return {
          ...book,
          status: book.status.toLowerCase(),
          category_name: state.newCategory[book.category],
          key: book._id,
        };
      });
      return {
        ...state,
        books: {
          loading: false,
          loaded: true,
          data: books,
          suggestions: books,
        },
      };

    case `${GET_ALL_BOOKSDETAILS}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case `${GET_ALL_CATEGORIES}_REQUEST`: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case GET_ALL_CATEGORIES:
      let newCategory = {};
      let categories = action.payload.categories.map((category) => {
        return { ...category, isChecked: false };
      });
      categories.forEach((category) => {
        newCategory[category._id] = category.name;
      });
      return {
        ...state,
        categories,
        newCategory,
        loading: false,
        loaded: true,
      };

    case `${GET_ALL_CATEGORIES}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case `${GET_FILTERED_BOOKS}_REQUEST`: {
      return {
        ...state,
        books: {
          ...state.books,
          loading: true,
          loaded: false,
        },
      };
    }

    case GET_FILTERED_BOOKS: {
      let books = action.payload.books.map((book) => {
        return {
          ...book,
          category_name: state.newCategory[book.category],
        };
      });
      return {
        ...state,
        books: {
          loading: false,
          loaded: true,
          data: books,
          suggestions: books,
        },
      };
    }

    case `${GET_FILTERED_BOOKS}_FAILURE`: {
      return {
        ...state,
        books: {
          ...state.books,
          loading: true,
          loaded: true,
        },
      };
    }

    case UPDATE_BOOK_STATUS: {
      let books = state.books.data.map((book) => {
        if (action.data.bookId.indexOf(book._id) !== -1) {
          return { ...book, status: action.data.status };
        } else {
          return { ...book };
        }
      });

      return {
        ...state,
        books: {
          loading: false,
          loaded: true,
          data: books,
          suggestions: books,
        },
      };
    }

    case CATEGORY_CHECKBOX: {
      let updatedCategories = state.categories.map((category) => {
        if (category._id === action.category._id) {
          return { ...category, isChecked: !action.category.isChecked };
        } else {
          return category;
        }
      });
      return { ...state, categories: updatedCategories };
    }
    /*
 CASE FOR BOOK LISTING ENDS
 */

    case CLEAR_BOOK_UPDATING_STATUS:
      return {
        ...state,
        updatingBook: false,
      };
    case SELECT_DROPDOWN:
      const selected = state[action.data.model].find((item) => item._id === action.data.selected);
      const filtered = state[action.data.model].filter((item) => item._id !== action.data.selected);

      if (action.data.model === 'sections') {
        return {
          ...state,
          selectedSections: [...state.selectedSections, { ...selected, templates: [] }],
          [action.data.model]: filtered,
        };
      } else if (action.data.model === 'categories')
        return {
          ...state,
          selectedCategories: [selected],
          [action.data.model]: filtered,
        };
      else if (action.data.model === 'templates') {
        return {
          ...state,
          selectedSections: state.selectedSections.map((section) => {
            if (section.name === action.data.section) {
              return {
                ...section,
                templates: [...section.templates, selected],
              };
            }

            return section;
          }),
        };
      }

      // The block above does not include else statement.
      // Thus, if any of conditions were met, the default will be returned
      return state;

    case REORDER_BOOK_SECTIONS: {
      return {
        ...state,
        selectedSections: action.sections,
        selectedBook: {
          ...state.selectedBook,
          //  sections: action.sections,
          sectionOrder: action.sectionOrder,
        },
      };
    }

    case UPDATE_BOOK_SECTIONS_ORDER: {
      const filteredSections = state.sections.data.filter(
        (section) => action.payload.sections.findIndex((data) => data._id === section._id) === -1,
      );
      let sectionOrder = action.order;
      let orderedSectionIds = Object.keys(sectionOrder).sort(function (a, b) {
        return sectionOrder[a] - sectionOrder[b];
      });
      const orderedSections = orderedSectionIds
        .map((orderedSectionId) =>
          action.payload.sections.find((section) => section._id === orderedSectionId),
        )
        .filter((section) => section !== undefined);

      return {
        ...state,
        sections: { ...state.sections, data: filteredSections },
        selectedBook: { ...action.payload, sections: orderedSections },
      };
    }

    case PERSIST_SECTIONS:
      return {
        ...state,
        persistedSectionOrder: state.selectedBook.sectionOrder,
      };
    case GET_PERSIST_SECTIONS:
      let SectionOrder = state.persistedSectionOrder;
      let OrderedSectionIds = Object.keys(SectionOrder).sort(function (a, b) {
        return SectionOrder[a] - SectionOrder[b];
      });
      const OrdSections = OrderedSectionIds.map((orderedSectionId) =>
        state.selectedBook.sections.find((section) => section._id === orderedSectionId),
      ).filter((section) => section !== undefined);

      return {
        ...state,
        selectedBook: {
          ...state.selectedBook,
          sections: [...OrdSections],
        },
      };

    case UPDATE_SUGGESTED_BOOKS: {
      return {
        ...state,
        books: {
          ...state.books,
          suggestions: action.suggestions.map((suggestion) => {
            return { ...suggestion, status: suggestion.status.toLowerCase() };
          }),
        },
      };
    }
    case `${EDIT_THIS_BOOK}_REQUEST`: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case EDIT_THIS_BOOK:
      const book = action.payload.book;
      const getKeyByValue = action.getKeyByValue;
      let unOrderedSections = [];
      let sectionOrder = book.sectionOrder;
      let sectionList = book.sections;
      let order = { ...book.sectionOrder };
      sectionList = sectionList.filter((section) => {
        if (order && Object.keys(order).findIndex((order) => order === section._id) === -1) {
          return true;
        } else {
          delete order[section._id];
          return false;
        }
      });
      Object.keys(order).map((extraOrder) => delete sectionOrder[extraOrder]);

      if (sectionList.length > 0) {
        unOrderedSections = book.sections.filter(
          (section) =>
            Object.keys(book.sectionOrder).findIndex(
              (sectionOrderId) => sectionOrderId === section._id,
            ) === -1,
        );
        sectionOrder = updateOrder(book, unOrderedSections, sectionOrder, getKeyByValue);
      }
      let orderedSectionIds = Object.keys(sectionOrder).sort(function (a, b) {
        return sectionOrder[a] - sectionOrder[b];
      });
      const orderedSections = orderedSectionIds
        .map((orderedSectionId) =>
          book.sections.find((section) => section._id === orderedSectionId),
        )
        .filter((section) => section !== undefined);

      return {
        ...state,
        selectedBookId: book._id,
        selectedBook: {
          ...book,
          sections: orderedSections,
          sectionOrder,
          status: 'inActive',
        },
        selectedSections: book.sections,
        loading: false,
        loaded: true,
        selectedBookTitle: book.name,
      };
    case `${EDIT_THIS_BOOK}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case ADD_NEW_BOOK:
      return {
        ...state,
        books: {
          ...state.books,
          data: [...state.books.data, action.payload],
          suggestions: [
            ...state.books.suggestions,
            { _id: action.payload._id, name: action.payload.name },
          ],
        },
      };
    case `${SAVE_BOOK}_REQUEST`:
      return {
        ...state,
        saveBook: {
          data: {},
          loading: true,
          loaded: false,
        },
      };
    case SAVE_BOOK:
      return {
        ...state,
        selectedBookTitle: action.payload.name,
        saveBook: {
          data: action.payload,
          loading: false,
          loaded: true,
        },
        selectedBook: {
          ...state.selectedBook,
          description: action.payload.description,
        },
      };
    case `${SAVE_BOOK}_FAILURE`:
      return {
        ...state,
        saveBook: {
          data: {},
          loading: true,
          loaded: true,
        },
      };

    case `${GET_ALL_SECTIONS}_REQUEST`:
      return {
        ...state,
        globalSectionList: {
          ...state.globalSectionList,
          loading: true,
          loaded: false,
        },
      };
    case GET_ALL_SECTIONS:
      return {
        ...state,
        globalSectionList: {
          ...state.globalSectionList,
          data: action.payload.sections,
          loading: false,
          loaded: true,
        },
      };
    case `${GET_ALL_SECTIONS}_FAILURE`:
      return {
        ...state,

        sections: {
          ...state.sections,
          globalSectionList: {
            ...state.globalSectionList,
            loading: true,
            loaded: true,
          },
        },
      };
    case `${GET_SECTION_BY_ID}_REQUEST`:
      return {
        ...state,
        updatingBook: true,
      };

    case GET_SECTION_BY_ID:
      const sectionToAttach = action.payload.section;

      return {
        ...state,
        selectedBook: {
          ...state.selectedBook,
        },
        selectedSectionId: sectionToAttach._id,
        selectedSectionTitle: sectionToAttach.name,
        selectedSection: sectionToAttach,
      };
    case `${GET_SECTION_BY_ID}_FAILURE`:
      return {
        ...state,
        updatingBook: false,
      };

    case `${ADD_NEW_SECTION}_REQUEST`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case ADD_NEW_SECTION:
      return {
        ...state,
        loading: false,
        loaded: true,
        sections: {
          ...state.sections,
          data: [...state.sections.data, action.payload],
        },
      };
    case `${ADD_NEW_SECTION}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case ADMIN_SELECT_SECTION:
      const selectedSection = state.selectedBook.sections.find(
        (section) => section._id === action.sectionId,
      );
      return {
        ...state,
        selectedSectionId: action.sectionId,
        selectedSection: selectedSection,
      };

    case UPDATE_TEMPLATE:
      //Update template response
      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          name: action.payload.name,
        },
      };

    case FETCH_TEMPLATE_ELEMENTS:
      const elements = state.selectedSection.templates.find(
        (template) => template._id === action.templateId,
      ).bookpage[0].elements;

      return {
        ...state,
        elements,
        selectedBookPageId: action.bookpageId,
      };

    case ADD_NEW_TEMPLATE:
      //Add template response
      return {
        ...state,
      };

    case `${GET_TEMPLATES_BY_TYPE}_REQUEST`: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TEMPLATES_BY_TYPE:
      const FilteredTemplates = action.payload.templates.filter(
        (template) =>
          state.selectedSection.templates.findIndex(
            (attachedTemplate) => template._id === attachedTemplate._id,
          ) === -1,
      );
      return {
        ...state,
        templates: {
          data: FilteredTemplates,
          allData: action.payload.templates,
          loading: false,
          loaded: true,
        },
      };

    case `${GET_TEMPLATES_BY_TYPE}_FAILURE`: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case `${GET_TEMPLATE_BY_ID}_REQUEST`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_TEMPLATE_BY_ID:
      const templateToAttach = action.payload.template;
      /**
       * Find "content" type element to render as an active element on editor
       */
      const activeElement =
        templateToAttach.bookpage[0] &&
        templateToAttach.bookpage[0].elements &&
        templateToAttach.bookpage[0].elements.length > 0
          ? templateToAttach.bookpage[0].elements.find((element) => element.id === 'content')
          : [];
      return {
        ...state,
        loading: false,
        loaded: true,
        selectedTemplate: {
          ...templateToAttach,
          bookpage: templateToAttach.bookpage.map((bookpage) => {
            return {
              ...bookpage,
              elements: templateToAttach.bookpage[0].elements.map((element, index) => {
                if (state.selectedSection.type === 'Chapter' && element.id === 'title') {
                  let { defaultValue } = element;
                  const preHtml = `${defaultValue.substr(
                    0,
                    defaultValue.indexOf('CHAPTER'),
                  )}CHAPTER ${getItem('chapterNumber') ? getItem('chapterNumber') : ''}`;
                  const postWithTextHtml = `${defaultValue.substr(
                    defaultValue.indexOf('CHAPTER'),
                  )}`;
                  const postHtml = `${postWithTextHtml.substr(postWithTextHtml.indexOf('<'))}`;
                  return {
                    ...element,
                    defaultValue: `${preHtml}${postHtml}`,
                  };
                } else {
                  return { ...element };
                }
              }),
            };
          }),
          activeElement,
        },
      };

    case `${GET_TEMPLATE_BY_ID}_FAILURE`:
      return {
        ...state,
        loading: true,
        loaded: true,
      };

    case UPDATE_FIELD_VALUE:
      if (action.field === 'layout') {
        const layout = state.layouts.data.find((layout) => layout.id === action.value);
        return {
          ...state,
          selectedBook: {
            ...state.selectedBook,
            layout,
          },
        };
      } else {
        return {
          ...state,
          selectedBook: {
            ...state.selectedBook,

            [action.field]: action.value,
          },
        };
      }
    case UPDATE_TEMPLATE_FIELD:
      return {
        ...state,
        selectedSection: {
          ...state.selectedSection,
          [action.field]: action.value,
        },
      };

    case `${SUBMIT_BOOK}_REQUEST`:
      return {
        ...state,
        updatingBook: true,
      };

    case SUBMIT_BOOK:
      const { sections } = action.payload;
      const updatedSectionOrder = action.payload.sectionOrder;
      const sectionsData = state.sections.data.filter(
        (section) => action.payload.sections.findIndex((data) => data._id === section._id) === -1,
      );
      let orderedSectionIDs = Object.keys(updatedSectionOrder).sort((a, b) => {
        return updatedSectionOrder[a] - updatedSectionOrder[b];
      });
      const OrderedSections = orderedSectionIDs
        .map((orderedSectionId) => sections.find((section) => section._id === orderedSectionId))
        .filter((section) => section !== undefined);
      return {
        ...state,
        selectedBook: {
          ...state.selectedBook,
          sections: OrderedSections,
          sectionOrder: updatedSectionOrder,
        },
        sections: { ...state.sections, data: sectionsData },
        updatingBook: false,
        selectedBookTitle: state.selectedBook.name,
      };
    case `${SUBMIT_BOOK}_FAILURE`:
      return {
        ...state,
        updatingBook: false,
      };
    case `${SUBMIT_SECTION}_REQUEST`:
      return {
        ...state,
        saving: true,
        saved: false,
      };

    case SUBMIT_SECTION:
      //update global template list by excluding already attached templates
      const filteredTemplates =
        state.templates.allData &&
        state.templates.allData.filter(
          (template) =>
            action.payload.templates.findIndex(
              (attachedTemplate) => template._id === attachedTemplate._id,
            ) === -1,
        );
      return {
        ...state,
        saving: false,
        saved: true,
        selectedSection: {
          ...state.selectedSection,
          ...action.payload,
        },
        templates: {
          ...state.templates,
          data: filteredTemplates,
        },
        selectedBook: {
          ...state.selectedBook,
          sections: state.selectedBook.sections.map((section) => {
            if (section._id === action.payload.id) return action.payload;
            else return section;
          }),
        },
        selectedSectionTitle: action.payload.name,
      };
    case `${SUBMIT_SECTION}_FAILURE`:
      return {
        ...state,
        saving: true,
        saved: true,
      };
    case `${GET_FILTERED_SECTIONS}_REQUEST`:
      return {
        ...state,
        sections: {
          ...state.sections,
          loaded: false,
          loading: true,
        },
      };
    case GET_FILTERED_SECTIONS:
      return {
        ...state,
        sections: {
          ...state.sections,
          data: action.payload.sections,
          allData: action.payload.sections,
          loaded: true,
          loading: false,
        },
      };
    case `${GET_FILTERED_SECTIONS}_FAILURE`:
      return {
        ...state,
        sections: {
          ...state.sections,
          loaded: true,
          loading: true,
        },
      };
    case SHOW_SUGGESTED_SECTIONS:
      const filteredSuggestedSections = action.suggestions.filter(
        (section) =>
          state.selectedBook.sections.findIndex((data) => data._id === section._id) === -1,
      );
      return {
        ...state,
        sections: {
          ...state.sections,
          data: filteredSuggestedSections,
        },
        sectionInputField: action.sectionInputField,
      };
    case ADD_TO_GLOBAL_SECTIONS:
      const filteredSections = state.sections.data.filter(
        (section) =>
          state.selectedBook.sections.findIndex((data) => data._id === section._id) === -1,
      );
      return {
        ...state,
        sections: {
          ...state.sections,
          data: [...filteredSections, action.section],
        },
      };
    case `${CREATE_BOOKPAGE}_REQUEST`:
      return {
        ...state,
        saving: true,
        saved: false,
      };
    case CREATE_BOOKPAGE:
      return {
        ...state,
        bookpage: action.payload,
        saving: false,
        saved: true,
      };
    case `${CREATE_BOOKPAGE}_FAILURE`:
      return {
        ...state,
        saving: true,
        saved: true,
      };

    case `${UPDATE_BOOKPAGE}_REQUEST`:
      return {
        ...state,
        saving: true,
        saved: false,
      };

    case UPDATE_BOOKPAGE:
      return {
        ...state,
        bookpage: action.payload,
        saving: false,
        saved: true,
      };

    case `${UPDATE_BOOKPAGE}_FAILURE`:
      return {
        ...state,
        saving: true,
        saved: true,
      };

    case `${GET_BOOK_LAYOUTS}_REQUEST`: {
      return {
        ...state,
        layouts: {
          ...state.layouts,
          loading: true,
          loaded: false,
        },
      };
    }

    case GET_BOOK_LAYOUTS: {
      return {
        ...state,
        layouts: {
          ...state.layouts,
          loading: false,
          loaded: true,
          data: action.payload.layouts,
        },
      };
    }

    case `${GET_BOOK_LAYOUTS}_FAILURE`: {
      return {
        ...state,
        layouts: {
          ...state.layouts,
          loading: true,
          loaded: true,
        },
      };
    }

    case `${GET_ALL_FONTS}_REQUEST`: {
      return {
        ...state,
        fonts: {
          ...state.fonts,
          loading: true,
          loaded: false,
        },
      };
    }

    case GET_ALL_FONTS: {
      return {
        ...state,
        fonts: {
          ...state.fonts,
          loading: false,
          loaded: true,
          data: action.payload.fonts,
        },
      };
    }

    case `${GET_ALL_FONTS}_FAILURE`: {
      return {
        ...state,
        fonts: {
          ...state.fonts,
          loading: true,
          loaded: true,
        },
      };
    }
    case `${ADD_NEW_LAYOUT}_REQUEST`:
      return {
        ...state,
        addLayout: {
          ...state.addLayout,
          loading: true,
          loaded: false,
        },
      };
    case ADD_NEW_LAYOUT:
      return {
        ...state,
        addLayout: {
          ...state.addLayout,
          data: action.payload.layouts,
          loading: false,
          loaded: true,
        },
      };
    case `${ADD_NEW_LAYOUT}_FAILURE`:
      return {
        ...state,
        addLayout: {
          ...state.addLayout,
          loading: true,
          loaded: true,
        },
      };
    case `${BOOK_VALIDATIONS}_REQUEST`:
      return {
        ...state,
        bookValidation: {
          ...state.bookValidation,
          loading: true,
          loaded: false,
        },
      };
    case BOOK_VALIDATIONS:
      return {
        ...state,
        bookValidation: {
          ...state.bookValidation,
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
    case `${BOOK_VALIDATIONS}_FAILURE`:
      return {
        ...state,
        bookValidation: {
          ...state.bookValidation,
          loading: true,
          loaded: true,
        },
      };

    case RESET_LAYOUT:
      return {
        ...state,
        addLayout: {
          data: {},
          loading: false,
          loaded: true,
        },
      };

    case `${UPDATE_ACTIVE_BOOKPAGE_ELEMENT}_REQUEST`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case UPDATE_ACTIVE_BOOKPAGE_ELEMENT:
      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          activeElement: action.element,
        },
      };

    case `${UPDATE_ACTIVE_BOOKPAGE_ELEMENT}_FAILURE`:
      return {
        ...state,
        loading: true,
        loaded: true,
      };

    case `${SAVE_BOOKPAGE_ELEMENT}_REQUEST`:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case SAVE_BOOKPAGE_ELEMENT:
      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          bookpage: state.selectedTemplate.bookpage.map((bookpage) => {
            return {
              ...bookpage,
              elements: bookpage.elements.map((element) => {
                if (element.id === action.element.id) {
                  return {
                    ...element,
                    ...action.element,
                  };
                } else {
                  return element;
                }
              }),
            };
          }),
        },
      };

    case `${SAVE_BOOKPAGE_ELEMENT}_FAILURE`:
      return {
        ...state,
        loading: true,
        loaded: true,
      };

    default:
      return state;
  }
};
