import { get } from 'lodash-es';

export const draftModuleSelector = (state) => state.draftModule;

export const getDraftState = (state) => state.draft;

export const getDraft = (draft) => draft.data;

export const getDraftId = (draft) => get(draft, 'draftId', '');

export const getDraftMilestone = (draft) => get(draft, 'milestone');

export const getDraftName = (draft) => get(draft, 'name');

export const getDraftUpdatedAt = (draft) => get(draft, 'updatedAt');
