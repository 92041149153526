import { flow } from 'lodash-es';
import { views } from 'modules/v2/pages/Dentist/OrderGuideModal/constants';
import { checkStep, convertStepToView, convertViewToStep, getViewTitle } from './utilities';

import S from './styles';

const Wizard = ({ view, fields, onChange }) => {
  if (view === views.CONFIRMATION) {
    return null;
  }

  const handleChange = flow(convertStepToView, onChange);
  const handleStep = checkStep(fields);

  const current = convertViewToStep(view);
  const items = [views.GUIDEDETAILS, views.CHECKOUT, views.ADDRESS_LIST, views.REVIEW];

  return (
    <S.Steps size="small" type="navigation" current={current} onChange={handleChange}>
      {items.map((next) => {
        const { status, disabled } = handleStep(view, next);
        return <S.Step key={next} title={getViewTitle(next)} status={status} disabled={disabled} />;
      })}
    </S.Steps>
  );
};

export default Wizard;
