import styled from 'styled-components';
import { Dropdown as BaseDropdown, Menu as BaseMenu } from 'antd';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Dropdown = styled(BaseDropdown)`
  display: flex;
  align-items: center;
  cursor: pointer;

  &.ant-dropdown-trigger {
    height: 64px;

    :hover {
      border-bottom: 3px solid ${color.PrimaryColor};
    }
    transition: 0.3s ease all;
  }

  &.ant-dropdown-open {
    border-bottom: 3px solid ${color.PrimaryColor};

    span {
      color: ${color.ActiveMenuItemColor} !important;
    }
    .anticon {
      position: relative;
      transform: rotate(180deg);
    }
  }
  .anticon {
    transition: 0.3s ease all;
  }
`;

const Button = styled.div`
  display: flex;
  color: ${color.White};
  font-weight: 500;
  height: 66px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: ${font.MontserratFont};
  border-radius: 0;
  padding: 8px 15px;
  align-items: center;

  :hover {
    border-bottom: 3px solid ${color.PrimaryColor};
    transition: 0.2s ease;
  }

  &:hover,
  &:focus {
    color: ${color.ActiveMenuItemColor} !important;
  }
`;

Button.Label = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
`;

Button.Icon = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0 0 10px;

  .anticon {
    font-size: 18px !important;

    svg {
      margin-bottom: 2px;
    }

    :focus,
    :active,
    &.active {
      color: ${color.ActiveMenuItemColor} !important;
    }
  }

  transition: all 0.5s linear;
`;

const Menu = styled(BaseMenu)`
  ::after {
    left: auto !important;
    right: 6px;
  }
`;

Menu.Item = styled(BaseMenu.Item)`
  display: flex !important;
  align-items: center;
  min-height: 52px;
  padding: 2px 15px;
  .ant-dropdown-menu-item {
    padding: 10px 20px;
  }

  span {
    font-weight: normal;
  }

  span.anticon svg {
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    font-size: 24px;
    fill: ${color.GreyIcon};
  }

  :active,
  :hover {
    .ant-dropdown-menu-item {
      background-color: #1b2f73;
    }

    span.anticon svg {
      transition: 0.2s ease;
    }
    transition: all 0.2s ease;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }
`;

const AvatarWrapper = styled.div`
  svg {
    path {
      fill: ${color.White};
    }
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
  }
`;

const UserName = styled.span`
  padding: 0 5px 0 10px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  text-transform: capitalize;
  font-family: ${font.RobotoFont};
  overflow: hidden;
  font-weight: normal;
  max-width: 150px;
  letter-spacing: 1px;
`;

const DropdownAvatar = styled.div`
  svg {
    path {
      fill: ${color.GreyFormBorder};
    }
    height: 40px;
    width: 40px;
    display: inline-block;
    vertical-align: middle;
  }
`;

const Details = styled.div`
  display: inline-block;
  padding: 0 0 15px 15px;
  margin: 15px 0 0;
`;

Details.Name = styled.div`
  line-height: 20px;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

Details.Email = styled.div`
  display: block;
  padding: 5px 0 0;
  width: 190px;

  color: ${color.UserMenuEmailText};
  line-height: 14px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default {
  Dropdown,
  Button,
  Menu,
  AvatarWrapper,
  UserName,
  DropdownAvatar,
  Details,
};
