import styled from 'styled-components';
import { Input as BaseInput } from 'antd';
import * as color from 'modules/common/theme/color';
import { PrimaryButton } from 'modules/common/components';

const Container = styled.div`
  background-color: ${color.White};
  th {
    font-weight: bold;
  }
  tr {
    border: 1px solid ${color.LightDullGray};
  }
`;

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.h3`
  font-size: 24px !important;
  line-height: 46px !important;
  color: ${color.Black};
  margin-top: 10px !important;
  font-weight: bold !important;
  padding-bottom: 15px !important;
`;

const CheckTitle = styled.h3`
  font-size: 20px !important;
  line-height: 46px !important;
  color: ${color.Black};
  margin-top: 10px !important;
  font-weight: bold !important;
  padding-bottom: 15px !important;
`;

const ToggleAllCheck = styled.div`
  display: block;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background: ${color.LightBlue};
    border: 1px solid ${color.LightBlue};
  }
  .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
    border: 2px solid ${color.White};
    border-top: 0;
    border-left: 0;
  }
`;

const AppsCheck = styled.div`
  display: block;
  flex-direction: column;
  border: 1px solid ${color.GrayBorderColor};
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background: ${color.LightBlue};
    border: 1px solid ${color.LightBlue};
  }
  .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
    border: 2px solid ${color.White};
    border-top: 0;
    border-left: 0;
  }
`;

const inputStyle = `
  height: 45px;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  height: 45px;
  width: 400px;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: ${color.GreySelectDd};

  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px ${color.FormBoxShadowColor};
  }
  &:disabled{
    border: 1px solid ${color.DarkWhite};
  }
  
`;

const Input = styled(BaseInput)`
  ${inputStyle}
`;

const SecondaryButton = styled(PrimaryButton)`
  background-color: ${color.SkyBlue} !important;
  margin: 10px 0 !important;

  &:hover,
  &:active,
  &:focus {
    background-color: ${color.SideBarActiveText} !important;
  }

  :disabled {
    background: ${color.ViewBlueDisabledButton} 0% 0% no-repeat padding-box !important;

    &:active,
    &:focus {
      background-color: ${color.ViewBlueDisabledButtonFocus} !important;
    }
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  font-weight: 600 !important;
`;
const AppsContainer = styled.div`
  padding-top: 20px;
  font-size: 20px;
  line-height: 28px;
`;
const AppsList = styled.div`
  margin-bottom: 8px;
  border-width: 1px;
  padding: 8px;
`;
const AppTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 8px;
  line-height: 28px;
`;
const AppCheck = styled(AppsCheck)`
  border: none !important;
`;
const AppHeading = styled.div`
  padding-top: 20px;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const App = styled.div`
  border: 1px solid ${color.GrayBorderColor};
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12.8px;
`;

const SuccessToast = styled.div`
  background-color: ${color.LightGreen};
  padding: 16px;
  margin-top: 16px;
`;
export default {
  Container,
  MainContain,
  Title,
  FieldGroup,
  FieldControl,
  Input,
  SecondaryButton,
  CheckTitle,
  AppsCheck,
  ToggleAllCheck,
  AppsContainer,
  AppsList,
  AppTitle,
  AppCheck,
  App,
  AppHeading,
  SuccessToast,
};
