//

import { pluralize } from 'modules/common/utils/pluralize';
import { Loader, Scrollbars } from 'modules/common/components';
import { CheckoutForm } from 'modules/editor/components';
import { MODAL_HEIGHT } from 'modules/publish/constants';
import { MIN_NUMBER_OF_PRINTS } from 'modules/editor/components/CheckoutForm/constants';

import S from 'modules/publish/styles';

const CheckoutView = ({
  loading,
  numberOfPrints,
  isDigital,
  credits,
  onSubmit,
  isTrialCustomer,
}) => {
  if (loading) {
    return <Loader height={MODAL_HEIGHT} title="Checking credits" />;
  }

  const intCredits = credits !== undefined ? Number(credits) : 0;
  const intNumberOfPrints = numberOfPrints === null ? intCredits : numberOfPrints;

  const plural = pluralize('credit', 'credits', intCredits);

  const fields = {
    numberOfPrints: intNumberOfPrints,
    isDigital,
  };

  return (
    <Scrollbars style={{ height: MODAL_HEIGHT }}>
      <S.ViewTitle>Select Book Quantity</S.ViewTitle>
      <S.ViewSubTitle fewCredits={intCredits < 8}>
        You have <strong>{plural}</strong> left
        <br />
        1 Credit = 1 Book
        <br /> <br />
        Minimum of <strong>{MIN_NUMBER_OF_PRINTS} books</strong> per order is required.
      </S.ViewSubTitle>
      <S.Content>
        {isTrialCustomer && intCredits < 8 ? (
          <S.ContentContainer>
            <S.ContentDescription>
              Please check with support to learn more about Authorify plans.
            </S.ContentDescription>
          </S.ContentContainer>
        ) : (
          <CheckoutForm fields={fields} credits={intCredits} onSubmit={onSubmit} />
        )}
      </S.Content>
    </Scrollbars>
  );
};

export default CheckoutView;
