import { Space, Button, Divider } from 'antd';
import { useMutation } from 'react-query';

import { deletePaymentProfile, setDefaultPaymentProfile } from 'modules/api';
import { notification } from 'modules/v2/common/utils';
import S from 'modules/v2/components/Payments/CardItem/styles';
import cardDetails from 'modules/v2/pages/PaymentSteps/payments';

import Card from './styles';

const CardItem = ({ creditData, refetchDefaultProfile, refetchProfiles }) => {
  const {
    card_type: card,
    masked_card_number: cardNumber,
    expired: expiredCard,
    expiration_month: expirationMonth,
    expiration_year: expirationYear,
    id,
  } = creditData;

  const { mutate, isLoading } = useMutation(
    ['setDefaultPaymentProfile'],
    setDefaultPaymentProfile,
    {
      onSuccess: async () => {
        await refetchDefaultProfile();
        await refetchProfiles();
        notification.success({
          description: 'Primary Profile Set Successfully',
        });
      },
      onError: () => {
        notification.error({
          description: 'Setting Primary Profile Failed',
        });
      },
    },
  );

  const { mutate: removeCard, isLoading: isRemoveLoading } = useMutation(
    ['deletePaymentProfile'],
    deletePaymentProfile,
    {
      onSuccess: async () => {
        await refetchDefaultProfile();
        await refetchProfiles();
        notification.success({
          description: 'Removal of payment method successful!',
        });
      },
      onError: () => {
        notification.error({
          description: 'Removal of payment method failed!',
        });
      },
    },
  );

  const handleSetPrimary = () => {
    mutate(id);
  };

  const handleRemove = () => {
    removeCard(id);
  };

  const formattedExpirationMonth = expirationMonth.toString().padStart(2, '0');
  return (
    <Card.CardWrap>
      <S.Container>
        <S.CreditWrapper direction="horizontal">
          <div style={{ display: 'inline-flex' }}>
            <S.CardImg src={cardDetails[card]} alt={card} />
            <S.CardWrapper>
              <S.CardName>{card}</S.CardName>
              <Space direction="horizontal">
                <S.DotContainer>
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                  <S.Dot />
                </S.DotContainer>
                <S.Description>
                  {cardNumber?.substring(cardNumber?.length - 4, cardNumber?.length)}
                  <Divider type="vertical" />
                  {formattedExpirationMonth}/{expirationYear}
                </S.Description>
              </Space>
            </S.CardWrapper>
          </div>
          {!expiredCard ? (
            <Space>
              <Card.PrimaryButton onClick={handleSetPrimary} loading={isLoading}>
                Set as Primary
              </Card.PrimaryButton>
              <Button size="large" onClick={handleRemove} loading={isRemoveLoading}>
                Delete Card
              </Button>
            </Space>
          ) : (
            <S.ExpiredTag>Expired</S.ExpiredTag>
          )}
        </S.CreditWrapper>
      </S.Container>
    </Card.CardWrap>
  );
};

export default CardItem;
