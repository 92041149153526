import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  figure {
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 0;
    line-height: 0;
  }

  img {
    margin: 8px;
  }
`;

export default {
  Wrapper,
};
