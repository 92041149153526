import styled from 'styled-components';
import { Modal } from 'modules/common/components';

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    width: 700px;
  }
`;

export default {
  ModalWrapper,
};
