//

import { STATUS_INIT } from 'store/status';

export const book = {
  data: null,
  status: STATUS_INIT,
};

// TODO: Move to draft/initialState
export const draft = {
  data: null,
  status: STATUS_INIT,
};

// TODO: Move to draft/initialState
export const preferences = {
  name: '',
  email: '',
  phone: '',
  authorImageFront: {
    status: false,
    value: null,
  },
  authorImageBack: {
    status: false,
    value: null,
  },
  brokerInfo: {
    status: false,
    value: null,
  },
  brokerLogo: {
    status: false,
    value: null,
  },
  realtorLogo: {
    status: false,
    value: null,
  },
  housingLogo: {
    status: false,
    value: null,
  },
  startChaptersInRecto: true,
};

export const save = {
  changed: {
    preferences: null,
    sections: null,
    cover: null,
    content: {},
  },
  saved: {
    preferences: null,
    sections: null,
    cover: null,
    content: {},
  },
  saving: false,
  pending: false,
};

export const loadingElements = [];

export const toolbar = {
  showGuides: false,
};

export const user = {
  logos: {
    data: null,
    status: STATUS_INIT,
  },
  images: {
    data: null,
    status: STATUS_INIT,
    upload: {
      data: null,
      status: STATUS_INIT,
    },
  },
};

export const preview = {
  data: null,
  status: STATUS_INIT,
};

export const reset = {
  status: STATUS_INIT,
};

export const resetSection = {
  status: STATUS_INIT,
};

export default {
  book,
  draft,
  loadingElements,
  preview,
  reset,
  resetSection,
  save,
  toolbar,
  user,
};
