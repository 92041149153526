import styled from 'styled-components';
import { Button as BaseButton } from 'antd';

import * as color from 'modules/common/theme/color';

const Button = styled(BaseButton)`
  color: ${color.Black} !important;
  background-color: ${color.White};
  border-color: ${color.GreyDivider} !important;
  padding: 0 12px !important;

  &.ant-popover-open,
  &:active,
  &:hover {
    border-color: ${color.GreyDivider};
  }
`;

Button.Icon = styled.span`
  display: inline-block;
  padding-right: ${({ hasSpacing }) => (hasSpacing ? '6px' : 0)};

  svg {
    display: block;
    width: 22px;
    height: 22px;
    line-height: 22px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  line-height: 30px;
`;

export default { Button, Title };
