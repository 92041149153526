import { Select as AntSelect } from 'antd';
import styled from 'styled-components';

const Select = styled(AntSelect)`
  width: 120px;
  margin: 0 8px;
`;

const { Option } = AntSelect;

export default { Select, Option };
