import { call, takeEvery } from 'redux-saga/effects';

import { fetchUserAddresses, getError, resource } from 'modules/api';

import { GET_USER_ADDRESSES } from 'modules/dashboard/store/constants';

import { getAddressesPayload } from './utils';

export function* onGetUserAddresses() {
  try {
    const request = resource(GET_USER_ADDRESSES, fetchUserAddresses, getAddressesPayload);
    const payload = yield call(request);

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchGetAddresses() {
  yield takeEvery(GET_USER_ADDRESSES, onGetUserAddresses);
}
