import styled from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';
import * as color from 'modules/common/theme/color';

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 4px 0;
  color: ${color.PrimaryColor};
  cursor: pointer;

  :hover {
    color: ${color.SecondaryColor};
  }
`;

const ActionIcon = styled(MoreOutlined)`
  transform: rotate(90deg);
  cursor: pointer;
`;

export default { ActionsWrapper, ActionIcon };
