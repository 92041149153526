import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import copyIcon from 'assets/images/copy-icon.svg';

import { addLandingPages, getCustomer, getGuidesOrders } from 'modules/api';
import {
  formatDateTime4,
  guidesInformation,
  guidesPacketInformation,
  notification,
} from 'modules/v2/common/utils';
import { Loader, PrimaryButton } from 'modules/v2/common/components';
import { CoverImage } from 'modules/editor/components';
import { getItem, setItem } from 'modules/v2/utils/legacy';
import { DashboardTemplate } from 'modules/v2/templates';

import { useOrderGuide } from './hooks';
import S from './styles';
import { normalizeUrl } from '../../../utils/stringManipulation';

const LANDING_PAGES = 'landingPages';

const WelcomeDentistPage = () => {
  const [showGuides, setShowGuides] = useState(false);
  const [generatedWebsites, setGeneratedWebsites] = useState([]);

  const landingPagesInitialValues = getItem(LANDING_PAGES, 'object') || guidesInformation;
  const [landingPages, setLandingPages] = useState(landingPagesInitialValues);

  const userData = getItem('userData', 'object');

  useEffect(() => {
    setItem(LANDING_PAGES, landingPages);
  }, [landingPages]);

  useQuery(['getCustomer'], getCustomer, {
    onSuccess: (data) => {
      const { landingPageProfile } = data || {};
      const { generatedWebsites: customerGeneratedWebsites } = landingPageProfile || {};
      setGeneratedWebsites(customerGeneratedWebsites);
    },
  });

  const { mutateAsync: addLandingPagesMutate } = useMutation(
    'saveLandingPageLinks',
    addLandingPages,
    {
      onSuccess: (data) => {
        const { landingPageProfile } = data;
        const { generatedWebsites: customerGeneratedWebsites } = landingPageProfile;
        setGeneratedWebsites(customerGeneratedWebsites);
      },
    },
  );

  const handleGenerateLandingPagesSuccess = ({ site, guideId }) => {
    setLandingPages((prevState) =>
      prevState.map((item) => (item.id === guideId ? { ...item, site } : item)),
    );

    const payloadAddLandingPage = {
      guideId,
      link: site,
      type: 'GUIDE',
    };

    addLandingPagesMutate(payloadAddLandingPage);
  };

  const { isOrderGuideLoading, handleGenerateLandingPages } = useOrderGuide({
    onSuccess: handleGenerateLandingPagesSuccess,
  });

  const currentOrderPage = 1;
  const perPage = 10;

  const { data: guidesOrders, isLoading: isLoadingOrders } = useQuery(
    ['getGuidesOrders', currentOrderPage, perPage],
    () => getGuidesOrders(currentOrderPage, perPage),
  );

  const packetOrder = guidesOrders?.data[0] || {};

  const handleCopy = (text: string) => {
    const temporaryInput = document.createElement('input');
    temporaryInput.value = text;
    document.body.appendChild(temporaryInput);
    temporaryInput.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      notification.error({
        description: error.message,
      });
      throw new Error(error.message);
    } finally {
      document.body.removeChild(temporaryInput);
    }
  };

  const InputAddonAfter = (text: string) => (
    <S.InputAddonAfter onClick={() => handleCopy(text)}>
      <span>
        <img src={copyIcon} alt="copyIcon" /> Copy Link
      </span>
    </S.InputAddonAfter>
  );

  const guidesContent = landingPages.map(({ id, thumbnailUrl, site, name }) => {
    const website = generatedWebsites?.find((item) => item.guideId === id) || {};

    return (
      <div key={id}>
        <S.Wrapper>
          <S.SideContent>
            <S.ImageWrapper>
              <CoverImage url={thumbnailUrl} loading={false} isAllowed />
            </S.ImageWrapper>
          </S.SideContent>
          <S.MainContent>
            <S.BookName>{name}</S.BookName>
            <S.LandingPageContainer>
              {website?.link ? (
                <S.InputContainer>
                  <S.LabeledInputWrapper>
                    <S.InputTitle>Lead capture website</S.InputTitle>
                    <S.FakeInput>
                      <span>
                        <a href={normalizeUrl(website?.link)} target="_blank" rel="noreferrer">
                          {normalizeUrl(website?.link)}
                        </a>
                      </span>
                      {InputAddonAfter(website?.link)}
                    </S.FakeInput>
                  </S.LabeledInputWrapper>
                </S.InputContainer>
              ) : (
                <PrimaryButton onClick={() => handleGenerateLandingPages(id)}>
                  Generate Website
                </PrimaryButton>
              )}
            </S.LandingPageContainer>
          </S.MainContent>
        </S.Wrapper>
        <S.Line />
      </div>
    );
  });

  const loadingContent = <Loader height="calc(100vh - 400px)" title="Loading Your Packet" />;
  const loadingLandingPageContent = (
    <Loader height="calc(70vh)" title="Creating and Loading Your Website" />
  );

  if (isOrderGuideLoading) {
    return (
      <DashboardTemplate hasSidebar={false}>
        <S.Container>{loadingLandingPageContent}</S.Container>
      </DashboardTemplate>
    );
  }

  if (isLoadingOrders) {
    return (
      <DashboardTemplate hasSidebar={false}>
        <S.Container>{loadingContent}</S.Container>
      </DashboardTemplate>
    );
  }

  const packetShippingAddress = `${packetOrder?.shippingAddress?.addressLine1}, ${packetOrder.shippingAddress?.state}, ${packetOrder.shippingAddress?.pincode}, ${packetOrder?.shippingAddress?.country}`;

  return (
    <DashboardTemplate hasSidebar={false} noPaddingLeft noPaddingRight>
      <S.Container>
        <S.WelcomeTitle>Welcome {userData.firstname}!</S.WelcomeTitle>
        <S.BoxContainer>
          <S.OrderedGuidesContainer>Ordered Guides</S.OrderedGuidesContainer>
          <S.Wrapper>
            <S.SideContent>
              <S.ImageWrapper>
                <img src={guidesPacketInformation.thumbnailUrl} alt="guides packet" />
              </S.ImageWrapper>
            </S.SideContent>
            <S.MainContent>
              <div>
                <S.BookName>The Cosmetic Dentistry Packet</S.BookName>
                <S.FreeIcon>Free</S.FreeIcon>
              </div>
              <S.Details>
                <div>
                  <S.DetailsTitle>Order Number: </S.DetailsTitle>
                  <S.DetailsInformation>{packetOrder?.orderId}</S.DetailsInformation>
                </div>
                <div>
                  <S.DetailsTitle>Order Date: </S.DetailsTitle>
                  <S.DetailsInformation>
                    {formatDateTime4(packetOrder?.createdAt)}
                  </S.DetailsInformation>
                </div>
                <div>
                  <S.DetailsTitle>Quantity: </S.DetailsTitle>
                  <S.DetailsInformation>{packetOrder?.quantity}</S.DetailsInformation>
                </div>
                <div>
                  <S.DetailsTitle>Delivered to: </S.DetailsTitle>
                  <S.DetailsInformation>{packetShippingAddress}</S.DetailsInformation>
                </div>
              </S.Details>
              <S.LandingPageContainer>
                <S.InputContainer>
                  <S.LabeledInputWrapper>
                    <S.InputTitle>Lead capture website</S.InputTitle>
                    <S.FakeInput>
                      <span>
                        <a
                          href={normalizeUrl(packetOrder?.landingPage)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {normalizeUrl(packetOrder?.landingPage)}
                        </a>
                      </span>
                      {InputAddonAfter(normalizeUrl(packetOrder?.landingPage))}
                    </S.FakeInput>
                  </S.LabeledInputWrapper>
                  {/* <S.LabeledInputWrapper>
                    <S.InputTitle>Digital guide</S.InputTitle>
                    <S.FakeInput>
                      <span>
                        <a href={guidesPacketInformation.pdfUrl} target="_blank" rel="noreferrer">
                          {guidesPacketInformation.pdfUrl}
                        </a>
                      </span>
                      {InputAddonAfter(guidesPacketInformation.pdfUrl)}
                    </S.FakeInput>
                  </S.LabeledInputWrapper> */}
                </S.InputContainer>
              </S.LandingPageContainer>
            </S.MainContent>
          </S.Wrapper>

          <S.Wrapper>
            <S.Title>
              <S.HideGuidesContainer onClick={() => setShowGuides(!showGuides)}>
                {showGuides ? (
                  <>
                    <span>Hide guides</span>
                    <UpOutlined />
                  </>
                ) : (
                  <>
                    <span>Show guides</span>
                    <DownOutlined />
                  </>
                )}
              </S.HideGuidesContainer>
            </S.Title>
          </S.Wrapper>
          {showGuides && guidesContent}
        </S.BoxContainer>
      </S.Container>
    </DashboardTemplate>
  );
};

export default WelcomeDentistPage;
