import Modal from 'modules/common/components/Modal';

import S from './styles';

const ConfirmModal = ({
  isOpen,
  title,
  text,
  width = '380px',
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  cancelButtonProps,
  maskClosable = false,
  hasCloseButton = false,
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    width={width}
    maskClosable={maskClosable}
    okText={confirmLabel}
    cancelText={cancelLabel}
    cancelButtonProps={cancelButtonProps}
    hasCloseButton={hasCloseButton}
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    <S.Wrapper>{text}</S.Wrapper>
  </Modal>
);

ConfirmModal.defaultProps = {
  confirmLabel: 'Yes',
  cancelLabel: 'Cancel',
};

export default ConfirmModal;
