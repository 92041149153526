// eslint-disable-next-line import/no-extraneous-dependencies
import { useMap } from '@vis.gl/react-google-maps';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

interface Props {
  place: google.maps.places.PlaceResult | null;
}

interface GeocodeResults {
  geocodeResult: google.maps.GeocoderResponse | null;
}

export function extractAddressComponents(geocodeResult: GeocodeResults) {
  const components = {
    street_number: '',
    route: '',
    locality: '',
    administrative_area_level_1: '',
    country: '',
    postal_code: '',
  };

  if (isEmpty(geocodeResult)) return [];

  geocodeResult[0].address_components.forEach((component) => {
    const { types } = component;
    const componentValue = {
      long_name: component.long_name,
      short_name: component.short_name === 'US' ? 'USA' : component.short_name,
    };

    if (types.includes('street_number')) {
      components.street_number = component.long_name;
    }
    if (types.includes('route')) {
      components.route = componentValue.long_name;
    }
    if (types.includes('locality')) {
      components.locality = componentValue.long_name;
    }
    if (types.includes('administrative_area_level_1')) {
      components.administrative_area_level_1 = componentValue.long_name;
    }
    if (types.includes('country')) {
      components.country = componentValue.short_name;
    }
    if (types.includes('postal_code')) {
      components.postal_code = componentValue.long_name;
    }
  });

  return {
    addressLine1: `${components.street_number} ${components.route}`,
    city: components.locality,
    state: components.administrative_area_level_1,
    country: components.country,
    pincode: components.postal_code,
  };
}

const MapHandler = ({ place }: Props) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

  return null;
};

export default React.memo(MapHandler);
