import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PiEye, PiMapPin } from 'react-icons/pi';

import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { HiShoppingCart } from 'react-icons/hi2';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ROUTE_BUY_CREDITS } from 'modules/v2/routes/constants';
import OrderTag from '../../../common/AtomicDesign/atoms/OrderTag';
import OrderedDateTime from '../../../common/AtomicDesign/atoms/OrderedDateTime';

import { Modal } from '../../../common/components';

import { getRouteDraftGenerateByOrderId } from '../../../../draft/routes/navigation';
import { InfoIconNew } from '../../../common/components/SvgIcon';
import { BooksContext } from '../context';
import QuantitySelector from '../../../common/AtomicDesign/molecules/QuantitySelector';
import FlippingBookEmbed from '../../../common/components/FlippingBookEmbed';
import { getDraftLinksById, getFlippingBookCanonicalLinkByBookId } from '../../../../api';
import Spinner from '../../../common/AtomicDesign/atoms/Spinner';
import { Tooltip } from '../../../common/AtomicDesign/molecules';
import ShieldWarningIcon from '../../../../common/components/SvgIcons/ShieldWarningIcon';
import QuestionCircle from '../../../common/components/SvgIcon/QuestionCircle';
import { Link } from '../../../draft/components';
import { normalizeUrl } from '../../../utils/stringManipulation';
import { MINIMUM_CHECKOUT_BOOK_QUANTITY } from '../../constants';

const OrderedBookCard = ({ orderData, onBuyAgain }) => {
  const [showQuantitySelectionModal, setShowQuantitySelectionModal] = useState(false);
  const [showBookPreviewModal, setShowBookPreviewModal] = useState(false);
  const [canonicalLink, setCanonicalLink] = useState(null);
  const [showLowCreditsWarningModa, setShowLowCreditsWarningModal] = useState(false);

  const ADDRESS_MAX_LENGTH = 70;

  const {
    bookQuantity,
    setBookQuantity,
    userCredits,
    isBookQuantityValid,
    setIsBookQuantityValid,
    setSelectedOrderContext,
    isBuyCreditEnabled,
  } = useContext(BooksContext);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDraftLinks, setIsLoadingDraftLinks] = useState(false);
  const [draftLinks, setDraftLinks] = useState({} as { site: string; book: string });

  const loadDraftLinks = () => {
    setIsLoadingDraftLinks(true);
    getDraftLinksById({ draftId: orderData.draftId })
      .then((draftResponse) => {
        setDraftLinks({
          site: draftResponse?.data?.url?.site,
          book: draftResponse?.data?.url?.book,
        });
      })
      .catch((error) => {
        console.error('Error fetching draft link for order:', orderData, error);
        return orderData;
      })
      .finally(() => {
        setIsLoadingDraftLinks(false);
      });
  };

  useEffect(() => {
    if (orderData) {
      loadDraftLinks();
    }
  }, [orderData]);

  const loadFlippingBookCanonicalLink = () => {
    setIsLoading(true);
    getFlippingBookCanonicalLinkByBookId({ bookId: orderData?.bookId })
      .then((response) => {
        setIsLoading(false);
        setCanonicalLink(response.data.canonicalLink);
        setShowBookPreviewModal(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(
          'Error fetching flipping book canonical link for book:',
          orderData.bookId,
          error,
        );
      });
  };

  const handleNavigateToCheckout = () => {
    if (userCredits < MINIMUM_CHECKOUT_BOOK_QUANTITY) {
      setShowLowCreditsWarningModal(true);
      return;
    }
    setSelectedOrderContext(orderData);
    setBookQuantity(orderData.quantity);
    onBuyAgain();
  };

  const handleShowConfirmationModal = () => {
    handleNavigateToCheckout();
  };

  const handleCancel = () => {
    setShowQuantitySelectionModal(false);
  };

  const handleConfirm = () => {
    const path = getRouteDraftGenerateByOrderId(orderData.orderId);
    setShowQuantitySelectionModal(false);
    history.push(path);
  };

  const validateInput = (e) => {
    if (e.target.value >= MINIMUM_CHECKOUT_BOOK_QUANTITY && e.target.value <= userCredits) {
      setIsBookQuantityValid(true);
    } else {
      setIsBookQuantityValid(false);
    }
  };

  useEffect(() => {
    validateInput({ target: { value: bookQuantity } });
  }, [bookQuantity, setBookQuantity]);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBookQuantity(Number(e.target.value));
  };

  const handleClosePreviewModal = () => {
    setShowBookPreviewModal(false);
  };

  const ModalFooterContainer = ({ children }) => (
    <div className="flex flex-col items-center justify-center gap-2.5 w-full">{children}</div>
  );

  const ContentContainer = ({ children }) => (
    <div className="flex flex-col items-center justify-center gap-4 w-full p-6 sm:w-full">
      <hr className="border-t-[1px] border-neutral-200 w-full" />
      {children}
    </div>
  );

  const BookThumbnail = ({ src, alt, className }) => (
    <div
      className={`h-32 md:h-48 overflow-hidden rounded-lg relative w-auto transition-transform duration-200 ${className}`}
    >
      <img
        src={src}
        alt={alt}
        className="h-full max-w-none object-cover rounded-lg transition-transform duration-200"
      />
    </div>
  );

  const Overlay = ({ children }) => (
    <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-white text-lg bg-black bg-opacity-0 transition duration-300 ease-in-out opacity-0 invisible">
      {children}
    </div>
  );

  const PreviewText = ({ children }) => <div className="flex items-center gap-2">{children}</div>;

  const QuantityTag = ({ children }) => (
    <div className="flex font-semibold items-center gap-2 rounded-md px-2 py-1 text-neutral-900 text-sm bg-neutral-100">
      {children}
    </div>
  );

  const PreviewImage = ({ src, alt }) => (
    <img src={src} alt={alt} className="w-full h-auto rounded-lg" />
  );

  function handlePreview() {
    if (!canonicalLink) loadFlippingBookCanonicalLink();
    else setShowBookPreviewModal(true);
  }

  function renderLoadingOnPreview() {
    return (
      <>
        {isLoading && (
          <div className="absolute inset-0 z-50 flex justify-center items-center bg-white bg-opacity-80">
            <Spinner />
          </div>
        )}
      </>
    );
  }

  function shippingAddressText() {
    const addressParts = [
      orderData?.shippingAddress?.addressLine1,
      orderData?.shippingAddress?.addressLine2,
      orderData?.shippingAddress?.city,
      orderData?.shippingAddress?.state,
      orderData?.shippingAddress?.country,
      orderData?.shippingAddress?.pincode,
    ];

    const address = addressParts.filter(Boolean).join(', ');
    const displayAddress =
      address.length > ADDRESS_MAX_LENGTH ? `${address.slice(0, ADDRESS_MAX_LENGTH)}...` : address;
    return {
      fullAddress: address,
      displayAddress,
    };
  }

  const { fullAddress, displayAddress } = shippingAddressText();

  const handleCancelLowCreditsWarningModal = () => {
    setShowLowCreditsWarningModal(false);
  };

  const handleNavigateToBuyCreditsPage = () => {
    setShowLowCreditsWarningModal(false);
    history.push(ROUTE_BUY_CREDITS);
  };

  return (
    <>
      <Box className="mb-6 relative">
        <div className="text-base gap-2 flex-row items-center px-6 py-[22px] text-boxTitle">
          <OrderedDateTime value={new Date(orderData?.createdAt)} />
          <span className="md:ml-0 sm:ml-0 flex-row text-base">
            <PiMapPin className="mr-2 text-green-400" size="24" />
            <span className="font-semibold mr-1">Shipped to</span>
            <Tooltip
              content={fullAddress}
              trigger="hover"
              placement="top"
              theme={{
                base: 'text-neutral-50 text-sm p-2 pr-3 pl-3 rounded-lg',
              }}
            >
              <span>{displayAddress}</span>
            </Tooltip>
          </span>
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 border-t-[1px] border-t-neutral-200 overflow-visible">
          <div className="p-6 flex gap-4">
            <div style={{ width: 'fit-content' }}>
              <div
                className="group relative overflow-hidden h-48 cursor-pointer rounded-lg"
                onClick={handlePreview}
                onKeyDown={handlePreview}
              >
                <BookThumbnail
                  src={orderData?.thumbnail?.url}
                  alt={orderData.bookTitle}
                  className="group-hover:scale-105"
                />
                <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-40 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                  <div className="flex items-center gap-2 text-white text-sm opacity-100">
                    <PiEye /> <span>Preview</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-col mb-2">
                <OrderTag className="mb-2">Order# {orderData?.orderId}</OrderTag>
                <div className="flex flex-row items-center mr-2">
                  <span className="mr-2 lowercase first-letter:capitalize">
                    {orderData?.bookTitle}
                  </span>{' '}
                  <QuantityTag>Qty: {orderData.creditUsed}</QuantityTag>
                </div>
              </div>

              <Button
                type="dark"
                onClick={handleNavigateToCheckout}
                onKeyDown={handleNavigateToCheckout}
              >
                <HiShoppingCart size={18} color="white" className="mr-2" /> Buy again
              </Button>
            </div>
          </div>

          <div className="p-6 overflow-visible">
            <>
              {isLoadingDraftLinks && (
                <div className="absolute inset-0 z-50 flex justify-center items-center bg-white bg-opacity-80">
                  <Spinner />
                </div>
              )}
            </>

            <div className="w-full">
              <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                Lead capture website
                <Tooltip
                  content="Generate more leads and grow your business. Receive instant notifications for every new lead, so you can follow up quickly and win the business. "
                  trigger="hover"
                  style="dark"
                  arrow
                  placement="top"
                  theme={{
                    base:
                      'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                  }}
                >
                  <QuestionCircle />
                </Tooltip>
              </h5>
              <Link
                key="landingPage"
                id="landingPage"
                showPreview={false}
                href={normalizeUrl(draftLinks?.site)}
              />
            </div>

            <div className="w-full mt-6">
              <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                Digital book
                <Tooltip
                  content="Share your book digitally to showcase expertise and build trust with remote clients."
                  trigger="hover"
                  style="dark"
                  arrow
                  placement="top"
                  theme={{
                    base:
                      'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                  }}
                >
                  <QuestionCircle />
                </Tooltip>
              </h5>
              <Link
                key="digitalBook"
                id="digitalBook"
                showPreview={false}
                href={normalizeUrl(draftLinks?.book)}
              />
            </div>
          </div>
        </div>

        {showQuantitySelectionModal && (
          <Modal
            isOpen={showQuantitySelectionModal}
            title="How many would you like to order?"
            hasCloseButton
            width="630px"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            footer={[
              <ModalFooterContainer key={1}>
                <Button
                  onClick={handleNavigateToCheckout}
                  disabled={!isBookQuantityValid}
                  size="base"
                  type="primary"
                  wrapperClassName="w-full"
                >
                  Proceed to checkout <ArrowRightOutlined className="ml-2" />
                </Button>
              </ModalFooterContainer>,
            ]}
          >
            <ContentContainer>
              <div className="bg-neutral-100 text-neutral-700 flex-auto flex items-center w-full text-sm leading-5 p-2 rounded-lg mt-2.5">
                <InfoIconNew />
                <span className="ml-2">
                  Minimum of 8 to order. Maximum of {userCredits} (Available credits)
                </span>
              </div>
              <div className="relative overflow-hidden h-48 cursor-pointer rounded-lg">
                <BookThumbnail src={orderData?.thumbnail?.url} alt={orderData.bookTitle} />
                <Overlay>
                  <PreviewText>
                    <PiEye /> <span>Preview</span>
                  </PreviewText>
                </Overlay>
              </div>
              <h3 className="text-sm mt-2.5 font-semibold text-neutral-700">
                {orderData?.bookTitle}
              </h3>
              <QuantitySelector
                onChange={handleQuantityChange}
                value={bookQuantity}
                maximum={userCredits}
                minimum={8}
                step={4}
                isValid={isBookQuantityValid}
                errorMessage={`Please enter a number between 8 and ${userCredits}`}
              />
            </ContentContainer>
          </Modal>
        )}

        <Modal
          isOpen={showBookPreviewModal}
          title="Preview"
          width="80%"
          onConfirm={handleClosePreviewModal}
          onCancel={handleClosePreviewModal}
          hasCloseButton
          footer={
            <div className="flex w-full justify-end">
              <Button type="dark" onClick={handleShowConfirmationModal}>
                <HiShoppingCart size={18} color="white" className="mr-2" /> Buy again
              </Button>
            </div>
          }
        >
          <FlippingBookEmbed src={canonicalLink} height="477px"/>
        </Modal>

        <Modal
          isOpen={showLowCreditsWarningModa}
          onConfirm={handleNavigateToBuyCreditsPage}
          onCancel={handleCancelLowCreditsWarningModal}
          footer={
            <div className="flex w-full mt-4 justify-end">
              <Button type="outlined" onClick={handleCancelLowCreditsWarningModal}>
                Cancel, I'll buy later
              </Button>
              <Button
                type="dark"
                disabled={!isBuyCreditEnabled}
                onClick={handleNavigateToBuyCreditsPage}
              >
                Buy credits
              </Button>
            </div>
          }
        >
          <div className="flex flex-col justify-center items-center font-figtree gap-2">
            <ShieldWarningIcon className="mt-4 mb-2" width={50} height={50} fill="#FFB703" />
            <p className="text-lg font-semibold">
              You have <span className="text-red-400">{userCredits}</span> left
            </p>
            <p className="text-base text-neutral-500">1 Credit = 1 Printed book</p>
            <p className="text-sm text-neutral-500">
              Please check with support to learn more about Authorify plans.
            </p>
          </div>
        </Modal>

        {renderLoadingOnPreview()}
      </Box>
    </>
  );
};

export default OrderedBookCard;
