import { call, put, race, take, select } from 'redux-saga/effects';

import { fetchPreFlightCheck, resource, getError } from 'modules/api';

import { CLEAR_PUBLISH, GET_PUBLISH, GET_PREFLIGHT_CHECK } from 'modules/publish/store/constants';

import { getUserAddresses, getUserCredits, getCountries } from 'modules/publish/store/actions';
import { get } from 'lodash-es';
import { onGenerateDraftLinks } from 'modules/draft/store/sagas/links/generateDraftLinks';
import { linksSelector } from 'modules/draft/store';
import { isLoading } from 'store/status';
import { isPreviewStatusReady } from 'modules/draft/utils/preview';

export function* onGetPublish({ payload: meta }) {
  try {
    const request = resource(GET_PREFLIGHT_CHECK, fetchPreFlightCheck);
    const payload = yield call(request, meta);
    const checkFinalPass = get(payload, 'finalCheckPass', false);
    const links = yield select(linksSelector);
    const digitalBookStatus = get(links, ['data', 'status']);
    const isGenerateDisabled = isLoading(links.status) || isPreviewStatusReady(digitalBookStatus);

    if (checkFinalPass && !isGenerateDisabled) {
      // Lambda function call, no wait needed. Users can continue with next step of order
      yield call(onGenerateDraftLinks, { payload: meta });
    }
    yield put(getUserCredits());
    yield put(getUserAddresses());
    yield put(getCountries());

    return payload;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);
    return payload;
  }
}

export function* watchCancellation(request, action) {
  yield race([call(request, action), take(CLEAR_PUBLISH)]);
}

export function* watchGetPublishData() {
  while (true) {
    const action = yield take(GET_PUBLISH);

    if (action) {
      yield call(watchCancellation, onGetPublish, action);
    }
  }
}
