import styled from 'styled-components';

const ResultWrap = styled.div`
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
`;

export default {
  ResultWrap,
};
