import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { BackArrowIcon } from 'modules/v2/common/components/SvgIcons';

const SectionHeader = ({
  onNavigateBack,
  // TODO: To be implemented
  // onAddChapter
}) => (
  <div className="w-full bg-white">
    <Button onClick={onNavigateBack} size="base" type="base" className='flex items-center justify-center'>
      <BackArrowIcon width={25} height={25} className="mr-2 -mt-1 p-1 rouneded-lg bg-neutral-100" />
      Manuscript
    </Button>
  </div>
);

export default SectionHeader;
