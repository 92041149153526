import styled from 'styled-components';
import { Input as BaseInput } from 'antd';
import { Form as BaseForm } from 'formik';
import { PrimaryButton } from 'modules/v2/common/components';
import * as color from 'modules/v2/common/theme/color';

const Form = styled(BaseForm)`
  display: block;
`;

const inputStyle = `
  height: 45px;
  border: 1px solid ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: ${color.White};
  height: 45px;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #2e3f50;

  &:focus {
    border: 1px solid ${color.InputFocusBorder};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const Input = styled(BaseInput)`
  ${inputStyle}
`;

const Button = styled(PrimaryButton)`
  margin: 10px 15px;
  height: 40px;
  width: 250px;
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  margin-bottom: 24px;
`;

const InfoText = styled.span`
  font-weight: 400;
  color: ${color.Body};
  padding: 15px;
`;

export default {
  Button,
  FieldGroup,
  FieldControl,
  Form,
  InfoText,
  Input,
};
