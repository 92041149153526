import { AppleIcon, GoogleIcon, YahooIcon, OutlookIcon } from '../../SvgIcon/SocialIcons';
import {
  generateGoogleCalendarUrl,
  generateIcsCalendarFile,
  generateYahooCalendarUrl,
  CalendarEvent,
} from '../utils';

interface AddToCalendarButtonTooltipProps {
  calendarEvent: CalendarEvent;
}

export default function AddToCalendarButtonTooltip({
  calendarEvent,
}: AddToCalendarButtonTooltipProps) {
  return (
    <div className="absolute mt-1.5 w-full bg-white border border-grayHover rounded-lg flex flex-col py-2 shadow-boxCalendar">
      <a
        href={generateIcsCalendarFile(calendarEvent)}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-1 px-5 py-2 hover:bg-neutral-100 transition-colors duration-2"
      >
        <AppleIcon />
        <span className="text-sm text-neutral-800 ml-2">iCal</span>
      </a>
      <a
        href={generateGoogleCalendarUrl(calendarEvent)}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-1 px-5 py-2 hover:bg-neutral-100 transition-colors duration-2"
      >
        <GoogleIcon />
        <span className="text-sm text-neutral-800 ml-2">Google</span>
      </a>
      <a
        href={generateYahooCalendarUrl(calendarEvent)}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-1 px-5 py-2 hover:bg-neutral-100 transition-colors duration-2"
      >
        <YahooIcon />
        <span className="text-sm text-neutral-800 ml-2">Yahoo</span>
      </a>

      <a
        href={generateIcsCalendarFile(calendarEvent)}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-1 px-5 py-2 hover:bg-neutral-100 transition-colors duration-2"
      >
        <OutlookIcon />
        <span className="text-sm text-neutral-800 ml-2">Outlook</span>
      </a>
    </div>
  );
}
