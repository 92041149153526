import { Radio } from 'antd';
import S from './styles';
import cardDetails from '../../payments';

const CardItem = ({ creditData, selectedId }) => {
  const { id, card_type: card, masked_card_number: cardNumber, expired: expiredCard } = creditData;

  const defaultCard = selectedId === id;

  return (
    <S.Container>
      <Radio value={id} disabled={expiredCard}>
        <S.CreditWrapper>
          <S.CardImg src={cardDetails[card]} alt={card} />
          <S.CardWrapper>
            <S.CardName>{card.charAt(0).toUpperCase() + card.slice(1)}</S.CardName>
            <S.Description>
              {' '}
              {cardNumber.substring(cardNumber.length - 4, cardNumber.length)}
            </S.Description>
          </S.CardWrapper>
        </S.CreditWrapper>
      </Radio>
      {defaultCard && (
        <S.DefaultCard>
          <S.Description>Primary</S.Description>
        </S.DefaultCard>
      )}
      {expiredCard && (
        <S.ExpiredCard>
          <S.Description>Expired</S.Description>
        </S.ExpiredCard>
      )}
    </S.Container>
  );
};

export default CardItem;
