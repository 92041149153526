import styled from 'styled-components';

import * as color from 'modules/common/theme/color';

const Table = styled.table`
  border: 1px solid ${color.LightDullGray};
  border-radius: 5px;
  background-color: ${color.White};
  margin: 20px 0px 0px 0px;
  width: 100%;
`;

const TableRow = styled.tr``;

const TableHeading = styled.th`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: ${color.Black};
  background-color: ${color.GreyHover};
  padding: 9px 20px;
  text-align: center;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

const TableData = styled.td`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${color.Black};
  background-color: ${color.White};
  padding: 9px 20px;
  border: 1px solid ${color.LightDullGray};
  border-right: 0;
  border-left: 0;
  text-align: center;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
  span {
    color: ${color.NotificationErrorBorder};
  }
`;

const SaveAmount = styled.span`
  color: ${color.PrimaryColor};
`;

const DowngradeButton = styled.button`
  color: ${({ $outline }) => ($outline ? color.SkyBlue : color.White)};
  padding: 0px 22px;
  height: 40px;
  background-color: ${({ $outline }) => ($outline ? 'transparent' : color.SkyBlue)};
  border: 1px solid ${color.SkyBlue};
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
`;

const CurrentPlan = styled.p`
  margin-bottom: -24px !important;
`;

const FooterData = styled.td`
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: ${color.Black};
  background-color: ${color.White};
  padding: 17px 20px;
  border: 1px solid ${color.LightDullGray};
  border-right: 0px;
  border-left: 0px;
  text-align: center;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

const BottomLine = styled.div`
  height: 10px;
  background: ${color.GreyHover};
  border: 1px solid ${color.LightDullGray};
  margin-top: -3px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 20px;
`;

const TableMainRow = styled(TableData)`
  text-transform: capitalize;
`;

export default {
  Table,
  TableRow,
  TableHeading,
  TableData,
  SaveAmount,
  DowngradeButton,
  CurrentPlan,
  FooterData,
  BottomLine,
  TableMainRow,
};
