import styled, { css } from 'styled-components';

import { Button } from 'antd';
import { Field } from 'formik';
import * as color from 'modules/common/theme/color';
import { device } from 'modules/common/theme/mediaqueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 80px;

  .ant-select.ant-select-focused {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${color.neutral800};
  margin: 0 10px 20px;
`;

const SelectField = styled(Field)`
  .ant-select-selector--single {
    height: 45px;
  }
  &.form-input-error {
    border: 1px solid ${color.ErrorWarning};
  }
`;

const FormikField = styled(Field).withConfig({
  shouldForwardProp: (prop) => !['hasError'].includes(prop),
})`
  flex: 1;
  flex-basis: 45px;
  border: 1px solid ${color.GreyLightText};
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 9px 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${color.neutral800};
  align-self: stretch;
  overflow: hidden;
  margin-top: 4px;

  .ant-select-selector {
    height: 42px !important;
    border: none !important;
    display: flex;
    align-items: center;
  }

  &:focus {
    outline: none;
    border: 1px solid ${color.primary500};
    box-shadow: none;
  }

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${color.ErrorWarning};
    `}
`;

const ButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  padding: 0 10px;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  display: flex;
  padding: 13px 18px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border: none;
  font-weight: bold;
  background-color: ${color.primary500};
  padding: 13px 18px;
  height: 45px;
  width: 100%;
  border-radius: 8px;
  color: ${color.neutral800};
  &:hover {
    background-color: ${color.primary500};
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: rgb(46, 63, 80);
  margin: 0 0 10px;
  padding: 0;
  margin-top: 20px;
  font-weight: 400;
`;

const CancelButton = styled(Button)`
  color: ${color.NotificationErrorBorder};

  &:hover {
    color: ${color.NotificationErrorBorder};
  }

  ${(props) =>
    props.type !== 'link' &&
    css`
      color: rgb(46, 63, 80);
      background-color: rgb(204, 204, 204);
      padding: 0 20px;
      border-color: rgb(204, 204, 204);
      font-weight: bold;
      &:hover {
        color: rgb(46, 63, 80);
        background-color: rgb(204, 204, 204);
      }
    `}
`;

export default {
  Wrapper,
  FieldGroup,
  FieldControl,
  Label,
  FormikField,
  SubmitButton,
  CancelButton,
  ButtonsWrapper,
  Paragraph,
  SelectField,
};
