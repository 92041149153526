import { get } from 'lodash-es';
import { getItem, setItem } from 'modules/dashboard/utils/legacy';
import { getRouteNextStep } from 'modules/onboard/routes/navigation';
import { availableSteps } from '../constants';

export const STEPS = {
  place_order: {
    path: 'place-order',
    alias: 'Place Order page',
    order: 1,
  },
  place_order_wait: {
    path: 'place-order-wait',
    alias: 'Place Order wait page',
    order: 2,
  },
  // addon: {
  //   path: 'addon',
  //   alias: 'Upsell Offer page',
  //   order: 1,
  // },
  schedule_coaching: {
    path: 'schedule-coaching',
    alias: 'Schedule Coaching page',
    order: 2,
  },
  training_webinar: {
    path: 'training-webinar',
    alias: 'Training Webinar page',
    order: 3,
  },
  dentist_guide_details: {
    path: 'dentist-guide-details',
    alias: 'Dentist Guide Details',
    order: 4,
  },
  book_details: {
    path: 'book-details',
    alias: 'Book Details page',
    order: 4,
  },
  your_guide: {
    path: 'your-guide',
    alias: 'Your Guide Page',
    order: 5,
  },
  your_book: {
    path: 'your-book',
    alias: 'Your Book Page',
    order: 6,
  },
  order_confirmation: {
    path: 'order-confirmation',
    alias: 'order confirmation page',
    order: 7,
  },
  account_wait: {
    path: 'account-wait',
    alias: 'account wait page',
    order: 8,
  },
  finish_onboarding: {
    path: 'finish-onboarding',
    alias: 'finish onboarding page',
    order: 0,
  },
};

export const getCurrentStep = (path) =>
  path.split('onboard/')[1].split('/')[0].replaceAll('-', '_');

export const setSession = (data) => {
  const localstorageData = {
    ...JSON.parse(getItem('onboardSession') || '{}'),
  };
  localstorageData[data?.offer?.code] = data;
  setItem('onboardSession', localstorageData);
};

export const resetSession = (offerCode) => {
  const storedSession = JSON.parse(getItem('onboardSession') || '{}');

  const localstorageData = {
    ...storedSession,
  };

  delete localstorageData[offerCode];

  setItem('payment_confirmed', '0');
  setItem('onboardSession', localstorageData);
};

export const getSession = (offerId) => {
  const currentSession = getItem('onboardSession');
  if (currentSession) {
    try {
      const session = JSON.parse(currentSession)[offerId];
      const storedOfferId = get(session, 'offer.code');

      if (offerId === storedOfferId) {
        return session;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return null;
};

export const handleStep = (step, currentPath, offerCode, push) => {
  const currentStep = getCurrentStep(currentPath);

  const queryString = window.location.search;

  if (step !== currentStep) {
    setTimeout(() => {
      push(getRouteNextStep(offerCode, STEPS[step].path, queryString));
    }, 500);
  }
};

export const getCurrentStepIndex = ({ step: currentStep, steps }) => {
  const offerSteps = steps?.length ? steps : Object.keys(availableSteps);
  const filteredOfferSteps = offerSteps.filter((step) => availableSteps[step]);
  const idx = filteredOfferSteps.indexOf(currentStep);
  return idx > -1 ? idx + 1 : 1;
};
