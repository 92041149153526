import Dropzone from 'react-dropzone';

import { notification } from 'modules/common/utils';

import { validateImage } from './validation';
import S from './styles';

const ImageUploader = ({
  onSelect,
  mimeTypes,
  imageType,
  noMargin = false,
  uploadText = 'Browse files',
}) => {
  const handleDrop = async (files) => {
    const [file] = files;

    validateImage(file, mimeTypes, imageType)
      .then(() => onSelect(file))
      .catch((error) => {
        notification.error({
          description: error.message,
        });
      });
  };

  let fileUploadDescriptions;
  switch (imageType) {
    case 'rm_profile':
      fileUploadDescriptions =
        'Works with any .PNG, .JPG, or .JPEG file from the web </br> Recommended size: 520 x 520';
      break;
    case 'brokerageLogo':
      fileUploadDescriptions =
        'Works with any .PNG, .JPG, or .JPEG file from the web </br> Recommended size: 540 x 192';
      break;
    default:
      fileUploadDescriptions =
        'Works with any .PNG, .JPG or .JPEG file from the web </br> Recommended size: 500 x 500';
  }

  return (
    <S.DragDropWrapper
      noMargin={noMargin}
      showWhite={imageType === 'preview-book-image'}
      className="mt-3 mb-0 w-full h-[292px] p-0 ml-0 bg-transparent border border-dashed"
    >
      <S.ResetModalHeightStyle />
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {imageType === 'email-template-header' ? null : (
                <svg
                  width={30}
                  height={30}
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx={20} cy={20} r={20} fill="#E9E9E9" />
                  <path
                    d="M19.8 12.9977L13.4 12.9977M13.4 12.9977L7 12.9977M13.4 12.9977L13.4 6.59766M13.4 12.9977L13.4 19.3977"
                    stroke="#535353"
                    strokeWidth={2}
                    strokeLinecap="round"
                    transform="translate(6, 7)"
                  />
                </svg>
              )}
              <div className="flex flex-col w-full items-center justify-center">
                <div className="action flex items-center gap-2">
                  <div className="flex items-center rounded-md bg-white text-primary-500 text-sm font-semibold bg-transparent">
                    {uploadText}
                  </div>
                  {imageType === 'email-template-header' ? null : (
                    <div className="text-gray-700 text-sm font-semibold">or drag and drop</div>
                  )}
                </div>
                {imageType === 'email-template-header' ? null : (
                  <p
                    className="text-neutral-400 text-center text-xs font-medium"
                    dangerouslySetInnerHTML={{ __html: fileUploadDescriptions }}
                  />
                )}
              </div>
            </div>
          );
        }}
      </Dropzone>
    </S.DragDropWrapper>
  );
};

export default ImageUploader;
