import { useEffect, useState } from 'react';
import Lightbox from 'react-spring-lightbox';
import { useFormik, useFormikContext } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { get, head, isEqual } from 'lodash-es';
import { useParams } from 'react-router';

import { PrimaryButton } from 'modules/v2/common/components';
import { ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE } from 'modules/v2/routes/constants';
import {
  createReferralMagazine,
  createSetupTicket,
  generateReferralMagazine,
  generateReferralMagazineAdmin,
  getGeneratedMagazineAdmin,
  getGeneratedMagazineMonth,
  getMagazine,
  getMagazineAdmin,
  getMagazinePreview,
  getMagazineStepsInfo,
  sendToPrint as sendMagazineToPrint,
  updateGeneratedMagazineById,
  updateReferralMagazine,
  updateReferralMagazineAdmin,
} from 'modules/api';
import { commonFunctions, isAdminEditing } from 'modules/v2/utils';
import { notification } from 'modules/v2/common/utils';
import Spinner from 'modules/v2/common/components/SvgIcon/Spinner';
import {
  getRouteRmMagazinePreview,
  getRouteRmMagazineReport,
} from 'modules/v2/routes/admin/navigation';
import {
  replaceFinalPayload,
  replaceNewLineWithBR,
} from 'modules/v2/pages/ReferralMarketingMagazines/components/Steps/FrontInsideCover/utils';

import { Alert, Modal } from 'flowbite-react';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import AlertWarning from 'modules/v2/common/components/SvgIcon/AlertWarning';
import PublishBookIcon from 'modules/v2/common/components/SvgIcon/PublishBookIcon';
import StepCheckIcon from 'modules/v2/common/components/SvgIcon/StepCheckIcon';
import AlertSolidWarningIcon from 'modules/v2/common/components/SvgIcon/AlertSolidWarningIcon';
import CloseXIcon from 'modules/v2/common/components/SvgIcon/CloseXIcon';
import { InfoIconNew } from 'modules/v2/common/components/SvgIcon/InfoIconNew';

import {
  generatedMagzineRefetchInterval,
  monthsList,
  rmMagazineStatus,
  rmMagzineEditorSteps,
  stepHavingForms,
  stepsList,
} from '../../constants';
import {
  BackCoverStepPage,
  BackInsideCover,
  FrontCoverStepPage,
  FrontInsideCover,
  GenerateMagazineStepPage,
  MagazineStepPage,
} from '../Steps';

import S from '../../styles';
import { initialValues } from './initialFormState';
import { formKeywordList, magazineFormikStepsList } from './constant';
import { getFormattedValues } from './utils';
import magazineEditorValidationSchema from './validationSchema';

const MagazineEditor = ({ history }) => {
  const { values: referralMagazineContext, setFieldValue: setFormikContext } = useFormikContext();
  const { current } = referralMagazineContext;

  const params = useParams();

  const [magazine, setMagazine] = useState({
    pdf: '',
    frontInsideCoverText: '',
    additionalInformation: '',
    isFormUpdated: false,
    previewPdf: '',
    maxCoverLetterLength: 1500,
  });
  const [showImageLightBox, setShowImageLightBox] = useState(false);
  const [imageLightBox, setImageLightBox] = useState(null);
  const [presentMagazineID, setPresentMagazineID] = useState('');
  const [refetchGeneratedMagazine, setRefetchGeneratedMagazine] = useState(false);
  const [currentMagazineStatus, setCurrentMagazineStatus] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isPublishedError, setPublishedError] = useState(false);
  const [stepError, setStepError] = useState(-1);
  const [stepSucceed, setStepSucceed] = useState([]);
  const [previousMagazineData, setPreviousMagazineData] = useState({});

  const savedMessage = isAdminEditing()
    ? 'Magazine changes saved!'
    : 'My magazine changes have been saved';

  const redirectUserToNextStep = (magazineData) => {
    const { baseReplacers = {}, selections = [] } = magazineData;
    let nextStep = 0;

    if (baseReplacers?.length) {
      nextStep = 1;
    }
    if (selections.length) {
      const presentData = [...selections.filter((stepData) => stepData.page)];
      const lastIndex = presentData.length - 1;
      nextStep = presentData[lastIndex].page + 1;
    }

    if (isAdminEditing()) nextStep = 0;

    return setFormikContext('current', nextStep);
  };

  const { data: magazinePreviewData } = useQuery(['getMagazinePreview', params.previewId], () =>
    getMagazinePreview(params.previewId),
  );

  const { customerInfo, url: previewUrl } = get(magazinePreviewData, 'data', {});
  const { firstName, lastName } = head(customerInfo) || {};
  const fullName = `${firstName} ${lastName}`;

  // Mutation to generate magazine in last step.
  const { mutate: generateMagazine, isLoading: isGeneratingMagazine } = useMutation(
    (payload) =>
      isAdminEditing() ? generateReferralMagazineAdmin(payload) : generateReferralMagazine(payload),
    {
      onSuccess: () => {
        /*  eslint-disable no-use-before-define */
        removeGeneratedMagazineInfo();
        setRefetchGeneratedMagazine(generatedMagzineRefetchInterval);
        notification.success({
          description: savedMessage,
          autoClose: 4000,
        });
      },
      onError: () => {
        notification.error({ description: 'Something went wrong', autoClose: 4000 });
      },
    },
  );

  const { mutate: updateMagazineById, isLoading: updatingGeneratedMagazineById } = useMutation(
    (req) => updateGeneratedMagazineById(req),
    {
      onSuccess: () => {
        notification.success({
          description: savedMessage,
          autoClose: 4000,
        });
        return false;
      },
      onError: () => {
        notification.error({
          description: 'Something went wrong',
          autoClose: 4000,
        });
      },
    },
  );

  const { mutate: updateGeneratedMagazine, isLoading: updatingGeneratedMagazine } = useMutation(
    (payload) =>
      isAdminEditing() ? generateReferralMagazineAdmin(payload) : generateReferralMagazine(payload),
    {
      onSuccess: (data) => {
        if (current === 5 && !isGeneratingMagazine) {
          notification.success({
            description: savedMessage,
            autoClose: 4000,
          });

          const parameters = {
            month: monthsList[get(data, 'magazine.month')],
            year: get(data, 'magazine.year'),
          };

          if (isAdminEditing()) {
            return history.push(
              getRouteRmMagazinePreview(
                generatedMagazineInfo?.id || params.previewId,
                params.customerId,
              ),
            );
          }

          return history.push(ROUTE_REFERRAL_MARKETING_GENERATED_MAGAZINE, {
            magazineId: presentMagazineID,
            params: parameters,
          });
        }
        return false;
      },
      onError: () => {
        notification.error({
          description: 'Something went wrong',
          autoClose: 4000,
        });
      },
    },
  );

  // Update the referral magazine
  const { mutate: sendToPrinting } = useMutation(
    (payload) => (isAdminEditing() ? createSetupTicket(payload) : sendMagazineToPrint(payload)),
    {
      onSuccess: (data) => {
        setCurrentMagazineStatus(get(data, 'status'));
      },
      onError: () => {
        notification.error({
          description: 'Something went wrong',
          autoClose: 4000,
        });
      },
    },
  );

  // get the generated magazine and update the additional Information
  let getGeneratedMagazineQueryKey = ['getGeneratedMagazineMonth', params.year, params.month];
  let getGeneratedMagazineFn = getGeneratedMagazineMonth;

  if (isAdminEditing()) {
    getGeneratedMagazineQueryKey = [
      'getGeneratedMagazineAdmin',
      params.year,
      params.month,
      params.customerId,
    ];
    getGeneratedMagazineFn = getGeneratedMagazineAdmin;
  }

  const {
    data: toFormat = {},
    remove: removeGeneratedMagazineInfo,
    refetch: fetchGeneratedMagazine,
  } = useQuery(getGeneratedMagazineQueryKey, getGeneratedMagazineFn, {
    refetchInterval: refetchGeneratedMagazine,
    onSuccess: (generatedMagazineData) => {
      const updatedMagazineData = {
        ...magazine,
        additionalInformation: get(generatedMagazineData, 'additionalInformation') || '',
        bookUrl: get(generatedMagazineData, 'bookUrl') || '',
        pageUrl: get(generatedMagazineData, 'pageUrl') || '',
      };
      setCurrentMagazineStatus(get(generatedMagazineData, 'magazine.status', ''));
      setMagazine(updatedMagazineData);
      const isStatusDone = generatedMagazineData?.status === 'DONE';
      if (isStatusDone) {
        setRefetchGeneratedMagazine(false);
      }
      return false;
    },
  });

  const generatedMagazineInfo = Array.isArray(toFormat) ? toFormat[0] : toFormat;
  const isMagazineSentToPrinting = isAdminEditing()
    ? false
    : currentMagazineStatus === rmMagazineStatus.SENT_FOR_PRINTING;

  // Magazine data coming from strapi.
  const {
    isLoading: isStepsInfoFetching,
    data: magazinePageData = {},
    isSuccess: hasMagazinePageData,
  } = useQuery(['getMagazineStepsInfo', params.month, params.year], getMagazineStepsInfo, {
    keepPreviousData: true,
    onSuccess: (data) => {
      const {
        frontCoverDesign,
        frontCoverStrip,
        backCover,
        frontInsideCover,
        backInsideCover,
        pdf,
        previewPdf,
      } = data || {};

      const optionsDefaultValue = {
        backInsideCover: backInsideCover?.[0],
        backCover: backCover?.[0],
        frontInsideCover: frontInsideCover?.[0],
        frontCoverDesign: frontCoverDesign?.[0],
        frontCoverStrip: frontCoverStrip?.[0],
        pdf,
        previewPdf,
        maxCoverLetterLength: 1500,
      };
      return setMagazine({ ...magazine, ...optionsDefaultValue });
    },
  });

  let getMagazineQueryKey = ['getMagazine', params.year, params.month];
  let getMagazineFn = () => getMagazine(params.year, params.month);

  if (isAdminEditing()) {
    getMagazineQueryKey = ['getMagazineAdmin', params.year, params.month, params.customerId];
    getMagazineFn = getMagazineAdmin;
  }

  const { data: magazineFromDatabase = {}, refetch: refetchMagazineFromDb } = useQuery(
    getMagazineQueryKey,
    getMagazineFn,
    {
      enabled: hasMagazinePageData,
      keepPreviousData: true,
      onSuccess: async (data) => {
        if (!data) {
          const payload = {
            magazineId: magazinePageData?.id,
          };
          return createMagazine({ payload });
        }

        const hasValues = Object.keys(data || {})?.length;
        setPresentMagazineID(data?.id);
        const hasSameValues = isEqual(data?.id, get(magazineFromDatabase, 'id'));
        if (hasValues) {
          //  Check if we have data till backCover option
          const selections = get(data, 'selections');

          const hasBackCoverValue =
            selections.find((step) => step.formKeyword.includes('backCover'))?.formKeyword ?? false;

          if (!generatedMagazineInfo?.id && hasBackCoverValue) {
            if (!hasSameValues) {
              await generateMagazine({ payload: params, isPreview: true });
              fetchGeneratedMagazine();
              refetchMagazineFromDb();
              return false;
            }
          }
          if (!generatedMagazineInfo?.id) redirectUserToNextStep(data);
          initialValues.magazine._formFields = get(data, 'baseReplacers[0]');
          // eslint-disable-next-line no-use-before-define
          syncFormikWithLastMagazineData(data);
          setPreviousMagazineData(data);
        }
        return true;
      },
    },
  );

  const { frontCoverDesign, frontCoverStrip, backCover, frontInsideCover, backInsideCover } =
    magazinePageData || {};

  const { mutate: updateMagazine, isLoading: isUpdatingMagazine } = useMutation(
    (req) => {
      if (req?.payload?.selection?.formKeyword === 'frontInsideCover-option-1') {
        req.payload.selection.dynamicFields = replaceFinalPayload(req);
      }
      if (isAdminEditing()) {
        return updateReferralMagazineAdmin(req);
      }
      return updateReferralMagazine(req);
    },
    {
      onSuccess: async (data) => {
        syncFormikWithLastMagazineData(data);
        setPreviousMagazineData(data);

        if (current === 4) {
          await generateMagazine({ payload: params, isPreview: true });
        }
        notification.success({
          description: savedMessage,
          autoClose: 4000,
        });
        return setFormikContext('current', current + 1);
      },
      onError: () => {
        notification.error({
          description: 'Something went wrong',
          autoClose: 4000,
        });
      },
    },
  );

  // Initial api call to create magazine draft from step 1.
  const { mutate: createMagazine, isLoading: isCreatingMagazine } = useMutation(
    (payload) => createReferralMagazine(payload),
    {
      onSuccess: (data) => {
        setPresentMagazineID(data.id);
        notification.success({
          description: savedMessage,
          autoClose: 4000,
        });
        return setFormikContext('current', current + 1);
      },
    },
  );

  /* eslint no-underscore-dangle: ['error', { 'allow': ['_formFields', '_id'] }] */
  const magazineDataFormik = useFormik({
    initialValues,
    validationSchema: magazineEditorValidationSchema(),
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async () => {
      const stepName = magazineFormikStepsList[current];
      const payload = {
        magazineId: magazinePageData.id,
      };
      const generatedMagzineExists = presentMagazineID || generatedMagazineInfo?.id;
      const location = 'frontInsideCover'.concat('.', '_formFields', '.', 'frontInsideCoverText');
      const data = replaceNewLineWithBR(
        magazineDataFormik.values.frontInsideCover._formFields.frontInsideCoverText,
      );
      await setFieldValue(location, data);

      const { baseReplacers: formikBaseReplacers = {} } = magazineDataFormik.values;

      validateStepFormFields();

      if (current === 0) {
        payload.baseReplacers = magazineDataFormik.values[stepName]._formFields;
        const { setFieldValue } = magazineDataFormik;
        await setFieldValue('baseReplacers', payload.baseReplacers);

        const actionToPerform = generatedMagzineExists ? updateMagazine : createMagazine;
        const req = {
          payload,
          ...(generatedMagzineExists && { month: params.month }),
          ...(generatedMagzineExists && { year: params.year }),
          ...(generatedMagzineExists && { customerId: params.customerId }),
        };
        return actionToPerform(req);
      }
      const selectedOption = magazine[stepName] || {};

      if (current === 5) {
        payload[stepName] = magazine[stepName] ?? '';
        payload.isPreview = false;

        const request = {
          bookUrl: get(magazine, 'bookUrl') || generatedMagazineInfo?.bookUrl,
          pageUrl: get(magazine, 'pageUrl') || generatedMagazineInfo?.pageUrl,
          flippingBookUrl:
            get(magazine, 'flippingBookUrl') || generatedMagazineInfo?.flippingBookUrl,
          additionalInformation:
            get(magazine, 'additionalInformation') || generatedMagazineInfo?.additionalInformation,
        };

        updateMagazineById({
          id: generatedMagazineInfo?.id,
          payload: request,
        });
      }

      payload.selection = {
        page: current,
        formKeyword: selectedOption?.formKeyword ?? stepName,
      };

      if (stepHavingForms.includes(current)) {
        const currentStepFormDataFields = magazineDataFormik.values[stepName]._formFields || {};
        payload.selection.dynamicFields = commonFunctions.convertObjectToArrayOfObject(
          currentStepFormDataFields,
        );
        const hasValues = Object.keys(formikBaseReplacers)?.length;
        if (hasValues) {
          payload.baseReplacers = formikBaseReplacers;
        }
      }

      if (current === 1) {
        const htmlReplacer = (magazine.frontCoverStrip?.formKeyword ?? '').split('-')[0];
        payload.selection.formKeyword = magazine.frontCoverDesign?.formKeyword;
        payload.selection.extraHtml = {
          formKeyword: magazine.frontCoverStrip?.formKeyword,
          dynamicFields: [...payload.selection.dynamicFields],
          htmlReplacer,
        };
        payload.selection.dynamicFields = [];
      }
      return updateMagazine({ ...params, payload });
    },
  });

  const { setFieldValue } = magazineDataFormik;

  const updateFormikWithPreviousData = (formattedValues, formKeyword, page) => {
    const lastValue = {};

    const currentStep = magazineFormikStepsList[page];
    const formKeywordText = formKeyword.split('-');
    const keyFromMagazine = formKeywordList[formKeywordText[0]];
    const currentStepMagazineData = magazinePageData[keyFromMagazine] || [];
    lastValue[keyFromMagazine] = currentStepMagazineData.find(
      (option) => option.formKeyword === formKeyword,
    );
    const valueToInitialize = {
      [currentStep]: {
        _formFields: formattedValues,
      },
    };
    return { lastValue, valueToInitialize };
  };

  const syncFormikWithLastMagazineData = (lastMagazineData) => {
    const filledSteps = lastMagazineData.selections;

    let magazineData = { ...magazine };
    const { resetForm } = magazineDataFormik;
    let reInitializeFormikValues = {};

    for (let index = 0; index < filledSteps.length; index++) {
      const stepInfo = filledSteps[index];
      const { dynamicFields = [], formKeyword, page, extraHtml = {} } = stepInfo;
      const formattedValues = getFormattedValues(dynamicFields);
      const {
        lastValue,
        valueToInitialize: stepValueToReInitialize,
      } = updateFormikWithPreviousData(formattedValues, formKeyword, page);
      reInitializeFormikValues = {
        ...reInitializeFormikValues,
        ...stepValueToReInitialize,
      };
      magazineData = { ...magazineData, ...lastValue };
      const hasExtraInfo = Object.keys(extraHtml)?.length;
      if (hasExtraInfo) {
        const {
          dynamicFields: extraDynamicFields = [],
          formKeyword: extraInfoFormKeyword,
        } = extraHtml;
        const extraValues = getFormattedValues(extraDynamicFields);
        const {
          lastValue: previousValue,
          valueToInitialize: extraValueToReInitialize,
        } = updateFormikWithPreviousData(extraValues, extraInfoFormKeyword, page);
        reInitializeFormikValues = {
          ...reInitializeFormikValues,
          ...extraValueToReInitialize,
        };
        magazineData = { ...magazineData, ...previousValue };
      }
    }
    reInitializeFormikValues = {
      ...reInitializeFormikValues,
      baseReplacers: get(magazineDataFormik.values, 'magazine._formFields'),
    };

    resetForm({
      values: { ...magazineDataFormik.values, ...reInitializeFormikValues },
    });
    return setMagazine(magazineData);
  };

  const handleImageLightBox = (imageForLightBox) => {
    setShowImageLightBox(!showImageLightBox);
    setImageLightBox({
      src: imageForLightBox,
      alt: 'Magazine option',
      loading: 'lazy',
    });
  };

  const handleUpdateMagazine = (newValue, keyToUpdate) => {
    const latestMagazineData = { ...magazine, [keyToUpdate]: newValue };
    setMagazine(latestMagazineData);
    setFieldValue('selectedOption', newValue.formKeyword);
  };

  useEffect(() => {
    setFieldValue('currentStep', current);
  }, [current, setFieldValue, isMagazineSentToPrinting, setFormikContext]);

  useEffect(() => {
    if (stepError > 0) {
      setFormikContext('current', stepError);
      setPublishedError(true);
    }

    return () => {
      setFormikContext('current', 0);
    };
  }, [setFormikContext, stepError]);

  const goToNextStep = () => {
    return setFormikContext('current', current + 1);
  };

  const goToPreviousStep = () => {
    return setFormikContext('current', current - 1);
  };

  const onPublishMagazine = async () => {
    const hasError = await validateStepFormFields();

    if (hasError) return;

    const payload = {
      magazineId: magazinePageData.id,
    };

    const location = 'frontInsideCover'.concat('.', '_formFields', '.', 'frontInsideCoverText');
    const data = replaceNewLineWithBR(
      magazineDataFormik.values.frontInsideCover._formFields.frontInsideCoverText,
    );
    await setFieldValue(location, data);

    const stepName = magazineFormikStepsList[5];
    payload[stepName] = magazine[stepName] ?? '';
    payload.isPreview = false;

    const request = {
      bookUrl: get(magazine, 'bookUrl') || generatedMagazineInfo?.bookUrl,
      pageUrl: get(magazine, 'pageUrl') || generatedMagazineInfo?.pageUrl,
      flippingBookUrl: get(magazine, 'flippingBookUrl') || generatedMagazineInfo?.flippingBookUrl,
      additionalInformation:
        get(magazine, 'additionalInformation') || generatedMagazineInfo?.additionalInformation,
    };

    updateMagazineById({
      id: generatedMagazineInfo?.id,
      payload: request,
    });

    setFormikContext('current', 5);
    // eslint-disable-next-line consistent-return
    updateGeneratedMagazine({
      payload: params,
      isPreview: payload.isPreview,
    });

    const magazinePayload = {
      id: generatedMagazineInfo?.id,
    };
    // eslint-disable-next-line consistent-return
    return sendToPrinting({ ...params, ...magazinePayload });
  };

  const validateStepFormFields = async (step = 0) => {
    const currentStep = current === -1 ? 0 : current;
    const stepName = magazineFormikStepsList[currentStep];
    const { validateForm } = magazineDataFormik || {};

    const hasError = await validateForm().then((err) => {
      if (Object.prototype.hasOwnProperty.call(err, stepName)) {
        setStepError(current);
        return true;
      }
      return false;
    });

    if (!hasError) {
      setStepSucceed((prevStep) => [...prevStep, current]);
      setFormikContext('current', step);
      setStepError(0);
    } else {
      setPublishedError(hasError);
      setStepSucceed((prevStep) => prevStep.filter((stp) => stp !== current));
    }

    return hasError;
  };

  const renderCurrentStep = (step) => {
    const nextStepExists = rmMagzineEditorSteps.length;
    const buttonText = current === 5 ? 'Generate Magazine' : 'Next Step';
    const isMagazineAlreadyPrinted = isAdminEditing()
      ? false
      : get(magazineFromDatabase, '[0].status') === rmMagazineStatus.SENT_FOR_PRINTING;
    switch (step) {
      case 0:
        return (
          <MagazineStepPage
            step="magazine"
            goToNextStep={goToNextStep}
            updateMagazineData={{
              magazine,
              handleUpdateMagazine,
              magazineDataFormik,
              createMagazine,
              setFormikContext,
            }}
            disabled={isMagazineAlreadyPrinted}
            params={params}
            previewUrl={previewUrl}
          />
        );
      case 1:
        return (
          <FrontCoverStepPage
            handleImageLightBox={handleImageLightBox}
            updateMagazineData={{
              magazine,
              handleUpdateMagazine,
              magazineDataFormik,
              updateMagazine,
              previousMagazineData,
              setFormikContext,
            }}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            frontCoverDesign={frontCoverDesign}
            frontCoverStrip={frontCoverStrip}
          />
        );
      case 2:
        return (
          <FrontInsideCover
            updateMagazineData={{
              magazine,
              handleUpdateMagazine,
              magazineDataFormik,
              previousMagazineData,
              setFormikContext,
            }}
            frontInsideCover={frontInsideCover}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case 3:
        return (
          <BackInsideCover
            handleImageLightBox={handleImageLightBox}
            updateMagazineData={{
              magazine,
              handleUpdateMagazine,
              magazineDataFormik,
              previousMagazineData,
              setFormikContext,
            }}
            backInsideCover={backInsideCover}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case 4:
        return (
          <BackCoverStepPage
            handleImageLightBox={handleImageLightBox}
            updateMagazineData={{
              magazine,
              handleUpdateMagazine,
              magazineDataFormik,
              previousMagazineData,
              setFormikContext,
            }}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            backCoverList={backCover}
          />
        );
      case 5:
        return (
          <GenerateMagazineStepPage
            generatedMagazineInfo={generatedMagazineInfo}
            updateMagazineData={{
              magazine,
              handleUpdateMagazine,
              magazineDataFormik,
              setFormikContext,
            }}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      default:
        return (
          <S.CurrentStep>
            You are on {rmMagzineEditorSteps[step]} step.
            {current < nextStepExists && (
              <S.BottomWrap>
                <PrimaryButton onClick={goToNextStep}>{buttonText}</PrimaryButton>
              </S.BottomWrap>
            )}
          </S.CurrentStep>
        );
    }
  };

  const showLoader =
    isStepsInfoFetching ||
    isCreatingMagazine ||
    isUpdatingMagazine ||
    updatingGeneratedMagazine ||
    updatingGeneratedMagazineById;

  if (!showLoader && !magazinePageData?.id) {
    return (
      <div className="relative w-full h-full min-h-[630px] overflow-hidden">
        <div className="w-full h-full flex flex-col justify-center bg-white rounded-lg p-6 shadow-box">
          <div className="frame_280 flex flex-col justify-center items-center self-stretch gap-4">
            <div className="frame_824 flex flex-col justify-center items-center self-stretch">
              <Alert className="frame_191 flex flex-col items-center self-stretch">
                <div className="self-stretch text-neutral-800 text-center  text-base font-semibold">
                  No active magazine for this month of
                  <span className="capitalize">{params.month}</span> {params.year}
                </div>
                <div className="text-neutral-600 text-centertext-sm font-medium leading-[1.4375rem]">
                  You haven't ordered any magazines yet
                </div>
              </Alert>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showLoader) {
    return (
      <>
        <div className="w-full h-dvh flex items-center justify-center">
          <div className="flex flex-col gap-2 items-center justify-center bg-white w-80 h-52 rounded-xl shadow-box py-8">
            <Spinner />
            <span className="text-neutral-800 text-base font-bold mt-6">
              Publishing your magazine...
            </span>
            <span className="text-neutral-500 text-sm font-medium">
              Personalizing your digital magazine
            </span>
          </div>
        </div>
      </>
    );
  }

  const onLightboxClose = () => setShowImageLightBox(false);
  const lightBoxStyles = { background: 'rgba(0,0,0,0.5)' };

  const magazinePreview = previewUrl ?? magazine?.previewPdf;

  return (
    <div className="flex flex-col w-full font-figtree">
      <S.SubPageContainer width="100%">
        <>
          <div className="w-full flex justify-between items-center self-stretch flex-wrap max-[700px]:justify-center">
            <div className="text-neutral-800 text-2xl font-bold">RM Magazine editor</div>
            <div className="flex items-center flex-wrap justify-center gap-2">
              <Button
                buttonType="button"
                onClick={() => setOpenModal(true)}
                size="lg"
                color="light"
              >
                See sample preview
              </Button>
              <Button
                buttonType="button"
                disabled={isMagazineSentToPrinting}
                onClick={() => onPublishMagazine()}
                size="lg"
                type="primary"
              >
                <PublishBookIcon />
                Publish magazine
              </Button>
            </div>
          </div>
          <div>
            {isPublishedError && (
              <Alert color="failure" className="my-6">
                <div className="w-full flex gap-4 items-center">
                  <AlertWarning />
                  <span className="font-medium text-error-500">
                    Please complete all required steps to publish your magazine
                  </span>
                </div>
              </Alert>
            )}
            {isAdminEditing() && (
              <Alert color="info" className="my-6 flex ga-3">
                <div className="w-full flex gap-4 items-center">
                  <InfoIconNew fill="#0e7490" />
                  <span className="font-medium text-info-500">
                    You are editing the magazine of <span className="capitalize">{fullName}.</span>
                    <a href={getRouteRmMagazineReport()}>Back to report</a>
                  </span>
                </div>
              </Alert>
            )}
            {isMagazineSentToPrinting && (
              <Alert color="warning" className="bg-secondary-orange-50 my-6">
                <div className="w-full flex gap-4 items-center">
                  <AlertWarning />
                  <span className="font-medium text-notificationWarning">
                    If everything looks good, click “Publish” to send your magazine for printing.
                    You can only send revisions once. If you wish to do more revisions, please
                    contact
                    <a
                      href="https://authorify.com/contact-us/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-notificationWarning hover:text-notificationWarning underline pl-1"
                    >
                      support
                    </a>
                    .
                  </span>
                </div>
              </Alert>
            )}
          </div>
          <div className="mt-6 rounded-lg bg-white border-neutral-300 shadow-box">
            <div className="flex items-center gap-6 self-stretch pt-[1.375rem] pb-[1.375rem] px-6 border-b border-b-neutral-200">
              <div className="frame_787 flex flex-col justify-center items-start">
                <div className="text-boxTitle font-semibold capitalize">{`${params.month} ${params.year} Magazine`}</div>
                <div className="text-neutral-500 text-sm font-medium">
                  The information provided here will be used for the magazine. Customization options
                  available in these steps are free of charge.
                </div>
              </div>
            </div>
            {/** Steps */}
            <div className="flex flex-col items-start self-stretch px-0">
              {stepsList.map(({ step, title }) => (
                <div
                  key={title}
                  className="flex flex-col items-center self-stretch px-6 py-5 border-b"
                >
                  <div key={title} className="flex items-center gap-5 self-stretch">
                    {isMagazineSentToPrinting && (
                      <>
                        {isPublishedError && step === stepError ? (
                          <AlertSolidWarningIcon />
                        ) : (
                          <div className="bg-success-500 rounded-full p-1.5">
                            <StepCheckIcon />
                          </div>
                        )}
                      </>
                    )}
                    {!isMagazineSentToPrinting && isPublishedError && (
                      <>{step === stepError && <AlertSolidWarningIcon />}</>
                    )}
                    {!isMagazineSentToPrinting && (
                      <>
                        {stepSucceed.includes(step) ? (
                          <div className="bg-success-500 rounded-full p-1.5">
                            <StepCheckIcon />
                          </div>
                        ) : (
                          <>
                            {stepError !== step && (
                              <div
                                className={`step_${step} flex justify-center items-center w-[1.875rem] h-[1.875rem] rounded-full border-2 ${
                                  current === step
                                    ? 'bg-neutral-800 text-white border-neutral-800'
                                    : 'bg-none text-neutral-400 border-neutral-400'
                                }`}
                              >
                                <div className="flex flex-col flex-shrink-0 p-0.5 justify-center items-center gap-2.5 text-sm font-semibold">
                                  {step + 1}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <div className="frame_792-1 w-full flex justify-between items-center">
                      <div
                        className={`${
                          current === step ? 'text-neutral-800' : 'text-neutral-400'
                        } text-base font-semibold`}
                      >
                        {title}
                      </div>
                      <button
                        color="light"
                        onClick={() => {
                          validateStepFormFields(step);
                        }}
                        type="button"
                        className={`${
                          current === step ? 'hidden' : 'text-neutral-800'
                        } flex w-[110px] justify-center items-center border text-neutral-800 px-[18px] py-[13px] rounded-md border-solid border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50`}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  <div className="w-full pl-[50px]">
                    {current === step && renderCurrentStep(current)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/** Modal */}
          <Modal
            dismissible
            show={openModal}
            onClose={() => setOpenModal(false)}
            size="6xl"
            theme={{
              body: {
                base: 'h-[631px] p-6',
              },
              sizes: {
                '6xl': 'max-w-6xl',
              },
            }}
          >
            <div>
              <div className="flex justify-between items-center self-stretch p-6">
                <div className="text-neutral-800 font-bold">
                  <span className="capitalize mr-[2px]">
                    {params.month} {params.year}
                  </span>
                  sample magazine
                </div>
                <Button
                  onClick={() => setOpenModal(false)}
                  type="button"
                  color="light"
                  className="flex justify-center items-center gap-2 p-2.5 p-2 w-9 h-9 rounded-md bg-neutral-100 focus:ring-0"
                >
                  <CloseXIcon />
                </Button>
              </div>
              <div className="flex items-start gap-3 self-stretch py-2 px-3 rounded-md bg-secondary-orange-50 mx-6">
                <AlertWarning />
                <div className="content flex flex-col items-start gap-2">
                  <div className="list flex flex-col items-start self-stretch">
                    <div className="alerts_list_item flex items-start self-stretch text text-secondary-orange-600 text-sm font-semibold">
                      This preview is a sample only and NOT your finalized version. If all inputted
                      information is correct, our setup team will send a proof link via your
                      registered email with the accurate magazine preview within 24-48 business
                      hours upon receiving the order
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal.Body>
              {magazinePreview ? (
                <afy-flip-book key="magazine-step-pdf" pdfurl={magazinePreview} />
              ) : null}
            </Modal.Body>
          </Modal>
        </>
      </S.SubPageContainer>
      <Lightbox
        isOpen={showImageLightBox}
        images={[imageLightBox]}
        // As we are passing array wth 1 image only
        currentIndex={0}
        style={lightBoxStyles}
        onClose={onLightboxClose}
      />
    </div>
  );
};

export default MagazineEditor;
