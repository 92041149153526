import styled from 'styled-components';
import { Table as TableAntd } from 'antd';
import { Link } from 'react-router-dom';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Table = styled(TableAntd)`
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0 40px;
  max-width: 100%;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  font-family: ${font.NunitoFont};

  .ant-table {
    font-size: 14px;
    font-weight: 400;
  }

  tr.ant-table-row-selected {
    background-color: ${color.TableRowSelected} !important;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
  }

  .ant-table-container {
    border: none !important;
  }

  .ant-table-thead > tr > th {
    background: ${color.grayTable} !important;
    border-color: ${color.grayTable} !important;
    font-size: 14px;
    font-weight: bold;
    overflow-wrap: normal;
  }

  .ant-table-row {
    height: 70px !important;
  }

  .ant-table-cell {
    border-color: ${color.grayTable} !important;
    border-right: none !important;
  }

  .ant-table-middle > .ant-table-footer {
    display: none;
  }

  //table pagination
  .ant-pagination {
    display: flex;
    align-items: center;
  }
  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-pagination-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    margin-right: auto;
    padding: 10px 14px;
  }

  .ant-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    margin-left: auto;
    padding: 10px 14px;
  }

  .ant-pagination-disabled {
    opacity: 0.5;
  }

  .ant-pagination-item {
    border: none;
  }

  .ant-pagination-next,
  .ant-pagination-jump-next {
    .ant-btn {
      :hover {
        border: none;
        border-radius: 4px;
        color: ${color.White} !important;
        background-color: ${color.ActivePageHover} !important;
      }
    }
  }
`;

Table.Link = styled(Link)`
  color: ${color.SkyBlue};
  text-decoration: none !important;
  font-weight: normal !important;
`;

Table.Container = styled.div`
  flex-direction: column;
  background-color: ${color.GreyHover};
  border: 1px solid ${color.GreyBorder};
  max-width: 100%;
  margin: 20px 0 0 0px;
  border-radius: 5px;
`;

Table.HeadRow = styled.tr`
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  height: 35px;
  overflow: hidden;
  box-shadow: 0 0 20px ${color.Black} 0.1;
  background-color: ${color.GreyHover};
  border: 1px solid ${color.GrayBorder};
  opacity: 1;
`;

Table.BodyRow = styled.tr`
  background: 0% 0% no-repeat padding-box;
  border: 1px solid ${color.GrayBorder};
  border-left: 0;
  border-right: 0;
  opacity: 1;
`;

Table.Heading = styled.th`
  background-color: ${color.GreyHover};
  font-size: 16px;
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
  font-weight: 700;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
  padding-left: 20px;
`;

Table.Body = styled.tbody`
  border-bottom: 1px solid ${color.TableBorder};
`;

Table.Footer = styled.tfoot`
  height: 10px;
  background: ${color.GreyHover};
  border: 1px solid ${color.LightDullGray};
  margin-top: -3px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
`;

Table.NoData = styled.td.attrs({
  colSpan: 5,
})`
  background: ${color.White} padding-box;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
  height: 45px;
  padding-left: 20px;

  a {
    text-decoration: underline !important;
    font-weight: bold;
  }
`;

Table.Data = styled.td`
  background: ${color.White} padding-box;
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
  font-size: 14px;
  letter-spacing: 0px;
  color: ${color.Black};
  opacity: 1;
  height: 45px;
  padding-left: 20px;
`;

Table.CheckBox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;

Table.Footer = styled.tfoot`
  display: table-footer-group;
`;

const PageDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  color: ${color.gray100};
  font-size: 16px;
`;

export default {
  Table,
  PageDescription,
};
