import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const ErrorStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-top: 100px;
`;

const ErrorMessage = styled.p`
  text-align: center;
  font-size: 14px;
  color: ${color.Black};
  margin-top: 0.8rem;
  &:last-child {
    text-align: center;
    font-size: 14px;
    color: ${color.Black};
    margin-top: 2rem !important;
  }
`;

export default {
  ErrorStateWrapper,
  ErrorMessage,
};
