import { getAutoLoginAuthToken } from 'modules/api';
import { clearStorage, getItem, setItem } from 'modules/dashboard/utils/legacy';
import { getCurrentStep, STEPS } from 'modules/onboard/utils';

export const getOnboardSession = (offerCode) => {
  try {
    const session = getItem('onboardSession', 'string');
    const parsedSession = JSON.parse(session) || {};
    const sessionObj = parsedSession[offerCode];

    return { session: sessionObj };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return {};
};

export const onboardGuard = (props) => {
  const {
    location: { pathname },
    match: {
      params: { offerCode },
    },
  } = props;

  const { session } = getOnboardSession(offerCode);

  if (!session) {
    return null;
  }

  const parsePathname = getCurrentStep(pathname);
  const currentStep = STEPS[parsePathname]?.path;
  const { step: sessionStep } = session || {};
  const sessionStepFormated = sessionStep?.replace(/_/g, '-');
  const email = session?.customer?.email;
  const autoLoginToken = session?.autoLoginToken;

  getAutoLoginAuthToken(autoLoginToken, email).then((res) => {
    if (res.token) {
      setItem('token', res.token);
      setItem('refresh_token', res.refresh_token);
    }
    return res;
  });

  const isPlaceOrder = pathname.includes('place-order');
  const isTrainingWebinar = pathname.includes('training-webinar');
  const isBookDetails = pathname.includes('book-details');
  const isBookSummary = pathname.includes('your-book');
  const isOrderConfirmation = pathname.includes('order-confirmation');
  const isYourGuide = pathname.includes('your-guide');
  const isNotCurrentStep = sessionStep && currentStep !== sessionStepFormated;
  const nextStep = STEPS[sessionStep]?.path;
  const selectedSlot = getItem('selectedSlot');

  if (sessionStep === 'done') {
    clearStorage();
  }

  if (!isNotCurrentStep) return null;

  if (isNotCurrentStep) {
    if (selectedSlot && !isPlaceOrder) {
      return null;
    }

    return { pathname: `/onboard/${nextStep}/${offerCode}` };
  }

  if (getItem('orderConfirmation') && !isOrderConfirmation && !isYourGuide) {
    return { pathname: `/onboard/${sessionStepFormated}/${offerCode}` };
  }

  if (
    (isTrainingWebinar || isBookDetails || isBookSummary || isOrderConfirmation) &&
    !selectedSlot
  ) {
    return { pathname: `/onboard/schedule-coaching/${offerCode}` };
  }

  if (!session && currentStep !== 'place-order') {
    return { pathname: `/onboard/place-order/${offerCode}` };
  }

  if (sessionStep === currentStep) {
    return { pathname: `/onboard/${nextStep}/${offerCode}` };
  }

  return null;
};
