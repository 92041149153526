import { get } from 'lodash-es';

export const element = (data, fileName) => {
  const blob = get(data, 'data');
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};
