import styled, { createGlobalStyle } from 'styled-components';
import * as color from 'modules/common/theme/color';

const DragDropWrapper = styled.div`
  border: 2px dotted ${color.GreyFormBorder};
  border-radius: 3px;
  background-color: #a29a9a;
  margin: ${({ noMargin }) => (noMargin ? '0' : '20px')};
  font-weight: normal;
  font-size: 16px;
  min-height: 210px;
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-height: 70vh !important;
  max-width: 90vw;
  overflow: auto;

  > div {
    width: 100%;
    outline: 0;
    cursor: pointer;
  }

  canvas {
    width: 640px !important;
    max-height: 400px !important;
  }

  svg {
    fill: #9c9a9a;
    width: 75px;
    height: 45px;
    margin: 0 auto;
    display: block;
  }
`;

const UploadText = styled.div`
  color: ${color.GreySelectDd};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  padding: 5px 0;
`;
const ModalWrapper = styled.div`
  padding: 2rem;
  background: ${color.White};
  max-height: 500px !important;
  overflow: scroll;
  text-align: center;
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin: 0 20px;
`;

const Btn = styled.button`
  border: 1px solid ${color.neutral200};
  padding: 13px 18px;
  font-weight: 700;
  border-radius: 6px;
`;

const PrimaryBtn = styled(Btn)`
  background-color: ${color.Black};
  color: ${color.White};
`;

const InputRange = styled.input`
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-bottom: 30px;

  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(
      to right,
      #4cc9e1,
      #4cc9e1 var(--value),
      #d7d7d7 var(--value),
      #d7d7d7
    );
  }

  &::-webkit-slider-thumb {
    border: 2px solid #d7d7d7 !important;
    background: ${color.White} !important;
    margin-top: -8px;
  }
`;

const ResetModalHeightStyle = createGlobalStyle`
  .ant-modal-body {
    max-height: 100% !important;
  
  }
`;

export default {
  DragDropWrapper,
  UploadText,
  ModalWrapper,
  BtnWrapper,
  Btn,
  PrimaryBtn,
  InputRange,
  ResetModalHeightStyle,
};
