export default function AppleIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0958 11.7242C12.8923 12.19 12.6515 12.6188 12.3724 13.0131C11.992 13.5505 11.6806 13.9225 11.4406 14.1291C11.0685 14.4682 10.6698 14.6418 10.243 14.6517C9.93649 14.6517 9.56691 14.5653 9.13669 14.39C8.70507 14.2155 8.30841 14.1291 7.94572 14.1291C7.56534 14.1291 7.15739 14.2155 6.72103 14.39C6.28401 14.5653 5.93195 14.6566 5.66278 14.6657C5.25341 14.683 4.84538 14.5044 4.43809 14.1291C4.17813 13.9044 3.85298 13.5192 3.46347 12.9736C3.04555 12.3909 2.70196 11.7152 2.43279 10.9448C2.14451 10.1127 2 9.307 2 8.52694C2 7.63339 2.19484 6.86271 2.5851 6.21689C2.89182 5.69814 3.29985 5.28893 3.81054 4.98853C4.32123 4.68813 4.87303 4.53505 5.46727 4.52525C5.79242 4.52525 6.21881 4.62492 6.74869 4.8208C7.27707 5.01734 7.61634 5.117 7.76508 5.117C7.87629 5.117 8.25318 5.00046 8.89211 4.76813C9.49631 4.55266 10.0063 4.46344 10.424 4.49859C11.556 4.58912 12.4065 5.03133 12.9721 5.82801C11.9596 6.43589 11.4588 7.28731 11.4688 8.37954C11.4779 9.2303 11.7894 9.93826 12.4015 10.5004C12.6789 10.7613 12.9887 10.9629 13.3333 11.1061C13.2586 11.3209 13.1797 11.5267 13.0958 11.7242ZM10.4996 1.60024C10.4996 2.26706 10.2537 2.88966 9.76374 3.46594C9.17241 4.15103 8.45716 4.5469 7.68153 4.48443C7.67165 4.40443 7.66592 4.32024 7.66592 4.23176C7.66592 3.59162 7.94714 2.90654 8.44653 2.34639C8.69585 2.06278 9.01295 1.82696 9.39748 1.63884C9.78118 1.45353 10.1441 1.35105 10.4855 1.3335C10.4954 1.42264 10.4996 1.5118 10.4996 1.60024Z"
        fill="#B3B3B3"
      />
    </svg>
  );
}
