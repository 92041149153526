import { Row, Col, Typography, Tooltip } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useContext, useState } from 'react';
import {
  getDefaultPaymentProfile,
  getPaymentProfilesList,
  getBookCreditsList,
  sendPayment,
} from 'modules/api';
import { Checkbox, Loader } from 'modules/common/components';
import { SelectPaymentComponent } from 'modules/dashboard/components/Payments/SelectPayment';
import S from './styles';
import { HolidaySaleContext } from '../../components/context';
import { OrderCredits } from '../OrderCredits';
import { setItem } from '../../../dashboard/utils/legacy';
import { ROUTE_HOLIDAY_SALE_CONFIRMED } from '../../routes/constants';
import { notification } from '../../../common/utils';

const { Title } = Typography;

const SHAKE_DURATION = 1000;
const TOOLTIP_DURATION = 3000;

const PlusPlansReviewAndPay = ({ history }) => {
  const {
    paymentProfiles,
    setDefaultProfile,
    setPaymentProfiles,
    creditValue,
    setCreditValue,
    paymentValue,
    setPaymentValue,
    setOrderId,
  } = useContext(HolidaySaleContext);

  const [shaking, setShaking] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile, {
    onSuccess: (data) => {
      setDefaultProfile(data);
      setPaymentValue(data);
    },
  });

  const { isLoading: isLoadingProfiles, refetch } = useQuery(
    'getPaymentProfilesList',
    getPaymentProfilesList,
    {
      onSuccess: (data) => {
        if (data?.length) {
          const filteredData = data.filter((item) => item.masked_card_number !== null);
          setPaymentProfiles(filteredData);
        }
      },
    },
  );

  const handleTermsToggle = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const { data: creditsData, isLoading: isCreditsLoading } = useQuery('getBookCreditsList', () =>
    getBookCreditsList({ type: 'holiday-sale' }),
  );

  const handleShake = () => {
    setShaking(true);
    setTooltipOpen(true);

    setTimeout(() => {
      setShaking(false);
    }, SHAKE_DURATION);

    setTimeout(() => {
      setTooltipOpen(false);
    }, TOOLTIP_DURATION);
  };

  const { mutate: payMutate, isLoading } = useMutation(['completePayment'], sendPayment, {
    onSuccess: ({ data }) => {
      setOrderId(data?.signup_payment_id);
      const { customer, id } = data || {};
      setItem('customerAndSubscriptionId', { customer, id });
      history.push(ROUTE_HOLIDAY_SALE_CONFIRMED);
    },
    onError: (error: { response: { data: { errors: string[] } }; data: { message: string } }) => {
      notification.error({
        description: error.response.data.errors?.[0] || error?.data?.message || 'Network Error',
      });
    },
  });
  const completePayment = () => {
    const lastFour = paymentValue?.masked_card_number?.slice(-4);
    const createSubscriptionData = {
      customer_id: paymentValue.customer_id,
      product: {
        product_handle: creditValue?.product?.chargifyProductHandle,
        product_price_point_handle: creditValue?.product?.chargifyProductPriceHandle,
      },
      customer: {
        id: paymentValue.customer_id,
        first_name: paymentValue.first_name,
        last_name: paymentValue.last_name,
        email: paymentValue.email,
      },
      credit_card: {
        first_name: paymentValue.first_name,
        last_name: paymentValue.last_name,
        card_type: paymentValue.card_type,
        expiration_month: paymentValue.expiration_month.toString(),
        expiration_year: paymentValue.expiration_year.toString(),
        last_four: lastFour,
        vault_token: paymentValue.vault_token,
        current_vault: paymentValue.current_vault,
        customer_vault_token: paymentValue.customer_vault_token,
      },
    };

    return payMutate(createSubscriptionData);
  };

  const handlePayment = () => {
    if (!isTermsChecked || paymentValue.id === '') {
      handleShake();
      return;
    }

    completePayment();
  };

  const getSetCreditValue = (detail) => {
    setCreditValue(detail);
  };

  if (isCreditsLoading || isLoadingProfiles) {
    return <Loader height="100%" title="Checking offers..." />;
  }

  return (
    <S.Card>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <S.MainLayout>
            <Title level={4}>Review and Pay</Title>
            <S.Section>
              <Title level={5}>Buy Credits</Title>
              <S.ModalDescription>
                <b>1 credits is 1 printed book.</b>
                The more book credits you order, the more you save.
              </S.ModalDescription>
              <OrderCredits
                getSetCreditValue={getSetCreditValue}
                creditValue={creditValue}
                creditsData={creditsData}
                paymentData={paymentProfiles || []}
              />
            </S.Section>
          </S.MainLayout>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <S.MainLayout>
            <S.SummarySection>
              <S.Section>
                <SelectPaymentComponent
                  cards={paymentProfiles || []}
                  currentCard={paymentValue}
                  setNewCard={setPaymentValue}
                  onAddCard={refetch}
                  stripePaymentProfiles={[]}
                  isStripeCustomer={false}
                />
              </S.Section>
              <S.TotalPaymentSection>
                <S.PlanTitleSection>
                  <S.PlanTitle strong>Total due today:</S.PlanTitle>
                  <S.TotalPayment>
                    <S.PlanTitle strong>${creditValue.totalAmount}</S.PlanTitle>
                  </S.TotalPayment>
                </S.PlanTitleSection>
              </S.TotalPaymentSection>
              <S.PayNowButton block onClick={handlePayment} loading={isLoading}>
                Pay Now
              </S.PayNowButton>
              <Tooltip
                open={tooltipOpen}
                title="Please check terms and conditions to proceed"
                placement="leftTop"
              >
                <S.ShakeDiv shaking={shaking}>
                  <Checkbox checked={isTermsChecked} onChange={handleTermsToggle}>
                    By clicking “Pay Now”, I agree to the{' '}
                    <strong>
                      <a
                        href="https://authorify.com/terms-of-use/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms and conditions
                      </a>
                    </strong>{' '}
                    and acknowledge that I am enrolling in a subscription plan and hereby authorize
                    Authorify to charge my payment method on a monthly basis at the then-current
                    list price (plus applicable taxes), unless I choose to cancel.
                  </Checkbox>
                </S.ShakeDiv>
              </Tooltip>
            </S.SummarySection>
          </S.MainLayout>
        </Col>
      </Row>
    </S.Card>
  );
};

export default PlusPlansReviewAndPay;
