export const PasswordSetupIcon = ({ width = 20, height = 20, stroke = '#4CC9E1' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconer" clipPath="url(#clip0_8567_118272)">
        <path
          id="Vector"
          d="M1.66675 13.333C1.66675 10.976 1.66675 9.79747 2.39898 9.06524C3.13121 8.33301 4.30973 8.33301 6.66675 8.33301H13.3334C15.6904 8.33301 16.8689 8.33301 17.6012 9.06524C18.3334 9.79747 18.3334 10.976 18.3334 13.333C18.3334 15.69 18.3334 16.8685 17.6012 17.6008C16.8689 18.333 15.6904 18.333 13.3334 18.333H6.66675C4.30973 18.333 3.13121 18.333 2.39898 17.6008C1.66675 16.8685 1.66675 15.69 1.66675 13.333Z"
          stroke={stroke}
          strokeWidth="1.5"
        />
        <path
          id="Vector_2"
          d="M5 8.33366V6.66699C5 3.90557 7.23858 1.66699 10 1.66699C12.7614 1.66699 15 3.90557 15 6.66699V8.33366"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8567_118272">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
