import {
  CLEAR_DRAFT_LINKS,
  GENERATE_DRAFT_LINKS,
  CANCEL_GENERATE_DRAFT_LINKS,
  GET_DRAFT_LINKS,
} from 'modules/v2/draft/store/constants';

export const clearDraftLinks = () => ({
  type: CLEAR_DRAFT_LINKS,
});

export const generateDraftLinks = (draftId) => ({
  type: GENERATE_DRAFT_LINKS,
  payload: {
    draftId,
  },
});

export const cancelGenerateDraftLinks = () => ({
  type: CANCEL_GENERATE_DRAFT_LINKS,
});

export const getDraftLinks = (draftId, notify = true) => ({
  type: GET_DRAFT_LINKS,
  payload: {
    draftId,
    notify,
  },
});
