import styled from 'styled-components';
import * as Color from 'modules/common/theme/color';
import LoaderImg from 'assets/images/alert-icon.png';

const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.span`
  margin-bottom: 20px;
  font-size: 20px;
  color: ${Color.LoaderLabel};
  margin-top: 20px;
  font-weight: 600;
`;

const ErrorMessage = styled.div`
  background: rgba(152, 15, 32, 0.1) url(${LoaderImg}) no-repeat center 40px;
  border-radius: 10px;
  padding: 20px;
  padding-top: 100px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
  line-height: 30px;
`;

export default {
  ErrorMessage,
  Label,
  Wrapper,
};
