import { useState, useEffect, useCallback } from 'react';

import { FilePicker, Modal } from 'modules/v2/common/components';

// TODO: needs to be moved on into common module components
const FilePickerModal = ({
  // DATA
  type,
  isOpen,
  title,

  // CALLBACKS
  onConfirm,
  onCancel,
}) => {
  const [image, setImage] = useState(null);
  const [imageFormat, setImageFormat] = useState();
  const group = 'upload';

  const handleConfirm = useCallback(() => {
    if (image instanceof HTMLCanvasElement) {
      onConfirm(image.toDataURL(imageFormat, 0.7), group, imageFormat);
    } else if (typeof image === 'string') {
      onConfirm(image, group, imageFormat);
    }
  }, [image]);

  useEffect(() => {
    if (image !== null) {
      handleConfirm();
    }
  }, [image, handleConfirm]);

  const handleSelect = (img, format) => {
    setImage(img);
    setImageFormat(format);
  };

  return (
    <Modal
      isOpen={isOpen}
      maskClosable={false}
      hasCloseButton
      title={title}
      width="630px"
      onCancel={onCancel}
      footer={null}
    >
      <FilePicker
        type={type}
        onSelect={handleSelect}
        isEditImage={false}
        toggleCancelEdit={null}
        imageUrlForEdit={null}
        onSuccess={handleConfirm}
      />
    </Modal>
  );
};

export default FilePickerModal;
