import { generatePath } from 'react-router-dom';

import {
  ROUTE_PLUS_PLANS_ALREADY_PLUS,
  ROUTE_PLUS_PLANS_CONTRACT_BINDING,
  ROUTE_PLUS_PLANS_LOGIN,
  ROUTE_PLUS_PLANS_REVIEW,
  ROUTE_PLUS_PLANS_REVIEW_CONFIRMED,
  ROUTE_PLUS_PLANS_SCHEDULE,
} from './constants';

export const getRoutePlusPlansLogin = (planType: string) =>
  generatePath(ROUTE_PLUS_PLANS_LOGIN, { planType });

export const getRoutePlusPlansReview = (planType: string) =>
  generatePath(ROUTE_PLUS_PLANS_REVIEW, { planType });

export const getRoutePlusPlansReviewConfirmed = (planType: string) =>
  generatePath(ROUTE_PLUS_PLANS_REVIEW_CONFIRMED, { planType });

export const getRoutePlusPlansSchedule = (planType: string) =>
  generatePath(ROUTE_PLUS_PLANS_SCHEDULE, { planType });

export const getRoutePlusPlansContractBinding = (planType: string) =>
  generatePath(ROUTE_PLUS_PLANS_CONTRACT_BINDING, { planType });

export const getRoutePlusPlansAlreadyPlus = (planType: string) =>
  generatePath(ROUTE_PLUS_PLANS_ALREADY_PLUS, { planType });
