import { useState } from 'react';

import { Form } from '@ant-design/compatible';
import { Formik, Field } from 'formik';
import classnames from 'classnames';
import { noop } from 'lodash-es';

import env from 'environments';

import { PrimaryButton } from 'modules/common/components';
import { pluralise } from 'modules/dashboard/pages/Dentist/OrderGuideModal/constants';

import { MIN_NUMBER_OF_PRINTS } from './constants';
import { CreditsSlider, CreditsNumericInput } from './components';
import { isNumberOfPrintsAllowed } from './utils';

import S from './styles';

const CheckoutForm = ({ fields, credits = 0, onSubmit = noop, guidePacketName }) => {
  const [isCheckedConditions, setIsCheckConditions] = useState(true);
  const hasCredits = credits >= MIN_NUMBER_OF_PRINTS;
  const initialNumberOfPrints = MIN_NUMBER_OF_PRINTS;

  const defaultFields = {
    ...fields,
    numberOfPrints: initialNumberOfPrints,
    isDigital: credits === 0,
  };

  return (
    <S.CrStyle>
      <Formik
        initialValues={defaultFields}
        validateOnChange={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form>
            <S.FormBlock>
              {hasCredits && (
                <div data-testid="quantity-form">
                  <S.SelectWrap>
                    <S.Select>
                      <S.STitle>I want to order:</S.STitle>

                      <Field name="numberOfPrints">
                        {({ field, form: { setFieldValue } }) => (
                          <>
                            <CreditsSlider
                              field={field}
                              name={field.name}
                              credits={credits}
                              setFieldValue={setFieldValue}
                            />
                            <CreditsNumericInput
                              field={field}
                              name={field.name}
                              data-testid="numeric-input"
                              credits={credits}
                              setFieldValue={setFieldValue}
                              disabled={credits === 0}
                            />
                          </>
                        )}
                      </Field>

                      <span>{pluralise(guidePacketName)}</span>
                      <br />
                    </S.Select>
                  </S.SelectWrap>

                  <Form.Item className="btnWrap">
                    <PrimaryButton
                      type="primary"
                      disabled={
                        !isCheckedConditions ||
                        !isNumberOfPrintsAllowed(values.numberOfPrints) ||
                        isSubmitting
                      }
                      data-testid="continue-button"
                      className={classnames({
                        disabled:
                          !isCheckedConditions ||
                          !isNumberOfPrintsAllowed(values.numberOfPrints) ||
                          isSubmitting,
                      })}
                      onClick={handleSubmit}
                    >
                      Continue
                    </PrimaryButton>
                  </Form.Item>
                  <S.Separator />
                </div>
              )}

              {hasCredits && (
                <S.AgreeCondition>
                  <S.Checkbox
                    id="agreeConditions"
                    onChange={(e) => setIsCheckConditions(e.target.checked)}
                    checked={isCheckedConditions}
                  />

                  <label htmlFor="agreeConditions" className="termConditions">
                    I have read and agree to the Authorify{' '}
                    <S.ConditionLink target="_blank" rel="noopener noreferrer" href={env.TERMS_URL}>
                      Terms &amp; Conditions
                    </S.ConditionLink>
                    .
                  </label>
                </S.AgreeCondition>
              )}
            </S.FormBlock>
          </Form>
        )}
      </Formik>
    </S.CrStyle>
  );
};

export default CheckoutForm;
