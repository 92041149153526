export const ClockCircleIcon = ({ width = 15, height = 16, fill = '#252525' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="8" r="6.25" stroke={fill} strokeWidth="1.5" />
    <path
      d="M7.5 5.5V8L9.0625 9.5625"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
