import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  memberFirstName: Yup.string()
    .trim()
    .required('Type first name'),
  memberLastName: Yup.string()
    .trim()
    .required('Type last name'),
  memberPhone: Yup.string()
    .trim()
    .required('Type phone number')
    .min(10, 'Phone number must be at least 10 characters long')
    .max(17, 'Phone number must be at most 17 characters long')
    .matches(/^(\+?1[ -]?)?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4,})( x\d{4})?$/, {
      message: 'Please enter a valid Phone Number',
      excludeEmptyString: false,
    }),
  memberEmail: Yup.string()
    .trim()
    .required('Type email address')
    .email('Email is not valid'),
});

export default ValidationSchema;
