import { call, takeLatest } from 'redux-saga/effects';
import { notification } from 'modules/common/utils';

import { updatePassword, resourceData, getError } from 'modules/api';
import { UPDATE_PASSWORD } from 'modules/dashboard/store/constants';

function* onUpdatePassword({ payload }) {
  try {
    const request = resourceData(UPDATE_PASSWORD, updatePassword);

    const response = yield call(request, payload);

    const regex = /\bsuccess\b/i;
    const updatedSuccessfuly = regex.test(response?.message);

    if (updatedSuccessfuly) {
      window.location.href = '/profile';
      notification.success({ description: 'Your password has been updated successfully.' });
    } else {
      notification.error({ description: response?.message });
    }

    return response;
  } catch (error) {
    const response = getError(error);

    // eslint-disable-next-line no-console
    console.error(response);

    const regex = /\bOld\s*password\b/i;
    const isOldPasswordError = regex.test(error?.message);

    notification.error({
      description: `${isOldPasswordError ? 'Old password does not match.' : error.message}`,
    });

    return error?.message;
  }
}

export function* watchUpdatePassword() {
  yield takeLatest(UPDATE_PASSWORD, onUpdatePassword);
}
