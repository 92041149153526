import styled from 'styled-components';

import * as color from 'modules/common/theme/color';
import { SecondaryBlackButton } from 'modules/common/components';

const ModalFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  width: 80%;
  margin: 0 auto;

  padding: 10px;

  p {
    margin-bottom: 20px;
  }

  button {
    flex: 1;
    flex-wrap: wrap;
  }

  ${SecondaryBlackButton} {
    color: ${color.GreyText}!important;
    font-weight: 500;
  }
`;

export default {
  ModalFooter,
};
