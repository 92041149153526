export const INITIAL_FORM_VALUES = {
  name: '',
  subject: '',
  templateTitle: '',
  templateContent: '',
  bodyContent: '',
  imageUrl: '',
  user: {
    firstName: '',
    lastName: '',
    memberTitle: '',
    memberBrokerName: '',
    email: '',
    phone: '',
    memberAddress: '',
    memberBookUrl: '',
    avatar: '',
  },
  address: {},
  content: '',
  controls: {
    hasHeader: true,
    hasHeaderTitle: true,
    hasSignature: true,
    hasFirstName: true,
    hasLastName: true,

    hasMemberBookUrl: true,
    hasMemberBrokerName: true,
    hasMemberTitle: true,
    hasMemberAddress: true,

    hasSignatureTitle: true,
    hasEmailAddress: true,
    hasPhoneNumber: true,
    hasAddress: true,
    hasBrokerName: true,
    hasProfileImage: true,
  },
};
