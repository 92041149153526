import { Spin as BaseSpin } from 'antd';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  ${({ width, height }) => css`
    height: ${height};
    width: ${width};
  `}
`;

const Spin = styled(BaseSpin)`
  .ant-spin-dot {
    width: 120px;
    height: 120px;
  }

  .ant-spin-dot-item {
    width: 60px;
    height: 60px;
  }
`;

export default {
  Wrapper,
  Spin,
};
