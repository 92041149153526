//

import S from './styles';

const ModalContent = ({ element, onChange, scale }) => {
  const handleChange = (e) => {
    const defaultValue = e.target.value;

    onChange({
      ...element,
      defaultValue,
      rawValue: undefined,
    });
  };

  return (
    <S.Text
      scale={scale}
      element={element}
      onChange={handleChange}
      defaultValue={element.defaultValue}
    />
  );
};

export default ModalContent;
