import styled from 'styled-components';
import { Button as AntButton, Radio, Input as BaseInput } from 'antd';
import BaseInputMask from 'react-input-mask';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';
import { device } from 'modules/common/theme/mediaqueries';

const inputStyle = `
  height: 45px;
  border: 1px solid ${color.gray200};
  border-radius: 3px;
  background-color: ${color.White};
  height: 45px;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #2e3f50;

  :hover {
      border-color: ${color.gray200};
      box-shadow: none;
  }

  @media ${device.tabletS} {
    font-size: 12px;
  }
`;

const InputMask = styled(BaseInputMask)`
  ${inputStyle}
  padding: 4px 10px;

  ${(props) =>
    props.$hasError
      ? `
      .ant-input:focus,
      .ant-input-focused {
    border-color: ${color.ErrorWarning};
  }

    border: 1px solid ${color.ErrorWarning};
    background-color: ${color.ErrorBg} !important;
    :hover,
    :focus {
      border-color: ${color.ErrorWarning};
      box-shadow: none;
    }
    :focus-visible {
      outline: unset;
      border: 1px solid ${color.ErrorWarning};
      box-shadow: none;
    }
    `
      : `
    :focus {
      border-color: ${color.gray200};
      box-shadow: none;
    }
    :focus-visible {
      outline: unset;
      border: 1px solid ${color.gray200};
      box-shadow: none;}
    `}

  ${({ disabled }) =>
    disabled
      ? `
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
    `
      : ''}
`;

const FieldControl = styled.div`
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
`;

const FieldGroup = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 10px;

  justify-content: ${({ justifyContent }) => justifyContent || null};

  ${({ gap }) => (gap ? 'grid-column-gap: 20px;' : '')}

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

const ErrorMessage = styled.span`
  color: ${color.ErrorWarning};
`;

// We need to overwrite the input mask plugin background.
// That is why this background rule is setup as !important
const Input = styled(BaseInput)`
  ${inputStyle}

  ${(props) =>
    props.$hasError
      ? `
    border: 1px solid ${color.ErrorWarning};
    background-color: ${color.ErrorBg} !important;
    :focus, :hover {
      border-color: ${color.ErrorWarning};
      box-shadow: none;
    }
    :focus-visible {
      outline: unset;
      border: 1px solid ${color.ErrorWarning};
      box-shadow: none;
    }
    `
      : `
    :focus {
      border-color: ${color.gray200};
      box-shadow: none;
    }
    :focus-visible {
      outline: unset;
      border: 1px solid ${color.gray200};
      box-shadow: none;}
    `}
`;

const InputRadio = styled(Radio)``;

const disabledStyle = `
  color: ${color.White}!important;
  background-color: ${color.ViewBlueDisabledButton}!important;
  border: none;
`;

const CustomButton = styled(AntButton)`
  display: flex !important;
  justify-content: center !important;
  align-content: center;
  align-items: center;
  min-height: 67px;

  padding: 24px 67px;
  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border-radius: 4px;
    font-family: ${font.RobotoFont} !important;
    text-align: center;

    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PrimaryButton = styled(AntButton)`
  padding: 11px 20px;
  border-radius: 2px;
  font-size: 20px;
  color: ${color.White};
  font-weight: normal !important;
  outline: none;
  cursor: pointer;
  height: 48px;

  &,
  &:hover,
  &:focus {
    background: ${color.DefaultBlue};
    color: ${color.White};
    border: 1px solid ${color.DefaultBlue};

    ${({ disabled }) => (disabled ? disabledStyle : null)};
  }

  @media ${device.tabletS} {
    display: block;
    max-width: 86%;
    white-space: normal;
    padding: 5px 8px !important;
    margin: 0 auto;
    margin-top: 30px;
    height: unset;
    font-size: 14px;
  }
`;

const SecondaryButton = styled(AntButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 14px;
  color: ${color.White};
  background: ${color.OnboardBlueButton};
  font-weight: 400 !important;
  height: 36px;
  width: fit-content;

  .ant-btn-loading {
    cursor: not-allowed;
  }

  &,
  &:hover,
  &:focus {
    background: ${color.DefaultBlue};
    color: ${color.White};
    border: 1px solid ${color.DefaultBlue};

    ${({ disabled }) => (disabled ? disabledStyle : null)};
  }

  @media ${device.tabletS} {
    display: block;
    max-width: 86%;
    white-space: normal;
    padding: 5px 8px !important;
    height: unset;
    font-size: 14px;
  }
`;

const TertiaryButton = styled(CustomButton)`
  background: none !important;
  border: none;
  color: ${color.PrimaryButton} !important;
  :hover {
    color: ${color.PrimaryButtonHover} !important;
  }

  @media ${device.tabletS} {
    font-size: 12px;
  }
`;

const PrimaryBlackButton = styled(CustomButton)`
  background-color: ${color.BlackButton} !important;
  border: 1px solid ${color.BlackButton};
  color: ${color.White} !important;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8 !important;
  }

  :disabled {
    background-color: rgba(0, 0, 0, 0.4) !important;
    cursor: not-allowed;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }
`;

const SecondaryBlackButton = styled(CustomButton)`
  color: ${color.OnboardingPlaceholder};
  border: 2px solid ${color.OnboardingPlaceholder};

  &:hover:enabled,
  &:focus,
  &:active,
  &:visited {
    color: ${color.SecondaryButtonText};
    border: 1px solid ${color.OnboardingPlaceholder};
  }
`;

const ContinueButton = styled(CustomButton)`
  min-width: 235px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: normal;

  background-color: ${color.OnboardBlueButton} !important;
  border: 1px solid ${color.OnboardBlueButton};
  color: ${color.White} !important;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8 !important;
  }

  :disabled {
    background-color: ${color.PrimaryButtonHover};
    cursor: not-allowed;

    &:hover,
    &:active,
    &:focus {
      background-color: ${color.PrimaryButtonHover};
    }
  }

  @media ${device.tabletS} {
    font-size: 11px;
    line-height: 14px;
    min-height: 52px;
  }
`;

const SubmitButton = styled(PrimaryBlackButton)`
  width: 300px;
  border-radius: 4px;
  align-self: end;
  text-transform: uppercase;
  font-weight: normal;
`;

const ConfirmButton = styled(PrimaryBlackButton)`
  width: 328px;
  border-radius: 4px;
  align-self: end;
  text-transform: uppercase;
  font-weight: 700;
`;

const GoBackButton = styled(SecondaryBlackButton)`
  width: 195px;
  border-radius: 4px;
  align-self: end;
  text-transform: uppercase;
  font-weight: normal;
`;

export {
  Input,
  ErrorMessage,
  InputRadio,
  FieldGroup,
  FieldControl,
  InputMask,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  PrimaryBlackButton,
  SecondaryBlackButton,
  ContinueButton,
  SubmitButton,
  ConfirmButton,
  GoBackButton,
};
