import { filter, find, get, includes, map, sortBy } from 'lodash-es';

import env from 'environments';

export const getDraftState = (editor) => editor.draft;

export const getDraft = (draft) => draft.data;

export const getDraftId = (draft) => get(draft, 'draftId', '');

export const getDraftName = (draft) => get(draft, 'name');

export const getDraftStatus = (draft) => get(draft, 'status');

export const getDraftThumbnailUrl = (draft) => get(draft, ['thumbnail', 'url']);

export const getPreferences = (draft) => get(draft, 'preferences', {});

export const getSections = (draft) => get(draft, 'sections', []);

export const getSectionById = (sectionId) => (sections) => {
  const finder = (section) => section._id === sectionId;
  return find(sections, finder);
};

export const getCoverSection = (sections) => {
  const finder = (section) => section.type === 'Cover';
  return find(sections, finder);
};

export const getSortedSections = (sections) => {
  const sorter = (section) => section.order;
  return sortBy(sections, sorter);
};

export const getActiveSections = (sections) => {
  const filterer = (section) => section.currentStatus === 'active';
  return filter(sections, filterer);
};

export const getContentSections = (sections) => {
  const filterer = (section) => includes(['Content', 'Chapter'], section.type);
  return filter(sections, filterer);
};

export const getChapterSections = (sections) => {
  const filterer = (section) => section.type === 'Chapter';
  return filter(sections, filterer);
};

export const getSectionId = (section) => get(section, '_id', []);

export const getDisplayName = (section) => get(section, 'displayName');

export const getType = (section) => get(section, 'type');

export const getTemplates = (section) => get(section, 'templates', []);

export const getTemplateById = (templateId) => (templates) => {
  const finder = (template) => template._id === templateId;
  return find(templates, finder);
};

export const getActiveTemplate = (templates) => {
  const finder = (template) => template.isActive;
  return find(templates, finder);
};

export const getSortedTemplates = (templates) => {
  const sorter = (template) => template.order;
  return sortBy(templates, sorter);
};

export const mapTemplateList = (templates) =>
  map(templates, (template) => {
    const _id = get(template, '_id');
    const name = get(template, 'name');
    const coverUrl = get(template, ['thumbnail_small', 'url']);

    // const cover = `${env.GRAPHQL_URL}${coverUrl}`;

    return { _id, name, cover: coverUrl };
  });

export const getTemplateId = (template) => get(template, '_id');

export const getBookpages = (template) => get(template, 'bookpage', []);

export const getActiveBookpage = (bookpage) => get(bookpage, 0);

export const getElements = (bookpage) => get(bookpage, 'elements', []);

export const getElementById = (elementId) => (elements) => {
  const finder = (element) => element._id === elementId;
  return find(elements, finder);
};

export const getCoverElements = (elements, preferences) =>
  filter(elements, (element) => {
    switch (element?.id) {
      case 'brokerLogo': {
        return get(preferences, ['brokerLogo', 'status'], true);
      }

      case 'realtorLogo': {
        return get(preferences, ['realtorLogo', 'status'], true);
      }

      case 'brokerInfo': {
        return get(preferences, ['brokerInfo', 'status'], true);
      }

      case 'housingLogo': {
        return get(preferences, ['housingLogo', 'status'], true);
      }

      default:
        return true;
    }
  });

export const getContactInfo = (preferences) => {
  const name = get(preferences, 'name');
  const email = get(preferences, 'email');
  const phone = get(preferences, 'phone');
  const brokerInfo = get(preferences, ['brokerInfo', 'status']);
  const brokerLogo = get(preferences, ['brokerLogo', 'status']);
  const realtorLogo = get(preferences, ['realtorLogo', 'status']);
  const housingLogo = get(preferences, ['housingLogo', 'status']);

  return {
    name,
    email,
    phone,
    brokerInfo,
    brokerLogo,
    realtorLogo,
    housingLogo,
  };
};
