function getDefaultProps() {
  return {
    width: '20',
    height: '20',
    fill: '#D7D7D7',
  };
}

function QuestionCircleIcon() {
  return (
    <svg
      width={getDefaultProps().width}
      height={getDefaultProps().height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.67 10.335C18.67 14.9383 14.9383 18.67 10.335 18.67C5.73171 18.67 2 14.9383 2 10.335C2 5.73171 5.73171 2 10.335 2C14.9383 2 18.67 5.73171 18.67 10.335ZM10.335 7.20937C9.95069 7.20937 9.6136 7.41693 9.43189 7.73107C9.14376 8.22915 8.50642 8.39935 8.00834 8.11123C7.51026 7.8231 7.34006 7.18576 7.62818 6.68768C8.16704 5.75615 9.17678 5.12563 10.335 5.12563C12.0612 5.12563 13.4606 6.52501 13.4606 8.25125C13.4606 9.61217 12.5909 10.7699 11.3769 11.199V11.3769C11.3769 11.9523 10.9104 12.4187 10.335 12.4187C9.7596 12.4187 9.29314 11.9523 9.29314 11.3769V10.335C9.29314 9.75959 9.7596 9.29313 10.335 9.29313C10.9104 9.29313 11.3769 8.82666 11.3769 8.25125C11.3769 7.67584 10.9104 7.20937 10.335 7.20937ZM10.335 15.5444C10.9104 15.5444 11.3769 15.0779 11.3769 14.5025C11.3769 13.9271 10.9104 13.4606 10.335 13.4606C9.75959 13.4606 9.29313 13.9271 9.29313 14.5025C9.29313 15.0779 9.75959 15.5444 10.335 15.5444Z"
        fill={getDefaultProps().fill}
      />
    </svg>
  );
}

export default QuestionCircleIcon;
