//

import { Item } from './components';
import S from './styles';

const Grid = ({ children }) => <S.Wrapper>{children}</S.Wrapper>;

Grid.Item = Item;

export default Grid;
