export interface Book {
  _id: string;
  name: string;
  thumbnail: Thumbnail;
  isAllowed: boolean;
  getBookSortOrder: () => void;
}

export interface Thumbnail {
  url: string;
}

export interface DragAndRearrangeProps {
  books: Book[];
}

export enum GuideType {
  GUIDE = 'guide',
  MAGAZINE = 'magazine',
  book = 'book',
  packet = 'packet',
}

export interface Guide {
  _id: string;
  guideName: string;
  guideId: string;
  thumbnail: string;
  pdfUrl?: string;
  position: number;
  type: GuideType;
}

export interface GuideCatalogProps {
  guides: Guide[];
}
