function PublishBookIcon() {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.718 10.5387L17.1494 8.92887L17.1494 8.92886C17.6529 7.04963 17.9047 6.11001 17.7151 5.29687C17.5654 4.65482 17.2287 4.07158 16.7475 3.62091C16.1381 3.05014 15.1985 2.79837 13.3192 2.29483C11.44 1.79129 10.5004 1.53952 9.68722 1.72912C9.04517 1.87883 8.46193 2.21556 8.01126 2.69674C7.52258 3.2185 7.26774 3.98233 6.88851 5.37069C6.82482 5.60385 6.75763 5.85462 6.68523 6.12481L6.68518 6.12499L6.25382 7.73487C5.75028 9.61411 5.49851 10.5537 5.68811 11.3669C5.83781 12.0089 6.17454 12.5922 6.65572 13.0428C7.26513 13.6136 8.20474 13.8654 10.084 14.3689L10.084 14.3689C11.7778 14.8228 12.7083 15.0721 13.4708 14.978C13.5543 14.9677 13.6358 14.9533 13.716 14.9346C14.358 14.7849 14.9413 14.4482 15.3919 13.967C15.9627 13.3576 16.2145 12.418 16.718 10.5387Z"
        fill="#252525"
      />
      <path
        d="M2.08707 12.2625L2.51843 13.8724C3.02197 15.7516 3.27374 16.6912 3.84451 17.3007C4.29519 17.7818 4.87842 18.1186 5.52047 18.2683C6.33362 18.4579 7.27323 18.2061 9.15246 17.7026L9.15247 17.7026C11.0317 17.199 11.9713 16.9473 12.5807 16.3765C12.6313 16.3291 12.6803 16.2803 12.7276 16.2301C12.4491 16.2067 12.1682 16.1619 11.8828 16.1051C11.3027 15.9898 10.6134 15.8051 9.79825 15.5866L9.70924 15.5628L9.68863 15.5573C8.80164 15.3196 8.0606 15.1206 7.46889 14.9069C6.84664 14.6821 6.28126 14.405 5.80115 13.9553C5.13953 13.3356 4.67652 12.5337 4.47068 11.6509C4.32131 11.0103 4.36401 10.3821 4.48047 9.73079C4.59207 9.10668 4.7925 8.35874 5.03263 7.46262L5.03264 7.46261L5.47796 5.80064L5.49362 5.74219C3.89317 6.17332 3.05076 6.4284 2.48897 6.95457C2.0078 7.40524 1.67106 7.98848 1.52136 8.63053C1.33176 9.44367 1.58353 10.3833 2.08707 12.2625Z"
        fill="#252525"
      />
    </svg>
  );
}

export default PublishBookIcon;
