import { call, put, select, takeEvery } from 'redux-saga/effects';
import { defaultTo, get } from 'lodash-es';
import { notification } from 'modules/common/utils';

import { resource, getError } from 'modules/api';

import {
  unmarkCover,
  unmarkContent,
  unmarkPreferences,
  unmarkSections,
  changedSelector,
  savedSelector,
} from 'modules/editor/store';

import { SAVE_DRAFT } from 'modules/editor/store/constants';
import { checkPendingSave } from 'modules/editor/utils';

import { onSaveCover } from './saveCover';
import { onSaveContent } from './saveContent';
import { onSavePreferences } from './savePreferences';
import { onSaveSections } from './saveSections';

export function* runSave() {
  let hasBeenSaved = true;
  const changed = yield select(changedSelector);
  const saved = yield select(savedSelector);

  const shouldSaveCover = checkPendingSave(changed.cover, saved.cover);
  const shouldSavePreferences = checkPendingSave(changed.preferences, saved.preferences);
  const shouldSaveSections = checkPendingSave(changed.sections, saved.sections);

  if (shouldSaveCover) {
    const unmark = unmarkCover(changed.cover);
    const isSaved = yield call(onSaveCover);

    if (isSaved) {
      yield put(unmark);
    }

    hasBeenSaved = hasBeenSaved && isSaved;
  }

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const sectionId in changed.content) {
    const changedContent = get(changed.content, sectionId);
    const savedContent = get(saved.content, sectionId);
    const shouldSaveContent = checkPendingSave(changedContent, savedContent);

    if (shouldSaveContent) {
      const payload = { sectionId };
      const unmark = unmarkContent(sectionId, changedContent);
      const isSaved = yield call(onSaveContent, { payload });

      if (isSaved) {
        yield put(unmark);
      }

      hasBeenSaved = hasBeenSaved && isSaved;
    }
  }

  if (shouldSavePreferences) {
    const unmark = unmarkPreferences(changed.preferences);
    const isSaved = yield call(onSavePreferences);

    if (isSaved) {
      yield put(unmark);
    }

    hasBeenSaved = hasBeenSaved && isSaved;
  }

  if (shouldSaveSections) {
    const unmark = unmarkSections(changed.sections);
    const isSaved = yield call(onSaveSections);

    if (isSaved) {
      yield put(unmark);
    }

    hasBeenSaved = hasBeenSaved && isSaved;
  }

  return hasBeenSaved;
}

export function* onSave() {
  try {
    const request = resource(SAVE_DRAFT, runSave, defaultTo);
    const isSaved = yield call(request);

    if (isSaved) {
      notification.success({ description: 'Changes saved successfully' });
    } else {
      notification.error({ description: 'Changes could not be saved' });
    }

    return isSaved;
  } catch (error) {
    const payload = getError(error);
    // eslint-disable-next-line no-console
    console.error(payload);

    notification.error({ description: 'Changes could not be saved' });

    return false;
  }
}

export function* watchSave() {
  yield takeEvery(SAVE_DRAFT, onSave);
}
