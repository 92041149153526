export const HeadphonesRound = ({ width = 20, height = 20, fill = '#252525' }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M17.5 14.1667V10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10V14.1667"
      stroke={fill}
      strokeWidth="1.5"
    />
    <path d="M18.3333 12.918V14.5846" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1.66675 12.918V14.5846" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M6.66667 11.5372C6.66667 10.9002 6.66667 10.5817 6.52105 10.3602C6.44778 10.2488 6.35051 10.1567 6.237 10.0913C6.01143 9.96127 5.70677 9.99131 5.09745 10.0514C4.07073 10.1526 3.55736 10.2032 3.18948 10.4804C3.00329 10.6206 2.84698 10.7994 2.73038 11.0056C2.5 11.4129 2.5 11.9496 2.5 13.023V14.3277C2.5 15.3904 2.5 15.9218 2.73498 16.3321C2.82293 16.4857 2.93317 16.6242 3.0618 16.7428C3.40544 17.0595 3.90703 17.1584 4.91023 17.3562C5.6162 17.4954 5.96918 17.565 6.2297 17.4184C6.32583 17.3643 6.4108 17.2911 6.47977 17.2028C6.66667 16.9637 6.66667 16.5898 6.66667 15.842V11.5372Z"
      stroke={fill}
      strokeWidth="1.5"
    />
    <path
      d="M13.3333 11.5372C13.3333 10.9002 13.3333 10.5817 13.4789 10.3602C13.5521 10.2488 13.6494 10.1567 13.7629 10.0913C13.9885 9.96127 14.2932 9.99131 14.9025 10.0514C15.9292 10.1526 16.4426 10.2032 16.8104 10.4804C16.9966 10.6206 17.1529 10.7994 17.2695 11.0056C17.4999 11.4129 17.4999 11.9496 17.4999 13.023V14.3277C17.4999 15.3904 17.4999 15.9218 17.2649 16.3321C17.177 16.4857 17.0667 16.6242 16.9381 16.7428C16.5945 17.0595 16.0929 17.1584 15.0897 17.3562C14.3837 17.4954 14.0307 17.565 13.7702 17.4184C13.6741 17.3643 13.5891 17.2911 13.5202 17.2028C13.3333 16.9637 13.3333 16.5898 13.3333 15.842V11.5372Z"
      stroke={fill}
      strokeWidth="1.5"
    />
  </svg>
);
