import { getInstance } from './instance';
import { getInstance as getInstanceApiKey } from './instanceApiKey';

const instance = getInstance();
const instanceWithApiKey = getInstanceApiKey();

export const getUpsellReport = (
  startDate: string,
  endDate: string,
  page: number,
  perPage: number,
  terms?: { [key: string]: string[] },
  sortByFilter?: { [key: string]: string },
) => {
  const url = '/upsell/find';

  try {
    const params = { startDate, endDate, page, perPage };

    if (sortByFilter) {
      params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
    }

    if (terms) {
      Object.entries(terms).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            params[`${key}[${index}]`] = item;
          });
        } else {
          params[key] = value;
        }
      });
    }

    return instance
      .get(url, { params })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  } catch (e) {
    return Promise.reject(e);
  }
};

// The CSV is available to the user at the email provided
export const postUpsellReportExport = (body: {
  startDate: string;
  endDate: string;
  email: string;
  filter?: {
    [x: string]: string;
  };
  reportIds?: string[];
}) => {
  const url = '/upsell/export';

  try {
    return instance
      .post(url, body)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAdvancedSearch = (field: string, keyword?: string) => {
  const url = '/upsell/advance-search';
  try {
    const params = { keyword, field };

    return instance
      .get(url, { params })
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  } catch (e) {
    return Promise.reject(e);
  }
};

type createUpsellWithNoSessionBody = {
  customerEmail: string;
  offerId: string;
  paymentProvider: string;
  paymentStatus: string;
  channel: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  utmTerm: string;
  firstName: string;
  lastName: string;
};
export const createUpsellWithNoSession = async (body: createUpsellWithNoSessionBody) => {
  const url = '/upsell/create/no-session';
  return instanceWithApiKey
    .post(url, body)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};
