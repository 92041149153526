import { useEffect } from 'react';

import HelpVideo from './component/HelpVideo';
import S from './styles';

const FAQ = () => {
  useEffect(() => {
    const wistiaScript = document.createElement('script');
    wistiaScript.src = 'https://fast.wistia.com/embed/medias/901fz33sed.jsonp';
    wistiaScript.async = true;

    const fastWistiaScript = document.createElement('script');
    fastWistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    fastWistiaScript.async = true;

    document.body.appendChild(wistiaScript);
    document.body.appendChild(fastWistiaScript);
  }, []);

  return (
    <>
      <S.Collapse.Title>Licensing Questions</S.Collapse.Title>
      <S.Collapse ghost bordered={false}>
        <S.Panel header="What does my Authorify account include?">
          <HelpVideo videoCode="wistia_async_901fz33sed" />
          <p>
            There are different membership levels, including some with printed book credits, but all
            of our memberships include:
          </p>
          <ul>
            <li>
              <strong>Book License —</strong> You gain access to our library of real estate books,
              available in a variety of niches, to use as your own.{' '}
            </li>
            <li>
              <strong>Access to the Book Builder App —</strong> Our Book Builder App allows you to
              easily customize your book, both inside and out. Add your photo, bio, intro, and
              testimonials to your book to make it your own.{' '}
            </li>
            <li>
              <strong>Custom Digital Flipbook —</strong> When you customize your book, we'll create
              a digital copy hosted on your Authority website. Your flipbook can be easily shared
              via email, text or on social media for anyone with web access to read.{' '}
            </li>
            <li>
              <strong>Customized Authorify Website —</strong> This is your own piece of digital real
              estate, a personalized, ready-made website. Use it as a digital business card and to
              rank for search engines in your market.
            </li>
            <li>
              <strong>Book Launch Kit —</strong> This includes customized images to share your book
              on social media, business card templates, banner ads, cover letters, call scripts, and
              much more — a bundle of marketing templates and resources for promoting your new book.
            </li>
            <li>
              <strong>Training —</strong> You have access to a library of guides, courses, and other
              resources — over 20 hours of content to help you grow your business.
            </li>
            <li>
              <strong>Marketing Success Coach —</strong> You'll get hands-on guidance from an
              Authorify expert. Whether it's helping you edit your bio or showing you how to
              generate leads, your coach has your back.
            </li>
          </ul>
          <p>
            If you want to learn more about our membership levels, visit{' '}
            <a href="https://authorify.com/pricing/" draggable="false">
              authorify.com/pricing.
            </a>
          </p>
        </S.Panel>

        <S.Panel header="What do I say if someone asks me if I wrote the book?">
          <HelpVideo videoCode="wistia_async_nt9t8ojb56 " />

          <p>
            We have worked long and hard to come up with new ways to help our members feel more
            comfortable handing out the books — while still capitalizing on the expert authority
            they provide.
          </p>
          <p>
            Of course, you are always welcome to claim sole authorship if you'd like (we'll talk
            more about that in a minute).
          </p>
          <p>
            It's important to note that this question doesn't come up nearly as often as people
            think. In fact, we've never had a single member report being accused of not authoring
            their book.{' '}
          </p>
          <p>
            But in the event that someone does ask you about the origin of your book just out of
            curiosity, here are some of the most popular options for how to respond.
          </p>

          <p>
            <strong>Say you licensed the content.</strong>
          </p>
          <p>
            The books are full of information that every real estate agent should know. You can
            simply say that you licensed the material to be able to give your clients helpful
            information about their home sales and that you already employ all of the tactics
            discussed in the books with your clients.
          </p>

          <p>
            <strong>Say you worked with a ghostwriter or group of writers. </strong>
          </p>
          <p>
            Did you know that some of the most famous celebrity authors didn't write their own
            books?
          </p>
          <p>
            That list includes presidents, athletes, actors, singers — even Elton John has admitted
            to using a ghostwriter on some of his most popular hits.
          </p>
          <p>
            The ghostwriting process is pretty straightforward: You provide the information and
            expertise, and a professional writer puts it all into a digestible book.
          </p>
          <p>
            And that's essentially what we do for you. You've been through the training, you've
            accumulated knowledge on the job. Give our books a read, and we can pretty much
            guarantee you already employ most, if not all, of the tactics we discuss in the books.
          </p>
          <p>
            Most of our members say they feel confident handing out the books because they include
            everything they already know and do as real estate agents.
          </p>
          <p>Take Jim, for instance.</p>
          <p>
            Jim was worried about appearing as the author of his book but quickly overcame his
            doubts.
          </p>
          <p>
            "I had to get kind of around that, like, 'I wrote the book' thing. But as I read them, I
            did write the book — I just didn't physically write it. But I mean, the stuff that they
            talk about, that's what I do. That's exactly what I say, so it really isn't any kind of
            departure."
          </p>
          <p>
            If you want to go with this route, when people ask if you wrote the book, simply say
            that you provided all of the knowledge and expertise in the book and a professional
            writer or team of writers put it together for you. If writing isn't your strong suit,
            you can even poke a little fun at yourself:
          </p>
          <p>“I may be a real estate expert, but I'm on the Grammar Police's most-wanted list!”</p>
          <p>
            Potential clients will be impressed that you have the knowledge to write a book on
            buying and selling real estate, but also the humility to admit you aren't perfect.
          </p>

          <p>
            <strong>Say you wrote the book.</strong>
          </p>
          <p>
            If you're on the fence about claiming to have written the books, we're here to tell you
            that you have nothing to worry about.
          </p>
          <p>
            Our members have found amazing success as published authors — even those who were
            skeptical to do so in the first place.
          </p>
          <p>Take Jacque, for instance.</p>
          <p>
            "I was kind of leery at first, but I have to tell you that it makes such a world of
            difference."
          </p>
          <p>
            Jessica, too, was worried even after reading through the books that potential clients
            would doubt her author status. It turns out, she had nothing to worry about.
          </p>
          <p>
            “They kind of just asked me more about my experience, how have I been able to overcome
            maybe homes that were slightly more outdated or that needed renovations, and those kinds
            of things, which the book touches on. But they didn't spend a lot of time asking me
            specific details. So I was kind of nervous that I was going to have a lot of the content
            of these books memorized in case they wanted to grill me or test me in some way.”
          </p>
        </S.Panel>

        <S.Panel header="Can you use the books as a new agent?">
          <HelpVideo videoCode="wistia_async_eqcxj8crqi" />

          <p>
            The answer is yes — you absolutely can. When we first launched the books, we tested them
            with my friend Joe, who had never sold real estate before. He used them to list a $1
            million property.{' '}
          </p>
          <p>If the books worked for Joe, they can definitely work for you. </p>
          <p>
            My only suggestion is if someone you know questions how you could write a book right out
            of the gate, you tell them you worked with a team of writers or another agent to help
            you write it. The important thing is that you tell people the book is based on
            information to help them sell their home for the most money possible — that's what most
            people care about, after all.{' '}
          </p>
        </S.Panel>

        <S.Panel header="What if multiple agents from the same area have books?">
          <HelpVideo videoCode="wistia_async_6y13px3jc1" />
          <p>
            Your book is fully customizable. You can choose your cover, edit the content and even
            write your own book title. This way, you ensure that your book is uniquely yours.{' '}
          </p>
          <p>
            In our experience, we don't have a lot of overlap for our U.S. or Canadian members. But
            if you are worried, you can go a step further and request a completely custom cover for
            a small investment. This cover can be tailored to your market and brand. Just talk to
            your coach to find out more if that's something you're interested in.{' '}
          </p>
          <p>
            Another great way to differentiate your book is to add in a bio, testimonials, resources
            and other sections that are uniquely yours. All of this is easy to do in the Book
            Builder app.{' '}
          </p>
        </S.Panel>

        <S.Panel header="Should I read my book before I send it out?">
          <HelpVideo videoCode="wistia_async_auhdcs6279" />

          <p>
            You should absolutely read the content in your book when you have time, just so you can
            answer questions or use it to point out specific information to potential clients. But
            you don't have to wait to read through it line by line before you send it out,
            especially if you want to get results in the least amount of time. All of the
            information in the books is information you likely already know as a real estate agent.
            Our books simply compile all of that knowledge and deliver it to your potential clients
            in a digestible way.{' '}
          </p>
        </S.Panel>
      </S.Collapse>

      <S.Collapse.Title>Setup Questions</S.Collapse.Title>
      <S.Collapse ghost bordered={false}>
        <S.Panel header="Which book should I start with?">
          <HelpVideo videoCode="wistia_async_76kedwsz55" />

          <p>
            We highly recommend our members start out with our seller book, also known as the
            Business Card Book. We call it that because we recommend giving it away in place of your
            business card. It's universally appealing to all types of home sellers — FSBOs,
            Expireds, people going through divorce, people with vacant homes, etc.{' '}
          </p>
          <p>The book is designed to do two things: </p>

          <p>
            <strong>
              1. Convince sellers that hiring you as an agent will make selling their home much
              easier and 2. Net them more money.
            </strong>
          </p>
          <p>
            However, if you primarily work with buyers, start with the Home Buyer's Guide, which is
            the buyer's equivalent to the Business Card Book.{' '}
          </p>
          <p>
            No matter which book you choose, we recommend starting with just one book. When you use
            one book, you can familiarize yourself with its content and truly own it as yours.{' '}
          </p>
          <p>
            You can always start with one book, then try out a different title if you want to target
            a particular niche or try something new.{' '}
          </p>
        </S.Panel>

        <S.Panel header="How many books should I start with?">
          <HelpVideo videoCode="wistia_async_t6b9gd22mk" />
          <p>
            We have a lot of members who join and want to print multiple copies of several different
            books — 4 of this type, 10 of that type, 10 of this type. However, this isn't something
            we recommend. We recommend starting with one book — either the buyer or seller book — or
            a specific niche book if you primarily work within a niche market.{' '}
          </p>
          <p>
            To start out, print 10 to 20 copies of that one book and give them to highly qualified
            leads — FSBOs, expireds, and any leads you're close to converting. Include a copy of
            your book as part of a pre-listing package or bring it along to any listing
            presentations you have lined up. It's important to remember that you also have a digital
            book flipper you can share with as many people as you'd like. You can email or text it
            out or share it on all of your social media pages. Some of our membership levels also
            include a certain number of printed books per month. If you want to learn more about our
            membership levels, visit{' '}
            <a href="https://authorify.com/pricing/" draggable="false">
              authorify.com/pricing.
            </a>
          </p>
          <p>Text</p>
        </S.Panel>

        <S.Panel header="How much should I customize my book?">
          <HelpVideo videoCode="wistia_async_nmkx8xq06h" />
          <p>
            A lot of agents want to fully customize their book and spend a lot of time editing it.
            While that's a good long-term plan, it isn't a great solution if you want to immediately
            start getting listings. If you want to start getting results quickly, publish your book
            with minimal edits to begin with, then go back over time and make changes for future
            versions of your book.{' '}
          </p>
          <p>
            You can send out your digital book link to people in your sphere for feedback and make
            changes based on any ideas they may have. With the Book Builder app, it's easy to make
            edits to your book — from the cover design to the inside contents — at any time.{' '}
          </p>
          <p>
            Winston Churchill once said, <i>“Perfection is the enemy of progress.”</i> If you want
            to make a real change for your business, getting caught up in the details will hold you
            back.{' '}
          </p>
          <p>Text</p>
        </S.Panel>

        <S.Panel header="What should I do if I don't have a good headshot photo?">
          <HelpVideo videoCode="wistia_async_b73lnmvdly" />
          <p>
            If you don't have a professional headshot ready to go, you can start by choosing a cover
            design without a photo. You can always go back later and switch your cover design once
            you get a new headshot you'd like to use. The important thing is to get started as soon
            as possible so you can publish your digital book and get physical copies in front of
            people looking to buy or sell real estate.{' '}
          </p>
          <p>
            Another option is to take a photo on your smartphone. If you have an iPhone, for
            example, you can put it in portrait mode and have a coworker, friend or family member
            take your picture. Here are some tips for taking your own photo:{' '}
          </p>

          <ul>
            <li>Make sure you're dressed professionally in neutral colors.</li>
            <li>
              Consider your scenery. We recommend choosing a simple background for your book cover
              image so it doesn't clash with your cover design.{' '}
            </li>
            <li>
              Consider the lighting. Make sure the light source is coming from in front of your face
              or slightly at an angle. Make sure you don't take a photo with your back to a light
              source or window. Natural light is typically most flattering, like the natural
              daylight coming through a window, a shaded area outside or anywhere outdoors on an
              overcast day
            </li>
          </ul>

          <p>
            If you want to find a professional photographer, ask around your brokerage to see if
            anyone has a recommendation. You can also use a number of websites to find local
            photographers:{' '}
          </p>

          <ul>
            <li>
              <strong>Thumbtack -</strong> A site dedicated to all kinds of services, from piano
              lessons to house cleaners, you'll find a quick list of photographers nearby with an
              easy “headshot photography” search, including prices and recent reviews.{' '}
            </li>
            <li>
              <strong>Photographer Central</strong> Similar to Thumbtack, add your search terms — we
              used “portrait photographer” — and then desired filters to discover photographers near
              your location and in your price range.{' '}
            </li>
            <li>
              <strong>Snappr Photography -</strong> A site dedicated only to photography, you'll
              need a few more details under your belt about your shoot — the location, for example —
              to find results, but you'll end up with a list that has been “vetted” by the Snappr
              experts. Fancy, right?
            </li>
          </ul>
        </S.Panel>

        <S.Panel header="How do I create a bio for my book?">
          <HelpVideo videoCode="wistia_async_yhzze5rafw" />
          <p>
            To start, we have a number or pre-written, customizable bio templates available in the
            Book Builder app. Use one of these templates as a jumping off point to create your own
            bio. You can edit them as much or as little as you want. The important thing is to
            replace the text highlighted in yellow. Click and drag to select this text, delete it,
            then fill in your own personal information.{' '}
          </p>
          <p>
            If you prefer to write a bio from scratch, here are some pointers to help you through
            the process.{' '}
          </p>

          <p>
            <strong>1. Establish your authority.</strong>
          </p>
          <p>
            The first thing you need to do is to show the reader you're good at what you do and why
            you wanted to write or provide a book in the first place.
          </p>
          <p>
            Talk about your experience, knowledge and accomplishments. If you're new to real estate,
            talk about why you got started and what you hope to accomplish.{' '}
          </p>

          <p>
            <strong>2. Build credibility.</strong>
          </p>
          <p>
            Once you've established your authority, next you need to build credibility. This is
            where you substantiate the experience, knowledge and accomplishments you've mentioned.
          </p>
          <p>Talk about your accomplishments in a way that's meaningful.</p>
          <p>
            Let me explain what that means. A lot of people say, "I'm the best. I'm number one. I'm
            this, I'm that." It doesn't really mean anything. It just means you think you're better.
          </p>
          <p>
            What you should do is include something that's tangible — such as, “I have a track
            record of selling homes that other agents couldn't sell.”
          </p>
          <p>
            Another good example is; “I use proven marketing techniques to help you get more money
            for your house.”
          </p>
          <p>
            Making money is a tangible skill. Don't forget to tell them a little bit about how
            you're going to do it, or why you're able to do it.
          </p>
          <p>If you have past experience, this is the time to lean on it.</p>
          <p>
            Talk about your most rewarding experiences or achievements. An example could be a time
            that you finally sold a home that had expired several times in the past or a buyer that
            you helped save $10,000 on their new home.
          </p>
          <p>
            Do NOT go on and on about things that are irrelevant to the reader. Put yourself in
            their shoes and ask, “Does this fact really matter to anyone but me?”
          </p>

          <p>
            <strong>3. Tell your story.</strong>
          </p>
          <p>
            People want to know how real you are. They want to read a bio that they can believe. You
            want them to see you as they read your bio.
          </p>
          <p>
            Therefore, tell your story — a story that makes you human and not a fictional character
            in a book. Tell stories that people will remember and ask about in person.
          </p>
          <p>
            Share unique stories about your life and your work. This is what people are interested
            in knowing about you.
          </p>
          <p>
            Your bio should be able to tell the reader who you are and give them a quick glance into
            your life, both in and outside of work. Make your bio stand out enough to turn the
            reader into a lead.{' '}
          </p>

          <p>
            <strong>4. Avoid selling yourself in your bio.</strong>
          </p>
          <p>
            Overselling yourself is one of the biggest mistakes most people make in their bios.
            Avoid using phrases that try to sell you to the reader, like, “I'm the best agent for
            the job.”
          </p>
          <p>
            Your bio is meant to be an introduction to your readers so that they know why your book
            is important for them.{' '}
          </p>
          <p>
            The goal of your bio is not to sell your services but to create a well-rounded picture
            of you and your experience in the industry. This is enough for the reader to make a
            connection with you and be open to working with you.
          </p>

          <p>
            <strong>5. Keep it short and interesting.</strong>
          </p>
          <p>
            It can be tempting to write your whole life story in your bio, but you should resist
            writing a second novel.
          </p>
          <p>
            Even though the readers are interested in finding out more about you, they don't need to
            know everything.
          </p>
          <p>Keep your bio short and relevant. Don't overstate your accomplishments.</p>
          <p>
            A good bio should be about 250 words. If you do have a long bio, you should include a
            summary at the top for people who don't read the whole thing. Begin with a summary of
            your biggest accomplishments that show people why they should work with you.
          </p>

          <p>
            <strong>6. Get some feedback or ask for help.</strong>
          </p>
          <p>
            Admittedly, tooting your own horn without sounding like you're bragging isn't the
            easiest thing to do. If you are having a hard time writing your bio — or if you feel
            like you're starting to sound arrogant — invite friends, coworkers or family members to
            read what you've written and give input.
          </p>
          <p>
            You could even ask someone else to write it on your behalf. There is no harm in asking
            for help.
          </p>

          <p>
            <strong>7. Figure out what makes you different.</strong>
          </p>
          <p>
            If you are struggling with what to say about yourself, take a step back and just think
            about what makes you unique.
          </p>
          <p>
            Before you start writing out the bio, first figure out what makes you a go-to real
            estate agent and why a home buyer or seller should come to you over your competition.
          </p>
        </S.Panel>
      </S.Collapse>

      <S.Collapse.Title>Implementation Questions</S.Collapse.Title>
      <S.Collapse ghost bordered={false}>
        <S.Panel header="How do I get listings with my books?">
          <HelpVideo videoCode="wistia_async_ichfzdgsry" />
          <p>
            There are so many ways you can use your books to get listings. When you signed up, you
            were mailed a guide with helpful tips for how to use your books. We recommend consulting
            that guide and reaching out to your Marketing Success Coach with any questions or for
            more ideas. Our membership site is also chalk full of information about how to use your
            books and testimonials from other members who have gotten listings with their books in
            creative ways.{' '}
          </p>
          <p>That being said, here are some of the best ways to use your book.</p>
          <ol>
            <li>
              <strong>Use your books as part of a pre-listing package.</strong> You can email out a
              link to your digital book to any leads you’re trying to convert or mail/drop off a
              physical book package. We recommend starting with the most qualified leads first —
              FSBOs, expireds, vacant homeowners, etc.
            </li>
            <li>
              <strong>Use your books on listing appointments.</strong> When you’re close to landing
              a deal, let your books be the final deciding factor that pushes you over the edge.
            </li>
            <li>
              <strong>Send your digital book link to everyone in your sphere.</strong> Save your
              printed books for highly qualified leads and encourage people in your sphere to
              forward your link on to anyone they know who may be looking to buy or sell real
              estate.
            </li>
            <li>
              <strong>Share your digital book link on social media. </strong>
            </li>
            <li>
              <strong>Add your digital book link to your email signature. </strong>
            </li>
            <li>
              <strong>
                Add a link to your digital book to your profiles on sites like Zillow and
                Realtor.com.
              </strong>
            </li>
            <li>
              <strong>Hand out your books at events.</strong> Leave your books on the table with a
              sign that says “take a free copy of my book.”
            </li>
          </ol>
          <p>
            Wherever you go, try to keep a few copies of your book on hand. You never know when you
            might meet someone who could become a new lead. The same goes for your digital book
            link. Print it on your business cards or have a flyer on hand that has the link or a QR
            code to view it.{' '}
          </p>
        </S.Panel>

        <S.Panel header="What is the fastest way to generate sales with my book?">
          <HelpVideo videoCode="wistia_async_4ps0vd6b0p" />
          <p>
            There’s obviously more than one way to generate sales with your book. But one of the
            easiest ways to reach a lot of people and spread your reach is through your sphere of
            influence. Text or email a link to your digital book to all the people you know — past
            clients, family, friends, acquaintances — asking them if they know anyone who would
            benefit from reading your book.{' '}
          </p>
          <p>
            <strong>Here is a simple email/text template you can use:</strong>
          </p>
          <p>
            <i>
              I'm working on a real estate book. Please check it out and let me know if you or
              anyone you know would like a free copy. You can read it here:{' '}
              <strong>
                {'{'}
                Your Digital Book Link
                {'}'}
              </strong>
            </i>
          </p>
          <p>
            <i>
              If you send this message by email, you can use a subject line like, "Check out my real
              estate book" or <strong>"Want a copy of my new book?"</strong>
            </i>
          </p>
          <p>
            You can also add your digital book link to your email signature, share it on social
            media, add it to your real estate profiles and more.{' '}
          </p>
          <p>
            For the fastest results, you should also use your books with highly qualified leads —
            either as part of a pre-listing package or on listing appointments.
          </p>
        </S.Panel>

        <S.Panel header="How can I generate leads with my books?">
          <HelpVideo videoCode="wistia_async_vjla5wm3hi" />
          <p>
            There are so many ways you can use your books to generate leads. We always recommend
            leaning on your strengths. Here are just a few ideas our members have found success
            with.
          </p>
          <ul>
            <li>
              If you’re great at <strong>digital marketing</strong>, share your digital book link on
              your social media pages, create Facebook ads, add it to your real estate profiles,
              send it out through your CRM, etc.{' '}
            </li>
            <li>
              If you excel at <strong>in-person communication</strong>, hand out your books at
              mixers and community events. We also have some members who wear pins or T-shirts that
              say “Ask me about my book.”
            </li>
            <li>
              If you’re great at cold-calling, try a method we call <strong>“warm calling.”</strong>{' '}
              Send a copy of your book in a pre-listing package, then follow up with a phone call.
              This way, you warm up leads ahead of time and have something to talk about — your book
              — when you call.{' '}
            </li>
            <li>
              Leave your books in <strong>waiting rooms</strong> at local hair salons, doctor or
              dentist offices, mechanics and other local establishments.
            </li>
          </ul>

          <p>
            When you signed up, you were mailed a guide with helpful tips for how to use your books.
            We recommend consulting that guide and reaching out to your Marketing Success Coach with
            any questions or for more ideas. Our membership site is also chalk full of information
            about how to use your books and testimonials from other members who have gotten listings
            with their books in creative ways.{' '}
          </p>
          <p>
            Wherever you go, try to keep a few copies of your book on hand. You never know when you
            might meet someone who could become a new lead. The same goes for your digital book
            link. Print it on your business cards or have a flyer on hand that has the link or a QR
            code to view it.
          </p>
        </S.Panel>

        <S.Panel header="What’s the best way to use my digital book?">
          <HelpVideo videoCode="wistia_async_kgmt7lkm35" />
          <p>
            The digital version of your Authorify book is included with every membership level, and
            it’s a great way to reach more people than ever before. You can show your leads that
            you’re a knowledgeable agent who can guide them through a successful transaction.{' '}
          </p>
          <p>Here are a few reasons we recommend making the most of your digital book:</p>

          <ul>
            <li>
              You can share it as many times as you want because it’s included with your Authorify
              membership.
            </li>
            <li>
              It is a complete copy of your book from beginning to end — unabridged! Furthermore, in
              the digital age, plenty of people read books on an electronic device.
            </li>
            <li>
              Anyone who reads your digital book will automatically find your Authority website,
              your contact information and your testimonials — in other words, they’ll get a
              well-rounded picture of you as an agent.
            </li>
            <li>
              Your digital book is easy to include on all kinds of marketing material, including
              emails, Facebook events and more.{' '}
            </li>
          </ul>

          <p>Here are eight ways you can use your digital book in your real estate business.</p>

          <p>
            <strong>1. Share your digital book link in pre-listing packages. </strong>
          </p>
          <p>
            When you send out a pre-listing package to a prospect, include a link to your digital
            book. You can share the link in your introduction letter, on its own “Read My Book”
            flyer or, really, anywhere the lead will easily see it.{' '}
          </p>

          <p>
            <strong>2. Send your digital book link in email or text introductions.</strong>
          </p>
          <p>
            Sending an email or text to a lead to see if they are interested in selling is the
            perfect way to share your digital book. They’ll get a better sense of your knowledge,
            and, of course, they’ll learn more about you from your Authority website. Put the
            digital book link in your email signature and include it in the text of the actual
            email.{' '}
          </p>

          <p>
            <strong>3. Put a link or QR code on any printed materials.</strong>
          </p>
          <p>
            Any printed materials you leave with prospects — flyers, postcards, brochures, etc —
            should invite people to view your digital book. It’s a simple way to share with leads
            that you’ve written a book without actually giving them a printed copy (unless, of
            course, they request one).{' '}
          </p>
          <p>
            You can also create a free QR code that your leads can scan with their phone cameras and
            instantly be linked to your digital book. This saves them the hassle of typing in your
            link. You can visit any number of free QR code generator sites, like
            qr-code-generator.com and create your own QR code image that you can download and use on
            all of your printed materials.{' '}
          </p>

          <p>
            <strong>
              4. Email or text a link to your digital book for past clients to share with everyone
              they know.
            </strong>
          </p>
          <p>
            When you have a satisfied client and the time comes to ask them for a review or
            testimonial, go ahead and send them a link to your digital book. Remind them that it’s
            free for anyone in their own circle of friends and family to read — and that you’d love
            to hear from anyone they know.{' '}
          </p>

          <p>
            <strong>
              5. Share the link to your digital book whenever you connect with someone you met
              through someone else.{' '}
            </strong>
          </p>
          <p>
            If you meet a new potential client through a former client or someone in your sphere,
            simply tell them where they can access your digital book if they’d like to read it. Say
            something like, “[Insert name] enjoyed reading my book; you might find it useful, as
            well.”
          </p>

          <p>
            <strong>6. Share links to your book on social media.</strong>
          </p>
          <p>
            Have you included the link to your digital book on every social media profile you have?
            Whether you’re a LinkedIn user, a Facebooker, or an Instagrammer, don’t forget to
            shepherd your followers to your book so they can learn more about you. Put the link in
            your “About” information so anyone who visits your page can find it. We also recommend
            sharing the link as a post every now and then with a headline like, “Looking to sell
            your home? Check out my book that shows you how to get the most money in the least
            amount of time! Click and read it now for free!”
          </p>
          <p>
            You can also encourage shares of your digital book by holding a contest for people who
            share your posts. Enter them into a drawing for a gift card to a local restaurant or
            tickets to a sporting event.{' '}
          </p>

          <p>
            <strong>
              7. Share links to your book on Zillow, Realtor.com, and other real estate websites.
            </strong>
          </p>
          <p>
            Add a link to your book on all of your real estate profiles, Google My Business, or any
            place your contact information appears. These are prime locations to add a link to your
            digital book so anyone who looks you up can read it.
          </p>

          <p>
            <strong>8. Include your digital book link in your listing presentation.</strong>
          </p>
          <p>
            As you work through your listing presentation, reference different chapters in your book
            to address various concerns and prove your expertise. You can share your link with the
            homeowners at any time to show them where they can read more after your presentation.
            Even if they don’t list with you at the appointment, you’ve given them something they
            can reference later on and learn more about what you offer.{' '}
          </p>
        </S.Panel>

        <S.Panel header="Is my digital book mobile compatible?">
          <HelpVideo videoCode="wistia_async_el0gwe2ozp" />
          <p>
            Yes. In fact, if you pull it up on your phone, the book automatically rotates when you
            turn your phone on its side, so you can literally flip through it. Of course, the text
            is smaller than it would be on a bigger screen, but people can still see and appreciate
            your expert status and zoom in and out to enlarge the text.{' '}
          </p>
        </S.Panel>

        <S.Panel header="How do I include ads in my book?">
          <HelpVideo videoCode="wistia_async_qovx56znrr" />
          <p>
            In the Book Builder App, there is a resource section that allows you to add content from
            your referral partners.{' '}
          </p>
          <p>
            The benefit here is mutual. You get advertisers to cover the cost of your books, and the
            advertisers get easy exposure to all the leads who receive them.
          </p>

          <p>
            Due to RESPA guidelines, you must carefully go through a process to sell advertising.
          </p>
          <p>
            According to the NAR, RESPA prohibits a real estate broker or agent from receiving a
            “thing of value” for referring business to a settlement service provider, or SSP, such
            as a mortgage banker, mortgage broker, title company, or title agent.
          </p>

          <p>
            Receiving kickbacks is illegal. And wrong. But, selling advertising is legal. Here’s how
            you can set up a mini-advertising company and sell ads inside your book.
          </p>
          <p>Let’s go through the steps.</p>

          <p>
            <strong>Step 1. Set up an LLC for your business.</strong>
          </p>
          <ul>
            <li>
              Set up an LLC for $150 on{' '}
              <a href="https://www.legalzoom.com/" draggable="false">
                Legalzoom.com
              </a>
            </li>
            <li>Or Google "Establish a (state name) LLC"</li>
          </ul>
          <p>
            Creating your own Marketing company enables you legally and ethically sell advertising
            to anyone who wants to advertise to the people you are marketing to.
          </p>

          <p>
            <strong>Step 2. Set up a bank account for your business.</strong>
          </p>
          <p>
            Once you have the corporation established, you’ll want to set-up a bank account. Just
            bring your corporation documents to your bank, and the clerk will handle everything to
            get it set up.
          </p>

          <p>
            <strong>Step 3. Research competition and determine advertising rates.</strong>
          </p>
          <ul>
            <li>Research advertising rates in your area</li>
            <li>
              Put together an advertising rate sheet so you can determine a fair price to charge
              anyone who wants to advertise with you.{' '}
            </li>
          </ul>

          <p>
            <strong>Step 4. Find potential advertisers and set up an appointment.</strong>
          </p>
          <p>Contact the following to discuss advertising within your books:</p>

          <ul>
            <li>Lenders &amp; Mortgage Brokers</li>
            <li>Title Companies or Title Attorneys</li>
            <li>Moving Companies</li>
            <li>Storage Companies</li>
            <li>Staging Professionals</li>
            <li>State Auctioneers</li>
            <li>Home Inspectors</li>
            <li>Painting Contractors</li>
            <li>Handymen</li>
          </ul>

          <p>
            <strong>Step 5. Present the marketing opportunity to potential advertisers.</strong>
          </p>
          <p>
            Explain the mutual benefit of advertising in your books. They'll get direct access to
            local home buyers and sellers, and because you're a published author, their business is
            immediately boosted to expert status. You can also offer to personally recommend them to
            all of your clients.{' '}
          </p>

          <p>
            <strong>Step 6. Get an agreement signed by the advertiser.</strong>
          </p>
          <p>It’s better to have things in writing so everyone understands what is happening.</p>

          <p>
            <strong>Step 7. Get a check from the advertiser.</strong>
          </p>
          <p>
            Have them write a check to your advertising company. Deposit the check into your
            company's bank account, and you are in business.
          </p>

          <p>
            <strong>Step 8. Put together the advertisements for your customers.</strong>
          </p>
          <p>
            Under the Resources section of your book in the Book Builder App, we have included
            customizable buyer and seller templates for a variety of referral partners. Add these
            templates to your book and type in the information of the professionals advertising in
            your books. If you have questions about how this all works, get in touch with your
            Marketing Success Coach.{' '}
          </p>
        </S.Panel>
      </S.Collapse>

      <S.Collapse.Title>General Questions</S.Collapse.Title>
      <S.Collapse ghost bordered={false}>
        <S.Panel header="Why is my website showing the wrong book cover?">
          <p>
            It can take up to five minutes for your book cover to appear on your website after
            generating it. So if you edit your book cover and generate your book, please give your
            website a few minutes to show your changes. If your website still shows the wrong book
            cover after five minutes, please let us know.
          </p>
        </S.Panel>
      </S.Collapse>
    </>
  );
};

export default FAQ;
