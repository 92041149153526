import { PrimaryButton } from 'modules/v2/common/components';
import { Item, Address } from './components';
import S from './styles';

const OrderReview = ({ children, onComplete, payloadData, 'data-testid': dataTestId }) => {
  return (
    <>
      <S.ListWrapper>{children}</S.ListWrapper>
      {onComplete && (
        <S.ButtonWrapper>
          <PrimaryButton
            data-testid={dataTestId}
            onClick={() => onComplete(payloadData)}
            type="primary"
          >
            Complete Order
          </PrimaryButton>
        </S.ButtonWrapper>
      )}
    </>
  );
};

OrderReview.Item = Item;
OrderReview.Address = Address;

export default OrderReview;
