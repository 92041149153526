import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, map } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';

import { getEmailCampaign, updateEmailCampaign, updateCampaignStatus } from 'modules/api';
import { getRouteLaunchCampaign } from 'modules/v2/routes/navigation';
import { DashboardTemplate } from 'modules/v2/templates';
import { EmailCard, EmailCampaignSidebar } from 'modules/v2/components';

import validationSchema from './validationSchema';

import { NavBar } from './components';
import S from './styles';

const EmailCampaign = ({ history }) => {
  const queryClient = useQueryClient();
  const { id: campaignId } = useParams();
  const { data, isLoading, isError } = useQuery(['getEmailCampaign', campaignId], () =>
    getEmailCampaign(campaignId),
  );

  const formFields = get(data, 'data', {});
  const content = get(formFields, 'content', {});
  const emails = get(content, 'emails', []);

  const {
    mutate: updateCampaign,
    isLoading: isLoadingUpdateEmailCampaign,
    isError: isErrorUpdateEmailCampaign,
  } = useMutation((formData) => updateEmailCampaign(formFields.id, formData), {
    onSuccess: () => {
      history.replace(getRouteLaunchCampaign());
    },
  });

  const {
    mutate: mutateCampaignStatus,
    isLoading: isLoadingUpdateCampaignStatus,
    isError: isErrorUpdateCampaignStatus,
  } = useMutation(
    ({ id, status }) => {
      updateCampaignStatus(id, status);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getEmailCampaign', campaignId]);
      },
    },
  );

  const handleCancel = () => {
    history.push(getRouteLaunchCampaign());
  };

  const handleFormSubmit = (formData) => {
    updateCampaign(formData);
  };

  const isPageLoading = isLoading || isLoadingUpdateEmailCampaign || isLoadingUpdateCampaignStatus;
  const hasPageErrors = isError || isErrorUpdateEmailCampaign || isErrorUpdateCampaignStatus;

  return (
    <Formik
      enableReinitialize
      initialValues={formFields}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      <DashboardTemplate
        isLoading={isPageLoading}
        // hasNoData={isEmpty(emails)}
        isError={hasPageErrors}
        loadingTitle="Loading Campaign"
        noDataTitle="No Campaign Available"
        marginRight="0"
        hasSidebar
        navBar={<NavBar onCampaignStatusUpdate={mutateCampaignStatus} onCancel={handleCancel} />}
      >
        <S.Layout>
          <EmailCampaignSidebar />
          <S.Wrapper>
            {map(emails, (email, index) => (
              <EmailCard key={index} email={email} />
            ))}
          </S.Wrapper>
        </S.Layout>
      </DashboardTemplate>
    </Formik>
  );
};

export default EmailCampaign;
