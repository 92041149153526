import styled from 'styled-components';
import * as color from 'modules/common/theme/color';
import { TickedIcon as BaseTickedIcon } from 'modules/onboard/components/UIComponents';
import { device as BreakPoints } from 'modules/common/theme/mediaqueries';

const AdBlockWrap = styled.div`
  display: flex;
  width: 100%;
  background-color: ${color.OfferBackground};
  padding: 30px 20px;
  justify-content: space-around;
  align-items: center;
  @media screen and ${BreakPoints.tabletS} {
    max-width: unset;
    width: 100%;
    display: block;
  }
`;

const ImgContainer = styled.div`
  margin-right: 20px;
  @media screen and ${BreakPoints.tabletS} {
    margin: unset;
  }
`;

const CoverImage = styled.img`
  object-fit: cover;
  max-width: 450px;
  width: 100%;
  height: 320px;
  @media screen and ${BreakPoints.tabletS} {
    width: 100%;
    object-fit: cover;
    height: unset;
    margin-bottom: 20px;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  margin: 10px auto;
  color: ${color.Black};
  line-height: 1.2;
  @media screen and ${BreakPoints.tabletS} {
    margin: unset;
  }
`;

const SubHeading = styled.div`
  font-size: 18px;
  color: ${color.Black};
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
  margin: 15px auto;
`;

const TextPointsContainer = styled.ul`
  display: block;
`;

const TextPoints = styled.li`
  display: flex;
  margin-bottom: 10px;
`;

const AdPoint = styled.p`
  margin-left: 13px !important;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: ${color.Black};
  flex: 1;
`;

const TextBox = styled.div`
  display: block;
`;

const TickedIcon = styled(BaseTickedIcon)`
  color: ${color.DefaultBlue};
  font-size: 16px;
`;

export default {
  AdBlockWrap,
  ImgContainer,
  Heading,
  TextPointsContainer,
  TextPoints,
  AdPoint,
  CoverImage,
  TextBox,
  SubHeading,
  TickedIcon,
};
