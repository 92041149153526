import { createSelector } from 'reselect';

import { editorSelector } from 'modules/editor/store/selectors/editor';

export const saveSelector = createSelector(editorSelector, (editor) => editor.save);

export const changedSelector = createSelector(saveSelector, (save) => save.changed);
export const savedSelector = createSelector(saveSelector, (save) => save.saved);
export const isSavingSelector = createSelector(saveSelector, (save) => save.saving);
export const isPendingSaveSelector = createSelector(saveSelector, (save) => save.pending);
