import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const MainWrapper = styled.div`
  padding: 24px 0 24px 0;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  margin: 1rem 0;
`;

const CardTitle = styled.p`
  width: 100%;
  padding: 10px 0 10px 20px;
  background: ${color.GreyHover} 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0 0;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  color: ${color.Black};
  opacity: 1;
  line-height: unset;
`;

const CardNumber = styled.span`
  height: 43px;
  text-align: center;
  letter-spacing: 0;
  color: ${color.Black};
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
`;

const CardLabel = styled.span`
  width: 142px;
  height: 23px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: bold;
  color: ${color.Black};
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 32%;
  border: 1px solid ${color.GreyBorder};
  border-radius: 5px;
  background: ${color.White};
`;

const CardDetail = styled.div`
  display: block;
`;

const CardBody = styled.div`
  text-align: center;
  margin: 20px auto;
`;

const DashboardTitle = styled.h3`
  font-size: 24px;
  line-height: 46px;
  font-weight: bold;
  margin: 20px 0 0px 0 !important;
`;

const DashboardSubTitle = styled.span`
  font-size: 16px;
  line-height: 16px;
`;
const DatePicker = styled.div`
  margin: 15px 0;
  .ant-input {
    width: 60%;
    display: block;
  }
`;
CardLabel.Percentage = styled.span`
  margin-left: 2px;
  color: ${({ percentageIncreased }) =>
    percentageIncreased ? color.NotificationSuccessBorder : color.DangerRed};
  font-weight: bold;
`;

export default {
  MainWrapper,
  CardWrapper,
  Card,
  CardLabel,
  CardNumber,
  CardTitle,
  CardDetail,
  CardBody,
  DashboardTitle,
  DashboardSubTitle,
  DatePicker,
};
