import { Loader } from 'modules/common/components';
import { AuthorifyIcon } from 'modules/common/components/SvgIcon';
import { OnboardTemplate } from 'modules/onboard/templates';

import S from './styles';

const Wait = ({ title, description }) => (
  <OnboardTemplate isStepsVisible={false}>
    <S.ResultWrap>
      <Loader
        title={title}
        height="100%"
        hasIcon
        description={description}
        icon={<AuthorifyIcon />}
      />
    </S.ResultWrap>
  </OnboardTemplate>
);

export default Wait;
