import get from 'lodash/get';
import { ErrorMessage } from 'modules/v2/common/components';

import { useEffect } from 'react';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import isEmpty from 'lodash/isEmpty';

import { htmlReplacers } from '../../../constants';
import { replaceBrWithNewLine, replaceNewLineWithBR } from './utils';

const FrontInsideCover = ({ step = 'frontInsideCover', frontInsideCover, updateMagazineData }) => {
  const { magazineDataFormik, previousMagazineData, setFormikContext } = updateMagazineData;

  const { values = {}, setFieldValue, errors } = magazineDataFormik || {};
  const targetLocation = step.concat('.', '_formFields');

  const handleChange = (e) => {
    const {
      target: { name = '', value = '' },
    } = e || {};
    const locationToSave = step.concat('.', '_formFields', '.', name);
    setFieldValue(locationToSave, value);
  };

  const { html = '', defaultText = '' } = frontInsideCover[0] || {};

  const frontInsideCoverText = replaceBrWithNewLine(
    get(values, `${targetLocation}.frontInsideCoverText`, defaultText) || defaultText,
  );

  useEffect(() => {
    const location = step.concat('.', '_formFields', '.', 'frontInsideCoverText');
    setFieldValue(location, frontInsideCoverText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Resize the iframe to fit the content.
    const iframe = document.querySelector('#front_cover');

    iframe.addEventListener('load', () => {
      setInterval(() => {
        iframe.style.height = `${iframe.contentDocument?.body?.scrollHeight}px`;
        iframe.style.width = `${iframe.contentDocument?.body?.scrollWidth}px`;
      }, 500);
    });
  }, []);

  const updateValuesInHtmlString = () => {
    let htmlInfoString = html;
    // update the basic information
    htmlReplacers.forEach((element) => {
      const basicDetailsLocation = `magazine._formFields.${element}`;
      const valueToReplace = get(values, basicDetailsLocation, '');
      if (valueToReplace) {
        htmlInfoString = htmlInfoString.split(`{{${element}}}`).join(valueToReplace);
      }
    });
    // update the frontInsideCover description
    htmlInfoString = htmlInfoString?.replace(
      '{{frontInsideCoverText}}',
      replaceNewLineWithBR(frontInsideCoverText),
    );

    return htmlInfoString || '';
  };

  const onHandleResetFormFields = () => {
    const locationToSave = `${targetLocation}.frontInsideCoverText`;

    if (!isEmpty(previousMagazineData)) {
      const insideCoverContent = previousMagazineData.selections.find(
        (selection) => selection.page === 2,
      );
      const { dynamicFields } = insideCoverContent;
      setFieldValue(locationToSave, replaceBrWithNewLine(dynamicFields[0].value));
    } else {
      setFieldValue(locationToSave, replaceBrWithNewLine(defaultText));
    }
    setFormikContext('current', -1);
  };

  const wordCount = frontInsideCoverText?.length || 0;

  return (
    <div className="pt-[57px]">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-[40%] flex flex-col pt-6 pr-6">
          <h5 className="text-neutral-800 text-sm font-semibold">
            Customize inside cover letter message
          </h5>
          <div className="overflow-hidden text-neutral-400 text-ellipsis text-sm font-medium">
            We'll add the Default Cover Letter unless you customize it below.
          </div>
          <textarea
            id="frontInsideCoverText"
            name="frontInsideCoverText"
            value={replaceBrWithNewLine(frontInsideCoverText)}
            onChange={handleChange}
            className="w-full h-full p-4 rounded-lg border border-neutral-200 mt-2 resize-none"
          />
          <ErrorMessage>
            {get(errors, 'frontInsideCover._formFields.frontInsideCoverText')}
          </ErrorMessage>
          <div className="flex justify-end">
            <div className="text-right text-neutral-400 text-ellipsis text-sm font-medium">
              {`${wordCount}/1500`} characters
            </div>
          </div>
          <div className="flex gap-2 justify-start mt-6">
            <Button color="light" onClick={onHandleResetFormFields}>
              Cancel
            </Button>
            <Button color="dark" buttonType="submit" onClick={magazineDataFormik.handleSubmit}>
              Save changes
            </Button>
          </div>
        </div>
        <div className="w-full xl:w-[60%] rounded-lg p-6 bg-neutral-50 border-neutral-100">
          <iframe
            id="front_cover"
            title="front cover"
            srcDoc={updateValuesInHtmlString()}
            className="w-full max-w-[99.5%] aspect-video overflow-hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default FrontInsideCover;
