import { call, takeLatest } from 'redux-saga/effects';
import { notification } from 'modules/common/utils';

import { updatePreferences, resource, getError } from 'modules/api';
import { UPDATE_PREFERENCES } from 'modules/dashboard/store/constants';

function* onUpdatePreferences({ payload }) {
  try {
    const request = resource(UPDATE_PREFERENCES, updatePreferences);
    const response = yield call(request, payload);

    notification.success({ description: 'Changes saved successfully' });

    return response;
  } catch (error) {
    const response = getError(error);
    // eslint-disable-next-line no-console
    console.error(response);

    notification.error({ description: 'Changes could not be saved' });

    return response;
  }
}

export function* watchUpdatePreferences() {
  yield takeLatest(UPDATE_PREFERENCES, onUpdatePreferences);
}
