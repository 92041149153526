import { useState } from 'react';
import { useQuery } from 'react-query';
import { isEmpty, map, get } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { getRouteLaunchCampaign } from 'modules/v2/routes/navigation';
import { DashboardTemplate } from 'modules/v2/templates';
import Table from 'modules/v2/common/components/Table';
import { formatDate } from 'modules/v2/common/utils';
import { getCampaignHistory } from 'modules/api';
import { BackLinkButton } from 'modules/v2/common/components';

const CampaignHistory = () => {
  const { id: campaignId } = useParams();
  const [campaignName, setCampaignName] = useState();

  const { data: campaignHistory, isLoading } = useQuery(
    ['campaignHistory', campaignId],
    () => getCampaignHistory(campaignId),
    {
      onSuccess: (data) => {
        setCampaignName(get(data, ['0', 'campaignName'], ''));
      },
    },
  );

  const tableColumns = [
    {
      title: 'Date Email Sent',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <span>{formatDate(createdAt, 'MM-DD-YYYY')}</span>,
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignName',
      key: 'campaignName',
    },
    {
      title: 'Template Name',
      dataIndex: 'templateNames',
      key: 'templateNames',
      render: (templateNames) => map(templateNames).join(', '),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Emails Sent',
      dataIndex: 'messageIds',
      key: 'messageIds',
      render: (messageIds) => messageIds.length,
    },
  ];

  return (
    <DashboardTemplate hasSidebar>
      <BackLinkButton href={getRouteLaunchCampaign} title="Back to my campaigns" />

      <h3>Campaign History</h3>
      <h4>Campaign's Name: {campaignName}</h4>
      <Table
        hasData={!isEmpty(campaignHistory)}
        rowKey="id"
        dataSource={campaignHistory}
        columns={tableColumns}
        loading={isLoading}
      />
    </DashboardTemplate>
  );
};

export default CampaignHistory;
