import styled from 'styled-components';
import { Button } from 'antd';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Wrapper = styled.div`
  display: flex;
`;

const ResetButton = styled(Button)`
  font-family: ${font.MontserratFont};
  font-size: 14px;
  margin-right: 10px;

  &,
  &:hover,
  &:active,
  &:focus {
    font-weight: normal !important;
    color: ${color.PrimaryColor};
  }

  &:hover {
    background: ${color.ResetButtonHoverBg};
  }

  .anticon {
    font-size: 18px;
  }
`;

const Divider = styled.div`
  width: 1px;
  background: ${color.GreyFormBorder};
  margin-right: 10px;
`;

export default {
  ResetButton,
  Divider,
  Wrapper,
};
