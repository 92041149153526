// import './configuration/render-monitoring';
import 'react-hot-loader';

import ReactDOM from 'react-dom';

import Root from './modules/Root';

import plugins from './configuration/plugins';
import graphql from './configuration/graphql';
import store from './configuration/store';
import './configuration/datadog';

ReactDOM.render(<Root store={store} graphql={graphql} />, document.getElementById('root'));

plugins();
