import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const FooterContainer = styled.div`
  height: ${({ tiny }) => (tiny ? '32px' : '120px')};
  background: ${color.BrandColor};
  color: ${color.White};
  width: 100%;
`;

const FooterWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: ${({ tiny }) => (tiny ? '0' : '25px')};
  font-size: 16px;
  font-size: ${({ tiny }) => (tiny ? '12px' : '16px')};
  line-height: ${({ tiny }) => (tiny ? '32px' : '24px')};
  margin: 0 auto;
  font-weight: 200;
  @media (max-width: 768px) {
    width: 100%;
  }
  a {
    color: #fff;
    :hover {
      text-decoration: underline !important;
    }
  }
`;

export default {
  FooterContainer,
  FooterWrapper,
};
