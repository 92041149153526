import styled from 'styled-components';
import { Layout as LayoutBase } from 'antd';

import * as color from 'modules/common/theme/color';

const ListCards = styled.div`
  padding: 0 100px;
  line-break: auto;
`;
const ContentInner = styled.div`
  border: 2px solid ${color.GrayBorder};
  border-radius: 2px;
  min-height: calc(90vh - 206px);
  padding: 20px;
`;

const Card = styled.div`
  float: left;
  width: 150px;
  margin-right: 30px;
  display: block;
`;

const CardImage = styled.div`
  width: 100%;
  border: 1px solid ${color.GrayBorder};
  position: relative;
  min-height: 145px;

  img {
    width: 130px;
    height: 145px;
  }
`;

const CardTitle = styled.span`
  font-size: 14px !important;
  font-weight: bold;
  margin-top: 10px;
  display: block;
  text-align: center;
  color: ${color.Black};
`;

const CardCount = styled.div`
  position: absolute;
  z-index: 1;
  top: 56px;
  height: 32px;
  width: 130px;
  text-align: center;
  padding: 7px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${color.White};
`;

const Wrapper = styled(LayoutBase.Content)`
  padding: 20px;
`;

const WrapperMainTitle = styled.h3`
  display: block;
  margin-bottom: 20px !important;
`;

const WrapperTitle = styled.h4`
  display: block;
  margin-bottom: 20px !important;
`;

export default {
  ListCards,
  ContentInner,
  Card,
  CardImage,
  CardTitle,
  CardCount,
  Wrapper,
  WrapperTitle,
  WrapperMainTitle,
};
