//

import { STATUS_INIT } from 'store/status';

export const draft = {
  data: null,
  status: STATUS_INIT,
};

export const links = {
  data: null,
  status: STATUS_INIT,
};

export const preview = {
  data: null,
  status: STATUS_INIT,
};

export const preflight = {
  data: null,
  status: STATUS_INIT,
};

export default {
  draft,
  links,
  preflight,
  preview,
};
