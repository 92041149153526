import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { call, delay, put, race, take, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash-es';

import { setItem } from 'modules/dashboard/utils/legacy';
import { getRouteHomePage } from 'modules/dashboard/routes/navigation';

import { fetchAutoSignin, getError, getPayload } from 'modules/api';

import { AUTH_AUTO_SIGNIN } from 'modules/auth/store/constants';
import {
  isAutoSigninStatusEmpty,
  isAutoSigninStatusFailed,
  isAutoSigninStatusAuthorized,
} from 'modules/auth/store/utils';

const RETRY_TIME = 1000;

export function* onGetAutoSignin({ payload: meta }) {
  yield put({
    type: `${AUTH_AUTO_SIGNIN}_REQUESTED`,
    meta,
  });

  try {
    while (true) {
      const response = yield call(fetchAutoSignin, meta);
      const payload = getPayload(response);

      const status = get(response, ['data', 'status']);

      const isResolved = [
        isAutoSigninStatusEmpty,
        isAutoSigninStatusAuthorized,
        isAutoSigninStatusFailed,
      ].some((check) => check(status));

      if (isAutoSigninStatusFailed(status)) {
        yield put({
          type: `${AUTH_AUTO_SIGNIN}_FAILED`,
          error: true,
          payload,
        });

        return payload;
      }

      if (isAutoSigninStatusAuthorized(status)) {
        const accessToken = get(payload, 'token');
        const refreshToken = get(payload, 'refresh_token');
        const userData = get(payload, 'properties', {});

        const location = getRouteHomePage();

        setItem('token', accessToken);
        setItem('refresh_token', refreshToken);
        setItem('userData', userData);

        // Use replace to avoid returning to the same URL with back button
        yield put(replace(location));
      }

      // The data may have the status failed, but the action has succeeded
      if (isResolved) {
        yield put({
          type: `${AUTH_AUTO_SIGNIN}_SUCCEEDED`,
        });

        return payload;
      }

      yield delay(RETRY_TIME);
    }
  } catch (error) {
    const payload = getError(error);

    yield put({
      type: `${AUTH_AUTO_SIGNIN}_FAILED`,
      error: true,
      payload,
    });

    // eslint-disable-next-line no-console
    console.error(payload);

    return payload;
  }
}

export function* watchCancellation(action) {
  yield race([call(onGetAutoSignin, action), take(LOCATION_CHANGE)]);
}

export function* watchGetAutoSignin() {
  yield takeEvery(AUTH_AUTO_SIGNIN, watchCancellation);
}
