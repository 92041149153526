import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const Toolbar = styled.div`
  height: 72.5px;

  .normal {
    color: ${color.GreySelectDd};
    background: no-repeat;
    border: 2px solid transparent;
    border-radius: 6px;
    font-size: 15px;
    line-height: 21px;
    font-weight: 600;
    margin: 0 3px;
    padding: 3px 5px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: inline-block;
    background-color: ${color.neutral100};
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #f0f5fa;
      border-color: ${color.primary500};
    }

    &.active {
      border-color: ${color.primary500};
    }
  }
`;

export default {
  Toolbar,
};
