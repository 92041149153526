import styled from 'styled-components';
import { DatePicker as DatePickerBase, Input as InputBase } from 'antd';
import { LineOutlined } from '@ant-design/icons';
import * as color from 'modules/common/theme/color';

const { Search } = InputBase;

const { RangePicker } = DatePickerBase;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 0 22px 0;

  span {
    margin: 0;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 1px;
`;

const Description = styled.div`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 20px;

  b {
    margin: 0 4px;
  }
`;

const DatePicker = styled(RangePicker)`
  margin: 10px 0 20px 0;

  .ant-calendar-picker-input.ant-input {
    width: 316px;
    height: 40px;
  }
`;

const SearchInput = styled(Search)`
  width: 316px;
  height: 40px;
`;

const CardsReport = styled.div`
  width: 100%;
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;

  width: 100%;
  margin-bottom: 20px;

  .ant-card {
    width: calc((100% - 30px) / 3);
  }

  .ant-card-head {
    background: ${color.TableHeader};
  }

  .ant-card-head-title {
    font-weight: bold;
    padding: 10px 0 !important;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 145px;
  }
`;

const FocusedCard = styled.p`
  color: ${color.Black};
  font-size: 32px;
  font-weight: bold;
`;

const DescriptionCard = styled.p`
  color: ${color.Black};
  font-size: 16px;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const EmptyTableAtribute = styled(LineOutlined)`
  font-size: 20px;
  color: #a6a6a6;
  margin-left: 20%;
`;

const CapitalizeText = styled.span`
  text-transform: capitalize;
  margin: 0 8px;
`;

export default {
  HeaderContainer,
  Title,
  Description,
  DatePicker,
  SearchInput,
  CardsReport,
  CardWrapper,
  FocusedCard,
  DescriptionCard,
  TableWrapper,
  EmptyTableAtribute,
  CapitalizeText,
};
