//

import { Droppable, Draggable } from 'react-beautiful-dnd';

import classnames from 'classnames';

import { DragIcon } from 'modules/common/components/SvgIcon';

import S from './styles';

const ListSorting = ({ droppableId, selectedId, items = [], onEdit, onSelect, isDragDisabled }) => {
  const onEditCallback = (e, item) => {
    e.stopPropagation();
    onEdit(item);
  };

  const onSelectCallback = (e, item) => {
    e.stopPropagation();
    onSelect(item);
  };

  return (
    <Droppable droppableId={droppableId} isCombineEnabled={false}>
      {(droppable) => (
        <div ref={droppable.innerRef}>
          <S.SortingList
            bordered
            dataSource={items}
            renderItem={(item, index) => (
              <Draggable
                key={item._id}
                draggableId={item._id}
                index={index}
                isDragDisabled={isDragDisabled}
              >
                {(draggable) => {
                  const selected = item._id === selectedId;

                  return (
                    <li
                      ref={draggable.innerRef}
                      {...draggable.draggableProps}
                      {...draggable.dragHandleProps}
                      className={classnames('ant-list-item', { selected })}
                    >
                      <div
                        className="ant-list-item-content"
                        onClick={(e) => onSelectCallback(e, item._id)}
                      >
                        {items.length > 1 && (
                          <S.Icon>
                            <DragIcon />
                          </S.Icon>
                        )}
                        <S.SectionItemName title={item.displayName}>
                          {item.displayName}
                        </S.SectionItemName>
                        <S.MenuWrap>
                          {onEdit && (
                            <S.EditLink onClick={(e) => onEditCallback(e, item._id)}>
                              Edit
                            </S.EditLink>
                          )}
                        </S.MenuWrap>
                      </div>
                    </li>
                  );
                }}
              </Draggable>
            )}
          >
            {items.length > 0 && droppable.placeholder}
          </S.SortingList>
        </div>
      )}
    </Droppable>
  );
};

export default ListSorting;
