import { DeleteOutlined } from '@ant-design/icons';
import S from './styles';

const ResetButton = ({ onClick, hasDivider }) => (
  <S.Wrapper>
    <S.ResetButton icon={<DeleteOutlined />} type="link" onClick={onClick}>
      Reset
    </S.ResetButton>
    {hasDivider && <S.Divider />}
  </S.Wrapper>
);

export default ResetButton;
