import styled from 'styled-components';
import * as color from 'modules/common/theme/color';

const TableWrap = styled.div`
  margin-bottom: 30px;
  .title {
    color: ${color.SkyBlue};
    cursor: pointer;
  }
  .centerAligned {
    text-align: center;
  }
  table {
    border-bottom: 5px solid ${color.GrayBorder};
    table-layout: fixed;
  }
  th:last-child,
  td:last-child {
    text-align: center;
  }
`;

const AllOfferFeaturesWrap = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-input svg {
    font-size: 25px;
  }
  span.ant-input-search.ant-input-affix-wrapper {
    font-size: 25px;
  }
`;

const SelectSearchWrapper = styled.div`
  display: flex;
  .ant-btn-icon-only {
    width: 45px;
    height: 47px;
    padding: 2.4px 0;
    font-size: 16px;
    border-radius: 2px;
    vertical-align: -3px;
  }
  .ant-input {
    width: 316px;
    padding: 10px 20px;
    border-radius: 2px;
    font-size: 16px;
    .anticon .anticon-search .ant-input-search-icon svg {
      width: 24px;
      height: 24px;
      margin-right: 24px;
    }
  }
`;

const SelectWrap = styled.select`
  font-size: 16px;
  border: 1px solid ${color.GreyFormBorder};
  color: ${color.GreyFormBorder};
  background: ${color.White};
  margin-left: 10px;
  text-transform: capitalize;
  min-width: 165px;
  border-radius: 2px;
  height: 40px;
  padding: 9px 20px;
  line-height: 24px;
  &:disabled {
    background-color: ${color.GreyHover};
    cursor: not-allowed;
  }
`;

const SelectOption = styled.option`
  font-size: 14;
`;

const ModalContainer = styled.div`
  margin: 10px 5px;
  padding: 20px 20px;
  height: 450px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${color.SideBarBackground};
    border-radius: 2px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    background: ${color.scrollbarThumb};
  }
`;

const TextContainer = styled.div`
  display: block;
  margin-bottom: 30px;
  border-bottom: ${(props) => (props.borderBottom ? `1.87px dashed ${color.GreyDropDown}` : '0px')};
  padding-bottom: ${(props) => (props.paddingBottom ? '20px' : '0px')};
`;

const DataWrapper = styled.div`
  display: flex;
`;
const Text = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
  min-width: ${(props) => (props.noWidth ? 'auto' : '163px')};
  text-align: ${(props) => (props.isValue ? 'left' : 'right')};

  font-weight: ${(props) => (props.isValue ? 800 : 400)};
  margin-left: ${(props) => (props.isValue ? '10px' : '0px')};
`;

export default {
  AllOfferFeaturesWrap,
  DataWrapper,
  ModalContainer,
  SelectOption,
  SelectSearchWrapper,
  SelectWrap,
  TableWrap,
  Text,
  TextContainer,
};
