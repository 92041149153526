import { useState } from 'react';
import { useMutation } from 'react-query';
import TrainingWebinarThumbnail from 'assets/images/training-webinar-thumbnail.png';
import { scheduleTraining } from 'modules/api';
import { TimeCard, ConfirmComponent, TimeSlots, ContinueButton } from 'modules/onboard/components';
import { AuthorifyIcon } from 'modules/common/components/SvgIcon';
import {
  notification,
  getCurrentTimeZone as timezone,
  getTimeSelectedZone,
  getCurrentTimeZoneWithDetachment,
} from 'modules/common/utils';
import { Loader, AlertWithButton } from 'modules/common/components';
import { isDentistOffer } from 'modules/dashboard/utils';
import { OnboardTemplate } from 'modules/onboard/templates';
import { useFetchSession, useStep } from 'modules/onboard/hooks';
import { setSession } from 'modules/onboard/utils';
import { getRouteBookDetails } from 'modules/onboard/routes/navigation';
import useHubspotTrackCode from '../Common/HubspotTrackCode';

import S from './styles';

const TrainingWebinar = ({ history }) => {
  const [selectedSlot, setSelectedSlot] = useState();
  const { isLoading, isError, mutate, isSuccess } = useMutation(scheduleTraining);
  const { goToNextStep } = useStep();
  const { session } = useFetchSession();
  const isDentist = isDentistOffer();

  useHubspotTrackCode();

  const handleSubmit = () => {
    if (selectedSlot) {
      mutate(
        {
          payload: {
            slot: selectedSlot,
            sms: false, // TODO: SMS issue needs to be fixed first.
            timezone,
          },
          queryKey: {
            offerCode: session?.offer?.code,
            sessionId: session?.id,
          },
        },
        {
          onSuccess: (data) => {
            setSession(data);
            const { step } = data;
            goToNextStep(step);
          },
        },
      );
    } else {
      notification.error({ description: 'Please select a slot first!' });
    }
  };

  const { offer } = session || {};
  const offerCode = offer?.code;
  const queryString = window.location.search;

  const goToNextComponent = () => history.replace(getRouteBookDetails(offerCode, queryString));

  const handleSelectSlot = (slot) => setSelectedSlot(slot);

  const getFormattedTime = () =>
    getTimeSelectedZone(selectedSlot, timezone, session?.webinar?.timeZoneId || 'Etc/UTC').format(
      'h:mm a',
    );

  const onScheduleWebinarSuccess = () => setTimeout(goToNextComponent, 3000);

  const renderComponent = () => {
    if (isLoading) {
      return (
        <S.ResultWrap>
          <Loader
            height="100%"
            hasIcon
            title="Confirming Your Schedule"
            description="Please wait while we are scheduling your training webinar."
            icon={<AuthorifyIcon />}
          />
        </S.ResultWrap>
      );
    }

    if (isSuccess) {
      return (
        <S.ResultWrap>
          <ConfirmComponent
            onSuccess={onScheduleWebinarSuccess}
            heading="Schedule Webinar Confirmed"
            subHeading="You have successfully registered for the training webinar. Check your email for more information."
            imgWidth={35}
          />
        </S.ResultWrap>
      );
    }

    if (isError) {
      return (
        <S.ResultWrap>
          <AlertWithButton
            type="info"
            buttonTitle="Continue"
            description="We apologize. It seems that we are having a technical/system failure while booking a schedule webinar. However, someone will reach out to you shortly to schedule an alternative training webinar."
            handleClick={goToNextComponent}
          />
        </S.ResultWrap>
      );
    }

    const formattedTimezone = getCurrentTimeZoneWithDetachment();

    return (
      <S.TrainingWebinars>
        <S.RegisterFreeWrap>
          <S.CoverBox src={TrainingWebinarThumbnail} />
        </S.RegisterFreeWrap>
        <S.TrainingWebinarHeading>{session?.webinar?.title}</S.TrainingWebinarHeading>
        <S.TrainingSubHeading>{session?.webinar?.description}</S.TrainingSubHeading>
        <S.PickDateTimeBox>
          <S.TimeDateBoxWrap>
            <S.TimeDateHead>What’s your preferred date and time?</S.TimeDateHead>
            <S.Timezone>{formattedTimezone}</S.Timezone>
            <S.TimeDateCardWrap>
              <TimeSlots
                type="date"
                selectedSlot={selectedSlot}
                onSelectSlot={handleSelectSlot}
                slots={session?.webinar?.slots}
                serverTimeZone={session?.webinar?.timeZoneId || 'Etc/UTC'}
                userTimeZone={timezone}
              />
            </S.TimeDateCardWrap>
          </S.TimeDateBoxWrap>
        </S.PickDateTimeBox>
        <TimeCard
          timezone={timezone}
          time={getFormattedTime()}
          date={selectedSlot}
          meeting="webinar"
        />

        <S.SubmitWrapper>
          <ContinueButton loading={isLoading} onClick={handleSubmit} disabled={!selectedSlot}>
            Schedule Training Webinar
          </ContinueButton>
        </S.SubmitWrapper>
      </S.TrainingWebinars>
    );
  };

  return (
    <OnboardTemplate currentStep="3" hideNavigation isDentist={isDentist}>
      {renderComponent()}
    </OnboardTemplate>
  );
};

export default TrainingWebinar;
