import { createSelector } from 'reselect';
import { get } from 'lodash-es';

import { dashboardSelector } from 'modules/v2/store/selectors/dashboard';

export const booksStateSelector = createSelector(dashboardSelector, ({ books, categories }) => {
  let data = get(books, 'data', []);

  let selected = [];
  if (categories.selected && typeof categories.selected === 'object') {
    selected = Object.keys(categories.selected).filter((k) => categories.selected[k]);
    data = data?.filter((item) => selected.includes(item.category));
  }

  if (!selected.length) {
    return books;
  }

  return {
    ...books,
    data,
  };
});
