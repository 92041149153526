import { spawn } from 'redux-saga/effects';

import { watchEditOrderedDraft } from './editOrderedDraft';
import { watchGetDraftById } from './getDraft';
import { watchCreateDraft } from './createDraft';
import { watchReplaceCoverImage } from './replaceCoverImage';
import { watchUploadContentImage } from './uploadContentImage';
import { watchUploadCoverImage } from './uploadCoverImage';
import { watchUpdateCoverElement } from './updateCoverElement';
import { watchUpdateCoverElementsByPreferences } from './updateCoverElementsByPreferences';
import { watchResetDraft } from './resetDraft';
import { watchResetSection } from './resetSection';

export default function* saga() {
  yield spawn(watchEditOrderedDraft);
  yield spawn(watchGetDraftById);
  yield spawn(watchCreateDraft);
  yield spawn(watchReplaceCoverImage);
  yield spawn(watchUploadContentImage);
  yield spawn(watchUploadCoverImage);
  yield spawn(watchUpdateCoverElement);
  yield spawn(watchUpdateCoverElementsByPreferences);
  yield spawn(watchResetDraft);
  yield spawn(watchResetSection);
}
