//

import S from './styles';

const SectionHeader = ({ onNavigateBack }) => (
  <S.SectionHeader>
    <button type="button" onClick={onNavigateBack} className="ant-btn ant-btn-primary fleft">
      Back to Sections
    </button>
    {/* TODO: Implement in the next task
    <button type="button" onClick={onAddChapter} className="ant-btn ant-btn-primary fright">
      Add new Chapter
    </button>
    */}
  </S.SectionHeader>
);

export default SectionHeader;
