import withStore from 'store/StoreContainer';
import {
  // actions
  updatePreferences,
  resetDraft,
  resetSection,
  // routes
  goToDraftView,
  goToEditDraft,
  // selectors
  draftStateSelector,
  resetStateSelector,
  resetSectionStateSelector,
  isSavingSelector,
  isPendingSaveSelector,
} from 'modules/editor/store';

const mapStateToProps = (state) => ({
  draft: draftStateSelector(state),
  reset: resetStateSelector(state),
  resetSection: resetSectionStateSelector(state),
  isSaving: isSavingSelector(state),
  isPendingSave: isPendingSaveSelector(state),
});

const mapDispatchToProps = {
  resetDraft,
  resetCurrentSection: resetSection,
  updatePreferences,
  goToDraftView,
  goToEditDraft,
};

export default withStore(mapStateToProps, mapDispatchToProps);
