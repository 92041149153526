//

import { SaveIcon } from 'modules/v2/common/components/SvgIcon';

import S from './styles';

const Item = ({ title, value, linkTitle, onClick, 'data-testid': dataTestId }) => (
  <S.ContentWrapper>
    <S.ContentDescription>
      <S.Icon>
        <SaveIcon />
      </S.Icon>
      <span>{title}:</span>
      {value}
    </S.ContentDescription>
    {linkTitle && onClick && (
      <S.Link data-testid={dataTestId} type="link" onClick={onClick}>
        {linkTitle}
      </S.Link>
    )}
  </S.ContentWrapper>
);
Item.defaultProps = {
  linkTitle: undefined,
  onClick: undefined,
  value: undefined,
};

export default Item;
