import styled from 'styled-components';
import { Dropdown as BaseDropdown } from 'antd';

import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const SiteModal = styled.div`
  background: white;
  display: flex;
  position: relative;
  top: 0;
  box-shadow: 0px 1px 4px #b7b7b7;
`;

SiteModal.Item = styled.div`
  height: 197px;
  width: 274px;
  margin-top: -3px;
  padding: 15px 20px;
  border-right: 1px solid #f2f2f2;

  &:last-child {
    border-top: 3px solid ${color.PrimaryColor};
    border-bottom: 3px solid ${color.PrimaryColor};
  }
`;

SiteModal.Title = styled.strong`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  display: block;
  color: black;
`;

SiteModal.Text = styled.p`
  margin-bottom: 15px;
`;

SiteModal.ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Dropdown = styled(BaseDropdown)`
  align-items: center;
  width: 120px;
  cursor: pointer;

  &.ant-dropdown-trigger {
    height: 64px;

    :hover {
      border-bottom: 3px solid ${color.PrimaryColor};
      padding-bottom: 0;
    }
    transition: 0.3s ease all;
  }

  &.ant-dropdown-open {
    border-bottom: 3px solid ${color.PrimaryColor};

    span {
      color: ${color.ActiveMenuItemColor} !important;
    }
    .anticon {
      position: relative;
      transform: rotate(180deg);
    }
  }
  .anticon {
    transition: 0.3s ease all;
  }
`;

const NonLink = styled.span`
  padding: 2px 15px;
  line-height: 40px;
  display: block;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 600;
  touch-action: manipulation;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  color: #2e3f50;
`;

const Link = styled.a`
  display: flex;
  align-items: center;

  :hover {
    transition: 0.2s ease all;
  }
`;

Link.Span = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  padding: 20px;
  border-radius: 0;

  color: ${color.White} !important;
  font-weight: 500;
  font-family: ${font.FigtreeFont};
  line-height: 20px;
  text-transform: capitalize;

  :hover {
    color: ${color.ActiveMenuItemColor} !important;
  }

  :focus,
  :active,
  &.active {
    transition: 0.2s ease;
  }
`;

Link.Label = styled.span`
  display: inline-block;
  max-width: calc(100% - 21.99px);
  min-width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Link.Icon = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0 0 10px;

  .anticon {
    font-size: 18px !important;

    svg {
      margin-bottom: 2px;
    }

    :focus,
    :active,
    &.active {
      color: ${color.ActiveMenuItemColor} !important;
    }
  }

  transition: all 0.5s linear;
`;
export default {
  SiteModal,
  Dropdown,
  Link,
  NonLink,
};
