import styled from 'styled-components';
import { DatePicker as DatePickerBase } from 'antd';
import { LineOutlined } from '@ant-design/icons';
import * as color from 'modules/common/theme/color';

const { RangePicker } = DatePickerBase;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 23px 0 13px;
`;

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 28px;
  position: relative;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 16px;
  }
`;

const MultipleSelect = styled.div`
  .ant-select-selector {
    min-width: 336px;
    max-width: 624px;
    overflow: hidden;
  }
`;

const ExportContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DatePicker = styled(RangePicker)`
  margin: 10px 0 20px 0;

  .ant-calendar-picker-input.ant-input {
    width: 316px;
    height: 40px;
  }
`;

const EmptyTableAtribute = styled(LineOutlined)`
  font-size: 20px;
  color: ${color.EmptyAtribute};
  margin-left: 20%;
`;

const Title = styled.h1`
  font-size: 25px;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
`;

const CustomerContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 700;
  }
`;

const ExportInformationBox = styled.div`
  z-index: 2;

  transform: ease-in-out 0.3s;

  position: absolute;
  right: 0;
  top: 56px;
  display: flex;
  flex-direction: column;

  width: 278px;
  padding: 15px 19px 24px;

  border-radius: 4px;
  border: 1px solid ${color.BoxInformationBorder};
  background: ${color.BoxInformationBackground};
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.05);

  .ant-input {
    margin-bottom: 17px;
  }

  .ant-btn-sm {
    height: 34px;
    span {
      font-size: 14px;
    }
  }
`;

const BoxTitle = styled.span`
  color: ${color.BoxInformationTitle};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const BoxContent = styled.span`
  width: 232px;
  margin: 3px 0 6px;
  color: ${color.BoxInformationDescription};
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Balsamiq Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export default {
  ContainerWrapper,
  FilterContainer,
  MultipleSelect,
  ExportContainer,
  DatePicker,
  EmptyTableAtribute,
  Title,
  TitleContainer,
  CustomerContainer,
  ExportInformationBox,
  BoxTitle,
  BoxContent,
};
