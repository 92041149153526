import { withRouter } from 'react-router';
import { Menu } from 'antd';

import { NavItem, NavLink } from 'modules/common/components/UIComponents';

import {
  getRouteDentistGuidesCatalog,
  getRouteDentistGuidesOrdered,
} from 'modules/dashboard/routes/dentist/navigation';

const DentistSideNav = ({ location }) => (
  <Menu
    mode="inline"
    selectedKeys={location.pathname.split('/')}
    defaultOpenKeys={location.pathname.split('/')}
    id="dentist-side-nav"
  >
    <NavItem key="catalog">
      <NavLink to={getRouteDentistGuidesCatalog()}>
        <span>Guide Catalog</span>
      </NavLink>
    </NavItem>
    <NavItem key="ordered">
      <NavLink to={getRouteDentistGuidesOrdered()}>
        <span>Ordered Guides</span>
      </NavLink>
    </NavItem>
  </Menu>
);

export default withRouter(DentistSideNav);
