import gql from 'graphql-tag';

export default gql(`
    query {
        fonts:fonts{
            id
            name
          }
    }
`);
