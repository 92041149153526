import { AddressForm } from 'modules/v2/editor/components';

const AddressFormView = ({
  fields,
  countries,
  states,
  onSubmit,
  onCancel,
  hasLeadingMessage,
  hasSubmitButton,
  addressFormRef,
  hasDefaultToggle,
}) => {
  return (
    <>
      {hasLeadingMessage && (
        <div className="text-neutral-500 font-medium text-sm ps-2 mb-6">
          Share your preferred shipping address with us.
        </div>
      )}
      <AddressForm
        fields={fields}
        countries={countries}
        states={states}
        onSubmit={onSubmit}
        onCancel={onCancel}
        hasSubmitButton={hasSubmitButton}
        hasDefaultToggle={hasDefaultToggle}
        ref={addressFormRef}
      />
    </>
  );
};

AddressFormView.defaultProps = {
  fields: {},
  countries: [],
  states: {},
  onCancel: null,
  hasLeadingMessage: true,
  hasSubmitButton: true,
  hasDefaultToggle: true,
  addressFormRef: null,
};

export default AddressFormView;
