import * as Yup from 'yup';

import { pincode as zip } from 'modules/editor/components/AddressForm/validationSchema';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('Type your first name'),
  lastName: Yup.string()
    .trim()
    .required('Type your last name'),
  phone: Yup.string()
    .trim()
    .required('Type your phone number')
    .min(10, 'Phone number must be at least 10 characters long')
    .max(17, 'Phone number must be at most 17 characters long')
    .matches(/^(\+?1[ -]?)?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4,})( x\d{4})?$/, {
      message: 'Please enter a valid Phone Number',
      excludeEmptyString: false,
    }),
  email: Yup.string()
    .trim()
    .required('Type your email address')
    .email('Email is not valid'),
  password: Yup.string()
    .trim()
    .required('Please enter your new password')
    .min(8, 'Password must be a minimum of 8-characters')
    .matches(/([^0-9]+\d+)|(\d+[^0-9]+)/, {
      message: 'Password must be a combination of numbers and letters',
      excludeEmptyString: false,
    }),
  billing: Yup.object().shape({
    address1: Yup.string()
      .trim()
      .required('Type your billing address'),
    country: Yup.string().required('Country is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip,
  }),
});

export default validationSchema;
