export const PinLocationIcon = ({
  width = 20,
  height = 20,
  stroke = '#38AB86',
  fill = 'none',
  strokeWidth = '1.5',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 8.45258C3.33337 4.70473 6.31814 1.6665 10 1.6665C13.6819 1.6665 16.6667 4.70473 16.6667 8.45258C16.6667 12.1711 14.5389 16.5102 11.2192 18.0619C10.4453 18.4236 9.55483 18.4236 8.78093 18.0619C5.46114 16.5102 3.33337 12.1711 3.33337 8.45258Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
      />
      <circle cx="10" cy="8.3335" r="2.5" stroke={stroke} strokeWidth={strokeWidth} fill={fill} />
    </svg>
  );
};
