import { PrimaryButton } from 'modules/common/components';

import S from './styles';

const Card = ({
  icon,
  title,
  description,
  buttonText,
  link,
  width,
  height,
  descriptionHeight,
  isNewTab,
}) => {
  const handleClick = () => {
    if (isNewTab) {
      window.open(link, '_blank');
    } else {
      window.location.assign(link);
    }
  };

  return (
    <S.CardContainer width={width} height={height}>
      <S.Icon>{icon}</S.Icon>
      <S.Title>{title}</S.Title>
      {description && (
        <S.Description descriptionHeight={descriptionHeight}>{description}</S.Description>
      )}
      <PrimaryButton type="link" onClick={handleClick}>
        {buttonText}
      </PrimaryButton>
    </S.CardContainer>
  );
};

export default Card;
