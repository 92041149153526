import getDISInstance from './disInstance';

const instance = getDISInstance();

export const createHubspotTicket = (body) => {
  const url = '/hubspot/ticket';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const uploadImage = (body) => {
  const url = '/upload/file';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateContact = ({ id, body }) => {
  const url = `/hubspot/contact/${id}`;
  return instance
    .patch(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
