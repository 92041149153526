import { spawn } from 'redux-saga/effects';

import draftWatchers from './draft';
import linksWatchers from './links';
import preflightWatchers from './preflight';
import previewWatchers from './preview';

export default function* saga() {
  yield spawn(draftWatchers);
  yield spawn(linksWatchers);
  yield spawn(preflightWatchers);
  yield spawn(previewWatchers);
}
