import { Authenticated } from 'modules/router/types';

// Pages
import {
  OrderedGuides,
  GuideCatalog,
  GuideAnalytics,
  GuideViewer,
  WelcomeDentistPage,
} from 'modules/v2/pages/Dentist';

import {
  ROUTE_DENTIST_GUIDES,
  ROUTE_DENTIST_GUIDES_CATALOG,
  ROUTE_DENTIST_GUIDES_ORDERED,
  ROUTE_DENTIST_GUIDES_VIEWER,
  ROUTE_DENTIST_GUIDES_ANALYTICS,
  ROUTE_DENTIST_GUIDES_WELCOME,
} from './constants';

const dentistRoutes = [
  {
    path: ROUTE_DENTIST_GUIDES,
    component: OrderedGuides,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_DENTIST_GUIDES_WELCOME,
    component: WelcomeDentistPage,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_DENTIST_GUIDES_CATALOG,
    component: GuideCatalog,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_DENTIST_GUIDES_ORDERED,
    component: OrderedGuides,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_DENTIST_GUIDES_VIEWER,
    component: GuideViewer,
    exact: true,
    type: Authenticated,
  },
  {
    path: ROUTE_DENTIST_GUIDES_ANALYTICS,
    component: GuideAnalytics,
    exact: true,
    type: Authenticated,
  },
];

export default dentistRoutes;
