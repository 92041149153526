import { Public } from 'modules/router/types';

import {
  ROUTE_PLUS_PLANS,
  ROUTE_PLUS_PLANS_ALREADY_PLUS,
  ROUTE_PLUS_PLANS_CONTRACT_BINDING,
  ROUTE_PLUS_PLANS_LOGIN,
  ROUTE_PLUS_PLANS_REVIEW,
  ROUTE_PLUS_PLANS_REVIEW_CONFIRMED,
  ROUTE_PLUS_PLANS_SCHEDULE,
} from './constants';

import { IsFeatureVisible } from '../../dashboard/utils/isAdminCustomer';
import PlusPlansLogin from '../pages/Login';
import PlusPlansReviewAndPay from '../pages/ReviewAndPay';
import PlusPlansSchedule from '../pages/Schedule';
import PlusPlans from '../index';
import PaymentConfirmation from '../components/PaymentConfirmation';
import AlreadyPlusPlan from '../components/AlreadyPlusPlan';
import PlusPlansContractBinding from '../pages/ContractBinding';

export const routes = IsFeatureVisible('FEATURE_PLUS_PLANS')
  ? [
    {
      type: Public,
      path: ROUTE_PLUS_PLANS,
      component: PlusPlans,
      children: [
        {
          path: ROUTE_PLUS_PLANS_LOGIN,
          component: PlusPlansLogin,
          exact: true,
        },
        {
          path: ROUTE_PLUS_PLANS_REVIEW,
          component: PlusPlansReviewAndPay,
          exact: true,
        },
        {
          path: ROUTE_PLUS_PLANS_REVIEW_CONFIRMED,
          component: PaymentConfirmation,
          exact: true,
        },
        {
          path: ROUTE_PLUS_PLANS_SCHEDULE,
          component: PlusPlansSchedule,
          exact: true,
        },
        {
          path: ROUTE_PLUS_PLANS_CONTRACT_BINDING,
          component: PlusPlansContractBinding,
          exact: true,
        },
        {
          path: ROUTE_PLUS_PLANS_ALREADY_PLUS,
          component: AlreadyPlusPlan,
          exact: true,
        },
      ],
    },
  ]
  : [];
