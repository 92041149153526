class IconProps {
  className?: string;

  stroke?: string;
}

const ConfirmationCheckIcon: React.FC<IconProps> = ({
  className,
  stroke = '#069668',
}) => {
  return (
    <svg className={className} width="57" height="56" viewBox="0 0 57 56" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_693_95111)">
        <rect x="3.5" y="2" width="50" height="50" rx="25" stroke={stroke} strokeWidth="2" />
        <path d="M18.2998 28.7L24.2363 35.5L39.0776 18.5" stroke={stroke} strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round" />
      </g>
      <defs>
        <filter id="filter0_d_693_95111" x="0.5" y="0" width="56" height="56" filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_693_95111" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_693_95111" result="shape" />
        </filter>
      </defs>
    </svg>

  );
};

export default ConfirmationCheckIcon;
