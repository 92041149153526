import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { CorrectIcon } from 'modules/v2/common/components/SvgIcons';

const ExpiredLink = ({ isResent, handleResend, email }) => {
  if (isResent === 0) {
    return (
      <h4 className="text-lg mt-4 font-figtree">
        <CorrectIcon className="w-6 h-6 inline-block mr-2" />
        Link has been Sent
      </h4>
    );
  }

  return (
    <div>
      <p className="text-sm my-4 text-center">
        The link for <strong>{email.username}</strong> has expired.
        <br />
        Kindly click on the below button to get the new activation link on your registered email
        address
      </p>
      <Button color="primary" size="lg" full onClick={handleResend}>
        Resend Link
      </Button>
    </div>
  );
};

export default ExpiredLink;
