import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

const ValidationSchema = Yup.object().shape({
  memberFirstName: validation.firstName,
  memberLastName: validation.lastName,
  memberPhone: validation.phoneNumber,
  memberEmail: validation.email,
});

export default ValidationSchema;
