import styled from 'styled-components';
import { Skeleton } from 'antd';
import * as color from 'modules/common/theme/color';

const ChartSkeleton = styled(Skeleton.Node)`
  width: 100%;
  height: 400px;
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color.White};
`;

const TooltipTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-bottom: 1px solid ${color.separateLine};
  width: 200px;
`;

const TooltipDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
`;

const TooltipItem = styled.div`
  display: flex;
  align-items: center;
`;

const SentEmailIcon = styled.div`
  width: 10px;
  height: 10px;
  border: 2px solid ${(props) => (props.color ? props.color : color.SentEmailsMetric)};
  border-radius: 50%;
  margin-right: 10px;
`;

const ClikedIcon = styled.div`
  width: 10px;
  height: 10px;
  border: 2px solid ${(props) => (props.color ? props.color : color.Black)};
  border-radius: 2px;
  margin-right: 10px;
`;

const TooltipTitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${color.Black};
  margin-right: 5px;
`;

const TooltipSubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const TooltipDescription = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${color.gray100};
  margin-right: 5px;
`;

export default {
  ChartSkeleton,
  SentEmailIcon,
  TooltipWrapper,
  ClikedIcon,
  TooltipTitle,
  TooltipSubTitle,
  TooltipDescription,
  TooltipTitleWrapper,
  TooltipDescriptionWrapper,
  TooltipItem,
};
