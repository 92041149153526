

const EmailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.97655 4.30965C2.00024 5.28596 2.00024 6.85731 2.00024 10C2.00024 13.1427 2.00024 14.7141 2.97655 15.6904C3.95287 16.6667 5.52421 16.6667 8.66691 16.6667H12.0002C15.1429 16.6667 16.7143 16.6667 17.6906 15.6904C18.6669 14.7141 18.6669 13.1427 18.6669 10C18.6669 6.85731 18.6669 5.28596 17.6906 4.30965C16.7143 3.33334 15.1429 3.33334 12.0002 3.33334H8.66691C5.52421 3.33334 3.95287 3.33334 2.97655 4.30965ZM15.8137 6.26656C16.0347 6.53173 15.9989 6.92584 15.7337 7.14681L13.9033 8.67215C13.1647 9.2877 12.566 9.78661 12.0376 10.1264C11.4872 10.4804 10.9511 10.7041 10.3336 10.7041C9.71601 10.7041 9.17997 10.4804 8.62956 10.1264C8.10117 9.78661 7.5025 9.2877 6.76387 8.67216L4.93346 7.14681C4.66829 6.92584 4.63246 6.53173 4.85344 6.26656C5.07442 6.00139 5.46852 5.96556 5.73369 6.18654L7.53277 7.68577C8.31024 8.33366 8.85002 8.78202 9.30573 9.07512C9.74686 9.35884 10.046 9.45407 10.3336 9.45407C10.6211 9.45407 10.9203 9.35884 11.3614 9.07512C11.8171 8.78202 12.3569 8.33366 13.1344 7.68577L14.9335 6.18654C15.1986 5.96556 15.5927 6.00139 15.8137 6.26656Z" fill="#252525"/>
    </svg>
  );
};
export default EmailIcon;
