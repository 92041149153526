import {
  CheckOutlined as CheckOutlinedBase,
  CloseOutlined as CloseOutlinedBase,
} from '@ant-design/icons/lib/icons';
import { Radio as RadioBase, Space as BaseSpace, Typography } from 'antd';
import Modal from 'modules/v2/common/components/Modal';
import Table from 'modules/v2/common/components/Table';
import styled from 'styled-components';

import { PrimaryButton } from 'modules/v2/common/components';
import * as color from 'modules/v2/common/theme/color';

const { Text } = Typography;

const DashboardWrapper = styled.div`
  margin-top: 64px;
  background-color: ${color.White};
`;

const Panel = styled.div`
  display: flex;
`;

const DashboardContent = styled.div`
  width: 70%;
`;

const MainContain = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 0px;
`;

const Title = styled.h5`
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  margin: 0px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${color.Black};
`;

const TopWrap = styled.div`
  margin: 30px 20px;
`;

const BookText = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  color: ${color.Black};
  margin-right: 20px;
  text-align: end;

  .ant-tooltip-inner {
    margin: -24px 0;
  }
`;

const BookCreditsWrap = styled.div`
  display: flex;
`;

const CurrentWrap = styled.div`
  display: flex;
  border: 1px solid ${color.LightGray};
  padding: 20px;
  border-radius: 5px;
  background-color: ${color.White};
  margin: 20px 0px;
`;

const PlanItem = styled.div`
  flex: 1;
`;

const BillingItem = styled.div`
  border-right: 1px solid ${color.LightGray};
  border-left: 1px solid ${color.LightGray};
  padding: 0px 40px;
  margin-right: 30px;
`;

const PlanTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: ${color.Black};
`;

const PlanDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${color.Black};
`;

const SeeAllLink = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;
  color: ${color.SkyBlue};
  text-align: center;
  cursor: pointer;
  margin-bottom: 21px !important;
  margin-top: 20px !important;
`;

const SWitchWrap = styled.div`
  display: flex;

  .ant-switch {
    margin-right: 20px;
    min-width: 32px;
    height: 18px;
    margin-top: 3px;
  }

  .ant-switch-loading-icon,
  .ant-switch::after {
    width: 14px;
    height: 14px;
    top: 0.5px;
  }

  .ant-switch-handle {
    top: 0;
    left: 0;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 0px);
  }
`;

const SwitchLabel = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: ${({ $active }) => ($active ? color.Black : color.LightHeadGray)};
  margin-right: 20px !important;
`;

const AnnuallyText = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: ${({ $active }) => ($active ? color.Black : color.LightHeadGray)};
`;

const InfoIcon = styled.span`
  margin-left: 8px;
  vertical-align: middle;

  svg {
    margin-top: 4px;
    cursor: pointer;
  }
`;

const ContactCustomerCare = styled.div`
  margin: unset;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;

  > div {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
`;

const UpgradeButton = styled(PrimaryButton)`
  margin: 0 !important;
`;

const Space = styled(BaseSpace)`
  width: 100%;
  justify-content: space-between;
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const MainSection = styled.div`
  padding: 20px;
`;

const FakeLink = styled.span`
  color: ${color.SkyBlue};
  cursor: pointer;
  text-decoration: underline;
`;

const BillingCycleSection = styled(BaseSpace)`
  background-color: ${color.GreyHover};
  padding: 20px;
`;

const PlanName = styled(Text)`
  font-size: 24px;
`;

const PlanTableSection = styled.div`
  margin-top: 60px;
`;

const CenterContent = styled.div`
  text-align: center;
`;

const RadioGroup = styled(RadioBase.Group)`
  background-color: ${color.GreyHover};
  padding: 10px !important;
  width: 280px !important;
  border-radius: 5px !important;
  display: inline-flex !important;
`;

const RadioButton = styled(RadioBase.Button)`
  color: ${color.RadioButtonUncheckedColor} !important;
  width: 100% !important;
  height: 45px !important;
  line-height: 43px !important;
  border-radius: 5px !important;
  margin: 0 5px !important;

  &.ant-radio-button-wrapper {
    background-color: transparent !important;
    border: none !important;
  }

  &.ant-radio-button-wrapper-checked {
    color: ${color.White} !important;
    background-color: ${color.SkyBlue} !important;
  }
`;

const UpgradePlanButton = styled(PrimaryButton)`
  width: 100% !important;
  visibility: ${(props) => (props.isActive ? 'hidden' : 'visible')} !important;

  &:disabled {
    background: ${color.PrimaryButtonHover} !important;
    color: ${color.White} !important;
  }
`;

const UpgradePlanModal = styled(Modal)`
  .ant-modal-body {
    max-height: 85vh !important;
  }
`;

const UpgradePlanTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: transparent !important;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    background-color: transparent !important;
  }
`;

const PerPlanBox = styled.div`
  background-color: ${(props) =>
    props.isActive ? 'transparent' : color.UpgradePlanHeader} !important;
  padding: 10px 20px;
`;

const PlanPrice = styled(Typography.Text)`
  font-size: 20px !important;
  margin-top: 20px;
  display: block;
`;

const CloseOutlined = styled(CloseOutlinedBase)`
  color: ${color.GreyBackground} !important;
  font-size: 20px;
`;

const CheckOutlined = styled(CheckOutlinedBase)`
  color: ${color.Black} !important;
  font-size: 20px;
`;

const TableText = styled.div`
  font-size: 16px;
`;

export default {
  Panel,
  BookCreditsWrap,
  ContactCustomerCare,
  DashboardWrapper,
  DashboardContent,
  MainContain,
  Title,
  Description,
  TopWrap,
  BookText,
  CurrentWrap,
  PlanTitle,
  PlanDescription,
  PlanItem,
  BillingItem,
  SeeAllLink,
  SWitchWrap,
  SwitchLabel,
  InfoIcon,
  AnnuallyText,
  UpgradeButton,
  Space,
  Section,
  MainSection,
  FakeLink,
  BillingCycleSection,
  PlanName,
  PlanTableSection,
  CenterContent,
  RadioGroup,
  RadioButton,
  UpgradePlanButton,
  UpgradePlanTable,
  UpgradePlanModal,
  PerPlanBox,
  PlanPrice,
  CloseOutlined,
  CheckOutlined,
  TableText,
};
