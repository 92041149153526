import pincode from './pincode';
import address1 from './address1';
import address2 from './address2';
import city from './city';
import country from './country';
import state from './state';

export default {
  pincode,
  address1,
  address2,
  city,
  country,
  state,
};
