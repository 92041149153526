import styled from 'styled-components';
import { Tooltip as TooltipBase, PrimaryButton } from 'modules/v2/common/components';
import * as color from 'modules/v2/common/theme/color';
import { Button } from 'antd';

const AddCampaignBtn = styled.div`
  display: block;
  button {
    margin-top: 12px;
  }
`;

const RowIcons = styled.div`
  display: inline-block;

  span.anticon {
    margin-right: 10px;
    vertical-align: middle;
    font-size: 16px;
  }
  span.anticon[aria-label='star'] {
    color: ${color.LightDullGray};
  }
  span.anticon[aria-label='eye'] {
    color: ${color.LightBlue};
  }
`;

const Name = styled.span`
  color: ${color.LightBlue};
  cursor: pointer;
`;

const MoreAction = styled.span`
  color: ${color.LightBlue};
  font-size: 25px;
`;

const HeaderSection = styled.div`
  display: flex;
  margin: 20px 0;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const HeaderTitle = styled.div`
  color: ${color.Black};
  flex: 3;
  font-size: 24px;
  margin: 0;
  font-weight: bold;
  padding: 0;
  margin: 8px 0 0 0;
`;

const Search = styled.div`
  flex: 0 0 1;
  margin-top: 54px;
  .ant-input-search {
    width: 247px;
  }
`;

const Tooltip = styled(TooltipBase)`
  color: ${color.LightBlue};
`;

const RowActionsSpan = styled.span`
  color: ${color.LightBlue};
  font-size: 16px;
  vertical-align: super;
  font-weight: bold;
  cursor: pointer;
`;

const SpanBackToManage = styled.span`
  color: ${color.LightBlue};
  font-size: 16px;
  margin-left: 10px;
  vertical-align: center;
  cursor: pointer;
`;

const InfoText = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 0;
  padding: 0;
  margin: 8px 0 0 0;
  color: ${color.LightBlue};
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: 600;
  color: ${color.LightBlue};
`;

List.Item = styled.li`
  padding: 5px;
  margin: 0;
  margin-right: -1px;

  &:last-child {
    margin-right: 0;
  }
`;

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;

const SubTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const SubTitlediv = styled.div`
  margin-bottom: 10px;
`;

const Table = styled.table`
  font-size: 18px;
  text-align: center;
  width: 80% !important;
  font-weight: 600 !important;
`;

const ListSubItem = styled.span`
  margin-left: 10px;
`;

const DeleteButton = styled(Button)`
  &.ant-btn {
    z-index: 9999;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    color: ${color.PrimaryColor};
    background-color: ${color.White};
    border: 2px solid ${color.PrimaryColor};
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 20px auto;
    cursor: pointer;
    float: right;

    :disabled {
      background-color: ${color.PrimaryDisabledColor};
      color: ${color.White};

      &:hover,
      &:active,
      &:focus {
        background-color: ${color.PrimaryDisabledColor};
        color: ${color.White};
      }
    }
  }
`;

const SpanBold = styled.span`
  font-weight: bold;
`;

const ButtonTitle = styled.span`
  font-weight: 800;
`;

const Container = styled.div`
  background-color: ${color.White};
  th {
    font-weight: bold;
  }
  tr {
    border: 1px solid ${color.LightDullGray};
  }
  tr td:last-child {
    text-align: left;
  }
`;

const SecondaryButton = styled(PrimaryButton)`
  background-color: ${color.LightBlue} !important;
  margin: 10px 0 !important;

  :disabled {
    background: ${color.ViewBlueDisabledButton} 0% 0% no-repeat padding-box !important;

    &:active,
    &:focus {
      background-color: ${color.ViewBlueDisabledButtonFocus} !important;
    }
  }
`;

const SecondaryButtonOutlined = styled(PrimaryButton)`
  border: 2px solid ${color.LightBlue}!important;
  color: ${color.LightBlue} !important;
  background-color: ${color.White} !important;
  margin: 10px 10px !important;

  :disabled {
    background: ${color.ViewBlueDisabledButton} 0% 0% no-repeat padding-box !important;

    &:active,
    &:focus {
      background-color: ${color.ViewBlueDisabledButtonFocus} !important;
    }
  }
`;
const Content = styled.div`
  padding: 30px;
  font-size: 18px;
  text-align: center;
`;

export default {
  SpanBold,
  Container,
  RowIcons,
  Name,
  HeaderSection,
  HeaderTitle,
  Search,
  Tooltip,
  AddCampaignBtn,
  RowActionsSpan,
  InfoText,
  SpanBackToManage,
  List,
  MoreAction,
  CheckBox,
  SubTitle,
  SubTitlediv,
  Content,
  Table,
  SecondaryButton,
  SecondaryButtonOutlined,
  ButtonSection,
  ButtonTitle,
  ListSubItem,
  DeleteButton,
};
