import { combineReducers } from 'redux';

import { CLEAR_PUBLISH } from 'modules/publish/store/constants';
import initialState from './initialState';

import addresses from './addresses';
import countries from './countries';
import credits from './credits';
import order from './order';
import preflight from './preflight';
import states from './states';

const combined = combineReducers({
  addresses,
  countries,
  credits,
  order,
  preflight,
  states,
});

const reducer = (state = initialState, action) => {
  if (action.type === CLEAR_PUBLISH) {
    return combined({ ...initialState }, action);
  }

  return combined(state, action);
};

export default reducer;
