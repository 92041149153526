import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';

const AvatarCropperWrapper = styled.div`
    .dragdropWrap {
      border: 2px dotted ${color.GreyFormBorder};
      border-radius: 3px;
      background-color: ${color.GreyMenuItemHover};
      margin: 20px 30px;
      min-height: 246px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      > div {
        width: 100%;
        outline: 0;
        cursor: pointer;
      }

      svg {
        fill: #569be6;
        width: 75px;
        margin: 0 auto;
        display: block;
      }

      p {
        color: ${color.GreySelectDd};
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        padding: 5px 0;
      }
    }

    .cropWrap {
      border: 2px dotted ${color.GreyFormBorder};
      border-radius: 3px;
      background-color: ${color.GreyMenuItemHover};
      margin-bottom: 20px;
      padding: 10px 20px;
      text-align: center;

      canvas {
        background-color: ${color.GreyMenuItemHover};
        width: 140px !important;
        height: 140px !important;
      }

      > p {
        font-size: 12px;
        font-weight: bold;
        line-height: 17px;
        color: ${color.GreySelectDd};
        padding: 0 0 5px 0;
        text-align: center;
        margin: 0;
      }

      .imgDetails {
        display: flex;
        padding: 20px 0 0;
        justify-content: center;

        p {
          width: 40%;
          font-size: 10px;
          font-weight: 600;
          line-height: 14px;
          color: ${color.GreySelectDd};
          text-align: left;
          position: relative;
          top: 3px;

          svg {
            width: 15px;
            display: inline-block;
            vertical-align: middle;
            fill: ${color.GreySelectDd};
            height: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
            white-space: nowrap;
          }

          span {
            display: inline-block;
            vertical-align: middle;
            padding: 0 0 0 5px;
            width: calc(100% - 24px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        ul {
          display: flex;
          justify-content: center;
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          width: 60%;
          position: relative;
          z-index: 10000;
          cursor: pointer;

          li {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            padding: 0 5px;

            svg {
              fill: ${color.GreySelectDd};
              width: 14px;
              height: auto;
            }
          }
        }
      }
    }
  }
`;


export default { AvatarCropperWrapper };
