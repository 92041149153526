import { spawn } from 'redux-saga/effects';

import { watchClearDraftPreview } from './clearDraftPreview';
import { watchGenerateDraftPreview } from './generateDraftPreview';
import { watchGetDraftPreview } from './getDraftPreview';

export default function* saga() {
  yield spawn(watchClearDraftPreview);
  yield spawn(watchGenerateDraftPreview);
  yield spawn(watchGetDraftPreview);
}
