import styled from 'styled-components';
import { device } from 'modules/common/theme/mediaqueries';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';
import { Layout as AntLayout } from 'antd';

const Layout = styled(AntLayout)`
  min-height: calc(100vh);
  height: 100%;
  position: relative;
  font-family: ${font.RobotoFont};
  padding-bottom: 120px;
`;

const NavigationContainer = styled.div`
  margin: 24px 0;

  &.navigation-wrap {
    span.anticon.anticon-check-circle {
      display: block;
      padding: 5px 10px;
      border-radius: 50%;
      font-size: 32px;
      line-height: 23px;
      position: relative;
      bottom: 16px;
      right: 17px;
      color: ${color.DefaultBlue};
    }
  }
`;

const FooterContainer = styled.div`
  height: 120px;
  background: ${color.OnboardConditionsText};
  color: ${color.White};
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const FooterWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 25px;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto;
  font-weight: 200;

  a {
    color: ${color.White};
  }

  @media ${device.tabletS} {
    width: 100%;
    font-size: 12px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 120px;
  border-radius: 5px;
  background: white;

  @media ${device.laptopL} {
    padding: 0 60px;
  }

  @media ${device.tabletS} {
    margin-top: 0;
    width: 100%;
  }

  @media ${device.mobileM} {
    padding: 0 20px;
  }
`;

const WrapperInner = styled.div`
  padding-bottom: 30px;
  margin: 0 auto;

  @media ${device.tabletS} {
    padding-top: 0;
  }
`;

const WrapperHeader = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;

  @media ${device.tabletS} {
    overflow: hidden;
    padding-bottom: 0;
  }
`;

const Container = styled.div`
  min-height: 200px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;

  h3 {
    margin-left: 15px;
    font-weight: 700;
  }

  @media ${device.tabletS} {
    padding: 8px 4px;
  }
`;

export default {
  Layout,
  Wrapper,
  WrapperInner,
  WrapperHeader,
  NavigationContainer,
  FooterContainer,
  FooterWrapper,
  Container,
};
