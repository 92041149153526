import env from 'environments';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { get, map } from 'lodash-es';

const { SCHEDULE_COACHING_DURATION } = env;

export const getUserTimeZone = -(new Date().getTimezoneOffset() / 60);

export const getCurrentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getCurrentTimeZoneWithDetachment = () => {
  const now = new Date();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const offsetMinutes = now.getTimezoneOffset();

  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMinutesPart = Math.abs(offsetMinutes) % 60;

  const offsetSign = offsetMinutes > 0 ? '-' : '+';
  const teimezoneFormatted = timeZone.replace(/_/g, ' ');

  const offsetString = `${teimezoneFormatted} ${offsetSign}${offsetHours}:${String(
    offsetMinutesPart,
  ).padStart(2, '0')}`;

  return offsetString;
};

export const formatTimeZone = (date, timeZoneId) =>
  moment.tz(moment(date).utc().format('YYYY-MM-DD HH:mm'), timeZoneId);

export const getTimeSelectedZone = (slot, timezone = getCurrentTimeZone, timeZoneId) => {
  const defaultTimeZone = formatTimeZone(slot, timeZoneId);
  return defaultTimeZone.clone().tz(timezone);
};

export const getTZDataTimeZonesList = () =>
  moment.tz.names().map((timezone) => ({
    title: `${timezone} (${moment.tz(timezone).format('Z')})`,
    value: timezone,
  }));

const generateSlots = (start, end) => {
  const slots = [];
  let localStart = new DateTime(start);
  do {
    slots.push(localStart);
    localStart = localStart.plus({ minutes: SCHEDULE_COACHING_DURATION });
  } while (localStart < end);

  return slots;
};

const removePastSlots = (bucket) => {
  return bucket.filter((slot) => {
    return slot > DateTime.now().plus({ hours: 2 });
  });
};

const timeShiftStart = (slot) => {
  const startMinute = parseInt(slot.toFormat('mm'), 10);
  const isWithinInterval = startMinute >= 0 && startMinute <= 45;

  return slot.set({ minute: isWithinInterval ? 0 : 45 });
};

const timeShiftEnd = (slot) => {
  const endMinute = parseInt(slot.toFormat('mm'), 10);

  if (endMinute >= 0 && endMinute <= SCHEDULE_COACHING_DURATION) {
    return slot.set({ minute: SCHEDULE_COACHING_DURATION });
  }

  return slot;
};

export const getFreeTimeSlots = (busySlots, date, userTimeZone) => {
  if (!busySlots) return [null, null];
  const startDate = DateTime.fromISO(date, { zone: 'America/New_york' }).set({
    hour: 9,
    minute: 0,
  });

  const endDate = DateTime.fromISO(date, { zone: 'America/New_york' }).set({ hour: 18, minute: 0 });

  let slots = generateSlots(startDate, endDate);

  let blockListSlots = [];

  busySlots.forEach((slot) => {
    const startSlot = get(slot, 'start');
    const endSlot = get(slot, 'end');
    const startDateObj = DateTime.fromISO(startSlot, { zone: 'America/New_york' });
    const endDateObj = DateTime.fromISO(endSlot, { zone: 'America/New_york' });

    const busyStart = timeShiftStart(startDateObj);
    const busyEnd = timeShiftEnd(endDateObj);

    const newBlockList = generateSlots(busyStart, busyEnd);

    blockListSlots = [...blockListSlots, ...newBlockList];
  });

  blockListSlots.forEach((blockedSlot) => {
    slots = slots.filter(
      (freeSlot) => !(freeSlot.toFormat('hh:mm') === blockedSlot.toFormat('hh:mm')),
    );
  });

  slots = removePastSlots(slots);

  return map(slots, (slot) => slot.setZone(userTimeZone).toFormat('hh:mm a'));
};

const WEEKEND = [moment().day('Saturday').weekday(), moment().day('Sunday').weekday()];

export const businessDays = (date, daysToAdd) => {
  let daysAdded = 0;
  let momentDate = moment(new Date(date));
  while (daysAdded < daysToAdd) {
    momentDate = momentDate.add(1, 'days');
    if (!WEEKEND.includes(momentDate.weekday())) {
      daysAdded += 1;
    }
  }
  return momentDate;
};
