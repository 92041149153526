import { get, includes } from 'lodash-es';

import { formatPhone } from 'modules/common/utils';

const shouldUpdateHeadshot = (originalId, currentId) => {
  const shouldUpdate = originalId === 'authorImageFront' && currentId === originalId;

  return shouldUpdate;
};

const shouldUpdateBookTitle = (originalId, currentId) => {
  const fields = ['bookTitleFrontcover', 'bookTitle', 'spineName'];

  const isTitle = includes(fields, originalId);
  const hasTitle = includes(fields, currentId);

  return isTitle && hasTitle;
};

const shouldUpdateAuthorName = (originalId, currentId) => {
  const fields = ['authorName', 'spineAuthor'];

  const isAuthorName = includes(fields, originalId);
  const hasName = includes(fields, currentId);

  return isAuthorName && hasName;
};

export const updateCoverElementImages = (id, defaultValue) => (element) => {
  const updateHeadshot = shouldUpdateHeadshot(id, element.id);

  if (!updateHeadshot && id !== element.id) {
    return element;
  }

  return {
    ...element,
    defaultValue,
  };
};

export const updateCoverElementRichText = (id, rawValue, defaultValue) => (element) => {
  const updateCoverTitle = shouldUpdateBookTitle(id, element.id);
  const updateAuthorName = shouldUpdateAuthorName(id, element.id);

  if (!updateCoverTitle && !updateAuthorName && id !== element.id) {
    return element;
  }

  return {
    ...element,
    rawValue,
    defaultValue,
  };
};

export const updateCoverElementsByPreferences = (preferences) => (element) => {
  // It's necessary to reset the rawValue, otherwise it will
  // keep displaying the data from the element when editing
  let { defaultValue, rawValue } = element;

  switch (element.id) {
    case 'authorName':
    case 'spineAuthor': {
      const name = get(preferences, 'name');

      if (name) {
        rawValue = undefined;
        defaultValue = name;
      }

      break;
    }

    case 'authorImageFront': {
      const image = get(preferences, ['authorImageFront', 'value']);

      if (image) {
        rawValue = undefined;
        defaultValue = image;
      }

      break;
    }
    case 'authorImageBack': {
      const frontImage = get(preferences, ['authorImageFront', 'value']);
      const image = get(preferences, ['authorImageBack', 'value'], frontImage);

      if (image) {
        rawValue = undefined;
        defaultValue = image;
      }

      break;
    }

    case 'brokerLogo':
    case 'realtorLogo':
    case 'brokerInfo': {
      const broker = get(preferences, [element.id, 'value']);

      if (broker) {
        rawValue = undefined;
        defaultValue = broker;
      }

      break;
    }

    case 'brokerContact': {
      // this is done in the backend by the single template
      // endpoint, but we are not using this endpoint anymore
      // so this manipulation had to be moved here for now.
      rawValue = undefined;
      defaultValue = `<p>${preferences.email}<br/>${formatPhone(preferences.phone)}</p>`;
      break;
    }
    default:
  }

  return {
    ...element,
    defaultValue,
    rawValue,
  };
};
