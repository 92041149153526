export const AddCircleIcon = ({ width = 20, height = 20, fill = '#3C3C3C', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 10L10 10M10 10L5 10M10 10L10 5M10 10L10 15"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
