import { Formik, Form } from 'formik';
import classnames from 'classnames';
import { find } from 'lodash';
import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

import { FormLabel } from 'modules/v2/common/components';
import { PasswordInput } from 'modules/v2/common/AtomicDesign/molecules';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { CorrectIcon } from 'modules/v2/common/components/SvgIcons';
import { CloseCircleIcon, CheckIconNew } from 'modules/v2/common/components/SvgIcon';

const resetPasswordSchema = Yup.object().shape({
  newPassword: validation.password,
  confirmPassword: validation.confirmPassword,
});

const validationRules = [
  {
    rule: /^.{8,}$/,
    message: 'Must be 8 characters or more',
  },
  {
    rule: /[a-z]+/,
    message: 'One lowercase character',
  },
  {
    rule: /[A-Z]+/,
    message: 'One uppercase character',
  },
  {
    rule: /\d/,
    message: 'Contains a number',
  },
  {
    rule: /[^\w\s]/,
    message: 'Contains a symbol',
  },
];

const validatePassword = (password) => {
  const validationMessages = [];

  validationRules.forEach(({ rule, message }) => {
    if (rule.test(password)) {
      validationMessages.push({ valid: true, message });
    } else {
      validationMessages.push({ valid: false, message });
    }
  });

  const touched = find(validationMessages, { valid: true });

  return validationMessages.map(({ valid, message }) => {
    if (!touched) {
      return (
        <div className="w-fit text-sm flex items-center gap-2">
          <span className="rounded-full w-3 h-3 flex justify-center items-center bg-neutral-500">
            <CloseCircleIcon fill="#ffffff" width={10} height={10} />
          </span>
          <span className="text-neutral-500">{message}</span>
        </div>
      );
    }
    return (
      <div className="w-fit text-sm flex items-center gap-2">
        <span
          className={classnames(
            'rounded-full w-3 h-3 flex justify-center items-center',
            valid ? 'bg-success-500' : 'bg-error-500',
          )}
        >
          {valid ? (
            <CheckIconNew width={10} height={10} />
          ) : (
            <CloseCircleIcon fill="#ffffff" width={10} height={10} />
          )}
        </span>
        <span className={classnames(valid ? 'text-success-500' : 'text-error-500')}>{message}</span>{' '}
      </div>
    );
  });
};

const SetPassword = ({ handleReset, message, resetPassword }) => {
  const resetSuccessMessage = (
    <h4 className="text-lg mt-4 font-figtree flex items-center justify-center gap-2">
      <CorrectIcon className="w-6 h-6 inline-block mr-2" />
      <p>You have set the password successfully.Please wait while we log you in</p>
    </h4>
  );

  const resetPasswordForm = (
    <div>
      <Formik
        initialValues={{ newPassword: '', confirmPassword: '' }}
        validationSchema={resetPasswordSchema}
        onSubmit={handleReset}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isValid, dirty }) => {
          const isDisabled = !isValid || !dirty;

          return (
            <Form onSubmit={handleSubmit}>
              <div className="mb-3.5">
                <FormLabel title="New password" htmlFor="newPassword" required>
                  <PasswordInput
                    id="newPassword"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.newPassword && errors.newPassword && (
                    <div className="grid grid-cols-2 gap-2 mt-2">
                      {validatePassword(values.newPassword)}
                    </div>
                  )}
                </FormLabel>
              </div>
              <FormLabel title="Re-enter new password" htmlFor="confirmPassword" required>
                <PasswordInput
                  id="confirmPassword"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                />
                {errors.confirmPassword ? (
                  <span className="text-error-500 mt-2">{errors.confirmPassword}</span>
                ) : null}
              </FormLabel>
              <Button
                size="lg"
                color="primary"
                buttonType="submit"
                className="mt-4"
                full
                disabled={isDisabled}
              >
                Update
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <div className="w-full">{resetPassword === 0 ? resetSuccessMessage : resetPasswordForm}</div>
  );
};

export default SetPassword;
