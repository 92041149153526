import styled from 'styled-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const SubWrapper = styled.div`
  opacity: 1;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ImportBtn = styled(Button)`
  width: 480px;
  height: 93px;
`;

const PlusCircle = styled(PlusCircleOutlined)`
  font-size: 23px;
  margin: 8px;
`;

const SubContent = styled.span`
  font-style: italic;
  font-size: 13px;
`;

export default {
  ImportBtn,
  SubWrapper,
  PlusCircle,
  SubContent,
};
