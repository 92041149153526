import S from './styles';

const CreditItemPay = ({ perAmount, credits, totalAmount, getStep }) => {
  return (
    <S.Container>
      <S.CreditWrapper>
        <S.Credit>{credits} Credits</S.Credit>
        <S.Description>${perAmount} each</S.Description>
        <S.Change onClick={() => getStep(1)}>Change</S.Change>
      </S.CreditWrapper>
      <S.Price>${totalAmount}</S.Price>
    </S.Container>
  );
};

export default CreditItemPay;
