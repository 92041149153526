import styled from 'styled-components';
import { Card } from 'antd';
import * as color from 'modules/common/theme/color';
import * as font from 'modules/common/theme/font';

const Wrapper = styled(Card)`
  background-color: ${color.White};
`;

const Title = styled.h3`
  color: ${color.WelcomeTitle};
  font-family: ${font.NunitoFont};
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 30px;
  padding-bottom: 5px;
  font-weight: 800;
  margin: 0;
`;

const Info = styled.p`
  min-height: 63px;
  font-size: 18px;
  line-height: 24px;
  color: ${color.WelcomeTitle};
  padding: 5px 0 10px 0;
  letter-spacing: 0.5px;
`;

export default { Info, Title, Wrapper };
