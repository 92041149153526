import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { QuestionCircleIcon } from 'modules/v2/common/components/SvgIcon';

interface QuestionLabelProps {
  description: string;
  title?: string;
  classNameBox?: string;
  classNameTitle?: string;
  timeout?: number;
  onHover?: boolean;
  displayNotification?: boolean;
}

const defaultProps: Partial<QuestionLabelProps> = {
  title: '',
  classNameBox: '',
  classNameTitle: '',
  timeout: 3000,
  onHover: false,
  displayNotification: true,
};

const QuestionLabel = ({
  title,
  description,
  classNameBox,
  classNameTitle,
  displayNotification,
}: QuestionLabelProps) => {
  const [showNotification, setShowNotification] = useState(false);

  const notification = (
    <div
      className={classNames(
        'z-20 absolute left-6 top-[-24px] w-[284px] transition-all px-5 py-2 bg-white border-[1px] border-neutral-100 rounded-lg shadow-notification',
        classNameBox,
      )}
    >
      <div className="mb-2.5 font-semibold">{title}</div>
      <div className="font-medium text-neutral-600">{description}</div>
    </div>
  );

  return (
    <div className="flex items-center space-x-2">
      <span className={classNames(classNameTitle)}>{title}</span>
      {displayNotification && (
        <div
          className="relative"
          onMouseEnter={() => setShowNotification(true)}
          onMouseLeave={() => setShowNotification(false)}
        >
          <QuestionCircleIcon />
          {showNotification && notification}
        </div>
      )}
    </div>
  );
};

QuestionLabel.defaultProps = defaultProps;

export default QuestionLabel;
