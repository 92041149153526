import { useState } from 'react';
import { BookLinksModal } from 'modules/bookLinks';
import { NavBar } from 'modules/v2/nav/components';
import { isDentistCustomer } from 'modules/v2/utils';
import { IsFeatureVisible } from 'modules/v2/utils/isAdminCustomer';
import {
  getRoutePlansAndBillings,
  getRouteProfile,
  getOrderedBooksRoute,
} from 'modules/v2/routes/navigation';
import {
  SettingIcon,
  CardIconNew,
  BoxIcon,
  LogoutIconNew,
} from 'modules/v2/common/components/SvgIcon';
import { HelpModal } from 'modules/v2/common/AtomicDesign/organisms';

const DashboardNavBar = ({ email, avatar, username, onLogout, mobileDevice, welcomeJourneyEligible=false }) => {
  const [isMyBookLinksModalOpen, setIsMyBookLinksModalOpen] = useState(false);
  const handleMyBookLinksCancel = () => setIsMyBookLinksModalOpen(false);
  const isDentist = isDentistCustomer();

  const [showHelpModal, setShowHelpModal] = useState(false);

  const bookLinksModal = isMyBookLinksModalOpen && (
    <BookLinksModal isOpen onCancel={handleMyBookLinksCancel} />
  );

  const sideContent = (
    <>
      <NavBar.UserMenu avatar={avatar} username={username} email={email}>
        {!welcomeJourneyEligible && <NavBar.UserMenu.Item
          icon={<SettingIcon />}
          key="Account settings"
          title="Account settings"
          to={getRouteProfile()}
        />
        }
        {IsFeatureVisible('FEATURE_UPGRADE_PLANS') && !isDentist && !welcomeJourneyEligible &&(
          <NavBar.UserMenu.Item
            icon={<CardIconNew />}
            title="Plans and billing"
            to={getRoutePlansAndBillings()}
          />
        )}
        {!welcomeJourneyEligible && <NavBar.UserMenu.Item
          icon={<BoxIcon />}
          key="Ordered books"
          title="Ordered books"
          to={getOrderedBooksRoute()}
        />}
        <NavBar.UserMenu.Item
          icon={<LogoutIconNew />}
          key="Logout"
          title="Logout"
          onClick={onLogout}
        />
      </NavBar.UserMenu>
      {bookLinksModal}
    </>
  );

  return (
    <>
      <NavBar
        sideContent={sideContent}
        mobileDevice={mobileDevice}
        setShowHelpModal={setShowHelpModal}
        fixed
      />
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
    </>
  );
};

export default DashboardNavBar;
