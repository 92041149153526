import {
  AuthenticatedRoute,
  AdminRoute,
  OnboardRoute,
  DentistRoute,
  PublicRoute,
  HolidaySaleRoute,
} from './components';

export const Authenticated = 'Authenticated';
export const Admin = 'Admin';
export const Onboard = 'Onboard';
export const Dentist = 'Dentist';
export const HolidaySale = 'HolidaySale';
export const Public = 'Public';

export const getRoute = (type) => {
  switch (type) {
    case Authenticated:
      return AuthenticatedRoute;

    case Admin:
      return AdminRoute;

    case Onboard:
      return OnboardRoute;

    case Dentist:
      return DentistRoute;

    case HolidaySale:
      return HolidaySaleRoute;

    case Public:
    default:
      return PublicRoute;
  }
};
