import { isEmpty, compact, map, filter } from 'lodash-es';
// Getting the inlineStyleRanges and converting them to be entityRanges

const HIGHLIGHT_STYLE = 'HIGHLIGHT';

export const entityRangesCreator = (range) => {
  if (range.length === 0) return {};
  const { offset, length } = range;

  // HIGHLIGHT style is our key for replaceable fields
  if (range.style === HIGHLIGHT_STYLE) {
    return { offset, length, key: 'label' };
  }

  return false;
};

export const blocksWithEntityRanges = (block) => {
  const {
    inlineStyleRanges: oldInlineStyleRanges = [],
    entityRanges: oldEntityRanges = [],
    ...blockProperties
  } = block;

  const entityRanges = [
    ...oldEntityRanges,
    ...compact(map(oldInlineStyleRanges, entityRangesCreator)),
  ];

  const inlineStyleRanges = filter(
    oldInlineStyleRanges,
    (range) => range.style !== HIGHLIGHT_STYLE,
  );

  return { ...blockProperties, inlineStyleRanges, entityRanges };
};

// We will add entity ranges to blocks based on inline style ranges
// and create entityMap
const getEntityObject = ({ blocks: oldBlocks, entityMap: oldEntityMap }) => {
  const entityMap = Object.assign(oldEntityMap, {
    label: {
      type: 'TOKEN',
      mutability: 'IMMUTABLE',
    },
  });

  const blocks = isEmpty(oldBlocks) ? [] : map(oldBlocks, blocksWithEntityRanges);

  return { blocks, entityMap };
};

export default getEntityObject;
