//

import S from './styles';

export const ReportCard = ({ name, value, description }) => (
  <S.Card>
    <S.CardTitle>{name}</S.CardTitle>
    <S.CardBody>
      <S.CardNumber>{value.toLocaleString()}</S.CardNumber>
      <S.CardDetail>
        <S.CardLabel>{description}</S.CardLabel>
      </S.CardDetail>
    </S.CardBody>
  </S.Card>
);
