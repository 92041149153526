import { getInstance } from './instance';

const instance = getInstance();

const registerCustomerEvent = (payload) => instance.post('/customer-events', payload);

const getCustomerEventsByCustomer = (customerId, perPage, page) => {
  const url = `customer-events/${customerId}`;

  const params = { perPage, page };

  return instance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export { registerCustomerEvent, getCustomerEventsByCustomer };
